import * as Yup from "yup";

interface FormValues {
  email: string;
  cohorts: string[];
}

export const schema = Yup.object().shape({
  email: Yup.string().email().required("Se requiere un email"),
  cohorts: Yup.array()
    .of(Yup.string())
    .required("Se requiere por lo menos un cohort"),
});

export const initialValues = (): FormValues => {
  return {
    email: "",
    cohorts: [],
  };
};
