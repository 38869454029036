import styled from "styled-components";

export const CohorListtItem = styled("div")`
  border-radius: 5px;
  padding: 6px 5px 5px 30px;
  font-size: 14px;
  color: #000f37;
  font-weight: 500;
`;

export const CohortListTitle = styled("h3")`
  font-size: 16px;
  font-weight: 500;
  color: #697482;
`;
