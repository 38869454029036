import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardTitle } from "./style";
import { Outlet } from "react-router-dom";
import List from "./components/List/index";
import { useRecruitingLayout } from "../../../provider/RecruitingProvider";
import { useDispatch } from "react-redux";
import { showModal } from "../../../store/actions/modal";
import { FlexContainer, PageTitle } from "../../../components/StyledComponents";
import Grid from "../../../components/Grid";
import { ReactElement, useState } from "react";
import InviteButton from "./components/InviteButton";
import InviteModalAcademy from "./components/InviteModalAcademy";
import InviteModalCohort from "./components/InviteModalCohort";

interface ComponentProps {
  // title: string;
  // icon: string;
  // list: { label: string; value: string | number }[];
  // ctas: {
  //   name: string;
  //   label: string;
  //   action: () => void;
  // };
  // tabs: {
  //   name: string;
  //   title: string;
  //   description: string;
  //   content: ReactNode;
  // }[];
}

const Component = ({}: ComponentProps) => {
  const [currentComponent, setCurrentComponent] = useState<ReactElement>();
  return (
    <>
      <PageTitle>Talentos</PageTitle>
      <FlexContainer justify="space-between" style={{ marginBottom: "10px" }}>
        <div></div>
        <FlexContainer align="center">
          <InviteButton
            text={"Invitar a Cohort"}
            modalName={"invite-cohort-modal-modal"}
          ></InviteButton>
          <InviteButton
            text={"Invitar a Academy"}
            modalName={"invite-academy-modal-modal"}
          ></InviteButton>
        </FlexContainer>
      </FlexContainer>
      <Grid.Row>
        <Grid.Col lg={4} style={{ marginBottom: "10px" }}>
          <Card style={{ padding: "10px", minHeight: "357px" }}>
            <CardTitle>Miembros</CardTitle>
            <List setCurrentComponent={setCurrentComponent} />
          </Card>
        </Grid.Col>

        <Grid.Col>
          <Card style={{ minHeight: "581px", padding: "20px 10px" }}>
            {currentComponent}
          </Card>
        </Grid.Col>
      </Grid.Row>
      <InviteModalAcademy></InviteModalAcademy>
      <InviteModalCohort></InviteModalCohort>
    </>
  );
};

export default Component;
