import * as Yup from "yup";

export const schema = Yup.object().shape({
  email: Yup.string()
    .email("El email es invalido.")
    .required("Este campo es requerido."),
});

interface FormValues {
  email: string;
}

export const initialValues: FormValues = {
  email: "",
};
