import styled from "styled-components";
import { FlexContainer } from "../../../../components/StyledComponents";

export const FinanceText = styled("h3")`
  font-size: ${(props) => props.size}px;
  font-weight: 500;
  color: #697482;
`;

export const FinanceWrapper = styled("div")`
  border-radius: 10px;
  box-shadow: 0 0 6px 0 rgba(21, 115, 229, 0.16);
  padding: 10px 13px 10px 10px;
  background-color: ${(props) => props.theme.colors["White"]};
`;

export const FinanceInputText = styled("div")`
  color: #000f37;
  font-weight: 500;
  font-size: ${(props) => props.size}px;
`;

export const FinanceInputTextBox = styled("div")`
  border-radius: 5px;
  box-shadow: 0 0 4px 0 rgba(21, 115, 229, 0.3);
  padding: 7px 10px;
  min-height: 30px;
  font-size: 14px;
`;

export const FinanceAccountPreview = styled("div")`
  position: relative;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.White};
  width: 100%;
  box-shadow: 0 0 2px 0 rgba(21, 115, 229, 0.16);
  height: ${(props) => props.height};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease-in-out;
  ${(props) =>
    props.hasAccount
      ? `background:url(https://unirse.com.ar/wp-content/uploads/2020/12/7181e84d50cb87fa4ab9a5a8ab613dbe.jpg);
        background-size:cover;
        background-position:center center;
      `
      : `&:hover {
    background-color: #1573e5;
    svg {
      color: #fff;
    }
    .title {
      color: #fff;
    }
  }`}
`;

export const FinanceBillWrapper = styled(FlexContainer)`
  height: 179px;
`;
