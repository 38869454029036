import {
  initialState,
  Actions,
  State,
  GET_ALL_CRITERIONS,
  GET_ALL_CRITERIONS_ERROR,
  GET_ALL_CRITERIONS_SUCCESS,
  GET_CRITERION,
  GET_CRITERION_ERROR,
  GET_CRITERION_SUCCESS,
  NEW_CRITERION,
  NEW_CRITERION_ERROR,
  NEW_CRITERION_SUCCESS,
  RESET_NEW_CRITERION,
  UPDATE_CRITERION,
  UPDATE_CRITERION_ERROR,
  UPDATE_CRITERION_SUCCESS,
  RESET_UPDATE_CRITERION,
  DELETE_CRITERION,
  DELETE_CRITERION_ERROR,
  DELETE_CRITERION_SUCCESS,
  RESET_DELETE_CRITERION,
  RESET_GET_ALL_CRITERIONS,
} from "../types/criterion";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_CRITERIONS:
      return {
        ...state,
        allCriterions: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_CRITERIONS_SUCCESS:
      return {
        ...state,
        allCriterions: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_CRITERIONS_ERROR:
      return {
        ...state,
        allCriterions: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_GET_ALL_CRITERIONS:
      return {
        ...state,
        allCriterions: initialState.allCriterions,
      };
    case GET_CRITERION:
      return {
        ...state,
        criterion: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_CRITERION_SUCCESS:
      return {
        ...state,
        criterion: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_CRITERION_ERROR:
      return {
        ...state,
        criterion: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case NEW_CRITERION:
      return {
        ...state,
        newCriterion: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_CRITERION_SUCCESS:
      return {
        ...state,
        newCriterion: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case NEW_CRITERION_ERROR:
      return {
        ...state,
        newCriterion: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_CRITERION:
      return {
        ...state,
        newCriterion: initialState.newCriterion,
      };
    case DELETE_CRITERION:
      return {
        ...state,
        deleteCriterion: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_CRITERION_SUCCESS:
      return {
        ...state,
        deleteCriterion: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_CRITERION_ERROR:
      return {
        ...state,
        deleteCriterion: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_CRITERION:
      return {
        ...state,
        deleteCriterion: initialState.deleteCriterion,
      };
    case UPDATE_CRITERION:
      return {
        ...state,
        updateCriterion: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_CRITERION_SUCCESS:
      return {
        ...state,
        updateCriterion: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_CRITERION_ERROR:
      return {
        ...state,
        updateCriterion: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_CRITERION:
      return {
        ...state,
        updateCriterion: initialState.updateCriterion,
      };
    default:
      return state;
  }
};

export default reducers;
