import React, { useEffect, useState } from "react";
import {
  GridCard,
  GridCardDescription,
  GridCardImage,
  GridCardImageContainer,
  GridCardTitle,
  GridCardWithLabel,
  GridStructure,
  ToolKit,
  ToolKitItem,
} from "../../../components/Layout/Dashboard/styles";
import {
  ButtonFilter,
  Center,
  FlexContainer,
  PageTitle,
} from "../../../components/StyledComponents";
import { Icon, Link, TrashIcon } from "./style";
import { connect, useDispatch } from "react-redux";
import { AcademyDto } from "../../../types/academy.dto";
import { UserDto } from "../../../types/user.dto";
import { BsFillTrashFill } from "react-icons/bs";
import contentActions from "../../../store/actions/content";
import FilterByName from "./components/FilterByName";
import { CohortDto } from "../../../types/cohort.dto";
import { ContentDto } from "../../../types/content.dto";
import ModalConfirmDelete from "../../../components/ModalConfirmDelete";
import { showModal } from "../../../store/actions/modal";
import Loader from "../../../components/Loader";
import { useNavigate } from "react-router-dom";
import { Img } from "../Cohort/Customize/style";
import Grid from "../../../components/Grid";
import Button from "../../../components/Button";
import NewModal from "./components/NewModal";
import { CurrentDto } from "../../../types/current.dto";
import { FiEdit } from "react-icons/fi";
import { FaTrash } from "react-icons/fa";
import EditModal from "./components/EditModal";
import { filterCohortByRole } from "../../../helpers/roleAcademy";
import { MdFlashlightOn } from "react-icons/md";
import { BiWorld } from "react-icons/bi";
import StateContentModal from "../../../components/StateContentModal";
import { StateDto } from "../../../types/states.dto";
import { FetchData } from "../../../types/fetchData.dto";
import InfinityScroll from "../../../components/InfinityScroll";
interface ComponentProps {
  user: UserDto;
  cohorts: CohortDto[];
  contents: ContentDto[];
  deleteStates;
  current: CurrentDto;
  updateManyContentStates: StateDto;
  contentsStates: StateDto;
  updateManyContents: ContentDto[];
  contentDeleted: ContentDto;
}

const Component = ({
  user,
  cohorts,
  contents,
  deleteStates,
  current,
  updateManyContentStates,
  contentsStates,
  updateManyContents,
  contentDeleted,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedContent, setSelectedContent] = useState<{
    name: string;
    _id: string;
  } | null>(null);
  const [currentContentsList, setCurrentContentsList] = useState<ContentDto[]>(
    []
  );
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);
  const [paginationOn, setPaginationOn] = useState<boolean>(false);
  const [applyedFilters, setFilters] = useState<
    {
      field: string;
      value: string | boolean;
      special: (data?: any, data2?: any) => boolean;
    }[]
  >([]);
  const [action, setAction] = useState<{ action: string; data: any }>();
  const [
    filteredCurrentContentsListValues,
    setFilteredCurrentContentsListValues,
  ] = useState<ContentDto[]>([]);
  const fetchData = ({ page, filterBy }: FetchData) => {
    if (page === 0) {
      setPaginationOn(true);
    }
    let payload = {
      limit: 9,
      offset: 9 * page,
      filterBy: {
        ...(filterBy ? filterBy : {}),
        active: true,
        academy: current.id,
      },
    };
    const select = {
      _id: 1,
      name: 1,
      link: 1,
      picture: 1,
      __v: 1,
      academy: 1,
      viewCount: 1,
      updatedAt: 1,
      createdAt: 1,
      users: 1,
    };
    const sortBy = "createdAt";

    const sortDirection = "desc";
    dispatch(
      contentActions.getAll({ ...payload, sortBy, sortDirection, select })
    );
  };
  const buildFilters = (newFilter) => {
    const filterUpdated =
      applyedFilters.filter(
        (applyedFilter) => applyedFilter.field === newFilter.field
      ).length > 0;

    const filterRemoved = newFilter.value === "no";

    /**
     * Se elimina el filtro
     */
    if (filterRemoved) {
      setFilters([
        ...applyedFilters.filter(
          (applyedFilter) => applyedFilter.field !== newFilter.field
        ),
      ]);
    }

    /**
     * Se actualiza un filtro
     */
    if (filterUpdated && !filterRemoved) {
      setFilters([
        ...applyedFilters.map((applyedFilter) => {
          if (applyedFilter.field === newFilter.field) {
            return newFilter;
          } else {
            return applyedFilter;
          }
        }),
      ]);
    }

    /**
     * Se agrega un filtro
     */
    if (!filterUpdated && !filterRemoved) {
      setFilters([...applyedFilters, newFilter]);
    }
  };

  useEffect(() => {
    if (deleteStates.success && contentDeleted) {
      setCurrentContentsList((state) =>
        state.filter((sta) => sta._id !== contentDeleted._id)
      );
    }
  }, [deleteStates, contentDeleted]);

  useEffect(() => {
    if (updateManyContentStates.success) {
      dispatch(contentActions.resetUpdateMany());
    }
    if (updateManyContentStates.error) {
      dispatch(contentActions.resetUpdateMany());
    }
  }, [updateManyContentStates]);

  useEffect(() => {
    if (!!selectedContent) {
      dispatch(showModal("confirm-delete-item-secuencial-modal"));
    }
  }, [selectedContent]);

  useEffect(() => {
    if (!!paginationOn) {
      if (!!contents && !isLoading) {
        const currentContents = contents.filter((content) =>
          current.role === "ADMIN"
            ? content
            : content.users.find((userFounded) => userFounded.id === user._id)
        );
        setCurrentContentsList((state) => [...state, ...currentContents]);
      }
      setThereAreItems(!!contents && contents?.length > 0);
    }
  }, [contents, isLoading, current, paginationOn]);

  useEffect(() => {
    setIsLoading(contentsStates.loading);
  }, [contentsStates]);

  useEffect(() => {
    const filteredTalents = currentContentsList?.filter(
      (currentTalentValues) => {
        const thereAreFilters = applyedFilters.length > 0;
        const totalOfFilters = applyedFilters.length;
        const passFilters = applyedFilters
          .filter((applyedFilter) => !applyedFilter.special)
          .filter(
            (applyedFilter) =>
              currentTalentValues[applyedFilter.field] === applyedFilter.value
          ).length;
        const specialFilter = applyedFilters.filter(
          (applyedFilter) => !!applyedFilter.special
        );

        const speacialFilterCheck = specialFilter.filter((applyedFilter) => {
          return applyedFilter.special(
            currentTalentValues,
            applyedFilter.value
          );
        });

        return (
          !thereAreFilters ||
          (thereAreFilters &&
            totalOfFilters === passFilters + specialFilter.length &&
            speacialFilterCheck.length === specialFilter.length)
        );
      }
    );

    setFilteredCurrentContentsListValues(filteredTalents);
  }, [applyedFilters, currentContentsList]);

  useEffect(() => {
    if (
      !!updateManyContents &&
      !!updateManyContentStates.success &&
      !!paginationOn
    ) {
      setCurrentContentsList(updateManyContents);
    }
  }, [updateManyContentStates, updateManyContents, paginationOn]);

  useEffect(() => {
    return () => {
      dispatch(contentActions.resetGetAllContents());
    };
  }, []);
  return (
    <>
      <PageTitle>Contenidos Varios</PageTitle>
      <Grid.Row>
        <Grid.Col
          offset={{ lg: 10, sm: 10, md: 10 }}
          lg={2}
          md={2}
          sm={2}
          style={{ marginBottom: "14px" }}
        >
          <Button
            onClick={() => {
              navigate(`management`);
            }}
            type="button"
            options={{
              type: "filled",
              skin: "primary",
              size: "md",
              marginBottom: "0px",
            }}
            style={{
              width: "100px",
              height: "50px",
            }}
          >
            Crear
          </Button>
        </Grid.Col>
      </Grid.Row>
      <Center>
        <Grid.Row style={{ width: "100%" }}>
          <Grid.Col lg={12} md={12} sm={12} style={{ marginBottom: "14px" }}>
            <FlexContainer
              style={{
                width: "100%",
                maxWidth: "700px",
                margin: "0 auto",
              }}
              justify="space-between"
              align="center"
              gap="14px"
            >
              <FilterByName
                items={filteredCurrentContentsListValues.map((content) => {
                  return { value: content.name, label: content.name };
                })}
                setFilter={buildFilters}
              ></FilterByName>
              <ButtonFilter
                onClick={() => {
                  dispatch(showModal("filter-cohort-modal"));
                }}
              >
                <MdFlashlightOn size={20}></MdFlashlightOn>
              </ButtonFilter>
              <ButtonFilter
                onClick={() => {
                  dispatch(showModal("change-content-states"));
                }}
              >
                <BiWorld size={20}></BiWorld>
              </ButtonFilter>
            </FlexContainer>
          </Grid.Col>
        </Grid.Row>
      </Center>
      <div style={{ height: "24px" }}></div>
      <InfinityScroll
        action={fetchData}
        page={page}
        setPage={setPage}
        data={filteredCurrentContentsListValues}
        active={thereAreItems}
        isLoading={isLoading}
      >
        <GridStructure width="275px" maxWidth="900px">
          {filteredCurrentContentsListValues.map((content, key) => (
            <>
              <GridCardWithLabel key={content._id}>
                {/*  <TrashIcon
                  onClick={() => {
                    setSelectedContent({
                      _id: content._id,
                      name: content.name,
                    });
                  }}
                >
                  <BsFillTrashFill color="red" fontSize={16}></BsFillTrashFill>
                </TrashIcon> */}
                <ToolKit className="toolkit" top="1px" right="1px">
                  <ToolKitItem
                    onClick={(ev) => {
                      ev.stopPropagation();
                      navigate(`management/${content._id}`);
                    }}
                  >
                    <FiEdit fontSize={15} color="#A9AFB7"></FiEdit>
                  </ToolKitItem>
                  <ToolKitItem
                    onClick={(ev) => {
                      ev.stopPropagation();
                      setAction({
                        action: "delete-content",
                        data: { _id: content._id, name: content.name },
                      });
                      dispatch(
                        showModal("confirm-delete-item-secuencial-modal")
                      );
                    }}
                  >
                    <FaTrash fontSize={15} color="#A9AFB7"></FaTrash>
                  </ToolKitItem>
                </ToolKit>
                <Link
                  href={content.link}
                  key={content._id}
                  onClick={(ev) => {
                    ev.stopPropagation();
                  }}
                  target="_blank"
                >
                  <GridCardImageContainer>
                    <GridCardImage
                      src={
                        content.picture ||
                        "https://upload.wikimedia.org/wikipedia/commons/8/81/Gallet_clamshell_600x600_6.jpg"
                      }
                    ></GridCardImage>
                  </GridCardImageContainer>
                  <FlexContainer justify="space-between">
                    <Icon
                      margin="0px 0px 0px 10px"
                      onClick={(ev) => {
                        ev.preventDefault();
                        navigate(`/dashboard/academy/${content.academy}/home`);
                      }}
                    >
                      <Img src={content.academy?.picture}></Img>
                    </Icon>
                    <FlexContainer direction="column" gap="5px">
                      <GridCardTitle>{content.name}</GridCardTitle>
                      <GridCardDescription>
                        {content.description}
                      </GridCardDescription>
                    </FlexContainer>
                  </FlexContainer>
                </Link>
              </GridCardWithLabel>
            </>
          ))}
        </GridStructure>
      </InfinityScroll>

      <NewModal></NewModal>
      {action?.action === "delete-content" && (
        <ModalConfirmDelete
          bntConfig={{
            content: "Quitar",
            style: {
              color: "Danger",

              options: {
                type: "filled",
                skin: "danger",
                size: "lg",
                marginBottom: "0px",
                loading: "Danger",
              },
            },
          }}
          states={deleteStates}
          elementActions={contentActions.deleteOne({ _id: action.data._id })}
          title={
            <span>
              {`¿Seguro deseas remover de tus contenidos a `}
              <span style={{ color: "#000" }}>{action.data.name}</span>?
            </span>
          }
          resetAction={contentActions.resetDeleteOne}
          resetState={setSelectedContent}
        ></ModalConfirmDelete>
      )}
      {action?.action === "edit-content" && (
        <EditModal content={action.data}></EditModal>
      )}

      <StateContentModal
        action={contentActions.updateMany}
        section="Contenidos Varios"
        loading={updateManyContentStates.loading}
        items={filteredCurrentContentsListValues}
      ></StateContentModal>
    </>
  );
};

const states = ({
  academyStore,
  userStore,
  cohortStore,
  contentStore,
  currentStore,
}) => {
  const { data: user } = userStore;
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: contents, states: contentsStates } = contentStore.all;
  const { states: updateManyContentStates, data: updateManyContents } =
    contentStore.updateMany;
  const { states: deleteStates, data: contentDeleted } = contentStore.delete;
  const { data: current } = currentStore;
  return {
    user,
    cohorts,
    contents,
    deleteStates,
    current,
    updateManyContentStates,
    contentsStates,
    updateManyContents,
    contentDeleted,
  };
};

export default connect(states)(Component);
