import {
  initialState,
  Actions,
  State,
  GET_ALL_SEARCHES,
  GET_ALL_SEARCHES_ERROR,
  GET_ALL_SEARCHES_SUCCESS,
  UPDATE_CURRENT_SEARCH_LIST,
  GET_SEARCH,
  GET_SEARCH_ERROR,
  GET_SEARCH_SUCCESS,
  NEW_SEARCH,
  NEW_SEARCH_ERROR,
  NEW_SEARCH_SUCCESS,
  RESET_NEW_SEARCH,
  UPDATE_SEARCH,
  UPDATE_SEARCH_ERROR,
  UPDATE_SEARCH_SUCCESS,
  RESET_UPDATE_SEARCH,
  DELETE_SEARCH,
  DELETE_SEARCH_ERROR,
  DELETE_SEARCH_SUCCESS,
  RESET_DELETE_SEARCH,
  SET_UPDATE_SEARCH_DATA,
  SET_NEW_SEARCH_DATA,
  UPDATE_MANY_SEARCH,
  UPDATE_MANY_SEARCH_SUCCESS,
  UPDATE_MANY_SEARCH_ERROR,
} from "../../types/recruiting/search";

const updateReducerArray = (array, data) =>
  array.map((elem) => (elem._id === data._id ? data : elem));

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_SEARCHES:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_SEARCHES_SUCCESS:
      return {
        ...state,
        all: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_SEARCHES_ERROR:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case UPDATE_CURRENT_SEARCH_LIST:
      return {
        ...state,
        all: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: false,
          },
        },
      };
    case GET_SEARCH:
      return {
        ...state,
        selected: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_SEARCH_SUCCESS:
      return {
        ...state,
        selected: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_SEARCH_ERROR:
      return {
        ...state,
        selected: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case NEW_SEARCH:
      return {
        ...state,
        create: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_MANY_SEARCH:
      return {
        ...state,
        updateMany: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_MANY_SEARCH_SUCCESS:
      return {
        ...state,
        updateMany: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_MANY_SEARCH_ERROR:
      return {
        ...state,
        updateMany: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case SET_NEW_SEARCH_DATA:
      return {
        ...state,
        all: {
          ...state.all,
          data: !!state.all.data
            ? [...state.all.data, action.payload]
            : [action.payload],
        },
      };
    case SET_UPDATE_SEARCH_DATA:
      return {
        ...state,
        all: {
          ...state.all,
          data: !!state.all.data
            ? updateReducerArray(state.all.data, action.payload)
            : null,
        },
      };
    case NEW_SEARCH_SUCCESS:
      return {
        ...state,
        create: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case NEW_SEARCH_ERROR:
      return {
        ...state,
        create: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_SEARCH:
      return {
        ...state,
        create: initialState.create,
      };
    case DELETE_SEARCH:
      return {
        ...state,
        delete: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_SEARCH_SUCCESS:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_SEARCH_ERROR:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_SEARCH:
      return {
        ...state,
        delete: initialState.delete,
      };
    case UPDATE_SEARCH:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_SEARCH_SUCCESS:
      return {
        ...state,
        update: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_SEARCH_ERROR:
      return {
        ...state,
        update: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_SEARCH:
      return {
        ...state,
        update: initialState.update,
      };
    default:
      return state;
  }
};

export default reducers;
