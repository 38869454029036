import styled from "styled-components";

export const Layout = styled("div")<{ sidebarCollapsed: boolean }>`
  padding: 44px 0px 0px 90px;
  height: 100vh;
  overflow-y: auto;
  transition: padding 0.3s ease-in-out;
  position: relative;
  ${(props) => (props.isMobile ? "padding:0px" : "")}
`;

export const Content = styled("main")`
  transition: all 0.3s ease-in-out;
  padding: ${(props) => (props.isMobile ? "60px 0px 5px 0px" : "30px 20px")};
  max-height: calc(100vh - 55px);
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
`;

export const Sidebar = styled("aside")<{
  sidebarCollapsed: boolean;
  isMoble: boolean;
}>`
  background-color: white;
  box-shadow: 0px 4px 6px rgba(36, 150, 237, 0.1);
  display: grid;
  max-width: ${({ sidebarCollapsed, isMobile }) =>
    sidebarCollapsed ? (isMobile ? "0px" : "90px") : "260px"};
  overflow: ${({ sidebarCollapsed }) =>
    sidebarCollapsed ? "visible" : "hidden"};
  transition: max-width 0.3s ease-in-out;
  overflow-y: auto;
  grid-template-rows: auto 1fr;
  grid-row-gap: 40px;
  width: 260px;
  z-index: ${({ modal, isMobile }) =>
    modal ? "-1" : isMobile ? "7002" : "7000"};
  transition: max-width 0.3s ease-in-out;
  position: fixed;
  left: 0px;
  top: ${({ isMobile }) => (isMobile ? "0px" : "44px")};
  bottom: 0px;
`;

export const SidebarHeader = styled("div")`
  position: relative;
  width: 100%;
  max-width: ${({ sidebarCollapsed }) => (sidebarCollapsed ? "90px" : "260px")};
  transition: max-width 0.3s ease-in-out;
`;

export const SidebarBody = styled("div")`
  overflow: ${({ sidebarCollapsed }) =>
    sidebarCollapsed ? "visible" : "hidden"};
  overflow-y: ${({ sidebarCollapsed }) =>
    sidebarCollapsed ? "visible" : "auto"};
`;

export const SidebarToggle = styled("button")<{ sidebarCollapsed: boolean }>`
  position: absolute;
  right: ${({ sidebarCollapsed }) => (sidebarCollapsed ? "25px" : "12px")};
  transition: right 0.3s ease-in-out;
  top: calc(50% + 4px);
  transform: translate(0%, -50%);
  border: 0;
  background: white;
  padding: 10px;
  cursor: pointer;
`;

export const GridSwitchStructure = styled("div")`
  ${(props: any) => {
    return `
    display: grid;
  width: 90%;
  max-width: ${!!props.maxWidth && props.maxWidth};
  justify-content: center;
  margin: 0 auto;
  gap: 20px;
  ${
    !!props.grid
      ? ` grid-template-columns: ${`repeat(${
          !!props.type ? props.type : "auto-fill"
        }, minmax(${props.width}, 1fr))`};`
      : `grid-template-columns: 1fr`
  }
    
    `;
  }}
`;

export const GridStructure = styled("div")`
  display: grid;
  width: 100%;
  grid-template-columns: ${(props) =>
    `repeat(${props.type || "auto-fill"}, minmax(${props.width}, ${
      props.limitCard ? props.limitCard : "1fr"
    }))`};
  justify-content: center;
  max-width: ${(props) => props.maxWidth};
  margin: 0 auto;
  gap: 20px;
`;

export const GridCardSmallContainer = styled("div")`
  width: 36px;
  height: 36px;
  border-radius: 10px;
  overflow: hidden;
`;

export const GridComment = styled("h6")`
  font-size: 12px;
  display: inline-block;
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  color: #6a6970;
`;

export const GridCard = styled("div")`
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  padding: ${(props) => (props.pd ? props.pd : "3px")};
  &:hover::before {
    background-color: #4f26a2;
  }
  &::before {
    border-radius: 10px;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0.05;
    z-index: -1;
    background-color: transparent;
  }

  ${(props) => !!props.style && props.style}
`;

export const GridCardWithLabel = styled(GridCard)`
  &:hover > .toolkit {
    display: flex;
  }
`;
export const TrashIcon = styled("div")`
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 2px;
`;

export const ToolKit = styled("div")`
  position: absolute;
  top: ${(props) => props.top};
  right: ${(props) => props.right};
  flex-direction: column;
  padding: 2px 7px;
  gap: 10px;
  border-radius: 10px;

  background-color: #fff;
  align-items: center;
  display: none;
`;

export const ToolKitItem = styled("div")`
  cursor: pointer;
`;

export const GridCardImageContainer = styled("div")`
  border-radius: 10px;
  overflow: hidden;
  height: 150px;
  margin-bottom: 8px;
`;
export const GridCardImage = styled("img")`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const GridCardTitle = styled(`h3`)`
  font-size: 16px;
  font-weight: bold;
  color: #2a2747;
   @media (max-width: 768px) {
     font-size: 13px;
  }
}
`;

export const GridCardCenter = styled("div")`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const GridCardDescription = styled("p")`
  max-width: 200px;
  word-break: break-all;
  text-overflow: ellipsis;
  font-size: 12px;
  color: #6a6970;
`;
