import {
  GetAllCertificates,
  GET_ALL_CERTIFICATES,
  GetCertificate,
  GET_CERTIFICATE,
  UpdateCertificate,
  UPDATE_CERTIFICATE,
  DeleteCertificate,
  DELETE_CERTIFICATE,
  GET_CERTIFICATE_SUCCESS,
  GetCertificateSuccess,
  NewCertificate,
  NEW_CERTIFICATE,
  ResetNewCertificate,
  RESET_NEW_CERTIFICATE,
  ResetDeleteCertificate,
  RESET_DELETE_CERTIFICATE,
  ResetUpdateCertificate,
  RESET_UPDATE_CERTIFICATE,
  DELETE_CERTIFICATE_MEMBER,
  DeleteCertificateMember,
} from "../types/certificate";

export const getAllCertificates = (): GetAllCertificates => {
  return { type: GET_ALL_CERTIFICATES, payload: null };
};

export const getCertificate = (id): GetCertificate => {
  return { type: GET_CERTIFICATE, payload: id };
};

export const setSelectedCertificate = (payload): GetCertificateSuccess => {
  return { type: GET_CERTIFICATE_SUCCESS, payload };
};

export const deleteMember = (payload): DeleteCertificateMember => {
  return { type: DELETE_CERTIFICATE_MEMBER, payload };
};

export const updateCertificate = (payload): UpdateCertificate => {
  return { type: UPDATE_CERTIFICATE, payload };
};

export const resetUpdateCertificate = (): ResetUpdateCertificate => {
  return { type: RESET_UPDATE_CERTIFICATE, payload: null };
};

export const newCertificate = (payload): NewCertificate => {
  return { type: NEW_CERTIFICATE, payload };
};

export const resetNewCertificate = (): ResetNewCertificate => {
  return { type: RESET_NEW_CERTIFICATE, payload: null };
};

export const deleteCertificate = (id): DeleteCertificate => {
  return { type: DELETE_CERTIFICATE, payload: id };
};

export const resetDeleteCertificate = (): ResetDeleteCertificate => {
  return { type: RESET_DELETE_CERTIFICATE, payload: null };
};
