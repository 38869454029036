import styled from "styled-components";
import { SectionTitle } from "../../../../../../components/StyledComponents";

export const GrowingStageWrapper = styled("div")`
    border-radius: 20px;
    box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.16);  
    padding: 15px 15px;
    margin-top: ${(props) => (props.mt ? props.mt : "30px")};
    height:600px;
}
`;

export const TitleStage = styled(SectionTitle)`
  font-size: 12px;
  margin: 0;
`;

export const TitleCriterion = styled(TitleStage)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const GrowingStages = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-evenly;
`;

export const GrowingStage = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 200px;
  justify-content: center;
`;

export const GrowingStageValue = styled("h3")`
  font-size: 60px;
  font-weight: 500;
  color: #001e6c;
  margin-bottom: 20px;
`;

export const GrowingText = styled("p")`
  color: #697482;
  font-size: 11px;
  font-weight: 500;
`;

export const GrowingStageArrow = styled("div")`
  position: absolute;
  top: 40px;
  right: -10px;
`;
