import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Button from "../../../../../components/Button";
import ReactSelect from "../../../../../components/Form/ReactSelect";
import { FlexContainer } from "../../../../../components/StyledComponents";
import {
  deleteMember,
  getAllCohorts,
  resetDeleteCohort,
  resetGetAllCohortsData,
} from "../../../../../store/actions/cohort";
import academyActions from "../../../../../store/actions/academies";
import { AcademyDto } from "../../../../../types/academy.dto";
import { CohortDto } from "../../../../../types/cohort.dto";
import { CurrentDto } from "../../../../../types/current.dto";
import { UsersDto } from "../../../../../types/users.dto";
import CohortModal from "../../../Academy/Gestion/components/MembersList/components/CohortModal";
import { Img } from "../../../Cohort/Customize/style";
import { EntityImageContainer } from "../../../Cohort/Events/style";
import { EntityTitleSemiBold, ListItem } from "../../style";
import { CardTitle } from "./style";
import { Formik } from "formik";
import { schema } from "../../../../../constants/form/auth/sign-in";
import Grid from "../../../../../components/Grid";
import { useDispatch } from "react-redux";
import Loader from "../../../../../components/Loader";
import { StateDto } from "../../../../../types/states.dto";
import { toast } from "react-toastify";
import { UserDto } from "../../../../../types/user.dto";
import ModalConfirmDelete from "../../../../../components/ModalConfirmDelete";
import { hideModal, showModal } from "../../../../../store/actions/modal";
import usersActions from "../../../../../store/actions/users";
interface ComponentProps {
  cohorts: CohortDto[];
  section: "academy" | "cohort";
  current: CurrentDto;
  users: UsersDto[];
  academies: AcademyDto[];
  deleteCohortMemberStates: StateDto;
  deleteAcademyMemberStates: StateDto;
  user: UserDto;
  updateUsersData;
  updateUsersStates: StateDto;
  updateAcademyData;
  updateAcademyStates;
  deleteCohortMember;
}

const Component = ({
  section,
  cohorts,
  current,
  users,
  academies,
  deleteCohortMemberStates,
  deleteAcademyMemberStates,
  user,
  updateUsersData,
  updateUsersStates,
  updateAcademyData,
  updateAcademyStates,
  deleteCohortMember,
}: ComponentProps) => {
  const [selectedCohort, setSelectedCohort] = useState<CohortDto>();
  const [fetchOn, setFetchOn] = useState<boolean>(false);
  const [action, setAction] = useState<{ action: string; data: any }>();

  const [userItems, setUserItems] = useState<UsersDto[]>();
  const isCohort = section === "cohort";
  const dispatch = useDispatch();
  const payloadCohort = {
    filterBy: {
      academy: current.id,
      active: true,
    },
    select: {
      _id: 1,
      name: 1,
      studentsIds: 1,
      mentorsIds: 1,
      hidden: 1,
    },
  };
  useEffect(() => {
    if (updateAcademyStates.success) {
      setSelectedCohort(undefined);
      dispatch(
        usersActions.getAllByAcademy({
          _id: current.id,
          payload: { filterBy: {} },
        })
      );
      dispatch(academyActions.resetUpdate());
      dispatch(hideModal());
    }
    if (updateAcademyStates.error) {
      toast.error(updateAcademyStates.error);
      dispatch(academyActions.resetUpdate());
      dispatch(hideModal());
    }
  }, [updateAcademyStates]);
  useEffect(() => {
    if (deleteCohortMemberStates.success) {
      setSelectedCohort(undefined);
      dispatch(resetDeleteCohort());
      dispatch(hideModal());
    }
    if (deleteCohortMemberStates.error) {
      toast.error(deleteCohortMemberStates.error);
      dispatch(resetDeleteCohort());
      dispatch(hideModal());
    }
  }, [deleteCohortMemberStates]);

  useEffect(() => {
    if (deleteCohortMemberStates.success) {
      dispatch(getAllCohorts(payloadCohort));
    }
  }, [deleteCohortMemberStates]);

  useEffect(() => {
    if (!!action) {
      dispatch(showModal("confirm-delete-item-secuencial-modal"));
    }
  }, [action]);

  useEffect(() => {
    if (!cohorts && fetchOn) dispatch(getAllCohorts(payloadCohort));
  }, [cohorts, fetchOn]);

  useEffect(() => {
    if (users && fetchOn) {
      setUserItems(users);
    }
  }, [users]);

  useEffect(() => {
    if (!users && fetchOn)
      dispatch(
        usersActions.getAllByAcademy({
          _id: current.id,
          payload: { filterBy: {} },
        })
      );
  }, [users, fetchOn]);

  useEffect(() => {
    if (!academies && fetchOn)
      dispatch(academyActions.getAll({ filterBy: { _id: current.id } }));
  }, [academies, fetchOn]);

  useEffect(() => {
    if (!academies && !users && !cohorts) {
      setFetchOn(true);
    }
  }, [academies, users, cohorts]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllCohortsData());
      dispatch(academyActions.resetGetAllAcademiesData());
      dispatch(usersActions.resetGetAll());
    };
  }, []);

  if (!cohorts || !userItems || !academies)
    return <Loader color="Primary"></Loader>;

  return (
    <>
      {isCohort && (
        <>
          <CardTitle>Selecciona el Cohort</CardTitle>
          <Formik
            initialValues={{}}
            onSubmit={(values, actions) => {}}
            validateOnBlur={false}
            validationSchema={schema}
            enableReinitialize
          >
            {({
              touched,
              errors,
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => {
              return (
                <Grid.Row>
                  <Grid.Col sm={8}>
                    <ReactSelect
                      name="private"
                      {...(!!cohorts && {
                        items: cohorts
                          .filter((cohort) =>
                            current.role === "PM"
                              ? cohort.mentorsIds.includes(user._id)
                              : cohort
                          )
                          .map((cohort) => {
                            return {
                              value: cohort._id,
                              label: cohort.name,
                            };
                          }),
                      })}
                      onOptionSelected={(newValue) => {
                        if (newValue) {
                          const cohortFounded = cohorts.filter(
                            (cohort) => cohort._id === newValue.value
                          )[0];
                          setSelectedCohort(cohortFounded);
                        }
                      }}
                      style={{ borderRadius: "5px" }}
                      placeholder="Seleccione un cohort"
                      options={{
                        label: "Cohort",
                        marginBottom: 20,
                        skin: "base",
                      }}
                    />
                  </Grid.Col>
                </Grid.Row>
              );
            }}
          </Formik>
          {selectedCohort ? (
            !selectedCohort?.hidden ? (
              userItems.filter(
                (userFounded) =>
                  selectedCohort.studentsIds.includes(userFounded._id) ||
                  selectedCohort.mentorsIds.includes(userFounded._id)
              ).length === 0 ? (
                <h3 style={{ textAlign: "center" }}>No hay resultados</h3>
              ) : (
                <FlexContainer
                  direction="column"
                  gap="10px"
                  style={{
                    maxHeight: "390px",
                    minHeight: "390px",
                    overflowY: "auto",
                  }}
                >
                  {userItems
                    .filter(
                      (userFounded) =>
                        selectedCohort.studentsIds.includes(userFounded._id) ||
                        selectedCohort.mentorsIds.includes(userFounded._id)
                    )
                    .map((userFounded) => {
                      return (
                        <ListItem>
                          <FlexContainer justify="space-between" align="center">
                            <FlexContainer align="center" gap="10px">
                              <EntityImageContainer>
                                <Img src={userFounded.picture}></Img>
                              </EntityImageContainer>
                              <EntityTitleSemiBold>
                                {`${userFounded.name} ${userFounded.lastName}`}
                              </EntityTitleSemiBold>
                            </FlexContainer>
                            <FlexContainer gap="20px" align="center">
                              <Button
                                type="button"
                                loading={deleteCohortMemberStates.loading}
                                onClick={() => {
                                  setAction({
                                    action: "remove-from-cohort-member",
                                    data: {
                                      _id: selectedCohort?._id,
                                      userId: userFounded._id,

                                      name: `${userFounded.name} ${userFounded.lastName}`,
                                    },
                                  });
                                }}
                                options={{
                                  type: "outline",
                                  size: "sm",
                                  skin: "gray",
                                }}
                                style={{
                                  borderRadius: "10px",
                                  minWidth: "77px",
                                  width: "156px",
                                  fontSize: "12px",
                                  fontWeight: "200",
                                }}
                              >
                                Eliminar de Cohort
                              </Button>
                            </FlexContainer>
                          </FlexContainer>
                        </ListItem>
                      );
                    })}
                </FlexContainer>
              )
            ) : (
              <h3 style={{ textAlign: "center" }}>
                Si la academia es publica se pueden suscribir directamente
              </h3>
            )
          ) : null}
        </>
      )}

      {!isCohort && (
        <>
          {!!academies && !!userItems ? (
            !academies.find((academy) => academy.hidden) ? (
              userItems?.length === 0 ? (
                <h3 style={{ textAlign: "center" }}>No hay resultados</h3>
              ) : (
                <FlexContainer
                  direction="column"
                  gap="10px"
                  style={{
                    maxHeight: "390px",
                    minHeight: "390px",
                    overflowY: "auto",
                  }}
                >
                  {userItems.map((userFounded) => {
                    return (
                      <ListItem>
                        <FlexContainer justify="space-between" align="center">
                          <FlexContainer align="center" gap="10px">
                            <EntityImageContainer>
                              <Img src={userFounded.picture}></Img>
                            </EntityImageContainer>
                            <EntityTitleSemiBold>
                              {`${userFounded.name} ${userFounded.lastName}`}
                            </EntityTitleSemiBold>
                          </FlexContainer>
                          <FlexContainer gap="20px" align="center">
                            <Button
                              type="button"
                              loading={deleteAcademyMemberStates.loading}
                              onClick={() => {
                                setAction({
                                  action: "remove-from-academy-member",
                                  data: {
                                    _id: current.id,
                                    userId: userFounded._id,
                                    entity: "academy",
                                    name: `${userFounded.name} ${userFounded.lastName}`,
                                  },
                                });
                              }}
                              options={{
                                type: "outline",
                                size: "sm",
                                skin: "gray",
                              }}
                              style={{
                                borderRadius: "10px",
                                minWidth: "77px",
                                width: "156px",
                                fontSize: "12px",
                                fontWeight: "200",
                              }}
                            >
                              Eliminar de Academy
                            </Button>
                          </FlexContainer>
                        </FlexContainer>
                      </ListItem>
                    );
                  })}
                </FlexContainer>
              )
            ) : (
              <h3 style={{ textAlign: "center" }}>
                Si la academia es publica se pueden suscribir directamente
              </h3>
            )
          ) : null}
        </>
      )}

      {action?.action === "remove-from-cohort-member" && (
        <ModalConfirmDelete
          bntConfig={{
            content: "Quitar",
            style: {
              options: {
                type: "outline",
                skin: "danger",
                size: "lg",
                marginBottom: "0px",
              },
            },
          }}
          states={deleteCohortMemberStates}
          title={
            <span>
              {`¿Seguro que quiere eliminar del cohort a `}
              <span style={{ fontWeight: "bold", color: "black" }}>
                {action.data.name}
              </span>
              ?
            </span>
          }
          elementActions={deleteMember(action.data)}
          resetAction={resetDeleteCohort}
          resetState={setAction}
        ></ModalConfirmDelete>
      )}
      {action?.action === "remove-from-academy-member" && (
        <ModalConfirmDelete
          bntConfig={{
            content: "Quitar",
            style: {
              options: {
                type: "outline",
                skin: "danger",
                size: "lg",
                marginBottom: "0px",
              },
            },
          }}
          states={updateAcademyStates}
          title={
            <span>
              {`¿Seguro que quiere eliminar de la academia a `}
              <span style={{ fontWeight: "bold", color: "black" }}>
                {action.data.name}
              </span>
              ?
            </span>
          }
          elementActions={academyActions.deleteMember(action.data)}
          resetAction={academyActions.resetUpdate}
          resetState={setAction}
        ></ModalConfirmDelete>
      )}
    </>
  );
};
const states = ({
  cohortStore,
  currentStore,
  usersStore,
  academyStore,
  userStore,
}) => {
  const { data: cohorts } = cohortStore.allCohorts;
  const { states: deleteCohortMemberStates, data: deleteCohortMember } =
    cohortStore.deleteCohort;
  const { states: deleteAcademyMemberStates } = academyStore.delete;
  const { data: current } = currentStore;
  const { data: users } = usersStore.all;
  const { data: updateUsersData, states: updateUsersStates } =
    usersStore.update;

  const { data: academies } = academyStore.all;
  const { data: updateAcademyData, states: updateAcademyStates } =
    academyStore.update;
  const { data: user } = userStore;
  return {
    cohorts,
    current,
    users,
    academies,
    deleteCohortMember,
    deleteCohortMemberStates,
    deleteAcademyMemberStates,
    user,
    updateUsersData,
    updateUsersStates,
    updateAcademyData,
    updateAcademyStates,
  };
};

export default connect(states)(Component);

/* 

<Formik
      initialValues={{}}
      onSubmit={(values, actions) => {
        
      }}
      validateOnBlur={false}
      validationSchema={schema}
      enableReinitialize
    >
      {({
        touched,
        errors,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }) => {
        return (
        <ReactSelect
          name="private"
          {...(!!cohorts && {
            items: cohorts
              .filter(
                (cohort) => cohort.active && cohort.academy === current.id
              )
              .map((cohort) => {
                return {
                  value: cohort._id,
                  label: cohort.name,
                };
              }),
          })}
          onOptionSelected={(newValue) => {
            const cohortFounded = cohorts.filter(
              (cohort) => cohort._id === newValue.value
            )[0];
            setSelectedCohort(cohortFounded);
          }}
          placeholder="Seleccione un tipo"
          options={{
            label: "Visibilidad",
            marginBottom: 20,
            skin: "gray",
          }}
        />
           
        );
      }}
    </Formik>


*/
