import {
  initialState,
  Actions,
  State,
  GET_ALL_LISTENS,
  GET_ALL_LISTENS_ERROR,
  GET_ALL_LISTENS_SUCCESS,
  GET_LISTEN,
  GET_LISTEN_ERROR,
  GET_LISTEN_SUCCESS,
  NEW_LISTEN,
  NEW_LISTEN_ERROR,
  NEW_LISTEN_SUCCESS,
  RESET_NEW_LISTEN,
  UPDATE_LISTEN,
  UPDATE_LISTEN_ERROR,
  UPDATE_LISTEN_SUCCESS,
  RESET_UPDATE_LISTEN,
  DELETE_LISTEN,
  DELETE_LISTEN_ERROR,
  DELETE_LISTEN_SUCCESS,
  RESET_DELETE_LISTEN,
  UPLOAD_MEDIA,
  UPDATE_LISTEN_LOADING,
  RESET_GET_LISTEN,
  ADD_USER_TO_LISTEN,
  SHARED_LISTEN,
  SET_GET_LISTEN,
  UPDATE_MANY_LISTEN,
  UPDATE_MANY_LISTEN_SUCCESS,
  UPDATE_MANY_LISTEN_ERROR,
  RESET_UPDATE_MANY_LISTEN,
  RESET_GET_ALL_LISTEN_DATA,
} from "../types/listen";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_LISTENS:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_LISTENS_SUCCESS:
      return {
        ...state,
        all: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_LISTENS_ERROR:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_GET_ALL_LISTEN_DATA:
      return {
        ...state,
        all: initialState.all,
      };
    case UPDATE_MANY_LISTEN:
      return {
        ...state,
        updateMany: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_MANY_LISTEN_SUCCESS:
      return {
        ...state,

        updateMany: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_MANY_LISTEN_ERROR:
      return {
        ...state,
        updateMany: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_MANY_LISTEN:
      return {
        ...state,
        updateMany: initialState.updateMany,
      };
    case UPLOAD_MEDIA:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };

    case ADD_USER_TO_LISTEN:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case SHARED_LISTEN:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_LISTEN_LOADING:
      return {
        ...state,
        new: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_LISTEN:
      return {
        ...state,
        listen: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_LISTEN_SUCCESS:
      return {
        ...state,
        listen: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_LISTEN_ERROR:
      return {
        ...state,
        listen: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case NEW_LISTEN:
      return {
        ...state,
        new: {
          ...state.new,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_LISTEN_SUCCESS:
      return {
        ...state,
        new: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case SET_GET_LISTEN:
      return {
        ...state,
        listen: {
          ...state.listen,
          data: !!action.payload ? action.payload : null,
        },
      };
    case RESET_GET_LISTEN:
      return {
        ...state,
        listen: {
          ...state.listen,
          states: {
            success: false,
            loading: false,
            error: false,
          },
        },
      };
    case NEW_LISTEN_ERROR:
      return {
        ...state,
        new: {
          ...state.new,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_LISTEN:
      return {
        ...state,
        new: {
          data: state.new.data,
          states: {
            loading: false,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_LISTEN:
      return {
        ...state,
        delete: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_LISTEN_SUCCESS:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_LISTEN_ERROR:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_LISTEN:
      return {
        ...state,
        delete: initialState.delete,
      };
    case UPDATE_LISTEN:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_LISTEN_SUCCESS:
      return {
        ...state,
        update: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_LISTEN_ERROR:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_LISTEN:
      return {
        ...state,
        update: initialState.update,
      };
    default:
      return state;
  }
};

export default reducers;
