import React, { useEffect, useState } from "react";
import { UnitDto } from "../../../../../../types/unit.dto";
import { connect, useDispatch } from "react-redux";
import { StateDto } from "../../../../../../types/states.dto";
import InfinityScroll from "../../../../../../components/InfinityScroll";
import { getAllUnits } from "../../../../../../store/actions/unit";
import { PaginationDto } from "../../../../../../types/pagination.dto";
import { CohortDto } from "../../../../../../types/cohort.dto";
import DropDown from "../DropDown";
import CenterTitle from "../../../../../../components/CenterTitle";
import {
  FlexContainer,
  Separator,
} from "../../../../../../components/StyledComponents";
import { BannerTitle } from "../../style";

interface FetchData {
  page: number;
  filterBy?: { [key: string]: string | number | boolean };
}

const Component = ({
  units,
  unitsStates,
  cohortSelected,
  setActive,
  active,
  handleItemClick,
  currentContent,
  isLoadingContents,
  setCurrentSection,
  setUnit,
  deletedUnit,
  deleteUnitStates,
  updatedUnit,
  updateUnitStates,
  newUnit,
  newUnitStates,
}: {
  units: UnitDto[];
  unitsStates: StateDto;
  cohortSelected: CohortDto;
  setActive: any;
  active?: number;
  handleItemClick: any;
  currentContent: any;
  isLoadingContents: boolean;
  setCurrentSection;
  setUnit;
  deletedUnit;
  deleteUnitStates;
  updatedUnit;
  updateUnitStates;
  newUnit;
  newUnitStates;
}) => {
  const dispatch = useDispatch();
  const [currentUnits, setCurrentUnits] = useState<UnitDto[]>([]);
  const [page, setPage] = useState<number>(0);
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const fetchData = ({ page, filterBy }: FetchData) => {
    let payload: { [key: string]: PaginationDto } = {
      units: {
        limit: 10,
        offset: 10 * page,
        filterBy: {
          ...(filterBy ? filterBy : {}),
          active: true,
          cohort: cohortSelected._id,
        },
      },
    };

    dispatch(getAllUnits(payload.units));
  };
  useEffect(() => {
    if (!!units && !isLoading) {
      setCurrentUnits((state) => [...state, ...units]);
    }
    setThereAreItems(!!units && units.length > 0);
  }, [units, isLoading]);
  useEffect(() => {
    setIsLoading(unitsStates.loading);
  }, [unitsStates.loading]);

  useEffect(() => {
    if (updateUnitStates.success) {
      setCurrentUnits((state) =>
        state.map((st) => (st._id === updatedUnit._id ? updatedUnit : st))
      );
    }
  }, [updateUnitStates]);

  useEffect(() => {
    if (deleteUnitStates.success) {
      setCurrentUnits((state) =>
        state.filter((st) => st._id !== deletedUnit._id)
      );
    }
  }, [deleteUnitStates]);

  useEffect(() => {
    if (newUnitStates.success) {
      setCurrentUnits((state) => [...state, newUnit]);
    }
  }, [newUnitStates]);

  useEffect(() => {
    if (cohortSelected) {
      setPage(0);
      setHasChanged(true);
    }
  }, [cohortSelected]);

  useEffect(() => {
    if (page === 0 && hasChanged) {
      setHasChanged(false);
      setCurrentUnits([]);
      fetchData({ page: 0, filterBy: {} });
    }
  }, [page, hasChanged]);

  return (
    <div>
      <BannerTitle size="16px">Modulos y Contenidos</BannerTitle>
      <Separator size={15}></Separator>
      <InfinityScroll
        action={fetchData}
        page={page}
        setPage={setPage}
        data={currentUnits}
        active={thereAreItems}
        isLoading={isLoading}
        {...(!thereAreItems &&
          currentUnits.length === 0 && {
            style: {
              minHeight: "150px",
              placeItems: "center",
              display: "flex",
            },
          })}
      >
        <FlexContainer
          direction="column"
          gap="15px"
          style={{ paddingBottom: "10px" }}
        >
          {currentUnits.map((unit, index) => {
            return (
              <DropDown
                setCurrentSection={setCurrentSection}
                onClick={() => {
                  setActive(index + 1 === active ? undefined : index + 1);
                  handleItemClick(unit._id);
                }}
                isLoadingContents={isLoadingContents}
                setActive={setActive}
                active={active}
                index={index + 1}
                name={unit.name}
                items={currentContent}
                setUnit={setUnit}
                unit={unit}
              ></DropDown>
            );
          })}
        </FlexContainer>
      </InfinityScroll>
    </div>
  );
};

const states = ({ unitStore, cohortStore }) => {
  const { data: units, states: unitsStates } = unitStore.allUnits;
  const { data: deletedUnit, states: deleteUnitStates } = unitStore.deleteUnit;
  const { data: updatedUnit, states: updateUnitStates } = unitStore.updateUnit;
  const { data: newUnit, states: newUnitStates } = unitStore.newUnit;
  return {
    units,
    unitsStates,
    deletedUnit,
    deleteUnitStates,
    updatedUnit,
    updateUnitStates,
    newUnit,
    newUnitStates,
  };
};

export default connect(states)(Component);
