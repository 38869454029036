import {
  GetAllCohorts,
  GET_ALL_COHORTS,
  GetCohort,
  GET_COHORT,
  UpdateCohort,
  UPDATE_COHORT,
  DeleteCohort,
  DELETE_COHORT,
  GET_COHORT_SUCCESS,
  GetCohortSuccess,
  NewCohort,
  NEW_COHORT,
  ResetNewCohort,
  RESET_NEW_COHORT,
  ResetDeleteCohort,
  RESET_DELETE_COHORT,
  ResetUpdateCohort,
  RESET_UPDATE_COHORT,
  DELETE_COHORT_MEMBER,
  DeleteCohortMember,
  RESET_GET_ALL_COHORT_DATA,
  ResetGetAllCohortData,
  GetAllCohortStudents,
  GET_ALL_COHORT_STUDENTS,
  ResetGetAllCohortStudents,
  RESET_GET_ALL_COHORT_STUDENTS,
  NEW_COHORT_PLAN,
  NewCohortPlan,
  UPDATE_COHORT_PLAN,
  UpdateCohortPlan,
} from "../types/cohort";

export const getAllCohorts = (payload): GetAllCohorts => {
  return { type: GET_ALL_COHORTS, payload };
};

export const resetGetAllCohortsData = (): ResetGetAllCohortData => {
  return { type: RESET_GET_ALL_COHORT_DATA, payload: null };
};

export const getCohort = (id): GetCohort => {
  return { type: GET_COHORT, payload: id };
};

export const setSelectedCohort = (payload): GetCohortSuccess => {
  return { type: GET_COHORT_SUCCESS, payload };
};

export const deleteMember = (payload): DeleteCohortMember => {
  return { type: DELETE_COHORT_MEMBER, payload };
};

export const getAllCohortStudents = (payload): GetAllCohortStudents => {
  return { type: GET_ALL_COHORT_STUDENTS, payload };
};

export const resetGetAllCohortStudents = (): ResetGetAllCohortStudents => {
  return { type: RESET_GET_ALL_COHORT_STUDENTS, payload: null };
};

export const updateCohort = (payload): UpdateCohort => {
  return { type: UPDATE_COHORT, payload };
};

export const resetUpdateCohort = (): ResetUpdateCohort => {
  return { type: RESET_UPDATE_COHORT, payload: null };
};

export const newCohort = (payload): NewCohort => {
  return { type: NEW_COHORT, payload };
};

export const resetNewCohort = (): ResetNewCohort => {
  return { type: RESET_NEW_COHORT, payload: null };
};

export const deleteCohort = (id): DeleteCohort => {
  return { type: DELETE_COHORT, payload: id };
};

export const resetDeleteCohort = (): ResetDeleteCohort => {
  return { type: RESET_DELETE_COHORT, payload: null };
};

export const newCohortPlan = (payload): NewCohortPlan => {
  return { type: NEW_COHORT_PLAN, payload };
};
export const updateCohortPlan = (payload): UpdateCohortPlan => {
  return { type: UPDATE_COHORT_PLAN, payload };
};
