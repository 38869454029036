import { PaginationDto } from "../../types/pagination.dto";
import { UnitDto } from "../../types/unit.dto";

export const GET_ALL_UNITS = "GET_ALL_UNITS";
export const GET_ALL_UNITS_ERROR = "GET_ALL_UNITS_ERROR";
export const GET_ALL_UNITS_SUCCESS = "GET_ALL_UNITS_SUCCESS";
export const RESET_GET_ALL_UNITS = "RESET_GET_ALL_UNITS";
export const GET_UNIT = "GET_UNIT";
export const GET_UNIT_ERROR = "GET_UNIT_ERROR";
export const GET_UNIT_SUCCESS = "GET_UNIT_SUCCESS";
export const NEW_UNIT = "NEW_UNIT";
export const NEW_UNIT_ERROR = "NEW_UNIT_ERROR";
export const NEW_UNIT_SUCCESS = "NEW_UNIT_SUCCESS";
export const RESET_NEW_UNIT = "RESET_NEW_UNIT";
export const UPDATE_UNIT = "UPDATE_UNIT";
export const UPDATE_UNIT_ERROR = "UPDATE_UNIT_ERROR";
export const UPDATE_UNIT_SUCCESS = "UPDATE_UNIT_SUCCESS";
export const RESET_UPDATE_UNIT = "RESET_UPDATE_UNIT";
export const DELETE_UNIT = "DELETE_UNIT";
export const DELETE_UNIT_ERROR = "DELETE_UNIT_ERROR";
export const DELETE_UNIT_SUCCESS = "DELETE_UNIT_SUCCESS";
export const RESET_DELETE_UNIT = "RESET_DELETE_UNIT";
export const DELETE_UNIT_MEMBER = "DELETE_UNIT_MEMBER";
export const DELETE_UNIT_MEMBER_SUCCESS = "DELETE_UNIT_MEMBER_SUCCESS";
export const DELETE_UNIT_MEMBER_ERROR = "DELETE_UNIT_MEMBER_ERROR";

export interface GetAllUnits {
  type: typeof GET_ALL_UNITS;
  payload: PaginationDto;
}

export interface GetAllUnitsSuccess {
  type: typeof GET_ALL_UNITS_SUCCESS;
  payload: UnitDto[];
}

export interface GetAllUnitsError {
  type: typeof GET_ALL_UNITS_ERROR;
  payload: boolean | string;
}

export interface ResetGetAllUnits {
  type: typeof RESET_GET_ALL_UNITS;
  payload: null;
}

export interface GetUnit {
  type: typeof GET_UNIT;
  payload: { id: string };
}

export interface GetUnitSuccess {
  type: typeof GET_UNIT_SUCCESS;
  payload: UnitDto;
}

export interface GetUnitError {
  type: typeof GET_UNIT_ERROR;
  payload: boolean | string;
}

export interface NewUnit {
  type: typeof NEW_UNIT;
  payload: UnitDto;
}

export interface NewUnitSuccess {
  type: typeof NEW_UNIT_SUCCESS;
  payload: UnitDto;
}

export interface NewUnitError {
  type: typeof NEW_UNIT_ERROR;
  payload: boolean | string;
}

export interface ResetNewUnit {
  type: typeof RESET_NEW_UNIT;
  payload: null;
}

export interface UpdateUnit {
  type: typeof UPDATE_UNIT;
  payload: UnitDto;
}

export interface UpdateUnitSuccess {
  type: typeof UPDATE_UNIT_SUCCESS;
  payload: UnitDto;
}

export interface UpdateUnitError {
  type: typeof UPDATE_UNIT_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateUnit {
  type: typeof RESET_UPDATE_UNIT;
  payload: null;
}

export interface DeleteUnit {
  type: typeof DELETE_UNIT;
  payload: { id: string };
}

export interface DeleteUnitSuccess {
  type: typeof DELETE_UNIT_SUCCESS;
  payload: null;
}

export interface DeleteUnitError {
  type: typeof DELETE_UNIT_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteUnit {
  type: typeof RESET_DELETE_UNIT;
  payload: null;
}

export interface DeleteUnitMember {
  type: typeof DELETE_UNIT_MEMBER;
  payload: any;
}
export interface DeleteUnitMemberSuccess {
  type: typeof DELETE_UNIT_MEMBER_SUCCESS;
  payload: any;
}
export interface DeleteUnitMemberError {
  type: typeof DELETE_UNIT_MEMBER_ERROR;
  payload: string | boolean;
}

export interface State {
  allUnits: {
    data: UnitDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  cohort: {
    data: UnitDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  newUnit: {
    data: UnitDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  deleteUnit: {
    data: UnitDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  updateUnit: {
    data: UnitDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  allUnits: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  cohort: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  newUnit: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  deleteUnit: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  updateUnit: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllUnits
  | GetAllUnitsSuccess
  | GetAllUnitsError
  | GetUnit
  | GetUnitSuccess
  | GetUnitError
  | NewUnit
  | NewUnitSuccess
  | NewUnitError
  | ResetNewUnit
  | UpdateUnit
  | UpdateUnitSuccess
  | UpdateUnitError
  | ResetUpdateUnit
  | DeleteUnit
  | DeleteUnitSuccess
  | DeleteUnitError
  | ResetDeleteUnit
  | DeleteUnitMember
  | DeleteUnitMemberSuccess
  | DeleteUnitMemberError
  | ResetGetAllUnits;
