import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import CrossIcon from "../../../../../../assets/icons/cross.svg";
import Icon from "../../../../../../components/Icon";
import { InLine } from "../../../../../../components/StyledComponents";
import { useRecruitingLayout } from "../../../../../../provider/RecruitingProvider";
import {
  TechnologyDto,
  TechSkillDto,
} from "../../../../../../types/recruiting.dto";
import ShowModal from "../../../components/ShoModal";
import {
  Tab,
  TabTitle,
  Description,
  Content,
  ListTitle,
  List,
  ListItem,
  Delete,
} from "../../../components/StyledComponents";

interface ComponentsProps {
  allTechnologies: TechnologyDto[];
  allSkills: TechSkillDto[];
}

const Component = ({ allTechnologies, allSkills }: ComponentsProps) => {
  const { actions, selectedItem } = useRecruitingLayout();
  const dispatch = useDispatch();
  const params = useParams();
  return (
    <>
      <InLine justify="space-between">
        <TabTitle>Asignar Tecnologias a Búsqueda</TabTitle>
        <ShowModal.AsignarTecnologia />
      </InLine>

      <Description>
        Asignar tecnologías a tu búsqueda te ayudara a ti y a Dev Place a
        facilitar la conexión entre talentos, tus búsquedas y las necesidades de
        esta empresa en particular. También te enviaremos recomendaciones de
        perfiles que no has seleccionado pero que por la información recopilada
        recomendaremos que les eches un vistazo.
      </Description>

      <Content>
        <ListTitle>Tecnología</ListTitle>

        <List>
          {allTechnologies
            ?.filter((technology) => technology.active)
            .filter((ability) => ability.team === params.id)
            .filter((technology) =>
              selectedItem?.data?.abilities?.includes(technology._id)
            )
            .map((item) => (
              <ListItem>
                {item.name}
                <Delete
                  onClick={() =>
                    dispatch(
                      actions.update({
                        ...selectedItem?.data,
                        abilities: selectedItem?.data?.abilities?.filter(
                          (ability) => ability !== item._id
                        ),
                      })
                    )
                  }
                >
                  <Icon icon={CrossIcon} size="9px" color="black" />
                </Delete>
              </ListItem>
            ))}
        </List>
      </Content>

      <Content>
        <ListTitle>Skills Técnicos</ListTitle>

        <List>
          {!!allSkills &&
            allSkills
              ?.filter((skill) => skill.active)
              .filter((ability) => ability.team === params.id)
              .filter((skill) =>
                selectedItem?.data?.criterions?.includes(skill._id)
              )
              .map((item) => (
                <ListItem>
                  {item.name}{" "}
                  <Delete
                    onClick={() =>
                      dispatch(
                        actions.update({
                          ...selectedItem?.data,
                          criterions: selectedItem?.data?.criterions?.filter(
                            (criterion) => criterion !== item._id
                          ),
                        })
                      )
                    }
                  >
                    <Icon icon={CrossIcon} size="9px" color="black" />
                  </Delete>
                </ListItem>
              ))}
        </List>
      </Content>
    </>
  );
};

const states = ({ recruitingStore }) => {
  const { data: allTechnologies } = recruitingStore.technology.all;
  const { data: allSkills } = recruitingStore.techSkill.all;
  return {
    allTechnologies,
    allSkills,
  };
};

export default connect(states)(Component);
