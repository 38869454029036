import { connect } from "react-redux";
import { InLine } from "../../../../../../components/StyledComponents";
import CrossIcon from "../../../../../../assets/icons/cross.svg";
import { useRecruitingLayout } from "../../../../../../provider/RecruitingProvider";
import Icon from "../../../../../../components/Icon";
import {
  TabTitle,
  Description,
  Content,
  ListTitle,
  List,
  ListItem,
  Delete,
} from "../../../components/StyledComponents";
import { useDispatch } from "react-redux";
import ShowModal from "../../../components/ShoModal";
import {
  SearchDto,
  TechnologyDto,
  TechSkillDto,
} from "../../../../../../types/recruiting.dto";
interface ComponentProps {
  techs: TechnologyDto[];
  skills: TechSkillDto[];
}

const Component = ({ techs, skills }: ComponentProps) => {
  const { selectedItem, actions } = useRecruitingLayout();
  const dispatch = useDispatch();
  return (
    <>
      <InLine justify="space-between">
        <TabTitle>Asignar Tecnologías a la Empresa</TabTitle>
        <ShowModal.AsignarTecnologia />
      </InLine>
      <Description>
        Asignar tecnologías a la empresa te ayudara a ti y a Dev Place a
        facilitar la conexión entre talentos, tus búsquedas y las necesidades de
        esta empresa en particular. También te enviaremos recomendaciones de
        perfiles que no has seleccionado pero que por la información recopilada
        recomendaremos que les eches un vistazo.
      </Description>

      <Content>
        <ListTitle>Tecnología</ListTitle>
        <List>
          {techs
            ?.filter((ability) => ability?.active)
            ?.filter((ability) =>
              selectedItem?.data?.abilities?.includes(ability._id)
            )
            .map((item) => (
              <ListItem>
                {item.name}
                <Delete
                  onClick={() =>
                    dispatch(
                      actions.update({
                        ...selectedItem?.data,
                        abilities: selectedItem?.data?.abilities?.filter(
                          (talent) => talent !== item._id
                        ),
                      })
                    )
                  }
                >
                  <Icon icon={CrossIcon} size="9px" color="black" />
                </Delete>
              </ListItem>
            ))}
        </List>
        <ListTitle>Skills Técnicos</ListTitle>
        <List>
          {skills
            ?.filter((criterion) => criterion.active)
            .filter((criterion) =>
              selectedItem?.data?.criterions?.includes(criterion._id)
            )
            .map((item) => (
              <ListItem>
                {item.name}
                <Delete
                  onClick={() =>
                    dispatch(
                      actions.update({
                        ...selectedItem?.data,
                        criterions: selectedItem?.data?.criterions?.filter(
                          (criterion) => criterion !== item._id
                        ),
                      })
                    )
                  }
                >
                  <Icon icon={CrossIcon} size="9px" color="black" />
                </Delete>
              </ListItem>
            ))}
        </List>
      </Content>
    </>
  );
};

const states = ({ recruitingStore }) => {
  const { data: techs, states: techStates } = recruitingStore.technology.all;
  const { data: skills } = recruitingStore.techSkill.all;
  return { techs, techStates, skills };
};

export default connect(states)(Component);
