import styled from "styled-components";

const resetFieldStyles = `
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  outline: none;
  border: 0px;
  margin: 0px;
  padding: 0px;
  background-color: transparent;
  width: 100%;`;

/* agregar padding links del menu, boton de salir esta muy al fondo, boton ver niveles con shadow */
export const Input = styled("input")<{ skin; size }>`
  ${resetFieldStyles}
  line-height: ${(props) => props.theme.form.size[props.size].lineHeight};
  font-size: ${(props) => props.theme.form.size[props.size].fontSize};
  min-height: calc(
    ${(props) => props.theme.form.size[props.size].height} - 2px
  );
  color: ${(props) => props.theme.colors.Black};
  padding: ${(props) =>
    !!props.padding ? props.padding : "0px 20px 3px 20px"};
  caret-color: #035397;
  border-radius: 10px;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    background-color: transparent !important;
    background-image: none !important;
    color: ${(props) => props.theme.colors.Black} !important;
    font-family: "Lato", sans-serif;
    font-size: ${(props) => props.theme.form.size[props.size].mobileFontSize};
    line-height: ${(props) =>
      props.theme.form.size[props.size].mobileLineHeight};

    @media (min-width: 768px) {
      font-size: ${(props) => props.theme.form.size[props.size].fontSize};
      line-height: ${(props) => props.theme.form.size[props.size].lineHeight};
    }
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
`;
