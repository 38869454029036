import styled from "styled-components";

export const CardItems = styled("div")`
  position: relative;
  border-radius: 10px;
  box-shadow: 6px 6px 10px 0 rgba(112, 112, 112, 0.16);
  background-color: white;
  width: 100%;
  height: 295px;
`;

export const List = styled("ul")`
  height: 100%;
  padding: 0px;
  margin: 10px 0px 0px;
`;

export const ListItem = styled("li")`
  list-style: none;
  padding: 0px;
  margin: 0px;
  display: block;
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const Card = styled(CardItems)`
  padding: 10px 10px;
  height: 100%;
`;

export const Button = styled("button")<{ active: boolean }>`
  border: 0px;
  margin: 0px;
  display: block;
  width: 100%;
  border-radius: 5px;
  box-shadow: 1px 1px 3px 0 rgba(112, 112, 112, 0.16);
  transition: all 0.3s ease-in-out;
  background-color: ${({ active }) => (active ? "#daf1ff" : "white")};
  cursor: pointer;
  height: 40px;
  text-align: left;
`;

export const DataText = styled("p")`
  margin: 0;
  font-size: ${(props) => props.size};
  color: ${(props) => (!!props.color ? props.color : "#697482")};
`;

export const Item = styled("div")`
  padding: 12px 10px;
  border-radius: 5px;
  box-shadow: 0 0 3px 0 rgba(112, 112, 112, 0.16);
  width: 100%;
`;
