import { Line } from "react-chartjs-2";
import type { ChartData, ChartOptions } from "chart.js";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface LineProps {
  options: ChartOptions<"line">;
  data: ChartData<"line">;
}

const Component = ({
  title,
  scales,
  datasets,
  labels,
}: {
  title?: string;
  scales: string[];
  datasets: { label: string; data: number[]; color: string }[];
  labels: string[];
}) => {
  const Props: any = {
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          display: false, // Hide point labels
        },
        title: {
          display: true,
          text: title,
        },
        legend: {
          position: "top",
        },
      },
      scales: {
        // to remove the labels
        x: {
          type: "linear",
          ticks: {
            display: false,
          },

          // to remove the x-axis grid
        },
        // to remove the y-axis labels
        y: {
          beginAtZero: true,
          ticks: {
            stepSize: 1, // Calculate the tick increment
            // Start at zero
            callback: function (value) {
              // Format the label without commas
              return value.toLocaleString("en-US", {
                maximumFractionDigits: 0,
              });
            },
          },
        },
      },
    },
    data: {
      datasets: [
        /*  {
          label: `${title ? title : "Crecimiento Global"}`,
          data: analytics.map((analytic) => analytic.note),
          borderColor: `${color ? color : "red"}`,
          backgroundColor: `${color ? color : "red"}`,
        }, */
        ...datasets.map((dataset) => {
          return {
            label: dataset.label,
            data: dataset.data,
            borderColor: dataset.color,
            backgroundColor: dataset.color,
          };
        }),
      ],
      labels: labels,
    },
  };
  return <Line data={Props.data} options={Props.options} />;
};

export default Component;
