/**
 *  Colors
 */

/* const Blue = "#1573e5"; */
const Blue = "rgb(0, 30, 108)";
const LightBlue = "#daf1ff";

const LightGray = "#f5f5f5";
const Gray = "#697482";
const DarkGray = "#707070";

const Danger = "#f76564";
const Warning = "#f3dd53";
const Success = "#26ab6d";
const Info = "#52ddfc";

const Primary = Blue;

const Black = "#2D3436";
const Black24 = "#C2C2C2";

const White = "#FFFFFF";

const Transparent = "rgba(255,255,255,0)";

const Violet = "#7b68ef";

export const colors = {
  Black,
  Black24,
  White,
  Transparent,
  Blue,
  LightBlue,
  LightGray,
  Violet,
  Gray,
  DarkGray,
  Danger,
  Warning,
  Success,
  Info,
  Primary,
};
