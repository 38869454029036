import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import Submit from "../../components/Form/Submit";
import ReactSelect from "../../components/Form/ReactSelect";
import {
  initialValues,
  schema,
} from "../../constants/form/others/statecontentmodal";
import Button from "../../components/Button";
import { hideModal } from "../../store/actions/modal";
import { Center } from "../../components/StyledComponents";
import Modal from "../Modal";

interface ComponentProps {
  items: any;
  loading: boolean;
  section: string;
  action: any;
}

const Component = ({ items, loading, section, action }: ComponentProps) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [fieldChanging, setFieldChanging] = useState<{
    field: string | string[];
    value: any | any[];
  }>();
  const dispatch = useDispatch();
  const fieldParsing = (value) => {
    if (typeof value.field === "string") return { [value.field]: value.value };
    const matchingArrayValues = value.field.map((f, index) => {
      return { label: f, value: value.value[index] };
    });

    return matchingArrayValues.reduce((obj, item) => {
      obj[item.label] = item.value;
      return obj;
    }, {});
  };
  const onSubmit = ({ values, actions }) => {
    const itemsMapped = items.map((item) => {
      return { _id: item._id, __v: item.__v, ...fieldParsing(fieldChanging) };
    });
    dispatch(action({ items: itemsMapped }));
  };

  const closeModal = () => {
    dispatch(hideModal());
  };

  return (
    <Modal
      height="300px"
      name="change-content-states"
      title={`Cambiar el estado de todos los ${section} `}
    >
      <Formik
        initialValues={{
          ...initialValues,
        }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="edit-event-form"
            >
              <ReactSelect
                name="category"
                error={errors["category"]}
                touched={touched["category"]}
                items={[
                  { label: "Publico", value: "public" },
                  { label: "Privado", value: "private" },
                  { label: "Publicos y Generales", value: "publicandgeneral" },
                ]}
                placeholder="Selecciona una estado"
                onChange={handleChange}
                onBlur={handleBlur}
                onOptionSelected={(newValue) => {
                  switch (newValue.value) {
                    case "public":
                      setFieldChanging({ field: "published", value: true });
                      break;
                    case "private":
                      setFieldChanging({ field: "published", value: false });
                      break;
                    case "publicandgeneral":
                      setFieldChanging({
                        field: ["published", "available"],
                        value: [true, true],
                      });
                      break;

                    default:
                      setFieldValue("category", "");
                      break;
                  }
                }}
                options={{
                  label: "Estado de Contenidos",
                  marginBottom: 40,
                }}
              />
              <Center>
                <Submit
                  isSubmmiting={loading}
                  type="submit"
                  form="edit-event-form"
                  color="Primary"
                  options={{
                    type: "filled",
                    skin: "primary",
                    size: "lg",
                    marginBottom: "0px",
                  }}
                >
                  Confirmar
                </Submit>
                <Button
                  onClick={closeModal}
                  type="button"
                  options={{
                    type: "outline",
                    skin: "danger",
                    size: "lg",
                    marginBottom: "0px",
                  }}
                  style={{ marginLeft: "10px" }}
                >
                  Cancelar
                </Button>
              </Center>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default Component;
