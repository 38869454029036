import { createRef, useState } from "react";
import { ArrayHelpers, FieldArray } from "formik";
import Input from "../Input";
import ReactSelect from "../ReactSelect";
import { ErrorMessage, FieldContainer, HelperLine } from "../Field";
import {
  AddButton,
  RemoveButton,
  EditButton,
  ScrollSection,
} from "../../StyledComponents";
import { List, Item, Label } from "./styles";
import { uniqueArrayValues } from "../../../helpers/unique-array-values";
import Createable from "../Createable";

interface ComponentProps {
  name: string;
  value?: any[];
  onFocus?: (data: any) => void;
  onBlur?: (data: any) => void;
  onChange?: (data: any) => void;
  initialValues?: string[] | Option[];
  error?: {};
  touched?: {};
  forwardRef?;
  options: {
    height?: string;
    multi?: boolean;
    skin?: "base" | "gray";
    type: "input" | "select";
    data?: { value: string | number; label: string }[];
    loading?: boolean;
    inputPlaceholder?: string;
    onClick?: (item: any, index: number, arrayHelpers: ArrayHelpers) => void;
    onOptionSelected?: (item: any, index: number, fieldValue: string[]) => void;
    inputLabel: string;
    listLabel?: string;
    specialValid?: () => boolean;
    externalData?: boolean;
    marginBottom?: number;
    onRemoveItem?: (item: any, index: number, fieldValue: string[]) => void;
  };
}

interface Option {
  label?: string;
  value?: string;
}

const Component = ({
  value,
  name,
  initialValues,
  error,
  options,
  ...rest
}: ComponentProps) => {
  const { loading = false } = options;
  const [selectedItems, setSelectedItems] = useState<string[] | Option[]>(
    initialValues || []
  );
  const inputRef = createRef<HTMLInputElement>();
  const externalValues = (array1, array2) => {
    const arrayValues = uniqueArrayValues([
      ...array1.map((el) => (!!el.value ? el.value : el)),
      ...array2.map((el) => (!!el.value ? el.value : el)),
    ]);
    const valuesOptions = arrayValues.map((el) => {
      const foundedValue = [...array1, ...array2].find(
        (itemF) => !!itemF._isNew || (!!itemF.value ? itemF.value === el : el)
      );
      return {
        label: !!foundedValue ? foundedValue?.label : el,
        value: !!foundedValue ? foundedValue?.value : el,
      };
    });
    return valuesOptions;
  };
  return (
    <>
      <FieldArray
        {...rest}
        name={name}
        render={(arrayHelpers) => (
          <>
            <Createable
              items={
                !!options.data
                  ? options.externalData
                    ? options.data?.filter(
                        (dataItem) =>
                          !arrayHelpers.form.values[name]?.find((val) =>
                            !!val.value
                              ? val.value === dataItem.value
                              : val === dataItem.value
                          )
                      )
                    : options.data?.filter(
                        (dataItem) => !value || !value.includes(dataItem.value)
                      )
                  : []
              }
              onOptionSelected={(data: { label: string; value: string }) => {
                if (
                  !value ||
                  (!!value &&
                    !value.find((v) =>
                      !!v.value ? v.value === data.value : v === data.value
                    ))
                ) {
                  arrayHelpers.push(options.externalData ? data : data.value);
                  !!options.onOptionSelected &&
                    options.onOptionSelected(
                      data.value,
                      selectedItems.length - 1,
                      arrayHelpers.form.values[name]
                    );
                  setSelectedItems([...(selectedItems as Option[]), data]);
                }
              }}
              {...(!!options.inputPlaceholder && {
                placeholder: options.inputPlaceholder,
              })}
              options={{
                ...options,
                loading: loading,
                label: options.inputLabel,
                marginBottom: 30,
              }}
            />

            <FieldContainer>
              {!!options.listLabel && <Label>{options.listLabel}</Label>}
              <ScrollSection
                style={{
                  ...(options.skin === "gray" && {
                    backgroundColor: "#f5f5f5",
                    boxShadow: "none",
                  }),
                  minHeight: "95px",
                  height: options?.height || "400px",
                  padding: "10px",
                }}
              >
                <List className={!!options.multi ? "multiLine" : "list"}>
                  {!!arrayHelpers.form.values[name] &&
                    arrayHelpers.form.values[name]?.map((item, index) => (
                      <Item
                        className={!!options.multi ? "multiLine" : "list"}
                        key={`${item}-${index}`}
                      >
                        <span>
                          {options.type === "select" &&
                          !!options.externalData &&
                          !!options.data
                            ? externalValues(
                                [...options.data, ...selectedItems],
                                arrayHelpers.form.values[name]
                              ).filter((selectedItem) =>
                                !!item.value
                                  ? selectedItem.value === item.value
                                  : selectedItem.value === item
                              )[0]?.label
                            : options.data?.filter(
                                (selectedItem) => selectedItem.value === item
                              )[0]?.label}

                          {options.type === "input" && item}
                        </span>

                        <span>
                          {!!options.onClick && (
                            <EditButton
                              type="button"
                              onClick={() =>
                                !!options.onClick &&
                                options.onClick(item, index, arrayHelpers)
                              }
                            />
                          )}

                          <RemoveButton
                            type="button"
                            onClick={() => {
                              arrayHelpers.remove(index);
                              if (options.type === "select") {
                                setSelectedItems(
                                  (selectedItems as Option[]).filter(
                                    (_selectedItem, selectedItemIndex) => {
                                      return _selectedItem.value !== item.value;
                                    }
                                  )
                                );
                                options.onRemoveItem &&
                                  options.onRemoveItem(
                                    item,
                                    index,
                                    arrayHelpers.form.values[name]
                                  );
                              } else {
                                setSelectedItems(
                                  (selectedItems as string[]).filter(
                                    (_selectedItem, selectedItemIndex) =>
                                      index !== selectedItemIndex
                                  )
                                );
                              }
                            }}
                          />
                        </span>
                      </Item>
                    ))}
                </List>
              </ScrollSection>
              {error && (
                <HelperLine marginBottom={10}>
                  <ErrorMessage>{error}</ErrorMessage>
                </HelperLine>
              )}
            </FieldContainer>
          </>
        )}
      />
    </>
  );
};

export default Component;
