import {
  NewTechnology,
  NEW_TECHNOLOGY,
  ResetNewTechnology,
  RESET_NEW_TECHNOLOGY,
  GET_ALL_TECHNOLOGIES,
  GetAllTechnologies,
  UpdateTechnology,
  UPDATE_TECHNOLOGY,
  ResetUpdateTechnology,
  RESET_UPDATE_TECHNOLOGY,
  GetTechnology,
  DeleteTechnology,
  DELETE_TECHNOLOGY,
  GET_TECHNOLOGY,
  RESET_DELETE_TECHNOLOGY,
} from "../../types/recruiting/technology";

const create = (payload): NewTechnology => {
  return { type: NEW_TECHNOLOGY, payload };
};
const resetCreate = (): ResetNewTechnology => {
  return { type: RESET_NEW_TECHNOLOGY, payload: null };
};

const update = (payload): UpdateTechnology => {
  return { type: UPDATE_TECHNOLOGY, payload };
};

const resetUpdate = (): ResetUpdateTechnology => {
  return { type: RESET_UPDATE_TECHNOLOGY, payload: null };
};

const getAll = (): GetAllTechnologies => {
  return { type: GET_ALL_TECHNOLOGIES, payload: null };
};

const getOne = (payload: { _id: string }): GetTechnology => {
  return { type: GET_TECHNOLOGY, payload };
};

const deleteOne = (payload: { _id: string }): DeleteTechnology => {
  return { type: DELETE_TECHNOLOGY, payload };
};

const resetDeleteOne = () => {
  return { type: RESET_DELETE_TECHNOLOGY, payload: null };
};

const actions = {
  create,
  resetDeleteOne,
  resetCreate,
  update,
  resetUpdate,
  getAll,
  getOne,
  deleteOne,
};

export default actions;
