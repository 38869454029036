import * as Yup from "yup";

export const schema = Yup.object().shape({
  avatar: Yup.string(),
  country: Yup.string().required(),
  province: Yup.string().required(),
  english: Yup.string().required(),
  email: Yup.string(),
  github: Yup.string(),
  portfolio: Yup.string(),
  linkedin: Yup.string(),
  curriculum: Yup.string(),
  comments: Yup.string(),
});

interface FormValues {
  avatar?: string;
  country: string;
  province: string;
  english: string;
  email?: string;
  github?: string;
  portfolio?: string;
  linkedin?: string;
  curriculum?: string;
  comments?: string;
}

export const initialValues: FormValues = {
  avatar: "",
  country: "argentina",
  province: "buenos_aires",
  english: "",
  email: "",
  github: "",
  portfolio: "",
  linkedin: "",
  curriculum: "",
  comments: "",
};
