import { CategoryDto } from "../../types/category.dto";

export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const GET_ALL_CATEGORIES_ERROR = "GET_ALL_CATEGORIES_ERROR";
export const GET_ALL_CATEGORIES_SUCCESS = "GET_ALL_CATEGORIES_SUCCESS";
export const GET_CATEGORY = "GET_CATEGORY";
export const GET_CATEGORY_ERROR = "GET_CATEGORY_ERROR";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const NEW_CATEGORY = "NEW_CATEGORY";
export const NEW_CATEGORY_ERROR = "NEW_CATEGORY_ERROR";
export const NEW_CATEGORY_SUCCESS = "NEW_CATEGORY_SUCCESS";
export const RESET_NEW_CATEGORY = "RESET_NEW_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const UPDATE_CATEGORY_ERROR = "UPDATE_CATEGORY_ERROR";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const RESET_UPDATE_CATEGORY = "RESET_UPDATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const DELETE_CATEGORY_ERROR = "DELETE_CATEGORY_ERROR";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const RESET_DELETE_CATEGORY = "RESET_DELETE_CATEGORY";

export interface GetAllCategories {
  type: typeof GET_ALL_CATEGORIES;
  payload: null;
}

export interface GetAllCategoriesSuccess {
  type: typeof GET_ALL_CATEGORIES_SUCCESS;
  payload: CategoryDto[];
}

export interface GetAllCategoriesError {
  type: typeof GET_ALL_CATEGORIES_ERROR;
  payload: boolean | string;
}

export interface GetCategory {
  type: typeof GET_CATEGORY;
  payload: { id: string };
}

export interface GetCategorySuccess {
  type: typeof GET_CATEGORY_SUCCESS;
  payload: CategoryDto;
}

export interface GetCategoryError {
  type: typeof GET_CATEGORY_ERROR;
  payload: boolean | string;
}

export interface NewCategory {
  type: typeof NEW_CATEGORY;
  payload: CategoryDto;
}

export interface NewCategorySuccess {
  type: typeof NEW_CATEGORY_SUCCESS;
  payload: CategoryDto;
}

export interface NewCategoryError {
  type: typeof NEW_CATEGORY_ERROR;
  payload: boolean | string;
}

export interface ResetNewCategory {
  type: typeof RESET_NEW_CATEGORY;
  payload: null;
}

export interface UpdateCategory {
  type: typeof UPDATE_CATEGORY;
  payload: CategoryDto;
}

export interface UpdateCategorySuccess {
  type: typeof UPDATE_CATEGORY_SUCCESS;
  payload: CategoryDto;
}

export interface UpdateCategoryError {
  type: typeof UPDATE_CATEGORY_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateCategory {
  type: typeof RESET_UPDATE_CATEGORY;
  payload: null;
}

export interface DeleteCategory {
  type: typeof DELETE_CATEGORY;
  payload: { id: string };
}

export interface DeleteCategorySuccess {
  type: typeof DELETE_CATEGORY_SUCCESS;
  payload: null;
}

export interface DeleteCategoryError {
  type: typeof DELETE_CATEGORY_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteCategory {
  type: typeof RESET_DELETE_CATEGORY;
  payload: null;
}

export interface State {
  allCategories: {
    data: CategoryDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  category: {
    data: CategoryDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  newCategory: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  deleteCategory: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  updateCategory: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  allCategories: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  category: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  newCategory: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  deleteCategory: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  updateCategory: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllCategories
  | GetAllCategoriesSuccess
  | GetAllCategoriesError
  | GetCategory
  | GetCategorySuccess
  | GetCategoryError
  | NewCategory
  | NewCategorySuccess
  | NewCategoryError
  | ResetNewCategory
  | UpdateCategory
  | UpdateCategorySuccess
  | UpdateCategoryError
  | ResetUpdateCategory
  | DeleteCategory
  | DeleteCategorySuccess
  | DeleteCategoryError
  | ResetDeleteCategory;
