import { PaginationDto } from "../../types/pagination.dto";
import { TagDto } from "../../types/tag.dto";

export const GET_ALL_TAGS = "GET_ALL_TAGS";
export const GET_ALL_TAGS_ERROR = "GET_ALL_TAGS_ERROR";
export const GET_ALL_TAGS_SUCCESS = "GET_ALL_TAGS_SUCCESS";
export const RESET_GET_ALL_TAGS = "RESET_GET_ALL_TAGS";
export const NEW_MANY_TAGS = "NEW_MANY_TAGS";
export const NEW_MANY_TAGS_SUCCESS = "NEW_MANY_TAGS_SUCCESS";
export const NEW_MANY_TAGS_ERROR = "NEW_MANY_TAGS_ERROR";
export const RESET_NEW_MANY_TAGS = "RESET_NEW_MANY_TAGS";
export const UPDATE_MANY_TAGS = "UPDATE_MANY_TAGS";
export const UPDATE_MANY_TAGS_SUCCESS = "UPDATE_MANY_TAGS_SUCCESS";
export const UPDATE_MANY_TAGS_ERROR = "UPDATE_MANY_TAGS_ERROR";
export const RESET_UPDATE_MANY_TAGS = "RESET_UPDATE_MANY_TAGS";

export interface GetAllTags {
  type: typeof GET_ALL_TAGS;
  payload: PaginationDto;
}

export interface GetAllTagsSuccess {
  type: typeof GET_ALL_TAGS_SUCCESS;
  payload: TagDto[];
}

export interface GetAllTagsError {
  type: typeof GET_ALL_TAGS_ERROR;
  payload: boolean | string;
}

export interface ResetAllTags {
  type: typeof RESET_GET_ALL_TAGS;
  payload: null;
}

export interface UpdateManyTags {
  type: typeof UPDATE_MANY_TAGS;
  payload: any;
}

export interface UpdateManyTagsSuccess {
  type: typeof UPDATE_MANY_TAGS_SUCCESS;
  payload: TagDto[];
}

export interface UpdateManyTagsError {
  type: typeof UPDATE_MANY_TAGS_ERROR;
  payload: string | boolean;
}

export interface ResetUpdateManyTags {
  type: typeof RESET_UPDATE_MANY_TAGS;
  payload: null;
}

export interface NewManyTags {
  type: typeof NEW_MANY_TAGS;
  payload: { items: any[] };
}
export interface NewManyTagsSuccess {
  type: typeof NEW_MANY_TAGS_SUCCESS;
  payload: TagDto[];
}
export interface NewManyTagsError {
  type: typeof NEW_MANY_TAGS_ERROR;
  payload: string | boolean;
}
export interface ResetNewManyTags {
  type: typeof RESET_NEW_MANY_TAGS;
  payload: null;
}

export interface State {
  all: {
    data: TagDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  newMany: {
    data: TagDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  updateMany: {
    data: TagDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  all: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  newMany: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  updateMany: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllTags
  | GetAllTagsSuccess
  | GetAllTagsError
  | ResetAllTags
  | NewManyTags
  | NewManyTagsSuccess
  | NewManyTagsError
  | ResetNewManyTags
  | UpdateManyTags
  | UpdateManyTagsSuccess
  | UpdateManyTagsError
  | ResetUpdateManyTags;
