import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Modal from "../../../../../../components/Modal";
import { Formik } from "formik";
import { Center } from "../../../../../../components/StyledComponents";
import {
  initialValues,
  schema,
} from "../../../../../../constants/form/user/profile/edit";
import Grid from "../../../../../../components/Grid";
import Button from "../../../../../../components/Button";
import Submit from "../../../../../../components/Form/Submit";
import { toast } from "react-toastify";
import Input from "../../../../../../components/Form/Input";
import usersActions from "../../../../../../store/actions/users";
import { hideModal } from "../../../../../../store/actions/modal";
import ReactSelect from "../../../../../../components/Form/ReactSelect";
import FileUploader from "../../../../../../components/Form/FileUploader";
import Icon from "../../../../../../components/Icon";
import UploadIcon from "../../../../../../assets/icons/cloud-upload.svg";
import { setFormData } from "../../../../../../helpers/formData";
import { updateUser } from "../../../../../../store/actions/user";
import Loader from "../../../../../../components/Loader";
import { removeUndefinedFromObject } from "../../../../../../helpers/removeUndefinedObject";

const Component = ({ updateStates, current, users, user }) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const [avatarPreview, setAvatarPreview] = useState<any>();
  const userFounded = user;
  const dispatch = useDispatch();
  const onSubmit = ({ values, actions }) => {
    const formValues = {
      ...removeUndefinedFromObject(values),
      ...(!!avatarPreview &&
        !!avatarPreview?.files && {
          picture: avatarPreview?.files[0],
        }),
    };

    dispatch(
      updateUser({
        _id: user._id,
        body: setFormData({ ...formValues }, ["picture"]),
      })
    );
    setFormActions(actions);
  };
  if (!userFounded) return <Loader color="Primary"></Loader>;
  return (
    <Modal name="edit-user-profile" title="Datos Personales">
      <Formik
        initialValues={{
          ...initialValues({
            name: userFounded.name,
            lastName: userFounded.lastName,
            birthDate: userFounded.birthDate,
            civilStatus: userFounded.civilStatus,
            dni: userFounded.dni,
            gender: userFounded.gender,
            nationality: userFounded.nationality,
          }),
        }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="edit-profile-profile-form"
            >
              <Grid.Row>
                <Grid.Col>
                  <Input
                    name={"name"}
                    error={errors["name"]}
                    touched={touched["name"]}
                    value={values["name"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={"Ingrese nombre"}
                    options={{
                      label: "Nombre",
                      skin: "gray",
                    }}
                  />
                  <Input
                    name={"lastName"}
                    error={errors["lastName"]}
                    touched={touched["lastName"]}
                    value={values["lastName"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={"Ingrese Apellido"}
                    options={{
                      label: "Apellido",
                      skin: "gray",
                    }}
                  />
                  <FileUploader
                    name="picture"
                    error={errors["picture"]}
                    touched={touched["picture"]}
                    placeholder={
                      <Icon icon={UploadIcon} size="24px" color="black" />
                    }
                    onChange={(event) => {
                      setAvatarPreview(event.target);
                      handleChange(event);
                    }}
                    onBlur={handleBlur}
                    accept="image/jpg;image/png;image/jpeg;capture=camera"
                    options={{
                      label: "Foto de Perfil",
                      marginBottom: 24,
                    }}
                  />
                  <Input
                    name={"nationality"}
                    error={errors["nationality"]}
                    touched={touched["nationality"]}
                    value={values["nationality"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "Nacionalidad",
                      skin: "gray",
                    }}
                  />
                  <Input
                    name="birthDate"
                    error={errors["birthDate"]}
                    touched={touched["birthDate"]}
                    value={values["birthDate"]}
                    type="date"
                    placeholder="Fecha"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "Fecha de Nacimiento",
                      skin: "gray",
                      marginBottom: 20,
                    }}
                  />

                  <Input
                    name={"dni"}
                    error={errors["dni"]}
                    touched={touched["dni"]}
                    value={values["dni"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={"Ej: 42392152"}
                    options={{
                      label: "DNI",
                      skin: "gray",
                    }}
                  />

                  <ReactSelect
                    name="gender"
                    error={errors["gender"]}
                    touched={touched["gender"]}
                    items={[
                      {
                        label: "Masculino",
                        value: "male",
                      },
                      {
                        label: "Femenino",
                        value: "female",
                      },
                      {
                        label: "Otro",
                        value: "other",
                      },
                      {
                        label: "No tengo",
                        value: "none",
                      },
                    ]}
                    placeholder="Seleccione un tipo"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "Genero",
                      skin: "gray",
                      marginBottom: 20,
                    }}
                  />
                  <ReactSelect
                    name="civilStatus"
                    error={errors["civilStatus"]}
                    touched={touched["civilStatus"]}
                    items={[
                      {
                        label: "Casado",
                        value: "married",
                      },
                      {
                        label: "Divorciado",
                        value: "divorced",
                      },
                      {
                        label: "Soltero",
                        value: "single",
                      },
                      {
                        label: "En Pareja",
                        value: "couple",
                      },
                    ]}
                    placeholder="Seleccione un tipo"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "Estado Civil",
                      skin: "gray",
                      marginBottom: 40,
                    }}
                  />
                </Grid.Col>
              </Grid.Row>

              <Grid.Row>
                <Grid.Col>
                  <Center gap={"70px"}>
                    <Button
                      onClick={resetForm}
                      type="button"
                      options={{
                        type: "outline",
                        skin: "gray",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                      style={{ marginLeft: "10px" }}
                    >
                      Cancelar
                    </Button>

                    <Submit
                      isSubmmiting={isSubmitting}
                      form="edit-profile-profile-form"
                      color="Primary"
                      options={{
                        type: "filled",
                        skin: "primary",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                    >
                      Confirmar
                    </Submit>
                  </Center>
                </Grid.Col>
              </Grid.Row>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const states = ({ usersStore, currentStore, userStore }) => {
  const { states: updateStates } = usersStore.update;
  const { data: users } = usersStore.all;
  const { data: current } = currentStore;
  const { data: user } = userStore;
  return {
    updateStates,
    current,
    users,
    user,
  };
};

export default connect(states)(Component);
