import * as Yup from "yup";

export const schema = Yup.object().shape({
  users: Yup.array().min(1).nullable(),
});

interface FormValues {
  users: any[];
}

export const initialValues = (intial): FormValues => {
  return {
    users: [],
    ...intial,
  };
};
