import {
  initialState,
  Actions,
  State,
  GET_ALL_CDOCS,
  GET_ALL_CDOCS_ERROR,
  GET_ALL_CDOCS_SUCCESS,
  GET_CDOC,
  GET_CDOC_ERROR,
  GET_CDOC_SUCCESS,
  NEW_CDOC,
  NEW_CDOC_ERROR,
  NEW_CDOC_SUCCESS,
  RESET_NEW_CDOC,
  UPDATE_CDOC,
  UPDATE_CDOC_ERROR,
  UPDATE_CDOC_SUCCESS,
  RESET_UPDATE_CDOC,
  DELETE_CDOC,
  DELETE_CDOC_ERROR,
  DELETE_CDOC_SUCCESS,
  RESET_DELETE_CDOC,
  RESET_GET_CDOC,
  ADD_USER_CDOC,
  SHARED_CDOC,
  SET_GET_CDOC,
  UPDATE_MANY_CDOC,
  UPDATE_MANY_CDOC_ERROR,
  UPDATE_MANY_CDOC_SUCCESS,
  RESET_UPDATE_MANY_CDOC,
  UPDATE_CDOC_CURRENT_LIST,
  RESET_GET_ALL_CDOC_DATA,
} from "../types/cdoc";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_CDOCS:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_CDOCS_SUCCESS:
      return {
        ...state,
        all: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_CDOCS_ERROR:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_GET_ALL_CDOC_DATA:
      return {
        ...state,
        all: initialState.all,
      };
    case GET_CDOC:
      return {
        ...state,
        selected: {
          data: state.selected.data,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };

    case GET_CDOC_SUCCESS:
      return {
        ...state,
        selected: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_CDOC_ERROR:
      return {
        ...state,
        selected: {
          data: state.selected.data,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_GET_CDOC:
      return {
        ...state,
        selected: {
          ...state.selected,
          states: {
            success: false,
            loading: false,
            error: false,
          },
        },
      };
    case NEW_CDOC:
      return {
        ...state,
        new: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_CDOC_SUCCESS:
      return {
        ...state,
        new: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case NEW_CDOC_ERROR:
      return {
        ...state,
        new: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_CDOC:
      return {
        ...state,
        new: initialState.new,
      };

    case SET_GET_CDOC:
      return {
        ...state,
        selected: {
          ...state.selected,
          data: !!action.payload ? action.payload : null,
        },
      };

    case ADD_USER_CDOC:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case SHARED_CDOC:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_CDOC:
      return {
        ...state,
        delete: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_CDOC_SUCCESS:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_CDOC_ERROR:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_CDOC:
      return {
        ...state,
        delete: initialState.delete,
      };
    case UPDATE_CDOC:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_CDOC_SUCCESS:
      return {
        ...state,
        update: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_CDOC_CURRENT_LIST:
      return {
        ...state,
        all: {
          ...state.all,
          data: action.payload,
        },
      };
    case UPDATE_CDOC_ERROR:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_CDOC:
      return {
        ...state,
        update: initialState.update,
      };
    case UPDATE_MANY_CDOC:
      return {
        ...state,
        updateMany: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_MANY_CDOC_SUCCESS:
      return {
        ...state,
        updateMany: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_MANY_CDOC_ERROR:
      return {
        ...state,
        updateMany: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_MANY_CDOC:
      return {
        ...state,
        updateMany: initialState.updateMany,
      };
    default:
      return state;
  }
};

export default reducers;
