import * as Yup from "yup";

export const schema = Yup.object().shape({
  user: Yup.string().required("Ingresa tus datos."),
  password: Yup.string().required("Ingresa la contraseña de tu cuenta."),
});

interface FormValues {
  user: string;
  password: string;
}

export const initialValues: FormValues = {
  user: "",
  password: "",
};
