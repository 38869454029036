import { useState } from "react";
import { useFormikFieldValidation } from "../../../../../../hooks/useFormikFieldValidation";
import { Field, Input } from "./style";

import {
  FieldContainer,
  Label,
  FieldBefore,
  FieldAfter,
  HelperLine,
  HelperText,
  ExplainText,
  ErrorMessage,
  TooglePassword,
  Required,
  CharacterCounter,
} from "../../../../../../components/Form/Field";

const initialValue = {
  options: {
    skin: "base",
    size: "md",
  },
};

interface ComponentProps {
  name?: string;
  value?: string | number | Date;
  type?: string;
  disabled?: boolean;
  readOnly?: boolean;
  error?: {};
  touched?: {};
  placeholder?: string;
  onFocus?: (data: any) => void;
  onBlur?: (data: any) => void;
  onChange?: (data: any) => void;
  min?: string | number;
  max?: string | number;
  size?: string;
  autocomplete?: "off" | "on" | "new-password";
  forwardRef?;
  modal?: boolean;
  checked?: boolean;
  classesField?: string;
  containerStyles?: any;
  fieldStyles?: any;
  options: {
    label?: string;
    skin?: "base" | "gray" | "blur";
    size?: "md";
    helperText?: string;
    explainText?: string;
    before?: any;
    after?: any;
    marginBottom?: number;
    padding?: string;
    hidden?: boolean;
  };
}

const Component = ({
  forwardRef,
  options = {},
  type,
  disabled,
  name,
  error,
  touched,
  max,
  onFocus,
  onBlur,
  modal,
  onChange,
  containerStyles,
  fieldStyles,
  ...rest
}: ComponentProps) => {
  const { classes, setActive } = useFormikFieldValidation(
    error,
    name,
    disabled
  );
  const { skin, size, marginBottom, hidden, padding } = {
    ...initialValue.options,
    ...options,
  };
  const [fieldType, setFieldType] = useState(type);
  const [numCharacters, setNumCharacters] = useState(0);

  const togglePassword = () => {
    !disabled &&
      setFieldType(type === `password` && fieldType === type ? `text` : type);
  };

  const handleOnChange = (event, callback) => {
    const { value } = event.target;
    setNumCharacters(value.length);
    callback && callback(event);
  };

  const handleOnBlur = (event, callback) => {
    setActive(false);
    callback && callback(event);
  };

  const handleOnFocus = (event, callback) => {
    setActive(true);
    callback && callback(event);
  };

  return (
    <FieldContainer
      marginBottom={marginBottom}
      {...(hidden && { style: { display: "none" } })}
      {...(!!containerStyles && {
        style: {
          ...containerStyles,
        },
      })}
    >
      {options?.label && (
        <Label
          size={options?.size}
          htmlFor={`input-${name}`}
          style={!!options?.explainText ? { marginBottom: "0px" } : {}}
        >
          {options.label}
          {/* {rules && rules.required && <Required>*</Required>} */}
        </Label>
      )}

      {!!options?.explainText && (
        <ExplainText>{options?.explainText}</ExplainText>
      )}

      <Field
        modal={!!modal}
        skin={skin}
        size={size}
        className={classes}
        search={!!rest.classesField ? true : false}
        {...(!!fieldStyles && { style: { ...fieldStyles } })}
      >
        {options?.before && (
          <FieldBefore size={options?.size} className="field-before">
            {options.before}
          </FieldBefore>
        )}
        <Input
          id={name}
          onlyRed={true}
          name={name}
          max={max}
          type={fieldType}
          disabled={disabled}
          ref={forwardRef}
          padding={padding}
          skin={skin}
          size={size}
          onChange={(event) => handleOnChange(event, onChange)}
          onBlur={(event) => handleOnBlur(event, onBlur)}
          onFocus={(event) => handleOnFocus(event, onFocus)}
          onWheel={(event) => event.currentTarget.blur()}
          {...(type === "date" && { max: max || "9999-12-31" })}
          {...rest}
        />

        {(options?.after || type === "password") && (
          <FieldAfter size={options?.size} className="field-after">
            {type === "password" && !options?.after ? (
              <TooglePassword
                type="button"
                onClick={togglePassword}
              ></TooglePassword>
            ) : (
              options && options.after
            )}
          </FieldAfter>
        )}
      </Field>

      {((options && options.helperText) || max || error) && (
        <HelperLine marginBottom={marginBottom}>
          {!!error && !!touched && <ErrorMessage>{error}</ErrorMessage>}
          {options && options.helperText && !error && (
            <HelperText>{options.helperText}</HelperText>
          )}
          {max && (
            <CharacterCounter errors={!!error && !!touched}>
              {numCharacters} / {max}
            </CharacterCounter>
          )}
        </HelperLine>
      )}
    </FieldContainer>
  );
};

export default Component;
