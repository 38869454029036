import { put, call, takeLatest } from "redux-saga/effects";
import services from "../../services";
import {
  NEW_CRITERION,
  NEW_CRITERION_ERROR,
  NEW_CRITERION_SUCCESS,
  NewCriterion,
  GET_ALL_CRITERIONS_ERROR,
  GET_ALL_CRITERIONS_SUCCESS,
  GetAllCriterions,
  GET_ALL_CRITERIONS,
  UPDATE_CRITERION_ERROR,
  UPDATE_CRITERION_SUCCESS,
  UpdateCriterion,
  UPDATE_CRITERION,
  DeleteCriterion,
  DELETE_CRITERION,
  DELETE_CRITERION_ERROR,
  DELETE_CRITERION_SUCCESS,
} from "../types/criterion";

function* newCriterion({ payload }) {
  const { response, error } = yield call(services.criterion.new, payload);

  if (error) {
    yield put({
      type: NEW_CRITERION_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: NEW_CRITERION_SUCCESS, payload: response });
  }
}

function* getAllCriterions({ payload }) {
  const { response, error } = yield call(services.criterion.getAll, payload);
  if (error) {
    yield put({
      type: GET_ALL_CRITERIONS_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_CRITERIONS_SUCCESS, payload: response });
  }
}

function* updatedCriterion({ payload }) {
  const { response, error } = yield call(services.criterion.updated, payload);
  if (error) {
    yield put({
      type: UPDATE_CRITERION_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: UPDATE_CRITERION_SUCCESS, payload: response });
  }
}
function* deleteCriterion({ payload }) {
  const { response, error } = yield call(services.criterion.delete, payload);
  if (error) {
    yield put({
      type: DELETE_CRITERION_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: DELETE_CRITERION_SUCCESS, payload: response });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<NewCriterion>(NEW_CRITERION, newCriterion);
  yield takeLatest<GetAllCriterions>(GET_ALL_CRITERIONS, getAllCriterions);
  yield takeLatest<UpdateCriterion>(UPDATE_CRITERION, updatedCriterion);
  yield takeLatest<DeleteCriterion>(DELETE_CRITERION, deleteCriterion);
}
