import { SignInData, SignOutData, SignUpData } from "../../types/auth.dto";
export const SIGN_IN = "SIGN_IN";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_ERROR = "SIGN_IN_ERROR";
export const RESET_SIGN_IN = "RESET_SIGN_IN";

export const SIGN_UP = "SIGN_UP";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_ERROR = "SIGN_UP_ERROR";
export const RESET_SIGN_UP = "RESET_SIGN_UP";

export const AUTO_SIGN_IN = "AUTO_SIGN_IN";
export const AUTO_SIGN_IN_SUCCESS = "AUTO_SIGN_IN_SUCCESS";
export const AUTO_SIGN_IN_ERROR = "AUTO_SIGN_IN_ERROR";

export const SIGN_OUT = "SIGN_OUT";
export const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS";
export const SIGN_OUT_ERROR = "SIGN_OUT_ERROR";

export const RECOVER_PASSWORD = "RECOVER_PASSWORD";
export const RECOVER_PASSWORD_SUCCESS = "RECOVER_PASSWORD_SUCCESS";
export const RECOVER_PASSWORD_ERROR = "RECOVER_PASSWORD_ERROR";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const SIGN_IN_WITH_SOCIAL = "SIGN_IN_WITH_SOCIAL"
export const SIGN_UP_WITH_SOCIAL = "SIGN_UP_WITH_SOCIAL"
export const SIGN_UP_WITH_SOCIAL_SUCCESS = "SIGN_UP_WITH_SOCIAL_SUCCESS"

export interface SignIn {
  type: typeof SIGN_IN;
  payload: SignInData;
}
export interface SignInSuccess {
  type: typeof SIGN_IN_SUCCESS;
  payload: boolean | string;
}

export interface SignInWithSocial{
  type:typeof SIGN_IN_WITH_SOCIAL;
  payload:{code:string}
}
export interface SignUpWithSocial{
  type:typeof SIGN_UP_WITH_SOCIAL;
  payload:{code:string}
}


export interface SignInOut {
  type: typeof SIGN_IN_ERROR;
  payload: boolean | string;
}

export interface ResetSignIn {
  type: typeof RESET_SIGN_IN;
  payload: null;
}
export interface SignUp {
  type: typeof SIGN_UP;
  payload: SignUpData;
}
export interface SignUpSuccess {
  type: typeof SIGN_UP_SUCCESS;
  payload: boolean | string;
}
export interface SignUpOut {
  type: typeof SIGN_UP_ERROR;
  payload: boolean | string;
}

export interface ResetSignUp {
  type: typeof RESET_SIGN_UP;
  payload: null;
}

export interface SignOut {
  type: typeof SIGN_OUT;
  payload: SignOutData;
}
export interface SignOutSuccess {
  type: typeof SIGN_OUT_SUCCESS;
  payload: boolean | string;
}
export interface SignOutOut {
  type: typeof SIGN_OUT_ERROR;
  payload: boolean | string;
}

export interface RecoverPassword {
  type: typeof RECOVER_PASSWORD;
  payload: { email: string };
}
export interface RecoverPasswordSuccess {
  type: typeof RECOVER_PASSWORD_SUCCESS;
  payload: null;
}
export interface RecoverPasswordError {
  type: typeof RECOVER_PASSWORD_ERROR;
  payload: boolean | string;
}

export interface ResetPassword {
  type: typeof RESET_PASSWORD;
  payload: { id: string; password: string };
}
export interface ResetPasswordSuccess {
  type: typeof RESET_PASSWORD_SUCCESS;
  payload: null;
}
export interface ResetPasswordError {
  type: typeof RESET_PASSWORD_ERROR;
  payload: boolean | string;
}

export interface State {
  signin: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  signup: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  signout: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  recoverPassword: {
    data: any;
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
  resetPassword: {
    data: any;
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
}

export const initialState: State = {
  signin: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  signup: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  signout: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  recoverPassword: {
    data: null,
    states: {
      loading: false,
      error: false,
      success: false,
    },
  },
  resetPassword: {
    data: null,
    states: {
      loading: false,
      error: false,
      success: false,
    },
  },
};

export type Actions =
  | SignIn
  | SignInSuccess
  | SignInOut
  | SignOut
  | SignOutSuccess
  | SignOutOut
  | ResetSignIn
  | SignUp
  | SignUpSuccess
  | SignUpOut
  | ResetSignUp
  | RecoverPassword
  | RecoverPasswordSuccess
  | RecoverPasswordError
  | ResetPassword
  | ResetPasswordSuccess
  | ResetPasswordError
  |SignInWithSocial
  |SignUpWithSocial
