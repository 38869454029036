import * as Yup from "yup";

interface FormValues {
  name: string;
  description: string;
  criterions: string[];
}

export const schema = Yup.object().shape({
  name: Yup.string().required("Ingresar minimo un caracter"),
  description: Yup.string(),
  criterions: Yup.array().of(Yup.string()),
});

export const initialValues: FormValues = {
  name: "",
  description: "",
  criterions: [],
};
