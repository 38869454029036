import styled from "styled-components";

export const ToolSideWrapper = styled("div")`
  padding: 4px 2px;
  border-radius: 5px;
  
  height: min-content;
  background-color: #fff;
`;
export const ToolItemWrapper = styled("div")`
  display: flex;
  align-items: center;
  background-color: #fff;
  justify-content: center;
  
  position: relative;
  padding: 4px 5px;
  border-radius: 5px;
  transition: bacground-color 0.2s ease-in-out;
  &:hover > div {
    visibility: visible;
  }
  svg {
    transition: color 0.2s ease-in-out;
  }
  &.active {
    background-color: #1573e5;
    svg {
      color: #fff;
    }
  }
  &:hover {
    background-color: #1573e5;
    svg {
      color: #fff;
    }
  }
  &.disavailable {
    background-color: #fff;
    svg {
      color: rgba(0, 30, 108, 0.3);
    }
  }
  &.disavailable:hover {
    background-color: #fff;
    svg {
      color: rgba(0, 30, 108, 0.3);
    }
  }
`;

/* export const ToolItemWrapper = styled("div")`` */

export const ToolTipItem = styled("div")`
  position: absolute;
  visibility: hidden;
  width: max-content;
  transition: visibility 0.3s ease-in-out;
  top: 0;
  right: 50px;
  padding: 5px;
  box-shadow: 0 0 6px 0 rgba(31, 119, 180, 0.2);
  background-color: #fff;
`;
