import {
  NewCompany,
  NEW_COMPANY,
  ResetNewCompany,
  RESET_NEW_COMPANY,
  GET_ALL_COMPANIES,
  GetAllCompanies,
  UpdateCompany,
  UPDATE_COMPANY,
  ResetUpdateCompany,
  RESET_UPDATE_COMPANY,
  GetCompany,
  DeleteCompany,
  DELETE_COMPANY,
  GET_COMPANY,
  RESET_DELETE_COMPANY,
} from "../../types/recruiting/company";

const create = (payload): NewCompany => {
  return { type: NEW_COMPANY, payload };
};
const resetCreate = (): ResetNewCompany => {
  return { type: RESET_NEW_COMPANY, payload: null };
};

const update = (payload): UpdateCompany => {
  return { type: UPDATE_COMPANY, payload };
};

const resetUpdate = (): ResetUpdateCompany => {
  return { type: RESET_UPDATE_COMPANY, payload: null };
};

const getAll = (): GetAllCompanies => {
  return { type: GET_ALL_COMPANIES, payload: null };
};

const getOne = (payload: { _id: string }): GetCompany => {
  return { type: GET_COMPANY, payload };
};

const deleteOne = (payload: { _id: string }): DeleteCompany => {
  return { type: DELETE_COMPANY, payload };
};

const resetDeleteOne = () => {
  return { type: RESET_DELETE_COMPANY, payload: null };
};

const actions = {
  create,
  resetCreate,
  update,
  resetUpdate,
  resetDeleteOne,
  getAll,
  getOne,
  deleteOne,
};

export default actions;
