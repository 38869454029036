import { put, call, takeLatest, select, all } from "redux-saga/effects";
import services from "../../services";
import {
  NEW_ORGANIZATION,
  NEW_ORGANIZATION_ERROR,
  NEW_ORGANIZATION_SUCCESS,
  NewOrganization,
  GET_ALL_ORGANIZATIONS,
  GET_ALL_ORGANIZATIONS_ERROR,
  GET_ALL_ORGANIZATIONS_SUCCESS,
  GetAllOrganizations,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_ERROR,
  UPDATE_ORGANIZATION_SUCCESS,
  UpdateOrganization,
  DELETE_ORGANIZATION,
  UPDATE_CURRENT_ORGANIZATION_LIST,
  DELETE_ORGANIZATION_SUCCESS,
  DELETE_ORGANIZATION_ERROR,
  NEW_ORGANIZATION_INVITE_LINK_ERROR,
  NEW_ORGANIZATION_INVITE_LINK_SUCCESS,
  NEW_ORGANIZATION_INVITE_LINK,
  DELETE_ORGANIZATION_INVITE_LINK,
  DELETE_ORGANIZATION_INVITE_LINK_ERROR,
  DELETE_ORGANIZATION_INVITE_LINK_SUCCESS,
  DELETE_ORGANIZATION_MEMBER,
  UPDATE_ORGANIZATION_ROLE,
  GetOrganization,
  GET_ORGANIZATION,
  GET_ORGANIZATION_ERROR,
  GET_ORGANIZATION_SUCCESS,
  AddUserOrganization,
  ADD_USER_ORGANIZATION,
} from "../types/organization";
import { GET_ALL_ACADEMIES } from "../types/academy";

const companyList = ({ organizationStore }) => organizationStore.all.data;

function* newOrganization({ payload }) {
  const { response, error } = yield call(services.organization.new, payload);

  if (error) {
    yield put({
      type: NEW_ORGANIZATION_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    const currentListOfTechsOrganizations = yield select(companyList);
    const newListofTechsOrganizations = !!currentListOfTechsOrganizations
      ? [...currentListOfTechsOrganizations, response]
      : null;
    yield put({
      type: UPDATE_CURRENT_ORGANIZATION_LIST,
      payload: newListofTechsOrganizations,
    });
    yield put({ type: NEW_ORGANIZATION_SUCCESS, payload: response });
  }
}

function* deleteOrganization({ payload }) {
  const { response, error } = yield call(services.organization.delete, payload);

  if (error) {
    yield put({
      type: DELETE_ORGANIZATION_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    const currentListOfOrganizations = yield select(companyList);
    const newListofOrganizations = currentListOfOrganizations?.filter(
      (company) => company._id !== payload._id
    );
    yield put({
      type: UPDATE_CURRENT_ORGANIZATION_LIST,
      payload: newListofOrganizations,
    });
    yield put({ type: DELETE_ORGANIZATION_SUCCESS, payload: response });
  }
}

function* getAllOrganizations() {
  const { response, error } = yield call(services.organization.getAll);
  if (error) {
    yield put({
      type: GET_ALL_ORGANIZATIONS_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_ORGANIZATIONS_SUCCESS, payload: response });
  }
}

function* getOrganization({ payload }) {
  const { response, error } = yield call(services.organization.getOne, payload);
  if (error) {
    yield put({
      type: GET_ORGANIZATION_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ORGANIZATION_SUCCESS, payload: response });
  }
}

function* createLink({ payload }) {
  const { response, error } = yield call(
    services.organization.createLink,
    payload
  );
  if (error) {
    yield put({
      type: NEW_ORGANIZATION_INVITE_LINK_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({
      type: NEW_ORGANIZATION_INVITE_LINK_SUCCESS,
      payload: response,
    });
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_URL}/link/${response.inviteLink}`
    );
  }
}

function* deleteLink({ payload }) {
  const { response, error } = yield call(
    services.organization.deleteLink,
    payload
  );
  if (error) {
    yield put({
      type: DELETE_ORGANIZATION_INVITE_LINK_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({
      type: DELETE_ORGANIZATION_INVITE_LINK_SUCCESS,
      payload: response,
    });
  }
}

function* updatedOrganization({ payload }) {
  const { response, error } = yield call(services.organization.update, payload);

  if (error) {
    yield put({
      type: UPDATE_ORGANIZATION_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    const currentListOfComapnies = yield select(companyList);
    const newListofComapnies = currentListOfComapnies?.data?.map((company) => {
      if (company._id === payload._id) {
        return { ...company, ...payload };
      }
      return company;
    });

    yield put({ type: UPDATE_ORGANIZATION_SUCCESS, payload: null });
    yield put({ type: GET_ALL_ORGANIZATIONS, payload: null });
  }
}

function* deleteMember({ payload }) {
  const { response, error } = yield call(
    services.organization.deleteMember,
    payload
  );

  if (error) {
    yield put({
      type: UPDATE_ORGANIZATION_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    const currentListOfComapnies = yield select(companyList);
    const newListofComapnies = currentListOfComapnies?.map((company) => {
      if (company._id === payload._id) {
        return { ...company, ...payload };
      }
      return company;
    });
    yield put({
      type: UPDATE_CURRENT_ORGANIZATION_LIST,
      payload: newListofComapnies,
    });
    yield put({ type: UPDATE_ORGANIZATION_SUCCESS, payload: null });
    yield put({ type: GET_ALL_ORGANIZATIONS, payload: null });
  }
}

function* updatedRole({ payload }) {
  const { response, error } = yield call(
    services.organization.updateRole,
    payload
  );

  if (error) {
    yield put({
      type: UPDATE_ORGANIZATION_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    const currentListOfComapnies = yield select(companyList);
    const newListofComapnies = currentListOfComapnies?.map((company) => {
      if (company._id === payload._id) {
        return { ...company, ...payload };
      }
      return company;
    });
    yield put({
      type: UPDATE_CURRENT_ORGANIZATION_LIST,
      payload: newListofComapnies,
    });
    yield put({ type: UPDATE_ORGANIZATION_SUCCESS, payload: null });
    yield put({ type: GET_ALL_ORGANIZATIONS, payload: null });
  }
}

function* addMember({ payload }) {
  const { response, error } = yield call(services.academy.addMember, payload);

  if (error) {
    yield put({
      type: UPDATE_ORGANIZATION_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([
      put({ type: UPDATE_ORGANIZATION_SUCCESS, payload: null }),
      put({ type: GET_ALL_ACADEMIES, payload: null }),
    ]);
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<NewOrganization>(NEW_ORGANIZATION, newOrganization);
  yield takeLatest<GetAllOrganizations>(
    GET_ALL_ORGANIZATIONS,
    getAllOrganizations
  );
  yield takeLatest<UpdateOrganization>(
    UPDATE_ORGANIZATION,
    updatedOrganization
  );
  yield takeLatest<GetOrganization>(GET_ORGANIZATION, getOrganization);
  yield takeLatest<any>(NEW_ORGANIZATION_INVITE_LINK, createLink);
  yield takeLatest<UpdateOrganization>(DELETE_ORGANIZATION, deleteOrganization);
  /* yield takeLatest<any>(NEW_INVITE_LINK, createLink); */
  yield takeLatest<any>(DELETE_ORGANIZATION_INVITE_LINK, deleteLink);
  yield takeLatest<any>(DELETE_ORGANIZATION_MEMBER, deleteMember);
  yield takeLatest<any>(UPDATE_ORGANIZATION_ROLE, updatedRole);
  yield takeLatest<AddUserOrganization>(ADD_USER_ORGANIZATION, addMember);
}
