import * as Yup from "yup";

export const schema = Yup.object().shape({
  team: Yup.array().of(Yup.string()).min(1),
});

interface FormValues {
  team: string[];
}

export const initialValues: FormValues = {
  team: [],
};
