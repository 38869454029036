import { AbilityDto } from "../../types/ability.dto";

export const GET_ALL_ABILITIES = "GET_ALL_ABILITIES";
export const GET_ALL_ABILITIES_ERROR = "GET_ALL_ABILITIES_ERROR";
export const GET_ALL_ABILITIES_SUCCESS = "GET_ALL_ABILITIES_SUCCESS";
export const GET_ABILITY = "GET_ABILITY";
export const GET_ABILITY_ERROR = "GET_ABILITY_ERROR";
export const GET_ABILITY_SUCCESS = "GET_ABILITY_SUCCESS";
export const NEW_ABILITY = "NEW_ABILITY";
export const NEW_ABILITY_ERROR = "NEW_ABILITY_ERROR";
export const NEW_ABILITY_SUCCESS = "NEW_ABILITY_SUCCESS";
export const RESET_NEW_ABILITY = "RESET_NEW_ABILITY";
export const UPDATE_ABILITY = "UPDATE_ABILITY";
export const UPDATE_ABILITY_ERROR = "UPDATE_ABILITY_ERROR";
export const UPDATE_ABILITY_SUCCESS = "UPDATE_ABILITY_SUCCESS";
export const RESET_UPDATE_ABILITY = "RESET_UPDATE_ABILITY";
export const DELETE_ABILITY = "DELETE_ABILITY";
export const DELETE_ABILITY_ERROR = "DELETE_ABILITY_ERROR";
export const DELETE_ABILITY_SUCCESS = "DELETE_ABILITY_SUCCESS";
export const RESET_DELETE_ABILITY = "RESET_DELETE_ABILITY";

export interface GetAllAbilities {
  type: typeof GET_ALL_ABILITIES;
  payload: null;
}

export interface GetAllAbilitiesSuccess {
  type: typeof GET_ALL_ABILITIES_SUCCESS;
  payload: AbilityDto[];
}

export interface GetAllAbilitiesError {
  type: typeof GET_ALL_ABILITIES_ERROR;
  payload: boolean | string;
}

export interface GetAbility {
  type: typeof GET_ABILITY;
  payload: { id: string };
}

export interface GetAbilitySuccess {
  type: typeof GET_ABILITY_SUCCESS;
  payload: AbilityDto;
}

export interface GetAbilityError {
  type: typeof GET_ABILITY_ERROR;
  payload: boolean | string;
}

export interface NewAbility {
  type: typeof NEW_ABILITY;
  payload: AbilityDto;
}

export interface NewAbilitySuccess {
  type: typeof NEW_ABILITY_SUCCESS;
  payload: AbilityDto;
}

export interface NewAbilityError {
  type: typeof NEW_ABILITY_ERROR;
  payload: boolean | string;
}

export interface ResetNewAbility {
  type: typeof RESET_NEW_ABILITY;
  payload: null;
}

export interface UpdateAbility {
  type: typeof UPDATE_ABILITY;
  payload: AbilityDto;
}

export interface UpdateAbilitySuccess {
  type: typeof UPDATE_ABILITY_SUCCESS;
  payload: AbilityDto;
}

export interface UpdateAbilityError {
  type: typeof UPDATE_ABILITY_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateAbility {
  type: typeof RESET_UPDATE_ABILITY;
  payload: null;
}

export interface DeleteAbility {
  type: typeof DELETE_ABILITY;
  payload: { id: string };
}

export interface DeleteAbilitySuccess {
  type: typeof DELETE_ABILITY_SUCCESS;
  payload: null;
}

export interface DeleteAbilityError {
  type: typeof DELETE_ABILITY_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteAbility {
  type: typeof RESET_DELETE_ABILITY;
  payload: null;
}

export interface State {
  allAbilities: {
    data: AbilityDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  ability: {
    data: AbilityDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  newAbility: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  deleteAbility: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  updateAbility: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  allAbilities: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  ability: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  newAbility: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  deleteAbility: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  updateAbility: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllAbilities
  | GetAllAbilitiesSuccess
  | GetAllAbilitiesError
  | GetAbility
  | GetAbilitySuccess
  | GetAbilityError
  | NewAbility
  | NewAbilitySuccess
  | NewAbilityError
  | ResetNewAbility
  | UpdateAbility
  | UpdateAbilitySuccess
  | UpdateAbilityError
  | ResetUpdateAbility
  | DeleteAbility
  | DeleteAbilitySuccess
  | DeleteAbilityError
  | ResetDeleteAbility;
