import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Modal from "../../../../../../components/Modal";
import { Formik } from "formik";
import { Center } from "../../../../../../components/StyledComponents";
import {
  initialValues,
  schema,
} from "../../../../../../constants/form/recruiting-search/edit";
import Grid from "../../../../../../components/Grid";
import Button from "../../../../../../components/Button";
import Submit from "../../../../../../components/Form/Submit";
import ReactSelect from "../../../../../../components/Form/ReactSelect";
import { toast } from "react-toastify";
import TextArea from "../../../../../../components/Form/TextArea";
import Input from "../../../../../../components/Form/Input";
import searchesActions from "../../../../../../store/actions/recruiting/search";
import { useRecruitingLayout } from "../../../../../../provider/RecruitingProvider";
import { hideModal } from "../../../../../../store/actions/modal";
interface ComponentProps {
  updateStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({ updateStates }: ComponentProps) => {
  const { selectedItem, statics, store } = useRecruitingLayout();
  const { states } = store.update;
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const dispatch = useDispatch();

  const onSubmit = ({ values, actions }) => {
    dispatch(searchesActions.update(values));
    setFormActions(actions);
  };

  useEffect(() => {
    if (states.success) {
      toast("👌🏼 Busqueda editada correctamente", {
        containerId: "submit",
        type: "success",
        toastId: "submitsucceful",
      });

      setTimeout(() => {
        dispatch(hideModal());
        dispatch(searchesActions.resetUpdate());
        formActions?.resetForm();
      }, 1000);
    }

    if (states.error) {
      toast(`😱 ${states.error}`, {
        containerId: "submit",
        type: "error",
        toastId: "submiterror",
      });

      setTimeout(() => {
        dispatch(searchesActions.resetUpdate());
      }, 1000);
    }
  }, [states]);

  return (
    <Modal name="edit-search-modal" title="Editar Busqueda">
      <Formik
        initialValues={{ ...initialValues, ...selectedItem?.data }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="new-evaluate-form"
            >
              <Grid.Row>
                <Grid.Col>
                  <Input
                    name={"name"}
                    error={errors["name"]}
                    touched={touched["name"]}
                    value={values["name"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{ skin: "gray", label: "Nombre de la búsqueda *" }}
                  />
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col>
                  <ReactSelect
                    name="country"
                    error={errors["country"]}
                    touched={touched["country"]}
                    items={[{ label: "Argentina", value: "argentina" }]}
                    placeholder="Selecciona un Pais"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      skin: "gray",
                      loading: statics?.allProvinces?.loading,
                      label: "País",
                      marginBottom: 24,
                    }}
                  />
                </Grid.Col>
                <Grid.Col>
                  <ReactSelect
                    name="province"
                    error={errors["province"]}
                    touched={touched["province"]}
                    items={statics?.allProvinces?.data}
                    placeholder="Selecciona una Provincia"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      skin: "gray",
                      loading: statics?.allProvinces?.loading,
                      label: "Provincia",
                      marginBottom: 24,
                    }}
                  />
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col>
                  <ReactSelect
                    name="english"
                    error={errors["english"]}
                    touched={touched["english"]}
                    items={[
                      { label: "A1", value: "a1" },
                      { label: "A2", value: "a2" },
                      { label: "B1", value: "b1" },
                      { label: "B2", value: "b2" },
                      { label: "C1", value: "c1" },
                      { label: "C2", value: "c2" },
                    ]}
                    placeholder="Selecciona nivel del idioma"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      skin: "gray",
                      label: "Ingles *",
                      marginBottom: 24,
                    }}
                  />
                </Grid.Col>
              </Grid.Row>

              <Grid.Row>
                <Grid.Col>
                  <TextArea
                    name="description"
                    error={errors["description"]}
                    touched={touched["description"]}
                    value={values["description"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      skin: "gray",
                      label: "Descripcion",
                      marginBottom: 24,
                    }}
                  />
                </Grid.Col>
              </Grid.Row>

              <Grid.Row>
                <Grid.Col>
                  <Center gap={"70px"}>
                    <Button
                      onClick={() => {
                        dispatch(hideModal());
                        resetForm();
                      }}
                      type="button"
                      options={{
                        type: "outline",
                        skin: "gray",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                      style={{ marginLeft: "10px" }}
                    >
                      Cancelar
                    </Button>

                    <Submit
                      isSubmmiting={updateStates.loading}
                      form="new-evaluate-form"
                      color="Primary"
                      options={{
                        type: "filled",
                        skin: "primary",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                    >
                      Confirmar
                    </Submit>
                  </Center>
                </Grid.Col>
              </Grid.Row>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const states = ({ recruitingStore }) => {
  const { states: updateStates } = recruitingStore.search.update;

  return {
    updateStates,
  };
};

export default connect(states)(Component);
