import { Formik } from "formik";

import ReactSelect from "../../../../../../components/Form/ReactSelect";

import { CohortDto } from "../../../../../../types/cohort.dto";
import { connect, useDispatch } from "react-redux";
import { getCohortStats } from "../../../../../../store/actions/analytics";
import { CurrentDto } from "../../../../../../types/current.dto";

interface ComponentProps {
  applyedFilters;
  setFilter;
  cohorts: CohortDto[];
  section?;
  placeholder?;
  setSelectedBootcamp;
  values;
  current: CurrentDto;
}

const Component = ({
  current,
  values,
  applyedFilters,
  setFilter,
  cohorts,
  placeholder,
  setSelectedBootcamp,
}: ComponentProps) => {
  const dispatch = useDispatch();
  return (
    <Formik
      initialValues={{}}
      onSubmit={(values, actions) => {}}
      enableReinitialize
    >
      {({ handleSubmit }) => {
        return (
          <form
            className="theme-form"
            onSubmit={(event) => {
              handleSubmit(event);
            }}
            id="userid-filter-form"
          >
            <ReactSelect
              name="userId"
              items={
                !!cohorts &&
                cohorts
                  .filter((cohort) =>
                    current.currentSection === "personal"
                      ? cohort.academy._id === values["academy"]
                      : cohort
                  )
                  .map((cohort) => {
                    return {
                      label: `${cohort.name}`,
                      value: cohort._id,
                    };
                  })
              }
              placeholder={`${placeholder}`}
              onOptionSelected={(newValue) => {
                if (newValue) {
                  const selected = cohorts.filter(
                    (user) => user._id === newValue.value
                  )[0];
                  setSelectedBootcamp({
                    id: selected._id,
                    name: ` ${selected.name}`,
                    cohortStudents: selected.studentsIds.length,
                  });
                  dispatch(getCohortStats(selected._id));
                }
              }}
              disabled={
                current.currentSection === "personal" && !values.academy
              }
              options={{ label: "Selecciona Cohort" }}
              /*     onOptionSelected={(optionSelected) => {
                if (optionSelected) {
                  setFilter({
                    field: "_id",
                    value: optionSelected ? optionSelected.value : "no",
                  });
                } else {
                  setFilter({
                    field: "_id",
                    value: "no",
                  });
                }
              }}
              isClearable
              options={{
                marginBottom: 0,
                indicator: true,
                before: <Icon icon={searchIcon} size="16px" color="" />,
              }} */
            />
          </form>
        );
      }}
    </Formik>
  );
};

const states = ({ currentStore }) => {
  const { data: current } = currentStore;
  return {
    current,
  };
};

export default connect(states)(Component);
