import { Center } from "../StyledComponents";
import { Title } from "./style";

const Component = ({
  title,
  mg,
  ...props
}: {
  title: string;
  mg?: number;
  size?: number;
  props?;
}) => {
  return (
    <Center style={{ marginTop: `${mg}px` }}>
      <Title {...props}>{title}</Title>
    </Center>
  );
};

export default Component;
