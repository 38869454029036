import { useEffect, useMemo, useState } from "react";
import {
  RecruitingLayout,
  useRecruitingLayout,
} from "../../../../provider/RecruitingProvider";
import Tabs from "../../../../components/Tabs";
import Icon from "../../../../components/Icon";
import { Center, InLine } from "../../../../components/StyledComponents";
import {
  Header,
  SectionIcon,
  Title,
  PrimarySubTitle,
  SecondSubtitle,
  Actions,
  Action,
  Tab,
  TabTitle,
  Description,
  Content,
  ListTitle,
  List,
  ListItem,
} from "../components/StyledComponents";
import { connect, useDispatch } from "react-redux";
import { showModal } from "../../../../store/actions/modal";
import recruiting from "../../../../store/actions/recruiting/";
import ModalCreateTalent from "./components/ModalCreateTalent";
import ModalEditTalent from "./components/ModalEditTalent";
import TalentSearchIcon from "../../../../assets/icons/talent-search.svg";
import ShowModal from "../components/ShoModal";
import { AitTalentDto } from "../../../../types/recruiting.dto";
import { TalentDto } from "../../../../types/talent.dto";

import RadarChart from "../../../../components/RadarChart";
import { TitleStage } from "../../Analytics/IndividualPerformance/components/NumericStats/styles";
import {
  CardCol,
  CardRow,
  SmallCardChart,
  NoteCard,
  BigCardChart,
} from "../../Marketplace/components/TalentCard/styles";
import { AnalyticsDto } from "../../../../types/analytics.dto";
import { getIndividualStats } from "../../../../store/actions/analytics";
import TabProfile from "./components/TabProfile";
import TabSearches from "./components/TabSearches";
import TabCompanies from "./components/TabCompanies";
import TabTechnologies from "./components/TabTechnologies";
import { getAllStudents } from "../../../../store/actions/student";
import { useParams } from "react-router-dom";
import recruitingActions from "../../../../store/actions/recruiting/index";
interface ComponentProps {
  allAitTalents: AitTalentDto[];
  allAitTalentsStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  allStudents: TalentDto[];
  allStudentsStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  stats?;
  selected;
}

const Component = ({
  allAitTalents,
  allAitTalentsStates,
  allStudents,
  allStudentsStates,
  stats,
  selected,
}: ComponentProps) => {
  const { setRecruitingLayout, selectedItem } = useRecruitingLayout();
  const dispatch = useDispatch();
  const params = useParams();
  const EditarPerfil = () => (
    <Action onClick={() => dispatch(showModal("edit-talent-modal"))}>
      <Icon icon={TalentSearchIcon} size="16px" color="black" />
      Editar Perfil
    </Action>
  );

  const getAllItems = () => {
    dispatch(getAllStudents());
    dispatch(recruitingActions.aitTalent.getAll());
  };

  const recruitingData = useMemo(
    (): RecruitingLayout => ({
      title: "Mis Talentos",
      icon: TalentSearchIcon,
      model: "talent",
      externalIds: [],
      fieldName: "talentIds",
      newProfileModal: "new-talent-modal",
      actions: recruiting.aitTalent,
      list: allAitTalents
        ?.filter((aitTalent) => aitTalent.active)
        .filter((aitTalent) => aitTalent.team === params.id)
        .map((aitTalent) => {
          const data = {
            ...aitTalent,
          };
          return {
            label: `${data.name} ${!!data.lastName && data.lastName}`,
            value: aitTalent._id,
            data,
          };
        }),
    }),
    [allAitTalents, selected, params]
  );

  useEffect(() => {
    setRecruitingLayout(recruitingData);
    getAllItems();
  }, [selected, params]);

  useEffect(() => {
    setRecruitingLayout(recruitingData);
  }, [allAitTalents, selected, params]);

  return (
    <>
      <Header>
        <InLine
          align="flex-start"
          justify="flex-start"
          style={{ marginBottom: "14px" }}
        >
          <SectionIcon>
            <Icon icon={TalentSearchIcon} size="16px" color="white" />
          </SectionIcon>
          <span>
            <Title>{selectedItem?.label}</Title>

            <PrimarySubTitle>
              {`Provincia de ${
                !!selectedItem?.data?.province
                  ? selectedItem?.data?.province
                  : ""
              }, ${
                !!selectedItem?.data?.country ? selectedItem?.data?.country : ""
              }`}
            </PrimarySubTitle>
            <SecondSubtitle>{selectedItem?.data?.english}</SecondSubtitle>
          </span>
        </InLine>

        <InLine justify="flex-start">
          <EditarPerfil />
          <ShowModal.AsignarBusqueda />
          <ShowModal.AsignarEmpresa />
          <ShowModal.AsignarTecnologia />
        </InLine>
      </Header>

      <Tabs>
        <Tab id="perfil" label="Perfil">
          <TabProfile>
            <EditarPerfil />
          </TabProfile>
        </Tab>

        <Tab id="busqueda" label="Busqueda">
          <TabSearches></TabSearches>
        </Tab>

        <Tab id="empresa" label="Empresa">
          <TabCompanies></TabCompanies>
        </Tab>

        <Tab id="tecnologias" label="Tecnologías">
          <TabTechnologies></TabTechnologies>
        </Tab>
        {!selectedItem?.data?.external ? (
          <Tab
            onClick={() => dispatch(getIndividualStats(selectedItem?.value))}
            id="metricas"
            label="Metricas"
          >
            <InLine justify="space-between">
              <Title>Métricas de Talento</Title>
            </InLine>

            <Description>
              Las métricas aplican solo a los talentos egresados de Bootcamps IT
              de Dev Place. Podrás ver indicadores de performance individual del
              talento Soft y tech. Todos los Talentos egresados de Dev Place son
              medidos por profesionales de la educación, formación y software.
              Podrás aprovechar al máximo las métricas para hacer "match"
              perfecto con tus busquedas.
            </Description>
            <Center>
              <CardCol>
                <CardRow>
                  <CardCol style={{ marginRight: "10px" }}>
                    <CardRow style={{ marginBottom: "10px" }}>
                      <SmallCardChart>
                        <TitleStage>Global</TitleStage>
                        <NoteCard>
                          {Math.round(stats?.globalProm * 10) / 10 || 0}
                        </NoteCard>
                      </SmallCardChart>
                    </CardRow>
                    <CardRow>
                      <SmallCardChart>
                        <TitleStage>Soft</TitleStage>
                        <NoteCard>
                          {Math.round(stats?.softProm * 10) / 10 || 0}
                        </NoteCard>
                      </SmallCardChart>
                    </CardRow>
                  </CardCol>

                  <CardCol>
                    <BigCardChart>
                      <TitleStage>Tech Skills</TitleStage>
                      <RadarChart
                        labels={stats?.hardNotes?.map((note) => note.skill)}
                        data={stats?.hardNotes?.map((note) => note.note)}
                      />
                    </BigCardChart>
                  </CardCol>
                </CardRow>
              </CardCol>
            </Center>
          </Tab>
        ) : (
          <></>
        )}
      </Tabs>

      <ModalCreateTalent />
      <ModalEditTalent />
    </>
  );
};

const states = ({
  recruitingStore,
  studentStore,
  analyticsStore,
  teamStore,
}) => {
  const { data: allAitTalents, states: allAitTalentsStates } =
    recruitingStore.talent.all;

  const { data: allStudents, states: allStudentsStates } =
    studentStore.allStudents;
  const { data: stats } = analyticsStore.getIndividualStats;
  const { data: selected } = teamStore.selected;
  return {
    allAitTalents,
    allAitTalentsStates,
    allStudents,
    allStudentsStates,
    selected,
  };
};

export default connect(states)(Component);
