import React, { useEffect, useState } from "react";
import { FlexContainer } from "../StyledComponents";
import { GraphWrapper, Select } from "./style";
import LineGraph from "../LineGraph";
import { SuscriptionText } from "../../pages/Dashboard/Monetization/Academy/style";
import Loader from "../Loader";
import { DateEStoDateEN } from "../../helpers/format-date";
import { formatNumber } from "../../helpers/number-fixed";
import { SuscriptionAnalyticsDto } from "../../types/analytics.dto";

const Component = ({
  title,
  isLoading,
  analytics,
}: {
  title: string;
  isLoading: boolean;
  analytics: SuscriptionAnalyticsDto;
}) => {
  const [spreadTime, setSpreadTime] = useState<string | null>(null);

  return (
    <FlexContainer direction="column" gap="16px">
      <FlexContainer align="center" gap="4px">
        <SuscriptionText size={14}>{title}</SuscriptionText>
        <Select
          onChange={(ev) => {
            setSpreadTime(
              ev.target.value.length === 0 ? null : ev.target.value
            );
          }}
          disabled={isLoading}
        >
          <option value={""}>---</option>
          <option value={"total"}>Historico</option>
          <option value={"lastWeek"}>Ultima Semana</option>
          <option value={"lastMonth"}>Ultimo Mes</option>
          <option value={"lastYear"}>Ultima Año</option>
        </Select>
      </FlexContainer>
      <GraphWrapper>
        {isLoading || (!analytics && spreadTime) ? (
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader color="Primary"></Loader>
          </div>
        ) : !analytics || !spreadTime ? (
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <SuscriptionText size={14}>Esperando seleccion</SuscriptionText>
          </div>
        ) : (
          <LineGraph
            options={{ scales: { x: { display: false } } }}
            title={title}
            analytics={Object.entries(analytics[spreadTime])
              .sort((a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0]))
              .map((growthData) => {
                return {
                  level: growthData[0],
                  note: formatNumber(growthData[1]),
                };
              })}
          ></LineGraph>
        )}
      </GraphWrapper>
    </FlexContainer>
  );
};

export default Component;
