import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import Button from "../../../../../../components/Button";
import Grid from "../../../../../../components/Grid";
import Loader from "../../../../../../components/Loader";
import {
  FlexContainer,
  PageTitle,
} from "../../../../../../components/StyledComponents";
import { DateEStoDateEN } from "../../../../../../helpers/format-date";
import {
  getAcademyStats,
  getAcademyUserTimeStats,
} from "../../../../../../store/actions/analytics";
import AnalyticCard from "../../../components/AnalyticCard";
import { PDFExport } from "@progress/kendo-react-pdf";
import { AcademyUserTimes } from "../../../../../../types/analytics.dto";
import { StateDto } from "../../../../../../types/states.dto";
import { CurrentDto } from "../../../../../../types/current.dto";
import NotData from "../../../components/NotData";
import LineGraphHorizontal from "../../../../../../components/LineGraphHorizontal";
import {
  formatNumber,
  overThousandFixed,
} from "../../../../../../helpers/number-fixed";

const Component = ({
  analyticsAcademyUserTimes,
  analyticsAcademyUserTimesStates,
  current,
}: {
  analyticsAcademyUserTimes: AcademyUserTimes;
  analyticsAcademyUserTimesStates: StateDto;
  current: CurrentDto;
}) => {
  const printRef: any = React.useRef();
  const dispatch = useDispatch();
  const handleExportWithComponent = () => {
    printRef.current.save();
  };
  useEffect(() => {
    dispatch(
      getAcademyUserTimeStats({ academyId: current.id, topContents: 10 })
    );
  }, []);
  if (!analyticsAcademyUserTimes) return <Loader color="Primary"></Loader>;

  return (
    <>
      <PageTitle>Dashboard</PageTitle>
      <Grid.Col offset={{ sm: 10 }} sm={12}>
        <Button
          onClick={() => {
            handleExportWithComponent();
          }}
          type="button"
          options={{
            type: "filled",
            skin: "primary",
            size: "md",
            marginBottom: "10px",
          }}
          style={{ marginLeft: "10px" }}
        >
          Descargar
        </Button>
      </Grid.Col>
      <PDFExport
        fileName={`Constana General ${new Date().valueOf()}`}
        ref={printRef}
      >
        <FlexContainer direction="column" gap="6px">
          <AnalyticCard
            title={
              "Horas totales de consumo de todos los contenidos de la academia."
            }
            graphTitle={
              "Horas totales de consumo de todos los contenidos de la academia- Crecimiento Histórico"
            }
            data={{
              stats: [
                {
                  label: "Últimos 30 días",
                  value: overThousandFixed(analyticsAcademyUserTimes.lastMonth),
                },
                {
                  label: "Últimos 7 días",
                  value: overThousandFixed(analyticsAcademyUserTimes.lastWeek),
                },
                {
                  label: "Últimas 24 horas",
                  value: overThousandFixed(analyticsAcademyUserTimes.lastDay),
                },
              ],
              graphData: Object.entries(analyticsAcademyUserTimes.growth)
                .sort((a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0]))
                .map((growthData) => {
                  return {
                    name: growthData[0],
                    value: formatNumber(growthData[1]),
                  };
                }),
            }}
          ></AnalyticCard>
          <AnalyticCard
            height="250px"
            title="Top 10 contenidos mas consumidos de la Acamedia"
          >
            <NotData data={analyticsAcademyUserTimes.topContents}>
              <LineGraphHorizontal
                data={analyticsAcademyUserTimes.topContents.total.map(
                  (contact) => {
                    return {
                      label: contact.name,
                      value: formatNumber(contact.total),
                    };
                  }
                )}
                title={""}
              ></LineGraphHorizontal>
            </NotData>
          </AnalyticCard>
          <AnalyticCard
            title={"Horas totales de consumo de contenido de videos."}
            graphTitle={
              "Horas totales de consumo de videos de la academia- Crecimiento Histórico"
            }
            data={{
              stats: [
                {
                  label: "Últimos 30 días",
                  value: overThousandFixed(
                    analyticsAcademyUserTimes.videos.lastMonth
                  ),
                },
                {
                  label: "Últimos 7 días",
                  value: overThousandFixed(
                    analyticsAcademyUserTimes.videos.lastWeek
                  ),
                },
                {
                  label: "Últimas 24 horas",
                  value: overThousandFixed(
                    analyticsAcademyUserTimes.videos.lastDay
                  ),
                },
              ],
              graphData: Object.entries(analyticsAcademyUserTimes.videos.growth)
                .sort((a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0]))
                .map((growthData) => {
                  return {
                    name: growthData[0],
                    value: formatNumber(growthData[1]),
                  };
                }),
            }}
          ></AnalyticCard>
          <AnalyticCard
            title={"Horas totales de consumo de contenido de Slideshows."}
            graphTitle={
              "Horas totales de consumo de contenido de Slideshows.- Crecimiento Histórico"
            }
            data={{
              stats: [
                {
                  label: "Últimos 30 días",
                  value: overThousandFixed(
                    analyticsAcademyUserTimes.slideshows.lastMonth
                  ),
                },
                {
                  label: "Últimos 7 días",
                  value: overThousandFixed(
                    analyticsAcademyUserTimes.slideshows.lastWeek
                  ),
                },
                {
                  label: "Últimas 24 horas",
                  value: overThousandFixed(
                    analyticsAcademyUserTimes.slideshows.lastDay
                  ),
                },
              ],
              graphData: Object.entries(
                analyticsAcademyUserTimes.slideshows.growth
              )
                .sort((a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0]))
                .map((growthData) => {
                  return {
                    name: growthData[0],
                    value: formatNumber(growthData[1]),
                  };
                }),
            }}
          ></AnalyticCard>
          <AnalyticCard
            title={"Horas totales de consumo de contenido de bytes."}
            graphTitle={
              "Horas totales de consumo de contenido de bytes.- Crecimiento Histórico"
            }
            data={{
              stats: [
                {
                  label: "Últimos 30 días",
                  value: overThousandFixed(
                    analyticsAcademyUserTimes.bytes.lastMonth
                  ),
                },
                {
                  label: "Últimos 7 días",
                  value: overThousandFixed(
                    analyticsAcademyUserTimes.bytes.lastWeek
                  ),
                },
                {
                  label: "Últimas 24 horas",
                  value: overThousandFixed(
                    analyticsAcademyUserTimes.bytes.lastDay
                  ),
                },
              ],
              graphData: Object.entries(analyticsAcademyUserTimes.bytes.growth)
                .sort((a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0]))
                .map((growthData) => {
                  return {
                    name: growthData[0],
                    value: formatNumber(growthData[1]),
                  };
                }),
            }}
          ></AnalyticCard>
          <AnalyticCard
            title={"Horas totales de consumo de contenido de imágenes."}
            graphTitle={
              "Horas totales de consumo de contenido de imágenes.- Crecimiento Histórico"
            }
            data={{
              stats: [
                {
                  label: "Últimos 30 días",
                  value: overThousandFixed(
                    analyticsAcademyUserTimes.images.lastMonth
                  ),
                },
                {
                  label: "Últimos 7 días",
                  value: overThousandFixed(
                    analyticsAcademyUserTimes.images.lastWeek
                  ),
                },
                {
                  label: "Últimas 24 horas",
                  value: overThousandFixed(
                    analyticsAcademyUserTimes.images.lastDay
                  ),
                },
              ],
              graphData: Object.entries(analyticsAcademyUserTimes.images.growth)
                .sort((a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0]))
                .map((growthData) => {
                  return {
                    name: growthData[0],
                    value: formatNumber(growthData[1]),
                  };
                }),
            }}
          ></AnalyticCard>
          <AnalyticCard
            title={"Horas totales de consumo de contenido de cdocs."}
            graphTitle={
              "Horas totales de consumo de contenido de cdocs.- Crecimiento Histórico"
            }
            data={{
              stats: [
                {
                  label: "Últimos 30 días",
                  value: overThousandFixed(
                    analyticsAcademyUserTimes.cdocs.lastMonth
                  ),
                },
                {
                  label: "Últimos 7 días",
                  value: overThousandFixed(
                    analyticsAcademyUserTimes.cdocs.lastWeek
                  ),
                },
                {
                  label: "Últimas 24 horas",
                  value: overThousandFixed(
                    analyticsAcademyUserTimes.cdocs.lastDay
                  ),
                },
              ],
              graphData: Object.entries(analyticsAcademyUserTimes.cdocs.growth)
                .sort((a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0]))
                .map((growthData) => {
                  return {
                    name: growthData[0],
                    value: formatNumber(growthData[1]),
                  };
                }),
            }}
          ></AnalyticCard>
          <AnalyticCard
            title={"Horas totales de consumo de contenido de listens."}
            graphTitle={
              "Horas totales de consumo de contenido de listens.- Crecimiento Histórico"
            }
            data={{
              stats: [
                {
                  label: "Últimos 30 días",
                  value: overThousandFixed(
                    analyticsAcademyUserTimes.listens.lastMonth
                  ),
                },
                {
                  label: "Últimos 7 días",
                  value: overThousandFixed(
                    analyticsAcademyUserTimes.listens.lastWeek
                  ),
                },
                {
                  label: "Últimas 24 horas",
                  value: overThousandFixed(
                    analyticsAcademyUserTimes.listens.lastDay
                  ),
                },
              ],
              graphData: Object.entries(
                analyticsAcademyUserTimes.listens.growth
              )
                .sort((a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0]))
                .map((growthData) => {
                  return {
                    name: growthData[0],
                    value: formatNumber(growthData[1]),
                  };
                }),
            }}
          ></AnalyticCard>
          <AnalyticCard
            title={"Horas totales de consumo de contenido de trayectos."}
            graphTitle={
              "Horas totales de consumo de contenido de trayectos.- Crecimiento Histórico"
            }
            data={{
              stats: [
                {
                  label: "Últimos 30 días",
                  value: overThousandFixed(
                    analyticsAcademyUserTimes.roadmaps.lastMonth
                  ),
                },
                {
                  label: "Últimos 7 días",
                  value: overThousandFixed(
                    analyticsAcademyUserTimes.roadmaps.lastWeek
                  ),
                },
                {
                  label: "Últimas 24 horas",
                  value: overThousandFixed(
                    analyticsAcademyUserTimes.roadmaps.lastDay
                  ),
                },
              ],
              graphData: Object.entries(
                analyticsAcademyUserTimes.roadmaps.growth
              )
                .sort((a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0]))
                .map((growthData) => {
                  return {
                    name: growthData[0],
                    value: formatNumber(growthData[1]),
                  };
                }),
            }}
          ></AnalyticCard>
        </FlexContainer>
      </PDFExport>
    </>
  );
};

const states = ({ analyticsStore, currentStore }) => {
  const {
    data: analyticsAcademyUserTimes,
    states: analyticsAcademyUserTimesStates,
  } = analyticsStore.academyUserTime;
  const { data: current } = currentStore;
  return {
    analyticsAcademyUserTimes,
    analyticsAcademyUserTimesStates,
    current,
  };
};

export default connect(states)(Component);
