import {
  initialState,
  Actions,
  State,
  GET_ALL_CERTIFICATES,
  GET_ALL_CERTIFICATES_ERROR,
  GET_ALL_CERTIFICATES_SUCCESS,
  GET_CERTIFICATE,
  GET_CERTIFICATE_ERROR,
  GET_CERTIFICATE_SUCCESS,
  NEW_CERTIFICATE,
  NEW_CERTIFICATE_ERROR,
  NEW_CERTIFICATE_SUCCESS,
  RESET_NEW_CERTIFICATE,
  UPDATE_CERTIFICATE,
  UPDATE_CERTIFICATE_ERROR,
  UPDATE_CERTIFICATE_SUCCESS,
  RESET_UPDATE_CERTIFICATE,
  DELETE_CERTIFICATE,
  DELETE_CERTIFICATE_ERROR,
  DELETE_CERTIFICATE_SUCCESS,
  RESET_DELETE_CERTIFICATE,
  DELETE_CERTIFICATE_MEMBER,
  DELETE_CERTIFICATE_MEMBER_ERROR,
  DELETE_CERTIFICATE_MEMBER_SUCCESS,
} from "../types/certificate";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_CERTIFICATES:
      return {
        ...state,
        allCertificates: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_CERTIFICATES_SUCCESS:
      return {
        ...state,
        allCertificates: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_CERTIFICATES_ERROR:
      return {
        ...state,
        allCertificates: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case DELETE_CERTIFICATE_MEMBER:
      return {
        ...state,
        deleteCertificate: {
          states: {
            loading: true,
            success: false,
            error: false,
          },
        },
      };
    case DELETE_CERTIFICATE_MEMBER_SUCCESS:
      return {
        ...state,
        deleteCertificate: {
          states: {
            loading: false,
            success: true,
            error: false,
          },
        },
      };
    case DELETE_CERTIFICATE_MEMBER_ERROR:
      return {
        ...state,
        deleteCertificate: {
          states: {
            loading: false,
            success: false,
            error: true,
          },
        },
      };
    case GET_CERTIFICATE:
      return {
        ...state,
        certificate: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };

    case GET_CERTIFICATE_SUCCESS:
      return {
        ...state,
        certificate: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_CERTIFICATE_ERROR:
      return {
        ...state,
        certificate: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case NEW_CERTIFICATE:
      return {
        ...state,
        newCertificate: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_CERTIFICATE_SUCCESS:
      return {
        ...state,
        newCertificate: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case NEW_CERTIFICATE_ERROR:
      return {
        ...state,
        newCertificate: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_CERTIFICATE:
      return {
        ...state,
        newCertificate: initialState.newCertificate,
      };
    case DELETE_CERTIFICATE:
      return {
        ...state,
        deleteCertificate: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_CERTIFICATE_SUCCESS:
      return {
        ...state,
        deleteCertificate: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_CERTIFICATE_ERROR:
      return {
        ...state,
        deleteCertificate: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_CERTIFICATE:
      return {
        ...state,
        deleteCertificate: initialState.deleteCertificate,
      };
    case UPDATE_CERTIFICATE:
      return {
        ...state,
        updateCertificate: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_CERTIFICATE_SUCCESS:
      return {
        ...state,
        updateCertificate: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_CERTIFICATE_ERROR:
      return {
        ...state,
        updateCertificate: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_CERTIFICATE:
      return {
        ...state,
        updateCertificate: initialState.updateCertificate,
      };
    default:
      return state;
  }
};

export default reducers;
