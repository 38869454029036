import {
  GetAllSlideShows,
  GET_ALL_SLIDESHOWS,
  GetSlideShow,
  GET_SLIDESHOW,
  UpdateSlideShow,
  UPDATE_SLIDESHOW,
  DeleteSlideShow,
  DELETE_SLIDESHOW,
  GetSlideShowSuccess,
  GET_SLIDESHOW_SUCCESS,
  NewSlideShow,
  NEW_SLIDESHOW,
  ResetNewSlideShow,
  RESET_NEW_SLIDESHOW,
  ResetUpdateSlideShow,
  RESET_UPDATE_SLIDESHOW,
  ResetDeleteSlideShow,
  RESET_DELETE_SLIDESHOW,
  UploadMedia,
  UPLOAD_MEDIA,
  RESET_GET_SLIDESHOW,
  ADD_USER_TO_SLIDESHOW,
  SHARED_SLIDESHOW,
  UPDATE_MANY_SLIDESHOW,
  RESET_UPDATE_MANY_SLIDESHOW,
  ResetGetAllSlideShowData,
  RESET_GET_ALL_SLIDESHOW_DATA,
} from "../types/slideshow";

export const getAllSlideShows = (payload): GetAllSlideShows => {
  return { type: GET_ALL_SLIDESHOWS, payload };
};

export const resetGetAllSlideShows = (): ResetGetAllSlideShowData => {
  return { type: RESET_GET_ALL_SLIDESHOW_DATA, payload: null };
};

export const getSlideShow = (id): GetSlideShow => {
  return { type: GET_SLIDESHOW, payload: id };
};

export const resetGetSlideShow = () => {
  return { type: RESET_GET_SLIDESHOW, payload: null };
};

export const setSelectedSlideShow = (payload): GetSlideShowSuccess => {
  return { type: GET_SLIDESHOW_SUCCESS, payload };
};

export const updateManySlideshow = (payload) => {
  return { type: UPDATE_MANY_SLIDESHOW, payload };
};

export const resetUpdateManySlideshow = () => {
  return { type: RESET_UPDATE_MANY_SLIDESHOW, payload: null };
};

export const newSlideShow = (payload): NewSlideShow => {
  return { type: NEW_SLIDESHOW, payload };
};
export const uploadMediaSlideShow = (payload): UploadMedia => {
  return { type: UPLOAD_MEDIA, payload };
};
export const resetNewSlideShow = (): ResetNewSlideShow => {
  return { type: RESET_NEW_SLIDESHOW, payload: null };
};

export const updateSlideShow = (payload): UpdateSlideShow => {
  return { type: UPDATE_SLIDESHOW, payload };
};

export const resetUpdateSlideShow = (): ResetUpdateSlideShow => {
  return { type: RESET_UPDATE_SLIDESHOW, payload: null };
};

export const addUserToSlideShow = (payload) => {
  return { type: ADD_USER_TO_SLIDESHOW, payload };
};

export const deleteSlideShow = (payload): DeleteSlideShow => {
  return { type: DELETE_SLIDESHOW, payload };
};

export const resetDeleteSlideShow = (): ResetDeleteSlideShow => {
  return { type: RESET_DELETE_SLIDESHOW, payload: null };
};

export const shareSlideShow = (payload) => {
  return { type: SHARED_SLIDESHOW, payload };
};
