import Grid from "../../../../../../components/Grid";
import {
  CriterionCard,
  CriterionNoGap,
  ListCriterions,
} from "../../../../../../components/LineGraph/styles";
import {
  TitleCriterion,
  TitleStage,
} from "../../../IndividualPerformance/components/NumericStats/styles";
import { BigGrade } from "../../../IndividualPerformance/components/SprintStats/styles";
import { connect } from "react-redux";
import { AnalyticsCohortDto } from "../../../../../../types/analytics.dto";
import { arrayLengthDivision } from "../../../../../../helpers/notLengthDivision";
import { LargeTitle, ListCriterion, ListCriterionItem } from "./styles";
import ShortString from "../../../../../../components/ShortString";
import { FlexContainer } from "../../../../../../components/StyledComponents";
import { roundedNumbers } from "../../../../../../helpers/rounded-numbers";
import { WrapperList } from "../../styles";
interface PropsComponent {
  selectedCohort?: {
    name: string;
    id: string;
    cohortStudents?: number;
  };
  analyticsStates: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
  analytics: AnalyticsCohortDto;
}

const Component = ({
  selectedCohort,
  analyticsStates,
  analytics,
}: PropsComponent) => {
  const globalProm =
    analytics.skills.reduce((prev, current) => prev + current.note, 0) /
    arrayLengthDivision(analytics.skills);
  const bestCriterionList = analytics.skills
    .reduce((prev: any, current, index, array) => {
      if (prev.find((item) => item.criterion._id === current.skill._id))
        return prev;
      else {
        const promCurrentCriterion = array
          .filter((item) => item.skill._id === current.skill._id)
          .map((item) => item.note);
        return [
          ...prev,
          {
            criterion: current.skill,
            average:
              promCurrentCriterion.reduce(
                (prev, current) => current + prev,
                0
              ) / arrayLengthDivision(promCurrentCriterion),
          },
        ];
      }
    }, [])
    .sort((a, b) => b.average - a.average);

  return (
    <>
      <Grid.Col style={{ flexDirection: "column", gap: "20px" }}>
        <Grid.Row>
          <Grid.Col sm={4}>
            <CriterionNoGap>
              <TitleCriterion>Nombre del Cohort:</TitleCriterion>
              <LargeTitle title={selectedCohort?.name}>
                {ShortString(selectedCohort?.name, 9)}
              </LargeTitle>
            </CriterionNoGap>
          </Grid.Col>
          <Grid.Col sm={2}>
            <CriterionCard style={{ justifyContent: "stretch" }}>
              <TitleCriterion>Promedio Global</TitleCriterion>
              <BigGrade>{roundedNumbers(globalProm)}</BigGrade>
            </CriterionCard>
          </Grid.Col>
          {bestCriterionList[0] && (
            <Grid.Col sm={2}>
              <CriterionCard>
                <TitleCriterion>Criterio con mejor promedio</TitleCriterion>
                <BigGrade>
                  {roundedNumbers(bestCriterionList[0].average)}
                </BigGrade>
                <TitleCriterion style={{ textAlign: "center" }}>
                  {bestCriterionList[0].criterion.name}
                </TitleCriterion>
              </CriterionCard>
            </Grid.Col>
          )}
          {bestCriterionList[1] && (
            <Grid.Col sm={2}>
              <CriterionCard>
                <TitleCriterion>2do criterio con mejor promedio</TitleCriterion>
                <BigGrade>
                  {roundedNumbers(bestCriterionList[1].average)}
                </BigGrade>
                <TitleCriterion style={{ textAlign: "center" }}>
                  {bestCriterionList[1].criterion.name}
                </TitleCriterion>
              </CriterionCard>
            </Grid.Col>
          )}
          {bestCriterionList[2] && (
            <Grid.Col sm={2}>
              <CriterionCard>
                <TitleCriterion>3er criterio con mejor promedio</TitleCriterion>
                <BigGrade>
                  {roundedNumbers(bestCriterionList[2].average)}
                </BigGrade>
                <TitleCriterion style={{ textAlign: "center" }}>
                  {bestCriterionList[2].criterion.name}
                </TitleCriterion>
              </CriterionCard>
            </Grid.Col>
          )}
        </Grid.Row>
        <Grid.Row style={{ marginTop: "30px" }}>
          <Grid.Col sm={5.3} style={{ justifyContent: "center" }}>
            <WrapperList>
              <TitleStage>Listado de criterios</TitleStage>
              <ListCriterion>
                {Array.from(
                  new Set(analytics.skills.map((skill) => skill.skill.name))
                ).map((criterion) => (
                  <ListCriterionItem title={criterion}>
                    {criterion}
                  </ListCriterionItem>
                ))}
              </ListCriterion>
            </WrapperList>
          </Grid.Col>
          <Grid.Col offset={{ sm: 1.4 }} sm={5.3}>
            <WrapperList>
              <TitleStage>Listado de Etapas con su promedio global</TitleStage>
              <ListCriterion>
                {analytics.stagesNotes.map((criterion) => (
                  <FlexContainer align="center" wrap="wrap" gap="10px">
                    <ListCriterionItem
                      style={{ minWidth: "150px" }}
                      title={criterion}
                    >
                      {`Etapa ${criterion.stage}`}
                    </ListCriterionItem>
                    <ListCriterionItem
                      style={{ minWidth: "50px", textAlign: "center" }}
                      title={criterion.avg}
                    >
                      {roundedNumbers(criterion.avg)}
                    </ListCriterionItem>
                  </FlexContainer>
                ))}
              </ListCriterion>
            </WrapperList>
          </Grid.Col>
        </Grid.Row>
      </Grid.Col>
    </>
  );
};

const states = ({ analyticsStore }) => {
  const { states: analyticsStates, data: analytics } =
    analyticsStore.getCohortStats;
  return {
    analyticsStates,
  };
};

export default connect(states)(Component);
