import { TechnologyDto } from "../../../types/recruiting.dto";

export const GET_ALL_TECHNOLOGIES = "GET_ALL_TECHNOLOGIES";
export const GET_ALL_TECHNOLOGIES_ERROR = "GET_ALL_TECHNOLOGIES_ERROR";
export const GET_ALL_TECHNOLOGIES_SUCCESS = "GET_ALL_TECHNOLOGIES_SUCCESS";
export const GET_TECHNOLOGY = "GET_TECHNOLOGY";
export const GET_TECHNOLOGY_ERROR = "GET_TECHNOLOGY_ERROR";
export const GET_TECHNOLOGY_SUCCESS = "GET_TECHNOLOGY_SUCCESS";
export const NEW_TECHNOLOGY = "NEW_TECHNOLOGY";
export const NEW_TECHNOLOGY_ERROR = "NEW_TECHNOLOGY_ERROR";
export const NEW_TECHNOLOGY_SUCCESS = "NEW_TECHNOLOGY_SUCCESS";
export const RESET_NEW_TECHNOLOGY = "RESET_NEW_TECHNOLOGY";
export const UPDATE_TECHNOLOGY = "UPDATE_TECHNOLOGY";
export const UPDATE_TECHNOLOGY_ERROR = "UPDATE_TECHNOLOGY_ERROR";
export const UPDATE_TECHNOLOGY_SUCCESS = "UPDATE_TECHNOLOGY_SUCCESS";
export const RESET_UPDATE_TECHNOLOGY = "RESET_UPDATE_TECHNOLOGY";
export const DELETE_TECHNOLOGY = "DELETE_TECHNOLOGY";
export const DELETE_TECHNOLOGY_ERROR = "DELETE_TECHNOLOGY_ERROR";
export const DELETE_TECHNOLOGY_SUCCESS = "DELETE_TECHNOLOGY_SUCCESS";
export const RESET_DELETE_TECHNOLOGY = "RESET_DELETE_TECHNOLOGY";
export const SET_NEW_TECHNOLOGY_DATA = "SET_NEW_TECHNOLOGY_DATA";
export const SET_UPDATE_TECHNOLOGY_DATA = "SET_UPDATE_TECHNOLOGY_DATA";
export const UPDATE_CURRENT_TECHNOLOGY_LIST = "UPDATE_CURRENT_TECHNOLOGY_LIST";

export interface GetAllTechnologies {
  type: typeof GET_ALL_TECHNOLOGIES;
  payload: null;
}

export interface GetAllTechnologiesSuccess {
  type: typeof GET_ALL_TECHNOLOGIES_SUCCESS;
  payload: TechnologyDto[];
}

export interface GetAllTechnologiesError {
  type: typeof GET_ALL_TECHNOLOGIES_ERROR;
  payload: boolean | string;
}

export interface UpdateCurrentTechnologyList {
  type: typeof UPDATE_CURRENT_TECHNOLOGY_LIST;
  payload: TechnologyDto[];
}

export interface GetTechnology {
  type: typeof GET_TECHNOLOGY;
  payload: { _id: string };
}

export interface GetTechnologySuccess {
  type: typeof GET_TECHNOLOGY_SUCCESS;
  payload: TechnologyDto;
}

export interface GetTechnologyError {
  type: typeof GET_TECHNOLOGY_ERROR;
  payload: boolean | string;
}

export interface SetNewTechnologyData {
  type: typeof SET_NEW_TECHNOLOGY_DATA;
  payload: TechnologyDto;
}
export interface SetUpdateTechnologyData {
  type: typeof SET_UPDATE_TECHNOLOGY_DATA;
  payload: TechnologyDto;
}

export interface NewTechnology {
  type: typeof NEW_TECHNOLOGY;
  payload: TechnologyDto;
}

export interface NewTechnologySuccess {
  type: typeof NEW_TECHNOLOGY_SUCCESS;
  payload: TechnologyDto;
}

export interface NewTechnologyError {
  type: typeof NEW_TECHNOLOGY_ERROR;
  payload: boolean | string;
}

export interface ResetNewTechnology {
  type: typeof RESET_NEW_TECHNOLOGY;
  payload: null;
}

export interface UpdateTechnology {
  type: typeof UPDATE_TECHNOLOGY;
  payload: {
    name: string;
    description: string;
  };
}

export interface UpdateTechnologySuccess {
  type: typeof UPDATE_TECHNOLOGY_SUCCESS;
  payload: null;
}

export interface UpdateTechnologyError {
  type: typeof UPDATE_TECHNOLOGY_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateTechnology {
  type: typeof RESET_UPDATE_TECHNOLOGY;
  payload: null;
}

export interface DeleteTechnology {
  type: typeof DELETE_TECHNOLOGY;
  payload: { _id: string };
}

export interface DeleteTechnologySuccess {
  type: typeof DELETE_TECHNOLOGY_SUCCESS;
  payload: null;
}

export interface DeleteTechnologyError {
  type: typeof DELETE_TECHNOLOGY_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteTechnology {
  type: typeof RESET_DELETE_TECHNOLOGY;
  payload: null;
}

export interface State {
  all: {
    data: TechnologyDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  selected: {
    data: TechnologyDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  create: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  delete: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  update: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  all: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  selected: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  create: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  delete: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  update: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllTechnologies
  | GetAllTechnologiesSuccess
  | GetAllTechnologiesError
  | GetTechnology
  | GetTechnologySuccess
  | GetTechnologyError
  | NewTechnology
  | NewTechnologySuccess
  | NewTechnologyError
  | ResetNewTechnology
  | UpdateTechnology
  | UpdateTechnologySuccess
  | UpdateTechnologyError
  | ResetUpdateTechnology
  | DeleteTechnology
  | DeleteTechnologySuccess
  | DeleteTechnologyError
  | ResetDeleteTechnology
  | SetUpdateTechnologyData
  | SetNewTechnologyData
  | UpdateCurrentTechnologyList;
