const HIDDEN_TEXTAREA_STYLE = {
  'min-height': '0',
  'max-height': 'none',
  visibility: 'hidden',
  overflow: 'hidden',
  position: 'absolute',
  'z-index': '-1000',
  top: '0',
  right: '0'
}

const SIZING_STYLE = [
  'borderBottomWidth',
  'borderLeftWidth',
  'borderRightWidth',
  'borderTopWidth',
  'boxSizing',
  'fontFamily',
  'fontSize',
  'fontStyle',
  'fontWeight',
  'letterSpacing',
  'lineHeight',
  'paddingBottom',
  'paddingLeft',
  'paddingRight',
  'paddingTop',
  // non-standard
  'tabSize',
  'textIndent',
  // non-standard
  'textRendering',
  'textTransform',
  'width',
] as const;

type SizingStyle = Pick<CSSStyleDeclaration, SizingProps>;

interface SizingData {
  sizingStyle: SizingStyle;
  paddingSize: number;
  borderSize: number;
}

type SizingProps = Extract<
  typeof SIZING_STYLE[number],
  keyof CSSStyleDeclaration
>;

let hiddenTextarea: HTMLTextAreaElement | null = null;

/**
 * Get field height
 * @param node 
 * @param sizingData 
 */

const getContentHeight = (
  node: HTMLElement, 
  sizingData: SizingData
  ): number => {
    const height = node.scrollHeight;

    if (sizingData.sizingStyle.boxSizing === 'border-box') {
      // border-box: add border, since height = content + padding + border
      return height + sizingData.borderSize;
    }
  
    // remove padding, since height = content
    return height - sizingData.paddingSize;
};

/**
 * Set helper element
 * @param node 
 */

const forceHiddenStyles = (node: HTMLElement) => {
  Object.keys(HIDDEN_TEXTAREA_STYLE).forEach(key => {
    node.style.setProperty(
      key,
      HIDDEN_TEXTAREA_STYLE[key as keyof typeof HIDDEN_TEXTAREA_STYLE],
      'important',
    );
  });
};

export const pick = <Obj extends { [key: string]: any }, Key extends keyof Obj>(
  props: Key[],
  obj: Obj,
): Pick<Obj, Key> =>
  props.reduce((acc, prop) => {
    acc[prop] = obj[prop];
    return acc;
  }, {} as Pick<Obj, Key>);

/**
 * Get field sizes
 * @param node 
 */

const getSizingData = (node: HTMLElement) : SizingData =>{
  const style = window.getComputedStyle(node);

  const sizingStyle = pick((SIZING_STYLE as unknown) as SizingProps[], style);

  const paddingSize =
    parseFloat(sizingStyle.paddingBottom!) +
    parseFloat(sizingStyle.paddingTop!)

  const borderSize =
    parseFloat(sizingStyle.borderBottomWidth!) +
    parseFloat(sizingStyle.borderTopWidth!)

  return {
    sizingStyle,
    paddingSize,
    borderSize,
  }
}

/**
 * Return the height of the field
 * 
 * @param node 
 * @param value 
 * @param minRows 
 * @param maxRows 
 */

export default function calculateNodeHeight(
  node: HTMLElement,
  value: string,
  minRows = 1,
  maxRows = Infinity,
): number {
  const sizingData:SizingData = getSizingData( node )

  if (!hiddenTextarea) {
    hiddenTextarea = document.createElement('textarea');
    hiddenTextarea.setAttribute('tab-index', '-1');
    hiddenTextarea.setAttribute('aria-hidden', 'true');
  }

  if (hiddenTextarea.parentNode === null) {
    document.body.appendChild(hiddenTextarea);
  }

  const { paddingSize, borderSize, sizingStyle } = sizingData;
  const { boxSizing } = sizingStyle;

  Object.keys(sizingStyle).forEach(_key => {
    const key = _key as keyof typeof sizingStyle;
    hiddenTextarea!.style[key] = sizingStyle[key] as any;
  });
  
  forceHiddenStyles(hiddenTextarea);

  hiddenTextarea.value = value;
  let height = getContentHeight(hiddenTextarea, sizingData);

  // measure height of a textarea with a single row
  hiddenTextarea.value = 'x';
  const rowHeight = getContentHeight(hiddenTextarea, sizingData);
  
  let minHeight = rowHeight * minRows;
  if (boxSizing === 'border-box') {
    minHeight = minHeight + paddingSize + borderSize;
  }
  height = Math.max(minHeight, height);

  let maxHeight = rowHeight * maxRows;
  if (boxSizing === 'border-box') {
    maxHeight = maxHeight + paddingSize + borderSize;
  }
  height = Math.min(maxHeight, height);

  return height;
}
