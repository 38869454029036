import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Modal from "../../../../../../components/Modal";
import { Formik } from "formik";
import { Center } from "../../../../../../components/StyledComponents";
import {
  initialValues,
  schema,
} from "../../../../../../constants/form/invitation/cohort/observer";
import Grid from "../../../../../../components/Grid";
import Submit from "../../../../../../components/Form/Submit";
import { toast } from "react-toastify";
import Input from "../../../../../../components/Form/Input";
import invitationActions from "../../../../../../store/actions/invitation";
import { hideModal } from "../../../../../../store/actions/modal";
import ReactSelect from "../../../../../../components/Form/ReactSelect";
import { AcademyDto } from "../../../../../../types/academy.dto";
import { CurrentDto } from "../../../../../../types/current.dto";
import DinamicField from "../../../../../../components/Form/DinamicField";
import { CohortDto } from "../../../../../../types/cohort.dto";
import { UserDto } from "../../../../../../types/user.dto";
import { StateDto } from "../../../../../../types/states.dto";
import services from "../../../../../../services";

const Component = ({
  selectedAcademy,
  current,
  cohorts,
  user,
  newManyInvitationsStates,
}: {
  newManyInvitationsStates: StateDto;
  selectedAcademy: AcademyDto;
  current: CurrentDto;
  cohorts: CohortDto[];
  user: UserDto;
}) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const [currentCohorts, setCurrentCohorts] = useState<CohortDto[]>();
  const dispatch = useDispatch();
  const onSubmit = ({ values, actions }) => {
    const { cohorts, ...rest } = values;

    let items: any[] = [];
    if (cohorts.length > 0) {
      for (const cohort of cohorts) {
        items = [
          ...items,
          { cohort: cohort, sendBy: user._id, role: "OBSERVER", ...rest },
        ];
      }
      dispatch(invitationActions.newMany({ items: items }));
    }

    setFormActions(actions);
  };

  useEffect(() => {
    if (newManyInvitationsStates.success) {
      dispatch(invitationActions.resetNewMany());
      toast.success(`Creadas exitosamente`);
      dispatch(hideModal());
    }
    if (newManyInvitationsStates.error) {
      toast.error(newManyInvitationsStates.error);
      dispatch(invitationActions.resetNewMany());
    }
  }, [newManyInvitationsStates]);

  useEffect(() => {
    const fetchData = async () => {
      const response: any = await services.cohort.getAll({
        filterBy: { academy: current.id },
      });
      if (response.response) {
        setCurrentCohorts(response.response);
      }
    };
    fetchData();
  }, []);

  return (
    <Modal
      name="invite-cohort-modal-modal"
      title="Invitar a Cohort como Observer"
    >
      <Formik
        initialValues={{ ...initialValues() }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="invite-academy-modal-form"
            >
              <Grid.Row>
                <Grid.Col>
                  <Input
                    name={"email"}
                    error={errors["email"]}
                    touched={touched["email"]}
                    value={values["email"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "E-mail",
                      skin: "gray",
                      marginBottom: 20,
                    }}
                  />

                  <DinamicField
                    name="cohorts"
                    error={errors["cohorts"]}
                    touched={touched["cohorts"]}
                    value={values["cohorts"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      type: "select",
                      height: "95px",
                      ...(!!currentCohorts && {
                        data: currentCohorts
                          ?.filter(
                            (cohort) =>
                              cohort.active && cohort.academy._id === current.id
                          )
                          .map((event) => ({
                            label: event.name,
                            value: event._id,
                          })),
                      }),
                      skin: "gray",
                      loading: !currentCohorts,
                      inputLabel: "Seleccionar Cohorts",
                      inputPlaceholder: "Selecciona un cohort",
                    }}
                  />
                </Grid.Col>
              </Grid.Row>

              <Grid.Row>
                <Grid.Col>
                  <Center gap={"70px"}>
                    <Submit
                      isSubmmiting={newManyInvitationsStates.loading}
                      form="invite-academy-modal-form"
                      color="Primary"
                      options={{
                        type: "filled",
                        skin: "primary",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                    >
                      Confirmar
                    </Submit>
                  </Center>
                </Grid.Col>
              </Grid.Row>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const states = ({
  invitationStore,
  academyStore,
  currentStore,
  cohortStore,
  userStore,
}) => {
  const { data: selectedAcademy } = academyStore.selected;
  const { data: newManyInvitations, states: newManyInvitationsStates } =
    invitationStore.newMany;
  const { data: current } = currentStore;
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: user } = userStore;
  return {
    selectedAcademy,
    newManyInvitationsStates,
    current,
    cohorts,
    user,
  };
};

export default connect(states)(Component);
