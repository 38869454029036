import styled from "styled-components";
import { Animation } from "../Animation";

export const MobileContentModal = styled(Animation)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 7001;
`;

export const MobileContentModalBody = styled("div")`
  background-color: #fff;
  width: 100%;
  position: relative;
`;

export const MobileContentModalHeader = styled("div")`
  padding: 10px;
  border-bottom: 1px solid rgba(106, 105, 112, 0.25);
  margin-bottom: 10px;
`;

export const MobileContentModalHeaderTitle = styled("h2")`
  font-size: 16px;
  font-weight: bold;
  color: #051725;
`;

export const MobileContentModalContent = styled("div")`
  overflow: auto;
  height: 352px;
  padding: 0px 11px 2px 11px;
`;
