import { colors } from "../globals/colors";

export const form = {
  skin: {
    base: {
      idle: {
        color: colors.Gray,
        placeHolderColor: colors.Gray,
        backgroundColor: "#fff",
        borderColor: "#fff",
        iconColor: colors.Gray,
      },
      disabled: {
        color: colors.Gray,
        placeHolderColor: colors.Black,
        backgroundColor: "#c8c8c8",
        borderColor: "#c8c8c8",
        iconColor: colors.Gray,
      },
      active: {
        color: colors.Gray,
        placeHolderColor: colors.Primary,
        backgroundColor: "#fff",
        borderColor: "#1573e5",
        iconColor: colors.Primary,
      },
      hover: {
        color: colors.Gray,
        placeHolderColor: colors.Black,
        backgroundColor: "#fff",
        borderColor: "#c2bfbf",
        iconColor: colors.Gray,
      },
      focus: {
        color: colors.Gray,
        placeHolderColor: colors.Primary,
        backgroundColor: "#fff",
        borderColor: "#c2bfbf",
        iconColor: colors.Primary,
      },
      error: {
        color: colors.Danger,
        placeHolderColor: colors.Danger,
        backgroundColor: "#fff",
        borderColor: colors.Danger,
        iconColor: colors.Danger,
      },
    },
    blur: {
      idle: {
        color: "#fff",
        placeHolderColor: "#fff",
        backgroundColor: "rgba(255, 255, 255, 0.6)",
        borderColor: "#fff",
        iconColor: colors.Gray,
      },
      disabled: {
        color: colors.Gray,
        placeHolderColor: "#fff",
        backgroundColor: "#c8c8c8",
        borderColor: "#c8c8c8",
        iconColor: colors.Gray,
      },
      active: {
        color: "#fff",
        placeHolderColor: "#fff",
        backgroundColor: "rgba(255, 255, 255, 0.6)",
        borderColor: "#fff",
        iconColor: colors.Primary,
      },
      hover: {
        color: "#fff",
        placeHolderColor: "#fff",
        backgroundColor: "#fff",
        borderColor: "#c2bfbf",
        iconColor: colors.Gray,
      },
      focus: {
        color: "#fff",
        placeHolderColor: "#fff",
        backgroundColor: "#fff",
        borderColor: "#c2bfbf",
        iconColor: colors.Primary,
      },
      error: {
        color: colors.Danger,
        placeHolderColor: colors.Danger,
        backgroundColor: "#fff",
        borderColor: colors.Danger,
        iconColor: colors.Danger,
      },
    },
    gray: {
      idle: {
        color: "#697482",
        placeHolderColor: colors.Gray,
        backgroundColor: "#f5f5f5",
        borderColor: "#f5f5f5",
        iconColor: colors.Gray,
      },
      disabled: {
        color: "#697482",
        placeHolderColor: colors.Black,
        backgroundColor: "#c8c8c8",
        borderColor: "#c8c8c8",
        iconColor: colors.Gray,
      },
      active: {
        color: "#697482",
        placeHolderColor: colors.Primary,
        backgroundColor: "#f5f5f5",
        borderColor: colors.Primary,
        iconColor: colors.Primary,
      },
      hover: {
        color: "#697482",
        placeHolderColor: colors.Black,
        backgroundColor: "#f5f5f5",
        borderColor: "#c2bfbf",
        iconColor: colors.Gray,
      },
      focus: {
        color: "#697482",
        placeHolderColor: colors.Primary,
        backgroundColor: "#f5f5f5",
        borderColor: "#c2bfbf",
        iconColor: colors.Primary,
      },
      error: {
        color: colors.Danger,
        placeHolderColor: colors.Danger,
        backgroundColor: "#f5f5f5",
        borderColor: colors.Danger,
        iconColor: colors.Danger,
      },
    },
  },

  size: {
    md: {
      fontSize: "16px",
      lineHeight: "19px",
      mobileFontSize: "14px",
      mobileLineHeight: "17px",
      height: "40px",
      padding: "20px",
    },
    sm: {
      fontSize: "14px",
      lineHeight: "normal",
      mobileFontSize: "14px",
      mobileLineHeight: "17px",
      height: "40px",
      padding: "20px",
    },
  },
};
