import { PlanDto } from "../../types/plan.dto";

export const GET_ALL_PLANS = "GET_ALL_PLANS";
export const GET_ALL_PLANS_ERROR = "GET_ALL_PLANS_ERROR";
export const GET_ALL_PLANS_SUCCESS = "GET_ALL_PLANS_SUCCESS";
export const RESET_GET_ALL_PLANS = "RESET_GET_ALL_PLANS";
export const GET_PLAN = "GET_PLAN";
export const GET_PLAN_ERROR = "GET_PLAN_ERROR";
export const GET_PLAN_SUCCESS = "GET_PLAN_SUCCESS";
export const NEW_PLAN = "NEW_PLAN";
export const NEW_PLAN_ERROR = "NEW_PLAN_ERROR";
export const NEW_PLAN_SUCCESS = "NEW_PLAN_SUCCESS";
export const RESET_NEW_PLAN = "RESET_NEW_PLAN";
export const UPDATE_PLAN = "UPDATE_PLAN";
export const UPDATE_PLAN_ERROR = "UPDATE_PLAN_ERROR";
export const UPDATE_PLAN_SUCCESS = "UPDATE_PLAN_SUCCESS";
export const RESET_UPDATE_PLAN = "RESET_UPDATE_PLAN";
export const DELETE_PLAN = "DELETE_PLAN";
export const DELETE_PLAN_ERROR = "DELETE_PLAN_ERROR";
export const DELETE_PLAN_SUCCESS = "DELETE_PLAN_SUCCESS";
export const RESET_DELETE_PLAN = "RESET_DELETE_PLAN";

export interface GetAllPlans {
  type: typeof GET_ALL_PLANS;
  payload: null;
}

export interface GetAllPlansSuccess {
  type: typeof GET_ALL_PLANS_SUCCESS;
  payload: PlanDto[];
}

export interface GetAllPlansError {
  type: typeof GET_ALL_PLANS_ERROR;
  payload: boolean | string;
}

export interface ResetGetAllPlans {
  type: typeof RESET_GET_ALL_PLANS;
  payload: null;
}

export interface GetPlan {
  type: typeof GET_PLAN;
  payload: { id: string };
}

export interface GetPlanSuccess {
  type: typeof GET_PLAN_SUCCESS;
  payload: PlanDto;
}

export interface GetPlanError {
  type: typeof GET_PLAN_ERROR;
  payload: boolean | string;
}

export interface NewPlan {
  type: typeof NEW_PLAN;
  payload: PlanDto;
}

export interface NewPlanSuccess {
  type: typeof NEW_PLAN_SUCCESS;
  payload: PlanDto;
}

export interface NewPlanError {
  type: typeof NEW_PLAN_ERROR;
  payload: boolean | string;
}

export interface ResetNewPlan {
  type: typeof RESET_NEW_PLAN;
  payload: null;
}

export interface UpdatePlan {
  type: typeof UPDATE_PLAN;
  payload: PlanDto;
}

export interface UpdatePlanSuccess {
  type: typeof UPDATE_PLAN_SUCCESS;
  payload: PlanDto;
}

export interface UpdatePlanError {
  type: typeof UPDATE_PLAN_ERROR;
  payload: boolean | string;
}

export interface ResetUpdatePlan {
  type: typeof RESET_UPDATE_PLAN;
  payload: null;
}

export interface DeletePlan {
  type: typeof DELETE_PLAN;
  payload: { id: string };
}

export interface DeletePlanSuccess {
  type: typeof DELETE_PLAN_SUCCESS;
  payload: null;
}

export interface DeletePlanError {
  type: typeof DELETE_PLAN_ERROR;
  payload: boolean | string;
}

export interface ResetDeletePlan {
  type: typeof RESET_DELETE_PLAN;
  payload: null;
}

export interface State {
  all: {
    data: PlanDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  plan: {
    data: PlanDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  new: {
    data: null | PlanDto;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  delete: {
    data: null | PlanDto;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  update: {
    data: null | PlanDto;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  all: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  plan: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  new: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  delete: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  update: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllPlans
  | GetAllPlansSuccess
  | GetAllPlansError
  | ResetGetAllPlans
  | GetPlan
  | GetPlanSuccess
  | GetPlanError
  | NewPlan
  | NewPlanSuccess
  | NewPlanError
  | ResetNewPlan
  | UpdatePlan
  | UpdatePlanSuccess
  | UpdatePlanError
  | ResetUpdatePlan
  | DeletePlan
  | DeletePlanSuccess
  | DeletePlanError
  | ResetDeletePlan;
