import React, { useEffect, useState } from "react";
import { MdFlashlightOn } from "react-icons/md";
import { connect, useDispatch } from "react-redux";
import Button from "../../../../components/Button";
import Grid from "../../../../components/Grid";
import {
  GridCard,
  GridCardImage,
  GridCardImageContainer,
  GridCardTitle,
  GridComment,
  GridStructure,
} from "../../../../components/Layout/Dashboard/styles";
import Loader from "../../../../components/Loader";
import {
  ButtonFilter,
  Center,
  FlexContainer,
  PageTitle,
} from "../../../../components/StyledComponents";
import { DateFromNow } from "../../../../helpers/format-date";
import { showModal } from "../../../../store/actions/modal";
import { CohortDto } from "../../../../types/cohort.dto";
import { CurrentDto } from "../../../../types/current.dto";
import { StateDto } from "../../../../types/states.dto";
import { ByteDto } from "../../../../types/byte.dto";
import FilterButton from "./components/FilterButton";
import FilterByName from "./components/FilterByName";
import FilterModal from "./components/FilterModal";
import { DotContent, ItemDataText } from "./style";
import { UserDto } from "../../../../types/user.dto";
import { useNavigate } from "react-router-dom";
import CenterTitle from "../../../../components/CenterTitle";
import {
  getAllBytes,
  resetGetAllBytes,
  resetUpdateManyByte,
  updateManyByte,
} from "../../../../store/actions/byte";
import { BiWorld } from "react-icons/bi";
import StateContentModal from "../../../../components/StateContentModal";
import { FetchData } from "../../../../types/fetchData.dto";
import InfinityScroll from "../../../../components/InfinityScroll";

const filterValues: any = [
  {
    fieldName: ["visibility", "order", "cohorts"],
    value: "no",
    name: "Todos",
  },
  {
    fieldName: "order",
    value: "recent",
    name: "Recientes",
    groupal: (array: ByteDto[]) =>
      array.sort(
        (a, b) =>
          new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
      ),
  },
  {
    fieldName: "order",
    value: "older",
    name: "Antiguos",
    groupal: (array: ByteDto[]) =>
      array.sort(
        (a, b) =>
          new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf()
      ),
  },
  {
    fieldName: "published",
    value: true,
    name: "Publicos",
  },
  {
    fieldName: "published",
    value: false,
    name: "Privados",
  },
];

const Component = ({
  current,
  bytes,
  newByteData,
  newByteDataStates,
  cohorts,
  user,
  byte,
  updateManyByteStates,
  bytesStates,
  updateManyBytes,
}: {
  current: CurrentDto;
  bytes: ByteDto[];
  newByteData: ByteDto;
  newByteDataStates: StateDto;
  user: UserDto;
  cohorts: CohortDto[];
  byte?: boolean;
  updateManyByteStates: StateDto;
  bytesStates: StateDto;
  updateManyBytes: ByteDto[];
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [applyedFilters, setFilters] = useState<
    {
      field: string;
      value: string | boolean;
      special: (data?: any, data2?: any) => boolean;
      groupal: (...args: any) => any[];
    }[]
  >([]);
  const [currentBytesList, setCurrentBytesList] = useState<ByteDto[]>([]);
  const [filteredCurrentBytesListValues, setFilteredCurrentBytesListValues] =
    useState<ByteDto[]>([]);
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);
  const [paginationOn, setPaginationOn] = useState<boolean>(false);
  const buildFilters = (newFilter) => {
    const filterUpdated =
      applyedFilters.filter((applyedFilter) =>
        !Array.isArray(newFilter.field)
          ? applyedFilter.field === newFilter.field
          : newFilter.field.includes(applyedFilter.field)
      ).length > 0;

    const filterRemoved = newFilter.value === "no";

    if (filterRemoved) {
      /**
       * Se elimina el filtro
       */
      if (Array.isArray(newFilter.field)) {
        setFilters([
          ...applyedFilters.filter(
            (applyedFilter) => !newFilter.field.includes(applyedFilter.field)
          ),
        ]);
      } else {
        setFilters([
          ...applyedFilters.filter(
            (applyedFilter) => applyedFilter.field !== newFilter.field
          ),
        ]);
      }
    }

    /**
     * Se actualiza un filtro
     */
    if (filterUpdated && !filterRemoved) {
      setFilters([
        ...applyedFilters.map((applyedFilter) => {
          if (applyedFilter.field === newFilter.field) {
            return newFilter;
          } else {
            return applyedFilter;
          }
        }),
      ]);
    }

    /**
     * Se agrega un filtro
     */
    if (!filterUpdated && !filterRemoved) {
      setFilters([...applyedFilters, newFilter]);
    }
  };

  const fetchData = ({ page, filterBy }: FetchData) => {
    if (page === 0) {
      setPaginationOn(true);
    }
    let payload = {
      limit: 9,
      offset: 9 * page,
      filterBy: {
        ...(filterBy ? filterBy : {}),
        url: { $exists: true },
        active: true,
        academy: current.id,
        ...(current.role !== "ADMIN" && {
          users: {
            $elemMatch: {
              id: user._id,
              roleEnd: { $exists: false },
            },
          },
        }),
      },
    };

    const select = {
      _id: 1,
      name: 1,
      picture: 1,
      __v: 1,
      academy: 1,
      updatedAt: 1,
      viewCount: 1,
      createdAt: 1,
      users: 1,
    };

    const sortBy = "createdAt";

    const sortDirection = "desc";
    dispatch(getAllBytes({ ...payload, sortBy, sortDirection, select }));
  };
  useEffect(() => {
    if (!!paginationOn) {
      if (!isLoading && !!bytes) {
        setCurrentBytesList((state) => [...state, ...bytes]);
      }
      setThereAreItems(!!bytes && bytes.length > 0);
    }
  }, [bytes, isLoading, paginationOn]);

  useEffect(() => {
    setIsLoading(bytesStates.loading);
  }, [bytesStates.loading]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllBytes());
    };
  }, []);

  useEffect(() => {
    if (!!updateManyBytes && !!updateManyByteStates.success && !!paginationOn) {
      setCurrentBytesList(updateManyBytes);
    }
  }, [updateManyByteStates, updateManyBytes, paginationOn]);

  useEffect(() => {
    const filteredTalents = currentBytesList?.filter((currentTalentValues) => {
      const thereAreFilters =
        applyedFilters.filter((applyedFilter) => !applyedFilter.groupal)
          .length > 0;
      const totalOfFilters = applyedFilters.filter(
        (applyedFilter) => !applyedFilter.groupal
      ).length;
      const passFilters = applyedFilters
        .filter(
          (applyedFilter) => !applyedFilter.special || !applyedFilter.groupal
        )
        .filter(
          (applyedFilter) =>
            currentTalentValues[applyedFilter.field] === applyedFilter.value
        ).length;
      const specialFilter = applyedFilters.filter(
        (applyedFilter) => !!applyedFilter.special
      );

      const speacialFilterCheck = specialFilter.filter((applyedFilter) => {
        return applyedFilter.special(currentTalentValues, applyedFilter.value);
      });
      return (
        !thereAreFilters ||
        (thereAreFilters &&
          totalOfFilters === passFilters + specialFilter.length &&
          speacialFilterCheck.length === specialFilter.length)
      );
    });
    let initResult = filteredTalents;
    applyedFilters
      .filter((applyedFilter) => !!applyedFilter.groupal)
      .forEach((applyedFilter) => {
        initResult = applyedFilter.groupal(initResult);
      });
    setFilteredCurrentBytesListValues(initResult);
  }, [applyedFilters, currentBytesList]);

  useEffect(() => {
    if (updateManyByteStates.success) {
      dispatch(resetUpdateManyByte());
    }
    if (updateManyByteStates.error) {
      dispatch(resetUpdateManyByte());
    }
  }, [updateManyByteStates, dispatch]);
  return (
    <>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col>
            <PageTitle>Bytes</PageTitle>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col
            offset={{ lg: 10, sm: 10, md: 10 }}
            lg={2}
            md={2}
            sm={2}
            style={{ marginBottom: "14px" }}
          >
            <Button
              onClick={() => {
                navigate(`management`);
              }}
              type="button"
              options={{
                type: "filled",
                skin: "primary",
                size: "md",
                marginBottom: "0px",
              }}
              style={{
                width: "100px",
                height: "50px",
              }}
            >
              Crear
            </Button>
          </Grid.Col>
        </Grid.Row>
        <Center>
          <Grid.Row style={{ width: "100%" }}>
            <Grid.Col lg={12} md={12} sm={12} style={{ marginBottom: "20px" }}>
              <FlexContainer
                style={{
                  width: "100%",
                  maxWidth: "700px",
                  margin: "0 auto",
                }}
                justify="space-between"
                align="center"
                gap="14px"
              >
                <FilterByName setFilter={buildFilters}></FilterByName>
                <ButtonFilter
                  onClick={() => {
                    dispatch(showModal("filter-cohort-modal"));
                  }}
                >
                  <MdFlashlightOn size={20}></MdFlashlightOn>
                </ButtonFilter>
                <ButtonFilter
                  onClick={() => {
                    dispatch(showModal("change-content-states"));
                  }}
                >
                  <BiWorld size={20}></BiWorld>
                </ButtonFilter>
              </FlexContainer>
            </Grid.Col>
          </Grid.Row>
        </Center>
        <Center gap="10px" style={{ marginBottom: "25px" }}>
          <FlexContainer wrap="wrap" align="center" gap="20px">
            {filterValues.map((filterValue) => {
              return (
                <FilterButton
                  setFilter={buildFilters}
                  name={filterValue.name}
                  fieldName={filterValue.fieldName}
                  value={filterValue.value}
                  filters={applyedFilters}
                  {...((!!filterValue.special || !!filterValue.groupal) && {
                    special: !!filterValue.special
                      ? { name: "special", function: filterValue.special }
                      : { name: "groupal", function: filterValue.groupal },
                  })}
                ></FilterButton>
              );
            })}
          </FlexContainer>
        </Center>
        <Grid.Row>
          <Grid.Col>
            <InfinityScroll
              action={fetchData}
              page={page}
              setPage={setPage}
              data={filteredCurrentBytesListValues}
              active={thereAreItems}
              isLoading={isLoading}
            >
              <GridStructure
                width={"240px"}
                maxWidth="900px"
                style={{ width: "100%", margin: "0 auto" }}
              >
                {filteredCurrentBytesListValues.map((item, index) => {
                  return (
                    <GridCard
                      onClick={(ev) => {
                        ev.stopPropagation();
                        navigate(`management/${item._id}`);
                      }}
                      pd={"12px"}
                      key={item._id}
                    >
                      <GridCardImageContainer>
                        <GridCardImage
                          src={
                            item.picture ||
                            "https://upload.wikimedia.org/wikipedia/commons/8/81/Gallet_clamshell_600x600_6.jpg"
                          }
                        ></GridCardImage>
                      </GridCardImageContainer>
                      <FlexContainer gap="12px">
                        <FlexContainer direction="column" gap="1px">
                          <GridCardTitle>{item.name}</GridCardTitle>
                          <FlexContainer direction="column">
                            <FlexContainer gap="10px" align="center">
                              <FlexContainer align="center" gap="5px">
                                <DotContent></DotContent>
                                <ItemDataText>
                                  <GridComment>{`Vistas: `}</GridComment>{" "}
                                  {`${item.viewCount}`}
                                </ItemDataText>
                              </FlexContainer>

                              <FlexContainer align="center" gap="5px">
                                <DotContent></DotContent>
                                <ItemDataText>
                                  <GridComment>{`Actualizado: `}</GridComment>{" "}
                                  {`${DateFromNow(item.updatedAt)}`}
                                </ItemDataText>
                              </FlexContainer>
                            </FlexContainer>
                            <FlexContainer gap="10px" align="center">
                              <FlexContainer align="center" gap="5px">
                                <DotContent></DotContent>
                                <ItemDataText>
                                  <GridComment>{`Publicado: `}</GridComment>{" "}
                                  {`${DateFromNow(item.createdAt)}`}
                                </ItemDataText>
                              </FlexContainer>
                              <FlexContainer align="center" gap="5px">
                                <DotContent></DotContent>
                                <ItemDataText>
                                  <></>
                                </ItemDataText>
                              </FlexContainer>
                            </FlexContainer>
                          </FlexContainer>
                        </FlexContainer>
                      </FlexContainer>
                    </GridCard>
                  );
                })}
              </GridStructure>
            </InfinityScroll>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
      <FilterModal buildFilters={buildFilters}></FilterModal>

      <StateContentModal
        action={updateManyByte}
        section="Bytes"
        loading={updateManyByteStates.loading}
        items={filteredCurrentBytesListValues}
      ></StateContentModal>
    </>
  );
};

const states = ({ currentStore, byteStore, cohortStore, userStore }) => {
  const { data: current } = currentStore;
  const { data: bytes, states: bytesStates } = byteStore.all;
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: newByteData, states: newByteDataStates } = byteStore.new;
  const { states: updateManyByteStates, data: updateManyBytes } =
    byteStore.updateMany;
  const { data: user } = userStore;
  return {
    current,
    bytes,
    newByteData,
    newByteDataStates,
    cohorts,
    user,
    updateManyByteStates,
    bytesStates,
    updateManyBytes,
  };
};

export default connect(states)(Component);
