import styled from "styled-components";

export const SuscriptionWrapper = styled("div")`
  border-radius: 10px;
  box-shadow: 0 0 6px 0 rgba(21, 115, 229, 0.16);
  padding: 10px 13px 10px 10px;
  background-color: ${(props) => props.theme.colors["White"]};
`;

export const SuscriptionStatus = styled("div")`
  padding: 6px 11px;
  border-radius: 10px;
  border: solid 2px #697482;
  color: #697482;
  &.active {
    background-color: #697482;
    color: #fff;
  }
`;

export const InvalidUntil = styled("div")`
  padding: 6px 10px;
  border: 1px solid #f76564;
  color: #f76564;
`;

export const SuscriptionTitle = styled("h1")`
  font-size: 20px;
  font-weight: 500;
  color: #000;
`;

export const SuscriptionText = styled("h3")`
  font-size: ${(props) => props.size}px;
  font-weight: 500;
  color: #697482;
`;

export const SuscriptionPrice = styled("div")`
  padding: 7px 54px 8px 53px;
`;

export const SuscriptionListWrapper = styled("div")`
  padding: 10px 7px 10px 7px;
  border-radius: 10px;
  box-shadow: 0 0 4px 0 rgba(21, 115, 229, 0.16);
  height: 252px;
  overflow: auto;
`;

export const SuscriptionListItem = styled("div")`
  border: 0px;
  margin: 0px;
  display: block;
  padding: 0px 10px;
  width: 100%;
  border-radius: 10px;
  box-shadow: 1px 1px 3px 0 rgba(112, 112, 112, 0.16);
  transition: all 0.3s ease-in-out;
  background-color: ${({ active }) => (active ? "#daf1ff" : "white")};
  cursor: pointer;
  height: 40px;
  text-align: left;
  display: flex;
  align-items: center;
`;
