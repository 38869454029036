import * as Yup from "yup";

interface InitialValues {
  companies: string[];
  criterions: string[];
}

export const schema = Yup.object().shape({
  companies: Yup.array(),
  criterions: Yup.array(),
});

export const initialValues: InitialValues = {
  companies: [],
  criterions: [],
};
