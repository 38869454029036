import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Modal from "../../../../../../components/Modal";
import { Formik } from "formik";
import {
  Center,
  FlexContainer,
  Separator,
} from "../../../../../../components/StyledComponents";
import {
  initialValues,
  schema,
} from "../../../../../../constants/form/suscription/new";
import Grid from "../../../../../../components/Grid";
import Button from "../../../../../../components/Button";
import Submit from "../../../../../../components/Form/Submit";

import { SuscriptionPrice, SuscriptionText } from "../../style";
import { ToggleButtonWrapper, ToggleInput, ToggleSlider } from "./style";
import Input from "../../../../../../components/Form/Input";
import academyActions from "../../../../../../store/actions/academies";
import { useLocation } from "react-router";
import { CurrentDto } from "../../../../../../types/current.dto";
import { StateDto } from "../../../../../../types/states.dto";
import { toast } from "react-toastify";
import { hideModal } from "../../../../../../store/actions/modal";
import { newPlan } from "../../../../../../store/actions/plan";

const Component = ({
  current,
  updateAcademyStates,
  modalName,
  createPlanStates,
}: {
  current: CurrentDto;
  updateAcademyStates: StateDto;
  modalName: string;
  createPlanStates: StateDto;
}) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const dispatch = useDispatch();

  const onSubmit = ({ values, actions }) => {
    setFormActions(actions);
    dispatch(
      newPlan({
        unitPrice: values.unitPrice,
        currencyId: "ARS",
        type: "SUBSCRIPTION",
        academy: current.id,
      })
    );
  };

  useEffect(() => {
    if (createPlanStates.success) {
      dispatch(hideModal());
    }
  }, [createPlanStates]);

  return (
    <Modal
      name="add-plan-modal-academy"
      title={`Creación de plan de monetización`}
    >
      <Formik
        initialValues={{ ...initialValues }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="invite-academy-modal-form"
            >
              {/*   <Grid.Row style={{ alignItems: "center" }}>
                <Grid.Col>
                  <SuscriptionText size={14}>
                    Tipo de monetizacion de la academy
                  </SuscriptionText>
                  <Separator size={12}></Separator>
                  <FlexContainer align="center" gap="6px">
                    <SuscriptionText size={14}>free</SuscriptionText>
                    <ToggleButtonWrapper>
                      <ToggleInput
                        value={true}
                        onChange={() =>
                          setFieldValue(
                            "monetizationStatus",
                            !values["monetizationStatus"]
                          )
                        }
                        checked={values["monetizationStatus"]}
                        type="checkbox"
                      ></ToggleInput>
                      <ToggleSlider></ToggleSlider>
                    </ToggleButtonWrapper>
                    <SuscriptionText size={14}>paid</SuscriptionText>
                  </FlexContainer>
                </Grid.Col>
                <Grid.Col>
                  <SuscriptionText size={12}>
                    (Una vez hecho el cambio, este tendra efecto en el proximo
                    mes)
                  </SuscriptionText>
                </Grid.Col>
              </Grid.Row> */}
              {/*    {!!values["premium"] && (
                <Grid.Row style={{ alignItems: "center" }}>
                  <Grid.Col>
                    <FlexContainer align="center" gap="6px">
                      <SuscriptionText size={14}>monthly</SuscriptionText>
                      <ToggleButtonWrapper>
                        <ToggleInput type="checkbox"></ToggleInput>
                        <ToggleSlider></ToggleSlider>
                      </ToggleButtonWrapper>
                      <SuscriptionText size={14}>unique</SuscriptionText>
                    </FlexContainer>
                  </Grid.Col>
                  <Grid.Col>
                    <SuscriptionText size={12}>
                      (Ten en cuenta que no puedes pasarte de “unique” a monthy)
                    </SuscriptionText>
                  </Grid.Col>
                </Grid.Row>
              )} */}

              <Separator size={12}></Separator>
              <Grid.Row>
                <Grid.Col>
                  <SuscriptionText size={14}>
                    Pago mensual para el acceso a mi Academia
                  </SuscriptionText>
                  <Separator size={10}></Separator>
                  <FlexContainer gap="7px" align="center" justify="center">
                    <Input
                      name="unitPrice"
                      error={errors["unitPrice"]}
                      touched={touched["unitPrice"]}
                      value={values["unitPrice"]}
                      type="number"
                      placeholder="Ingresa monto"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={{
                        marginBottom: 1,
                        skin: "base",
                        before: (
                          <span
                            style={{
                              display: "inline-block",
                              paddingLeft: "10px",
                            }}
                          >
                            ARS$
                          </span>
                        ),
                      }}
                    />
                    <SuscriptionText style={{ whiteSpace: "nowrap" }} size={10}>
                      (por mes)
                    </SuscriptionText>
                  </FlexContainer>
                </Grid.Col>
              </Grid.Row>
              <Separator size={20}></Separator>
              <Grid.Row>
                <Grid.Col>
                  <Center gap={"70px"}>
                    <Submit
                      isSubmmiting={createPlanStates.loading}
                      form="invite-academy-modal-form"
                      color="Primary"
                      options={{
                        type: "filled",
                        skin: "primary",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                    >
                      Confirmar
                    </Submit>
                    <Button
                      onClick={resetForm}
                      type="button"
                      options={{
                        type: "filled",
                        skin: "danger",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                      style={{ marginLeft: "10px" }}
                    >
                      Deshacer
                    </Button>
                  </Center>
                </Grid.Col>
              </Grid.Row>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const states = ({
  associationStore,
  academyStore,
  currentStore,
  organizationStore,
  modalStore,
  planStore,
}) => {
  const { data: current } = currentStore;
  const { states: updateAcademyStates } = academyStore.update;
  const { visibleModal: modalName } = modalStore;
  const { states: createPlanStates } = planStore.new;
  return { current, updateAcademyStates, modalName, createPlanStates };
};

export default connect(states)(Component);
