import styled, { css } from "styled-components";

export const Layout = styled("div")<{ sidebarCollapse: boolean }>`
  display: flex;
  height: 100vh;
`;

export const Card = styled("div")`
  position: relative;
  border-radius: 10px;
  box-shadow: 6px 6px 10px 0 rgba(112, 112, 112, 0.16);
  background-color: white;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const SearchColumn = styled("div")`
  ${Card}
`;
export const ContentColumn = styled("div")`
  ${Card}
`;
