import {
  NewPlan,
  NEW_PLAN,
  ResetNewPlan,
  RESET_NEW_PLAN,
  GET_ALL_PLANS,
  GetAllPlans,
  UpdatePlan,
  UPDATE_PLAN,
  ResetUpdatePlan,
  RESET_UPDATE_PLAN,
  RESET_DELETE_PLAN,
  DELETE_PLAN,
  RESET_GET_ALL_PLANS,
} from "../types/plan";

export const newPlan = (payload): NewPlan => {
  return { type: NEW_PLAN, payload };
};
export const resetNewPlan = (): ResetNewPlan => {
  return { type: RESET_NEW_PLAN, payload: null };
};

export const updatePlan = (payload): UpdatePlan => {
  return { type: UPDATE_PLAN, payload };
};

export const resetUpdatePlan = (): ResetUpdatePlan => {
  return { type: RESET_UPDATE_PLAN, payload: null };
};

export const getPlans = (payload): GetAllPlans => {
  return { type: GET_ALL_PLANS, payload };
};

export const resetGetAllPlans = () => {
  return { type: RESET_GET_ALL_PLANS, payload: null };
};

export const resetDeletePlan = () => {
  return { type: RESET_DELETE_PLAN, payload: null };
};

export const deletePlan = (payload) => {
  return { type: DELETE_PLAN, payload };
};
