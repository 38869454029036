import { createGlobalStyle } from "styled-components";

export const DataTableGlobalStyles = createGlobalStyle`
  .data-table-extensions {
    margin-bottom: 16px;
    padding: 0px 3px;
    
    .data-table-extensions-filter {
      border-bottom: 1px solid ${(props) => props.theme.colors.Gray};
      padding: 4px 20px;

      label {

      }

      input {
        -webkit-appearance: none;
        border: 0px;
        background: none;
        font-size: 14px;
        padding: 0px;
        display: block;
        outline: 0;
        width: 100%;
        color: ${(props) => props.theme.colors.Gray}
      }
    }

    .data-table-extensions-action {

    }
  }

  .rdt_Table {
    background-color: transparent!important;
    padding: 0px 3px;
    margin-top: 13px;
  }

  .rdt_TableRow {
    background-color: white;
    margin-bottom: 10px;
    border-radius: 3px;
    box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 20%);
    min-height: 50px;

    &:not(:last-of-type){
      border: none;
    }
  }

  .rdt_TableCol {
    padding-left: 20px;
    padding-right: 20px;
  }

  .rdt_TableCol_Sortable {
  }

  .rdt_TableCell {
    font-size: 14px;
    color: ${(props) => props.theme.colors.Gray};
    padding-left: 20px;
    padding-right: 20px;
  }

  .rdt_TableHeader {
  }
  .rdt_TableFooter {
  }
  .rdt_TableHead {
  }
  .rdt_TableHeadRow {
    background-color: transparent!important;
    border: none;
    min-height: 0px;
    margin-bottom: 22px;

    .rdt_TableCol {
      font-size: 16px;
      font-weight: 500;
      color: ${(props) => props.theme.colors.Gray}
    }
  }
  .rdt_TableBody {
  }
  .rdt_ExpanderRow {
  }

  .rdt_Pagination{
    background-color: transparent;

    & > span {
      &:first-child {
        color: transparent;
        height: 32px;

        &:before {
          content: 'Items por página';
          color: rgba(0, 0, 0, 0.54);
          display: block;
          line-height: 32px;
          vertical-align: middle;
        }
      }
    }
  }
`;
