import action from "../helpers/rest-client";

export class ErrorAcademy extends Error {}

class Academy {
  public getOne(payload) {
    return action
      .Get({
        url: `/organizations/${payload}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public new(payload) {
    return action
      .Post({
        url: "/organizations",
        body: payload,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public addMember({ _id, ...rest }) {
    return action
      .Patch({
        url: `/users/add-to-organization/${_id}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public update({ _id, ...rest }) {
    return action
      .Patch({
        url: `/organizations/${_id}`,
        body: !!rest?.body ? rest.body : { ...rest },
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public createLink(payload) {
    return action
      .Patch({
        url: `/organizations/create-link/${payload}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public deleteLink(payload) {
    return action
      .Patch({
        url: `/organizations/delete-link/${payload}`,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return { error };
      });
  }

  public updateRole({ _id, ...rest }) {
    return action
      .Patch({
        url: `/organizations/update-role/${_id}`,
        body: rest.user,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public deleteMember({ _id, ...rest }) {
    return action
      .Patch({
        url: `/users/delete-from-organization/${_id}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getAll() {
    return action
      .Get({
        url: "/organizations",
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public delete(cohortId) {
    return action
      .Del({
        url: `/organizations/${cohortId}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const organizations = new Academy();

export default organizations;
