import { Formik, FormikErrors } from "formik";
import Grid from "../../../../../../components/Grid";
import {
  SectionTitle,
  LabelScore,
  ScrollSection,
} from "../../../../../../components/StyledComponents";
import { useRef, useState } from "react";
import React from "react";
import { useDispatch } from "react-redux";
import {
  newEvaluate,
  newManyEvaluate,
} from "../../../../../../store/actions/evaluate";
import { toast } from "react-toastify";
import { SubCriterionDtoApi } from "../../../../../../types/sub-criterion.dto";
import Loader from "../../../../../../components/Loader";

interface ComponentProps {
  students: {
    name: string;
    grade: number | null;
    id: string;
  }[];
  noteSubCriterionStatus: SubCriterionDtoApi[];
  values: any;
  loading: boolean;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  errors?:
    | string
    | string[]
    | FormikErrors<{ note: number; id: string; name: string }>[]
    | undefined;
}

const Component = ({
  students,
  setFieldValue,
  errors: studentErrors,
  noteSubCriterionStatus,
  values,
  loading,
}: ComponentProps) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const dispatch = useDispatch();
  const [focusedInput, setFocusedInput] = useState(0);
  const inputRefs: any = Array.from({ length: students.length }, () =>
    React.createRef()
  );

  const handleInputChange = (value) => {
    // Remove leading zeros and update state
    const formatNumber = value.charAt(value.length - 1);
    if (/^[1-5]$/.test(formatNumber)) {
      return formatNumber;
    } else {
      return "1";
    }
  };

  const handleNote = (value, id) => {
    const changeStudentNote = students.map((student) =>
      student.id === id
        ? { ...student, grade: Number(handleInputChange(value)) }
        : student
    );
    setFieldValue("students", changeStudentNote);
  };

  const handleNewNotes = (students) => {
    let evaluteItems: any = [];
    for (let i = 0; i < noteSubCriterionStatus.length; i++) {
      evaluteItems = [
        ...evaluteItems,
        {
          students,
          subCriterion: noteSubCriterionStatus[i]._id,
          criterion: noteSubCriterionStatus[i].criterion,
          cohort: values.cohort,
          stage: values.stage,
        },
      ];
    }
    dispatch(newManyEvaluate({ items: evaluteItems }));
  };

  const handleKeyPress = (event: any, currentIndex: number) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (
        students[currentIndex].grade !== null &&
        (students[currentIndex].grade! <= 0 ||
          students[currentIndex].grade! > 5)
      ) {
        toast.error("La nota debe ser menor a 5 y mayor que 0");
        return null;
      }
      if (noteSubCriterionStatus.length === 0) {
        toast.error("No hay subcriterio/s para calificar");
        return null;
      }
      handleNewNotes([students[currentIndex]]);
      if (currentIndex < students.length - 1) {
        inputRefs[currentIndex + 1].current.focus();
        setFocusedInput(currentIndex + 1);
      }
    }
  };
  return (
    <ScrollSection
      style={{
        height: "550px",
        padding: "20px 10px 0px 40px",
        marginBottom: "15px",
      }}
    >
      {loading ? (
        <Loader color="Primary"></Loader>
      ) : (
        <>
          <Grid.Row style={{ marginBottom: "20px" }}>
            <Grid.Col>
              <SectionTitle>Estudiante</SectionTitle>
            </Grid.Col>
            <Grid.Col>
              <SectionTitle>Calificacion</SectionTitle>
            </Grid.Col>
          </Grid.Row>
          <Formik
            initialValues={{}}
            onSubmit={(values, actions) => {}}
            validateOnChange={formSubmmited}
            validateOnBlur={false}
            enableReinitialize
          >
            {({
              touched,
              errors,
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              resetForm,
              setFieldValue,
              isSubmitting,
            }) => {
              return (
                <form
                  className="theme-form"
                  onSubmit={(event) => {
                    setFormSubmmited(true);
                    handleSubmit(event);
                  }}
                  id="student-score-form"
                >
                  <>
                    {students.map((student, index) => (
                      <Grid.Row
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "0px",
                          marginBottom: "5px",
                        }}
                        key={student.id}
                      >
                        <Grid.Col>
                          <LabelScore>{student.name}</LabelScore>
                        </Grid.Col>
                        <Grid.Col>
                          <input
                            key={index}
                            ref={inputRefs[index]}
                            onKeyDown={(event) => handleKeyPress(event, index)}
                            onFocus={() => setFocusedInput(index)}
                            style={{
                              border: `${
                                !student.grade ||
                                student.grade > 5 ||
                                student.grade <= 0
                                  ? "1px solid red"
                                  : "none"
                              }`,
                              textAlign: "center",
                              marginLeft: "27px",
                              width: "65px",
                              height: "26px",
                              borderRadius: "3px",
                              backgroundColor: "#f5f5f5",
                            }}
                            onChange={(ev) =>
                              handleNote(ev.target.value, student.id)
                            }
                            value={!student.grade ? "" : student.grade}
                          />
                        </Grid.Col>
                      </Grid.Row>
                    ))}
                  </>
                </form>
              );
            }}
          </Formik>
        </>
      )}
    </ScrollSection>
  );
};

export default Component;
