import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Modal from "../../../../../../components/Modal";
import { Formik } from "formik";
import {
  Center,
  FlexContainer,
  Separator,
} from "../../../../../../components/StyledComponents";
import {
  initialValues,
  schema,
} from "../../../../../../constants/form/event/edit";
import Button from "../../../../../../components/Button";
import Submit from "../../../../../../components/Form/Submit";
import { toast } from "react-toastify";
import Input from "../../../../../../components/Form/Input";
import { hideModal } from "../../../../../../store/actions/modal";
import {
  newEvent,
  resetNewEvent,
  updateEvent,
} from "../../../../../../store/actions/event";
import WeekDays from "../../../../Event/components/WeekDays";
import { minInputDate } from "../../../../../../helpers/data-handler";
import { ErrorMessage, InLine } from "../../../../../../components/Form/Field";
import { EntityTitleSemiBold } from "../../style";
const Component = ({
  eventSelected,
  selectedAcademy,
  updateAcademyStates,
  academies,
  current,
  newEventStates,
  updateEventStates,
}) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const dispatch = useDispatch();
  const [frecuency, setFrecuency] = useState<string>();
  const onSubmit = ({ values, actions }) => {
    dispatch(
      updateEvent({ _id: eventSelected._id, academy: current.id, ...values })
    );
  };
  const {
    _id,
    updatedAt,
    cohortSpecific,
    createdAt,
    createdBy,
    active,
    academy,
    updatedBy,
    __v,
    ...rest
  } = eventSelected;
  const closeModal = () => {
    dispatch(hideModal());
  };
  useEffect(() => {
    if (updateEventStates.success) {
      toast("👌🏼 Evento Modificada", {
        containerId: "submit",
        type: "success",
        toastId: "submitsucceful",
      });

      setTimeout(() => {
        dispatch(resetNewEvent());
        dispatch(hideModal());
        formActions?.resetForm();
      }, 1000);
    }

    if (updateEventStates.error) {
      toast(`😱 ${updateEventStates.error}`, {
        containerId: "submit",
        type: "error",
        toastId: "submiterror",
      });

      setTimeout(() => {
        dispatch(resetNewEvent());
      }, 1000);
    }
  }, [updateEventStates]);

  return (
    <Modal name="edit-event-cohort" title="Editar Evento">
      <Formik
        initialValues={{ ...initialValues, ...rest }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="new-event-form"
            >
              <Input
                name="name"
                error={errors["name"]}
                touched={touched["name"]}
                value={values["name"]}
                type="text"
                placeholder=""
                onChange={handleChange}
                onBlur={handleBlur}
                options={{
                  label: "Nombre del Evento",
                  marginBottom: 20,
                  skin: "gray",
                }}
              />
              <FlexContainer align="center" style={{ marginBottom: "20px" }}>
                <input
                  style={{ height: "17px", width: "17px" }}
                  name={"frecuency"}
                  checked={values["frecuency"] === "unique"}
                  onChange={() => {
                    setFieldValue(
                      "frecuency",
                      values["frecuency"] === "unique"
                        ? "personalized"
                        : "unique"
                    );
                  }}
                  type="checkbox"
                />
                <EntityTitleSemiBold>Evento Unico</EntityTitleSemiBold>
              </FlexContainer>

              <InLine template="1fr 1fr">
                <Input
                  name="startDate"
                  error={errors["startDate"]}
                  touched={touched["startDate"]}
                  value={values["startDate"]}
                  type="date"
                  min={minInputDate()}
                  placeholder="Fecha inicio"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={{
                    label: "Fecha inicio",
                    marginBottom: 20,
                    skin: "gray",
                  }}
                />
                {values["frecuency"] === "personalized" && (
                  <Input
                    name="endDate"
                    error={errors["endDate"]}
                    touched={touched["endDate"]}
                    value={values["endDate"]}
                    type="date"
                    min={values["startDate"] || minInputDate()}
                    placeholder="Fecha de Cierre"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={frecuency === "unique"}
                    options={{
                      label: "Fecha Cierre",
                      marginBottom: 20,
                      skin: "gray",
                    }}
                  />
                )}
              </InLine>
              <InLine template="1fr 1fr">
                <Input
                  name="startHour"
                  error={errors["startHour"]}
                  touched={touched["startHour"]}
                  value={values["startHour"]}
                  type="time"
                  placeholder="Hora inicio"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={{
                    label: "Hora inicio",
                    marginBottom: 20,
                    skin: "gray",
                  }}
                />

                <Input
                  name="endHour"
                  error={errors["endHour"]}
                  touched={touched["endHour"]}
                  value={values["endHour"]}
                  type="time"
                  placeholder="Hora fin"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={{
                    label: "Hora fin",
                    marginBottom: 20,
                    skin: "gray",
                  }}
                />
              </InLine>
              {values["frecuency"] === "personalized" && (
                <>
                  <WeekDays
                    setValue={(value) => {
                      if (values.eventDays.includes(value)) {
                        setFieldValue(
                          "eventDays",
                          values.eventDays.filter((event) => event !== value)
                        );
                      } else {
                        setFieldValue("eventDays", [
                          ...values.eventDays,
                          value,
                        ]);
                      }
                    }}
                    error={errors["eventDays"]}
                    touched={touched["eventDays"]}
                    value={values["eventDays"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.eventDays && (
                    <ErrorMessage>{errors.eventDays}</ErrorMessage>
                  )}
                  <Separator size={10}></Separator>
                </>
              )}

              <Input
                name="meetLink"
                error={errors["meetLink"]}
                touched={touched["meetLink"]}
                value={values["meetLink"]}
                type="text"
                placeholder=""
                onChange={handleChange}
                onBlur={handleBlur}
                options={{
                  label: "Link del Evento",
                  marginBottom: 40,
                  skin: "gray",
                }}
              />

              <Center>
                <Submit
                  isSubmmiting={updateEventStates.loading}
                  form="new-event-form"
                  color="Primary"
                  options={{
                    type: "filled",
                    skin: "primary",
                    size: "lg",
                    marginBottom: "0px",
                  }}
                >
                  Confirmar
                </Submit>
                <Button
                  onClick={closeModal}
                  type="button"
                  options={{
                    type: "outline",
                    skin: "danger",
                    size: "lg",
                    marginBottom: "0px",
                  }}
                  style={{ marginLeft: "10px" }}
                >
                  Cancelar
                </Button>
              </Center>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const states = ({ academyStore, currentStore, eventStore }) => {
  const { data: selectedAcademy } = academyStore.selected;
  const { states: updateAcademyStates } = academyStore.update;
  const { data: current } = currentStore;
  const { data: academies } = academyStore.all;
  const { states: newEventStates } = eventStore.newEvent;
  const { states: updateEventStates } = eventStore.updateEvent;
  return {
    selectedAcademy,
    updateAcademyStates,
    academies,
    newEventStates,
    current,
    updateEventStates,
  };
};

export default connect(states)(Component);
