import React, { useEffect, useState } from "react";

import {
  Center,
  FlexContainer,
  PageTitle,
  ScrollSection,
} from "../../../components/StyledComponents";
import Button from "../../../components/Button";
import { connect, useDispatch } from "react-redux";
import { AcademyDto } from "../../../types/academy.dto";
import { UserDto } from "../../../types/user.dto";

import { CohortDto } from "../../../types/cohort.dto";
import notificationActions from "../../../store/actions/notification";
import Loader from "../../../components/Loader";

import {
  Cell,
  EntityImageContainer,
  EntityTitleDescription,
  EntityTitleSemiBold,
  ListItem,
  ListWrapper,
  Row,
  Table,
} from "./style";
import { StateDto } from "../../../types/states.dto";
import { Img } from "../Cohort/Customize/style";
import { NotificationDto } from "../../../types/notification.dto";
import FilterButton from "./components/FilterButton";
import { CurrentDto } from "../../../types/current.dto";
import Grid from "../../../components/Grid";
import { currentSectionSelect } from "../../../helpers/currentSection";
import { sections } from "../../../constants/sections";
import { UsersDto } from "../../../types/users.dto";
import { DateFromNow } from "../../../helpers/format-date";
import InfinityScroll from "../../../components/InfinityScroll";
import { FetchData } from "../../../types/fetchData.dto";
interface ComponentProps {
  user: UserDto;
  cohorts: CohortDto[];
  notifications: NotificationDto[];
  academies: AcademyDto[];
  updateNotificationStates: StateDto;
  current: CurrentDto;
  users: UsersDto[];
  notificationsStates: StateDto;
}

const filterValues = [
  {
    fieldName: "type",
    value: "no",
    name: "Todos",
  },
  {
    fieldName: "type",
    value: "Academy",
    name: "Academias",
  },
  {
    fieldName: "type",
    value: "Organization",
    name: "Organizaciones",
  },
  {
    fieldName: "type",
    value: "User",
    name: "Personal",
  },
];

const Component = ({
  user,
  cohorts,
  notifications,
  academies,
  updateNotificationStates,
  current,
  users,
  notificationsStates,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const [currentNotificationsList, setCurrentNotificationsList] = useState<
    NotificationDto[]
  >([]);
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);
  const [applyedFilters, setFilters] = useState<
    {
      field: string;
      value: string | boolean;
      special: (...args: any) => boolean;
      groupal: (...args: any) => any[];
    }[]
  >([]);

  const [
    filteredCurrentNotificationsListValues,
    setFilteredCurrentNotificationsListValues,
  ] = useState<NotificationDto[]>([]);

  const buildFilters = (newFilter) => {
    const filterUpdated =
      applyedFilters.filter(
        (applyedFilter) => applyedFilter.field === newFilter.field
      ).length > 0;

    const filterRemoved = newFilter.value === "no";

    /**
     * Se elimina el filtro
     */
    if (filterRemoved) {
      setFilters([
        ...applyedFilters.filter(
          (applyedFilter) => applyedFilter.field !== newFilter.field
        ),
      ]);
    }

    /**
     * Se actualiza un filtro
     */
    if (filterUpdated && !filterRemoved) {
      setFilters([
        ...applyedFilters.map((applyedFilter) => {
          if (applyedFilter.field === newFilter.field) {
            return newFilter;
          } else {
            return applyedFilter;
          }
        }),
      ]);
    }

    /**
     * Se agrega un filtro
     */
    if (!filterUpdated && !filterRemoved) {
      setFilters([...applyedFilters, newFilter]);
    }
  };

  const fetchData = ({ page, filterBy }: FetchData) => {
    let payload = {
      limit: 9,
      offset: 9 * Math.floor(page),
      filterBy: {
        ...(filterBy ? filterBy : {}),
        active: true,
      },
    };
    const sortBy = "createdAt";

    const sortDirection = "desc";
    dispatch(notificationActions.getAll({ ...payload, sortBy, sortDirection }));
  };

  useEffect(() => {
    setThereAreItems(!!notifications && notifications?.length > 0);
  }, [notifications]);

  useEffect(() => {
    setIsLoading(notificationsStates.loading);
  }, [notificationsStates]);

  useEffect(() => {
    buildFilters({ field: "type", value: "no" });
    return () => {
      dispatch(notificationActions.resetGetAll());
    };
  }, []);

  useEffect(() => {
    if (!!notifications && !isLoading) {
      /* Me dice en que lugar estoy */

      const currentNotifications = notifications.filter((notification) =>
        user.notifications.includes(notification._id)
      );
      setCurrentNotificationsList((state) => [
        ...state,
        ...currentNotifications,
      ]);
    }
  }, [notifications, isLoading, user]);
  useEffect(() => {
    const filteredNotifications = currentNotificationsList?.filter(
      (currentTalentValues) => {
        const thereAreFilters = applyedFilters.length > 0;
        const totalOfFilters = applyedFilters.filter(
          (applyedFilter) => !applyedFilter.groupal
        ).length;
        const passFilters = applyedFilters
          .filter((applyedFilter) => !applyedFilter.special)
          .filter(
            (applyedFilter) =>
              currentTalentValues[applyedFilter.field] === applyedFilter.value
          ).length;
        const specialFilter = applyedFilters.filter(
          (applyedFilter) => !!applyedFilter.special
        );

        const speacialFilterCheck = specialFilter.filter((applyedFilter) => {
          return applyedFilter.special(
            currentTalentValues,
            applyedFilter.value
          );
        });

        return (
          !thereAreFilters ||
          (thereAreFilters &&
            totalOfFilters === passFilters + specialFilter.length &&
            speacialFilterCheck.length === specialFilter.length)
        );
      }
    );
    setFilteredCurrentNotificationsListValues(filteredNotifications);
  }, [applyedFilters, currentNotificationsList]);

  return (
    <>
      <PageTitle>Notificaciones</PageTitle>
      <ListWrapper>
        <Grid.Row>
          <Grid.Col>
            <FlexContainer align="center" gap="10px" wrap="wrap">
              {current.currentSection === "personal" && (
                <>
                  <h3>Filtros:</h3>
                  <FlexContainer wrap="wrap" align="center" gap="8px">
                    {filterValues.map((filterValue) => (
                      <FilterButton
                        setFilter={buildFilters}
                        name={filterValue.name}
                        fieldName={filterValue.fieldName}
                        value={filterValue.value}
                        filters={applyedFilters}
                      ></FilterButton>
                    ))}
                  </FlexContainer>
                </>
              )}
            </FlexContainer>
            <ScrollSection style={{ height: "500px", boxShadow: "none" }}>
              <InfinityScroll
                style={{ width: "100%", padding: "20px" }}
                action={fetchData}
                page={page}
                setPage={setPage}
                data={filteredCurrentNotificationsListValues}
                active={thereAreItems}
                isLoading={isLoading}
              >
                <FlexContainer
                  direction="column"
                  gap="14px"
                  style={{ paddingBottom: "30px" }}
                >
                  {filteredCurrentNotificationsListValues.map(
                    (notification) => {
                      return (
                        <ListItem>
                          <FlexContainer
                            wrap="wrap"
                            justify="space-between"
                            align="center"
                          >
                            <FlexContainer align="center" gap="10px">
                              <EntityImageContainer>
                                <Img src={notification.entity.picture}></Img>
                              </EntityImageContainer>
                              <EntityTitleSemiBold style={{ width: "100px" }}>
                                {`${notification.entity.name} ${
                                  notification.entity.lastName
                                    ? notification.entity.lastName
                                    : ""
                                }`}
                              </EntityTitleSemiBold>
                            </FlexContainer>
                            <EntityTitleSemiBold>
                              {new Date(
                                notification.createdAt
                              ).toLocaleDateString("en-GB")}
                            </EntityTitleSemiBold>

                            <EntityTitleSemiBold style={{ width: "300px" }}>
                              {notification.description}
                            </EntityTitleSemiBold>
                          </FlexContainer>
                        </ListItem>
                      );
                    }
                  )}
                </FlexContainer>
              </InfinityScroll>
            </ScrollSection>
          </Grid.Col>
        </Grid.Row>
      </ListWrapper>
    </>
  );
};

const states = ({
  userStore,
  cohortStore,
  invitationStore,
  academyStore,
  notificationStore,
  currentStore,
  usersStore,
}) => {
  const { states: updateNotificationStates } = invitationStore.update;
  const { data: academies } = academyStore.all;
  const { data: notifications, states: notificationsStates } =
    notificationStore.all;
  const { data: user } = userStore;
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: current } = currentStore;
  const { data: users } = usersStore.all;
  return {
    notifications,
    user,
    cohorts,
    academies,
    updateNotificationStates,
    current,
    users,
    notificationsStates,
  };
};

export default connect(states)(Component);
