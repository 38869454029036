import { put, call, takeLatest } from "redux-saga/effects";
import services from "../../services";
import {
  GET_ALL_PROVINCES_ERROR,
  GET_ALL_PROVINCES_SUCCESS,
  GET_ALL_PROVINCES,
  GetAllProvinces,
} from "../types/statics";

function* getAllProvinces({ payload }) {
  const { response, error } = yield call(
    services.statics.getAllProvinces,
    payload
  );
  if (error) {
    yield put({
      type: GET_ALL_PROVINCES_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_PROVINCES_SUCCESS, payload: response });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllProvinces>(GET_ALL_PROVINCES, getAllProvinces);
}
