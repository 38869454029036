import styled from "styled-components";

export const Card = styled("div")`
  box-shadow: 0 0 10px 0 rgba(112, 112, 112, 0.16);
  position: relative;
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
`;

export const CardRow = styled("div")`
  display: flex;
  gap: ${(props) => props.gap};
  align-items: ${(props) => (!!props.align ? props.align : "stretch")};
  justify-content: ${(props) => (!!props.justify ? props.justify : "stretch")};
  ${(props) => !!props.style && props.style};
`;

export const CardColumn = styled(CardRow)`
  flex-direction: column;
  gap: ${(props) => props.gap};
  align-items: ${(props) => (!!props.align ? props.align : "stretch")};
  justify-content: ${(props) => (!!props.justify ? props.justify : "stretch")};
  ${(props) => !!props.style && props.style};
`;

export const CardTitle = styled("h3")`
  margin: 0px;
  font-size: 14px;
  font-weight: 500;
  color: #273241;
`;

export const TextTitle = styled("p")`
  padding: 6px 0px 6px 14px;
  margin: 0 0 5px 8px;
  border-radius: 5px;
  box-shadow: 0 0 3px 0 rgba(112, 112, 112, 0.16);
`;

export const DataText = styled("span")`
  font-size: 14px;
  color: #273241;
`;
