import { MentorDto } from "../../types/mentor.dto";

export const GET_ALL_MENTORS = "GET_ALL_MENTORS";
export const GET_ALL_MENTORS_ERROR = "GET_ALL_MENTORS_ERROR";
export const GET_ALL_MENTORS_SUCCESS = "GET_ALL_MENTORS_SUCCESS";
export const GET_MENTOR = "GET_MENTOR";
export const GET_MENTOR_ERROR = "GET_MENTOR_ERROR";
export const GET_MENTOR_SUCCESS = "GET_MENTOR_SUCCESS";
export const NEW_MENTOR = "NEW_MENTOR";
export const NEW_MENTOR_ERROR = "NEW_MENTOR_ERROR";
export const NEW_MENTOR_SUCCESS = "NEW_MENTOR_SUCCESS";
export const RESET_NEW_MENTOR = "RESET_NEW_MENTOR";
export const UPDATE_MENTOR = "UPDATE_MENTOR";
export const UPDATE_MENTOR_ERROR = "UPDATE_MENTOR_ERROR";
export const UPDATE_MENTOR_SUCCESS = "UPDATE_MENTOR_SUCCESS";
export const RESET_UPDATE_MENTOR = "RESET_UPDATE_MENTOR";
export const DELETE_MENTOR = "DELETE_MENTOR";
export const DELETE_MENTOR_ERROR = "DELETE_MENTOR_ERROR";
export const DELETE_MENTOR_SUCCESS = "DELETE_MENTOR_SUCCESS";
export const RESET_DELETE_MENTOR = "RESET_DELETE_MENTOR";

export interface GetAllMentors {
  type: typeof GET_ALL_MENTORS;
  payload: null;
}

export interface GetAllMentorsSuccess {
  type: typeof GET_ALL_MENTORS_SUCCESS;
  payload: MentorDto[];
}

export interface GetAllMentorsError {
  type: typeof GET_ALL_MENTORS_ERROR;
  payload: boolean | string;
}

export interface GetMentor {
  type: typeof GET_MENTOR;
  payload: { id: string };
}

export interface GetMentorSuccess {
  type: typeof GET_MENTOR_SUCCESS;
  payload: MentorDto;
}

export interface GetMentorError {
  type: typeof GET_MENTOR_ERROR;
  payload: boolean | string;
}

export interface NewMentor {
  type: typeof NEW_MENTOR;
  payload: MentorDto;
}

export interface NewMentorSuccess {
  type: typeof NEW_MENTOR_SUCCESS;
  payload: MentorDto;
}

export interface NewMentorError {
  type: typeof NEW_MENTOR_ERROR;
  payload: boolean | string;
}

export interface ResetNewMentor {
  type: typeof RESET_NEW_MENTOR;
  payload: null;
}

export interface UpdateMentor {
  type: typeof UPDATE_MENTOR;
  payload: MentorDto;
}

export interface UpdateMentorSuccess {
  type: typeof UPDATE_MENTOR_SUCCESS;
  payload: MentorDto;
}

export interface UpdateMentorError {
  type: typeof UPDATE_MENTOR_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateMentor {
  type: typeof RESET_UPDATE_MENTOR;
  payload: null;
}

export interface DeleteMentor {
  type: typeof DELETE_MENTOR;
  payload: { id: string };
}

export interface DeleteMentorSuccess {
  type: typeof DELETE_MENTOR_SUCCESS;
  payload: null;
}

export interface DeleteMentorError {
  type: typeof DELETE_MENTOR_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteMentor {
  type: typeof RESET_DELETE_MENTOR;
  payload: null;
}

export interface State {
  allMentors: {
    data: MentorDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  mentor: {
    data: MentorDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  newMentor: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  deleteMentor: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  updateMentor: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  allMentors: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  mentor: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  newMentor: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  deleteMentor: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  updateMentor: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllMentors
  | GetAllMentorsSuccess
  | GetAllMentorsError
  | GetMentor
  | GetMentorSuccess
  | GetMentorError
  | NewMentor
  | NewMentorSuccess
  | NewMentorError
  | ResetNewMentor
  | UpdateMentor
  | UpdateMentorSuccess
  | UpdateMentorError
  | ResetUpdateMentor
  | DeleteMentor
  | DeleteMentorSuccess
  | DeleteMentorError
  | ResetDeleteMentor;
