import React, { useState } from "react";
import {
  Center,
  FlexContainer,
} from "../../../../../../components/StyledComponents";
import { Formik } from "formik";
import {
  ModalInputWrapper,
  ModalTextField,
} from "../../../../Certificates/Template/components/ModalSendCertificate/style";
import {
  schema,
  initialValues,
} from "../../../../../../constants/form/node/edit";
import InsideModal from "../../../../../../components/InsideModal";
import { InlineInput, ModalInsideText } from "./style";
import { MdOutlineAddCircle, MdOutlineRemoveCircle } from "react-icons/md";
import Button from "../../../../../../components/Button";
const Component = ({
  node,
  setNodes,
  isVisible,
  setIsVisible,
  setSelectedNode,
}) => {
  const [createUrl, setCreateUrl] = useState<string>("");
  const [formSubmmited, setFormSubmmited] = useState(false);
  const onSubmit = ({ values }) => {
    setNodes((state) =>
      state.map((item) =>
        item.id === node.id
          ? {
              ...item,
              urls: values.urls,
              data: { ...item.data, label: values.name },
              style: {
                ...item.style,
                ...(!!values.backgroundColor && {
                  backgroundColor: values.backgroundColor,
                }),
                ...(!!values.color && {
                  color: values.color,
                }),
                ...(!!values.fontSize && { fontSize: `${values.fontSize}px` }),
              },
            }
          : item
      )
    );
    setSelectedNode(null);
    setIsVisible(null);
  };

  const generateNewUserId = (nodes) => {
    if (!(nodes.length > 0)) return 1;
    const yes = Number(nodes[nodes.length - 1].id) + 1;
    return yes;
  };
  return (
    <InsideModal
      width={"100%"}
      animation="fadeInSlideRight"
      name="node-edit"
      title={`Editar Nodo`}
      padding={"11px 5px 30px 5px"}
      position={"right"}
      styles={{
        position: "absolute",
        width: "400px",
        top: 0,
        bottom: 0,
        right: 0,
        display: isVisible ? "block" : "none",
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
      }}
      height={"100%"}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      onClose={() => {
        setSelectedNode(null);
      }}
    >
      <Formik
        initialValues={initialValues({
          name: node.data.label,
          ...(!!node.style?.backgroundColor && {
            backgroundColor: node.style.backgroundColor,
          }),
          ...(!!node.style?.color && {
            color: node.style.color,
          }),
          ...(!!node.style?.fontSize && {
            fontSize: Number(node.style.fontSize.split("px")[0]),
          }),
          ...(!!node.data.urls && {
            urls: node.data.urls,
          }),
        })}
        onSubmit={(values, actions) => {
          /*  if (onSubmit) onSubmit({ values, actions }); */
        }}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="edit-node"
            >
              <ModalInputWrapper style={{ marginBottom: "10px" }}>
                <FlexContainer align="center" gap="5px">
                  <ModalTextField>Nombre del nodo:</ModalTextField>
                  <InlineInput
                    name={"name"}
                    value={values["name"]}
                    onChange={(ev) => {
                      setFieldValue("name", ev.target.value);
                    }}
                  ></InlineInput>
                </FlexContainer>
              </ModalInputWrapper>
              <ModalInputWrapper style={{ marginBottom: "10px" }}>
                <FlexContainer align="center" gap="5px">
                  <ModalTextField>Fondo de color:</ModalTextField>
                  <input
                    name="backgroundColor"
                    value={values["backgroundColor"]}
                    onChange={(ev) => {
                      setFieldValue("backgroundColor", ev.target.value);
                    }}
                    type="color"
                    style={{ background: "transparent", border: "none" }}
                  ></input>
                </FlexContainer>
              </ModalInputWrapper>
              <ModalInputWrapper style={{ marginBottom: "10px" }}>
                <FlexContainer align="center" gap="5px">
                  <ModalTextField>Color:</ModalTextField>
                  <input
                    name="color"
                    value={values["color"]}
                    onChange={(ev) => {
                      setFieldValue("color", ev.target.value);
                    }}
                    type="color"
                    style={{ background: "transparent", border: "none" }}
                  ></input>
                </FlexContainer>
              </ModalInputWrapper>
              <ModalInputWrapper style={{ marginBottom: "10px" }}>
                <FlexContainer align="center" gap="5px">
                  <ModalTextField>Tamaño de Fuente:</ModalTextField>
                  <InlineInput
                    max="30"
                    min="2"
                    value={values["fontSize"]}
                    type="number"
                    onChange={(ev) => {
                      setFieldValue(
                        "fontSize",
                        Number(ev.target.value === 0 ? 1 : ev.target.value)
                      );
                    }}
                  />
                  <h5>{!!errors["fontSize"] && errors["fontSize"]}</h5>
                </FlexContainer>
              </ModalInputWrapper>
              <ModalInputWrapper style={{ marginBottom: "10px" }}>
                Agregar Links:
              </ModalInputWrapper>
              <ModalInputWrapper style={{ marginBottom: "10px" }}>
                <FlexContainer justify="space-between" gap="5px" align="center">
                  <FlexContainer
                    style={{ width: "100%" }}
                    direction="column"
                    gap="5px"
                  >
                    <FlexContainer align="center" gap="5px">
                      <ModalInsideText>Crear link:</ModalInsideText>
                      <InlineInput
                        style={{ flex: "1" }}
                        value={createUrl}
                        onChange={(ev) => {
                          setCreateUrl(ev.target.value);
                        }}
                      />
                    </FlexContainer>
                  </FlexContainer>
                  <MdOutlineAddCircle
                    onClick={() => {
                      setFieldValue("urls", [
                        ...values["urls"],
                        {
                          url: createUrl,
                          id: generateNewUserId(values["urls"]),
                        },
                      ]);
                      setCreateUrl("");
                    }}
                    size={20}
                    color="#63a1ed"
                  ></MdOutlineAddCircle>
                </FlexContainer>
              </ModalInputWrapper>
              {values["urls"].map((url) => {
                return (
                  <ModalInputWrapper style={{ marginBottom: "10px" }}>
                    <FlexContainer
                      justify="space-between"
                      align="center"
                      gap="5px"
                    >
                      <FlexContainer
                        style={{ width: "100%" }}
                        direction="column"
                        gap="2px"
                      >
                        <FlexContainer align="center" gap="5px">
                          <ModalInsideText>Link:</ModalInsideText>
                          <InlineInput
                            style={{ flex: "1" }}
                            value={
                              values["urls"].filter(
                                (urlF) => urlF.id === url.id
                              )[0].url
                            }
                            onChange={(ev) => {
                              setFieldValue(
                                "urls",
                                values["urls"].map((formUrl) =>
                                  formUrl.id === url.id
                                    ? { ...url, url: ev.target.value }
                                    : formUrl
                                )
                              );
                            }}
                          />
                        </FlexContainer>
                      </FlexContainer>
                      <MdOutlineRemoveCircle
                        onClick={() => {
                          setFieldValue(
                            "urls",
                            values["urls"].filter(
                              (formUrl) => formUrl.id !== url.id
                            )
                          );
                        }}
                        size={20}
                        color="#fd433a"
                      ></MdOutlineRemoveCircle>
                    </FlexContainer>
                  </ModalInputWrapper>
                );
              })}
              <Center style={{ marginTop: "50px" }}>
                <Button
                  onClick={() => onSubmit({ values })}
                  options={{
                    type: "filled",
                    skin: "primary",
                    size: "lg",
                    marginBottom: "0px",
                  }}
                >
                  Confirmar
                </Button>
              </Center>
            </form>
          );
        }}
      </Formik>
    </InsideModal>
  );
};

export default Component;
