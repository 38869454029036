import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import {
  GrowingStage,
  GrowingStageArrow,
  GrowingStageValue,
  GrowingText,
} from "./styles";
import { overThousandFixed } from "../../../../../../helpers/number-fixed";

interface ComponentProps {
  value: number;
  label: string;
  height?: string;
}

const Component = ({ value, label, ...rest }: ComponentProps) => {
  return (
    <GrowingStage style={{ ...rest }}>
      <GrowingStageValue>{`${overThousandFixed(value)}%`}</GrowingStageValue>
      <GrowingText>{label}</GrowingText>
      {value > 0 ? (
        <GrowingStageArrow>
          <FaArrowUp color="#30ff22" fontSize={20} />
        </GrowingStageArrow>
      ) : (
        <GrowingStageArrow>
          {value !== 0 && <FaArrowDown color="#ff2222" fontSize={20} />}
        </GrowingStageArrow>
      )}
    </GrowingStage>
  );
};

export default Component;
