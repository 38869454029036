import { Formik } from "formik";

import searchIcon from "../../../../../../assets/icons/search.svg";
import { Input } from "./style";
import { AiOutlineSearch } from "react-icons/ai";
import ReactSelect from "../../../../../../components/Form/ReactSelect";
import Icon from "../../../../../../components/Icon";
interface ComponentProps {
  setFilter;
  items;
  onselect?;
}

const Component = ({ setFilter, items, onselect }: ComponentProps) => {
  return (
    <Formik
      initialValues={{}}
      onSubmit={(values, actions) => {}}
      enableReinitialize
    >
      {({ handleSubmit }) => {
        return (
          <form
            className="theme-form"
            onSubmit={(event) => {
              handleSubmit(event);
            }}
            id="userid-filter-form"
          >
            <div style={{ position: "relative" }}>
              <ReactSelect
                name="userId"
                items={
                  !!items &&
                  items.map((cohort) => {
                    return {
                      label: cohort.name,
                      value: cohort._id,
                    };
                  })
                }
                placeholder="Busca por nombre del cohort"
                onOptionSelected={(optionSelected) => {
                  onselect && onselect(optionSelected);
                  setFilter({
                    field: "_id",
                    value: optionSelected ? optionSelected.value : "no",
                  });
                }}
                options={{
                  marginBottom: 0,
                  indicator: true,
                  before: <Icon icon={searchIcon} size="16px" color="" />,
                }}
              />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default Component;
