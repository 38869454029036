import { DaysList, Day, Label } from "./styles";
import { Label as FieldLabel } from "../../../../../components/Form/Field";

interface ComponentProps {
  error;
  touched;
  value;
  onChange;
  onBlur;
  setValue;
}

const Component = ({
  error,
  touched,
  value,
  onChange,
  onBlur,
  setValue,
}: ComponentProps) => {
  return (
    <>
      <FieldLabel>Se repite el</FieldLabel>

      <DaysList>
        <Day>
          <input
            onChange={(ev) => {
              setValue(ev.target.value);
            }}
            onBlur={onBlur}
            type="checkbox"
            name="eventDays"
            value="0"
            id="domingo"
            checked={value.includes("0")}
          />
          <Label htmlFor="domingo">D</Label>
        </Day>
        <Day>
          <input
            onChange={(ev) => {
              setValue(ev.target.value);
            }}
            onBlur={onBlur}
            type="checkbox"
            name="eventDays"
            value="1"
            id="lunes"
            checked={value.includes("1")}
          />
          <Label htmlFor="lunes">L</Label>
        </Day>
        <Day>
          <input
            onChange={(ev) => {
              setValue(ev.target.value);
            }}
            onBlur={onBlur}
            type="checkbox"
            name="eventDays"
            value="2"
            id="martes"
            checked={value.includes("2")}
          />
          <Label htmlFor="martes">M</Label>
        </Day>
        <Day>
          <input
            onChange={(ev) => {
              setValue(ev.target.value);
            }}
            onBlur={onBlur}
            type="checkbox"
            name="eventDays"
            value="3"
            id="miercoles"
            checked={value.includes("3")}
          />
          <Label htmlFor="miercoles">X</Label>
        </Day>
        <Day>
          <input
            onChange={(ev) => {
              setValue(ev.target.value);
            }}
            onBlur={onBlur}
            type="checkbox"
            name="eventDays"
            value="4"
            id="jueves"
            checked={value.includes("4")}
          />
          <Label htmlFor="jueves">J</Label>
        </Day>
        <Day>
          <input
            onChange={(ev) => {
              setValue(ev.target.value);
            }}
            onBlur={onBlur}
            type="checkbox"
            name="eventDays"
            value="5"
            id="viernes"
            checked={value.includes("5")}
          />
          <Label htmlFor="viernes">V</Label>
        </Day>
        <Day>
          <input
            onChange={(ev) => {
              setValue(ev.target.value);
            }}
            onBlur={onBlur}
            type="checkbox"
            name="eventDays"
            value="6"
            id="sabado"
            checked={value.includes("6")}
          />
          <Label htmlFor="sabado">S</Label>
        </Day>
      </DaysList>
    </>
  );
};

export default Component;
