import styled from "styled-components";

export const Title = styled("h3")`
  ${(props) => {
    return `
    font-size:${!!props.size ? `${props.size}px` : "15px"};
    font-weight:600;
    text-align:center;
    `;
  }}
`;
