import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { AiFillEdit, AiFillLock, AiFillUnlock } from "react-icons/ai";
import { FaCloudUploadAlt, FaSave, FaShare } from "react-icons/fa";
import Submit from "../../../../components/Form/Submit";
import Grid from "../../../../components/Grid";
import { FlexContainer } from "../../../../components/StyledComponents";
import { schema, initialValues } from "../../../../constants/form/content/new";
import TextArea from "./components/TextArea";
import { RiCloseCircleFill } from "react-icons/ri";
import {
  VideoUploaderDescriptionWrapper,
  VideoUploaderTitle,
  ContentUploadWrapper,
  ContentImagePreview,
} from "./style";
import InputDescription from "./components/Field/index";
import { BiLink } from "react-icons/bi";
import { BsFillTrash2Fill } from "react-icons/bs";
import { FiUpload } from "react-icons/fi";
import { HiUserAdd } from "react-icons/hi";
import { toast } from "react-toastify";
import { showModal } from "../../../../store/actions/modal";
import { connect, useDispatch } from "react-redux";
import { VideoDto } from "../../../../types/video.dto";
import SideBar from "./components/SideBar";
import Loader from "../../../../components/Loader";

import { useNavigate, useParams } from "react-router-dom";
import { StateDto } from "../../../../types/states.dto";
import Button from "../../../../components/Button";
import PublishVideoModal from "./components/PublishContentModal";

import AddUsersModal from "./components/AddUsersModal";
import { UsersDto } from "../../../../types/users.dto";
import ShareModal from "./components/ShareModal";
import PrivateModal from "./components/PrivateModal";
import ModalConfirmDelete from "../../../../components/ModalConfirmDelete";
import { CurrentDto } from "../../../../types/current.dto";
import { setFormData } from "../../../../helpers/formData";
import contentActions from "../../../../store/actions/content";
import { ContentDto } from "../../../../types/content.dto";
import Input from "../../../../components/Form/Input";
import {
  GET_CONTENT_SUCCESS,
  SET_GET_CONTENT,
} from "../../../../store/types/content";
import DropzoneField from "../../../../components/Form/Dropzone";
import { getAllUnits, resetGetAllUnits } from "../../../../store/actions/unit";
import {
  getAllCohorts,
  resetGetAllCohortsData,
} from "../../../../store/actions/cohort";
import { CohortDto } from "../../../../types/cohort.dto";
const Component = ({
  content,
  videoGetStates,
  videoUpdateStates,
  users,
  videoDeleteStates,
  videos,
  current,
  newVideoDataStates,
  user,
  newContentStates,
  newContent,
  updateContentStates,
  getContentStates,
  deleteContentStates,
  cohorts,
}: {
  content: ContentDto;
  videoGetStates: StateDto;
  videoUpdateStates: StateDto;
  users: UsersDto[];
  videoDeleteStates: StateDto;
  videos: VideoDto[];
  current: CurrentDto;
  newVideoDataStates: StateDto;
  user: UsersDto;
  newContentStates: StateDto;
  newContent: ContentDto;
  updateContentStates: StateDto;
  getContentStates: StateDto;
  deleteContentStates: StateDto;
  cohorts: CohortDto[];
}) => {
  const [fetchOn, setFetchOn] = useState<boolean>(false);
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [currentImage, setCurrentImage] = useState<{
    file: any;
    preview: any;
  }>();
  const [contentDataChanged, setContentDataChanged] = useState<{
    name: string;
    description: string;
    link: string;
  }>({ name: "", description: "", link: "" });
  const [imageApi, setImageApi] = useState<string>();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = (values, actions) => {
    if (!content) {
      dispatch(
        contentActions.create(
          setFormData(
            {
              name: values.name,
              description: values.description,
              link: values.link,
              ...(!!currentImage && { picture: currentImage.file }),
              type: "MISC",
              academy: current.id,
              published: true,
              allCohorts: true,
              available: true,
            },
            ["picture"]
          )
        )
      );
    } else {
      dispatch(
        contentActions.update({
          _id: content._id,
          body: setFormData(
            {
              name: values.name,
              description: values.description,
              ...(!!currentImage && { picture: currentImage.file }),
              link: values.link,
              published: true,
              allCohorts: values.allCohorts,
              available: values.available,
              __v: content.__v,
            },
            ["picture"]
          ),
        })
      );
    }
  };

  const onSelectImage = (files) => {
    if (files.length <= 0) {
      setCurrentImage(undefined);

      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setCurrentImage({
      file: files[0],
      preview: URL.createObjectURL(files[0]),
    });
  };

  const menu = [
    {
      contentRole: ["OWNER"],
      Icon: FiUpload,
      toolTipName: !!content?.published
        ? "Editar publicacion"
        : "Publicar Contenido",
      onClick: () => {
        dispatch(showModal("publish-content-modal"));
      },
      IconActive: AiFillEdit,
      activeFunction: () => !!content?.published,
      onClickActive: () => {
        dispatch(showModal("publish-content-modal"));
      },
    },
    {
      Icon: AiFillLock,
      contentRole: ["OWNER"],
      IconActive: AiFillUnlock,
      toolTipName: "Cambiar visibilidad",
      onAvailable: () => !!content && !!content?.published,
      activeFunction: () => !!content?.published,
      onClick: () => {
        dispatch(showModal("private-video"));
      },
      onClickActive: () => {
        dispatch(showModal("private-video"));
      },
    },
    {
      Icon: HiUserAdd,
      contentRole: ["OWNER", "COOWNER"],
      toolTipName: "Añadir Usuarios",
      onClick: () => {
        dispatch(showModal("add-user-video"));
      },
      onAvailable: () => !!content && !!content?.published,
    },
    {
      Icon: FaSave,
      onClick: () => {},
      toolTipName: "Guardar",
      contentRole: ["OWNER", "COOWNER"],
      activeFunction: () =>
        (!content &&
          contentDataChanged.name?.length > 5 &&
          contentDataChanged.description?.length > 0 &&
          !!currentImage &&
          !!contentDataChanged?.link &&
          /^https:\/\/www\.[a-zA-Z0-9-]+\.[a-zA-Z]{2,3}(?:[/?].*)?$/.test(
            contentDataChanged?.link
          )) ||
        (!!content &&
          contentDataChanged.name?.length > 5 &&
          contentDataChanged.description?.length > 0 &&
          !!contentDataChanged.link &&
          /^https:\/\/www\.[a-zA-Z0-9-]+\.[a-zA-Z]{2,3}(?:[/?].*)?$/.test(
            contentDataChanged.link
          ) &&
          (contentDataChanged.link !== content.link ||
            contentDataChanged.description !== content.description ||
            contentDataChanged.name !== content.name)),
      onClickActive: () => {
        if (!content) {
          dispatch(
            contentActions.create(
              setFormData(
                {
                  name: contentDataChanged.name,
                  description: contentDataChanged.description,
                  ...(!!currentImage && { picture: currentImage?.file }),
                  link: contentDataChanged.link,
                  academy: current.id,
                  type: "MISC",
                },
                ["picture"]
              )
            )
          );
        } else {
          dispatch(
            contentActions.update({
              _id: content._id,
              body: setFormData(
                {
                  name: contentDataChanged.name,
                  description: contentDataChanged.description,
                  ...(!!currentImage && { picture: currentImage?.file }),
                  link: contentDataChanged.link,
                  __v: content.__v,
                },
                ["picture"]
              ),
            })
          );
        }
      },
    },
    {
      Icon: BiLink,
      contentRole: ["OWNER", "COOWNER", "READER"],
      toolTipName: "Copiar Link",
      onAvailable: () =>
        !!content && !!content.available && !!content.published,
      onClick: () => {
        navigator.clipboard.writeText(content.link);
        toast.success(`Link copiado`);
      },
    },
    {
      Icon: FaShare,
      toolTipName: "Compartir",
      contentRole: ["OWNER", "COOWNER", "READER"],
      onAvailable: () =>
        !!content && !!content.available && !!content.published,
      onClick: () => {
        dispatch(showModal("share-user-content"));
      },
    },
    {
      Icon: BsFillTrash2Fill,
      contentRole: ["OWNER"],
      toolTipName: "Eliminar Video",
      onAvailable: () => !!content,
      onClick: () => {
        dispatch(showModal("confirm-delete-item-secuencial-modal"));
      },
    },
  ];

  useEffect(() => {
    if (!!params.id) {
      dispatch(contentActions.getOne({ _id: params.id }));
    }
    if (!params.id && !!content) {
      dispatch({ type: SET_GET_CONTENT, response: null });
    }
  }, [params]);

  useEffect(() => {
    if (!!content) {
      setContentDataChanged({
        name: content.name,
        description: content.description,
        link: content.link,
      });
      setImageApi(content.picture);
    } else {
      setContentDataChanged({
        name: "",
        description: "",
        link: "",
      });
      setImageApi(undefined);
    }
  }, [content]);

  useEffect(() => {
    !cohorts &&
      fetchOn &&
      dispatch(
        getAllCohorts({ filterBy: { academy: current.id, active: true } })
      );
  }, [cohorts, fetchOn]);

  useEffect(() => {
    if (cohorts && fetchOn) {
      dispatch(
        getAllUnits({
          filterBy: { cohort: { $in: cohorts.map((cohort) => cohort._id) } },
        })
      );
    }
  }, [cohorts]);
  useEffect(() => {
    if (!cohorts) {
      setFetchOn(true);
    }
  }, [cohorts]);

  useEffect(() => {
    if (newContentStates.success) {
      toast.success("Se creo el contenido exitosamente");
      if (!!newContent) {
        navigate(newContent._id);
      }
      setTimeout(() => {
        dispatch(contentActions.resetCreate());
      }, 1000);
    }
    if (newContentStates.error) {
      toast.error(newContentStates.error);
      setTimeout(() => {
        dispatch(contentActions.resetCreate());
      }, 1000);
    }
  }, [newContentStates]);

  useEffect(() => {
    if (updateContentStates.success) {
      dispatch(contentActions.getOne({ _id: content._id }));
      toast.success(`👌🏼 Contenido guardado`);
      dispatch(contentActions.resetUpdate());
    }
    if (updateContentStates.error) {
      dispatch(contentActions.getOne({ _id: content._id }));
      toast.error(
        `${updateContentStates.error || "No se pudo actualizar contenido"}`
      );
      dispatch(contentActions.resetUpdate());
    }
  }, [updateContentStates]);

  useEffect(() => {
    if (getContentStates.success) {
      dispatch(contentActions.resetGetOne());
    }
    if (getContentStates.error) {
      toast.error(getContentStates.error);
      dispatch(contentActions.resetGetOne());
    }
  }, [getContentStates]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllCohortsData());
      dispatch(resetGetAllUnits());
    };
  }, []);

  if ((!!params.id && !content) || getContentStates.loading)
    return <Loader color="Primary"></Loader>;
  return (
    <>
      <Grid.Container>
        <Formik
          initialValues={initialValues({
            link: contentDataChanged.link,
            name: contentDataChanged.name,
            description: contentDataChanged.description,
          })}
          onSubmit={(values, actions) => {
            onSubmit(values, actions);
          }}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={schema}
          validateOnMount={false}
          enableReinitialize
        >
          {({
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
            setFieldValue,
            isSubmitting,
          }) => {
            return (
              <form
                className="theme-form"
                onSubmit={(event) => {
                  setFormSubmmited(true);
                  handleSubmit(event);
                }}
                id="video-player-create"
              >
                <FlexContainer gap="10px">
                  <div style={{ width: "calc(100% - 50px)" }}>
                    <FlexContainer direction="column" gap="10px">
                      <ContentUploadWrapper height={"500px"}>
                        <Input
                          containerStyles={{
                            width: "100%",
                            maxWidth: "450px",
                            borderColor: "#697482",
                          }}
                          name="link"
                          touched={touched["link"]}
                          value={values["link"]}
                          error={errors["link"]}
                          type="text"
                          placeholder="Link del contenido"
                          onChange={(ev) => {
                            setContentDataChanged((state) => {
                              return { ...state, link: ev.target.value };
                            });
                            handleChange(ev);
                          }}
                          onBlur={handleBlur}
                          options={{
                            skin: "gray",
                            label:
                              "Pon el link al que quieres que los usuarios accedan",
                            marginBottom: 10,
                          }}
                        />
                      </ContentUploadWrapper>
                      <VideoUploaderDescriptionWrapper>
                        <FlexContainer
                          justify="space-between"
                          wrap="wrap"
                          gap="10px"
                        >
                          <FlexContainer direction="column" gap="12px">
                            <FlexContainer
                              direction="column"
                              gap="12px"
                              style={{ width: "360px" }}
                            >
                              <InputDescription
                                containerStyles={{
                                  width: "100%",
                                  maxWidth: "340px",
                                  borderColor: "#697482",
                                }}
                                error={errors["name"]}
                                name="name"
                                touched={touched["name"]}
                                value={values["name"]}
                                type="text"
                                placeholder="Nombre del Video"
                                onChange={(ev) => {
                                  setContentDataChanged((state) => {
                                    return {
                                      ...state,
                                      name: ev.target.value,
                                    };
                                  });
                                  handleChange(ev);
                                }}
                                onBlur={handleBlur}
                                options={{
                                  marginBottom: 10,
                                }}
                              />
                              <TextArea
                                containerStyles={{
                                  width: "100%",
                                  maxWidth: "340px",
                                }}
                                name="description"
                                error={errors["description"]}
                                touched={touched["description"]}
                                value={values["description"]}
                                onChange={(ev) => {
                                  setContentDataChanged((state) => {
                                    return {
                                      ...state,
                                      description: ev.target.value,
                                    };
                                  });
                                  handleChange(ev);
                                }}
                                placeholder="Escriba su descripcion"
                                onBlur={handleBlur}
                                options={{
                                  skin: "base",
                                  marginBottom: 10,
                                }}
                              />
                              <FlexContainer
                                gap="10px"
                                align="center"
                                wrap="wrap"
                              >
                                {!content && (
                                  <>
                                    <Submit
                                      isSubmmiting={videoUpdateStates.loading}
                                      form="video-player-create"
                                      color="Primary"
                                      options={{
                                        type: "filled",
                                        skin: "primary",
                                        size: "lg",
                                      }}
                                    >
                                      Publicar Rapido
                                    </Submit>

                                    <Button
                                      onClick={() => {
                                        dispatch(
                                          showModal("publish-content-modal")
                                        );
                                      }}
                                      type="button"
                                      options={{
                                        type: "filled",
                                        skin: "primary",
                                        size: "lg",
                                      }}
                                      style={{ marginLeft: "10px" }}
                                    >
                                      Publicar
                                    </Button>
                                  </>
                                )}
                              </FlexContainer>
                            </FlexContainer>
                          </FlexContainer>
                          {!currentImage && !imageApi ? (
                            <DropzoneField
                              name="videoImg"
                              onChange={(files) => {
                                onSelectImage(files);
                              }}
                              onBlur={handleBlur}
                              error={
                                !currentImage &&
                                !imageApi &&
                                "Se debe subir una imagen"
                              }
                              touched={touched["videoImg"]}
                              placeholder={
                                "Selecciona el archivo que quieras subir"
                              }
                              options={{ size: "small", openOnClick: true }}
                              validation={{
                                maxFileSize: 1024,
                                maxFiles: 1,
                                accept: "image/*",
                              }}
                            />
                          ) : (
                            <ContentImagePreview
                              height={"200px"}
                              style={{
                                width: "340px",
                                ...(!!currentImage && {
                                  backgroundImage: `url(${currentImage?.preview})`,
                                }),
                                ...(!!imageApi && {
                                  backgroundImage: `url(${imageApi})`,
                                }),
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center center",
                              }}
                            >
                              <RiCloseCircleFill
                                onClick={() => {
                                  setCurrentImage(undefined);
                                }}
                                size={25}
                                fill={"#E91E63"}
                                color={"#fff"}
                                style={{
                                  position: "absolute",
                                  top: "10px",
                                  right: "10px",
                                }}
                              ></RiCloseCircleFill>
                            </ContentImagePreview>
                          )}
                        </FlexContainer>
                      </VideoUploaderDescriptionWrapper>
                    </FlexContainer>
                  </div>
                  <SideBar content={content} menu={menu}></SideBar>
                </FlexContainer>
              </form>
            );
          }}
        </Formik>
      </Grid.Container>

      <PublishVideoModal
        contentDataChanged={contentDataChanged}
        imageApi={imageApi}
        currentImage={currentImage}
        content={content}
      ></PublishVideoModal>

      {!!content && <AddUsersModal content={content}></AddUsersModal>}
      {!!content && <ShareModal content={content}></ShareModal>}
      {!!content && (
        <PrivateModal
          content={{
            _id: content._id,
            published: content.published,
            name: contentDataChanged.name,
            description: contentDataChanged.description,
            __v: content.__v,
          }}
        ></PrivateModal>
      )}

      {!!content && (
        <ModalConfirmDelete
          bntConfig={{
            content: "Eliminar",
            style: {
              style: { width: "170px", height: "40px" },
              color: "Danger",
              options: {
                type: "filled",
                skin: "danger",
                size: "lg",
                marginBottom: "0px",
              },
            },
          }}
          sucessAction={() => navigate("/dashboard/contents/variety")}
          states={deleteContentStates}
          description={`El Contenido se eliminara para siempre del sistema.`}
          title={
            <span
              style={{
                color: "#697482",
                fontSize: "20px",
                fontWeight: "600",
              }}
            >
              {`¿Seguro que quiere eliminar a ${content.name}?`}
            </span>
          }
          elementActions={contentActions.deleteOne({ _id: content._id })}
          resetAction={contentActions.resetDeleteOne}
          resetState={() => {}}
        ></ModalConfirmDelete>
      )}
    </>
  );
};

const states = ({
  videoStore,
  usersStore,
  currentStore,
  userStore,
  contentStore,
  cohortStore,
}) => {
  const { states: videoGetStates } = videoStore.video;
  const { states: videoUpdateStates } = videoStore.update;
  const { states: videoDeleteStates } = videoStore.delete;
  const { states: newContentStates, data: newContent } = contentStore.create;
  const { data: users } = usersStore.all;
  const { data: videos } = videoStore.all;
  const { data: current } = currentStore;
  const { data: user } = userStore;
  const { data: content } = contentStore.content;
  const { states: newVideoDataStates } = videoStore.new;
  const { states: updateContentStates } = contentStore.update;
  const { states: getContentStates } = contentStore.content;
  const { states: deleteContentStates } = contentStore.delete;
  const { data: cohorts } = cohortStore.allCohorts;
  return {
    videoGetStates,
    videoUpdateStates,
    users,
    videoDeleteStates,
    videos,
    cohorts,
    current,
    newVideoDataStates,
    user,
    content,
    newContentStates,
    newContent,
    updateContentStates,
    getContentStates,
    deleteContentStates,
  };
};

export default connect(states)(Component);
