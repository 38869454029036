import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Modal from "../../../../../../components/Modal";
import { Formik } from "formik";
import { Center } from "../../../../../../components/StyledComponents";
import {
  initialValues,
  schema,
} from "../../../../../../constants/form/user/profile/visibility";
import Grid from "../../../../../../components/Grid";
import Button from "../../../../../../components/Button";
import Submit from "../../../../../../components/Form/Submit";
import { toast } from "react-toastify";
import { hideModal } from "../../../../../../store/actions/modal";
import ReactSelect from "../../../../../../components/Form/ReactSelect";
import usersActions from "../../../../../../store/actions/users";
import { updateUser } from "../../../../../../store/actions/user";
const Component = ({ updateStates, current, users, user }) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const dispatch = useDispatch();

  const onSubmit = ({ values, actions }) => {
    dispatch(updateUser({ _id: user._id, body: { ...values } }));
    setFormActions(actions);
  };

  useEffect(() => {
    if (updateStates.success) {
      toast("👌🏼 Usuario editado correctamente", {
        containerId: "submit",
        type: "success",
        toastId: "submitsucceful",
      });

      setTimeout(() => {
        dispatch(usersActions.resetUpdate());
        dispatch(hideModal());
        formActions?.resetForm();
      }, 1000);
    }

    if (updateStates.error) {
      toast(`😱 ${updateStates.error}`, {
        containerId: "submit",
        type: "error",
        toastId: "submiterror",
      });

      setTimeout(() => {
        dispatch(usersActions.resetUpdate());
      }, 1000);
    }
  }, [updateStates]);

  return (
    <Modal name="edit-profile-visibility" title="Visibilidad">
      <Formik
        initialValues={{
          ...initialValues(user),
        }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="edit-profile-visibility-form"
            >
              <Grid.Row>
                <Grid.Col>
                  <ReactSelect
                    name="private"
                    error={errors["private"]}
                    touched={touched["private"]}
                    items={[
                      {
                        label: "Privado",
                        value: true,
                      },
                      {
                        label: "Publico",
                        value: false,
                      },
                    ]}
                    placeholder="Seleccione un tipo"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "Visibilidad",
                      marginBottom: 20,
                      skin: "gray",
                    }}
                  />
                </Grid.Col>
              </Grid.Row>

              <Grid.Row>
                <Grid.Col>
                  <Center gap={"70px"}>
                    <Button
                      onClick={resetForm}
                      type="button"
                      options={{
                        type: "outline",
                        skin: "gray",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                      style={{ marginLeft: "10px" }}
                    >
                      Cancelar
                    </Button>

                    <Submit
                      isSubmmiting={isSubmitting}
                      form="edit-profile-visibility-form"
                      color="Primary"
                      options={{
                        type: "filled",
                        skin: "primary",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                    >
                      Confirmar
                    </Submit>
                  </Center>
                </Grid.Col>
              </Grid.Row>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const states = ({ usersStore, currentStore, userStore }) => {
  const { states: updateStates } = usersStore.update;
  const { data: current } = currentStore;
  const { data: users } = usersStore.all;
  const { data: user } = userStore;
  return {
    updateStates,
    current,
    users,
    user,
  };
};

export default connect(states)(Component);
