const permissions = {
  static: [
    "route:table:preview",
    "route:table:create",
    "route:table:edit",

    "route:calendar:preview",

    "route:analytics:preview",

    "route:marketplace:preview",

    "route:team:preview",
    "route:team:edit",
    "route:team:create",
  ],
  dynamic: {
    "op:edit:state": ({ status }) => {
      if (!status) return false;
      return ["requested", "pending", "inProgress", "done"].includes(status);
    },
  },
};

export default permissions;
