import { ImageDto } from "../../types/image.dto";
import { PaginationDto } from "../../types/pagination.dto";

export const GET_ALL_IMAGES = "GET_ALL_IMAGE";
export const GET_ALL_IMAGES_ERROR = "GET_ALL_IMAGE_ERROR";
export const GET_ALL_IMAGES_SUCCESS = "GET_ALL_IMAGE_SUCCESS";
export const RESET_GET_ALL_IMAGE_DATA = "RESET_GET_ALL_IMAGE_DATA";
export const GET_IMAGE = "GET_Image";
export const GET_IMAGE_ERROR = "GET_Image_ERROR";
export const GET_IMAGE_SUCCESS = "GET_Image_SUCCESS";
export const RESET_GET_IMAGE = "RESET_GET_IMAGE";
export const SET_GET_IMAGE = "SET_GET_IMAGE";
export const NEW_IMAGE = "NEW_Image";
export const NEW_IMAGE_ERROR = "NEW_Image_ERROR";
export const NEW_IMAGE_SUCCESS = "NEW_Image_SUCCESS";
export const RESET_NEW_IMAGE = "RESET_NEW_Image";
export const UPDATE_IMAGE = "UPDATE_Image";
export const UPDATE_IMAGE_ERROR = "UPDATE_Image_ERROR";
export const UPDATE_IMAGE_SUCCESS = "UPDATE_Image_SUCCESS";
export const RESET_UPDATE_IMAGE = "RESET_UPDATE_Image";
export const DELETE_IMAGE = "DELETE_Image";
export const DELETE_IMAGE_ERROR = "DELETE_Image_ERROR";
export const DELETE_IMAGE_SUCCESS = "DELETE_Image_SUCCESS";
export const RESET_DELETE_IMAGE = "RESET_DELETE_Image";
export const UPLOAD_MEDIA = "UPLOAD_MEDIA";
export const UPDATE_IMAGE_LOADING = "UPDATE_IMAGE_LOADING";
export const ADD_USER_TO_IMAGE = "ADD_USER_TO_IMAGE";
export const SHARED_IMAGE = "SHARED_IMAGE";
export const UPDATE_MANY_IMAGE = "UPDATE_MANY_IMAGE";
export const UPDATE_MANY_IMAGE_ERROR = "UPDATE_MANY_IMAGE_ERROR";
export const UPDATE_MANY_IMAGE_SUCCESS = "UPDATE_MANY_IMAGE_SUCCESS";
export const RESET_UPDATE_MANY_IMAGE = "RESET_UPDATE_MANY_IMAGE";

export interface SharedImage {
  type: typeof SHARED_IMAGE;
  payload: any;
}
export interface GetAllImages {
  type: typeof GET_ALL_IMAGES;
  payload: PaginationDto;
}
export interface ResetGetAllImageData {
  type: typeof RESET_GET_ALL_IMAGE_DATA;
  payload: null;
}

export interface GetAllImagesSuccess {
  type: typeof GET_ALL_IMAGES_SUCCESS;
  payload: ImageDto[];
}

export interface SetGetImage {
  type: typeof SET_GET_IMAGE;
  payload: ImageDto | null;
}

export interface GetAllImagesError {
  type: typeof GET_ALL_IMAGES_ERROR;
  payload: boolean | string;
}

export interface AddUserToImage {
  type: typeof ADD_USER_TO_IMAGE;
  payload: any;
}

export interface GetImage {
  type: typeof GET_IMAGE;
  payload: { id: string };
}

export interface GetImageSuccess {
  type: typeof GET_IMAGE_SUCCESS;
  payload: ImageDto;
}

export interface UpdateManyImage {
  type: typeof UPDATE_MANY_IMAGE;
  payload: any;
}

export interface UpdateManyImageSuccess {
  type: typeof UPDATE_MANY_IMAGE_SUCCESS;
  payload: ImageDto[];
}

export interface UpdateManyImageError {
  type: typeof UPDATE_MANY_IMAGE_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateManyImage {
  type: typeof RESET_UPDATE_MANY_IMAGE;
  payload: null;
}

export interface GetImageError {
  type: typeof GET_IMAGE_ERROR;
  payload: boolean | string;
}

export interface NewImage {
  type: typeof NEW_IMAGE;
  payload: ImageDto;
}

export interface NewIMAGEuccess {
  type: typeof NEW_IMAGE_SUCCESS;
  payload: ImageDto;
}

export interface ResetGetImage {
  type: typeof RESET_GET_IMAGE;
  payload: null;
}

export interface NewImageError {
  type: typeof NEW_IMAGE_ERROR;
  payload: boolean | string;
}

export interface UploadMedia {
  type: typeof UPLOAD_MEDIA;
  payload: any;
}

export interface ResetNewImage {
  type: typeof RESET_NEW_IMAGE;
  payload: null;
}

export interface UpdateImage {
  type: typeof UPDATE_IMAGE;
  payload: any;
}

export interface UpdateImageLoading {
  type: typeof UPDATE_IMAGE_LOADING;
  payload: ImageDto;
}

export interface UpdateIMAGEuccess {
  type: typeof UPDATE_IMAGE_SUCCESS;
  payload: ImageDto;
}

export interface UpdateImageError {
  type: typeof UPDATE_IMAGE_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateImage {
  type: typeof RESET_UPDATE_IMAGE;
  payload: null;
}

export interface DeleteImage {
  type: typeof DELETE_IMAGE;
  payload: { _id: string };
}

export interface DeleteIMAGEuccess {
  type: typeof DELETE_IMAGE_SUCCESS;
  payload: null;
}

export interface DeleteImageError {
  type: typeof DELETE_IMAGE_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteImage {
  type: typeof RESET_DELETE_IMAGE;
  payload: null;
}

export interface State {
  all: {
    data: ImageDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  image: {
    data: ImageDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  new: {
    data: ImageDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  updateMany: {
    data: null | ImageDto[];
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  delete: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  update: {
    data: null | ImageDto;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  all: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  image: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  updateMany: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  new: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  delete: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  update: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllImages
  | GetAllImagesSuccess
  | GetAllImagesError
  | ResetGetAllImageData
  | GetImage
  | GetImageSuccess
  | GetImageError
  | NewImage
  | NewIMAGEuccess
  | NewImageError
  | ResetNewImage
  | UpdateImage
  | UpdateIMAGEuccess
  | UpdateImageError
  | ResetUpdateImage
  | DeleteImage
  | DeleteIMAGEuccess
  | DeleteImageError
  | ResetDeleteImage
  | UploadMedia
  | UpdateImageLoading
  | ResetGetImage
  | AddUserToImage
  | SharedImage
  | SetGetImage
  | UpdateManyImage
  | UpdateManyImageSuccess
  | UpdateManyImageError
  | ResetUpdateManyImage;
