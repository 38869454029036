const permissions = {
  static: [
    "route:contents:preview",
    "route:contents:videos:preview",
    "route:contents:cdocs:preview",
    "route:contents:roadmaps:preview",
    "route:contents:variety:preview",
    "route:contents:roadmaps:preview",
    "route:contents:slideshows:preview",
    "route:contents:listens:preview",
    "route:cohort:preview",
    "route:cohort:list:preview",
    "route:cohort:events:preview",
    "route:cohort:customize:preview",

    "route:calificate:preview",
    "route:calificate:evaluate:preview",
    "route:calificate:criterions:preview",
    "route:calificate:criterions:create",
    "route:calificate:criterions:edit",
    "route:calificate:subcriterion:preview",
    "route:calificate:subcriterion:create",
    "route:calificate:subcriterion:edit",
    "route:contents:roadmaps:preview",

    "route:management:preview",
    "route:management:marketplace:preview",
    "route:management:calendar:preview",

    "route:dashboard:preview",
    "route:dashboard:cohorts:preview",
    "route:dashboard:cohorts:cohort:preview",
    "route:dashboard:cohorts:individual:preview",

    "route:notification:preview",

    "route:calendar:preview",

    "route:education:preview",
    "route:education:edit",
    "route:education:create",

    "route:cohort:preview",

    "route:analytics:preview",
    "route:cdocs:list",

    "route:contents:preview",

    "route:marketplace:preview",
  ],
  dynamic: {
    "op:edit:state": ({ status }) => {
      if (!status) return false;
      return ["requested", "pending", "inProgress", "done"].includes(status);
    },
  },
};

export default permissions;
