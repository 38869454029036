import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Modal from "../../../../../../components/Modal";
import { Formik } from "formik";
import { Center } from "../../../../../../components/StyledComponents";
import {
  initialValues,
  schema,
} from "../../../../../../constants/form/cdoc/addUser";
import Grid from "../../../../../../components/Grid";
import Button from "../../../../../../components/Button";
import Submit from "../../../../../../components/Form/Submit";
import { toast } from "react-toastify";
import { hideModal } from "../../../../../../store/actions/modal";

import { StateDto } from "../../../../../../types/states.dto";
import {
  addUserToListen,
  resetUpdateListen,
} from "../../../../../../store/actions/listen";
import DinamicFieldListIn from "../../../../../../components/Form/DinamicFieldListIn";
import { UsersDto } from "../../../../../../types/users.dto";
import { AcademyDto } from "../../../../../../types/academy.dto";
import { CurrentDto } from "../../../../../../types/current.dto";
import { ListenDto } from "../../../../../../types/listen.dto";
import { fetchFilterUsersByAcademy } from "../../../../../../helpers/usersFromAcademies";
import { UserDto } from "../../../../../../types/user.dto";

const Component = ({
  users,
  updateStates,
  listen,
  academies,
  current,
  user,
}: {
  users: UsersDto[];
  updateStates: StateDto;
  listen: ListenDto;
  academies: AcademyDto[];
  current: CurrentDto;
  user: UserDto;
}) => {
  const [currentUsers, setCurrentUsers] = useState<UsersDto[]>();
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const [filteredValues, setFilteredValues] = useState<any>();
  const dispatch = useDispatch();

  const onSubmit = ({ values, actions }) => {
    for (const value of values.users) {
      dispatch(
        addUserToListen({
          _id: listen._id,
          role: value.role,
          __v: listen.__v,
          ...(value.field === "email" && { email: value.value }),
          ...(value.field === "username" && { username: value.value }),
        })
      );
    }

    setFormActions(actions);
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchFilterUsersByAcademy({
        academyId: current.id,
        filterUsers: (user) => user.active && (user.username || user.email),
        filterUsersAcademy: (userFounded) =>
          userFounded.user !== user._id && !userFounded.roleEnd,
      });
      setCurrentUsers(response);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (currentUsers) {
      const filteredValues = currentUsers
        .map((user, index) => {
          const objUser = {
            userName: {
              ...(!!user.username && {
                label: user.username,
                value: {
                  id: user._id,
                  value: user.username,
                  field: "username",
                  role: "READER",
                },
              }),
            },
            email: {
              ...(!!user.email && {
                label: user.email,
                value: {
                  id: user._id,
                  value: user.email,
                  field: "email",
                  role: "READER",
                },
              }),
            },
          };

          return Object.values(objUser).filter(
            (obj) => Object.values(obj).length !== 0
          );
        })
        .flat(1);
      setFilteredValues(filteredValues);
    }
  }, [currentUsers]);

  useEffect(() => {
    if (updateStates.success) {
      setTimeout(() => {
        dispatch(resetUpdateListen());
        dispatch(hideModal());
        formActions?.resetForm();
      }, 1000);
    }

    if (updateStates.error) {
      setTimeout(() => {
        dispatch(resetUpdateListen());
      }, 1000);
    }
  }, [updateStates]);

  useEffect(() => {
    if (!!users && !!academies) {
      const filteredValues = users
        .filter(
          (user) =>
            user.active &&
            (user.username || user.email) &&
            !!academies
              .find((academy) => academy._id === current.id)
              ?.users.find(
                (userFounded) =>
                  userFounded.user === user._id &&
                  userFounded.role !== "STUDENT" &&
                  userFounded.role !== "OBSERVER" &&
                  !userFounded.roleEnd
              )
        )
        .map((user, index) => {
          const objUser = {
            userName: {
              ...(!!user.username && {
                label: user.username,
                value: {
                  id: user._id,
                  value: user.username,
                  field: "username",
                  role: "READER",
                },
              }),
            },
            email: {
              ...(!!user.email && {
                label: user.email,
                value: {
                  id: user._id,
                  value: user.email,
                  field: "email",
                  role: "READER",
                },
              }),
            },
          };

          return Object.values(objUser).filter(
            (obj) => Object.values(obj).length !== 0
          );
        })
        .flat(1);
      setFilteredValues(filteredValues);
    }
  }, [users, academies]);

  return (
    <Modal name="add-user-listen" title={`Añadir usuarios a Listen`}>
      <Formik
        initialValues={initialValues({})}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="invite-academy-modal-form"
            >
              <Grid.Row>
                <Grid.Col>
                  <DinamicFieldListIn
                    name="users"
                    error={errors["users"]}
                    touched={touched["users"]}
                    value={values["users"]}
                    onChange={handleChange}
                    valueFieldName="id"
                    onBlur={handleBlur}
                    pickFunction={(array, valuePicked, fieldName) => {
                      const firstResult = array.filter(
                        (item) =>
                          valuePicked[fieldName] === item.value[fieldName]
                      );

                      if (
                        firstResult[0].value["value"] !== valuePicked["value"]
                      ) {
                        return firstResult[1].value;
                      } else {
                        return firstResult[0].value;
                      }
                    }}
                    options={{
                      skin: "gray",
                      type: "select",
                      height: "95px",
                      insideDataList: [
                        { value: "COOWNER", label: "Co-Owner" },
                        { value: "EDITOR", label: "Editor" },
                        { value: "READER", label: "Observador" },
                      ],
                      setFieldValue: setFieldValue,
                      ...(!!filteredValues && {
                        data: filteredValues.map((user) => {
                          return { value: user.value, label: user.label };
                        }),
                      }),
                      externalData: true,
                      loading: !filteredValues,
                      inputLabel: "Seleccionar usuarios para Listen",
                      inputPlaceholder: "Selecciona un usuario",
                    }}
                  />
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col>
                  <Center gap={"10px"}>
                    <Submit
                      isSubmmiting={isSubmitting}
                      form="invite-academy-modal-form"
                      color="Primary"
                      style={{ width: "170px" }}
                      options={{
                        type: "filled",
                        skin: "primary",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                    >
                      Confirmar
                    </Submit>
                    <Button
                      onClick={resetForm}
                      type="button"
                      options={{
                        type: "outline",
                        skin: "danger",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                      style={{ marginLeft: "10px", width: "170px" }}
                    >
                      Reestablecer
                    </Button>
                  </Center>
                </Grid.Col>
              </Grid.Row>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const states = ({
  academyStore,
  cohortStore,
  currentStore,
  unitStore,
  usersStore,
  userStore,
}) => {
  const { data: academies } = academyStore.all;
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: current } = currentStore;
  const { data: units } = unitStore.allUnits;
  const { states: updateStates } = unitStore.updateUnit;
  const { data: users } = usersStore.all;
  const { data: user } = userStore;
  return { updateStates, users, academies, current, user };
};

export default connect(states)(Component);
