import { put, call, takeLatest } from "redux-saga/effects";
import services from "../../services";
import {
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_IN_ERROR,
  AUTO_SIGN_IN,
  SIGN_OUT,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_ERROR,
  SignIn,
  SignUp,
  SIGN_UP,
  SIGN_UP_ERROR,
  SIGN_UP_SUCCESS,
  RECOVER_PASSWORD,
  RECOVER_PASSWORD_SUCCESS,
  RECOVER_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  SIGN_UP_WITH_SOCIAL_SUCCESS,
  SIGN_UP_WITH_SOCIAL,
  SIGN_IN_WITH_SOCIAL,
} from "../types/auth";

function* signIn({ payload }: SignIn) {
  try {
    const { response, error } = yield call(services.auth.SignIn, payload);
    if (error) {
      yield put({
        type: SIGN_IN_ERROR,
        payload: error.response.data?.message || "Error",
      });
    } else {
      yield put({ type: SIGN_IN_SUCCESS, payload: response });
    }
  } catch {}
}

function* signUp({ payload }: SignUp) {
  const { response, error } = yield call(services.auth.SignUp, payload);
  if (error) {
    yield put({
      type: SIGN_UP_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: SIGN_UP_SUCCESS, payload: response });
  }
}

function* autoSignIn() {
  try {
    const { response, error } = yield call(services.auth.AutoSignIn);
    if (error) {
      yield put({
        type: SIGN_IN_ERROR,
        payload: error.response.data?.message || "Error",
      });
    } else {
      yield put({ type: SIGN_IN_SUCCESS, payload: response });
    }
  } catch {}
}
function* signInSocial({ payload }) {
  try {
    const { response, error } = yield call(services.auth.SignSocial, payload);
    if (error) {
      yield put({
        type: SIGN_IN_ERROR,
        payload: error.response.data?.message || "Error",
      });
    } else {
      yield put({ type: SIGN_IN_SUCCESS, payload: response });
    }
  } catch {}
}
function* signOut() {
  try {
    const result = yield call(services.auth.SignOut);
    yield put({ type: SIGN_OUT_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: SIGN_OUT_ERROR, payload: error });
  }
}

function* recoverPassword({ payload }): any {
  try {
    const { response, error } = yield call(
      services.auth.recoverPassword,
      payload
    );

    if (error) {
      yield put({
        type: RECOVER_PASSWORD_ERROR,
        payload: error.response.data?.message || "Error",
      });
    } else {
      yield put({ type: RECOVER_PASSWORD_SUCCESS, payload: response });
    }
  } catch (error) {
    yield put({
      type: RECOVER_PASSWORD_ERROR,
      payload: "Error al intentar recuperar la contraseña.",
    });
  }
}

function* signUpSocial({ payload }) {
  const { response, error } = yield call(services.auth.SignSocial, payload);

  if (error) {
    yield put({
      type: SIGN_UP_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: SIGN_UP_SUCCESS, payload: response });
    yield put({ type: SIGN_UP_WITH_SOCIAL_SUCCESS, payload: response });
  }
}

function* resetPassword({ payload }): any {
  try {
    const { response, error } = yield call(
      services.auth.resetPassword,
      payload
    );

    if (error) {
      yield put({
        type: RESET_PASSWORD_ERROR,
        payload: error.response.data?.message || "Error",
      });
    } else {
      yield put({ type: RESET_PASSWORD_SUCCESS, payload: response });
    }
  } catch (error) {
    yield put({
      type: RESET_PASSWORD_ERROR,
      payload: "Error al intentar cambiar la contraseña.",
    });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(SIGN_IN, signIn);
  yield takeLatest<any>(SIGN_OUT, signOut);
  yield takeLatest<any>(AUTO_SIGN_IN, autoSignIn);
  yield takeLatest<any>(SIGN_UP, signUp);
  yield takeLatest<any>(RECOVER_PASSWORD, recoverPassword);
  yield takeLatest<any>(RESET_PASSWORD, resetPassword);
  yield takeLatest<any>(SIGN_IN_WITH_SOCIAL, signInSocial);
  yield takeLatest<any>(SIGN_UP_WITH_SOCIAL, signUpSocial);
}
