// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DraftEditor-root {
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: text;
  padding: 16px;
  margin-bottom: 2em;
  border-radius: 10px;
  box-shadow: 0 0 2px 0 rgba(21, 115, 229, 0.16);
  background: #fefefe;
  height: 700px;
  overflow: auto;
}
.DraftEditor-root::-webkit-scrollbar {
  background: none;
  border-radius: 5px;
}
.DraftEditor-root::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #d9d9d9;
}
.DraftEditor-root::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
}
.DraftEditor-root:global(.public-DraftEditor-content) {
  min-height: 140px;
}

/* .public-DraftStyleDefault-block > span {
  display: inline-block;
} */

div[data-editor]:nth-child(1),
h1[data-editor]:nth-child(1),
h2[data-editor]:nth-child(1),
h3[data-editor]:nth-child(1) {
  margin-bottom: 4px;
}

div[data-editor]:not(:nth-child(1)),
h2[data-editor]:not(:nth-child(1)),
h3[data-editor]:not(:nth-child(1)) {
  margin: 4px 0;
}

h1[data-editor]:not(:nth-child(1)) {
  margin: 10px 0;
}

/* .buttons div:has(button) {
  box-shadow: 0 0 2px 0 rgba(21, 115, 229, 0.16);
  border-radius: 5px;
  color: #000;
}

.buttons button[role="button"]:focus-visible {
  background-color: #1573e5;
  color: #fff;
}
 */
`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/Cdocs/Document/style.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,8CAA8C;EAC9C,mBAAmB;EACnB,aAAa;EACb,cAAc;AAChB;AACA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,uBAAuB;EACvB,kBAAkB;AACpB;AACA;EACE,iBAAiB;AACnB;;AAEA;;GAEG;;AAEH;;;;EAIE,kBAAkB;AACpB;;AAEA;;;EAGE,aAAa;AACf;;AAEA;EACE,cAAc;AAChB;;AAEA;;;;;;;;;;EAUE","sourcesContent":[".DraftEditor-root {\n  box-sizing: border-box;\n  border: 1px solid #ddd;\n  cursor: text;\n  padding: 16px;\n  margin-bottom: 2em;\n  border-radius: 10px;\n  box-shadow: 0 0 2px 0 rgba(21, 115, 229, 0.16);\n  background: #fefefe;\n  height: 700px;\n  overflow: auto;\n}\n.DraftEditor-root::-webkit-scrollbar {\n  background: none;\n  border-radius: 5px;\n}\n.DraftEditor-root::-webkit-scrollbar-thumb {\n  border-radius: 2px;\n  background: #d9d9d9;\n}\n.DraftEditor-root::-webkit-scrollbar-track {\n  background: transparent;\n  border-radius: 5px;\n}\n.DraftEditor-root:global(.public-DraftEditor-content) {\n  min-height: 140px;\n}\n\n/* .public-DraftStyleDefault-block > span {\n  display: inline-block;\n} */\n\ndiv[data-editor]:nth-child(1),\nh1[data-editor]:nth-child(1),\nh2[data-editor]:nth-child(1),\nh3[data-editor]:nth-child(1) {\n  margin-bottom: 4px;\n}\n\ndiv[data-editor]:not(:nth-child(1)),\nh2[data-editor]:not(:nth-child(1)),\nh3[data-editor]:not(:nth-child(1)) {\n  margin: 4px 0;\n}\n\nh1[data-editor]:not(:nth-child(1)) {\n  margin: 10px 0;\n}\n\n/* .buttons div:has(button) {\n  box-shadow: 0 0 2px 0 rgba(21, 115, 229, 0.16);\n  border-radius: 5px;\n  color: #000;\n}\n\n.buttons button[role=\"button\"]:focus-visible {\n  background-color: #1573e5;\n  color: #fff;\n}\n */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
