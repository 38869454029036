import {
  GetAllVideos,
  GET_ALL_VIDEOS,
  GetVideo,
  GET_VIDEO,
  UpdateVideo,
  UPDATE_VIDEO,
  DeleteVideo,
  DELETE_VIDEO,
  GetVideoSuccess,
  GET_VIDEO_SUCCESS,
  NewVideo,
  NEW_VIDEO,
  ResetNewVideo,
  RESET_NEW_VIDEO,
  ResetUpdateVideo,
  RESET_UPDATE_VIDEO,
  ResetDeleteVideo,
  RESET_DELETE_VIDEO,
  UploadMedia,
  UPLOAD_MEDIA,
  RESET_GET_VIDEO,
  ADD_USER_TO_VIDEO,
  SHARED_VIDEO,
  RESET_UPDATE_MANY_VIDEO,
  UPDATE_MANY_VIDEO,
  RESET_GET_ALL_VIDEO_DATA,
} from "../types/video";

export const getAllVideos = (payload): GetAllVideos => {
  return { type: GET_ALL_VIDEOS, payload };
};

export const resetGetAllVideos = () => {
  return { type: RESET_GET_ALL_VIDEO_DATA, payload: null };
};

export const getVideo = (id): GetVideo => {
  return { type: GET_VIDEO, payload: id };
};

export const resetGetVideo = () => {
  return { type: RESET_GET_VIDEO, payload: null };
};

export const setSelectedVideo = (payload): GetVideoSuccess => {
  return { type: GET_VIDEO_SUCCESS, payload };
};

export const newVideo = (payload): NewVideo => {
  return { type: NEW_VIDEO, payload };
};
export const uploadMediaVideo = (payload): UploadMedia => {
  return { type: UPLOAD_MEDIA, payload };
};
export const resetNewVideo = (): ResetNewVideo => {
  return { type: RESET_NEW_VIDEO, payload: null };
};

export const updateManyVideo = (payload) => {
  return { type: UPDATE_MANY_VIDEO, payload };
};

export const resetUpdateManyVideo = () => {
  return { type: RESET_UPDATE_MANY_VIDEO, payload: null };
};

export const updateVideo = (payload): UpdateVideo => {
  return { type: UPDATE_VIDEO, payload };
};

export const resetUpdateVideo = (): ResetUpdateVideo => {
  return { type: RESET_UPDATE_VIDEO, payload: null };
};

export const addUserToVideo = (payload) => {
  return { type: ADD_USER_TO_VIDEO, payload };
};

export const deleteVideo = (payload): DeleteVideo => {
  return { type: DELETE_VIDEO, payload };
};

export const resetDeleteVideo = (): ResetDeleteVideo => {
  return { type: RESET_DELETE_VIDEO, payload: null };
};

export const shareVideo = (payload) => {
  return { type: SHARED_VIDEO, payload };
};
