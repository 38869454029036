import React, { useEffect, useState } from "react";
import Grid from "../Grid";
import {
  SelectMenuItem,
  SelectMenuItemTitle,
  SelectMenuWrapper,
} from "./style";
import { FlexContainer } from "../StyledComponents";

const Component = ({
  selectorSizes,
  childrenSizes,
  selectorItems,
  noShowMenu,
}) => {
  const [activeItem, setActiveItem] = useState(selectorItems[0]);

  useEffect(() => {
    if (noShowMenu) {
      setActiveItem(selectorItems[0]);
    }
  }, [noShowMenu]);
  return (
    <Grid.Row>
      <Grid.Col {...selectorSizes}>
        {!noShowMenu && (
          <SelectMenuWrapper>
            {selectorItems.map((selectorItem) => {
              return (
                <SelectMenuItem
                  className={selectorItem.id === activeItem.id ? "active" : ""}
                  onClick={() => {
                    setActiveItem(selectorItem);
                  }}
                  key={selectorItem.id}
                >
                  <FlexContainer align="center" gap="6px">
                    <SelectMenuItemTitle
                      className={
                        selectorItem.id === activeItem.id ? "active" : ""
                      }
                    >
                      {selectorItem.label}
                    </SelectMenuItemTitle>
                    {selectorItem.After && selectorItem.After}
                  </FlexContainer>
                </SelectMenuItem>
              );
            })}
          </SelectMenuWrapper>
        )}
      </Grid.Col>
      <Grid.Col {...childrenSizes}>{activeItem.Component}</Grid.Col>
    </Grid.Row>
  );
};

export default Component;
