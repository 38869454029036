import { button } from "./button";
import { dropdown } from "./dropdown";
import { form } from "./form";
import { grid } from "./grid";
import { link } from "./links";

export const components = {
  button,
  dropdown,
  form,
  grid,
  link,
};
