import styled, { css } from "styled-components";
import cross from "../../assets/icons/cross.svg";
import minus from "../../assets/icons/minus.svg";
import plus from "../../assets/icons/plus.svg";
import edit from "../../assets/icons/edit.svg";

export const PageTitle = styled("h1")`
  font-size: 20px;
  font-weight: 500;
  color: #273241;
  margin-top: 0px;
  margin-bottom: 40px;
  text-align: left;
`;

export const CustomText = css`
  ${({ weight, size, color }) => `
  font-weight:${weight};
  font-size:${size}px;
  color:${color};
`}
`;

export const EllipsisText = css`
  ${(props) =>
    props.hideLine
      ? `display: -webkit-box;
  -webkit-line-clamp: ${props.hideLine}; 
  -webkit-box-orient: vertical;
  overflow:hidden;
text-overflow: ellipsis;
white-space: normal;`
      : ""}
`;

export const Input = styled("input")`
  border: none;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 30px;
  outline: none;
  width: 100%;
  min-height: calc(40px - 2px);
  height: auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
  background: #ffffff inset !important;

  color: #4f26a2;
`;

export const Separator = styled("div")`
  height: ${(props) => props.size}px;
`;

export const ButtonFilter = styled("div")`
  padding: 6px;
  border-radius: 10px;
  width: 32px;
  height: 32px;
  border: solid 1px ${(props) => props.theme.colors.Primary};
  background-color: #fff;
  color: ${(props) => props.theme.colors.Primary};
  &:hover {
    background-color: ${(props) => props.theme.colors.Primary};
    color: #fff;
  }
`;

export const TopIcon = styled("div")`
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 2px;
`;

export const FlexContainer = styled("div")`
  display: flex;
  align-items: ${(props) => (!!props.align ? props.align : "stretch")};
  justify-content: ${(props) => (!!props.justify ? props.justify : "stretch")};
  flex-direction: ${(props) => (!!props.direction ? props.direction : "row")};
  flex-wrap: ${(props) => (!!props.wrap ? props.wrap : "nowrap")};
  gap: ${(props) => props.gap};
  ${(props) => !!props.style && props.style};
`;
export const SectionTitle = styled("h2")`
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.Black};
`;

export const LabelScore = styled("h5")`
  font-size: 16px;
  font-weight: 500;
  opacity: 0.8;
  color: ${({ theme }) => theme.colors.Black};
`;

// export const ScrollTable = styled("div")`
//   width: 100%;
//   display: flex;
//   ::-webkit-scrollbar {
//     width: 20px;
//     height: 15px;
//   }
//   ::-webkit-scrollbar-track {
//     border-radius: 10px;
//     background: rgba(0, 0, 0, 0.1);
//   }
//   ::-webkit-scrollbar-thumb {
//     border-radius: 10px;
//     background: rgba(0, 0, 0, 0.2);
//   }
//   ::-webkit-scrollbar-thumb:hover {
//     background: rgba(0, 0, 0, 0.4);
//   }
//   ::-webkit-scrollbar-thumb:active {
//     background: rgba(0, 0, 0, 0.9);
//   }
// `;

export const AddButton = styled("button")`
  border: 0;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.Primary};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:before {
    content: "";
    mask: url(${plus});
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-position: center;
    width: 100%;
    height: 100%;
    background-color: white;
  }
`;

export const SearchMagnifying = styled("span")`
  position: relative;
  display: inline-block;
  background: #fff;
  border-radius: 30px;
  height: 10px;
  width: 10px;
  border: 2px solid #888;

  &:after {
    content: "";
    height: 2px;
    width: 6px;
    background: #888;
    position: absolute;
    top: 7px;
    left: 5px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
  }
`;

export const ScrollingSection = styled("div")`
  width: 100%;
  overflow-y: auto;
  border-radius: ${(props) =>
    !!props.boderRadius ? props.boderRadius : "20px"};
  box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.16);
  background-color: white;

  ::-webkit-scrollbar {
    display: ${(props) => (props.scrollBar ? "unset" : "none")};
  }
`;

export const EditButton = styled("button")`
  border: 0;
  width: 29px;
  height: 20px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;

  &:before {
    content: "";
    mask: url(${edit});
    mask-repeat: no-repeat;
    mask-size: cover;
    mask-position: center;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.Primary};
  }
`;

export const RemoveButton = styled("button")`
  border: 0;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.Danger};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:before {
    content: "";
    mask: url(${minus});
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-position: center;
    width: 100%;
    height: 100%;
    background-color: white;
  }
`;

export const Center = styled("div")<{ gap?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: ${(props) => (props.gap ? props.gap : 0)};
`;

export const Card = styled("div")`
  padding: 10px;
  border-radius: 20px;
  box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.16);
  background-color: white;
`;

export const ScrollSection = styled("div")`
  width: 100%;
  overflow-y: auto;
  border-radius: ${(props) =>
    !!props.boderRadius ? props.boderRadius : "20px"};
  box-shadow: 0 0 6px 0 rgba(21, 115, 229, 0.16);
  background-color: white;
`;

export const NoteField = styled("input")`
  margin-left: 27px;
  width: 65px;
  height: 26px;
  border-radius: 3px;
  border: none;
  background-color: #f5f5f5;
`;

export const InLine = styled("div")<{
  justify: "center" | "space-between" | "flex-start" | "flex-end";
  align: "center" | "space-between" | "flex-start" | "flex-end";
}>`
  display: flex;
  position: relative;
  align-items: ${({ align }) => (align ? align : "center")};
  justify-content: ${({ justify }) => (justify ? justify : "center")};
  gap: ${(props) => props.gap};
`;
