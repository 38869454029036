import React, { useEffect, useState } from "react";
import Grid from "../../../../components/Grid";
import {
  Center,
  FlexContainer,
  PageTitle,
  RemoveButton,
  ScrollSection,
  ScrollingSection,
  Separator,
} from "../../../../components/StyledComponents";
import { Formik } from "formik";
import {
  initialValues,
  schema,
} from "../../../../constants/form/sub-criterion/edit";

import { connect } from "react-redux";
import { CriterionDto } from "../../../../types/criterion.dto";
import Loader from "../../../../components/Loader";
import { CohortDto } from "../../../../types/cohort.dto";
import { CurrentDto } from "../../../../types/current.dto";
import { useDispatch } from "react-redux";
import { showModal } from "../../../../store/actions/modal";
import EditCriterionCohorts from "./components/EditCriterionCohorts";
import {
  deleteCriterion,
  getCriterions,
  newCriterion,
  resetDeleteCriterion,
  resetGetAllCriterions,
  resetUpdateCriterion,
  updateCriterion,
} from "../../../../store/actions/criterion";
import {
  getAllCohorts,
  resetGetAllCohortsData,
} from "../../../../store/actions/cohort";
import {
  ArrowIcon,
  CohorListtItem,
  CohortListTitle,
  DropDownItem,
  ListCriterionTitle,
  ListItem,
  ListSubCriterionTitle,
} from "./style";
import ModalConfirmDelete from "../../../../components/ModalConfirmDelete";
import { StateDto } from "../../../../types/states.dto";
import Button from "../../../../components/Button";
import { Label } from "../../../../components/Form/InputList/styles";
import { Wrapper } from "../style";
import { SubCriterionDtoApi } from "../../../../types/sub-criterion.dto";
import CheckBox from "../components/CheckBox";
import { MdKeyboardArrowDown } from "react-icons/md";
import {
  deleteSubCriterion,
  getAllSubCriterions,
  resetDeleteSubCriterion,
  resetGetAllSubCriterions,
  resetNewManySubCriterion,
  resetNewSubCriterion,
  resetUpdateManySubCriterion,
  resetUpdateSubCriterion,
  updateSubcriterion,
} from "../../../../store/actions/sub-criterion";
import TextArea from "../../../../components/Form/TextArea";
const Component = ({
  criterions,
  cohorts,
  current,
  updateCriterionStates,
  deleteSubCriterionStates,
  deletedSubCriterion,
  subCriterions,
  updatedSubCriterion,
  updateSubCriterionStates,
  newSubCriterionStates,
  newSubCriterion,
}: {
  criterions: CriterionDto[];
  cohorts: CohortDto[];
  current: CurrentDto;
  updateCriterionStates: StateDto;
  deletedSubCriterion: SubCriterionDtoApi;
  deleteSubCriterionStates: StateDto;
  subCriterions: SubCriterionDtoApi[];
  updatedSubCriterion: SubCriterionDtoApi;
  updateSubCriterionStates: StateDto;
  newSubCriterionStates: StateDto;
  newSubCriterion: SubCriterionDtoApi;
}) => {
  const dispatch = useDispatch();
  const [formSubmmited, setFormSubmmited] = useState<boolean>(false);
  const [filteredCriterions, setFilteredCriterions] =
    useState<CriterionDto[]>();
  const [selectedSubCriterion, setSelectedSubCriterion] =
    useState<SubCriterionDtoApi>();
  const [subCriterionForCohort, setSubCriterionForCohort] =
    useState<SubCriterionDtoApi>();
  const [currentSubCriterions, setCurrentSubCriterions] =
    useState<SubCriterionDtoApi[]>();
  const [action, setAction] = useState<any>();
  /*  const [createdCriterions, setCreatedCriterions] = useState<any[]>([]); */
  const [openCriterions, setOpenCriterions] = useState<CriterionDto[]>([]);
  const [fetchOn, setFetchOn] = useState<boolean>(false);
  const onSubmit = ({ values, actions }) => {
    const skills = {
      "skill-1": values["skill-1"],
      "skill-2": values["skill-2"],
      "skill-3": values["skill-3"],
      "skill-4": values["skill-4"],
      "skill-5": values["skill-5"],
    };
    dispatch(updateSubcriterion({ _id: subCriterionForCohort?._id, skills }));
  };
  const payload = {
    filterBy: {
      active: true,
    },
    select: {
      _id: 1,
      name: 1,
    },
  };
  const handleOpenDropDown = (criterion: CriterionDto) => {
    setOpenCriterions((state) =>
      state.find((st) => st._id === criterion._id)
        ? state.filter((st) => st._id !== criterion._id)
        : [...state, criterion]
    );
  };

  useEffect(() => {
    if (!!criterions && !!cohorts && fetchOn) {
      const criterionMatchedWithCohorts = criterions.filter((criterion) => {
        return cohorts
          .filter((cohort) => criterion.bootcamps.includes(cohort._id))
          .map((cohort) => cohort.academy._id)
          .includes(current.id);
      });
      setFilteredCriterions(criterionMatchedWithCohorts);
      dispatch(
        getAllSubCriterions({
          filterBy: {
            criterion: {
              $in: criterionMatchedWithCohorts.map(
                (criterion) => criterion._id
              ),
            },
          },
        })
      );
    }
  }, [criterions, cohorts]);

  useEffect(() => {
    if (subCriterionForCohort && fetchOn) {
      setSubCriterionForCohort((sus) =>
        (currentSubCriterions ?? []).find((el) => el._id === sus?._id)
      );
    }
  }, [currentSubCriterions]);

  useEffect(() => {
    if (subCriterions && fetchOn) {
      setCurrentSubCriterions(subCriterions);
    }
  }, [subCriterions, fetchOn]);

  useEffect(() => {
    if (!criterions && fetchOn)
      dispatch(getCriterions({ filterBy: { academy: current.id } }));
  }, [criterions, fetchOn]);

  useEffect(() => {
    if (!criterions && !cohorts) {
      setFetchOn(true);
    }
  }, [criterions, cohorts]);

  useEffect(() => {
    if (!cohorts && fetchOn) dispatch(getAllCohorts(payload));
  }, [cohorts, fetchOn]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllCohortsData());
      dispatch(resetGetAllSubCriterions());
      dispatch(resetGetAllCriterions());
    };
  }, []);

  useEffect(() => {
    if (deleteSubCriterionStates.success) {
      dispatch(resetDeleteSubCriterion());
      setCurrentSubCriterions((state) =>
        (state ?? []).filter((st) => st._id !== deletedSubCriterion._id)
      );
    }
    if (deleteSubCriterionStates.error) {
      dispatch(resetDeleteCriterion());
    }
  }, [deleteSubCriterionStates, dispatch]);

  useEffect(() => {
    if (updateSubCriterionStates.success) {
      setCurrentSubCriterions((state) =>
        (state ?? []).map((st) =>
          st._id === updatedSubCriterion._id ? updatedSubCriterion : st
        )
      );
      dispatch(resetUpdateSubCriterion());
    }
    if (updateSubCriterionStates.error) {
      dispatch(resetUpdateSubCriterion());
    }
  }, [updateSubCriterionStates, dispatch]);

  useEffect(() => {
    if (newSubCriterionStates.success) {
      setCurrentSubCriterions((state) => [...(state ?? []), newSubCriterion]);
      dispatch(resetNewSubCriterion());
    }
    if (newSubCriterionStates.error) {
      dispatch(resetNewSubCriterion());
    }
  }, [newSubCriterionStates, dispatch]);
  if (!filteredCriterions || !cohorts || !currentSubCriterions)
    return <Loader color="Primary"></Loader>;
  return (
    <>
      <Grid.Container>
        <PageTitle>Subcriterios</PageTitle>
        <Formik
          initialValues={{
            ...initialValues,
          }}
          onSubmit={(values, actions) => {
            if (onSubmit) onSubmit({ values, actions });
          }}
          validateOnChange={formSubmmited}
          validateOnBlur={false}
          validationSchema={schema}
          enableReinitialize
        >
          {({
            touched,
            errors,
            setFieldValue,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
          }) => {
            return (
              <form
                className="theme-form"
                onSubmit={(event) => {
                  setFormSubmmited(true);
                  handleSubmit(event);
                }}
                id="new-criterion-form"
              >
                <Grid.Row>
                  <Grid.Col lg={5.8}>
                    <Button
                      type="button"
                      options={{
                        size: "lg",
                        type: "filled",
                        skin: "primary",
                      }}
                      onClick={() => {
                        dispatch(showModal("edit-cohort-subcriterion"));
                      }}
                    >
                      Agregar
                    </Button>
                    <Separator size={10}></Separator>
                    <Label>Listado de Criterios</Label>

                    <Wrapper>
                      <ScrollingSection
                        style={{
                          boxShadow: "none",
                          height: "450px",
                          padding: "6px",
                        }}
                      >
                        <FlexContainer direction="column" gap="6px">
                          {filteredCriterions
                            .filter((criterion) => criterion.bootcamps)
                            .map((criterion) => {
                              return (
                                <ListItem
                                  onClick={(ev) => {
                                    ev.stopPropagation();
                                    handleOpenDropDown(criterion);
                                  }}
                                >
                                  <FlexContainer
                                    align="center"
                                    justify="space-between"
                                  >
                                    <FlexContainer align="center" gap="5px">
                                      <ListCriterionTitle>
                                        {criterion.name}
                                      </ListCriterionTitle>
                                    </FlexContainer>
                                    <ArrowIcon
                                      className={
                                        !!openCriterions.find(
                                          (crit) => crit._id === criterion._id
                                        )
                                          ? "active"
                                          : ""
                                      }
                                      onClick={(ev) => {
                                        ev.stopPropagation();
                                        handleOpenDropDown(criterion);
                                      }}
                                    >
                                      <MdKeyboardArrowDown
                                        size={20}
                                      ></MdKeyboardArrowDown>
                                    </ArrowIcon>
                                  </FlexContainer>

                                  <DropDownItem
                                    onClick={(ev) => {
                                      ev.stopPropagation();
                                    }}
                                    active={
                                      !!openCriterions.find(
                                        (crit) => crit._id === criterion._id
                                      )
                                    }
                                  >
                                    {currentSubCriterions
                                      .filter(
                                        (subcriterion) =>
                                          subcriterion.criterion ===
                                          criterion._id
                                      )
                                      .map((subcriterion) => {
                                        return (
                                          <ListItem
                                            className={
                                              subcriterion._id ===
                                              subCriterionForCohort?._id
                                                ? `active`
                                                : ``
                                            }
                                            style={{ marginBottom: "5px" }}
                                            onClick={(ev) => {
                                              ev.stopPropagation();
                                              setSubCriterionForCohort(
                                                subcriterion
                                              );
                                              setFieldValue(
                                                "skill-1",
                                                subcriterion.skills["skill-1"]
                                              );
                                              setFieldValue(
                                                "skill-2",
                                                subcriterion.skills["skill-2"]
                                              );
                                              setFieldValue(
                                                "skill-4",
                                                subcriterion.skills["skill-3"]
                                              );
                                              setFieldValue(
                                                "skill-4",
                                                subcriterion.skills["skill-4"]
                                              );
                                              setFieldValue(
                                                "skill-5",
                                                subcriterion.skills["skill-5"]
                                              );
                                            }}
                                          >
                                            <FlexContainer
                                              align="center"
                                              justify="space-between"
                                            >
                                              <FlexContainer gap="4px">
                                                <RemoveButton
                                                  type="button"
                                                  onClick={(ev) => {
                                                    ev.stopPropagation();
                                                    dispatch(
                                                      showModal(
                                                        "confirm-delete-item-secuencial-modal"
                                                      )
                                                    );
                                                    setAction({
                                                      action:
                                                        "delete-subcriterion",
                                                      data: {
                                                        _id: subcriterion?._id,
                                                        name: subcriterion?.name,
                                                        title: `¿Seguro quieres ELIMINAR el Subcriterio ${subcriterion.name}?`,
                                                        action: () =>
                                                          deleteSubCriterion({
                                                            _id: subcriterion?._id,
                                                          }),
                                                        resetAction: () => {},
                                                      },
                                                    });
                                                    setSelectedSubCriterion(
                                                      subcriterion
                                                    );
                                                  }}
                                                />
                                                <ListSubCriterionTitle>
                                                  {subcriterion.name}
                                                </ListSubCriterionTitle>
                                              </FlexContainer>
                                            </FlexContainer>
                                          </ListItem>
                                        );
                                      })}
                                  </DropDownItem>
                                </ListItem>
                              );
                            })}
                        </FlexContainer>
                      </ScrollingSection>
                    </Wrapper>
                  </Grid.Col>
                  <Grid.Col offset={{ lg: 0.2 }} lg={5.8}>
                    <Wrapper>
                      <CohortListTitle>
                        Skills del subcriterio seleccionado
                      </CohortListTitle>
                      <Separator size={9}></Separator>
                      <ScrollSection
                        style={{
                          height: "500px",
                          boxShadow: "none",
                          padding: "14px 40px",
                        }}
                      >
                        <FlexContainer direction="column" gap="8px">
                          {!!subCriterionForCohort &&
                            Object.entries(subCriterionForCohort.skills).map(
                              ([skill, skillText], index) => {
                                return (
                                  <TextArea
                                    key={skill}
                                    name={skill}
                                    value={values[skill]}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    options={{
                                      label: `Nivel ${index + 1}`,
                                    }}
                                  />
                                );
                              }
                            )}
                        </FlexContainer>
                      </ScrollSection>
                    </Wrapper>
                    <Center>
                      <Button
                        loading={updateSubCriterionStates.loading}
                        disabled={!subCriterionForCohort}
                        type="submit"
                        options={{
                          type: "filled",
                          skin: "primary",
                          size: "lg",
                          marginBottom: "0px",
                        }}
                      >
                        Confirmar
                      </Button>
                    </Center>
                  </Grid.Col>
                </Grid.Row>
              </form>
            );
          }}
        </Formik>
      </Grid.Container>

      <EditCriterionCohorts
        criterions={filteredCriterions}
        cohorts={cohorts}
        subCriterion={selectedSubCriterion}
        onClose={() => setSelectedSubCriterion(undefined)}
      ></EditCriterionCohorts>
      {action?.action === "delete-subcriterion" && (
        <ModalConfirmDelete
          bntConfig={{
            content: "Eliminar",

            style: {
              style: { width: "170px", height: "40px" },
              color: "Danger",
              options: {
                type: "filled",
                skin: "danger",
                size: "lg",
                marginBottom: "0px",
              },
            },
          }}
          description="El criterio se eliminara de manera global con todos sus subcriterios y de todos los cohorts a los que fue asignado."
          states={deleteSubCriterionStates}
          title={
            <span
              style={{ color: "#697482", fontSize: "20px", fontWeight: "600" }}
            >
              {`${action.data.title}`}
            </span>
          }
          elementActions={action.data.action()}
          resetState={setAction}
        ></ModalConfirmDelete>
      )}
    </>
  );
};

const states = ({
  criterionStore,
  cohortStore,
  currentStore,
  subCriterionStore,
}) => {
  const { data: criterions } = criterionStore.allCriterions;
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: current } = currentStore;
  const { states: updateCriterionStates } = criterionStore.updateCriterion;
  const { states: deleteSubCriterionStates, data: deletedSubCriterion } =
    subCriterionStore.deleteSubCriterion;
  const { data: subCriterions } = subCriterionStore.allSubCriterions;
  const { states: updateSubCriterionStates, data: updatedSubCriterion } =
    subCriterionStore.updateSubCriterion;
  const { states: newSubCriterionStates, data: newSubCriterion } =
    subCriterionStore.newSubCriterion;

  return {
    criterions,
    cohorts,
    current,
    updateCriterionStates,
    deletedSubCriterion,
    deleteSubCriterionStates,
    subCriterions,
    updateSubCriterionStates,
    updatedSubCriterion,
    newSubCriterionStates,
    newSubCriterion,
  };
};

export default connect(states)(Component);
