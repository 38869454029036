const permissions = {
  static: [
    "route:contents:preview",
    "route:contents:videos:preview",
    "route:contents:cdocs:preview",
    "route:contents:roadmaps:preview",
    "route:contents:variety:preview",
    "route:contents:images:preview",
    "route:contents:roadmaps:preview",
    "route:contents:slideshows:preview",
    "route:cohort:preview",
    "route:cohort:list:preview",
    "route:cohort:events:preview",
    "route:cohort:customize:preview",
    "route:calificate:preview",
    "route:calificate:evaluate:preview",
    "route:calificate:criterions:preview",
    "route:calificate:criterions:create",
    "route:calificate:criterions:edit",
    "route:contents:listens:preview",
    "route:calificate:subcriterion:preview",
    "route:calificate:subcriterion:create",
    "route:calificate:subcriterion:edit",
    "route:management:preview",
    "route:management:profile:preview",
    "route:management:collaborators:preview",
    "route:management:collaborators:invitation:preview",
    "route:management:collaborators:management:preview",
    "route:management:collaborators:customize:preview",
    "route:management:certificate:preview",
    "route:management:certificate:management:preview",
    "route:management:suscribers:preview",
    "route:management:suscribers:list:preview",
    "route:management:suscribers:access:preview",
    "route:management:marketplace:preview",
    "route:management:calendar:preview",
    "route:dashboard:preview",
    "route:dashboard:constana:preview",
    "route:dashboard:constana:general:preview",
    "route:dashboard:constana:jobs:preview",
    "route:dashboard:campus:preview",
    "route:dashboard:campus:general:preview",
    "route:dashboard:campus:audience:preview",
    "route:dashboard:cohorts:preview",
    "route:dashboard:cohorts:cohort:preview",
    "route:dashboard:cohorts:individual:preview",
    "route:dashboard:earning:preview",
    "route:dashboard:earning:academy:preview",
    "route:dashboard:earning:kpis:preview",
    "route:dashboard:contents:preview",
    "route:dashboard:contents:general:preview",
    "route:dashboard:contents:cohorts:preview",
    "route:monetization:preview",
    "route:monetization:academy:preview",
    "route:monetization:cohort:preview",
    "route:monetization:content:preview",
    "route:notification:preview",
    "route:calendar:preview",
    "route:members:preview",
    "route:members:edit",
    "route:members:create",
    "route:myfinances:preview",
    "route:dashboard:earning:preview",

    "route:talents:preview",
    "route:talents:edit",
    "route:talents:create",

    "route:education:preview",
    "route:education:edit",
    "route:education:create",

    "route:dashboard:preview",

    "route:cohort:preview",
    "route:cohort:edit",
    "route:cohort:create",

    "route:analytics:preview",
    "route:analytics:edit",
    "route:analytics:create",

    "route:videos:preview",

    "route:cdocs:preview",
    "route:marketplace:preview",
    "route:marketplace:edit",
    "route:marketplace:create",

    "route:management:preview",
    "route:management:edit",
    "route:management:create",

    "route:certificate:preview",
    "route:certificate:new",
    "route:certificate:edit",
    "route:certificate:management:preview",
    "route:certificate:templates:preview",

    "route:notification:preview",
    "route:notification:new",
    "route:notification:edit",

    "route:template:preview",
    "route:template:new",
    "route:template:edit",

    "route:settings:preview",
    "route:settings:new",
    "route:settings:edit",

    "route:forms:preview",
    "route:forms:new",
    "route:forms:edit",

    "route:contents:preview",
  ],
  dynamic: {
    "op:edit:state": ({ status }) => {
      if (!status) return false;
      return ["requested", "pending", "inProgress", "done"].includes(status);
    },
  },
};

export default permissions;
