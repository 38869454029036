import * as Yup from "yup";

export const schema = Yup.object().shape({
  name: Yup.string().required(),
  lastName: Yup.string().required(),
  birthDate: Yup.string(),
  nationality: Yup.string(),
  dni: Yup.string(),
  gender: Yup.string(),
  civilStatus: Yup.string(),
});

interface FormValues {
  name: string;
  lastName: string;
  birthDate: string;
  nationality: string;
  dni: string;
  gender: string;
  civilStatus: string;
}

export const initialValues = (intial): FormValues => {
  return {
    birthDate: "",
    name: "",
    lastName: "",
    nationality: "",
    dni: "",
    gender: "",
    civilStatus: "",
    ...intial,
  };
};
