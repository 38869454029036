import styled from "styled-components";

export const Card = styled("div")`
  position: relative;
  border-radius: 10px;
  box-shadow: 6px 6px 10px 0 rgba(112, 112, 112, 0.16);
  background-color: white;
  width: 100%;
  overflow: hidden;
`;

export const CardTitle = styled("h3")`
  font-size: 14px;
  font-weight: 500;
  color: #273241;
  margin-bottom: 15px;
`;

export const ListItem = styled("div")`
  background-color: #fff;
  padding: 4px 20px;
  border-radius: 10px;
  box-shadow: 0 0 3px 0 rgba(112, 112, 112, 0.16);
`;

export const EntityTitleSemiBold = styled("h3")`
  font-size: 14px;
  font-weight: normal;
  color: #2a2747;
`;
