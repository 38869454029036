import {
  NewInvitation,
  NEW_INVITATION,
  ResetNewInvitation,
  RESET_NEW_INVITATION,
  GetAllInvitation,
  GET_ALL_INVITATION,
  ResetGetAllInvitation,
  RESET_GET_ALL_INVITATION,
  DELETE_INVITATION,
  DeleteInvitation,
  RESET_DELETE_INVITATION,
  ResetDeleteInvitation,
  UPDATE_INVITATION,
  UpdateInvitation,
  RESET_UPDATE_INVITATION,
  ResetUpdateInvitation,
  GetInvitation,
  GET_INVITATION,
  RESET_GET_INVITATION,
  ResetGetInvitation,
  NEW_MANY_INVITATION,
  NewManyInvitation,
  RESET_NEW_MANY_INVITATION,
} from "../types/invitation";

const create = (payload): NewInvitation => {
  return { type: NEW_INVITATION, payload };
};
const resetCreate = (): ResetNewInvitation => {
  return { type: RESET_NEW_INVITATION, payload: null };
};

const getAll = (payload): GetAllInvitation => {
  return { type: GET_ALL_INVITATION, payload };
};

const newMany = (payload): NewManyInvitation => {
  return { type: NEW_MANY_INVITATION, payload };
};

const resetNewMany = () => {
  return { type: RESET_NEW_MANY_INVITATION, payload: null };
};

const resetGetAll = (): ResetGetAllInvitation => {
  return { type: RESET_GET_ALL_INVITATION, payload: null };
};

const deleteOne = (payload: { _id: string }): DeleteInvitation => {
  return { type: DELETE_INVITATION, payload };
};

const resetDeleteOne = (): ResetDeleteInvitation => {
  return { type: RESET_DELETE_INVITATION, payload: null };
};

const update = (payload): UpdateInvitation => {
  return { type: UPDATE_INVITATION, payload };
};

const resetUpdate = (): ResetUpdateInvitation => {
  return { type: RESET_UPDATE_INVITATION, payload: null };
};

const getOne = (payload): GetInvitation => {
  return { type: GET_INVITATION, payload };
};
const resetGetOne = (): ResetGetInvitation => {
  return { type: RESET_GET_INVITATION, payload: null };
};

const actions = {
  create,
  resetGetAll,
  resetCreate,
  resetDeleteOne,
  getAll,
  deleteOne,
  update,
  resetUpdate,
  getOne,
  resetGetOne,
  newMany,
  resetNewMany,
};

export default actions;
