import {
  NewCriterion,
  NEW_CRITERION,
  ResetNewCriterion,
  RESET_NEW_CRITERION,
  GET_ALL_CRITERIONS,
  GetAllCriterions,
  UpdateCriterion,
  UPDATE_CRITERION,
  ResetUpdateCriterion,
  RESET_UPDATE_CRITERION,
  RESET_DELETE_CRITERION,
  DELETE_CRITERION,
  RESET_GET_ALL_CRITERIONS,
} from "../types/criterion";

export const newCriterion = (payload): NewCriterion => {
  return { type: NEW_CRITERION, payload };
};
export const resetNewCriterion = (): ResetNewCriterion => {
  return { type: RESET_NEW_CRITERION, payload: null };
};

export const updateCriterion = (payload): UpdateCriterion => {
  return { type: UPDATE_CRITERION, payload };
};

export const resetUpdateCriterion = (): ResetUpdateCriterion => {
  return { type: RESET_UPDATE_CRITERION, payload: null };
};

export const getCriterions = (payload): GetAllCriterions => {
  return { type: GET_ALL_CRITERIONS, payload };
};

export const resetGetAllCriterions = () => {
  return { type: RESET_GET_ALL_CRITERIONS, payload: null };
};

export const resetDeleteCriterion = () => {
  return { type: RESET_DELETE_CRITERION, payload: null };
};

export const deleteCriterion = (payload) => {
  return { type: DELETE_CRITERION, payload };
};
