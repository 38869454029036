import styled from "styled-components";

export const Select = styled.select`
  width: 100px;
  height: 35px;
  background-color: #fff;
  color: gray;
  padding-left: 5px;
  font-size: 14px;
  border: none;
  margin-left: 10px;
  -moz-user-select: none;
  -khtml-user-select: none;
  font-size: 12px;
  font-weight: 300;
  color: #697482;
  -webkit-user-select: none;
  -ms-user-select: none;
  outline: none;
  border-radius: 5px;
  user-select: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  option {
    color: black;
    background: white;
    display: flex;
    background-color: #f5f5f5;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
    font-size: 12px;
    font-weight: 300;
    color: #697482;
  }
`;

export const GraphWrapper = styled("div")`
  height: 234px;
`;
