import {
  initialState,
  Actions,
  State,
  GET_ALL_TECHNOLOGIES,
  GET_ALL_TECHNOLOGIES_ERROR,
  GET_ALL_TECHNOLOGIES_SUCCESS,
  GET_TECHNOLOGY,
  GET_TECHNOLOGY_ERROR,
  GET_TECHNOLOGY_SUCCESS,
  NEW_TECHNOLOGY,
  NEW_TECHNOLOGY_ERROR,
  NEW_TECHNOLOGY_SUCCESS,
  RESET_NEW_TECHNOLOGY,
  UPDATE_TECHNOLOGY,
  UPDATE_TECHNOLOGY_ERROR,
  UPDATE_TECHNOLOGY_SUCCESS,
  RESET_UPDATE_TECHNOLOGY,
  DELETE_TECHNOLOGY,
  DELETE_TECHNOLOGY_ERROR,
  DELETE_TECHNOLOGY_SUCCESS,
  RESET_DELETE_TECHNOLOGY,
  SET_NEW_TECHNOLOGY_DATA,
  SET_UPDATE_TECHNOLOGY_DATA,
  UPDATE_CURRENT_TECHNOLOGY_LIST,
} from "../../types/recruiting/technology";

const updateReducerArray = (array, data) =>
  array.map((elem) => (elem._id === data._id ? data : elem));

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_TECHNOLOGIES:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_TECHNOLOGIES_SUCCESS:
      return {
        ...state,
        all: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_TECHNOLOGIES_ERROR:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case GET_TECHNOLOGY:
      return {
        ...state,
        selected: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_TECHNOLOGY_SUCCESS:
      return {
        ...state,
        selected: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_TECHNOLOGY_ERROR:
      return {
        ...state,
        selected: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case NEW_TECHNOLOGY:
      return {
        ...state,
        create: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_TECHNOLOGY_SUCCESS:
      return {
        ...state,
        create: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case NEW_TECHNOLOGY_ERROR:
      return {
        ...state,
        create: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_TECHNOLOGY:
      return {
        ...state,
        create: initialState.create,
      };
    case DELETE_TECHNOLOGY:
      return {
        ...state,
        delete: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_TECHNOLOGY_SUCCESS:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_TECHNOLOGY_ERROR:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_TECHNOLOGY:
      return {
        ...state,
        delete: initialState.delete,
      };
    case UPDATE_TECHNOLOGY:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_CURRENT_TECHNOLOGY_LIST:
      return {
        ...state,
        all: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: false,
          },
        },
      };
    case SET_NEW_TECHNOLOGY_DATA:
      return {
        ...state,
        all: {
          ...state.all,
          data: !!state.all.data
            ? [...state.all.data, action.payload]
            : [action.payload],
        },
      };
    case SET_UPDATE_TECHNOLOGY_DATA:
      return {
        ...state,
        all: {
          ...state.all,
          data: !!state.all.data
            ? updateReducerArray(state.all.data, action.payload)
            : null,
        },
      };
    case UPDATE_TECHNOLOGY_SUCCESS:
      return {
        ...state,
        update: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_TECHNOLOGY_ERROR:
      return {
        ...state,
        update: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_TECHNOLOGY:
      return {
        ...state,
        update: initialState.update,
      };
    default:
      return state;
  }
};

export default reducers;
