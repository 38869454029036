import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Modal from "../../../../../../components/Modal";
import { Formik } from "formik";
import { Center } from "../../../../../../components/StyledComponents";
import {
  initialValues,
  schema,
} from "../../../../../../constants/form/recruiting/companies/edit";
import Grid from "../../../../../../components/Grid";
import Button from "../../../../../../components/Button";
import Submit from "../../../../../../components/Form/Submit";
import ReactSelect from "../../../../../../components/Form/ReactSelect";
import { toast } from "react-toastify";
import Input from "../../../../../../components/Form/Input";
import TextArea from "../../../../../../components/Form/TextArea";
import companiesActions from "../../../../../../store/actions/recruiting/company";
import { useRecruitingLayout } from "../../../../../../provider/RecruitingProvider";
import { hideModal } from "../../../../../../store/actions/modal";
interface ComponentProps {
  updateStates: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

const Component = ({ updateStates }: ComponentProps) => {
  const { selectedItem } = useRecruitingLayout();
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const dispatch = useDispatch();

  const onSubmit = ({ values, actions }) => {
    dispatch(companiesActions.update(values));
    setFormActions(actions);
  };

  useEffect(() => {
    if (updateStates.success) {
      toast("👌🏼 Empresa creada correctamente", {
        containerId: "submit",
        type: "success",
        toastId: "submitsucceful",
      });

      setTimeout(() => {
        dispatch(companiesActions.resetUpdate());
        dispatch(hideModal());
        formActions?.resetForm();
      }, 1000);
    }

    if (updateStates.error) {
      toast(`😱 ${updateStates.error}`, {
        containerId: "submit",
        type: "error",
        toastId: "submiterror",
      });

      setTimeout(() => {
        dispatch(companiesActions.resetUpdate());
      }, 1000);
    }
  }, [updateStates]);

  return (
    <Modal name="edit-company-modal" title="Editar Empresa">
      <Formik
        initialValues={{ ...initialValues(selectedItem?.data) }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="new-evaluate-form"
            >
              <Grid.Row>
                <Grid.Col>
                  <Input
                    name={"name"}
                    error={errors["name"]}
                    touched={touched["name"]}
                    value={values["name"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{ label: "Nombre", skin: "gray" }}
                  />
                  <Input
                    name={"linkedin"}
                    error={errors["linkedin"]}
                    touched={touched["linkedin"]}
                    value={values["linkedin"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{ label: "Linkedin", skin: "gray" }}
                  />
                  <Input
                    name={"email"}
                    error={errors["email"]}
                    touched={touched["email"]}
                    value={values["email"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{ label: "E-mail", skin: "gray" }}
                  />
                  <Input
                    name={"location"}
                    error={errors["location"]}
                    touched={touched["location"]}
                    value={values["location"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{ label: "Ubicacion", skin: "gray" }}
                  />
                  <Input
                    name={"portfolio"}
                    error={errors["portfolio"]}
                    touched={touched["portfolio"]}
                    value={values["portfolio"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{ label: "Portfolio", skin: "gray" }}
                  />
                  <ReactSelect
                    name="area"
                    error={errors["area"]}
                    touched={touched["area"]}
                    items={
                      []
                      /*  !!abilities &&
                      abilities
                        .filter((ability) => ability.active)
                        .map((ability) => ({
                          label: ability.name,
                          value: ability._id,
                        })) */
                    }
                    placeholder="Selecciona un Rubro"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      /* loading: !abilities || abilitiesStates.loading, */
                      label: "Asignar Rubro",
                      marginBottom: 24,
                    }}
                  />
                  <TextArea
                    name="description"
                    error={errors["description"]}
                    touched={touched["description"]}
                    value={values["description"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "Descripcion",
                      marginBottom: 24,
                      skin: "gray",
                    }}
                  />
                </Grid.Col>
              </Grid.Row>

              <Grid.Row>
                <Grid.Col>
                  <Center gap={"70px"}>
                    <Button
                      onClick={resetForm}
                      type="button"
                      options={{
                        type: "outline",
                        skin: "gray",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                      style={{ marginLeft: "10px" }}
                    >
                      Cancelar
                    </Button>

                    <Submit
                      isSubmmiting={isSubmitting}
                      form="new-evaluate-form"
                      color="Primary"
                      options={{
                        type: "filled",
                        skin: "primary",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                    >
                      Confirmar
                    </Submit>
                  </Center>
                </Grid.Col>
              </Grid.Row>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const states = ({ recruitingStore }) => {
  const { states: updateStates } = recruitingStore.company.update;
  return {
    updateStates,
  };
};
export default connect(states)(Component);
