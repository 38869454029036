import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import Input from "../../../../../components/Form/Input";
import Submit from "../../../../../components/Form/Submit";
import ReactSelect from "../../../../../components/Form/ReactSelect";
import {
  initialValues,
  schema,
} from "../../../../../constants/form/content/new";
import { newCohort, resetNewCohort } from "../../../../../store/actions/cohort";
import { getAllEvents } from "../../../../../store/actions/event";
import { getAllAbilities } from "../../../../../store/actions/ability";
import { AbilityDto } from "../../../../../types/ability.dto";
import { EventDto } from "../../../../../types/event.dto";
import Button from "../../../../../components/Button";
import { hideModal } from "../../../../../store/actions/modal";
import { UserDto } from "../../../../../types/user.dto";
import { getAllMentors } from "../../../../../store/actions/mentor";
import {
  Center,
  FlexContainer,
} from "../../../../../components/StyledComponents";
import { CurrentDto } from "../../../../../types/current.dto";
import { CohortDto } from "../../../../../types/cohort.dto";
import FileUploader from "../../../../../components/Form/FileUploader";
import Icon from "../../../../../components/Icon";
import UploadIcon from "../../../../../assets/icons/cloud-upload.svg";
import DinamicField from "../../../../../components/Form/DinamicField";
import { AcademyDto } from "../../../../../types/academy.dto";
import { UsersDto } from "../../../../../types/users.dto";
import contentActions from "../../../../../store/actions/content";
import TextArea from "../../../../../components/Form/TextArea";
import { EntityTitleSemiBold } from "../../../Cohort/Events/style";
import { toast } from "react-toastify";
import { StateDto } from "../../../../../types/states.dto";
import Modal from "../../../../../components/Modal";
import { ContentDto } from "../../../../../types/content.dto";
interface ComponentProps {
  mentors: UserDto[];
  abilities: AbilityDto[];
  events: EventDto[];
  current: CurrentDto;
  cohorts: CohortDto[];
  user: UserDto;
  academies: AcademyDto[];
  users: UsersDto[];
  content: ContentDto;
  updateContentStates: StateDto;
}

const Component = ({
  abilities,
  mentors,
  events,
  updateContentStates,
  current,
  cohorts,
  user,
  academies,
  users,

  content,
}: ComponentProps) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [type, setType] = useState<string>();
  const [currentTypeEvents, setCurrentTypeEvents] = useState<EventDto[]>();
  const [currentTypeAbilities, setCurrentTypeAbilities] =
    useState<AbilityDto[]>();
  const [avatarPreview, setAvatarPreview] = useState<HTMLInputElement>();

  const dispatch = useDispatch();
  const onSubmit = ({ values, actions }) => {
    const formValues = {
      ...values,
      ...(!!avatarPreview &&
        !!avatarPreview?.files && {
          picture: avatarPreview?.files[0],
        }),
    };
    dispatch(contentActions.update({ academy: current.id, ...formValues }));
  };

  const closeModal = () => {
    dispatch(hideModal());
  };

  const onTypeChange = (event) => {};

  useEffect(() => {
    if (!!events) {
      setCurrentTypeEvents(
        events.filter((event) => event.active && event.academy === current.id)
      );
    }

    if (!!abilities) {
      setCurrentTypeAbilities(
        abilities.filter((ability) => ability.type === type)
      );
    }
  }, [type, events, abilities, mentors]);

  useEffect(() => {
    !events && dispatch(getAllEvents());
    !abilities && dispatch(getAllAbilities());
    !mentors && dispatch(getAllMentors());
  }, []);

  useEffect(() => {
    if (updateContentStates.success) {
      toast("👌🏼 Contenido editado", {
        containerId: "submit",
        type: "success",
        toastId: "submitsucceful",
      });
      setTimeout(() => {
        dispatch(contentActions.resetUpdate());
      }, 500);
      dispatch(hideModal());
    }

    if (updateContentStates.error) {
      toast(`😱 ${updateContentStates.error}`, {
        containerId: "submit",
        type: "error",
        toastId: "submiterror",
      });
      setTimeout(() => {
        dispatch(contentActions.resetUpdate());
      }, 500);
    }
  }, [updateContentStates]);

  return (
    <Modal name="edit-content-modal" title="Editar Contenido">
      <Formik
        initialValues={{
          ...initialValues,
          ...content,
          cohorts: content.cohorts.map((cohort) => cohort),
        }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="edit-cohort-form"
            >
              <Input
                name="name"
                error={errors["name"]}
                touched={touched["name"]}
                value={values["name"]}
                type="text"
                placeholder=""
                onChange={handleChange}
                onBlur={handleBlur}
                options={{
                  skin: "gray",
                  label: "Nombre del cohort",
                  marginBottom: 20,
                }}
              />
              <FileUploader
                name="picture"
                error={errors["picture"]}
                touched={touched["picture"]}
                placeholder={
                  <Icon icon={UploadIcon} size="24px" color="black" />
                }
                onChange={(event) => {
                  setAvatarPreview(event.target);
                  handleChange(event);
                }}
                onBlur={handleBlur}
                accept="image/jpg;image/png;image/jpeg;capture=camera"
                options={{
                  label: "Foto del cohort",
                  marginBottom: 24,
                }}
              />

              <Input
                name="link"
                error={errors["link"]}
                touched={touched["link"]}
                value={values["link"]}
                type="text"
                placeholder=""
                onChange={handleChange}
                onBlur={handleBlur}
                options={{
                  skin: "gray",
                  label: "Link del Contenido",
                  marginBottom: 20,
                }}
              />
              <TextArea
                name="description"
                error={errors["description"]}
                touched={touched["description"]}
                value={values["description"]}
                onChange={handleChange}
                onBlur={handleBlur}
                options={{
                  skin: "gray",
                  label: "Descripcion",
                  marginBottom: 24,
                }}
              />
              <FlexContainer align="center" style={{ marginBottom: "20px" }}>
                <input
                  style={{ height: "17px", width: "17px" }}
                  name={"hidden"}
                  checked={values["hidden"] === true}
                  onChange={() => {
                    setFieldValue(
                      "hidden",
                      values["hidden"] === true ? false : true
                    );
                  }}
                  type="checkbox"
                />
                <EntityTitleSemiBold>
                  Hacer contenido Privado
                </EntityTitleSemiBold>
              </FlexContainer>

              <DinamicField
                name="cohorts"
                error={errors["cohorts"]}
                touched={touched["cohorts"]}
                value={values["cohorts"]}
                onChange={handleChange}
                onBlur={handleBlur}
                options={{
                  type: "select",
                  height: "95px",
                  ...(!!cohorts && {
                    data: cohorts
                      ?.filter(
                        (cohort) =>
                          cohort.active && cohort.academy._id === current.id
                      )
                      .map((event) => ({
                        label: event.name,
                        value: event._id,
                      })),
                  }),
                  skin: "gray",
                  loading: !cohorts,
                  inputLabel: "Seleccionar Cohorts",
                  inputPlaceholder: "Selecciona un cohort",
                }}
              />

              <FlexContainer align="center" style={{ marginBottom: "20px" }}>
                <input
                  style={{ height: "17px", width: "17px" }}
                  name={"private"}
                  checked={values["private"] === true}
                  onChange={() => {
                    setFieldValue(
                      "private",
                      values["private"] === true ? false : true
                    );
                  }}
                  type="checkbox"
                />
                <EntityTitleSemiBold>
                  Permitir acceso desde otros Cohorts
                </EntityTitleSemiBold>
              </FlexContainer>

              <Center>
                <Submit
                  isSubmmiting={updateContentStates.loading}
                  form="edit-cohort-form"
                  color="Primary"
                >
                  Confirmar
                </Submit>
                <Button
                  onClick={closeModal}
                  type="button"
                  options={{
                    type: "outline",
                    skin: "danger",
                    size: "lg",
                    marginBottom: "0px",
                  }}
                  style={{ marginLeft: "10px" }}
                >
                  Cancelar
                </Button>
              </Center>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const states = ({
  abilityStore,
  eventStore,
  cohortStore,
  mentorStore,
  currentStore,
  userStore,
  academyStore,
  usersStore,
  contentStore,
}) => {
  const { data: mentors } = mentorStore.allMentors;
  const { data: abilities } = abilityStore.allAbilities;
  const { data: academies } = academyStore.all;
  const { data: events } = eventStore.allEvents;
  const { data: current } = currentStore;
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: user } = userStore;
  const { data: users } = usersStore.all;
  const { states: updateContentStates } = contentStore.update;
  return {
    abilities,
    events,
    mentors,
    current,
    cohorts,
    user,
    academies,
    users,
    updateContentStates,
  };
};

export default connect(states)(Component);
