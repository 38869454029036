import styled from "styled-components";

const makeVariation = ({ theme, skin, state }) => {
  return `
    border-color: ${theme.form.skin[skin][state].borderColor};
    background-color: ${theme.form.skin[skin][state].backgroundColor};
    

    textarea, 
    input {
      color: ${theme.form.skin[skin][state].color};
    }

    textarea::-webkit-input-placeholder {
      color: #c1c1c1;
    }
    
    textarea:-moz-placeholder { /* Firefox 18- */
      color: #c1c1c1;  
    }
    
    textarea::-moz-placeholder {  /* Firefox 19+ */
      color: #c1c1c1;  
    }
    
    textarea:-ms-input-placeholder {
      color: #c1c1c1;  
    }
    
    textarea::placeholder {
      color: #c1c1c1;  
    }

    
    input::-webkit-input-placeholder {
      color: #c1c1c1;
    }
    
    input:-moz-placeholder { /* Firefox 18- */
      color: #c1c1c1;  
    }
    
    input::-moz-placeholder {  /* Firefox 19+ */
      color: #c1c1c1;  
    }
    
    input:-ms-input-placeholder {
      color: #c1c1c1;  
    }
    
    input::placeholder {
      color: #c1c1c1;  
    }


    .select__control {
      color: ${theme.form.skin[skin][state].color}!important;

      .select__placeholder{
        color: #c1c1c1!important;
      }

      & > i {
        &:before{
          background-color: ${theme.form.skin[skin][state].iconColor}!important;
        }
      }
    }

    .field-before,
    .field-after{
      i {
        &:before{
          background-color: ${theme.form.skin[skin][state].iconColor}!important;
        }
      }
    }

    .select__indicators{
      i {
        &:before{
          background-color: ${theme.form.skin[skin][state].iconColor}!important;
        }
      }
    }`;
};

const resetFieldStyles = `
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  outline: none;
  border: 0px;
  margin: 0px;
  padding: 0px;
  background-color: transparent;
  width: 100%;`;

/* agregar padding links del menu, boton de salir esta muy al fondo, boton ver niveles con shadow */
export const Input = styled("input")<{ skin; size }>`
  ${resetFieldStyles}
  height: calc(17px);
  line-height: ${(props) => props.theme.form.size[props.size].lineHeight};
  font-size: ${(props) => "14px"};
  font-family: 'Lato', sans-serif;
  color: ${(props) => props.theme.colors.Black};
  padding: ${(props) => props.padding};
  caret-color: #035397;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px
    background-color: transparent !important;
    background-image: none !important;
    color: ${(props) => props.theme.colors.Black} !important;
    font-family: 'Lato', sans-serif;
    font-size: ${(props) => props.theme.form.size[props.size].mobileFontSize};
    line-height: ${(props) =>
      props.theme.form.size[props.size].mobileLineHeight};
min-height: calc(17px);
    @media (min-width: 768px) {
      font-size: ${(props) => props.theme.form.size[props.size].fontSize};
      line-height: ${(props) => props.theme.form.size[props.size].lineHeight};
    }
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

export const Field = styled("div")<{ skin; size }>`
  ${(props) => {
    const { theme, skin = "base", size = "md" } = props;
    return `
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: calc(17px);
    background-color: rgba(42, 39, 71, 0.8);;
    transition: all 0.3s ease-in-out;
    padding-left: ${theme.form.size[size].padding};
    padding-right: ${theme.form.size[size].padding};
    transition: all 150ms ease-in-out;
   
    border-radius: 5px;
    ${makeVariation({ theme, skin, state: "idle" })}
    border: 0.3px solid #697482;
    &:disabled,
    &:hover,
    &:focus,
    &:active {
      outline: 0;
    }
  
    &.with-error {
      ${makeVariation({ theme, skin, state: "error" })}
    }
  
    &.field-active {
      ${makeVariation({ theme, skin, state: "active" })}
    }
  
    &.field-disabled{
      cursor: not-allowed;
      ${makeVariation({ theme, skin, state: "disabled" })}
    }
   
    
    
    `;
  }}
`;
