import { connect } from "react-redux";
import { InLine } from "../../../../../../components/StyledComponents";
import { useRecruitingLayout } from "../../../../../../provider/RecruitingProvider";
import Icon from "../../../../../../components/Icon";
import ShowModal from "../../../components/ShoModal";
import {
  Tab,
  TabTitle,
  Description,
  Content,
  ListTitle,
  List,
  ListItem,
  GrayCard,
} from "../../../components/StyledComponents";

import TalentSearchIcon from "../../../../../../assets/icons/talent-search.svg";
import GlobeIcon from "../../../../../../assets/icons/globe.svg";
import MarkerIcon from "../../../../../../assets/icons/marker.svg";

const Component = ({ children }) => {
  const { selectedItem, statics } = useRecruitingLayout();

  return (
    <>
      <InLine justify="space-between">
        <TabTitle>Perfil de Empresa</TabTitle>
        {children}
      </InLine>

      <Description>
        Customizar el perfil de empresa te ayudara a ti y a Dev Place a
        facilitar la conexión entre talentos, tus búsquedas y las necesidades de
        esta empresa en particular. También te enviaremos recomendaciones de
        perfiles que no has seleccionado pero que por la información recopilada
        recomendaremos que les eches un vistazo.
      </Description>

      <Content>
        <GrayCard>
          <InLine
            style={{
              alignItems: "flex-start",
              justifyContent: "flex-start",
              marginBottom: "5px",
            }}
          >
            <InLine style={{ justifyContent: "flex-start" }}>
              <Icon icon={TalentSearchIcon} size="12px" color="black" />
              <span style={{ margin: "0px 5px", color: "#334253" }}>
                Perfil:
              </span>
            </InLine>
            {selectedItem?.data?.name}
          </InLine>
          <InLine
            style={{
              alignItems: "flex-start",
              justifyContent: "flex-start",
              marginBottom: "5px",
            }}
          >
            <InLine style={{ justifyContent: "flex-start" }}>
              <span style={{ margin: "0px 5px", color: "#334253" }}>
                Descripcion:
              </span>
            </InLine>
            {selectedItem?.data?.description}
          </InLine>
        </GrayCard>
      </Content>
    </>
  );
};

const states = ({ recruitingStore }) => {
  const { data: allSearches, states: allSearchesStates } =
    recruitingStore.search.all;

  return { allSearches, allSearchesStates };
};

export default connect(states)(Component);
