import * as Yup from "yup";

export const schema = Yup.object().shape({
  criterion: Yup.string().required("Se requiere un criterio"),
  name: Yup.string()
    .required("Se requiere un nombre")
    .min(3, "Minimo 3 caracteres"),
});

interface FormValues {
  criterion: string;
  name: string;
}

export const initialValues: FormValues = {
  criterion: "",
  name: "",
};
