import styled, { css } from "styled-components";
import { NavLink as NavLinkUi } from "react-router-dom";

export const Navbar = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const NavItems = styled("ul")`
  margin: 0px;
  padding: 0px;
  list-style: none;
`;

export const NavItem = styled("li")`
  padding: 0 10px;
  margin-bottom: 2px;
  position: relative;
  &.sidebar-collapsed {
    &:hover {
      & > button {
        & + div {
          opacity: 1 !important;
          z-index: 10 !important;
          left: 100%;
        }
      }
    }

    & > button {
      background-color: #fff;

      & + div {
        opacity: 0 !important;
        z-index: -1 !important;
        height: auto;
        max-height: none;
        padding: 8px 10px;
        position: absolute;
        left: -1000%;
        bottom: 0px;
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
  }
`;

const Link = css`
  text-decoration: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  width: 100%;
  padding: 12px 15px 12px 10px;
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out;
  color: ${(props) => props.theme.colors.Gray};
  font-size: 13px;
  font-weight: 500;
  font-family: "Lato", sans-serif;
  border-radius: 10px;

  &.no-styles {
    border: 0;
    margin: 0;
    background: transparent;

    &:before,
    &:after {
      display: none;
    }
  }

  &.active {
    font-weight: 500;
    background-color: ${(props) => props.theme.colors.Primary};
    color: ${(props) => props.theme.colors.White};

    i {
      &:before {
        background-color: ${(props) => props.theme.colors.White};
      }
    }

    .caret {
      transform: rotate(0deg);
    }
  }

  &:not(.active):hover {
    background-color: ${(props) => props.theme.colors.Primary};

    color: ${(props) => props.theme.colors.White};

    i {
      &:before {
        background-color: ${(props) => props.theme.colors.White};
      }
    }
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    i {
      margin-right: ${(props) => (props.sidebarCollapsed ? "0px" : "10px")};

      &:before {
        transition: all 0.25s ease-in-out;
      }
    }
  }
`;

export const ButtonLink = styled("button")`
  font-family: "Lato", sans-serif;
  border: 0px;
  margin: 0px;
  background: none;
  cursor: pointer;
  margin-bottom: 2px;
  ${Link}
  ${(props) =>
    props.sidebarCollapsed ? "justify-content:center;background: #fff;" : ""}
`;

export const NavLink = styled(NavLinkUi)`
  ${Link}
  ${(props) => (props.sidebarCollapsed ? "justify-content:center;" : "")}
`;

export const Caret = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-in-out;
  transform: rotate(-180deg);
  width: 24px;
  height: 24px;

  &.active {
    transform: rotate(0deg);
  }
`;

export const DropdownMenu = styled("div")<{ amountItems; isActiveDropdown }>`
  display: flex;
  flex-direction: column;
  font-family: "Lato", sans-serif;
  align-items: flex-end;
  overflow: hidden;
  transition: height 0.3s ease-in-out, max-height 0.3s ease-in-out;
  //Dropdown
  opacity: ${({ isActiveDropdown }) => (isActiveDropdown ? 1 : 0)};
  height: ${({ amountItems }) => amountItems * 34 + 30}px;
  max-height: ${({ isActiveDropdown, amountItems }) =>
    isActiveDropdown ? amountItems * 34 + 30 : 0}px;
  ${(props) =>
    !!props.subChilds && props.isActiveDropdown
      ? `max-height:${
          props.isActiveDropdown ? "unset" : `${props.amountItems * 34 + 30}px`
        }};height:unset`
      : ""}
`;

export const DropdownItem = styled("div")`
  font-family: "Lato", sans-serif;
  min-width: 185px;
  margin-bottom: 1px;
`;
export const DropdownMenuChild = styled(DropdownMenu)`
  font-family: "Lato", sans-serif;
  max-height: unset;
  height: 100%;
  justify-content: unset;
  max-height: ${(props) =>
    props.isActiveDropdown ? props.amountItems * 34 : 0}px;
`;

export const SubNavLink = styled(NavLinkUi)`
  font-family: "Lato", sans-serif;
  text-decoration: none;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 30px;
  padding: 7px 10px;
  position: relative;
  border-radius: 8px;
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out;
  color: ${(props) => props.theme.colors.Primary};
  background-color: white;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &.no-styles {
    border: 0;
    margin: 0;
    background: transparent;

    &:before,
    &:after {
      display: none;
    }
  }

  &.active {
    font-weight: 500;
    background-color: ${(props) => props.theme.colors.Primary};
    color: ${(props) => props.theme.colors.White};
  }

  &:not(.active):hover {
    background-color: ${(props) => props.theme.colors.Primary};
    color: ${(props) => props.theme.colors.White};
  }
`;
export const Nav = styled("nav")`
  grid-area: nav;

 &.sidebar-collapsed {
    & > ul {
      & > li > ${ButtonLink},
      & > li > ${NavLink}{
          span {
            text-indent: -999px;
          }
        }

        ${ButtonLink} {
          div:last-child {
            display: none;
          }
        }
      }
    }
  }
`;
