import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../../../../components/Loader";
import academyActions from "../../../../store/actions/academies";
import organizationActions from "../../../../store/actions/organization";
import { StateDto } from "../../../../types/states.dto";
import { useDispatch } from "react-redux";
import { UserDto } from "../../../../types/user.dto";
import { AcademyDto } from "../../../../types/academy.dto";
import { OrganizationDto } from "../../../../types/organization.dto";
import { ToastContainer, toast } from "react-toastify";
const Component = ({
  academies,
  organizations,
  updateAcademyStates,
  updateOrganizationStates,
  user,
}: {
  academies: AcademyDto[];
  organizations: OrganizationDto[];
  updateAcademyStates: StateDto;
  updateOrganizationStates: StateDto;
  user: UserDto;
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [added, setAdded] = useState(false);
  useEffect(() => {
    if (params.id && !!academies && !!organizations && !added) {
      const entity = [
        ...academies.map((academy) => {
          return { ...academy, type: "academy" };
        }),
        ...organizations.map((academy) => {
          return { ...academy, type: "organization" };
        }),
      ].find((ent) => ent.inviteLink === params.id);
      if (!!entity && entity.type === "academy") {
        dispatch(
          academyActions.addMember({
            user: user._id,
            role: "OBSERVER",
            _id: entity._id,
          })
        );
      }
      if (!!entity && entity.type === "organization") {
        organizationActions.addMember({
          role: "OBSERVER",
          user: user._id,
          _id: entity._id,
        });
      }
    }
  }, [params, academies, organizations]);
  useEffect(() => {
    if (!academies) dispatch(academyActions.getAll({}));
    /* if (!organizations) dispatch(organizationActions.getAll({})); */
  }, []);
  useEffect(() => {
    if (updateAcademyStates.success) {
      dispatch(academyActions.resetUpdate());
      toast.success("Aceptado");
      setAdded(true);
      navigate("/dashboard");
    }
    if (updateAcademyStates.error) {
      toast.error(updateAcademyStates.error);
      navigate("/dashboard");
      dispatch(organizationActions.resetUpdate());
    }
  }, [updateAcademyStates]);
  useEffect(() => {
    if (updateOrganizationStates.success) {
      dispatch(organizationActions.resetUpdate());
      toast.success("Aceptado");
      setAdded(true);
      navigate("/dashboard");
    }
    if (updateOrganizationStates.error) {
      toast.error(updateOrganizationStates.error);
      navigate("/dashboard");
      dispatch(organizationActions.resetUpdate());
    }
  }, [updateOrganizationStates]);

  return (
    <>
      <Wrapper>
        <Loader color="Primary"></Loader>
      </Wrapper>
      <ToastContainer limit={1}></ToastContainer>
    </>
  );
};

const states = ({ academyStore, organizationStore, userStore }) => {
  const { data: academies } = academyStore.all;
  const { data: organizations } = organizationStore.all;
  const { states: updateAcademyStates } = academyStore.update;
  const { states: updateOrganizationStates } = organizationStore.update;
  const { data: user } = userStore;
  return {
    academies,
    organizations,
    updateAcademyStates,
    updateOrganizationStates,
    user,
  };
};

export default connect(states)(Component);
