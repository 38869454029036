import talent from "./ait-talent";
import company from "./companies";
import technology from "./external-abilities";
import techSkill from "./external-criterions";
import search from "./searches";
import ait from "./ait";
const services = {
  talent,
  company,
  ait,
  technology,
  techSkill,
  search,
};

export default services;
