import styled from "styled-components";

export const List = styled("ul")`
  height: 100%;
  padding: 0px;
  margin: 10px 0px 0px;
`;

export const ListItem = styled("li")`
  list-style: none;
  padding: 0px;
  margin: 0px;
  display: block;
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const Button = styled("button")<{ active: boolean }>`
  border: 0px;
  margin: 0px;
  display: block;
  width: 100%;
  border-radius: 5px;
  box-shadow: 1px 1px 3px 0 rgba(112, 112, 112, 0.16);
  transition: all 0.3s ease-in-out;
  background-color: ${({ active }) => (active ? "#daf1ff" : "white")};
  cursor: pointer;
  height: 40px;
  text-align: left;
`;
