import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Modal from "../../../../../../components/Modal";
import { Formik } from "formik";
import { Center } from "../../../../../../components/StyledComponents";
import { schema } from "../../../../../../constants/form/cdoc/addUser";
import Grid from "../../../../../../components/Grid";

import Submit from "../../../../../../components/Form/Submit";

import { hideModal } from "../../../../../../store/actions/modal";

import { StateDto } from "../../../../../../types/states.dto";
import { resetUpdateCDOCs } from "../../../../../../store/actions/cdoc";

import { updateListen } from "../../../../../../store/actions/listen";

const Component = ({
  updateStates,
  listen,
}: {
  updateStates: StateDto;
  listen: {
    _id: string;
    published: boolean;
    name: string;
    description: string;
    __v: number | string;
  };
}) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const dispatch = useDispatch();

  const onSubmit = ({ values, actions }) => {
    dispatch(
      updateListen({
        _id: listen._id,
        body: {
          published: !listen.published,
          available: !listen.published,
          __v: listen.__v,
        },
      })
    );

    setFormActions(actions);
  };

  useEffect(() => {
    if (updateStates.success) {
      dispatch(hideModal());
      formActions?.resetForm();
    }

    if (updateStates.error) {
      setTimeout(() => {
        dispatch(resetUpdateCDOCs());
      }, 1000);
    }
  }, [updateStates]);

  return (
    <Modal
      name="private-listen"
      title={`¿Seguro quieres cambiar el status del contenido a ${
        !!listen.published ? "Privado" : "Publico"
      }?`}
      description={
        !!listen.published
          ? `El Listen se esconderá en todos los lugares en donde esta publicado. Ningún usuario podrá acceder a el.`
          : `El Listen se mostrara en todos los lugares en donde esta publicado. Los usuarios podran acceder.`
      }
    >
      <Formik
        initialValues={{}}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="invite-academy-modal-form"
            >
              <Grid.Row>
                <Grid.Col>
                  <Center gap={"10px"}>
                    <Submit
                      isSubmmiting={isSubmitting}
                      form="invite-academy-modal-form"
                      color="Primary"
                      style={{ width: "170px" }}
                      options={{
                        type: "filled",
                        skin: "primary",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                    >
                      Confirmar
                    </Submit>
                  </Center>
                </Grid.Col>
              </Grid.Row>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const states = ({ cdocStore }) => {
  const { states: updateStates } = cdocStore.update;
  return { updateStates };
};

export default connect(states)(Component);
