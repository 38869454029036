import styled from "styled-components";

export const CohortModal = styled("div")`
  min-width: 250px;
  height: 0px;
  padding: 24px 10px 24px 27px;
  position: absolute;
  top: ${(props) => props.top};
  right: ${(props) => props.right};
  background-color: #fff;
  z-index: 200;
  overflow: hidden;
  display: none;
  &.active {
    display: block;
    height: 229px;
  }
`;

export const ModalTitle = styled("h3")`
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: #646464;
`;

export const DataText = styled("p")`
  margin: 0;
  font-size: ${(props) => props.size};
  color: #646464;
`;
