import React, { useEffect, useState } from "react";
import { FlexContainer } from "../StyledComponents";
import {
  ProfileHambuger,
  ProfileInfo,
  ProfileInfoTitle,
  ProfileMenuImageWrapper,
  ProfileWrapper,
} from "./style";
import { Img } from "../../pages/SignIn/styles";
import { AcademyDto } from "../../types/academy.dto";
import { OrganizationDto } from "../../types/organization.dto";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../Loader";
import { UserDto } from "../../types/user.dto";
import { CurrentDto } from "../../types/current.dto";
import services from "../../services";
import { FaBars } from "react-icons/fa";

const Component = ({
  user,
  current,
  academies,
  organizations,
  sidebarCollapsed,
  onClick,
}: {
  user: UserDto;
  current: CurrentDto;
  academies: AcademyDto[];
  organizations: OrganizationDto[];
  sidebarCollapsed: boolean;
  onClick: () => void;
}) => {
  const [entity, setEntity] = useState<AcademyDto | OrganizationDto>();
  const navigate = useNavigate();
  useEffect(() => {
    if (current.currentSection === "academy") {
      const fetchData = async () => {
        const response: any = await services.academy.getOne({
          _id: current.id,
        });
        if (response.response) {
          setEntity(response.response);
        }
      };
      fetchData();
    }
  }, [current]);

  if (!entity && current.currentSection !== "personal")
    return <Loader color="Primary"></Loader>;
  return (
    <ProfileWrapper sidebarCollapsed={sidebarCollapsed}>
      <ProfileInfo sidebarCollapsed={sidebarCollapsed}>
        <ProfileMenuImageWrapper>
          <Img src={entity?.picture}></Img>
        </ProfileMenuImageWrapper>
        <ProfileInfoTitle hideLine={1}>
          {current.currentSection === "personal"
            ? `${user.name} ${user.lastName}`
            : entity?.name}
        </ProfileInfoTitle>
      </ProfileInfo>
      <ProfileHambuger sidebarCollapsed={sidebarCollapsed} onClick={onClick}>
        <FaBars size={22}></FaBars>
      </ProfileHambuger>
    </ProfileWrapper>
  );
};

const states = ({
  userStore,
  currentStore,
  academyStore,
  organizationStore,
}) => {
  const { data: user } = userStore;
  const { data: current } = currentStore;
  const { data: academies } = academyStore.all;
  const { data: organizations } = organizationStore.all;
  return {
    user,
    current,
    academies,
    organizations,
  };
};

export default connect(states)(Component);
