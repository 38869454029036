import * as Yup from "yup";

export const schema = Yup.object().shape({
  name: Yup.string()
    .required("Ingresa el nombre de tu Contenido.")
    .min(5, "Minimo 5 letras"),
  link: Yup.string()
    .matches(/^https:\/\//, "Porfavor introduzca https link")
    .required("Porfavor introduzca https ej:https://www.example.com"),
  picture: Yup.string(),
  available: Yup.bool().required(),
  description: Yup.string()
    .required("Se requiere descripcion")
    .min(5, "Minimo 5 letras"),
  cohorts: Yup.array(),
  units: Yup.array(),
  allCohorts: Yup.bool().required(),
});

interface FormValues {
  name: string;
  link: string;
  picture?: string;
  available: boolean;
  units: string[];
  cohorts: string[];
  allCohorts: boolean;
}

export const initialValues = (values) => {
  return {
    name: "",
    picture: "",
    link: "",
    available: false,
    cohorts: [],
    units: [],
    allCohorts: false,
    ...values,
  };
};
