import {
  GetIndividualStats,
  GET_INDIVIDUAL_STATS,
  RESET_GET_INDIVIDUAL_STATS,
  ResetGetIndividualStats,
  GetCohortsStats,
  GET_COHORT_STATS,
  RESET_GET_COHORT_STATS,
  ResetGetCohortStats,
  GetAllStudentsStats,
  GET_ALL_STUDENTS_STATS,
  GetAcademyStats,
  GET_ACADEMY_STATS,
  ResetAcademyStats,
  RESET_ACADEMY_STATS,
  ResetOrganizationStats,
  GetOrganizationStats,
  GET_ORGANIZATION_STATS,
  RESET_ORGANIZATION_STATS,
  GET_MANY_INDIVIDUAL_STATS,
  RESET_GET_ALL_STUDENTS_STATS,
  GET_COHORT_USER_TIME,
  RESET_GET_COHORT_USER_TIME,
  GET_ACADEMY_USER_TIME,
  GetAcademySuscriptionStats,
  GET_ACADEMY_SUSCRIPTION_STATS,
  ResetGetAcademySuscription,
  RESET_GET_ACADEMY_SUSCRIPTION_STATS,
  GetCohortSuscriptionStats,
  GET_COHORT_SUSCRIPTION_STATS,
  RESET_GET_COHORT_SUSCRIPTION_STATS,
  ResetGetCohortSuscription,
} from "../types/analytics";

export const getIndividualStats = (payload): GetIndividualStats => {
  return { type: GET_INDIVIDUAL_STATS, payload };
};

export const getAcademySuscriptionStats = (
  payload
): GetAcademySuscriptionStats => {
  return { type: GET_ACADEMY_SUSCRIPTION_STATS, payload };
};
export const getCohortSuscriptionStats = (
  payload
): GetCohortSuscriptionStats => {
  return { type: GET_COHORT_SUSCRIPTION_STATS, payload };
};

export const resetGetCohortSuscriptionStats = (): ResetGetCohortSuscription => {
  return { type: RESET_GET_COHORT_SUSCRIPTION_STATS, payload: null };
};

export const resetGetAcademySuscriptionStats =
  (): ResetGetAcademySuscription => {
    return { type: RESET_GET_ACADEMY_SUSCRIPTION_STATS, payload: null };
  };

export const resetGetIndividualStats = (): ResetGetIndividualStats => {
  return { type: RESET_GET_INDIVIDUAL_STATS, payload: null };
};

export const getCohortStats = (id: string): GetCohortsStats => {
  return { type: GET_COHORT_STATS, payload: id };
};

export const resetGetCohortStats = (): ResetGetCohortStats => {
  return { type: RESET_GET_COHORT_STATS, payload: null };
};

export const getManyIndividualStats = (payload) => {
  return { type: GET_MANY_INDIVIDUAL_STATS, payload };
};

export const resetGetAllStudentsStats = () => {
  return { type: RESET_GET_ALL_STUDENTS_STATS, payload: null };
};

export const getCohortUserTimeStats = (payload) => {
  return { type: GET_COHORT_USER_TIME, payload };
};
export const getAcademyUserTimeStats = (payload) => {
  return { type: GET_ACADEMY_USER_TIME, payload };
};

export const resetGetCohortUserTimeStats = () => {
  return { type: RESET_GET_COHORT_USER_TIME, payload: null };
};

export const analyticsStudents = (): GetAllStudentsStats => {
  return { type: GET_ALL_STUDENTS_STATS, payload: null };
};

export const getAcademyStats = (payload): GetAcademyStats => {
  return { type: GET_ACADEMY_STATS, payload };
};
export const resetGetAcademyStats = (): ResetAcademyStats => {
  return { type: RESET_ACADEMY_STATS, payload: null };
};
export const getOrganizationStats = (payload): GetOrganizationStats => {
  return { type: GET_ORGANIZATION_STATS, payload };
};
export const resetGetOrganizationStats = (): ResetOrganizationStats => {
  return { type: RESET_ORGANIZATION_STATS, payload: null };
};
