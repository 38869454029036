import styled from "styled-components";

export const Tabs = styled("div")``;
export const Header = styled("div")`
  border-radius: 10px;
  padding: 10px 10px 0px;
  box-shadow: 1px 3px 6px -2px rgb(112 112 112 / 16%);
`;
export const Body = styled("div")`
  padding: 30px 0px;
`;

export const Toggle = styled("button")`
  border: 0px;
  padding: 0px 12px 9px;
  position: relative;
  background-color: transparent;
  margin-right: 5px;
  cursor: pointer;
  font-size: 14px;
  color: ${({ active }) => (active ? "#1573e5" : "#697482")};

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 2px;
    background-color: ${({ active }) => (active ? "#1573e5" : "#697482")};
    transition: all 0.3s ease-in-out;
    border-radius: 1px;
  }

  &:hover {
    &:after {
      background-color: #1573e5;
    }
  }
`;

export const Content = styled("div")<{ active }>`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: ${({ active }) => (active ? "block" : "none")};
`;
