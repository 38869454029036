import { TeamDto } from "../../types/team.dto";

export const GET_ALL_TEAMS = "GET_ALL_TEAMS";
export const GET_ALL_TEAMS_ERROR = "GET_ALL_TEAMS_ERROR";
export const GET_ALL_TEAMS_SUCCESS = "GET_ALL_TEAMS_SUCCESS";
export const GET_TEAM = "GET_TEAM";
export const GET_TEAM_ERROR = "GET_TEAM_ERROR";
export const GET_TEAM_SUCCESS = "GET_TEAM_SUCCESS";
export const NEW_TEAM = "NEW_TEAM";
export const NEW_TEAM_ERROR = "NEW_TEAM_ERROR";
export const NEW_TEAM_SUCCESS = "NEW_TEAM_SUCCESS";
export const RESET_NEW_TEAM = "RESET_NEW_TEAM";
export const UPDATE_TEAM = "UPDATE_TEAM";
export const UPDATE_TEAM_ERROR = "UPDATE_TEAM_ERROR";
export const UPDATE_TEAM_SUCCESS = "UPDATE_TEAM_SUCCESS";
export const RESET_UPDATE_TEAM = "RESET_UPDATE_TEAM";
export const DELETE_TEAM = "DELETE_TEAM";
export const DELETE_TEAM_ERROR = "DELETE_TEAM_ERROR";
export const DELETE_TEAM_SUCCESS = "DELETE_TEAM_SUCCESS";
export const RESET_DELETE_TEAM = "RESET_DELETE_TEAM";
export const SET_UPDATE_TEAM_DATA = "SET_UPDATE_TEAM_DATA";
export const SET_NEW_TEAM_DATA = "SET_NEW_TEAM_DATA";
export const UPDATE_CURRENT_TEAM_LIST = "UPDATE_CURRENT_TEAM_LIST";
export const NEW_TEAM_INVITE_LINK = "NEW_TEAM_INVITE_LINK";
export const NEW_TEAM_INVITE_LINK_SUCCESS = "NEW_TEAM_INVITE_LINK_SUCCESS";
export const NEW_TEAM_INVITE_LINK_ERROR = "NEW_TEAM_INVITE_LINK_ERROR";
export const DELETE_TEAM_INVITE_LINK = "DELETE_TEAM_INVITE_LINK";
export const DELETE_TEAM_INVITE_LINK_SUCCESS =
  "DELETE_TEAM_INVITE_LINK_SUCCESS";
export const DELETE_TEAM_INVITE_LINK_ERROR = "DELETE_TEAM_INVITE_LINK_ERROR";
export const RESET_TEAM_DELETE_INVITE_LINK = "RESET_TEAM_DELETE_INVITE_LINK";
export const RESET_TEAM_NEW_INVITE_LINK = "RESET_TEAM_NEW_INVITE_LINK";
export const SELECT_TEAM = "SELECT_TEAM";
export const DELETE_TEAM_MEMBER = "DELETE_TEAM_MEMBER";
export const DELETE_TEAM_MEMBER_SUCCESS = "DELETE_TEAM_MEMBER_SUCCESS";
export const DELETE_TEAM_MEMBER_ERROR = "DELETE_TEAM_MEMBER_ERROR";
export const UPDATE_TEAM_ROLE = "UPDATE_TEAM_ROLE";

export interface UpdateTeamRole {
  type: typeof UPDATE_TEAM_ROLE;
  payload: { _id: string; user: string };
}

export interface SelectTeam {
  type: typeof SELECT_TEAM;
  payload: TeamDto;
}

export interface DeleteTeamMember {
  type: typeof DELETE_TEAM_MEMBER;
  payload: { _id: string; userId: string };
}
export interface DeleteTeamMemberSuccess {
  type: typeof DELETE_TEAM_MEMBER_SUCCESS;
  payload: null;
}
export interface DeleteTeamMemberError {
  type: typeof DELETE_TEAM_MEMBER_ERROR;
  payload: string | boolean;
}

export interface UpdateCurrentTeamList {
  type: typeof UPDATE_CURRENT_TEAM_LIST;
  payload: TeamDto[];
}

export interface GetAllTeams {
  type: typeof GET_ALL_TEAMS;
  payload: null;
}

export interface GetAllTeamsSuccess {
  type: typeof GET_ALL_TEAMS_SUCCESS;
  payload: TeamDto[];
}

export interface NewInviteLink {
  type: typeof NEW_TEAM_INVITE_LINK;
  payload: string;
}
export interface NewInviteLinkSuccess {
  type: typeof NEW_TEAM_INVITE_LINK_SUCCESS;
  payload: string;
}
export interface NewInviteLinkError {
  type: typeof NEW_TEAM_INVITE_LINK_ERROR;
  payload: string | boolean;
}
export interface ResetNewInviteLink {
  type: typeof RESET_TEAM_NEW_INVITE_LINK;
  payload: null;
}

export interface DeleteInviteLink {
  type: typeof DELETE_TEAM_INVITE_LINK;
  payload: string;
}
export interface DeleteInviteLinkSuccess {
  type: typeof DELETE_TEAM_INVITE_LINK_SUCCESS;
  payload: string;
}
export interface DeleteInviteLinkError {
  type: typeof DELETE_TEAM_INVITE_LINK_ERROR;
  payload: string | boolean;
}

export interface ResetDeleteInviteLink {
  type: typeof RESET_TEAM_DELETE_INVITE_LINK;
  payload: null;
}

export interface GetAllTeamsError {
  type: typeof GET_ALL_TEAMS_ERROR;
  payload: boolean | string;
}

export interface GetTeam {
  type: typeof GET_TEAM;
  payload: { _id: string };
}

export interface GetTeamSuccess {
  type: typeof GET_TEAM_SUCCESS;
  payload: TeamDto;
}

export interface GetTeamError {
  type: typeof GET_TEAM_ERROR;
  payload: boolean | string;
}

export interface NewTeam {
  type: typeof NEW_TEAM;
  payload: TeamDto;
}

export interface NewTeamSuccess {
  type: typeof NEW_TEAM_SUCCESS;
  payload: TeamDto;
}

export interface NewTeamError {
  type: typeof NEW_TEAM_ERROR;
  payload: boolean | string;
}

export interface SetUpdateTeamData {
  type: typeof SET_UPDATE_TEAM_DATA;
  payload: TeamDto;
}

export interface SetNewTeamData {
  type: typeof SET_NEW_TEAM_DATA;
  payload: TeamDto;
}

export interface ResetNewTeam {
  type: typeof RESET_NEW_TEAM;
  payload: null;
}

export interface UpdateTeam {
  type: typeof UPDATE_TEAM;
  payload: TeamDto;
}

export interface UpdateTeamSuccess {
  type: typeof UPDATE_TEAM_SUCCESS;
  payload: TeamDto;
}

export interface UpdateTeamError {
  type: typeof UPDATE_TEAM_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateTeam {
  type: typeof RESET_UPDATE_TEAM;
  payload: null;
}

export interface DeleteTeam {
  type: typeof DELETE_TEAM;
  payload: { _id: string };
}

export interface DeleteTeamSuccess {
  type: typeof DELETE_TEAM_SUCCESS;
  payload: null;
}

export interface DeleteTeamError {
  type: typeof DELETE_TEAM_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteTeam {
  type: typeof RESET_DELETE_TEAM;
  payload: null;
}

export interface State {
  all: {
    data: TeamDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  selected: {
    data: TeamDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  create: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  delete: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  invite: {
    create: {
      data: null | string;
      states: {
        loading: boolean;
        success: boolean;
        error: boolean | string;
      };
    };
    delete: {
      states: {
        loading: boolean;
        success: boolean;
        error: boolean | string;
      };
    };
  };
  update: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  all: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  selected: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  create: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  delete: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  invite: {
    create: {
      data: null,
      states: {
        loading: false,
        success: false,
        error: false,
      },
    },
    delete: {
      states: {
        loading: false,
        success: false,
        error: false,
      },
    },
  },
  update: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllTeams
  | GetAllTeamsSuccess
  | GetAllTeamsError
  | GetTeam
  | GetTeamSuccess
  | GetTeamError
  | NewTeam
  | NewTeamSuccess
  | NewTeamError
  | ResetNewTeam
  | UpdateTeam
  | UpdateTeamSuccess
  | UpdateTeamError
  | ResetUpdateTeam
  | DeleteTeam
  | DeleteTeamSuccess
  | DeleteTeamError
  | ResetDeleteTeam
  | SetUpdateTeamData
  | SetNewTeamData
  | UpdateCurrentTeamList
  | NewInviteLink
  | NewInviteLinkSuccess
  | NewInviteLinkError
  | DeleteInviteLink
  | DeleteInviteLinkSuccess
  | DeleteInviteLinkError
  | ResetNewInviteLink
  | ResetDeleteInviteLink
  | SelectTeam
  | DeleteTeamMember
  | DeleteTeamMemberSuccess
  | DeleteTeamMemberError;
