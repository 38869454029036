import { Container } from "./styles";

interface propsInterface {
  margin?: number;
  children: any;
}

const Component = ({ children, margin }: propsInterface) => {
  return <Container margin={margin}>{children}</Container>;
};

export default Component;
