import {
  initialState,
  Actions,
  State,
  GET_ALL_ROADMAPS,
  GET_ALL_ROADMAPS_ERROR,
  GET_ALL_ROADMAPS_SUCCESS,
  GET_ROADMAP,
  GET_ROADMAP_ERROR,
  GET_ROADMAP_SUCCESS,
  NEW_ROADMAP,
  NEW_ROADMAP_ERROR,
  NEW_ROADMAP_SUCCESS,
  RESET_NEW_ROADMAP,
  UPDATE_ROADMAP,
  UPDATE_ROADMAP_ERROR,
  UPDATE_ROADMAP_SUCCESS,
  RESET_UPDATE_ROADMAP,
  DELETE_ROADMAP,
  DELETE_ROADMAP_ERROR,
  DELETE_ROADMAP_SUCCESS,
  RESET_DELETE_ROADMAP,
  UPLOAD_MEDIA,
  UPDATE_ROADMAP_LOADING,
  RESET_GET_ROADMAP,
  ADD_USER_TO_ROADMAP,
  SHARED_ROADMAP,
  SET_GET_ROADMAP,
  RESET_UPDATE_MANY_ROADMAP,
  UPDATE_MANY_ROADMAP,
  UPDATE_MANY_ROADMAP_ERROR,
  UPDATE_MANY_ROADMAP_SUCCESS,
  RESET_GET_ALL_ROADMAP_DATA,
} from "../types/roadmap";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_ROADMAPS:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_ROADMAPS_SUCCESS:
      return {
        ...state,
        all: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_ROADMAPS_ERROR:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_GET_ALL_ROADMAP_DATA:
      return {
        ...state,
        all: initialState.all,
      };
    case UPLOAD_MEDIA:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_MANY_ROADMAP:
      return {
        ...state,
        updateMany: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_MANY_ROADMAP_SUCCESS:
      return {
        ...state,
        updateMany: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_MANY_ROADMAP_ERROR:
      return {
        ...state,
        updateMany: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_MANY_ROADMAP:
      return {
        ...state,
        updateMany: initialState.updateMany,
      };

    case ADD_USER_TO_ROADMAP:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case SHARED_ROADMAP:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_ROADMAP_LOADING:
      return {
        ...state,
        new: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ROADMAP:
      return {
        ...state,
        roadmap: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ROADMAP_SUCCESS:
      return {
        ...state,
        roadmap: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ROADMAP_ERROR:
      return {
        ...state,
        roadmap: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case NEW_ROADMAP:
      return {
        ...state,
        new: {
          ...state.new,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_ROADMAP_SUCCESS:
      return {
        ...state,
        new: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case SET_GET_ROADMAP:
      return {
        ...state,
        roadmap: {
          ...state.roadmap,
          data: !!action.payload ? action.payload : null,
        },
      };
    case RESET_GET_ROADMAP:
      return {
        ...state,
        roadmap: {
          ...state.roadmap,
          states: {
            success: false,
            loading: false,
            error: false,
          },
        },
      };
    case NEW_ROADMAP_ERROR:
      return {
        ...state,
        new: {
          ...state.new,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_ROADMAP:
      return {
        ...state,
        new: {
          data: state.new.data,
          states: {
            loading: false,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_ROADMAP:
      return {
        ...state,
        delete: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_ROADMAP_SUCCESS:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_ROADMAP_ERROR:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_ROADMAP:
      return {
        ...state,
        delete: initialState.delete,
      };
    case UPDATE_ROADMAP:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_ROADMAP_SUCCESS:
      return {
        ...state,
        update: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_ROADMAP_ERROR:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_ROADMAP:
      return {
        ...state,
        update: initialState.update,
      };
    default:
      return state;
  }
};

export default reducers;
