import React, { useEffect, useState } from "react";
import { BiNews } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import Button from "../../../../../../components/Button";
import Icon from "../../../../../../components/Icon";
import { CardColumn, CardRow, CardTitle } from "../../../Profile/style";
import { DataText, Item } from "../../style";
import academyActions from "../../../../../../store/actions/academies";
import {
  deleteMember,
  getAllCohorts,
  resetDeleteCohort,
  resetGetAllCohortsData,
} from "../../../../../../store/actions/cohort";
import CohortModal from "../MembersList/components/CohortModal";
import { useCurrentLayout } from "../../../../../../provider/CurrentProvider";
import teamActions from "../../../../../../store/actions/team";
import AcademyInviteButton from "../../components/InviteButton";
import OrganizationInviteButton from "../../../../Organization/Gestion/components/InviteButton";
import Loader from "../../../../../../components/Loader";
import { CohortDto } from "../../../../../../types/cohort.dto";
import { AcademyDto } from "../../../../../../types/academy.dto";
import { toast } from "react-toastify";
import usersActions from "../../../../../../store/actions/users";
import InviteCohortButton from "../InviteCohortButton";
import { FlexContainer } from "../../../../../../components/StyledComponents";
const Component = ({
  cohorts,
  academies,
  organizations,
  users,
  current,
  teams,
  selectedItem,
  deletedCohort,
  deleteCohortStates,
  updatedAcademyStates,
  updatedAcademy,
}) => {
  const [fetchOn, setFetchOn] = useState<boolean>(false);
  const [activeItem, setActiveItem] = useState<string>();
  const { data } = useCurrentLayout();
  const entity =
    current.currentSection === "academy" ? academies : organizations;
  const dispatch = useDispatch();
  const [currentCohorts, setCurrentCohorts] = useState<CohortDto[]>();
  const [currentAcademies, setCurrentAcademies] = useState<AcademyDto[]>();
  useEffect(() => {
    if (!academies && fetchOn)
      dispatch(academyActions.getAll({ filterBy: { _id: current.id } }));
  }, [academies, fetchOn]);

  useEffect(() => {
    if (!users && fetchOn)
      dispatch(
        usersActions.getAllByAcademy({
          _id: current.id,
          payload: { filterBy: {} },
        })
      );
  }, [users, fetchOn]);

  useEffect(() => {
    if (!cohorts && fetchOn)
      dispatch(
        getAllCohorts({ filterBy: { academy: current.id, active: true } })
      );
  }, [cohorts, fetchOn]);

  useEffect(() => {
    if (cohorts && fetchOn) {
      setCurrentCohorts(cohorts);
    }
  }, [cohorts]);

  useEffect(() => {
    if (academies && fetchOn) {
      setCurrentAcademies(academies);
    }
  }, [academies]);

  useEffect(() => {
    if (deleteCohortStates.success) {
      setCurrentCohorts((state) => [
        ...(state ?? []).filter((st) => st._id !== deletedCohort._id),
      ]);
      dispatch(resetDeleteCohort());
    }
    if (deleteCohortStates.error) {
      toast.error(deleteCohortStates.error);
      dispatch(resetDeleteCohort());
    }
  }, [deleteCohortStates]);

  useEffect(() => {
    if (updatedAcademyStates.success) {
      setCurrentAcademies((state) => [
        ...(state ?? []).map((st) =>
          st._id === updatedAcademy._id ? updatedAcademy : st
        ),
      ]);
      dispatch(academyActions.resetUpdate());
    }
    if (updatedAcademyStates.error) {
      toast.error(updatedAcademyStates.error);
      dispatch(academyActions.resetUpdate());
    }
  }, [updatedAcademyStates]);
  useEffect(() => {
    return () => {
      dispatch(resetGetAllCohortsData());
      dispatch(academyActions.resetGetAllAcademiesData());
      dispatch(usersActions.resetGetAll());
    };
  }, []);

  useEffect(() => {
    if (!academies && !cohorts && !users) {
      setFetchOn(true);
    }
  }, [academies, cohorts, users]);

  if (!currentAcademies || !users || !currentCohorts)
    return <Loader color="Primary"></Loader>;
  return (
    <>
      <CardRow justify="space-between">
        <CardRow>
          <Icon icon="" size="40px" />
          <CardColumn gap="9px">
            <DataText>{selectedItem.label}</DataText>
            {current.currentSection !== "personal" && (
              <DataText>{selectedItem.data.description}</DataText>
            )}
          </CardColumn>
        </CardRow>

        {current.currentSection === "academy" && (
          <FlexContainer direction="column" gap="10px" justify="center">
            <AcademyInviteButton></AcademyInviteButton>
            <InviteCohortButton></InviteCohortButton>
          </FlexContainer>
        )}
      </CardRow>

      <CardColumn style={{ width: "100%" }} gap="15px">
        <CardRow align="center" gap="8px">
          <BiNews fontSize={20}></BiNews>
          <CardTitle>Listado de Observadores</CardTitle>
        </CardRow>
        <CardColumn>
          {!!currentAcademies &&
            !!users &&
            currentAcademies
              .filter((ent) => ent._id === current.id && ent.active)[0]
              .users.filter((user) => user.role === "OBSERVER" && !user.roleEnd)
              .map((user) => {
                const userFounded = users?.find(
                  (userFound) => userFound._id === user.user && userFound.active
                );
                return (
                  <Item>
                    <CardRow align="center" justify="space-between">
                      <CardRow align="center" gap="3px">
                        <Icon icon=""></Icon>
                        <CardColumn>
                          <DataText size="14px">{`${userFounded?.name} ${userFounded.lastName}`}</DataText>
                          <DataText size="12px">
                            {userFounded?.position}
                          </DataText>
                        </CardColumn>
                      </CardRow>
                      <CardRow gap="15px" align="center">
                        <div style={{ position: "relative" }}>
                          <Button
                            type="button"
                            options={{
                              type: "link",
                              size: "md",
                              skin: "gray",
                            }}
                            style={{
                              fontSize: "12px",
                              fontWeight: "200",
                              boxShadow: "none",
                            }}
                            onClick={() => setActiveItem(user.user)}
                          >
                            En{" "}
                            {current.currentSection === "academy"
                              ? currentCohorts?.filter(
                                  (cohort) =>
                                    cohort.studentsIds.find(
                                      (student) => student === user.user
                                    ) && cohort.active === true
                                ).length
                              : teams
                                  ?.filter(
                                    (team) => team.organization === current.id
                                  )
                                  .filter((team) => team.active)
                                  .filter((team) =>
                                    team.users.find(
                                      (teamUser) => teamUser === user.user
                                    )
                                  ).length}{" "}
                            {current.currentSection === "academy"
                              ? "Co-horts"
                              : "Equipos"}
                          </Button>
                          <CohortModal
                            setActive={setActiveItem}
                            title={
                              current.currentSection === "academy"
                                ? "Co-horts"
                                : "Equipos"
                            }
                            top={"20px"}
                            right={"0px"}
                            cohorts={
                              current.currentSection === "academy"
                                ? currentCohorts?.filter(
                                    (cohort) =>
                                      cohort.studentsIds.find(
                                        (student) => student === user.user
                                      ) && cohort.active === true
                                  )
                                : teams
                                    ?.filter(
                                      (team) => team.organization === current.id
                                    )
                                    .filter((team) => team.active)
                                    .filter((team) =>
                                      team.users.find(
                                        (teamUser) => teamUser === user.user
                                      )
                                    )
                            }
                            payload={{ userId: user.user }}
                            actions={
                              current.currentSection === "academy"
                                ? deleteMember
                                : teamActions.deleteMember
                            }
                            active={activeItem === user.user}
                          ></CohortModal>
                        </div>
                        <Button
                          type="button"
                          options={{
                            type: "outline",
                            size: "sm",
                            skin: "gray",
                          }}
                          style={{
                            minWidth: "77px",
                            width: "112px",
                            fontSize: "12px",
                            fontWeight: "200",
                          }}
                          onClick={() =>
                            dispatch(
                              !!data?.updateRole &&
                                data.updateRole({
                                  _id: current.id,
                                  user: { user: user.user, role: "ADMIN" },
                                })
                            )
                          }
                        >
                          Hacer Admin
                        </Button>

                        <Button
                          type="button"
                          options={{
                            type: "outline",
                            size: "sm",
                            skin: "gray",
                          }}
                          style={{
                            minWidth: "77px",
                            width: "156px",
                            fontSize: "12px",
                            fontWeight: "200",
                          }}
                          onClick={() =>
                            dispatch(
                              academyActions.deleteMember({
                                _id: current.id,
                                userId: user.user,
                              })
                            )
                          }
                        >
                          Eliminar de Academy
                        </Button>
                      </CardRow>
                    </CardRow>
                  </Item>
                );
              })}
        </CardColumn>
      </CardColumn>
    </>
  );
};

const states = ({
  cohortStore,
  academyStore,
  usersStore,
  currentStore,
  organizationStore,
  teamStore,
}) => {
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: academies } = academyStore.all;
  const { data: organizations } = organizationStore.all;
  const { data: users } = usersStore.all;
  const { data: teams } = teamStore.all;
  const { data: current } = currentStore;
  const { data: deletedCohort, states: deleteCohortStates } =
    cohortStore.deleteCohort;
  const { states: updatedAcademyStates, data: updatedAcademy } =
    academyStore.update;
  return {
    cohorts,
    academies,
    organizations,
    users,
    current,
    teams,
    deletedCohort,
    deleteCohortStates,
    updatedAcademyStates,
    updatedAcademy,
  };
};
export default connect(states)(Component);
