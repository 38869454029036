import React, { useEffect, useState } from "react";
import SelectMenu from "../../../../components/SelectMenu";
import Grid from "../../../../components/Grid";
import MyAcademies from "./components/MyAcademies";
import { Title } from "../../../SignIn/styles";
import InvitationList from "../../Academy/Gestion/components/InvitationList";
import services from "../../../../services";
import { InvitationDto } from "../../../../types/invitation.dto";
import { ChildrenSelectorWrapper, CounterWrapper } from "./style";
import { connect } from "react-redux";
import RequestsList from "../../Academy/Gestion/components/RequestsList";
import { RequestDto } from "../../../../types/request.dto";
import { useWindowSize } from "../../../../hooks/useWindowScreen";

const Component = ({ user }) => {
  const [currentInvitations, setCurrentInvitations] = useState<InvitationDto[]>(
    []
  );
  const { isMobile } = useWindowSize();
  const [currentRequests, setCurrentRequests] = useState<RequestDto[]>([]);
  const items = [
    {
      id: "MY-ACADEMIES",
      label: "Mis Academias",
      Component: <MyAcademies></MyAcademies>,
    },
    {
      id: "MY-INVITATIONS",
      label: "Mis Invitaciones",
      Component: (
        <ChildrenSelectorWrapper style={{ minHeight: "400px" }}>
          <InvitationList
            selectedItem={{
              label: "Invitaciones",
              value: "invitations",
              data: {
                description: "Las invitaciones pendientes",
              },
            }}
          ></InvitationList>
        </ChildrenSelectorWrapper>
      ),
      After: (
        <CounterWrapper>
          {currentInvitations.length > 9 ? `9+` : currentInvitations.length}
        </CounterWrapper>
      ),
    },
    {
      id: "MY_REQUESTS",
      label: "Mis Solicitudes",
      Component: (
        <ChildrenSelectorWrapper style={{ minHeight: "400px" }}>
          <RequestsList
            selectedItem={{
              label: "Solicitudes",
              value: "requests",
              data: {
                description: "Solicitudes pendientes.",
              },
            }}
          ></RequestsList>
        </ChildrenSelectorWrapper>
      ),
      After: (
        <CounterWrapper>
          {currentRequests.length > 9 ? `9+` : currentRequests.length}
        </CounterWrapper>
      ),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const responseInvitation: any = await services.invitation.getAll({
        filterBy: { active: true, status: "PENDING", email: user.email },
      });
      const responseRequests: any = await services.request.getAll({
        filterBy: { sentBy: user._id, active: true, status: "PENDING" },
      });
      if (responseInvitation.response) {
        setCurrentInvitations(responseInvitation.response);
      }
      if (responseRequests.response) {
        setCurrentRequests(responseRequests.response);
      }
    };
    fetchData();
  }, []);

  return (
    <Grid.Container marginMobile={0.1}>
      <Title>Home</Title>
      <SelectMenu
        noShowMenu={isMobile}
        selectorItems={items}
        selectorSizes={{ sm: 12, lg: 4 }}
        childrenSizes={{ sm: 12, lg: 7.6, offSet: { lg: 0.4 } }}
      ></SelectMenu>
    </Grid.Container>
  );
};

const states = ({ userStore }) => {
  const { data: user } = userStore;
  return {
    user,
  };
};

export default connect(states)(Component);
