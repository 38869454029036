import React from "react";
import Button from "../../../../../components/Button";

const Component = ({ setFilter, fieldName, value, filters, name }) => {
  return (
    <Button
      type="button"
      onClick={() => setFilter({ field: fieldName, value: value })}
      options={{
        size: "md",
        type: "filled",
        skin: "gray",
      }}
      minwidth="150px"
      style={{
        width: "119px",
        height: "34px",
        ...(!!filters.find(
          (filter) => filter.field === fieldName && filter.value === value
        ) && {
          backgroundColor: "#061a4e",
        }),
        ...(!filters.find((filter) => filter.field === fieldName) &&
          value === "no" && {
            backgroundColor: "#061a4e",
          }),
      }}
    >
      {name}
    </Button>
  );
};

export default Component;
