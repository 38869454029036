import styled from "styled-components";
import { FaCloudUploadAlt } from "react-icons/fa";

export const Placeholder = styled("div")`
  font-size: 24px;
  font-weight: 500;
  color: rgb(105, 116, 130);
  text-align: center;
  transition: color 0.2s ease-in-out;
`;

export const Icon = styled(FaCloudUploadAlt)`
  transition: color 0.2s ease-in-out;
`;

export const Wrapper = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  min-width: 300px;
  box-shadow: 0 0 2px 0 rgba(21, 115, 229, 0.16);
  border-radius: 10px;

  background-color: ${(props) => props.theme.colors.White};
  transition: background-color 0.2s ease-in-out;

  &.active {
    outline: 1px solid black;
  }

  &.small {
    height: ${({ height }) => height || "200px"};

    ${Placeholder} {
      font-size: 16px;
    }
  }

  &.big {
    height: ${({ height }) => height || "419px"};

    ${Placeholder} {
      font-size: 24px;
    }
  }

  &:hover {
    background-color: #1573e5;

    ${Icon} {
      color: #fff;
    }

    ${Placeholder} {
      color: #fff;
    }
  }
`;
