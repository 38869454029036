import {
  NewAitTalent,
  NEW_AIT_TALENT,
  ResetNewAitTalent,
  RESET_NEW_AIT_TALENT,
  GET_ALL_AIT_TALENTS,
  GetAllAitTalents,
  UpdateAitTalent,
  UPDATE_AIT_TALENT,
  ResetUpdateAitTalent,
  RESET_UPDATE_AIT_TALENT,
  GetAitTalent,
  DeleteAitTalent,
  DELETE_AIT_TALENT,
  GET_AIT_TALENT,
  UPDATE_MANY_AIT_TALENT,
  RESET_DELETE_AIT_TALENT,
} from "../../types/recruiting/ait-talent";

const create = (payload): NewAitTalent => {
  return { type: NEW_AIT_TALENT, payload };
};
const resetCreate = (): ResetNewAitTalent => {
  return { type: RESET_NEW_AIT_TALENT, payload: null };
};

const updateMany = (payload) => {
  return { type: UPDATE_MANY_AIT_TALENT, payload };
};

const update = (payload): UpdateAitTalent => {
  return { type: UPDATE_AIT_TALENT, payload };
};

const resetUpdate = (): ResetUpdateAitTalent => {
  return { type: RESET_UPDATE_AIT_TALENT, payload: null };
};

const getAll = (): GetAllAitTalents => {
  return { type: GET_ALL_AIT_TALENTS, payload: null };
};

const getOne = (payload: { _id: string }): GetAitTalent => {
  return { type: GET_AIT_TALENT, payload };
};

const deleteOne = (payload: { _id: string }): DeleteAitTalent => {
  return { type: DELETE_AIT_TALENT, payload };
};

const resetDeleteOne = () => {
  return { type: RESET_DELETE_AIT_TALENT, payload: null };
};
const actions = {
  create,
  resetCreate,
  update,
  resetUpdate,
  getAll,
  getOne,
  resetDeleteOne,
  deleteOne,
  updateMany,
};

export default actions;
