import styled from "styled-components";
import { EllipsisText } from "../StyledComponents";

export const ProfileMenuImageWrapper = styled("div")`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
`;

export const ProfileWrapper = styled("div")`
  padding: 7px 10px 0px 10px;
  position: relative;
  width: 100%;
  max-width: ${({ sidebarCollapsed }) => (sidebarCollapsed ? "90px" : "260px")};
  transition: max-width 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ProfileInfo = styled("div")`
  visibility: ${(props) => (props.sidebarCollapsed ? "hidden" : "visible")};
  opacity: ${({ sidebarCollapsed }) => (sidebarCollapsed ? 0 : 1)};
  transition: opacity 0.3s ease-in-out;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const ProfileInfoTitle = styled("h3")`
  font-size: 12px;
  color: #2a2747;
  ${EllipsisText}
`;

export const ProfileHambuger = styled("button")`
  all: unset;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 0;
  cursor: pointer;
  background-color: ${(props) =>
    props.sidebarCollapsed ? "transparent" : props.theme.colors.Primary};
  color: ${(props) =>
    props.sidebarCollapsed
      ? props.theme.colors.Primary
      : props.theme.colors.White};
  padding: 6.5px 3px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
`;
