import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import Input from "../../../../../../components/Form/Input";
import Submit from "../../../../../../components/Form/Submit";
import ReactSelect from "../../../../../../components/Form/ReactSelect";
import {
  initialValues,
  schema,
} from "../../../../../../constants/form/organization/new";
import organizationActions from "../../../../../../store/actions/organization";

import { getAllEvents } from "../../../../../../store/actions/event";
import { getAllAbilities } from "../../../../../../store/actions/ability";
import { AbilityDto } from "../../../../../../types/ability.dto";
import { EventDto } from "../../../../../../types/event.dto";
import Button from "../../../../../../components/Button";
import { hideModal } from "../../../../../../store/actions/modal";
import { UserDto } from "../../../../../../types/user.dto";
import { getAllMentors } from "../../../../../../store/actions/mentor";
import { Center } from "../../../../../../components/StyledComponents";
import { CurrentDto } from "../../../../../../types/current.dto";
import { CohortDto } from "../../../../../../types/cohort.dto";
import FileUploader from "../../../../../../components/Form/FileUploader";
import Icon from "../../../../../../components/Icon";
import UploadIcon from "../../../../../../assets/icons/cloud-upload.svg";
import { AcademyDto } from "../../../../../../types/academy.dto";
import { UsersDto } from "../../../../../../types/users.dto";
import { setFormData } from "../../../../../../helpers/formData";
import DinamicFieldListIn from "../../../../../../components/Form/DinamicFieldListIn";
import Modal from "../../../../../../components/Modal";
import { StateDto } from "../../../../../../types/states.dto";
import { toast } from "react-toastify";
import invitationActions from "../../../../../../store/actions/invitation";
import { OrganizationDto } from "../../../../../../types/organization.dto";
interface ComponentProps {
  newCohortStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  mentors: UserDto[];
  abilities: AbilityDto[];
  events: EventDto[];
  current: CurrentDto;
  cohorts: CohortDto[];
  user: UserDto;
  academies: AcademyDto[];
  users: UsersDto[];
  newOrganizationStates: StateDto;
  newOrganization: OrganizationDto | null;
}

const Component = ({
  abilities,
  mentors,
  events,
  newCohortStates,
  current,
  cohorts,
  user,
  academies,
  users,
  newOrganizationStates,
  newOrganization,
}: ComponentProps) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [filteredValues, setFilteredValues] = useState<any>();
  const [avatarPreview, setAvatarPreview] = useState<HTMLInputElement>();
  const [organizationUsers, setOrganizationUsers] = useState<any>([]);
  const dispatch = useDispatch();

  const onSubmit = ({ values, actions }) => {
    const formValues = {
      ...values,
      ...(!!avatarPreview &&
        !!avatarPreview?.files && {
          picture: avatarPreview?.files[0],
        }),
    };
    const { users, ...rest } = formValues;

    dispatch(organizationActions.create(setFormData({ ...rest }, ["picture"])));
  };

  const closeModal = () => {
    dispatch(hideModal());
  };

  useEffect(() => {
    if (!!users) {
      const filteredValues = users
        .filter((user) => user.active && (user.username || user.email))
        .map((user, index) => {
          const objUser = {
            userName: {
              ...(!!user.username && {
                label: user.username,
                value: {
                  id: user._id,
                  value: user.username,
                  field: "username",
                },
              }),
            },
            email: {
              ...(!!user.email && {
                label: user.email,
                value: { id: user._id, value: user.email, field: "email" },
              }),
            },
          };

          return Object.values(objUser).filter(
            (obj) => Object.values(obj).length !== 0
          );
        })
        .flat(1);
      setFilteredValues(filteredValues);
    }
  }, [users]);

  useEffect(() => {
    !abilities && dispatch(getAllAbilities());
    !mentors && dispatch(getAllMentors());
  }, []);

  useEffect(() => {
    if (newOrganizationStates.success) {
      toast.success("Se ha creado la organizacion");
      setTimeout(() => {
        dispatch(hideModal());
        dispatch(organizationActions.resetCreate());
      }, 2000);
      if (!!users && !!newOrganization) {
        for (let i = 0; i < organizationUsers.length; i++) {
          const userFounded = users.find(
            (userF) => userF._id === organizationUsers[i].id
          );
          dispatch(
            invitationActions.create({
              email: userFounded?.email,
              organization: newOrganization._id,
              role: "MEMBER",
            })
          );
        }
      }
    }
    if (newOrganizationStates.error) {
      toast.error(newOrganizationStates.error);
      setTimeout(() => {
        dispatch(organizationActions.resetCreate());
      }, 2000);
    }
  }, [newOrganizationStates, dispatch]);

  return (
    <Modal name="new-organization-modal" title="Crear Organizacion">
      <Formik
        initialValues={{ ...initialValues }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="edit-cohort-form"
            >
              <Input
                name="name"
                error={errors["name"]}
                touched={touched["name"]}
                value={values["name"]}
                type="text"
                placeholder="Ingrese nombre de Organizacion"
                onChange={handleChange}
                onBlur={handleBlur}
                options={{
                  skin: "gray",
                  label: "Nombre de la Organizacion",
                  marginBottom: 20,
                }}
              />
              <FileUploader
                name="picture"
                error={errors["picture"]}
                touched={touched["picture"]}
                placeholder={
                  <Icon icon={UploadIcon} size="24px" color="black" />
                }
                onChange={(event) => {
                  setAvatarPreview(event.target);
                  handleChange(event);
                }}
                onBlur={handleBlur}
                accept="image/jpg;image/png;image/jpeg;capture=camera"
                options={{
                  label: "Foto de la Organizacion",
                  marginBottom: 24,
                }}
              />

              <ReactSelect
                name="private"
                error={errors["private"]}
                touched={touched["private"]}
                items={[
                  { label: "Publico", value: false },
                  { label: "Privado", value: true },
                ]}
                placeholder="Seleccione un tipo"
                onChange={handleChange}
                onBlur={handleBlur}
                options={{
                  skin: "gray",
                  label: "Acceso a la Organizacion",
                  marginBottom: 20,
                }}
              />
              <Input
                name="description"
                error={errors["description"]}
                touched={touched["description"]}
                value={values["description"]}
                type="text"
                placeholder="Escriba una descripcion"
                onChange={handleChange}
                onBlur={handleBlur}
                options={{
                  skin: "gray",
                  label: "Rubro de la Organizacion",
                  marginBottom: 20,
                }}
              />
              <Input
                name="email"
                error={errors["email"]}
                touched={touched["email"]}
                value={values["email"]}
                type="text"
                placeholder="Escriba un email"
                onChange={handleChange}
                onBlur={handleBlur}
                options={{
                  skin: "gray",
                  label: "Email de la Organizacion",
                  marginBottom: 20,
                }}
              />
              <Input
                name="phone"
                error={errors["phone"]}
                touched={touched["phone"]}
                value={values["phone"]}
                type="text"
                placeholder="Escriba un telefono"
                onChange={handleChange}
                onBlur={handleBlur}
                options={{
                  skin: "gray",
                  label: "Telefono de Contacto",
                  marginBottom: 20,
                }}
              />

              <DinamicFieldListIn
                name="users"
                error={errors["users"]}
                touched={touched["users"]}
                value={values["users"]}
                onChange={handleChange}
                valueFieldName="id"
                onBlur={handleBlur}
                pickFunction={(array, valuePicked, fieldName) => {
                  const firstResult = array.filter(
                    (item) => valuePicked[fieldName] === item.value[fieldName]
                  );

                  if (firstResult[0].value["value"] !== valuePicked["value"]) {
                    return firstResult[1].value;
                  } else {
                    return firstResult[0].value;
                  }
                }}
                options={{
                  skin: "gray",
                  type: "select",
                  height: "95px",
                  setFieldValue: setFieldValue,
                  ...(!!filteredValues && {
                    data: filteredValues.map((user) => {
                      return { value: user.value, label: user.label };
                    }),
                  }),
                  externalData: true,
                  loading: !users,
                  inputLabel:
                    "Invita personas a que se suscriban a tu Organizacion!!!",
                  inputPlaceholder: "Selecciona un usuario",
                }}
              />

              <Center>
                <Submit
                  isSubmmiting={newCohortStates.loading}
                  form="edit-cohort-form"
                  color="Primary"
                >
                  Confirmar
                </Submit>
                <Button
                  onClick={closeModal}
                  type="button"
                  options={{
                    type: "outline",
                    skin: "danger",
                    size: "lg",
                    marginBottom: "0px",
                  }}
                  style={{ marginLeft: "10px" }}
                >
                  Cancelar
                </Button>
              </Center>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const states = ({
  abilityStore,
  eventStore,
  cohortStore,
  mentorStore,
  currentStore,
  userStore,
  academyStore,
  usersStore,
  organizationStore,
}) => {
  const { data: mentors } = mentorStore.allMentors;
  const { data: abilities } = abilityStore.allAbilities;
  const { data: academies } = academyStore.all;
  const { data: events } = eventStore.allEvents;
  const { states: newCohortStates } = cohortStore.newCohort;
  const { states: newOrganizationStates } = organizationStore.create;
  const { data: newOrganization } = organizationStore.create;
  const { data: current } = currentStore;
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: user } = userStore;
  const { data: users } = usersStore.all;
  return {
    abilities,
    events,
    mentors,
    newCohortStates,
    current,
    cohorts,
    user,
    academies,
    users,
    newOrganizationStates,
    newOrganization,
  };
};

export default connect(states)(Component);
