import styled from "styled-components";

export const Button = styled("button")`
  display: flex;
  justify-content: center;
  width: 180px;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 6.9px 14px 7.1px;
  height: min-content;
  border-radius: 10px;
  background-color: #1573e5;
  color: #fff;
  margin-left: 55px;
`;
