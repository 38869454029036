import {
  NewTalent,
  NEW_TALENT,
  ResetNewTalent,
  RESET_NEW_TALENT,
  GET_ALL_TALENTS,
  GetAllTalents,
  TALENT_ADD_CLICK,
  TALENT_ADD_CONTACT,
  TALENT_ADD_VIEW,
} from "../types/talent";

export const newTalent = (payload): NewTalent => {
  return { type: NEW_TALENT, payload };
};
export const resetNewTalent = (): ResetNewTalent => {
  return { type: RESET_NEW_TALENT, payload: null };
};

export const getTalents = (): GetAllTalents => {
  return { type: GET_ALL_TALENTS, payload: null };
};

export const makeClick = (payload) => {
  return { type: TALENT_ADD_CLICK, payload };
};

export const addContact = (payload) => {
  return { type: TALENT_ADD_CONTACT, payload };
};

export const addView = (payload) => {
  return { type: TALENT_ADD_VIEW, payload };
};
