import { useState } from "react";
import { connect, useDispatch } from "react-redux";
import Modal from "../../../../../../components/Modal";
import { Formik } from "formik";
import { Center } from "../../../../../../components/StyledComponents";
import Grid from "../../../../../../components/Grid";
import Submit from "../../../../../../components/Form/Submit";
import * as Yup from "yup";

import { CurrentDto } from "../../../../../../types/current.dto";
import { CohortDto } from "../../../../../../types/cohort.dto";
import ReactSelect from "../../../../../../components/Form/ReactSelect";
import { hideModal } from "../../../../../../store/actions/modal";

export const schema = Yup.object().shape({
  cohort: Yup.string().required(),
});

const Component = ({
  cohorts,
  current,
  buildFilters,
}: {
  cohorts: CohortDto[];
  current: CurrentDto;
  buildFilters;
}) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const dispatch = useDispatch();
  const onSubmit = ({ values, actions }) => {
    buildFilters({
      field: "cohorts",
      value: values.cohort,
      special: (academy, value) => {
        return academy.cohorts.includes(value);
      },
    });
    dispatch(hideModal());
  };

  return (
    <Modal name="filter-cohort-modal" title={`Filtrar por Cohort`}>
      <Formik
        initialValues={{ cohort: "" }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="invite-academy-modal-form"
            >
              <Grid.Row style={{ marginBottom: "20px" }}>
                <Grid.Col>
                  <ReactSelect
                    name="cohort"
                    error={errors["cohort"]}
                    touched={touched["cohort"]}
                    items={
                      !!cohorts &&
                      cohorts
                        .filter(
                          (cohort) =>
                            cohort.active && cohort.academy._id === current.id
                        )
                        .map((cohort) => ({
                          label: cohort.name,
                          value: cohort._id,
                        }))
                    }
                    placeholder="Seleccionar Cohort*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      loading: !cohorts,
                      label: "Cohort",
                      marginBottom: 24,
                    }}
                  />
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col>
                  <Center gap={"10px"}>
                    <Submit
                      isSubmmiting={isSubmitting}
                      form="invite-academy-modal-form"
                      color="Primary"
                      style={{ width: "170px" }}
                      options={{
                        type: "filled",
                        skin: "primary",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                    >
                      Confirmar
                    </Submit>
                  </Center>
                </Grid.Col>
              </Grid.Row>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const states = ({ academyStore, cohortStore, currentStore, unitStore }) => {
  const { data: academies } = academyStore.all;
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: current } = currentStore;
  return { cohorts, current };
};

export default connect(states)(Component);
