import {
  initialState,
  Actions,
  State,
  GET_ALL_MEETS,
  GET_ALL_MEETS_ERROR,
  GET_ALL_MEETS_SUCCESS,
} from "../types/meet";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_MEETS:
      return {
        ...state,
        allMeets: {
          data: null,
          states: {
            loading: false,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_MEETS_SUCCESS:
      return {
        ...state,
        allMeets: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_MEETS_ERROR:
      return {
        ...state,
        allMeets: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    default:
      return state;
  }
};

export default reducers;
