import React, { useEffect, useState } from "react";
import { CardColumn, CardRow, CardTitle } from "../../../Profile/style";
import { BiNews } from "react-icons/bi";
import Icon from "../../../../../../components/Icon";
import { DataText, Item } from "../../style";
import Button from "../../../../../../components/Button";
import { connect } from "react-redux";
import { InvitationDto } from "../../../../../../types/invitation.dto";
import { AcademyDto } from "../../../../../../types/academy.dto";
import { StudentDto } from "../../../../../../types/student.dto";
import { UsersDto } from "../../../../../../types/users.dto";
import { useDispatch } from "react-redux";
import invitationActions from "../../../../../../store/actions/invitation";
import { toast } from "react-toastify";
import { CurrentDto } from "../../../../../../types/current.dto";
import AcademyInviteButton from "../../components/InviteButton";
import OrganizationInviteButton from "../../../../Organization/Gestion/components/InviteButton";
import Loader from "../../../../../../components/Loader";
import { resetGetAllRequests } from "../../../../../../store/actions/request";
import usersActions from "../../../../../../store/actions/users";

const Component = ({
  selectedAcademy,
  invitations,
  users,
  deleteStates,
  current,
  selectedItem,
  deletedInvitation,
}: {
  invitations: InvitationDto[];
  selectedAcademy: AcademyDto;
  users: UsersDto[];
  deleteStates;
  current: CurrentDto;
  deletedInvitation: InvitationDto;
  selectedItem: {
    label: string;
    value: string;
    data: {
      description: string;
    };
  };
}) => {
  const dispatch = useDispatch();
  const [fetchOn, setFetchOn] = useState<boolean>(false);
  const [currentInvitations, setCurrentInvitations] =
    useState<InvitationDto[]>();
  const payloadInvitations = {
    filterBy: {
      active: true,
      status: { $in: ["PENDING", "AWAITING SIGNUP"] },
      [current.currentSection]: current.id,
    },
  };
  useEffect(() => {
    if (deleteStates.success) {
      toast.success("Se elimino correctamente");
      setCurrentInvitations((state) => [
        ...(state ?? []).filter((st) => st._id !== deletedInvitation._id),
      ]);
      setTimeout(() => {
        dispatch(invitationActions.resetDeleteOne());
      }, 1000);
    }
    if (deleteStates.error) {
      toast.error(deleteStates.error);
      setTimeout(() => {
        dispatch(invitationActions.resetDeleteOne());
      }, 1000);
    }
  }, [deleteStates]);

  useEffect(() => {
    if (!invitations && fetchOn)
      dispatch(invitationActions.getAll(payloadInvitations));
  }, [invitations, fetchOn, dispatch]);

  useEffect(() => {
    if (invitations && fetchOn) {
      setCurrentInvitations(invitations);
    }
  }, [invitations]);

  useEffect(() => {
    return () => {
      dispatch(invitationActions.resetGetAll());
      dispatch(resetGetAllRequests());
      /* dispatch(usersActions()); */
    };
  }, []);
  useEffect(() => {
    if (!invitations) {
      setFetchOn(true);
    }
  }, [invitations]);
  if (!currentInvitations) return <Loader color="Primary"></Loader>;
  return (
    <>
      <CardRow justify="space-between">
        <CardRow>
          <Icon icon="" size="40px" />
          <CardColumn gap="9px">
            <DataText>{selectedItem.label}</DataText>
            {current.currentSection !== "personal" && (
              <DataText>{selectedItem.data.description}</DataText>
            )}
          </CardColumn>
        </CardRow>

        {current.currentSection === "organization" && (
          <OrganizationInviteButton></OrganizationInviteButton>
        )}
        {current.currentSection === "academy" && (
          <AcademyInviteButton></AcademyInviteButton>
        )}
      </CardRow>
      <CardColumn style={{ width: "100%" }} gap="21px">
        <CardColumn>
          <CardRow align="center" gap="8px">
            <BiNews fontSize={20}></BiNews>
            <CardTitle>Listado de Invitaciones</CardTitle>
          </CardRow>
          <CardColumn>
            {!!currentInvitations &&
              currentInvitations
                .filter(
                  (invitation) =>
                    (invitation.status === "PENDING" ||
                      invitation.status === "AWAITING SIGNUP") &&
                    invitation[current.currentSection]._id === current.id
                )
                .map((invitation) => {
                  return (
                    <Item>
                      <CardRow
                        style={{ width: "100%" }}
                        align="center"
                        justify="space-between"
                      >
                        <CardRow align="center" gap="3px">
                          <CardColumn>
                            <DataText size="14px">{invitation.email}</DataText>
                          </CardColumn>
                        </CardRow>
                        <CardRow gap="15px">
                          <Button
                            type="button"
                            options={{
                              type: "outline",
                              size: "sm",
                              skin: "gray",
                            }}
                            style={{
                              minWidth: "77px",
                              width: "140px",
                              fontSize: "12px",
                              fontWeight: "200",
                            }}
                            onClick={() =>
                              dispatch(
                                invitationActions.deleteOne({
                                  _id: invitation._id,
                                })
                              )
                            }
                          >
                            Eliminar Invitacion
                          </Button>
                        </CardRow>
                      </CardRow>
                    </Item>
                  );
                })}
          </CardColumn>
        </CardColumn>
      </CardColumn>
    </>
  );
};

const states = ({
  cohortStore,
  academyStore,
  invitationStore,
  usersStore,
  currentStore,
}) => {
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: selectedAcademy } = academyStore.selected;
  const { data: invitations } = invitationStore.all;
  const { states: deleteStates, data: deletedInvitation } =
    invitationStore.delete;
  const { data: users } = usersStore.all;
  const { data: current } = currentStore;
  return {
    cohorts,
    selectedAcademy,
    deleteStates,
    invitations,
    current,
    users,
    deletedInvitation,
  };
};
export default connect(states)(Component);
