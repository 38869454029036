import * as Yup from "yup";

export const schema = Yup.object().shape({
  unitPrice: Yup.number()
    .required("Transaction amount is required")
    .min(1, "Valor mayor a 0"),
});

interface FormValues {
  unitPrice: number;
}

export const initialValues: FormValues = {
  unitPrice: 0,
};
