import Modal from "../../../../../../components/Modal";
import EditCohortForm from "../../../Edit";

const Component = ({ cohort, tags }) => {
  return (
    <Modal name="edit-cohort-modal" title="Editar Co-Hort">
      <EditCohortForm tags={tags} cohort={cohort} />
    </Modal>
  );
};

export default Component;
