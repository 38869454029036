import styled from "styled-components";

export const List = styled("ul")`
  min-height: 357px;
  padding: 0px;
  margin: 10px 0px 0px;
`;

export const ListItem = styled("li")`
  list-style: none;
  padding: 0px;
  margin: 0px;
  display: block;
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const Button = styled("button")<{ active: boolean }>`
  border: 0px;
  margin: 0px;
  display: block;
  width: 100%;
  border-radius: 5px;
  box-shadow: 1px 1px 3px 0 rgba(112, 112, 112, 0.16);
  transition: all 0.3s ease-in-out;
  background-color: ${({ active }) => (active ? "#daf1ff" : "white")};
  cursor: pointer;
  height: 40px;
  text-align: left;
`;

export const SubNavLink = styled("div")`
  text-decoration: none;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 30px;
  padding: 7px 10px;
  position: relative;
  border-radius: 16px;
  transition: all 0.25s ease-in-out;
  color: ${(props) => props.theme.colors.Gray};
  background-color: white;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &.no-styles {
    border: 0;
    margin: 0;
    background: transparent;

    &:before,
    &:after {
      display: none;
    }
  }

  &.active {
    font-weight: 500;
    background-color: ${(props) => props.theme.colors.LightBlue};
    color: ${(props) => props.theme.colors.Primary};
  }

  &:not(.active):hover {
    background-color: ${(props) => props.theme.colors.LightGray};
    color: ${(props) => props.theme.colors.DarkGray};
  }
`;

export const ButtonLink = styled("div")`
  text-decoration: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  width: 100%;
  padding: 0px 0px 0px 10px;
  position: relative;
  border-radius: 0px 0px 0px 0px;
  transition: all 0.25s ease-in-out;
  color: ${(props) => props.theme.colors.Gray};
  font-size: 13px;
  box-shadow: 0 0 3px 0 rgba(112, 112, 112, 0.16);
  margin-bottom: 10px;

  &.no-styles {
    border: 0;
    margin: 0;
    background: transparent;

    &:before,
    &:after {
      display: none;
    }
  }

  &.active {
    font-weight: 500;
    background-color: ${(props) => props.theme.colors.LightBlue};
    color: ${(props) => props.theme.colors.Primary};

    i {
      &:before {
        background-color: ${(props) => props.theme.colors.Primary};
      }
    }

    .caret {
      transform: rotate(0deg);
    }
  }

  &:not(.active):hover {
    background-color: ${(props) => props.theme.colors.LightGray};
    color: ${(props) => props.theme.colors.DarkGray};

    i {
      &:before {
        background-color: ${(props) => props.theme.colors.DarkGray};
      }
    }
  }
`;

export const DropdownMenu = styled("div")<{ amountItems; isActiveDropdown }>`
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: hidden;
  transition: height 0.3s ease-in-out, max-height 0.3s ease-in-out;
  //Dropdown
  opacity: ${({ isActiveDropdown }) => (isActiveDropdown ? 1 : 0)};
  height: ${({ amountItems }) => amountItems * 34 + 60}px;
  max-height: ${({ isActiveDropdown, amountItems }) =>
    isActiveDropdown ? amountItems * 34 + 60 : 0}px;
`;
