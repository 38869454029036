import { put, call, all, takeLatest } from "redux-saga/effects";
import services from "../../services/";
import {
  GET_ABILITY,
  GET_ABILITY_SUCCESS,
  GET_ABILITY_ERROR,
  GET_ALL_ABILITIES,
  GET_ALL_ABILITIES_SUCCESS,
  GET_ALL_ABILITIES_ERROR,
  NEW_ABILITY,
  NEW_ABILITY_SUCCESS,
  NEW_ABILITY_ERROR,
  NewAbility,
  UPDATE_ABILITY,
  UPDATE_ABILITY_SUCCESS,
  UPDATE_ABILITY_ERROR,
  DELETE_ABILITY,
  DELETE_ABILITY_SUCCESS,
  DELETE_ABILITY_ERROR,
  GetAbility,
  GetAllAbilities,
  UpdateAbility,
  DeleteAbility,
} from "../types/ability";

const abilities = ({ abilityStore }) => abilityStore.allAbilities;

function* getAllAbilities({ payload }) {
  try {
    const { response, error } = yield call(services.ability.getAll, payload);

    if (error) {
      yield put({
        type: GET_ALL_ABILITIES_ERROR,
        payload: error.response.data?.message || "Error",
      });
    } else {
      yield put({ type: GET_ALL_ABILITIES_SUCCESS, payload: response });
    }
  } catch {
    yield put({
      type: NEW_ABILITY_ERROR,
      payload: "Error desconocido al listar Habilidades",
    });
  }
}

function* getAbility({ payload }) {
  try {
    const { response, error } = yield call(services.ability.getOne, payload);

    if (error) {
      yield put({
        type: GET_ABILITY_ERROR,
        payload: error.response.data?.message || "Error",
      });
    } else {
      yield put({ type: GET_ABILITY_SUCCESS, payload: response });
    }
  } catch {
    yield put({
      type: NEW_ABILITY_ERROR,
      payload: "Error desconocido al buscar Habilidad",
    });
  }
}

function* newAbility({ payload }) {
  try {
    const { response, error } = yield call(services.ability.new, payload);

    if (error) {
      yield put({
        type: NEW_ABILITY_ERROR,
        payload: error.response.data?.message || "Error",
      });
    } else {
      yield all([put({ type: NEW_ABILITY_SUCCESS, payload: response })]);
    }
  } catch {
    yield put({
      type: NEW_ABILITY_ERROR,
      payload: "Error desconocido al crear Habilidad",
    });
  }
}

function* updateAbility({ payload }) {
  try {
    const { response, error } = yield call(services.ability.update, payload);

    if (error) {
      yield put({
        type: UPDATE_ABILITY_ERROR,
        payload: error.response.data?.message || "Error",
      });
    } else {
      yield all([put({ type: UPDATE_ABILITY_SUCCESS, payload: response })]);
    }
  } catch {
    yield put({
      type: NEW_ABILITY_ERROR,
      payload: "Error desconocido al actualizar Habilidad",
    });
  }
}

function* deleteAbility({ payload }) {
  const { response, error } = yield call(services.ability.delete, payload);

  if (error) {
    yield put({
      type: DELETE_ABILITY_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: DELETE_ABILITY_SUCCESS, payload: response })]);
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllAbilities>(GET_ALL_ABILITIES, getAllAbilities);
  yield takeLatest<GetAbility>(GET_ABILITY, getAbility);
  yield takeLatest<NewAbility>(NEW_ABILITY, newAbility);
  yield takeLatest<UpdateAbility>(UPDATE_ABILITY, updateAbility);
  yield takeLatest<DeleteAbility>(DELETE_ABILITY, deleteAbility);
}
