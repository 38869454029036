import styled from "styled-components";
import { GridStructure as GridCommon } from "../../../../components/Layout/Dashboard/styles";
export const CardWrapper = styled("div")`
  position: relative;
  border-radius: 10px;
  box-shadow: 0 0 6px 0 rgba(79, 38, 162, 0.16);
  width: 100%;
  padding: 20px 50px;
  background-color: #fff;
`;

export const BannerTitle = styled("h3")`
  font-size: ${(props) => props.size};
  font-weight: 500;
  color: ${(props) => props.theme.colors.Night};
  font-family: Lato;
`;

export const GridStructure = styled(GridCommon)`
  margin: 0;
`;

export const GridGrayWrapper = styled("div")`
  padding: 20.1px 65.8px;
  background-color: #f5f5f5;
  border-radius: 15px;
  height: 384px;
  overflow-y: auto;
`;

export const MiniGridCard = styled("div")`
  border-radius: 40px;
  position: relative;
  padding: 3px;
`;

export const Img = styled("img")`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const Icon = styled("div")`
  display: block;
  width: 42px;
  height: 42px;
  border-radius: 15px;
  overflow: hidden;
  ${(props) => !!props.margin && `margin:${props.margin};`}
`;

export const Link = styled("")`
  text-decoration: none;
  display: block;
`;

export const AddIcon = styled("div")`
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 2px;
`;

export const BannerCohortPhoto = styled("div")`
  border-radius: 15px;
  width: 60px;
  height: 60px;
  overflow: hidden;
  box-shadow: 0 0 3px 0 rgba(79, 38, 162, 0.16);
`;

export const AddModule = styled("div")`
  padding: 11px 0;
  text-align: center;
  border-radius: 10px;
  border: solid 2px #1573e5;
  background-color: #fff;
  color: #1573e5;
  cursor: pointer;
  border-radius: 10px;
`;

export const CohortBoldFont = styled("h3")`
  font-size: ${(props) => (!!props.size ? props.size : "16px")};
  font-weight: bold;
  color: #2a2747;
`;

export const CohortLabelFont = styled("h3")`
  font-size: 12px;
  font-weight: 300;
  color: #2a2747;
`;

export const CohortMediumFont = styled("h3")`
  font-size: 14px;
  font-weight: normal;
  color: #2a2747;
`;
