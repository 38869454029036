import { put, call, all, takeLatest, select } from "redux-saga/effects";
import services from "../../services";
import {
  GET_GENERALCONTENT,
  GET_GENERALCONTENT_SUCCESS,
  GET_GENERALCONTENT_ERROR,
  GET_ALL_GENERALCONTENTS,
  GET_ALL_GENERALCONTENTS_SUCCESS,
  GET_ALL_GENERALCONTENTS_ERROR,
  NEW_GENERALCONTENT,
  NEW_GENERALCONTENT_ERROR,
  NEW_GENERALCONTENT_SUCCESS,
  NewGeneralContent,
  UPDATE_GENERALCONTENT,
  UPDATE_GENERALCONTENT_SUCCESS,
  UPDATE_GENERALCONTENT_ERROR,
  DELETE_GENERALCONTENT,
  DELETE_GENERALCONTENT_SUCCESS,
  DELETE_GENERALCONTENT_ERROR,
  GetGeneralContent,
  GetAllGeneralContents,
  UpdateGeneralContent,
  DeleteGeneralContent,
  UploadMedia,
  UPLOAD_MEDIA,
  AddUserToGeneralContent,
  ADD_USER_TO_GENERALCONTENT,
  SharedGeneralContent,
  SHARED_GENERALCONTENT,
  SavedGeneralContent,
  SAVED_GENERALCONTENT,
  UNSAVED_GENERALCONTENT,
  UnSavedGeneralContent,
  QUERY_GENERALCONTENT_SUCCESS,
  QUERY_GENERALCONTENT_ERROR,
  QUERY_GENERALCONTENT,
  AddClickGeneralContent,
  ADD_CLICK_GENERALCONTENT,
  UPDATE_CURRENT_GENERALCONTENT_LIST,
  AddViewGeneralContent,
  ADD_VIEW_GENERALCONTENT,
  UPDATE_ONE_GENERALCONTENT_ERROR,
  UPDATE_ONE_GENERALCONTENT_SUCCESS,
  SET_GET_GENERALCONTENT,
  ManageLikeDislikeGeneralContent,
  MANAGE_LIKE_DISLIKE_GENERALCONTENT,
  GET_GENERALCONTENTS_OFFSET,
  GET_GENERALCONTENTS_OFFSET_ERROR,
  GET_GENERALCONTENTS_OFFSET_SUCCESS,
  GetGeneralContentsOffset,
  UPDATE_ITEM_LIST_GENERALCONTENT,
  UPDATE_MANY_GENERAL_CONTENT_SUCCESS,
  UPDATE_MANY_GENERAL_CONTENT_ERROR,
  UpdateManyGeneralContent,
  UPDATE_MANY_GENERAL_CONTENT,
} from "../types/generalContent";
import { GeneralContentDto } from "../../types/generalContent.dto";

const generalContentList = ({ generalContentStore }) =>
  generalContentStore.all.data;

const generalContentsOffset = ({ generalContentStore }) =>
  generalContentStore.offSet.data;

function* getAllGeneralContents({ payload }) {
  const { response, error } = yield call(
    services.generalContent.getAll,
    payload
  );

  if (error) {
    yield put({
      type: GET_ALL_GENERALCONTENTS_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_GENERALCONTENTS_SUCCESS, payload: response });
  }
}

function* getGeneralContent({ payload }) {
  const { response, error } = yield call(
    services.generalContent.getOne,
    payload
  );

  if (error) {
    yield put({
      type: GET_GENERALCONTENT_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_GENERALCONTENT_SUCCESS, payload: response });
  }
}

function* addClick({ payload }) {
  const { response, error } = yield call(
    services.generalContent.addClick,
    payload
  );
  /*  if (error) {
  } else {
    const currentListOfGeneralContents = yield select(companyList);
    const newListofGeneralContents = currentListOfGeneralContents?.map((content) => {
      if (content._id === response._id) {
        return response;
      }
      return content;
    });
    yield put({
      type: UPDATE_CURRENT_GENERALCONTENT_LIST,
      payload: newListofGeneralContents,
    });
  } */
}

function* newGeneralContent({ payload }) {
  const { response, error } = yield call(services.generalContent.new, payload);

  if (error) {
    yield put({
      type: NEW_GENERALCONTENT_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([put({ type: NEW_GENERALCONTENT_SUCCESS, payload: response })]);
  }
}

function* updateGeneralContent({ payload }) {
  const { response, error } = yield call(
    services.generalContent.update,
    payload
  );
  if (error) {
    yield put({
      type: UPDATE_GENERALCONTENT_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([
      put({ type: UPDATE_GENERALCONTENT_SUCCESS, payload: response }),
    ]);
  }
}

/* function* saveGeneralContent({ payload }) {
  const { response, error } = yield call(services.listen.saveListen, payload);

  if (error) {
    yield put({
      type: UPDATE_GENERALCONTENT_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: UPDATE_GENERALCONTENT_SUCCESS, payload: response });
    if (!!payload.individual) {
      yield put({ type: SET_GET_GENERALCONTENT, payload: response });
    } else {
      yield put({ type: GET_ALL_GENERALCONTENTS, payload: null });
    }
  }
}
 */
function* updateManyGeneralContent({ payload }) {
  let updatedList: GeneralContentDto[] = [];

  try {
    for (let i = 0; i < payload.items.length; i++) {
      const { _id, ...body } = payload.items[i];
      const formDataInsteadBody = body.body ? body.body : body;
      const { response, error } = yield call(services.generalContent.update, {
        _id,
        body: formDataInsteadBody,
      });
      if (error) {
        throw new Error(error.response.data.message);
      }
      if (response) {
        updatedList = [...updatedList, response];
      }
    }

    yield put({
      type: UPDATE_MANY_GENERAL_CONTENT_SUCCESS,
      payload: updatedList,
    });
  } catch (err) {
    yield put({ type: UPDATE_MANY_GENERAL_CONTENT_ERROR, payload: err });
  }
}

function* addUserToGeneralContent({ payload }) {
  const { response, error } = yield call(
    services.generalContent.addUserGeneralContent,
    payload
  );

  if (error) {
    yield put({
      type: UPDATE_GENERALCONTENT_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([
      put({ type: UPDATE_GENERALCONTENT_SUCCESS, payload: response }),
    ]);
  }
}

function* queryGeneralContent({ payload }) {
  const { response, error } = yield call(
    services.generalContent.query,
    payload
  );

  if (error) {
    yield put({
      type: QUERY_GENERALCONTENT_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: QUERY_GENERALCONTENT_SUCCESS, payload: response });
  }
}

function* uploadMedia({ payload }) {
  const { response, error } = yield call(
    services.generalContent.update,
    payload
  );

  if (error) {
    yield put({
      type: UPDATE_GENERALCONTENT_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([
      put({ type: UPDATE_GENERALCONTENT_SUCCESS, payload: response }),
    ]);
  }
}

function* deleteGeneralContent({ payload }) {
  const { response, error } = yield call(
    services.generalContent.delete,
    payload
  );

  if (error) {
    yield put({
      type: DELETE_GENERALCONTENT_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([
      put({ type: DELETE_GENERALCONTENT_SUCCESS, payload: response }),
    ]);
  }
}

function* manageContent({ payload }) {
  const { response, error } = yield call(
    services.generalContent.manageContent,
    payload
  );

  if (error) {
    yield put({
      type: UPDATE_GENERALCONTENT_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: UPDATE_GENERALCONTENT_SUCCESS, payload: response });
    if (!!payload.individual) {
      yield put({ type: SET_GET_GENERALCONTENT, payload: response });
    }
  }
}

function* shareGeneralContent({ payload }) {
  const { response, error } = yield call(
    services.generalContent.shareGeneralContent,
    payload
  );

  if (error) {
    yield put({
      type: UPDATE_GENERALCONTENT_ERROR,
      payload: error,
    });
  } else {
    yield all([
      put({ type: UPDATE_GENERALCONTENT_SUCCESS, payload: response }),
    ]);
  }
}

function* addView({ payload }) {
  const { response, error } = yield call(
    services.generalContent.addView,
    payload
  );

  /*  if (error) {
    yield put({
      type: UPDATE_GENERALCONTENT_ERROR,
      payload: error,
    });
  } else {
    yield all([put({ type: UPDATE_GENERALCONTENT_SUCCESS, payload: response })]);
  } */
}

function* manageLike({ payload }) {
  const { response, error } = yield call(
    services.generalContent.manageLike,
    payload
  );
  if (error) {
    yield put({
      type: UPDATE_ONE_GENERALCONTENT_ERROR,
      payload: error,
    });
  } else {
    yield all([
      put({ type: UPDATE_ONE_GENERALCONTENT_SUCCESS, payload: response }),
      put({ type: SET_GET_GENERALCONTENT, payload: response }),
    ]);
  }
}
function* manageDislike({ payload }) {
  const { response, error } = yield call(
    services.generalContent.manageDislike,
    payload
  );
  if (error) {
    yield put({
      type: UPDATE_ONE_GENERALCONTENT_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([
      put({ type: UPDATE_ONE_GENERALCONTENT_SUCCESS, payload: response }),
      put({ type: SET_GET_GENERALCONTENT, payload: response }),
    ]);
  }
}
function* manageLikeDislike({ payload }) {
  const { response, error } = yield call(
    services.generalContent.manageLikeDislike,
    payload
  );
  if (error) {
    yield put({
      type: UPDATE_GENERALCONTENT_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: UPDATE_GENERALCONTENT_SUCCESS, payload: response });
    if (payload.individual) {
      yield put({ type: SET_GET_GENERALCONTENT, payload: response });
    }
  }
}

function* getGeneralContentsOffset({ payload }) {
  const { response, error } = yield call(
    services.generalContent.getOffset,
    payload
  );
  if (error) {
    yield put({
      type: GET_GENERALCONTENTS_OFFSET_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    const offsetGeneralContents = yield select(generalContentsOffset);
    const newOffsetGeneralContents = !offsetGeneralContents
      ? response
      : [...offsetGeneralContents, ...response];
    yield put({
      type: GET_GENERALCONTENTS_OFFSET_SUCCESS,
      payload: newOffsetGeneralContents,
    });
  }
}

function* updateGeneralContentListItem({ payload }) {
  const { response, error } = yield call(
    services.generalContent.update,
    payload
  );

  if (error) {
    yield put({
      type: UPDATE_GENERALCONTENT_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield all([
      put({ type: UPDATE_GENERALCONTENT_SUCCESS, payload: response }),
      put({ type: SET_GET_GENERALCONTENT, payload: response }),
    ]);
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllGeneralContents>(
    GET_ALL_GENERALCONTENTS,
    getAllGeneralContents
  );
  yield takeLatest<GetGeneralContent>(GET_GENERALCONTENT, getGeneralContent);
  yield takeLatest<NewGeneralContent>(NEW_GENERALCONTENT, newGeneralContent);
  yield takeLatest<UpdateGeneralContent>(
    UPDATE_GENERALCONTENT,
    updateGeneralContent
  );
  yield takeLatest<DeleteGeneralContent>(
    DELETE_GENERALCONTENT,
    deleteGeneralContent
  );
  yield takeLatest<UploadMedia>(UPLOAD_MEDIA, uploadMedia);
  yield takeLatest<AddUserToGeneralContent>(
    ADD_USER_TO_GENERALCONTENT,
    addUserToGeneralContent
  );
  yield takeLatest<SharedGeneralContent>(
    SHARED_GENERALCONTENT,
    shareGeneralContent
  );
  yield takeLatest<SavedGeneralContent>(SAVED_GENERALCONTENT, manageContent);
  yield takeLatest<UnSavedGeneralContent>(
    UNSAVED_GENERALCONTENT,
    manageContent
  );
  yield takeLatest<UnSavedGeneralContent>(
    QUERY_GENERALCONTENT,
    queryGeneralContent
  );
  yield takeLatest<AddClickGeneralContent>(ADD_CLICK_GENERALCONTENT, addClick);
  yield takeLatest<AddViewGeneralContent>(ADD_VIEW_GENERALCONTENT, addView);
  yield takeLatest<ManageLikeDislikeGeneralContent>(
    MANAGE_LIKE_DISLIKE_GENERALCONTENT,
    manageLikeDislike
  );
  yield takeLatest<GetGeneralContentsOffset>(
    GET_GENERALCONTENTS_OFFSET,
    getGeneralContentsOffset
  );
  yield takeLatest<any>(
    UPDATE_ITEM_LIST_GENERALCONTENT,
    updateGeneralContentListItem
  );
  yield takeLatest<UpdateManyGeneralContent>(
    UPDATE_MANY_GENERAL_CONTENT,
    updateManyGeneralContent
  );
}
