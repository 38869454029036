import { useEffect, useMemo, useState } from "react";
import {
  RecruitingLayout,
  useRecruitingLayout,
} from "../../../../provider/RecruitingProvider";
import Tabs from "../../../../components/Tabs";
import Icon from "../../../../components/Icon";
import { InLine } from "../../../../components/StyledComponents";
import {
  Header,
  SectionIcon,
  Title,
  PrimarySubTitle,
  SecondSubtitle,
  Actions,
  Action,
  Tab,
  TabTitle,
  Description,
  Content,
  ListTitle,
  List,
  ListItem,
} from "../components/StyledComponents";
import { useDispatch } from "react-redux";
import recruiting from "../../../../store/actions/recruiting/";
import { showModal } from "../../../../store/actions/modal";
import ModalCreateTechnology from "./components/ModalCreateTechnology";
import ModalEditTechnology from "./components/ModalEditTechnology";
import TalentSearchIcon from "../../../../assets/icons/talent-search.svg";
import ShowModal from "../components/ShoModal";
import { TechnologyDto } from "../../../../types/recruiting.dto";
import { connect } from "react-redux";
import TabProfile from "./components/TabProfile";
import TabSkill from "./components/TabSkill";
import { useParams } from "react-router-dom";

interface ComponentProps {
  allTechnologies: TechnologyDto[];
  allTechnologiesStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  selected;
}

const Component = ({
  allTechnologies,
  allTechnologiesStates,
  selected,
}: ComponentProps) => {
  const { setRecruitingLayout, selectedItem } = useRecruitingLayout();
  const dispatch = useDispatch();
  const params = useParams();
  const EditarPerfil = () => (
    <Action onClick={() => dispatch(showModal("edit-techbology-modal"))}>
      <Icon icon={TalentSearchIcon} size="16px" color="black" />
      Editar Perfil
    </Action>
  );

  const getAllItems = () => {
    dispatch(recruiting.technology.getAll());
  };

  const recruitingData = useMemo(
    (): RecruitingLayout => ({
      title: "Mis Tecnologías",
      icon: "",
      model: "technology",
      fieldName: "abilitesIds",
      externalIds: [],
      newProfileModal: "new-technology-modal",
      actions: recruiting.technology,
      list: allTechnologies
        ?.filter((technology) => technology.active)
        .filter((technology) => technology.team === params.id)
        .map((technology) => ({
          label: technology.name,
          value: technology._id,
          data: technology,
        })),
    }),
    [allTechnologies, selected, params]
  );

  useEffect(() => {
    setRecruitingLayout(recruitingData);
    getAllItems();
  }, [selected, params]);

  useEffect(() => {
    setRecruitingLayout(recruitingData);
  }, [allTechnologies, selected, params]);

  return (
    <>
      <Header>
        <InLine
          align="flex-start"
          justify="flex-start"
          style={{ marginBottom: "14px" }}
        >
          <SectionIcon>
            <Icon icon={TalentSearchIcon} size="16px" color="white" />
          </SectionIcon>

          <span>
            <Title>{selectedItem?.label}</Title>
          </span>
        </InLine>

        <InLine justify="flex-start">
          <EditarPerfil />
          <ShowModal.AsignarSkill></ShowModal.AsignarSkill>
        </InLine>
      </Header>

      <Tabs>
        <Tab id="perfil" label="Perfil">
          <TabProfile>
            <EditarPerfil></EditarPerfil>
          </TabProfile>
        </Tab>
        <Tab id="skills" label="Skills">
          <TabSkill></TabSkill>
        </Tab>
      </Tabs>

      <ModalCreateTechnology />
      <ModalEditTechnology />
    </>
  );
};

const states = ({ recruitingStore, teamStore }) => {
  const { data: allTechnologies, states: allTechnologiesStates } =
    recruitingStore.technology.all;
  const { data: selected } = teamStore.selected;
  return { allTechnologies, allTechnologiesStates, selected };
};

export default connect(states)(Component);
