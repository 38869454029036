import React from "react";
import { ProgressBarText, ProgressBarWrapper } from "./style";

const Component = ({ loaded }) => {
  return (
    <ProgressBarWrapper width={loaded}>
      <ProgressBarText>{`Procesando: ${loaded}%`}</ProgressBarText>
    </ProgressBarWrapper>
  );
};

export default Component;
