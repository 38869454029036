import Grid from "../../../../components/Grid";
import { Logo } from "../../../../components/Header/styles";
import {
  Card,
  CardColumn,
  CardRow,
  CardTitle,
  DataText,
  TextTitle,
} from "./style";
import EditButton from "../Profile/components/EditButton/index";
import AcademyEditProfileModal from "./components/EditProfileModal";
import AcademyEditContactModal from "./components/EditContactModal";
import AcademyEditVisibilityModal from "./components/EditVisibilityModal";
import OrganizationEditProfileModal from "../../Organization/Profile/components/EditProfileModal";
import OrganizationEditContactModal from "../../Organization/Profile/components/EditContactModal";
import OrganizationEditVisibilityModal from "../../Organization/Profile/components/EditVisibilityModal";
import {
  updateCohort,
  resetUpdateCohort,
  getAllCohorts,
  resetGetAllCohortsData,
} from "../../../../store/actions/cohort";

import {
  AiOutlineTeam,
  AiFillLinkedin,
  AiOutlineMail,
  AiFillGithub,
} from "react-icons/ai";
import { HiAcademicCap } from "react-icons/hi";
import { BsEyeFill, BsPersonCircle } from "react-icons/bs";
import { FiSmartphone } from "react-icons/fi";
import { GoLocation } from "react-icons/go";
import { TbWorld } from "react-icons/tb";
import Button from "../../../../components/Button";
import { connect, useDispatch } from "react-redux";
import { CohortDto } from "../../../../types/cohort.dto";
import { useEffect, useState } from "react";
import { AcademyDto } from "../../../../types/academy.dto";
import Loader from "../../../../components/Loader";
import { UsersDto } from "../../../../types/users.dto";
import { toast } from "react-toastify";
import { CurrentDto } from "../../../../types/current.dto";
import { OrganizationDto } from "../../../../types/organization.dto";
import { TeamDto } from "../../../../types/team.dto";
import { setCurrent } from "../../../../store/actions/current";
import { UserDto } from "../../../../types/user.dto";
import { useNavigate } from "react-router-dom";
import { LinkText } from "../../User/Profile/style";
import Entity from "../../../../assets/images/team-icon-24.png";
import academyActions from "../../../../store/actions/academies";
import usersActions from "../../../../store/actions/users";

interface ComponentProps {
  cohorts: CohortDto[];
  academies: AcademyDto[];
  selectedAcademy: AcademyDto;
  users: UsersDto[];
  deleteStates;
  organizations: OrganizationDto[];
  current: CurrentDto;
  teams: TeamDto[];
  cohortUpdateStates;
  organizationUpdateStates;
  user: UserDto;
  updatedCohort;
  updateAcademyStates;
  updatedAcademy;
}

const Component = ({
  cohorts,
  academies,
  selectedAcademy,
  teams,
  users,
  organizations,
  current,
  deleteStates,
  cohortUpdateStates,
  organizationUpdateStates,
  user,
  updatedCohort,
  updateAcademyStates,
  updatedAcademy,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const [fetchOn, setFetchOn] = useState<boolean>(false);
  const [currentCohorts, setCurrentCohorts] = useState<CohortDto[]>();
  const [currentAcademies, setCurrentAcademies] = useState<AcademyDto[]>();
  const [entity, setEntity] = useState<AcademyDto>();
  useEffect(() => {
    if (cohortUpdateStates.success) {
      toast.success(`Se ha modificado correctamente`);
      setCurrentCohorts((state) =>
        state?.map((st) => (st._id === updatedCohort._id ? updatedCohort : st))
      );
      setTimeout(() => {
        dispatch(resetUpdateCohort());
      }, 2000);
    }
    if (cohortUpdateStates.error) {
      toast.error(deleteStates.error);
      setTimeout(() => {
        dispatch(resetUpdateCohort());
      }, 2000);
    }
  }, [cohortUpdateStates]);

  useEffect(() => {
    if (updateAcademyStates.success) {
      toast("👌🏼 Academia modificada", {
        containerId: "submit",
        type: "success",
        toastId: "submitsucceful",
      });
      setCurrentAcademies((state) =>
        state?.map((st) =>
          st._id === updatedAcademy._id ? updatedAcademy : st
        )
      );
      dispatch(academyActions.resetUpdate());
    }

    if (updateAcademyStates.error) {
      toast.error(`😱 ${updateAcademyStates.error}`);

      setTimeout(() => {
        dispatch(academyActions.resetUpdate());
      }, 1000);
    }
  }, [updateAcademyStates]);
  useEffect(() => {
    if (!cohorts && fetchOn)
      dispatch(
        getAllCohorts({ filterBy: { academy: current.id, active: true } })
      );
  }, [cohorts, fetchOn]);

  useEffect(() => {
    if (cohorts && fetchOn) {
      setCurrentCohorts(cohorts);
    }
  }, [cohorts]);

  useEffect(() => {
    if (!academies && fetchOn)
      dispatch(
        academyActions.getAll({ filterBy: { active: true, _id: current.id } })
      );
  }, [academies, fetchOn]);

  useEffect(() => {
    if (academies && fetchOn) {
      setCurrentAcademies(academies);
    }
  }, [academies]);

  useEffect(() => {
    if (currentAcademies) {
      const founded = currentAcademies.find(
        (academy) => academy._id === current.id
      );
      if (founded) {
        setEntity(founded);
      }
    }
  }, [currentAcademies]);

  useEffect(() => {
    if (!users && fetchOn)
      dispatch(
        usersActions.getAllByAcademy({
          _id: current.id,
          payload: { filterBy: {} },
        })
      );
  }, [users, fetchOn]);

  useEffect(() => {
    if (!users && !academies && !cohorts) {
      setFetchOn(true);
    }
  }, [users, academies, cohorts]);

  useEffect(() => {
    return () => {
      dispatch(usersActions.resetGetAll());
      dispatch(academyActions.resetGetAllAcademiesData());
      dispatch(resetGetAllCohortsData());
    };
  }, []);

  if (!currentCohorts || !entity) return <Loader color="Primary"></Loader>;

  return (
    <>
      <Grid.Row>
        <Grid.Col sm={4}>
          <Grid.Row>
            <Grid.Col style={{ marginBottom: "20px" }}>
              <Card>
                <CardRow align="center" gap="5px">
                  <CardColumn>
                    <div
                      style={{
                        height: "50px",
                        width: "50px",
                        overflow: "hidden",
                        objectFit: "cover",
                        borderRadius: "50%",
                      }}
                    >
                      <Logo
                        src={entity?.picture || Entity}
                        alt={entity?.description}
                      />
                    </div>
                  </CardColumn>
                  <CardColumn gap="5px">
                    <CardColumn>
                      <CardTitle>{entity?.name}</CardTitle>
                    </CardColumn>
                    <CardColumn>
                      <DataText>
                        {entity?.description ? entity?.description : ""}
                      </DataText>
                    </CardColumn>
                  </CardColumn>
                </CardRow>
                <EditButton
                  modalName={`edit-${current.currentSection}-profile`}
                />
              </Card>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col>
              <Card>
                <CardColumn gap="20px">
                  <CardRow align="center" gap="10.6px">
                    <BsPersonCircle fontSize={32}></BsPersonCircle>
                    <CardTitle>Datos de Contacto</CardTitle>
                  </CardRow>
                  <CardRow>
                    <CardColumn gap="16px">
                      <CardRow gap="8px" align="center">
                        <AiOutlineMail fontSize={20}></AiOutlineMail>
                        <DataText>{entity?.email}</DataText>
                      </CardRow>
                      <CardRow gap="8px" align="center">
                        <FiSmartphone fontSize={20}></FiSmartphone>
                        <DataText>{entity?.phone}</DataText>
                      </CardRow>
                      <CardRow gap="8px" align="center">
                        <GoLocation fontSize={20}></GoLocation>
                        <DataText>{entity?.location}</DataText>
                      </CardRow>
                      <CardRow gap="8px" align="center">
                        <AiFillLinkedin fontSize={20}></AiFillLinkedin>

                        <LinkText href={entity?.linkedin} target="_blank">
                          {entity?.linkedin}
                        </LinkText>
                      </CardRow>
                      <CardRow gap="8px" align="center">
                        <AiFillGithub fontSize={20}></AiFillGithub>
                        <LinkText href={entity?.github} target="_blank">
                          {entity?.github}
                        </LinkText>
                      </CardRow>
                      <CardRow gap="8px" align="center">
                        <TbWorld fontSize={20}></TbWorld>
                        <LinkText href={entity?.website} target="_blank">
                          {entity?.website}
                        </LinkText>
                      </CardRow>
                    </CardColumn>
                  </CardRow>
                </CardColumn>
                <EditButton
                  modalName={`edit-${current.currentSection}-data`}
                ></EditButton>
              </Card>
            </Grid.Col>
          </Grid.Row>
        </Grid.Col>
        <Grid.Col sm={current.currentSection === "academy" ? 6 : 8}>
          <Card>
            <CardColumn gap="21px">
              <CardColumn gap="10px">
                <CardRow align="center" gap="8px">
                  <AiOutlineTeam fontSize={20}></AiOutlineTeam>
                  <CardTitle>
                    {current.currentSection === "academy"
                      ? "Co-horts"
                      : "Equipos"}
                  </CardTitle>
                </CardRow>
                <CardColumn gap="5px">
                  {!!currentCohorts &&
                    (current.currentSection === "academy"
                      ? currentCohorts
                          .filter((cohort) => cohort.academy._id === current.id)
                          .filter((cohort) => cohort.active)
                      : teams
                          .filter((team) => team.organization === current.id)
                          .filter((team) => team.active)
                    ).map((entity) => (
                      <TextTitle>
                        <CardRow align="center" justify="space-between">
                          <CardRow align="center" gap="3px">
                            <CardColumn>
                              <DataText size="10px">{entity?.name}</DataText>
                            </CardColumn>
                          </CardRow>
                          <CardRow gap="15px">
                            {current.currentSection === "academy" && (
                              <>
                                <Button
                                  type="button"
                                  options={{
                                    type: "outline",
                                    size: "sm",
                                    skin: "gray",
                                  }}
                                  style={{
                                    minWidth: "30px",
                                    width: "90px",
                                    fontSize: "12px",
                                    fontWeight: "200",
                                  }}
                                  onClick={() =>
                                    dispatch(
                                      updateCohort({
                                        _id: entity._id,
                                        hidden: true,
                                      })
                                    )
                                  }
                                >
                                  Deshabilitar
                                </Button>
                                <Button
                                  type="button"
                                  options={{
                                    type: "outline",
                                    size: "sm",
                                    skin: "primary",
                                  }}
                                  style={{
                                    minWidth: "30px",
                                    width: "80px",
                                    fontSize: "12px",
                                    fontWeight: "200",
                                  }}
                                  onClick={() =>
                                    dispatch(
                                      updateCohort({
                                        _id: entity._id,
                                        hidden: false,
                                      })
                                    )
                                  }
                                >
                                  Publicar
                                </Button>
                              </>
                            )}
                          </CardRow>
                        </CardRow>
                      </TextTitle>
                    ))}
                </CardColumn>
              </CardColumn>
              <CardColumn gap="10px">
                <CardRow align="center" gap="8px">
                  <HiAcademicCap fontSize={20}></HiAcademicCap>
                  <CardTitle>Miembros</CardTitle>
                </CardRow>
                {users && currentAcademies ? (
                  <CardColumn
                    style={{
                      maxHeight: "300px",
                      overflow: "auto",
                      padding: "2px",
                    }}
                    gap="5px"
                  >
                    {!!users &&
                      entity?.users
                        ?.filter((userF) => user.active && !userF.roleEnd)
                        .map((user) => {
                          const userFounded = users.find(
                            (userFounded) => userFounded._id === user.user
                          );
                          return (
                            <TextTitle>{`${userFounded?.name} ${
                              !!userFounded?.lastName
                                ? userFounded.lastName
                                : ""
                            }`}</TextTitle>
                          );
                        })}
                  </CardColumn>
                ) : (
                  <Loader color="Primary"></Loader>
                )}
              </CardColumn>
            </CardColumn>
          </Card>
        </Grid.Col>
        {current.currentSection === "academy" && (
          <Grid.Col sm={2}>
            <Card>
              <CardColumn gap="20.5px">
                <CardRow align="center" gap="5px">
                  <BsEyeFill fontSize={20}></BsEyeFill>
                  <CardTitle>Visibilidad</CardTitle>
                </CardRow>
                <CardRow gap="6px">
                  <CardTitle>Perfil: </CardTitle>
                  <DataText>{entity?.private ? "Privado" : "Publico"}</DataText>
                </CardRow>
              </CardColumn>
              <EditButton
                modalName={`edit-${current.currentSection}-visibility`}
              ></EditButton>
            </Card>
          </Grid.Col>
        )}
      </Grid.Row>
      <AcademyEditProfileModal></AcademyEditProfileModal>
      <AcademyEditContactModal></AcademyEditContactModal>
      <AcademyEditVisibilityModal></AcademyEditVisibilityModal>
      <OrganizationEditProfileModal></OrganizationEditProfileModal>
      <OrganizationEditContactModal></OrganizationEditContactModal>
      <OrganizationEditVisibilityModal></OrganizationEditVisibilityModal>
    </>
  );
};

const states = ({
  cohortStore,
  academyStore,
  usersStore,
  currentStore,
  organizationStore,
  teamStore,
  userStore,
}) => {
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: academies } = academyStore.all;
  const { data: selectedAcademy } = academyStore.selected;
  const { data: users } = usersStore.all;
  const { states: deleteStates } = cohortStore.deleteCohort;
  const { states: cohortUpdateStates, data: updatedCohort } =
    cohortStore.updateCohort;
  const { data: current } = currentStore;
  const { data: organizations } = organizationStore.all;
  const { data: teams } = teamStore.all;
  const { states: organizationUpdateStates } = organizationStore.update;
  const { data: user } = userStore;
  const { states: updateAcademyStates, data: updatedAcademy } =
    academyStore.update;
  return {
    cohorts,
    user,
    organizations,
    teams,
    academies,
    organizationUpdateStates,
    selectedAcademy,
    current,
    cohortUpdateStates,
    users,
    deleteStates,
    updatedCohort,
    updateAcademyStates,
    updatedAcademy,
  };
};

export default connect(states)(Component);
