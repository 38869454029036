import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Field, Formik } from "formik";
import { initialValues, schema } from "../../constants/form/auth/sign-up";
import { AuthContext } from "../../provider/AuthProvider";
import Input from "../../components/Form/Input";
import Submit from "../../components/Form/Submit";
import { resetSignUp, signUp, signUpSocial } from "../../store/actions/auth";
import { UserDto } from "../../types/user.dto";
import { toast, ToastContainer } from "react-toastify";
import { setCurrent } from "../../store/actions/current";
import userActions from "../../store/actions/users";
import { useCurrentLayout } from "../../provider/CurrentProvider";
import {
  Center,
  FlexContainer,
  Separator,
} from "../../components/StyledComponents";
import { Img } from "../Dashboard/Cohort/Customize/style";
import Constana from "../../assets/images/constana.png";
import { AuthWrapper, Link, Title } from "../SignIn/styles";
import { InLine } from "../../components/Form/Field";
import { useGoogleLogin } from "@react-oauth/google";
import {
  LoginSocialFacebook,
  LoginSocialInstagram,
} from "reactjs-social-login";
import FacebookButton from "../../components/FacebookButton";
import SocialButton from "../../components/SocialButton";
import InstagramButton from "../../components/InstagramButton";
import { getGitHubUrl } from "../../helpers/githubUrl";
import { FaFacebookSquare, FaGithub, FaLinkedin } from "react-icons/fa";
import { getLinkedinUrl } from "../../helpers/linkedinUrl";
import { FcGoogle } from "react-icons/fc";
import {
  CautionBox,
  CautionText,
  ErrorSpan,
  Logo,
  OverlayButton,
  TermsLink,
} from "./style";
import caution from "../../assets/icons/caution.svg";
import { RiErrorWarningFill } from "react-icons/ri";
import { GridStructure } from "../../components/Layout/Dashboard/styles";
import ConstanaLogo from "../../assets/images/constanaacademy.svg";
import { useTheme } from "styled-components";
import Grid from "../../components/Grid";
import FacebookLogin from "@greatsumini/react-facebook-login";
interface CustomizedState {
  sigupStates: {
    loading: boolean;
    success: boolean;
    error: boolean;
  };
  from: {
    pathname: string;
    search: string;
    hash: string;
  };
}

interface ComponentProps {
  signupStates: {
    loading: boolean;
    success: boolean;
    error: boolean;
  };
  userStates: {
    loading: boolean;
    success: boolean;
    error: boolean;
  };
  user: UserDto;
  usersStore;
}

const Component = ({
  signupStates,
  user,
  userStates,
  usersStore,
}: ComponentProps) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const location = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();
  let from = !(location.state as CustomizedState)?.from.search
    ? (location.state as CustomizedState)?.from?.pathname || "/dashboard"
    : `${(location.state as CustomizedState)?.from?.pathname}/${
        (location.state as CustomizedState)?.from.search
      }`;
  const { setData } = useCurrentLayout();
  let navigate = useNavigate();
  const signUpGoogle = useGoogleLogin({
    onSuccess: (codeResponse) =>
      dispatch(
        signUpSocial({ code: codeResponse.access_token, social: "google" })
      ),
  });
  /* let location = useLocation(); */
  let { isAuthenticated } = React.useContext(AuthContext);
  /* let from =
    (location.state as CustomizedState)?.from?.pathname || "/dashboard"; */

  const onSubmit = ({ values, actions }) => {
    dispatch(signUp(values));
  };
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard", { replace: true });
    }
    return () => {};
  }, [isAuthenticated]);

  useEffect(() => {
    if (!!user) {
      navigate("/dashboard", { replace: true });
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (signupStates.error) {
      toast.error(`😱 No se ha podido crear usuario`);
      setTimeout(() => {
        dispatch(resetSignUp());
      }, 1000);
    }
    if (signupStates.success) {
      setData(userActions);
      toast.success("Confirme su cuenta para acceder a la plataforma");
      dispatch(
        setCurrent({
          id: user?._id,
          currentSection: "personal",
          role: "USER",
          store: usersStore,
        })
      );
      setTimeout(() => {
        dispatch(resetSignUp());
      }, 1000);
    }
  }, [signupStates]);
  useEffect(() => {
    if (!!searchParams.get("code")) {
      if ((location.search as string).split("%3D")[1] === "github") {
        dispatch(
          signUpSocial({ social: "github", code: searchParams.get("code") })
        );
      }
      if ((location.search as string).split("%3D")[1] === "linkedin") {
        dispatch(
          signUpSocial({ social: "linkedin", code: searchParams.get("code") })
        );
      }
    }
  }, [searchParams]);
  return (
    <AuthWrapper>
      <Center>
        <Logo>
          <div style={{ height: "26px" }}>
            <Img src={ConstanaLogo} />
          </div>
        </Logo>
      </Center>
      <Title style={{ marginBottom: "6px" }}>Registrarse</Title>
      <CautionText>
        Al continuar, confirmas que estás de acuerdo con los
        <TermsLink
          rel="noreferrer"
          href={`${process.env.REACT_APP_URL}/terms`}
          target="_blank"
        >
          {`Términos de uso y Política de privacidad`}
        </TermsLink>
        de DevPlace
      </CautionText>
      <Separator size={14}></Separator>

      <GridStructure>
        <SocialButton
          color={"#2a2747"}
          background={"#fff"}
          active={`${theme.colors.Primary}`}
          text={"Continuar con Google"}
          onClick={() => {
            signUpGoogle();
          }}
          icon={<FcGoogle size={20}></FcGoogle>}
        ></SocialButton>

        <FacebookLogin
          appId="1149804909391563"
          fields="name,email,picture,gender"
          scope="public_profile, email, user_gender"
          onSuccess={(response) => {
            dispatch(
              signUpSocial({
                social: "facebook",
                body: response.userID,
                code: response.accessToken,
              })
            );
          }}
          onFail={(error) => {
            toast.error(`Error al acceder`, {
              containerId: "container-sign-up",
            });
          }}
          /* onProfileSuccess={(response) => {
                console.log("Get Profile Success!", response);
              }} */
          render={({ onClick, logout }) => (
            <SocialButton
              color={"#2a2747"}
              background={"#fff"}
              active={`${theme.colors.Primary}`}
              text={"Continuar con Facebook"}
              onClick={onClick}
              icon={
                <FaFacebookSquare size={20} color="#0866ff"></FaFacebookSquare>
              }
            ></SocialButton>
          )}
        />

        <SocialButton
          color={"#2a2747"}
          background={"#fff"}
          active={`${theme.colors.Primary}`}
          text={"Continuar con Linkedin"}
          onClick={() => {
            window.location.href = getLinkedinUrl(from);
          }}
          icon={<FaLinkedin size={20} color={"#177FB1"}></FaLinkedin>}
        ></SocialButton>
      </GridStructure>
      <Separator size={30}></Separator>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          textAlign: "center",
        }}
      >
        <div
          style={{
            flexGrow: "1",
            borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
          }}
        ></div>
        <div
          style={{
            width: "49px",
            textAlign: "center",
            fontSize: "12px",
            fontWeight: "500",
          }}
        >
          ó
        </div>
        <div
          style={{
            flexGrow: "1",
            borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
          }}
        ></div>
      </div>
      <Separator size={14}></Separator>
      <Formik
        initialValues={{ ...initialValues }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="sign-up-form"
            >
              <Grid.Row>
                <Grid.Col>
                  <InLine column="1fr 1fr">
                    <Input
                      name="name"
                      error={errors["name"]}
                      touched={touched["name"]}
                      type="text"
                      fieldStyles={{
                        height: "29.5px",
                        borderRadius: "10px",
                        boxShadow: "none",
                      }}
                      inputStyles={{
                        padding: "7.9px 9px 7.9px 9px",
                        fontSize: "12px",
                        fontWeight: "500",
                      }}
                      placeholder="Nombre"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={signupStates.loading}
                      options={{
                        marginBottom: 14,
                        skin: "gray",
                      }}
                    />
                    <Input
                      name="lastName"
                      error={errors["lastName"]}
                      touched={touched["lastName"]}
                      type="text"
                      placeholder="Apellido"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={signupStates.loading}
                      fieldStyles={{
                        height: "29.5px",
                        borderRadius: "10px",
                        boxShadow: "none",
                      }}
                      inputStyles={{
                        padding: "7.9px 9px 7.9px 9px",
                        fontSize: "12px",
                        fontWeight: "500",
                      }}
                      options={{
                        marginBottom: 14,
                        skin: "gray",
                      }}
                    />
                  </InLine>
                </Grid.Col>
              </Grid.Row>

              <Input
                name="username"
                error={errors["username"]}
                touched={touched["username"]}
                type="text"
                placeholder="Nombre de usuario"
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={signupStates.loading}
                fieldStyles={{
                  height: "29.5px",
                  borderRadius: "10px",
                  boxShadow: "none",
                }}
                inputStyles={{
                  padding: "7.9px 9px 7.9px 9px",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
                options={{
                  marginBottom: 14,
                  skin: "gray",
                }}
              />
              <Input
                name="email"
                error={errors["email"]}
                touched={touched["email"]}
                type="text"
                placeholder="Email"
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={signupStates.loading}
                fieldStyles={{
                  height: "29.5px",
                  borderRadius: "10px",
                  boxShadow: "none",
                }}
                inputStyles={{
                  padding: "7.9px 9px 7.9px 9px",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
                options={{
                  marginBottom: 14,

                  skin: "gray",
                }}
              />

              <Input
                name="password"
                error={errors["password"]}
                touched={touched["password"]}
                type="password"
                placeholder="Contraseña"
                onChange={handleChange}
                onBlur={handleBlur}
                fieldStyles={{
                  height: "29.5px",
                  borderRadius: "10px",
                  boxShadow: "none",
                }}
                inputStyles={{
                  padding: "7.9px 9px 7.9px 9px",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
                disabled={signupStates.loading}
                options={{
                  marginBottom: 14,

                  skin: "gray",
                }}
              />

              <Input
                name="confirm-password"
                error={errors["confirm-password"]}
                touched={touched["confirm-password"]}
                type="password"
                placeholder="Confirmar Contraseña"
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={signupStates.loading}
                fieldStyles={{
                  height: "29.5px",
                  borderRadius: "10px",
                  boxShadow: "none",
                }}
                inputStyles={{
                  padding: "7.9px 9px 7.9px 9px",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
                options={{
                  marginBottom: 25,

                  skin: "gray",
                }}
              />

              <Submit
                isSubmmiting={signupStates.loading}
                form="sign-up-form"
                type="submit"
                color="Primary"
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  height: "29px",
                  borderRadius: "10px",
                }}
                options={{
                  block: true,
                  type: "filled",
                  skin: "primary",
                  loading: "Primary",
                  size: "sm",
                  marginBottom: "27px",
                }}
              >
                Registrarse
              </Submit>
              <Center>
                <Link
                  style={{
                    fontSize: "10px",
                    fontWeight: "600",
                  }}
                  onClick={() => navigate("/sign-in")}
                >
                  Ya tengo cuenta
                </Link>
              </Center>
            </form>
          );
        }}
      </Formik>
      <ToastContainer
        containerId="container-sign-up"
        enableMultiContainer
        limit={1}
      ></ToastContainer>
    </AuthWrapper>
  );
};

const state = ({ authStore, userStore, usersStore }) => {
  const { data: user, states: userStates } = userStore;
  const { states: signupStates } = authStore.signup;

  return {
    user,
    signupStates,
    userStates,
    usersStore,
  };
};

export default connect(state)(Component);
