import { SocialButton } from "./style";

const Component = ({ onClick, icon, text, ...rest }) => {
  return (
    <SocialButton type="button" onClick={onClick} {...rest}>
      {icon}
      <h3
        style={{
          width: "100%",
          fontSize: "12px",
        }}
      >
        {text}
      </h3>
    </SocialButton>
  );
};

export default Component;
