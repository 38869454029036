import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import Button from "../../../../../components/Button";
import Grid from "../../../../../components/Grid";
import Loader from "../../../../../components/Loader";
import {
  FlexContainer,
  PageTitle,
} from "../../../../../components/StyledComponents";
import { DateEStoDateEN } from "../../../../../helpers/format-date";
import { getOrganizationStats } from "../../../../../store/actions/analytics";
import AnalyticCard from "../../components/AnalyticCard";
import {
  formatNumber,
  overThousandFixed,
} from "../../../../../helpers/number-fixed";

const Component = ({
  analyticsOrganization,
  analyticsOrganizationStates,
  current,
}) => {
  const printRef = React.useRef();
  const dispatch = useDispatch();
  const handleDownloadPdf = async () => {
    if (!!printRef.current) {
      const element = printRef.current;
      const canvas = await html2canvas(element);
      const data = canvas.toDataURL("image/png");

      const pdf = new jsPDF("p", "px", [canvas.width, canvas.height]);
      const imgProperties = pdf.getImageProperties(data);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

      pdf.addImage(data, "PNG", 5, 10, pdfWidth - 10, pdfHeight + 5);
      pdf.save("print.pdf");
    }
  };
  useEffect(() => {
    dispatch(getOrganizationStats({ _id: current.id }));
  }, []);
  if (!analyticsOrganization) return <Loader color="Primary"></Loader>;

  return (
    <>
      <PageTitle>Dashboard</PageTitle>
      <Grid.Col offset={{ sm: 10 }} sm={12}>
        <Button
          onClick={() => {
            handleDownloadPdf();
          }}
          type="button"
          options={{
            type: "filled",
            skin: "primary",
            size: "md",
            marginBottom: "10px",
          }}
          style={{ marginLeft: "10px" }}
        >
          Descargar
        </Button>
      </Grid.Col>
      <FlexContainer ref={printRef} direction="column" gap="6px">
        <AnalyticCard
          title={"Interacciones dentro de la Organizacion"}
          graphTitle={
            "Interacciones dentro de la Organizacion - Crecimiento Histórico"
          }
          data={{
            stats: [
              {
                label: "Últimos 30 días",
                value: analyticsOrganization.organization.clicks.lastMonth,
              },
              {
                label: "Últimos 7 días",
                value: analyticsOrganization.organization.clicks.lastWeek,
              },
              {
                label: "Últimas 24 horas",
                value: analyticsOrganization.organization.clicks.lastDay,
              },
            ],
            graphData: Object.entries(
              analyticsOrganization.organization.clicks.growth
            )
              .sort((a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0]))
              .map((growthData) => {
                return {
                  name: growthData[0],
                  value: overThousandFixed(formatNumber(growthData[1])),
                };
              }),
          }}
        ></AnalyticCard>
        <AnalyticCard
          title={"Talentos agregados"}
          graphTitle={"Talentos agregados - Crecimiento Histórico "}
          data={{
            stats: [
              {
                label: "Últimos 30 días",
                value:
                  analyticsOrganization.aitTalents.saves.lastMonth.external,
              },
              {
                label: "Últimos 7 días",
                value: analyticsOrganization.aitTalents.saves.lastWeek.external,
              },
              {
                label: "Últimas 24 horas",
                value: analyticsOrganization.aitTalents.saves.lastDay.external,
              },
            ],
            graphData: Object.entries(
              analyticsOrganization.aitTalents.saves.growth.external
            )
              .sort((a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0]))
              .map((growthData) => {
                return {
                  name: growthData[0],
                  value: overThousandFixed(formatNumber(growthData[1])),
                };
              }),
          }}
        ></AnalyticCard>
        <AnalyticCard
          title={"Staff"}
          graphTitle={"Staff - Evolución Historica"}
          data={{
            stats: [
              {
                label: "Admins",
                value: analyticsOrganization.organization.users.admins.current,
              },
              {
                label: "PMs",
                value: analyticsOrganization.organization.users.pms.current,
              },
              {
                label: "Miembros",
                value: analyticsOrganization.organization.users.members.current,
              },
            ],
            graphData: Object.entries(
              analyticsOrganization.organization.users.history
            )
              .sort((a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0]))
              .map((growthData) => {
                return {
                  name: growthData[0],
                  value: overThousandFixed(formatNumber(growthData[1])),
                };
              }),
          }}
        ></AnalyticCard>
        <AnalyticCard
          title={"Empresas Agregadas"}
          graphTitle={"Empresas Agregadas - Crecimiento Histórico "}
          data={{
            stats: [
              {
                label: "Últimos 30 días",
                value: analyticsOrganization.companies.lastMonth,
              },
              {
                label: "Últimos 7 días",
                value: analyticsOrganization.companies.lastWeek,
              },
              {
                label: "Últimas 24 horas",
                value: analyticsOrganization.companies.lastDay,
              },
            ],
            graphData: Object.entries(analyticsOrganization.companies.growth)
              .sort((a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0]))
              .map((growthData) => {
                return {
                  name: growthData[0],
                  value: overThousandFixed(formatNumber(growthData[1])),
                };
              }),
          }}
        ></AnalyticCard>
        <AnalyticCard
          title={"Busquedas Agregadas"}
          graphTitle={"Busquedas Agregadas - Crecimiento Histórico "}
          data={{
            stats: [
              {
                label: "Últimos 30 días",
                value: analyticsOrganization.searches.lastMonth,
              },
              {
                label: "Últimos 7 días",
                value: analyticsOrganization.searches.lastMonth,
              },
              {
                label: "Últimas 24 horas",
                value: analyticsOrganization.searches.lastMonth,
              },
            ],
            graphData: Object.entries(analyticsOrganization.searches.growth)
              .sort((a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0]))
              .map((growthData) => {
                return {
                  name: growthData[0],
                  value: overThousandFixed(formatNumber(growthData[1])),
                };
              }),
          }}
        ></AnalyticCard>
        <AnalyticCard
          title={"Tecnologías Agregadas"}
          graphTitle={"Tecnologias Agregadas - Crecimiento Histórico  "}
          data={{
            stats: [
              {
                label: "Últimos 30 días",
                value: analyticsOrganization.extAbilities.lastMonth,
              },
              {
                label: "Últimos 7 días",
                value: analyticsOrganization.extAbilities.lastWeek,
              },
              {
                label: "Últimas 24 horas",
                value: analyticsOrganization.extAbilities.lastDay,
              },
            ],
            graphData: Object.entries(analyticsOrganization.extAbilities.growth)
              .sort((a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0]))
              .map((growthData) => {
                return {
                  name: growthData[0],
                  value: overThousandFixed(formatNumber(growthData[1])),
                };
              }),
          }}
        ></AnalyticCard>
        <AnalyticCard
          title={"Skills técnicos agregados"}
          graphTitle={"Skills técnicos agregados - Crecimiento Histórico "}
          data={{
            stats: [
              {
                label: "Últimos 30 días",
                value: analyticsOrganization.extCriterions.lastMonth,
              },
              {
                label: "Últimos 7 días",
                value: analyticsOrganization.extCriterions.lastWeek,
              },
              {
                label: "Últimas 24 horas",
                value: analyticsOrganization.extCriterions.lastDay,
              },
            ],
            graphData: Object.entries(
              analyticsOrganization.extCriterions.growth
            )
              .sort((a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0]))
              .map((growthData) => {
                return {
                  name: growthData[0],
                  value: overThousandFixed(formatNumber(growthData[1])),
                };
              }),
          }}
        ></AnalyticCard>
      </FlexContainer>
    </>
  );
};

const states = ({ analyticsStore, currentStore }) => {
  const { data: analyticsOrganization, states: analyticsOrganizationStates } =
    analyticsStore.getOrganizationStats;
  const { data: current } = currentStore;
  return {
    analyticsOrganization,
    analyticsOrganizationStates,
    current,
  };
};

export default connect(states)(Component);
