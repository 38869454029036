import { put, call, all, takeLatest, select } from "redux-saga/effects";
import services from "../../services";
import {
  GET_VIDEO,
  GET_VIDEO_SUCCESS,
  GET_VIDEO_ERROR,
  GET_ALL_VIDEOS,
  GET_ALL_VIDEOS_SUCCESS,
  GET_ALL_VIDEOS_ERROR,
  NEW_VIDEO,
  NEW_VIDEO_ERROR,
  NEW_VIDEO_SUCCESS,
  NewVideo,
  UPDATE_VIDEO,
  UPDATE_VIDEO_SUCCESS,
  UPDATE_VIDEO_ERROR,
  DELETE_VIDEO,
  DELETE_VIDEO_SUCCESS,
  DELETE_VIDEO_ERROR,
  GetVideo,
  GetAllVideos,
  UpdateVideo,
  DeleteVideo,
  UploadMedia,
  UPLOAD_MEDIA,
  AddUserToVideo,
  ADD_USER_TO_VIDEO,
  SharedVideo,
  SHARED_VIDEO,
  UPDATE_MANY_VIDEO,
  UPDATE_MANY_VIDEO_ERROR,
  UPDATE_MANY_VIDEO_SUCCESS,
  UpdateManyVideo,
} from "../types/video";
import { VideoDto } from "../../types/video.dto";

const videoList = ({ videoStore }) => videoStore.all.data;

function* updateManyVideo({ payload }) {
  let updatedList: VideoDto[] = [];

  try {
    for (let i = 0; i < payload.items.length; i++) {
      const { _id, ...body } = payload.items[i];
      const formDataInsteadBody = !!body.body ? body.body : body;
      const { response, error } = yield call(services.video.update, {
        _id,
        body: formDataInsteadBody,
      });
      if (!!response) {
        updatedList = [...updatedList, response];
      }
    }

    yield put({ type: UPDATE_MANY_VIDEO_SUCCESS, payload: updatedList });
  } catch (err) {
    yield put({ type: UPDATE_MANY_VIDEO_ERROR, payload: err });
  }
}

function* getAllVideos({ payload }) {
  const { response, error } = yield call(services.video.getAll, payload);

  if (error) {
    yield put({
      type: GET_ALL_VIDEOS_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_VIDEOS_SUCCESS, payload: response });
  }
}

function* getVideo({ payload }) {
  const { response, error } = yield call(services.video.getOne, payload);

  if (error) {
    yield put({
      type: GET_VIDEO_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_VIDEO_SUCCESS, payload: response });
  }
}

function* newVideo({ payload }) {
  const { response, error } = yield call(services.video.new, payload);

  if (error) {
    yield put({
      type: NEW_VIDEO_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: NEW_VIDEO_SUCCESS, payload: response })]);
  }
}

function* updateVideo({ payload }) {
  const { response, error } = yield call(services.video.update, payload);
  if (error) {
    yield put({
      type: UPDATE_VIDEO_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_VIDEO_SUCCESS, payload: response })]);
  }
}
function* addUserToVideo({ payload }) {
  const { response, error } = yield call(services.video.addUserVideo, payload);

  if (error) {
    yield put({
      type: UPDATE_VIDEO_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_VIDEO_SUCCESS, payload: response })]);
  }
}

function* uploadMedia({ payload }) {
  const { response, error } = yield call(services.video.update, payload);

  if (error) {
    yield put({
      type: UPDATE_VIDEO_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_VIDEO_SUCCESS, payload: response })]);
  }
}

function* deleteVideo({ payload }) {
  const { response, error } = yield call(services.video.delete, payload);

  if (error) {
    yield put({
      type: DELETE_VIDEO_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: DELETE_VIDEO_SUCCESS, payload: response })]);
  }
}

function* shareVideo({ payload }) {
  const { response, error } = yield call(services.video.shareVideo, payload);

  if (error) {
    yield put({
      type: UPDATE_VIDEO_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_VIDEO_SUCCESS, payload: response })]);
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllVideos>(GET_ALL_VIDEOS, getAllVideos);
  yield takeLatest<GetVideo>(GET_VIDEO, getVideo);
  yield takeLatest<NewVideo>(NEW_VIDEO, newVideo);
  yield takeLatest<UpdateVideo>(UPDATE_VIDEO, updateVideo);
  yield takeLatest<DeleteVideo>(DELETE_VIDEO, deleteVideo);
  yield takeLatest<UploadMedia>(UPLOAD_MEDIA, uploadMedia);
  yield takeLatest<AddUserToVideo>(ADD_USER_TO_VIDEO, addUserToVideo);
  yield takeLatest<SharedVideo>(SHARED_VIDEO, shareVideo);
  yield takeLatest<UpdateManyVideo>(UPDATE_MANY_VIDEO, updateManyVideo);
}
