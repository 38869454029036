import { Formik } from "formik";
import { connect, useDispatch } from "react-redux";
import ReactSelect from "../../../../../../../components/Form/ReactSelect";
import Icon from "../../../../../../../components/Icon";
import { showModal } from "../../../../../../../store/actions/modal";
import searchIcon from "../../../../../../../assets/icons/search.svg";

interface ComponentProps {
  setFilter;
  items?;
  setAction;
  user;
}

const Component = ({ setFilter, items, setAction, user }: ComponentProps) => {
  const dispatch = useDispatch();
  return (
    <Formik
      initialValues={{}}
      onSubmit={(values, actions) => {}}
      enableReinitialize
    >
      {({ handleSubmit }) => {
        return (
          <form
            className="theme-form"
            onSubmit={(event) => {
              handleSubmit(event);
            }}
            id="userid-filter-form"
          >
            <ReactSelect
              name="entity"
              items={
                !!items &&
                items
                  .filter(
                    (item) =>
                      !item.users.find(
                        (userF) => userF.user === user._id && !userF.roleEnd
                      )
                  )
                  .map((item) => {
                    return {
                      label: `${item.name}`,
                      value: item._id,
                    };
                  })
              }
              isClearable
              placeholder="Busca por nombre de la entidad"
              onOptionSelected={(optionSelected) => {
                if (!!optionSelected) {
                  const itemFounded = items.find(
                    (item) => item._id === optionSelected.value
                  );
                  setAction({
                    data: {
                      entity: itemFounded.section,
                      platform: "CONSTANA",
                      entityData: itemFounded,
                      sentBy: user._id,
                    },
                    action: "create-request",
                  });
                  dispatch(showModal("confirm-delete-item-secuencial-modal"));
                }
              }}
              options={{
                marginBottom: 0,
                indicator: true,
                before: <Icon icon={searchIcon} size="16px" color="" />,
              }}
            />
          </form>
        );
      }}
    </Formik>
  );
};

const states = ({ userStore }) => {
  const { data: user } = userStore;
  return {
    user,
  };
};

export default connect(states)(Component);

/* <AsyncSelect
              name="entity"
              isClearable
              placeholder="Busca por nombre de la entidad"
              actionRequest={async (offset, limit, inputValue) => {
                try {
                  const response: any = await services.academy.getAll({
                    limit,
                    offset,
                    ...(inputValue.length > 0 && { keyword: inputValue }),
                  });
                  console.log(response, "municio");
                  return response.response.map((el) => {
                    return {
                      value: el._id,
                      label: el.name,
                    };
                  }); // Assuming the API response contains an array of options
                } catch (error) {
           
                  return [];
                }
              }}
              limitInit={10}
              onOptionSelected={(optionSelected) => {
                if (optionSelected) {
                  const itemFounded = items.find(
                    (item) => item._id === optionSelected.value
                  );
                  setAction({
                    data: {
                      entity: itemFounded.section,
                      platform: "CONSTANA",
                      entityData: itemFounded,
                      sentBy: user._id,
                    },
                    action: "create-request",
                  });
                  dispatch(showModal("confirm-delete-item-secuencial-modal"));
                }
              }}
              options={{
                marginBottom: 0,
                indicator: true,
                before: <Icon icon={searchIcon} size="16px" color="" />,
              }}
            /> */
