import { put, call, takeLatest } from "redux-saga/effects";
import services from "../../services";
import {
  NEW_TALENT,
  NEW_TALENT_ERROR,
  NEW_TALENT_SUCCESS,
  NewTalent,
  GET_ALL_TALENTS_ERROR,
  GET_ALL_TALENTS_SUCCESS,
  GetAllTalents,
  GET_ALL_TALENTS,
  TALENT_ADD_CLICK,
  TALENT_ADD_VIEW,
  TALENT_ADD_CONTACT,
} from "../types/talent";

function* newTalent({ payload }) {
  const { response, error } = yield call(services.talent.new, payload);

  if (error) {
    yield put({
      type: NEW_TALENT_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: NEW_TALENT_SUCCESS, payload: response });
  }
}

function* getAllTalents() {
  const { response, error } = yield call(services.talent.getAll);
  if (error) {
    yield put({
      type: GET_ALL_TALENTS_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_TALENTS_SUCCESS, payload: response });
  }
}
function* addView({ payload }) {
  const { response, error } = yield call(services.talent.addView, payload);
  if (error) {
  } else {
  }
}
function* addClick({ payload }) {
  const { response, error } = yield call(services.talent.addClick, payload);
  if (error) {
  } else {
  }
}
function* addContact({ payload }) {
  const { response, error } = yield call(services.talent.addContact, payload);
  if (error) {
  } else {
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<NewTalent>(NEW_TALENT, newTalent);
  yield takeLatest<GetAllTalents>(GET_ALL_TALENTS, getAllTalents);
  yield takeLatest<any>(TALENT_ADD_CLICK, addClick);
  yield takeLatest<any>(TALENT_ADD_VIEW, addView);
  yield takeLatest<any>(TALENT_ADD_CONTACT, addContact);
}
