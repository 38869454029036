import styled from "styled-components";
import { Card } from "../../pages/Dashboard/Marketplace/components/TalentCard/styles";

export const ChartContainer = styled("div")`
  margin-bottom: 20px;
`;

export const IndividualCard = styled(Card)`
  padding: 10px;
  max-width: none;
`;

export const GrowCard = styled(IndividualCard)`
  flex-direction: column;
  padding: 20px;
  height: 234px;
`;

export const SoloGraph = styled("div")`
  padding: 20px;
  height: 234px;
  margin: 0 0 0 9px;
`;
export const NoteCard = styled(GrowCard)`
  white-space: nowrap;
  overflow: hidden;
  height: ${(props) => props.height || "auto"};
`;

export const CriterionCard = styled(NoteCard)`
  gap: 30px;
  height: 100%;
`;

export const CriterionNoGap = styled(Card)`
  gap: 5px;
  height: 100%;
  flex-direction: column;
  justify-content: stretch;
`;

export const ListCriterions = styled(GrowCard)`
  justify-content: initial;
`;
/* export const ChartTitle = styled(SubTitle); */
