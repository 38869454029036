import { ThemeProvider } from "styled-components";
import { main } from "./skins/main";
import { GlobalStyles } from "./GlobalStyles";
import { DataTableGlobalStyles } from "./DataTableGlobalStyles";
import { CalendarGlobalStyles } from "./CalendarGlobalStyles";

const themes: any = {
  main,
};

const Component = ({ portal, children }: any) => {
  return (
    <ThemeProvider theme={themes[portal] || main}>
      <GlobalStyles />
      <DataTableGlobalStyles />
      <CalendarGlobalStyles />
      {children}
    </ThemeProvider>
  );
};

export default Component;
