import { createBrowserHistory } from "history";
import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "connected-react-router";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducers";
import rootSaga from "./sagas";

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const composeWithDevTools =
  ((window as any)["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] as typeof compose) ||
  compose;

const store = (preloadedState?: any) => {
  return {
    ...createStore(
      rootReducer(history),
      preloadedState,
      composeWithDevTools(
        applyMiddleware(routerMiddleware(history), sagaMiddleware)
      )
    ),
    runSaga: sagaMiddleware.run(rootSaga),
  };
};

export default store;
