import {
  initialState,
  Actions,
  State,
  GET_ALL_AIT_TALENTS,
  GET_ALL_AIT_TALENTS_ERROR,
  GET_ALL_AIT_TALENTS_SUCCESS,
  GET_AIT_TALENT,
  GET_AIT_TALENT_ERROR,
  GET_AIT_TALENT_SUCCESS,
  NEW_AIT_TALENT,
  NEW_AIT_TALENT_ERROR,
  NEW_AIT_TALENT_SUCCESS,
  RESET_NEW_AIT_TALENT,
  UPDATE_AIT_TALENT,
  UPDATE_AIT_TALENT_ERROR,
  UPDATE_AIT_TALENT_SUCCESS,
  RESET_UPDATE_AIT_TALENT,
  DELETE_AIT_TALENT,
  DELETE_AIT_TALENT_ERROR,
  DELETE_AIT_TALENT_SUCCESS,
  RESET_DELETE_AIT_TALENT,
  UPDATE_MANY_AIT_TALENT,
  UPDATE_MANY_AIT_TALENT_SUCCESS,
  UPDATE_MANY_AIT_TALENT_ERROR,
  RESET_UPDATE_MANY_AIT_TALENT,
  SET_NEW_AIT_TALENTS_DATA,
  SET_UPDATE_AIT_TALENTS_DATA,
  UPDATE_CURRENT_AIT_TALENTS_LIST,
} from "../../types/recruiting/ait-talent";

const updateReducerArray = (array, data) =>
  array.map((elem) => (elem._id === data._id ? data : elem));

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_AIT_TALENTS:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_AIT_TALENTS_SUCCESS:
      return {
        ...state,
        all: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_AIT_TALENTS_ERROR:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case GET_AIT_TALENT:
      return {
        ...state,
        selected: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_AIT_TALENT_SUCCESS:
      return {
        ...state,
        selected: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_AIT_TALENT_ERROR:
      return {
        ...state,
        selected: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case NEW_AIT_TALENT:
      return {
        ...state,
        create: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_AIT_TALENT_SUCCESS:
      return {
        ...state,
        create: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case NEW_AIT_TALENT_ERROR:
      return {
        ...state,
        create: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_AIT_TALENT:
      return {
        ...state,
        create: initialState.create,
      };
    case DELETE_AIT_TALENT:
      return {
        ...state,
        delete: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_AIT_TALENT_SUCCESS:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_AIT_TALENT_ERROR:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_AIT_TALENT:
      return {
        ...state,
        delete: initialState.delete,
      };
    case UPDATE_AIT_TALENT:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case SET_NEW_AIT_TALENTS_DATA:
      return {
        ...state,
        all: {
          ...state.all,
          data: !!state.all.data
            ? [...state.all.data, action.payload]
            : [action.payload],
        },
      };
    case UPDATE_CURRENT_AIT_TALENTS_LIST:
      return {
        ...state,
        all: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: false,
          },
        },
      };
    case SET_UPDATE_AIT_TALENTS_DATA:
      return {
        ...state,
        all: {
          ...state.all,
          data: !!state.all.data
            ? updateReducerArray(state.all.data, action.payload)
            : null,
        },
      };
    case UPDATE_AIT_TALENT_SUCCESS:
      return {
        ...state,
        update: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_AIT_TALENT_ERROR:
      return {
        ...state,
        update: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_AIT_TALENT:
      return {
        ...state,
        update: initialState.update,
      };
    case UPDATE_MANY_AIT_TALENT:
      return {
        ...state,
        updateMany: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_MANY_AIT_TALENT_SUCCESS:
      return {
        ...state,
        updateMany: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_MANY_AIT_TALENT_ERROR:
      return {
        ...state,
        updateMany: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_MANY_AIT_TALENT:
      return {
        ...state,
        updateMany: initialState.updateMany,
      };
    default:
      return state;
  }
};

export default reducers;
