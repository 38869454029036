import { put, call, takeLatest, select } from "redux-saga/effects";
import services from "../../../services";
import { GET_AIT } from "../../types/recruiting/ait";
import {
  NEW_AIT_TALENT,
  NEW_AIT_TALENT_ERROR,
  NEW_AIT_TALENT_SUCCESS,
  NewAitTalent,
  GET_ALL_AIT_TALENTS,
  GET_ALL_AIT_TALENTS_ERROR,
  GET_ALL_AIT_TALENTS_SUCCESS,
  GetAllAitTalents,
  UPDATE_AIT_TALENT,
  UPDATE_AIT_TALENT_ERROR,
  UPDATE_AIT_TALENT_SUCCESS,
  UpdateAitTalent,
  DELETE_AIT_TALENT,
  UpdateManyAitTalent,
  UPDATE_MANY_AIT_TALENT,
  SET_NEW_AIT_TALENTS_DATA,
  SET_UPDATE_AIT_TALENTS_DATA,
  UPDATE_CURRENT_AIT_TALENTS_LIST,
  UPDATE_MANY_AIT_TALENT_SUCCESS,
  UPDATE_MANY_AIT_TALENT_ERROR,
  DELETE_AIT_TALENT_ERROR,
  DELETE_AIT_TALENT_SUCCESS,
} from "../../types/recruiting/ait-talent";

const aitTalentsList = ({ recruitingStore }) => recruitingStore.talent.all.data;

function* newAitTalent({ payload }) {
  const { response, error } = yield call(
    services.recruiting.talent.new,
    payload
  );

  if (error) {
    yield put({
      type: NEW_AIT_TALENT_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    const currentListOfTechsSkills = yield select(aitTalentsList);
    const newListofTechsSkills = [...currentListOfTechsSkills, response];
    yield put({
      type: UPDATE_CURRENT_AIT_TALENTS_LIST,
      payload: newListofTechsSkills,
    });
    yield put({ type: NEW_AIT_TALENT_SUCCESS, payload: response });
    yield put({ type: GET_AIT, payload: "asdas" });
  }
}

function* deleteAitTalent({ payload }) {
  const { response, error } = yield call(
    services.recruiting.talent.delete,
    payload
  );

  if (error) {
    yield put({
      type: DELETE_AIT_TALENT_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    const currentListOfTechsSkills = yield select(aitTalentsList);
    const newListofTechsSkills = currentListOfTechsSkills?.filter(
      (techSkill) => techSkill._id !== payload._id
    );
    yield put({
      type: UPDATE_CURRENT_AIT_TALENTS_LIST,
      payload: newListofTechsSkills,
    });
    yield put({ type: DELETE_AIT_TALENT_SUCCESS, payload: response });
    yield put({ type: GET_AIT, payload: "asdas" });
  }
}

function* getAllAitTalents() {
  const { response, error } = yield call(services.recruiting.talent.getAll);
  if (error) {
    yield put({
      type: GET_ALL_AIT_TALENTS_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_AIT_TALENTS_SUCCESS, payload: response });
  }
}

function* updatedAitTalent({ payload }) {
  const { response, error } = yield call(
    services.recruiting.talent.update,
    payload
  );

  if (error) {
    yield put({
      type: UPDATE_AIT_TALENT_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    const currentListOfTechsTalents = yield select(aitTalentsList);
    const newListofTechsTalents = currentListOfTechsTalents?.map(
      (aitTalent) => {
        if (aitTalent._id === payload._id) {
          return { ...aitTalent, ...response };
        }
        return aitTalent;
      }
    );
    yield put({
      type: UPDATE_CURRENT_AIT_TALENTS_LIST,
      payload: newListofTechsTalents,
    });
    yield put({ type: UPDATE_AIT_TALENT_SUCCESS, payload: null });
    yield put({ type: GET_AIT, payload: "asdas" });
  }
}

function* updateManyAitTalent({ payload }) {
  const { response, error } = yield call(
    services.recruiting.talent.updateMany,
    payload
  );
  if (error) {
    yield put({
      type: UPDATE_MANY_AIT_TALENT_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    const currentListOfTechsTalents = yield select(aitTalentsList);
    const newListOfAitTalents = currentListOfTechsTalents?.map((talent) => {
      const changedIds = response.map((el) => el._id);
      if (changedIds.includes(talent._id)) {
        return response.find((elem) => elem._id === talent._id);
      }
      return talent;
    });
    yield put({ type: UPDATE_MANY_AIT_TALENT_SUCCESS, payload: null });
    yield put({
      type: UPDATE_CURRENT_AIT_TALENTS_LIST,
      payload: newListOfAitTalents,
    });
    yield put({ type: GET_AIT, payload: "asdas" });
    yield put({ type: GET_ALL_AIT_TALENTS, payload: null });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<NewAitTalent>(NEW_AIT_TALENT, newAitTalent);
  yield takeLatest<UpdateManyAitTalent>(
    UPDATE_MANY_AIT_TALENT,
    updateManyAitTalent
  );
  yield takeLatest<GetAllAitTalents>(GET_ALL_AIT_TALENTS, getAllAitTalents);
  yield takeLatest<UpdateAitTalent>(UPDATE_AIT_TALENT, updatedAitTalent);
  yield takeLatest<UpdateAitTalent>(DELETE_AIT_TALENT, deleteAitTalent);
}
