import {
  Actions,
  GET_INDIVIDUAL_STATS,
  GET_INDIVIDUAL_STATS_ERROR,
  GET_INDIVIDUAL_STATS_SUCCESS,
  State,
  initialValues,
  RESET_GET_INDIVIDUAL_STATS,
  GET_COHORT_STATS,
  GET_COHORT_STATS_SUCCESS,
  GET_COHORT_STATS_ERROR,
  RESET_GET_COHORT_STATS,
  GET_ALL_STUDENTS_STATS,
  GET_ALL_STUDENTS_STATS_SUCCESS,
  GET_ALL_STUDENTS_STATS_ERROR,
  GET_ACADEMY_STATS,
  GET_ACADEMY_STATS_SUCCESS,
  GET_ACADEMY_STATS_ERROR,
  RESET_ACADEMY_STATS,
  GET_ORGANIZATION_STATS,
  GET_ORGANIZATION_STATS_ERROR,
  GET_ORGANIZATION_STATS_SUCCESS,
  RESET_ORGANIZATION_STATS,
  GET_MANY_INDIVIDUAL_STATS,
  RESET_GET_ALL_STUDENTS_STATS,
  GET_COHORT_USER_TIME,
  GET_COHORT_USER_TIME_SUCCESS,
  GET_COHORT_USER_TIME_ERROR,
  RESET_GET_COHORT_USER_TIME,
  GET_ACADEMY_USER_TIME,
  GET_ACADEMY_USER_TIME_SUCCESS,
  GET_ACADEMY_USER_TIME_ERROR,
  RESET_GET_ACADEMY_USER_TIME,
  GET_ACADEMY_SUSCRIPTION_STATS,
  GET_ACADEMY_SUSCRIPTION_STATS_SUCCESS,
  GET_ACADEMY_SUSCRIPTION_STATS_ERROR,
  RESET_GET_ACADEMY_SUSCRIPTION_STATS,
  GET_COHORT_SUSCRIPTION_STATS,
  GET_COHORT_SUSCRIPTION_STATS_ERROR,
  GET_COHORT_SUSCRIPTION_STATS_SUCCESS,
  RESET_GET_COHORT_SUSCRIPTION_STATS,
} from "../types/analytics";
const reducers = (state = initialValues, action: Actions): State => {
  switch (action.type) {
    case GET_INDIVIDUAL_STATS:
      return {
        ...state,
        getIndividualStats: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_INDIVIDUAL_STATS_SUCCESS:
      return {
        ...state,
        getIndividualStats: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_INDIVIDUAL_STATS_ERROR:
      return {
        ...state,
        getIndividualStats: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_GET_INDIVIDUAL_STATS:
      return {
        ...state,
        getIndividualStats: {
          data: state.getIndividualStats.data,
          states: {
            loading: false,
            error: false,
            success: false,
          },
        },
      };
    case GET_MANY_INDIVIDUAL_STATS:
      return {
        ...state,
        getAllStudentsStats: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ACADEMY_STATS:
      return {
        ...state,
        getAcademyStats: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ACADEMY_STATS_SUCCESS:
      return {
        ...state,
        getAcademyStats: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ACADEMY_STATS_ERROR:
      return {
        ...state,
        getAcademyStats: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_ACADEMY_STATS:
      return {
        ...state,
        getAcademyStats: {
          data: state.getAcademyStats.data,
          states: {
            loading: false,
            error: false,
            success: false,
          },
        },
      };
    case GET_ORGANIZATION_STATS:
      return {
        ...state,
        getOrganizationStats: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ORGANIZATION_STATS_SUCCESS:
      return {
        ...state,
        getOrganizationStats: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ORGANIZATION_STATS_ERROR:
      return {
        ...state,
        getOrganizationStats: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_ORGANIZATION_STATS:
      return {
        ...state,
        getOrganizationStats: {
          data: state.getOrganizationStats.data,
          states: {
            loading: false,
            error: false,
            success: false,
          },
        },
      };
    case GET_COHORT_STATS:
      return {
        ...state,
        getCohortStats: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case RESET_GET_COHORT_STATS:
      return {
        ...state,
        getCohortStats: {
          data: state.getCohortStats.data,
          states: {
            loading: false,
            error: false,
            success: false,
          },
        },
      };
    case GET_ACADEMY_SUSCRIPTION_STATS:
      return {
        ...state,
        academySuscriptionStats: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ACADEMY_SUSCRIPTION_STATS_SUCCESS:
      return {
        ...state,
        academySuscriptionStats: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ACADEMY_SUSCRIPTION_STATS_ERROR:
      return {
        ...state,
        academySuscriptionStats: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_GET_ACADEMY_SUSCRIPTION_STATS:
      return {
        ...state,
        academySuscriptionStats: initialValues.academySuscriptionStats,
      };
    case GET_COHORT_SUSCRIPTION_STATS:
      return {
        ...state,
        cohortSuscriptionStats: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_COHORT_SUSCRIPTION_STATS_SUCCESS:
      return {
        ...state,
        cohortSuscriptionStats: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_COHORT_SUSCRIPTION_STATS_ERROR:
      return {
        ...state,
        cohortSuscriptionStats: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_GET_COHORT_SUSCRIPTION_STATS:
      return {
        ...state,
        cohortSuscriptionStats: initialValues.cohortSuscriptionStats,
      };
    case GET_ALL_STUDENTS_STATS:
      return {
        ...state,
        getAllStudentsStats: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_STUDENTS_STATS_SUCCESS:
      return {
        ...state,
        getAllStudentsStats: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_STUDENTS_STATS_ERROR:
      return {
        ...state,
        getAllStudentsStats: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_GET_ALL_STUDENTS_STATS:
      return {
        ...state,
        getAllStudentsStats: initialValues.getAllStudentsStats,
      };
    case GET_COHORT_USER_TIME:
      return {
        ...state,
        cohortUserTime: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_COHORT_USER_TIME_SUCCESS:
      return {
        ...state,
        cohortUserTime: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_COHORT_USER_TIME_ERROR:
      return {
        ...state,
        cohortUserTime: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_GET_COHORT_USER_TIME:
      return {
        ...state,
        cohortUserTime: initialValues.cohortUserTime,
      };
    case GET_ACADEMY_USER_TIME:
      return {
        ...state,
        academyUserTime: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ACADEMY_USER_TIME_SUCCESS:
      return {
        ...state,
        academyUserTime: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ACADEMY_USER_TIME_ERROR:
      return {
        ...state,
        academyUserTime: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_GET_ACADEMY_USER_TIME:
      return {
        ...state,
        academyUserTime: initialValues.academyUserTime,
      };
    case GET_COHORT_STATS_ERROR:
      return {
        ...state,
        getCohortStats: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case GET_COHORT_STATS_SUCCESS:
      return {
        ...state,
        getCohortStats: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    default:
      return state;
  }
};

export default reducers;
