import React from "react";
import { Spinner } from "./style";

const Component = ({ size }) => {
  return (
    <Spinner size={size} className="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </Spinner>
  );
};

export default Component;
