import * as Yup from "yup";

export const schema = Yup.object().shape({
  bootcamps: Yup.array().of(Yup.object()),
  criterions: Yup.array().min(1, "al menos un elemento"),
});

interface FormValues {
  bootcamps: string[];
  criterions: any[];
}

export const initialValues: FormValues = {
  bootcamps: [],
  criterions: [],
};
