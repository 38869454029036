import { CSSProperties, useEffect, useRef, useState } from "react";
import Search from "../Search";
import UserProfile from "./components/UserProfile";
import {
  Header,
  HeaderDropDown,
  HeaderTools,
  HearderDropDownWrapper,
  Logo,
  MarkerWrapper,
} from "./styles";
import Apps from "../../assets/icons/menu/apps.svg";
import Constana from "../../assets/icons/menu/constana.svg";
import SubMenuApp from "./components/SubMenuApp";
import DropDownItem from "./components/DropDownItem/index";
import List from "./components/ListItems";
import { connect } from "react-redux";
import { OrganizationDto } from "../../types/organization.dto";
import { AcademyDto } from "../../types/academy.dto";
import { UserDto } from "../../types/user.dto";
import { useDispatch } from "react-redux";
import { setCurrent } from "../../store/actions/current";
import { signOut } from "../../store/actions/auth";
import { Link } from "./components/ListItems/style";
import ConstanaLogoName from "../../assets/images/constana-new.svg";
import ConstanaLogo from "../../assets/images/constanaacademy.svg";
import ConstanaLogoIcon from "../../assets/images/isotipo-constana-blue.svg";
import AppItem from "./components/AppItem";
import services from "../../services";
import { FlexContainer } from "../StyledComponents";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "../../hooks/useWindowScreen";
import { Img } from "../../pages/Dashboard/Cohort/Customize/style";
import { StateDto } from "../../types/states.dto";
import UserProfileItems from "./components/UserProfileItems";
import CurrentNotifications from "./components/CurrentNotifications";
import NotificationList from "./components/NotificationList";
import { GrApps } from "react-icons/gr";
import { MdOutlineApps } from "react-icons/md";
import { useTheme } from "styled-components";
import { ProfileHambuger } from "../ProfileMobile/style";
import { FaBars } from "react-icons/fa";
interface ComponentProps {
  sidebarCollapsed: boolean;
  style?: CSSProperties;
  organizations: OrganizationDto[];
  academies: AcademyDto[];
  user: UserDto;
  organizationStore;
  academyStore;
  modal;
  newAcademyData: AcademyDto;
  newAcademyStates: StateDto;
  setSidebarCollapsed;
  isModalOpen: boolean;
  setIsModalOpen;
}

const Component = (props: ComponentProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { isMobile } = useWindowSize();
  const [activeName, setActiveName] = useState<string>();
  const [myAcademies, setMyAcademies] = useState<AcademyDto[]>();
  const ref: any = useRef();
  const [openDropdown, setOpenDropdown] = useState(null);
  const dropdownRefs: any = {
    item1: useRef(null),
    item2: useRef(null),
    item3: useRef(null),
    item4: useRef(null),
  };

  const handleItemClick = (item) => {
    if (openDropdown === item) {
      // Si se hace clic en el ítem activo, cierra el dropdown.
      setOpenDropdown(null);
    } else {
      setOpenDropdown(item);
    }
  };
  useEffect(() => {
    const handleDocumentClick = (event) => {
      // Si se hace clic en cualquier parte del documento excepto en los elementos del dropdown, cierra el dropdown.
      if (
        openDropdown &&
        !event.target.closest(".pole") &&
        !event.target.closest(".dropdown")
      ) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [openDropdown]);

  useEffect(() => {
    if (props.newAcademyStates.success) {
      setMyAcademies((state) => [props.newAcademyData, ...(state ?? [])]);
    }
  }, [props.newAcademyStates]);

  useEffect(() => {
    if (props.user) {
      const fetchData = async () => {
        const response: any = await services.academy.getAll({
          filterBy: {
            active: true,
            users: {
              $elemMatch: {
                user: props.user._id,
                roleEnd: { $exists: false },
              },
            },
          },
        });

        if (response.response) {
          setMyAcademies(response.response);
        }
      };
      fetchData();
    }
  }, [props.user]);

  const nameIsActive = (name) => activeName === name;
  console.log(openDropdown, "asasas");
  return (
    <Header {...props}>
      {!isMobile && (
        <HeaderTools>
          <FlexContainer
            onClick={() => {
              setCurrent({
                currentSection: "personal",
                id: props.user._id,
                role: "USER",
              });
              navigate("/dashboard/home");
            }}
            align="center"
            gap="6px"
            style={{ cursor: "pointer" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: isMobile ? "11px" : "20px",
              }}
            >
              <Img src={ConstanaLogo} alt="text-icon-constana"></Img>
            </div>
          </FlexContainer>
          <HearderDropDownWrapper
            dropdownName={"my-academies"}
            className={`${openDropdown === "item1" ? "open" : ""} dropdown`}
            style={{ width: "auto", height: "auto" }}
            onClick={(ev) => {
              ev.stopPropagation();
              handleItemClick("item1");
            }}
          >
            <DropDownItem
              isActive={openDropdown === "item1"}
              linkLabel="Academias"
            ></DropDownItem>
            <HeaderDropDown
              style={{ width: "320px" }}
              className={`${openDropdown === "item1" ? "open" : ""} pole`}
              ref={dropdownRefs.item1}
              onClick={(ev) => {
                ev.stopPropagation();
              }}
            >
              {!!myAcademies && (
                <List
                  id={props.user?._id}
                  section={"academy"}
                  title={"asda"}
                  subLinks={myAcademies.map((academy) => {
                    return { ...academy, goTo: "/dashboard/contents/video" };
                  })}
                ></List>
              )}
            </HeaderDropDown>
          </HearderDropDownWrapper>
        </HeaderTools>
      )}
      {isMobile && (
        <ProfileHambuger
          sidebarCollapsed={props.sidebarCollapsed}
          onClick={() => {
            props.setSidebarCollapsed((state) => !state);
          }}
        >
          <FaBars size={22}></FaBars>
        </ProfileHambuger>
      )}
      <HeaderTools isMobile={isMobile}>
        <HearderDropDownWrapper
          dropdownName={"notifications"}
          className={`${
            openDropdown === "item4" || props.isModalOpen ? "open" : ""
          } dropdown circle`}
          style={{ width: "auto", height: "22px" }}
          onClick={() => {
            if (isMobile) {
              props.setIsModalOpen((state) => !state);
            } else {
              handleItemClick("item4");
            }
          }}
        >
          <CurrentNotifications></CurrentNotifications>
          <HeaderDropDown
            style={{
              right: 0,
              left: "unset",
              overflow: "auto",
              maxHeight: "400px",
              padding: "2px 2px 10px 2px",
              width: "360px",
            }}
            className={`${openDropdown === "item4" ? "open" : ""} pole`}
            ref={dropdownRefs.item4}
            onClick={(ev) => {
              ev.stopPropagation();
            }}
          >
            {!isMobile && <NotificationList></NotificationList>}
          </HeaderDropDown>
        </HearderDropDownWrapper>

        {!isMobile && (
          <HearderDropDownWrapper
            dropdownName={"other-apps"}
            className={`${
              openDropdown === "item2" ? "open" : ""
            } dropdown circle`}
            style={{ width: "auto", height: "auto" }}
            onClick={(ev) => {
              ev.stopPropagation();
              handleItemClick("item2");
            }}
          >
            <div
              style={{
                width: "22px",
                height: "22px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MdOutlineApps
                color={theme.colors.Primary}
                size={22}
              ></MdOutlineApps>
            </div>

            <HeaderDropDown
              style={{ right: 0, left: "unset" }}
              className={`${openDropdown === "item2" ? "open" : ""} pole`}
              ref={dropdownRefs.item2}
              onClick={(ev) => {
                ev.stopPropagation();
              }}
            >
              <AppItem logo={ConstanaLogoName}></AppItem>
            </HeaderDropDown>
          </HearderDropDownWrapper>
        )}

        <HearderDropDownWrapper
          dropdownName={"user-account"}
          className={`${
            openDropdown === "item3" ? "open" : ""
          } dropdown circle`}
          style={{ width: "auto", height: "auto" }}
          onClick={(ev) => {
            ev.stopPropagation();
            handleItemClick("item3");
          }}
        >
          <div
            style={{
              height: "32px",
              width: "32px",
              borderRadius: "10px",
              overflow: "hidden",
            }}
          >
            {props.user ? (
              <Img src={props.user.picture} alt={"icono usuario"}></Img>
            ) : (
              <Img
                src={`https://cdn1.iconfinder.com/data/icons/user-pictures/100/unknown-512.png`}
                alt={"icono usuario desconocido"}
              ></Img>
            )}
          </div>
          <HeaderDropDown
            style={{ right: 0, left: "unset" }}
            className={`${openDropdown === "item3" ? "open" : ""} pole`}
            ref={dropdownRefs.item3}
            onClick={(ev) => {
              ev.stopPropagation();
            }}
          >
            <UserProfileItems></UserProfileItems>
          </HeaderDropDown>
        </HearderDropDownWrapper>
      </HeaderTools>
    </Header>
  );
};

const states = ({ organizationStore, academyStore, userStore }) => {
  const { data: organizations } = organizationStore.all;
  const { data: academies } = academyStore.all;
  const { data: newAcademyData, states: newAcademyStates } =
    academyStore.create;
  const { data: user } = userStore;
  return {
    organizationStore,
    academyStore,
    organizations,
    academies,
    user,
    newAcademyData,
    newAcademyStates,
  };
};

export default connect(states)(Component);
