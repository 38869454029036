import styled from "styled-components";

export const ListItem = styled("div")`
  background-color: #fff;
  padding: 4px 12px;
`;

export const ListWrapper = styled("div")`
  width: 100%;
  margin: 0 auto;
  max-width: 1000px;
  padding: 9px 38px 44px 118px;
  background-color: #fff;
`;

export const EntityImageContainer = styled("div")`
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  overflow: hidden;
  box-shadow: 0 0 1px 0 rgba(0, 30, 108, 0.16);
  border-radius: 50%;
`;

export const EntityTitleSemiBold = styled("h3")`
  font-size: 14px;
  font-weight: normal;
  color: #2a2747;
`;

export const EntityTitleDescription = styled("h3")`
  max-width: 412px;
  font-size: 12px;
  font-weight: 600;
  color: #697482;
`;

export const DateFromNotification = styled("span")`
  font-size: 12px;
  font-weight: normal;
  color: #697482;
`;

export const EntityName = styled("h4")`
  font-size: 14px;
  color: #2a2747;
`;
