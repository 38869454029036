import { PaginationDto } from "../../types/pagination.dto";
import { VideoDto } from "../../types/video.dto";

export const GET_ALL_VIDEOS = "GET_ALL_VIDEO";
export const GET_ALL_VIDEOS_ERROR = "GET_ALL_VIDEO_ERROR";
export const GET_ALL_VIDEOS_SUCCESS = "GET_ALL_VIDEO_SUCCESS";
export const RESET_GET_ALL_VIDEO_DATA = "RESET_GET_ALL_VIDEO_DATA";
export const GET_VIDEO = "GET_Video";
export const GET_VIDEO_ERROR = "GET_Video_ERROR";
export const GET_VIDEO_SUCCESS = "GET_Video_SUCCESS";
export const RESET_GET_VIDEO = "RESET_GET_VIDEO";
export const SET_GET_VIDEO = "SET_GET_VIDEO";
export const NEW_VIDEO = "NEW_Video";
export const NEW_VIDEO_ERROR = "NEW_Video_ERROR";
export const NEW_VIDEO_SUCCESS = "NEW_Video_SUCCESS";
export const RESET_NEW_VIDEO = "RESET_NEW_Video";
export const UPDATE_VIDEO = "UPDATE_Video";
export const UPDATE_VIDEO_ERROR = "UPDATE_Video_ERROR";
export const UPDATE_VIDEO_SUCCESS = "UPDATE_Video_SUCCESS";
export const RESET_UPDATE_VIDEO = "RESET_UPDATE_Video";
export const DELETE_VIDEO = "DELETE_Video";
export const DELETE_VIDEO_ERROR = "DELETE_Video_ERROR";
export const DELETE_VIDEO_SUCCESS = "DELETE_Video_SUCCESS";
export const RESET_DELETE_VIDEO = "RESET_DELETE_Video";
export const UPLOAD_MEDIA = "UPLOAD_MEDIA";
export const UPDATE_VIDEO_LOADING = "UPDATE_VIDEO_LOADING";
export const ADD_USER_TO_VIDEO = "ADD_USER_TO_VIDEO";
export const SHARED_VIDEO = "SHARED_VIDEO";
export const UPDATE_MANY_VIDEO = "UPDATE_MANY_VIDEO";
export const UPDATE_MANY_VIDEO_ERROR = "UPDATE_MANY_VIDEO_ERROR";
export const UPDATE_MANY_VIDEO_SUCCESS = "UPDATE_MANY_VIDEO_SUCCESS";
export const RESET_UPDATE_MANY_VIDEO = "RESET_UPDATE_MANY_VIDEO";

export interface UpdateManyVideo {
  type: typeof UPDATE_MANY_VIDEO;
  payload: any;
}

export interface UpdateManyVideoSuccess {
  type: typeof UPDATE_MANY_VIDEO_SUCCESS;
  payload: VideoDto[];
}

export interface UpdateManyVideoError {
  type: typeof UPDATE_MANY_VIDEO_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateManyVideo {
  type: typeof RESET_UPDATE_MANY_VIDEO;
  payload: null;
}

export interface SharedVideo {
  type: typeof SHARED_VIDEO;
  payload: any;
}
export interface GetAllVideos {
  type: typeof GET_ALL_VIDEOS;
  payload: PaginationDto;
}

export interface GetAllVideosSuccess {
  type: typeof GET_ALL_VIDEOS_SUCCESS;
  payload: VideoDto[];
}

export interface ResetGetAllVideoData {
  type: typeof RESET_GET_ALL_VIDEO_DATA;
  payload: null;
}

export interface SetGetVideo {
  type: typeof SET_GET_VIDEO;
  payload: VideoDto | null;
}

export interface GetAllVideosError {
  type: typeof GET_ALL_VIDEOS_ERROR;
  payload: boolean | string;
}

export interface AddUserToVideo {
  type: typeof ADD_USER_TO_VIDEO;
  payload: any;
}

export interface GetVideo {
  type: typeof GET_VIDEO;
  payload: { id: string };
}

export interface GetVideoSuccess {
  type: typeof GET_VIDEO_SUCCESS;
  payload: VideoDto;
}

export interface GetVideoError {
  type: typeof GET_VIDEO_ERROR;
  payload: boolean | string;
}

export interface NewVideo {
  type: typeof NEW_VIDEO;
  payload: VideoDto;
}

export interface NewVIDEOuccess {
  type: typeof NEW_VIDEO_SUCCESS;
  payload: VideoDto;
}

export interface ResetGetVideo {
  type: typeof RESET_GET_VIDEO;
  payload: null;
}

export interface NewVideoError {
  type: typeof NEW_VIDEO_ERROR;
  payload: boolean | string;
}

export interface UploadMedia {
  type: typeof UPLOAD_MEDIA;
  payload: any;
}

export interface ResetNewVideo {
  type: typeof RESET_NEW_VIDEO;
  payload: null;
}

export interface UpdateVideo {
  type: typeof UPDATE_VIDEO;
  payload: any;
}

export interface UpdateVideoLoading {
  type: typeof UPDATE_VIDEO_LOADING;
  payload: VideoDto;
}

export interface UpdateVIDEOuccess {
  type: typeof UPDATE_VIDEO_SUCCESS;
  payload: VideoDto;
}

export interface UpdateVideoError {
  type: typeof UPDATE_VIDEO_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateVideo {
  type: typeof RESET_UPDATE_VIDEO;
  payload: null;
}

export interface DeleteVideo {
  type: typeof DELETE_VIDEO;
  payload: { _id: string };
}

export interface DeleteVIDEOuccess {
  type: typeof DELETE_VIDEO_SUCCESS;
  payload: null;
}

export interface DeleteVideoError {
  type: typeof DELETE_VIDEO_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteVideo {
  type: typeof RESET_DELETE_VIDEO;
  payload: null;
}

export interface State {
  all: {
    data: VideoDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  updateMany: {
    data: null | VideoDto[];
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  video: {
    data: VideoDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  new: {
    data: VideoDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  delete: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  update: {
    data: null | VideoDto;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  all: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  video: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  updateMany: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  new: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  delete: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  update: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllVideos
  | GetAllVideosSuccess
  | GetAllVideosError
  | ResetGetAllVideoData
  | GetVideo
  | GetVideoSuccess
  | GetVideoError
  | NewVideo
  | NewVIDEOuccess
  | NewVideoError
  | ResetNewVideo
  | UpdateVideo
  | UpdateVIDEOuccess
  | UpdateVideoError
  | ResetUpdateVideo
  | DeleteVideo
  | DeleteVIDEOuccess
  | DeleteVideoError
  | ResetDeleteVideo
  | UploadMedia
  | UpdateVideoLoading
  | ResetGetVideo
  | AddUserToVideo
  | SharedVideo
  | SetGetVideo
  | UpdateManyVideo
  | UpdateManyVideoSuccess
  | UpdateManyVideoError
  | ResetUpdateManyVideo;
