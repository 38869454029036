import * as Yup from "yup";

export const initialValues = {
  academy: "",
  chart: "",
  bootcamp: "",
  userId: "",
};

export const schema = Yup.object().shape({
  name: Yup.string().nullable(),
  chart: Yup.string().nullable(),
  bootcamp: Yup.string().nullable(),
  userId: Yup.string().nullable(),
});
