import React, { useEffect, useState } from "react";

import {
  Center,
  FlexContainer,
  PageTitle,
} from "../../../../components/StyledComponents";
import Button from "../../../../components/Button";
import { connect, useDispatch } from "react-redux";
import { AcademyDto } from "../../../../types/academy.dto";
import { UserDto } from "../../../../types/user.dto";

import FilterByName from "./components/FilterByName";
import { CohortDto } from "../../../../types/cohort.dto";

import Loader from "../../../../components/Loader";
import { FaTrash } from "react-icons/fa";
import { FiEdit2 } from "react-icons/fi";
import { EntityTitleSemiBold, ListItem } from "./style";

import { EventDto } from "../../../../types/event.dto";
import { CurrentDto } from "../../../../types/current.dto";
import Grid from "../../../../components/Grid";
import { showModal } from "../../../../store/actions/modal";
import ModalCreateEvent from "./components/ModalCreateEvent";
import ModalEditEvent from "./components/ModalEditEvent";
import ModalConfirmDelete from "../../../../components/ModalConfirmDelete";
import { StateDto } from "../../../../types/states.dto";
import {
  deleteEvet,
  getAllEvents,
  resetDeleteEvent,
  resetGetAllEvents,
} from "../../../../store/actions/event";
import {
  getAllCohorts,
  resetGetAllCohortsData,
} from "../../../../store/actions/cohort";

interface ComponentProps {
  user: UserDto;
  cohorts: CohortDto[];
  academies: AcademyDto[];
  events: EventDto[];
  current: CurrentDto;
  deleteEventStates: StateDto;
  deletedEvent: EventDto;
  updatedEvent: EventDto;
  updateEventStates: StateDto;
  newEvent: EventDto;
  newEventStates: StateDto;
}

const Component = ({
  user,
  cohorts,
  academies,
  events,
  current,
  deleteEventStates,
  deletedEvent,
  updatedEvent,
  updateEventStates,
  newEvent,
  newEventStates,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const [currentEventsList, setCurrentEventsList] = useState<EventDto[]>([]);
  const [applyedFilters, setFilters] = useState<
    {
      field: string;
      value: string | boolean;
      special: (data?: any, data2?: any) => boolean;
    }[]
  >([]);
  const [eventSelected, setEventSelected] = useState<EventDto>();
  const [filteredCurrentEventsListValues, setFilteredCurrentEventsListValues] =
    useState<EventDto[]>([]);

  const buildFilters = (newFilter) => {
    const filterUpdated =
      applyedFilters.filter(
        (applyedFilter) => applyedFilter.field === newFilter.field
      ).length > 0;

    const filterRemoved = newFilter.value === "no";

    /**
     * Se elimina el filtro
     */
    if (filterRemoved) {
      setFilters([
        ...applyedFilters.filter(
          (applyedFilter) => applyedFilter.field !== newFilter.field
        ),
      ]);
    }

    /**
     * Se actualiza un filtro
     */
    if (filterUpdated && !filterRemoved) {
      setFilters([
        ...applyedFilters.map((applyedFilter) => {
          if (applyedFilter.field === newFilter.field) {
            return newFilter;
          } else {
            return applyedFilter;
          }
        }),
      ]);
    }

    /**
     * Se agrega un filtro
     */
    if (!filterUpdated && !filterRemoved) {
      setFilters([...applyedFilters, newFilter]);
    }
  };

  useEffect(() => {
    if (!!events) {
      const currentEvents =
        /* Array.from(
        new Set(
          cohorts
            .filter((cohort) => cohort.active && cohort.academy === current.id)
            .map((cohort) => {
              return cohort.eventsIds;
            })
            .flat(1)
        )
      ) */
        events.filter((event) =>
          current.role !== "ADMIN"
            ? cohorts
                .find((cohort) => cohort.academy._id === current.id)
                ?.mentorsIds.includes(user._id)
            : event
        );

      setCurrentEventsList(currentEvents);
    }
  }, [events, cohorts]);

  useEffect(() => {
    const filteredEvents = currentEventsList?.filter((currentTalentValues) => {
      const thereAreFilters = applyedFilters.length > 0;
      const totalOfFilters = applyedFilters.length;
      const passFilters = applyedFilters
        .filter((applyedFilter) => !applyedFilter.special)
        .filter(
          (applyedFilter) =>
            currentTalentValues[applyedFilter.field] === applyedFilter.value
        ).length;
      const specialFilter = applyedFilters.filter(
        (applyedFilter) => !!applyedFilter.special
      );

      const speacialFilterCheck = specialFilter.filter((applyedFilter) => {
        return applyedFilter.special(currentTalentValues, applyedFilter.value);
      });

      return (
        !thereAreFilters ||
        (thereAreFilters &&
          totalOfFilters === passFilters + specialFilter.length &&
          speacialFilterCheck.length === specialFilter.length)
      );
    });
    setFilteredCurrentEventsListValues(filteredEvents);
  }, [applyedFilters, currentEventsList]);

  useEffect(() => {
    if (updateEventStates.success) {
      setCurrentEventsList((state) =>
        state.map((st) => (st._id === updatedEvent._id ? updatedEvent : st))
      );
    }
  }, [updateEventStates]);

  useEffect(() => {
    if (deleteEventStates.success) {
      setCurrentEventsList((state) =>
        state.filter((st) => st._id !== deletedEvent._id)
      );
    }
  }, [deleteEventStates]);

  useEffect(() => {
    if (newEventStates.success) {
      setCurrentEventsList((state) => [...state, newEvent]);
    }
  }, [newEventStates]);

  useEffect(() => {
    dispatch(getAllEvents({ filterBy: { academy: current.id } }));
    dispatch(getAllCohorts({ filterBy: { academy: current.id } }));

    return () => {
      dispatch(resetGetAllEvents());
      dispatch(resetGetAllCohortsData());
    };
  }, []);

  if (!events || !cohorts) return <Loader color="Primary"></Loader>;
  return (
    <>
      <PageTitle>Cohorts - Eventos Sincrónicos </PageTitle>
      <Grid.Row>
        <Grid.Col offset={{ sm: 10 }} sm={12}>
          <Button
            onClick={() => {
              dispatch(showModal("create-event-cohort"));
            }}
            type="button"
            options={{
              type: "filled",
              skin: "primary",
              size: "md",
              marginBottom: "10px",
            }}
            style={{ marginLeft: "10px" }}
          >
            Crear
          </Button>
        </Grid.Col>
      </Grid.Row>
      <Center>
        <div style={{ width: "70%", marginBottom: "20px" }}>
          <FilterByName
            items={filteredCurrentEventsListValues.map((event) => {
              return { value: event.name, label: event.name };
            })}
            setFilter={buildFilters}
          ></FilterByName>
        </div>
      </Center>
      <Grid.Row>
        <Grid.Col>
          <EntityTitleSemiBold
            style={{
              margin: "14px 0px 20px 20px ",
              fontSize: "16px",
              fontWeight: "500",
            }}
          >
            Nombre del Evento
          </EntityTitleSemiBold>
          {filteredCurrentEventsListValues.length > 0 ? (
            <FlexContainer direction="column" gap="14px">
              {filteredCurrentEventsListValues.map((event) => {
                return (
                  <FlexContainer direction="column" gap="14px">
                    <ListItem>
                      <FlexContainer
                        gap="14px"
                        justify="space-between"
                        align="center"
                      >
                        <EntityTitleSemiBold>{event.name}</EntityTitleSemiBold>
                        <FlexContainer gap="20px">
                          <FiEdit2
                            onClick={() => {
                              setEventSelected(event);
                              dispatch(showModal("edit-event-cohort"));
                            }}
                            cursor={"pointer"}
                            color="#418DEA"
                            fontSize={16}
                          ></FiEdit2>
                          <FaTrash
                            onClick={() => {
                              setEventSelected(event);
                              dispatch(
                                showModal(
                                  "confirm-delete-item-secuencial-modal"
                                )
                              );
                            }}
                            cursor={"pointer"}
                            color="#f76564"
                            fontSize={16}
                          ></FaTrash>
                        </FlexContainer>
                      </FlexContainer>
                    </ListItem>
                  </FlexContainer>
                );
              })}
            </FlexContainer>
          ) : (
            <Center>
              <h3>No hay resultados</h3>
            </Center>
          )}
        </Grid.Col>
      </Grid.Row>
      <ModalCreateEvent></ModalCreateEvent>
      {eventSelected && (
        <ModalEditEvent eventSelected={eventSelected}></ModalEditEvent>
      )}
      {eventSelected && (
        <ModalConfirmDelete
          bntConfig={{
            content: "Eliminar",
            style: {
              options: {
                type: "outline",
                skin: "danger",
                size: "lg",
                marginBottom: "0px",
              },
            },
          }}
          states={deleteEventStates}
          title={
            <span>
              {`¿Seguro que quiere eliminar el evento `}
              <span style={{ fontWeight: "bold", color: "black" }}>
                {eventSelected?.name}
              </span>
              ?
            </span>
          }
          elementActions={deleteEvet({ _id: eventSelected._id })}
          resetAction={resetDeleteEvent}
          resetState={setEventSelected}
        ></ModalConfirmDelete>
      )}
    </>
  );
};

const states = ({
  userStore,
  cohortStore,
  academyStore,
  currentStore,
  eventStore,
}) => {
  const { data: academies } = academyStore.all;
  const { data: user } = userStore;
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: current } = currentStore;
  const { data: events } = eventStore.allEvents;
  const { states: deleteEventStates, data: deletedEvent } =
    eventStore.deleteEvent;
  const { data: updatedEvent, states: updateEventStates } =
    eventStore.updateEvent;
  const { data: newEvent, states: newEventStates } = eventStore.newEvent;
  return {
    user,
    cohorts,
    academies,
    current,
    events,
    deleteEventStates,
    deletedEvent,
    updatedEvent,
    updateEventStates,
    newEvent,
    newEventStates,
  };
};

export default connect(states)(Component);
