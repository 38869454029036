import { put, call, all, takeLatest } from "redux-saga/effects";
import services from "../../services";
import {
  GET_COHORT,
  GET_COHORT_SUCCESS,
  GET_COHORT_ERROR,
  NEW_COHORT,
  NEW_COHORT_SUCCESS,
  NEW_COHORT_ERROR,
  GET_ALL_COHORTS,
  GET_ALL_COHORTS_SUCCESS,
  GET_ALL_COHORTS_ERROR,
  UPDATE_COHORT,
  UPDATE_COHORT_SUCCESS,
  UPDATE_COHORT_ERROR,
  DELETE_COHORT,
  DELETE_COHORT_SUCCESS,
  DELETE_COHORT_ERROR,
  GetCohort,
  NewCohort,
  GetAllCohorts,
  UpdateCohort,
  DeleteCohort,
  DELETE_COHORT_MEMBER,
  DELETE_COHORT_MEMBER_ERROR,
  DELETE_COHORT_MEMBER_SUCCESS,
  DeleteCohortMember,
  GET_ALL_COHORT_STUDENTS_ERROR,
  GET_ALL_COHORT_STUDENTS_SUCCESS,
  GetAllCohortStudents,
  GET_ALL_COHORT_STUDENTS,
  UpdateCohortPlan,
  NewCohortPlan,
  UPDATE_COHORT_PLAN,
  NEW_COHORT_PLAN,
} from "../types/cohort";
import { GET_ALL_ABILITIES } from "../types/ability";

function* getAllCohorts({ payload }) {
  const { response, error } = yield call(services.cohort.getAll, payload);

  if (error) {
    yield put({
      type: GET_ALL_COHORTS_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_COHORTS_SUCCESS, payload: response });
  }
}

function* getCohortStudents({ payload }) {
  const { response, error } = yield call(
    services.cohort.getStudentsByCohort,
    payload
  );
  if (error) {
    yield put({
      type: GET_ALL_COHORT_STUDENTS_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_COHORT_STUDENTS_SUCCESS, payload: response });
  }
}

function* getCohort({ payload }) {
  const { response, error } = yield call(services.cohort.getOne, payload);

  if (error) {
    yield put({
      type: GET_COHORT_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_COHORT_SUCCESS, payload: response });
  }
}

function* newPlan({ payload }) {
  const { response, error } = yield call(services.academy.newPlan, payload);

  if (error) {
    yield put({
      type: UPDATE_COHORT_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: UPDATE_COHORT_SUCCESS, payload: response });
  }
}
function* updatePlan({ payload }) {
  const { response, error } = yield call(services.academy.updatePlan, payload);

  if (error) {
    yield put({
      type: UPDATE_COHORT_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: UPDATE_COHORT_SUCCESS, payload: response });
  }
}

function* newCohort({ payload }) {
  const { response, error } = yield call(services.cohort.new, payload);

  if (error) {
    yield put({
      type: NEW_COHORT_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: NEW_COHORT_SUCCESS, payload: response })]);
  }
}

function* updateCohort({ payload }) {
  const { response, error } = yield call(services.cohort.update, payload);

  if (error) {
    yield put({
      type: UPDATE_COHORT_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_COHORT_SUCCESS, payload: response })]);
  }
}

function* deleteCohort({ payload }) {
  const { response, error } = yield call(services.cohort.delete, payload);

  if (error) {
    yield put({
      type: DELETE_COHORT_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: DELETE_COHORT_SUCCESS, payload: response })]);
  }
}
function* deleteCohortMember({ payload }) {
  const { response, error } = yield call(services.cohort.deleteMember, payload);

  if (error) {
    yield put({
      type: DELETE_COHORT_MEMBER_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: DELETE_COHORT_MEMBER_SUCCESS, payload: response })]);
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllCohorts>(GET_ALL_COHORTS, getAllCohorts);
  yield takeLatest<GetCohort>(GET_COHORT, getCohort);
  yield takeLatest<NewCohort>(NEW_COHORT, newCohort);
  yield takeLatest<UpdateCohort>(UPDATE_COHORT, updateCohort);
  yield takeLatest<DeleteCohort>(DELETE_COHORT, deleteCohort);
  yield takeLatest<DeleteCohortMember>(
    DELETE_COHORT_MEMBER,
    deleteCohortMember
  );
  yield takeLatest<GetAllCohortStudents>(
    GET_ALL_COHORT_STUDENTS,
    getCohortStudents
  );
  yield takeLatest<UpdateCohortPlan>(UPDATE_COHORT_PLAN, updatePlan);
  yield takeLatest<NewCohortPlan>(NEW_COHORT_PLAN, newPlan);
}
