import { SearchDto } from "../../../types/recruiting.dto";

export const GET_ALL_SEARCHES = "GET_ALL_SEARCHES";
export const GET_ALL_SEARCHES_ERROR = "GET_ALL_SEARCHES_ERROR";
export const GET_ALL_SEARCHES_SUCCESS = "GET_ALL_SEARCHES_SUCCESS";
export const UPDATE_CURRENT_SEARCH_LIST = "UPDATE_CURRENT_SEARCH_LIST";
export const GET_SEARCH = "GET_SEARCH";
export const GET_SEARCH_ERROR = "GET_SEARCH_ERROR";
export const GET_SEARCH_SUCCESS = "GET_SEARCH_SUCCESS";
export const NEW_SEARCH = "NEW_SEARCH";
export const NEW_SEARCH_ERROR = "NEW_SEARCH_ERROR";
export const NEW_SEARCH_SUCCESS = "NEW_SEARCH_SUCCESS";
export const RESET_NEW_SEARCH = "RESET_NEW_SEARCH";
export const UPDATE_SEARCH = "UPDATE_SEARCH";
export const UPDATE_SEARCH_ERROR = "UPDATE_SEARCH_ERROR";
export const UPDATE_SEARCH_SUCCESS = "UPDATE_SEARCH_SUCCESS";
export const RESET_UPDATE_SEARCH = "RESET_UPDATE_SEARCH";
export const DELETE_SEARCH = "DELETE_SEARCH";
export const DELETE_SEARCH_ERROR = "DELETE_SEARCH_ERROR";
export const DELETE_SEARCH_SUCCESS = "DELETE_SEARCH_SUCCESS";
export const RESET_DELETE_SEARCH = "RESET_DELETE_SEARCH";
export const SET_NEW_SEARCH_DATA = "SET_NEW_SEARCH_DATA";
export const SET_UPDATE_SEARCH_DATA = "SET_UPDATE_SEARCH_DATA";
export const UPDATE_MANY_SEARCH = "UPDATE_MANY_SEARCH";
export const UPDATE_MANY_SEARCH_SUCCESS = "UPDATE_MANY_SEARCH_SUCCESS";
export const UPDATE_MANY_SEARCH_ERROR = "UPDATE_MANY_SEARCH_ERROR";

export interface GetAllSearchs {
  type: typeof GET_ALL_SEARCHES;
  payload: null;
}

export interface GetAllSearchsSuccess {
  type: typeof GET_ALL_SEARCHES_SUCCESS;
  payload: SearchDto[];
}

export interface GetAllSearchsError {
  type: typeof GET_ALL_SEARCHES_ERROR;
  payload: boolean | string;
}

export interface UpdateCurrentSearchList {
  type: typeof UPDATE_CURRENT_SEARCH_LIST;
  payload: SearchDto[];
}

export interface GetSearch {
  type: typeof GET_SEARCH;
  payload: { _id: string };
}

export interface GetSearchSuccess {
  type: typeof GET_SEARCH_SUCCESS;
  payload: SearchDto;
}

export interface GetSearchError {
  type: typeof GET_SEARCH_ERROR;
  payload: boolean | string;
}

export interface SetNewSearchData {
  type: typeof SET_NEW_SEARCH_DATA;
  payload: SearchDto;
}
export interface SetUpdateSearchData {
  type: typeof SET_UPDATE_SEARCH_DATA;
  payload: SearchDto;
}

export interface NewSearch {
  type: typeof NEW_SEARCH;
  payload: SearchDto;
}

export interface NewSearchSuccess {
  type: typeof NEW_SEARCH_SUCCESS;
  payload: SearchDto;
}

export interface NewSearchError {
  type: typeof NEW_SEARCH_ERROR;
  payload: boolean | string;
}

export interface ResetNewSearch {
  type: typeof RESET_NEW_SEARCH;
  payload: null;
}

export interface UpdateSearch {
  type: typeof UPDATE_SEARCH;
  payload: SearchDto;
}

export interface UpdateManySearch {
  type: typeof UPDATE_MANY_SEARCH;
  payload: SearchDto;
}

export interface UpdateManySearchSuccess {
  type: typeof UPDATE_MANY_SEARCH_SUCCESS;
  payload: null;
}
export interface UpdateManySearchError {
  type: typeof UPDATE_MANY_SEARCH_ERROR;
  payload: boolean | string;
}

export interface UpdateSearchSuccess {
  type: typeof UPDATE_SEARCH_SUCCESS;
  payload: null;
}

export interface UpdateSearchError {
  type: typeof UPDATE_SEARCH_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateSearch {
  type: typeof RESET_UPDATE_SEARCH;
  payload: null;
}

export interface DeleteSearch {
  type: typeof DELETE_SEARCH;
  payload: { _id: string };
}

export interface DeleteSearchSuccess {
  type: typeof DELETE_SEARCH_SUCCESS;
  payload: null;
}

export interface DeleteSearchError {
  type: typeof DELETE_SEARCH_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteSearch {
  type: typeof RESET_DELETE_SEARCH;
  payload: null;
}

export interface State {
  all: {
    data: SearchDto[] | any | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  selected: {
    data: SearchDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  create: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  delete: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  updateMany: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  update: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  all: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  updateMany: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  selected: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  create: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  delete: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  update: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllSearchs
  | GetAllSearchsSuccess
  | GetAllSearchsError
  | UpdateCurrentSearchList
  | GetSearch
  | GetSearchSuccess
  | GetSearchError
  | NewSearch
  | NewSearchSuccess
  | NewSearchError
  | ResetNewSearch
  | UpdateSearch
  | UpdateSearchSuccess
  | UpdateSearchError
  | ResetUpdateSearch
  | DeleteSearch
  | DeleteSearchSuccess
  | DeleteSearchError
  | ResetDeleteSearch
  | SetNewSearchData
  | SetUpdateSearchData
  | UpdateManySearch
  | UpdateManySearchSuccess
  | UpdateManySearchError;
