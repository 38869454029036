import { put, call, takeLatest, select } from "redux-saga/effects";
import services from "../../services";
import {
  NewEvaluate,
  NEW_EVALUATE,
  NEW_EVALUATE_ERROR,
  NEW_EVALUATE_SUCCESS,
  GetAllEvaluates,
  GET_ALL_EVALUATE,
  GET_ALL_EVALUATE_ERRROR,
  GET_ALL_EVALUATE_SUCCESS,
  SET_EVALUATE_LIST,
  UpdateEvaluate,
  UPDATE_EVALUATE,
  UPDATE_EVALUATE_ERROR,
  UPDATE_EVALUATE_SUCCESS,
  NEW_MANY_EVALUATE_SUCCESS,
  NewManyEvaluate,
  NEW_MANY_EVALUATE,
  NEW_MANY_EVALUATE_ERROR,
} from "../types/evaluate";

function* newEvaluate({ payload }) {
  const { response, error } = yield call(services.evaluate.new, payload);

  if (error) {
    yield put({
      type: NEW_EVALUATE_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: NEW_EVALUATE_SUCCESS, payload: response });
  }
}
function* newEvaluateMany({ payload }) {
  let allItems: any[] = [];

  try {
    for (let i = 0; i < payload.items.length; i++) {
      const { response, error } = yield call(
        services.evaluate.new,
        payload.items[i]
      );
      if (!!error) {
        throw new Error(error.response.data.message);
      }
      if (!!response) {
        allItems = [...allItems, ...response];
      }
    }
    yield put({
      type: NEW_MANY_EVALUATE_SUCCESS,
      payload: allItems,
    });
  } catch (err: any) {
    yield put({
      type: NEW_MANY_EVALUATE_ERROR,
      payload: err.message || "Error",
    });
  }
}
function* updateEvaluate({ payload }) {
  const { response, error } = yield call(services.evaluate.update, payload);

  if (error) {
    yield put({
      type: UPDATE_EVALUATE_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: UPDATE_EVALUATE_SUCCESS, payload: response });
  }
}
function* getAllEvaluate({ payload }) {
  const { response, error } = yield call(services.evaluate.getAll, payload);

  if (error) {
    yield put({
      type: GET_ALL_EVALUATE_ERRROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_EVALUATE_SUCCESS, payload: response });
  }
}

export default function* applicant() {
  yield takeLatest<NewEvaluate>(NEW_EVALUATE, newEvaluate);
  yield takeLatest<GetAllEvaluates>(GET_ALL_EVALUATE, getAllEvaluate);
  yield takeLatest<UpdateEvaluate>(UPDATE_EVALUATE, updateEvaluate);
  yield takeLatest<NewManyEvaluate>(NEW_MANY_EVALUATE, newEvaluateMany);
}
