import React, { useState } from "react";
import {
  GridCard,
  GridCardDescription,
  GridCardImage,
  GridStructure,
  TrashIcon,
} from "../../../../../../components/Layout/Dashboard/styles";
import {
  Center,
  FlexContainer,
} from "../../../../../../components/StyledComponents";
import { CohortMediumFont } from "../../style";
import {
  AccordeonContent,
  AccordeonContentWrapper,
  AccordeonWrapper,
  AddContent,
  ArrowIcon,
  ImageContent,
} from "./style";
import contentActions from "../../../../../../store/actions/content";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useDispatch } from "react-redux";
import { showModal } from "../../../../../../store/actions/modal";
import { BsFillTrashFill } from "react-icons/bs";
import {
  resetUpdateVideo,
  updateVideo,
} from "../../../../../../store/actions/video";
import {
  resetUpdateCDOCs,
  updateCDOCs,
} from "../../../../../../store/actions/cdoc";
import { setFormData } from "../../../../../../helpers/formData";
import { AiOutlineEdit } from "react-icons/ai";
import {
  resetUpdateImage,
  updateImage,
} from "../../../../../../store/actions/image";
import {
  resetUpdateRoadmap,
  updateRoadmap,
} from "../../../../../../store/actions/roadmap";
import {
  resetUpdateSlideShow,
  updateSlideShow,
} from "../../../../../../store/actions/slideshow";
import {
  resetUpdateListen,
  updateListen,
} from "../../../../../../store/actions/listen";
import Loader from "../../../../../../components/Loader";
const Component = ({
  index,
  name,
  items,
  active,
  setActive,
  setUnit,
  unit,
  setCurrentSection,
  onClick,
  isLoadingContents,
}) => {
  const dispatch = useDispatch();
  return (
    <>
      <AccordeonWrapper onClick={!!onClick && onClick}>
        <FlexContainer justify="space-between" align="center">
          <FlexContainer gap="8px" align="center">
            <CohortMediumFont>{`${index}: ${name}`}</CohortMediumFont>
            {index === active && !isLoadingContents && (
              <CohortMediumFont
                style={{ fontWeight: "bold" }}
              >{`(${items.length} Contenidos)`}</CohortMediumFont>
            )}
            <BsFillTrashFill
              onClick={(ev) => {
                ev.stopPropagation();
                setUnit({ action: "delete-unit", data: { ...unit } });
                dispatch(showModal("confirm-delete-item-secuencial-modal"));
              }}
              size={15}
              color="#f76564"
            ></BsFillTrashFill>
            <AiOutlineEdit
              onClick={(ev) => {
                ev.stopPropagation();
                setUnit({ action: "update-unit", data: unit });
                dispatch(showModal("edit-unit-name"));
              }}
              size={15}
              color="#448fe1"
            ></AiOutlineEdit>
          </FlexContainer>
          <ArrowIcon className={index === active ? "active" : ""}>
            <MdKeyboardArrowDown
              onClick={() => {
                setActive(index === active ? undefined : index);
              }}
              fontSize={25}
              color={"blue"}
            ></MdKeyboardArrowDown>
          </ArrowIcon>
        </FlexContainer>
        <AccordeonContentWrapper
          onClick={(ev) => {
            ev.stopPropagation();
          }}
          className={index === active ? "active" : ""}
        >
          {!!isLoadingContents || index !== active ? (
            <Center>
              <Loader color="Primary"></Loader>
            </Center>
          ) : (
            <AccordeonContent>
              <FlexContainer direction="column" gap="10px">
                <AddContent
                  onClick={() => {
                    setUnit({
                      action: "add-content-to-unit",
                      data: unit,
                    });
                    dispatch(showModal("new-content-modal"));
                  }}
                >
                  {"Agregar contenidos"}
                </AddContent>
                <div>
                  <FlexContainer direction="column" gap="10px">
                    <span>{`Videos: (${
                      items.filter((itemF) => itemF.type === "video").length
                    })`}</span>
                    <GridStructure
                      style={{ gap: "5px", minHeight: "94px" }}
                      width="120px"
                      maxWidth="900px"
                    >
                      {items
                        .filter((itemF) => itemF.type === "video")
                        .map((content, key) => (
                          <GridCard
                            key={content._id}
                            style={{ cursor: "pointer", padding: "0px" }}
                          >
                            <TrashIcon style={{ right: "18px" }}>
                              <BsFillTrashFill
                                onClick={() => {
                                  setCurrentSection(content.type);
                                  setUnit({
                                    action: "delete-content-from-unit",
                                    data: {
                                      _id: content._id,
                                      name: content.name,
                                      units: content.units.filter(
                                        (unitF) => unitF !== unit._id
                                      ),
                                      type: content.type,
                                      externalFunction: true,
                                      action: () =>
                                        updateVideo({
                                          _id: content._id,
                                          body: setFormData(
                                            {
                                              units: content.units.filter(
                                                (unitF) => unitF !== unit._id
                                              ),
                                              __v: content.__v,
                                            },
                                            []
                                          ),
                                        }),
                                      resetAction: () => resetUpdateVideo(),
                                    },
                                  });
                                  dispatch(
                                    showModal(
                                      "confirm-delete-item-secuencial-modal"
                                    )
                                  );
                                }}
                                fontSize={16}
                              ></BsFillTrashFill>
                            </TrashIcon>
                            <FlexContainer justify="center">
                              <ImageContent>
                                <GridCardImage
                                  src={
                                    content.picture ||
                                    "https://upload.wikimedia.org/wikipedia/commons/8/81/Gallet_clamshell_600x600_6.jpg"
                                  }
                                ></GridCardImage>
                              </ImageContent>
                            </FlexContainer>
                            <FlexContainer justify="center">
                              <GridCardDescription>
                                {content.name}
                              </GridCardDescription>
                            </FlexContainer>
                          </GridCard>
                        ))}
                    </GridStructure>
                  </FlexContainer>
                </div>
                <div>
                  <FlexContainer direction="column" gap="10px">
                    <span>{`Cdocs: (${
                      items.filter((itemF) => itemF.type === "cdoc").length
                    })`}</span>

                    <GridStructure
                      style={{ gap: "5px", minHeight: "94px" }}
                      width="120px"
                      maxWidth="900px"
                    >
                      {items
                        .filter((itemF) => itemF.type === "cdoc")
                        .map((content, key) => (
                          <GridCard
                            key={content._id}
                            style={{ cursor: "pointer", padding: "0px" }}
                          >
                            <TrashIcon style={{ right: "18px" }}>
                              <BsFillTrashFill
                                onClick={() => {
                                  setCurrentSection(content.type);
                                  setUnit({
                                    action: "delete-content-from-unit",
                                    data: {
                                      _id: content._id,
                                      name: content.name,
                                      units: content.units.filter(
                                        (unitF) => unitF !== unit._id
                                      ),
                                      externalFunction: true,
                                      action: () =>
                                        updateCDOCs({
                                          _id: content._id,
                                          body: {
                                            name: content.name,
                                            units: content.units.filter(
                                              (unitF) => unitF !== unit._id
                                            ),
                                            __v: content.__v,
                                          },
                                        }),
                                      resetAction: () => resetUpdateCDOCs(),
                                    },
                                  });
                                  dispatch(
                                    showModal(
                                      "confirm-delete-item-secuencial-modal"
                                    )
                                  );
                                }}
                                fontSize={16}
                              ></BsFillTrashFill>
                            </TrashIcon>
                            <FlexContainer justify="center">
                              <ImageContent>
                                <GridCardImage
                                  src={
                                    content.picture ||
                                    "https://upload.wikimedia.org/wikipedia/commons/8/81/Gallet_clamshell_600x600_6.jpg"
                                  }
                                ></GridCardImage>
                              </ImageContent>
                            </FlexContainer>
                            <FlexContainer justify="center">
                              <GridCardDescription>
                                {content.name}
                              </GridCardDescription>
                            </FlexContainer>
                          </GridCard>
                        ))}
                    </GridStructure>
                  </FlexContainer>
                </div>
                <div>
                  <FlexContainer direction="column" gap="10px">
                    <span>{`Contenidos Varios: (${
                      items.filter((itemF) => itemF.type === "variety").length
                    })`}</span>
                    <GridStructure
                      style={{ gap: "5px", minHeight: "94px" }}
                      width="120px"
                      maxWidth="900px"
                    >
                      {items
                        .filter((itemF) => itemF.type === "variety")
                        .map((content, key) => (
                          <GridCard
                            key={content._id}
                            style={{ cursor: "pointer", padding: "0px" }}
                          >
                            <TrashIcon style={{ right: "18px" }}>
                              <BsFillTrashFill
                                onClick={() => {
                                  setCurrentSection(content.type);
                                  setUnit({
                                    action: "delete-content-from-unit",
                                    data: {
                                      _id: content._id,
                                      name: content.name,
                                      units: content.units.filter(
                                        (unitF) => unitF !== unit._id
                                      ),
                                      externalFunction: true,
                                      action: () =>
                                        contentActions.update({
                                          _id: content._id,
                                          body: {
                                            name: content.name,
                                            units: content.units.filter(
                                              (unitF) => unitF !== unit._id
                                            ),
                                            __v: content.__v,
                                          },
                                        }),
                                      resetAction: () =>
                                        contentActions.resetUpdate(),
                                    },
                                  });
                                  dispatch(
                                    showModal(
                                      "confirm-delete-item-secuencial-modal"
                                    )
                                  );
                                }}
                                fontSize={16}
                              ></BsFillTrashFill>
                            </TrashIcon>
                            <FlexContainer justify="center">
                              <ImageContent>
                                <GridCardImage
                                  src={
                                    content.picture ||
                                    "https://upload.wikimedia.org/wikipedia/commons/8/81/Gallet_clamshell_600x600_6.jpg"
                                  }
                                ></GridCardImage>
                              </ImageContent>
                            </FlexContainer>
                            <FlexContainer justify="center">
                              <GridCardDescription>
                                {content.name}
                              </GridCardDescription>
                            </FlexContainer>
                          </GridCard>
                        ))}
                    </GridStructure>
                  </FlexContainer>
                </div>
                <div>
                  <FlexContainer direction="column" gap="10px">
                    <span>{`Images: (${
                      items.filter((itemF) => itemF.type === "image").length
                    })`}</span>
                    <GridStructure
                      style={{ gap: "5px", minHeight: "94px" }}
                      width="120px"
                      maxWidth="900px"
                    >
                      {items
                        .filter((itemF) => itemF.type === "image")
                        .map((content, key) => (
                          <GridCard
                            key={content._id}
                            style={{ cursor: "pointer", padding: "0px" }}
                          >
                            <TrashIcon style={{ right: "18px" }}>
                              <BsFillTrashFill
                                onClick={() => {
                                  setCurrentSection(content.type);
                                  setUnit({
                                    action: "delete-content-from-unit",
                                    data: {
                                      _id: content._id,
                                      name: content.name,
                                      units: content.units.filter(
                                        (unitF) => unitF !== unit._id
                                      ),
                                      externalFunction: true,
                                      action: () =>
                                        updateImage({
                                          _id: content._id,
                                          body: {
                                            name: content.name,
                                            units: content.units.filter(
                                              (unitF) => unitF !== unit._id
                                            ),
                                            __v: content.__v,
                                          },
                                        }),
                                      resetAction: () => resetUpdateImage(),
                                    },
                                  });
                                  dispatch(
                                    showModal(
                                      "confirm-delete-item-secuencial-modal"
                                    )
                                  );
                                }}
                                fontSize={16}
                              ></BsFillTrashFill>
                            </TrashIcon>
                            <FlexContainer justify="center">
                              <ImageContent>
                                <GridCardImage
                                  src={
                                    content.picture ||
                                    "https://upload.wikimedia.org/wikipedia/commons/8/81/Gallet_clamshell_600x600_6.jpg"
                                  }
                                ></GridCardImage>
                              </ImageContent>
                            </FlexContainer>
                            <FlexContainer justify="center">
                              <GridCardDescription>
                                {content.name}
                              </GridCardDescription>
                            </FlexContainer>
                          </GridCard>
                        ))}
                    </GridStructure>
                  </FlexContainer>
                </div>
                <div>
                  <FlexContainer direction="column" gap="10px">
                    <span>{`Roadmaps: (${
                      items.filter((itemF) => itemF.type === "roadmap").length
                    })`}</span>
                    <GridStructure
                      style={{ gap: "5px", minHeight: "94px" }}
                      width="120px"
                      maxWidth="900px"
                    >
                      {items
                        .filter((itemF) => itemF.type === "roadmap")
                        .map((content, key) => (
                          <GridCard
                            key={content._id}
                            style={{ cursor: "pointer", padding: "0px" }}
                          >
                            <TrashIcon style={{ right: "18px" }}>
                              <BsFillTrashFill
                                onClick={() => {
                                  setCurrentSection(content.type);
                                  setUnit({
                                    action: "delete-content-from-unit",
                                    data: {
                                      _id: content._id,
                                      name: content.name,
                                      units: content.units.filter(
                                        (unitF) => unitF !== unit._id
                                      ),
                                      externalFunction: true,
                                      action: () =>
                                        updateRoadmap({
                                          _id: content._id,
                                          body: {
                                            name: content.name,
                                            units: content.units.filter(
                                              (unitF) => unitF !== unit._id
                                            ),
                                            __v: content.__v,
                                          },
                                        }),
                                      resetAction: () => resetUpdateRoadmap(),
                                    },
                                  });
                                  dispatch(
                                    showModal(
                                      "confirm-delete-item-secuencial-modal"
                                    )
                                  );
                                }}
                                fontSize={16}
                              ></BsFillTrashFill>
                            </TrashIcon>
                            <FlexContainer justify="center">
                              <ImageContent>
                                <GridCardImage
                                  src={
                                    content.picture ||
                                    "https://upload.wikimedia.org/wikipedia/commons/8/81/Gallet_clamshell_600x600_6.jpg"
                                  }
                                ></GridCardImage>
                              </ImageContent>
                            </FlexContainer>
                            <FlexContainer justify="center">
                              <GridCardDescription>
                                {content.name}
                              </GridCardDescription>
                            </FlexContainer>
                          </GridCard>
                        ))}
                    </GridStructure>
                  </FlexContainer>
                </div>
                <div>
                  <FlexContainer direction="column" gap="10px">
                    <span>{`SlideShows: (${
                      items.filter((itemF) => itemF.type === "slideshow").length
                    })`}</span>
                    <GridStructure
                      style={{ gap: "5px", minHeight: "94px" }}
                      width="120px"
                      maxWidth="900px"
                    >
                      {items
                        .filter((itemF) => itemF.type === "slideshow")
                        .map((content, key) => (
                          <GridCard
                            key={content._id}
                            style={{ cursor: "pointer", padding: "0px" }}
                          >
                            <TrashIcon style={{ right: "18px" }}>
                              <BsFillTrashFill
                                onClick={() => {
                                  setCurrentSection(content.type);
                                  setUnit({
                                    action: "delete-content-from-unit",
                                    data: {
                                      _id: content._id,
                                      name: content.name,
                                      units: content.units.filter(
                                        (unitF) => unitF !== unit._id
                                      ),
                                      externalFunction: true,
                                      action: () =>
                                        updateSlideShow({
                                          _id: content._id,
                                          body: {
                                            name: content.name,
                                            units: content.units.filter(
                                              (unitF) => unitF !== unit._id
                                            ),
                                            __v: content.__v,
                                          },
                                        }),
                                      resetAction: () => resetUpdateSlideShow(),
                                    },
                                  });
                                  dispatch(
                                    showModal(
                                      "confirm-delete-item-secuencial-modal"
                                    )
                                  );
                                }}
                                fontSize={16}
                              ></BsFillTrashFill>
                            </TrashIcon>
                            <FlexContainer justify="center">
                              <ImageContent>
                                <GridCardImage
                                  src={
                                    content.picture ||
                                    "https://upload.wikimedia.org/wikipedia/commons/8/81/Gallet_clamshell_600x600_6.jpg"
                                  }
                                ></GridCardImage>
                              </ImageContent>
                            </FlexContainer>
                            <FlexContainer justify="center">
                              <GridCardDescription>
                                {content.name}
                              </GridCardDescription>
                            </FlexContainer>
                          </GridCard>
                        ))}
                    </GridStructure>
                  </FlexContainer>
                </div>
                <div>
                  <FlexContainer direction="column" gap="10px">
                    <span>{`Bytes: (${
                      items.filter((itemF) => itemF.type === "byte").length
                    })`}</span>
                    <GridStructure
                      style={{ gap: "5px", minHeight: "94px" }}
                      width="120px"
                      maxWidth="900px"
                    >
                      {items
                        .filter((itemF) => itemF.type === "byte")
                        .map((content, key) => (
                          <GridCard
                            key={content._id}
                            style={{ cursor: "pointer", padding: "0px" }}
                          >
                            <TrashIcon style={{ right: "18px" }}>
                              <BsFillTrashFill
                                onClick={() => {
                                  setCurrentSection(content.type);
                                  setUnit({
                                    action: "delete-content-from-unit",
                                    data: {
                                      _id: content._id,
                                      name: content.name,
                                      units: content.units.filter(
                                        (unitF) => unitF !== unit._id
                                      ),
                                      externalFunction: true,
                                      action: () =>
                                        updateVideo({
                                          _id: content._id,
                                          body: {
                                            name: content.name,
                                            units: content.units.filter(
                                              (unitF) => unitF !== unit._id
                                            ),
                                            __v: content.__v,
                                          },
                                        }),
                                      resetAction: () => resetUpdateVideo(),
                                    },
                                  });
                                  dispatch(
                                    showModal(
                                      "confirm-delete-item-secuencial-modal"
                                    )
                                  );
                                }}
                                fontSize={16}
                              ></BsFillTrashFill>
                            </TrashIcon>
                            <FlexContainer justify="center">
                              <ImageContent>
                                <GridCardImage
                                  src={
                                    content.picture ||
                                    "https://upload.wikimedia.org/wikipedia/commons/8/81/Gallet_clamshell_600x600_6.jpg"
                                  }
                                ></GridCardImage>
                              </ImageContent>
                            </FlexContainer>
                            <FlexContainer justify="center">
                              <GridCardDescription>
                                {content.name}
                              </GridCardDescription>
                            </FlexContainer>
                          </GridCard>
                        ))}
                    </GridStructure>
                  </FlexContainer>
                </div>
                <div>
                  <FlexContainer direction="column" gap="10px">
                    <span>{`Listens: (${
                      items.filter((itemF) => itemF.type === "listen").length
                    })`}</span>
                    <GridStructure
                      style={{ gap: "5px", minHeight: "94px" }}
                      width="120px"
                      maxWidth="900px"
                    >
                      {items
                        .filter((itemF) => itemF.type === "listen")
                        .map((content, key) => (
                          <GridCard
                            key={content._id}
                            style={{ cursor: "pointer", padding: "0px" }}
                          >
                            <TrashIcon style={{ right: "18px" }}>
                              <BsFillTrashFill
                                onClick={() => {
                                  setCurrentSection(content.type);
                                  setUnit({
                                    action: "delete-content-from-unit",
                                    data: {
                                      _id: content._id,
                                      name: content.name,
                                      units: content.units.filter(
                                        (unitF) => unitF !== unit._id
                                      ),
                                      externalFunction: true,
                                      action: () =>
                                        updateListen({
                                          _id: content._id,
                                          body: {
                                            name: content.name,
                                            units: content.units.filter(
                                              (unitF) => unitF !== unit._id
                                            ),
                                            __v: content.__v,
                                          },
                                        }),
                                      resetAction: () => resetUpdateListen(),
                                    },
                                  });
                                  dispatch(
                                    showModal(
                                      "confirm-delete-item-secuencial-modal"
                                    )
                                  );
                                }}
                                fontSize={16}
                              ></BsFillTrashFill>
                            </TrashIcon>
                            <FlexContainer justify="center">
                              <ImageContent>
                                <GridCardImage
                                  src={
                                    content.picture ||
                                    "https://upload.wikimedia.org/wikipedia/commons/8/81/Gallet_clamshell_600x600_6.jpg"
                                  }
                                ></GridCardImage>
                              </ImageContent>
                            </FlexContainer>
                            <FlexContainer justify="center">
                              <GridCardDescription>
                                {content.name}
                              </GridCardDescription>
                            </FlexContainer>
                          </GridCard>
                        ))}
                    </GridStructure>
                  </FlexContainer>
                </div>
              </FlexContainer>
            </AccordeonContent>
          )}
        </AccordeonContentWrapper>
      </AccordeonWrapper>
    </>
  );
};

export default Component;
