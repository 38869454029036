import styled from "styled-components";
import {
  fadeIn,
  fadeInSlideUp,
  fadeInSlideDown,
  fadeInSlideLeft,
  fadeInSlideRight,
  bounceIn,
  bounceInDown,
  bounceInLeft,
  bounceInRight,
  bounceInUp,
  bounceUpAndDown,
  zoomIn,
} from "./styles";

export const Animation = styled("div")<{
  name?: string;
  duration?: number;
  width?: number;
  styles?;
}>`
  ${(props) => {
    const { name, duration, width } = props;

    switch (name) {
      case "fadeIn":
        return fadeIn(duration);

      case "fadeInSlideDown":
        return fadeInSlideDown(duration);

      case "fadeInSlideUp":
        return fadeInSlideUp(duration);

      case "fadeInSlideLeft":
        return fadeInSlideLeft(duration);

      case "fadeInSlideRight":
        return fadeInSlideRight(duration, width);

      case "bounceIn":
        return bounceIn(duration);

      case "bounceInDown":
        return bounceInDown(duration);

      case "bounceInLeft":
        return bounceInLeft(duration);

      case "bounceInRight":
        return bounceInRight(duration);

      case "bounceInUp":
        return bounceInUp(duration);

      case "bounceUpAndDown":
        return bounceUpAndDown(duration);

      case "zoomIn":
        return zoomIn(duration);

      default:
        return fadeIn(duration);
    }
  }}
`;

export const AnimationFixed = styled(Animation)<{
  name?: string;
  duration?: number;
  width?: number;
  styles?;
  position?;
}>`
  position: fixed;
  width: 100%;
  max-width: ${(props) => (props.width ? props.width : "100%")};
  margin: 0 auto;
  top: 0;
  bottom: 0;
  ${(props) => (props.position === "left" ? "left: 0; right: auto;" : "")}
  ${(props) => (props.position === "right" ? "left: auto; right: 0;" : "")}
  ${(props) => (!props.position ? "left: 0; right: 0;" : "")}
  z-index: 1050;
  outline: 0;
  display: block;
  pointer-events: none;
`;

export const AnimationOnTop = styled(AnimationFixed)`
  z-index: 1051;
  top: auto;
  pointer-events: auto;
`;
