import styled from "styled-components";

export const VideoPreview = styled("div")`
  position: relative;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 0 2px 0 rgba(21, 115, 229, 0.16);
  height: ${(props) => props.height};
  & .react-player__preview {
    position: relative;
  }
  &:focus {
    outline: none;
    border: none;
  }
`;

export const SpinnerWrapper = styled("div")`
  position: absolute;
  width: 80px;
  height: 80px;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const LightWrapper = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
