import { Formik } from "formik";
import { Input } from "./style";
import { AiOutlineSearch } from "react-icons/ai";
interface ComponentProps {
  setFilter;
  items;
}

const Component = ({ setFilter, items }: ComponentProps) => {
  return (
    <Formik
      initialValues={{}}
      onSubmit={(values, actions) => {}}
      enableReinitialize
    >
      {({ handleSubmit }) => {
        return (
          <form
            className="theme-form"
            onSubmit={(event) => {
              handleSubmit(event);
            }}
            id="userid-filter-form"
          >
            <div style={{ position: "relative" }}>
              <AiOutlineSearch
                color="black"
                fontWeight={900}
                style={{
                  position: "absolute",
                  left: "10px",
                  top: "12.5px",
                  zIndex: 2,
                }}
              ></AiOutlineSearch>
              <Input
                name="userId"
                placeholder="Busca por nombre de contenido"
                onChange={(ev) => {
                  setFilter({
                    field: "name",
                    value: ev.target.value !== "" ? ev.target.value : "no",
                    special: (academy, value) => {
                      return academy.name.includes(value);
                    },
                  });
                }}
              />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default Component;
