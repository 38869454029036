import React from "react";
import { Link, LinkText, Links } from "../ListItems/style";
import { connect, useDispatch } from "react-redux";
import { setCurrent } from "../../../../store/actions/current";
import { signOut } from "../../../../store/actions/auth";
import { useNavigate } from "react-router-dom";

const Component = ({ user }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <Links>
      <Link>
        <LinkText
          onClick={() => {
            navigate("/dashboard/home");
            dispatch(
              setCurrent({
                currentSection: "personal",
                id: user._id,
                role: "USER",
              })
            );
          }}
        >
          Mi Perfil
        </LinkText>
      </Link>
      <Link>
        <LinkText
          onClick={() => {
            dispatch(signOut());
          }}
        >
          Cerrar Sesion
        </LinkText>
      </Link>
    </Links>
  );
};

const states = ({ userStore }) => {
  const { data: user } = userStore;
  return {
    user,
  };
};

export default connect(states)(Component);
