import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import { AuthContext } from "../../provider/AuthProvider";
import { Wrapper } from "./style";
import Loader from "../Loader";
import { connect } from "react-redux";
import { UserDto } from "../../types/user.dto";

const Component = ({
  children,
  user,
}: {
  children: JSX.Element;
  user: UserDto;
}) => {
  let { isAuthenticated } = React.useContext(AuthContext);
  let location = useLocation();
  const accessToken = (
    document.cookie.split(";").find((x) => x.includes("refreshToken")) || "="
  ).split("=")[1];
  if (!isAuthenticated && !accessToken) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/sign-in" replace state={{ from: location }} />;
  }
  return (
    <>
      {(!isAuthenticated && !!accessToken) || !user ? (
        <Wrapper>
          <Loader color="Primary" />
        </Wrapper>
      ) : (
        children
      )}
    </>
  );
};

const states = ({ userStore }) => {
  const { data: user } = userStore;
  return { user };
};

export default connect(states)(Component);
