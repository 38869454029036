import { TechSkillDto } from "../../../types/recruiting.dto";

export const GET_ALL_TECHSKILLS = "GET_ALL_TECHSKILLS";
export const GET_ALL_TECHSKILLS_ERROR = "GET_ALL_TECHSKILLS_ERROR";
export const GET_ALL_TECHSKILLS_SUCCESS = "GET_ALL_TECHSKILLS_SUCCESS";
export const GET_TECHSKILL = "GET_TECHSKILL";
export const GET_TECHSKILL_ERROR = "GET_TECHSKILL_ERROR";
export const GET_TECHSKILL_SUCCESS = "GET_TECHSKILL_SUCCESS";
export const NEW_TECHSKILL = "NEW_TECHSKILL";
export const NEW_TECHSKILL_ERROR = "NEW_TECHSKILL_ERROR";
export const NEW_TECHSKILL_SUCCESS = "NEW_TECHSKILL_SUCCESS";
export const RESET_NEW_TECHSKILL = "RESET_NEW_TECHSKILL";
export const UPDATE_TECHSKILL = "UPDATE_TECHSKILL";
export const UPDATE_TECHSKILL_ERROR = "UPDATE_TECHSKILL_ERROR";
export const UPDATE_TECHSKILL_SUCCESS = "UPDATE_TECHSKILL_SUCCESS";
export const RESET_UPDATE_TECHSKILL = "RESET_UPDATE_TECHSKILL";
export const DELETE_TECHSKILL = "DELETE_TECHSKILL";
export const DELETE_TECHSKILL_ERROR = "DELETE_TECHSKILL_ERROR";
export const DELETE_TECHSKILL_SUCCESS = "DELETE_TECHSKILL_SUCCESS";
export const RESET_DELETE_TECHSKILL = "RESET_DELETE_TECHSKILL";
export const SET_NEW_TECHSKILL_DATA = "SET_NEW_TECHSKILL_DATA";
export const SET_UPDATE_TECHSKILL_DATA = "SET_UPDATE_TECHSKILL_DATA";
export const UPDATE_CURRENT_TECHSKILL_LIST = "UPDATE_CURRENT_TECHSKILL_LIST";
export interface GetAllTechSkills {
  type: typeof GET_ALL_TECHSKILLS;
  payload: null;
}

export interface UpdateCurrentTechSkillsList {
  type: typeof UPDATE_CURRENT_TECHSKILL_LIST;
  payload: TechSkillDto[];
}
export interface GetAllTechSkillsSuccess {
  type: typeof GET_ALL_TECHSKILLS_SUCCESS;
  payload: TechSkillDto[];
}

export interface GetAllTechSkillsError {
  type: typeof GET_ALL_TECHSKILLS_ERROR;
  payload: boolean | string;
}

export interface SetNewTechSkillData {
  type: typeof SET_NEW_TECHSKILL_DATA;
  payload: TechSkillDto;
}
export interface SetUpdateTechSkillData {
  type: typeof SET_UPDATE_TECHSKILL_DATA;
  payload: TechSkillDto;
}

export interface GetTechSkill {
  type: typeof GET_TECHSKILL;
  payload: { _id: string };
}

export interface GetTechSkillSuccess {
  type: typeof GET_TECHSKILL_SUCCESS;
  payload: TechSkillDto;
}

export interface GetTechSkillError {
  type: typeof GET_TECHSKILL_ERROR;
  payload: boolean | string;
}

export interface NewTechSkill {
  type: typeof NEW_TECHSKILL;
  payload: TechSkillDto;
}

export interface NewTechSkillSuccess {
  type: typeof NEW_TECHSKILL_SUCCESS;
  payload: TechSkillDto;
}

export interface NewTechSkillError {
  type: typeof NEW_TECHSKILL_ERROR;
  payload: boolean | string;
}

export interface ResetNewTechSkill {
  type: typeof RESET_NEW_TECHSKILL;
  payload: null;
}

export interface UpdateTechSkill {
  type: typeof UPDATE_TECHSKILL;
  payload: TechSkillDto;
}

export interface UpdateTechSkillSuccess {
  type: typeof UPDATE_TECHSKILL_SUCCESS;
  payload: null;
}

export interface UpdateTechSkillError {
  type: typeof UPDATE_TECHSKILL_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateTechSkill {
  type: typeof RESET_UPDATE_TECHSKILL;
  payload: null;
}

export interface DeleteTechSkill {
  type: typeof DELETE_TECHSKILL;
  payload: { _id: string };
}

export interface DeleteTechSkillSuccess {
  type: typeof DELETE_TECHSKILL_SUCCESS;
  payload: null;
}

export interface DeleteTechSkillError {
  type: typeof DELETE_TECHSKILL_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteTechSkill {
  type: typeof RESET_DELETE_TECHSKILL;
  payload: null;
}

export interface State {
  all: {
    data: TechSkillDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  selected: {
    data: TechSkillDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  create: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  delete: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  update: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  all: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  selected: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  create: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  delete: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  update: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllTechSkills
  | GetAllTechSkillsSuccess
  | GetAllTechSkillsError
  | GetTechSkill
  | GetTechSkillSuccess
  | GetTechSkillError
  | NewTechSkill
  | NewTechSkillSuccess
  | NewTechSkillError
  | ResetNewTechSkill
  | UpdateTechSkill
  | UpdateTechSkillSuccess
  | UpdateTechSkillError
  | ResetUpdateTechSkill
  | DeleteTechSkill
  | DeleteTechSkillSuccess
  | DeleteTechSkillError
  | ResetDeleteTechSkill
  | SetNewTechSkillData
  | SetUpdateTechSkillData
  | UpdateCurrentTechSkillsList;
