import {
  initialState,
  Actions,
  State,
  GET_ALL_MENTORS,
  GET_ALL_MENTORS_ERROR,
  GET_ALL_MENTORS_SUCCESS,
  GET_MENTOR,
  GET_MENTOR_ERROR,
  GET_MENTOR_SUCCESS,
  UPDATE_MENTOR,
  UPDATE_MENTOR_ERROR,
  UPDATE_MENTOR_SUCCESS,
  DELETE_MENTOR,
  DELETE_MENTOR_ERROR,
  DELETE_MENTOR_SUCCESS,
  NEW_MENTOR,
  NEW_MENTOR_SUCCESS,
  NEW_MENTOR_ERROR,
  RESET_NEW_MENTOR,
  RESET_DELETE_MENTOR,
  RESET_UPDATE_MENTOR,
} from "../types/mentor";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_MENTORS:
      return {
        ...state,
        allMentors: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_MENTORS_SUCCESS:
      return {
        ...state,
        allMentors: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_MENTORS_ERROR:
      return {
        ...state,
        allMentors: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case GET_MENTOR:
      return {
        ...state,
        mentor: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_MENTOR_SUCCESS:
      return {
        ...state,
        mentor: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_MENTOR_ERROR:
      return {
        ...state,
        mentor: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case NEW_MENTOR:
      return {
        ...state,
        newMentor: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_MENTOR_SUCCESS:
      return {
        ...state,
        newMentor: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case NEW_MENTOR_ERROR:
      return {
        ...state,
        newMentor: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_MENTOR:
      return {
        ...state,
        newMentor: initialState.newMentor,
      };
    case DELETE_MENTOR:
      return {
        ...state,
        deleteMentor: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_MENTOR_SUCCESS:
      return {
        ...state,
        deleteMentor: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_MENTOR_ERROR:
      return {
        ...state,
        deleteMentor: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_MENTOR:
      return {
        ...state,
        deleteMentor: initialState.deleteMentor,
      };
    case UPDATE_MENTOR:
      return {
        ...state,
        updateMentor: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_MENTOR_SUCCESS:
      return {
        ...state,
        updateMentor: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_MENTOR_ERROR:
      return {
        ...state,
        updateMentor: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_MENTOR:
      return {
        ...state,
        updateMentor: initialState.updateMentor,
      };
    default:
      return state;
  }
};

export default reducers;
