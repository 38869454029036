import { put, call, takeLatest, select } from "redux-saga/effects";
import services from "../../../services";
import {
  NEW_TECHNOLOGY,
  NEW_TECHNOLOGY_ERROR,
  NEW_TECHNOLOGY_SUCCESS,
  NewTechnology,
  GET_ALL_TECHNOLOGIES,
  GET_ALL_TECHNOLOGIES_ERROR,
  GET_ALL_TECHNOLOGIES_SUCCESS,
  GetAllTechnologies,
  UPDATE_TECHNOLOGY,
  UPDATE_TECHNOLOGY_ERROR,
  UPDATE_TECHNOLOGY_SUCCESS,
  UpdateTechnology,
  DELETE_TECHNOLOGY,
  UPDATE_CURRENT_TECHNOLOGY_LIST,
  DELETE_TECHNOLOGY_ERROR,
  DELETE_TECHNOLOGY_SUCCESS,
} from "../../types/recruiting/technology";

const techList = ({ recruitingStore }) => recruitingStore.technology.all.data;

function* newTechnology({ payload }) {
  const { response, error } = yield call(
    services.recruiting.technology.new,
    payload
  );

  if (error) {
    yield put({
      type: NEW_TECHNOLOGY_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    const currentListOfTechs = yield select(techList);
    const addNewTechToList = [...currentListOfTechs, response];
    yield put({
      type: UPDATE_CURRENT_TECHNOLOGY_LIST,
      payload: addNewTechToList,
    });
    yield put({ type: NEW_TECHNOLOGY_SUCCESS, payload: response });
  }
}

function* deleteTechnology({ payload }) {
  const { response, error } = yield call(
    services.recruiting.technology.delete,
    payload
  );

  if (error) {
    yield put({
      type: DELETE_TECHNOLOGY_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    const currentListOfTechs = yield select(techList);
    const updatedListOfTechs = currentListOfTechs?.filter(
      (tech) => tech._id !== payload._id
    );

    yield put({
      type: UPDATE_CURRENT_TECHNOLOGY_LIST,
      payload: updatedListOfTechs,
    });
    yield put({ type: DELETE_TECHNOLOGY_SUCCESS, payload: response });
  }
}

function* getAllTechnologies() {
  const { response, error } = yield call(services.recruiting.technology.getAll);
  if (error) {
    yield put({
      type: GET_ALL_TECHNOLOGIES_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_TECHNOLOGIES_SUCCESS, payload: response });
  }
}

function* updatedTechnology({ payload }) {
  const { response, error } = yield call(
    services.recruiting.technology.update,
    payload
  );
  if (error) {
    yield put({
      type: UPDATE_TECHNOLOGY_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    const currentListOfTechs = yield select(techList);
    const updatedListOfTechs = currentListOfTechs?.data?.map((tech) => {
      if (tech._id === payload._id) {
        return { ...tech, ...payload };
      }
      return tech;
    });
    yield put({
      type: UPDATE_CURRENT_TECHNOLOGY_LIST,
      payload: updatedListOfTechs,
    });
    yield put({ type: UPDATE_TECHNOLOGY_SUCCESS, payload: null });
    yield put({ type: GET_ALL_TECHNOLOGIES, payload: null });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<NewTechnology>(NEW_TECHNOLOGY, newTechnology);
  yield takeLatest<GetAllTechnologies>(
    GET_ALL_TECHNOLOGIES,
    getAllTechnologies
  );
  yield takeLatest<UpdateTechnology>(UPDATE_TECHNOLOGY, updatedTechnology);
  yield takeLatest<UpdateTechnology>(DELETE_TECHNOLOGY, deleteTechnology);
}
