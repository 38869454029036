import {
  initialState,
  Actions,
  State,
  GET_ALL_IMAGES,
  GET_ALL_IMAGES_ERROR,
  GET_ALL_IMAGES_SUCCESS,
  GET_IMAGE,
  GET_IMAGE_ERROR,
  GET_IMAGE_SUCCESS,
  NEW_IMAGE,
  NEW_IMAGE_ERROR,
  NEW_IMAGE_SUCCESS,
  RESET_NEW_IMAGE,
  UPDATE_IMAGE,
  UPDATE_IMAGE_ERROR,
  UPDATE_IMAGE_SUCCESS,
  RESET_UPDATE_IMAGE,
  DELETE_IMAGE,
  DELETE_IMAGE_ERROR,
  DELETE_IMAGE_SUCCESS,
  RESET_DELETE_IMAGE,
  UPLOAD_MEDIA,
  UPDATE_IMAGE_LOADING,
  RESET_GET_IMAGE,
  ADD_USER_TO_IMAGE,
  SHARED_IMAGE,
  SET_GET_IMAGE,
  RESET_UPDATE_MANY_IMAGE,
  UPDATE_MANY_IMAGE,
  UPDATE_MANY_IMAGE_ERROR,
  UPDATE_MANY_IMAGE_SUCCESS,
  RESET_GET_ALL_IMAGE_DATA,
} from "../types/image";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_IMAGES:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_IMAGES_SUCCESS:
      return {
        ...state,
        all: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_IMAGES_ERROR:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_GET_ALL_IMAGE_DATA:
      return {
        ...state,
        all: initialState.all,
      };
    case UPLOAD_MEDIA:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };

    case ADD_USER_TO_IMAGE:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case SHARED_IMAGE:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_IMAGE_LOADING:
      return {
        ...state,
        new: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_IMAGE:
      return {
        ...state,
        image: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_IMAGE_SUCCESS:
      return {
        ...state,
        image: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_IMAGE_ERROR:
      return {
        ...state,
        image: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };

    case UPDATE_MANY_IMAGE:
      return {
        ...state,
        updateMany: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_MANY_IMAGE_SUCCESS:
      return {
        ...state,
        updateMany: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_MANY_IMAGE_ERROR:
      return {
        ...state,
        updateMany: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_MANY_IMAGE:
      return {
        ...state,
        updateMany: initialState.updateMany,
      };
    case NEW_IMAGE:
      return {
        ...state,
        new: {
          ...state.new,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_IMAGE_SUCCESS:
      return {
        ...state,
        new: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case SET_GET_IMAGE:
      return {
        ...state,
        image: {
          ...state.image,
          data: !!action.payload ? action.payload : null,
        },
      };
    case RESET_GET_IMAGE:
      return {
        ...state,
        image: {
          ...state.image,
          states: {
            success: false,
            loading: false,
            error: false,
          },
        },
      };
    case NEW_IMAGE_ERROR:
      return {
        ...state,
        new: {
          ...state.new,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_IMAGE:
      return {
        ...state,
        new: {
          data: state.new.data,
          states: {
            loading: false,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_IMAGE:
      return {
        ...state,
        delete: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_IMAGE_SUCCESS:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_IMAGE_ERROR:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_IMAGE:
      return {
        ...state,
        delete: initialState.delete,
      };
    case UPDATE_IMAGE:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_IMAGE_SUCCESS:
      return {
        ...state,
        update: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_IMAGE_ERROR:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_IMAGE:
      return {
        ...state,
        update: initialState.update,
      };
    default:
      return state;
  }
};

export default reducers;
