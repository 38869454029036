import React, { ReactElement } from "react";
import rules from "./permissions";

interface CheckProps {
  section: string;
  rules: typeof rules;
  role:
    | "ADMIN"
    | "PM"
    | "PROFESSOR"
    | "OBSERVER"
    | "STUDENT"
    | "MEMBER"
    | "USER";
  action: string;
  data: any;
}

interface CheckPermissionProps {
  section: string;
  role:
    | "ADMIN"
    | "PM"
    | "PROFESSOR"
    | "OBSERVER"
    | "STUDENT"
    | "MEMBER"
    | "USER";
  action: string;
  data?: any;
}

interface CheckerProps {
  section: string;
  role:
    | "ADMIN"
    | "PM"
    | "PROFESSOR"
    | "OBSERVER"
    | "STUDENT"
    | "MEMBER"
    | "USER";
  action: string;
  data?: any;
  yes: () => ReactElement;
  no: () => ReactElement;
}

const check = ({
  rules,
  role = "USER",
  action,
  data,
  section = "personal",
}: CheckProps) => {
  /* le paso la seccion actual */
  const permissions = rules[section][role];
  // role is not present in the rules
  if (!permissions) {
    return false;
  }
  const staticPermissions = permissions.static;

  // static rule not provided for action
  if (staticPermissions && staticPermissions.includes(action)) {
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

export const checkPermission = ({
  role,
  action,
  data,
  section,
}: CheckPermissionProps) => {
  return check({ rules, role, action, data, section });
};

const PermissionsChecker = ({
  role,
  action,
  data,
  section,
  yes = () => <></>,
  no = () => <></>,
}: CheckerProps) => {
  return check({ rules, role, action, data, section }) ? yes() : no();
};

export default PermissionsChecker;
