import action from "../helpers/rest-client";

export class ErrorCriterion extends Error {}

class Analytics {
  public getOne(payload) {
    let castedArray = "";
    if (payload.cohorts && payload.cohorts.length > 0) {
      for (let i = 0; i < payload.cohorts.length; i++) {
        castedArray += `cohorts=${payload.cohorts[i]}&`;
      }
    }
    return action
      .Get({
        url: `/analytics/student/${payload._id}?${
          castedArray.length > 0 && castedArray
        }`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getAcademySuscriptionStats({ _id, period }) {
    return action
      .Get({
        url: `/analytics/academy/${_id}/payments`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public getCohortSuscriptionStats({ _id, period }) {
    return action
      .Get({
        url: `/analytics/cohort/${_id}/payments`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public getOneCohort(payload) {
    return action
      .Get({
        url: `/analytics/cohort/${payload}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public getOneCohortUserTime(payload) {
    return action
      .Get({
        url: `analytics/user-times/cohort?cohortId=${payload.cohortId}&topCohortContents=${payload.topCohortContents}&topUnitsContents=${payload.topUnitsContents}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public getOneAcademyUserTime(payload) {
    return action
      .Get({
        url: `analytics/user-times/academy?academyId=${payload.academyId}&topContents=${payload.topContents}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public getStudentsStats() {
    return action
      .Get({
        url: `/analytics/allstudents`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public getAcademyStats({ _id }) {
    return action
      .Get({
        url: `/analytics/academy/${_id}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public getOrganizationStats({ _id }) {
    return action
      .Get({
        url: `/analytics/organization/${_id}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const analytics = new Analytics();

export default analytics;
