import { put, call, takeLatest, select } from "redux-saga/effects";
import services from "../../../services";
import {
  GetAit,
  GET_AIT,
  UpdateAit,
  GET_AIT_ERROR,
  GET_AIT_SUCCESS,
  UPDATE_AIT,
  UPDATE_AIT_ERROR,
  UPDATE_AIT_SUCCESS,
  UPDATE_AIT_TABLE,
  SET_AIT_LIST,
} from "../../types/recruiting/ait";

const aitList = ({ recruitingStore }) => recruitingStore.ait.all.data;

function* getAitTable({ payload }) {
  const { response, error } = yield call(
    services.recruiting.ait.getOne,
    payload
  );

  if (error) {
    yield put({
      type: GET_AIT_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_AIT_SUCCESS, payload: response });
  }
}
function* updateAitTable({ payload }) {
  const { response, error } = yield call(
    services.recruiting.ait.update,
    payload
  );
  if (error) {
    yield put({
      type: UPDATE_AIT_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    const currentListOfComapnies = yield select(aitList);
    const newListofComapnies = currentListOfComapnies?.map((company) => {
      if (company._id === payload._id) {
        return response;
      }
      return company;
    });
    yield put({ type: SET_AIT_LIST, payload: newListofComapnies });
    yield put({ type: UPDATE_AIT_SUCCESS, payload: null });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAit>(GET_AIT, getAitTable);
  yield takeLatest<UpdateAit>(UPDATE_AIT, updateAitTable);
}
