import { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import ReactSelect from "../../../../../components/Form/ReactSelect";
import Grid from "../../../../../components/Grid";
import Modal from "../../../../../components/Modal";
import {
  Center,
  FlexContainer,
} from "../../../../../components/StyledComponents";
import Button from "../../../../../components/Button";
import Submit from "../../../../../components/Form/Submit";
import talentActions from "../../../../../store/actions/recruiting/ait-talent";
import {
  schema,
  initialValues,
} from "../../../../../constants/form/academy/observer/add-talent";
import { EntityTitleSemiBold } from "../../../Talents/style";
import DinamicField from "../../../../../components/Form/DinamicField";
const Component = ({
  selectedAcademy,
  createStates,
  current,
  talent,
  teams,
  updateStates,
  organizations,
  user,
  aitTalents,
  academies,
}) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const dispatch = useDispatch();

  const onSubmit = ({ values, actions }) => {
    const { _id, userId, ...restTalent } = talent;

    for (let i = 0; i <= values.teams.length - 1; i++) {
      dispatch(
        talentActions.create({
          ...restTalent,
          talent: _id,
          cohorts: talent.cohorts.map((cohort) => cohort._id),
          external: false,
          team: values.teams[i],
          ...(current.currentSection === "academy" && {
            currentAcademy: current.id,
          }),
        })
      );
    }
    if (!!values.addOwn) {
      dispatch(
        talentActions.create({
          ...restTalent,
          talent: _id,
          cohorts: talent.cohorts.map((cohort) => cohort._id),
          external: false,
          user: user._id,
          ...(current.currentSection === "academy" && {
            currentAcademy: current.id,
          }),
        })
      );
    }
    setFormActions(actions);
  };

  return (
    <Modal name="invite-team-academy-modal" title="Agregar a Equipo">
      <Formik
        initialValues={{
          ...initialValues,
        }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="invite-team-modal-form"
            >
              <Grid.Row>
                <FlexContainer
                  align="center"
                  justify="space-between"
                  style={{
                    marginBottom: "20px",
                    width: "100%",
                    padding: "0 10px  ",
                  }}
                >
                  <EntityTitleSemiBold>
                    Agregar a tablero personal
                  </EntityTitleSemiBold>
                  <input
                    style={{ height: "17px", width: "17px" }}
                    name={"addOwn"}
                    checked={!!values["addOwn"]}
                    onChange={() => {
                      setFieldValue("addOwn", !values["addOwn"]);
                    }}
                    type="checkbox"
                  />
                </FlexContainer>

                <ReactSelect
                  name="organization"
                  error={errors["organization"]}
                  touched={touched["organization"]}
                  {...(!!organizations && {
                    items: organizations
                      ?.filter((organization) =>
                        academies
                          .find((academy) => academy._id === current.id)
                          .associatedOrgs.includes(organization._id)
                      )
                      .map((organization) => ({
                        label: organization.name,
                        value: organization._id,
                      })),
                  })}
                  placeholder="Seleccione Organizaciones"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={{
                    skin: "gray",
                    label: "Selecciona Organizaciones",
                    marginBottom: 20,
                  }}
                />
                <DinamicField
                  name="teams"
                  error={errors["teams"]}
                  touched={touched["teams"]}
                  value={values["teams"]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={{
                    skin: "gray",
                    type: "select",
                    height: "95px",
                    ...(!!organizations &&
                      !!teams && {
                        data: teams
                          .filter(
                            (team) =>
                              team.organization === values["organization"]
                          )
                          .map((team) => {
                            return {
                              label: team.name,
                              value: team._id,
                            };
                          }),
                      }),

                    loading: !teams,
                    inputLabel: "Selecciona Equipos",
                    inputPlaceholder: "Selecciona un equipo",
                  }}
                />
              </Grid.Row>

              <Grid.Row>
                <Grid.Col>
                  <Center gap={"70px"}>
                    <Button
                      onClick={resetForm}
                      type="button"
                      options={{
                        type: "outline",
                        skin: "gray",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                      style={{ marginLeft: "10px" }}
                    >
                      Cancelar
                    </Button>

                    <Submit
                      isSubmmiting={updateStates.loading}
                      form="invite-team-modal-form"
                      color="Primary"
                      options={{
                        type: "filled",
                        skin: "primary",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                    >
                      Confirmar
                    </Submit>
                  </Center>
                </Grid.Col>
              </Grid.Row>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};
/* Cada vez que paso de entidad tengo que borrar el historial */
const states = ({
  invitationStore,
  academyStore,
  currentStore,
  teamStore,
  recruitingStore,
  organizationStore,
  userStore,
}) => {
  const { data: selectedAcademy } = academyStore.selected;
  const { states: createStates } = invitationStore.create;
  const { data: academies } = academyStore.all;
  const { data: teams } = teamStore.all;
  const { data: organizations } = organizationStore.all;
  const { data: current } = currentStore;
  const { states: updateStates } = recruitingStore.talent.create;
  const { data: aitTalents } = recruitingStore.talent.all;
  const { data: user } = userStore;
  return {
    selectedAcademy,
    createStates,
    teams,
    organizations,
    current,
    aitTalents,
    updateStates,
    user,
    academies,
  };
};

export default connect(states)(Component);
