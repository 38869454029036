import {
  GetAllUnits,
  GET_ALL_UNITS,
  GetUnit,
  GET_UNIT,
  UpdateUnit,
  UPDATE_UNIT,
  DeleteUnit,
  DELETE_UNIT,
  GET_UNIT_SUCCESS,
  GetUnitSuccess,
  NewUnit,
  NEW_UNIT,
  ResetNewUnit,
  RESET_NEW_UNIT,
  ResetDeleteUnit,
  RESET_DELETE_UNIT,
  ResetUpdateUnit,
  RESET_UPDATE_UNIT,
  DELETE_UNIT_MEMBER,
  DeleteUnitMember,
  RESET_GET_ALL_UNITS,
} from "../types/unit";

export const getAllUnits = (payload): GetAllUnits => {
  return { type: GET_ALL_UNITS, payload };
};

export const resetGetAllUnits = () => {
  return { type: RESET_GET_ALL_UNITS, payload: null };
};

export const getUnit = (id): GetUnit => {
  return { type: GET_UNIT, payload: id };
};

export const setSelectedUnit = (payload): GetUnitSuccess => {
  return { type: GET_UNIT_SUCCESS, payload };
};

export const deleteMember = (payload): DeleteUnitMember => {
  return { type: DELETE_UNIT_MEMBER, payload };
};

export const updateUnit = (payload): UpdateUnit => {
  return { type: UPDATE_UNIT, payload };
};

export const resetUpdateUnit = (): ResetUpdateUnit => {
  return { type: RESET_UPDATE_UNIT, payload: null };
};

export const newUnit = (payload): NewUnit => {
  return { type: NEW_UNIT, payload };
};

export const resetNewUnit = (): ResetNewUnit => {
  return { type: RESET_NEW_UNIT, payload: null };
};

export const deleteUnit = (id): DeleteUnit => {
  return { type: DELETE_UNIT, payload: id };
};

export const resetDeleteUnit = (): ResetDeleteUnit => {
  return { type: RESET_DELETE_UNIT, payload: null };
};
