import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Modal from "../../../../../../components/Modal";
import { Field, Formik } from "formik";
import {
  Center,
  FlexContainer,
} from "../../../../../../components/StyledComponents";
import {
  initialValues,
  schema,
} from "../../../../../../constants/form/slideshow/public-slideshow";
import Grid from "../../../../../../components/Grid";
import Button from "../../../../../../components/Button";
import Submit from "../../../../../../components/Form/Submit";
import { toast } from "react-toastify";
import { hideModal } from "../../../../../../store/actions/modal";
import { AcademyDto } from "../../../../../../types/academy.dto";
import { CurrentDto } from "../../../../../../types/current.dto";
import DinamicField from "../../../../../../components/Form/DinamicField";
import { LetterModal } from "./style";
import { CohortDto } from "../../../../../../types/cohort.dto";
import { UnitDto } from "../../../../../../types/unit.dto";
import { StateDto } from "../../../../../../types/states.dto";
import { UserDto } from "../../../../../../types/user.dto";
import { setFormData } from "../../../../../../helpers/formData";
import {
  newSlideShow,
  updateSlideShow,
} from "../../../../../../store/actions/slideshow";
import DinamicLinked from "../../../../../../components/Form/DinamicLinked";
import Loader from "../../../../../../components/Loader";

const Component = ({
  academies,
  cohorts,
  current,
  units,
  updateStates,
  user,
  slideshowDataChanged,
  imageApi,
  currentImage,
  slideshow,
  createStates,
  currentPdf,
}: {
  academies: AcademyDto[];
  cohorts: CohortDto[];
  current: CurrentDto;
  units: UnitDto[];
  updateStates: StateDto;
  user: UserDto;
  slideshowDataChanged;
  imageApi;
  currentImage;
  slideshow;
  createStates;
  currentPdf;
}) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const [activeAcademy, setActiveAcademy] = useState<any>();
  const dispatch = useDispatch();
  const onSubmit = ({ values, actions }) => {
    if (!slideshow) {
      if (!currentPdf || !currentImage) {
        toast.error("Se necesita presentacion y imagen");
        return null;
      }
      dispatch(
        newSlideShow(
          setFormData(
            {
              name: values.name,
              cohorts: values.cohorts.map((cohort) =>
                !!cohort.value ? cohort.value : cohort
              ),
              description: values.description,
              ...(!!currentImage && { picture: currentImage.file }),
              ...(!!currentPdf && { slides: currentPdf.file }),

              units: values.units.map((unit) =>
                !!unit.value ? unit.value : unit
              ),
              academy: current.id,
              type: "SLIDESHOW",

              published: true,
              allCohorts: values.allCohorts,
              available: values.available,
            },
            ["picture", "slides"]
          )
        )
      );
    } else {
      if (!slideshow.picture && !currentImage) {
        toast.error("Se necesita imagen");
        return null;
      }
      dispatch(
        updateSlideShow({
          _id: slideshow._id,
          body: setFormData(
            {
              name: values.name,
              cohorts: values.cohorts.map((cohort) =>
                !!cohort.value ? cohort.value : cohort
              ),
              description: values.description,
              units: values.units.map((unit) =>
                !!unit.value ? unit.value : unit
              ),
              ...(!!currentImage && { picture: currentImage.file }),
              published: true,
              allCohorts: values.allCohorts,
              available: values.available,
              __v: slideshow.__v,
            },
            ["picture"]
          ),
        })
      );
    }

    setFormActions(actions);
  };
  useEffect(() => {
    if (updateStates.success) {
      setTimeout(() => {
        dispatch(hideModal());
        formActions?.resetForm();
      }, 1000);
    }
  }, [updateStates]);

  useEffect(() => {
    if (createStates.success) {
      setTimeout(() => {
        dispatch(hideModal());
        formActions?.resetForm();
      }, 1000);
    }
  }, [createStates]);
  return (
    <Modal name="publish-slideshow-modal" title={`Publicar Contenido`}>
      {!units ? (
        <Loader color="Primary"></Loader>
      ) : (
        <Formik
          initialValues={{
            ...initialValues({
              name: slideshowDataChanged.name,
              description: slideshowDataChanged.description,
              ...(!!slideshow && {
                available: slideshow.available,
                cohorts: slideshow.cohorts,
                units: slideshow.units.map((unit) => {
                  const unitFounded = units.find(
                    (unitFounded) => unitFounded._id === unit
                  );
                  return {
                    label: unitFounded?.name,
                    value: unitFounded?._id,
                  };
                }),
                allCohorts: slideshow.allCohorts,
              }),
            }),
          }}
          onSubmit={(values, actions) => {
            if (onSubmit) onSubmit({ values, actions });
          }}
          validateOnMount={true}
          validateOnChange={formSubmmited}
          validateOnBlur={false}
          validationSchema={schema}
          enableReinitialize
        >
          {({
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
            setFieldValue,
            isSubmitting,
          }) => {
            return (
              <form
                className="theme-form"
                onSubmit={(event) => {
                  Object.entries(errors).forEach((item) => {
                    toast.error(item[1] as string);
                  });
                  setFormSubmmited(true);
                  handleSubmit(event);
                }}
                id="publish-form-slideshow"
              >
                <Grid.Row style={{ marginBottom: "20px" }}>
                  <Grid.Col>
                    <FlexContainer
                      justify="space-between"
                      style={{ padding: "0px 0px 0px 20px" }}
                    >
                      <FlexContainer
                        direction="column"
                        gap="2px"
                        style={{ maxWidth: "300px" }}
                      >
                        <FlexContainer align="center" gap="1px">
                          <LetterModal lg>Publicación General</LetterModal>
                          <LetterModal lg gray>
                            (Recomendado):
                          </LetterModal>
                        </FlexContainer>
                        <LetterModal gray>
                          El contenido se publicara para todo el publico de
                          Constana Campus, dándole visibilidad a tu Academia y
                          contenidos a nuevos usuarios.
                        </LetterModal>
                      </FlexContainer>
                      <Field
                        style={{
                          width: "20px",
                          height: "20px",
                          boxShadow: "0 0 4px 0 rgba(21, 115, 229, 0.16)",
                        }}
                        type="checkbox"
                        name="available"
                      />
                    </FlexContainer>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row style={{ marginBottom: "20px" }}>
                  <Grid.Col>
                    <FlexContainer
                      justify="space-between"
                      style={{ padding: "0px 0px 0px 20px" }}
                    >
                      <FlexContainer
                        direction="column"
                        gap="2px"
                        style={{ maxWidth: "300px" }}
                      >
                        {" "}
                        <FlexContainer align="center" gap="1px">
                          <LetterModal lg>Compartir entre Cohorts</LetterModal>
                        </FlexContainer>
                        <LetterModal gray>
                          Mostrar a todos los cohorts de la academia
                        </LetterModal>
                      </FlexContainer>
                      <Field
                        style={{
                          width: "20px",
                          height: "20px",
                          boxShadow: "0 0 4px 0 rgba(21, 115, 229, 0.16)",
                        }}
                        type="checkbox"
                        name="allCohorts"
                      />
                    </FlexContainer>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col>
                    <DinamicField
                      name="cohorts"
                      error={errors["cohorts"]}
                      touched={touched["cohorts"]}
                      value={values["cohorts"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={{
                        itemOnClick: {
                          functionItem: (value) =>
                            setActiveAcademy((state) =>
                              state === value ? undefined : value
                            ),
                          value: activeAcademy,
                        },
                        onDelete: (item, index) => {
                          setFieldValue(
                            "units",
                            values["units"].filter((val) => val.linked !== item)
                          );
                        },
                        skin: "gray",
                        type: "select",
                        height: "95px",
                        ...(!!cohorts && {
                          data: cohorts
                            .filter(
                              (cohort) =>
                                cohort.active &&
                                cohort.academy._id === current.id
                            )
                            .map((cohort) => {
                              return { value: cohort._id, label: cohort.name };
                            }),
                        }),
                        loading: !cohorts,
                        inputLabel: "Seleccionar Cohorts (Opcional)",
                        inputPlaceholder: "Selecciona un Cohort",
                      }}
                    />
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col>
                    <DinamicLinked
                      name="units"
                      error={errors["units"]}
                      touched={touched["units"]}
                      value={values["units"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={{
                        notLoading: true,
                        skin: "gray",
                        type: "select",
                        height: "95px",
                        ...(!!units &&
                          !!activeAcademy && {
                            data: units
                              .filter(
                                (unit) =>
                                  unit.active &&
                                  unit.cohort.includes(activeAcademy)
                              )
                              .map((cohort) => {
                                return {
                                  linked: activeAcademy,
                                  value: cohort._id,
                                  label: cohort.name,
                                };
                              }),
                          }),
                        externalData: true,
                        loading: !units,
                        inputLabel:
                          "Seleccionar Módulos del Cohort Seleccionado",
                        inputPlaceholder: "Selecciona un Modulo",
                      }}
                    ></DinamicLinked>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col>
                    <Center gap={"10px"}>
                      <Submit
                        isSubmmiting={
                          !!slideshow
                            ? updateStates.loading
                            : createStates.loading
                        }
                        form="publish-form-slideshow"
                        color="Primary"
                        style={{ width: "170px" }}
                        options={{
                          type: "filled",
                          skin: "primary",
                          size: "lg",
                          marginBottom: "0px",
                        }}
                      >
                        Confirmar
                      </Submit>
                      <Button
                        onClick={resetForm}
                        type="button"
                        options={{
                          type: "outline",
                          skin: "danger",
                          size: "lg",
                          marginBottom: "0px",
                        }}
                        style={{ marginLeft: "10px", width: "170px" }}
                      >
                        Cancelar
                      </Button>
                    </Center>
                  </Grid.Col>
                </Grid.Row>
              </form>
            );
          }}
        </Formik>
      )}
    </Modal>
  );
};

const states = ({
  academyStore,
  cohortStore,
  currentStore,
  unitStore,
  userStore,
  slideshowStore,
}) => {
  const { data: academies } = academyStore.all;
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: current } = currentStore;
  const { data: units } = unitStore.allUnits;
  const { states: updateStates } = slideshowStore.update;
  const { states: createStates } = slideshowStore.new;
  const { data: user } = userStore;
  return {
    academies,
    cohorts,
    current,
    units,
    updateStates,
    user,
    createStates,
  };
};

export default connect(states)(Component);
