import { put, call, all, takeLatest, select } from "redux-saga/effects";
import services from "../../services";
import {
  GET_BYTE,
  GET_BYTE_SUCCESS,
  GET_BYTE_ERROR,
  GET_ALL_BYTES,
  GET_ALL_BYTES_SUCCESS,
  GET_ALL_BYTES_ERROR,
  NEW_BYTE,
  NEW_BYTE_ERROR,
  NEW_BYTE_SUCCESS,
  NewByte,
  UPDATE_BYTE,
  UPDATE_BYTE_SUCCESS,
  UPDATE_BYTE_ERROR,
  DELETE_BYTE,
  DELETE_BYTE_SUCCESS,
  DELETE_BYTE_ERROR,
  GetByte,
  GetAllBytes,
  UpdateByte,
  DeleteByte,
  UploadMedia,
  UPLOAD_MEDIA,
  AddUserToByte,
  ADD_USER_TO_BYTE,
  SharedByte,
  SHARED_BYTE,
  UPDATE_MANY_BYTE,
  UPDATE_MANY_BYTE_ERROR,
  UPDATE_MANY_BYTE_SUCCESS,
  UpdateManyByte,
} from "../types/byte";
import { ByteDto } from "../../types/byte.dto";

function* updateManyByte({ payload }) {
  let updatedList: ByteDto[] = [];

  try {
    for (let i = 0; i < payload.items.length; i++) {
      const { _id, ...body } = payload.items[i];
      const formDataInsteadBody = !!body.body ? body.body : body;
      const { response, error } = yield call(services.byte.update, {
        _id,
        body: formDataInsteadBody,
      });
      if (!!response) {
        updatedList = [...updatedList, response];
      }
    }

    yield put({ type: UPDATE_MANY_BYTE_SUCCESS, payload: updatedList });
  } catch (err) {
    yield put({ type: UPDATE_MANY_BYTE_ERROR, payload: err });
  }
}

function* getAllBytes({ payload }) {
  const { response, error } = yield call(services.byte.getAll, payload);

  if (error) {
    yield put({
      type: GET_ALL_BYTES_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_BYTES_SUCCESS, payload: response });
  }
}

function* getByte({ payload }) {
  const { response, error } = yield call(services.byte.getOne, payload);

  if (error) {
    yield put({
      type: GET_BYTE_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_BYTE_SUCCESS, payload: response });
  }
}

function* newByte({ payload }) {
  const { response, error } = yield call(services.byte.new, payload);

  if (error) {
    yield put({
      type: NEW_BYTE_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: NEW_BYTE_SUCCESS, payload: response })]);
  }
}

function* updateByte({ payload }) {
  const { response, error } = yield call(services.byte.update, payload);
  if (error) {
    yield put({
      type: UPDATE_BYTE_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_BYTE_SUCCESS, payload: response })]);
  }
}
function* addUserToByte({ payload }) {
  const { response, error } = yield call(services.byte.addUserByte, payload);

  if (error) {
    yield put({
      type: UPDATE_BYTE_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_BYTE_SUCCESS, payload: response })]);
  }
}

function* uploadMedia({ payload }) {
  const { response, error } = yield call(services.byte.update, payload);

  if (error) {
    yield put({
      type: UPDATE_BYTE_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_BYTE_SUCCESS, payload: response })]);
  }
}

function* deleteByte({ payload }) {
  const { response, error } = yield call(services.byte.delete, payload);

  if (error) {
    yield put({
      type: DELETE_BYTE_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: DELETE_BYTE_SUCCESS, payload: response })]);
  }
}

function* shareByte({ payload }) {
  const { response, error } = yield call(services.byte.shareByte, payload);

  if (error) {
    yield put({
      type: UPDATE_BYTE_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_BYTE_SUCCESS, payload: response })]);
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllBytes>(GET_ALL_BYTES, getAllBytes);
  yield takeLatest<GetByte>(GET_BYTE, getByte);
  yield takeLatest<NewByte>(NEW_BYTE, newByte);
  yield takeLatest<UpdateByte>(UPDATE_BYTE, updateByte);
  yield takeLatest<DeleteByte>(DELETE_BYTE, deleteByte);
  yield takeLatest<UploadMedia>(UPLOAD_MEDIA, uploadMedia);
  yield takeLatest<AddUserToByte>(ADD_USER_TO_BYTE, addUserToByte);
  yield takeLatest<SharedByte>(SHARED_BYTE, shareByte);
  yield takeLatest<UpdateManyByte>(UPDATE_MANY_BYTE, updateManyByte);
}
