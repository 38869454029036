import { put, call, all, takeLatest, select } from "redux-saga/effects";
import services from "../../services";
import {
  GET_ROADMAP,
  GET_ROADMAP_SUCCESS,
  GET_ROADMAP_ERROR,
  GET_ALL_ROADMAPS,
  GET_ALL_ROADMAPS_SUCCESS,
  GET_ALL_ROADMAPS_ERROR,
  NEW_ROADMAP,
  NEW_ROADMAP_ERROR,
  NEW_ROADMAP_SUCCESS,
  NewRoadmap,
  UPDATE_ROADMAP,
  UPDATE_ROADMAP_SUCCESS,
  UPDATE_ROADMAP_ERROR,
  DELETE_ROADMAP,
  DELETE_ROADMAP_SUCCESS,
  DELETE_ROADMAP_ERROR,
  GetRoadmap,
  GetAllRoadmaps,
  UpdateRoadmap,
  DeleteRoadmap,
  UploadMedia,
  UPLOAD_MEDIA,
  AddUserToRoadmap,
  ADD_USER_TO_ROADMAP,
  SharedRoadmap,
  SHARED_ROADMAP,
  UPDATE_MANY_ROADMAP,
  UPDATE_MANY_ROADMAP_ERROR,
  UPDATE_MANY_ROADMAP_SUCCESS,
  UpdateManyRoadmap,
} from "../types/roadmap";
import { RoadmapDto } from "../../types/roadmap.dto";

function* updateManyRoadmap({ payload }) {
  let updatedList: RoadmapDto[] = [];

  try {
    for (let i = 0; i < payload.items.length; i++) {
      const { _id, ...body } = payload.items[i];
      const formDataInsteadBody = !!body.body ? body.body : body;
      const { response, error } = yield call(services.roadmap.update, {
        _id,
        body: formDataInsteadBody,
      });
      if (!!response) {
        updatedList = [...updatedList, response];
      }
    }

    yield put({ type: UPDATE_MANY_ROADMAP_SUCCESS, payload: updatedList });
  } catch (err) {
    yield put({ type: UPDATE_MANY_ROADMAP_ERROR, payload: err });
  }
}

function* getAllRoadmaps({ payload }) {
  const { response, error } = yield call(services.roadmap.getAll, payload);

  if (error) {
    yield put({
      type: GET_ALL_ROADMAPS_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_ROADMAPS_SUCCESS, payload: response });
  }
}

function* getRoadmap({ payload }) {
  const { response, error } = yield call(services.roadmap.getOne, payload);

  if (error) {
    yield put({
      type: GET_ROADMAP_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ROADMAP_SUCCESS, payload: response });
  }
}

function* newRoadmap({ payload }) {
  const { response, error } = yield call(services.roadmap.new, payload);

  if (error) {
    yield put({
      type: NEW_ROADMAP_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: NEW_ROADMAP_SUCCESS, payload: response })]);
  }
}

function* updateRoadmap({ payload }) {
  const { response, error } = yield call(services.roadmap.update, payload);
  if (error) {
    yield put({
      type: UPDATE_ROADMAP_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_ROADMAP_SUCCESS, payload: response })]);
  }
}
function* addUserToRoadmap({ payload }) {
  const { response, error } = yield call(
    services.roadmap.addUserRoadmap,
    payload
  );

  if (error) {
    yield put({
      type: UPDATE_ROADMAP_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_ROADMAP_SUCCESS, payload: response })]);
  }
}

function* uploadMedia({ payload }) {
  const { response, error } = yield call(services.roadmap.update, payload);

  if (error) {
    yield put({
      type: UPDATE_ROADMAP_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_ROADMAP_SUCCESS, payload: response })]);
  }
}

function* deleteRoadmap({ payload }) {
  const { response, error } = yield call(services.roadmap.delete, payload);

  if (error) {
    yield put({
      type: DELETE_ROADMAP_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: DELETE_ROADMAP_SUCCESS, payload: response })]);
  }
}

function* shareRoadmap({ payload }) {
  const { response, error } = yield call(
    services.roadmap.shareRoadmap,
    payload
  );

  if (error) {
    yield put({
      type: UPDATE_ROADMAP_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_ROADMAP_SUCCESS, payload: response })]);
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllRoadmaps>(GET_ALL_ROADMAPS, getAllRoadmaps);
  yield takeLatest<GetRoadmap>(GET_ROADMAP, getRoadmap);
  yield takeLatest<NewRoadmap>(NEW_ROADMAP, newRoadmap);
  yield takeLatest<UpdateRoadmap>(UPDATE_ROADMAP, updateRoadmap);
  yield takeLatest<DeleteRoadmap>(DELETE_ROADMAP, deleteRoadmap);
  yield takeLatest<UploadMedia>(UPLOAD_MEDIA, uploadMedia);
  yield takeLatest<AddUserToRoadmap>(ADD_USER_TO_ROADMAP, addUserToRoadmap);
  yield takeLatest<SharedRoadmap>(SHARED_ROADMAP, shareRoadmap);
  yield takeLatest<UpdateManyRoadmap>(UPDATE_MANY_ROADMAP, updateManyRoadmap);
}
