const permissions = {
  static: [
    "route:calendar:preview",

    "route:talents:preview",
    "route:talents:edit",
    "route:talents:create",

    "route:education:preview",
    "route:education:edit",
    "route:education:create",

    "route:cohort:preview",
    "route:cohort:edit",
    "route:cohort:create",

    "route:analytics:preview",

    "route:marketplace:preview",

    "route:team:preview",
    "route:team:edit",
    "route:team:create",

    "route:integrations:preview",
    "route:integrations:edit",
    "route:integrations:create",

    "route:certificate:preview",
    "route:certificate:new",
    "route:certificate:edit",
    "route:certificate:search:preview",
    "route:certificate:recieved:preview",

    "route:notification:preview",
    "route:notification:new",
    "route:notification:edit",

    "route:template:preview",
    "route:template:new",
    "route:template:edit",

    "route:config:preview",
    "route:config:new",
    "route:config:edit",

    "route:forms:preview",
    "route:forms:new",
    "route:forms:edit",
  ],
  dynamic: {
    "op:edit:state": ({ status }) => {
      if (!status) return false;
      return ["requested", "pending", "inProgress", "done"].includes(status);
    },
  },
};

export default permissions;
