import * as Yup from "yup";

interface FormValues {
  name?: string;
}

export const schema = Yup.object().shape({
  name: Yup.string().required(),
});

export const initialValues = (values): FormValues => {
  return {
    name: "",
    ...values,
  };
};
