import { useEffect, useMemo, useState } from "react";
import {
  RecruitingLayout,
  useRecruitingLayout,
} from "../../../../provider/RecruitingProvider";
import Tabs from "../../../../components/Tabs";
import Icon from "../../../../components/Icon";
import { InLine } from "../../../../components/StyledComponents";
import {
  Header,
  SectionIcon,
  Title,
  PrimarySubTitle,
  SecondSubtitle,
  Actions,
  Action,
  Tab,
  TabTitle,
  Description,
  Content,
  ListTitle,
  List,
  ListItem,
} from "../components/StyledComponents";
import { useDispatch } from "react-redux";
import recruiting from "../../../../store/actions/recruiting/";
import { showModal } from "../../../../store/actions/modal";
import ModalCreateCompany from "./components/ModalCreateCompany";
import ModalEditCompany from "./components/ModalEditCompany";
import TalentSearchIcon from "../../../../assets/icons/talent-search.svg";
import ShowModal from "../components/ShoModal";
import { connect } from "react-redux";
import { CompanyDto } from "../../../../types/recruiting.dto";
import TabProfile from "./components/TabProfile";
import TabTalents from "./components/TabTalents";
import TabSearches from "./components/TabSearches";
import TabTechnologies from "./components/TabTechnologies";
import { useParams } from "react-router-dom";

interface ComponentProps {
  allCompanies: CompanyDto[];
  allCompaniesStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  selected;
}

const Component = ({
  allCompanies,
  allCompaniesStates,
  selected,
}: ComponentProps) => {
  const { setRecruitingLayout, selectedItem } = useRecruitingLayout();
  const dispatch = useDispatch();
  const params = useParams();
  const EditarPerfil = () => (
    <Action onClick={() => dispatch(showModal("edit-company-modal"))}>
      <Icon icon={TalentSearchIcon} size="16px" color="black" />
      Editar Perfil
    </Action>
  );

  const getAllItems = () => {
    dispatch(recruiting.company.getAll());
  };

  const recruitingData = useMemo(
    (): RecruitingLayout => ({
      title: "Mis Empresas",
      icon: TalentSearchIcon,
      model: "company",
      externalIds: ["searches"],
      fieldName: "companies",
      newProfileModal: "new-company-modal",
      actions: recruiting.company,
      list: allCompanies
        ?.filter((company) => company.active)
        .filter((company) =>
          !params.id ? !company.team : company.team === params.id
        )
        .map((company) => ({
          label: company.name,
          value: company._id,
          data: company,
        })),
    }),
    [allCompanies, selected, params]
  );

  useEffect(() => {
    setRecruitingLayout(recruitingData);
    getAllItems();
  }, [selected, params]);

  useEffect(() => {
    setRecruitingLayout(recruitingData);
  }, [allCompanies, selected, params]);

  return (
    <>
      <Header>
        <InLine
          align="flex-start"
          justify="flex-start"
          style={{ marginBottom: "14px" }}
        >
          <SectionIcon>
            <Icon icon={TalentSearchIcon} size="16px" color="white" />
          </SectionIcon>

          <span>
            <Title>{selectedItem?.label}</Title>
            <PrimarySubTitle>
              {`Provincia de ${
                !!selectedItem?.data?.location
                  ? selectedItem?.data?.location
                  : ""
              }`}
            </PrimarySubTitle>
            <SecondSubtitle>
              {!!selectedItem?.data.english && selectedItem?.data.english}
            </SecondSubtitle>
          </span>
        </InLine>

        <InLine justify="flex-start">
          <EditarPerfil />
          <ShowModal.AsignarBusqueda />
          <ShowModal.AsignarTalento />
          <ShowModal.AsignarTecnologia />
        </InLine>
      </Header>

      <Tabs>
        <Tab id="perfil" label="Perfil">
          <TabProfile>
            <EditarPerfil></EditarPerfil>
          </TabProfile>
        </Tab>

        <Tab id="busquedas" label="Busqueda">
          <TabSearches></TabSearches>
        </Tab>

        <Tab id="talentos" label="Talentos">
          <TabTalents></TabTalents>
        </Tab>

        <Tab id="tecnologias" label="Tecnologias">
          <TabTechnologies></TabTechnologies>
        </Tab>
      </Tabs>

      <ModalCreateCompany />
      <ModalEditCompany />
    </>
  );
};

const states = ({ recruitingStore, teamStore }) => {
  const { data: allCompanies, states: allCompaniesStates } =
    recruitingStore.company.all;
  const { data: selected } = teamStore.selected;
  return { allCompanies, allCompaniesStates, selected };
};

export default connect(states)(Component);
