import { ConfirmationDto } from "../../types/confirmation.dto";

export const GET_CONFIRMATION = "GET_CONFIRMATION";
export const GET_CONFIRMATION_ERROR = "GET_CONFIRMATION_ERROR";
export const GET_CONFIRMATION_SUCCESS = "GET_CONFIRMATION_SUCCESS";
export const RESET_GET_CONFIRMATION = "RESET_GET_CONFIRMATION";
export const UPDATE_CONFIRMATION = "UPDATE_CONFIRMATION";
export const UPDATE_CONFIRMATION_ERROR = "UPDATE_CONFIRMATION_ERROR";
export const UPDATE_CONFIRMATION_SUCCESS = "UPDATE_CONFIRMATION_SUCCESS";
export const RESET_UPDATE_CONFIRMATION = "RESET_UPDATE_CONFIRMATION";

export interface GetConfirmation {
  type: typeof GET_CONFIRMATION;
  payload: { id: string };
}

export interface GetConfirmationSuccess {
  type: typeof GET_CONFIRMATION_SUCCESS;
  payload: ConfirmationDto;
}

export interface ResetGetConfirmation {
  type: typeof RESET_GET_CONFIRMATION;
  payload: null;
}

export interface GetConfirmationError {
  type: typeof GET_CONFIRMATION_ERROR;
  payload: boolean | string;
}

export interface UpdateConfirmation {
  type: typeof UPDATE_CONFIRMATION;
  payload: ConfirmationDto;
}

export interface UpdateConfirmationSuccess {
  type: typeof UPDATE_CONFIRMATION_SUCCESS;
  payload: ConfirmationDto;
}

export interface UpdateConfirmationError {
  type: typeof UPDATE_CONFIRMATION_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateConfirmation {
  type: typeof RESET_UPDATE_CONFIRMATION;
  payload: null;
}

export interface State {
  confirmation: {
    data: ConfirmationDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  updateConfirmation: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  confirmation: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  updateConfirmation: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetConfirmation
  | GetConfirmationSuccess
  | GetConfirmationError
  | ResetGetConfirmation
  | UpdateConfirmation
  | UpdateConfirmationSuccess
  | UpdateConfirmationError
  | ResetUpdateConfirmation;
