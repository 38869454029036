import styled from "styled-components";

export const GridButton = styled("div")`
  width: 32px;
  height: 32px;
  border-radius: 10px;
  color: ${(props) => (!!props.active ? "#fff" : "#1573e5")};
  background-color: ${(props) => (!!props.active ? "#1573e5" : "#fff")};
  display: flex;
  align-items: center;
  justify-content: center;
`;
