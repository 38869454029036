import * as Yup from "yup";

export const schema = Yup.object().shape({
  entities: Yup.array().of(Yup.string()).min(1),
});

interface FormValues {
  entities: string[];
}

export const initialValues = (values?): FormValues => {
  return { entities: [], ...values };
};
