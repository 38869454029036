import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Button from "../../../../../components/Button";
import ReactSelect from "../../../../../components/Form/ReactSelect";
import {
  FlexContainer,
  Separator,
} from "../../../../../components/StyledComponents";
import {
  deleteMember,
  getAllCohorts,
  resetGetAllCohortsData,
} from "../../../../../store/actions/cohort";
import academyActions from "../../../../../store/actions/academies";
import invitationActions from "../../../../../store/actions/invitation";
import {
  getAllRequests,
  resetGetAllRequests,
  resetUpdateManyRequest,
  resetUpdateRequest,
  updateManyRequest,
  updateRequest,
} from "../../../../../store/actions/request";
import { AcademyDto } from "../../../../../types/academy.dto";
import { CohortDto } from "../../../../../types/cohort.dto";
import { CurrentDto } from "../../../../../types/current.dto";
import { UsersDto } from "../../../../../types/users.dto";
import CohortModal from "../../../Academy/Gestion/components/MembersList/components/CohortModal";
import { Img } from "../../../Cohort/Customize/style";
import { EntityImageContainer } from "../../../Cohort/Events/style";
import { CardTitle, EntityTitleSemiBold, ListItem } from "../../style";
import { Formik } from "formik";
import { schema } from "../../../../../constants/form/auth/sign-in";
import Grid from "../../../../../components/Grid";
import { useDispatch } from "react-redux";
import { RequestDto } from "../../../../../types/request.dto";
import Loader from "../../../../../components/Loader";
import { UserDto } from "../../../../../types/user.dto";
import { StateDto } from "../../../../../types/states.dto";
import { hideModal } from "../../../../../store/actions/modal";
import usersActions from "../../../../../store/actions/users";
import { FetchData } from "../../../../../types/fetchData.dto";
import InfinityScroll from "../../../../../components/InfinityScroll";
import services from "../../../../../services";
interface ComponentProps {
  cohorts: CohortDto[];
  section: "academy" | "cohort";
  current: CurrentDto;
  users: UsersDto[];
  academies: AcademyDto[];
  invitations: RequestDto[];
  requests: RequestDto[];
  user: UserDto;
  updateRequestStates: StateDto;
  updateManyRequestStates: StateDto;
  cohortStates;
  requestStates;
  updateRequestData: RequestDto;
  updateManyRequestsData: RequestDto[];
}

const ComponentCohort = ({
  section,
  cohorts,
  current,
  users,
  academies,
  invitations,
  requests,
  user,
  updateRequestStates,
  updateManyRequestStates,
  cohortStates,
  requestStates,
  updateRequestData,
  updateManyRequestsData,
}: ComponentProps) => {
  const [selectedCohort, setSelectedCohort] = useState<CohortDto>();
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);
  const [paginationOn, setPaginationOn] = useState<boolean>(false);
  const [currentRequestsList, setCurrentRequestsList] = useState<RequestDto[]>(
    []
  );
  const [usersFromRequests, setUsersFromRequests] = useState<UsersDto[]>();
  const dispatch = useDispatch();

  const fetchData = ({ page, filterBy }: FetchData) => {
    if (page === 0) {
      setPaginationOn(true);
    }
    let payload = {
      limit: 4,
      offset: 4 * page,
      filterBy: {
        ...(filterBy ? filterBy : {}),
        ...(selectedCohort && { cohort: selectedCohort._id }),
        status: "PENDING",
        active: true,
      },
    };
    const sortBy = "createdAt";

    const sortDirection = "desc";
    dispatch(getAllRequests({ ...payload, sortBy, sortDirection }));
  };

  useEffect(() => {
    if (updateRequestStates.success) {
      dispatch(resetUpdateRequest());
      dispatch(hideModal());
    }
    if (updateRequestStates.error) {
      dispatch(resetUpdateRequest());
      dispatch(hideModal());
    }
  }, [updateRequestStates]);

  useEffect(() => {
    if (updateManyRequestStates.success) {
      setCurrentRequestsList((state) =>
        state.map((st) =>
          st._id === updateRequestData._id ? updateRequestData : st
        )
      );
      dispatch(resetUpdateManyRequest());
    }
    if (updateManyRequestStates.error) {
      dispatch(resetUpdateManyRequest());
    }
  }, [updateManyRequestStates, dispatch]);

  useEffect(() => {
    if (!!updateManyRequestsData && !!paginationOn) {
      setCurrentRequestsList((state) =>
        state.map((st) => {
          const updatedRequest = updateManyRequestsData.find(
            (el) => el._id === st._id
          );
          if (updatedRequest) {
            return updatedRequest;
          }
          return st;
        })
      );
    }
  }, [updateManyRequestsData, paginationOn]);

  useEffect(() => {
    if (updateRequestData && paginationOn) {
      setCurrentRequestsList((state) =>
        state.map((st) =>
          st._id === updateRequestData._id ? updateRequestData : st
        )
      );
    }
  }, [updateRequestData, paginationOn]);

  useEffect(() => {
    if (!cohorts)
      dispatch(
        getAllCohorts({ filterBy: { active: true, academy: current.id } })
      );
  }, [cohorts]);

  useEffect(() => {
    if (paginationOn) {
      if (!isLoading && requests && requests.length > 0) {
        setCurrentRequestsList((state) => [...state, ...requests]);
      }
      setThereAreItems(!!requests && requests?.length > 0);
    }
  }, [requests, isLoading, paginationOn]);

  useEffect(() => {
    if (currentRequestsList && paginationOn) {
      const allEmails = Array.from(
        new Set(currentRequestsList.map((invitation) => invitation.sentBy))
      );

      const fetchUsers = async (emails) => {
        const response: any = await services.users.getAll({
          filterBy: { _id: { $in: emails } },
        });
        if (response.response) {
          setUsersFromRequests(response.response);
        }
      };
      fetchUsers(allEmails);
    }
  }, [currentRequestsList, paginationOn]);
  useEffect(() => {
    return () => {
      dispatch(resetGetAllCohortsData());
      dispatch(resetGetAllRequests());
    };
  }, []);

  useEffect(() => {
    if (paginationOn) {
      setIsLoading(requestStates.loading);
    }
  }, [requestStates]);

  useEffect(() => {
    if (selectedCohort && paginationOn) {
      setPage(0);
      setIsLoading(true);
      setThereAreItems(true);
      setCurrentRequestsList([]);
    }
  }, [selectedCohort]);

  return (
    <>
      <FlexContainer justify="space-between" align="center" wrap="wrap">
        <CardTitle style={{ marginBottom: "0px" }}>Solicitudes</CardTitle>
        {((selectedCohort && currentRequestsList.length > 0) ||
          currentRequestsList.length > 0) && (
          <Button
            type="button"
            onClick={() => {
              const itemsMapped = currentRequestsList.map((item) => {
                return { _id: item._id, condition: "accept" };
              });
              dispatch(updateManyRequest({ items: itemsMapped }));
            }}
            loading={updateManyRequestStates.loading}
            options={{
              size: "md",
              type: "filled",
              skin: "primary",
            }}
          >
            Aceptar Todas
          </Button>
        )}
      </FlexContainer>
      <Separator size={20}></Separator>

      <Formik
        initialValues={{}}
        onSubmit={(values, actions) => {}}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <Grid.Row>
              <Grid.Col sm={8}>
                <ReactSelect
                  name="private"
                  {...(!!cohorts && {
                    items: cohorts

                      .filter((cohort) =>
                        current.role === "PM"
                          ? cohort.mentorsIds.includes(user._id)
                          : cohort
                      )
                      .map((cohort) => {
                        return {
                          value: cohort._id,
                          label: cohort.name,
                        };
                      }),
                  })}
                  onOptionSelected={(newValue) => {
                    const cohortFounded = cohorts.filter(
                      (cohort) => cohort._id === newValue.value
                    )[0];

                    setSelectedCohort(cohortFounded);
                  }}
                  style={{ borderRadius: "5px" }}
                  placeholder="Seleccione un cohort"
                  options={{
                    label: "Cohort",
                    marginBottom: 20,
                    skin: "base",
                    loading: !cohorts,
                  }}
                />
              </Grid.Col>
            </Grid.Row>
          );
        }}
      </Formik>

      {selectedCohort && (
        <FlexContainer
          direction="column"
          gap="10px"
          style={{ maxHeight: "390px", overflowY: "auto" }}
        >
          <InfinityScroll
            action={fetchData}
            page={page}
            setPage={setPage}
            data={currentRequestsList}
            active={thereAreItems}
            isLoading={isLoading}
          >
            {currentRequestsList
              .filter(
                (request) =>
                  request.status === "PENDING" &&
                  request.active &&
                  request.cohort._id === selectedCohort?._id
              )
              .map((request) => {
                const userFounded = usersFromRequests?.find(
                  (userF) => userF._id === request.sentBy._id && userF.active
                );
                if (!userFounded) return null;
                return (
                  <ListItem>
                    <FlexContainer justify="space-between" align="center">
                      <FlexContainer align="center" gap="10px">
                        <EntityImageContainer>
                          <Img src={userFounded?.picture}></Img>
                        </EntityImageContainer>
                        <EntityTitleSemiBold>
                          {`${userFounded?.name} ${userFounded?.lastName}`}
                        </EntityTitleSemiBold>
                      </FlexContainer>
                      <FlexContainer gap="20px" align="center">
                        <Button
                          type="button"
                          onClick={() => {
                            dispatch(
                              updateRequest({
                                condition: "accept",
                                _id: request._id,
                              })
                            );
                          }}
                          style={{ height: "26px" }}
                          loading={updateRequestStates.loading}
                          options={{
                            size: "md",
                            type: "filled",
                            skin: "primary",
                          }}
                        >
                          Aceptar Solicitud
                        </Button>
                        <Button
                          type="button"
                          onClick={() => {
                            dispatch(
                              updateRequest({
                                condition: "reject",
                                _id: request._id,
                              })
                            );
                          }}
                          style={{ height: "26px", width: "150px" }}
                          loading={updateRequestStates.loading}
                          options={{
                            size: "md",
                            type: "filled",
                            skin: "danger",
                          }}
                        >
                          Rechazar
                        </Button>
                      </FlexContainer>
                    </FlexContainer>
                  </ListItem>
                );
              })}
          </InfinityScroll>
        </FlexContainer>
      )}
    </>
  );
};
const ComponentAcademy = ({
  section,
  cohorts,
  current,
  users,
  academies,
  invitations,
  requests,
  user,
  updateRequestStates,
  updateManyRequestStates,
  cohortStates,
  requestStates,
  updateRequestData,
  updateManyRequestsData,
}: ComponentProps) => {
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);
  const [paginationOn, setPaginationOn] = useState<boolean>(false);
  const [currentRequestsList, setCurrentRequestsList] = useState<RequestDto[]>(
    []
  );
  const [usersFromRequests, setUsersFromRequests] = useState<UsersDto[]>();
  const dispatch = useDispatch();

  const fetchData = ({ page, filterBy }: FetchData) => {
    if (page === 0) {
      setPaginationOn(true);
    }
    let payload = {
      limit: 4,
      offset: 4 * page,
      filterBy: {
        ...(filterBy ? filterBy : {}),
        academy: current.id,
        status: "PENDING",
        active: true,
      },
    };
    const sortBy = "createdAt";

    const sortDirection = "desc";
    dispatch(getAllRequests({ ...payload, sortBy, sortDirection }));
  };

  useEffect(() => {
    if (updateRequestStates.success) {
      setCurrentRequestsList((state) =>
        state.map((st) =>
          st._id === updateRequestData._id ? updateRequestData : st
        )
      );
      dispatch(resetUpdateRequest());
      dispatch(hideModal());
    }
    if (updateRequestStates.error) {
      dispatch(resetUpdateRequest());
      dispatch(hideModal());
    }
  }, [updateRequestStates]);

  useEffect(() => {
    if (updateManyRequestStates.success) {
      dispatch(resetUpdateManyRequest());
    }
    if (updateManyRequestStates.error) {
      dispatch(resetUpdateManyRequest());
    }
  }, [updateManyRequestStates, dispatch]);

  useEffect(() => {
    if (!!updateManyRequestsData && !!paginationOn) {
      setCurrentRequestsList((state) =>
        state.map((st) => {
          const updatedRequest = updateManyRequestsData.find(
            (el) => el._id === st._id
          );
          if (updatedRequest) {
            return updatedRequest;
          }
          return st;
        })
      );
    }
  }, [updateManyRequestsData, paginationOn]);

  useEffect(() => {
    if (updateRequestData && paginationOn) {
      setCurrentRequestsList((state) =>
        state.map((st) =>
          st._id === updateRequestData._id ? updateRequestData : st
        )
      );
    }
  }, [updateRequestData, paginationOn]);

  useEffect(() => {
    if (!cohorts)
      dispatch(
        getAllCohorts({ filterBy: { active: true, academy: current.id } })
      );
  }, [cohorts]);

  useEffect(() => {
    if (paginationOn) {
      if (!isLoading && !!requests) {
        const filterRequests = requests.map((cdoc) => {
          return {
            ...cdoc,
          };
        });
        setCurrentRequestsList((state) => [...state, ...filterRequests]);
      }
      setThereAreItems(!!requests && requests?.length > 0);
    }
  }, [requests, isLoading, paginationOn]);

  useEffect(() => {
    if (currentRequestsList && paginationOn) {
      const allEmails = Array.from(
        new Set(currentRequestsList.map((invitation) => invitation.sentBy))
      );

      const fetchUsers = async (emails) => {
        const response: any = await services.users.getAll({
          filterBy: { _id: { $in: emails } },
        });
        if (response.response) {
          setUsersFromRequests(response.response);
        }
      };
      fetchUsers(allEmails);
    }
  }, [currentRequestsList, paginationOn]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllCohortsData());
      dispatch(resetGetAllRequests());
      dispatch(usersActions.resetGetAll());
    };
  }, []);

  useEffect(() => {
    if (paginationOn) {
      setIsLoading(requestStates.loading);
    }
  }, [requestStates]);

  return (
    <>
      <FlexContainer justify="space-between" align="center" wrap="wrap">
        <CardTitle style={{ marginBottom: "0px" }}>Solicitudes</CardTitle>
        {currentRequestsList.filter(
          (request) =>
            request.academy._id === current.id &&
            request.status === "PENDING" &&
            request.active
        ).length > 0 && (
          <Button
            type="button"
            onClick={() => {
              const itemsMapped = currentRequestsList.map((item) => {
                return { _id: item._id, condition: "accept" };
              });
              dispatch(updateManyRequest({ items: itemsMapped }));
            }}
            loading={updateManyRequestStates.loading}
            options={{
              size: "md",
              type: "filled",
              skin: "primary",
            }}
          >
            Aceptar Todas
          </Button>
        )}
      </FlexContainer>
      <Separator size={20}></Separator>

      <InfinityScroll
        action={fetchData}
        page={page}
        setPage={setPage}
        data={requests}
        active={thereAreItems}
        isLoading={isLoading}
      >
        <FlexContainer
          direction="column"
          gap="10px"
          style={{ maxHeight: "390px", overflowY: "auto" }}
        >
          {currentRequestsList
            .filter(
              (request) =>
                request.academy._id === current.id &&
                request.status === "PENDING" &&
                request.active
            )
            .map((request) => {
              const userFounded = usersFromRequests?.find(
                (userF) => userF._id === request.sentBy._id && userF.active
              );
              if (!userFounded) return null;
              return (
                <ListItem>
                  <FlexContainer justify="space-between" align="center">
                    <FlexContainer align="center" gap="10px">
                      <EntityImageContainer>
                        <Img src={userFounded?.picture}></Img>
                      </EntityImageContainer>
                      <EntityTitleSemiBold>
                        {`${userFounded?.name} ${userFounded?.lastName}`}
                      </EntityTitleSemiBold>
                    </FlexContainer>
                    <FlexContainer gap="20px" align="center">
                      <Button
                        type="button"
                        onClick={() => {
                          dispatch(
                            updateRequest({
                              condition: "accept",
                              _id: request._id,
                            })
                          );
                        }}
                        style={{ height: "26px" }}
                        loading={updateRequestStates.loading}
                        options={{
                          size: "md",
                          type: "filled",
                          skin: "primary",
                        }}
                      >
                        Aceptar Solicitud
                      </Button>
                      <Button
                        type="button"
                        onClick={() => {
                          dispatch(
                            updateRequest({
                              condition: "reject",
                              _id: request._id,
                            })
                          );
                        }}
                        style={{ height: "26px", width: "150px" }}
                        loading={updateRequestStates.loading}
                        options={{
                          size: "md",
                          type: "filled",
                          skin: "danger",
                        }}
                      >
                        Rechazar
                      </Button>
                    </FlexContainer>
                  </FlexContainer>
                </ListItem>
              );
            })}
        </FlexContainer>
      </InfinityScroll>
    </>
  );
};
const states = ({
  cohortStore,
  currentStore,
  usersStore,
  academyStore,
  invitationStore,
  requestStore,
  userStore,
}) => {
  const { data: cohorts, states: cohortStates } = cohortStore.allCohorts;
  const { data: current } = currentStore;
  const { data: users } = usersStore.all;
  const { data: academies } = academyStore.all;
  const { data: invitations } = invitationStore.all;
  const { data: requests, states: requestStates } = requestStore.allRequests;
  const { states: updateRequestStates, data: updateRequestData } =
    requestStore.updateRequest;
  const { states: updateManyRequestStates, data: updateManyRequestsData } =
    requestStore.updateMany;
  const { data: user } = userStore;
  return {
    cohorts,
    current,
    users,
    academies,
    invitations,
    requests,
    user,
    updateRequestStates,
    updateManyRequestStates,
    cohortStates,
    requestStates,
    updateRequestData,
    updateManyRequestsData,
  };
};

const Components = {
  Cohort: connect(states)(ComponentCohort),
  Academy: connect(states)(ComponentAcademy),
};

export default Components;
