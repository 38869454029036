import * as Yup from "yup";

export const schema = Yup.object().shape({
  name: Yup.string().required("Ingresa un nombre."),
  country: Yup.string().required("Selecciona un país."),
  province: Yup.string().required("Selecciona una provincia."),
  english: Yup.string().required("Selecciona un nivel de inglés."),
  description: Yup.string(),
});

interface FormValues {
  name: string;
  country: string;
  province: string;
  english: string;
  description: string;
}

export const initialValues: FormValues = {
  name: "",
  country: "",
  province: "",
  english: "",
  description: "",
};
