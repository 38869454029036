import { useEffect, useState } from "react";
import { StudentDto } from "../../../../../../types/student.dto";
import { TalentDto } from "../../../../../../types/talent.dto";
import { connect, useDispatch } from "react-redux";
import { getTalents } from "../../../../../../store/actions/talent";
import {
  Avatar,
  CardCol,
  CardRow,
  Information,
  Name,
  Profile,
  Location,
  Card,
} from "../../../../Marketplace/components/TalentCard/styles";
import { getAllStudents } from "../../../../../../store/actions/student";
import { textComparation } from "../../../../../../helpers/text-comparation";
import { UsersDto } from "../../../../../../types/users.dto";
import ShortString from "../../../../../../components/ShortString";
import { UserDto } from "../../../../../../types/user.dto";
interface ComponentProps {
  userId?: string;
  talentsStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  talents: TalentDto[];
  studentsStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  students: StudentDto[];
  section?: string;
  users: UsersDto[];
  cohortStudentsStates;
  cohortStudents;
}

interface CurrentUser {
  _id?: string;
  userId: string;
  name: string;
  lastName: string;
  avatar?: string;
  country?: string;
  province?: string;
  english?: string;
  email?: string;
  portfolio?: string;
  linkedin?: string;
  curriculum?: string;
  comments?: string;
}

const Component = ({
  userId,
  cohortStudentsStates,
  cohortStudents,
  section = "marketplace",
  users,
}: ComponentProps) => {
  const [currentStudent, setCurrentStudent] = useState<UsersDto>();

  useEffect(() => {
    if (cohortStudents) {
      const studentsFind = cohortStudents.filter(
        (student) => student._id === userId
      )[0];
      setCurrentStudent(studentsFind);
    }
  }, [userId, cohortStudents]);

  return (
    <Card>
      <CardCol>
        <CardRow>
          <Avatar>
            <img src={currentStudent?.picture} alt="avatar" />
          </Avatar>
          <Information>
            <Name>
              {currentStudent?.name} {currentStudent?.lastName}
            </Name>
            <Profile title={currentStudent?.email}>
              {ShortString(currentStudent?.email, 22)}
            </Profile>
            <Location>
              Genero: {currentStudent?.gender ?? "Incompleto"}
            </Location>
          </Information>
        </CardRow>
      </CardCol>
    </Card>
  );
};

const states = ({ talentStore, studentStore, usersStore, cohortStore }) => {
  const { states: studentsStates, data: students } = studentStore.allStudents;
  const { states: talentsStates, data: talents } = talentStore.allTalents;
  const { data: users } = usersStore.all;
  const { states: cohortStudentsStates, data: cohortStudents } =
    cohortStore.usersByCohort;
  return {
    studentsStates,
    students,
    talentsStates,
    users,
    talents,
    cohortStudentsStates,
    cohortStudents,
  };
};

export default connect(states)(Component);
