import {
  initialState,
  Actions,
  State,
  GET_ALL_ASSOCIATION,
  GET_ALL_ASSOCIATION_SUCCESS,
  GET_ALL_ASSOCIATION_ERROR,
  NEW_ASSOCIATION,
  NEW_ASSOCIATION_ERROR,
  NEW_ASSOCIATION_SUCCESS,
  DELETE_ASSOCIATION,
  DELETE_ASSOCIATION_SUCCESS,
  DELETE_ASSOCIATION_ERROR,
  RESET_DELETE_ASSOCIATION,
  RESET_NEW_ASSOCIATION,
  UPDATE_ASSOCIATION,
  UPDATE_ASSOCIATION_ERROR,
  UPDATE_ASSOCIATION_SUCCESS,
  RESET_UPDATE_ASSOCIATION,
} from "../types/association";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_ASSOCIATION:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_ASSOCIATION_SUCCESS:
      return {
        ...state,
        all: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_ASSOCIATION_ERROR:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case UPDATE_ASSOCIATION:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_ASSOCIATION_ERROR:
      return {
        ...state,
        update: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case UPDATE_ASSOCIATION_SUCCESS:
      return {
        ...state,
        update: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case RESET_UPDATE_ASSOCIATION:
      return {
        ...state,
        update: {
          states: initialState.update.states,
        },
      };
    case NEW_ASSOCIATION:
      return {
        ...state,
        create: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_ASSOCIATION_SUCCESS:
      return {
        ...state,
        create: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case NEW_ASSOCIATION_ERROR:
      return {
        ...state,
        create: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_ASSOCIATION:
      return {
        ...state,
        create: initialState.create,
      };
    case DELETE_ASSOCIATION:
      return {
        ...state,
        delete: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_ASSOCIATION_SUCCESS:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_ASSOCIATION_ERROR:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_ASSOCIATION:
      return {
        ...state,
        delete: initialState.delete,
      };
    default:
      return state;
  }
};

export default reducers;
