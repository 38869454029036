import { AssociationDto } from "../../types/association.dto";
export const GET_ALL_ASSOCIATION = "GET_ALL_ASSOCIATION";
export const RESET_GET_ALL_ASSOCIATION = "RESET_GET_ALL_ASSOCIATION";
export const GET_ALL_ASSOCIATION_SUCCESS = "GET_ALL_ASSOCIATION_SUCCESS";
export const GET_ALL_ASSOCIATION_ERROR = "GET_ALL_ASSOCIATION_ERROR";
export const NEW_ASSOCIATION = "NEW_ASSOCIATION";
export const NEW_ASSOCIATION_SUCCESS = "NEW_ASSOCIATION_SUCCESS";
export const NEW_ASSOCIATION_ERROR = "NEW_ASSOCIATION_ERROR";
export const RESET_NEW_ASSOCIATION = "RESET_NEW_ASSOCIATION";
export const DELETE_ASSOCIATION = "DELETE_ASSOCIATION";
export const DELETE_ASSOCIATION_SUCCESS = "DELETE_ASSOCIATION_SUCCESS";
export const DELETE_ASSOCIATION_ERROR = "DELETE_ASSOCIATION_ERROR";
export const RESET_DELETE_ASSOCIATION = "RESET_DELETE_ASSOCIATION";
export const UPDATE_ASSOCIATION = "UPDATE_ASSOCIATION";
export const UPDATE_ASSOCIATION_SUCCESS = "UPDATE_ASSOCIATION_SUCCESS";
export const UPDATE_ASSOCIATION_ERROR = "UPDATE_ASSOCIATION_ERROR";
export const RESET_UPDATE_ASSOCIATION = "RESET_UPDATE_ASSOCIATION";
export interface GetAllAssociation {
  type: typeof GET_ALL_ASSOCIATION;
  payload: null;
}
export interface GetAllAssociationSuccess {
  type: typeof GET_ALL_ASSOCIATION_SUCCESS;
  payload: AssociationDto[];
}
export interface GetAllAssociationError {
  type: typeof GET_ALL_ASSOCIATION_ERROR;
  payload: string | boolean;
}
export interface ResetGetAllAssociation {
  type: typeof RESET_GET_ALL_ASSOCIATION;
  payload: null;
}

export interface NewAssociation {
  type: typeof NEW_ASSOCIATION;
  payload: any;
}
export interface NewAssociationSuccess {
  type: typeof NEW_ASSOCIATION_SUCCESS;
  payload: null;
}
export interface NewAssociationError {
  type: typeof NEW_ASSOCIATION_ERROR;
  payload: string | boolean;
}
export interface ResetNewAssociation {
  type: typeof RESET_NEW_ASSOCIATION;
  payload: null;
}

export interface DeleteAssociation {
  type: typeof DELETE_ASSOCIATION;
  payload: any;
}
export interface DeleteAssociationSuccess {
  type: typeof DELETE_ASSOCIATION_SUCCESS;
  payload: null;
}
export interface DeleteAssociationError {
  type: typeof DELETE_ASSOCIATION_ERROR;
  payload: string | boolean;
}

export interface ResetDeleteAssociation {
  type: typeof RESET_DELETE_ASSOCIATION;
  payload: null;
}

export interface UpdateAssociation {
  type: typeof UPDATE_ASSOCIATION;
  payload: any;
}
export interface UpdateAssociationSuccess {
  type: typeof UPDATE_ASSOCIATION_SUCCESS;
  payload: null;
}
export interface UpdateAssociationError {
  type: typeof UPDATE_ASSOCIATION_ERROR;
  payload: string | boolean;
}
export interface ResetUpdateAssociation {
  type: typeof RESET_UPDATE_ASSOCIATION;
  payload: null;
}

export interface State {
  all: {
    data: AssociationDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  create: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  delete: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  update: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  all: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  create: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  delete: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  update: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};
export type Actions =
  | GetAllAssociation
  | GetAllAssociationSuccess
  | GetAllAssociationError
  | NewAssociation
  | NewAssociationSuccess
  | NewAssociationError
  | DeleteAssociation
  | DeleteAssociationSuccess
  | DeleteAssociationError
  | ResetDeleteAssociation
  | ResetNewAssociation
  | ResetGetAllAssociation
  | UpdateAssociation
  | UpdateAssociationSuccess
  | UpdateAssociationError
  | ResetUpdateAssociation;
