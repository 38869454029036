import { connect } from "react-redux";
import { AcademyDto } from "../../types/academy.dto";
import { CurrentDto } from "../../types/current.dto";
import { OrganizationDto } from "../../types/organization.dto";
import { Logo } from "../Header/styles";
import image from "../../assets/images/team-icon-24.png";
import { Profile, Name, FirstName, LastName } from "./styles";
import { TalentAvatar } from "../../pages/Dashboard/Recruiting/AIT/components/TalentCard/styles";
import UserShadow from "../../assets/images/usershadow.png";
import Loader from "../Loader";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import services from "../../services";
interface ComponentProps {
  user;
  sidebarCollapsed?: boolean;
  style?;
  current: CurrentDto;
  academies: AcademyDto[];
  organizations: OrganizationDto[];
}

const Component = ({
  user,
  current,
  sidebarCollapsed,
  academies,
  organizations,
  ...rest
}: ComponentProps) => {
  const [entity, setEntity] = useState<AcademyDto | OrganizationDto>();
  const navigate = useNavigate();
  useEffect(() => {
    /*  if (!!academies && !!organizations) {
      
      setEntity(
        [...academies, ...organizations].find((ent) => ent._id === current.id)
      );
    } */
    if (current.currentSection === "academy") {
      const fetchData = async () => {
        const response: any = await services.academy.getOne({
          _id: current.id,
        });
        if (response.response) {
          setEntity(response.response);
        }
      };
      fetchData();
    }
  }, [current]);

  if (!entity && current.currentSection !== "personal")
    return <Loader color="Primary"></Loader>;
  return (
    <Profile
      sidebarCollapsed={sidebarCollapsed}
      onClick={() => {
        if (
          current.currentSection === "academy" ||
          current.currentSection === "organization"
        ) {
          navigate("/dashboard/management/profile");
        } else {
          navigate("/dashboard/profile");
        }
      }}
      {...rest}
    >
      {current.currentSection === "personal" && (
        <>
          <TalentAvatar
            height={"32px"}
            width={"32px"}
            avatar={user?.picture || UserShadow}
          />

          <Name>
            <FirstName>
              {!!user?.name && user?.name} {!!user?.lastName && user?.lastName}
            </FirstName>
            <LastName>{user?.position}</LastName>
          </Name>
        </>
      )}
      {(current.currentSection === "organization" ||
        current.currentSection === "academy") && (
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <div
            style={{
              height: "40px",
              width: "40px",
              overflow: "hidden",
              borderRadius: "50%",
            }}
          >
            <Logo
              src={!!entity?.picture ? entity?.picture : image}
              alt={entity?.description}
            />
          </div>
          <Name>
            <FirstName>{!!entity?.name && entity?.name}</FirstName>
            <LastName>
              {!!entity &&
                (entity.users as any).find(
                  (userF) => userF.user === user?._id && !userF.roleEnd
                )?.role}
            </LastName>
          </Name>
        </div>
      )}
    </Profile>
  );
};

const states = ({
  userStore,
  currentStore,
  academyStore,
  organizationStore,
}) => {
  const { data: user } = userStore;
  const { data: current } = currentStore;
  const { data: academies } = academyStore.all;
  const { data: organizations } = organizationStore.all;
  return {
    user,
    current,
    academies,
    organizations,
  };
};

export default connect(states)(Component);

/* {current.currentSection === "personal" && (
        <>
          <Avatar
            size="big"
            style={{ marginLeft: "64px", marginTop: "-40px" }}
          />

          <Name>
            {!!user && user.name} <Company>{!!user && user.institute}</Company>
          </Name>
          <h4>{!!user && user.position}</h4>
        </>
      )}
      {current.currentSection === "academy" ||
        (current.currentSection === "organization" && (
          <>
            <Avatar
              size="big"
              style={{ marginLeft: "64px", marginTop: "-40px" }}
            />
            <Name>
              {!!entity?.name && entity?.name}{" "}
              <Company>{current.currentSection}</Company>
            </Name>
            <h4>{!!current.role && current.role}</h4>
          </>
        ))} */
