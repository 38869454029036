import company from "./company";
import search from "./search";
import aitTalent from "./ait-talent";
import techSkill from "./tech-skill";
import technology from "./technology";
import ait from "./ait";
const createRootReducer = {
  company,
  search,
  ait,
  aitTalent,
  techSkill,
  technology,
};

export default createRootReducer;
