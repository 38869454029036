import styled from "styled-components";

export const VideoUploadWrapper = styled("div")`
  position: relative;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 0 2px 0 rgba(21, 115, 229, 0.16);
  height: ${(props) => props.height};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: #1573e5;
    svg {
      color: #fff;
    }
    .title {
      color: #fff;
    }
  }
`;

export const VideoPreview = styled("div")`
  position: relative;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 0 2px 0 rgba(21, 115, 229, 0.16);
  height: ${(props) => props.height};
`;

export const VideoUploaderTitle = styled("h3")`
  font-size: 24px;
  font-weight: 500;
  color: #697482;
  text-align: center;
`;

export const VideoPreviewPublished = styled("div")`
  box-shadow: 0 0 6px 0 rgba(31, 119, 180, 0.2);
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  font-size: 16px;
`;

export const VideoUploaderDescriptionWrapper = styled("div")`
  border-radius: 10px;
  width: 100%;
  padding: 10px;
  box-shadow: 0 0 2px 0 rgba(21, 115, 229, 0.16);
  background-color: #fff;
`;

export const TrianglePlay = styled("div")`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 25px 0 25px 50px;
  border-color: transparent transparent transparent #007bff;
`;

export const VideoUploadLoaderWrapper = styled("div")`
  position: relative;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 0 2px 0 rgba(21, 115, 229, 0.16);
  height: ${(props) => props.height};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  flex-direction: column;
  gap: 10px;
`;
