import { put, call, all, takeLatest } from "redux-saga/effects";
import services from "../../services/";
import {
  GET_ALL_TAGS,
  GET_ALL_TAGS_SUCCESS,
  GET_ALL_TAGS_ERROR,
  GetAllTags,
  NEW_MANY_TAGS_SUCCESS,
  NEW_MANY_TAGS_ERROR,
  NewManyTags,
  NEW_MANY_TAGS,
  UPDATE_MANY_TAGS_SUCCESS,
  UPDATE_MANY_TAGS_ERROR,
  UpdateManyTags,
  UPDATE_MANY_TAGS,
} from "../types/tag";
import { TagDto } from "../../types/tag.dto";

function* getAllTags({ payload }) {
  try {
    const { response, error } = yield call(services.tag.getAll, payload);

    if (error) {
      yield put({
        type: GET_ALL_TAGS_ERROR,
        payload: error.response.data?.message || "Error",
      });
    } else {
      yield put({ type: GET_ALL_TAGS_SUCCESS, payload: response });
    }
  } catch {
    yield put({
      type: GET_ALL_TAGS_ERROR,
      payload: "Error desconocido al listar Habilidades",
    });
  }
}

function* updateManyTags({ payload }) {
  let updatedList: TagDto[] = [];

  try {
    for (let i = 0; i < payload.items.length; i++) {
      const { response, error } = yield call(
        services.tag.update,
        payload.items[i]
      );
      if (error) {
        throw new Error(error.response.data.message);
      }
      if (response) {
        updatedList = [...updatedList, response];
      }
    }

    yield put({ type: UPDATE_MANY_TAGS_SUCCESS, payload: updatedList });
  } catch (err) {
    yield put({ type: UPDATE_MANY_TAGS_ERROR, payload: err });
  }
}

function* newManyTags({ payload }) {
  let allItems: any[] = [];

  try {
    for (let i = 0; i < payload.items.length; i++) {
      const { response, error } = yield call(
        services.tag.new,
        payload.items[i]
      );
      if (error) {
        throw new Error(error.response.data.message);
      }
      if (response) {
        allItems = [...allItems, response];
      }
    }
    yield put({
      type: NEW_MANY_TAGS_SUCCESS,
      payload: allItems,
    });
  } catch (err: any) {
    yield put({
      type: NEW_MANY_TAGS_ERROR,
      payload: err.message || "Error",
    });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllTags>(GET_ALL_TAGS, getAllTags);
  yield takeLatest<NewManyTags>(NEW_MANY_TAGS, newManyTags);
  yield takeLatest<UpdateManyTags>(UPDATE_MANY_TAGS, updateManyTags);
}
