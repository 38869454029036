import styled from "styled-components";

export const ItemDataText = styled("h5")`
  font-size: 12px;
  color: #6a6970;
`;

export const DotContent = styled("div")`
  width: 6px;
  height: 6px;
  background-color: #d9d9d9;
`;
