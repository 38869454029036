import { forwardRef, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Modal from "../../../../../../components/Modal";
import { Field, Formik } from "formik";
import {
  Center,
  FlexContainer,
} from "../../../../../../components/StyledComponents";
import {
  initialValues,
  schema,
} from "../../../../../../constants/form/cdoc/publish";
import Grid from "../../../../../../components/Grid";
import Button from "../../../../../../components/Button";
import Submit from "../../../../../../components/Form/Submit";
import { toast } from "react-toastify";
import associationActions from "../../../../../../store/actions/association";
import { hideModal } from "../../../../../../store/actions/modal";
import { AiOutlineSearch } from "react-icons/ai";
import { AcademyDto } from "../../../../../../types/academy.dto";
import { CurrentDto } from "../../../../../../types/current.dto";
import DinamicField from "../../../../../../components/Form/DinamicField";
import { LetterModal } from "./style";
import { CohortDto } from "../../../../../../types/cohort.dto";
import { UnitDto } from "../../../../../../types/unit.dto";
import { StateDto } from "../../../../../../types/states.dto";
import {
  newCDOCs,
  resetUpdateCDOCs,
  updateCDOCs,
} from "../../../../../../store/actions/cdoc";
import { CDocDto } from "../../../../../../types/cdoc.dto";
import { useScreenshot } from "use-react-screenshot";
import { setFormData } from "../../../../../../helpers/formData";
import DinamicLinked from "../../../../../../components/Form/DinamicLinked";
import Loader from "../../../../../../components/Loader";

/* 
({
  academies,
  cohorts,
  current,
  units,
  cdoc,
  updateStates,
  name,
  text,
  takeScreenshot,
},ref)
 {
  academies: AcademyDto[];
  cohorts: CohortDto[];
  current: CurrentDto;
  units: UnitDto[];
  cdoc: CDocDto;
  updateStates: StateDto;
  name: string;
  text: any;
  takeScreenshot;
}

*/
const Component = forwardRef(
  (
    {
      academies,
      cohorts,
      current,
      units,
      cdoc,
      updateStates,
      name,
      text,
      imagePreviewRef,
    }: {
      academies: AcademyDto[];
      cohorts: CohortDto[];
      current: CurrentDto;
      units: UnitDto[];
      cdoc: CDocDto;
      updateStates: StateDto;
      name: string;
      text: any;
      imagePreviewRef;
    },
    ref
  ) => {
    const [formSubmmited, setFormSubmmited] = useState(false);
    const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
    const [activeAcademy, setActiveAcademy] = useState<any>();
    const [image, takeScreenshot] = useScreenshot();
    const dispatch = useDispatch();
    const onSubmit = ({ values, actions }) => {
      takeScreenshot(imagePreviewRef?.current)
        .then((res) => {
          if (!cdoc) {
            dispatch(
              newCDOCs(
                setFormData(
                  {
                    name: name,
                    cohorts: values.cohorts,
                    units: values.units.map((unit) => unit.value),
                    allCohorts: values.allCohorts,
                    type: "CDOC",
                    picture: res,
                    text: text,
                    published: true,
                    available: values.available,
                    academy: current.id,
                  },
                  []
                )
              )
            );
          } else {
            dispatch(
              updateCDOCs({
                _id: cdoc._id,
                body: setFormData(
                  {
                    name: name,
                    picture: res,
                    text: text,
                    cohorts: values.cohorts,
                    allCohorts: values.allCohorts,
                    units: values.units.map((unit) => unit.value),
                    published: true,
                    available: values.available,
                    __v: cdoc.__v,
                  },
                  []
                ),
              })
            );
          }
        })
        .catch((err) => toast.error(err));

      setFormActions(actions);
    };

    useEffect(() => {
      if (updateStates.success) {
        toast.success("👌🏼 Cdoc publicado");

        setTimeout(() => {
          dispatch(resetUpdateCDOCs());
          dispatch(hideModal());
          formActions?.resetForm();
        }, 1000);
      }

      if (updateStates.error) {
        toast.error(`😱 ${updateStates.error || "No se pudo publicar cdoc"}`);

        setTimeout(() => {
          dispatch(resetUpdateCDOCs());
        }, 1000);
      }
    }, [updateStates]);
    return (
      <Modal name="publish-cdoc-modal" title={`Publicar Cdoc`}>
        {!units ? (
          <Loader color="Primary"></Loader>
        ) : (
          <Formik
            initialValues={{
              ...initialValues({
                name: name,
                cohorts: cdoc?.cohorts || [],
                units:
                  cdoc?.units.map((unit) => {
                    const unitFounded = units.find(
                      (unitFounded) => unitFounded._id === unit
                    );
                    return {
                      label: unitFounded?.name,
                      value: unitFounded?._id,
                    };
                  }) || [],
                available: cdoc?.available || false,
                allCohorts: cdoc?.allCohorts || false,
              }),
            }}
            onSubmit={(values, actions) => {
              if (onSubmit) onSubmit({ values, actions });
            }}
            validateOnMount={true}
            validateOnChange={formSubmmited}
            validateOnBlur={false}
            validationSchema={schema}
            enableReinitialize
          >
            {({
              touched,
              errors,
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              resetForm,
              setFieldValue,
              isSubmitting,
            }) => {
              return (
                <form
                  className="theme-form"
                  onSubmit={(event) => {
                    setFormSubmmited(true);
                    handleSubmit(event);
                    Object.entries(errors).forEach((item) => {
                      toast.error(item[1] as string);
                    });
                  }}
                  id="invite-academy-modal-form"
                >
                  <Grid.Row style={{ marginBottom: "20px" }}>
                    <Grid.Col>
                      <FlexContainer
                        justify="space-between"
                        style={{ padding: "0px 0px 0px 20px" }}
                      >
                        <FlexContainer
                          direction="column"
                          gap="2px"
                          style={{ maxWidth: "300px" }}
                        >
                          <FlexContainer align="center" gap="1px">
                            <LetterModal lg>Publicación General</LetterModal>
                            <LetterModal lg gray>
                              (Recomendado):
                            </LetterModal>
                          </FlexContainer>
                          <LetterModal gray>
                            El contenido se publicara para todo el publico de
                            Constana Campus, dándole visibilidad a tu Academia y
                            contenidos a nuevos usuarios.
                          </LetterModal>
                        </FlexContainer>
                        <Field
                          style={{
                            width: "20px",
                            height: "20px",
                            boxShadow: "0 0 4px 0 rgba(21, 115, 229, 0.16)",
                          }}
                          type="checkbox"
                          name="available"
                        />
                      </FlexContainer>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row style={{ marginBottom: "20px" }}>
                    <Grid.Col>
                      <FlexContainer
                        justify="space-between"
                        style={{ padding: "0px 0px 0px 20px" }}
                      >
                        <FlexContainer
                          direction="column"
                          gap="2px"
                          style={{ maxWidth: "300px" }}
                        >
                          {" "}
                          <FlexContainer align="center" gap="1px">
                            <LetterModal lg>
                              Compartir entre Cohorts
                            </LetterModal>
                          </FlexContainer>
                          <LetterModal gray>
                            Mostrar a todos los cohorts de la academia
                          </LetterModal>
                        </FlexContainer>
                        <Field
                          style={{
                            width: "20px",
                            height: "20px",
                            boxShadow: "0 0 4px 0 rgba(21, 115, 229, 0.16)",
                          }}
                          type="checkbox"
                          name="allCohorts"
                        />
                      </FlexContainer>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col>
                      <DinamicField
                        name="cohorts"
                        error={errors["cohorts"]}
                        touched={touched["cohorts"]}
                        value={values["cohorts"]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        options={{
                          itemOnClick: {
                            functionItem: (value) =>
                              setActiveAcademy((state) =>
                                state === value ? undefined : value
                              ),
                            value: activeAcademy,
                          },
                          skin: "gray",
                          type: "select",
                          height: "95px",
                          ...(!!academies &&
                            !!cohorts && {
                              data: cohorts
                                .filter(
                                  (cohort) =>
                                    cohort.active &&
                                    cohort.academy._id === current.id
                                )
                                .map((cohort) => {
                                  return {
                                    value: cohort._id,
                                    label: cohort.name,
                                  };
                                }),
                            }),
                          onDelete: (item, index) => {
                            setFieldValue(
                              "units",
                              values["units"].filter(
                                (val) => val.linked !== item
                              )
                            );
                          },
                          loading: !cohorts,
                          inputLabel: "Seleccionar Cohorts (Opcional)",
                          inputPlaceholder: "Selecciona un Cohort",
                        }}
                      />
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col>
                      <DinamicLinked
                        name="units"
                        error={errors["units"]}
                        touched={touched["units"]}
                        value={values["units"]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        options={{
                          notLoading: true,
                          skin: "gray",
                          type: "select",
                          height: "95px",
                          ...(!!units &&
                            !!activeAcademy && {
                              data: units
                                .filter(
                                  (unit) =>
                                    unit.active &&
                                    unit.cohort.includes(activeAcademy)
                                )
                                .map((cohort) => {
                                  return {
                                    linked: activeAcademy,
                                    value: cohort._id,
                                    label: cohort.name,
                                  };
                                }),
                            }),
                          externalData: true,
                          loading: !units,
                          inputLabel:
                            "Seleccionar Módulos del Cohort Seleccionado",
                          inputPlaceholder: "Selecciona un Modulo",
                        }}
                      ></DinamicLinked>
                      <DinamicField
                        name="units"
                        error={errors["units"]}
                        touched={touched["units"]}
                        value={values["units"]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        options={{
                          skin: "gray",
                          type: "select",
                          height: "95px",
                          ...(!!units &&
                            !!activeAcademy && {
                              data: units
                                .filter(
                                  (unit) =>
                                    unit.active &&
                                    unit.cohort.includes(activeAcademy)
                                )
                                .map((cohort) => {
                                  return {
                                    value: cohort._id,
                                    label: cohort.name,
                                  };
                                }),
                            }),
                          externalData: true,
                          loading: !units,
                          inputLabel:
                            "Seleccionar Módulos del Cohort Seleccionado",
                          inputPlaceholder: "Selecciona un Modulo",
                        }}
                      />
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col>
                      <Center gap={"10px"}>
                        <Submit
                          isSubmmiting={isSubmitting}
                          form="invite-academy-modal-form"
                          color="Primary"
                          style={{ width: "170px" }}
                          options={{
                            type: "filled",
                            skin: "primary",
                            size: "lg",
                            marginBottom: "0px",
                          }}
                        >
                          Confirmar
                        </Submit>
                        <Button
                          onClick={resetForm}
                          type="button"
                          options={{
                            type: "outline",
                            skin: "danger",
                            size: "lg",
                            marginBottom: "0px",
                          }}
                          style={{ marginLeft: "10px", width: "170px" }}
                        >
                          Cancelar
                        </Button>
                      </Center>
                    </Grid.Col>
                  </Grid.Row>
                </form>
              );
            }}
          </Formik>
        )}
      </Modal>
    );
  }
);

const states = ({ academyStore, cohortStore, currentStore, unitStore }) => {
  const { data: academies } = academyStore.all;
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: current } = currentStore;
  const { data: units } = unitStore.allUnits;
  const { states: updateStates } = unitStore.updateUnit;
  return { academies, cohorts, current, units, updateStates };
};

export default connect(states)(Component);

/* 





*/
