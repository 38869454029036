import {
  NewTeam,
  NEW_TEAM,
  ResetNewTeam,
  RESET_NEW_TEAM,
  GET_ALL_TEAMS,
  GetAllTeams,
  UpdateTeam,
  UPDATE_TEAM,
  ResetUpdateTeam,
  RESET_UPDATE_TEAM,
  GetTeam,
  DeleteTeam,
  DELETE_TEAM,
  GET_TEAM,
  RESET_DELETE_TEAM,
  NEW_TEAM_INVITE_LINK,
  SELECT_TEAM,
  DELETE_TEAM_MEMBER,
  UPDATE_TEAM_ROLE,
  RESET_TEAM_NEW_INVITE_LINK,
  DELETE_TEAM_INVITE_LINK,
  RESET_TEAM_DELETE_INVITE_LINK,
} from "../types/team";

const create = (payload): NewTeam => {
  return { type: NEW_TEAM, payload };
};
const resetCreate = (): ResetNewTeam => {
  return { type: RESET_NEW_TEAM, payload: null };
};

const update = (payload): UpdateTeam => {
  return { type: UPDATE_TEAM, payload };
};

const resetUpdate = (): ResetUpdateTeam => {
  return { type: RESET_UPDATE_TEAM, payload: null };
};

const getAll = (): GetAllTeams => {
  return { type: GET_ALL_TEAMS, payload: null };
};

const getOne = (payload: { _id: string }): GetTeam => {
  return { type: GET_TEAM, payload };
};

const deleteOne = (payload: { _id: string }): DeleteTeam => {
  return { type: DELETE_TEAM, payload };
};

const resetDeleteOne = () => {
  return { type: RESET_DELETE_TEAM, payload: null };
};

const newLink = (payload) => {
  return { type: NEW_TEAM_INVITE_LINK, payload };
};

const resetNewLink = () => {
  return { type: RESET_TEAM_NEW_INVITE_LINK, payload: null };
};

const deleteLink = (payload) => {
  return { type: DELETE_TEAM_INVITE_LINK, payload };
};

const resetDeleteLink = () => {
  return { type: RESET_TEAM_DELETE_INVITE_LINK, payload: null };
};

const selectOne = (payload) => {
  return { type: SELECT_TEAM, payload };
};

const deleteMember = (payload) => {
  return { type: DELETE_TEAM_MEMBER, payload };
};

const updateRole = (payload) => {
  return { type: UPDATE_TEAM_ROLE, payload };
};

const actions = {
  resetDeleteLink,
  updateRole,
  deleteMember,
  selectOne,
  deleteLink,
  newLink,
  resetNewLink,
  create,
  resetCreate,
  update,
  resetUpdate,
  resetDeleteOne,
  getAll,
  getOne,
  deleteOne,
};

export default actions;
