import styled from "styled-components";

export const CountNumber = styled("h3")`
  margin: 0;
  padding: 0;
  font-family: NirmalaUI;
  font-size: 12px;
  font-weight: bold;
`;

export const DateString = styled("h3")`
  font-family: NirmalaUI;
  font-size: 12px;
  color: #6a6970;
`;

export const TrashIcon = styled("div")`
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 2px;
`;

export const Icon = styled("div")`
  display: block;
  width: 42px;
  height: 42px;
  border-radius: 15px;
  overflow: hidden;
  ${(props) => !!props.margin && `margin:${props.margin};`}
`;

export const Link = styled("a")`
  text-decoration: none;
  display: block;
 
`;
