import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Modal from "../../../../../../components/Modal";
import { Formik } from "formik";
import { Center } from "../../../../../../components/StyledComponents";
import {
  initialValues,
  schema,
} from "../../../../../../constants/form/recruiting/technolgies/create";
import Grid from "../../../../../../components/Grid";
import Button from "../../../../../../components/Button";
import Submit from "../../../../../../components/Form/Submit";
import ReactSelect from "../../../../../../components/Form/ReactSelect";
import techActions from "../../../../../../store/actions/recruiting/index";
import { toast, ToastContainer } from "react-toastify";
import Input from "../../../../../../components/Form/Input";
import TextArea from "../../../../../../components/Form/TextArea";
import { TechSkillDto } from "../../../../../../types/recruiting.dto";
import { hideModal } from "../../../../../../store/actions/modal";
import { useParams } from "react-router-dom";

interface ComponentProps {
  createStates: {
    loading: boolean;
    error: boolean | string;
    success: boolean;
  };
  skills: TechSkillDto[];
}

const Component = ({ createStates, skills }: ComponentProps) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const dispatch = useDispatch();
  const params = useParams();
  const onSubmit = ({ values, actions }) => {
    dispatch(techActions.technology.create({ ...values, team: params.id }));
    setFormActions(actions);
  };

  useEffect(() => {
    if (createStates.success) {
      toast("👌🏼 Tecnología agregada correctamente", {
        containerId: "submit",
        type: "success",
        toastId: "submitsucceful",
      });

      setTimeout(() => {
        dispatch(techActions.technology.resetCreate());
        dispatch(hideModal());
        formActions?.resetForm();
      }, 1000);
    }

    if (createStates.error) {
      toast(`😱 ${createStates.error}`, {
        containerId: "submit",
        type: "error",
        toastId: "submiterror",
      });

      setTimeout(() => {
        dispatch(techActions.technology.resetCreate());
      }, 1000);
    }
  }, [createStates]);

  return (
    <Modal name="new-technology-modal" title="Crear Tecnología">
      <Formik
        initialValues={{ ...initialValues }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="new-evaluate-form"
            >
              <Grid.Row>
                <Grid.Col>
                  <Input
                    name={"name"}
                    error={errors["name"]}
                    touched={touched["name"]}
                    value={values["name"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{ label: "Tecnologia*", skin: "gray" }}
                  />
                </Grid.Col>
                <ReactSelect
                  name="criterions"
                  error={errors["criterions"]}
                  touched={touched["criterions"]}
                  isMulti
                  items={
                    !!skills &&
                    skills
                      .filter((skill) => skill.active)
                      .map((skill) => ({ label: skill.name, value: skill._id }))
                  }
                  placeholder="Skill Tecnico"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={{
                    loading: createStates.loading,
                    label: "Skill Tecnico",
                    marginBottom: 24,
                    skin: "gray",
                  }}
                />
                <TextArea
                  name="description"
                  error={errors["description"]}
                  touched={touched["description"]}
                  value={values["description"]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={{
                    label: "Descripcion",
                    marginBottom: 24,
                    skin: "gray",
                  }}
                />
              </Grid.Row>

              <Grid.Row>
                <Grid.Col>
                  <Center gap={"70px"}>
                    <Button
                      onClick={resetForm}
                      type="button"
                      options={{
                        type: "outline",
                        skin: "gray",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                      style={{ marginLeft: "10px" }}
                    >
                      Cancelar
                    </Button>

                    <Submit
                      isSubmmiting={createStates.loading}
                      form="new-evaluate-form"
                      color="Primary"
                      options={{
                        type: "filled",
                        skin: "primary",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                    >
                      Confirmar
                    </Submit>
                  </Center>
                </Grid.Col>
              </Grid.Row>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const states = ({ recruitingStore }) => {
  const { states: createStates } = recruitingStore.technology.create;
  const { data: skills } = recruitingStore.techSkill.all;
  return {
    skills,
    createStates,
  };
};
export default connect(states)(Component);
