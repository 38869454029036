import styled from "styled-components";

export const CertificateWrapper = styled("div")`
  position: relative;
  min-height: 534px;
  border-radius: 20px;
  border: 2px solid #c3c3c3;
  padding: 20px 24px 21px;
  background-color: #fff;
`;

export const CertificateTitle = styled("h3")`
  font-size: 32px;
  color: #697482;
  font-weight: normal;
`;

export const CertificateSubTitle = styled("h3")`
  font-size: ${(props) => (!!props.size ? props.size : 24)}px;
  color: ${(props) => (!!props.color ? props.color : "#697482")};
  font-weight: normal;
`;
export const CertificateLitteTitle = styled("h3")`
  font-size: ${(props) => (!!props.size ? props.size : "16px")};
  color: ${(props) => (!!props.color ? props.color : "#697482")};
  font-weight: normal;
`;
