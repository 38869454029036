import {
  initialState,
  Actions,
  State,
  GET_ALL_ORGANIZATIONS,
  GET_ALL_ORGANIZATIONS_ERROR,
  GET_ALL_ORGANIZATIONS_SUCCESS,
  GET_ORGANIZATION,
  GET_ORGANIZATION_ERROR,
  GET_ORGANIZATION_SUCCESS,
  NEW_ORGANIZATION,
  NEW_ORGANIZATION_ERROR,
  NEW_ORGANIZATION_SUCCESS,
  RESET_NEW_ORGANIZATION,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_ERROR,
  UPDATE_ORGANIZATION_SUCCESS,
  RESET_UPDATE_ORGANIZATION,
  DELETE_ORGANIZATION,
  DELETE_ORGANIZATION_ERROR,
  DELETE_ORGANIZATION_SUCCESS,
  RESET_DELETE_ORGANIZATION,
  SET_UPDATE_ORGANIZATION_DATA,
  SET_NEW_ORGANIZATION_DATA,
  UPDATE_CURRENT_ORGANIZATION_LIST,
  NEW_ORGANIZATION_INVITE_LINK,
  DELETE_ORGANIZATION_INVITE_LINK_SUCCESS,
  NEW_ORGANIZATION_INVITE_LINK_SUCCESS,
  NEW_ORGANIZATION_INVITE_LINK_ERROR,
  DELETE_ORGANIZATION_INVITE_LINK,
  DELETE_ORGANIZATION_INVITE_LINK_ERROR,
  RESET_ORGANIZATION_DELETE_INVITE_LINK,
  RESET_ORGANIZATION_NEW_INVITE_LINK,
  SELECT_ORGANIZATION,
  ADD_USER_ORGANIZATION,
} from "../types/organization";

const updateReducerArray = (array, data) =>
  array.map((elem) => (elem._id === data._id ? data : elem));

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_ORGANIZATIONS:
      return {
        ...state,
        all: {
          ...state.all,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        all: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_ORGANIZATIONS_ERROR:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case GET_ORGANIZATION:
      return {
        ...state,
        selected: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ORGANIZATION_SUCCESS:
      return {
        ...state,
        selected: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ORGANIZATION_ERROR:
      return {
        ...state,
        selected: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case NEW_ORGANIZATION:
      return {
        ...state,
        create: {
          ...state.create,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_ORGANIZATION_SUCCESS:
      return {
        ...state,
        create: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case NEW_ORGANIZATION_INVITE_LINK:
      return {
        ...state,
        invite: {
          ...state.invite,
          create: {
            data: null,
            states: {
              loading: true,
              error: false,
              success: false,
            },
          },
        },
      };
    case NEW_ORGANIZATION_INVITE_LINK_SUCCESS:
      return {
        ...state,
        invite: {
          ...state.invite,
          create: {
            data: action.payload,
            states: {
              loading: false,
              error: false,
              success: true,
            },
          },
        },
      };
    case NEW_ORGANIZATION_INVITE_LINK_ERROR:
      return {
        ...state,
        invite: {
          ...state.invite,
          create: {
            data: null,
            states: {
              loading: false,
              error: true,
              success: false,
            },
          },
        },
      };
    case RESET_ORGANIZATION_NEW_INVITE_LINK:
      return {
        ...state,
        invite: {
          ...state.invite,
          create: {
            ...state.invite.create,
            states: {
              loading: false,
              error: false,
              success: false,
            },
          },
        },
      };
    case DELETE_ORGANIZATION_INVITE_LINK:
      return {
        ...state,
        invite: {
          ...state.invite,
          delete: {
            states: {
              loading: true,
              error: false,
              success: false,
            },
          },
        },
      };
    case DELETE_ORGANIZATION_INVITE_LINK_SUCCESS:
      return {
        ...state,
        invite: {
          ...state.invite,
          create: {
            ...state.invite.create,
            data: null,
          },
          delete: {
            states: {
              loading: false,
              error: false,
              success: true,
            },
          },
        },
      };
    case DELETE_ORGANIZATION_INVITE_LINK_ERROR:
      return {
        ...state,
        invite: {
          ...state.invite,
          delete: {
            states: {
              loading: false,
              error: true,
              success: false,
            },
          },
        },
      };
    case RESET_ORGANIZATION_DELETE_INVITE_LINK:
      return {
        ...state,
        invite: {
          ...state.invite,
          delete: initialState.invite.delete,
        },
      };
    case SET_NEW_ORGANIZATION_DATA:
      return {
        ...state,
        all: {
          ...state.all,
          data: !!state.all.data
            ? [...state.all.data, action.payload]
            : [action.payload],
        },
      };
    case UPDATE_CURRENT_ORGANIZATION_LIST:
      return {
        ...state,
        all: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: false,
          },
        },
      };
    case SET_UPDATE_ORGANIZATION_DATA:
      return {
        ...state,
        all: {
          ...state.all,
          data: !!state.all.data
            ? updateReducerArray(state.all.data, action.payload)
            : null,
        },
      };
    case ADD_USER_ORGANIZATION:
      return {
        ...state,
        update: {
          states: {
            success: false,
            loading: true,
            error: false,
          },
        },
      };
    case NEW_ORGANIZATION_ERROR:
      return {
        ...state,
        create: {
          ...state.create,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_ORGANIZATION:
      return {
        ...state,
        create: initialState.create,
      };
    case DELETE_ORGANIZATION:
      return {
        ...state,
        delete: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case SELECT_ORGANIZATION: {
      return {
        ...state,
        selected: {
          ...state.selected,
          data: action.payload,
          states: {
            success: true,
            error: false,
            loading: false,
          },
        },
      };
    }
    case DELETE_ORGANIZATION_ERROR:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_ORGANIZATION:
      return {
        ...state,
        delete: initialState.delete,
      };
    case UPDATE_ORGANIZATION:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        update: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_ORGANIZATION_ERROR:
      return {
        ...state,
        update: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_ORGANIZATION:
      return {
        ...state,
        update: initialState.update,
      };
    default:
      return state;
  }
};

export default reducers;
