import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Modal from "../../../../../../components/Modal";
import { Formik } from "formik";
import { Center } from "../../../../../../components/StyledComponents";
import {
  initialValues,
  schema,
} from "../../../../../../constants/form/organization/profile/contact/edit";
import Grid from "../../../../../../components/Grid";
import Button from "../../../../../../components/Button";
import Submit from "../../../../../../components/Form/Submit";
import { toast } from "react-toastify";
import Input from "../../../../../../components/Form/Input";
import academiesActions from "../../../../../../store/actions/academies";
import { hideModal } from "../../../../../../store/actions/modal";
import FileUploader from "../../../../../../components/Form/FileUploader";
import Icon from "../../../../../../components/Icon";
import organizationsActions from "../../../../../../store/actions/organization";
import UploadIcon from "../../../../../../assets/icons/cloud-upload.svg";
import { setFormData } from "../../../../../../helpers/formData";
import services from "../../../../../../services";
import { AcademyDto } from "../../../../../../types/academy.dto";
const Component = ({
  selectedAcademy,
  updateAcademyStates,
  academies,
  current,
  updateOrganizationStates,
}) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [avatarPreview, setAvatarPreview] = useState<any>();
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const dispatch = useDispatch();
  const [currentAcademy, setCurrentAcademy] = useState<AcademyDto>();
  const onSubmit = ({ values, actions }) => {
    if (current.currentSection === "academy") {
      dispatch(
        academiesActions.update({
          _id: current.id,
          body: setFormData(
            {
              ...values,
              ...(!!avatarPreview &&
                !!avatarPreview?.files && {
                  picture: avatarPreview?.files[0],
                }),
            },
            ["picture"]
          ),
        })
      );
    }
    if (current.currentSection === "organization") {
      dispatch(
        organizationsActions.update({
          _id: current.id,
          body: setFormData(
            {
              ...values,
              ...(!!avatarPreview &&
                !!avatarPreview?.files && {
                  picture: avatarPreview?.files[0],
                }),
            },
            ["picture"]
          ),
        })
      );
    }
    setFormActions(actions);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response: any = await services.academy.getOne({
          _id: current.id,
        });
        setCurrentAcademy(response.response);
      } catch (err) {
        setCurrentAcademy(undefined);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (updateAcademyStates.success) {
      toast("👌🏼 Academia Modificada", {
        containerId: "submit",
        type: "success",
        toastId: "submitsucceful",
      });

      setTimeout(() => {
        dispatch(academiesActions.resetUpdate());
        dispatch(hideModal());
        formActions?.resetForm();
      }, 1000);
    }

    if (updateAcademyStates.error) {
      toast(`😱 ${updateAcademyStates.error}`, {
        containerId: "submit",
        type: "error",
        toastId: "submiterror",
      });

      setTimeout(() => {
        dispatch(academiesActions.resetUpdate());
      }, 1000);
    }
  }, [updateAcademyStates]);
  useEffect(() => {
    if (updateOrganizationStates.success) {
      toast("👌🏼 Academia Modificada", {
        containerId: "submit",
        type: "success",
        toastId: "submitsucceful",
      });

      setTimeout(() => {
        dispatch(organizationsActions.resetUpdate());
        dispatch(hideModal());
        formActions?.resetForm();
      }, 1000);
    }

    if (updateOrganizationStates.error) {
      toast(`😱 ${updateOrganizationStates.error}`, {
        containerId: "submit",
        type: "error",
        toastId: "submiterror",
      });

      setTimeout(() => {
        dispatch(organizationsActions.resetUpdate());
      }, 1000);
    }
  }, [updateOrganizationStates]);

  return (
    <Modal name="edit-academy-profile" title="Datos de la Academia">
      {currentAcademy && (
        <Formik
          initialValues={{
            ...initialValues(
              !!currentAcademy && {
                name: currentAcademy.name,
                description: currentAcademy.description || "",
              }
            ),
          }}
          onSubmit={(values, actions) => {
            if (onSubmit) onSubmit({ values, actions });
          }}
          validateOnChange={formSubmmited}
          validateOnBlur={false}
          validationSchema={schema}
          enableReinitialize
        >
          {({
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
            setFieldValue,
            isSubmitting,
          }) => {
            return (
              <form
                className="theme-form"
                onSubmit={(event) => {
                  setFormSubmmited(true);
                  handleSubmit(event);
                }}
                id="edit-academy-profile-form"
              >
                <Grid.Row>
                  <Grid.Col>
                    <Input
                      name={"name"}
                      error={errors["name"]}
                      touched={touched["name"]}
                      value={values["name"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={{ label: "Nombre", skin: "gray" }}
                    />
                    <FileUploader
                      name="picture"
                      error={errors["picture"]}
                      touched={touched["picture"]}
                      placeholder={
                        <Icon icon={UploadIcon} size="24px" color="black" />
                      }
                      onChange={(event) => {
                        setAvatarPreview(event.target);
                        handleChange(event);
                      }}
                      onBlur={handleBlur}
                      accept="image/jpg;image/png;image/jpeg;capture=camera"
                      options={{
                        label: "Foto de Perfil",
                        marginBottom: 24,
                      }}
                    />
                    <Input
                      name={"description"}
                      error={errors["description"]}
                      touched={touched["description"]}
                      value={values["description"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={{
                        label: "A que se dedica la Organización",
                        skin: "gray",
                      }}
                    />
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col>
                    <Center gap={"70px"}>
                      <Button
                        onClick={resetForm}
                        type="button"
                        options={{
                          type: "outline",
                          skin: "gray",
                          size: "lg",
                          marginBottom: "0px",
                        }}
                        style={{ marginLeft: "10px" }}
                      >
                        Cancelar
                      </Button>

                      <Submit
                        isSubmmiting={isSubmitting}
                        form="edit-academy-profile-form"
                        color="Primary"
                        options={{
                          type: "filled",
                          skin: "primary",
                          size: "lg",
                          marginBottom: "0px",
                        }}
                      >
                        Confirmar
                      </Submit>
                    </Center>
                  </Grid.Col>
                </Grid.Row>
              </form>
            );
          }}
        </Formik>
      )}
    </Modal>
  );
};

const states = ({ academyStore, currentStore, organizationStore }) => {
  const { data: selectedAcademy } = academyStore.selected;
  const { states: updateAcademyStates } = academyStore.update;
  const { data: current } = currentStore;
  const { data: academies } = academyStore.all;
  const { states: updateOrganizationStates } = organizationStore.update;
  return {
    selectedAcademy,
    updateAcademyStates,
    academies,
    current,
    updateOrganizationStates,
  };
};

export default connect(states)(Component);
