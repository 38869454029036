import {
  initialState,
  Actions,
  State,
  GET_ALL_TALENTS,
  GET_ALL_TALENTS_ERROR,
  GET_ALL_TALENTS_SUCCESS,
  GET_TALENT,
  GET_TALENT_ERROR,
  GET_TALENT_SUCCESS,
  NEW_TALENT,
  NEW_TALENT_ERROR,
  NEW_TALENT_SUCCESS,
  RESET_NEW_TALENT,
  UPDATE_TALENT,
  UPDATE_TALENT_ERROR,
  UPDATE_TALENT_SUCCESS,
  RESET_UPDATE_TALENT,
  DELETE_TALENT,
  DELETE_TALENT_ERROR,
  DELETE_TALENT_SUCCESS,
  RESET_DELETE_TALENT,
} from "../types/talent";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_TALENTS:
      return {
        ...state,
        allTalents: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_TALENTS_SUCCESS:
      return {
        ...state,
        allTalents: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_TALENTS_ERROR:
      return {
        ...state,
        allTalents: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case GET_TALENT:
      return {
        ...state,
        talent: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_TALENT_SUCCESS:
      return {
        ...state,
        talent: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_TALENT_ERROR:
      return {
        ...state,
        talent: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case NEW_TALENT:
      return {
        ...state,
        newTalent: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_TALENT_SUCCESS:
      return {
        ...state,
        newTalent: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case NEW_TALENT_ERROR:
      return {
        ...state,
        newTalent: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_TALENT:
      return {
        ...state,
        newTalent: initialState.newTalent,
      };
    case DELETE_TALENT:
      return {
        ...state,
        deleteTalent: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_TALENT_SUCCESS:
      return {
        ...state,
        deleteTalent: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_TALENT_ERROR:
      return {
        ...state,
        deleteTalent: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_TALENT:
      return {
        ...state,
        deleteTalent: initialState.deleteTalent,
      };
    case UPDATE_TALENT:
      return {
        ...state,
        updateTalent: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_TALENT_SUCCESS:
      return {
        ...state,
        updateTalent: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_TALENT_ERROR:
      return {
        ...state,
        updateTalent: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_TALENT:
      return {
        ...state,
        updateTalent: initialState.updateTalent,
      };
    default:
      return state;
  }
};

export default reducers;
