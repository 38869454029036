import { MainContainer } from "./styles";

interface propsInterface {
  margin?: number;
  children: any;
}

const Component = ({ children, ...rest }: propsInterface) => {
  return <MainContainer {...rest}>{children}</MainContainer>;
};

export default Component;
