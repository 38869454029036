import {
  Actions,
  State,
  initialValues,
  NEW_EVALUATE,
  NEW_EVALUATE_SUCCESS,
  NEW_EVALUATE_ERROR,
  RESET_NEW_EVALUATE,
  GET_ALL_EVALUATE,
  GET_ALL_EVALUATE_SUCCESS,
  GET_ALL_EVALUATE_ERRROR,
  RESET_GET_ALL_EVALUATE,
  SET_EVALUATE_LIST,
  UPDATE_EVALUATE,
  UPDATE_EVALUATE_SUCCESS,
  UPDATE_EVALUATE_ERROR,
  RESET_UPDATE_EVALUATE,
  NEW_MANY_EVALUATE,
  NEW_MANY_EVALUATE_SUCCESS,
  NEW_MANY_EVALUATE_ERROR,
  RESET_NEW_MANY_EVALUATE,
} from "../types/evaluate";
const reducers = (state = initialValues, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_EVALUATE:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_EVALUATE_SUCCESS:
      return {
        ...state,
        all: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case SET_EVALUATE_LIST:
      return {
        ...state,
        all: {
          ...state.all,
          data: action.payload,
        },
      };
    case GET_ALL_EVALUATE_ERRROR:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_GET_ALL_EVALUATE:
      return {
        ...state,
        all: initialValues.all,
      };

    case NEW_EVALUATE:
      return {
        ...state,
        newEvaluate: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_EVALUATE_SUCCESS:
      return {
        ...state,
        newEvaluate: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case NEW_EVALUATE_ERROR:
      return {
        ...state,
        newEvaluate: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };

    case NEW_MANY_EVALUATE:
      return {
        ...state,
        newManyEvaluate: {
          data: null,
          states: {
            error: false,
            loading: true,
            success: false,
          },
        },
      };
    case NEW_MANY_EVALUATE_SUCCESS:
      return {
        ...state,

        newManyEvaluate: {
          data: action.payload,
          states: {
            error: false,
            loading: false,
            success: true,
          },
        },
      };
    case NEW_MANY_EVALUATE_ERROR:
      return {
        ...state,
        newManyEvaluate: {
          data: null,
          states: {
            error: action.payload,
            loading: false,
            success: false,
          },
        },
      };
    case RESET_NEW_MANY_EVALUATE:
      return {
        ...state,
        newManyEvaluate: initialValues.newManyEvaluate,
      };
    case RESET_NEW_EVALUATE:
      return {
        ...state,
        newEvaluate: initialValues.newEvaluate,
      };
    case UPDATE_EVALUATE:
      return {
        ...state,
        update: {
          states: {
            success: false,
            error: false,
            loading: true,
          },
        },
      };
    case UPDATE_EVALUATE_SUCCESS:
      return {
        ...state,
        update: {
          states: {
            success: true,
            error: false,
            loading: false,
          },
        },
      };
    case UPDATE_EVALUATE_ERROR:
      return {
        ...state,
        update: {
          states: {
            success: false,
            error: action.payload,
            loading: true,
          },
        },
      };
    case RESET_UPDATE_EVALUATE: {
      return {
        ...state,
        update: initialValues.update,
      };
    }
    default:
      return state;
  }
};

export default reducers;
