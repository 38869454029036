import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../../../../components/Loader";
import academyActions from "../../../../store/actions/academies";
import organizationActions from "../../../../store/actions/organization";
import { StateDto } from "../../../../types/states.dto";
import { useDispatch } from "react-redux";
import { UserDto } from "../../../../types/user.dto";
import { AcademyDto } from "../../../../types/academy.dto";
import { OrganizationDto } from "../../../../types/organization.dto";
import { ToastContainer, toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { setCurrent } from "../../../../store/actions/current";
import { Roles } from "../../../../types/current.dto";

const entities = {
  academy: "academy",
  organization: "organization",
};

const Component = ({
  academies,
  organizations,
  updateAcademyStates,
  updateOrganizationStates,
  user,
}: {
  academies: AcademyDto[];
  organizations: OrganizationDto[];
  updateAcademyStates: StateDto;
  updateOrganizationStates: StateDto;
  user: UserDto;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (!!academies && !!organizations && !!searchParams) {
      if (searchParams.get(entities.organization)) {
        const queryOrganization = searchParams.get(entities.organization);
        const organizationFounded = organizations.find(
          (academy) =>
            academy._id === queryOrganization &&
            academy.users.find((userF) => userF.user === user._id)
        );
        const roleOrganization = organizationFounded?.users.find(
          (userF) => userF.user === user._id
        )?.role;
        if (!!organizationFounded && !!roleOrganization) {
          dispatch(
            setCurrent({
              currentSection: entities.organization,
              id: organizationFounded._id,
              role: roleOrganization,
            })
          );
          navigate(`/dashboard/management/profile`);
        } else {
          toast.error(
            `No te encuentras en la organizacion o la organizacion no existe`
          );
          setTimeout(() => {
            navigate(`/dashboard`);
          }, 2000);
        }
      }
      if (searchParams.get(entities.academy)) {
        const queryAcademy = searchParams.get(entities.academy);
        const academyFounded = academies.find(
          (academy) =>
            academy._id === queryAcademy &&
            academy.users.find((userF) => userF.user === user._id)
        );
        const roleAcademy = academyFounded?.users.find(
          (userF) => userF.user === user._id
        )?.role;
        if (!!academyFounded && !!roleAcademy) {
          dispatch(
            setCurrent({
              currentSection: entities.academy,
              id: academyFounded._id,
              role: roleAcademy,
            })
          );
          if (roleAcademy === "PROFESSOR") {
            navigate(`/dashboard/cohorts/list`);
          } else if (roleAcademy === "OBSERVER") {
            navigate(`/dashboard/dashboard/cohorts/cohort`);
          } else {
            navigate(`/dashboard/management/profile`);
          }
        } else {
          toast.error(
            `No te encuentras en la organizacion o la organizacion no existe`
          );
          setTimeout(() => {
            navigate(`/dashboard`);
          }, 2000);
        }
      }
    }
  }, [searchParams, academies, organizations]);
  useEffect(() => {
    if (!academies) dispatch(academyActions.getAll());
    if (!organizations) dispatch(organizationActions.getAll());
  }, []);

  return (
    <>
      <Wrapper>
        <Loader color="Primary"></Loader>
      </Wrapper>
      <ToastContainer limit={1}></ToastContainer>
    </>
  );
};

const states = ({ academyStore, organizationStore, userStore }) => {
  const { data: academies } = academyStore.all;
  const { data: organizations } = organizationStore.all;
  const { states: updateAcademyStates } = academyStore.update;
  const { states: updateOrganizationStates } = organizationStore.update;
  const { data: user } = userStore;
  return {
    academies,
    organizations,
    updateAcademyStates,
    updateOrganizationStates,
    user,
  };
};

export default connect(states)(Component);
