import styled from "styled-components";

export const ModalSubTitle = styled("h3")`
  color: #707070;
  font-size: 12px;
  word-break: break-word;
  max-width: 307px;
`;

export const ModalTextField = styled("h3")`
  font-size: 14px;
  font-weight: normal;
  color: #697482;
`;

export const ModalInputWrapper = styled("div")`
  position: relative;
  border-radius: 5px;
  
  padding: 5px 20px;
  background-color: #fff;
  &::before {
    content: "";
    position: absolute;
    display: ${(props) => (props.disabled ? "block" : "none")};
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(195, 195, 195, 0.3);
    cursor: not-allowed;
    z-index: 2;
  }
`;

export const ModalInputLitte = styled("Input")`
  text-align: center;
  width: 32px;
  height: 18px;
  border-radius: 3px;
  background-color: #fff;
  color: #697482;
`;

export const HiddenInput = styled("input")`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  outline: 0;
  border: 1px solid red;
`;
