import action from "../helpers/rest-client";

export class ErrorUser extends Error {}

class User {
  public new(payload) {
    return action
      .Post({
        url: "/users",
        body: { ...payload, roles: ["MANAGER"], comments: "", active: true },
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public update({ _id, ...rest }) {
    return action
      .Patch({
        url: `/users/${_id}`,
        body: rest.body,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getAll(filters) {
    const queryString = new URLSearchParams(filters).toString();

    return action
      .Get({
        url: `/users?${queryString}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getOne(_id) {
    return action
      .Get({
        url: `/users/${_id}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public forgottenPassword({ _id, ...rest }) {
    return action
      .Patch({
        url: `/users/${_id}/update-password`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const user = new User();
export default user;
