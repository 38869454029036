import { createRef, ReactElement, useState } from "react";
import { useFormikFieldValidation } from "../../../hooks/useFormikFieldValidation";
import { Input, ClearInput } from "./styles";
import {
  FieldContainer,
  Field,
  Label,
  FieldBefore,
  FieldAfter,
  HelperLine,
  HelperText,
  ExplainText,
  ErrorMessage,
  CharacterCounter,
} from "../Field";
import Icon from "../../Icon";
import UploadIcon from "../../../assets/icons/cloud-upload.svg";
import ClearInputIcon from "../../../assets/icons/cross-circle-red.svg";
import { FaCloudUploadAlt } from "react-icons/fa";

const initialValue = {
  options: {
    skin: "base",
    size: "md",
  },
};

interface ComponentProps {
  name?: string;
  value?: string | number;
  disabled?: boolean;
  error?: {};
  touched?: {};
  placeholder?: string | ReactElement;
  onFocus?: (data: any) => void;
  onBlur?: (data: any) => void;
  onChange?: (data: any) => void;
  min?: string | number;
  max?: string | number;
  autocomplete?: "off" | "on" | "new-password";
  accept?: string;
  options: {
    label?: string;
    skin?: "base";
    size?: "md";
    helperText?: string;
    explainText?: string;
    before?: any;
    after?: any;
    marginBottom?: number;
    hidden?: boolean;
  };
}

const Component = ({
  options = {},
  disabled,
  name,
  error,
  touched,
  max,
  placeholder,
  onFocus,
  onBlur,
  onChange,
  ...rest
}: ComponentProps) => {
  const [fileName, setFileName] = useState("");
  const { classes, setActive } = useFormikFieldValidation(
    error,
    name,
    disabled
  );
  const inputRef = createRef<HTMLInputElement>();

  const { skin, size, marginBottom, hidden } = {
    ...initialValue.options,
    ...options,
  };
  const [numCharacters, setNumCharacters] = useState(0);

  const clearInput = () => {
    if (!!inputRef && !!inputRef?.current) {
      setFileName("");
      inputRef.current.value = "";
    }
  };

  const handleOnChange = (event, callback) => {
    const { value } = event.target;
    setNumCharacters(value.length);
    callback && callback(event);
  };

  const handleOnBlur = (event, callback) => {
    setActive(false);
    callback && callback(event);
  };

  const handleOnFocus = (event, callback) => {
    setActive(true);
    callback && callback(event);
  };

  return (
    <FieldContainer
      marginBottom={marginBottom}
      {...(hidden && { style: { display: "none" } })}
    >
      {options?.label && (
        <Label
          size={options?.size}
          htmlFor={`input-${name}`}
          style={!!options?.explainText ? { marginBottom: "0px" } : {}}
        >
          {options.label}
        </Label>
      )}

      {!!options?.explainText && (
        <ExplainText>{options?.explainText}</ExplainText>
      )}

      <Field
        skin={skin}
        size={size}
        className={classes}
        style={{ padding: "0px", border: "0px" }}
      >
        {options?.before && (
          <FieldBefore size={options?.size} className="field-before">
            {options.before}
          </FieldBefore>
        )}

        <label
          htmlFor={name}
          style={{
            backgroundColor: "#f5f5f5",
            width: "100%",
            borderRadius: "15px",
            height: "38px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          {!!placeholder ? (
            placeholder
          ) : (
            <FaCloudUploadAlt size={25} color={"#000"} />
          )}
          {!!fileName && <span style={{ marginLeft: "10px" }}>{fileName}</span>}
        </label>

        {!!fileName && (
          <ClearInput
            style={{ marginLeft: "10px" }}
            onClick={() => clearInput()}
          >
            <Icon icon={ClearInputIcon} size="18px" color="red" />
          </ClearInput>
        )}

        <Input
          style={{ display: "none" }}
          id={name}
          type="file"
          disabled={disabled}
          ref={inputRef}
          onChange={(event) => {
            const files = event.currentTarget?.files;

            if (files && files.length > 0) {
              setFileName(files[0].name);
            }

            handleOnChange(event, onChange);
          }}
          onBlur={(event) => handleOnBlur(event, onBlur)}
          onFocus={(event) => handleOnFocus(event, onFocus)}
          onWheel={(event) => event.currentTarget.blur()}
          {...rest}
        />

        {options?.after && (
          <FieldAfter size={options?.size} className="field-after">
            {options && options.after}
          </FieldAfter>
        )}
      </Field>

      {((options && options.helperText) || max || error) && (
        <HelperLine marginBottom={marginBottom}>
          {!!error && !!touched && <ErrorMessage>{error}</ErrorMessage>}
          {options && options.helperText && !error && (
            <HelperText>{options.helperText}</HelperText>
          )}
          {max && (
            <CharacterCounter errors={!!error && !!touched}>
              {numCharacters} / {max}
            </CharacterCounter>
          )}
        </HelperLine>
      )}
    </FieldContainer>
  );
};

export default Component;
