import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Modal from "../../../../../../components/Modal";
import { Formik } from "formik";
import { Center } from "../../../../../../components/StyledComponents";
import {
  initialValues,
  schema,
} from "../../../../../../constants/form/recruiting/tech-skills/edit";
import Grid from "../../../../../../components/Grid";
import Button from "../../../../../../components/Button";
import Submit from "../../../../../../components/Form/Submit";
import ReactSelect from "../../../../../../components/Form/ReactSelect";
import { toast, ToastContainer } from "react-toastify";
import TextArea from "../../../../../../components/Form/TextArea";
import Input from "../../../../../../components/Form/Input";
import techSkillsActions from "../../../../../../store/actions/recruiting/tech-skill";
import { useRecruitingLayout } from "../../../../../../provider/RecruitingProvider";
import { TechnologyDto } from "../../../../../../types/recruiting.dto";
import { hideModal } from "../../../../../../store/actions/modal";

interface ComponentProps {
  updateStates: {
    loading: boolean;
    error: boolean | string;
    success: boolean;
  };
  techs: TechnologyDto[];
}

const Component = ({ updateStates, techs }: ComponentProps) => {
  const { selectedItem } = useRecruitingLayout();
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const dispatch = useDispatch();

  const onSubmit = ({ values, actions }) => {
    dispatch(techSkillsActions.update({ ...values, ...selectedItem }));
    setFormActions(actions);
  };

  useEffect(() => {
    if (updateStates.success) {
      toast("👌🏼 Skill editado correctamente", {
        containerId: "submit",
        type: "success",
        toastId: "submitsucceful",
      });

      setTimeout(() => {
        dispatch(techSkillsActions.resetUpdate());
        dispatch(hideModal());
        formActions?.resetForm();
      }, 1000);
    }
    if (updateStates.error) {
      toast(`😱 ${updateStates.error}`, {
        containerId: "submit",
        type: "error",
        toastId: "submiterror",
      });

      setTimeout(() => {
        dispatch(techSkillsActions.resetUpdate());
      }, 1000);
    }
  }, [updateStates]);
  return (
    <Modal name="edit-skill-modal" title="Editar Skill">
      <Formik
        initialValues={{ ...initialValues(selectedItem?.data) }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="new-evaluate-form"
            >
              <Grid.Row>
                <Grid.Col>
                  <Input
                    name={"name"}
                    error={errors["name"]}
                    touched={touched["name"]}
                    value={values["name"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{ label: "Skill Tecnico *", skin: "gray" }}
                  />
                  <ReactSelect
                    name="abilities"
                    error={errors["abilities"]}
                    touched={touched["abilities"]}
                    isMulti
                    items={
                      !!techs &&
                      techs
                        .filter((tech) => tech.active)
                        .map((tech) => ({
                          value: tech._id,
                          label: tech.name,
                        }))
                    }
                    placeholder="Selecciona una tecnología"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      /* loading: !abilities || abilitiesStates.loading, */
                      label: "Asignar Tecnologia/s",
                      marginBottom: 24,
                      skin: "gray",
                    }}
                  />
                  <TextArea
                    name="description"
                    error={errors["description"]}
                    touched={touched["description"]}
                    value={values["description"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "Descripcion",
                      marginBottom: 24,
                      skin: "gray",
                    }}
                  />
                </Grid.Col>
              </Grid.Row>

              <Grid.Row>
                <Grid.Col>
                  <Center gap={"70px"}>
                    <Button
                      onClick={resetForm}
                      type="button"
                      options={{
                        type: "outline",
                        skin: "gray",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                      style={{ marginLeft: "10px" }}
                    >
                      Cancelar
                    </Button>

                    <Submit
                      isSubmmiting={isSubmitting}
                      form="new-evaluate-form"
                      color="Primary"
                      options={{
                        type: "filled",
                        skin: "primary",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                    >
                      Confirmar
                    </Submit>
                  </Center>
                </Grid.Col>
              </Grid.Row>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};
const states = ({ recruitingStore }) => {
  const { data: techs } = recruitingStore.technology.all;
  const { states: updateStates } = recruitingStore.techSkill.update;
  return {
    techs,
    updateStates,
  };
};
export default connect(states)(Component);
