import Spinner from "../../Loader";
import { Button } from "./style";

interface Options {
  type: "filled" | "outline" | "link";
  size: "xs" | "sm" | "md" | "lg" | "xl";
  skin: "primary" | "secondary" | "gray" | "transparent" | "white" | "violet";
  block?: boolean;
  marginBottom?: string;
  loading?: "Primary" | "Secondary" | "White" | "Danger" | "Violet";
  disabled?: boolean;
}

const defaultOptions: Options = {
  skin: "primary",
  size: "lg",
  type: "filled",
  block: false,
  marginBottom: "0px",
};

interface ComponentProps {
  isSubmmiting: boolean;
  type?: "submit" | "button";
  onClick?: () => void;
  options?: Options;
  form: string;
  children: any;
  style?: any;
  color?: string;
}

const Component = ({
  isSubmmiting,
  children,
  options,
  type = "submit",
  ...rest
}: ComponentProps) => {
  return (
    <Button
      options={{
        ...defaultOptions,
        ...options,
      }}
      type={type}
      disabled={isSubmmiting || options?.disabled}
      {...rest}
    >
      {isSubmmiting ? <Spinner color={options?.loading} /> : children}
    </Button>
  );
};

export default Component;
