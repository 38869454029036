import {
  GetAllStudents,
  GET_ALL_STUDENTS,
  GetStudent,
  GET_STUDENT,
  UpdateStudent,
  UPDATE_STUDENT,
  DeleteStudent,
  DELETE_STUDENT,
  GetStudentSuccess,
  GET_STUDENT_SUCCESS,
  NewStudent,
  NEW_STUDENT,
  ResetNewStudent,
  RESET_NEW_STUDENT,
  ResetUpdateStudent,
  RESET_UPDATE_STUDENT,
  ResetDeleteStudent,
  RESET_DELETE_STUDENT,
} from "../types/student";

export const getAllStudents = (): GetAllStudents => {
  return { type: GET_ALL_STUDENTS, payload: null };
};

export const getStudent = (id): GetStudent => {
  return { type: GET_STUDENT, payload: id };
};

export const setSelectedStudent = (payload): GetStudentSuccess => {
  return { type: GET_STUDENT_SUCCESS, payload };
};

export const newStudent = (payload): NewStudent => {
  return { type: NEW_STUDENT, payload };
};

export const resetNewStudent = (): ResetNewStudent => {
  return { type: RESET_NEW_STUDENT, payload: null };
};

export const updateStudent = (id): UpdateStudent => {
  return { type: UPDATE_STUDENT, payload: id };
};

export const resetUpdateStudent = (): ResetUpdateStudent => {
  return { type: RESET_UPDATE_STUDENT, payload: null };
};

export const deleteStudent = (id): DeleteStudent => {
  return { type: DELETE_STUDENT, payload: id };
};

export const resetDeleteStudent = (): ResetDeleteStudent => {
  return { type: RESET_DELETE_STUDENT, payload: null };
};
