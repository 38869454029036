import React from "react";
import { useDispatch } from "react-redux";
import Icon from "../../../../../../components/Icon";
import { showModal } from "../../../../../../store/actions/modal";
import { IconWrapper } from "./style";
import { FaRegEdit } from "react-icons/fa";
interface ComponentProps {
  modalName: string;
}

const Component = ({ modalName }: ComponentProps) => {
  const dispatch = useDispatch();
  return (
    <IconWrapper
      onClick={() => {
        dispatch(showModal(modalName));
      }}
    >
      <FaRegEdit fontSize={16}></FaRegEdit>
    </IconWrapper>
  );
};

export default Component;
