import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Button from "../../../../../components/Button";
import ReactSelect from "../../../../../components/Form/ReactSelect";
import { FlexContainer } from "../../../../../components/StyledComponents";
import {
  deleteMember,
  getAllCohorts,
  resetGetAllCohortsData,
} from "../../../../../store/actions/cohort";
import academyActions from "../../../../../store/actions/academies";
import invitationActions from "../../../../../store/actions/invitation";
import { AcademyDto } from "../../../../../types/academy.dto";
import { CohortDto } from "../../../../../types/cohort.dto";
import { CurrentDto } from "../../../../../types/current.dto";
import { UsersDto } from "../../../../../types/users.dto";
import CohortModal from "../../../Academy/Gestion/components/MembersList/components/CohortModal";
import { Img } from "../../../Cohort/Customize/style";
import { EntityImageContainer } from "../../../Cohort/Events/style";
import { CardTitle, EntityTitleSemiBold, ListItem } from "../../style";
import { Formik } from "formik";
import { schema } from "../../../../../constants/form/auth/sign-in";
import Grid from "../../../../../components/Grid";
import { useDispatch } from "react-redux";
import { InvitationDto } from "../../../../../types/invitation.dto";
import Loader from "../../../../../components/Loader";
import { UserDto } from "../../../../../types/user.dto";
import ModalConfirmDelete from "../../../../../components/ModalConfirmDelete";
import { StateDto } from "../../../../../types/states.dto";
import { showModal } from "../../../../../store/actions/modal";
import usersActions from "../../../../../store/actions/users";
import InfinityScroll from "../../../../../components/InfinityScroll";
import { FetchData } from "../../../../../types/fetchData.dto";
import services from "../../../../../services";
import { toast } from "react-toastify";
import { filterCohortByRole } from "../../../../../helpers/roleAcademy";
interface ComponentProps {
  cohorts: CohortDto[];
  section: "academy" | "cohort";
  current: CurrentDto;
  users: UsersDto[];
  academies: AcademyDto[];
  invitations: InvitationDto[];

  user: UserDto;
  deleteAcademyMemberStates: StateDto;
  cohortStates;
  updateInvitationStates: StateDto;
  updateInvitationData: InvitationDto;
  invitationStates: StateDto;
  newManyInvitations: InvitationDto[];
  newManyInvitationsStates: StateDto;
  deletedInvitation: InvitationDto;
  deleteInvitationStates: StateDto;
}

const ComponentCohort = ({
  section,
  cohorts,
  current,
  users,
  academies,
  invitations,
  user,
  deleteAcademyMemberStates,
  cohortStates,
  updateInvitationStates,
  updateInvitationData,
  invitationStates,
  newManyInvitations,
  newManyInvitationsStates,
  deletedInvitation,
  deleteInvitationStates,
}: ComponentProps) => {
  const [selectedCohort, setSelectedCohort] = useState<CohortDto>();
  const [action, setAction] = useState<{ action: string; data: any }>();
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);
  const [paginationOn, setPaginationOn] = useState<boolean>(false);
  const [currentInvitationsList, setCurrentInvitationsList] = useState<
    InvitationDto[]
  >([]);
  const [usersFromInvitations, setUsersFromInvitations] =
    useState<UsersDto[]>();
  const dispatch = useDispatch();
  const [fetchOn, setFetchOn] = useState<boolean>(false);
  const fetchData = ({ page, filterBy }: FetchData) => {
    if (page === 0) {
      setPaginationOn(true);
    }
    let payload = {
      limit: 4,
      offset: 4 * page,
      filterBy: {
        ...(filterBy ? filterBy : {}),
        ...(selectedCohort && { cohort: selectedCohort?._id }),
        status: { $in: ["PENDING", "AWAITING SIGNUP"] },
        active: true,
      },
    };
    const sortBy = "createdAt";

    const sortDirection = "desc";

    dispatch(invitationActions.getAll({ ...payload, sortBy, sortDirection }));
  };

  useEffect(() => {
    if (!!action) {
      dispatch(showModal("confirm-delete-item-secuencial-modal"));
    }
  }, [action]);

  useEffect(() => {
    if (paginationOn) {
      if (!isLoading && invitations && invitations.length > 0) {
        setCurrentInvitationsList((state) => [
          ...(state ?? []),
          ...invitations,
        ]);
      }
      setThereAreItems(invitations && invitations?.length > 0);
    }
  }, [invitations, isLoading, paginationOn]);
  useEffect(() => {
    if (currentInvitationsList && paginationOn) {
      const allEmails = Array.from(
        new Set(currentInvitationsList.map((invitation) => invitation.email))
      );
      const fetchUsers = async (emails) => {
        const response: any = await services.users.getAll({
          filterBy: { email: { $in: emails } },
        });
        if (response.response) {
          setUsersFromInvitations(response.response);
        }
      };
      fetchUsers(allEmails);
    }
  }, [currentInvitationsList, paginationOn]);

  useEffect(() => {
    if (newManyInvitations) {
      setCurrentInvitationsList((state) =>
        state.map((st) => {
          const foundedElement = newManyInvitations.filter(
            (manyInvitation) => manyInvitation._id === st._id
          )[0];
          if (foundedElement) {
            return foundedElement;
          }
          return st;
        })
      );
    }
  }, [newManyInvitations]);

  useEffect(() => {
    if (deleteInvitationStates.success) {
      setCurrentInvitationsList((state) =>
        state.filter((st) => st._id !== deletedInvitation._id)
      );
    }
  }, [deleteInvitationStates]);

  useEffect(() => {
    if (newManyInvitationsStates.success) {
      dispatch(invitationActions.resetNewMany());
    }
    if (newManyInvitationsStates.error) {
      toast.error(newManyInvitationsStates.error);
      dispatch(invitationActions.resetNewMany());
    }
  }, [newManyInvitationsStates]);

  useEffect(() => {
    if (paginationOn) {
      setIsLoading(invitationStates.loading);
    }
  }, [invitationStates]);

  useEffect(() => {
    if (!cohorts && fetchOn)
      dispatch(
        getAllCohorts({ filterBy: { active: true, academy: current.id } })
      );
  }, [cohorts, fetchOn]);

  useEffect(() => {
    if (selectedCohort && paginationOn) {
      fetchData({ page: 0 });
      setCurrentInvitationsList([]);
      setUsersFromInvitations(undefined);
    }
  }, [selectedCohort]);

  useEffect(() => {
    if (!academies && fetchOn)
      dispatch(academyActions.getAll({ filterBy: { _id: current.id } }));
  }, [academies, fetchOn]);

  useEffect(() => {
    if (!academies && !invitations && !cohorts) {
      setFetchOn(true);
    }
  }, [academies, invitations, cohorts]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllCohortsData());
      dispatch(invitationActions.resetGetAll());
      dispatch(academyActions.resetGetAllAcademiesData());
    };
  }, []);

  return (
    <>
      <CardTitle>Invitaciones</CardTitle>

      <Formik
        initialValues={{}}
        onSubmit={(values, actions) => {}}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <Grid.Row>
              <Grid.Col sm={8}>
                <ReactSelect
                  name="private"
                  {...(!!cohorts && {
                    items: filterCohortByRole(
                      cohorts,
                      user._id,
                      current.role
                    ).map((cohort) => {
                      return {
                        value: cohort._id,
                        label: cohort.name,
                      };
                    }),
                  })}
                  onOptionSelected={(newValue) => {
                    const cohortFounded = cohorts.filter(
                      (cohort) => cohort._id === newValue.value
                    )[0];

                    setSelectedCohort(cohortFounded);
                  }}
                  style={{ borderRadius: "5px" }}
                  placeholder="Seleccione un cohort"
                  options={{
                    label: "Cohort",
                    marginBottom: 20,
                    skin: "base",
                    loading: !cohorts,
                  }}
                />
              </Grid.Col>
            </Grid.Row>
          );
        }}
      </Formik>

      {selectedCohort && (
        <InfinityScroll
          action={fetchData}
          page={page}
          setPage={setPage}
          data={currentInvitationsList}
          active={thereAreItems}
          isLoading={isLoading}
        >
          <FlexContainer
            direction="column"
            gap="10px"
            style={{ maxHeight: "390px", overflowY: "auto" }}
          >
            {currentInvitationsList?.map((invitation) => {
              const userFounded = usersFromInvitations?.find(
                (userF) => userF.email === invitation.email && userF.active
              );

              return (
                <ListItem>
                  <FlexContainer justify="space-between" align="center">
                    <FlexContainer align="center" gap="10px">
                      {userFounded ? (
                        <>
                          <EntityImageContainer>
                            <Img src={userFounded?.picture}></Img>
                          </EntityImageContainer>
                          <EntityTitleSemiBold>
                            {`${userFounded?.name} ${userFounded?.lastName}`}
                          </EntityTitleSemiBold>
                        </>
                      ) : (
                        <EntityTitleSemiBold>
                          {invitation.email}
                        </EntityTitleSemiBold>
                      )}
                      <EntityTitleSemiBold>
                        {`(Como ${invitation.role})`}
                      </EntityTitleSemiBold>
                    </FlexContainer>

                    <FlexContainer gap="20px" align="center">
                      <Button
                        type="button"
                        onClick={() => {
                          dispatch(
                            invitationActions.deleteOne({
                              _id: invitation._id,
                            })
                          );
                        }}
                        style={{ height: "26px" }}
                        loading={deleteInvitationStates.loading}
                        options={{
                          size: "md",
                          type: "filled",
                          skin: "danger",
                        }}
                      >
                        Eliminar Invitacion
                      </Button>
                    </FlexContainer>
                  </FlexContainer>
                </ListItem>
              );
            })}
          </FlexContainer>
        </InfinityScroll>
      )}

      {action?.action === "remove-from-academy-member" && (
        <ModalConfirmDelete
          bntConfig={{
            content: "Quitar",
            style: {
              options: {
                type: "outline",
                skin: "danger",
                size: "lg",
                marginBottom: "0px",
              },
            },
          }}
          states={deleteAcademyMemberStates}
          title={
            <span>
              {`¿Seguro que quiere eliminar de la academia a `}
              <span style={{ fontWeight: "bold", color: "black" }}>
                {action.data.name}
              </span>
              ?
            </span>
          }
          elementActions={academyActions.deleteMember(action.data)}
          resetAction={academyActions.resetUpdate}
          resetState={setAction}
        ></ModalConfirmDelete>
      )}
    </>
  );
};

const ComponentAcademy = ({
  section,
  cohorts,
  current,
  users,
  academies,
  invitations,
  user,
  deleteAcademyMemberStates,
  cohortStates,
  updateInvitationStates,
  updateInvitationData,
  invitationStates,
  newManyInvitations,
  newManyInvitationsStates,
  deletedInvitation,
  deleteInvitationStates,
}: ComponentProps) => {
  const [action, setAction] = useState<{ action: string; data: any }>();
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);
  const [paginationOn, setPaginationOn] = useState<boolean>(false);
  const [currentInvitationsList, setCurrentInvitationsList] = useState<
    InvitationDto[]
  >([]);
  const [usersFromInvitations, setUsersFromInvitations] =
    useState<UsersDto[]>();
  const dispatch = useDispatch();

  const fetchData = ({ page, filterBy }: FetchData) => {
    if (page === 0) {
      setPaginationOn(true);
    }
    let payload = {
      limit: 4,
      offset: 4 * page,
      filterBy: {
        ...(filterBy ? filterBy : {}),

        academy: current.id,
        status: { $in: ["PENDING", "AWAITING SIGNUP"] },
        active: true,
      },
    };
    const sortBy = "createdAt";

    const sortDirection = "desc";

    dispatch(invitationActions.getAll({ ...payload, sortBy, sortDirection }));
  };

  useEffect(() => {
    if (!!action) {
      dispatch(showModal("confirm-delete-item-secuencial-modal"));
    }
  }, [action]);

  useEffect(() => {
    if (paginationOn) {
      if (!isLoading && invitations && invitations.length > 0) {
        setCurrentInvitationsList((state) => [
          ...(state ?? []),
          ...invitations,
        ]);
      }
      setThereAreItems(invitations && invitations?.length > 0);
    }
  }, [invitations, isLoading, paginationOn]);

  useEffect(() => {
    if (currentInvitationsList && paginationOn) {
      const allEmails = Array.from(
        new Set(currentInvitationsList.map((invitation) => invitation.email))
      );
      const fetchUsers = async (emails) => {
        const response: any = await services.users.getAll({
          filterBy: { email: { $in: emails } },
        });
        if (response.response) {
          setUsersFromInvitations(response.response);
        }
      };
      fetchUsers(allEmails);
    }
  }, [currentInvitationsList, paginationOn]);

  useEffect(() => {
    if (newManyInvitations) {
      setCurrentInvitationsList((state) =>
        state.map((st) => {
          const foundedElement = newManyInvitations.filter(
            (manyInvitation) => manyInvitation._id === st._id
          )[0];
          if (foundedElement) {
            return foundedElement;
          }
          return st;
        })
      );
    }
  }, [newManyInvitations]);

  useEffect(() => {
    if (newManyInvitationsStates.success) {
      dispatch(invitationActions.resetNewMany());
    }
    if (newManyInvitationsStates.error) {
      toast.error(newManyInvitationsStates.error);
      dispatch(invitationActions.resetNewMany());
    }
  }, [newManyInvitationsStates]);

  useEffect(() => {
    if (paginationOn) {
      setIsLoading(invitationStates.loading);
    }
  }, [invitationStates]);

  useEffect(() => {
    if (deleteInvitationStates.success) {
      setCurrentInvitationsList((state) =>
        state.filter((st) => st._id !== deletedInvitation._id)
      );
    }
  }, [deleteInvitationStates]);

  useEffect(() => {
    if (!cohorts)
      dispatch(
        getAllCohorts({ filterBy: { active: true, academy: current.id } })
      );
  }, [cohorts]);

  useEffect(() => {
    if (!academies)
      dispatch(academyActions.getAll({ filterBy: { _id: current.id } }));
  }, [academies]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllCohortsData());
      dispatch(invitationActions.resetGetAll());
      dispatch(usersActions.resetGetAll());
      dispatch(academyActions.resetGetAllAcademiesData());
    };
  }, []);

  return (
    <>
      <CardTitle>Invitaciones</CardTitle>

      <InfinityScroll
        action={fetchData}
        page={page}
        setPage={setPage}
        data={invitations}
        active={thereAreItems}
        isLoading={isLoading}
      >
        <FlexContainer
          direction="column"
          gap="10px"
          style={{ maxHeight: "390px", overflowY: "auto" }}
        >
          {currentInvitationsList?.map((invitation) => {
            const userFounded = usersFromInvitations?.find(
              (userF) => userF.email === invitation.email && userF.active
            );

            return (
              <ListItem>
                <FlexContainer justify="space-between" align="center">
                  <FlexContainer align="center" gap="10px">
                    {userFounded ? (
                      <>
                        <EntityImageContainer>
                          <Img src={userFounded?.picture}></Img>
                        </EntityImageContainer>
                        <EntityTitleSemiBold>
                          {`${userFounded?.name} ${userFounded?.lastName}`}
                        </EntityTitleSemiBold>
                      </>
                    ) : (
                      <EntityTitleSemiBold>
                        {invitation.email}
                      </EntityTitleSemiBold>
                    )}
                    <EntityTitleSemiBold>{invitation.role}</EntityTitleSemiBold>
                  </FlexContainer>
                  <FlexContainer gap="20px" align="center">
                    <Button
                      type="button"
                      onClick={() => {
                        dispatch(
                          invitationActions.deleteOne({
                            _id: invitation._id,
                          })
                        );
                      }}
                      style={{ height: "26px" }}
                      loading={deleteInvitationStates.loading}
                      options={{
                        size: "md",
                        type: "filled",
                        skin: "danger",
                      }}
                    >
                      Eliminar Invitacion
                    </Button>
                  </FlexContainer>
                </FlexContainer>
              </ListItem>
            );
          })}
        </FlexContainer>
      </InfinityScroll>

      {action?.action === "remove-from-academy-member" && (
        <ModalConfirmDelete
          bntConfig={{
            content: "Quitar",
            style: {
              options: {
                type: "outline",
                skin: "danger",
                size: "lg",
                marginBottom: "0px",
              },
            },
          }}
          states={deleteAcademyMemberStates}
          title={
            <span>
              {`¿Seguro que quiere eliminar de la academia a `}
              <span style={{ fontWeight: "bold", color: "black" }}>
                {action.data.name}
              </span>
              ?
            </span>
          }
          elementActions={academyActions.deleteMember(action.data)}
          resetAction={academyActions.resetUpdate}
          resetState={setAction}
        ></ModalConfirmDelete>
      )}
    </>
  );
};

const states = ({
  cohortStore,
  currentStore,
  usersStore,
  academyStore,
  invitationStore,
  userStore,
}) => {
  const { data: cohorts, states: cohortStates } = cohortStore.allCohorts;
  const { states: deleteAcademyMemberStates } = academyStore.update;
  const { data: current } = currentStore;
  const { data: users } = usersStore.all;
  const { data: academies } = academyStore.all;
  const { data: invitations, states: invitationStates } = invitationStore.all;
  const { data: newManyInvitations, states: newManyInvitationsStates } =
    invitationStore.newMany;
  const { states: updateInvitationStates, data: updateInvitationData } =
    invitationStore.update;
  const { data: deletedInvitation, states: deleteInvitationStates } =
    invitationStore.delete;
  const { data: user } = userStore;
  return {
    cohorts,
    current,
    users,
    academies,
    invitations,
    user,
    deleteAcademyMemberStates,
    cohortStates,
    invitationStates,
    updateInvitationStates,
    updateInvitationData,
    newManyInvitations,
    newManyInvitationsStates,
    deletedInvitation,
    deleteInvitationStates,
  };
};

const Components = {
  Cohort: connect(states)(ComponentCohort),
  Academy: connect(states)(ComponentAcademy),
};

export default Components;
