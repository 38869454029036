import { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import { Center } from "../../../../../../components/StyledComponents";
import Grid from "../../../../../../components/Grid";
import Button from "../../../../../../components/Button";
import Submit from "../../../../../../components/Form/Submit";
import Modal from "../../../../../../components/Modal";
import Icon from "../../../../../../components/Icon";
import Input from "../../../../../../components/Form/Input";
import Textarea from "../../../../../../components/Form/TextArea";
import FileUploader from "../../../../../../components/Form/FileUploader";
import ReactSelect from "../../../../../../components/Form/ReactSelect";
import {
  initialValues,
  schema,
} from "../../../../../../constants/form/talent/new";
import { hideModal } from "../../../../../../store/actions/modal";
import UploadIcon from "../../../../../../assets/icons/cloud-upload.svg";
import { getAllProvinces } from "../../../../../../store/actions/statics";
interface ComponentProps {
  currentTalent;
  setCurrentTalentValues: (talent) => void;
  currentTalentValues;
  provincesStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  provinces: { value: string; label: string }[];
}

const Component = ({
  currentTalent,
  setCurrentTalentValues,
  currentTalentValues,
  provincesStates,
  provinces,
}: ComponentProps) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [avatarPreview, setAvatarPreview] = useState<HTMLInputElement>();
  const [cvPreview, setCvPreview] = useState<HTMLInputElement>();

  const dispatch = useDispatch();

  const onSubmit = ({ values, actions }) => {
    setCurrentTalentValues({
      ...values,
      ...(!!avatarPreview &&
        !!avatarPreview?.files && {
          avatar: avatarPreview?.files[0],
          avatarPreview: URL.createObjectURL(avatarPreview?.files[0]),
        }),
      ...(!!cvPreview &&
        !!cvPreview?.files && {
          curriculum: cvPreview?.files[0],
        }),
    });
    dispatch(hideModal());
  };

  return (
    <Modal name="talent-edit-modal" title="Tarjeta de talento">
      <Grid.Row>
        <Grid.Col>
          <Input
            name="name"
            value={`${currentTalent?.name} ${currentTalent?.lastName}`}
            readOnly
            options={{
              label: "Nombre del talento *",
              marginBottom: 24,
            }}
            modal
          />
        </Grid.Col>
      </Grid.Row>

      <Formik
        initialValues={{
          ...initialValues,
          ...currentTalentValues,
          country: initialValues.country,
        }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="edit-student-information-form"
            >
              <Grid.Row>
                <Grid.Col>
                  <FileUploader
                    name="avatar"
                    error={errors["avatar"]}
                    touched={touched["avatar"]}
                    placeholder={
                      <Icon icon={UploadIcon} size="24px" color="black" />
                    }
                    onChange={(event) => {
                      setAvatarPreview(event.target);
                      handleChange(event);
                    }}
                    onBlur={handleBlur}
                    accept="image/jpg;image/png;image/jpeg;capture=camera"
                    options={{
                      label: "Foto de perfil",
                      marginBottom: 24,
                    }}
                  />
                </Grid.Col>
              </Grid.Row>

              <Grid.Row>
                <Grid.Col>
                  <ReactSelect
                    name="country"
                    error={errors["country"]}
                    touched={touched["country"]}
                    items={[{ label: "Argentina", value: "argentina" }]}
                    placeholder="Selecciona un país"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onOptionSelected={(optionSelected) => {
                      dispatch(getAllProvinces(optionSelected.value));
                    }}
                    options={{
                      label: "País *",
                      marginBottom: 24,
                    }}
                    modal
                  />
                </Grid.Col>
                <Grid.Col>
                  <ReactSelect
                    name="province"
                    error={errors["province"]}
                    touched={touched["province"]}
                    items={provinces}
                    placeholder="Selecciona una provincia"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      loading: provincesStates.loading,
                      label: "Provincia *",
                      marginBottom: 24,
                    }}
                    modal
                  />
                </Grid.Col>
              </Grid.Row>

              <Grid.Row>
                <Grid.Col>
                  <ReactSelect
                    name="english"
                    error={errors["english"]}
                    touched={touched["english"]}
                    items={[
                      { label: "A1", value: "a1" },
                      { label: "A2", value: "a2" },
                      { label: "B1", value: "b1" },
                      { label: "B2", value: "b2" },
                      { label: "C1", value: "c1" },
                      { label: "C2", value: "c2" },
                    ]}
                    placeholder="Selecciona nivel del idioma"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "Ingles *",
                      marginBottom: 24,
                    }}
                    modal
                  />
                </Grid.Col>
              </Grid.Row>

              <Grid.Row>
                <Grid.Col>
                  <Input
                    name="email"
                    error={errors["email"]}
                    touched={touched["email"]}
                    value={values["email"]}
                    placeholder="Correo electrónico"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    modal
                    options={{
                      label: "Link e-mail",
                      marginBottom: 24,
                    }}
                  />
                </Grid.Col>
              </Grid.Row>

              <Grid.Row>
                <Grid.Col>
                  <Input
                    name="github"
                    error={errors["github"]}
                    touched={touched["github"]}
                    value={values["github"]}
                    placeholder="Link al GitHub"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    modal
                    options={{
                      label: "Link GitHub",
                      marginBottom: 24,
                    }}
                  />
                </Grid.Col>
              </Grid.Row>

              <Grid.Row>
                <Grid.Col>
                  <Input
                    name="portfolio"
                    error={errors["portfolio"]}
                    touched={touched["portfolio"]}
                    value={values["portfolio"]}
                    placeholder="Link al portfolio"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "Link portfolio",
                      marginBottom: 24,
                    }}
                    modal
                  />
                </Grid.Col>
              </Grid.Row>

              <Grid.Row>
                <Grid.Col>
                  <Input
                    name="linkedin"
                    error={errors["linkedin"]}
                    touched={touched["linkedin"]}
                    value={values["linkedin"]}
                    placeholder="Link al perfil de LinkedIn"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "Link LinkedIn",
                      marginBottom: 24,
                    }}
                    modal
                  />
                </Grid.Col>
              </Grid.Row>

              <Grid.Row>
                <Grid.Col>
                  <FileUploader
                    name="curriculum"
                    error={errors["curriculum"]}
                    touched={touched["curriculum"]}
                    onBlur={handleBlur}
                    onChange={(event) => {
                      setCvPreview(event.target);
                      handleChange(event);
                    }}
                    options={{
                      label: "Curriculum Vitae - CV",
                      marginBottom: 24,
                    }}
                  />
                </Grid.Col>
              </Grid.Row>

              <Grid.Row>
                <Grid.Col>
                  <Textarea
                    name="comments"
                    error={errors["comments"]}
                    touched={touched["comments"]}
                    value={values["comments"]}
                    placeholder="..."
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "Resumen",
                      marginBottom: 24,
                    }}
                  />
                </Grid.Col>
              </Grid.Row>

              <Center>
                <Submit
                  isSubmmiting={false}
                  form="edit-student-information-form"
                  color="Primary"
                  options={{
                    type: "filled",
                    skin: "primary",
                    size: "lg",
                    marginBottom: "0px",
                  }}
                >
                  Confirmar
                </Submit>

                <Button
                  onClick={() => {
                    resetForm();
                    dispatch(hideModal());
                  }}
                  type="button"
                  options={{
                    type: "outline",
                    skin: "danger",
                    size: "lg",
                    marginBottom: "0px",
                  }}
                  style={{ marginLeft: "10px" }}
                >
                  Cancelar
                </Button>
              </Center>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const states = ({ staticsStore }) => {
  const { states: provincesStates, data: provinces } =
    staticsStore.allProvinces;

  return {
    provincesStates,
    provinces,
  };
};

export default connect(states)(Component);
