import Modal from "../../../../../../components/Modal";
import NewCohortForm from "../../../New";

const Component = ({ tags }) => {
  return (
    <Modal name="new-cohort-modal" title="Nuevo Co-Hort">
      <NewCohortForm tags={tags} />
    </Modal>
  );
};

export default Component;
