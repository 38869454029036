export function formatNumber(number) {
  if (!number) return 0;
  if (Number.isInteger(number)) {
    return number;
  } else {
    return parseFloat(number.toFixed(1));
  }
}

export function overThousandFixed(number) {
  if (!number) return 0;
  if (number > 1000) {
    const formattedNumber = (number / 1000).toFixed(1);
    return `${formattedNumber}k`;
  }
  if (Number.isInteger(number)) {
    return number.toString();
  } else {
    return number.toFixed(1);
  }
}
