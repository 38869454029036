import React, { useEffect, useState } from "react";
import { MdFlashlightOn } from "react-icons/md";
import { connect, useDispatch } from "react-redux";
import Button from "../../../../components/Button";
import Grid from "../../../../components/Grid";
import {
  GridCard,
  GridCardImage,
  GridCardImageContainer,
  GridCardTitle,
  GridComment,
  GridStructure,
} from "../../../../components/Layout/Dashboard/styles";
import Loader from "../../../../components/Loader";
import {
  ButtonFilter,
  Center,
  FlexContainer,
  PageTitle,
} from "../../../../components/StyledComponents";
import { DateFromNow } from "../../../../helpers/format-date";
import { showModal } from "../../../../store/actions/modal";
import { CohortDto } from "../../../../types/cohort.dto";
import { CurrentDto } from "../../../../types/current.dto";
import { StateDto } from "../../../../types/states.dto";
import { VideoDto } from "../../../../types/video.dto";
import FilterButton from "./components/FilterButton";
import FilterByName from "./components/FilterByName";
import FilterModal from "./components/FilterModal";
import { DotContent, ItemDataText } from "./style";
import { UserDto } from "../../../../types/user.dto";
import { useNavigate } from "react-router-dom";
import CenterTitle from "../../../../components/CenterTitle";
import {
  getAllVideos,
  resetGetAllVideos,
  resetUpdateManyVideo,
  updateManyVideo,
} from "../../../../store/actions/video";
import { BiWorld } from "react-icons/bi";
import StateContentModal from "../../../../components/StateContentModal";
import { FetchData } from "../../../../types/fetchData.dto";
import InfinityScroll from "../../../../components/InfinityScroll";

const filterValues: any = [
  {
    fieldName: ["visibility", "order", "cohorts"],
    value: "no",
    name: "Todos",
  },
  {
    fieldName: "order",
    value: "recent",
    name: "Recientes",
    groupal: (array: VideoDto[]) =>
      array.sort(
        (a, b) =>
          new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
      ),
  },
  {
    fieldName: "order",
    value: "older",
    name: "Antiguos",
    groupal: (array: VideoDto[]) =>
      array.sort(
        (a, b) =>
          new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf()
      ),
  },
  {
    fieldName: "published",
    value: true,
    name: "Publicos",
  },
  {
    fieldName: "published",
    value: false,
    name: "Privados",
  },
];

const Component = ({
  current,
  videos,
  newVideoData,
  newVideoDataStates,
  cohorts,
  user,
  video,
  updateManyVideoStates,
  videosStates,
  updateManyVideos,
}: {
  current: CurrentDto;
  videos: VideoDto[];
  newVideoData: VideoDto;
  newVideoDataStates: StateDto;
  user: UserDto;
  cohorts: CohortDto[];
  video?: boolean;
  updateManyVideoStates: StateDto;
  videosStates: StateDto;
  updateManyVideos: VideoDto[];
}) => {
  const [applyedFilters, setFilters] = useState<
    {
      field: string;
      value: string | boolean;
      special: (data?: any, data2?: any) => boolean;
      groupal: (...args: any) => any[];
    }[]
  >([]);
  const [currentVideosList, setCurrentVideosList] = useState<VideoDto[]>([]);
  const [filteredCurrentVideosListValues, setFilteredCurrentVideosListValues] =
    useState<VideoDto[]>([]);
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);
  const [paginationOn, setPaginationOn] = useState<boolean>(false);
  const [available, setAvailable] = useState<string>();
  const buildFilters = (newFilter) => {
    const filterUpdated =
      applyedFilters.filter((applyedFilter) =>
        !Array.isArray(newFilter.field)
          ? applyedFilter.field === newFilter.field
          : newFilter.field.includes(applyedFilter.field)
      ).length > 0;

    const filterRemoved = newFilter.value === "no";

    if (filterRemoved) {
      /**
       * Se elimina el filtro
       */
      if (Array.isArray(newFilter.field)) {
        setFilters([
          ...applyedFilters.filter(
            (applyedFilter) => !newFilter.field.includes(applyedFilter.field)
          ),
        ]);
      } else {
        setFilters([
          ...applyedFilters.filter(
            (applyedFilter) => applyedFilter.field !== newFilter.field
          ),
        ]);
      }
    }

    /**
     * Se actualiza un filtro
     */
    if (filterUpdated && !filterRemoved) {
      setFilters([
        ...applyedFilters.map((applyedFilter) => {
          if (applyedFilter.field === newFilter.field) {
            return newFilter;
          } else {
            return applyedFilter;
          }
        }),
      ]);
    }

    /**
     * Se agrega un filtro
     */
    if (!filterUpdated && !filterRemoved) {
      setFilters([...applyedFilters, newFilter]);
    }
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fetchData = ({ page, filterBy }: FetchData) => {
    if (page === 0) {
      setPaginationOn(true);
    }
    let payload = {
      limit: 9,
      offset: 9 * page,
      filterBy: {
        ...(filterBy ? filterBy : {}),
        url: { $exists: true },
        active: true,
        academy: current.id,
        /*  ...(current.role !== "ADMIN" && {
          users: {
            $elemMatch: {
              id: user._id,
              roleEnd: { $exists: false },
            },
          },
        }), */
      },
    };

    const select = {
      _id: 1,
      name: 1,
      picture: 1,
      __v: 1,
      academy: 1,
      updatedAt: 1,
      viewCount: 1,
      createdAt: 1,
      users: 1,
    };

    const sortBy = "createdAt";

    const sortDirection = "desc";
    dispatch(getAllVideos({ ...payload, sortBy, sortDirection, select }));
  };

  useEffect(() => {
    if (!!paginationOn) {
      if (!isLoading && !!videos) {
        setCurrentVideosList((state) => [...state, ...videos]);
      }
      setThereAreItems(!!videos && videos.length > 0);
    }
  }, [videos, isLoading, paginationOn]);

  useEffect(() => {
    setIsLoading(videosStates.loading);
  }, [videosStates.loading]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllVideos());
    };
  }, []);

  useEffect(() => {
    if (
      !!updateManyVideos &&
      !!updateManyVideoStates.success &&
      !!paginationOn
    ) {
      setCurrentVideosList(updateManyVideos);
    }
  }, [updateManyVideoStates, updateManyVideos, paginationOn]);

  useEffect(() => {
    if (!available) {
      setAvailable(current.id);
    }

    if (available) {
      // Si element cambia, reinicia la página a 1
      setPage(0);
    }
  }, [current.id]);

  useEffect(() => {
    if (page === 0 && available) {
      setCurrentVideosList([]);
      fetchData({ page: 0, filterBy: {} });
    }
  }, [page, current.id]);

  useEffect(() => {
    const filteredTalents = currentVideosList?.filter((currentTalentValues) => {
      const thereAreFilters =
        applyedFilters.filter((applyedFilter) => !applyedFilter.groupal)
          .length > 0;
      const totalOfFilters = applyedFilters.filter(
        (applyedFilter) => !applyedFilter.groupal
      ).length;
      const passFilters = applyedFilters
        .filter(
          (applyedFilter) => !applyedFilter.special || !applyedFilter.groupal
        )
        .filter(
          (applyedFilter) =>
            currentTalentValues[applyedFilter.field] === applyedFilter.value
        ).length;
      const specialFilter = applyedFilters.filter(
        (applyedFilter) => !!applyedFilter.special
      );

      const speacialFilterCheck = specialFilter.filter((applyedFilter) => {
        return applyedFilter.special(currentTalentValues, applyedFilter.value);
      });
      return (
        !thereAreFilters ||
        (thereAreFilters &&
          totalOfFilters === passFilters + specialFilter.length &&
          speacialFilterCheck.length === specialFilter.length)
      );
    });
    let initResult = filteredTalents;
    applyedFilters
      .filter((applyedFilter) => !!applyedFilter.groupal)
      .forEach((applyedFilter) => {
        initResult = applyedFilter.groupal(initResult);
      });
    setFilteredCurrentVideosListValues(initResult);
  }, [applyedFilters, currentVideosList]);

  useEffect(() => {
    if (updateManyVideoStates.success) {
      dispatch(resetUpdateManyVideo());
    }
    if (updateManyVideoStates.error) {
      dispatch(resetUpdateManyVideo());
    }
  }, [updateManyVideoStates, dispatch]);
  return (
    <>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col>
            <PageTitle>Videos</PageTitle>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col
            offset={{ lg: 10, sm: 10, md: 10 }}
            lg={2}
            md={2}
            sm={2}
            style={{ marginBottom: "14px" }}
          >
            <Button
              onClick={() => {
                navigate(`management`);
              }}
              type="button"
              options={{
                type: "filled",
                skin: "primary",
                size: "md",
                marginBottom: "0px",
              }}
              style={{
                width: "100px",
                height: "50px",
              }}
            >
              Crear
            </Button>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col lg={12} md={12} sm={12} style={{ marginBottom: "14px" }}>
            <FlexContainer justify="center" align="center" gap="14px">
              <FilterByName setFilter={buildFilters}></FilterByName>
              <ButtonFilter
                onClick={() => {
                  dispatch(showModal("filter-cohort-modal"));
                }}
              >
                <MdFlashlightOn size={20}></MdFlashlightOn>
              </ButtonFilter>
              <ButtonFilter
                onClick={() => {
                  dispatch(showModal("change-content-states"));
                }}
              >
                <BiWorld size={20}></BiWorld>
              </ButtonFilter>
            </FlexContainer>
          </Grid.Col>
        </Grid.Row>
        <Center gap="10px" style={{ marginBottom: "25px" }}>
          <FlexContainer wrap="wrap" align="center" gap="20px">
            {filterValues.map((filterValue) => {
              return (
                <FilterButton
                  setFilter={buildFilters}
                  name={filterValue.name}
                  fieldName={filterValue.fieldName}
                  value={filterValue.value}
                  filters={applyedFilters}
                  {...((!!filterValue.special || !!filterValue.groupal) && {
                    special: !!filterValue.special
                      ? { name: "special", function: filterValue.special }
                      : { name: "groupal", function: filterValue.groupal },
                  })}
                ></FilterButton>
              );
            })}
          </FlexContainer>
        </Center>
        <Grid.Row>
          <Grid.Col>
            <InfinityScroll
              action={fetchData}
              page={page}
              setPage={setPage}
              data={filteredCurrentVideosListValues}
              active={thereAreItems}
              isLoading={isLoading}
            >
              <GridStructure
                width={"240px"}
                maxWidth="900px"
                style={{ width: "100%", margin: "0 auto" }}
              >
                {filteredCurrentVideosListValues.map((item, index) => {
                  return (
                    <GridCard
                      onClick={(ev) => {
                        ev.stopPropagation();
                        navigate(`management/${item._id}`);
                      }}
                      pd={"12px"}
                      key={item._id}
                    >
                      <GridCardImageContainer>
                        <GridCardImage
                          src={
                            item.picture ||
                            "https://upload.wikimedia.org/wikipedia/commons/8/81/Gallet_clamshell_600x600_6.jpg"
                          }
                        ></GridCardImage>
                      </GridCardImageContainer>
                      <FlexContainer gap="12px">
                        <FlexContainer direction="column" gap="1px">
                          <GridCardTitle>{item.name}</GridCardTitle>
                          <FlexContainer direction="column">
                            <FlexContainer gap="10px" align="center">
                              <FlexContainer align="center" gap="5px">
                                <DotContent></DotContent>
                                <ItemDataText>
                                  <GridComment>{`Vistas: `}</GridComment>{" "}
                                  {`${item.viewCount}`}
                                </ItemDataText>
                              </FlexContainer>

                              <FlexContainer align="center" gap="5px">
                                <DotContent></DotContent>
                                <ItemDataText>
                                  <GridComment>{`Actualizado: `}</GridComment>{" "}
                                  {`${DateFromNow(item.updatedAt)}`}
                                </ItemDataText>
                              </FlexContainer>
                            </FlexContainer>
                            <FlexContainer gap="10px" align="center">
                              <FlexContainer align="center" gap="5px">
                                <DotContent></DotContent>
                                <ItemDataText>
                                  <GridComment>{`Publicado: `}</GridComment>{" "}
                                  {`${DateFromNow(item.createdAt)}`}
                                </ItemDataText>
                              </FlexContainer>
                              <FlexContainer align="center" gap="5px">
                                <DotContent></DotContent>
                                <ItemDataText>
                                  <></>
                                </ItemDataText>
                              </FlexContainer>
                            </FlexContainer>
                          </FlexContainer>
                        </FlexContainer>
                      </FlexContainer>
                    </GridCard>
                  );
                })}
              </GridStructure>
            </InfinityScroll>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
      <FilterModal buildFilters={buildFilters}></FilterModal>

      <StateContentModal
        action={updateManyVideo}
        section="Videos"
        loading={updateManyVideoStates.loading}
        items={filteredCurrentVideosListValues}
      ></StateContentModal>
    </>
  );
};

const states = ({ currentStore, videoStore, cohortStore, userStore }) => {
  const { data: current } = currentStore;
  const { data: videos, states: videosStates } = videoStore.all;
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: newVideoData, states: newVideoDataStates } = videoStore.new;
  const { states: updateManyVideoStates, data: updateManyVideos } =
    videoStore.updateMany;
  const { data: user } = userStore;
  return {
    current,
    videos,
    newVideoData,
    newVideoDataStates,
    cohorts,
    user,
    updateManyVideoStates,
    videosStates,
    updateManyVideos,
  };
};

export default connect(states)(Component);
