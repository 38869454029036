import { useEffect, useMemo } from "react";
import { connect, useDispatch } from "react-redux";
import {
  RecruitingLayout,
  useRecruitingLayout,
} from "../../../../provider/RecruitingProvider";
import ShowModal from "../components/ShoModal";

import Tabs from "../../../../components/Tabs";
import Icon from "../../../../components/Icon";
import { InLine } from "../../../../components/StyledComponents";
import {
  Header,
  SectionIcon,
  Title,
  PrimarySubTitle,
  SecondSubtitle,
  Action,
  Tab,
} from "../components/StyledComponents";

import recruiting from "../../../../store/actions/recruiting/";
import { showModal } from "../../../../store/actions/modal";
import ModalCreateSearch from "./components/ModalCreateSearch";
import ModalEditSearch from "./components/ModalEditSearch";
import { SearchDto } from "../../../../types/recruiting.dto";

import TalentSearchIcon from "../../../../assets/icons/talent-search.svg";

import TabProfile from "./components/TabProfile";
import TabTechnologiesAndSkills from "./components/TabTechnologiesAndSkills";
import TabTalents from "./components/TabTalents";
import TabCompanies from "./components/TabCompanies";
import { useParams } from "react-router-dom";
interface ComponentProps {
  allSearches: SearchDto[];
  allSearchesStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  selected;
}

const Component = ({
  allSearches,
  allSearchesStates,
  selected,
}: ComponentProps) => {
  const { setRecruitingLayout, selectedItem, statics } = useRecruitingLayout();
  const dispatch = useDispatch();
  const params = useParams();
  const EditarPerfil = () => (
    <Action onClick={() => dispatch(showModal("edit-search-modal"))}>
      <Icon icon={TalentSearchIcon} size="16px" color="black" />
      Editar Perfil
    </Action>
  );

  const getAllItems = () => {
    dispatch(recruiting.search.getAll());
  };

  const recruitingData = useMemo(
    (): RecruitingLayout => ({
      title: "Mis Búsquedas",
      icon: TalentSearchIcon,
      model: "search",
      externalIds: [],
      newProfileModal: "new-search-modal",
      fieldName: "searches",
      actions: recruiting.search,
      list: allSearches
        ?.filter((search) => search.active)
        .filter((company) =>
          !params.id ? !company.team : company.team === params.id
        )
        .map((search) => ({
          label: search.name,
          value: search._id,
          data: search,
        })),
    }),
    [allSearches, selected, params]
  );

  useEffect(() => {
    setRecruitingLayout(recruitingData);
    getAllItems();
  }, [selected, params]);

  useEffect(() => {
    setRecruitingLayout(recruitingData);
  }, [allSearches, selected, params]);

  return (
    <>
      <Header>
        <InLine
          align="flex-start"
          justify="flex-start"
          style={{ marginBottom: "14px" }}
        >
          <SectionIcon>
            <Icon icon={TalentSearchIcon} size="16px" color="white" />
          </SectionIcon>

          <span>
            <Title>{selectedItem?.label}</Title>
            <PrimarySubTitle>
              Provincia de{" "}
              {
                statics?.allProvinces?.data?.filter(
                  (province) => province?.value === selectedItem?.data?.province
                )[0]?.label
              }
              , {!!selectedItem?.data.country && selectedItem?.data.country}
            </PrimarySubTitle>
            <SecondSubtitle>{selectedItem?.data?.english}</SecondSubtitle>
          </span>
        </InLine>

        <InLine justify="flex-start">
          <EditarPerfil />
          <ShowModal.AsignarTalento />
          <ShowModal.AsignarEmpresa />
          <ShowModal.AsignarTecnologia />
        </InLine>
      </Header>

      <Tabs>
        <Tab id="perfil" label="Perfil">
          <TabProfile>
            <EditarPerfil />
          </TabProfile>
        </Tab>

        <Tab id="talentos" label="Talentos">
          <TabTalents />
        </Tab>

        <Tab id="empresa" label="Empresa">
          <TabCompanies />
        </Tab>

        <Tab id="tecnologias" label="Tecnologías">
          <TabTechnologiesAndSkills />
        </Tab>
      </Tabs>

      <ModalEditSearch />
      <ModalCreateSearch />
    </>
  );
};

const states = ({ recruitingStore, teamStore }) => {
  const { data: allSearches, states: allSearchesStates } =
    recruitingStore.search.all;
  const { data: selected } = teamStore.selected;
  return { allSearches, allSearchesStates, selected };
};

export default connect(states)(Component);
