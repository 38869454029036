import React, { CSSProperties } from "react";
import { useField } from "formik";
import { useDropzone } from "react-dropzone";
import { ErrorMessage, HelperLine } from "../Field";
import { Wrapper, Placeholder, Icon } from "./styles";

const extensionValidation = (extensions) => {
  if (!extensions || extensions === "") {
    return {};
  }

  let accept = {};

  extensions
    ?.trim()
    .split(",")
    .forEach((format) => {
      accept[format] = [];
    });

  return accept;
};

const sizeValidation = (file, maxFileSize) => {
  if (file.size.length > maxFileSize) {
    return {
      code: "file-too-large",
      message: "El archivo no puede superar los ",
    };
  }

  return null;
};

interface ComponentProps {
  name: string;
  placeholder?: string;
  error?: {};
  touched?: {};
  disabled?: boolean;
  onFocus?: (data: any) => void;
  onBlur?: (data: any) => void;
  onChange?: (files: File | File[]) => void;
  options: {
    helperText?: string;
    explainText?: string;
    before?: any;
    after?: any;
    openOnClick?: boolean;
    debug?: boolean;
    size: "small" | "big";
  };
  validation?: {
    maxFileSize: number;
    maxFiles: number;
    accept: string;
  };
  styles?: CSSProperties;
}

const DropzoneField = ({
  name,
  placeholder,
  error,
  touched,
  disabled,
  onFocus,
  onBlur,
  onChange,
  options,
  styles,
  validation,
}: ComponentProps) => {
  const initialOptions = { openOnClick: false, debug: false, ...options };
  const [field, meta, helpers] = useField(name);
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    open,
    isDragActive,
  } = useDropzone({
    ...(validation?.accept && {
      accept: extensionValidation(validation?.accept),
    }),
    ...(validation?.maxFiles && { maxFiles: validation?.maxFiles }),
    ...(validation?.maxFileSize && {
      validator: (file) => sizeValidation(file, validation?.maxFileSize),
    }),
    ...(!initialOptions?.openOnClick && {
      noClick: true,
      noKeyboard: true,
    }),
    multiple:
      !!validation && !!validation?.maxFiles && validation?.maxFiles > 1,
    disabled,
    onDropAccepted: (files) => {
      helpers?.setValue(files);
      onChange && onChange(files);
    },
  });

  const acceptedFileItems = acceptedFiles.map((file) => (
    <li key={file?.name}>
      {file?.name} - {file.size} bytes
    </li>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file?.name}>
      {file?.name} - {file.size} bytes
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  return (
    <div>
      <Wrapper
        {...getRootProps({
          className: `dropzone ${isDragActive ? "active" : ""} ${
            options?.size ? options?.size : "small"
          }`,
        })}
      >
        <input {...getInputProps()} />
        {placeholder && <Placeholder>{placeholder}</Placeholder>}
        <Icon size={options?.size === "small" ? 40 : 100} />
        {!!error && !!touched && <ErrorMessage>{error}</ErrorMessage>}
        {meta?.touched && meta?.error && (
          <div style={{ color: "red" }}>{meta.error}</div>
        )}
        {!initialOptions?.openOnClick && (
          <button type="button" onClick={open}>
            Subir archivo
          </button>
        )}
        <HelperLine marginBottom={10}>
          {!!error && <ErrorMessage>{error}</ErrorMessage>}
        </HelperLine>
      </Wrapper>

      {initialOptions?.debug && (
        <>
          <h4>Accepted files</h4>
          <ul>{acceptedFileItems}</ul>
          <h4>Rejected files</h4>
          <ul>{fileRejectionItems}</ul>
        </>
      )}
    </div>
  );
};

export default DropzoneField;
