import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Modal from "../../../../../../components/Modal";
import { Formik } from "formik";
import { Center } from "../../../../../../components/StyledComponents";
import {
  initialValues,
  schema,
} from "../../../../../../constants/form/invitation/create";
import Grid from "../../../../../../components/Grid";
import Button from "../../../../../../components/Button";
import Submit from "../../../../../../components/Form/Submit";
import { toast } from "react-toastify";
import Input from "../../../../../../components/Form/Input";
import invitationActions from "../../../../../../store/actions/invitation";
import { hideModal } from "../../../../../../store/actions/modal";
import ReactSelect from "../../../../../../components/Form/ReactSelect";
import { InvitationDto } from "../../../../../../types/invitation.dto";
import { AcademyDto } from "../../../../../../types/academy.dto";
import { CurrentDto } from "../../../../../../types/current.dto";

const Component = ({
  selectedAcademy,
  createStates,
  current,
}: {
  createStates: {
    loading: boolean;
    error: boolean;
    success: boolean;
  };
  selectedAcademy: AcademyDto;
  current: CurrentDto;
}) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const dispatch = useDispatch();

  const onSubmit = ({ values, actions }) => {
    dispatch(invitationActions.create({ academy: current.id, ...values }));
    setFormActions(actions);
  };

  useEffect(() => {
    if (createStates.success) {
      toast.success("👌🏼 Invitacion creada");

      setTimeout(() => {
        dispatch(invitationActions.resetCreate());
        dispatch(hideModal());
        formActions?.resetForm();
      }, 1000);
    }

    if (createStates.error) {
      toast.error(`😱 ${createStates.error}`);

      setTimeout(() => {
        dispatch(invitationActions.resetCreate());
      }, 1000);
    }
  }, [createStates]);

  return (
    <Modal name="invite-academy-modal-modal" title="Invitar a Academia">
      <Formik
        initialValues={{ ...initialValues() }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="invite-academy-modal-form"
            >
              <Grid.Row>
                <Grid.Col>
                  <Input
                    name={"email"}
                    error={errors["email"]}
                    touched={touched["email"]}
                    value={values["email"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{ label: "E-mail", skin: "gray" }}
                  />
                  <ReactSelect
                    name="role"
                    error={errors["role"]}
                    touched={touched["role"]}
                    items={[
                      { label: "Project Manager", value: "PM" },
                      { label: "Profesor", value: "PROFESSOR" },
                      { label: "Observador", value: "OBSERVER" },
                      { label: "Administrador", value: "ADMIN" },
                      { label: "Estudiante", value: "STUDENT" },
                    ]}
                    placeholder="Seleccione un tipo"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      skin: "gray",
                      label: "Tipo de Usuario",
                      marginBottom: 20,
                    }}
                  />
                </Grid.Col>
              </Grid.Row>

              <Grid.Row>
                <Grid.Col>
                  <Center gap={"70px"}>
                    <Button
                      onClick={resetForm}
                      type="button"
                      options={{
                        type: "outline",
                        skin: "gray",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                      style={{ marginLeft: "10px" }}
                    >
                      Cancelar
                    </Button>

                    <Submit
                      isSubmmiting={createStates.loading}
                      form="invite-academy-modal-form"
                      color="Primary"
                      options={{
                        type: "filled",
                        skin: "primary",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                    >
                      Confirmar
                    </Submit>
                  </Center>
                </Grid.Col>
              </Grid.Row>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const states = ({ invitationStore, academyStore, currentStore }) => {
  const { data: selectedAcademy } = academyStore.selected;
  const { states: createStates } = invitationStore.create;
  const { data: current } = currentStore;
  return {
    selectedAcademy,
    createStates,
    current,
  };
};

export default connect(states)(Component);
