import {
  GetAllMentors,
  GET_ALL_MENTORS,
  GetMentor,
  GET_MENTOR,
  UpdateMentor,
  UPDATE_MENTOR,
  DeleteMentor,
  DELETE_MENTOR,
  GetMentorSuccess,
  GET_MENTOR_SUCCESS,
  NewMentor,
  NEW_MENTOR,
  ResetNewMentor,
  RESET_NEW_MENTOR,
  ResetUpdateMentor,
  RESET_UPDATE_MENTOR,
  ResetDeleteMentor,
  RESET_DELETE_MENTOR,
} from "../types/mentor";

export const getAllMentors = (): GetAllMentors => {
  return { type: GET_ALL_MENTORS, payload: null };
};

export const getMentor = (id): GetMentor => {
  return { type: GET_MENTOR, payload: id };
};

export const setSelectedMentor = (payload): GetMentorSuccess => {
  return { type: GET_MENTOR_SUCCESS, payload };
};

export const newMentor = (payload): NewMentor => {
  return { type: NEW_MENTOR, payload };
};

export const resetNewMentor = (): ResetNewMentor => {
  return { type: RESET_NEW_MENTOR, payload: null };
};

export const updateMentor = (id): UpdateMentor => {
  return { type: UPDATE_MENTOR, payload: id };
};

export const resetUpdateMentor = (): ResetUpdateMentor => {
  return { type: RESET_UPDATE_MENTOR, payload: null };
};

export const deleteMentor = (id): DeleteMentor => {
  return { type: DELETE_MENTOR, payload: id };
};

export const resetDeleteMentor = (): ResetDeleteMentor => {
  return { type: RESET_DELETE_MENTOR, payload: null };
};
