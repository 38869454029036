import {
  NewSearch,
  NEW_SEARCH,
  ResetNewSearch,
  RESET_NEW_SEARCH,
  GET_ALL_SEARCHES,
  GetAllSearchs,
  UpdateSearch,
  UPDATE_SEARCH,
  ResetUpdateSearch,
  RESET_UPDATE_SEARCH,
  GetSearch,
  DeleteSearch,
  DELETE_SEARCH,
  GET_SEARCH,
  UpdateManySearch,
  UPDATE_MANY_SEARCH,
  RESET_DELETE_SEARCH,
} from "../../types/recruiting/search";

const create = (payload): NewSearch => {
  return { type: NEW_SEARCH, payload };
};
const resetCreate = (): ResetNewSearch => {
  return { type: RESET_NEW_SEARCH, payload: null };
};

const update = (payload): UpdateSearch => {
  return { type: UPDATE_SEARCH, payload };
};

const updateMany = (payload): UpdateManySearch => {
  return { type: UPDATE_MANY_SEARCH, payload };
};

const resetUpdate = (): ResetUpdateSearch => {
  return { type: RESET_UPDATE_SEARCH, payload: null };
};

const getAll = (): GetAllSearchs => {
  return { type: GET_ALL_SEARCHES, payload: null };
};

const getOne = (payload: { _id: string }): GetSearch => {
  return { type: GET_SEARCH, payload };
};

const deleteOne = (payload: { _id: string }): DeleteSearch => {
  return { type: DELETE_SEARCH, payload };
};

const resetDeleteOne = () => {
  return { type: RESET_DELETE_SEARCH, payload: null };
};

const actions = {
  create,
  resetCreate,
  update,
  resetUpdate,
  getAll,
  getOne,
  resetDeleteOne,
  deleteOne,
  updateMany,
};

export default actions;
