import {
  GetAllSubCriterions,
  GET_ALL_SUB_CRITERIONS,
  NewSubCriterion,
  NEW_SUB_CRITERION,
  ResetNewSubCriterion,
  ResetUpdateSubCriterion,
  RESET_NEW_SUB_CRITERION,
  RESET_UPDATE_SUB_CRITERION,
  UpdateSubCriterion,
  UPDATE_SUB_CRITERION,
  DELETE_SUB_CRITERION,
  RESET_DELETE_SUB_CRITERION,
  NEW_MANY_SUB_CRITERION,
  UPDATE_MANY_SUB_CRITERION,
  RESET_UPDATE_MANY_SUB_CRITERION,
  RESET_NEW_MANY_SUB_CRITERION,
  RESET_GET_ALL_SUB_CRITERIONS,
} from "../types/sub-criterion";

export const newSubCriterion = (payload): NewSubCriterion => {
  return { type: NEW_SUB_CRITERION, payload };
};

export const getAllSubCriterions = (payload): GetAllSubCriterions => {
  return { type: GET_ALL_SUB_CRITERIONS, payload };
};

export const resetGetAllSubCriterions = () => {
  return { type: RESET_GET_ALL_SUB_CRITERIONS, payload: null };
};

export const resetNewSubCriterion = (): ResetNewSubCriterion => {
  return { type: RESET_NEW_SUB_CRITERION, payload: null };
};

export const updateSubcriterion = (payload): UpdateSubCriterion => {
  return { type: UPDATE_SUB_CRITERION, payload };
};

export const updateManySubCriterion = (payload) => {
  return { type: UPDATE_MANY_SUB_CRITERION, payload };
};

export const resetUpdateSubCriterion = (): ResetUpdateSubCriterion => {
  return { type: RESET_UPDATE_SUB_CRITERION, payload: null };
};

export const deleteSubCriterion = (payload) => {
  return { type: DELETE_SUB_CRITERION, payload };
};

export const newManySubCriterion = (payload) => {
  return { type: NEW_MANY_SUB_CRITERION, payload };
};

export const resetDeleteSubCriterion = () => {
  return { type: RESET_DELETE_SUB_CRITERION, payload: null };
};

export const resetUpdateManySubCriterion = () => {
  return { type: RESET_UPDATE_MANY_SUB_CRITERION, payload: null };
};
export const resetNewManySubCriterion = () => {
  return { type: RESET_NEW_MANY_SUB_CRITERION, payload: null };
};
