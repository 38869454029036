import styled from "styled-components";

export const NumberStatWrapper = styled("div")`
  padding: 22px 30px 2px 30px;
  border-radius: 10px;
  
  background-color: #fff;
`;

export const NumberStatLabel = styled("h3")`
  font-size: 10px;
  font-weight: normal;
  color: #273241;
  word-break: break-word;
  text-align: center;
`;

export const NumberStatValue = styled("h3")`
  font-size: 48px;
  font-weight: 500;
  text-align: center;
  color: #001e6c;
`;
