import { put, call, all, takeLatest } from "redux-saga/effects";
import services from "../../services";
import {
  GET_CONFIRMATIONAUTH,
  GET_CONFIRMATIONAUTH_SUCCESS,
  GET_CONFIRMATIONAUTH_ERROR,
  UPDATE_CONFIRMATIONAUTH,
  UPDATE_CONFIRMATIONAUTH_SUCCESS,
  UPDATE_CONFIRMATIONAUTH_ERROR,
  GetConfirmationAuth,
  UpdateConfirmationAuth,
} from "../types/confirmationAuth";

function* getConfirmationAuth({ payload }) {
  const { response, error } = yield call(
    services.confirmationAuth.getOne,
    payload
  );

  if (error) {
    yield put({
      type: GET_CONFIRMATIONAUTH_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_CONFIRMATIONAUTH_SUCCESS, payload: response });
  }
}

function* updateConfirmationAuth({ payload }) {
  const { response, error } = yield call(
    services.confirmationAuth.update,
    payload
  );

  if (error) {
    yield put({
      type: UPDATE_CONFIRMATIONAUTH_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([
      put({ type: UPDATE_CONFIRMATIONAUTH_SUCCESS, payload: response }),
    ]);
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetConfirmationAuth>(
    GET_CONFIRMATIONAUTH,
    getConfirmationAuth
  );
  yield takeLatest<UpdateConfirmationAuth>(
    UPDATE_CONFIRMATIONAUTH,
    updateConfirmationAuth
  );
}
