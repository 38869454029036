import { EditorState, Modifier, RichUtils } from "draft-js";
import React, { useEffect, useState } from "react";
import { stylesMapGlobal } from "../..";
import { FontSizes } from "../../../../../../constants/fontSizeCdos";
import { Select } from "./style";

const Component = ({ editorState, setEditorState, previewState }) => {
  const [actualFont, setActualFont] = useState(`FONT_SIZE_16`);

  const onChange = (value) => {
    const selection = EditorState.forceSelection(
      editorState,
      editorState.getSelection()
    ).getSelection();
    // Let's just allow one color at a time. Turn off all active colors.
    const nextContentState = Object.keys(
      FontSizes.reduce(
        (a, v) => ({ ...a, [v.label]: { [v.prop]: v.value } }),
        {}
      )
    ).reduce((contentState, color) => {
      return Modifier.removeInlineStyle(contentState, selection, color);
    }, editorState.getCurrentContent());
    let nextEditorState = EditorState.push(
      editorState,
      nextContentState,
      "change-inline-style"
    );
    const currentStyle = editorState.getCurrentInlineStyle();

    // Unset style override for current color.
    if (selection.isCollapsed()) {
      nextEditorState = currentStyle.reduce((state, color) => {
        return RichUtils.toggleInlineStyle(state, color);
      }, nextEditorState);
    }

    // If the color is being toggled on, apply it.
    if (!currentStyle.has(value)) {
      nextEditorState = [...currentStyle, value].reduce((state, color) => {
        return RichUtils.toggleInlineStyle(state, color);
      }, nextEditorState);
    }

    setEditorState(nextEditorState);
  };

  useEffect(() => {
    const actualLocalFont = FontSizes.filter((font) =>
      editorState.getCurrentInlineStyle().has(font.label)
    )[0]?.label;
    /*  editorState.getCurrentInlineStyle().forEach((el) => {
      console.log("messi22", el);
    }); */
    if (
      !!actualLocalFont &&
      previewState.getCurrentContent() !== editorState.getCurrentContent()
    ) {
      /*  onChange(actualLocalFont); */
      setActualFont(actualLocalFont);
    }
  }, [editorState.getCurrentInlineStyle(), editorState.getCurrentContent()]);
  return (
    <Select
      onClick={(ev) => {
        ev.preventDefault();
      }}
      value={actualFont}
      id="selecteda"
      onChange={(ev) => {
        ev.preventDefault();
        onChange(ev.target.value);
      }}
    >
      {FontSizes.map((size) => {
        return (
          <option onMouseDown={(ev) => ev.preventDefault()} value={size.label}>
            {size.value}
          </option>
        );
      })}
    </Select>
  );
};

export default Component;
