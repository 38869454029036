import { put, call, takeLatest } from "redux-saga/effects";
import services from "../../services";
import { SET_USER_INFORMATION } from "../types/user";
import {
  GET_ALL_USERS,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_ERROR,
  GetAllUsers,
  UPDATE_USERS_SUCCESS,
  UPDATE_USERS_ERROR,
  UPDATE_USERS,
  UpdateUsers,
  GET_ALL_USERS_BY_ACADEMY,
  GetUsersByAcademy,
  DeleteUsersFromEntity,
  DELETE_USER_FROM_ENTITY,
} from "../types/users";

function* getAllCategories({ payload }) {
  const { response, error } = yield call(services.users.getAll, payload);
  if (error) {
    yield put({
      type: GET_ALL_USERS_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_USERS_SUCCESS, payload: response });
  }
}
function* getAllUsersByAcademy({ payload }) {
  const { response, error } = yield call(
    services.users.getAllByAcademy,
    payload
  );
  if (error) {
    yield put({
      type: GET_ALL_USERS_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_USERS_SUCCESS, payload: response });
  }
}
function* updateUsers({ payload }) {
  const { response, error } = yield call(services.users.update, payload);
  if (error) {
    yield put({
      type: UPDATE_USERS_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: UPDATE_USERS_SUCCESS, payload: response });
    yield put({ type: SET_USER_INFORMATION, payload: response });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllUsers>(GET_ALL_USERS, getAllCategories);
  yield takeLatest<GetUsersByAcademy>(
    GET_ALL_USERS_BY_ACADEMY,
    getAllUsersByAcademy
  );
  yield takeLatest<UpdateUsers>(UPDATE_USERS, updateUsers);
}
