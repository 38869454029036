import {
  initialState,
  Actions,
  State,
  GET_ALL_USERS,
  GET_ALL_USERS_ERROR,
  GET_ALL_USERS_SUCCESS,
  UPDATE_USERS,
  UPDATE_USERS_SUCCESS,
  UPDATE_USERS_ERROR,
  GET_ALL_USERS_BY_ACADEMY,
  RESET_GET_ALL_USERS,
  DELETE_USER_FROM_ENTITY,
} from "../types/users";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_USERS:
      return {
        ...state,
        all: {
          ...state.all,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case RESET_GET_ALL_USERS: {
      return {
        ...state,
        all: initialState.all,
      };
    }
    case GET_ALL_USERS_BY_ACADEMY:
      return {
        ...state,
        all: {
          ...state.all,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        all: {
          ...state.all,
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_USERS_ERROR:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: false,
            error: true,
            success: false,
          },
        },
      };
    case UPDATE_USERS:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_USER_FROM_ENTITY:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_USERS_SUCCESS:
      return {
        ...state,
        update: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_USERS_ERROR:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: false,
            error: true,
            success: false,
          },
        },
      };
    default:
      return state;
  }
};

export default reducers;
