import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCurrent } from "../../../../store/actions/current";
import { AcademyDto } from "../../../../types/academy.dto";
import { OrganizationDto } from "../../../../types/organization.dto";
import {
  Links,
  List,
  Link,
  LinkText,
  LinkImageWrapper,
  LinkRole,
} from "./style";
import academyActions from "../../../../store/actions/academies";
import organizationActions from "../../../../store/actions/organization";
import { CurrentDto } from "../../../../types/current.dto";
import { Img } from "../../../../pages/SignIn/styles";
import { FlexContainer } from "../../../StyledComponents";

interface ComponentProps {
  subLinks:
    | AcademyDto[]
    | OrganizationDto[]
    | { name: string; link: string; goTo?: any }[];
  title: string;
  section: string;
  id: string;
  current: CurrentDto;
}

const Component = ({
  subLinks,
  title,
  section,
  current,
  id,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Links
      style={{ maxHeight: "400px", overflow: "auto", paddingRight: "2px" }}
    >
      {subLinks.map((subLink) => (
        <Link
          key={subLink._id}
          onClick={() => {
            dispatch(
              setCurrent({
                id: subLink._id,
                currentSection: section,
                role:
                  subLink.users.filter(
                    (user) => user.user === id && !user.roleEnd
                  )[0].role ?? "OBSERVER",
              })
            );
            !!subLink.goTo && navigate(subLink.goTo);
            window.history.pushState(null, "", window.location.href);
            window.onpopstate = function () {
              window.history.go(1);
            };
          }}
        >
          <FlexContainer
            justify="space-between"
            wrap="wrap"
            gap="2px"
            align="center"
          >
            <FlexContainer gap="4px" align="center">
              <LinkImageWrapper>
                <Img
                  src={subLink.picture}
                  alt="academy-picture"
                  title={subLink.name}
                ></Img>
              </LinkImageWrapper>
              <LinkText hideLine={2}>{subLink.name}</LinkText>
            </FlexContainer>
            <LinkRole>
              {subLink.users.filter(
                (user) => user.user === id && !user.roleEnd
              )[0].role ?? "OBSERVER"}
            </LinkRole>
          </FlexContainer>
        </Link>
      ))}
    </Links>
  );
};

const states = ({ currentStore }) => {
  const { data: current } = currentStore;
  return {
    current,
  };
};

export default connect(states)(Component);
