import {
  GetAllGeneralContents,
  GET_ALL_GENERALCONTENTS,
  GetGeneralContent,
  GET_GENERALCONTENT,
  UpdateGeneralContent,
  UPDATE_GENERALCONTENT,
  DeleteGeneralContent,
  DELETE_GENERALCONTENT,
  GetGeneralContentSuccess,
  GET_GENERALCONTENT_SUCCESS,
  NewGeneralContent,
  NEW_GENERALCONTENT,
  ResetNewGeneralContent,
  RESET_NEW_GENERALCONTENT,
  ResetUpdateGeneralContent,
  RESET_UPDATE_GENERALCONTENT,
  ResetDeleteGeneralContent,
  RESET_DELETE_GENERALCONTENT,
  UploadMedia,
  UPLOAD_MEDIA,
  RESET_GET_GENERALCONTENT,
  ADD_USER_TO_GENERALCONTENT,
  SHARED_GENERALCONTENT,
  UNSAVED_GENERALCONTENT,
  SAVED_GENERALCONTENT,
  QUERY_GENERALCONTENT,
  AddClickGeneralContent,
  ADD_CLICK_GENERALCONTENT,
  AddViewGeneralContent,
  ADD_VIEW_GENERALCONTENT,
  MANAGE_LIKE_DISLIKE_GENERALCONTENT,
  GET_GENERALCONTENTS_OFFSET,
  GetGeneralContentsOffset,
  RESET_GET_GENERALCONTENTS_OFFSET,
  ResetGetGeneralContentsOffset,
  RESET_GET_ALL_GENERALCONTENT_DATA,
  RESET_GET_ONE_GENERALCONTENT_DATA,
  UPDATE_ITEM_LIST_GENERALCONTENT,
  UPDATE_MANY_GENERAL_CONTENT,
  RESET_UPDATE_MANY_GENERAL_CONTENT,
} from "../types/generalContent";

export const getAllGeneralContents = (payload): GetAllGeneralContents => {
  return { type: GET_ALL_GENERALCONTENTS, payload };
};

export const getGeneralContent = (id): GetGeneralContent => {
  return { type: GET_GENERALCONTENT, payload: id };
};

export const updateGeneralContentistItem = (payload) => {
  return { type: UPDATE_ITEM_LIST_GENERALCONTENT, payload };
};

export const resetGetGeneralContent = () => {
  return { type: RESET_GET_GENERALCONTENT, payload: null };
};

export const unSavedGeneralContent = (payload) => {
  return { type: UNSAVED_GENERALCONTENT, payload };
};

export const queryGeneralContent = (payload) => {
  return { type: QUERY_GENERALCONTENT, payload };
};

export const savedGeneralContent = (payload) => {
  return { type: SAVED_GENERALCONTENT, payload };
};

export const setSelectedGeneralContent = (
  payload
): GetGeneralContentSuccess => {
  return { type: GET_GENERALCONTENT_SUCCESS, payload };
};

export const resetGetAllGeneralContentsData = () => {
  return { type: RESET_GET_ALL_GENERALCONTENT_DATA, payload: null };
};

export const newGeneralContent = (payload): NewGeneralContent => {
  return { type: NEW_GENERALCONTENT, payload };
};
export const uploadMediaGeneralContent = (payload): UploadMedia => {
  return { type: UPLOAD_MEDIA, payload };
};
export const resetNewGeneralContent = (): ResetNewGeneralContent => {
  return { type: RESET_NEW_GENERALCONTENT, payload: null };
};

export const updateGeneralContent = (payload): UpdateGeneralContent => {
  return { type: UPDATE_GENERALCONTENT, payload };
};

export const resetUpdateGeneralContent = (): ResetUpdateGeneralContent => {
  return { type: RESET_UPDATE_GENERALCONTENT, payload: null };
};

export const addUserToGeneralContent = (payload) => {
  return { type: ADD_USER_TO_GENERALCONTENT, payload };
};

export const deleteGeneralContent = (payload): DeleteGeneralContent => {
  return { type: DELETE_GENERALCONTENT, payload };
};
export const offSetGeneralContents = (payload): GetGeneralContentsOffset => {
  return { type: GET_GENERALCONTENTS_OFFSET, payload };
};

export const resetOffsetGeneralContents = (): ResetGetGeneralContentsOffset => {
  return { type: RESET_GET_GENERALCONTENTS_OFFSET, payload: null };
};

export const resetGetAllGeneralContents = () => {
  return { type: RESET_GET_ALL_GENERALCONTENT_DATA, payload: null };
};

export const resetGetOneGeneralContent = () => {
  return { type: RESET_GET_ONE_GENERALCONTENT_DATA, payload: null };
};

export const resetDeleteGeneralContent = (): ResetDeleteGeneralContent => {
  return { type: RESET_DELETE_GENERALCONTENT, payload: null };
};

export const shareGeneralContent = (payload) => {
  return { type: SHARED_GENERALCONTENT, payload };
};

export const addClick = (payload): AddClickGeneralContent => {
  return { type: ADD_CLICK_GENERALCONTENT, payload };
};

export const addView = (payload): AddViewGeneralContent => {
  return { type: ADD_VIEW_GENERALCONTENT, payload };
};

export const manageLikeDislikeGeneralContent = (payload) => {
  return { type: MANAGE_LIKE_DISLIKE_GENERALCONTENT, payload };
};

export const updateManyGeneralContents = (payload) => {
  return { type: UPDATE_MANY_GENERAL_CONTENT, payload };
};

export const resetUpdateManyGeneralContents = () => {
  return { type: RESET_UPDATE_MANY_GENERAL_CONTENT, payload: null };
};
