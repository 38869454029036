import { PaginationDto } from "../../types/pagination.dto";
import { ByteDto } from "../../types/byte.dto";

export const GET_ALL_BYTES = "GET_ALL_BYTE";
export const GET_ALL_BYTES_ERROR = "GET_ALL_BYTE_ERROR";
export const GET_ALL_BYTES_SUCCESS = "GET_ALL_BYTE_SUCCESS";
export const RESET_GET_ALL_BYTE_DATA = "RESET_GET_ALL_BYTE_DATA";
export const GET_BYTE = "GET_Byte";
export const GET_BYTE_ERROR = "GET_Byte_ERROR";
export const GET_BYTE_SUCCESS = "GET_Byte_SUCCESS";
export const RESET_GET_BYTE = "RESET_GET_BYTE";
export const SET_GET_BYTE = "SET_GET_BYTE";
export const NEW_BYTE = "NEW_Byte";
export const NEW_BYTE_ERROR = "NEW_Byte_ERROR";
export const NEW_BYTE_SUCCESS = "NEW_Byte_SUCCESS";
export const RESET_NEW_BYTE = "RESET_NEW_Byte";
export const UPDATE_BYTE = "UPDATE_Byte";
export const UPDATE_BYTE_ERROR = "UPDATE_Byte_ERROR";
export const UPDATE_BYTE_SUCCESS = "UPDATE_Byte_SUCCESS";
export const RESET_UPDATE_BYTE = "RESET_UPDATE_Byte";
export const DELETE_BYTE = "DELETE_Byte";
export const DELETE_BYTE_ERROR = "DELETE_Byte_ERROR";
export const DELETE_BYTE_SUCCESS = "DELETE_Byte_SUCCESS";
export const RESET_DELETE_BYTE = "RESET_DELETE_Byte";
export const UPLOAD_MEDIA = "UPLOAD_MEDIA";
export const UPDATE_BYTE_LOADING = "UPDATE_BYTE_LOADING";
export const ADD_USER_TO_BYTE = "ADD_USER_TO_BYTE";
export const SHARED_BYTE = "SHARED_BYTE";
export const UPDATE_MANY_BYTE = "UPDATE_MANY_BYTE";
export const UPDATE_MANY_BYTE_ERROR = "UPDATE_MANY_BYTE_ERROR";
export const UPDATE_MANY_BYTE_SUCCESS = "UPDATE_MANY_BYTE_SUCCESS";
export const RESET_UPDATE_MANY_BYTE = "RESET_UPDATE_MANY_BYTE";

export interface UpdateManyByte {
  type: typeof UPDATE_MANY_BYTE;
  payload: any;
}

export interface UpdateManyByteSuccess {
  type: typeof UPDATE_MANY_BYTE_SUCCESS;
  payload: ByteDto[];
}

export interface UpdateManyByteError {
  type: typeof UPDATE_MANY_BYTE_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateManyByte {
  type: typeof RESET_UPDATE_MANY_BYTE;
  payload: null;
}

export interface SharedByte {
  type: typeof SHARED_BYTE;
  payload: any;
}
export interface GetAllBytes {
  type: typeof GET_ALL_BYTES;
  payload: PaginationDto;
}

export interface GetAllBytesSuccess {
  type: typeof GET_ALL_BYTES_SUCCESS;
  payload: ByteDto[];
}

export interface ResetGetAllByteData {
  type: typeof RESET_GET_ALL_BYTE_DATA;
  payload: null;
}

export interface SetGetByte {
  type: typeof SET_GET_BYTE;
  payload: ByteDto | null;
}

export interface GetAllBytesError {
  type: typeof GET_ALL_BYTES_ERROR;
  payload: boolean | string;
}

export interface AddUserToByte {
  type: typeof ADD_USER_TO_BYTE;
  payload: any;
}

export interface GetByte {
  type: typeof GET_BYTE;
  payload: { id: string };
}

export interface GetByteSuccess {
  type: typeof GET_BYTE_SUCCESS;
  payload: ByteDto;
}

export interface GetByteError {
  type: typeof GET_BYTE_ERROR;
  payload: boolean | string;
}

export interface NewByte {
  type: typeof NEW_BYTE;
  payload: ByteDto;
}

export interface NewBYTEuccess {
  type: typeof NEW_BYTE_SUCCESS;
  payload: ByteDto;
}

export interface ResetGetByte {
  type: typeof RESET_GET_BYTE;
  payload: null;
}

export interface NewByteError {
  type: typeof NEW_BYTE_ERROR;
  payload: boolean | string;
}

export interface UploadMedia {
  type: typeof UPLOAD_MEDIA;
  payload: any;
}

export interface ResetNewByte {
  type: typeof RESET_NEW_BYTE;
  payload: null;
}

export interface UpdateByte {
  type: typeof UPDATE_BYTE;
  payload: any;
}

export interface UpdateByteLoading {
  type: typeof UPDATE_BYTE_LOADING;
  payload: ByteDto;
}

export interface UpdateBYTEuccess {
  type: typeof UPDATE_BYTE_SUCCESS;
  payload: ByteDto;
}

export interface UpdateByteError {
  type: typeof UPDATE_BYTE_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateByte {
  type: typeof RESET_UPDATE_BYTE;
  payload: null;
}

export interface DeleteByte {
  type: typeof DELETE_BYTE;
  payload: { _id: string };
}

export interface DeleteBYTEuccess {
  type: typeof DELETE_BYTE_SUCCESS;
  payload: null;
}

export interface DeleteByteError {
  type: typeof DELETE_BYTE_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteByte {
  type: typeof RESET_DELETE_BYTE;
  payload: null;
}

export interface State {
  all: {
    data: ByteDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  updateMany: {
    data: null | ByteDto[];
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  byte: {
    data: ByteDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  new: {
    data: ByteDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  delete: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  update: {
    data: null | ByteDto;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  all: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  byte: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  updateMany: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  new: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  delete: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  update: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllBytes
  | GetAllBytesSuccess
  | GetAllBytesError
  | ResetGetAllByteData
  | GetByte
  | GetByteSuccess
  | GetByteError
  | NewByte
  | NewBYTEuccess
  | NewByteError
  | ResetNewByte
  | UpdateByte
  | UpdateBYTEuccess
  | UpdateByteError
  | ResetUpdateByte
  | DeleteByte
  | DeleteBYTEuccess
  | DeleteByteError
  | ResetDeleteByte
  | UploadMedia
  | UpdateByteLoading
  | ResetGetByte
  | AddUserToByte
  | SharedByte
  | SetGetByte
  | UpdateManyByte
  | UpdateManyByteSuccess
  | UpdateManyByteError
  | ResetUpdateManyByte;
