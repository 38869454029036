const index = (
  text: string | undefined,
  large: number,
  dots?: boolean
): string | undefined => {
  if (text && text.length < large) return text;
  return text?.slice(0, large).concat(`${!dots ? "..." : ""}`);
};

export default index;
