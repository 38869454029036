import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Icon from "../../../../../components/Icon";
import {
  Center,
  FlexContainer,
} from "../../../../../components/StyledComponents";
import {
  Card,
  CardRow,
  CardCol,
  Avatar,
  Information,
  Name,
  Profile,
  Location,
  ItemLink,
  Commets,
  List,
  Item,
  SmallCardChart,
  BigCardChart,
  NoteCard,
  ButtonText,
} from "./styles";
import Link from "../../../../../components/Button";
import envelopeIcon from "../../../../../assets/icons/envelope.svg";
import cv from "../../../../../assets/icons/cv.svg";
import github from "../../../../../assets/icons/github.svg";
import linkedin from "../../../../../assets/icons/linkedin.svg";
import briefcase from "../../../../../assets/icons/briefcase.svg";
import talentActions from "../../../../../store/actions/recruiting/ait-talent";
import { GiPerson } from "react-icons/gi";
import RadarChart from "../../../../../components/RadarChart";
import { TitleStage } from "../../../Analytics/IndividualPerformance/components/NumericStats/styles";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CurrentDto } from "../../../../../types/current.dto";
import { hideModal, showModal } from "../../../../../store/actions/modal";
import { addContact, makeClick } from "../../../../../store/actions/talent";
import { FaTable } from "react-icons/fa";
import { BsFillBarChartFill } from "react-icons/bs";
import Loader from "../../../../../components/Loader";
import { arrayLengthDivision } from "../../../../../helpers/notLengthDivision";
import { StateDto } from "../../../../../types/states.dto";
interface ComponentProps {
  currentTalent;
  currentTalentValues;
  provinces;
  isAIT: boolean;
  stats?;
  grid: boolean;
  aitTalentStates: {
    success: boolean;
    error: string | boolean;
    loading: boolean;
  };
  addAitTalent?: (values: any) => void;
  setTalent?: any;
  current: CurrentDto;
  studentsStatsStates: StateDto;
  usersIdsForAnalytics: { _id: string; cohorts: string[] }[];
}

const Component = ({
  currentTalent,
  currentTalentValues,
  provinces,
  stats,
  addAitTalent,
  aitTalentStates,
  isAIT,
  grid,
  setTalent,
  current,
  studentsStatsStates,
  usersIdsForAnalytics,
}: ComponentProps) => {
  const [mergedCurrentTalent, setMergedCurrentTarget] = useState({
    ...currentTalent,
    ...currentTalentValues,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getStats = (stats) => {
    const subarrays = (data): any[] => {
      return data.reduce((result, obj) => {
        const category = obj.skill._id;
        if (!result[category]) {
          result[category] = [];
        }
        result[category].push(obj);
        return result;
      }, {});
    };
    return Object.values(subarrays(stats.skills)).reduce(
      (prev: any, current, index, array) => {
        if (prev.find((item) => item.criterion._id === current.skill._id))
          return prev;
        else {
          const promCurrentCriterion = array
            .filter((item) => item.skill._id === current.skill._id)
            .map((item) => item.note);
          return [
            ...prev,
            {
              criterion: current.skill,
              average:
                promCurrentCriterion.reduce(
                  (prev, current) => current + prev,
                  0
                ) / arrayLengthDivision(promCurrentCriterion),
            },
          ];
        }
      },
      []
    );
  };

  useEffect(() => {
    setMergedCurrentTarget({ ...currentTalent, ...currentTalentValues });
  }, [currentTalent, currentTalentValues]);
  useEffect(() => {
    if (aitTalentStates.success) {
      toast("👌🏼 Talento agregado correctamente", {
        containerId: "submit",
        type: "success",
        toastId: "submitsucceful",
      });
      dispatch(hideModal());
      setTimeout(() => {
        dispatch(talentActions.resetCreate());
      }, 1000);
    }

    if (aitTalentStates.error) {
      toast(`😱 ${aitTalentStates.error}`, {
        containerId: "submit",
        type: "error",
        toastId: "submiterror",
      });

      setTimeout(() => {
        dispatch(talentActions.resetCreate());
      }, 1000);
    }
  }, [aitTalentStates]);

  return (
    <Center>
      <Card className={grid ? "grid" : ""}>
        <CardCol style={{ maxWidth: "265px" }}>
          <CardRow>
            <Avatar grid={grid} avatar={mergedCurrentTalent.picture} />
            <Information>
              <Name grid={grid}>
                {mergedCurrentTalent?.name
                  ? `${mergedCurrentTalent?.name} ${mergedCurrentTalent?.lastName}`
                  : "Talento"}
              </Name>
              <Profile grid={grid}>
                {mergedCurrentTalent.bootcampName || "No Bootcamp"}
              </Profile>
              {mergedCurrentTalent.gender && (
                <Location grid={grid}>
                  {`Genero: ${mergedCurrentTalent.gender}`}
                </Location>
              )}
            </Information>
          </CardRow>

          <CardRow style={{ margin: "20px 0px" }}>
            <List>
              {mergedCurrentTalent?.email && (
                <Item
                  onClick={() => {
                    dispatch(
                      addContact({
                        _id: mergedCurrentTalent._id,
                        ...(current.currentSection === "academy" && {
                          currentAcademy: current.id,
                        }),
                      })
                    );
                    dispatch(
                      makeClick({
                        _id: mergedCurrentTalent._id,
                        ...(current.currentSection === "academy" && {
                          currentAcademy: current.id,
                        }),
                      })
                    );
                  }}
                >
                  <ItemLink
                    target="_blank"
                    href={`mailto:${mergedCurrentTalent?.email}`}
                  >
                    <Icon icon={envelopeIcon} size="18px" color="#697482" />
                  </ItemLink>
                </Item>
              )}

              {mergedCurrentTalent?.github && (
                <Item
                  onClick={() => {
                    dispatch(
                      addContact({
                        _id: mergedCurrentTalent._id,
                        ...(current.currentSection === "academy" && {
                          currentAcademy: current.id,
                        }),
                      })
                    );
                    dispatch(
                      makeClick({
                        _id: mergedCurrentTalent._id,
                        ...(current.currentSection === "academy" && {
                          currentAcademy: current.id,
                        }),
                      })
                    );
                  }}
                >
                  <ItemLink
                    target="_blank"
                    href={`http://${mergedCurrentTalent?.github}`}
                  >
                    <Icon icon={github} size="18px" color="#697482" />
                  </ItemLink>
                </Item>
              )}

              {mergedCurrentTalent?.curriculum && (
                <Item
                  onClick={() => {
                    dispatch(
                      addContact({
                        _id: mergedCurrentTalent._id,
                        ...(current.currentSection === "academy" && {
                          currentAcademy: current.id,
                        }),
                      })
                    );
                    dispatch(
                      makeClick({
                        _id: mergedCurrentTalent._id,
                        ...(current.currentSection === "academy" && {
                          currentAcademy: current.id,
                        }),
                      })
                    );
                  }}
                >
                  <ItemLink
                    target="_blank"
                    href={`http://${mergedCurrentTalent?.curriculum}`}
                  >
                    <Icon icon={cv} size="18px" color="#697482" />
                  </ItemLink>
                </Item>
              )}

              {mergedCurrentTalent?.portfolio && (
                <Item
                  onClick={() => {
                    dispatch(
                      addContact({
                        _id: mergedCurrentTalent._id,
                        ...(current.currentSection === "academy" && {
                          currentAcademy: current.id,
                        }),
                      })
                    );
                    dispatch(
                      makeClick({
                        _id: mergedCurrentTalent._id,
                        ...(current.currentSection === "academy" && {
                          currentAcademy: current.id,
                        }),
                      })
                    );
                  }}
                >
                  <ItemLink
                    target="_blank"
                    href={`http://${mergedCurrentTalent?.portfolio}`}
                  >
                    <Icon icon={briefcase} size="18px" color="#697482" />
                  </ItemLink>
                </Item>
              )}

              {mergedCurrentTalent?.linkedin && (
                <Item
                  onClick={() => {
                    dispatch(
                      addContact({
                        _id: mergedCurrentTalent._id,
                        ...(current.currentSection === "academy" && {
                          currentAcademy: current.id,
                        }),
                      })
                    );
                    dispatch(
                      makeClick({
                        _id: mergedCurrentTalent._id,
                        ...(current.currentSection === "academy" && {
                          currentAcademy: current.id,
                        }),
                      })
                    );
                  }}
                >
                  <ItemLink
                    target="_blank"
                    href={`http://${mergedCurrentTalent?.linkedin}`}
                  >
                    <Icon icon={linkedin} size="18px" color="#697482" />
                  </ItemLink>
                </Item>
              )}
            </List>
          </CardRow>

          {!grid && (
            <CardRow>
              <Commets>{mergedCurrentTalent?.comments}</Commets>
            </CardRow>
          )}
          {!!grid && (
            <FlexContainer direction="column" gap="10px">
              <Link
                onClick={() => {
                  dispatch(
                    makeClick({
                      _id: mergedCurrentTalent._id,
                      ...(current.currentSection === "academy" && {
                        currentAcademy: current.id,
                      }),
                    })
                  );
                  navigate(
                    `/dashboard/analytics/individual-performance/${mergedCurrentTalent._id}`
                  );
                }}
                type="button"
                options={{
                  type: "filled",
                  skin: "primary",
                  size: "sm",
                  marginBottom: "0px",
                }}
                style={{
                  height: "24px",
                  borderRadius: "10px",
                  padding: "0px 9px",
                  minwidth: "0px",
                }}
              >
                <FlexContainer gap="4px">
                  <BsFillBarChartFill size={13}></BsFillBarChartFill>
                  <span>Ver performance completa</span>
                </FlexContainer>
              </Link>
              {/*  {current.currentSection === "personal" && (
                <Link
                  onClick={() => {
                    dispatch(
                      addContact({
                        _id: mergedCurrentTalent._id,
                        ...(current.currentSection === "academy" && {
                          currentAcademy: current.id,
                        }),
                      })
                    );

                    !!addAitTalent && addAitTalent(mergedCurrentTalent);
                  }}
                  type="button"
                  options={{
                    type: "filled",
                    skin: "primary",
                    size: "sm",
                    marginBottom: "0px",
                  }}
                  style={{
                    height: "24px",
                    borderRadius: "10px",
                    padding: "0px 9px",
                    minwidth: "0px",
                  }}
                >
                  <FlexContainer gap="4px">
                    <FaTable size={13}></FaTable>
                    <span>Agregar a tableros</span>
                  </FlexContainer>
                </Link>
              )} */}
            </FlexContainer>
          )}
        </CardCol>

        {!grid && (
          <CardCol>
            <CardRow>
              <CardCol style={{ marginRight: "10px" }}>
                <CardRow style={{ marginBottom: "10px" }}>
                  <SmallCardChart>
                    {!!stats ? (
                      <>
                        {stats.length === 1 && stats[0] ? (
                          <>
                            <TitleStage>Promedio</TitleStage>
                            <NoteCard>
                              {Math.round(
                                stats[0].skills.reduce((prev, current) => {
                                  return prev + current.note;
                                }, 0) / arrayLengthDivision(stats[0].skills)
                              )}
                            </NoteCard>
                          </>
                        ) : (
                          <FlexContainer
                            align="center"
                            style={{ height: "100%" }}
                            justify="center"
                          >
                            <h3>No stats</h3>
                          </FlexContainer>
                        )}
                      </>
                    ) : studentsStatsStates.loading &&
                      !usersIdsForAnalytics.find(
                        (user) => user._id === mergedCurrentTalent._id
                      ) ? (
                      <FlexContainer
                        align="center"
                        style={{ height: "100%" }}
                        justify="center"
                      >
                        <Loader color="Primary" />
                      </FlexContainer>
                    ) : (
                      <FlexContainer
                        align="center"
                        style={{ height: "100%" }}
                        justify="center"
                      >
                        <h3>No stats</h3>
                      </FlexContainer>
                    )}
                  </SmallCardChart>
                </CardRow>
              </CardCol>

              <CardCol>
                <BigCardChart>
                  {!!stats ? (
                    <>
                      {stats.length === 1 && stats[0] ? (
                        <>
                          <TitleStage>Skills</TitleStage>
                          <RadarChart
                            labels={getStats(stats[0]).map(
                              (prom) => prom.criterion.name
                            )}
                            data={getStats(stats[0]).map(
                              (prom) => prom.average
                            )}
                          />
                        </>
                      ) : (
                        <FlexContainer
                          align="center"
                          style={{ height: "100%" }}
                          justify="center"
                        >
                          <h3>No stats</h3>
                        </FlexContainer>
                      )}
                    </>
                  ) : studentsStatsStates.loading &&
                    !usersIdsForAnalytics.find(
                      (user) => user._id === mergedCurrentTalent._id
                    ) ? (
                    <FlexContainer
                      align="center"
                      style={{ height: "100%" }}
                      justify="center"
                    >
                      <Loader color="Primary" />
                    </FlexContainer>
                  ) : (
                    <FlexContainer
                      align="center"
                      style={{ height: "100%" }}
                      justify="center"
                    >
                      <h3>No stats</h3>
                    </FlexContainer>
                  )}
                </BigCardChart>
              </CardCol>
            </CardRow>
            <CardRow>
              <Center>
                <Link
                  onClick={() => {
                    dispatch(
                      makeClick({
                        _id: mergedCurrentTalent._id,
                        ...(current.currentSection === "academy" && {
                          currentAcademy: current.id,
                        }),
                      })
                    );
                    dispatch(
                      addContact({
                        _id: mergedCurrentTalent._id,
                        ...(current.currentSection === "academy" && {
                          currentAcademy: current.id,
                        }),
                      })
                    );
                    navigate(
                      `/dashboard/analytics/individual-performance/${mergedCurrentTalent._id}`
                    );
                  }}
                  type="button"
                  options={{
                    type: "filled",
                    skin: "primary",
                    size: "sm",
                    marginBottom: "0px",
                  }}
                  style={{
                    height: "28px",
                    marginLeft: "10px",
                    marginTop: "15px",
                    padding: "0px 9px",
                    minwidth: grid ? "100%" : "0px",
                  }}
                >
                  <FlexContainer gap="6px" align="center">
                    <BsFillBarChartFill size={17}></BsFillBarChartFill>
                    <ButtonText>Ver performance completa</ButtonText>
                  </FlexContainer>
                </Link>
                {/*  {current.currentSection === "personal" && (
                  <Link
                    onClick={() =>
                      !!addAitTalent && addAitTalent(mergedCurrentTalent)
                    }
                    type="button"
                    options={{
                      type: "filled",
                      skin: "primary",
                      size: "sm",
                      marginBottom: "0px",
                    }}
                    style={{
                      height: "28px",
                      marginLeft: "10px",
                      marginTop: "15px",
                      padding: "0px 9px",
                      minwidth: "0px",
                    }}
                  >
                    <FlexContainer gap="6px" align="center">
                      <FaTable size={17}></FaTable>
                      <ButtonText>Agregar a tableros</ButtonText>
                    </FlexContainer>
                  </Link>
                )} */}

                {/*  {current.currentSection === "academy" && (
                  <Link
                    onClick={() => {
                      setTalent(mergedCurrentTalent);
                      dispatch(showModal("invite-team-academy-modal"));
                    }}
                    type="button"
                    options={{
                      type: "filled",
                      skin: "primary",
                      size: "sm",
                      marginBottom: "0px",
                    }}
                    style={{
                      height: "28px",
                      marginLeft: "10px",
                      marginTop: "15px",
                      padding: "0px 9px",
                      minwidth: "0px",
                    }}
                  >
                    <FlexContainer gap="6px" align="center">
                      <GiPerson
                        size={17}
                        style={{ marginRight: "4.4px" }}
                      ></GiPerson>
                      <ButtonText>Agregar a Equipo</ButtonText>
                    </FlexContainer>
                  </Link>
                )} */}
                {current.currentSection === "organization" && (
                  <Link
                    onClick={() => {
                      setTalent(mergedCurrentTalent);
                      dispatch(showModal("invite-team-modal"));
                    }}
                    type="button"
                    options={{
                      type: "filled",
                      skin: "primary",
                      size: "sm",
                      marginBottom: "0px",
                    }}
                    style={{
                      height: "28px",
                      marginLeft: "10px",
                      marginTop: "15px",
                      padding: "0px 9px",
                      minwidth: "0px",
                    }}
                  >
                    <FlexContainer gap="6px" align="center">
                      <GiPerson
                        size={17}
                        style={{ marginRight: "4.4px" }}
                      ></GiPerson>
                      <ButtonText>Agregar a Equipo</ButtonText>
                    </FlexContainer>
                  </Link>
                )}
              </Center>
            </CardRow>
          </CardCol>
        )}
      </Card>
    </Center>
  );
};

const states = ({
  staticsStore,
  analyticsStore,
  recruitingStore,
  currentStore,
}) => {
  const { data: provinces } = staticsStore.allProvinces;
  const { data: individualAnalytics } = analyticsStore.getIndividualStats;
  const { states: aitTalentStates } = recruitingStore.talent.create;
  const { data: current } = currentStore;
  return {
    provinces,
    aitTalentStates,
    individualAnalytics,
    current,
  };
};

export default connect(states)(Component);
