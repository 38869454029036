import styled from "styled-components";

export const Icon = styled("i")<{ size; color?; icon }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  display: block;

  &:before {
    content: "";
    display: block;
    background-color: ${({ color }) => color};
    mask: url(${({ icon }) => icon});
    mask-size: cover;
    width: ${({ size }) => size};
    height: ${({ size }) => size};
  }
`;
