import * as Yup from "yup";

export const schema = Yup.object().shape({
  name: Yup.string().required("Ingresa un nombre."),

  /*  description: Yup.string().required("Ingresa una descripción."), */
  startDate: Yup.string().required("Ingresa la fecha de inicio."),
  startHour: Yup.string().required("Ingresa la hora de inicio."),
  endHour: Yup.string()
    .required("Ingresa la hora de fin.")
    .test("is-later", "Hora anterior", function (endTime) {
      const { startHour } = this.parent;
      const startHourDate = new Date(`1970-01-01T${startHour}`);
      const endTimeDate = new Date(`1970-01-01T${endTime}`);
      return endTimeDate > startHourDate;
    }),
  frecuency: Yup.string().required("Ingresa la frecuencia del evento."),
  endDate: Yup.string().when("frecuency", {
    is: "personalized", // alternatively: (val) => val == true
    then: Yup.string().required("Ingresa una fecha de fin."),
  }),
  eventDays: Yup.array()
    .nullable()
    .when("frecuency", {
      is: "personalized", // alternatively: (val) => val == true
      then: Yup.array()
        .min(1, "Al menos un dia")
        .required("Ingresa que dias se dictara el curso."),
    }),
  meetLink: Yup.string()
    .matches(
      /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
      "Porfavor introduzca https link"
    )
    .required("Porfavor introduzca https link"),
});

interface FormValues {
  name: string;
  /*   description: string; */
  startDate: string;
  startHour: string;
  endHour: string;
  frecuency: string;
  endDate: string;
  eventDays: string[];
  meetLink: string;
}

export const initialValues: FormValues = {
  name: "",
  /*  description: "", */
  startDate: "",
  startHour: "",
  meetLink: "https://",
  endHour: "",
  frecuency: "unique",
  endDate: "",
  eventDays: [],
};
