import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import services from "../../services";
import { autoSignIn } from "../../store/actions/auth";
import { UserDto } from "../../types/user.dto";

interface AuthContextType {
  isAuthenticated: boolean;
  user: UserDto | null;
}

interface AuthProviderType {
  children: React.ReactNode;
  user: UserDto;
  signInStates;
  signUpStates;
  userStates;
  signOutStates;
}

export let AuthContext = React.createContext<AuthContextType>(null!);

const AuthProvider = ({
  children,
  user,
  signInStates,
  signUpStates,
  userStates,
  signOutStates,
}: AuthProviderType) => {
  const [hasValidToken, setHasValidToken] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (hasValidToken && !signInStates.success) {
      dispatch(autoSignIn());
    }
  }, [hasValidToken]);

  useEffect(() => {
    setHasValidToken(services.auth.isValidToken());
  }, []);

  useEffect(() => {
    if (signUpStates.success) {
      setHasValidToken(services.auth.isValidToken());
    }
  }, [signUpStates]);

  useEffect(() => {
    if (signInStates.success) {
      setHasValidToken(services.auth.isValidToken());
    }
  }, [signInStates, signUpStates]);

  useEffect(() => {
    if (signOutStates.success) {
      window.location.href = "/";
    }
  }, [signOutStates]);

  return (
    <AuthContext.Provider value={{ user, isAuthenticated: hasValidToken }}>
      {children}
    </AuthContext.Provider>
  );
};

const states = ({ authStore, userStore }) => {
  const { data: user, states: userStates } = userStore;
  const { states: signInStates } = authStore.signin;
  const { states: signUpStates } = authStore.signup;
  const { states: signOutStates } = authStore.signout;

  return {
    user,
    userStates,
    signUpStates,
    signInStates,
    signOutStates,
  };
};

export default connect(states)(AuthProvider);
