import styled from "styled-components";

export const ListItem = styled("div")`
  background-color: #fff;
  padding: 4px 20px;
  border-radius: 10px;
  box-shadow: 0 0 6px 0 rgba(79, 38, 162, 0.16);
`;

export const Table = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  margin-top: 20px;
  overflow-y: scroll;
  max-height: 500px;
`;

export const Row = styled("div")`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
export const Cell = styled("div")`
  flex: 1;
  min-width: 0;
  border: 1px solid #ccc;
  padding: 8px;
  word-break: break-word;
`;

export const ListWrapper = styled("div")`
  width: 100%;
  margin: 0 auto;
  max-width: 1000px;
  padding: 10px;
  background-color: #fff;
`;

export const EntityImageContainer = styled("div")`
  min-width: 32px;
  min-height: 32px;
  max-width: 32px;
  max-height: 32px;
  overflow: hidden;
  box-shadow: 0 0 1px 0 rgba(0, 30, 108, 0.16);
  border-radius: 50%;
`;

export const EntityTitleSemiBold = styled("h3")`
  font-size: 14px;
  font-weight: normal;
  color: #2a2747;
  word-break: break-word;
  overflow-wrap: break-word;
`;

export const EntityTitleDescription = styled("h3")`
  max-width: 412px;
  font-size: 12px;
  font-weight: normal;
  color: #697482;
`;
