import { CDocDto } from "../../types/cdoc.dto";
import { PaginationDto } from "../../types/pagination.dto";

export const GET_ALL_CDOCS = "GET_ALL_CDOCS";
export const GET_ALL_CDOCS_ERROR = "GET_ALL_CDOCS_ERROR";
export const GET_ALL_CDOCS_SUCCESS = "GET_ALL_CDOCS_SUCCESS";
export const RESET_GET_ALL_CDOC_DATA = "RESET_GET_ALL_CDOC_DATA";
export const GET_CDOC = "GET_CDOC";
export const GET_CDOC_ERROR = "GET_CDOC_ERROR";
export const GET_CDOC_SUCCESS = "GET_CDOC_SUCCESS";
export const RESET_GET_CDOC = "RESET_GET_CDOC";
export const SET_GET_CDOC = "SET_GET_CDOC";
export const NEW_CDOC = "NEW_CDOC";
export const NEW_CDOC_ERROR = "NEW_CDOC_ERROR";
export const NEW_CDOC_SUCCESS = "NEW_CDOC_SUCCESS";
export const RESET_NEW_CDOC = "RESET_NEW_CDOC";
export const UPDATE_CDOC = "UPDATE_CDOC";
export const UPDATE_CDOC_ERROR = "UPDATE_CDOC_ERROR";
export const UPDATE_CDOC_SUCCESS = "UPDATE_CDOC_SUCCESS";
export const RESET_UPDATE_CDOC = "RESET_UPDATE_CDOC";
export const UPDATE_MANY_CDOC = "UPDATE_MANY_CDOC";
export const UPDATE_MANY_CDOC_ERROR = "UPDATE_MANY_CDOC_ERROR";
export const UPDATE_MANY_CDOC_SUCCESS = "UPDATE_MANY_CDOC_SUCCESS";
export const UPDATE_CDOC_CURRENT_LIST = "UPDATE_CDOC_CURRENT_LIST";
export const RESET_UPDATE_MANY_CDOC = "RESET_UPDATE_MANY_CDOC";
export const DELETE_CDOC = "DELETE_CDOC";
export const DELETE_CDOC_ERROR = "DELETE_CDOC_ERROR";
export const DELETE_CDOC_SUCCESS = "DELETE_CDOC_SUCCESS";
export const RESET_DELETE_CDOC = "RESET_DELETE_CDOC";
export const DELETE_CDOC_MEMBER = "DELETE_CDOC_MEMBER";
export const DELETE_CDOC_MEMBER_SUCCESS = "DELETE_CDOC_MEMBER_SUCCESS";
export const DELETE_CDOC_MEMBER_ERROR = "DELETE_CDOC_MEMBER_ERROR";
export const ADD_COHORT_CDOC = "ADD_COHORT_CDOC";
export const ADD_USER_CDOC = "ADD_USER_CDOC";
export const SHARED_CDOC = "SHARED_CDOC";
export interface GetAllCDOCs {
  type: typeof GET_ALL_CDOCS;
  payload: PaginationDto;
}
export interface ResetGetAllCdocData {
  type: typeof RESET_GET_ALL_CDOC_DATA;
  payload: null;
}

export interface AddCohortCdoc {
  type: typeof ADD_COHORT_CDOC;
  payload: { _id: string; cohortId: string };
}

export interface AddUserCdoc {
  type: typeof ADD_USER_CDOC;
  payload: any;
}
export interface GetAllCDOCsSuccess {
  type: typeof GET_ALL_CDOCS_SUCCESS;
  payload: CDocDto[];
}
export interface GetAllCDOCsError {
  type: typeof GET_ALL_CDOCS_ERROR;
  payload: boolean | string;
}

export interface GetCDOC {
  type: typeof GET_CDOC;
  payload: { _id: string };
}

export interface GetCDOCSuccess {
  type: typeof GET_CDOC_SUCCESS;
  payload: CDocDto;
}

export interface GetCDOCError {
  type: typeof GET_CDOC_ERROR;
  payload: boolean | string;
}

export interface ResetGetCDOC {
  type: typeof RESET_GET_CDOC;
  payload: null;
}

export interface SetGetCdoc {
  type: typeof SET_GET_CDOC;
  payload: CDocDto | null;
}

export interface NewCDOC {
  type: typeof NEW_CDOC;
  payload: CDocDto;
}

export interface NewCDOCSuccess {
  type: typeof NEW_CDOC_SUCCESS;
  payload: CDocDto;
}

export interface NewCDOCError {
  type: typeof NEW_CDOC_ERROR;
  payload: boolean | string;
}

export interface ResetNewCDOC {
  type: typeof RESET_NEW_CDOC;
  payload: null;
}

export interface UpdateCDOC {
  type: typeof UPDATE_CDOC;
  payload: any;
}

export interface UpdateCDOCSuccess {
  type: typeof UPDATE_CDOC_SUCCESS;
  payload: CDocDto;
}

export interface UpdateCDOCError {
  type: typeof UPDATE_CDOC_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateCDOC {
  type: typeof RESET_UPDATE_CDOC;
  payload: null;
}
export interface UpdateManyCDOC {
  type: typeof UPDATE_MANY_CDOC;
  payload: any;
}

export interface UpdateManyCDOCSuccess {
  type: typeof UPDATE_MANY_CDOC_SUCCESS;
  payload: CDocDto[];
}

export interface UpdateManyCDOCError {
  type: typeof UPDATE_MANY_CDOC_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateManyCDOC {
  type: typeof RESET_UPDATE_MANY_CDOC;
  payload: null;
}

export interface UpdateCdocCurrentList {
  type: typeof UPDATE_CDOC_CURRENT_LIST;
  payload: CDocDto[];
}

export interface DeleteCDOC {
  type: typeof DELETE_CDOC;
  payload: { id: string };
}

export interface DeleteCDOCSuccess {
  type: typeof DELETE_CDOC_SUCCESS;
  payload: null;
}

export interface DeleteCDOCError {
  type: typeof DELETE_CDOC_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteCDOC {
  type: typeof RESET_DELETE_CDOC;
  payload: null;
}

export interface SharedCdoc {
  type: typeof SHARED_CDOC;
  payload: any;
}

export interface State {
  all: {
    data: CDocDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  selected: {
    data: CDocDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  updateMany: {
    data: null | CDocDto[];
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  new: {
    data: CDocDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  delete: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  update: {
    data: null | CDocDto;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  all: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  selected: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  new: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  delete: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  update: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  updateMany: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllCDOCs
  | GetAllCDOCsSuccess
  | GetAllCDOCsError
  | GetCDOC
  | GetCDOCSuccess
  | GetCDOCError
  | NewCDOC
  | NewCDOCSuccess
  | NewCDOCError
  | ResetNewCDOC
  | UpdateCDOC
  | UpdateCDOCSuccess
  | UpdateCDOCError
  | ResetUpdateCDOC
  | DeleteCDOC
  | DeleteCDOCSuccess
  | DeleteCDOCError
  | ResetDeleteCDOC
  | AddCohortCdoc
  | ResetGetCDOC
  | AddUserCdoc
  | SharedCdoc
  | SetGetCdoc
  | UpdateManyCDOC
  | UpdateManyCDOCSuccess
  | UpdateManyCDOCError
  | ResetUpdateManyCDOC
  | UpdateCdocCurrentList
  | ResetGetAllCdocData;
