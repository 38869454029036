import { ContentDto } from "../../types/content.dto";
import { PaginationDto } from "../../types/pagination.dto";

export const GET_ALL_CONTENTS = "GET_ALL_CONTENTS";
export const GET_ALL_CONTENTS_ERROR = "GET_ALL_CONTENTS_ERROR";
export const GET_ALL_CONTENTS_SUCCESS = "GET_ALL_CONTENTS_SUCCESS";
export const RESET_GET_ALL_CONTENT_DATA = "RESET_GET_ALL_CONTENT_DATA";
export const GET_CONTENT = "GET_CONTENT";
export const GET_CONTENT_ERROR = "GET_CONTENT_ERROR";
export const GET_CONTENT_SUCCESS = "GET_CONTENT_SUCCESS";
export const SET_GET_CONTENT = "SET_GET_CONTENT";
export const NEW_CONTENT = "NEW_CONTENT";
export const NEW_CONTENT_ERROR = "NEW_CONTENT_ERROR";
export const NEW_CONTENT_SUCCESS = "NEW_CONTENT_SUCCESS";
export const RESET_NEW_CONTENT = "RESET_NEW_CONTENT";
export const UPDATE_CONTENT = "UPDATE_CONTENT";
export const UPDATE_CONTENT_ERROR = "UPDATE_CONTENT_ERROR";
export const UPDATE_MANY_CONTENT = "UPDATE_MANY_CONTENT";
export const UPDATE_MANY_CONTENT_ERROR = "UPDATE_MANY_CONTENT_ERROR";
export const UPDATE_MANY_CONTENT_SUCCESS = "UPDATE_MANY_CONTENT_SUCCESS";
export const RESET_UPDATE_MANY_CONTENT = "RESET_UPDATE_MANY_CONTENT";
export const UPDATE_CONTENT_SUCCESS = "UPDATE_CONTENT_SUCCESS";
export const RESET_UPDATE_CONTENT = "RESET_UPDATE_CONTENT";
export const DELETE_CONTENT = "DELETE_CONTENT";
export const DELETE_CONTENT_ERROR = "DELETE_CONTENT_ERROR";
export const DELETE_CONTENT_SUCCESS = "DELETE_CONTENT_SUCCESS";
export const RESET_DELETE_CONTENT = "RESET_DELETE_CONTENT";
export const SET_UPDATE_CONTENT_DATA = "SET_UPDATE_CONTENT_DATA";
export const SET_NEW_CONTENT_DATA = "SET_NEW_CONTENT_DATA";
export const UPDATE_CURRENT_CONTENT_LIST = "UPDATE_CURRENT_CONTENT_LIST";
export const UNSAVED_CONTENT = "UNSAVED_CONTENT";
export const SAVED_CONTENT = "SAVED_CONTENT";
export const SELECT_CONTENT = "SELECT_CONTENT";
export const UPDATE_CONTENT_ROLE = "UPDATE_CONTENT_ROLE";
export const DELETE_CONTENT_MEMBER = "DELETE_CONTENT_MEMBER";
export const RESET_GET_CONTENT = "RESET_GET_CONTENT";
export const ADD_USER_TO_CONTENT = "ADD_USER_TO_CONTENT";
export const SHARED_CONTENT = "SHARED_CONTENT";

export interface SelectCONTENT {
  type: typeof SELECT_CONTENT;
  payload: ContentDto;
}

export interface SharedContent {
  type: typeof SHARED_CONTENT;
  payload: any;
}

export interface SavedContent {
  type: typeof SAVED_CONTENT;
  payload: string;
}
export interface UnSavedContent {
  type: typeof UNSAVED_CONTENT;
  payload: string;
}

export interface UpdateCurrentCONTENTList {
  type: typeof UPDATE_CURRENT_CONTENT_LIST;
  payload: ContentDto[];
}

export interface UpdateManyContent {
  type: typeof UPDATE_MANY_CONTENT;
  payload: any;
}

export interface UpdateManyContentSuccess {
  type: typeof UPDATE_MANY_CONTENT_SUCCESS;
  payload: ContentDto[];
}

export interface UpdateManyContentError {
  type: typeof UPDATE_MANY_CONTENT_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateManyContent {
  type: typeof RESET_UPDATE_MANY_CONTENT;
  payload: null;
}

export interface GetAllContents {
  type: typeof GET_ALL_CONTENTS;
  payload: PaginationDto;
}

export interface GetAllContentsSuccess {
  type: typeof GET_ALL_CONTENTS_SUCCESS;
  payload: ContentDto[];
}

export interface GetAllContentsError {
  type: typeof GET_ALL_CONTENTS_ERROR;
  payload: boolean | string;
}

export interface ResetGetAllContentData {
  type: typeof RESET_GET_ALL_CONTENT_DATA;
  payload: null;
}

export interface AddUserToContent {
  type: typeof ADD_USER_TO_CONTENT;
  payload: {
    _id: string;
    role: string;
    __v: string;
    email?: any;
    username?: any;
  };
}

export interface SetGetContent {
  type: typeof SET_GET_CONTENT;
  payload: ContentDto | null;
}

export interface GetContent {
  type: typeof GET_CONTENT;
  payload: { _id: string };
}

export interface GetContentSuccess {
  type: typeof GET_CONTENT_SUCCESS;
  payload: ContentDto;
}

export interface ResetGetContent {
  type: typeof RESET_GET_CONTENT;
  payload: null;
}

export interface GetContentError {
  type: typeof GET_CONTENT_ERROR;
  payload: boolean | string;
}

export interface NewContent {
  type: typeof NEW_CONTENT;
  payload: ContentDto;
}

export interface NewContentSuccess {
  type: typeof NEW_CONTENT_SUCCESS;
  payload: ContentDto;
}

export interface NewContentError {
  type: typeof NEW_CONTENT_ERROR;
  payload: boolean | string;
}

export interface SetUpdateContentData {
  type: typeof SET_UPDATE_CONTENT_DATA;
  payload: ContentDto;
}

export interface SetNewContentData {
  type: typeof SET_NEW_CONTENT_DATA;
  payload: ContentDto;
}

export interface ResetNewContent {
  type: typeof RESET_NEW_CONTENT;
  payload: null;
}

export interface UpdateContent {
  type: typeof UPDATE_CONTENT;
  payload: ContentDto;
}

export interface UpdateContentSuccess {
  type: typeof UPDATE_CONTENT_SUCCESS;
  payload: ContentDto;
}

export interface UpdateContentError {
  type: typeof UPDATE_CONTENT_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateContent {
  type: typeof RESET_UPDATE_CONTENT;
  payload: null;
}

export interface DeleteContent {
  type: typeof DELETE_CONTENT;
  payload: { _id: string };
}

export interface DeleteContentSuccess {
  type: typeof DELETE_CONTENT_SUCCESS;
  payload: null;
}

export interface DeleteContentError {
  type: typeof DELETE_CONTENT_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteContent {
  type: typeof RESET_DELETE_CONTENT;
  payload: null;
}

export interface DeleteContentMember {
  type: typeof DELETE_CONTENT_MEMBER;
  payload: { _id: string; userId: string };
}

export interface State {
  all: {
    data: ContentDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  create: {
    data: null | ContentDto;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  delete: {
    data: null | ContentDto;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  updateMany: {
    data: null | ContentDto[];
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  content: {
    data: null | ContentDto;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  update: {
    data: null | ContentDto;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  all: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  content: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  create: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  updateMany: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  delete: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  update: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllContents
  | GetAllContentsSuccess
  | GetAllContentsError
  | ResetGetAllContentData
  | GetContent
  | GetContentSuccess
  | GetContentError
  | NewContent
  | NewContentSuccess
  | NewContentError
  | ResetNewContent
  | UpdateContent
  | UpdateContentSuccess
  | UpdateContentError
  | ResetUpdateContent
  | DeleteContent
  | DeleteContentSuccess
  | DeleteContentError
  | ResetDeleteContent
  | SavedContent
  | UnSavedContent
  | ResetGetContent
  | SetGetContent
  | AddUserToContent
  | SharedContent
  | UpdateManyContent
  | UpdateManyContentSuccess
  | UpdateManyContentError
  | ResetUpdateManyContent;
