import { StudentDto } from "../../types/student.dto";

export const GET_ALL_STUDENTS = "GET_ALL_STUDENTS";
export const GET_ALL_STUDENTS_ERROR = "GET_ALL_STUDENTS_ERROR";
export const GET_ALL_STUDENTS_SUCCESS = "GET_ALL_STUDENTS_SUCCESS";
export const GET_STUDENT = "GET_STUDENT";
export const GET_STUDENT_ERROR = "GET_STUDENT_ERROR";
export const GET_STUDENT_SUCCESS = "GET_STUDENT_SUCCESS";
export const NEW_STUDENT = "NEW_STUDENT";
export const NEW_STUDENT_ERROR = "NEW_STUDENT_ERROR";
export const NEW_STUDENT_SUCCESS = "NEW_STUDENT_SUCCESS";
export const RESET_NEW_STUDENT = "RESET_NEW_STUDENT";
export const UPDATE_STUDENT = "UPDATE_STUDENT";
export const UPDATE_STUDENT_ERROR = "UPDATE_STUDENT_ERROR";
export const UPDATE_STUDENT_SUCCESS = "UPDATE_STUDENT_SUCCESS";
export const RESET_UPDATE_STUDENT = "RESET_UPDATE_STUDENT";
export const DELETE_STUDENT = "DELETE_STUDENT";
export const DELETE_STUDENT_ERROR = "DELETE_STUDENT_ERROR";
export const DELETE_STUDENT_SUCCESS = "DELETE_STUDENT_SUCCESS";
export const RESET_DELETE_STUDENT = "RESET_DELETE_STUDENT";

export interface GetAllStudents {
  type: typeof GET_ALL_STUDENTS;
  payload: null;
}

export interface GetAllStudentsSuccess {
  type: typeof GET_ALL_STUDENTS_SUCCESS;
  payload: StudentDto[];
}

export interface GetAllStudentsError {
  type: typeof GET_ALL_STUDENTS_ERROR;
  payload: boolean | string;
}

export interface GetStudent {
  type: typeof GET_STUDENT;
  payload: { id: string };
}

export interface GetStudentSuccess {
  type: typeof GET_STUDENT_SUCCESS;
  payload: StudentDto;
}

export interface GetStudentError {
  type: typeof GET_STUDENT_ERROR;
  payload: boolean | string;
}

export interface NewStudent {
  type: typeof NEW_STUDENT;
  payload: StudentDto;
}

export interface NewStudentSuccess {
  type: typeof NEW_STUDENT_SUCCESS;
  payload: StudentDto;
}

export interface NewStudentError {
  type: typeof NEW_STUDENT_ERROR;
  payload: boolean | string;
}

export interface ResetNewStudent {
  type: typeof RESET_NEW_STUDENT;
  payload: null;
}

export interface UpdateStudent {
  type: typeof UPDATE_STUDENT;
  payload: StudentDto;
}

export interface UpdateStudentSuccess {
  type: typeof UPDATE_STUDENT_SUCCESS;
  payload: StudentDto;
}

export interface UpdateStudentError {
  type: typeof UPDATE_STUDENT_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateStudent {
  type: typeof RESET_UPDATE_STUDENT;
  payload: null;
}

export interface DeleteStudent {
  type: typeof DELETE_STUDENT;
  payload: { id: string };
}

export interface DeleteStudentSuccess {
  type: typeof DELETE_STUDENT_SUCCESS;
  payload: null;
}

export interface DeleteStudentError {
  type: typeof DELETE_STUDENT_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteStudent {
  type: typeof RESET_DELETE_STUDENT;
  payload: null;
}

export interface State {
  allStudents: {
    data: StudentDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  student: {
    data: StudentDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  newStudent: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  deleteStudent: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  updateStudent: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  allStudents: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  student: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  newStudent: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  deleteStudent: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  updateStudent: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllStudents
  | GetAllStudentsSuccess
  | GetAllStudentsError
  | GetStudent
  | GetStudentSuccess
  | GetStudentError
  | NewStudent
  | NewStudentSuccess
  | NewStudentError
  | ResetNewStudent
  | UpdateStudent
  | UpdateStudentSuccess
  | UpdateStudentError
  | ResetUpdateStudent
  | DeleteStudent
  | DeleteStudentSuccess
  | DeleteStudentError
  | ResetDeleteStudent;
