import * as Yup from "yup";

interface FormValues {
  organization: string;
  addOwn: boolean;
  teams: string[];
}

export const schema = Yup.object().shape({
  organization: Yup.string(),
  addOwn: Yup.bool(),
  teams: Yup.array().required(),
});

export const initialValues = (initialValues = {}): FormValues => {
  return {
    organization: "",
    addOwn: false,
    teams: [],
    ...initialValues,
  };
};
