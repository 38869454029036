import React from "react";
import {
  AcademyCardBannerImage,
  AcademyCardDataText,
  AcademyCardName,
  AcademyCardProfileImage,
  AcademyCardTags,
  AcademyCardWrapper,
} from "./style";
import { Img } from "../../../../../../../SignIn/styles";
import {
  FlexContainer,
  Separator,
} from "../../../../../../../../components/StyledComponents";
import { MdGroups } from "react-icons/md";
import { useTheme } from "styled-components";
import { AiFillEye } from "react-icons/ai";
import { overThousandFixed } from "../../../../../../../../helpers/number-fixed";
import { setCurrent } from "../../../../../../../../store/actions/current";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
const Component = ({ item }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <AcademyCardWrapper
      onClick={() => {
        navigate("/dashboard/contents/video");
        dispatch(
          setCurrent({
            currentSection: "academy",
            id: item._id,
            role: item.role ?? "OBSERVER",
          })
        );
      }}
    >
      <AcademyCardBannerImage>
        <Img
          src={item.bannerPic}
          alt={"Academy image banner"}
          title={item.name}
        ></Img>
      </AcademyCardBannerImage>
      <Separator size={"2px"}></Separator>
      <div style={{ padding: "0px 3px 10px 9px" }}>
        <FlexContainer align="center" gap="10px">
          <AcademyCardProfileImage>
            <Img
              src={item.picture}
              alt={"Academy image"}
              title={item.name}
            ></Img>
          </AcademyCardProfileImage>
          <AcademyCardName hideLine={1}>{item.name}</AcademyCardName>
        </FlexContainer>
        <Separator size={6}></Separator>
        <FlexContainer align="center" gap="3px">
          <AcademyCardDataText color={"#697482"} weight={500} size={14}>
            Rol:
          </AcademyCardDataText>
          <AcademyCardDataText
            color={theme.colors.Black}
            weight={500}
            size={14}
          >
            {item.role}
          </AcademyCardDataText>
        </FlexContainer>
        <Separator size={7}></Separator>
        <FlexContainer align="center" gap="4px">
          <FlexContainer align="center" gap="3px">
            <MdGroups color={theme.colors.Black} fontSize={20}></MdGroups>
            <AcademyCardDataText
              color={theme.colors.Black}
              weight={500}
              size={14}
            >
              {overThousandFixed(item.userCount)}
            </AcademyCardDataText>
          </FlexContainer>
          <FlexContainer align="center" gap="3px">
            <AiFillEye color={theme.colors.Black} fontSize={20}></AiFillEye>
            <AcademyCardDataText
              color={theme.colors.Black}
              weight={500}
              size={14}
            >
              {overThousandFixed(item.viewCount)}
            </AcademyCardDataText>
          </FlexContainer>
        </FlexContainer>
        <Separator size={6}></Separator>
        <FlexContainer align="center" gap="10px">
          <AcademyCardDataText color={"#697482"} weight={500} size={14}>
            Tags:
          </AcademyCardDataText>
          <FlexContainer wrap="wrap" align="center" gap="10px">
            {item.tags.map((tag) => (
              <AcademyCardTags>{tag.name}</AcademyCardTags>
            ))}
          </FlexContainer>
        </FlexContainer>
        <Separator size={6}></Separator>
        <FlexContainer gap="10px">
          <AcademyCardDataText color={"#697482"} weight={500} size={14}>
            Cohorts:
          </AcademyCardDataText>
          <AcademyCardDataText color={"#697482"} weight={500} size={14}>
            {item.cohorts
              .map((cohort) => cohort.name)
              .reduce(
                (prev, current, index) =>
                  `${index === 0 ? "" : `${prev}${current}, `}`,
                ""
              )}
          </AcademyCardDataText>
        </FlexContainer>
      </div>
    </AcademyCardWrapper>
  );
};

export default Component;
