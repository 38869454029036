// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttonStyles_buttonWrapper__W3Ner {
  display: inline-block;
  box-shadow: 0 0 2px 0 rgba(21, 115, 229, 0.16);
  border-radius: 5px;
  overflow: hidden;
}

.buttonStyles_button__6CpkD {
  font-size: 18px;
  background: transparent;
  border: 0;
  padding-top: 5px;
  vertical-align: bottom;
  height: 34px;
  width: 36px;
}

.buttonStyles_button__6CpkD svg {
  fill: #888;
}

.buttonStyles_button__6CpkD:hover,
.buttonStyles_button__6CpkD:focus {
  background: #1573e5;
  outline: 0; /* reset for :focus */
  color: #fff;
}

.buttonStyles_button__6CpkD:hover svg,
.buttonStyles_button__6CpkD:focus svg {
  fill: #fff;
}

.buttonStyles_active__QVrqI {
  background: #1573e5;
  color: #fff;
}

.buttonStyles_active__QVrqI svg {
  fill: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/Cdocs/Document/buttonStyles.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,8CAA8C;EAC9C,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,uBAAuB;EACvB,SAAS;EACT,gBAAgB;EAChB,sBAAsB;EACtB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,UAAU;AACZ;;AAEA;;EAEE,mBAAmB;EACnB,UAAU,EAAE,qBAAqB;EACjC,WAAW;AACb;;AAEA;;EAEE,UAAU;AACZ;;AAEA;EACE,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".buttonWrapper {\n  display: inline-block;\n  box-shadow: 0 0 2px 0 rgba(21, 115, 229, 0.16);\n  border-radius: 5px;\n  overflow: hidden;\n}\n\n.button {\n  font-size: 18px;\n  background: transparent;\n  border: 0;\n  padding-top: 5px;\n  vertical-align: bottom;\n  height: 34px;\n  width: 36px;\n}\n\n.button svg {\n  fill: #888;\n}\n\n.button:hover,\n.button:focus {\n  background: #1573e5;\n  outline: 0; /* reset for :focus */\n  color: #fff;\n}\n\n.button:hover svg,\n.button:focus svg {\n  fill: #fff;\n}\n\n.active {\n  background: #1573e5;\n  color: #fff;\n}\n\n.active svg {\n  fill: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonWrapper": `buttonStyles_buttonWrapper__W3Ner`,
	"button": `buttonStyles_button__6CpkD`,
	"active": `buttonStyles_active__QVrqI`
};
export default ___CSS_LOADER_EXPORT___;
