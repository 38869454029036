import {
  DELETE_USER_FROM_ENTITY,
  GET_ALL_USERS,
  GET_ALL_USERS_BY_ACADEMY,
  RESET_GET_ALL_USERS,
  RESET_UPDATE_USERS,
  UPDATE_USERS,
} from "../types/users";

const getAll = (payload) => {
  return { type: GET_ALL_USERS, payload };
};
const getAllByAcademy = (payload) => {
  return { type: GET_ALL_USERS_BY_ACADEMY, payload };
};

const resetGetAll = () => {
  return { type: RESET_GET_ALL_USERS, payload: null };
};

const update = (payload) => {
  return { type: UPDATE_USERS, payload };
};

const resetUpdate = () => {
  return { type: RESET_UPDATE_USERS, payload: null };
};

const deleteFromEntity = (payload) => {
  return { type: DELETE_USER_FROM_ENTITY, payload };
};

export default {
  getAll,
  update,
  resetUpdate,
  getAllByAcademy,
  resetGetAll,
  deleteFromEntity,
};
