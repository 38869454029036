import styled from "styled-components";

export const ToolBarWrapper = styled("div")`
  background: #fff;
  min-height: 120px;
  padding: 10px;
`;

export const ToolBarMain = styled("div")`
  border-radius: 10px;
  box-shadow: 0 0 4px 0 rgba(21, 115, 229, 0.16);
  overflow: hidden;
  & > div {
    all: unset;
  }
`;
