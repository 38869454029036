import { put, call, all, takeLatest, select } from "redux-saga/effects";
import services from "../../services";
import {
  GET_CDOC,
  GET_CDOC_SUCCESS,
  GET_CDOC_ERROR,
  NEW_CDOC,
  NEW_CDOC_SUCCESS,
  NEW_CDOC_ERROR,
  GET_ALL_CDOCS,
  GET_ALL_CDOCS_SUCCESS,
  GET_ALL_CDOCS_ERROR,
  UPDATE_CDOC,
  UPDATE_CDOC_SUCCESS,
  UPDATE_CDOC_ERROR,
  DELETE_CDOC,
  DELETE_CDOC_SUCCESS,
  DELETE_CDOC_ERROR,
  GetCDOC,
  NewCDOC,
  GetAllCDOCs,
  UpdateCDOC,
  DeleteCDOC,
  AddUserCdoc,
  ADD_USER_CDOC,
  SharedCdoc,
  SHARED_CDOC,
  UpdateManyCDOC,
  UPDATE_MANY_CDOC,
  UPDATE_MANY_CDOC_SUCCESS,
  UPDATE_MANY_CDOC_ERROR,
} from "../types/cdoc";
import { CDocDto } from "../../types/cdoc.dto";

const cdocList = ({ cdocStore }) => cdocStore.all.data;

function* getAllCdocs({ payload }) {
  const { response, error } = yield call(services.cdoc.getAll, payload);

  if (error) {
    yield put({
      type: GET_ALL_CDOCS_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_CDOCS_SUCCESS, payload: response });
  }
}

function* getCdoc({ payload }) {
  const { response, error } = yield call(services.cdoc.getOne, payload);

  if (error) {
    yield put({
      type: GET_CDOC_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_CDOC_SUCCESS, payload: response });
  }
}

function* newCdoc({ payload }) {
  const { response, error } = yield call(services.cdoc.new, payload);

  if (error) {
    yield put({
      type: NEW_CDOC_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: NEW_CDOC_SUCCESS, payload: response })]);
  }
}

function* updateManyCdoc({ payload }) {
  let updatedList: CDocDto[] = [];

  try {
    for (let i = 0; i < payload.items.length; i++) {
      const { _id, ...body } = payload.items[i];
      const formDataInsteadBody = !!body.body ? body.body : body;
      const { response, error } = yield call(services.cdoc.update, {
        _id,
        body: formDataInsteadBody,
      });
      if (!!response) {
        updatedList = [...updatedList, response];
      }
    }

    yield put({ type: UPDATE_MANY_CDOC_SUCCESS, payload: updatedList });
  } catch (err) {
    yield put({ type: UPDATE_MANY_CDOC_ERROR, payload: err });
  }
}

function* updateCdoc({ payload }) {
  const { response, error } = yield call(services.cdoc.update, payload);

  if (error) {
    yield put({
      type: UPDATE_CDOC_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_CDOC_SUCCESS, payload: response })]);
  }
}

function* deleteCdoc({ payload }) {
  const { response, error } = yield call(services.cdoc.delete, payload);

  if (error) {
    yield put({
      type: DELETE_CDOC_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: DELETE_CDOC_SUCCESS, payload: response })]);
  }
}

function* addUserCdoc({ payload }) {
  const { response, error } = yield call(services.cdoc.addUserCdoc, payload);

  if (error) {
    yield put({
      type: UPDATE_CDOC_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_CDOC_SUCCESS, payload: response })]);
  }
}

function* shareCdoc({ payload }) {
  const { response, error } = yield call(services.cdoc.shareCdoc, payload);

  if (error) {
    yield put({
      type: UPDATE_CDOC_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_CDOC_SUCCESS, payload: response })]);
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllCDOCs>(GET_ALL_CDOCS, getAllCdocs);
  yield takeLatest<GetCDOC>(GET_CDOC, getCdoc);
  yield takeLatest<NewCDOC>(NEW_CDOC, newCdoc);
  yield takeLatest<UpdateCDOC>(UPDATE_CDOC, updateCdoc);
  yield takeLatest<DeleteCDOC>(DELETE_CDOC, deleteCdoc);
  yield takeLatest<AddUserCdoc>(ADD_USER_CDOC, addUserCdoc);
  yield takeLatest<SharedCdoc>(SHARED_CDOC, shareCdoc);
  yield takeLatest<UpdateManyCDOC>(UPDATE_MANY_CDOC, updateManyCdoc);
}
