import { Container } from "./styles";

interface propsInterface {
  margin?: number;
  children: any;
  marginMobile?: number;
}

const Component = ({ children, margin, marginMobile }: propsInterface) => {
  return (
    <Container margin={margin} marginMobile={marginMobile}>
      {children}
    </Container>
  );
};

export default Component;
