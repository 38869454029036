import { Formik } from "formik";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

import ReactSelect from "../../../../components/Form/ReactSelect";
import Grid from "../../../../components/Grid";
import {
  Center,
  FlexContainer,
  PageTitle,
} from "../../../../components/StyledComponents";
import { schema } from "../../../../constants/form/event/new";
import { AcademyDto } from "../../../../types/academy.dto";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { BiEdit } from "react-icons/bi";
import { CurrentDto } from "../../../../types/current.dto";
import { BannerCohortPhoto } from "../../Cohort/Customize/style";
import {
  CertificateLitteTitle,
  CertificateSubTitle,
  CertificateTitle,
  CertificateWrapper,
} from "./style";
import { TalentDto } from "../../../../types/talent.dto";
import academiesActions from "../../../../store/actions/academies";
import { UsersDto } from "../../../../types/users.dto";
import { CohortDto } from "../../../../types/cohort.dto";
import Loader from "../../../../components/Loader";
import ModalSendCertificate from "./components/ModalSendCertificate";
import { useDispatch } from "react-redux";
import { showModal } from "../../../../store/actions/modal";
import {
  getAllCohorts,
  resetGetAllCohortsData,
} from "../../../../store/actions/cohort";
import usersActions from "../../../../store/actions/users";
import { StateDto } from "../../../../types/states.dto";

interface ComponentProps {
  academies: AcademyDto[];
  current: CurrentDto;
  talents: TalentDto[];
  users: UsersDto[];
  cohorts: CohortDto[];
  getUsersStates: StateDto;
}

const Component = ({
  academies,
  current,
  talents,
  users,
  cohorts,
  getUsersStates,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const [selectedTalent, setSelectedTalent] = useState<UsersDto>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [cohortSelected, setCohortSelected] = useState<CohortDto>();
  const [fetchOn, setFetchOn] = useState<boolean>(false);
  const [currentStyle, setCurrentStyle] = useState<{
    certificateName?: string;
    titleFont?: string;
    titleSize?: string;
    titleColor?: string;
    nameFont?: string;
    nameSize?: string;
    nameColor?: string;
    cohortName?: boolean;
    background?: any;
    academyLogo?: boolean;
  }>();
  const [image, setImage] = useState<string | ArrayBuffer | null>();
  const printRef = React.useRef();
  const handleDownloadPdf = async () => {
    if (!!printRef.current) {
      const element = printRef.current;
      const canvas = await html2canvas(element);
      const data = canvas.toDataURL("image/png");

      const pdf = new jsPDF("landscape", "mm", "a4");
      const imgProperties = pdf.getImageProperties(data);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

      pdf.addImage(data, "PNG", 5, 10, pdfWidth - 10, pdfHeight + 5);
      pdf.save("print.pdf");
    }
  };
  /*   const makeBlob = async (url) => {
    const blob = await (await fetch(url)).blob();
    setImage(blob);
  }; */
  /*  useEffect(() => {
    if (!!cohortSelected && !!academies && fetchOn) {
      const academy = academies.filter(
        (academy) => cohortSelected.academy._id === academy._id
      )[0];
      console.log(academy, "mundan");
      !!academy.picture ? makeBlob(academy.picture) : setImage(undefined);
    }
  }, [cohortSelected, academies]); */

  useEffect(() => {
    if (currentStyle?.background) {
      if (currentStyle?.background instanceof File) {
        const imageUrl = URL.createObjectURL(currentStyle?.background);
        setImage(imageUrl);
      } else {
        setImage(currentStyle?.background);
      }
    } else {
      setImage(undefined);
    }
  }, [currentStyle]);

  useEffect(() => {
    if (cohortSelected) {
      dispatch(
        usersActions.getAll({
          filterBy: { _id: { $in: cohortSelected.studentsIds } },
        })
      );
    }
  }, [cohortSelected]);

  console.log(currentStyle?.background, "mundi");

  useEffect(() => {
    !cohorts &&
      fetchOn &&
      dispatch(getAllCohorts({ filterBy: { academy: current.id } }));
  }, [cohorts, fetchOn]);

  useEffect(() => {
    !academies &&
      fetchOn &&
      dispatch(academiesActions.getAll({ filterBy: { _id: current.id } }));
  }, [academies, fetchOn]);

  useEffect(() => {
    if (!academies && !cohorts) {
      setFetchOn(true);
    }
  }, [cohorts, academies]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllCohortsData());
      dispatch(academiesActions.resetGetAllAcademiesData());
      dispatch(usersActions.resetGetAll());
    };
  }, []);

  if (!cohorts || !academies) return <Loader color="Primary"></Loader>;
  return (
    <Grid.Container>
      {!!currentStyle && isModalOpen && !!cohortSelected && (
        <ModalSendCertificate
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          setCurrentStyle={setCurrentStyle}
          currentStyle={currentStyle}
          currentCohort={cohortSelected}
        ></ModalSendCertificate>
      )}

      <PageTitle>Certificados</PageTitle>
      <Formik
        initialValues={{}}
        onSubmit={(values, actions) => {}}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <>
              <Grid.ContainerFluid>
                <Grid.Row style={{ alignItems: "center" }}>
                  <Grid.Col sm={5} offset={{ sm: 0 }}>
                    <ReactSelect
                      name="cohort"
                      {...(!!cohorts && {
                        items: cohorts
                          .filter(
                            (cohort) =>
                              cohort.active && cohort.academy._id === current.id
                          )
                          .map((cohort) => {
                            return {
                              label: cohort.name,
                              value: cohort._id,
                            };
                          }),
                      })}
                      placeholder="Selecciona una cohort"
                      options={{
                        loading: !cohorts,
                        label: "Seleccionar Cohort",
                        marginBottom: 20,
                      }}
                      onOptionSelected={(value) => {
                        setFieldValue("talent", null);
                        const cohortFounded = cohorts.filter(
                          (cohort) => cohort._id === value.value
                        )[0];

                        setCohortSelected(cohortFounded);
                        setCurrentStyle({
                          certificateName:
                            cohortFounded.certificateStyles.certificateName,
                          titleFont: cohortFounded.certificateStyles.titleFont,
                          titleSize: cohortFounded.certificateStyles.titleSize,
                          titleColor:
                            cohortFounded.certificateStyles.titleColor,
                          nameFont: cohortFounded.certificateStyles.nameFont,
                          nameSize: cohortFounded.certificateStyles.nameSize,
                          nameColor: cohortFounded.certificateStyles.nameColor,
                          cohortName:
                            cohortFounded.certificateStyles.cohortName,
                          background:
                            cohortFounded.certificateStyles.background,
                          academyLogo:
                            cohortFounded.certificateStyles.academyLogo,
                        });
                      }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid.Col>

                  <Grid.Col sm={5} offset={{ sm: 2 }}>
                    <ReactSelect
                      name="talent"
                      {...(!!users &&
                        !!users && {
                          items: users
                            .filter(
                              (talent) =>
                                talent.cohorts.find(
                                  (cohort) => cohort._id === values["cohort"]
                                ) && talent.active
                            )
                            .map((talent) => ({
                              label: `${talent.name} ${talent.lastName}`,
                              value: talent._id,
                            })),
                        })}
                      placeholder="Selecciona un talento"
                      isClearable
                      onOptionSelected={(value: {
                        value: string;
                        label: string;
                      }) => {
                        const talentFounded = users.find(
                          (talent) => talent._id === value.value
                        );
                        if (!!talentFounded) {
                          setSelectedTalent({
                            ...talentFounded,
                            name: value.label,
                          });
                        }
                      }}
                      disabled={
                        !values["cohort"] || getUsersStates.loading || !users
                      }
                      notLoading={true}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={{
                        label:
                          "Selecciona talento del cohort para vista previa",
                        marginBottom: 20,
                      }}
                    />
                  </Grid.Col>
                </Grid.Row>
              </Grid.ContainerFluid>
            </>
          );
        }}
      </Formik>
      <FlexContainer justify="space-between" style={{ marginBottom: "11px" }}>
        <div></div>
        <FlexContainer
          style={{ marginRight: "26px" }}
          gap="50px"
          align="center"
        >
          <FaCloudDownloadAlt
            color="#697482"
            fontSize={30}
            onClick={handleDownloadPdf}
          ></FaCloudDownloadAlt>
          <BiEdit
            onClick={() => {
              setIsModalOpen(true);
            }}
            color="#697482"
            fontSize={30}
          ></BiEdit>
        </FlexContainer>
      </FlexContainer>
      <Grid.Row>
        <Grid.Col>
          <CertificateWrapper
            ref={printRef}
            style={{
              backgroundPosition: `center center`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              ...(image && {
                backgroundImage: `url(${image})`,
              }),
            }}
          >
            {!!currentStyle && !!cohortSelected ? (
              <>
                <FlexContainer
                  style={{ marginBottom: "30px" }}
                  justify="space-between"
                  align="center"
                >
                  <BannerCohortPhoto
                    style={{
                      width: "50px",
                      height: "50px",
                      backgroundImage:
                        !!academies.filter(
                          (academy) =>
                            cohortSelected.academy._id === academy._id
                        )[0].picture &&
                        !!currentStyle.academyLogo &&
                        `url(${
                          academies.filter(
                            (academy) =>
                              cohortSelected.academy._id === academy._id
                          )[0].picture
                        })`,
                      backgroundPosition: `center center`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      boxShadow: "unset",
                    }}
                  ></BannerCohortPhoto>
                </FlexContainer>
                <FlexContainer direction="column" gap="60px">
                  <Center>
                    <Grid.Row>
                      <Grid.Col>
                        <CertificateTitle
                          style={{
                            fontSize: `${currentStyle.titleSize}px`,
                            color: currentStyle.titleColor,
                          }}
                        >
                          {currentStyle.certificateName}
                        </CertificateTitle>
                      </Grid.Col>
                    </Grid.Row>
                  </Center>
                  <Center>
                    <Grid.Row>
                      <Grid.Col>
                        <CertificateSubTitle
                          style={{
                            fontSize: `${currentStyle.nameSize}px`,
                            color: currentStyle.nameColor,
                          }}
                        >
                          {selectedTalent?.name || "Estudiante"}
                        </CertificateSubTitle>
                      </Grid.Col>
                    </Grid.Row>
                  </Center>
                </FlexContainer>
                <FlexContainer
                  justify="space-between"
                  style={{ marginTop: "276px" }}
                >
                  <CertificateLitteTitle>
                    {academies.find((academy) => academy._id === current.id)
                      ?.maxCertificateNumber || "#0000"}
                  </CertificateLitteTitle>
                  <CertificateLitteTitle
                    style={{
                      fontSize: currentStyle.nameSize,
                      color: currentStyle.nameColor,
                    }}
                  >
                    {currentStyle.cohortName ? cohortSelected?.name : ""}
                  </CertificateLitteTitle>
                </FlexContainer>
              </>
            ) : (
              <Center>
                <h3>Esperando seleccion</h3>
              </Center>
            )}
          </CertificateWrapper>
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
  );
};

const states = ({
  currentStore,
  academyStore,
  talentStore,
  usersStore,
  cohortStore,
}) => {
  const { data: academies } = academyStore.all;
  const { data: current } = currentStore;
  const { data: talents } = talentStore.allTalents;
  const { data: users, states: getUsersStates } = usersStore.all;
  const { data: cohorts } = cohortStore.allCohorts;
  return {
    academies,
    current,
    talents,
    users,
    cohorts,
    getUsersStates,
  };
};

export default connect(states)(Component);
