import { connect } from "react-redux";
import { InLine } from "../../../../../../components/StyledComponents";
import { useRecruitingLayout } from "../../../../../../provider/RecruitingProvider";
import Icon from "../../../../../../components/Icon";
import {
  TabTitle,
  Description,
  Content,
  ListTitle,
  GrayCard,
} from "../../../components/StyledComponents";

import TalentSearchIcon from "../../../../../../assets/icons/talent-search.svg";
import GlobeIcon from "../../../../../../assets/icons/globe.svg";
import MarkerIcon from "../../../../../../assets/icons/marker.svg";

const Component = ({ children }) => {
  const { selectedItem, statics } = useRecruitingLayout();

  return (
    <>
      <InLine justify="space-between">
        <TabTitle>Perfil de Búsqueda</TabTitle>
        {children}
      </InLine>

      <Description>
        Los perfiles de búsqueda te ayudaran a describir mejor la necesidad y el
        talento específicamente que estas buscando.
      </Description>

      <Content>
        <ListTitle>Talentos</ListTitle>

        <GrayCard>
          <InLine
            style={{
              alignItems: "flex-start",
              justifyContent: "flex-start",
              marginBottom: "5px",
            }}
          >
            <InLine style={{ justifyContent: "flex-start" }}>
              <Icon icon={TalentSearchIcon} size="12px" color="black" />
              <span style={{ margin: "0px 5px", color: "#334253" }}>
                Perfil:
              </span>
            </InLine>
            {selectedItem?.data?.name}
          </InLine>

          <InLine
            style={{
              alignItems: "flex-start",
              justifyContent: "flex-start",
              marginBottom: "5px",
            }}
          >
            <InLine style={{ justifyContent: "flex-start" }}>
              <Icon icon={MarkerIcon} size="12px" color="black" />
              <span style={{ margin: "0px 5px", color: "#334253" }}>
                Ubicación:
              </span>
            </InLine>
            Provincia de{" "}
            {
              statics?.allProvinces?.data?.filter(
                (province) => province?.value === selectedItem?.data?.province
              )[0]?.label
            }
            , Argentina
          </InLine>

          <InLine
            style={{
              alignItems: "flex-start",
              justifyContent: "flex-start",
              marginBottom: "5px",
            }}
          >
            <InLine style={{ justifyContent: "flex-start" }}>
              <Icon icon={GlobeIcon} size="12px" color="black" />
              <span style={{ margin: "0px 5px", color: "#334253" }}>
                Inglés:
              </span>
            </InLine>
            {selectedItem?.data?.english}
          </InLine>

          <InLine
            style={{
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <InLine style={{ justifyContent: "flex-start" }}>
              <span style={{ margin: "0px 5px 0px 0px", color: "#334253" }}>
                Resumen:
              </span>
            </InLine>
            {selectedItem?.data?.description}
          </InLine>
        </GrayCard>
      </Content>
    </>
  );
};

const states = ({ recruitingStore }) => {
  const { data: allSearches, states: allSearchesStates } =
    recruitingStore.search.all;

  return { allSearches, allSearchesStates };
};

export default connect(states)(Component);
