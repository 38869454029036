import { put, call, takeLatest, select } from "redux-saga/effects";
import services from "../../../services";
import {
  NEW_TECHSKILL,
  NEW_TECHSKILL_ERROR,
  NEW_TECHSKILL_SUCCESS,
  NewTechSkill,
  GET_ALL_TECHSKILLS,
  GET_ALL_TECHSKILLS_ERROR,
  GET_ALL_TECHSKILLS_SUCCESS,
  GetAllTechSkills,
  UPDATE_TECHSKILL,
  UPDATE_TECHSKILL_ERROR,
  UPDATE_TECHSKILL_SUCCESS,
  UpdateTechSkill,
  DELETE_TECHSKILL,
  UPDATE_CURRENT_TECHSKILL_LIST,
  DELETE_TECHSKILL_ERROR,
  DELETE_TECHSKILL_SUCCESS,
} from "../../types/recruiting/tech-skill";

const techSkillList = ({ recruitingStore }) =>
  recruitingStore.techSkill.all.data;

function* newTechSkill({ payload }) {
  const { response, error } = yield call(
    services.recruiting.techSkill.new,
    payload
  );

  if (error) {
    yield put({
      type: NEW_TECHSKILL_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    const currentListOfTechsSkills = yield select(techSkillList);
    const newListofTechsSkills = [...currentListOfTechsSkills, response];
    yield put({
      type: UPDATE_CURRENT_TECHSKILL_LIST,
      payload: newListofTechsSkills,
    });
    yield put({ type: NEW_TECHSKILL_SUCCESS, payload: response });
  }
}

function* deleteTechSkill({ payload }) {
  const { response, error } = yield call(
    services.recruiting.techSkill.delete,
    payload
  );

  if (error) {
    yield put({
      type: DELETE_TECHSKILL_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    const currentListOfTechsSkills = yield select(techSkillList);
    const newListofTechsSkills = currentListOfTechsSkills?.filter(
      (techSkill) => techSkill._id !== payload._id
    );
    yield put({
      type: UPDATE_CURRENT_TECHSKILL_LIST,
      payload: newListofTechsSkills,
    });
    yield put({ type: DELETE_TECHSKILL_SUCCESS, payload: response });
  }
}

function* getAllTechSkills() {
  const { response, error } = yield call(services.recruiting.techSkill.getAll);
  if (error) {
    yield put({
      type: GET_ALL_TECHSKILLS_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_TECHSKILLS_SUCCESS, payload: response });
  }
}

function* updatedTechSkill({ payload }) {
  const { response, error } = yield call(
    services.recruiting.techSkill.update,
    payload
  );
  if (error) {
    yield put({
      type: UPDATE_TECHSKILL_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: UPDATE_TECHSKILL_SUCCESS, payload: null });
    const currentListOfTechsSkills = yield select(techSkillList);
    const newListofTechsSkills = currentListOfTechsSkills?.data?.map(
      (techSkill) => {
        if (techSkill._id === payload._id) {
          return { ...techSkill, ...payload };
        }
        return techSkill;
      }
    );
    yield put({
      type: UPDATE_CURRENT_TECHSKILL_LIST,
      payload: newListofTechsSkills,
    });
    yield put({ type: GET_ALL_TECHSKILLS, payload: null });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<NewTechSkill>(NEW_TECHSKILL, newTechSkill);
  yield takeLatest<GetAllTechSkills>(GET_ALL_TECHSKILLS, getAllTechSkills);
  yield takeLatest<UpdateTechSkill>(UPDATE_TECHSKILL, updatedTechSkill);
  yield takeLatest<UpdateTechSkill>(DELETE_TECHSKILL, deleteTechSkill);
}
