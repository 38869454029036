export const GET_AIT = "GET_AIT";
export const GET_AIT_SUCCESS = "GET_AIT_SUCCESS";
export const GET_AIT_ERROR = "GET_AIT_ERROR";
export const UPDATE_AIT = "UPDATE_AIT";
export const UPDATE_AIT_SUCCESS = "UPDATE_AIT_SUCCESS";
export const UPDATE_AIT_ERROR = "UPDATE_AIT_ERROR";
export const UPDATE_AIT_TABLE = "UPDATE_AIT_TABLE";
export const RESET_UPDATE_AIT = "RESET_UPDATE_AIT";
export const RESET_GET_AIT = "RESET_GET_AIT";
export const SET_AIT_LIST = "SET_AIT_LIST";
export interface ResetGetAit {
  type: typeof RESET_GET_AIT;
  payload: null;
}
export interface ResetUpdateAit {
  type: typeof RESET_UPDATE_AIT;
  payload: null;
}

export interface SetAitList {
  type: typeof SET_AIT_LIST;
  payload: any;
}

export interface GetAit {
  type: typeof GET_AIT;
  payload: { _id: string };
}
export interface GetAitSuccess {
  type: typeof GET_AIT_SUCCESS;
  payload: any;
}
export interface GetAitError {
  type: typeof GET_AIT_ERROR;
  payload: string | boolean;
}
export interface UpdateAit {
  type: typeof UPDATE_AIT;
  payload: { tables: any; _id?: string; __v?: number };
}
export interface UpdateAitSuccess {
  type: typeof UPDATE_AIT_SUCCESS;
  payload: null;
}
export interface UpdateAitError {
  type: typeof UPDATE_AIT_ERROR;
  payload: string | boolean;
}

export interface UpdateAitTable {
  type: typeof UPDATE_AIT_TABLE;
  payload: any;
}

export interface State {
  all: {
    data: any;
    states: {
      success: boolean;
      error: boolean | string;
      loading: boolean;
    };
  };
  update: {
    states: {
      success: boolean;
      error: boolean | string;
      loading: boolean;
    };
  };
}

export const initialState: State = {
  all: {
    data: null,
    states: {
      success: false,
      error: false,
      loading: false,
    },
  },
  update: {
    states: {
      success: false,
      error: false,
      loading: false,
    },
  },
};

export type Actions =
  | GetAit
  | GetAitSuccess
  | GetAitError
  | UpdateAit
  | UpdateAitSuccess
  | UpdateAitError
  | UpdateAitTable
  | ResetGetAit
  | ResetUpdateAit
  | SetAitList;
