import React, { useEffect, useState } from "react";
import Grid from "../../../../components/Grid";
import {
  Center,
  FlexContainer,
  PageTitle,
  RemoveButton,
  ScrollingSection,
  Separator,
} from "../../../../components/StyledComponents";
import { Formik } from "formik";
import { CohortDto } from "../../../../types/cohort.dto";
import { CurrentDto } from "../../../../types/current.dto";
import ReactSelect from "../../../../components/Form/ReactSelect";
import Submit from "../../../../components/Form/Submit";
import { Wrapper } from "../style";
import { connect } from "react-redux";
import { CriterionDto } from "../../../../types/criterion.dto";
import { initialValues, schema } from "../../../../constants/form/evaluate/new";
import {
  ArrowIcon,
  DropDownItem,
  HasBeenEvaluated,
  ListCriterionTitle,
  ListItem,
  ListSubCriterionTitle,
} from "./style";
import { SubCriterionDtoApi } from "../../../../types/sub-criterion.dto";
import CheckBox from "../components/CheckBox";
import { MdKeyboardArrowDown } from "react-icons/md";
import { UserDto } from "../../../../types/user.dto";
import Loader from "../../../../components/Loader";
import { useDispatch } from "react-redux";
import {
  getAllCohortStudents,
  getAllCohorts,
  resetGetAllCohortStudents,
  resetGetAllCohortsData,
} from "../../../../store/actions/cohort";
import {
  getAllSubCriterions,
  resetGetAllSubCriterions,
} from "../../../../store/actions/sub-criterion";
import {
  deleteCriterion,
  getCriterions,
  resetDeleteCriterion,
  resetGetAllCriterions,
} from "../../../../store/actions/criterion";
import { EvaluateDto } from "../../../../types/evaluate.dto";
import {
  getEvaluates,
  newManyEvaluate,
  resetGetAllEvaluate,
  resetNewManyEvaluate,
} from "../../../../store/actions/evaluate";
import StudentsGrade from "./components/StudentsGrade";
import { StateDto } from "../../../../types/states.dto";
import { toast } from "react-toastify";
import { ErrorSpan } from "../../../SignUp/style";
import ModalConfirmDelete from "../../../../components/ModalConfirmDelete";
import { hideModal, showModal } from "../../../../store/actions/modal";
import { UsersDto } from "../../../../types/users.dto";

const Component = ({
  cohorts,
  current,
  criterions,
  subCriterions,
  users,
  notes,
  newNoteStates,
  newManyEvaluates,
  newManyEvaluateStates,
  deleteCriterionStates,
  deletedCriterion,
  getNotesStates,
  cohortStudents,
  cohortStudentsStates,
}: {
  cohorts: CohortDto[];
  current: CurrentDto;
  criterions: CriterionDto[];
  subCriterions: SubCriterionDtoApi[];
  users: UserDto[];
  notes: EvaluateDto[];
  newNoteStates: StateDto;
  newManyEvaluates: EvaluateDto[];
  newManyEvaluateStates: StateDto;
  deleteCriterionStates: StateDto;
  deletedCriterion: CriterionDto;
  getNotesStates: StateDto;
  cohortStudents: UsersDto[];
  cohortStudentsStates: StateDto;
}) => {
  const dispatch = useDispatch();
  const [fetchOn, setFetchOn] = useState<boolean>(false);
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const [cohortSelected, setCohortSelected] = useState<CohortDto>();
  const [currentCriterion, setCurrentCriterion] = useState<CriterionDto>();
  const [openCriterions, setOpenCriterions] = useState<CriterionDto[]>([]);
  const [noteSubCriterionStatus, setNoteSubCriterionStatus] = useState<
    SubCriterionDtoApi[]
  >([]);
  const [checkedCriterions, setCheckedCriterions] = useState<CriterionDto[]>(
    []
  );
  const [currentNotes, setCurrentNotes] = useState<EvaluateDto[]>();
  const [currentCriterions, setCurrentCriterions] = useState<CriterionDto[]>();
  const [generalNote, setGeneralNote] = useState<number | null>(null);
  const [action, setAction] = useState<any>();
  const [thereIsNotePicked, setThereIsNotePicked] =
    useState<SubCriterionDtoApi | null>(null);
  const onSubmit = ({ values, actions }) => {
    setFormActions(actions);
    let evaluteItems: any = [];

    for (let i = 0; i < noteSubCriterionStatus.length; i++) {
      evaluteItems = [
        ...evaluteItems,
        {
          students: values.students,
          subCriterion: noteSubCriterionStatus[i]._id,
          criterion: noteSubCriterionStatus[i].criterion,
          cohort: values.cohort,
          stage: values.stage,
        },
      ];
    }
    dispatch(newManyEvaluate({ items: evaluteItems }));
  };
  const payload = {
    filterBy: {
      academy: current.id,
      active: true,
    },
    select: {
      studentsIds: 1,
      _id: 1,
      name: 1,
      maxStage: 1,
    },
  };
  const handleOpenDropDown = (criterion: CriterionDto) => {
    setOpenCriterions((state) =>
      state.find((st) => st._id === criterion._id)
        ? state.filter((st) => st._id !== criterion._id)
        : [...state, criterion]
    );
  };
  const criterionCheckHandle = ({
    criterion,
    subCriterions,
  }: {
    criterion: CriterionDto;
    subCriterions: SubCriterionDtoApi[];
  }) => {
    const noteSubCriterion = noteSubCriterionStatus.filter(
      (st) => st.criterion === criterion._id
    );
    const criterionIsChecked = checkedCriterions.find(
      (checked) => checked._id === criterion._id
    );
    setCheckedCriterions((state) =>
      criterionIsChecked
        ? state.filter((st) => st._id !== criterion._id)
        : [...state, criterion]
    );
    if (subCriterions.length > 0) {
      const restOfSubCriterions = subCriterions.filter(
        (subcriterion) =>
          !noteSubCriterion.find((sbcr) => sbcr._id === subcriterion._id)
      );
      setNoteSubCriterionStatus((state) =>
        criterionIsChecked
          ? state.filter((st) => st.criterion !== criterion._id)
          : [...state, ...restOfSubCriterions]
      );
    }
  };

  const resetStore = () => {
    setCheckedCriterions([]);
    setOpenCriterions([]);
    setNoteSubCriterionStatus([]);
    setThereIsNotePicked(null);
    setGeneralNote(null);
  };

  const handleInputChange = (value) => {
    // Remove leading zeros and update state
    const formatNumber = value.charAt(value.length - 1);
    if (/^[1-5]$/.test(formatNumber)) {
      return formatNumber;
    } else {
      return "1";
    }
  };

  const subCriterionHandle = ({
    subcriterion,
    notes,
    stage,
    cohort,
    criterion,
    setFieldValue,
  }: {
    subcriterion: SubCriterionDtoApi;
    notes?: EvaluateDto[];
    criterion: CriterionDto;
    stage: number | null;
    cohort: string;
    setFieldValue: any;
  }) => {
    if (
      (currentNotes ?? []).filter(
        (note) =>
          note.cohort === cohort &&
          note.stage === stage &&
          note.grades.find((grade) => grade.subCriterion === subcriterion._id)
      ).length > 0
    ) {
      setThereIsNotePicked((state) =>
        state?._id === subcriterion._id ? null : subcriterion
      );
      setNoteSubCriterionStatus((state) =>
        state.find((st) => st._id === subcriterion._id) ? [] : [subcriterion]
      );
      getBootcampStudents({
        studentsIds: cohortSelected?.studentsIds,
        setFieldValue,
        users: cohortStudents,
        cohortId: cohort,
        stage,
        noteSubCriterionStatus: [subcriterion],
      });
    } else {
      setNoteSubCriterionStatus((state) =>
        state.find((st) => st._id === subcriterion._id)
          ? state.filter((sta) => sta._id !== subcriterion._id)
          : [...state, subcriterion]
      );
    }
  };

  const getBootcampStudents = ({
    studentsIds = [],
    setFieldValue,
    users,
    cohortId,
    stage,
    noteSubCriterionStatus,
  }: {
    studentsIds?: string[];
    setFieldValue: any;
    users: UsersDto[];
    cohortId?: string;
    stage: number | null;
    noteSubCriterionStatus: SubCriterionDtoApi[];
  }) => {
    const evaluatesFounded = currentNotes?.find((evaluate) => {
      return (
        evaluate.cohort === cohortId &&
        evaluate.grades?.find((grade) =>
          noteSubCriterionStatus.find((sub) => sub._id === grade.subCriterion)
        ) &&
        evaluate.stage === stage
      );
    });
    /*  console.log(evaluatesFounded, "manhasa"); */
    const setStudentNote = users
      .filter(
        (talent) =>
          studentsIds.find((cohort) => cohort === talent._id) && talent.active
      )
      .map((talent) => {
        return {
          ...talent,
        };
      })
      .map((student) => {
        return {
          id: student._id,
          name: `${student.name} ${student.lastName}`,
          grade: null,
        };
      });
    const existStudentNote = users
      .filter(
        (talent) =>
          studentsIds.find((cohort) => cohort === talent._id) && talent.active
      )
      .map((talent) => {
        return {
          ...talent,
        };
      })
      .map((student) => {
        const evaluatesFounded = currentNotes?.find(
          (evaluate) =>
            evaluate.cohort === cohortId &&
            evaluate.student === student._id &&
            evaluate.grades.find((grade) =>
              noteSubCriterionStatus.find(
                (sub) => sub._id === grade.subCriterion
              )
            ) &&
            evaluate.stage === stage
        );

        return {
          id: student._id,
          name: `${student.name} ${student.lastName}`,
          grade: evaluatesFounded?.grades.find((grade) =>
            noteSubCriterionStatus.find((sub) => sub._id === grade.subCriterion)
          )?.grade,
        };
      });

    setFieldValue(
      "students",
      evaluatesFounded ? existStudentNote : setStudentNote
    );
  };
  useEffect(() => {
    if (newManyEvaluateStates.success) {
      toast.success(`Se ha creado la nota/s`);
      setCurrentNotes((state) => {
        const array = [...(state ?? []), ...newManyEvaluates];
        return array;
      });
      setNoteSubCriterionStatus([]);
      setThereIsNotePicked(null);
      /* formActions?.resetForm(); */
      /*   setGeneralNote(0); */
      dispatch(resetNewManyEvaluate());
    }
    if (newManyEvaluateStates.error) {
      toast.error(`${newManyEvaluateStates.error}`);

      dispatch(resetNewManyEvaluate());
    }
  }, [newManyEvaluateStates, dispatch]);

  useEffect(() => {
    if (deleteCriterionStates.success) {
      setCurrentCriterions((state) =>
        (state ?? []).filter((st) => st._id !== deletedCriterion._id)
      );
      dispatch(resetDeleteCriterion());
    }
    if (deleteCriterionStates.error) {
      dispatch(resetDeleteCriterion());
    }
  }, [deleteCriterionStates, dispatch]);

  useEffect(() => {
    if (!criterions && fetchOn)
      dispatch(getCriterions({ filterBy: { academy: current.id } }));
  }, [criterions, fetchOn]);

  useEffect(() => {
    if (criterions && fetchOn) {
      setCurrentCriterions(criterions);
      dispatch(
        getAllSubCriterions({
          filterBy: {
            criterion: { $in: criterions.map((criterion) => criterion._id) },
          },
        })
      );
    }
  }, [criterions, fetchOn]);

  useEffect(() => {
    if (!cohorts && fetchOn) dispatch(getAllCohorts(payload));
  }, [cohorts, fetchOn]);

  useEffect(() => {
    if (notes && fetchOn) {
      setCurrentNotes(notes);
    }
  }, [notes, fetchOn]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllCriterions());
      dispatch(resetGetAllSubCriterions());
      dispatch(resetGetAllEvaluate());
      dispatch(resetGetAllCohortStudents());
      dispatch(resetGetAllCohortsData());
    };
  }, []);
  useEffect(() => {
    if (!cohorts && !subCriterions && !criterions && !notes) {
      setFetchOn(true);
    }
  }, [cohorts, subCriterions, criterions, notes]);

  if (!cohorts || !currentCriterions || !subCriterions)
    return <Loader color="Primary"></Loader>;
  return (
    <>
      <Grid.Container>
        <PageTitle>Calificar</PageTitle>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            if (onSubmit) onSubmit({ values, actions });
          }}
          validateOnChange={formSubmmited}
          validateOnBlur={false}
          validationSchema={schema}
          enableReinitialize
        >
          {({
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
            setFieldValue,
            isSubmitting,
          }) => {
            return (
              <form
                className="theme-form"
                onSubmit={(event) => {
                  setFormSubmmited(true);
                  handleSubmit(event);
                }}
                id="edit-academy-data-forms"
              >
                <Grid.Row>
                  <Grid.Col lg={5.8} sm={12}>
                    <ReactSelect
                      name="cohort"
                      error={errors["cohort"]}
                      touched={touched["cohort"]}
                      items={
                        !!cohorts &&
                        cohorts.map((cohort) => ({
                          label: cohort.name,
                          value: cohort._id,
                        }))
                      }
                      onOptionSelected={(newValue) => {
                        resetStore();
                        setCohortSelected(
                          cohorts?.filter(
                            (cohort) => cohort._id === newValue.value
                          )[0]
                        );
                        setFieldValue("stage", null);
                        setFieldValue("students", []);
                        dispatch(
                          getAllCohortStudents({
                            _id: newValue.value,
                            payload: { role: "STUDENT" },
                          })
                        );
                        /* setFieldValue(
                          "students",
                          cohorts
                            ?.filter(
                              (cohort) => cohort._id === newValue.value
                            )[0]
                            .studentsIds.map((student) => {
                              const userFounded = users.find(
                                (userF) => userF._id === student
                              );
                              return {
                                id: userFounded?._id,
                                name: `${userFounded?.name} ${userFounded?.lastName}`,
                                grade: null,
                              };
                            })
                        ); */
                      }}
                      placeholder="Seleccionar Cohort*"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={{
                        loading: !cohorts,
                        label: "Cohort",
                        marginBottom: 24,
                      }}
                    />
                    <ReactSelect
                      name="stage"
                      error={errors["stage"]}
                      touched={touched["stage"]}
                      {...(cohorts && {
                        items: [
                          ...Array.from(
                            Array(
                              cohorts.filter(
                                (cohort) => cohort._id === values.cohort
                              )[0]?.maxStage
                            ).keys()
                          ).map((stage) => {
                            return {
                              label: `Etapa ${stage + 1}`,
                              value: stage + 1,
                            };
                          }),
                        ],
                      })}
                      onOptionSelected={(newValue) => {
                        if (newValue) {
                          resetStore();
                          setFieldValue(
                            "students",
                            cohorts
                              ?.filter(
                                (cohort) => cohort._id === values.cohort
                              )[0]
                              ?.studentsIds.map((student) => {
                                const userFounded = cohortStudents.find(
                                  (userF) => userF._id === student
                                );
                                return {
                                  id: userFounded?._id,
                                  name: `${userFounded?.name} ${userFounded?.lastName}`,
                                  grade: null,
                                };
                              })
                          );
                          dispatch(
                            getEvaluates({
                              filterBy: { cohort: values.cohort },
                            })
                          );
                        }
                      }}
                      placeholder="Selecciona una etapa*"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={
                        !cohorts ||
                        !values.cohort ||
                        getNotesStates.loading ||
                        !cohortStudents ||
                        cohortStudentsStates.loading
                      }
                      options={{
                        label: "Etapa",
                        marginBottom: 24,
                      }}
                    />
                    <Wrapper>
                      <ScrollingSection
                        style={{
                          boxShadow: "none",
                          height: "400px",
                          padding: "6px",
                        }}
                      >
                        {getNotesStates.loading ||
                        cohortStudentsStates.loading ? (
                          <Loader color="Primary"></Loader>
                        ) : currentNotes && values["stage"] ? (
                          <>
                            <FlexContainer direction="column" gap="6px">
                              {criterions
                                .filter((criterion) =>
                                  criterion.bootcamps.includes(values.cohort)
                                )
                                .map((criterion) => {
                                  return (
                                    <ListItem
                                      onClick={(ev) => {
                                        ev.stopPropagation();
                                        handleOpenDropDown(criterion);
                                      }}
                                    >
                                      <FlexContainer
                                        align="center"
                                        justify="space-between"
                                      >
                                        <FlexContainer align="center" gap="5px">
                                          {currentNotes.filter(
                                            (note) =>
                                              note.stage === values.stage &&
                                              note.criterion === criterion._id
                                          ).length === 0 &&
                                            !thereIsNotePicked &&
                                            subCriterions.filter(
                                              (subcriterion) =>
                                                subcriterion.criterion ===
                                                criterion._id
                                            ).length !== 0 && (
                                              <CheckBox
                                                onChange={(ev) => {
                                                  const subcriterionsOfCriterion =
                                                    subCriterions.filter(
                                                      (subcriterion) =>
                                                        subcriterion.criterion ===
                                                        criterion._id
                                                    );
                                                  criterionCheckHandle({
                                                    criterion,
                                                    subCriterions:
                                                      subcriterionsOfCriterion,
                                                  });
                                                }}
                                                checked={
                                                  !!checkedCriterions.find(
                                                    (crit) =>
                                                      crit._id === criterion._id
                                                  )
                                                }
                                              />
                                            )}
                                          <RemoveButton
                                            type="button"
                                            onClick={(ev) => {
                                              ev.stopPropagation();
                                              dispatch(
                                                showModal(
                                                  "confirm-delete-item-secuencial-modal"
                                                )
                                              );

                                              setCurrentCriterion(criterion);
                                            }}
                                          />
                                          <ListCriterionTitle>
                                            {criterion.name}
                                          </ListCriterionTitle>
                                        </FlexContainer>
                                        {subCriterions.filter(
                                          (subcriterion) =>
                                            subcriterion.criterion ===
                                            criterion._id
                                        ).length > 0 && (
                                          <ArrowIcon
                                            className={
                                              !!openCriterions.find(
                                                (crit) =>
                                                  crit._id === criterion._id
                                              )
                                                ? "active"
                                                : ""
                                            }
                                            onClick={(ev) => {
                                              ev.stopPropagation();
                                              handleOpenDropDown(criterion);
                                            }}
                                          >
                                            <MdKeyboardArrowDown
                                              size={20}
                                            ></MdKeyboardArrowDown>
                                          </ArrowIcon>
                                        )}
                                      </FlexContainer>

                                      <DropDownItem
                                        onClick={(ev) => {
                                          ev.stopPropagation();
                                        }}
                                        active={
                                          !!openCriterions.find(
                                            (crit) => crit._id === criterion._id
                                          )
                                        }
                                      >
                                        {subCriterions
                                          .filter(
                                            (subcriterion) =>
                                              subcriterion.criterion ===
                                              criterion._id
                                          )
                                          .map((subcriterion) => {
                                            return (
                                              <ListItem
                                                style={{ marginBottom: "5px" }}
                                              >
                                                <FlexContainer
                                                  align="center"
                                                  justify="space-between"
                                                >
                                                  <FlexContainer gap="4px">
                                                    {(thereIsNotePicked?._id ===
                                                      subcriterion._id ||
                                                      !thereIsNotePicked) && (
                                                      <CheckBox
                                                        onChange={() => {
                                                          subCriterionHandle({
                                                            subcriterion,
                                                            cohort:
                                                              values.cohort,
                                                            criterion,
                                                            notes: currentNotes,
                                                            stage: values.stage,
                                                            setFieldValue,
                                                          });
                                                        }}
                                                        checked={
                                                          !!noteSubCriterionStatus.find(
                                                            (subcrit) =>
                                                              subcrit._id ===
                                                              subcriterion._id
                                                          )
                                                        }
                                                      />
                                                    )}
                                                    <ListSubCriterionTitle>
                                                      {subcriterion.name}
                                                    </ListSubCriterionTitle>
                                                  </FlexContainer>
                                                  {currentNotes.filter(
                                                    (note) =>
                                                      note.stage ===
                                                        values.stage &&
                                                      note.criterion ===
                                                        criterion._id &&
                                                      note.grades.find(
                                                        (grade) =>
                                                          grade.subCriterion ===
                                                          subcriterion._id
                                                      )
                                                  ).length > 0 && (
                                                    <HasBeenEvaluated>
                                                      calificado
                                                    </HasBeenEvaluated>
                                                  )}
                                                </FlexContainer>
                                              </ListItem>
                                            );
                                          })}
                                      </DropDownItem>
                                    </ListItem>
                                  );
                                })}
                            </FlexContainer>
                          </>
                        ) : (
                          <Center>
                            <h4>Elegir cohort y stage</h4>
                          </Center>
                        )}
                      </ScrollingSection>
                    </Wrapper>
                  </Grid.Col>
                  <Separator size={15}></Separator>
                  <Grid.Col lg={5.8} offset={{ lg: 0.2 }} sm={12}>
                    <FlexContainer align="center" justify="space-between">
                      <FlexContainer gap="8px" align="center" wrap="wrap">
                        <h4>
                          Aplicar nota default para subcriterios seleccionados:
                        </h4>
                        <input
                          style={{
                            textAlign: "center",
                            width: "65px",
                            height: "26px",
                          }}
                          min={1}
                          max={5}
                          {...(generalNote !== null && { value: generalNote })}
                          type="text"
                          name="generalNote"
                          onChange={(ev) => {
                            setGeneralNote(
                              Number(handleInputChange(ev.target.value))
                            );
                            setFieldValue(
                              "students",
                              values.students.map((student) => {
                                return {
                                  ...student,
                                  grade: Number(
                                    handleInputChange(ev.target.value)
                                  ),
                                };
                              })
                            );
                          }}
                        ></input>
                      </FlexContainer>
                      <Submit
                        isSubmmiting={newManyEvaluateStates.loading}
                        form="edit-academy-data-forms"
                        color="Primary"
                        options={{
                          type: "filled",
                          skin: "primary",
                          size: "lg",
                          marginBottom: "0px",
                          disabled: noteSubCriterionStatus.length === 0,
                        }}
                      >
                        Aplicar
                      </Submit>
                    </FlexContainer>
                    <Separator size={3}></Separator>
                    {errors["generalNote"] && (
                      <ErrorSpan>{errors["generalNote"]}</ErrorSpan>
                    )}
                    <Separator size={12}></Separator>
                    <StudentsGrade
                      loading={
                        getNotesStates.loading || cohortStudentsStates.loading
                      }
                      values={values}
                      noteSubCriterionStatus={noteSubCriterionStatus}
                      setFieldValue={setFieldValue}
                      students={values.students}
                      errors={errors.students}
                    />
                  </Grid.Col>
                </Grid.Row>
              </form>
            );
          }}
        </Formik>
      </Grid.Container>
      {currentCriterion && (
        <ModalConfirmDelete
          bntConfig={{
            content: "Eliminar",

            style: {
              style: { width: "170px", height: "40px" },
              color: "Danger",
              options: {
                type: "filled",
                skin: "danger",
                size: "lg",
                marginBottom: "0px",
              },
            },
          }}
          description="El criterio se eliminara de manera global con todos sus subcriterios y de todos los cohorts a los que fue asignado."
          states={deleteCriterionStates}
          title={
            <span
              style={{ color: "#697482", fontSize: "20px", fontWeight: "600" }}
            >
              {`¿Seguro que quieres eliminar el criterion ${currentCriterion.name}?`}
            </span>
          }
          elementActions={deleteCriterion({ _id: currentCriterion._id })}
          resetAction={hideModal}
          resetState={setAction}
        ></ModalConfirmDelete>
      )}
    </>
  );
};

const states = ({
  criterionStore,
  cohortStore,
  subCriterionStore,
  usersStore,
  currentStore,
  evaluateStore,
}) => {
  const { data: criterions } = criterionStore.allCriterions;
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: cohortStudents, states: cohortStudentsStates } =
    cohortStore.usersByCohort;
  const { data: subCriterions } = subCriterionStore.allSubCriterions;
  const { data: users } = usersStore.all;
  const { data: current } = currentStore;
  const { data: notes, states: getNotesStates } = evaluateStore.all;
  const { states: newNoteStates } = evaluateStore.newEvaluate;
  const { states: newManyEvaluateStates, data: newManyEvaluates } =
    evaluateStore.newManyEvaluate;
  const { states: deleteCriterionStates, data: deletedCriterion } =
    criterionStore.deleteCriterion;
  return {
    criterions,
    cohorts,
    subCriterions,
    users,
    current,
    notes,
    newNoteStates,
    newManyEvaluateStates,
    newManyEvaluates,
    deleteCriterionStates,
    deletedCriterion,
    getNotesStates,
    cohortStudents,
    cohortStudentsStates,
  };
};

export default connect(states)(Component);
