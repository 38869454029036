import * as Yup from "yup";

export const schema = Yup.object().shape({
  recoveryCode: Yup.string().required("Este campo es requerido."),
  email: Yup.string()
    .email("Ingrese un e-mail correcto.")
    .required("Este campo es requerido"),
  password: Yup.string()
    .required("Este campo es requerido")
    .min(5, "Debe tener al menos 5 caracteres")
    .max(40, "Debe tener al menos 40 caracteres"),
  "confirm-password": Yup.string()
    .required("Este campo es requerido")
    .oneOf([Yup.ref("password"), null], "La contraseña no coincide"),
});

interface FormValues {
  recoveryCode: string;
  email: string;
  password: string;
  "confirm-password": string;
}

export const initialValues: FormValues = {
  recoveryCode: "",
  email: "",
  password: "",
  "confirm-password": "",
};
