import { createRef, useState } from "react";
import { ArrayHelpers, FieldArray } from "formik";
import Input from "../Input";
import ReactSelect from "../ReactSelect";
import { FieldContainer } from "../Field";
import {
  AddButton,
  RemoveButton,
  EditButton,
  ScrollSection,
  FlexContainer,
  Separator,
} from "../../StyledComponents";
import { List, Item, Label } from "./styles";
import { uniqueArrayValues } from "../../../helpers/unique-array-values";
import Button from "../../Button";

interface ComponentProps {
  name: string;
  value?: (string | number | { label: string; value: any })[];
  onFocus?: (data: any) => void;
  onBlur?: (data: any) => void;
  onChange?: (data: any) => void;
  initialValues?: string[] | Option[];
  error?: {};
  touched?: {};
  forwardRef?;
  options: {
    itemOnClick?: { functionItem: (value: any) => any; value: any };
    height?: string;
    createdItem?: string[];
    multi?: boolean;
    before?: any;
    skin?: "base" | "gray";
    type: "input" | "select";
    data?: { value: string | number; label: string }[];
    loading?: boolean;
    inputPlaceholder?: string;
    onClick?: (item: any, index: number, arrayHelpers: ArrayHelpers) => void;
    onOptionSelected?: (item: any, index: number, fieldValue: string[]) => void;
    onDelete?: (item: any, index: number, fieldValue: string[]) => void;
    onAdd?: (text: string) => void;
    inputLabel: string;
    listLabel?: string;
    specialValid?: () => boolean;
    externalData?: boolean;
    indicator?: boolean;
    marginBottom?: number;
  };
}

interface Option {
  label?: string;
  value?: string;
}

const Component = ({
  value,
  name,
  initialValues,
  options,
  ...rest
}: ComponentProps) => {
  const { loading = false } = options;
  const [selectedItems, setSelectedItems] = useState<string[] | Option[]>(
    initialValues || []
  );
  const [text, setText] = useState("");
  const inputRef = createRef<HTMLInputElement>();
  return (
    <>
      <FieldArray
        {...rest}
        name={name}
        render={(arrayHelpers) => (
          <>
            <FieldContainer>
              <Button
                type="button"
                options={{
                  size: "lg",
                  type: "filled",
                  skin: "primary",
                }}
                onClick={() => {
                  !!options.onAdd && options.onAdd(text);
                }}
              >
                Agregar
              </Button>
              <Separator size={10}></Separator>
              {!!options.listLabel && <Label>{options.listLabel}</Label>}
              <ScrollSection
                style={{
                  ...(options.skin === "gray" && {
                    backgroundColor: "#f5f5f5",
                  }),
                  height: options?.height || "400px",
                  padding: "5px",
                }}
              >
                <List className={!!options.multi ? "multiLine" : "list"}>
                  {!!arrayHelpers.form.values[name] &&
                    arrayHelpers.form.values[name]?.map((item, index) => (
                      <Item
                        onClick={(ev) => {
                          ev.stopPropagation();
                          !!options.itemOnClick &&
                            options.itemOnClick.functionItem(item);
                        }}
                        className={`${!!options.multi ? "multiLine" : "list"} ${
                          !!options.itemOnClick &&
                          options.itemOnClick.value === item
                            ? "active"
                            : ""
                        }`}
                        key={`${item}-${index}`}
                      >
                        <FlexContainer gap="8px" align="center">
                          <span>
                            {!!options.onClick && (
                              <EditButton
                                type="button"
                                onClick={(ev) => {
                                  ev.stopPropagation();
                                  !!options.onClick &&
                                    options.onClick(item, index, arrayHelpers);
                                }}
                              />
                            )}

                            <RemoveButton
                              type="button"
                              onClick={(ev) => {
                                ev.stopPropagation();
                                options.onDelete &&
                                  options.onDelete(
                                    item,
                                    index,
                                    arrayHelpers.form.values[name]
                                  );
                                /*  arrayHelpers.remove(index);
                                !!options.onDelete &&
                                  options.onDelete(
                                    item,
                                    index,
                                    arrayHelpers.form.values[name]
                                  );
                                setSelectedItems(
                                  (selectedItems as string[]).filter(
                                    (_selectedItem, selectedItemIndex) =>
                                      index !== selectedItemIndex
                                  )
                                ); */
                                /*  if (options.type === "select") {
                                setSelectedItems(
                                  (selectedItems as Option[]).filter(
                                    (_selectedItem, selectedItemIndex) =>
                                      index !== selectedItemIndex
                                  )
                                );
                              } else {
                                setSelectedItems(
                                  (selectedItems as string[]).filter(
                                    (_selectedItem, selectedItemIndex) =>
                                      index !== selectedItemIndex
                                  )
                                );
                              } */
                              }}
                            />
                          </span>
                          <span>
                            {/* {options.type === "select" && !!options.externalData
                            ? externalValues(
                                selectedItems,
                                arrayHelpers.form.values[name]
                              ).filter((selectedItem) => {
                                return selectedItem.value === item.value;
                              })[0]?.label
                            : (options.data as Option[])?.filter(
                                (selectedItem) => selectedItem.value === item
                              )[0]?.label}
 */}
                            {
                              (options.data as Option[])?.filter(
                                (selectedItem) => selectedItem.value === item
                              )[0]?.label
                            }
                          </span>
                        </FlexContainer>
                      </Item>
                    ))}
                </List>
              </ScrollSection>
            </FieldContainer>
          </>
        )}
      />
    </>
  );
};

export default Component;
