import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import { Center } from "../../../../../../components/StyledComponents";
import Grid from "../../../../../../components/Grid";
import Button from "../../../../../../components/Button";
import Input from "../../../../../../components/Form/Input";

import Submit from "../../../../../../components/Form/Submit";

import {
  initialValues,
  schema,
} from "../../../../../../constants/form/finance/bankInformation";
import { newExecutive } from "../../../../../../store/actions/executive";

import Modal from "../../../../../../components/Modal";
import { AcademyDto } from "../../../../../../types/academy.dto";
import { StateDto } from "../../../../../../types/states.dto";
import academyActions from "../../../../../../store/actions/academies";
import { CurrentDto } from "../../../../../../types/current.dto";
import { toast } from "react-toastify";
import { hideModal } from "../../../../../../store/actions/modal";

interface ComponentProps {
  currentAcademy: AcademyDto;
  updateAcademyStates: StateDto;
  current: CurrentDto;
}

const Component = ({
  currentAcademy,
  updateAcademyStates,
  current,
}: ComponentProps) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();

  const dispatch = useDispatch();

  const onSubmit = ({ values, actions }) => {
    dispatch(
      academyActions.update({ _id: current.id, taxInfo: { ...values } })
    );
    setFormActions(actions);
  };

  useEffect(() => {
    if (updateAcademyStates.success) {
      toast.success("Informacion bancaria actualizada");
      dispatch(hideModal());
    }
  }, [updateAcademyStates]);

  return (
    <>
      <Modal name="bank-information-modal" title={"Informacion Bancaria"}>
        <Formik
          initialValues={{
            ...initialValues,
            ...(currentAcademy.taxInfo && {
              accountHolder: currentAcademy.taxInfo.accountHolder,
              cbu: currentAcademy.taxInfo.cbu,
              alias: currentAcademy.taxInfo.alias,
              bankName: currentAcademy.taxInfo.bankName,
            }),
          }}
          onSubmit={(values, actions) => {
            if (onSubmit) onSubmit({ values, actions });
          }}
          validateOnChange={formSubmmited}
          validateOnBlur={false}
          validationSchema={schema}
          enableReinitialize
        >
          {({
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
            setFieldValue,
            isSubmitting,
          }) => {
            return (
              <form
                className="theme-form"
                onSubmit={(event) => {
                  setFormSubmmited(true);
                  handleSubmit(event);
                }}
                id="edit-executive-form"
              >
                <Grid.Row>
                  <Input
                    name="accountHolder"
                    error={errors["accountHolder"]}
                    touched={touched["accountHolder"]}
                    value={values["accountHolder"]}
                    type="text"
                    placeholder="Ingrese el titular"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "Titular de la cuenta",
                      marginBottom: 20,
                    }}
                  />
                  <Input
                    name="cbu"
                    error={errors["cbu"]}
                    touched={touched["cbu"]}
                    value={values["cbu"]}
                    type="text"
                    placeholder="Ingrese el CBU"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "CBU",
                      marginBottom: 20,
                    }}
                  />
                  <Input
                    name="alias"
                    error={errors["alias"]}
                    touched={touched["alias"]}
                    value={values["alias"]}
                    type="text"
                    placeholder="Ingrese un alias"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "Alias",
                      marginBottom: 20,
                    }}
                  />

                  <Input
                    name="bankName"
                    error={errors["bankName"]}
                    touched={touched["bankName"]}
                    value={values["bankName"]}
                    type="text"
                    placeholder="Nombre del banco..."
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "Nombre del Banco",
                      marginBottom: 40,
                    }}
                  />
                </Grid.Row>
                <Center>
                  <Submit
                    isSubmmiting={updateAcademyStates.loading}
                    form="edit-executive-form"
                    color="Primary"
                    options={{
                      type: "filled",
                      skin: "primary",
                      size: "lg",
                      marginBottom: "0px",
                    }}
                  >
                    Confirmar
                  </Submit>

                  <Button
                    onClick={resetForm}
                    type="button"
                    options={{
                      type: "outline",
                      skin: "danger",
                      size: "lg",
                      marginBottom: "0px",
                    }}
                    style={{ marginLeft: "10px" }}
                  >
                    Deshacer
                  </Button>
                </Center>
              </form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

const states = ({ academyStore, currentStore }) => {
  const { states: updateAcademyStates } = academyStore.update;
  const { data: current } = currentStore;
  return {
    updateAcademyStates,
    current,
  };
};

export default connect(states)(Component);
