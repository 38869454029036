import {
  initialState,
  Actions,
  State,
  GET_ALL_CONTENTS,
  GET_ALL_CONTENTS_ERROR,
  GET_ALL_CONTENTS_SUCCESS,
  NEW_CONTENT,
  NEW_CONTENT_ERROR,
  NEW_CONTENT_SUCCESS,
  RESET_NEW_CONTENT,
  UPDATE_CONTENT,
  UPDATE_CONTENT_ERROR,
  UPDATE_CONTENT_SUCCESS,
  RESET_UPDATE_CONTENT,
  DELETE_CONTENT,
  DELETE_CONTENT_ERROR,
  DELETE_CONTENT_SUCCESS,
  RESET_DELETE_CONTENT,
  SAVED_CONTENT,
  UNSAVED_CONTENT,
  GET_CONTENT,
  GET_CONTENT_SUCCESS,
  GET_CONTENT_ERROR,
  RESET_GET_CONTENT,
  SET_GET_CONTENT,
  ADD_USER_TO_CONTENT,
  SHARED_CONTENT,
  RESET_UPDATE_MANY_CONTENT,
  UPDATE_MANY_CONTENT,
  UPDATE_MANY_CONTENT_ERROR,
  UPDATE_MANY_CONTENT_SUCCESS,
  RESET_GET_ALL_CONTENT_DATA,
} from "../types/content";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_CONTENTS:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_CONTENTS_SUCCESS:
      return {
        ...state,
        all: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case RESET_GET_ALL_CONTENT_DATA:
      return {
        ...state,
        all: initialState.all,
      };
    case SAVED_CONTENT:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UNSAVED_CONTENT:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_CONTENTS_ERROR:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };

    case NEW_CONTENT:
      return {
        ...state,
        create: {
          ...state.create,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_CONTENT_SUCCESS:
      return {
        ...state,
        create: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };

    case NEW_CONTENT_ERROR:
      return {
        ...state,
        create: {
          ...state.create,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_CONTENT:
      return {
        ...state,
        create: initialState.create,
      };
    case UPDATE_MANY_CONTENT:
      return {
        ...state,
        updateMany: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_MANY_CONTENT_SUCCESS:
      return {
        ...state,

        updateMany: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_MANY_CONTENT_ERROR:
      return {
        ...state,
        updateMany: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_MANY_CONTENT:
      return {
        ...state,
        updateMany: initialState.updateMany,
      };
    case GET_CONTENT:
      return {
        ...state,
        content: {
          ...state.content,
          states: {
            success: false,
            loading: true,
            error: false,
          },
        },
      };
    case GET_CONTENT_SUCCESS:
      return {
        ...state,
        content: {
          data: action.payload,
          states: {
            success: true,
            loading: false,
            error: false,
          },
        },
      };
    case GET_CONTENT_ERROR:
      return {
        ...state,
        content: {
          data: null,
          states: {
            success: false,
            loading: false,
            error: action.payload,
          },
        },
      };
    case ADD_USER_TO_CONTENT:
      return {
        ...state,
        update: {
          data: null,
          states: {
            success: false,
            loading: true,
            error: false,
          },
        },
      };
    case SHARED_CONTENT:
      return {
        ...state,
        update: {
          data: null,
          states: {
            success: false,
            loading: true,
            error: false,
          },
        },
      };
    case SET_GET_CONTENT:
      return {
        ...state,
        content: {
          ...state.content,
          data: !!action.payload ? action.payload : null,
        },
      };
    case RESET_GET_CONTENT:
      return {
        ...state,
        content: {
          ...state.content,
          states: {
            success: false,
            loading: false,
            error: false,
          },
        },
      };
    case DELETE_CONTENT:
      return {
        ...state,
        delete: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_CONTENT_SUCCESS:
      return {
        ...state,
        delete: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_CONTENT_ERROR:
      return {
        ...state,
        delete: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_CONTENT:
      return {
        ...state,
        delete: initialState.delete,
      };
    case UPDATE_CONTENT:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_CONTENT_SUCCESS:
      return {
        ...state,
        update: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_CONTENT_ERROR:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_CONTENT:
      return {
        ...state,
        update: initialState.update,
      };
    default:
      return state;
  }
};

export default reducers;
