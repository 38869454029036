import action from "../helpers/rest-client";

export class ErrorRequest extends Error {}

class Request {
  public new(payload) {
    return action
      .Post({
        url: "/requests",
        body: payload,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public update({ _id, condition }) {
    return action
      .Patch({
        url: `/requests/${condition}/${_id}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getAll(payload) {
    if (payload.filterBy) {
      payload.filterBy = JSON.stringify(payload.filterBy);
    }
    if (payload.select) {
      payload.select = JSON.stringify(payload.select);
    }
    const queryString = new URLSearchParams(payload).toString();
    return action
      .Get({
        url: `/requests?platform=CAMPUS${queryString ? "&" + queryString : ""}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getOne({ _id }) {
    return action
      .Get({
        url: `/categories/${_id}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public delete({ _id }) {
    return action
      .Del({
        url: `/requests/${_id}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const request = new Request();
export default request;
