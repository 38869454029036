import styled from "styled-components";

export const ListItem = styled("div")`
  background-color: #fff;
  padding: 16px 20px;
  border-radius: 15px;
  box-shadow: 0 0 3px 0 rgba(21, 115, 229, 0.16);
`;

export const EntityImageContainer = styled("div")`
  width: 32px;
  height: 32px;
  overflow: hidden;
  box-shadow: 0 0 1px 0 rgba(0, 30, 108, 0.16);
  border-radius: 50%;
`;

export const EntityTitleSemiBold = styled("h3")`
  font-size: 14px;
  font-weight: normal;
  color: #2a2747;
`;
