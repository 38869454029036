import { CohortDto } from "../../types/cohort.dto";
import { PaginationDto } from "../../types/pagination.dto";
import { UsersDto } from "../../types/users.dto";

export const GET_ALL_COHORTS = "GET_ALL_COHORTS";
export const GET_ALL_COHORTS_ERROR = "GET_ALL_COHORTS_ERROR";
export const GET_ALL_COHORTS_SUCCESS = "GET_ALL_COHORTS_SUCCESS";
export const RESET_GET_ALL_COHORT_DATA = "RESET_GET_ALL_COHORT_DATA";
export const GET_COHORT = "GET_COHORT";
export const GET_COHORT_ERROR = "GET_COHORT_ERROR";
export const GET_COHORT_SUCCESS = "GET_COHORT_SUCCESS";
export const NEW_COHORT = "NEW_COHORT";
export const NEW_COHORT_ERROR = "NEW_COHORT_ERROR";
export const NEW_COHORT_SUCCESS = "NEW_COHORT_SUCCESS";
export const RESET_NEW_COHORT = "RESET_NEW_COHORT";
export const UPDATE_COHORT = "UPDATE_COHORT";
export const UPDATE_COHORT_ERROR = "UPDATE_COHORT_ERROR";
export const UPDATE_COHORT_SUCCESS = "UPDATE_COHORT_SUCCESS";
export const RESET_UPDATE_COHORT = "RESET_UPDATE_COHORT";
export const DELETE_COHORT = "DELETE_COHORT";
export const DELETE_COHORT_ERROR = "DELETE_COHORT_ERROR";
export const DELETE_COHORT_SUCCESS = "DELETE_COHORT_SUCCESS";
export const RESET_DELETE_COHORT = "RESET_DELETE_COHORT";
export const DELETE_COHORT_MEMBER = "DELETE_COHORT_MEMBER";
export const DELETE_COHORT_MEMBER_SUCCESS = "DELETE_COHORT_MEMBER_SUCCESS";
export const DELETE_COHORT_MEMBER_ERROR = "DELETE_COHORT_MEMBER_ERROR";
export const GET_ALL_COHORT_STUDENTS = "GET_ALL_COHORT_STUDENTS";
export const GET_ALL_COHORT_STUDENTS_SUCCESS =
  "GET_ALL_COHORT_STUDENTS_SUCCESS";
export const GET_ALL_COHORT_STUDENTS_ERROR = "GET_ALL_COHORT_STUDENTS_ERROR";
export const RESET_GET_ALL_COHORT_STUDENTS = "RESET_GET_ALL_COHORT_STUDENTS";
export const NEW_COHORT_PLAN = "NEW_COHORT_PLAN";
export const UPDATE_COHORT_PLAN = "EDIT_COHORT_PLAN";

export interface NewCohortPlan {
  type: typeof NEW_COHORT_PLAN;
  payload: any;
}
export interface UpdateCohortPlan {
  type: typeof UPDATE_COHORT_PLAN;
  payload: any;
}

export interface GetAllCohorts {
  type: typeof GET_ALL_COHORTS;
  payload: PaginationDto;
}

export interface GetAllCohortsSuccess {
  type: typeof GET_ALL_COHORTS_SUCCESS;
  payload: CohortDto[];
}

export interface GetAllCohortsError {
  type: typeof GET_ALL_COHORTS_ERROR;
  payload: boolean | string;
}

export interface ResetGetAllCohortData {
  type: typeof RESET_GET_ALL_COHORT_DATA;
  payload: null;
}

export interface GetAllCohortStudents {
  type: typeof GET_ALL_COHORT_STUDENTS;
  payload: any;
}

export interface GetAllCohortStudentsSuccess {
  type: typeof GET_ALL_COHORT_STUDENTS_SUCCESS;
  payload: UsersDto[];
}

export interface GetAllCohortStudentsError {
  type: typeof GET_ALL_COHORT_STUDENTS_ERROR;
  payload: string | boolean;
}

export interface ResetGetAllCohortStudents {
  type: typeof RESET_GET_ALL_COHORT_STUDENTS;
  payload: null;
}

export interface GetCohort {
  type: typeof GET_COHORT;
  payload: { id: string };
}

export interface GetCohortSuccess {
  type: typeof GET_COHORT_SUCCESS;
  payload: CohortDto;
}

export interface GetCohortError {
  type: typeof GET_COHORT_ERROR;
  payload: boolean | string;
}

export interface NewCohort {
  type: typeof NEW_COHORT;
  payload: CohortDto;
}

export interface NewCohortSuccess {
  type: typeof NEW_COHORT_SUCCESS;
  payload: CohortDto;
}

export interface NewCohortError {
  type: typeof NEW_COHORT_ERROR;
  payload: boolean | string;
}

export interface ResetNewCohort {
  type: typeof RESET_NEW_COHORT;
  payload: null;
}

export interface UpdateCohort {
  type: typeof UPDATE_COHORT;
  payload: CohortDto;
}

export interface UpdateCohortSuccess {
  type: typeof UPDATE_COHORT_SUCCESS;
  payload: CohortDto;
}

export interface UpdateCohortError {
  type: typeof UPDATE_COHORT_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateCohort {
  type: typeof RESET_UPDATE_COHORT;
  payload: null;
}

export interface DeleteCohort {
  type: typeof DELETE_COHORT;
  payload: { id: string };
}

export interface DeleteCohortSuccess {
  type: typeof DELETE_COHORT_SUCCESS;
  payload: null;
}

export interface DeleteCohortError {
  type: typeof DELETE_COHORT_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteCohort {
  type: typeof RESET_DELETE_COHORT;
  payload: null;
}

export interface DeleteCohortMember {
  type: typeof DELETE_COHORT_MEMBER;
  payload: any;
}
export interface DeleteCohortMemberSuccess {
  type: typeof DELETE_COHORT_MEMBER_SUCCESS;
  payload: any;
}
export interface DeleteCohortMemberError {
  type: typeof DELETE_COHORT_MEMBER_ERROR;
  payload: string | boolean;
}

export interface State {
  allCohorts: {
    data: CohortDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  cohort: {
    data: CohortDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  newCohort: {
    data: CohortDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  usersByCohort: {
    data: UsersDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  deleteCohort: {
    data: null | CohortDto;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  updateCohort: {
    data: null | CohortDto;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  allCohorts: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  cohort: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  newCohort: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  deleteCohort: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  usersByCohort: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  updateCohort: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllCohorts
  | GetAllCohortsSuccess
  | GetAllCohortsError
  | ResetGetAllCohortData
  | GetCohort
  | GetCohortSuccess
  | GetCohortError
  | NewCohort
  | NewCohortSuccess
  | NewCohortError
  | ResetNewCohort
  | UpdateCohort
  | UpdateCohortSuccess
  | UpdateCohortError
  | ResetUpdateCohort
  | DeleteCohort
  | DeleteCohortSuccess
  | DeleteCohortError
  | ResetDeleteCohort
  | DeleteCohortMember
  | DeleteCohortMemberSuccess
  | DeleteCohortMemberError
  | GetAllCohortStudents
  | GetAllCohortStudentsSuccess
  | GetAllCohortStudentsError
  | ResetGetAllCohortStudents
  | NewCohortPlan
  | UpdateCohortPlan;
