import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import { AuthWrapper, Link, Title, Logo, GridStructure } from "./styles";
import { AuthContext } from "../../provider/AuthProvider";
import Input from "../../components/Form/Input";
import Submit from "../../components/Form/Submit";
import { UserDto } from "../../types/user.dto";
import Button from "../../components/Button";
import { toast, ToastContainer } from "react-toastify";
import Constana from "../../assets/images/constana.png";
import FacebookButton from "../../components/FacebookButton";
import {
  LoginSocialFacebook,
  LoginSocialInstagram,
} from "reactjs-social-login";
import "./style.css";
import {
  schema as signInSchema,
  initialValues as signInInitialValues,
} from "../../constants/form/auth/sign-in";

import {
  schema as recoveryCodeSchema,
  initialValues as recoveryCodeInitialValues,
} from "../../constants/form/auth/recovery-code";

import {
  schema as passwordRecoverySchema,
  initialValues as passwordRecoveryInitialValues,
} from "../../constants/form/auth/password-recovery";

import {
  recoverPassword,
  resetPassword,
  signIn,
  resetSignIn,
  signInSocial,
} from "../../store/actions/auth";
import { setCurrent } from "../../store/actions/current";
import { CurrentDto } from "../../types/current.dto";
import {
  Center,
  FlexContainer,
  Separator,
} from "../../components/StyledComponents";
import { Img } from "../Dashboard/Cohort/Customize/style";
import { useGoogleLogin } from "@react-oauth/google";
import { FaFacebookSquare, FaGithub, FaLinkedin } from "react-icons/fa";
import { getGitHubAccessToken, getGitHubUrl } from "../../helpers/githubUrl";
import {
  getLinkedinAccessToken,
  getLinkedinUrl,
} from "../../helpers/linkedinUrl";
import InstagramButton from "../../components/InstagramButton";
import SocialButton from "../../components/SocialButton";
import { FcGoogle } from "react-icons/fc";
import { CautionText } from "../SignUp/style";
import ConstanaLogo from "../../assets/images/constanaacademy.svg";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { initialValues, schema } from "../../constants/form/auth/sign-in";

interface CustomizedState {
  siginStates: {
    loading: boolean;
    success: boolean;
    error: boolean;
  };
  from: {
    pathname: string;
    search: string;
    hash: string;
  };
}

interface ComponentProps {
  signInStates: {
    loading: boolean;
    success: boolean;
    error: boolean;
  };
  sendSecurityCode;
  sendSecurityCodeStates: {
    loading: boolean;
    success: boolean;
    error: boolean;
  };
  resetPasswordStates: {
    loading: boolean;
    success: boolean;
    error: boolean;
  };
  user: UserDto;
  userStates: {
    loading: boolean;
    success: boolean;
    error: boolean;
  };
  usersStore;
  current: CurrentDto;
}

const Component = ({
  signInStates,
  sendSecurityCode,
  sendSecurityCodeStates,
  resetPasswordStates,
  user,
  userStates,
  usersStore,
  current,
}: ComponentProps) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();
  let { isAuthenticated } = React.useContext(AuthContext);
  let from = !(location.state as CustomizedState)?.from.search
    ? (location.state as CustomizedState)?.from?.pathname || "/dashboard"
    : `${(location.state as CustomizedState)?.from?.pathname}/${
        (location.state as CustomizedState)?.from.search
      }`;

  const [showLogin, setShowLogin] = useState(true);
  const [showPasswordRecovery, setShowPasswordRecovery] = useState(false);
  const [showPasswordChange, setShowPasswordChange] = useState(false);
  const [accountToRecover, setAccountToRecover] = useState("");
  const [githubToken, setGithubToken] = useState<{
    access_token: string;
    expires_in: number;
    refresh_token: string;
    refresh_token_expires_in: number;
    scope: string;
    token_type: string;
  }>();

  /* const githubDataUser = async (token) => {
    const data = await getGithubUserData(token);
    if (!!data.error) {
      //error toast
      console.log("pedri", data.error);
    } else {
      console.log("pedri", data.response);
    }
  }; */
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      dispatch(
        signInSocial({ code: codeResponse.access_token, social: "google" })
      );
    },
  });
  const onSubmit = ({ values, actions }) => {
    dispatch(signIn(values));
  };

  const sendRecoveryCode = ({ values, actions }) => {
    dispatch(recoverPassword(values));
    setAccountToRecover(values.email);
  };

  const changePassword = ({ values, actions }) => {
    dispatch(resetPassword(values));
  };
  useEffect(() => {
    if (sendSecurityCodeStates.success) {
      setShowLogin(false);
      setShowPasswordRecovery(false);
      setShowPasswordChange(true);
    }
  }, [sendSecurityCodeStates]);
  /*  useEffect(() => {
    if (!!githubToken) {
      githubDataUser(githubToken.access_token);
    }
  }, [githubToken]); */

  useEffect(() => {
    if (resetPasswordStates.success) {
      setShowLogin(true);
      setShowPasswordRecovery(false);
      setShowPasswordChange(false);
    }
  }, [resetPasswordStates]);
  useEffect(() => {
    if (!!searchParams.get("code")) {
      if ((location.search as string).split("%3D")[1] === "github") {
        dispatch(
          signInSocial({
            code: searchParams.get("code"),
            social: "github",
          })
        );
      }
      if ((location.search as string).split("%3D")[1] === "linkedin") {
        dispatch(
          signInSocial({
            code: searchParams.get("code"),
            social: "linkedin",
          })
        );
      }
    }
  }, [searchParams]);
  useEffect(() => {
    if (isAuthenticated) {
      if (!(location.state as CustomizedState)?.from?.pathname) {
        /* setData(userActions); */
      }
      navigate(from, { replace: true });
    }
    return () => {};
  }, [isAuthenticated]);
  /* hacer context para agarrar las acciones y cuando entro a una vista hago un get one */
  useEffect(() => {
    if (!!user) {
      /* setData(userActions); */

      navigate("/dashboard", { replace: true });
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (signInStates.error) {
      toast.error(`😱 No se ha podido iniciar sesion`);
      setTimeout(() => {
        dispatch(resetSignIn());
      }, 1000);
    }
    if (signInStates.success && !current) {
      dispatch(
        setCurrent({
          id: user?._id,
          currentSection: "personal",
          role: "USER",
          store: usersStore,
        })
      );
    }
  }, [signInStates]);

  // if (signInStates.loading || userStates.loading) {
  //   return null;
  // }

  return (
    <AuthWrapper>
      <Center>
        <Logo>
          <div style={{ height: "26px" }}>
            <Img src={ConstanaLogo} />
          </div>
        </Logo>
      </Center>
      {!showPasswordChange && !showPasswordRecovery && (
        <>
          <Title style={{ marginBottom: "27px" }}>Ingresar</Title>
          <GridStructure>
            <SocialButton
              color={"#2a2747"}
              background={"#fff"}
              active={"#1268a9"}
              text={"Continuar con Google"}
              onClick={() => {
                login();
              }}
              icon={<FcGoogle size={20}></FcGoogle>}
            ></SocialButton>

            <FacebookLogin
              appId="1149804909391563"
              fields="name,email,picture,gender"
              onSuccess={(response) => {
                dispatch(
                  signInSocial({
                    social: "facebook",
                    body: response.userID,
                    code: response.accessToken,
                  })
                );
              }}
              scope="public_profile, email, user_gender"
              onFail={(error) => {
                toast.error(`Error al acceder`, {
                  containerId: "container-sign-in",
                });
              }}
              /* onProfileSuccess={(response) => {
                console.log("Get Profile Success!", response);
              }} */
              render={({ onClick, logout }) => (
                <SocialButton
                  color={"#2a2747"}
                  background={"#fff"}
                  active={"#1268a9"}
                  text={"Continuar con Facebook"}
                  onClick={onClick}
                  icon={
                    <FaFacebookSquare
                      size={20}
                      color="#0866ff"
                    ></FaFacebookSquare>
                  }
                ></SocialButton>
              )}
            />
            <SocialButton
              color={"#2a2747"}
              background={"#fff"}
              active={"#1268a9"}
              text={"Continuar con Linkedin"}
              onClick={() => {
                window.location.href = getLinkedinUrl(from);
              }}
              icon={<FaLinkedin color={"#177FB1"} size={20}></FaLinkedin>}
            ></SocialButton>
          </GridStructure>
          <Separator size={14}></Separator>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              textAlign: "center",
            }}
          >
            <div
              style={{
                flexGrow: "1",
                borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
              }}
            ></div>
            <div
              style={{
                width: "49px",
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "500",
              }}
            >
              ó
            </div>
            <div
              style={{
                flexGrow: "1",
                borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
              }}
            ></div>
          </div>
          <Separator size={14}></Separator>
          <Formik
            initialValues={{ ...initialValues }}
            onSubmit={(values, actions) => {
              if (onSubmit) onSubmit({ values, actions });
            }}
            validateOnChange={formSubmmited}
            validateOnMount={false}
            validateOnBlur={false}
            validationSchema={schema}
            enableReinitialize
          >
            {({
              touched,
              errors,
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => {
              return (
                <form
                  className="theme-form"
                  onSubmit={(event) => {
                    setFormSubmmited(true);
                    handleSubmit(event);
                  }}
                  id="sign-in-form"
                >
                  <Input
                    name="user"
                    error={errors["user"]}
                    touched={touched["user"]}
                    type="text"
                    placeholder="Nombre de usuario ó e-mail"
                    onChange={handleChange}
                    fieldStyles={{
                      height: "29.5px",
                      borderRadius: "10px",
                      overflow: "hidden",
                      boxShadow: "none",
                    }}
                    inputStyles={{
                      padding: "7.9px 9px 7.9px 9px",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                    onBlur={handleBlur}
                    options={{
                      marginBottom: 20,

                      skin: "gray",
                    }}
                  />

                  <Input
                    name="password"
                    error={errors["password"]}
                    touched={touched["password"]}
                    type="password"
                    placeholder="Contraseña"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fieldStyles={{
                      height: "29.5px",
                      borderRadius: "10px",
                      overflow: "hidden",
                      boxShadow: "none",
                    }}
                    inputStyles={{
                      padding: "7.9px 9px 7.9px 9px",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                    options={{
                      marginBottom: 14,
                      skin: "gray",
                    }}
                  />
                  <FlexContainer align="center" justify="center">
                    <Link
                      style={{
                        fontSize: "10px",
                        fontWeight: "600",
                      }}
                      onClick={() => {
                        setShowLogin(false);
                        setShowPasswordRecovery(true);
                        setShowPasswordChange(false);
                      }}
                    >
                      ¿Olvidaste tu contraseña?
                    </Link>
                  </FlexContainer>
                  <Separator size={27}></Separator>

                  <Submit
                    isSubmmiting={signInStates.loading}
                    form="sign-in-form"
                    type="submit"
                    color="Primary"
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      height: "29px",
                      borderRadius: "10px",
                    }}
                    options={{
                      block: true,
                      type: "filled",
                      skin: "primary",
                      loading: "Primary",
                      size: "sm",
                      marginBottom: "27px",
                    }}
                  >
                    Ingresar
                  </Submit>
                  <FlexContainer align="center" justify="center">
                    <Link
                      style={{
                        fontSize: "10px",
                        fontWeight: "600",
                      }}
                      onClick={() => navigate("/sign-up")}
                    >
                      ¿No tenés una cuenta?
                    </Link>
                  </FlexContainer>
                </form>
              );
            }}
          </Formik>
        </>
      )}

      {showPasswordRecovery && !showPasswordChange && (
        <>
          <Title>Recuperar Contraseña</Title>
          <CautionText>
            Por favor, escribe la dirección de correo electrónico asociado con
            tu cuenta de Constana, y te enviaremos un correo con un enlace para
            recuperar tu contraseña.
          </CautionText>
          <Separator size={17}></Separator>
          <Formik
            initialValues={{ ...recoveryCodeInitialValues }}
            onSubmit={(values, actions) => {
              if (sendRecoveryCode) sendRecoveryCode({ values, actions });
            }}
            validateOnChange={formSubmmited}
            validateOnBlur={false}
            validationSchema={recoveryCodeSchema}
            enableReinitialize
          >
            {({
              touched,
              errors,
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => {
              return (
                <form
                  className="theme-form"
                  onSubmit={(event) => {
                    setFormSubmmited(true);
                    handleSubmit(event);
                  }}
                  id="recovery-code-form"
                >
                  <Input
                    name="email"
                    error={errors["email"]}
                    touched={touched["email"]}
                    type="text"
                    placeholder="Ingresar e-mail"
                    onChange={handleChange}
                    fieldStyles={{ height: "29.5px", borderRadius: "10px" }}
                    inputStyles={{
                      padding: "7.9px 9px 7.9px 9px",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                    onBlur={handleBlur}
                    options={{
                      marginBottom: 11,
                      skin: "gray",
                    }}
                  />

                  <Submit
                    form="recovery-code-form"
                    isSubmmiting={sendSecurityCodeStates.loading}
                    color="Primary"
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      height: "29px",
                      borderRadius: "10px",
                    }}
                    options={{
                      block: true,
                      type: "filled",
                      skin: "primary",
                      loading: "Primary",
                      size: "sm",
                      marginBottom: "10px",
                    }}
                  >
                    Recuperar contraseña
                  </Submit>

                  <Button
                    type="button"
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      height: "29px",
                      borderRadius: "10px",
                    }}
                    options={{
                      hoverSkin: true,
                      block: true,
                      type: "outline",
                      skin: "danger",
                      size: "sm",
                      marginBottom: "19px",
                    }}
                    onClick={() => {
                      setShowLogin(true);
                      setShowPasswordRecovery(false);
                      setShowPasswordChange(false);
                    }}
                  >
                    Cancelar
                  </Button>
                </form>
              );
            }}
          </Formik>
        </>
      )}

      {showPasswordChange && (
        <>
          <Title>Recupera tu contraseña</Title>
          <CautionText>
            Ingresa el código de recuperación que enviamos a tu correo
            electrónico y luego rellena los campos con la nueva contraseña.
          </CautionText>

          <Formik
            initialValues={{
              ...passwordRecoveryInitialValues,
              ...sendSecurityCode,
            }}
            onSubmit={(values, actions) => {
              if (changePassword) changePassword({ values, actions });
            }}
            validateOnChange={formSubmmited}
            validateOnBlur={false}
            validationSchema={passwordRecoverySchema}
            enableReinitialize
          >
            {({
              touched,
              errors,
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => {
              return (
                <form
                  className="theme-form"
                  onSubmit={(event) => {
                    setFormSubmmited(true);
                    handleSubmit(event);
                  }}
                  id="recover-password-form"
                >
                  <input
                    type="hidden"
                    name="varificationCode"
                    value={values["verificationCode"]}
                  />

                  <Input
                    name="email"
                    error={errors["email"]}
                    touched={touched["email"]}
                    value={values["email"]}
                    type="text"
                    placeholder="Ingresar email"
                    fieldStyles={{ height: "29.5px", borderRadius: "10px" }}
                    inputStyles={{
                      padding: "7.9px 9px 7.9px 9px",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      skin: "gray",
                      marginBottom: 11,
                    }}
                  />

                  <Input
                    name="recoveryCode"
                    error={errors["recoveryCode"]}
                    touched={touched["recoveryCode"]}
                    value={values["recoveryCode"]}
                    type="text"
                    placeholder="Código de recuperación"
                    onChange={handleChange}
                    fieldStyles={{ height: "29.5px", borderRadius: "10px" }}
                    inputStyles={{
                      padding: "7.9px 9px 7.9px 9px",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                    onBlur={handleBlur}
                    options={{
                      label: "Código de recuperación",
                      skin: "gray",
                      marginBottom: 11,
                    }}
                    autocomplete="off"
                  />

                  <Input
                    name="password"
                    error={errors["password"]}
                    touched={touched["password"]}
                    type="password"
                    placeholder="Nueva contraseña"
                    fieldStyles={{ height: "29.5px", borderRadius: "10px" }}
                    inputStyles={{
                      padding: "7.9px 9px 7.9px 9px",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "Nueva contraseña",
                      skin: "gray",
                      marginBottom: 11,
                    }}
                    autocomplete="off"
                  />

                  <Input
                    name="confirm-password"
                    error={errors["confirm-password"]}
                    touched={touched["confirm-password"]}
                    type="password"
                    placeholder="Repetir nueva contraseña"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "Confirmar contraseña",
                      skin: "gray",
                      marginBottom: 36,
                    }}
                    autocomplete="off"
                  />

                  <Submit
                    form="recover-password-form"
                    isSubmmiting={resetPasswordStates.loading}
                    options={{
                      block: true,
                      type: "filled",
                      skin: "primary",
                      size: "lg",
                      marginBottom: "20px",
                    }}
                  >
                    Cambiar contraseña
                  </Submit>

                  <Button
                    type="button"
                    options={{
                      hoverSkin: true,
                      block: true,
                      skin: "danger",
                      size: "lg",
                      type: "outline",
                    }}
                    onClick={() => {
                      setShowLogin(true);
                      setShowPasswordRecovery(false);
                      setShowPasswordChange(false);
                    }}
                  >
                    Cancelar
                  </Button>
                </form>
              );
            }}
          </Formik>
        </>
      )}

      <ToastContainer limit={1}></ToastContainer>
    </AuthWrapper>
  );
};

const state = ({ authStore, userStore, usersStore, currentStore }) => {
  const { states: signInStates } = authStore.signin;
  const { states: sendSecurityCodeStates, data: sendSecurityCode } =
    authStore.recoverPassword;
  const { states: resetPasswordStates } = authStore.resetPassword;
  const { data: user, states: userStates } = userStore;
  const { data: current } = currentStore;

  return {
    signInStates,
    sendSecurityCodeStates,
    sendSecurityCode,
    resetPasswordStates,
    userStates,
    user,
    usersStore,
    current,
  };
};

export default connect(state)(Component);
