import { Formik } from "formik";
import { useDispatch } from "react-redux";
import ReactSelect from "../../../../../../components/Form/ReactSelect";
import Icon from "../../../../../../components/Icon";
import searchIcon from "../../../../../../assets/icons/search.svg";
import { addView } from "../../../../../../store/actions/talent";
import { UsersDto } from "../../../../../../types/users.dto";

interface ComponentProps {
  applyedFilters;
  setFilter;
  users: UsersDto[];
  section?;
  placeholder?;
  setSelectedStudentId;
}

const Component = ({
  applyedFilters,
  setFilter,
  users,
  placeholder,
  setSelectedStudentId,
}: ComponentProps) => {
  return (
    <Formik
      initialValues={{}}
      onSubmit={(values, actions) => {}}
      enableReinitialize
    >
      {({ handleSubmit }) => {
        return (
          <form
            className="theme-form"
            onSubmit={(event) => {
              handleSubmit(event);
            }}
            id="userid-filter-form"
          >
            <ReactSelect
              name="userId"
              items={
                !!users &&
                users.map((user) => {
                  return {
                    label: `${user.lastName} ${user.name}`,
                    value: user._id,
                  };
                })
              }
              isClearable
              placeholder={`${placeholder}`}
              onOptionSelected={(newValue) => {
                const selected = users.filter(
                  (user) => user._id === newValue?.value
                )[0];
                setSelectedStudentId({
                  id: selected?._id,
                  name: `${selected?.lastName} ${selected?.name}`,
                });
              }}
              /*     onOptionSelected={(optionSelected) => {
                if (optionSelected) {
                  setFilter({
                    field: "_id",
                    value: optionSelected ? optionSelected.value : "no",
                  });
                } else {
                  setFilter({
                    field: "_id",
                    value: "no",
                  });
                }
              }}
              isClearable
              options={{
                marginBottom: 0,
                indicator: true,
                before: <Icon icon={searchIcon} size="16px" color="" />,
              }} */
            />
          </form>
        );
      }}
    </Formik>
  );
};

export default Component;
