import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { AiFillEdit, AiFillLock, AiFillUnlock } from "react-icons/ai";
import { FaCloudUploadAlt, FaSave, FaShare } from "react-icons/fa";
import Submit from "../../../../components/Form/Submit";
import Grid from "../../../../components/Grid";
import { FlexContainer } from "../../../../components/StyledComponents";
import {
  schema,
  initialValues,
} from "../../../../constants/form/byte/byte-creator";
import Input from "./components/Field/index";
import TextArea from "./components/TextArea";
import { RiCloseCircleFill } from "react-icons/ri";
import {
  ButtonInput,
  TrianglePlay,
  BytePreviewPublished,
  ByteUploaderDescriptionWrapper,
  ByteUploaderTitle,
  ByteUploadLoaderWrapper,
  ByteUploadWrapper,
} from "./style";
import { BiLink } from "react-icons/bi";
import { BsFillTrash2Fill } from "react-icons/bs";
import { FiUpload } from "react-icons/fi";
import { HiUserAdd } from "react-icons/hi";
import { toast } from "react-toastify";
import { hideModal, showModal } from "../../../../store/actions/modal";
import { connect, useDispatch } from "react-redux";
import { ByteDto } from "../../../../types/byte.dto";
import SideBar from "./components/SideBar";
import Loader from "../../../../components/Loader";
import {
  deleteByte,
  getAllBytes,
  getByte,
  newByte,
  resetDeleteByte,
  resetGetByte,
  resetNewByte,
  resetUpdateByte,
} from "../../../../store/actions/byte";
import { useNavigate, useParams } from "react-router-dom";
import { StateDto } from "../../../../types/states.dto";
import Button from "../../../../components/Button";
import PublishByteModal from "./components/PublishByteModal";
import {
  NEW_BYTE_ERROR,
  NEW_BYTE_SUCCESS,
  SET_GET_BYTE,
  UPDATE_BYTE,
  UPDATE_BYTE_LOADING,
} from "../../../../store/types/byte";
import axios from "axios";
import services from "../../../../services";
import AddUsersModal from "./components/AddUsersModal";
import { UsersDto } from "../../../../types/users.dto";
import ShareModal from "./components/ShareModal";
import PrivateModal from "./components/PrivateModal";
import ModalConfirmDelete from "../../../../components/ModalConfirmDelete";
import { CurrentDto } from "../../../../types/current.dto";
import LoadingSource from "./components/LoadingSource";
import CustomPlayer from "./components/CustomPlayer";
import { setFormData } from "../../../../helpers/formData";
import DropzoneField from "../../../../components/Form/Dropzone";
import { AcademyDto } from "../../../../types/academy.dto";
import { getAllUnits, resetGetAllUnits } from "../../../../store/actions/unit";
import {
  getAllCohorts,
  resetGetAllCohortsData,
} from "../../../../store/actions/cohort";
import { CohortDto } from "../../../../types/cohort.dto";

const Component = ({
  byte,
  byteGetStates,
  byteUpdateStates,
  users,
  byteDeleteStates,
  bytes,
  current,
  newByteDataStates,
  user,
  newByteData,
  academies,
  cohorts,
}: {
  byte: ByteDto;
  byteGetStates: StateDto;
  byteUpdateStates: StateDto;
  users: UsersDto[];
  byteDeleteStates: StateDto;
  bytes: ByteDto[];
  current: CurrentDto;
  newByteDataStates: StateDto;
  user: UsersDto;
  newByteData: ByteDto;
  academies: AcademyDto[];
  cohorts: CohortDto[];
}) => {
  const [fetchOn, setFetchOn] = useState<boolean>(false);
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const [byteImage, setByteImage] = useState<any>();
  const [byteCurrent, setByteCurrent] = useState<any>();
  const [byteDataChanged, setByteDataChanged] = useState<any>({
    name: "",
    description: "",
  });
  const [loading, setLoading] = useState(0);
  const [duration, setDuration] = useState<number>(0);
  const [byteMedia, setByteMedia] = useState<{
    byteApi?: string;
    imageApi?: string;
  }>({ byteApi: undefined, imageApi: undefined });
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cancelFileUpload = useRef<any>(null);
  const ref: any = useRef(null);

  const getConfig = async (config) => {
    const token = (await services.auth.getToken()) || "";
    const BASE_URL = process.env.REACT_APP_API_URL || "/";
    return {
      baseURL: BASE_URL,
      ...config,
      ...(token && {
        headers: { authorization: `Bearer ${token}`, ...config.headers },
      }),
    };
  };

  const onSubmitPatch = async (values, actions) => {
    const config = await getConfig({});
    dispatch({ type: UPDATE_BYTE, payload: null });
    axios
      .patch(
        `/contents/${byte?._id}`,
        setFormData(
          {
            name: values.name,
            description: values.description,
            ...(!!values.picture &&
              !byteMedia.imageApi && { picture: values.picture }),
            ...(!!values.url && !byte?.beenPublished && { url: values.url }),
            __v: byte?.__v,
          },
          ["picture", "url"]
        ),
        {
          ...config,
        }
      )
      .then((response) => {
        dispatch({ type: "UPDATE_BYTE_SUCCESS", payload: response.data });
        dispatch(getByte({ _id: response.data._id }));
        setByteCurrent(undefined);
        setByteImage(undefined);
        setTimeout(() => {
          dispatch(resetUpdateByte());
        }, 1000);
      })
      .catch((response) => {
        dispatch({
          type: "UPDATE_BYTE_ERROR",
          payload: response.message,
        });
        setTimeout(() => {
          dispatch(resetUpdateByte());
        }, 1000);
      });
  };

  const onSubmitPost = async (values, actions) => {
    const config = await getConfig({});
    if (!byte?.url && !byteCurrent) return null;
    if (!byte?.picture && !byteImage) return null;
    if (byteCurrent && duration > 60) {
      toast.error(`El byte tiene que durar menos de 1 minuto`);
      return null;
    }

    dispatch({ type: UPDATE_BYTE_LOADING, payload: null });

    axios
      .post(
        `/contents`,
        setFormData(
          {
            name: values.name,
            description: values.description,
            length: duration,
            ...(!!byteImage && { picture: byteImage.file }),
            ...(!!byteCurrent && { url: byteCurrent.file }),
            type: "BYTE",
            available: true,
            published: true,
            academy: current.id,
            ...values,
          },
          ["picture", "url"]
        ),
        {
          ...config,
          onUploadProgress(data) {
            const currentValue = Math.round((data.loaded / data.total) * 100);
            if (currentValue >= 99) {
              toast.info("Procesando byte");
            }
            setLoading(Math.min(currentValue, 99));
          },
          cancelToken: new axios.CancelToken(
            (cancel) => (cancelFileUpload.current = cancel)
          ),
        }
      )
      .then((response) => {
        setLoading(100);
        dispatch({ type: NEW_BYTE_SUCCESS, payload: response.data });
        setTimeout(() => {
          setLoading(0);
        }, 200);
      })
      .catch((response) => {
        dispatch({
          type: NEW_BYTE_ERROR,
          payload: response.message,
        });
        setTimeout(() => {
          setLoading(0);
        }, 200);
      });
  };

  const onSelectByte = (files) => {
    if (files.length <= 0) {
      setByteCurrent(undefined);
    }

    // I've kept this example simple by using the first image instead of multiple
    setByteCurrent({
      file: files[0],
      preview: URL.createObjectURL(files[0]),
    });
  };

  const onSelectCover = (files) => {
    if (files.length <= 0) {
      setByteImage(undefined);
    }

    // I've kept this example simple by using the first image instead of multiple
    setByteImage({
      file: files[0],
      preview: URL.createObjectURL(files[0]),
    });
  };

  const setChangedByteData = (e) => {
    setByteDataChanged((state) => {
      return { ...state, [e.target.name]: e.target.value };
    });
  };

  const menu = [
    {
      byteRole: ["OWNER"],
      Icon: FiUpload,
      toolTipName: !!byte?.published ? "Editar publicacion" : "Publicar CDoc",
      onClick: () => {
        dispatch(showModal("publish-byte-modal"));
      },
      IconActive: AiFillEdit,
      activeFunction: () => !!byte?.published,
      onClickActive: () => {
        dispatch(showModal("publish-byte-modal"));
      },
    },
    {
      Icon: AiFillLock,
      byteRole: ["OWNER"],
      IconActive: AiFillUnlock,
      toolTipName: "Cambiar visibilidad",
      onAvailable: () => !!byte && !!byte?.published,
      activeFunction: () => !!byte?.published,
      onClick: () => {
        dispatch(showModal("private-byte"));
      },
      onClickActive: () => {
        dispatch(showModal("private-byte"));
      },
    },
    {
      Icon: HiUserAdd,
      byteRole: ["OWNER", "COOWNER"],
      toolTipName: "Añadir Usuarios",
      onAvailable: () => !!byte && !!byte?.published,
      onClick: () => {
        dispatch(showModal("add-user-byte"));
      },
    },
    {
      Icon: FaSave,
      toolTipName: "Guardar",
      byteRole: ["OWNER", "COOWNER"],
      onClick: () => {},
      activeFunction: () =>
        (!byte &&
          byteDataChanged.name?.length > 5 &&
          byteDataChanged.description?.length > 0 &&
          !!byteImage &&
          !!byteCurrent) ||
        (!!byte &&
          byteDataChanged.name?.length > 5 &&
          byteDataChanged.description?.length > 0 &&
          (byte?.name !== byteDataChanged.name ||
            byte?.description !== byteDataChanged.description ||
            (!!byteCurrent && byte?.url) ||
            (!!byteImage && byte?.picture))),
      onAvailable: () =>
        (!byte &&
          byteDataChanged.name?.length > 5 &&
          byteDataChanged.description?.length > 0 &&
          !!byteImage &&
          !!byteCurrent) ||
        (!!byte &&
          byteDataChanged.name?.length > 5 &&
          byteDataChanged.description?.length > 0 &&
          (byte?.name !== byteDataChanged.name ||
            byte?.description !== byteDataChanged.description ||
            (!!byteCurrent && byte?.url) ||
            (!!byteImage && byte?.picture))),
      onClickActive: () => {
        if (!!byte) {
          if (byteCurrent && duration > 60) {
            toast.error(`El byte tiene que durar menos de 1 minuto`);
            return null;
          }
          onSubmitPatch(
            {
              name: byteDataChanged.name,
              description: byteDataChanged.description,
              _v: byte?.__v,
              ...(!!byteCurrent && { url: byteCurrent.file }),
              ...(!!byteImage && { picture: byteImage.file }),
            },
            {}
          );
        } else {
          if (byteCurrent && duration > 60) {
            toast.error(`El byte tiene que durar menos de 1 minuto`);
            return null;
          }
          onSubmitPost(
            {
              name: byteDataChanged.name,
              description: byteDataChanged.description,
              available: false,
              published: false,
            },
            {}
          );
        }
      },
    },
    {
      Icon: BiLink,
      byteRole: ["OWNER", "COOWNER", "READER"],
      toolTipName: "Copiar Link",
      onAvailable: () => !!byte && !!byte?.available && !!byte?.published,
      onClick: () => {
        navigator.clipboard.writeText(
          `https://academia.catel.org.ar/dashboard/bytes/${byte?._id}`
        );
        toast.success(`Link copiado`);
      },
    },
    {
      Icon: FaShare,
      toolTipName: "Compartir",
      byteRole: ["OWNER", "COOWNER", "READER"],
      onAvailable: () => !!byte && !!byte?.available && !!byte?.published,
      onClick: () => {
        dispatch(showModal("share-user-byte"));
      },
    },
    {
      Icon: BsFillTrash2Fill,
      byteRole: ["OWNER"],
      toolTipName: "Eliminar Byte",
      onAvailable: () => !!byte,
      onClick: () => {
        dispatch(showModal("confirm-delete-item-secuencial-modal"));
      },
    },
  ];

  const cancelUploading = () => {
    if (cancelFileUpload.current) {
      cancelFileUpload.current("User has cancel");
    }
  };

  useEffect(() => {
    !cohorts &&
      fetchOn &&
      dispatch(
        getAllCohorts({ filterBy: { academy: current.id, active: true } })
      );
  }, [cohorts, fetchOn]);

  useEffect(() => {
    if (cohorts && fetchOn) {
      dispatch(
        getAllUnits({
          filterBy: { cohort: { $in: cohorts.map((cohort) => cohort._id) } },
        })
      );
    }
  }, [cohorts]);

  useEffect(() => {
    setByteCurrent(undefined);
    setByteImage(undefined);
    dispatch({ type: SET_GET_BYTE, response: null });
  }, []);

  useEffect(() => {
    if (!!params.id && !byte) {
      dispatch(getByte({ _id: params.id }));
    }
    if (!params.id && !!byte) {
      dispatch({ type: SET_GET_BYTE, response: null });
    }
  }, [dispatch, params.id, byte]);

  useEffect(() => {
    if (byteUpdateStates.success) {
      toast.success("👌🏼 Byte actualizado");
      setByteCurrent(undefined);
      setByteImage(undefined);
      dispatch(getByte({ _id: byte?._id }));
      setTimeout(() => {
        dispatch(resetUpdateByte());
        dispatch(hideModal());
        formActions?.resetForm();
      }, 1000);
    }

    if (byteUpdateStates.error) {
      toast.error(`😱 ${byteUpdateStates.error}`);
      dispatch(getByte({ _id: byte?._id }));
      setTimeout(() => {
        dispatch(resetUpdateByte());
      }, 1000);
    }
  }, [byteUpdateStates]);

  useEffect(() => {
    if (!cohorts) {
      setFetchOn(true);
    }
  }, [cohorts]);

  useEffect(() => {
    if (byteGetStates.success) {
      setTimeout(() => {
        dispatch(resetGetByte());
      }, 1000);
    }
    if (byteGetStates.error) {
      toast.error(byteGetStates.error);
      setTimeout(() => {
        dispatch(resetGetByte());
      }, 1000);
    }
  }, [byteGetStates]);

  useEffect(() => {
    if (!!byte) {
      setByteDataChanged({
        name: byte?.name,
        description: byte?.description,
      });
      setByteMedia({
        byteApi: byte?.url,
        imageApi: byte?.picture,
      });
    } else {
      setByteDataChanged({ name: "", description: "" });
      setByteMedia({ byteApi: undefined, imageApi: undefined });
    }
  }, [byte]);

  useEffect(() => {
    if (newByteDataStates.success) {
      toast.success("Se creo el byte exitosamente");
      setByteCurrent(undefined);
      setByteImage(undefined);

      if (!!newByte) {
        navigate(newByteData._id);
      }
      /*  dispatch(getAllBytes()); */
      setTimeout(() => {
        dispatch(resetNewByte());
      }, 1000);
    }

    if (newByteDataStates.error) {
      toast.error("No se pudo crear el byte");
      setTimeout(() => {
        dispatch(resetNewByte());
      }, 1000);
    }
  }, [newByteDataStates, dispatch]);

  useEffect(() => {
    const getDuration = async function getDuration(file) {
      const url = URL.createObjectURL(file);

      return new Promise((resolve) => {
        const audio = document.createElement("audio");
        audio.muted = true;
        const source = document.createElement("source");
        source.src = url; //--> blob URL
        audio.preload = "metadata";
        audio.appendChild(source);
        audio.onloadedmetadata = function () {
          resolve(audio.duration);
        };
      });
    };

    if (!!byteCurrent) {
      getDuration(byteCurrent.file).then((response: any) => {
        setDuration(response);
      });
    }
  }, [byteCurrent]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllCohortsData());
      dispatch(resetGetAllUnits());
    };
  }, []);

  return (
    <>
      {(!!params.id && !byte) || byteGetStates.loading ? (
        <Loader color="Primary"></Loader>
      ) : (
        <>
          <Grid.Container>
            <Formik
              initialValues={initialValues({
                name: byteDataChanged.name,
                description: byteDataChanged.description,
              })}
              onSubmit={(values, actions) => {
                if (!!byte) {
                  onSubmitPatch(values, actions);
                } else {
                  onSubmitPost(values, actions);
                }
              }}
              validateOnChange={false}
              validateOnBlur={false}
              validationSchema={schema}
              validateOnMount={false}
              enableReinitialize
            >
              {({
                touched,
                errors,
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                resetForm,
                setFieldValue,
                isSubmitting,
              }) => {
                return (
                  <form
                    className="theme-form"
                    onSubmit={(event) => {
                      if (
                        values.description.length < 5 ||
                        values.name.length < 5
                      ) {
                        toast.error(
                          "La longitud de la descripcion y el titulo tienen que ser mayor a 5 caracteres"
                        );
                      }
                      if (!byteImage && !byte?.picture) {
                        toast.error(
                          "Se necesita subir una imagen de portada para el byte"
                        );
                      }
                      if (!byte?.url && !byteCurrent) {
                        toast.error("Se necesita subir un byte");
                      }
                      setFormSubmmited(true);
                      handleSubmit(event);
                    }}
                    id="byte-player-create"
                  >
                    <FlexContainer gap="10px">
                      <div style={{ width: "calc(100% - 50px)" }}>
                        <FlexContainer direction="column" gap="10px">
                          {!byteMedia.byteApi ? (
                            !!byte ? (
                              <ByteUploadLoaderWrapper height={"419px"}>
                                <h2>
                                  El byte se esta procesando, aparecera en todos
                                  los lugares donde indico una vez terminado
                                </h2>
                              </ByteUploadLoaderWrapper>
                            ) : !byteCurrent ? (
                              <DropzoneField
                                name="byteSource"
                                onChange={(files) => {
                                  toast.info(
                                    "Los unicos formatos que se pueden previsualizar antes de crearse son mp4-webm-ogv"
                                  );
                                  onSelectByte(files);
                                }}
                                onBlur={handleBlur}
                                error={errors["byteSource"]}
                                touched={touched["byteSource"]}
                                placeholder={
                                  "Selecciona el archivo que quieras subir"
                                }
                                options={{ size: "big", openOnClick: true }}
                                validation={{
                                  maxFileSize: 1024,
                                  maxFiles: 1,
                                  accept: "byte/mp4,byte/x-m4v,byte/mkv,byte/*",
                                }}
                              />
                            ) : !!newByteDataStates.loading ? (
                              <LoadingSource
                                height={"419px"}
                                loading={loading}
                                iconSize={100}
                                cancelUploading={cancelUploading}
                              ></LoadingSource>
                            ) : (
                              <CustomPlayer
                                videoCurrent={values}
                                ref={ref}
                                playerHeight="419px"
                                video={byteCurrent.preview}
                                CustomPlay={TrianglePlay}
                                lightImage={
                                  "https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8&w=1000&q=80"
                                }
                                {...(!!byteImage && {
                                  lightImage: byteImage.preview,
                                })}
                                {...(!!byteMedia.imageApi && {
                                  lightImage: byteMedia.imageApi,
                                })}
                              >
                                <RiCloseCircleFill
                                  onClick={() => {
                                    setByteCurrent(undefined);
                                  }}
                                  size={25}
                                  fill={"#E91E63"}
                                  color={"#fff"}
                                  style={{
                                    position: "absolute",
                                    top: "10px",
                                    right: "10px",
                                    zIndex: 3,
                                  }}
                                ></RiCloseCircleFill>
                              </CustomPlayer>
                            )
                          ) : (
                            <CustomPlayer
                              videoCurrent={byteCurrent}
                              ref={ref}
                              playerHeight="419px"
                              video={byteMedia.byteApi}
                              CustomPlay={TrianglePlay}
                              lightImage={
                                "https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8&w=1000&q=80"
                              }
                              {...(!!byteImage && {
                                lightImage: byteImage.preview,
                              })}
                              {...(!!byteMedia.imageApi && {
                                lightImage: byteMedia.imageApi,
                              })}
                            >
                              {!!byte && !byte?.published && (
                                <BytePreviewPublished>
                                  El byte no ha sido publicado
                                </BytePreviewPublished>
                              )}
                              {!!byte && (
                                <RiCloseCircleFill
                                  onClick={() => {
                                    setFieldValue("byteSource", "");
                                    setByteMedia((state) => {
                                      return { ...state, byteApi: undefined };
                                    });
                                    setByteCurrent(undefined);
                                  }}
                                  size={25}
                                  fill={"#E91E63"}
                                  color={"#fff"}
                                  style={{
                                    position: "absolute",
                                    top: "10px",
                                    right: "10px",
                                    zIndex: 3,
                                  }}
                                ></RiCloseCircleFill>
                              )}
                            </CustomPlayer>
                          )}
                          <ByteUploaderDescriptionWrapper>
                            <FlexContainer
                              justify="space-between"
                              wrap="wrap"
                              gap="10px"
                            >
                              <FlexContainer direction="column" gap="12px">
                                <FlexContainer
                                  direction="column"
                                  gap="12px"
                                  style={{ width: "360px" }}
                                >
                                  <Input
                                    containerStyles={{
                                      width: "100%",
                                      maxWidth: "340px",
                                      borderColor: "#697482",
                                    }}
                                    name="name"
                                    touched={touched["name"]}
                                    value={values["name"]}
                                    type="text"
                                    placeholder="Nombre del Byte"
                                    onChange={(ev) => {
                                      setChangedByteData(ev);
                                      handleChange(ev);
                                    }}
                                    onBlur={handleBlur}
                                    options={{
                                      marginBottom: 1,
                                    }}
                                  />
                                  <TextArea
                                    containerStyles={{
                                      width: "100%",
                                      maxWidth: "340px",
                                    }}
                                    name="description"
                                    touched={touched["description"]}
                                    value={values["description"]}
                                    onChange={(ev) => {
                                      setChangedByteData(ev);
                                      handleChange(ev);
                                    }}
                                    placeholder="Escriba su descripcion"
                                    onBlur={handleBlur}
                                    options={{
                                      skin: "base",
                                      marginBottom: 1,
                                    }}
                                  />
                                  <FlexContainer
                                    gap="10px"
                                    align="center"
                                    wrap="wrap"
                                  >
                                    {((!!byte &&
                                      (byte?.users.find(
                                        (userFounded) =>
                                          !userFounded.roleEnd &&
                                          userFounded.id === user._id
                                      )?.role === "OWNER" ||
                                        byte?.users.find(
                                          (userFounded) =>
                                            !userFounded.roleEnd &&
                                            userFounded.id === user._id
                                        )?.role === "COOWNER")) ||
                                      !byte) && (
                                      <>
                                        <Submit
                                          isSubmmiting={
                                            byteUpdateStates.loading
                                          }
                                          form="byte-player-create"
                                          color="Primary"
                                          options={{
                                            type: "filled",
                                            skin: "primary",
                                            size: "lg",
                                          }}
                                        >
                                          Publicar Rapido
                                        </Submit>

                                        <Button
                                          onClick={() => {
                                            dispatch(
                                              showModal("publish-byte-modal")
                                            );
                                          }}
                                          type="button"
                                          options={{
                                            type: "filled",
                                            skin: "primary",
                                            size: "lg",
                                          }}
                                          style={{ marginLeft: "10px" }}
                                        >
                                          Publicar
                                        </Button>

                                        <ButtonInput
                                          style={{
                                            ...(!byteCurrent && {
                                              cursor: "not-allowed",
                                            }),
                                            backgroundColor: `${
                                              !byteCurrent
                                                ? "grey"
                                                : "rgb(21, 115, 229)"
                                            }`,
                                          }}
                                        >
                                          Subir Otro Byte
                                          {!!byteCurrent && (
                                            <input
                                              accept="byte/mp4,byte/x-m4v,byte/*"
                                              onChange={(ev) => {
                                                toast.info(
                                                  "Los unicos formatos que se pueden previsualizar antes de crearse son mp4-webm-ogv"
                                                );
                                                if (!!byteCurrent) {
                                                  onSelectByte(ev);
                                                }
                                              }}
                                              type="file"
                                              style={{
                                                visibility: "hidden",
                                                width: "0",
                                                height: "0",
                                              }}
                                            ></input>
                                          )}
                                        </ButtonInput>
                                      </>
                                    )}
                                  </FlexContainer>
                                </FlexContainer>
                              </FlexContainer>
                              {!byteImage && !byteMedia.imageApi ? (
                                <DropzoneField
                                  name="byteImg"
                                  onChange={(files) => {
                                    onSelectCover(files);
                                  }}
                                  onBlur={handleBlur}
                                  error={errors["byteImg"]}
                                  touched={touched["byteImg"]}
                                  placeholder={
                                    "Selecciona el archivo que quieras subir"
                                  }
                                  options={{ size: "small", openOnClick: true }}
                                  validation={{
                                    maxFileSize: 1024,
                                    maxFiles: 1,
                                    accept: "image/*",
                                  }}
                                />
                              ) : (
                                <ByteUploadWrapper
                                  height={"200px"}
                                  style={{
                                    width: "340px",
                                    ...(!!byteImage && {
                                      backgroundImage: `url(${byteImage.preview})`,
                                    }),
                                    ...(!!byteMedia.imageApi && {
                                      backgroundImage: `url(${byteMedia.imageApi})`,
                                    }),
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center center",
                                  }}
                                >
                                  <RiCloseCircleFill
                                    onClick={() => {
                                      setByteImage(undefined);
                                      setByteMedia((state) => {
                                        return {
                                          ...state,
                                          imageApi: undefined,
                                        };
                                      });
                                    }}
                                    size={25}
                                    fill={"#E91E63"}
                                    color={"#fff"}
                                    style={{
                                      position: "absolute",
                                      top: "10px",
                                      right: "10px",
                                    }}
                                  ></RiCloseCircleFill>
                                </ByteUploadWrapper>
                              )}
                            </FlexContainer>
                          </ByteUploaderDescriptionWrapper>
                        </FlexContainer>
                      </div>
                      <SideBar video={byte} menu={menu}></SideBar>
                    </FlexContainer>
                  </form>
                );
              }}
            </Formik>
          </Grid.Container>

          <PublishByteModal
            onSubmitPost={onSubmitPost}
            duration={duration}
            byte={byte}
            byteDataChanged={byteDataChanged}
            byteImage={byteImage}
            byteCurrent={byteCurrent}
          ></PublishByteModal>

          {!!byte && <AddUsersModal byte={byte}></AddUsersModal>}
          {!!byte && <ShareModal byte={byte}></ShareModal>}
          {!!byte && <PrivateModal byte={byte}></PrivateModal>}
          {!!byte && (
            <ModalConfirmDelete
              bntConfig={{
                content: "Eliminar",
                style: {
                  style: { width: "170px", height: "40px" },
                  color: "Danger",
                  options: {
                    type: "filled",
                    skin: "danger",
                    size: "lg",
                    marginBottom: "0px",
                  },
                },
              }}
              sucessAction={() => navigate("/dashboard/contents/bytes")}
              states={byteDeleteStates}
              description={`El Byte se eliminara para siempre del sistema.`}
              title={
                <span
                  style={{
                    color: "#697482",
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  {`¿Seguro que quiere eliminar a ${byte?.name}?`}
                </span>
              }
              elementActions={deleteByte({ _id: byte?._id })}
              resetAction={resetDeleteByte}
              resetState={() => {}}
            ></ModalConfirmDelete>
          )}
        </>
      )}
    </>
  );
};

const states = ({
  byteStore,
  usersStore,
  currentStore,
  userStore,
  academyStore,
  cohortStore,
}) => {
  const { data: byte } = byteStore.byte;
  const { states: byteGetStates } = byteStore.byte;
  const { states: byteUpdateStates } = byteStore.update;
  const { states: byteDeleteStates } = byteStore.delete;
  const { data: users } = usersStore.all;
  const { data: bytes } = byteStore.all;
  const { data: current } = currentStore;
  const { data: user } = userStore;
  const { states: newByteDataStates, data: newByteData } = byteStore.new;
  const { data: academies } = academyStore.all;
  const { data: cohorts } = cohortStore.allCohorts;
  return {
    byte,
    byteGetStates,
    byteUpdateStates,
    users,
    byteDeleteStates,
    bytes,
    current,
    newByteDataStates,
    user,
    newByteData,
    academies,
    cohorts,
  };
};

export default connect(states)(Component);
