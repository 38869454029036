import { useEffect, useState } from "react";
import { useDispatch, connect } from "react-redux";

import { Formik } from "formik";
import { toast } from "react-toastify";
import { schema } from "../../../../../../../constants/form/cohort/new";
import Modal from "../../../../../../../components/Modal";
import Grid from "../../../../../../../components/Grid";
import { Center } from "../../../../../../../components/StyledComponents";
import { hideModal, showModal } from "../../../../../../../store/actions/modal";
import { ModalBigTitle, ModalTitleTitle } from "./style";
import {
  newCertificate,
  resetNewCertificate,
} from "../../../../../../../store/actions/certificates";
import Submit from "../../../../../../../components/Form/Submit";
const Component = ({
  newCertificateStates,
  certificate,
}: {
  newCertificateStates;
  certificate;
}) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const dispatch = useDispatch();
  const onSubmit = ({ values, actions }) => {
    dispatch(newCertificate(certificate));
    setFormActions(actions);
  };

  /* useEffect(() => {
    if (newCertificateStates.success) {
      toast("👌🏼 Enviado", {
        containerId: "submit",
        type: "success",
        toastId: "submitsucceful",
      });
      dispatch(hideModal());

      setTimeout(() => {
        dispatch(resetNewCertificate());
      }, 500);
    }

    if (newCertificateStates.error) {
      toast(`😱 ${newCertificateStates.error}`, {
        containerId: "submit",
        type: "error",
        toastId: "submiterror",
      });
      setTimeout(() => {
        dispatch(resetNewCertificate());
      }, 500);
    }
  }, [newCertificateStates]); */
  return (
    <Modal
      onClose={() => dispatch(showModal("send-certificates"))}
      name="modal-config-save"
      title={""}
      showClose={true}
    >
      <Grid.Row>
        <Center>
          <ModalBigTitle>
            ¿Estas seguro que deseas enviar el primer certificado?
          </ModalBigTitle>
        </Center>
      </Grid.Row>
      <Grid.Row style={{ marginBottom: "10px" }}>
        <Center>
          <ModalTitleTitle>
            Una vez que envíes el primer certificado no podrás hacer cambios de
            configuración general.
          </ModalTitleTitle>
        </Center>
      </Grid.Row>
      <Formik
        initialValues={{ asdas: "asd" }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="new-evaluate-form"
            >
              <Grid.Row>
                <Grid.Col>
                  <Center gap={"70px"}>
                    <Submit
                      isSubmmiting={newCertificateStates.loading}
                      form="new-evaluate-form"
                      color="Primary"
                      options={{
                        type: "filled",
                        skin: "primary",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                    >
                      {"Confirmar"}
                    </Submit>
                  </Center>
                </Grid.Col>
              </Grid.Row>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const states = ({ certificateStore }) => {
  const { states: newCertificateStates } = certificateStore.newCertificate;
  return {
    newCertificateStates,
  };
};

export default connect(states)(Component);
