import {
  initialState,
  Actions,
  State,
  GET_ALL_SUB_CRITERIONS,
  GET_ALL_SUB_CRITERIONS_ERROR,
  GET_ALL_SUB_CRITERIONS_SUCCESS,
  GET_SUB_CRITERION,
  GET_SUB_CRITERION_ERROR,
  GET_SUB_CRITERION_SUCCESS,
  NEW_SUB_CRITERION,
  NEW_SUB_CRITERION_ERROR,
  NEW_SUB_CRITERION_SUCCESS,
  RESET_NEW_SUB_CRITERION,
  UPDATE_SUB_CRITERION,
  UPDATE_SUB_CRITERION_ERROR,
  UPDATE_SUB_CRITERION_SUCCESS,
  RESET_UPDATE_SUB_CRITERION,
  DELETE_SUB_CRITERION,
  DELETE_SUB_CRITERION_ERROR,
  DELETE_SUB_CRITERION_SUCCESS,
  RESET_DELETE_SUB_CRITERION,
  NEW_MANY_SUB_CRITERION,
  NEW_MANY_SUB_CRITERION_SUCCESS,
  NEW_MANY_SUB_CRITERION_ERROR,
  RESET_NEW_MANY_SUB_CRITERION,
  RESET_UPDATE_MANY_SUB_CRITERION,
  UPDATE_MANY_SUB_CRITERION_ERROR,
  UPDATE_MANY_SUB_CRITERION_SUCCESS,
  UPDATE_MANY_SUB_CRITERION,
  RESET_GET_ALL_SUB_CRITERIONS,
} from "../types/sub-criterion";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_SUB_CRITERIONS:
      return {
        ...state,
        allSubCriterions: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_SUB_CRITERIONS_SUCCESS:
      return {
        ...state,
        allSubCriterions: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_SUB_CRITERIONS_ERROR:
      return {
        ...state,
        allSubCriterions: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_GET_ALL_SUB_CRITERIONS:
      return {
        ...state,
        allSubCriterions: initialState.allSubCriterions,
      };
    case NEW_MANY_SUB_CRITERION:
      return {
        ...state,
        newMany: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_MANY_SUB_CRITERION_SUCCESS:
      return {
        ...state,
        allSubCriterions: {
          ...state.allSubCriterions,
          data: action.payload,
        },
        newMany: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case NEW_MANY_SUB_CRITERION_ERROR:
      return {
        ...state,
        newMany: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_MANY_SUB_CRITERION:
      return {
        ...state,
        newMany: initialState.newMany,
      };
    case UPDATE_MANY_SUB_CRITERION:
      return {
        ...state,
        updateMany: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_MANY_SUB_CRITERION_SUCCESS:
      return {
        ...state,
        allSubCriterions: {
          ...state.allSubCriterions,
          data: action.payload,
        },
        updateMany: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_MANY_SUB_CRITERION_ERROR:
      return {
        ...state,
        updateMany: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_MANY_SUB_CRITERION:
      return {
        ...state,
        updateMany: initialState.updateMany,
      };
    case GET_SUB_CRITERION:
      return {
        ...state,
        subCriterion: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_SUB_CRITERION_SUCCESS:
      return {
        ...state,
        subCriterion: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_SUB_CRITERION_ERROR:
      return {
        ...state,
        subCriterion: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case NEW_SUB_CRITERION:
      return {
        ...state,
        newSubCriterion: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_SUB_CRITERION_SUCCESS:
      return {
        ...state,
        newSubCriterion: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case NEW_SUB_CRITERION_ERROR:
      return {
        ...state,
        newSubCriterion: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_SUB_CRITERION:
      return {
        ...state,
        newSubCriterion: initialState.newSubCriterion,
      };
    case DELETE_SUB_CRITERION:
      return {
        ...state,
        deleteSubCriterion: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_SUB_CRITERION_SUCCESS:
      return {
        ...state,
        deleteSubCriterion: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_SUB_CRITERION_ERROR:
      return {
        ...state,
        deleteSubCriterion: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_SUB_CRITERION:
      return {
        ...state,
        deleteSubCriterion: initialState.deleteSubCriterion,
      };
    case UPDATE_SUB_CRITERION:
      return {
        ...state,
        updateSubCriterion: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_SUB_CRITERION_SUCCESS:
      return {
        ...state,
        updateSubCriterion: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_SUB_CRITERION_ERROR:
      return {
        ...state,
        updateSubCriterion: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_SUB_CRITERION:
      return {
        ...state,
        updateSubCriterion: initialState.updateSubCriterion,
      };
    default:
      return state;
  }
};

export default reducers;
