import { put, call, takeLatest, select } from "redux-saga/effects";
import services from "../../../services";
import {
  NEW_SEARCH,
  NEW_SEARCH_ERROR,
  NEW_SEARCH_SUCCESS,
  NewSearch,
  GET_ALL_SEARCHES,
  GET_ALL_SEARCHES_ERROR,
  GET_ALL_SEARCHES_SUCCESS,
  UPDATE_CURRENT_SEARCH_LIST,
  GetAllSearchs,
  UPDATE_SEARCH,
  UPDATE_SEARCH_ERROR,
  UPDATE_SEARCH_SUCCESS,
  UpdateSearch,
  DELETE_SEARCH,
  SET_NEW_SEARCH_DATA,
  SET_UPDATE_SEARCH_DATA,
  UPDATE_MANY_SEARCH_ERROR,
  UPDATE_MANY_SEARCH_SUCCESS,
  UpdateManySearch,
  UPDATE_MANY_SEARCH,
  DELETE_SEARCH_ERROR,
  DELETE_SEARCH_SUCCESS,
} from "../../types/recruiting/search";

const searchList = ({ recruitingStore }) => recruitingStore.search.all.data;

function* newSearch({ payload }) {
  const { response, error } = yield call(
    services.recruiting.search.new,
    payload
  );

  if (error) {
    yield put({
      type: NEW_SEARCH_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: SET_NEW_SEARCH_DATA, payload: response });
    yield put({ type: NEW_SEARCH_SUCCESS, payload: response });
  }
}

function* deleteSearch({ payload }) {
  const { response, error } = yield call(
    services.recruiting.search.delete,
    payload
  );

  if (error) {
    yield put({
      type: DELETE_SEARCH_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    const currentListOfSearches = yield select(searchList);
    const updatedListOfSearches = currentListOfSearches?.filter(
      (search) => search._id !== payload._id
    );

    yield put({
      type: UPDATE_CURRENT_SEARCH_LIST,
      payload: updatedListOfSearches,
    });
    yield put({ type: DELETE_SEARCH_SUCCESS, payload: response });
  }
}

function* getAllSearchs() {
  const { response, error } = yield call(services.recruiting.search.getAll);
  if (error) {
    yield put({
      type: GET_ALL_SEARCHES_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_SEARCHES_SUCCESS, payload: response });
  }
}

function* updateManySearch({ payload }) {
  const { response, error } = yield call(
    services.recruiting.search.updateMany,
    payload
  );
  if (error) {
    yield put({
      type: UPDATE_MANY_SEARCH_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    const currentListOfTechsTalents = yield select(searchList);
    const newListOfAitTalents = currentListOfTechsTalents?.map((talent) => {
      const changedIds = response.map((el) => el._id);
      if (changedIds.includes(talent._id)) {
        return response.find((elem) => elem._id === talent._id);
      }
      return talent;
    });
    yield put({ type: UPDATE_MANY_SEARCH_SUCCESS, payload: null });
    yield put({
      type: UPDATE_CURRENT_SEARCH_LIST,
      payload: newListOfAitTalents,
    });
  }
}

function* updatedSearch({ payload }) {
  const { response, error } = yield call(
    services.recruiting.search.update,
    payload
  );

  if (error) {
    yield put({
      type: UPDATE_SEARCH_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: UPDATE_SEARCH_SUCCESS, payload: null });
    const currentListOfSearches = yield select(searchList);
    const updatedListOfSearches = currentListOfSearches?.map((search) => {
      if (search._id === payload._id) {
        return { ...search, ...payload };
      }
      return search;
    });
    yield put({
      type: UPDATE_CURRENT_SEARCH_LIST,
      payload: updatedListOfSearches,
    });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<NewSearch>(NEW_SEARCH, newSearch);
  yield takeLatest<UpdateManySearch>(UPDATE_MANY_SEARCH, updateManySearch);
  yield takeLatest<GetAllSearchs>(GET_ALL_SEARCHES, getAllSearchs);
  yield takeLatest<UpdateSearch>(UPDATE_SEARCH, updatedSearch);
  yield takeLatest<UpdateSearch>(DELETE_SEARCH, deleteSearch);
}
