import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Modal from "../../../../../../components/Modal";
import { Formik } from "formik";
import { FlexContainer } from "../../../../../../components/StyledComponents";
import { schema } from "../../../../../../constants/form/organization/profile/contact/edit";
import Button from "../../../../../../components/Button";
import { toast } from "react-toastify";
import Input from "../../../../../../components/Form/Input";
import academiesActions from "../../../../../../store/actions/academies";
import { hideModal, showModal } from "../../../../../../store/actions/modal";
import {
  HiddenInput,
  ModalInputWrapper,
  ModalSubTitle,
  ModalTextField,
} from "./style";
import { FaCloudUploadAlt } from "react-icons/fa";
import { AiFillCloseCircle } from "react-icons/ai";
import Toggle from "../../../../../../components/Toggle";
import { AcademyDto } from "../../../../../../types/academy.dto";
import { StateDto } from "../../../../../../types/states.dto";
import { CurrentDto } from "../../../../../../types/current.dto";
import { CohortDto } from "../../../../../../types/cohort.dto";
import { CertificateDto } from "../../../../../../types/certificate.dto";
import { UsersDto } from "../../../../../../types/users.dto";
import { TalentDto } from "../../../../../../types/talent.dto";
import {
  newCertificate,
  resetNewCertificate,
} from "../../../../../../store/actions/certificates";
import Loader from "../../../../../../components/Loader";
import ModalConfigSave from "./ModalConfigSave";
import { removeUndefinedFromObject } from "../../../../../../helpers/removeUndefinedObject";
import MobileContentModal from "../../../../../../components/MobileContentModal";
import { GrCertificate } from "react-icons/gr";

const Component = ({
  selectedAcademy,
  updateAcademyStates,
  academies,
  current,
  currentCohort,
  currentStyle,
  certificates,
  users,
  talents,
  createCertificateStates,
  setCurrentStyle,
  isModalOpen,
  setIsModalOpen,
}: {
  selectedAcademy: AcademyDto;
  updateAcademyStates: StateDto;
  academies: AcademyDto[];
  current: CurrentDto;
  currentCohort: CohortDto;
  currentStyle: {
    name?: string;
    academyLogo?: boolean;
    titleFont?: string;
    titleSize?: string;
    titleColor?: string;
    nameFont?: string;
    nameSize?: string;
    nameColor?: string;
    cohortName?: boolean;
    background?: any;
  };
  certificates: CertificateDto[];
  users: UsersDto[];
  talents: TalentDto[];
  createCertificateStates: StateDto;
  setCurrentStyle: any;
  isModalOpen: boolean;
  setIsModalOpen;
}) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const [background, setBackground] = useState<HTMLInputElement>();
  const [certificateData, setCertificateData] = useState<any>();
  const dispatch = useDispatch();
  const onSubmit = ({ values, actions }) => {
    dispatch(academiesActions.update({ _id: current.id, ...values }));
    setFormActions(actions);
  };
  const handleChangeStyles = (value, name) => {
    setCurrentStyle((state) => {
      return { ...state, [name]: value };
    });
  };

  const disabledInput = !!certificates?.filter(
    (certificate) => certificate.cohort._id === currentCohort._id
  )[0]
    ? "disabledInput"
    : "";

  const sendMultipleCertificates = (values, cohortUsers) => {
    for (const cohortUser of cohortUsers) {
      const talent = users.filter((talent) => talent._id === cohortUser._id)[0];
      !!talent &&
        dispatch(
          newCertificate(
            removeUndefinedFromObject({
              ...currentStyle,
              ...values,
              talent: talent._id,
            })
          )
        );
    }
  };

  useEffect(() => {
    if (createCertificateStates.success) {
      toast("👌🏼 Enviado", {
        containerId: "submit",
        type: "success",
        toastId: "submitsucceful",
      });
      dispatch(hideModal());

      setTimeout(() => {
        dispatch(resetNewCertificate());
      }, 500);
    }

    if (createCertificateStates.error) {
      toast(`😱 ${createCertificateStates.error}`, {
        containerId: "submit",
        type: "error",
        toastId: "submiterror",
      });
      setTimeout(() => {
        dispatch(resetNewCertificate());
      }, 500);
    }
  }, [createCertificateStates]);
  useEffect(() => {
    if (!!background?.files && !!background?.files[0]) {
      handleChangeStyles(background?.files[0], "background");
    } else {
      console.log("muxz");
      handleChangeStyles(undefined, "background");
    }
  }, [background]);

  return (
    <>
      <MobileContentModal
        isVisible={isModalOpen}
        setIsVisible={setIsModalOpen}
        title="Certificados"
        Icon={GrCertificate}
        styles={{
          animation: "fadeInSlideRight",
          style: { width: "365px", top: "0px", right: "0px", left: "unset" },
        }}
        bodyStyle={{ height: "100%", maxWidth: "365px", width: "100%" }}
        bodyContentStyle={{ height: "100%", overflow: "unset" }}
      >
        <FlexContainer justify="center">
          <ModalSubTitle>
            Todas las modificaciones a realizarse afectaran a todos los
            certificados del cohort seleccionado
          </ModalSubTitle>
        </FlexContainer>
        <Formik
          initialValues={{
            ...currentStyle,
            titleSize: Number(currentStyle.titleSize?.split("px")[0] ?? 0),
            nameSize: Number(currentStyle.nameSize?.split("px")[0] ?? 0),
          }}
          onSubmit={(values, actions) => {
            if (onSubmit) onSubmit({ values, actions });
          }}
          validateOnBlur={true}
          validationSchema={schema}
          validateOnChange
          validateOnMount={true}
          enableReinitialize
        >
          {({
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
            setFieldValue,
            isSubmitting,
          }) => {
            return (
              <form
                className="theme-form"
                onSubmit={(event) => {
                  setFormSubmmited(true);
                  handleSubmit(event);
                }}
                id="edit-academy-profile-form"
              >
                <ModalInputWrapper
                  disabled={disabledInput}
                  style={{ marginBottom: "10px" }}
                >
                  <>{console.log(values, "mjzxz")}</>
                  <FlexContainer
                    justify="center"
                    align="center"
                    direction="column"
                  >
                    {!background?.files ? (
                      <>
                        <ModalTextField>Imagen de Fondo</ModalTextField>
                        <FaCloudUploadAlt
                          color="#697482"
                          fontSize={40}
                        ></FaCloudUploadAlt>
                        <HiddenInput
                          type="file"
                          onChange={(ev) => setBackground(ev.target)}
                        ></HiddenInput>
                      </>
                    ) : (
                      <FlexContainer align="center" gap="5px">
                        <ModalTextField>
                          {background?.files && background?.files[0].name}
                        </ModalTextField>
                        <AiFillCloseCircle
                          color="red"
                          onClick={() => setBackground(undefined)}
                        ></AiFillCloseCircle>
                      </FlexContainer>
                    )}
                  </FlexContainer>
                </ModalInputWrapper>
                <ModalInputWrapper
                  disabled={disabledInput}
                  style={{ marginBottom: "10px" }}
                >
                  <FlexContainer align="center" justify="space-between">
                    <ModalTextField>Logo de tu academia:</ModalTextField>
                    <Toggle
                      field={"academyLogo"}
                      setActive={setFieldValue}
                      value={values["academyLogo"]}
                      checked={values["academyLogo"] === true}
                      checkedValue={true}
                      unCheckedValue={false}
                      handleChange={(ev) => {
                        setFieldValue("academyLogo", !values["academyLogo"]);
                        handleChangeStyles(
                          !values["academyLogo"],
                          "academyLogo"
                        );
                      }}
                    ></Toggle>
                  </FlexContainer>
                </ModalInputWrapper>
                <ModalInputWrapper
                  disabled={disabledInput}
                  style={{ marginBottom: "10px" }}
                >
                  <FlexContainer direction="column">
                    <ModalTextField>Nombre del Certificado *</ModalTextField>

                    <Input
                      name="certificateName"
                      type="text"
                      value={values["certificateName"]}
                      error={errors["certificateName"]}
                      touched={touched["certificateName"]}
                      onBlur={handleBlur}
                      onChange={(ev) => {
                        handleChange(ev);
                        handleChangeStyles(ev.target.value, ev.target.name);
                      }}
                      fieldStyles={{
                        borderRadius: "10px",
                      }}
                      options={{
                        marginBottom: 20,
                      }}
                    />
                    <ModalTextField style={{ fontWeight: "500" }}>
                      {currentStyle.name}
                    </ModalTextField>
                  </FlexContainer>
                </ModalInputWrapper>
                <ModalInputWrapper
                  disabled={disabledInput}
                  style={{ marginBottom: "10px" }}
                >
                  <FlexContainer direction="column">
                    <FlexContainer align="center" gap="5px">
                      <ModalTextField>Tamaño de fuente:</ModalTextField>
                      <Input
                        name="titleSize"
                        type="number"
                        value={values["titleSize"]}
                        error={errors["titleSize"]}
                        touched={touched["titleSize"]}
                        onBlur={handleBlur}
                        onChange={(ev) => {
                          handleChangeStyles(ev.target.value, ev.target.name);
                        }}
                        fieldStyles={{
                          width: "32px",
                          height: "18px",
                          padding: "0px",
                          borderRadius: "3px",
                          border: "1px solid #1573e5",
                        }}
                        inputStyles={{ padding: "0px" }}
                        options={{
                          marginBottom: 0,
                        }}
                      />
                    </FlexContainer>
                    <FlexContainer>
                      <ModalTextField>Color:</ModalTextField>
                      <input
                        name="titleColor"
                        value={currentStyle.titleColor}
                        onChange={(ev) => {
                          handleChangeStyles(ev.target.value, ev.target.name);
                        }}
                        defaultValue={"#000"}
                        type="color"
                        style={{ background: "transparent", border: "none" }}
                      ></input>
                    </FlexContainer>
                  </FlexContainer>
                </ModalInputWrapper>
                <ModalInputWrapper
                  disabled={disabledInput}
                  style={{ marginBottom: "10px" }}
                >
                  <FlexContainer direction="column">
                    <FlexContainer align="center" gap="4px">
                      <ModalTextField>Nombre del Usuario:</ModalTextField>
                    </FlexContainer>
                    <FlexContainer align="center" gap="5px">
                      <ModalTextField>Tamaño de fuente:</ModalTextField>
                      <Input
                        name="nameSize"
                        type="number"
                        value={values["nameSize"]}
                        error={errors["nameSize"]}
                        touched={touched["nameSize"]}
                        onBlur={handleBlur}
                        onChange={(ev) => {
                          handleChangeStyles(ev.target.value, ev.target.name);
                        }}
                        inputStyles={{ padding: "0px" }}
                        containerStyles={{
                          width: "32px",
                          height: "18px",
                          padding: "0px",
                        }}
                        fieldStyles={{
                          width: "100%",
                          height: "18px",
                          padding: "0px",
                          borderRadius: "3px",
                          border: "1px solid #1573e5",
                          minHeight: "0px",
                        }}
                        options={{
                          marginBottom: 0,
                        }}
                      />
                    </FlexContainer>
                    <FlexContainer align="center">
                      <ModalTextField>Color:</ModalTextField>
                      <input
                        name="nameColor"
                        value={values["nameColor"]}
                        onChange={(ev) => {
                          setFieldValue("nameColor", ev.target.value);
                          handleChangeStyles(ev.target.value, ev.target.name);
                        }}
                        defaultValue={"#000"}
                        type="color"
                        style={{ background: "transparent", border: "none" }}
                      ></input>
                    </FlexContainer>
                  </FlexContainer>
                </ModalInputWrapper>
                <ModalInputWrapper
                  disabled={disabledInput}
                  style={{ marginBottom: "10px" }}
                >
                  <FlexContainer align="center" justify="space-between">
                    <ModalTextField>Mostrar nombre del cohort:</ModalTextField>
                    <Toggle
                      field={"cohortName"}
                      setActive={setFieldValue}
                      value={values["cohortName"]}
                      checked={values["cohortName"] === true}
                      checkedValue={true}
                      unCheckedValue={false}
                      handleChange={(ev) => {
                        setFieldValue(
                          "cohortName",
                          values["cohortName"] === true ? false : true
                        );
                        handleChangeStyles(
                          values["cohortName"] === true ? false : true,
                          "cohortName"
                        );
                      }}
                    ></Toggle>
                  </FlexContainer>
                </ModalInputWrapper>
                <ModalInputWrapper>
                  {users ? (
                    <>
                      <FlexContainer
                        align="center"
                        justify="space-between"
                        style={{ marginBottom: "8px" }}
                      >
                        <ModalTextField>
                          Usuarios dentro del Cohort:
                        </ModalTextField>
                        <Button
                          type="button"
                          disabled={!!errors["certificateName"]}
                          onClick={() => {
                            const data = {
                              name: values["name"],
                              cohort: currentCohort._id,
                              academy: current.id,
                            };
                            const cohortUsers = users.filter((userF) =>
                              currentCohort.studentsIds.includes(userF._id)
                            );
                            sendMultipleCertificates(data, cohortUsers);
                          }}
                          options={{
                            size: "md",
                            type: "filled",
                            skin: "primary",
                          }}
                          style={{
                            minwidth: "0px",
                            width: "120px",
                            height: "25px",
                            fontSize: "12px",
                            fontWeight: "500",
                          }}
                        >
                          Enviar a todos
                        </Button>
                      </FlexContainer>
                      <FlexContainer
                        style={{ maxHeight: "200px", overflowY: "auto" }}
                        direction="column"
                        gap="5px"
                      >
                        {users
                          .filter((user) =>
                            currentCohort.studentsIds.includes(user._id)
                          )
                          .map((student) => {
                            return (
                              <FlexContainer
                                align="center"
                                justify="space-between"
                                style={{ marginRight: "26px" }}
                              >
                                <ModalTextField style={{ fontSize: "13px" }}>
                                  {`${student.name} ${student.lastName}`}
                                </ModalTextField>
                                {!certificates?.find(
                                  (certificate) =>
                                    certificate.cohort._id ===
                                      currentCohort._id &&
                                    certificate.talent === student._id
                                ) ? (
                                  <Button
                                    type="button"
                                    disabled={!!errors["certificateName"]}
                                    onClick={() => {
                                      if (
                                        certificates?.filter(
                                          (certificate) =>
                                            certificate.cohort._id ===
                                            currentCohort._id
                                        ).length > 0
                                      ) {
                                        dispatch(
                                          newCertificate({
                                            ...removeUndefinedFromObject({
                                              ...currentStyle,
                                              talent: student._id,
                                              name: values["name"],
                                              cohort: currentCohort._id,
                                              academy: current.id,
                                            }),
                                          })
                                        );
                                      } else {
                                        setCertificateData({
                                          ...removeUndefinedFromObject({
                                            ...currentStyle,
                                            talent: student._id,
                                            name: values["name"],
                                            cohort: currentCohort._id,
                                            academy: current.id,
                                          }),
                                        });
                                        dispatch(
                                          showModal("modal-config-save")
                                        );
                                      }
                                    }}
                                    options={{
                                      size: "md",
                                      type: "filled",
                                      skin: "primary",
                                    }}
                                    style={{
                                      minwidth: "0px",
                                      width: "70px",
                                      height: "20px",
                                      fontSize: "12px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Enviar
                                  </Button>
                                ) : (
                                  <Button
                                    type="button"
                                    onClick={() => {}}
                                    options={{
                                      size: "md",
                                      type: "filled",
                                      skin: "primary",
                                    }}
                                    style={{
                                      minwidth: "0px",
                                      width: "70px",
                                      height: "20px",
                                      fontSize: "12px",
                                      fontWeight: "500",
                                      backgroundColor: "#000",
                                      borderColor: "#000",
                                    }}
                                  >
                                    Enviado
                                  </Button>
                                )}
                              </FlexContainer>
                            );
                          })}
                      </FlexContainer>
                    </>
                  ) : (
                    <Loader color="Primary"></Loader>
                  )}
                </ModalInputWrapper>
              </form>
            );
          }}
        </Formik>
      </MobileContentModal>

      {!!certificateData && (
        <ModalConfigSave certificate={certificateData}></ModalConfigSave>
      )}
    </>
  );
};

const states = ({
  academyStore,
  currentStore,
  certificateStore,
  usersStore,
  talentStore,
}) => {
  const { data: selectedAcademy } = academyStore.selected;
  const { states: updateAcademyStates } = academyStore.update;
  const { data: current } = currentStore;
  const { data: academies } = academyStore.all;
  const { data: certificates } = certificateStore.allCertificates;
  const { data: users } = usersStore.all;
  const { data: talents } = talentStore.allTalents;
  const { states: createCertificateStates } = certificateStore.newCertificate;

  return {
    selectedAcademy,
    updateAcademyStates,
    academies,
    current,
    certificates,
    users,
    talents,
    createCertificateStates,
  };
};

export default connect(states)(Component);
