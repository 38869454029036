import React from "react";
import { Button } from "./style";
import { AiOutlineUserAdd } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { showModal } from "../../../../../store/actions/modal";
import { DataText } from "../../../User/Profile/style";
import { CardRow } from "../../../Academy/Profile/style";

const Component = ({ text, modalName }) => {
  const dispatch = useDispatch();
  return (
    <Button onClick={() => dispatch(showModal(modalName))}>
      <DataText size="14px" style={{ color: "#fff" }}>
        {text}
      </DataText>
    </Button>
  );
};

export default Component;
