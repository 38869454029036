import { string } from "prop-types";
import { AcademyDto } from "../../types/academy.dto";
import { PaginationDto } from "../../types/pagination.dto";

export const GET_ALL_ACADEMIES = "GET_ALL_ACADEMIES";
export const GET_ALL_ACADEMIES_ERROR = "GET_ALL_ACADEMIES_ERROR";
export const GET_ALL_ACADEMIES_SUCCESS = "GET_ALL_ACADEMIES_SUCCESS";
export const RESET_GET_ALL_ACADEMIES_DATA = "RESET_GET_ALL_ACADEMIES_DATA";
export const GET_ACADEMY = "GET_ACADEMY";
export const GET_ACADEMY_ERROR = "GET_ACADEMY_ERROR";
export const GET_ACADEMY_SUCCESS = "GET_ACADEMY_SUCCESS";
export const RESET_GET_ACADEMY = "RESET_GET_ACADEMY";
export const NEW_ACADEMY = "NEW_ACADEMY";
export const NEW_ACADEMY_ERROR = "NEW_ACADEMY_ERROR";
export const NEW_ACADEMY_SUCCESS = "NEW_ACADEMY_SUCCESS";
export const RESET_NEW_ACADEMY = "RESET_NEW_ACADEMY";
export const UPDATE_ACADEMY = "UPDATE_ACADEMY";
export const UPDATE_ACADEMY_ERROR = "UPDATE_ACADEMY_ERROR";
export const UPDATE_ACADEMY_SUCCESS = "UPDATE_ACADEMY_SUCCESS";
export const RESET_UPDATE_ACADEMY = "RESET_UPDATE_ACADEMY";
export const DELETE_ACADEMY = "DELETE_ACADEMY";
export const DELETE_ACADEMY_ERROR = "DELETE_ACADEMY_ERROR";
export const DELETE_ACADEMY_SUCCESS = "DELETE_ACADEMY_SUCCESS";
export const RESET_DELETE_ACADEMY = "RESET_DELETE_ACADEMY";
export const SET_UPDATE_ACADEMY_DATA = "SET_UPDATE_ACADEMY_DATA";
export const SET_NEW_ACADEMY_DATA = "SET_NEW_ACADEMY_DATA";
export const UPDATE_CURRENT_ACADEMY_LIST = "UPDATE_CURRENT_ACADEMY_LIST";
export const NEW_INVITE_LINK = "NEW_INVITE_LINK";
export const NEW_INVITE_LINK_SUCCESS = "NEW_INVITE_LINK_SUCCESS";
export const NEW_INVITE_LINK_ERROR = "NEW_INVITE_LINK_ERROR";
export const DELETE_INVITE_LINK = "DELETE_INVITE_LINK";
export const DELETE_INVITE_LINK_SUCCESS = "DELETE_INVITE_LINK_SUCCESS";
export const DELETE_INVITE_LINK_ERROR = "DELETE_INVITE_LINK_ERROR";
export const RESET_DELETE_INVITE_LINK = "RESET_DELETE_INVITE_LINK";
export const RESET_NEW_INVITE_LINK = "RESET_CREATE_INVITE_LINK";
export const SELECT_ACADEMY = "SELECT_ACADEMY";
export const UPDATE_ACADEMY_ROLE = "UPDATE_ACADEMY_ROLE";
export const DELETE_ACADEMY_MEMBER = "DELETE_ACADEMY_MEMBER";
export const ACADEMY_ADD_VIEW = "ACADEMY_ADD_VIEW";
export const ACADEMY_ADD_CLICK = "ACADEMY_ADD_CLICK";
export const ACADEMY_DISSOCIATE_ORGANIZATION =
  "ACADEMY_DISSOCIATE_ORGANIZATION";
export const ADD_ACADEMY_MEMBER = "ADD_ACADEMY_MEMBER";
export const SET_ACADEMY_MP = "SET_ACADEMY_MP";
export const NEW_ACADEMY_PLAN = "NEW_ACADEMY_PLAN";
export const UPDATE_ACADEMY_PLAN = "EDIT_ACADEMY_PLAN";

export interface NewAcademyPlan {
  type: typeof NEW_ACADEMY_PLAN;
  payload: any;
}
export interface UpdateAcademyPlan {
  type: typeof UPDATE_ACADEMY_PLAN;
  payload: any;
}

export interface SetAcademyMp {
  type: typeof SET_ACADEMY_MP;
  payload: { code: string; _id: string };
}

export interface SelectAcademy {
  type: typeof SELECT_ACADEMY;
  payload: AcademyDto;
}

export interface AcademyDissociateOrganization {
  type: typeof ACADEMY_DISSOCIATE_ORGANIZATION;
  payload: { _id: string; organizationId: string };
}

export interface UpdateCurrentAcademyList {
  type: typeof UPDATE_CURRENT_ACADEMY_LIST;
  payload: AcademyDto[];
}
export interface AddAcademyMember {
  type: typeof ADD_ACADEMY_MEMBER;
  payload: { user: string; role: string; _id: string };
}

export interface GetAllAcademies {
  type: typeof GET_ALL_ACADEMIES;
  payload: PaginationDto;
}

export interface GetAllAcademiesSuccess {
  type: typeof GET_ALL_ACADEMIES_SUCCESS;
  payload: AcademyDto[];
}

export interface ResetGetAllAcademiesData {
  type: typeof RESET_GET_ALL_ACADEMIES_DATA;
  payload: null;
}

export interface NewInviteLink {
  type: typeof NEW_INVITE_LINK;
  payload: string;
}
export interface NewInviteLinkSuccess {
  type: typeof NEW_INVITE_LINK_SUCCESS;
  payload: string;
}
export interface NewInviteLinkError {
  type: typeof NEW_INVITE_LINK_ERROR;
  payload: string | boolean;
}
export interface ResetNewInviteLink {
  type: typeof RESET_NEW_INVITE_LINK;
  payload: null;
}

export interface DeleteInviteLink {
  type: typeof DELETE_INVITE_LINK;
  payload: string;
}
export interface DeleteInviteLinkSuccess {
  type: typeof DELETE_INVITE_LINK_SUCCESS;
  payload: string;
}
export interface DeleteInviteLinkError {
  type: typeof DELETE_INVITE_LINK_ERROR;
  payload: string | boolean;
}

export interface ResetDeleteInviteLink {
  type: typeof RESET_DELETE_INVITE_LINK;
  payload: null;
}

export interface GetAllAcademiesError {
  type: typeof GET_ALL_ACADEMIES_ERROR;
  payload: boolean | string;
}

export interface GetAcademy {
  type: typeof GET_ACADEMY;
  payload: { _id: string };
}

export interface GetAcademySuccess {
  type: typeof GET_ACADEMY_SUCCESS;
  payload: AcademyDto;
}

export interface GetAcademyError {
  type: typeof GET_ACADEMY_ERROR;
  payload: boolean | string;
}

export interface ResetGetAcademy {
  type: typeof RESET_GET_ACADEMY;
  payload: null;
}

export interface NewAcademy {
  type: typeof NEW_ACADEMY;
  payload: AcademyDto;
}

export interface NewAcademySuccess {
  type: typeof NEW_ACADEMY_SUCCESS;
  payload: AcademyDto;
}

export interface NewAcademyError {
  type: typeof NEW_ACADEMY_ERROR;
  payload: boolean | string;
}

export interface SetUpdateAcademyData {
  type: typeof SET_UPDATE_ACADEMY_DATA;
  payload: AcademyDto;
}

export interface SetNewAcademyData {
  type: typeof SET_NEW_ACADEMY_DATA;
  payload: AcademyDto;
}

export interface ResetNewAcademy {
  type: typeof RESET_NEW_ACADEMY;
  payload: null;
}

export interface UpdateAcademy {
  type: typeof UPDATE_ACADEMY;
  payload: AcademyDto;
}

export interface UpdateAcademySuccess {
  type: typeof UPDATE_ACADEMY_SUCCESS;
  payload: AcademyDto;
}

export interface UpdateAcademyError {
  type: typeof UPDATE_ACADEMY_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateAcademy {
  type: typeof RESET_UPDATE_ACADEMY;
  payload: null;
}

export interface DeleteAcademy {
  type: typeof DELETE_ACADEMY;
  payload: { _id: string };
}

export interface DeleteAcademySuccess {
  type: typeof DELETE_ACADEMY_SUCCESS;
  payload: null;
}

export interface DeleteAcademyError {
  type: typeof DELETE_ACADEMY_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteAcademy {
  type: typeof RESET_DELETE_ACADEMY;
  payload: null;
}

export interface DeleteAcademyMember {
  type: typeof DELETE_ACADEMY_MEMBER;
  payload: { _id: string; userId: string };
}

export interface State {
  all: {
    data: AcademyDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  selected: {
    data: AcademyDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  create: {
    data: null | AcademyDto;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  delete: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  invite: {
    create: {
      data: null | string;
      states: {
        loading: boolean;
        success: boolean;
        error: boolean | string;
      };
    };
    delete: {
      states: {
        loading: boolean;
        success: boolean;
        error: boolean | string;
      };
    };
  };
  update: {
    data: null | AcademyDto;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  all: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  selected: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  create: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  delete: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  invite: {
    create: {
      data: null,
      states: {
        loading: false,
        success: false,
        error: false,
      },
    },
    delete: {
      states: {
        loading: false,
        success: false,
        error: false,
      },
    },
  },
  update: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllAcademies
  | GetAllAcademiesSuccess
  | GetAllAcademiesError
  | GetAcademy
  | GetAcademySuccess
  | GetAcademyError
  | NewAcademy
  | NewAcademySuccess
  | NewAcademyError
  | ResetNewAcademy
  | UpdateAcademy
  | UpdateAcademySuccess
  | UpdateAcademyError
  | ResetUpdateAcademy
  | DeleteAcademy
  | DeleteAcademySuccess
  | DeleteAcademyError
  | ResetDeleteAcademy
  | SetUpdateAcademyData
  | SetNewAcademyData
  | UpdateCurrentAcademyList
  | NewInviteLink
  | NewInviteLinkSuccess
  | NewInviteLinkError
  | DeleteInviteLink
  | DeleteInviteLinkSuccess
  | DeleteInviteLinkError
  | ResetNewInviteLink
  | ResetDeleteInviteLink
  | SelectAcademy
  | DeleteAcademyMember
  | AcademyDissociateOrganization
  | AddAcademyMember
  | ResetGetAllAcademiesData
  | SetAcademyMp
  | ResetGetAcademy
  | NewAcademyPlan
  | UpdateAcademyPlan;
