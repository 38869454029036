import { put, call, takeLatest, select, take, all } from "redux-saga/effects";
import services from "../../services";
import {
  NEW_ACADEMY,
  NEW_ACADEMY_ERROR,
  NEW_ACADEMY_SUCCESS,
  NewAcademy,
  GET_ALL_ACADEMIES,
  GET_ALL_ACADEMIES_ERROR,
  GET_ALL_ACADEMIES_SUCCESS,
  GetAllAcademies,
  UPDATE_ACADEMY,
  UPDATE_ACADEMY_ERROR,
  UPDATE_ACADEMY_SUCCESS,
  UpdateAcademy,
  DELETE_ACADEMY,
  UPDATE_CURRENT_ACADEMY_LIST,
  DELETE_ACADEMY_SUCCESS,
  DELETE_ACADEMY_ERROR,
  NEW_INVITE_LINK_ERROR,
  NEW_INVITE_LINK_SUCCESS,
  NEW_INVITE_LINK,
  DELETE_INVITE_LINK,
  DELETE_INVITE_LINK_ERROR,
  DELETE_INVITE_LINK_SUCCESS,
  UPDATE_ACADEMY_ROLE,
  DELETE_ACADEMY_MEMBER,
  DeleteAcademyMember,
  GET_ACADEMY_ERROR,
  GET_ACADEMY_SUCCESS,
  GET_ACADEMY,
  ACADEMY_ADD_VIEW,
  AcademyDissociateOrganization,
  ACADEMY_DISSOCIATE_ORGANIZATION,
  SELECT_ACADEMY,
  AddAcademyMember,
  ADD_ACADEMY_MEMBER,
  SetAcademyMp,
  SET_ACADEMY_MP,
  UpdateAcademyPlan,
  UPDATE_ACADEMY_PLAN,
  NewAcademyPlan,
  NEW_ACADEMY_PLAN,
} from "../types/academy";
import { GET_ALL_ASSOCIATION } from "../types/association";

const companyList = ({ academyStore }) => academyStore.all.data;

function* newAcademy({ payload }) {
  const { response, error } = yield call(services.academy.new, payload);

  if (error) {
    yield put({
      type: NEW_ACADEMY_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    const currentListOfTechsAcademies = yield select(companyList);
    const newListofTechsAcademies = !!currentListOfTechsAcademies
      ? [...currentListOfTechsAcademies, response]
      : null;
    yield put({
      type: UPDATE_CURRENT_ACADEMY_LIST,
      payload: newListofTechsAcademies,
    });
    yield put({ type: NEW_ACADEMY_SUCCESS, payload: response });
  }
}

function* addMember({ payload }) {
  const { response, error } = yield call(services.academy.addMember, payload);

  if (error) {
    yield put({
      type: UPDATE_ACADEMY_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_ACADEMY_SUCCESS, payload: null })]);
  }
}

function* setAcademyMp({ payload }) {
  const { response, error } = yield call(services.academy.setMp, payload);

  if (error) {
    yield put({
      type: UPDATE_ACADEMY_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: UPDATE_ACADEMY_SUCCESS, payload: response });
  }
}
function* newPlan({ payload }) {
  const { response, error } = yield call(services.academy.newPlan, payload);

  if (error) {
    yield put({
      type: UPDATE_ACADEMY_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: UPDATE_ACADEMY_SUCCESS, payload: response });
  }
}
function* updatePlan({ payload }) {
  const { response, error } = yield call(services.academy.updatePlan, payload);

  if (error) {
    yield put({
      type: UPDATE_ACADEMY_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: UPDATE_ACADEMY_SUCCESS, payload: response });
  }
}

function* deleteAcademy({ payload }) {
  const { response, error } = yield call(services.academy.delete, payload);

  if (error) {
    yield put({
      type: DELETE_ACADEMY_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    const currentListOfAcademies = yield select(companyList);
    const newListofAcademies = currentListOfAcademies?.filter(
      (company) => company._id !== payload._id
    );
    yield put({
      type: UPDATE_CURRENT_ACADEMY_LIST,
      payload: newListofAcademies,
    });
    yield put({ type: DELETE_ACADEMY_SUCCESS, payload: response });
  }
}

function* getAllAcademies({ payload }: GetAllAcademies) {
  const { response, error } = yield call(services.academy.getAll, payload);
  if (error) {
    yield put({
      type: GET_ALL_ACADEMIES_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_ACADEMIES_SUCCESS, payload: response });
  }
}

function* getOneAcademy({ payload }) {
  const { response, error } = yield call(services.academy.getOne, payload);
  if (error) {
    yield put({
      type: GET_ACADEMY_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ACADEMY_SUCCESS, payload: response });
  }
}

function* createLink({ payload }) {
  const { response, error } = yield call(services.academy.createLink, payload);
  if (error) {
    yield put({
      type: NEW_INVITE_LINK_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: NEW_INVITE_LINK_SUCCESS, payload: response });
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_URL}/link/${response.inviteLink}`
    );
  }
}

function* deleteLink({ payload }) {
  const { response, error } = yield call(services.academy.deleteLink, payload);
  if (error) {
    yield put({
      type: DELETE_INVITE_LINK_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: DELETE_INVITE_LINK_SUCCESS, payload: response });
  }
}

function* updatedAcademy({ payload }) {
  const { response, error } = yield call(services.academy.update, payload);

  if (error) {
    yield put({
      type: UPDATE_ACADEMY_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: UPDATE_ACADEMY_SUCCESS, payload: response });
  }
}

function* updatedRole({ payload }) {
  const { response, error } = yield call(services.academy.updateRole, payload);

  if (error) {
    yield put({
      type: UPDATE_ACADEMY_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: UPDATE_ACADEMY_SUCCESS, payload: response });
  }
}

function* deleteMember({ payload }) {
  const { response, error } = yield call(
    services.academy.deleteMember,
    payload
  );

  if (error) {
    yield put({
      type: UPDATE_ACADEMY_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: UPDATE_ACADEMY_SUCCESS, payload: response });
  }
}

function* addView({ payload }) {
  const { response, error } = yield call(services.academy.addView, payload);
  if (error) {
  } else {
    const currentListOfComapnies = yield select(companyList);
    const newListofComapnies = currentListOfComapnies?.map((company) => {
      if (company._id === response._id) {
        return { ...company, ...response };
      }
      return company;
    });
    yield put({
      type: UPDATE_CURRENT_ACADEMY_LIST,
      payload: newListofComapnies,
    });
  }
}
function* dissociateOrganization({ payload }) {
  const { response, error } = yield call(services.academy.dissociated, payload);
  if (error) {
    yield put({
      type: UPDATE_ACADEMY_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: UPDATE_ACADEMY_SUCCESS, payload: response });
    /*   yield put({ type: GET_ALL_ASSOCIATION, payload: null }); */
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<NewAcademy>(NEW_ACADEMY, newAcademy);
  yield takeLatest<GetAllAcademies>(GET_ALL_ACADEMIES, getAllAcademies);
  yield takeLatest<UpdateAcademy>(UPDATE_ACADEMY, updatedAcademy);
  yield takeLatest<UpdateAcademy>(DELETE_ACADEMY, deleteAcademy);
  yield takeLatest<DeleteAcademyMember>(DELETE_ACADEMY_MEMBER, deleteMember);
  yield takeLatest<any>(NEW_INVITE_LINK, createLink);
  yield takeLatest<any>(DELETE_INVITE_LINK, deleteLink);
  yield takeLatest<any>(UPDATE_ACADEMY_ROLE, updatedRole);
  yield takeLatest<any>(GET_ACADEMY, getOneAcademy);
  yield takeLatest<any>(ACADEMY_ADD_VIEW, addView);
  yield takeLatest<AcademyDissociateOrganization>(
    ACADEMY_DISSOCIATE_ORGANIZATION,
    dissociateOrganization
  );
  yield takeLatest<AddAcademyMember>(ADD_ACADEMY_MEMBER, addMember);
  yield takeLatest<SetAcademyMp>(SET_ACADEMY_MP, setAcademyMp);
  yield takeLatest<UpdateAcademyPlan>(UPDATE_ACADEMY_PLAN, updatePlan);
  yield takeLatest<NewAcademyPlan>(NEW_ACADEMY_PLAN, newPlan);
}

/* hacer que cuando cargue la app busque el rol nuevamente */
