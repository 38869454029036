import React from "react";
import Warning from "../../assets/images/warning.png";
const index = () => {
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <img src={Warning} alt="" />
      <h4 style={{ fontSize: "20px" }}>
        Alto ahi!, nos encontramos en construcción
      </h4>
    </div>
  );
};

export default index;
