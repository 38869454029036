import {
  initialState,
  Actions,
  State,
  GET_ALL_EXECUTIVES,
  GET_ALL_EXECUTIVES_ERROR,
  GET_ALL_EXECUTIVES_SUCCESS,
  GET_EXECUTIVE,
  GET_EXECUTIVE_ERROR,
  GET_EXECUTIVE_SUCCESS,
  NEW_EXECUTIVE,
  NEW_EXECUTIVE_ERROR,
  NEW_EXECUTIVE_SUCCESS,
  RESET_NEW_EXECUTIVE,
  UPDATE_EXECUTIVE,
  UPDATE_EXECUTIVE_ERROR,
  UPDATE_EXECUTIVE_SUCCESS,
  RESET_UPDATE_EXECUTIVE,
  DELETE_EXECUTIVE,
  DELETE_EXECUTIVE_ERROR,
  DELETE_EXECUTIVE_SUCCESS,
  RESET_DELETE_EXECUTIVE,
} from "../types/executive";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_EXECUTIVES:
      return {
        ...state,
        allExecutives: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_EXECUTIVES_SUCCESS:
      return {
        ...state,
        allExecutives: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_EXECUTIVES_ERROR:
      return {
        ...state,
        allExecutives: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case GET_EXECUTIVE:
      return {
        ...state,
        executive: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_EXECUTIVE_SUCCESS:
      return {
        ...state,
        executive: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_EXECUTIVE_ERROR:
      return {
        ...state,
        executive: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case NEW_EXECUTIVE:
      return {
        ...state,
        newExecutive: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_EXECUTIVE_SUCCESS:
      return {
        ...state,
        newExecutive: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case NEW_EXECUTIVE_ERROR:
      return {
        ...state,
        newExecutive: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_EXECUTIVE:
      return {
        ...state,
        newExecutive: initialState.newExecutive,
      };
    case DELETE_EXECUTIVE:
      return {
        ...state,
        deleteExecutive: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_EXECUTIVE_SUCCESS:
      return {
        ...state,
        deleteExecutive: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_EXECUTIVE_ERROR:
      return {
        ...state,
        deleteExecutive: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_EXECUTIVE:
      return {
        ...state,
        deleteExecutive: initialState.deleteExecutive,
      };
    case UPDATE_EXECUTIVE:
      return {
        ...state,
        updateExecutive: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_EXECUTIVE_SUCCESS:
      return {
        ...state,
        updateExecutive: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_EXECUTIVE_ERROR:
      return {
        ...state,
        updateExecutive: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_EXECUTIVE:
      return {
        ...state,
        updateExecutive: initialState.updateExecutive,
      };
    default:
      return state;
  }
};

export default reducers;
