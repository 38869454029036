import { Formik } from "formik";
import { AiOutlineSearch } from "react-icons/ai";
import { Input } from "../../../../../../components/StyledComponents";
interface ComponentProps {
  setFilter;
  items?;
}

const Component = ({ setFilter, items }: ComponentProps) => {
  return (
    <div
      style={{
        maxWidth: "700px",
        width: "100%",
        flexGrow: 1,
      }}
    >
      <Formik
        initialValues={{}}
        onSubmit={(values, actions) => {}}
        enableReinitialize
      >
        {({ handleSubmit }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                handleSubmit(event);
              }}
              id="userid-filter-form"
            >
              <div
                style={{
                  position: "relative",
                  border: "1px solid rgb(239 240 246)",
                  borderRadius: "10px",
                  overflow: "hidden",
                  flexGrow: 1,
                }}
              >
                <AiOutlineSearch
                  color="black"
                  fontWeight={900}
                  style={{
                    position: "absolute",
                    left: "10px",
                    top: "15px",
                    zIndex: 2,
                  }}
                ></AiOutlineSearch>
                <Input
                  name="userId"
                  placeholder="Busca por nombre"
                  style={{ height: "45px" }}
                  onChange={(ev) => {
                    setFilter({
                      field: "name",
                      value: ev.target.value !== "" ? ev.target.value : "no",
                      special: (academy, value) => {
                        return academy.name?.includes(value);
                      },
                    });
                  }}
                />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Component;
