import styled from "styled-components";

export const ModalBigTitle = styled("h3")`
  font-size: 18px;
  color: #697482;
  text-align: center;
`;
export const ModalTitleTitle = styled("h3")`
  font-size: 14px;
  color: #697482;
  text-align: center;
`;
