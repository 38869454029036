import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import CrossIcon from "../../../../../../assets/icons/cross.svg";
import Icon from "../../../../../../components/Icon";
import { InLine } from "../../../../../../components/StyledComponents";
import { useRecruitingLayout } from "../../../../../../provider/RecruitingProvider";
import {
  TechnologyDto,
  TechSkillDto,
} from "../../../../../../types/recruiting.dto";
import ShowModal from "../../../components/ShoModal";
import {
  Tab,
  TabTitle,
  Description,
  Content,
  ListTitle,
  List,
  ListItem,
  Delete,
} from "../../../components/StyledComponents";
import actionData from "../../../../../../store/actions/recruiting/tech-skill";
import { useParams } from "react-router-dom";
interface ComponentsProps {
  allTechnologies: TechnologyDto[];
  allSkills: TechSkillDto[];
}

const Component = ({ allTechnologies, allSkills }: ComponentsProps) => {
  const { actions, selectedItem } = useRecruitingLayout();
  const dispatch = useDispatch();
  const params = useParams();
  useEffect(() => {
    !allSkills && dispatch(actionData.getAll());
  }, []);

  return (
    <>
      <InLine justify="space-between">
        <TabTitle>Asignar Skills Tecnicos</TabTitle>
        <ShowModal.AsignarSkill />
      </InLine>

      <Description>
        Podras asignar skills técnicos a esta tecnología. Encontraras skills
        técnicos que ofrece la TAAP pero también podrás encontrar y asignar los
        skills técnicos creados por vos.
      </Description>

      <Content>
        <ListTitle>Skills Técnicos</ListTitle>

        <List>
          {!!allSkills &&
            allSkills
              ?.filter((skill) => skill.active)
              .filter((ability) => ability.team === params.id)
              ?.filter((skill) =>
                selectedItem?.data?.criterions?.includes(skill._id)
              )
              .map((item) => (
                <ListItem>
                  {item.name}{" "}
                  <Delete
                    onClick={() =>
                      dispatch(
                        actions.update({
                          ...selectedItem?.data,
                          criterions: selectedItem?.data?.criterions?.filter(
                            (criterion) => criterion !== item._id
                          ),
                        })
                      )
                    }
                  >
                    <Icon icon={CrossIcon} size="9px" color="black" />
                  </Delete>
                </ListItem>
              ))}
        </List>
      </Content>
    </>
  );
};

const states = ({ recruitingStore }) => {
  const { data: allTechnologies } = recruitingStore.technology.all;
  const { data: allSkills } = recruitingStore.techSkill.all;
  return {
    allTechnologies,
    allSkills,
  };
};

export default connect(states)(Component);
