import { InvitationDto } from "../../types/invitation.dto";
import { PaginationDto } from "../../types/pagination.dto";
export const GET_ALL_INVITATION = "GET_ALL_INVITATION";
export const RESET_GET_ALL_INVITATION = "RESET_GET_ALL_INVITATION";
export const GET_ALL_INVITATION_SUCCESS = "GET_ALL_INVITATION_SUCCESS";
export const GET_ALL_INVITATION_ERROR = "GET_ALL_INVITATION_ERROR";
export const NEW_INVITATION = "NEW_INVITATION";
export const NEW_INVITATION_SUCCESS = "NEW_INVITATION_SUCCESS";
export const NEW_INVITATION_ERROR = "NEW_INVITATION_ERROR";
export const RESET_NEW_INVITATION = "RESET_NEW_INVITATION";
export const NEW_MANY_INVITATION = "NEW_MANY_INVITATION";
export const NEW_MANY_INVITATION_SUCCESS = "NEW_MANY_INVITATION_SUCCESS";
export const NEW_MANY_INVITATION_ERROR = "NEW_MANY_INVITATION_ERROR";
export const RESET_NEW_MANY_INVITATION = "RESET_NEW_MANY_INVITATION";
export const GET_INVITATION = "GET_INVITATION";
export const GET_INVITATION_SUCCESS = "GET_INVITATION_SUCCESS";
export const GET_INVITATION_ERROR = "GET_INVITATION_ERROR";
export const RESET_GET_INVITATION = "RESET_GET_INVITATION";
export const DELETE_INVITATION = "DELETE_INVITATION";
export const DELETE_INVITATION_SUCCESS = "DELETE_INVITATION_SUCCESS";
export const DELETE_INVITATION_ERROR = "DELETE_INVITATION_ERROR";
export const RESET_DELETE_INVITATION = "RESET_DELETE_INVITATION";
export const UPDATE_INVITATION = "UPDATE_INVITATION";
export const UPDATE_INVITATION_SUCCESS = "UPDATE_INVITATION_SUCCESS";
export const UPDATE_INVITATION_ERROR = "UPDATE_INVITATION_ERROR";
export const RESET_UPDATE_INVITATION = "RESET_UPDATE_INVITATION";
export interface GetAllInvitation {
  type: typeof GET_ALL_INVITATION;
  payload: PaginationDto;
}
export interface GetAllInvitationSuccess {
  type: typeof GET_ALL_INVITATION_SUCCESS;
  payload: InvitationDto[];
}
export interface GetAllInvitationError {
  type: typeof GET_ALL_INVITATION_ERROR;
  payload: string | boolean;
}
export interface ResetGetAllInvitation {
  type: typeof RESET_GET_ALL_INVITATION;
  payload: null;
}

export interface NewManyInvitation {
  type: typeof NEW_MANY_INVITATION;
  payload: any;
}

export interface NewManyInvitationSuccess {
  type: typeof NEW_MANY_INVITATION_SUCCESS;
  payload: InvitationDto[];
}

export interface NewManyInvitationError {
  type: typeof NEW_MANY_INVITATION_ERROR;
  payload: string | boolean;
}

export interface ResetManyInvitation {
  type: typeof RESET_NEW_MANY_INVITATION;
  payload: null;
}

export interface GetInvitation {
  type: typeof GET_INVITATION;
  payload: { _id: string };
}

export interface GetInvitationSuccess {
  type: typeof GET_INVITATION_SUCCESS;
  payload: InvitationDto;
}

export interface GetInvitationError {
  type: typeof GET_INVITATION_ERROR;
  payload: string | boolean;
}

export interface ResetGetInvitation {
  type: typeof RESET_GET_INVITATION;
  payload: null;
}

export interface NewInvitation {
  type: typeof NEW_INVITATION;
  payload: any;
}
export interface NewInvitationSuccess {
  type: typeof NEW_INVITATION_SUCCESS;
  payload: null;
}
export interface NewInvitationError {
  type: typeof NEW_INVITATION_ERROR;
  payload: string | boolean;
}
export interface ResetNewInvitation {
  type: typeof RESET_NEW_INVITATION;
  payload: null;
}

export interface DeleteInvitation {
  type: typeof DELETE_INVITATION;
  payload: any;
}
export interface DeleteInvitationSuccess {
  type: typeof DELETE_INVITATION_SUCCESS;
  payload: null;
}
export interface DeleteInvitationError {
  type: typeof DELETE_INVITATION_ERROR;
  payload: string | boolean;
}

export interface ResetDeleteInvitation {
  type: typeof RESET_DELETE_INVITATION;
  payload: null;
}

export interface UpdateInvitation {
  type: typeof UPDATE_INVITATION;
  payload: any;
}
export interface UpdateInvitationSuccess {
  type: typeof UPDATE_INVITATION_SUCCESS;
  payload: null;
}
export interface UpdateInvitationError {
  type: typeof UPDATE_INVITATION_ERROR;
  payload: string | boolean;
}
export interface ResetUpdateInvitation {
  type: typeof RESET_UPDATE_INVITATION;
  payload: null;
}

export interface State {
  all: {
    data: InvitationDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  create: {
    data: null | InvitationDto;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  delete: {
    data: null | InvitationDto;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  newMany: {
    data: null | InvitationDto[];
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  invitation: {
    data: null | InvitationDto;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  update: {
    data: null | InvitationDto;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  all: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  invitation: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  create: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  newMany: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  delete: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  update: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};
export type Actions =
  | GetAllInvitation
  | GetAllInvitationSuccess
  | GetAllInvitationError
  | NewInvitation
  | NewInvitationSuccess
  | NewInvitationError
  | DeleteInvitation
  | DeleteInvitationSuccess
  | DeleteInvitationError
  | ResetDeleteInvitation
  | ResetNewInvitation
  | ResetGetAllInvitation
  | UpdateInvitation
  | UpdateInvitationSuccess
  | UpdateInvitationError
  | ResetUpdateInvitation
  | GetInvitation
  | GetInvitationSuccess
  | GetInvitationError
  | ResetGetInvitation
  | NewManyInvitation
  | NewManyInvitationSuccess
  | NewManyInvitationError
  | ResetManyInvitation;
