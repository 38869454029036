import React, { useContext, useEffect, useState } from "react";
import ModalAssignSearch from "./components/ModalAssignSearch";
import ModalAssignCompany from "./components/ModalAssignCompany";
import ModalAssignTalent from "./components/ModalAssignTalent";
import ModalAssignTechnology from "./components/ModalAssignTechnology";
import ModalAssignSkill from "./components/ModalAssignSkill";
import { connect, useDispatch } from "react-redux";
import { getAllProvinces } from "../../store/actions/statics";
import ModalConfirmDelete from "./components/ModalConfirmDelete";
import actionsAcademy from "../../store/actions/academies";
import actionInvitation from "../../store/actions/invitation";
import contentActions from "../../store/actions/content";
import actionsOrganization from "../../store/actions/organization";
import actionsTeam from "../../store/actions/team";
import { analyticsStudents } from "../../store/actions/analytics";
import { getAllCohorts } from "../../store/actions/cohort";
import actionsData from "../../store/actions/recruiting/index";
import usersActions from "../../store/actions/users";
import { useParams } from "react-router-dom";
import { getAllUnits } from "../../store/actions/unit";
import { getAllEvents } from "../../store/actions/event";
import { getTalents } from "../../store/actions/talent";
import { getAllCertificates } from "../../store/actions/certificates";
import { getAllRequests } from "../../store/actions/request";
import associationActions from "../../store/actions/association";
import { getAllVideos } from "../../store/actions/video";
import { getAllRoadmaps } from "../../store/actions/roadmap";
import { getAllImages } from "../../store/actions/image";
import { getAllSlideShows } from "../../store/actions/slideshow";
import { getAllListens } from "../../store/actions/listen";
declare global {
  interface Document {
    mozFullScreen: any;
    webkitIsFullScreen: any;
    cancelFullScreen;
    mozCancelFullScreen;
    webkitCancelFullScreen;
  }

  interface HTMLElement {
    mozRequestFullScreen: any;
    webkitRequestFullScreen: any;
  }
}

interface ListItem {
  label: string;
  value: string | number;
  data: any;
}

export interface RecruitingLayout {
  title: string;
  icon: string;
  list: ListItem[] | null;
  filteredList?: ListItem[] | null;
  selectedItem?: ListItem | null;
  newProfileModal: string | null;
  externalIds: string[];
  model: string;
  fieldName: string;
  actions: {
    create: (payload: any) => any;
    resetCreate: () => any;
    update: (payload: any) => any;
    resetDeleteOne: () => any;
    resetUpdate: () => any;
    getAll: () => any;
    getOne: (payload: { _id: string }) => any;
    deleteOne: (payload: { _id: string }) => any;
    updateMany?: (payload) => any;
  };
}

interface Store {
  all: {
    data: any[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  selected: {
    data: any[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  update: {
    data: any[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  create: {
    data: any[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  updateMany: {
    data: any[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  delete: {
    data: any[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

const initialStoreValues = {
  all: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  selected: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  update: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  updateMany: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  create: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  delete: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

const initialValues: RecruitingLayout = {
  title: "",
  icon: "",
  externalIds: [],
  list: null,
  fieldName: "",
  filteredList: null,
  selectedItem: null,
  newProfileModal: null,
  model: "",
  actions: {
    create: () => {},
    resetCreate: () => {},
    update: () => {},
    resetUpdate: () => {},
    getAll: () => {},
    resetDeleteOne: () => {},
    getOne: () => {},
    deleteOne: () => {},
  },
};

interface ContexInitialValuesDto extends RecruitingLayout {
  statics: any;
  store: Store;
  filterList: (filter: string) => void;
  selectItem: (selectedItem: ListItem) => void;
  setRecruitingLayout: React.Dispatch<React.SetStateAction<RecruitingLayout>>;
  resetRecruitingLayout: () => void;
}

const contexInitialValues: ContexInitialValuesDto = {
  title: "",
  icon: "",
  fieldName: "",
  list: null,
  filteredList: null,
  selectedItem: null,
  newProfileModal: null,
  externalIds: [],
  statics: {},
  store: initialStoreValues,
  model: "",
  actions: {
    create: () => {},
    resetCreate: () => {},
    update: () => {},
    resetUpdate: () => {},
    getAll: () => {},
    getOne: () => {},
    deleteOne: () => {},
    resetDeleteOne: () => {},
    updateMany: () => {},
  },
  filterList: () => {},
  selectItem: () => {},
  setRecruitingLayout: () => {},
  resetRecruitingLayout: () => {},
};

export const RecruitingLayoutProvidersContext =
  React.createContext(contexInitialValues);

export const useRecruitingLayout = () =>
  useContext(RecruitingLayoutProvidersContext);

interface ComponentProps {
  children;
  recruitingStore;
  staticsStore;
  userStore;
  academyStore;
  cohortStore;
  invitationStore;
  studentStore;
  usersStore;
  organizationStore;
  teamStore;
  analyticsStore;
  contentStore;
  unitStore;
  eventStore;
  talentStore;
  certificateStore;
  requestStore;
  associationStore;
  videoStore;
  roadmapStore;
  imageStore;
  slideshowStore;
  listenStore;
}

const RecruitingLayoutProvider = ({
  children,
  imageStore,
  recruitingStore,
  staticsStore,
  userStore,
  academyStore,
  cohortStore,
  invitationStore,
  studentStore,
  usersStore,
  organizationStore,
  teamStore,
  analyticsStore,
  contentStore,
  unitStore,
  eventStore,
  talentStore,
  certificateStore,
  requestStore,
  associationStore,
  videoStore,
  roadmapStore,
  slideshowStore,
  listenStore,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const [data, setData] = useState<RecruitingLayout>(initialValues);
  const [statics, setStatics] = useState();
  const [store, setStore] = useState<Store>(initialStoreValues);
  const [selectedItem, setSelectedItem] = useState<ListItem | null>(null);
  const [filteredList, setFilteredList] = useState<ListItem[] | null>(null);
  const params = useParams();
  const setRecruitingLayout = (obj) => {
    setData({ ...obj, filteredList: obj.list });

    if (!!obj?.list) {
      const alreadySelectedItem = obj.list.filter(
        (item) => item?.value === selectedItem?.value
      );

      setSelectedItem(
        alreadySelectedItem.length > 0 ? alreadySelectedItem[0] : obj.list[0]
      );
    }
  };

  const resetRecruitingLayout = () => {
    setData(initialValues);
  };

  const filterList = ({ search }) => {
    const filteredListItems = data?.list?.filter((item) => {
      return !search || (!!search && item?.label.includes(search));
    });

    setFilteredList(filteredListItems ? filteredListItems : null);
  };

  const selectItem = (selectedItem) => {
    setSelectedItem(selectedItem);
  };

  useEffect(() => {
    setData({ ...data, filteredList });
  }, [filteredList, teamStore.selected.data, params]);

  useEffect(() => {
    setData({ ...data, selectedItem });
  }, [selectedItem, teamStore.selected.data, params]);

  useEffect(() => {
    dispatch(getAllProvinces("argentina"));
  }, []);

  useEffect(() => {
    setStatics(staticsStore);
  }, [staticsStore]);

  useEffect(() => {
    if (data.model) {
      setStore(recruitingStore[data.model]);
    }
  }, [recruitingStore, data]);

  return (
    <RecruitingLayoutProvidersContext.Provider
      value={{
        ...data,
        statics,
        store,
        filterList,
        selectItem,
        setRecruitingLayout,
        resetRecruitingLayout,
      }}
    >
      {children}
      {/*     <ModalAssignSearch />
      <ModalAssignCompany />
      <ModalAssignTalent />
      <ModalAssignTechnology />
      <ModalAssignSkill />
      <ModalConfirmDelete /> */}
    </RecruitingLayoutProvidersContext.Provider>
  );
};

const states = ({
  recruitingStore,
  staticsStore,
  userStore,
  academyStore,
  cohortStore,
  invitationStore,
  studentStore,
  usersStore,
  organizationStore,
  analyticsStore,
  teamStore,
  contentStore,
  unitStore,
  eventStore,
  certificateStore,
  talentStore,
  requestStore,
  associationStore,
  videoStore,
  roadmapStore,
  imageStore,
  slideshowStore,
  listenStore,
}) => {
  return {
    talentStore,
    organizationStore,
    eventStore,
    imageStore,
    analyticsStore,
    teamStore,
    recruitingStore,
    usersStore,
    studentStore,
    staticsStore,
    userStore,
    academyStore,
    cohortStore,
    invitationStore,
    contentStore,
    unitStore,
    certificateStore,
    requestStore,
    associationStore,
    videoStore,
    roadmapStore,
    slideshowStore,
    listenStore,
  };
};

export default connect(states)(RecruitingLayoutProvider);
