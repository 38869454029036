import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
} from "chart.js";
import { Radar } from "react-chartjs-2";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);
interface LineProps {
  options: ChartOptions<"radar">;
  data: ChartData<"radar">;
}

interface PropsComponent {
  labels: string[];
  data: number[];
}

interface PropsComponent {
  labels: string[];
  data: number[];
}
const Component = ({ labels, data }: PropsComponent) => {
  const Props: LineProps = {
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "top",
          display: false,
        },
      },
      scales: {
        r: {
          min: -5,
          max: 5,
          beginAtZero: true,
          angleLines: {
            display: false,
          },
          ticks: {
            display: false,
            stepSize: 1,
          },
        },
      },
      aspectRatio: 20,
    },
    data: {
      datasets: [
        {
          label: "Datos",
          data: data,
          borderColor: "red",
          backgroundColor: "red",
        },
      ],
      labels: labels,
    },
  };
  return <Radar options={Props.options} data={Props.data}></Radar>;
};

export default Component;
