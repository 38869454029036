import Styled from "styled-components";

export const MainContainer = Styled("div")<{ margin?: number }>`
  ${(props) => {
    const { breakpoints } = props.theme;
    const { margin, gutter, maxWidth } = props.theme.grid;

    let styles = `
      width: 100%;
      padding-right: ${props.margin || margin}px;
      padding-left: ${props.margin || margin}px;
      margin-right: auto;
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: center;

      @media ( max-width: 767px ) {
        padding-right: ${gutter}px;
        padding-left: ${gutter}px;
      }
    `;

    Object.keys(breakpoints).forEach((breakpoint) => {
      styles += `
        @media ( min-width: ${breakpoints[breakpoint]} ) {
          max-width: ${maxWidth[breakpoint]};
        }
      `;
    });

    return styles;
  }}
`;
