import { Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import ReactSelect from "../../../../components/Form/ReactSelect/index";
import Input from "../../../../components/Form/Input/index";
import StatusChart from "./components/Status/index";
import SprintStats from "./components/SprintStats/index";
import Grid from "../../../../components/Grid";
import {
  FlexContainer,
  PageTitle,
} from "../../../../components/StyledComponents";
import Growing from "./components/Growing/index";
import { analyticTypes } from "../../../../constants/analytics-select";
import {
  schema,
  initialValues,
} from "../../../../constants/form/analytics/individual/index";
import { IndividualCard } from "../../../../components/LineGraph/styles";
import { connect, useDispatch } from "react-redux";
import {
  getIndividualStats,
  resetGetIndividualStats,
} from "../../../../store/actions/analytics";
import { toast } from "react-toastify";
import FilterByName from "../../Marketplace/List/components/FilterByName";
import { CurrentTalentValues } from "../../Marketplace/New";
import { CurrentTalent } from "../../Marketplace/List";
import { TalentDto } from "../../../../types/talent.dto";
import {
  getAllCohortStudents,
  getAllCohorts,
  resetGetAllCohortStudents,
  resetGetAllCohortsData,
} from "../../../../store/actions/cohort";
import { addView, getTalents } from "../../../../store/actions/talent";
import { useParams } from "react-router-dom";
import { StateDto } from "../../../../types/states.dto";
import { AnalyticsUserDto } from "../../../../types/analytics.dto";
import { CohortDto } from "../../../../types/cohort.dto";
import { UsersDto } from "../../../../types/users.dto";
import { AcademyDto } from "../../../../types/academy.dto";
import { CurrentDto } from "../../../../types/current.dto";
import { UserDto } from "../../../../types/user.dto";
import { GrowingStageWrapper } from "./components/NumericStats/styles";
import Loader from "../../../../components/Loader";
import usersActions from "../../../../store/actions/users";
import academyActions from "../../../../store/actions/academies";
import { filterCohortByRole } from "../../../../helpers/roleAcademy";
import services from "../../../../services";
const Component = ({
  individualStatsState,
  individualData,
  cohorts,
  aitUsers,
  users,
  user,
  academies,
  current,
  cohortStudentsStates,
  cohortStudents,
}: {
  individualStatsState: StateDto;
  individualData: AnalyticsUserDto[];
  cohorts: CohortDto[];
  aitUsers;
  users: UsersDto[];
  user: UserDto;
  academies: AcademyDto[];
  current: CurrentDto;
  cohortStudentsStates: StateDto;
  cohortStudents: UsersDto[];
}) => {
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const [selectedStudent, setSelectedStudent] = useState<string>();
  const [selectedStudentId, setSelectedStudentId] = useState<{
    name?: string;
    id?: string;
  }>();
  const [studentSelectedForPerformance, setStudentSelectedForPerformance] =
    useState<{
      _id: string;
      cohorts: string[];
    }>();

  const [chartSelected, setChartSelected] = useState<{
    label: string;
    value: string;
  }>();

  const [fetchOn, setFetchOn] = useState<boolean>(false);
  const [cohortSelected, setCohortSelected] = useState<string>();

  const dispatch = useDispatch();
  const params = useParams();
  let formikRef: any = useRef(null);

  const payload = {
    filterBy: {
      active: true,
      hidden: false,
      ...(current.currentSection !== "personal" && { academy: current.id }),
    },
  };

  useEffect(() => {
    if (!academies && current.currentSection === "personal" && fetchOn) {
      dispatch(
        academyActions.getAll({
          filterBy: {
            active: true,
            private: false,
          },
        })
      );
    }
  }, [academies, current, fetchOn]);

  useEffect(() => {
    !cohorts && fetchOn && dispatch(getAllCohorts(payload));
  }, [cohorts, fetchOn]);

  useEffect(() => {
    if (!cohorts && !academies) {
      setFetchOn(true);
    }
  }, [cohorts, academies]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllCohortsData());

      dispatch(academyActions.resetGetAllAcademiesData());
      dispatch(resetGetAllCohortStudents());
    };
  }, []);

  useEffect(() => {
    if (params.id) {
      const fetchData = async () => {
        const response: any = await services.user.getOne(params.id);
        if (response.response) {
          dispatch(
            getIndividualStats({
              _id: response.response._id,
              cohorts: response.response.cohorts,
            })
          );
          setStudentSelectedForPerformance({
            _id: response.response._id,
            cohorts: response.response.cohorts,
          });
          setSelectedStudentId({
            id: response.response._id,
            name: `${response.response.name} ${response.response.lastName}`,
          });
          setCohortSelected(response.response.cohorts[0]);
          setChartSelected({ label: "Status", value: "status" });
          dispatch(
            getAllCohortStudents({
              _id: response.response.cohorts[0] ?? "",
              payload: { role: "STUDENT" },
            })
          );
        }
      };
      fetchData();
    }
  }, [params.id, dispatch]);

  useEffect(() => {
    if (individualStatsState.success) {
      toast.success("👌🏼 User encontrado");
      if (!params.id) {
        setSelectedStudent(selectedStudentId?.name);
      }
      setTimeout(() => {
        formActions?.resetForm();
        dispatch(resetGetIndividualStats());
      }, 1000);
    }

    if (individualStatsState.error) {
      toast.error(individualStatsState.error);
      setTimeout(() => {
        dispatch(resetGetIndividualStats());
      }, 2000);
      setSelectedStudent(undefined);
    }
  }, [individualStatsState]);
  /* OFFSET DEJAR COL VACIAS
    LG largo
  */
  return (
    <>
      <PageTitle>Analytics Individual del Talento</PageTitle>
      <Grid.Row>
        <Grid.Col lg={12}>
          <Formik
            innerRef={(ref) => (formikRef = ref)}
            initialValues={{
              ...initialValues,
              ...(studentSelectedForPerformance && {
                bootcamp: studentSelectedForPerformance.cohorts[0],
                userId: studentSelectedForPerformance._id,
                chart: "status",
              }),
            }}
            onSubmit={(values, actions) => {}}
            enableReinitialize
            validationSchema={schema}
          >
            {({
              handleSubmit,
              errors,
              touched,
              handleChange,
              setFieldValue,
              handleBlur,
              values,
            }) => {
              return (
                <form
                  onSubmit={(event) => {
                    handleSubmit(event);
                  }}
                  id="userid-a-form"
                >
                  <Grid.Row>
                    {current.currentSection !== "academy" && (
                      <Grid.Col lg={3}>
                        <ReactSelect
                          name="academy"
                          error={errors["academy"]}
                          touched={touched["academy"]}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={!academies}
                          items={
                            !!academies &&
                            academies.map((academy) => {
                              return {
                                value: academy._id,
                                label: academy.name,
                              };
                            })
                          }
                          onOptionSelected={(newValue) => {
                            setFieldValue("userId", null);
                            setCohortSelected(undefined);
                            setFieldValue("bootcamp", null);
                            setSelectedStudentId(undefined);
                            dispatch(
                              getAllCohorts({
                                filterBy: {
                                  academy: newValue.value,
                                  hidden: false,
                                  private: false,
                                  active: true,
                                },
                              })
                            );
                          }}
                          options={{
                            label: "Academia:",
                            marginBottom: 0,
                          }}
                        />
                      </Grid.Col>
                    )}

                    <Grid.Col lg={3}>
                      <ReactSelect
                        name="bootcamp"
                        error={errors["bootcamp"]}
                        touched={touched["bootcamp"]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onOptionSelected={(newValue) => {
                          if (newValue) {
                            setSelectedStudentId(undefined);
                            setFieldValue("userId", null);
                            dispatch(
                              getAllCohortStudents({
                                _id: newValue.value,
                                payload: { role: "STUDENT" },
                              })
                            );
                            setCohortSelected(newValue.value);
                          }
                        }}
                        options={{
                          loading: !cohorts,
                          label: "Co-hort:",
                          marginBottom: 0,
                        }}
                        notLoading={true}
                        /* disabled={
                          current.currentSection === "personal" &&
                          !values.academy 
                        } */
                        {...(!!cohorts && {
                          items:
                            current.currentSection === "personal"
                              ? cohorts
                                  .filter((cohort) =>
                                    values["academy"]
                                      ? cohort.academy._id === values["academy"]
                                      : cohort
                                  )
                                  .map((cohort) => {
                                    return {
                                      value: cohort._id,
                                      label: cohort.name,
                                    };
                                  })
                              : filterCohortByRole(
                                  cohorts,
                                  user._id,
                                  current.role
                                ).map((cohort) => {
                                  return {
                                    value: cohort._id,
                                    label: cohort.name,
                                  };
                                }),
                        })}
                      />
                    </Grid.Col>

                    <Grid.Col lg={3}>
                      <ReactSelect
                        name="userId"
                        items={
                          !!cohortStudents &&
                          cohortStudents.map((user) => {
                            return {
                              label: `${user.lastName} ${user.name}`,
                              value: user._id,
                            };
                          })
                        }
                        placeholder={`Selecciona un estudiante`}
                        onOptionSelected={(newValue) => {
                          if (newValue) {
                            const selectedUser = cohortStudents.filter(
                              (user) => user._id === newValue.value
                            )[0];
                            setChartSelected(undefined);
                            setFieldValue("chart", null);
                            dispatch(
                              getIndividualStats({
                                _id: selectedUser._id,
                                cohorts: [cohortSelected],
                              })
                            );
                            setSelectedStudentId({
                              id: selectedUser._id,
                              name: `${selectedUser.lastName} ${selectedUser.name}`,
                            });
                          }
                        }}
                        notLoading={true}
                        disabled={!cohortSelected || !cohortStudents}
                        options={{
                          loading: cohortStudentsStates.loading,
                          label: "Selecciona un usuario",
                          marginBottom: 0,
                        }}
                      />
                    </Grid.Col>

                    <Grid.Col lg={3}>
                      <ReactSelect
                        name="chart"
                        error={errors["chart"]}
                        touched={touched["chart"]}
                        {...(!!individualData &&
                          !!individualData?.find(
                            (data) => data.cohort === values.bootcamp
                          ) &&
                          !!values.bootcamp && {
                            items: [
                              ...analyticTypes(),
                              ...[
                                ...Object.keys(
                                  individualData.filter(
                                    (data) => data.cohort === values.bootcamp
                                  )[0]?.stagesNotes
                                ).map((item) => {
                                  return { label: item, value: item };
                                }),
                              ].map((stage, index) => {
                                return {
                                  value: stage.value,
                                  label: `Etapa ${index + 1}`,
                                };
                              }),
                            ],
                          })}
                        disabled={!values.bootcamp || !selectedStudentId}
                        onChange={handleChange}
                        notLoading
                        onBlur={handleBlur}
                        onOptionSelected={(newValue) =>
                          setChartSelected(newValue)
                        }
                        options={{
                          label: "Tablero",
                          marginBottom: 0,
                        }}
                      ></ReactSelect>
                    </Grid.Col>
                  </Grid.Row>
                </form>
              );
            }}
          </Formik>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row style={{ marginTop: "40px" }}>
        <Grid.Col>
          {individualData &&
          (current.currentSection === "personal" ? true : selectedStudentId) &&
          cohortSelected &&
          individualData.filter((data) => data.cohort === cohortSelected)[0] &&
          chartSelected ? (
            <IndividualCard>
              {chartSelected.value === "crecimiento" && (
                <Growing
                  cohortSelected={cohortSelected}
                  selectedStudent={selectedStudentId?.id}
                ></Growing>
              )}

              {Object.keys(
                individualData.filter(
                  (data) => data.cohort === cohortSelected
                )[0]?.stagesNotes
              ).map((stage, index) => {
                return chartSelected.value === stage ? (
                  <SprintStats
                    cohortSelected={cohortSelected}
                    stage={index + 1}
                    stageLabel={stage}
                    selectedStudent={selectedStudentId?.id}
                  ></SprintStats>
                ) : null;
              })}
              {chartSelected.value === "status" && (
                <StatusChart
                  cohortSelected={cohortSelected}
                  selectedStudent={selectedStudentId?.id}
                ></StatusChart>
              )}
            </IndividualCard>
          ) : (
            <GrowingStageWrapper>
              <FlexContainer
                style={{ width: "100%", height: "100%" }}
                align="center"
                justify="center"
              >
                {individualStatsState.loading ? (
                  <Loader color="Primary"></Loader>
                ) : (
                  "Esperando seleccion"
                )}
              </FlexContainer>
            </GrowingStageWrapper>
          )}
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

const states = ({
  cohortStore,
  analyticsStore,
  recruitingStore,
  usersStore,
  userStore,
  academyStore,
  currentStore,
}) => {
  const { states: individualStatsState, data: individualData } =
    analyticsStore.getIndividualStats;
  const { data: users } = usersStore.all;
  const { data: user } = userStore;
  const { states: cohortsStates, data: cohorts } = cohortStore.allCohorts;
  const { states: cohortStudentsStates, data: cohortStudents } =
    cohortStore.usersByCohort;
  const { data: aitUsers } = recruitingStore.talent.all;
  const { data: academies } = academyStore.all;
  const { data: current } = currentStore;
  return {
    individualStatsState,
    individualData,
    users,
    user,
    cohortsStates,
    cohorts,
    aitUsers,
    academies,
    current,
    cohortStudentsStates,
    cohortStudents,
  };
};

export default connect(states)(Component);
