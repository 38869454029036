import * as Yup from "yup";

export const schema = Yup.object().shape({
  name: Yup.string().required("Ingresa el nombre de tu Co-hort."),
  startDate: Yup.string().when("type", {
    is: "SYNCHRONOUS",
    then: Yup.string().required("Ingresa la fecha de inicio."),
  }),
  picture: Yup.string(),
  classes: Yup.number().min(1).required("Se requieren numero de clases"),
  private: Yup.boolean().required("Se requiere acceso"),
  type: Yup.string().required("Se requiere un tipo"),
  endDate: Yup.string()
    .nullable()
    .when("type", {
      is: "SYNCHRONOUS",
      then: Yup.string().required("Ingresa una fecha de fin."),
    }),
  abilitiesIds: Yup.array()
    .required("Al menos una abilidad")
    .min(1, "Minimo una habilidad")
    .max(1, "Maximo una habilidad"),
  tags: Yup.array(),
  mentorsIds: Yup.array().of(Yup.string()).nullable(),
  hidden: Yup.bool().required(),
  eventsIds: Yup.array().of(Yup.string()).nullable(),
  maxStage: Yup.number().min(1).required(),
});

interface FormValues {
  name: string;
  abilitiesIds: string[];
  type: string;
  classes: number;
  mentorsIds: string[];
  private: boolean;
  startDate: string;
  hidden: boolean;
  endDate: string;
  tags: string[];
  eventsIds: string[];
  picture: string;
  maxStage: number;
}

export const initialValues: FormValues = {
  name: "",
  classes: 1,
  picture: "",
  startDate: "",
  hidden: false,
  endDate: "",
  private: false,
  abilitiesIds: [],
  mentorsIds: [],
  type: "",
  tags: [],
  eventsIds: [],
  maxStage: 1,
};
