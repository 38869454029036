import {
  NewNotification,
  NEW_NOTIFICATION,
  ResetNewNotification,
  RESET_NEW_NOTIFICATION,
  GetAllNotification,
  GET_ALL_NOTIFICATION,
  ResetGetAllNotification,
  RESET_GET_ALL_NOTIFICATION,
  DELETE_NOTIFICATION,
  DeleteNotification,
  RESET_DELETE_NOTIFICATION,
  ResetDeleteNotification,
  UPDATE_NOTIFICATION,
  UpdateNotification,
  RESET_UPDATE_NOTIFICATION,
  ResetUpdateNotification,
  UPDATE_MANY_NOTIFICATIONS,
  UpdateManyNotifications,
  RESET_UPDATE_MANY_NOTIFICATIONS,
} from "../types/notification";

const create = (payload): NewNotification => {
  return { type: NEW_NOTIFICATION, payload };
};
const resetCreate = (): ResetNewNotification => {
  return { type: RESET_NEW_NOTIFICATION, payload: null };
};

const getAll = (payload): GetAllNotification => {
  return { type: GET_ALL_NOTIFICATION, payload };
};

const resetGetAll = (): ResetGetAllNotification => {
  return { type: RESET_GET_ALL_NOTIFICATION, payload: null };
};

const deleteOne = (payload: { _id: string }): DeleteNotification => {
  return { type: DELETE_NOTIFICATION, payload };
};

const updateMany = (payload): UpdateManyNotifications => {
  return { type: UPDATE_MANY_NOTIFICATIONS, payload };
};

const resetDeleteOne = (): ResetDeleteNotification => {
  return { type: RESET_DELETE_NOTIFICATION, payload: null };
};

const update = (payload): UpdateNotification => {
  return { type: UPDATE_NOTIFICATION, payload };
};

const resetUpdate = (): ResetUpdateNotification => {
  return { type: RESET_UPDATE_NOTIFICATION, payload: null };
};

const resetUpdateMany = () => {
  return { type: RESET_UPDATE_MANY_NOTIFICATIONS, payload: null };
};

const actions = {
  create,
  resetGetAll,
  resetCreate,
  resetDeleteOne,
  getAll,
  deleteOne,
  update,
  resetUpdate,
  updateMany,
  resetUpdateMany,
};

export default actions;
