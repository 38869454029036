import {
  NewTechSkill,
  NEW_TECHSKILL,
  ResetNewTechSkill,
  RESET_NEW_TECHSKILL,
  GET_ALL_TECHSKILLS,
  GetAllTechSkills,
  UpdateTechSkill,
  UPDATE_TECHSKILL,
  ResetUpdateTechSkill,
  RESET_UPDATE_TECHSKILL,
  GetTechSkill,
  DeleteTechSkill,
  DELETE_TECHSKILL,
  GET_TECHSKILL,
  RESET_DELETE_TECHSKILL,
} from "../../types/recruiting/tech-skill";

const create = (payload): NewTechSkill => {
  return { type: NEW_TECHSKILL, payload };
};
const resetCreate = (): ResetNewTechSkill => {
  return { type: RESET_NEW_TECHSKILL, payload: null };
};

const update = (payload): UpdateTechSkill => {
  return { type: UPDATE_TECHSKILL, payload };
};

const resetUpdate = (): ResetUpdateTechSkill => {
  return { type: RESET_UPDATE_TECHSKILL, payload: null };
};

const getAll = (): GetAllTechSkills => {
  return { type: GET_ALL_TECHSKILLS, payload: null };
};

const getOne = (payload: { _id: string }): GetTechSkill => {
  return { type: GET_TECHSKILL, payload };
};

const deleteOne = (payload: { _id: string }): DeleteTechSkill => {
  return { type: DELETE_TECHSKILL, payload };
};

const resetDeleteOne = () => {
  return { type: RESET_DELETE_TECHSKILL, payload: null };
};

const actions = {
  create,
  resetCreate,
  resetDeleteOne,
  update,
  resetUpdate,
  getAll,
  getOne,
  deleteOne,
};

export default actions;
