import styled from "styled-components";
import {
  Avatar,
  CardCol,
  CardRow,
  Location,
} from "../../../../Marketplace/components/TalentCard/styles";

export const TalentCard = styled("div")`
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: white;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.16);
  margin-bottom: 1rem;
  padding: ${(props) => (!!props.padding ? props.padding : "3px 22px 5px 3px")};
  @media (min-width: 960px) {
    width: 100%;
    max-width: 760px;
  }
`;

export const TalentCol = styled(CardCol)`
  font-size: 10px;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  width: 100%;
  gap: 4px;
`;

export const TalentRow = styled(CardRow)`
  align-items: center;
`;

export const ListLabel = styled("div")`
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
`;

export const LocationTalent = styled(Location)`
  font-size: 10px;
`;

export const TalentAvatar = styled(Avatar)`
  height: 50px;
  width: 50px;
`;

export const Name = styled("p")`
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: #707070;
`;

export const Profile = styled("p")`
  margin: 0;
  opacity: 0.9;
  font-size: 10px;
`;

export const Label = styled("label")`
  padding: 1px 8px;
  border-radius: 7.5px;
  background-color: #d5f5ff;
  font-size: 10px;
`;
