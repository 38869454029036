import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import Input from "../../../../../../components/Form/Input";
import Submit from "../../../../../../components/Form/Submit";
import Grid from "../../../../../../components/Grid";
import Modal from "../../../../../../components/Modal";
import { Center } from "../../../../../../components/StyledComponents";
import {
  schema,
  initialValues,
} from "../../../../../../constants/form/recruiting/ait/create-search";
import { connect, useDispatch } from "react-redux";
import ReactSelect from "../../../../../../components/Form/ReactSelect";
import Button from "../../../../../../components/Button";
import { hideModal, showModal } from "../../../../../../store/actions/modal";
import { CompanyDto } from "../../../../../../types/recruiting.dto";
import actionsData from "../../../../../../store/actions/recruiting/index";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { UserDto } from "../../../../../../types/user.dto";
interface ComponentProps {
  companies: CompanyDto[];
  searchesStates: {
    success: boolean;
    error: string | boolean;
    loading: boolean;
  };
  user: UserDto;
}

const Index = ({ companies, searchesStates, user }: ComponentProps) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const onSubmit = (values, actions) => {
    const data = !params.id
      ? { external: true, user: user._id }
      : { external: true, team: params.id };
    dispatch(actionsData.search.create({ ...values, ...data }));
    setFormActions(actions);
  };
  useEffect(() => {
    if (searchesStates.success) {
      toast.success("👌🏼 Busqueda creada exitosamente");
      dispatch(hideModal());
      setTimeout(() => {
        dispatch(actionsData.search.resetCreate());
        formActions?.resetForm();
      }, 1000);
    }

    if (searchesStates.error) {
      toast.error(`😱 ${searchesStates.error}`);

      setTimeout(() => {
        dispatch(actionsData.search.resetCreate());
      }, 1000);
    }
  }, [searchesStates]);
  return (
    <>
      <Button
        onClick={() => dispatch(showModal("create-search-modal"))}
        options={{
          type: "outline",
          size: "md",
          skin: "gray",
        }}
      >
        Agregar Busquedas
      </Button>
      <Modal name="create-search-modal" title="Crear Búsqueda" width={"580px"}>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => onSubmit(values, actions)}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={schema}
          enableReinitialize
        >
          {({
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
            setFieldValue,
            isSubmitting,
          }) => {
            return (
              <form
                className="theme-form"
                onSubmit={(event) => {
                  setFormSubmmited(true);
                  handleSubmit(event);
                }}
                id="create-search-form"
              >
                <Grid.Row>
                  <Grid.Col>
                    <Input
                      name="name"
                      modal
                      error={errors["name"]}
                      touched={touched["name"]}
                      value={values["name"]}
                      type="text"
                      placeholder=""
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={{
                        label: "Busqueda",
                        marginBottom: 24,
                      }}
                    />
                    <ReactSelect
                      name="companies"
                      modal
                      isMulti
                      error={errors["companies"]}
                      touched={touched["companies"]}
                      items={
                        !!companies &&
                        companies
                          .filter((company) => company.active)
                          .filter((company) =>
                            !params.id
                              ? !company.team
                              : company.team === params.id
                          )
                          .map((company) => ({
                            label: company.name,
                            value: company._id,
                          }))
                      }
                      placeholder="Seleccionar Empresa"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={{
                        label: "Seleccionar Empresas",
                        marginBottom: 24,
                      }}
                    />
                  </Grid.Col>
                </Grid.Row>
                <Center>
                  <Submit
                    isSubmmiting={isSubmitting}
                    form="create-search-form"
                    color="Primary"
                    options={{
                      type: "filled",
                      skin: "primary",
                      size: "lg",
                      marginBottom: "0px",
                    }}
                  >
                    Confirmar
                  </Submit>
                </Center>
              </form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

const states = ({ recruitingStore, userStore }) => {
  const { data: companies } = recruitingStore.company.all;
  const { states: searchesStates } = recruitingStore.search.create;
  const { data: user } = userStore;
  return {
    companies,
    searchesStates,
    user,
  };
};

export default connect(states)(Index);
