import {
  GET_ALL_TAGS,
  NEW_MANY_TAGS,
  RESET_GET_ALL_TAGS,
  RESET_NEW_MANY_TAGS,
  RESET_UPDATE_MANY_TAGS,
  UPDATE_MANY_TAGS,
} from "../types/tag";

export const getAllTags = (payload) => {
  return { type: GET_ALL_TAGS, payload };
};

export const resetGetAllTags = () => {
  return { type: RESET_GET_ALL_TAGS, payload: null };
};

export const newManyTags = (payload) => {
  return { type: NEW_MANY_TAGS, payload };
};

export const resetNewManyTags = () => {
  return { type: RESET_NEW_MANY_TAGS, payload: null };
};

export const updateManyTags = (payload) => {
  return { type: UPDATE_MANY_TAGS, payload };
};

export const resetUpdateManyTags = () => {
  return { type: RESET_UPDATE_MANY_TAGS, payload: null };
};
