import React, { useEffect, useState } from "react";
import Grid from "../../../../../../components/Grid";
import {
  Center,
  FlexContainer,
  Separator,
} from "../../../../../../components/StyledComponents";
import DinamicField from "../../../../../../components/Form/DinamicField";
import {
  initialValues,
  schema,
} from "../../../../../../constants/form/criterion/editCohorts";
import Submit from "../../../../../../components/Form/Submit";
import Button from "../../../../../../components/Button";
import Modal from "../../../../../../components/Modal";
import { Formik } from "formik";
import { connect } from "react-redux";
import { AcademyDto } from "../../../../../../types/academy.dto";
import { CohortDto } from "../../../../../../types/cohort.dto";
import { CriterionDto } from "../../../../../../types/criterion.dto";
import { CurrentDto } from "../../../../../../types/current.dto";
import { useDispatch } from "react-redux";
import {
  newCriterion,
  updateCriterion,
} from "../../../../../../store/actions/criterion";
import Input from "../../../../../../components/Form/Input";
import { StateDto } from "../../../../../../types/states.dto";
import { hideModal } from "../../../../../../store/actions/modal";

const Component = ({
  academies,
  cohorts,
  criterion,
  criterions,
  current,
  updateCriterionStates,
  newCriterionStates,
  onClose,
}: {
  academies: AcademyDto[];
  cohorts: CohortDto[];
  criterion?: string;
  current: CurrentDto;
  criterions: CriterionDto[];
  updateCriterionStates: StateDto;
  newCriterionStates: StateDto;
  onClose?: any;
}) => {
  const [formSubmmited, setFormSubmmited] = useState<boolean>(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const dispatch = useDispatch();
  const onSubmit = ({ values, actions }) => {
    setFormActions(actions);
    if (!criterion) {
      dispatch(
        newCriterion({
          name: values.name,
          bootcamps: values.cohorts,
          academy: current.id,
        })
      );
    } else {
      dispatch(
        updateCriterion({
          _id: criterion,
          name: values.name,
          bootcamps: values.cohorts,
        })
      );
    }
  };

  useEffect(() => {
    if (updateCriterionStates.success) {
      formActions?.resetForm();
      dispatch(hideModal());
    }
  }, [updateCriterionStates]);
  useEffect(() => {
    if (newCriterionStates.success) {
      formActions?.resetForm();
      dispatch(hideModal());
    }
  }, [newCriterionStates]);

  return (
    <Modal
      name="edit-cohort-criterion"
      title={criterion ? "Editar Criterio" : "Crear Criterio"}
      onClose={onClose}
    >
      <Formik
        initialValues={{
          cohorts: cohorts
            .filter(
              (cohort) =>
                cohort.academy._id === current.id &&
                criterions
                  .find((crit) => crit._id === criterion)
                  ?.bootcamps.includes(cohort._id)
            )
            .map((cohort) => cohort._id),
          name: criterions.find((crit) => crit._id === criterion)?.name || "",
        }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="publish-form"
            >
              <Grid.Row>
                <Grid.Col>
                  <Input
                    name="name"
                    error={errors["name"]}
                    touched={touched["name"]}
                    value={values["name"]}
                    type="text"
                    placeholder="Nombre del Criterio"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      skin: "gray",
                      label: "Nombre del criterio",
                      marginBottom: 20,
                    }}
                  />
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col>
                  <DinamicField
                    name="cohorts"
                    error={errors["cohorts"]}
                    touched={touched["cohorts"]}
                    value={values["cohorts"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      skin: "gray",
                      type: "select",
                      height: "95px",
                      ...(!!cohorts && {
                        data: cohorts
                          ?.filter(
                            (cohort) =>
                              cohort.active && cohort.academy._id === current.id
                          )
                          .map((cohort) => {
                            return { value: cohort._id, label: cohort.name };
                          }),
                      }),
                      loading: !cohorts,
                      inputLabel: "Seleccionar Cohorts (Opcional)",
                      inputPlaceholder: "Selecciona un Cohort",
                    }}
                  />
                </Grid.Col>
              </Grid.Row>
              <Separator size={20}></Separator>
              <Grid.Row>
                <Grid.Col>
                  <Center gap={"10px"}>
                    <Submit
                      isSubmmiting={
                        updateCriterionStates.loading ||
                        newCriterionStates.loading
                      }
                      form="publish-form"
                      color="Primary"
                      style={{ width: "170px" }}
                      options={{
                        type: "filled",
                        skin: "primary",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                    >
                      Confirmar
                    </Submit>
                    <Button
                      onClick={() => dispatch(hideModal())}
                      type="button"
                      options={{
                        type: "outline",
                        skin: "danger",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                      style={{ marginLeft: "10px", width: "170px" }}
                    >
                      Cancelar
                    </Button>
                  </Center>
                </Grid.Col>
              </Grid.Row>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const states = ({ academyStore, currentStore, criterionStore }) => {
  const { data: academies } = academyStore.all;
  const { data: current } = currentStore;
  const { states: updateCriterionStates } = criterionStore.updateCriterion;
  const { states: newCriterionStates } = criterionStore.newCriterion;
  return {
    academies,
    current,
    updateCriterionStates,
    newCriterionStates,
  };
};

export default connect(states)(Component);
