import {
  initialState,
  Actions,
  State,
  GET_ALL_REQUESTS,
  GET_ALL_REQUESTS_ERROR,
  GET_ALL_REQUESTS_SUCCESS,
  GET_REQUEST,
  GET_REQUEST_ERROR,
  GET_REQUEST_SUCCESS,
  NEW_REQUEST,
  NEW_REQUEST_ERROR,
  NEW_REQUEST_SUCCESS,
  RESET_NEW_REQUEST,
  UPDATE_REQUEST,
  UPDATE_REQUEST_ERROR,
  UPDATE_REQUEST_SUCCESS,
  RESET_UPDATE_REQUEST,
  DELETE_REQUEST,
  DELETE_REQUEST_ERROR,
  DELETE_REQUEST_SUCCESS,
  RESET_DELETE_REQUEST,
  UPDATE_MANY_REQUEST,
  UPDATE_MANY_REQUEST_ERROR,
  UPDATE_MANY_REQUEST_SUCCESS,
  RESET_UPDATE_MANY_REQUEST,
  RESET_GET_ALL_REQUESTS_DATA,
} from "../types/request";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_REQUESTS:
      return {
        ...state,
        allRequests: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_REQUESTS_SUCCESS:
      return {
        ...state,
        allRequests: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_REQUESTS_ERROR:
      return {
        ...state,
        allRequests: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_GET_ALL_REQUESTS_DATA:
      return {
        ...state,
        allRequests: initialState.allRequests,
      };
    case GET_REQUEST:
      return {
        ...state,
        category: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_REQUEST_SUCCESS:
      return {
        ...state,
        category: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_REQUEST_ERROR:
      return {
        ...state,
        category: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case NEW_REQUEST:
      return {
        ...state,
        newRequest: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_REQUEST_SUCCESS:
      return {
        ...state,
        newRequest: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case NEW_REQUEST_ERROR:
      return {
        ...state,
        newRequest: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_REQUEST:
      return {
        ...state,
        newRequest: initialState.newRequest,
      };
    case DELETE_REQUEST:
      return {
        ...state,
        deleteRequest: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_REQUEST_SUCCESS:
      return {
        ...state,
        deleteRequest: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_REQUEST_ERROR:
      return {
        ...state,
        deleteRequest: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_REQUEST:
      return {
        ...state,
        deleteRequest: initialState.deleteRequest,
      };
    case UPDATE_REQUEST:
      return {
        ...state,
        updateRequest: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_REQUEST_SUCCESS:
      return {
        ...state,
        updateRequest: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_REQUEST_ERROR:
      return {
        ...state,
        updateRequest: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_REQUEST:
      return {
        ...state,
        updateRequest: initialState.updateRequest,
      };
    case UPDATE_MANY_REQUEST:
      return {
        ...state,
        updateMany: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_MANY_REQUEST_SUCCESS:
      return {
        ...state,
        updateMany: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_MANY_REQUEST_ERROR:
      return {
        ...state,
        updateMany: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_MANY_REQUEST:
      return {
        ...state,
        updateMany: initialState.updateMany,
      };
    default:
      return state;
  }
};

export default reducers;
