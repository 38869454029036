import {
  initialState,
  Actions,
  State,
  GET_AIT,
  GET_AIT_ERROR,
  GET_AIT_SUCCESS,
  UPDATE_AIT,
  UPDATE_AIT_ERROR,
  UPDATE_AIT_SUCCESS,
  RESET_GET_AIT,
  RESET_UPDATE_AIT,
  UPDATE_AIT_TABLE,
  SET_AIT_LIST,
} from "../../types/recruiting/ait";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_AIT:
      return {
        ...state,
        all: {
          data: null,
          states: {
            success: false,
            error: false,
            loading: true,
          },
        },
      };
    case GET_AIT_ERROR:
      return {
        ...state,
        all: {
          data: null,
          states: {
            success: false,
            error: action.payload,
            loading: false,
          },
        },
      };
    case GET_AIT_SUCCESS:
      return {
        ...state,
        all: {
          data: action.payload,
          states: {
            success: true,
            error: false,
            loading: false,
          },
        },
      };
    case UPDATE_AIT:
      return {
        ...state,
        update: {
          states: {
            success: false,
            loading: true,
            error: false,
          },
        },
      };
    case UPDATE_AIT_ERROR:
      return {
        ...state,
        update: {
          states: {
            success: false,
            loading: false,
            error: action.payload,
          },
        },
      };
    case UPDATE_AIT_SUCCESS:
      return {
        ...state,
        update: {
          states: {
            success: true,
            loading: false,
            error: false,
          },
        },
      };
    case RESET_GET_AIT:
      return {
        ...state,
        all: {
          ...state.all,
          states: {
            success: false,
            loading: false,
            error: false,
          },
        },
      };
    case RESET_UPDATE_AIT:
      return {
        ...state,
        update: {
          states: {
            success: false,
            loading: false,
            error: false,
          },
        },
      };
    case UPDATE_AIT_TABLE:
      return {
        ...state,
        all: {
          data: action.payload,
          states: {
            success: false,
            loading: false,
            error: false,
          },
        },
      };
    case SET_AIT_LIST:
      return {
        ...state,
        all: {
          ...state.all,
          data: action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducers;
