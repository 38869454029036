import React, { CSSProperties, ReactElement, ReactNode, useState } from "react";
import Icon from "../Icon";
import {
  Dropdown,
  DropdownToogleButton,
  DropdownBody,
  DropdownItem,
  DropdownButton,
  DropdownAnchor,
} from "./styles";
import DotsIcon from "../../assets/icons/menu-dots.svg";

interface ComponentProps {
  children;
  style?: CSSProperties;
}

const Component = ({ children, style }: ComponentProps) => {
  const [opened, setOpened] = useState<boolean>(false);

  return (
    <Dropdown style={style}>
      <DropdownToogleButton onClick={() => setOpened((state) => !state)}>
        <Icon icon={DotsIcon} size="16px" color="#697482" />
      </DropdownToogleButton>
      <DropdownBody opened={opened} length={children.length}>
        {React.Children.map(children, (child, index) => {
          if (!child) return null;

          return (
            <DropdownItem key={`${index}`}>
              {child.type === "button" && (
                <DropdownButton
                  onClick={(event) => {
                    setOpened(false);
                    child.props.onClick(event);
                  }}
                >
                  {child.props.children}
                </DropdownButton>
              )}

              {child.type === "a" && (
                <DropdownAnchor
                  href={child.props.href}
                  onClick={() => {
                    setOpened(false);
                  }}
                >
                  {child.props.children}
                </DropdownAnchor>
              )}
            </DropdownItem>
          );
        })}
      </DropdownBody>
    </Dropdown>
  );
};

export default Component;
