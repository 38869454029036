import styled from "styled-components";

export const List = styled("ul")`
  margin: 0px;
  padding: 0px;

  &.list {
    padding: 0px;
    margin: 10px 0px;
    width: 100%;
  }

  &.multiLine {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  &:before {
    content: "";
    height: 1px;
    background-color: #707070;
    margin-top: 5px;
    margin-bottom: 15px;
  }
`;

export const Item = styled("li")`
  &.multiLine {
    list-style: none;
    display: flex;
    gap: 5px;
    padding: 3.5px 10.1px 4.5px 13px;
    align-items: center;
    border-radius: 11px;
    background-color: #d5f5ff;
    width: max-content;
    font-size: 12px;

    span {
      display: flex;
    }

    margin: 5px;
  }

  &.list {
    width: 100%;
    list-style: none;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 500;
    color: #334253;
    margin-bottom: 12px;

    span {
      display: flex;
    }
  }
  &.multiLine {
    list-style: none;
    display: flex;
    gap: 5px;
    padding: 1px 8px;
    align-items: center;
    border-radius: 11px;
    background-color: #d5f5ff;
    width: max-content;
    font-size: 12px;
  }
  &.list {
    width: 100%;
    list-style: none;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 500;
    color: #334253;
    margin-bottom: 12px;

    span {
      display: flex;
    }
  }
`;

export const Label = styled("label")`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 15px;
  padding: 0px 20px 7px;
  display: block;
  width: 100%;
  position: relative;

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    background-color: #707070;
  }
`;
