import React from "react";
import { FaCloudUploadAlt } from "react-icons/fa";
import ProgressBar from "../../../../../../components/ProgressBar";
import { VideoUploadLoaderWrapper } from "./style";
import Button from "../../../../../../components/Button";

const Component = ({ loading, height, cancelUploading, iconSize }) => {
  return (
    <VideoUploadLoaderWrapper height={height}>
      <ProgressBar loaded={loading}></ProgressBar>
      <FaCloudUploadAlt size={iconSize}></FaCloudUploadAlt>
      <Button
        onClick={() => {
          cancelUploading();
        }}
        type="button"
        options={{
          type: "filled",
          skin: "danger",
          size: "md",
        }}
      >
        Cancelar la carga
      </Button>
    </VideoUploadLoaderWrapper>
  );
};

export default Component;
