export const GET_ALL_PROVINCES = "GET_ALL_PROVINCES";
export const GET_ALL_PROVINCES_ERROR = "GET_ALL_PROVINCES_ERROR";
export const GET_ALL_PROVINCES_SUCCESS = "GET_ALL_PROVINCES_SUCCESS";

interface OptionDto {
  label: string;
  value: string;
}

export interface GetAllProvinces {
  type: typeof GET_ALL_PROVINCES;
  payload: string;
}

export interface GetAllProvincesSuccess {
  type: typeof GET_ALL_PROVINCES_SUCCESS;
  payload: OptionDto[];
}

export interface GetAllProvincesError {
  type: typeof GET_ALL_PROVINCES_ERROR;
  payload: boolean | string;
}

export interface State {
  allProvinces: {
    data: OptionDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  allProvinces: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllProvinces
  | GetAllProvincesSuccess
  | GetAllProvincesError;
