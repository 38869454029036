import {
  initialState,
  Actions,
  State,
  GET_ALL_PLANS,
  GET_ALL_PLANS_ERROR,
  GET_ALL_PLANS_SUCCESS,
  GET_PLAN,
  GET_PLAN_ERROR,
  GET_PLAN_SUCCESS,
  NEW_PLAN,
  NEW_PLAN_ERROR,
  NEW_PLAN_SUCCESS,
  RESET_NEW_PLAN,
  UPDATE_PLAN,
  UPDATE_PLAN_ERROR,
  UPDATE_PLAN_SUCCESS,
  RESET_UPDATE_PLAN,
  DELETE_PLAN,
  DELETE_PLAN_ERROR,
  DELETE_PLAN_SUCCESS,
  RESET_DELETE_PLAN,
  RESET_GET_ALL_PLANS,
} from "../types/plan";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_PLANS:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_PLANS_SUCCESS:
      return {
        ...state,
        all: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_PLANS_ERROR:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_GET_ALL_PLANS:
      return {
        ...state,
        all: initialState.all,
      };
    case GET_PLAN:
      return {
        ...state,
        plan: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_PLAN_SUCCESS:
      return {
        ...state,
        plan: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_PLAN_ERROR:
      return {
        ...state,
        plan: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case NEW_PLAN:
      return {
        ...state,
        new: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_PLAN_SUCCESS:
      return {
        ...state,
        new: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case NEW_PLAN_ERROR:
      return {
        ...state,
        new: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_PLAN:
      return {
        ...state,
        new: initialState.new,
      };
    case DELETE_PLAN:
      return {
        ...state,
        delete: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_PLAN_SUCCESS:
      return {
        ...state,
        delete: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_PLAN_ERROR:
      return {
        ...state,
        delete: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_PLAN:
      return {
        ...state,
        delete: initialState.delete,
      };
    case UPDATE_PLAN:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_PLAN_SUCCESS:
      return {
        ...state,
        update: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_PLAN_ERROR:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_PLAN:
      return {
        ...state,
        update: initialState.update,
      };
    default:
      return state;
  }
};

export default reducers;
