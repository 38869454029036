import React, { useState } from "react";
import { Tabs, Header, Body, Toggle, Content } from "./styles";

const Component = ({ children }) => {
  const [activeTab, setActiveTab] = useState();

  return (
    <Tabs>
      <Header>
        {React.Children.map(children, (child, index) => {
          if (!child) return null;

          const { id, label } = child.props;

          if (!activeTab && index === 0) {
            setActiveTab(id);
          }

          return (
            !!id && (
              <Toggle
                type="button"
                active={activeTab === id}
                onClick={() => setActiveTab(id)}
              >
                {label}
              </Toggle>
            )
          );
        })}
      </Header>

      <Body>
        {React.Children.map(children, (child) => {
          const { id } = child.props;
          return !!id && <Content active={activeTab === id}>{child}</Content>;
        })}
      </Body>
    </Tabs>
  );
};

export default Component;
