import moment from "moment";
import "moment/dist/locale/es";
moment.locale("es", {
  relativeTime: {
    future: "en %s",
    past: "hace %s",
    s: "hace un segundo",
    ss: "hace %s segundos",
    m: "hace un minuto",
    mm: "hace %d minutos",
    h: "una hora",
    hh: "hace %d horas",
    d: "hace 1 dia",
    dd: "hace %d dias",
    M: "hace un mes",
    MM: "hace %d meses",
    y: "hace un año",
    yy: "hace %d años",
  },
});

export const DateFromNow = (date: Date) => {
  const dateTime = moment(new Date(date)).format("YYYY-MM-DD HH:mm:ss");
  const dateFromNow = moment(dateTime).locale("es").fromNow(true);
  return dateFromNow.charAt(0).toUpperCase() + dateFromNow.slice(1);
};
export const DateEStoDateEN = (date: string) => {
  const sliptDate = date
    .split("/")
    .reverse()
    .map((a) => Number(a));
  return new Date(sliptDate[0], sliptDate[1] - 1, sliptDate[2]).getTime();
};

/* resto uno al meno */
