import * as Yup from "yup";

export const schema = Yup.object().shape({
  unitPrice: Yup.number().when("monetizationStatus", {
    is: true,
    then: Yup.number()
      .required("Se requiere un precio")
      .min(1, "Precio mayor a 0"),
    otherwise: Yup.number(),
  }),

  monetizationStatus: Yup.boolean(),
  isUnique: Yup.boolean(),
});

interface FormValues {
  unitPrice: number;
  monetizationStatus: boolean;
  isUnique: boolean;
}

export const initialValues: FormValues = {
  unitPrice: 0,
  monetizationStatus: true,
  isUnique: false,
};
