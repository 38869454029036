import auth from "./auth";
import category from "./category";
import executive from "./executive";
import criterion from "./criterion";
import subCriterion from "./sub-criterion";
import evaluate from "./evaluate";
import user from "./user";
import talent from "./talent";
import statics from "./statics";
import analytics from "./analytics";
import recruiting from "./recruiting";
import ability from "./ability";
import cohort from "./cohort";
import event from "./event";
import mentor from "./mentor";
import student from "./student";
import technology from "./technology";
import meet from "./meet";
import academy from "./academy";
import organization from "./organization";
import invitation from "./invitation";
import users from "./users";
import team from "./team";
import confirmation from "./confirmation";
import confirmationAuth from "./confirmationAuth";
import content from "./content";
import unit from "./unit";
import certificate from "./certificate";
import request from "./request";
import notification from "./notification";
import association from "./association";
import cdoc from "./cdoc";
import video from "./video";
import roadmap from "./roadmap";
import image from "./image";
import slideshow from "./slideshow";
import listen from "./listen";
import byte from "./byte";
import generalContent from "./generalContent";
import tag from "./tag";
import plan from "./plan";

const services = {
  organization,
  cdoc,
  video,
  confirmationAuth,
  slideshow,
  request,
  unit,
  team,
  tag,
  invitation,
  generalContent,
  byte,
  listen,
  image,
  plan,
  certificate,
  roadmap,
  notification,
  association,
  content,
  users,
  auth,
  meet,
  technology,
  student,
  mentor,
  executive,
  event,
  category,
  ability,
  cohort,
  criterion,
  subCriterion,
  evaluate,
  user,
  talent,
  statics,
  analytics,
  recruiting,
  academy,
  confirmation,
};

export default services;
