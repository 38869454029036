import { put, call, all, takeLatest, select } from "redux-saga/effects";
import services from "../../services";
import {
  GET_SLIDESHOW,
  GET_SLIDESHOW_SUCCESS,
  GET_SLIDESHOW_ERROR,
  GET_ALL_SLIDESHOWS,
  GET_ALL_SLIDESHOWS_SUCCESS,
  GET_ALL_SLIDESHOWS_ERROR,
  NEW_SLIDESHOW,
  NEW_SLIDESHOW_ERROR,
  NEW_SLIDESHOW_SUCCESS,
  NewSlideShow,
  UPDATE_SLIDESHOW,
  UPDATE_SLIDESHOW_SUCCESS,
  UPDATE_SLIDESHOW_ERROR,
  DELETE_SLIDESHOW,
  DELETE_SLIDESHOW_SUCCESS,
  DELETE_SLIDESHOW_ERROR,
  GetSlideShow,
  GetAllSlideShows,
  UpdateSlideShow,
  DeleteSlideShow,
  UploadMedia,
  UPLOAD_MEDIA,
  AddUserToSlideShow,
  ADD_USER_TO_SLIDESHOW,
  SharedSlideShow,
  SHARED_SLIDESHOW,
  UPDATE_MANY_SLIDESHOW,
  UPDATE_MANY_SLIDESHOW_ERROR,
  UPDATE_MANY_SLIDESHOW_SUCCESS,
  UpdateManySlideShow,
} from "../types/slideshow";
import { SlideShowDto } from "../../types/slideshow.dto";

function* updateManySlideshow({ payload }) {
  let updatedList: SlideShowDto[] = [];

  try {
    for (let i = 0; i < payload.items.length; i++) {
      const { _id, ...body } = payload.items[i];
      const formDataInsteadBody = !!body.body ? body.body : body;
      const { response, error } = yield call(services.slideshow.update, {
        _id,
        body: formDataInsteadBody,
      });
      if (!!response) {
        updatedList = [...updatedList, response];
      }
    }

    yield put({ type: UPDATE_MANY_SLIDESHOW_SUCCESS, payload: updatedList });
  } catch (err) {
    yield put({ type: UPDATE_MANY_SLIDESHOW_ERROR, payload: err });
  }
}

function* getAllSlideShows({ payload }) {
  const { response, error } = yield call(services.slideshow.getAll, payload);

  if (error) {
    yield put({
      type: GET_ALL_SLIDESHOWS_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_SLIDESHOWS_SUCCESS, payload: response });
  }
}

function* getSlideShow({ payload }) {
  const { response, error } = yield call(services.slideshow.getOne, payload);

  if (error) {
    yield put({
      type: GET_SLIDESHOW_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_SLIDESHOW_SUCCESS, payload: response });
  }
}

function* newSlideShow({ payload }) {
  const { response, error } = yield call(services.slideshow.new, payload);

  if (error) {
    yield put({
      type: NEW_SLIDESHOW_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: NEW_SLIDESHOW_SUCCESS, payload: response })]);
  }
}

function* updateSlideShow({ payload }) {
  const { response, error } = yield call(services.slideshow.update, payload);
  if (error) {
    yield put({
      type: UPDATE_SLIDESHOW_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_SLIDESHOW_SUCCESS, payload: response })]);
  }
}
function* addUserToSlideShow({ payload }) {
  const { response, error } = yield call(
    services.slideshow.addUserSlideShow,
    payload
  );

  if (error) {
    yield put({
      type: UPDATE_SLIDESHOW_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([
      put({ type: UPDATE_SLIDESHOW_SUCCESS, payload: response }),
      put({ type: GET_ALL_SLIDESHOWS, payload: null }),
    ]);
  }
}

function* uploadMedia({ payload }) {
  const { response, error } = yield call(services.slideshow.update, payload);

  if (error) {
    yield put({
      type: UPDATE_SLIDESHOW_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_SLIDESHOW_SUCCESS, payload: response })]);
  }
}

function* deleteSlideShow({ payload }) {
  const { response, error } = yield call(services.slideshow.delete, payload);

  if (error) {
    yield put({
      type: DELETE_SLIDESHOW_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: DELETE_SLIDESHOW_SUCCESS, payload: response })]);
  }
}

function* shareSlideShow({ payload }) {
  const { response, error } = yield call(
    services.slideshow.shareSlideShow,
    payload
  );

  if (error) {
    yield put({
      type: UPDATE_SLIDESHOW_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_SLIDESHOW_SUCCESS, payload: response })]);
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllSlideShows>(GET_ALL_SLIDESHOWS, getAllSlideShows);
  yield takeLatest<GetSlideShow>(GET_SLIDESHOW, getSlideShow);
  yield takeLatest<NewSlideShow>(NEW_SLIDESHOW, newSlideShow);
  yield takeLatest<UpdateSlideShow>(UPDATE_SLIDESHOW, updateSlideShow);
  yield takeLatest<DeleteSlideShow>(DELETE_SLIDESHOW, deleteSlideShow);
  yield takeLatest<UploadMedia>(UPLOAD_MEDIA, uploadMedia);
  yield takeLatest<AddUserToSlideShow>(
    ADD_USER_TO_SLIDESHOW,
    addUserToSlideShow
  );
  yield takeLatest<SharedSlideShow>(SHARED_SLIDESHOW, shareSlideShow);
  yield takeLatest<UpdateManySlideShow>(
    UPDATE_MANY_SLIDESHOW,
    updateManySlideshow
  );
}
