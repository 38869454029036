import { useState } from "react";
import Icon from "../../../../../components/Icon";
import { Caret, DropdownItem } from "../../../../../components/Menu/styles";
import { List, ListItem, Button, ButtonLink, DropdownMenu } from "./style";
import CaretDown from "../../../../../assets/icons/menu/caret-down.svg";
import Members from "../Members";
import Requests from "../Requests";
import Invitations from "../Invitations";
const Component = ({ setCurrentComponent }) => {
  const [activeDropdown, setActiveDropdown] = useState<string>();
  const [activeDropdownChildren, setActiveDropdownChildren] =
    useState<string>();
  const menu = [
    {
      title: "Academia",
      id: "academy",
      children: [
        {
          title: "Dentro de la Academia",
          id: "inside",
          element: <Members section="academy" />,
        },
        {
          title: "Solicitudes",
          id: "requests",
          element: <Requests.Academy section="academy"></Requests.Academy>,
        },
        {
          title: "Invitaciones",
          id: "invitations",
          element: <Invitations.Academy section="academy" />,
        },
      ],
    },
    {
      title: "Cohorts",
      id: "cohort",
      children: [
        {
          title: "Dentro de Cohorts",
          id: "inside",
          element: <Members section="cohort" />,
        },
        {
          title: "Solicitudes",
          id: "requests",
          element: <Requests.Cohort section="cohort"></Requests.Cohort>,
        },
        {
          title: "Invitaciones",
          id: "invitation",
          element: <Invitations.Cohort section="cohort" />,
        },
      ],
    },
  ];
  return (
    <List>
      {menu.map((menuItem) => {
        const isActiveDropdown =
          menuItem.id === activeDropdown ||
          activeDropdown?.includes(menuItem.id);
        return (
          <>
            <ButtonLink
              onClick={(event) => {
                setActiveDropdown(isActiveDropdown ? "" : menuItem.id);
              }}
              className={isActiveDropdown ? "active" : ""}
            >
              <div>
                <span>{menuItem.title}</span>
              </div>

              <Caret className="caret">
                <Icon icon={CaretDown} color="#697482" size="18px" />
              </Caret>
            </ButtonLink>
            <DropdownMenu
              amountItems={menuItem.children.length}
              isActiveDropdown={isActiveDropdown}
              subChilds={
                activeDropdown?.includes(menuItem.id) &&
                activeDropdown?.length > menuItem.id.length
              }
            >
              {menuItem.children.map((children) => {
                return (
                  <DropdownItem>
                    <ButtonLink
                      style={{ paddingLeft: "12px" }}
                      className={
                        activeDropdownChildren ===
                        `${menuItem.id}/${children.id}`
                          ? "active"
                          : ""
                      }
                      onClick={() => {
                        setCurrentComponent(children.element);
                        setActiveDropdownChildren(
                          `${menuItem.id}/${children.id}`
                        );
                      }}
                    >
                      {children.title}
                    </ButtonLink>
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </>
        );
      })}
    </List>
  );
};

export default Component;
