import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface LineProps {
  options: ChartOptions<"bar">;
  data: ChartData<"bar">;
}

interface PropsComponent {
  labels: string[];
  data: number[];
  barwidth?: number;
}
const Component = ({ labels, data, barwidth }: PropsComponent) => {
  const Props: LineProps = {
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "top",
        },
      },
      scales: {
        yAxis: {
          min: 0,
          max: 5,
          ticks: {
            stepSize: 1,
          },
        },
      },
    },
    data: {
      datasets: [
        {
          label: "Datos",
          data: data,
          borderColor: "red",
          backgroundColor: "red",
          barThickness: !!barwidth ? barwidth : "flex",
        },
      ],
      labels: labels,
    },
  };
  return <Bar options={Props.options} data={Props.data}></Bar>;
};

export default Component;
