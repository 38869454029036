import {
  initialState,
  Actions,
  State,
  GET_ALL_VIDEOS,
  GET_ALL_VIDEOS_ERROR,
  GET_ALL_VIDEOS_SUCCESS,
  GET_VIDEO,
  GET_VIDEO_ERROR,
  GET_VIDEO_SUCCESS,
  NEW_VIDEO,
  NEW_VIDEO_ERROR,
  NEW_VIDEO_SUCCESS,
  RESET_NEW_VIDEO,
  UPDATE_VIDEO,
  UPDATE_VIDEO_ERROR,
  UPDATE_VIDEO_SUCCESS,
  RESET_UPDATE_VIDEO,
  DELETE_VIDEO,
  DELETE_VIDEO_ERROR,
  DELETE_VIDEO_SUCCESS,
  RESET_DELETE_VIDEO,
  UPLOAD_MEDIA,
  UPDATE_VIDEO_LOADING,
  RESET_GET_VIDEO,
  ADD_USER_TO_VIDEO,
  SHARED_VIDEO,
  SET_GET_VIDEO,
  RESET_UPDATE_MANY_VIDEO,
  UPDATE_MANY_VIDEO,
  UPDATE_MANY_VIDEO_ERROR,
  UPDATE_MANY_VIDEO_SUCCESS,
  RESET_GET_ALL_VIDEO_DATA,
} from "../types/video";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_VIDEOS:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_VIDEOS_SUCCESS:
      return {
        ...state,
        all: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_VIDEOS_ERROR:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_GET_ALL_VIDEO_DATA:
      return {
        ...state,
        all: initialState.all,
      };
    case UPLOAD_MEDIA:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };

    case ADD_USER_TO_VIDEO:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_MANY_VIDEO:
      return {
        ...state,
        updateMany: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_MANY_VIDEO_SUCCESS:
      return {
        ...state,
        updateMany: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_MANY_VIDEO_ERROR:
      return {
        ...state,
        updateMany: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_MANY_VIDEO:
      return {
        ...state,
        updateMany: initialState.updateMany,
      };
    case SHARED_VIDEO:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_VIDEO_LOADING:
      return {
        ...state,
        new: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_VIDEO:
      return {
        ...state,
        video: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_VIDEO_SUCCESS:
      return {
        ...state,
        video: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_VIDEO_ERROR:
      return {
        ...state,
        video: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case NEW_VIDEO:
      return {
        ...state,
        new: {
          ...state.new,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_VIDEO_SUCCESS:
      return {
        ...state,
        new: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case SET_GET_VIDEO:
      return {
        ...state,
        video: {
          ...state.video,
          data: !!action.payload ? action.payload : null,
        },
      };
    case RESET_GET_VIDEO:
      return {
        ...state,
        video: {
          ...state.video,
          states: {
            success: false,
            loading: false,
            error: false,
          },
        },
      };
    case NEW_VIDEO_ERROR:
      return {
        ...state,
        new: {
          ...state.new,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_VIDEO:
      return {
        ...state,
        new: {
          data: state.new.data,
          states: {
            loading: false,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_VIDEO:
      return {
        ...state,
        delete: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_VIDEO_SUCCESS:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_VIDEO_ERROR:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_VIDEO:
      return {
        ...state,
        delete: initialState.delete,
      };
    case UPDATE_VIDEO:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_VIDEO_SUCCESS:
      return {
        ...state,
        update: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_VIDEO_ERROR:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_VIDEO:
      return {
        ...state,
        update: initialState.update,
      };
    default:
      return state;
  }
};

export default reducers;
