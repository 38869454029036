import styled from "styled-components";

export const SelectMenuWrapper = styled("div")`
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 4px 0 rgba(0, 30, 108, 0.2);
  background-color: ${(props) => props.theme.colors.White};
`;

export const SelectMenuItem = styled("div")`
  padding: 12px 10px 11px 10px;
  cursor: pointer;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.White};
  box-shadow: 0 0 3px 0 rgba(0, 30, 108, 0.1);
  transition: background-color 0.3s ease-in-out;
  &.active {
    background-color: ${(props) => props.theme.colors.Primary};
  }
`;

export const SelectMenuItemTitle = styled("h3")`
  color: #2a2747;
  transition: color 0.3s ease-in-out;
  &.active {
    color: ${(props) => props.theme.colors.White};
  }
`;
