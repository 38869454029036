import styled from "styled-components";
export const LargeTitle = styled("h2")`
  font-size: 45px;
  line-height: 1;
  color: #001e6c;
  margin: 10px 0 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ListCriterion = styled("ul")`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0;
  min-height: 100px;
  overflow: auto;
`;

export const ListCriterionItem = styled("li")`
  list-style: none;
  font-size: 16px;
  font-weight: 500;
  color: #001e6c;
  border-radius: 10px;
  box-shadow: 0 0 3px 0 rgba(21, 115, 229, 0.16);
  padding: 5px 10px;
  min-width: 255px;
`;
