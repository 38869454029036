import action from "../../helpers/rest-client";

export class ErrorAit extends Error {}

class Ait {
  public update({ _id, ...rest }) {
    return action
      .Patch({
        url: `/ait/${_id}`,
        body: { columns: rest.tables, __v: rest.__v },
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getOne({ _id }) {
    return action
      .Get({
        url: `/ait`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const ait = new Ait();
export default ait;
