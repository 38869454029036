import styled from "styled-components";

export const DaysList = styled("ul")`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0px 0px 20px;
  padding: 0px;

  li {
    list-style: none;
  }
`;

export const Day = styled("li")`
  &:not(:last-child) {
    margin-right: 10px;
  }

  input {
    display: none;
  }

  input:checked + label {
    background-color: ${(props) => props.theme.colors.Primary};
  }
`;

export const Label = styled("label")`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.Gray};
  color: white;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  font-size: 10px;
  font-weight: 500;
  cursor: pointer;
`;
