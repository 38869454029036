import React, { ReactElement, useEffect, useState } from "react";
import Grid from "../../../../components/Grid";
import {
  Button as ManagmentButton,
  CardItems,
  List,
  ListItem,
  Card,
  DataText,
} from "./style";
import { CardColumn, CardRow, CardTitle } from "../Profile/style";
import MembersList from "./components/MembersList";
import ObserverList from "./components/ObserverList";
import PendingList from "./components/PendingList";
import {
  FlexContainer,
  PageTitle,
} from "../../../../components/StyledComponents";
import AcademyInviteModal from "./components/InviteModal";
import OrganizationInviteModal from "../../Organization/Gestion/components/InviteModal";
import { connect, useDispatch } from "react-redux";
import { CohortDto } from "../../../../types/cohort.dto";
import { AcademyDto } from "../../../../types/academy.dto";
import { CurrentDto } from "../../../../types/current.dto";
import { useCurrentLayout } from "../../../../provider/CurrentProvider";
import RequestsList from "./components/RequestsList";
import InvitationList from "./components/InvitationList";
import AssociationsList from "./components/AssociationsList";
import AssociationModal from "./components/AssociationModal";
import { showModal } from "../../../../store/actions/modal";
import Button from "../../../../components/Button";
import NewAcademy from "./components/NewAcademy";
import NewOrganization from "./components/NewOrganization";
import InviteToCohortModal from "./components/InviteToCohortModal";

interface ComponentProps {
  cohorts: CohortDto[];
  academies: AcademyDto[];
  current: CurrentDto;
}

const Component = ({ cohorts, academies, current }: ComponentProps) => {
  const items = [
    {
      label: `Miembros de ${
        current.currentSection === "academy" ? "Academia" : "Organizacion"
      }`,
      value: "members",
      data: {
        description:
          "Los miembros de la Academy pueden acceder a los co-horts y calificar, crear criterios y sub criterios de los co-horts seleccionados. Los Proyect Manager pueden  crear co-horts.",
      },
      Component: (
        <MembersList
          selectedItem={{
            label: `Miembros de ${
              current.currentSection === "academy" ? "Academia" : "Organizacion"
            }`,
            value: "members",
            data: {
              description:
                "Los miembros de la Academy pueden acceder a los co-horts y calificar, crear criterios y sub criterios de los co-horts seleccionados. Los Proyect Manager pueden  crear co-horts.",
            },
          }}
        ></MembersList>
      ),
    },
    {
      Component: (
        <ObserverList
          selectedItem={{
            label: "Observadores",
            value: "observer",
            data: {
              description:
                "Los Observadores de Academy tendrán acceso solo a la sección de Analytics y Marketplace de tu Academy y podrán ver los co-horts que le has asignado.",
            },
          }}
        ></ObserverList>
      ),
      label: "Observadores",
      value: "observer",
      data: {
        description:
          "Los Observadores de Academy tendrán acceso solo a la sección de Analytics y Marketplace de tu Academy y podrán ver los co-horts que le has asignado.",
      },
    },
    {
      Component: (
        <PendingList
          selectedItem={{
            label: "Pendientes",
            value: "pendings",
            data: {
              description: "Las invitaciones pendientes",
            },
          }}
        ></PendingList>
      ),
      label: "Pendientes",
      value: "pendings",
      data: {
        description: "Las invitaciones pendientes",
      },
    },
    {
      Component: (
        <RequestsList
          selectedItem={{
            label: "Solicitudes",
            value: "requests",
            data: {
              description: "Solicitudes pendientes",
            },
          }}
        ></RequestsList>
      ),
      label: "Solicitudes",
      value: "requests",
      data: {
        description: "Solicitudes pendientes",
      },
    },
    /*  {
      Component: (
        <AssociationsList
          selectedItem={{
            label: "Asociaciones",
            value: "associations",
            data: {
              description: "Asociaciones pendientes",
            },
          }}
        ></AssociationsList>
      ),
      label: "Asociaciones",
      value: "associations",
      data: {
        description: "Asociaciones pendientes",
      },
    }, */
  ];
  const profileItems = [
    {
      label: "Solicitudes",
      value: "requests",
      data: {
        description: "Solicitudes pendientes.",
      },
      Component: (
        <RequestsList
          selectedItem={{
            label: "Solicitudes",
            value: "requests",
            data: {
              description: "Solicitudes pendientes.",
            },
          }}
        ></RequestsList>
      ),
    },
    {
      Component: (
        <InvitationList
          selectedItem={{
            label: "Invitaciones",
            value: "invitations",
            data: {
              description: "Las invitaciones pendientes",
            },
          }}
        ></InvitationList>
      ),
      label: "Invitaciones",
      value: "invitations",
      data: {
        description: "Las invitaciones pendientes",
      },
    },
  ];
  const [selectedItem, setSelectedItem] = useState<any>(
    current.currentSection === "personal" ? profileItems[0] : items[0]
  );
  const { data } = useCurrentLayout();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!!data?.getOne) {
      dispatch(data.getOne(current.id));
    }
  }, [data]);
  return (
    <>
      <FlexContainer
        style={{ width: "100%" }}
        justify="space-between"
        align="center"
      >
        <PageTitle>Gestión</PageTitle>
        {current.currentSection === "personal" && (
          <FlexContainer gap="10px">
            <Button
              onClick={() => {
                dispatch(showModal("new-academy-modal"));
              }}
              type="button"
              options={{
                type: "filled",
                skin: "primary",
                size: "md",
                marginBottom: "10px",
              }}
              style={{ marginLeft: "10px" }}
            >
              Crear Academia
            </Button>
            {     <Button
              onClick={() => {
                dispatch(showModal("new-organization-modal"));
              }}
              type="button"
              options={{
                type: "filled",
                skin: "primary",
                size: "md",
                marginBottom: "10px",
              }}
              style={{ marginLeft: "10px" }}
            >
              Crear Organizacion
            </Button> }
          </FlexContainer>
        )}
      </FlexContainer>
      <Grid.Row style={{ height: "calc(100% - 59px)" }}>
        <Grid.Col lg={4} style={{ marginBottom: "10px" }}>
          <CardItems style={{ padding: "10px" }}>
            <CardTitle>
              {current.currentSection === "personal" ? "" : "Miembros"}
            </CardTitle>
            <List>
              {[
                ...(current.currentSection === "personal"
                  ? profileItems
                  : items),
              ].map((item) => (
                <ListItem>
                  <ManagmentButton
                    onClick={() => {
                      setSelectedItem(item);
                    }}
                    active={selectedItem.value === item.value}
                  >
                    {item.label}
                  </ManagmentButton>
                </ListItem>
              ))}
            </List>
          </CardItems>
        </Grid.Col>
        <Grid.Col>
          <Card>
            <CardColumn gap="21px">{selectedItem.Component}</CardColumn>
          </Card>
        </Grid.Col>
      </Grid.Row>

      <OrganizationInviteModal />
      <AssociationModal></AssociationModal>
      <InviteToCohortModal></InviteToCohortModal>
      <AcademyInviteModal />
      {current.currentSection === "personal" && <NewAcademy></NewAcademy>}
      {current.currentSection === "personal" && (
        <NewOrganization></NewOrganization>
      )}
    </>
  );
};

const states = ({ cohortStore, academyStore, currentStore }) => {
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: academies } = academyStore.all;
  const { data: current } = currentStore;
  return {
    cohorts,
    academies,
    current,
  };
};

export default connect(states)(Component);
