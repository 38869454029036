import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Modal from "../../../../../../components/Modal";
import { Formik } from "formik";
import { Center } from "../../../../../../components/StyledComponents";
import {
  initialValues,
  schema,
} from "../../../../../../constants/form/cdoc/addUser";
import Grid from "../../../../../../components/Grid";
import Button from "../../../../../../components/Button";
import Submit from "../../../../../../components/Form/Submit";
import { toast } from "react-toastify";
import { hideModal } from "../../../../../../store/actions/modal";

import { StateDto } from "../../../../../../types/states.dto";
import {
  addUserToCdoc,
  resetUpdateCDOCs,
  updateCDOCs,
} from "../../../../../../store/actions/cdoc";
import { CDocDto } from "../../../../../../types/cdoc.dto";

const Component = ({
  updateStates,
  cdoc,
}: {
  updateStates: StateDto;
  cdoc: CDocDto;
}) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const dispatch = useDispatch();

  const onSubmit = ({ values, actions }) => {
    dispatch(
      updateCDOCs({
        ...cdoc,
        published: !cdoc.published,
        available: !cdoc.published,
        text: JSON.stringify(cdoc.text),
      })
    );

    setFormActions(actions);
  };

  useEffect(() => {
    if (updateStates.success) {
      setTimeout(() => {
        dispatch(hideModal());
        formActions?.resetForm();
      }, 1000);
    }

    if (updateStates.error) {
      toast.error(updateStates.error);
      setTimeout(() => {
        dispatch(resetUpdateCDOCs());
      }, 1000);
    }
  }, [updateStates]);

  return (
    <Modal
      name="private-cdoc"
      title={`¿Seguro quieres cambiar el status del contenido a ${
        !!cdoc.published ? "Privado" : "Publico"
      }?`}
      description={
        !!cdoc.published
          ? `El CDoc se esconderá en todos los lugares en donde esta publicado. Ningún usuario podrá acceder a el.`
          : `El CDoc se mostrara en todos los lugares en donde esta publicado. Los usuarios podran acceder.`
      }
    >
      <Formik
        initialValues={{}}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="invite-academy-modal-form"
            >
              <Grid.Row>
                <Grid.Col>
                  <Center gap={"10px"}>
                    <Submit
                      isSubmmiting={isSubmitting}
                      form="invite-academy-modal-form"
                      color="Primary"
                      style={{ width: "170px" }}
                      options={{
                        type: "filled",
                        skin: "primary",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                    >
                      Confirmar
                    </Submit>
                  </Center>
                </Grid.Col>
              </Grid.Row>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const states = ({ cdocStore }) => {
  const { states: updateStates } = cdocStore.update;
  return { updateStates };
};

export default connect(states)(Component);
