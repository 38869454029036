import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { CurrentDto } from "../../types/current.dto";
import academyActions from "../../store/actions/academies";
import organizationActions from "../../store/actions/organization";
import usersActions from "../../store/actions/users";
import { setCurrent } from "../../store/actions/current";
import { AcademyDto } from "../../types/academy.dto";
import services from "../../services";
declare global {
  interface Document {
    mozFullScreen: any;
    webkitIsFullScreen: any;
    cancelFullScreen;
    mozCancelFullScreen;
    webkitCancelFullScreen;
  }

  interface HTMLElement {
    mozRequestFullScreen: any;
    webkitRequestFullScreen: any;
  }
}

export interface RecruitingLayout {
  create?: (data: any) => any;
  resetCreate?: () => any;
  update?: (data: any) => any;
  resetUpdate?: () => any;
  getAll?: () => any;
  getOne?: (data: any) => any;
  deleteOne?: (data: any) => any;
  resetDeleteOne?: () => any;
  newLink?: (data: any) => any;
  resetNewLink?: () => any;
  deleteLink?: (data: any) => any;
  resetDeleteLink?: () => any;
  selectOne?: (data: any) => any;
  deleteMember?: (data: any) => any;
  updateRole?: (data: any) => any;
}

const initialValues: RecruitingLayout = {
  create: () => {},
  resetCreate: () => {},
  update: () => {},
  resetUpdate: () => {},
  getAll: () => {},
  getOne: () => {},
  deleteOne: () => {},
  resetDeleteOne: () => {},
  newLink: () => {},
  resetNewLink: () => {},
  deleteLink: () => {},
  resetDeleteLink: () => {},
  selectOne: () => {},
  deleteMember: () => {},
  updateRole: () => {},
};

interface A {
  data: RecruitingLayout | null;
  setData: any;
}

const contexInitialValues: A = {
  data: null,
  setData: null,
};

export const CurrentLayoutProvidersContext =
  React.createContext(contexInitialValues);

export const useCurrentLayout = () => useContext(CurrentLayoutProvidersContext);

interface ComponentProps {
  children;
  current: CurrentDto;
  academyStore;
  organizationStore;
  usersStore;
  user;
  updateAcademyStates;
  updatedAcademy;
}

const RecruitingLayoutProvider = ({
  children,
  current,
  academyStore,
  organizationStore,
  usersStore,
  user,
  updateAcademyStates,
  updatedAcademy,
}: ComponentProps) => {
  const [data, setData] = useState<RecruitingLayout | null>(null);
  const [currentAcademies, setCurrentAcademies] = useState<AcademyDto[]>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!current) {
      dispatch(
        setCurrent({
          currentSection: "personal",
          role: "USER",
          id: user?.data?._id || "",
        })
      );
    }
  }, []);

  useEffect(() => {
    if (currentAcademies) {
      const currentAcademy = currentAcademies.find((academy) =>
        academy.users.find(
          (userFounded) => !userFounded.roleEnd && userFounded.user === user
        )
      );
      if (current.currentSection === "academy" && currentAcademy) {
        setCurrent({
          currentSection: "academy",
          id: currentAcademy._id,
          role:
            currentAcademy.users.find(
              (userFounded) =>
                !userFounded.roleEnd && userFounded.user === user._id
            )?.role ?? "OBSERVER",
        });
      }
    }
  }, [currentAcademies]);

  useEffect(() => {
    if (user) {
      const fetchData = async () => {
        const response: any = await services.academy.getAll({
          filterBy: {
            users: {
              $elemMatch: {
                user: user._id,
                roleEnd: { $exists: false },
              },
            },
          },
        });
        setCurrentAcademies(response.response);
      };
      fetchData();
    }
  }, [user]);

  useEffect(() => {
    if (updateAcademyStates.success) {
      setCurrentAcademies((state) =>
        state!.map((st) =>
          st._id === updatedAcademy._id ? st : updatedAcademy
        )
      );
    }
  }, [updateAcademyStates]);

  return (
    <CurrentLayoutProvidersContext.Provider
      value={{
        data,
        setData,
      }}
    >
      {children}
    </CurrentLayoutProvidersContext.Provider>
  );
};

const states = ({
  currentStore,
  academyStore,
  organizationStore,
  usersStore,
  userStore,
}) => {
  const { states: updateAcademyStates, data: updatedAcademy } =
    academyStore.update;
  return {
    current: currentStore.data,
    academyStore,
    organizationStore,
    usersStore,
    user: userStore.data,
    updatedAcademy,
    updateAcademyStates,
  };
};

export default connect(states)(RecruitingLayoutProvider);
