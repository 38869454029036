import { put, call, all, takeLatest } from "redux-saga/effects";
import services from "../../services";
import {
  GET_EXECUTIVE,
  GET_EXECUTIVE_SUCCESS,
  GET_EXECUTIVE_ERROR,
  GET_ALL_EXECUTIVES,
  GET_ALL_EXECUTIVES_SUCCESS,
  GET_ALL_EXECUTIVES_ERROR,
  NEW_EXECUTIVE,
  NEW_EXECUTIVE_ERROR,
  NEW_EXECUTIVE_SUCCESS,
  UPDATE_EXECUTIVE,
  UPDATE_EXECUTIVE_SUCCESS,
  UPDATE_EXECUTIVE_ERROR,
  DELETE_EXECUTIVE,
  DELETE_EXECUTIVE_SUCCESS,
  DELETE_EXECUTIVE_ERROR,
  NewExecutive,
  GetExecutive,
  GetAllExecutives,
  UpdateExecutive,
  DeleteExecutive,
} from "../types/executive";

function* getAllExecutives() {
  const { response, error } = yield call(services.executive.getAll);

  if (error) {
    yield put({
      type: GET_ALL_EXECUTIVES_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_EXECUTIVES_SUCCESS, payload: response });
  }
}

function* getExecutive({ payload }) {
  const { response, error } = yield call(services.executive.getOne, payload);

  if (error) {
    yield put({
      type: GET_EXECUTIVE_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_EXECUTIVE_SUCCESS, payload: response });
  }
}

function* newExecutive({ payload }) {
  const { response, error } = yield call(services.executive.new, payload);

  if (error) {
    yield put({
      type: NEW_EXECUTIVE_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([
      put({ type: NEW_EXECUTIVE_SUCCESS, payload: response }),
      put({ type: GET_ALL_EXECUTIVES, payload: null }),
    ]);
  }
}

function* updateExecutive({ payload }) {
  const { response, error } = yield call(services.executive.update, payload);

  if (error) {
    yield put({
      type: UPDATE_EXECUTIVE_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([
      put({ type: UPDATE_EXECUTIVE_SUCCESS, payload: response }),
      put({ type: GET_ALL_EXECUTIVES, payload: null }),
    ]);
  }
}

function* deleteExecutive({ payload }) {
  const { response, error } = yield call(services.executive.delete, payload);

  if (error) {
    yield put({
      type: DELETE_EXECUTIVE_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([
      put({ type: DELETE_EXECUTIVE_SUCCESS, payload: response }),
      put({ type: GET_ALL_EXECUTIVES, payload: null }),
    ]);
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllExecutives>(GET_ALL_EXECUTIVES, getAllExecutives);
  yield takeLatest<GetExecutive>(GET_EXECUTIVE, getExecutive);
  yield takeLatest<UpdateExecutive>(UPDATE_EXECUTIVE, updateExecutive);
  yield takeLatest<DeleteExecutive>(DELETE_EXECUTIVE, deleteExecutive);
  yield takeLatest<NewExecutive>(NEW_EXECUTIVE, newExecutive);
}
