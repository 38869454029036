import * as Yup from "yup";

interface InitialValues {
  name: string;
  companies: string[];
}

export const schema = Yup.object().shape({
  name: Yup.string().required().min(7, "Al menos 7 caracteres"),
  companies: Yup.array().required().min(1, "Al menos una empresa"),
});

export const initialValues: InitialValues = {
  name: "",
  companies: [],
};
