import { put, call, all, takeLatest } from "redux-saga/effects";
import services from "../../services";
import {
  GET_CONFIRMATION,
  GET_CONFIRMATION_SUCCESS,
  GET_CONFIRMATION_ERROR,
  UPDATE_CONFIRMATION,
  UPDATE_CONFIRMATION_SUCCESS,
  UPDATE_CONFIRMATION_ERROR,
  GetConfirmation,
  UpdateConfirmation,
} from "../types/confirmation";

function* getConfirmation({ payload }) {
  const { response, error } = yield call(services.confirmation.getOne, payload);

  if (error) {
    yield put({
      type: GET_CONFIRMATION_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_CONFIRMATION_SUCCESS, payload: response });
  }
}

function* updateConfirmation({ payload }) {
  const { response, error } = yield call(services.confirmation.update, payload);

  if (error) {
    yield put({
      type: UPDATE_CONFIRMATION_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_CONFIRMATION_SUCCESS, payload: response })]);
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetConfirmation>(GET_CONFIRMATION, getConfirmation);
  yield takeLatest<UpdateConfirmation>(UPDATE_CONFIRMATION, updateConfirmation);
}
