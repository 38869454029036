import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Modal from "../../../../../../components/Modal";
import { Formik } from "formik";
import { Center } from "../../../../../../components/StyledComponents";
import {
  initialValues,
  schema,
} from "../../../../../../constants/form/unit/create";
import Grid from "../../../../../../components/Grid";
import Button from "../../../../../../components/Button";
import Submit from "../../../../../../components/Form/Submit";
import { toast } from "react-toastify";
import Input from "../../../../../../components/Form/Input";
import organizationActions from "../../../../../../store/actions/organization";
import { hideModal } from "../../../../../../store/actions/modal";
import { newUnit, resetNewUnit } from "../../../../../../store/actions/unit";
const Component = ({ cohort, createUnitStates }) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const dispatch = useDispatch();

  const onSubmit = ({ values, actions }) => {
    dispatch(newUnit({ ...values, cohort: cohort._id }));
    setFormActions(actions);
  };

  useEffect(() => {
    if (createUnitStates.success) {
      toast("👌🏼 Unidad Creada", {
        containerId: "submit",
        type: "success",
        toastId: "submitsucceful",
      });

      setTimeout(() => {
        dispatch(resetNewUnit());
        dispatch(hideModal());
        formActions?.resetForm();
      }, 1000);
    }

    if (createUnitStates.error) {
      toast(`😱 ${createUnitStates.error}`, {
        containerId: "submit",
        type: "error",
        toastId: "submiterror",
      });

      setTimeout(() => {
        dispatch(resetNewUnit());
      }, 1000);
    }
  }, [createUnitStates]);

  return (
    <Modal name="add-unit-cohort" title="Crear Unidad">
      <Formik
        initialValues={initialValues({ name: "" })}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="edit-organization-data-form"
            >
              <Grid.Row>
                <Grid.Col>
                  <Input
                    name={"name"}
                    error={errors["name"]}
                    touched={touched["name"]}
                    value={values["name"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{ label: "Nombre", skin: "gray" }}
                  />
                </Grid.Col>
              </Grid.Row>

              <Grid.Row>
                <Grid.Col>
                  <Center gap={"70px"}>
                    <Button
                      onClick={resetForm}
                      type="button"
                      options={{
                        type: "outline",
                        skin: "gray",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                      style={{ marginLeft: "10px" }}
                    >
                      Cancelar
                    </Button>

                    <Submit
                      isSubmmiting={createUnitStates.loading}
                      form="edit-organization-data-form"
                      color="Primary"
                      options={{
                        type: "filled",
                        skin: "primary",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                    >
                      Confirmar
                    </Submit>
                  </Center>
                </Grid.Col>
              </Grid.Row>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const states = ({ unitStore }) => {
  const { states: createUnitStates } = unitStore.newUnit;

  return {
    createUnitStates,
  };
};

export default connect(states)(Component);
