import Icon from "../../../Icon";
import { Caret } from "../../../Menu/styles";
import { InLine } from "../../../StyledComponents";
import CaretDown from "../../../../assets/icons/menu/caret-down.svg";
import Button from "../../../../components/Button/index";
import SubMenuApp from "../SubMenuApp";
import { MarkerWrapper } from "../../styles";
import { useEffect, useRef } from "react";

interface ComponentProps {
  isActive?: boolean;
  linkLabel: string;
}

const Component = ({ linkLabel, isActive }: ComponentProps) => {
  return (
    <MarkerWrapper className={isActive ? "active" : ""}>
      <Button
        type="button"
        options={{
          type: "link",
          skin: "gray",
          size: "sm",
          marginBottom: "0px",
        }}
        style={{ marginLeft: "10px" }}
      >
        {linkLabel}
      </Button>
      <Caret className="caret">
        <Icon icon={CaretDown} color="gray" size="18px" />
      </Caret>
    </MarkerWrapper>
  );
};

export default Component;
