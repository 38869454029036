import React, { useEffect, useState } from "react";
import Grid from "../../../../../../components/Grid";
import {
  Center,
  FlexContainer,
  Separator,
} from "../../../../../../components/StyledComponents";
import DinamicField from "../../../../../../components/Form/DinamicField";
import {
  initialValues,
  schema,
} from "../../../../../../constants/form/sub-criterion/editSubCriterionCriterions";
import Submit from "../../../../../../components/Form/Submit";
import Button from "../../../../../../components/Button";
import Modal from "../../../../../../components/Modal";
import { Formik } from "formik";
import { connect } from "react-redux";
import { AcademyDto } from "../../../../../../types/academy.dto";
import { CohortDto } from "../../../../../../types/cohort.dto";
import { CriterionDto } from "../../../../../../types/criterion.dto";
import { CurrentDto } from "../../../../../../types/current.dto";
import { useDispatch } from "react-redux";
import {
  newCriterion,
  updateCriterion,
} from "../../../../../../store/actions/criterion";
import Input from "../../../../../../components/Form/Input";
import { StateDto } from "../../../../../../types/states.dto";
import { hideModal } from "../../../../../../store/actions/modal";
import { SubCriterionDtoApi } from "../../../../../../types/sub-criterion.dto";
import {
  newManySubCriterion,
  newSubCriterion,
  updateManySubCriterion,
  updateSubcriterion,
} from "../../../../../../store/actions/sub-criterion";
import ReactSelect from "../../../../../../components/Form/ReactSelect";

const Component = ({
  academies,
  criterions,
  subCriterion,
  current,
  updateCriterionStates,
  newCriterionStates,
  onClose,
  cohorts,
  updateSubCriterionStates,
  newSubCriterionStates,
}: {
  academies: AcademyDto[];
  criterions: CriterionDto[];
  subCriterion?: SubCriterionDtoApi;
  current: CurrentDto;
  updateCriterionStates: StateDto;
  newCriterionStates: StateDto;
  onClose?: any;
  cohorts: CohortDto[];
  updateSubCriterionStates: StateDto;
  newSubCriterionStates: StateDto;
}) => {
  const [formSubmmited, setFormSubmmited] = useState<boolean>(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const dispatch = useDispatch();
  const onSubmit = ({ values, actions }) => {
    setFormActions(actions);

    if (!subCriterion) {
      dispatch(newSubCriterion(values));
    } else {
      dispatch(updateSubcriterion(values));
    }
  };

  useEffect(() => {
    if (updateSubCriterionStates.success) {
      formActions?.resetForm();
      dispatch(hideModal());
    }
  }, [updateSubCriterionStates, dispatch]);
  useEffect(() => {
    if (newSubCriterionStates.success) {
      formActions?.resetForm();
      dispatch(hideModal());
    }
  }, [newSubCriterionStates, dispatch]);

  return (
    <Modal
      name="edit-cohort-subcriterion"
      title={subCriterion ? "Editar Subcriterio" : "Crear Subcriterio"}
      onClose={onClose}
    >
      <Formik
        initialValues={{
          criterion: subCriterion?.criterion,
          name: subCriterion?.name,
        }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="publish-form"
            >
              <Grid.Row>
                <Grid.Col>
                  <Input
                    name="name"
                    error={errors["name"]}
                    touched={touched["name"]}
                    value={values["name"]}
                    type="text"
                    placeholder="Nombre del Criterio"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      skin: "gray",
                      label: "Nombre del Subcriterio",
                      marginBottom: 20,
                    }}
                  />
                </Grid.Col>
              </Grid.Row>

              <Grid.Row>
                <Grid.Col>
                  <ReactSelect
                    name="criterion"
                    error={errors["criterion"]}
                    touched={touched["criterion"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    items={criterions
                      ?.filter(
                        (criterion) =>
                          criterion.active &&
                          !!cohorts
                            .filter((cohort) =>
                              criterion.bootcamps.includes(cohort._id)
                            )
                            .find((cohort) => cohort.academy._id === current.id)
                      )
                      .map((criterion) => {
                        return {
                          value: criterion._id,
                          label: criterion.name,
                        };
                      })}
                    options={{
                      skin: "gray",
                      label: "Criterio",
                      loading: !criterions || !cohorts,
                    }}
                  />
                </Grid.Col>
              </Grid.Row>
              <Separator size={20}></Separator>
              <Grid.Row>
                <Grid.Col>
                  <Center gap={"10px"}>
                    <Submit
                      isSubmmiting={
                        updateSubCriterionStates.loading ||
                        newSubCriterionStates.loading
                      }
                      form="publish-form"
                      color="Primary"
                      style={{ width: "170px" }}
                      options={{
                        type: "filled",
                        skin: "primary",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                    >
                      Confirmar
                    </Submit>
                    <Button
                      onClick={() => dispatch(hideModal())}
                      type="button"
                      options={{
                        type: "outline",
                        skin: "danger",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                      style={{ marginLeft: "10px", width: "170px" }}
                    >
                      Cancelar
                    </Button>
                  </Center>
                </Grid.Col>
              </Grid.Row>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const states = ({
  academyStore,
  currentStore,
  criterionStore,
  subCriterionStore,
}) => {
  const { data: academies } = academyStore.all;
  const { data: current } = currentStore;
  const { states: updateCriterionStates } = criterionStore.updateCriterion;
  const { states: newCriterionStates } = criterionStore.newCriterion;
  const { states: updateSubCriterionStates } =
    subCriterionStore.updateSubCriterion;
  const { states: newSubCriterionStates } = subCriterionStore.newSubCriterion;
  return {
    academies,
    current,
    updateCriterionStates,
    newCriterionStates,
    updateSubCriterionStates,
    newSubCriterionStates,
  };
};

export default connect(states)(Component);
