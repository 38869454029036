import { put, call, all, takeLatest } from "redux-saga/effects";
import services from "../../services";
import {
  GET_CERTIFICATE,
  GET_CERTIFICATE_SUCCESS,
  GET_CERTIFICATE_ERROR,
  NEW_CERTIFICATE,
  NEW_CERTIFICATE_SUCCESS,
  NEW_CERTIFICATE_ERROR,
  GET_ALL_CERTIFICATES,
  GET_ALL_CERTIFICATES_SUCCESS,
  GET_ALL_CERTIFICATES_ERROR,
  UPDATE_CERTIFICATE,
  UPDATE_CERTIFICATE_SUCCESS,
  UPDATE_CERTIFICATE_ERROR,
  DELETE_CERTIFICATE,
  DELETE_CERTIFICATE_SUCCESS,
  DELETE_CERTIFICATE_ERROR,
  GetCertificate,
  NewCertificate,
  GetAllCertificates,
  UpdateCertificate,
  DeleteCertificate,
  DELETE_CERTIFICATE_MEMBER,
  DELETE_CERTIFICATE_MEMBER_ERROR,
  DELETE_CERTIFICATE_MEMBER_SUCCESS,
  DeleteCertificateMember,
} from "../types/certificate";

function* getAllCertificates() {
  const { response, error } = yield call(services.certificate.getAll);

  if (error) {
    yield put({
      type: GET_ALL_CERTIFICATES_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_CERTIFICATES_SUCCESS, payload: response });
  }
}

function* getCertificate({ payload }) {
  const { response, error } = yield call(services.certificate.getOne, payload);

  if (error) {
    yield put({
      type: GET_CERTIFICATE_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_CERTIFICATE_SUCCESS, payload: response });
  }
}

function* newCertificate({ payload }) {
  const { response, error } = yield call(services.certificate.new, payload);

  if (error) {
    yield put({
      type: NEW_CERTIFICATE_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([
      put({ type: NEW_CERTIFICATE_SUCCESS, payload: response }),
      put({ type: GET_ALL_CERTIFICATES, payload: null }),
    ]);
  }
}

function* updateCertificate({ payload }) {
  const { response, error } = yield call(services.certificate.update, payload);

  if (error) {
    yield put({
      type: UPDATE_CERTIFICATE_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([
      put({ type: UPDATE_CERTIFICATE_SUCCESS, payload: response }),
      put({ type: GET_ALL_CERTIFICATES, payload: null }),
    ]);
  }
}

function* deleteCertificate({ payload }) {
  const { response, error } = yield call(services.certificate.delete, payload);

  if (error) {
    yield put({
      type: DELETE_CERTIFICATE_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([
      put({ type: DELETE_CERTIFICATE_SUCCESS, payload: response }),
      put({ type: GET_ALL_CERTIFICATES, payload: null }),
    ]);
  }
}
function* deleteCertificateMember({ payload }) {
  const { response, error } = yield call(
    services.certificate.deleteMember,
    payload
  );

  if (error) {
    yield put({
      type: DELETE_CERTIFICATE_MEMBER_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([
      put({ type: DELETE_CERTIFICATE_MEMBER_SUCCESS, payload: response }),
      put({ type: GET_ALL_CERTIFICATES, payload: null }),
    ]);
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllCertificates>(
    GET_ALL_CERTIFICATES,
    getAllCertificates
  );
  yield takeLatest<GetCertificate>(GET_CERTIFICATE, getCertificate);
  yield takeLatest<NewCertificate>(NEW_CERTIFICATE, newCertificate);
  yield takeLatest<UpdateCertificate>(UPDATE_CERTIFICATE, updateCertificate);
  yield takeLatest<DeleteCertificate>(DELETE_CERTIFICATE, deleteCertificate);
  yield takeLatest<DeleteCertificateMember>(
    DELETE_CERTIFICATE_MEMBER,
    deleteCertificateMember
  );
}
