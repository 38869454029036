import AIT from "./AIT";
import MySearches from "./MySearches";
import MyTalents from "./MyTalents";
import MyTechSkills from "./MyTechSkills";
import MyTechnologies from "./MyTechnologies";
import MyCompanies from "./MyCompanies";

const pages = {
  AIT,
  MySearches,
  MyTalents,
  MyTechSkills,
  MyCompanies,
  MyTechnologies,
};

export default pages;
