import * as Yup from "yup";

export const schema = Yup.object().shape({
  private: Yup.bool(),
});

interface FormValues {
  private: boolean;
}

export const initialValues = (intial): FormValues => {
  return {
    private: false,

    ...intial,
  };
};
