import styled from "styled-components";

export const Header = styled("div")``;
export const Title = styled("h3")`
  margin: 0px 0px 5px 0px;
  font-size: 14px;
  font-weight: 500;
  color: #697482;
  padding: 0px 5px;
`;

export const Column = styled("div")`
  width: 260px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 5px;
  border-radius: 10px;
  box-shadow: 6px 6px 10px 0 rgba(0, 0, 0, 0.16);
  background-color: white;
  overflow: hidden;
  &.overlay {
    &:hover::before {
      z-index: -1;
      width: 0%;
      height: 0%;
    }
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      background: rgba(0, 0, 0, 0.16);
      z-index: 5;
      pointer-events: none;
    }
  }
  &:not(:last-child) {
    margin-right: 16px;
  }
`;

export const Body = styled("div")<{ isDraggingOver }>`
  padding: 5px;
  border-radius: 5px;
  min-height: calc(100% - 24px);
  outline: ${({ isDraggingOver }) =>
    isDraggingOver
      ? "1px solid rgba(80, 137, 198, 0.5)"
      : "0px solid rgba(80, 137, 198, 0.5)"};
  background-color: ${({ isDraggingOver }) =>
    isDraggingOver ? "rgba(80, 137, 198, 0.3)" : "rgba(80, 137, 198, 0.2)"};
`;

export const Card = styled("div")<{ isDragging }>`
  padding: 3px 3px 5px;
  user-select: none;
  border-radius: 5px;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.16);
  background-color: white;
  min-height: 80px;
  color: black;
  opacity: ${({ isDragging }) => (isDragging ? ".8" : "1")};

  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;

export const Scroll = styled("div")`
  height: 400px;
  overflow: auto;
`;
