import { put, call, takeLatest, takeEvery, select } from "redux-saga/effects";
import services from "../../services";
import { GET_ALL_CRITERIONS } from "../types/criterion";
import {
  NEW_SUB_CRITERION,
  NEW_SUB_CRITERION_ERROR,
  NEW_SUB_CRITERION_SUCCESS,
  NewSubCriterion,
  GET_ALL_SUB_CRITERIONS,
  GET_ALL_SUB_CRITERIONS_ERROR,
  GET_ALL_SUB_CRITERIONS_SUCCESS,
  GetAllSubCriterions,
  UPDATE_SUB_CRITERION_ERROR,
  UPDATE_SUB_CRITERION_SUCCESS,
  UpdateSubCriterion,
  UPDATE_SUB_CRITERION,
  NEW_MANY_SUB_CRITERION_SUCCESS,
  NEW_MANY_SUB_CRITERION_ERROR,
  NewManySubCriterion,
  NEW_MANY_SUB_CRITERION,
  UPDATE_MANY_SUB_CRITERION_SUCCESS,
  UPDATE_MANY_SUB_CRITERION_ERROR,
  UpdateManySubCriterion,
  UPDATE_MANY_SUB_CRITERION,
  DeleteSubCriterion,
  DELETE_SUB_CRITERION,
  DELETE_SUB_CRITERION_ERROR,
  DELETE_SUB_CRITERION_SUCCESS,
} from "../types/sub-criterion";
import { SubCriterionDtoApi } from "../../types/sub-criterion.dto";

const subCriterionList = ({ subCriterionStore }) =>
  subCriterionStore.allSubCriterions.data;

function* newSubCriterion({ payload }) {
  const { response, error } = yield call(services.subCriterion.new, payload);
  if (error) {
    yield put({
      type: NEW_SUB_CRITERION_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: NEW_SUB_CRITERION_SUCCESS, payload: response });
  }
}
function* deleteSubCriterion({ payload }) {
  const { response, error } = yield call(services.subCriterion.delete, payload);
  if (error) {
    yield put({
      type: DELETE_SUB_CRITERION_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: DELETE_SUB_CRITERION_SUCCESS, payload: response });
  }
}

function* getAllSubCriterion({ payload }) {
  const { response, error } = yield call(services.subCriterion.getAll, payload);
  if (error) {
    yield put({
      type: GET_ALL_SUB_CRITERIONS_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_SUB_CRITERIONS_SUCCESS, payload: response });
  }
}

function* updateSubCriterion({ payload }) {
  const { response, error } = yield call(services.subCriterion.update, payload);
  if (error) {
    yield put({
      type: UPDATE_SUB_CRITERION_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: UPDATE_SUB_CRITERION_SUCCESS, payload: response });
  }
}

function* updateManySubCriterion({ payload }) {
  let updatedList: SubCriterionDtoApi[] = [];
  const currentListEvaluates = yield select(subCriterionList);
  try {
    for (let i = 0; i < payload.items.length; i++) {
      const { _id, ...body } = payload.items[i];
      const formDataInsteadBody = !!body.body ? body.body : body;
      const { response, error } = yield call(services.subCriterion.update, {
        _id,
        body: formDataInsteadBody,
      });
      if (!!error) {
        throw new Error(error.response.data.message);
      }
      if (!!response) {
        updatedList = [...updatedList, response];
      }
    }
    const updateListChanges = currentListEvaluates.map((current) =>
      updatedList.find((list) => list._id === current._id)
        ? updatedList.find((list) => list._id === current._id)
        : current
    );
    yield put({
      type: UPDATE_MANY_SUB_CRITERION_SUCCESS,
      payload: updateListChanges,
    });
  } catch (err) {
    yield put({ type: UPDATE_MANY_SUB_CRITERION_ERROR, payload: err });
  }
}

function* newEvaluateMany({ payload }) {
  const currentListEvaluates = yield select(subCriterionList);
  let allItems: any[] = [];

  try {
    for (let i = 0; i < payload.items.length; i++) {
      const { response, error } = yield call(
        services.subCriterion.new,
        payload.items[i]
      );
      if (!!error) {
        throw new Error(error.response.data.message);
      }
      if (!!response) {
        allItems = [...allItems, response];
      }
    }
    yield put({
      type: NEW_MANY_SUB_CRITERION_SUCCESS,
      payload: !!currentListEvaluates
        ? [...allItems, ...currentListEvaluates]
        : allItems,
    });
  } catch (err: any) {
    yield put({
      type: NEW_MANY_SUB_CRITERION_ERROR,
      payload: err.message || "Error",
    });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllSubCriterions>(
    GET_ALL_SUB_CRITERIONS,
    getAllSubCriterion
  );
  yield takeEvery<NewSubCriterion>(NEW_SUB_CRITERION, newSubCriterion);
  yield takeLatest<UpdateSubCriterion>(
    UPDATE_SUB_CRITERION,
    updateSubCriterion
  );
  yield takeLatest<NewManySubCriterion>(
    NEW_MANY_SUB_CRITERION,
    newEvaluateMany
  );
  yield takeLatest<UpdateManySubCriterion>(
    UPDATE_MANY_SUB_CRITERION,
    updateManySubCriterion
  );
  yield takeLatest<DeleteSubCriterion>(
    DELETE_SUB_CRITERION,
    deleteSubCriterion
  );
}
