import { CertificateDto } from "../../types/certificate.dto";

export const GET_ALL_CERTIFICATES = "GET_ALL_CERTIFICATES";
export const GET_ALL_CERTIFICATES_ERROR = "GET_ALL_CERTIFICATES_ERROR";
export const GET_ALL_CERTIFICATES_SUCCESS = "GET_ALL_CERTIFICATES_SUCCESS";
export const GET_CERTIFICATE = "GET_CERTIFICATE";
export const GET_CERTIFICATE_ERROR = "GET_CERTIFICATE_ERROR";
export const GET_CERTIFICATE_SUCCESS = "GET_CERTIFICATE_SUCCESS";
export const NEW_CERTIFICATE = "NEW_CERTIFICATE";
export const NEW_CERTIFICATE_ERROR = "NEW_CERTIFICATE_ERROR";
export const NEW_CERTIFICATE_SUCCESS = "NEW_CERTIFICATE_SUCCESS";
export const RESET_NEW_CERTIFICATE = "RESET_NEW_CERTIFICATE";
export const UPDATE_CERTIFICATE = "UPDATE_CERTIFICATE";
export const UPDATE_CERTIFICATE_ERROR = "UPDATE_CERTIFICATE_ERROR";
export const UPDATE_CERTIFICATE_SUCCESS = "UPDATE_CERTIFICATE_SUCCESS";
export const RESET_UPDATE_CERTIFICATE = "RESET_UPDATE_CERTIFICATE";
export const DELETE_CERTIFICATE = "DELETE_CERTIFICATE";
export const DELETE_CERTIFICATE_ERROR = "DELETE_CERTIFICATE_ERROR";
export const DELETE_CERTIFICATE_SUCCESS = "DELETE_CERTIFICATE_SUCCESS";
export const RESET_DELETE_CERTIFICATE = "RESET_DELETE_CERTIFICATE";
export const DELETE_CERTIFICATE_MEMBER = "DELETE_CERTIFICATE_MEMBER";
export const DELETE_CERTIFICATE_MEMBER_SUCCESS =
  "DELETE_CERTIFICATE_MEMBER_SUCCESS";
export const DELETE_CERTIFICATE_MEMBER_ERROR =
  "DELETE_CERTIFICATE_MEMBER_ERROR";

export interface GetAllCertificates {
  type: typeof GET_ALL_CERTIFICATES;
  payload: null;
}

export interface GetAllCertificatesSuccess {
  type: typeof GET_ALL_CERTIFICATES_SUCCESS;
  payload: CertificateDto[];
}

export interface GetAllCertificatesError {
  type: typeof GET_ALL_CERTIFICATES_ERROR;
  payload: boolean | string;
}

export interface GetCertificate {
  type: typeof GET_CERTIFICATE;
  payload: { id: string };
}

export interface GetCertificateSuccess {
  type: typeof GET_CERTIFICATE_SUCCESS;
  payload: CertificateDto;
}

export interface GetCertificateError {
  type: typeof GET_CERTIFICATE_ERROR;
  payload: boolean | string;
}

export interface NewCertificate {
  type: typeof NEW_CERTIFICATE;
  payload: CertificateDto;
}

export interface NewCertificateSuccess {
  type: typeof NEW_CERTIFICATE_SUCCESS;
  payload: CertificateDto;
}

export interface NewCertificateError {
  type: typeof NEW_CERTIFICATE_ERROR;
  payload: boolean | string;
}

export interface ResetNewCertificate {
  type: typeof RESET_NEW_CERTIFICATE;
  payload: null;
}

export interface UpdateCertificate {
  type: typeof UPDATE_CERTIFICATE;
  payload: CertificateDto;
}

export interface UpdateCertificateSuccess {
  type: typeof UPDATE_CERTIFICATE_SUCCESS;
  payload: CertificateDto;
}

export interface UpdateCertificateError {
  type: typeof UPDATE_CERTIFICATE_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateCertificate {
  type: typeof RESET_UPDATE_CERTIFICATE;
  payload: null;
}

export interface DeleteCertificate {
  type: typeof DELETE_CERTIFICATE;
  payload: { id: string };
}

export interface DeleteCertificateSuccess {
  type: typeof DELETE_CERTIFICATE_SUCCESS;
  payload: null;
}

export interface DeleteCertificateError {
  type: typeof DELETE_CERTIFICATE_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteCertificate {
  type: typeof RESET_DELETE_CERTIFICATE;
  payload: null;
}

export interface DeleteCertificateMember {
  type: typeof DELETE_CERTIFICATE_MEMBER;
  payload: any;
}
export interface DeleteCertificateMemberSuccess {
  type: typeof DELETE_CERTIFICATE_MEMBER_SUCCESS;
  payload: any;
}
export interface DeleteCertificateMemberError {
  type: typeof DELETE_CERTIFICATE_MEMBER_ERROR;
  payload: string | boolean;
}

export interface State {
  allCertificates: {
    data: CertificateDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  certificate: {
    data: CertificateDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  newCertificate: {
    data: CertificateDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  deleteCertificate: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  updateCertificate: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  allCertificates: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  certificate: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  newCertificate: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  deleteCertificate: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  updateCertificate: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllCertificates
  | GetAllCertificatesSuccess
  | GetAllCertificatesError
  | GetCertificate
  | GetCertificateSuccess
  | GetCertificateError
  | NewCertificate
  | NewCertificateSuccess
  | NewCertificateError
  | ResetNewCertificate
  | UpdateCertificate
  | UpdateCertificateSuccess
  | UpdateCertificateError
  | ResetUpdateCertificate
  | DeleteCertificate
  | DeleteCertificateSuccess
  | DeleteCertificateError
  | ResetDeleteCertificate
  | DeleteCertificateMember
  | DeleteCertificateMemberSuccess
  | DeleteCertificateMemberError;
