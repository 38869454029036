import { useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import { Outlet } from "react-router-dom";
import { toast } from "react-toastify";
import { resetGetOrganizationStats } from "../../../../store/actions/analytics";

const Component = ({
  analyticsOrganization,
  analyticsOrganizationStates,
  current,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (analyticsOrganizationStates.success) {
      setTimeout(() => {
        dispatch(resetGetOrganizationStats());
      }, 1000);
    }

    if (analyticsOrganizationStates.error) {
      toast.error(`😱 ${analyticsOrganizationStates.error}`);

      setTimeout(() => {
        dispatch(resetGetOrganizationStats());
      }, 1000);
    }
  }, [analyticsOrganizationStates]);

  return <Outlet></Outlet>;
};

const states = ({ analyticsStore, currentStore }) => {
  const { data: analyticsOrganization, states: analyticsOrganizationStates } =
    analyticsStore.getOrganizationStats;
  const { data: current } = currentStore;
  return {
    analyticsOrganization,
    analyticsOrganizationStates,
    current,
  };
};

export default connect(states)(Component);
