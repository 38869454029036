import {
  AcademyUserTimes,
  AnalyticsUserDto,
  CohortUserTimeDto,
  SuscriptionAnalyticsDto,
} from "../../types/analytics.dto";

export const GET_INDIVIDUAL_STATS = "GET_INDIVIDUAL_STATS";
export const GET_INDIVIDUAL_STATS_SUCCESS = "GET_INDIVIDUAL_STATS_SUCCESS";
export const GET_INDIVIDUAL_STATS_ERROR = "GET_INDIVIDUAL_STATS_ERROR";
export const RESET_GET_INDIVIDUAL_STATS = "RESET_GET_INDIVIDUAL_STATS";
export const GET_ALL_STUDENTS_STATS = "GET_ALL_STUDENTS_STATS";
export const GET_COHORT_STATS = "GET_COHORT_STATS";
export const GET_COHORT_STATS_SUCCESS = "GET_COHORT_STATS_SUCCESS";
export const GET_COHORT_STATS_ERROR = "GET_COHORT_STATS_ERROR";
export const RESET_GET_COHORT_STATS = "RESET_GET_COHORT_STATS";
export const GET_ALL_STUDENTS_STATS_SUCCESS = "GET_ALL_STUDENTS_STATS_SUCCESS";
export const GET_ALL_STUDENTS_STATS_ERROR = "GET_ALL_STUDENTS_STATS_ERROR";
export const GET_ACADEMY_STATS = "GET_ACADEMY_STATS";
export const RESET_ACADEMY_STATS = "RESET_ACADEMY_STATS";
export const GET_ACADEMY_STATS_ERROR = "GET_ACADEMY_STATS_ERROR";
export const GET_ACADEMY_STATS_SUCCESS = "GET_ACADEMY_STATS_SUCCESS";
export const GET_ORGANIZATION_STATS = "GET_ORGANIZATION_STATS";
export const RESET_ORGANIZATION_STATS = "RESET_ORGANIZATION_STATS";
export const GET_ORGANIZATION_STATS_ERROR = "GET_ORGANIZATION_STATS_ERROR";
export const GET_ORGANIZATION_STATS_SUCCESS = "GET_ORGANIZATION_STATS_SUCCESS";
export const GET_MANY_INDIVIDUAL_STATS = "GET_MANY_INDIVIDUAL_STATS";
export const RESET_GET_ALL_STUDENTS_STATS = "RESET_GET_ALL_STUDENTS_STATS";
export const GET_COHORT_USER_TIME = "GET_COHORT_USER_TIME";
export const GET_COHORT_USER_TIME_ERROR = "GET_COHORT_USER_TIME_ERROR";
export const GET_COHORT_USER_TIME_SUCCESS = "GET_COHORT_USER_TIME_SUCCESS";
export const RESET_GET_COHORT_USER_TIME = "RESET_GET_COHORT_USER_TIME";
export const GET_ACADEMY_USER_TIME = "GET_ACADEMY_USER_TIME";
export const GET_ACADEMY_USER_TIME_SUCCESS = "GET_ACADEMY_USER_TIME_SUCCESS";
export const GET_ACADEMY_USER_TIME_ERROR = "GET_ACADEMY_USER_TIME_ERROR";
export const RESET_GET_ACADEMY_USER_TIME = "RESET_GET_ACADEMY_USER_TIME";
export const GET_ACADEMY_SUSCRIPTION_STATS = "GET_ACADEMY_SUSCRIPTION_STATS";
export const GET_ACADEMY_SUSCRIPTION_STATS_SUCCESS =
  "GET_ACADEMY_SUSCRIPTION_STATS_SUCCESS";
export const GET_ACADEMY_SUSCRIPTION_STATS_ERROR =
  "GET_ACADEMY_SUSCRIPTION_STATS_ERROR";
export const RESET_GET_ACADEMY_SUSCRIPTION_STATS =
  "RESET_GET_ACADEMY_SUSCRIPTION_STATS";
export const GET_COHORT_SUSCRIPTION_STATS = "GET_COHORT_SUSCRIPTION_STATS";
export const GET_COHORT_SUSCRIPTION_STATS_SUCCESS =
  "GET_COHORT_SUSCRIPTION_STATS_SUCCESS";
export const GET_COHORT_SUSCRIPTION_STATS_ERROR =
  "GET_COHORT_SUSCRIPTION_STATS_ERROR";
export const RESET_GET_COHORT_SUSCRIPTION_STATS =
  "RESET_GET_COHORT_SUSCRIPTION_STATS";

export interface GetAcademySuscriptionStats {
  type: typeof GET_ACADEMY_SUSCRIPTION_STATS;
  payload: {
    _id: string;
    period: "day" | "week" | "month" | "year" | "historical";
  };
}

export interface GetAcademySuscriptionStatsSuccess {
  type: typeof GET_ACADEMY_SUSCRIPTION_STATS_SUCCESS;
  payload: SuscriptionAnalyticsDto;
}

export interface GetAcademySuscriptionError {
  type: typeof GET_ACADEMY_SUSCRIPTION_STATS_ERROR;
  payload: string | boolean;
}

export interface ResetGetAcademySuscription {
  type: typeof RESET_GET_ACADEMY_SUSCRIPTION_STATS;
  payload: null;
}

export interface GetCohortSuscriptionStats {
  type: typeof GET_COHORT_SUSCRIPTION_STATS;
  payload: {
    _id: string;
    period: "day" | "week" | "month" | "year" | "historical";
  };
}

export interface GetCohortSuscriptionStatsSuccess {
  type: typeof GET_COHORT_SUSCRIPTION_STATS_SUCCESS;
  payload: SuscriptionAnalyticsDto;
}

export interface GetCohortSuscriptionError {
  type: typeof GET_COHORT_SUSCRIPTION_STATS_ERROR;
  payload: string | boolean;
}

export interface ResetGetCohortSuscription {
  type: typeof RESET_GET_COHORT_SUSCRIPTION_STATS;
  payload: null;
}

export interface GetCohortUserTime {
  type: typeof GET_COHORT_USER_TIME;
  payload: {
    cohortId: string;
    topCohortContents: number;
    topUnitsContents: number;
  };
}

export interface GetCohortUserTimeSuccess {
  type: typeof GET_COHORT_USER_TIME_SUCCESS;
  payload: CohortUserTimeDto;
}

export interface GetCohortUserTimeError {
  type: typeof GET_COHORT_USER_TIME_ERROR;
  payload: string | boolean;
}

export interface ResetGetCohortUserTime {
  type: typeof RESET_GET_COHORT_USER_TIME;
  payload: null;
}
export interface GetAcademyUserTime {
  type: typeof GET_ACADEMY_USER_TIME;
  payload: {
    academyId: string;
    topContents: number;
  };
}

export interface GetAcademyUserTimeSuccess {
  type: typeof GET_ACADEMY_USER_TIME_SUCCESS;
  payload: CohortUserTimeDto;
}

export interface GetAcademyUserTimeError {
  type: typeof GET_ACADEMY_USER_TIME_ERROR;
  payload: string | boolean;
}

export interface ResetGetAcademyUserTime {
  type: typeof RESET_GET_ACADEMY_USER_TIME;
  payload: null;
}

export interface GetIndividualStats {
  type: typeof GET_INDIVIDUAL_STATS;
  payload: { _id: string; cohort?: string };
}

export interface GetCohortsStats {
  type: typeof GET_COHORT_STATS;
  payload: string;
}

export interface GetCohortsStatsSuccess {
  type: typeof GET_COHORT_STATS_SUCCESS;
  payload: any;
}
export interface GetCohortsStatsError {
  type: typeof GET_COHORT_STATS_ERROR;
  payload: string | boolean;
}

export interface ResetGetCohortStats {
  type: typeof RESET_GET_COHORT_STATS;
  payload: null;
}

export interface GetManyIndividualStats {
  type: typeof GET_MANY_INDIVIDUAL_STATS;
  payload: any;
}

export interface GetAllStudentsStats {
  type: typeof GET_ALL_STUDENTS_STATS;
  payload: null;
}

export interface GetAllStudentsStatsSuccess {
  type: typeof GET_ALL_STUDENTS_STATS_SUCCESS;
  payload: any;
}

export interface ResetGetAllStudentsStats {
  type: typeof RESET_GET_ALL_STUDENTS_STATS;
  payload: null;
}

export interface GetAllStudentsStatsError {
  type: typeof GET_ALL_STUDENTS_STATS_ERROR;
  payload: string | boolean;
}

export interface GetIndividualStatsSuccess {
  type: typeof GET_INDIVIDUAL_STATS_SUCCESS;
  payload: AnalyticsUserDto;
}

export interface ResetGetIndividualStats {
  type: typeof RESET_GET_INDIVIDUAL_STATS;
  payload: null;
}

export interface GetIndividualStatsError {
  type: typeof GET_INDIVIDUAL_STATS_ERROR;
  payload: string | boolean;
}

export interface GetAcademyStats {
  type: typeof GET_ACADEMY_STATS;
  payload: string;
}
export interface GetAcademyStatsError {
  type: typeof GET_ACADEMY_STATS_ERROR;
  payload: string | boolean;
}
export interface GetAcademyStatsSuccess {
  type: typeof GET_ACADEMY_STATS_SUCCESS;
  payload: any;
}

export interface ResetAcademyStats {
  type: typeof RESET_ACADEMY_STATS;
  payload: null;
}

export interface GetOrganizationStats {
  type: typeof GET_ORGANIZATION_STATS;
  payload: string;
}
export interface GetOrganizationStatsError {
  type: typeof GET_ORGANIZATION_STATS_ERROR;
  payload: string | boolean;
}
export interface GetOrganizationStatsSuccess {
  type: typeof GET_ORGANIZATION_STATS_SUCCESS;
  payload: any;
}
export interface ResetOrganizationStats {
  type: typeof RESET_ORGANIZATION_STATS;
  payload: null;
}

export interface State {
  getIndividualStats: {
    data: AnalyticsUserDto | null;
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
  cohortUserTime: {
    data: null | CohortUserTimeDto;
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
  academyUserTime: {
    data: null | AcademyUserTimes;
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
  getCohortStats: {
    data: any;
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
  academySuscriptionStats: {
    data: SuscriptionAnalyticsDto | null;
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
  cohortSuscriptionStats: {
    data: SuscriptionAnalyticsDto | null;
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
  getAcademyStats: {
    data: any;
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
  getOrganizationStats: {
    data: any;
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
  getAllStudentsStats: {
    data: any;
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
}

export const initialValues: State = {
  getAcademyStats: {
    data: null,
    states: {
      loading: false,
      error: false,
      success: false,
    },
  },
  getOrganizationStats: {
    data: null,
    states: {
      loading: false,
      error: false,
      success: false,
    },
  },
  academySuscriptionStats: {
    data: null,
    states: {
      loading: false,
      error: false,
      success: false,
    },
  },
  cohortSuscriptionStats: {
    data: null,
    states: {
      loading: false,
      error: false,
      success: false,
    },
  },
  cohortUserTime: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  academyUserTime: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  getIndividualStats: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  getCohortStats: {
    data: null,
    states: {
      loading: false,
      error: false,
      success: false,
    },
  },
  getAllStudentsStats: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetIndividualStats
  | GetIndividualStatsSuccess
  | GetIndividualStatsError
  | ResetGetIndividualStats
  | GetCohortsStats
  | GetCohortsStatsSuccess
  | GetCohortsStatsError
  | ResetGetCohortStats
  | GetAllStudentsStats
  | GetAllStudentsStatsSuccess
  | GetAllStudentsStatsError
  | GetAcademyStats
  | GetAcademyStatsError
  | GetAcademyStatsSuccess
  | ResetAcademyStats
  | GetOrganizationStats
  | GetOrganizationStatsError
  | GetOrganizationStatsSuccess
  | ResetOrganizationStats
  | GetManyIndividualStats
  | ResetGetAllStudentsStats
  | GetCohortUserTime
  | GetCohortUserTimeSuccess
  | GetCohortUserTimeError
  | ResetGetCohortUserTime
  | GetAcademyUserTime
  | GetAcademyUserTimeSuccess
  | GetAcademyUserTimeError
  | ResetGetAcademyUserTime
  | GetAcademySuscriptionStats
  | GetAcademySuscriptionStatsSuccess
  | GetAcademySuscriptionError
  | ResetGetAcademySuscription
  | GetCohortSuscriptionStats
  | GetCohortSuscriptionStatsSuccess
  | GetCohortSuscriptionError
  | ResetGetCohortSuscription;
