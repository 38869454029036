import * as Yup from "yup";

interface FormValues {
  private: boolean;
}

export const schema = Yup.object().shape({
  private: Yup.bool().required(),
});

export const initialValues = (initialValues = {}): FormValues => {
  return {
    private: false,
    ...initialValues,
  };
};
