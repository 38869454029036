import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Formik } from "formik";
import Modal from "../Modal";
import Grid from "../Grid";
import { Center } from "../StyledComponents";
import { hideModal } from "../../store/actions/modal";
import Submit from "../Form/Submit";
import { toast } from "react-toastify";
import { schema } from "../../constants/form/user/profile/visibility";
const Component = ({
  states,
  elementActions,
  resetAction,
  title = "Confirmar Eliminacion",
  resetState,
  acceptMessage = "Se ha completado correctamente",
  rejectMessage = "No se a podido realizar la accion",
  bntConfig,
  description,
  sucessAction,
  externalFunction,
}: {
  states?;
  elementActions?;
  externalFunction?;
  resetAction?;
  resetState?;
  acceptMessage?: string;
  rejectMessage?: string;
  title?: string | JSX.Element;
  bntConfig: { content: string; style: any };
  description?: string;
  sucessAction?: () => any;
}) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const dispatch = useDispatch();
  const onSubmit = ({ values, actions }) => {
    !!elementActions &&
      dispatch(externalFunction ? elementActions() : elementActions);
    setFormActions(actions);
  };

  useEffect(() => {
    if (states.success) {
      toast(`👌🏼 ${acceptMessage}`, {
        containerId: "submit",
        type: "success",
        toastId: "submitsucceful",
      });
      dispatch(hideModal());
      resetState(null);
      !!sucessAction && sucessAction();
      setTimeout(() => {
        !!resetAction && dispatch(resetAction());
      }, 500);
    }

    if (states.error) {
      toast(`😱 ${states.error}`, {
        containerId: "submit",
        type: "error",
        toastId: "submiterror",
      });
      setTimeout(() => {
        dispatch(resetAction());
      }, 500);
    }
  }, [states]);
  return (
    <Modal
      name="confirm-delete-item-secuencial-modal"
      title={title}
      showClose={true}
      description={description}
    >
      <Formik
        initialValues={{}}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="new-evaluate-form"
            >
              <Grid.Row>
                <Grid.Col>
                  <Center gap={"70px"}>
                    <Submit
                      isSubmmiting={states.loading}
                      form="new-evaluate-form"
                      {...bntConfig.style}
                    >
                      {bntConfig.content}
                    </Submit>
                  </Center>
                </Grid.Col>
              </Grid.Row>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default Component;
