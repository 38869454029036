import * as Yup from "yup";

interface FormValues {
  email: string;
  role: string;
}

export const schema = Yup.object().shape({
  email: Yup.string().email().required(),
  role: Yup.string(),
});

export const initialValues = (): FormValues => {
  return {
    email: "",
    role: "",
  };
};
