import {
  initialState,
  Actions,
  State,
  GET_ALL_COMPANIES,
  GET_ALL_COMPANIES_ERROR,
  GET_ALL_COMPANIES_SUCCESS,
  GET_COMPANY,
  GET_COMPANY_ERROR,
  GET_COMPANY_SUCCESS,
  NEW_COMPANY,
  NEW_COMPANY_ERROR,
  NEW_COMPANY_SUCCESS,
  RESET_NEW_COMPANY,
  UPDATE_COMPANY,
  UPDATE_COMPANY_ERROR,
  UPDATE_COMPANY_SUCCESS,
  RESET_UPDATE_COMPANY,
  DELETE_COMPANY,
  DELETE_COMPANY_ERROR,
  DELETE_COMPANY_SUCCESS,
  RESET_DELETE_COMPANY,
  SET_UPDATE_COMPANY_DATA,
  SET_NEW_COMPANY_DATA,
  UPDATE_CURRENT_COMPANY_LIST,
} from "../../types/recruiting/company";

const updateReducerArray = (array, data) =>
  array.map((elem) => (elem._id === data._id ? data : elem));

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_COMPANIES:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_COMPANIES_SUCCESS:
      return {
        ...state,
        all: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_COMPANIES_ERROR:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case GET_COMPANY:
      return {
        ...state,
        selected: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_COMPANY_SUCCESS:
      return {
        ...state,
        selected: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_COMPANY_ERROR:
      return {
        ...state,
        selected: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case NEW_COMPANY:
      return {
        ...state,
        create: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_COMPANY_SUCCESS:
      return {
        ...state,
        create: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case SET_NEW_COMPANY_DATA:
      return {
        ...state,
        all: {
          ...state.all,
          data: !!state.all.data
            ? [...state.all.data, action.payload]
            : [action.payload],
        },
      };
    case UPDATE_CURRENT_COMPANY_LIST:
      return {
        ...state,
        all: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: false,
          },
        },
      };
    case SET_UPDATE_COMPANY_DATA:
      return {
        ...state,
        all: {
          ...state.all,
          data: !!state.all.data
            ? updateReducerArray(state.all.data, action.payload)
            : null,
        },
      };
    case NEW_COMPANY_ERROR:
      return {
        ...state,
        create: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_COMPANY:
      return {
        ...state,
        create: initialState.create,
      };
    case DELETE_COMPANY:
      return {
        ...state,
        delete: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_COMPANY_SUCCESS:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_COMPANY_ERROR:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_COMPANY:
      return {
        ...state,
        delete: initialState.delete,
      };
    case UPDATE_COMPANY:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        update: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_COMPANY_ERROR:
      return {
        ...state,
        update: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_COMPANY:
      return {
        ...state,
        update: initialState.update,
      };
    default:
      return state;
  }
};

export default reducers;
