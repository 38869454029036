import {
  GetAit,
  GET_AIT,
  RESET_UPDATE_AIT,
  UpdateAit,
  UPDATE_AIT,
} from "../../types/recruiting/ait";

const getAit = (payload: { _id: string }): GetAit => {
  return { type: GET_AIT, payload };
};
const updateAitTable = (payload: {
  tables: any;
  _id?: string;
  __v?: number;
}): UpdateAit => {
  return { type: UPDATE_AIT, payload: payload };
};

const resetUpdateAitTable = () => {
  return { type: RESET_UPDATE_AIT, payload: null };
};

const actions = { getAit, updateAitTable, resetUpdateAitTable };

export default actions;
