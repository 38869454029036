import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import {
  PageTitle,
  Center,
} from "../../../../../../components/StyledComponents";
import Grid from "../../../../../../components/Grid";
import Button from "../../../../../../components/Button";
import Input from "../../../../../../components/Form/Input";
import DinamicField from "../../../../../../components/Form/DinamicField";
import Submit from "../../../../../../components/Form/Submit";
import ReactSelect from "../../../../../../components/Form/ReactSelect";
import { usersTypes } from "../../../../../../constants/users-types";
import {
  initialValues,
  schema,
} from "../../../../../../constants/form/academy/fiscalInformation/fiscal";
import {
  newExecutive,
  resetNewExecutive,
} from "../../../../../../store/actions/executive";
import { hideModal } from "../../../../../../store/actions/modal";
import { AbilityDto } from "../../../../../../types/ability.dto";
import { CohortDto } from "../../../../../../types/cohort.dto";
import { toast } from "react-toastify";
import { getAllAbilities } from "../../../../../../store/actions/ability";
import { getAllCohorts } from "../../../../../../store/actions/cohort";
import Modal from "../../../../../../components/Modal";
import { CurrentDto } from "../../../../../../types/current.dto";
import { AcademyDto } from "../../../../../../types/academy.dto";
import academyActions from "../../../../../../store/actions/academies";
interface ComponentProps {
  abilities: AbilityDto[];
  currentAcademy: AcademyDto;
  abilitiesStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  cohorts: CohortDto[];
  cohortsStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  newExecutiveStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  current: CurrentDto;
}

const Component = ({
  newExecutiveStates,
  abilities,
  abilitiesStates,
  cohorts,
  cohortsStates,
  currentAcademy,
  current,
}: ComponentProps) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();

  const dispatch = useDispatch();

  const onSubmit = ({ values, actions }) => {
    dispatch(
      academyActions.update({ _id: current.id, taxInfo: { ...values } })
    );
    setFormActions(actions);
  };

  return (
    <>
      <Modal name="fiscal-info-modal" title={"Informacion Fiscal"}>
        <Formik
          initialValues={{
            ...initialValues,
            ...(currentAcademy.taxInfo && {
              cuit: currentAcademy.taxInfo.cuit,
              dni: currentAcademy.taxInfo.dni,
              ivaType: currentAcademy.taxInfo.ivaType,
              personName: currentAcademy.taxInfo.personName,
              personType: currentAcademy.taxInfo.personType,
            }),
          }}
          onSubmit={(values, actions) => {
            if (onSubmit) onSubmit({ values, actions });
          }}
          validateOnChange={formSubmmited}
          validateOnBlur={false}
          validationSchema={schema}
          enableReinitialize
        >
          {({
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
            setFieldValue,
            isSubmitting,
          }) => {
            return (
              <form
                className="theme-form"
                onSubmit={(event) => {
                  setFormSubmmited(true);
                  handleSubmit(event);
                }}
                id="edit-executive-form"
              >
                <Grid.Row>
                  <ReactSelect
                    name="personType"
                    error={errors["personType"]}
                    touched={touched["personType"]}
                    items={[
                      { label: "Persona Juridica", value: "Jurídica" },
                      { label: "Persona Fisica", value: "Fisica" },
                    ]}
                    placeholder="Selecciona una opcion"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "Persona Fisica/Persona Juridica",
                      marginBottom: 14,
                    }}
                  />
                  <Input
                    name="personName"
                    error={errors["personName"]}
                    touched={touched["personName"]}
                    value={values["personName"]}
                    type="text"
                    placeholder="Ingrese el nombre"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "Nombre",
                      marginBottom: 14,
                    }}
                  />
                  <Input
                    name="cuit"
                    error={errors["cuit"]}
                    touched={touched["cuit"]}
                    value={values["cuit"]}
                    type="text"
                    placeholder="Ingrese el CUIT"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "CUIT",
                      marginBottom: 14,
                    }}
                  />
                  <Input
                    name="dni"
                    error={errors["dni"]}
                    touched={touched["dni"]}
                    value={values["dni"]}
                    type="text"
                    placeholder="Ingrese un DNI"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "DNI",
                      marginBottom: 14,
                    }}
                  />

                  <ReactSelect
                    name="ivaType"
                    error={errors["ivaType"]}
                    touched={touched["ivaType"]}
                    items={[
                      {
                        label: "Responsable inscripto",
                        value: "Responsable inscripto",
                      },
                      { label: "Autónomo", value: "Autónomo" },
                      { label: "Monotributista", value: "Monotributista" },
                      { label: "Autónomo", value: "Autónomo" },
                      { label: "Exento", value: "Exento" },
                      { label: "No alcanzado", value: "No alcanzado" },
                    ]}
                    placeholder="Selecciona una opcion"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "Persona Fisica/Persona Juridica",
                      marginBottom: 30,
                    }}
                  />
                </Grid.Row>

                <Center>
                  <Submit
                    isSubmmiting={newExecutiveStates.loading}
                    form="edit-executive-form"
                    color="Primary"
                    options={{
                      type: "filled",
                      skin: "primary",
                      size: "lg",
                      marginBottom: "0px",
                    }}
                  >
                    Confirmar
                  </Submit>

                  <Button
                    onClick={resetForm}
                    type="button"
                    options={{
                      type: "outline",
                      skin: "danger",
                      size: "lg",
                      marginBottom: "0px",
                    }}
                    style={{ marginLeft: "10px" }}
                  >
                    Deshacer
                  </Button>
                </Center>
              </form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

const states = ({
  executiveStore,
  abilityStore,
  cohortStore,
  currentStore,
}) => {
  const { states: newExecutiveStates } = executiveStore.newExecutive;
  const { states: abilitiesStates, data: abilities } =
    abilityStore.allAbilities;
  const { states: cohortsStates, data: cohorts } = cohortStore.allCohorts;
  const { data: current } = currentStore;
  return {
    newExecutiveStates,
    abilitiesStates,
    abilities,
    cohortsStates,
    cohorts,
    current,
  };
};

export default connect(states)(Component);
