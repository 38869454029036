import services from "../services";

export const fetchFilterUsersByAcademy = async ({
  academyId,
  filterUsers,
  filterUsersAcademy,
}) => {
  const response: any = await services.users.getAllByAcademy({
    _id: academyId,
    payload: { filterBy: {} },
  });

  const responseAcademy: any = await services.academy.getOne({
    _id: academyId,
  });
  const filteredUsers = responseAcademy.response.users.filter((user) =>
    filterUsersAcademy(user)
  );
  return response.response.filter(
    (res) =>
      filteredUsers.find((user) => user.user === res._id) && filterUsers(res)
  );
};
