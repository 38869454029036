import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Modal from "../../../../../../components/Modal";
import { Formik } from "formik";
import { Center } from "../../../../../../components/StyledComponents";
import {
  initialValues,
  schema,
} from "../../../../../../constants/form/organization/profile/data/edit";
import Grid from "../../../../../../components/Grid";
import Button from "../../../../../../components/Button";
import Submit from "../../../../../../components/Form/Submit";
import { toast } from "react-toastify";
import Input from "../../../../../../components/Form/Input";
import organizationActions from "../../../../../../store/actions/organization";
import { hideModal } from "../../../../../../store/actions/modal";

const Component = ({
  selectedOrganization,
  updateOrganizationStates,
  current,
  organizations,
}) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const dispatch = useDispatch();
  const organizationFounded = organizations?.find(
    (organization) => organization._id === current.id
  );
  const onSubmit = ({ values, actions }) => {
    dispatch(organizationActions.update({ _id: current.id, ...values }));
    setFormActions(actions);
  };

  useEffect(() => {
    if (updateOrganizationStates.success) {
      toast("👌🏼 Empresa creada correctamente", {
        containerId: "submit",
        type: "success",
        toastId: "submitsucceful",
      });

      setTimeout(() => {
        dispatch(organizationActions.resetUpdate());
        dispatch(hideModal());
        formActions?.resetForm();
      }, 1000);
    }

    if (updateOrganizationStates.error) {
      toast(`😱 ${updateOrganizationStates.error}`, {
        containerId: "submit",
        type: "error",
        toastId: "submiterror",
      });

      setTimeout(() => {
        dispatch(organizationActions.resetUpdate());
      }, 1000);
    }
  }, [updateOrganizationStates]);

  return (
    <Modal name="edit-organization-data" title="Datos de la Organización">
      <Formik
        initialValues={{
          ...initialValues(
            !!organizationFounded && {
              phone: organizationFounded.phone,
              email: organizationFounded.email,
              location: organizationFounded.location,
              linkedin: organizationFounded.linkedin,
              github: organizationFounded.github,
              website: organizationFounded.website,
            }
          ),
        }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="edit-organization-data-form"
            >
              <Grid.Row>
                <Grid.Col>
                  <Input
                    name={"phone"}
                    error={errors["phone"]}
                    touched={touched["phone"]}
                    value={values["phone"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{ label: "Teléfono", skin: "gray" }}
                  />
                  <Input
                    name={"email"}
                    error={errors["email"]}
                    touched={touched["email"]}
                    value={values["email"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{ label: "E-mail", skin: "gray" }}
                  />
                  <Input
                    name={"location"}
                    error={errors["location"]}
                    touched={touched["location"]}
                    value={values["location"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "Ubicación",
                      skin: "gray",
                    }}
                  />
                  <Input
                    name={"linkedin"}
                    error={errors["linkedin"]}
                    touched={touched["linkedin"]}
                    value={values["linkedin"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{ label: "Linkedin", skin: "gray" }}
                  />
                  <Input
                    name={"github"}
                    error={errors["github"]}
                    touched={touched["github"]}
                    value={values["github"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "GitHub",
                      skin: "gray",
                    }}
                  />
                  <Input
                    name={"website"}
                    error={errors["website"]}
                    touched={touched["website"]}
                    value={values["website"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "WebSite",
                      skin: "gray",
                    }}
                  />
                </Grid.Col>
              </Grid.Row>

              <Grid.Row>
                <Grid.Col>
                  <Center gap={"70px"}>
                    <Button
                      onClick={resetForm}
                      type="button"
                      options={{
                        type: "outline",
                        skin: "gray",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                      style={{ marginLeft: "10px" }}
                    >
                      Cancelar
                    </Button>

                    <Submit
                      isSubmmiting={isSubmitting}
                      form="edit-organization-data-form"
                      color="Primary"
                      options={{
                        type: "filled",
                        skin: "primary",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                    >
                      Confirmar
                    </Submit>
                  </Center>
                </Grid.Col>
              </Grid.Row>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const states = ({ organizationStore, currentStore }) => {
  const { data: selectedOrganization } = organizationStore.selected;
  const { states: updateOrganizationStates } = organizationStore.update;
  const { data: current } = currentStore;
  const { data: organizations } = organizationStore.all;
  return {
    selectedOrganization,
    updateOrganizationStates,
    current,
    organizations,
  };
};

export default connect(states)(Component);
