import Grid from "../../../../../../components/Grid";
import {
  CriterionCard,
  GrowCard,
  NoteCard,
} from "../../../../../../components/LineGraph/styles";
import BarChart from "../../../../../../components/BarChart/index";
import {
  TitleCriterion,
  TitleStage,
} from "../../../IndividualPerformance/components/NumericStats/styles";
import { BigGrade } from "../../../IndividualPerformance/components/SprintStats/styles";
import { connect } from "react-redux";
import { AnalyticsCohortDto } from "../../../../../../types/analytics.dto";
import { maxValue } from "../../../../../../helpers/max-value";
import ShortString from "../../../../../../components/ShortString";
import { roundedNumbers } from "../../../../../../helpers/rounded-numbers";
import { arrayLengthDivision } from "../../../../../../helpers/notLengthDivision";
import { LargeTitle, ListCriterion, ListCriterionItem } from "../Status/styles";
import {
  FlexContainer,
  Separator,
} from "../../../../../../components/StyledComponents";
import { RankingItem, RankingTable } from "./styles";
import { BootcampCard } from "../../styles";
import Loader from "../../../../../../components/Loader";

interface PropsComponent {
  selectedCohort?: {
    name: string;
    id: string;
    cohortStudents?: number;
  };
  analyticsStates: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
  analytics: AnalyticsCohortDto;
  stageLabel: string;
  stage: number;
}

const Component = ({
  stage,
  selectedCohort,
  analyticsStates,
  analytics,
  stageLabel,
}: PropsComponent) => {
  if (!analytics) return <Loader color="Primary"></Loader>;
  const bestCriterionList = analytics.skills
    .filter((skill) => skill.stage === stage)
    .reduce((prev: any, current, index, array) => {
      if (prev.find((item) => item.criterion._id === current.skill._id))
        return prev;
      else {
        const promCurrentCriterion = array
          .filter((item) => item.skill._id === current.skill._id)
          .map((item) => item.note);
        return [
          ...prev,
          {
            criterion: current.skill,
            average:
              promCurrentCriterion.reduce(
                (prev, current) => current + prev,
                0
              ) / arrayLengthDivision(promCurrentCriterion),
          },
        ];
      }
    }, [])
    .sort((a, b) => b.average - a.average);
  return (
    <Grid.Row style={{ width: "100%" }}>
      <Grid.Col lg={5.3} sm={12}>
        <BootcampCard>
          <TitleCriterion>Nombre del Cohort:</TitleCriterion>
          <LargeTitle title={selectedCohort?.name}>
            {selectedCohort?.name}
          </LargeTitle>
        </BootcampCard>

        <Separator size={8}></Separator>

        <BootcampCard>
          <TitleStage>Listado de criterios</TitleStage>
          <ListCriterion>
            {bestCriterionList.map((criterion) => (
              <FlexContainer gap="8px" align="center">
                <ListCriterionItem
                  style={{ width: "150px" }}
                  title={criterion.criterion.name}
                >
                  {criterion.criterion.name}
                </ListCriterionItem>
                <ListCriterionItem
                  style={{ minWidth: "50px", textAlign: "center" }}
                  title={criterion.average}
                >
                  {roundedNumbers(criterion.average)}
                </ListCriterionItem>
              </FlexContainer>
            ))}
          </ListCriterion>
        </BootcampCard>
      </Grid.Col>
      <Grid.Col offset={{ lg: 0.4 }} lg={6.3} sm={12}>
        <BootcampCard>
          <TitleCriterion>Usuarios destacados de la Etapa:</TitleCriterion>
          <Separator size={12}></Separator>
          <RankingTable>
            {analytics.topStudents
              .filter((user) => user.stage === stage)[0]
              ?.top.map((user, index) => {
                return (
                  <FlexContainer align="center" wrap="wrap" gap="10px">
                    <ListCriterionItem
                      style={{ width: "150px" }}
                      title={user.student}
                    >
                      {user.student}
                    </ListCriterionItem>
                    <ListCriterionItem
                      style={{ minWidth: "50px", textAlign: "center" }}
                      title={user.avg}
                    >
                      {roundedNumbers(user.avg)}
                    </ListCriterionItem>
                  </FlexContainer>
                );
              })}
          </RankingTable>
        </BootcampCard>
      </Grid.Col>
    </Grid.Row>
  );
};

const states = ({ analyticsStore }) => {
  const { states: analyticsStates, data: analytics } =
    analyticsStore.getCohortStats;
  return {
    analyticsStates,
    analytics,
  };
};

export default connect(states)(Component);
