import { AitTalentDto } from "../../../types/recruiting.dto";

export const GET_ALL_AIT_TALENTS = "GET_ALL_AIT_TALENTS";
export const GET_ALL_AIT_TALENTS_ERROR = "GET_ALL_AIT_TALENTS_ERROR";
export const GET_ALL_AIT_TALENTS_SUCCESS = "GET_ALL_AIT_TALENTS_SUCCESS";
export const GET_AIT_TALENT = "GET_AIT_TALENT";
export const GET_AIT_TALENT_ERROR = "GET_AIT_TALENT_ERROR";
export const GET_AIT_TALENT_SUCCESS = "GET_AIT_TALENT_SUCCESS";
export const NEW_AIT_TALENT = "NEW_AIT_TALENT";
export const NEW_AIT_TALENT_ERROR = "NEW_AIT_TALENT_ERROR";
export const NEW_AIT_TALENT_SUCCESS = "NEW_AIT_TALENT_SUCCESS";
export const RESET_NEW_AIT_TALENT = "RESET_NEW_AIT_TALENT";
export const UPDATE_AIT_TALENT = "UPDATE_AIT_TALENT";
export const UPDATE_AIT_TALENT_ERROR = "UPDATE_AIT_TALENT_ERROR";
export const UPDATE_AIT_TALENT_SUCCESS = "UPDATE_AIT_TALENT_SUCCESS";
export const RESET_UPDATE_AIT_TALENT = "RESET_UPDATE_AIT_TALENT";
export const DELETE_AIT_TALENT = "DELETE_AIT_TALENT";
export const DELETE_AIT_TALENT_ERROR = "DELETE_AIT_TALENT_ERROR";
export const DELETE_AIT_TALENT_SUCCESS = "DELETE_AIT_TALENT_SUCCESS";
export const RESET_DELETE_AIT_TALENT = "RESET_DELETE_AIT_TALENT";
export const UPDATE_MANY_AIT_TALENT = "UPDATE_MANY_AIT_TALENT";
export const UPDATE_MANY_AIT_TALENT_SUCCESS = "UPDATE_MANY_AIT_TALENT_SUCCESS";
export const UPDATE_MANY_AIT_TALENT_ERROR = "UPDATE_MANY_AIT_TALENT_ERROR";
export const RESET_UPDATE_MANY_AIT_TALENT = "RESET_UPDATE_MANY_AIT_TALENT";
export const SET_UPDATE_AIT_TALENTS_DATA = "SET_UPDATE_AIT_TALENTS_DATA";
export const SET_NEW_AIT_TALENTS_DATA = "SET_NEW_AIT_TALENTS_DATA";
export const UPDATE_CURRENT_AIT_TALENTS_LIST =
  "UPDATE_CURRENT_AIT_TALENTS_LIST";

export interface GetAllAitTalents {
  type: typeof GET_ALL_AIT_TALENTS;
  payload: null;
}

export interface GetAllAitTalentsSuccess {
  type: typeof GET_ALL_AIT_TALENTS_SUCCESS;
  payload: AitTalentDto[];
}

export interface GetAllAitTalentsError {
  type: typeof GET_ALL_AIT_TALENTS_ERROR;
  payload: boolean | string;
}

export interface UpdateCurrentAitTalentsList {
  type: typeof UPDATE_CURRENT_AIT_TALENTS_LIST;
  payload: AitTalentDto[];
}

export interface GetAitTalent {
  type: typeof GET_AIT_TALENT;
  payload: { _id: string };
}

export interface GetAitTalentSuccess {
  type: typeof GET_AIT_TALENT_SUCCESS;
  payload: AitTalentDto;
}

export interface GetAitTalentError {
  type: typeof GET_AIT_TALENT_ERROR;
  payload: boolean | string;
}

export interface NewAitTalent {
  type: typeof NEW_AIT_TALENT;
  payload: AitTalentDto;
}

export interface SetUpdateAitTalentData {
  type: typeof SET_UPDATE_AIT_TALENTS_DATA;
  payload: AitTalentDto;
}
export interface SetNewAitTalentData {
  type: typeof SET_NEW_AIT_TALENTS_DATA;
  payload: AitTalentDto;
}

export interface UpdateManyAitTalent {
  type: typeof UPDATE_MANY_AIT_TALENT;
  payload: any;
}

export interface UpdateManyAitTalentSuccess {
  type: typeof UPDATE_MANY_AIT_TALENT_SUCCESS;
  payload: null;
}

export interface UpdateManyAitTalentError {
  type: typeof UPDATE_MANY_AIT_TALENT_ERROR;
  payload: string | boolean;
}

export interface ResetUpdateManyAitTalent {
  type: typeof RESET_UPDATE_MANY_AIT_TALENT;
  payload: null;
}

export interface NewAitTalentSuccess {
  type: typeof NEW_AIT_TALENT_SUCCESS;
  payload: AitTalentDto;
}

export interface NewAitTalentError {
  type: typeof NEW_AIT_TALENT_ERROR;
  payload: boolean | string;
}

export interface ResetNewAitTalent {
  type: typeof RESET_NEW_AIT_TALENT;
  payload: null;
}

export interface UpdateAitTalent {
  type: typeof UPDATE_AIT_TALENT;
  payload: AitTalentDto;
}

export interface UpdateAitTalentSuccess {
  type: typeof UPDATE_AIT_TALENT_SUCCESS;
  payload: null;
}

export interface UpdateAitTalentError {
  type: typeof UPDATE_AIT_TALENT_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateAitTalent {
  type: typeof RESET_UPDATE_AIT_TALENT;
  payload: null;
}

export interface DeleteAitTalent {
  type: typeof DELETE_AIT_TALENT;
  payload: { _id: string };
}

export interface DeleteAitTalentSuccess {
  type: typeof DELETE_AIT_TALENT_SUCCESS;
  payload: null;
}

export interface DeleteAitTalentError {
  type: typeof DELETE_AIT_TALENT_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteAitTalent {
  type: typeof RESET_DELETE_AIT_TALENT;
  payload: null;
}

export interface State {
  all: {
    data: AitTalentDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  selected: {
    data: AitTalentDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  create: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  delete: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  update: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  updateMany: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  all: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  selected: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  create: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  delete: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  update: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  updateMany: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllAitTalents
  | GetAllAitTalentsSuccess
  | GetAllAitTalentsError
  | GetAitTalent
  | GetAitTalentSuccess
  | GetAitTalentError
  | NewAitTalent
  | NewAitTalentSuccess
  | NewAitTalentError
  | ResetNewAitTalent
  | UpdateAitTalent
  | UpdateAitTalentSuccess
  | UpdateAitTalentError
  | ResetUpdateAitTalent
  | DeleteAitTalent
  | DeleteAitTalentSuccess
  | DeleteAitTalentError
  | ResetDeleteAitTalent
  | UpdateManyAitTalent
  | UpdateManyAitTalentSuccess
  | UpdateManyAitTalentError
  | ResetUpdateManyAitTalent
  | SetUpdateAitTalentData
  | SetNewAitTalentData
  | UpdateCurrentAitTalentsList;
