import React from "react";
import { Img } from "../../../../pages/Dashboard/Cohort/Customize/style";
import { FlexContainer } from "../../../StyledComponents";
import { AppItemContainer } from "./style";

const Component = ({ logo }) => {
  return (
    <AppItemContainer href={"https://academia.catel.org.ar/sign-in"} target="_autoblank">
      <FlexContainer gap="10px" align="center">
        <div style={{ height: "20px" }}>
          <Img src={logo}></Img>
        </div>
      </FlexContainer>
    </AppItemContainer>
  );
};

export default Component;
