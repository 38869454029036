import action from "../helpers/rest-client";

export class ErrorStatics extends Error {}

class Statics {
  public getAllProvinces(payload) {
    return action
      .Get({
        url: `/statics/provinces/${payload}`,
        body: payload,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const statics = new Statics();
export default statics;
