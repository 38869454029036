import { put, call, all, takeLatest } from "redux-saga/effects";
import services from "../../services";
import {
  NewAssociation,
  NEW_ASSOCIATION,
  NEW_ASSOCIATION_ERROR,
  NEW_ASSOCIATION_SUCCESS,
  DELETE_ASSOCIATION_ERROR,
  DELETE_ASSOCIATION_SUCCESS,
  GetAllAssociation,
  GET_ALL_ASSOCIATION,
  DELETE_ASSOCIATION,
  DeleteAssociation,
  GET_ALL_ASSOCIATION_ERROR,
  GET_ALL_ASSOCIATION_SUCCESS,
  UpdateAssociation,
  UPDATE_ASSOCIATION,
  UPDATE_ASSOCIATION_SUCCESS,
  UPDATE_ASSOCIATION_ERROR,
} from "../types/association";

function* getAllAssociations() {
  const { response, error } = yield call(services.association.getAll);

  if (error) {
    yield put({
      type: GET_ALL_ASSOCIATION_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_ASSOCIATION_SUCCESS, payload: response });
  }
}

function* updateAssociation({ payload }) {
  const { response, error } = yield call(services.association.update, payload);

  if (error) {
    yield put({
      type: UPDATE_ASSOCIATION_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([
      put({ type: UPDATE_ASSOCIATION_SUCCESS, payload: response }),
      put({ type: GET_ALL_ASSOCIATION, payload: null }),
    ]);
  }
}

function* newAssociation({ payload }) {
  const { response, error } = yield call(services.association.new, payload);

  if (error) {
    yield put({
      type: NEW_ASSOCIATION_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([
      put({ type: NEW_ASSOCIATION_SUCCESS, payload: response }),
      put({ type: GET_ALL_ASSOCIATION, payload: null }),
    ]);
  }
}

function* deleteAssociation({ payload }) {
  const { response, error } = yield call(services.association.delete, payload);

  if (error) {
    yield put({
      type: DELETE_ASSOCIATION_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([
      put({ type: DELETE_ASSOCIATION_SUCCESS, payload: response }),
      put({ type: GET_ALL_ASSOCIATION, payload: null }),
    ]);
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllAssociation>(GET_ALL_ASSOCIATION, getAllAssociations);
  yield takeLatest<DeleteAssociation>(DELETE_ASSOCIATION, deleteAssociation);
  yield takeLatest<NewAssociation>(NEW_ASSOCIATION, newAssociation);
  yield takeLatest<UpdateAssociation>(UPDATE_ASSOCIATION, updateAssociation);
}
