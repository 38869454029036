import {
  NewAssociation,
  NEW_ASSOCIATION,
  ResetNewAssociation,
  RESET_NEW_ASSOCIATION,
  GetAllAssociation,
  GET_ALL_ASSOCIATION,
  ResetGetAllAssociation,
  RESET_GET_ALL_ASSOCIATION,
  DELETE_ASSOCIATION,
  DeleteAssociation,
  RESET_DELETE_ASSOCIATION,
  ResetDeleteAssociation,
  UPDATE_ASSOCIATION,
  UpdateAssociation,
  RESET_UPDATE_ASSOCIATION,
  ResetUpdateAssociation,
} from "../types/association";

const create = (payload): NewAssociation => {
  return { type: NEW_ASSOCIATION, payload };
};
const resetCreate = (): ResetNewAssociation => {
  return { type: RESET_NEW_ASSOCIATION, payload: null };
};

const getAll = (): GetAllAssociation => {
  return { type: GET_ALL_ASSOCIATION, payload: null };
};

const resetGetAll = (): ResetGetAllAssociation => {
  return { type: RESET_GET_ALL_ASSOCIATION, payload: null };
};

const deleteOne = (payload: { _id: string }): DeleteAssociation => {
  return { type: DELETE_ASSOCIATION, payload };
};

const resetDeleteOne = (): ResetDeleteAssociation => {
  return { type: RESET_DELETE_ASSOCIATION, payload: null };
};

const update = (payload): UpdateAssociation => {
  return { type: UPDATE_ASSOCIATION, payload };
};

const resetUpdate = (): ResetUpdateAssociation => {
  return { type: RESET_UPDATE_ASSOCIATION, payload: null };
};

const actions = {
  create,
  resetGetAll,
  resetCreate,
  resetDeleteOne,
  getAll,
  deleteOne,
  update,
  resetUpdate,
};

export default actions;
