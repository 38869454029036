import action from "./rest-client";

export function getLinkedinUrl(from: string) {
  const rootURl = "https://www.linkedin.com/oauth/v2/authorization";

  const options = {
    client_id: "78jgp9xqr236wx",
    client_secret: "2jtvLFjKhKQ3Gg8K",
    redirect_uri: `${process.env.REACT_APP_URL}/sign-in`,
    scope: "r_emailaddress r_liteprofile",
    state: `${from}&social=linkedin`,
  };

  const qs = new URLSearchParams(options);

  return `${rootURl}?response_type=code&${qs.toString()}`;
}

export async function getLinkedinAccessToken(code) {
  return action
    .Post({ url: "/auth/linkedin", body: { code } })
    .then((response) => {
      return { response, error: null };
    })
    .catch((response) => {
      return { response: null, error: response };
    });

  /*   return action
    .Post({
      url: `${rootURl}${params}`,
    })
    .then((response) => {
      return { response, error: null };
    })
    .catch((error) => {
      return { error, response: null };
    }); */
}
