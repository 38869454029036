export const setFormData = (payload, fileName) => {
  const formData = new FormData();
  for (let key in payload) {
    const currentElement = payload[key];
    if (fileName.includes(key) && !!currentElement) {
      formData.append(key, currentElement, currentElement.name);
    } else {
      if (Array.isArray(currentElement)) {
        formData.append(key, JSON.stringify(currentElement));
      } else {
        formData.append(key, currentElement);
      }
    }
  }
  return formData;
};
