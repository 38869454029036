import styled from "styled-components";

export const RankingTable = styled("div")`
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: ${(props) => props.height}px;
  width: 100%;
  gap: 5px;
`;

export const RankingItem = styled("div")`
  width: 100%;
  padding: 4px 4px 4px 10px;
  border-radius: 10px;
  box-shadow: 0 0 3px 0 rgba(21, 115, 229, 0.16);
  background-color: #fff;
`;
