import {
  NewAcademy,
  NEW_ACADEMY,
  ResetNewAcademy,
  RESET_NEW_ACADEMY,
  GET_ALL_ACADEMIES,
  GetAllAcademies,
  UpdateAcademy,
  UPDATE_ACADEMY,
  ResetUpdateAcademy,
  RESET_UPDATE_ACADEMY,
  GetAcademy,
  DeleteAcademy,
  DELETE_ACADEMY,
  GET_ACADEMY,
  RESET_DELETE_ACADEMY,
  NEW_INVITE_LINK,
  RESET_NEW_INVITE_LINK,
  DELETE_INVITE_LINK,
  RESET_DELETE_INVITE_LINK,
  SELECT_ACADEMY,
  UPDATE_ACADEMY_ROLE,
  DELETE_ACADEMY_MEMBER,
  DeleteAcademyMember,
  AcademyDissociateOrganization,
  ACADEMY_DISSOCIATE_ORGANIZATION,
  AddAcademyMember,
  ADD_ACADEMY_MEMBER,
  RESET_GET_ALL_ACADEMIES_DATA,
  SET_ACADEMY_MP,
  RESET_GET_ACADEMY,
  NewAcademyPlan,
  NEW_ACADEMY_PLAN,
  UpdateAcademyPlan,
  UPDATE_ACADEMY_PLAN,
} from "../types/academy";

const create = (payload): NewAcademy => {
  return { type: NEW_ACADEMY, payload };
};
const resetCreate = (): ResetNewAcademy => {
  return { type: RESET_NEW_ACADEMY, payload: null };
};

const update = (payload): UpdateAcademy => {
  return { type: UPDATE_ACADEMY, payload };
};

const resetGetAllAcademiesData = () => {
  return { type: RESET_GET_ALL_ACADEMIES_DATA, payload: null };
};

const resetUpdate = (): ResetUpdateAcademy => {
  return { type: RESET_UPDATE_ACADEMY, payload: null };
};

const getAll = (payload): GetAllAcademies => {
  return { type: GET_ALL_ACADEMIES, payload };
};

const addMember = (payload): AddAcademyMember => {
  return { type: ADD_ACADEMY_MEMBER, payload };
};

const setMp = (payload) => {
  return { type: SET_ACADEMY_MP, payload };
};

const resetGetOne = () => {
  return { type: RESET_GET_ACADEMY, payload: null };
};

const getOne = (payload: { _id: string }): GetAcademy => {
  return { type: GET_ACADEMY, payload };
};

const deleteOne = (payload: { _id: string }): DeleteAcademy => {
  return { type: DELETE_ACADEMY, payload };
};

const resetDeleteOne = () => {
  return { type: RESET_DELETE_ACADEMY, payload: null };
};

const dissociateOrganization = (payload: {
  _id: string;
  organizationId: string;
}): AcademyDissociateOrganization => {
  return { type: ACADEMY_DISSOCIATE_ORGANIZATION, payload };
};

const newLink = (payload) => {
  return { type: NEW_INVITE_LINK, payload };
};

const resetNewLink = () => {
  return { type: RESET_NEW_INVITE_LINK, payload: null };
};

const deleteLink = (payload) => {
  return { type: DELETE_INVITE_LINK, payload };
};

const resetDeleteLink = () => {
  return { type: RESET_DELETE_INVITE_LINK, payload: null };
};

const selectOne = (payload) => {
  return { type: SELECT_ACADEMY, payload };
};

const updateRole = (payload) => {
  return { type: UPDATE_ACADEMY_ROLE, payload };
};

const deleteMember = (payload): DeleteAcademyMember => {
  return { type: DELETE_ACADEMY_MEMBER, payload };
};

const newPlan = (payload): NewAcademyPlan => {
  return { type: NEW_ACADEMY_PLAN, payload };
};
const updatePlan = (payload): UpdateAcademyPlan => {
  return { type: UPDATE_ACADEMY_PLAN, payload };
};

const actions = {
  resetDeleteLink,
  deleteMember,
  updateRole,
  selectOne,
  deleteLink,
  newLink,
  resetNewLink,
  create,
  resetCreate,
  update,
  resetUpdate,
  resetDeleteOne,
  getAll,
  getOne,
  deleteOne,
  dissociateOrganization,
  addMember,
  resetGetAllAcademiesData,
  setMp,
  resetGetOne,
  newPlan,
  updatePlan,
};

export default actions;
