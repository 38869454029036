import React from "react";
import { checkPermission } from "../PermissionsChecker";
import { connect } from "react-redux";
import { Outlet } from "react-router-dom";

const Component = ({
  action,
  current,
  element,
  children,
  unique,
}: {
  action: string;
  current: any;
  element?;
  children?;
  unique?;
}) => {
  return checkPermission({
    role: current?.role,
    action,
    section: current?.currentSection,
  })
    ? element
    : null;
};

const states = ({ currentStore }) => {
  const { data: current } = currentStore;
  return {
    current,
  };
};

export default connect(states)(Component);
