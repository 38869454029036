import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import Button from "../../../../../../components/Button";
import Grid from "../../../../../../components/Grid";
import Loader from "../../../../../../components/Loader";
import {
  FlexContainer,
  PageTitle,
} from "../../../../../../components/StyledComponents";
import { DateEStoDateEN } from "../../../../../../helpers/format-date";
import { getAcademyStats } from "../../../../../../store/actions/analytics";
import AnalyticCard from "../../../components/AnalyticCard";
import { PDFExport } from "@progress/kendo-react-pdf";
import {
  formatNumber,
  overThousandFixed,
} from "../../../../../../helpers/number-fixed";
import { AcademyAnalyticsDto } from "../../../../../../types/analytics.dto";
import { StateDto } from "../../../../../../types/states.dto";
import { CurrentDto } from "../../../../../../types/current.dto";

const Component = ({
  analyticsAcademy,
  analyticsAcademyStates,
  current,
}: {
  analyticsAcademy: AcademyAnalyticsDto;
  analyticsAcademyStates: StateDto;
  current: CurrentDto;
}) => {
  const printRef: any = React.useRef();
  const dispatch = useDispatch();
  const handleExportWithComponent = () => {
    printRef.current.save();
  };
  useEffect(() => {
    dispatch(getAcademyStats({ _id: current.id }));
  }, []);
  if (!analyticsAcademy) return <Loader color="Primary"></Loader>;

  return (
    <>
      <PageTitle>Dashboard</PageTitle>
      <Grid.Col offset={{ sm: 10 }} sm={12}>
        <Button
          onClick={() => {
            handleExportWithComponent();
          }}
          type="button"
          options={{
            type: "filled",
            skin: "primary",
            size: "md",
            marginBottom: "10px",
          }}
          style={{ marginLeft: "10px" }}
        >
          Descargar
        </Button>
      </Grid.Col>
      <PDFExport
        fileName={`Constana General ${new Date().valueOf()}`}
        ref={printRef}
      >
        <FlexContainer direction="column" gap="6px">
          <AnalyticCard
            title={"Interacciones con talentos"}
            graphTitle={"Interacciones con talentos - Crecimiento Histórico"}
            data={{
              stats: [
                {
                  label: "Últimos 30 días",
                  value: overThousandFixed(
                    analyticsAcademy.talents.clicks.lastMonth
                  ),
                },
                {
                  label: "Últimos 7 días",
                  value: overThousandFixed(
                    analyticsAcademy.talents.clicks.lastWeek
                  ),
                },
                {
                  label: "Últimas 24 horas",
                  value: overThousandFixed(
                    analyticsAcademy.talents.clicks.lastDay
                  ),
                },
              ],
              graphData: Object.entries(analyticsAcademy.talents.clicks.growth)
                .sort((a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0]))
                .map((growthData) => {
                  return {
                    name: growthData[0],
                    value: formatNumber(growthData[1]),
                  };
                }),
            }}
          ></AnalyticCard>
          <AnalyticCard
            title={"Interacciones con Cohorts"}
            graphTitle={"Interacciones con Cohorts - Crecimiento Histórico"}
            data={{
              stats: [
                {
                  label: "Últimos 30 días",
                  value: overThousandFixed(
                    analyticsAcademy.cohorts.clicks.lastMonth
                  ),
                },
                {
                  label: "Últimos 7 días",
                  value: overThousandFixed(
                    analyticsAcademy.cohorts.clicks.lastWeek
                  ),
                },
                {
                  label: "Últimas 24 horas",
                  value: overThousandFixed(
                    analyticsAcademy.cohorts.clicks.lastDay
                  ),
                },
              ],
              graphData: Object.entries(analyticsAcademy.cohorts.clicks.growth)
                .sort((a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0]))
                .map((growthData) => {
                  return {
                    name: growthData[0],
                    value: formatNumber(growthData[1]),
                  };
                }),
            }}
          ></AnalyticCard>
          <AnalyticCard
            title={"Vistas de Talentos"}
            graphTitle={"Vistas de Talentos - Crecimiento Histórico"}
            data={{
              stats: [
                {
                  label: "Últimos 30 días",
                  value: overThousandFixed(
                    analyticsAcademy.talents.views.lastMonth
                  ),
                },
                {
                  label: "Últimos 7 días",
                  value: overThousandFixed(
                    analyticsAcademy.talents.views.lastWeek
                  ),
                },
                {
                  label: "Últimas 24 horas",
                  value: overThousandFixed(
                    analyticsAcademy.talents.views.lastDay
                  ),
                },
              ],
              graphData: Object.entries(analyticsAcademy.talents.views.growth)
                .sort((a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0]))
                .map((growthData) => {
                  return {
                    name: growthData[0],
                    value: formatNumber(growthData[1]),
                  };
                }),
            }}
          ></AnalyticCard>
          <AnalyticCard
            title={"Vistas de Cohorts"}
            graphTitle={"Vistas de Cohorts - Crecimiento Histórico"}
            data={{
              stats: [
                {
                  label: "Últimos 30 días",
                  value: overThousandFixed(
                    analyticsAcademy.cohorts.views.lastMonth
                  ),
                },
                {
                  label: "Últimos 7 días",
                  value: overThousandFixed(
                    analyticsAcademy.cohorts.views.lastWeek
                  ),
                },
                {
                  label: "Últimas 24 horas",
                  value: overThousandFixed(
                    analyticsAcademy.cohorts.views.lastDay
                  ),
                },
              ],
              graphData: Object.entries(analyticsAcademy.cohorts.views.growth)
                .sort((a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0]))
                .map((growthData) => {
                  return {
                    name: growthData[0],
                    value: formatNumber(growthData[1]),
                  };
                }),
            }}
          ></AnalyticCard>
        </FlexContainer>
      </PDFExport>
    </>
  );
};

const states = ({ analyticsStore, currentStore }) => {
  const { data: analyticsAcademy, states: analyticsAcademyStates } =
    analyticsStore.getAcademyStats;
  const { data: current } = currentStore;
  return {
    analyticsAcademy,
    analyticsAcademyStates,
    current,
  };
};

export default connect(states)(Component);
