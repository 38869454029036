import React, { useEffect, useState } from "react";
import { ChildrenSelectorWrapper } from "../../style";
import Grid from "../../../../../../components/Grid";
import InfinityScroll from "../../../../../../components/InfinityScroll";
import { FetchData } from "../../../../../../types/fetchData.dto";
import { connect, useDispatch } from "react-redux";
import academyActions from "../../../../../../store/actions/academies";
import { GridStructure } from "../../../../../../components/Layout/Dashboard/styles";
import { AcademyDto } from "../../../../../../types/academy.dto";
import {
  FlexContainer,
  Separator,
} from "../../../../../../components/StyledComponents";
import AcademyCard from "./components/AcademyCard";
import { StateDto } from "../../../../../../types/states.dto";
import {
  getAllTags,
  resetGetAllTags,
} from "../../../../../../store/actions/tag";
import {
  getAllCohorts,
  resetGetAllCohortsData,
} from "../../../../../../store/actions/cohort";
import { CohortDto } from "../../../../../../types/cohort.dto";
import { TagDto } from "../../../../../../types/tag.dto";
import { UserDto } from "../../../../../../types/user.dto";
import FilterByName from "../../../../Content/components/FilterByName";
import SelectCustomItem from "../../../../../../components/SelectCustomItem";
import {
  Link,
  LinkImageWrapper,
  LinkRole,
  LinkText,
} from "../../../../../../components/Header/components/ListItems/style";
import { Img } from "../../../../../SignIn/styles";
import { useNavigate } from "react-router-dom";
import { setCurrent } from "../../../../../../store/actions/current";
import { useWindowSize } from "../../../../../../hooks/useWindowScreen";

const Component = ({
  academies,
  getAcademiesStates,
  cohorts,
  tags,
  user,
}: {
  academies: AcademyDto[];
  getAcademiesStates: StateDto;
  cohorts: CohortDto[];
  tags: TagDto[];
  user: UserDto;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const buildFilters = (newFilter) => {
    const filterUpdated =
      applyedFilters.filter((applyedFilter) =>
        !Array.isArray(newFilter.field)
          ? applyedFilter.field === newFilter.field
          : newFilter.field.includes(applyedFilter.field)
      ).length > 0;

    const filterRemoved = newFilter.value === "no";
    if (filterRemoved) {
      /**
       * Se elimina el filtro
       */
      if (Array.isArray(newFilter.field)) {
        setFilters([
          ...applyedFilters.filter(
            (applyedFilter) => !newFilter.field.includes(applyedFilter.field)
          ),
        ]);
      } else {
        setFilters([
          ...applyedFilters.filter(
            (applyedFilter) => applyedFilter.field !== newFilter.field
          ),
        ]);
      }
    }

    /**
     * Se actualiza un filtro
     */
    if (filterUpdated && !filterRemoved) {
      setFilters([
        ...applyedFilters.map((applyedFilter) => {
          if (applyedFilter.field === newFilter.field) {
            return newFilter;
          } else {
            return applyedFilter;
          }
        }),
      ]);
    }

    /**
     * Se agrega un filtro
     */
    if (!filterUpdated && !filterRemoved) {
      setFilters([...applyedFilters, newFilter]);
    }
  };
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);
  const [paginationOn, setPaginationOn] = useState<boolean>(false);
  const [fetchOn, setFetchOn] = useState<boolean>(false);
  const [currentAcademiesList, setCurrentAcademiesList] = useState<
    AcademyDto[]
  >([]);
  const [currentCohortsFromAcademies, setCurrentCohortsFromAcademies] =
    useState<CohortDto[]>([]);
  const [currentCohortsFromTags, setCurrentCohortsFromTags] = useState<
    TagDto[]
  >([]);
  const [applyedFilters, setFilters] = useState<
    {
      field: string;
      value: string | boolean;
      special: (data?: any, data2?: any) => boolean;
      groupal: (...args: any) => any[];
    }[]
  >([]);
  const [
    filteredCurrentAcademiesListValues,
    setFilteredCurrentAcademiesListValues,
  ] = useState<AcademyDto[]>([]);
  const fetchData = ({ page, filterBy }: FetchData) => {
    if (page === 0) {
      setPaginationOn(true);
    }
    let payload = {
      limit: 9,
      offset: 9 * page,
      filterBy: {
        ...(filterBy ? filterBy : {}),
        active: true,
        users: {
          $elemMatch: {
            user: user._id,
            roleEnd: { $exists: false },
          },
        },
      },
    };
    const sortBy = "createdAt";
    const select = {
      _id: 1,
      name: 1,
      picture: 1,
      bannerPic: 1,
      userCount: 1,
      viewCount: 1,
      __v: 1,
      users: 1,
    };
    const sortDirection = "desc";
    dispatch(
      academyActions.getAll({ ...payload, sortBy, sortDirection, select })
    );
  };

  useEffect(() => {
    const filteredTalents = currentAcademiesList.filter(
      (currentTalentValues) => {
        const thereAreFilters =
          applyedFilters.filter((applyedFilter) => !applyedFilter.groupal)
            .length > 0;
        const totalOfFilters = applyedFilters.filter(
          (applyedFilter) => !applyedFilter.groupal
        ).length;
        const passFilters = applyedFilters
          .filter(
            (applyedFilter) => !applyedFilter.special || !applyedFilter.groupal
          )
          .filter(
            (applyedFilter) =>
              currentTalentValues[applyedFilter.field] === applyedFilter.value
          ).length;
        const specialFilter = applyedFilters.filter(
          (applyedFilter) => !!applyedFilter.special
        );

        const speacialFilterCheck = specialFilter.filter((applyedFilter) => {
          return applyedFilter.special(
            currentTalentValues,
            applyedFilter.value
          );
        });
        return (
          !thereAreFilters ||
          (thereAreFilters &&
            totalOfFilters === passFilters + specialFilter.length &&
            speacialFilterCheck.length === specialFilter.length)
        );
      }
    );
    let initResult = filteredTalents;
    applyedFilters
      .filter((applyedFilter) => !!applyedFilter.groupal)
      .forEach((applyedFilter) => {
        initResult = applyedFilter.groupal(initResult);
      });
    setFilteredCurrentAcademiesListValues(initResult);
  }, [applyedFilters, currentAcademiesList]);
  useEffect(() => {
    setIsLoading(getAcademiesStates.loading);
  }, [getAcademiesStates]);

  useEffect(() => {
    if (paginationOn && !isLoading && academies) {
      setCurrentAcademiesList((state) => [...state, ...academies]);
      setThereAreItems(academies.length > 0);
      dispatch(
        getAllTags({
          filterBy: {
            active: true,
            academy: { $in: academies.map((academy) => academy._id) },
          },
        })
      );
      dispatch(
        getAllCohorts({
          filterBy: {
            active: true,
            academy: { $in: academies.map((academy) => academy._id) },
          },
        })
      );
    }
  }, [academies, isLoading]);

  useEffect(() => {
    if (fetchOn && cohorts) {
      setCurrentCohortsFromAcademies((state) => [...state, ...cohorts]);
    }
  }, [cohorts, fetchOn]);
  useEffect(() => {
    if (fetchOn && tags) {
      setCurrentCohortsFromTags((state) => [...state, ...tags]);
    }
  }, [tags, fetchOn]);

  useEffect(() => {
    setFetchOn(!tags && !cohorts && !fetchOn);
  }, [tags, cohorts]);

  useEffect(() => {
    return () => {
      dispatch(academyActions.resetGetAllAcademiesData());
      dispatch(resetGetAllTags());
      dispatch(resetGetAllCohortsData());
    };
  }, []);

  return (
    <ChildrenSelectorWrapper>
      <div style={{ maxWidth: "400px" }}>
        <SelectCustomItem
          items={filteredCurrentAcademiesListValues.map((academy) => {
            return {
              label: (
                <Link
                  onClick={() => {
                    navigate("/dashboard/contents/video");
                    dispatch(
                      setCurrent({
                        currentSection: "academy",
                        id: academy._id,
                        role:
                          academy.users.filter(
                            (userF) => userF.user === user._id && !userF.roleEnd
                          )[0].role ?? "OBSERVER",
                      })
                    );
                  }}
                >
                  <FlexContainer
                    justify="space-between"
                    wrap="wrap"
                    gap="2px"
                    align="center"
                  >
                    <FlexContainer gap="4px" align="center">
                      <LinkImageWrapper>
                        <Img
                          src={academy.picture}
                          alt="academy-picture"
                          title={academy.name}
                        ></Img>
                      </LinkImageWrapper>
                      <LinkText hideLine={2}>{academy.name}</LinkText>
                    </FlexContainer>
                    <LinkRole>
                      {academy.users.filter(
                        (userF) => userF.user === user._id && !userF.roleEnd
                      )[0].role ?? "OBSERVER"}
                    </LinkRole>
                  </FlexContainer>
                </Link>
              ),
              value: academy._id,
            };
          })}
          options={{
            size: "md",
            skin: "base",
            loading: filteredCurrentAcademiesListValues.length === 0,
          }}
          style={{ border: "solid 1px rgba(128, 128, 128, 0.5)" }}
        />
      </div>
      <Separator size={12}></Separator>
      <InfinityScroll
        action={fetchData}
        page={page}
        setPage={setPage}
        data={academies}
        active={thereAreItems}
        isLoading={isLoading}
        style={{
          overflowY: "auto",
          maxHeight: isMobile ? "500px" : "400px",
          paddingBottom: "4px",
        }}
      >
        <GridStructure
          style={{ padding: isMobile ? "4px" : "2px" }}
          width={isMobile ? "200px" : "340px"}
        >
          {filteredCurrentAcademiesListValues.map((item) => {
            const fullAcademyItem = {
              ...item,
              cohorts: currentCohortsFromAcademies
                .filter((cohort) => cohort.academy._id === item._id)
                .slice(0, 3),
              tags: currentCohortsFromTags
                .filter((tag) => tag.academy === item._id)
                .slice(0, 3),
              role: item.users.find(
                (userAcademy) =>
                  userAcademy.user === user._id && !userAcademy.roleEnd
              )?.role,
            };
            return (
              <AcademyCard key={item._id} item={fullAcademyItem}></AcademyCard>
            );
          })}
        </GridStructure>
      </InfinityScroll>
    </ChildrenSelectorWrapper>
  );
};

const states = ({ academyStore, cohortStore, tagStore, userStore }) => {
  const { data: academies, states: getAcademiesStates } = academyStore.all;
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: tags } = tagStore.all;
  const { data: user } = userStore;
  return {
    academies,
    getAcademiesStates,
    cohorts,
    tags,
    user,
  };
};

export default connect(states)(Component);
