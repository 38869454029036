const permissions = {
  static: [
    "route:table:preview",
    "route:table:create",
    "route:table:edit",
    "route:home:preview",
    "route:profile:preview",
    "route:analytics:preview",
    "route:personal:analytics:bootcamp",
    "route:personal:analytics:individual",
    "route:calendar:preview",
    "route:team:preview",
    "route:analytics:preview",
    "route:marketplace:preview",
    "route:notification:preview",
    "route:integrations:preview",
    "route:template:preview",
    "route:certificate:preview",
    "route:config:preview",
    "route:suscribe:preview",
    "route:form:preview",
    "route:management:preview",

    "route:dashboard:preview",
    "route:dashboard:cohorts:preview",
    "route:dashboard:cohorts:cohort:preview",
    "route:dashboard:cohorts:individual:preview",
  ],
  dynamic: {
    "op:edit:state": ({ status }) => {
      if (!status) return false;
      return ["requested", "pending", "inProgress", "done"].includes(status);
    },
  },
};

export default { USER: permissions };
