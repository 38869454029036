import {
  GetAllBytes,
  GET_ALL_BYTES,
  GetByte,
  GET_BYTE,
  UpdateByte,
  UPDATE_BYTE,
  DeleteByte,
  DELETE_BYTE,
  GetByteSuccess,
  GET_BYTE_SUCCESS,
  NewByte,
  NEW_BYTE,
  ResetNewByte,
  RESET_NEW_BYTE,
  ResetUpdateByte,
  RESET_UPDATE_BYTE,
  ResetDeleteByte,
  RESET_DELETE_BYTE,
  UploadMedia,
  UPLOAD_MEDIA,
  RESET_GET_BYTE,
  ADD_USER_TO_BYTE,
  SHARED_BYTE,
  RESET_UPDATE_MANY_BYTE,
  UPDATE_MANY_BYTE,
  RESET_GET_ALL_BYTE_DATA,
} from "../types/byte";

export const getAllBytes = (payload): GetAllBytes => {
  return { type: GET_ALL_BYTES, payload };
};

export const resetGetAllBytes = () => {
  return { type: RESET_GET_ALL_BYTE_DATA, payload: null };
};

export const getByte = (id): GetByte => {
  return { type: GET_BYTE, payload: id };
};

export const resetGetByte = () => {
  return { type: RESET_GET_BYTE, payload: null };
};

export const setSelectedByte = (payload): GetByteSuccess => {
  return { type: GET_BYTE_SUCCESS, payload };
};

export const newByte = (payload): NewByte => {
  return { type: NEW_BYTE, payload };
};
export const uploadMediaByte = (payload): UploadMedia => {
  return { type: UPLOAD_MEDIA, payload };
};
export const resetNewByte = (): ResetNewByte => {
  return { type: RESET_NEW_BYTE, payload: null };
};

export const updateManyByte = (payload) => {
  return { type: UPDATE_MANY_BYTE, payload };
};

export const resetUpdateManyByte = () => {
  return { type: RESET_UPDATE_MANY_BYTE, payload: null };
};

export const updateByte = (payload): UpdateByte => {
  return { type: UPDATE_BYTE, payload };
};

export const resetUpdateByte = (): ResetUpdateByte => {
  return { type: RESET_UPDATE_BYTE, payload: null };
};

export const addUserToByte = (payload) => {
  return { type: ADD_USER_TO_BYTE, payload };
};

export const deleteByte = (payload): DeleteByte => {
  return { type: DELETE_BYTE, payload };
};

export const resetDeleteByte = (): ResetDeleteByte => {
  return { type: RESET_DELETE_BYTE, payload: null };
};

export const shareByte = (payload) => {
  return { type: SHARED_BYTE, payload };
};
