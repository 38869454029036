import { CSSProperties, ReactElement } from "react";

export const formatNotificationMessage = (
  text: string,
  strongStyles: CSSProperties,
  normalStyles: CSSProperties
) => {
  const splittedText = text.split("*"); //["*","texto","*"]
  let formattedText: ReactElement[] = [];
  for (let i = 0; i < splittedText.length; i++) {
    if (i % 2 === 0) {
      formattedText.push(
        <span style={normalStyles} key={i}>
          {splittedText[i]}
        </span>
      );
    } else {
      formattedText.push(
        <span style={strongStyles} key={i}>
          {splittedText[i]}
        </span>
      );
    }
  }
  return formattedText;
};

const Component = ({
  text,
  strongTextStyle,
  normalTextStyle,
}: {
  text: string;
  strongTextStyle: CSSProperties;
  normalTextStyle: CSSProperties;
}) => {
  return (
    <>
      {formatNotificationMessage(text, strongTextStyle, normalTextStyle).map(
        (el) => el
      )}
    </>
  );
};

export default Component;
