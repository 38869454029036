const permissions = {
  static: [
    "route:dashboard:preview",
    "route:marketplace:preview",
    "route:dashboard:cohorts:preview",
    "route:dashboard:cohorts:cohort:preview",
    "route:dashboard:cohorts:individual:preview",
    "route:management:marketplace:preview",
  ],
  dynamic: {
    "op:edit:state": ({ status }) => {
      if (!status) return false;
      return ["requested", "pending", "inProgress", "done"].includes(status);
    },
  },
};

export default permissions;
