import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  PageTitle,
  Center,
  FlexContainer,
} from "../../../../components/StyledComponents";
import TalentCard from "../components/TalentCard";
import Grid from "../../../../components/Grid";
import { TalentDto } from "../../../../types/talent.dto";

import { CohortDto } from "../../../../types/cohort.dto";
import { AbilityDto } from "../../../../types/ability.dto";
import { StudentDto } from "../../../../types/student.dto";
import actionData from "../../../../store/actions/recruiting/ait-talent";
import FilterByName from "./components/FilterByName";
import FilterByEnglish from "./components/FilterByEnglish";
import FilterByLocation from "./components/FilterByLocation";
import {
  getManyIndividualStats,
  resetGetAllStudentsStats,
} from "../../../../store/actions/analytics";
import { AitTalentDto } from "../../../../types/recruiting.dto";
import {
  getAllCohorts,
  resetGetAllCohortsData,
} from "../../../../store/actions/cohort";
import { UsersDto } from "../../../../types/users.dto";
import { UserDto } from "../../../../types/user.dto";
import { CurrentDto } from "../../../../types/current.dto";
import { AcademyDto } from "../../../../types/academy.dto";
import TeamModal from "../components/TeamModal";
import { GridSwitchStructure } from "../../../../components/Layout/Dashboard/styles";
import { GridButton } from "./styles";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { BsGrid } from "react-icons/bs";
import ObserverModal from "../components/ObserverModal";
import usersActions from "../../../../store/actions/users";
import { FetchData } from "../../../../types/fetchData.dto";
import InfinityScroll from "../../../../components/InfinityScroll";
import { StateDto } from "../../../../types/states.dto";
import { AnalyticsUserDto } from "../../../../types/analytics.dto";
import { filterCohortByRole } from "../../../../helpers/roleAcademy";
import Loader from "../../../../components/Loader";
export interface CurrentTalentValues {
  _id?: string;
  userId?: string;
  cohorts?: CohortDto[];
  avatar?: string;
  country: string;
  province: string;
  english: string;
  email?: string;
  portfolio?: string;
  linkedin?: string;
  curriculum?: string;
  comments?: string;
  active: boolean;
  hidden: boolean;
}

export interface CurrentTalent {
  _id?: string;
  userId: string;
  cohorts: CohortDto[];
  bootcampName: string;
  name: string;
  hidden: boolean;
  lastName: string;
  avatar?: string;
  country?: string;
  province?: string;
  english?: string;
  email?: string;
  portfolio?: string;
  linkedin?: string;
  curriculum?: string;
  comments?: string;
  active: boolean;
}

interface Option {
  label: string;
  value: string;
}
interface ComponentProps {
  abilities: AbilityDto[];
  cohorts: CohortDto[];
  students: StudentDto[];
  talents: TalentDto[];
  provinces: Option[];
  aitTalents: AitTalentDto[];
  usersStates: StateDto;
  studentsStatsStates: {
    loading: boolean;
    error: boolean | string;
    success: boolean;
  };
  studentsStats: any;
  users: UsersDto[];
  user: UserDto;
  current: CurrentDto;
  academies: AcademyDto[];
}

const Component = ({
  abilities,
  cohorts,
  students,
  talents,
  provinces,
  studentsStatsStates,
  aitTalents,
  studentsStats,
  users,
  user,
  current,
  academies,
  usersStates,
}: ComponentProps) => {
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);
  const [paginationOn, setPaginationOn] = useState<boolean>(false);
  const [grid, setGrid] = useState<boolean>(false);
  const [talentSelected, setTalentSelected] = useState<any>();
  const [fetchOn, setFetchOn] = useState<boolean>(false);
  const [usersIdsForAnalytics, setUserIdsForAnalytics] = useState<
    { _id: string; cohorts: string[] }[]
  >([]);
  const [analyticsFromUsers, setAnalyticsFromUsers] = useState<
    AnalyticsUserDto[]
  >([]);
  const [applyedFilters, setFilters] = useState<
    {
      field: string;
      value: string | boolean;
      special: (data?: any, data2?: any) => boolean;
      groupal: (...args: any) => any[];
    }[]
  >([]);

  const [currentTalentsListValues, setCurrentTalentsListValues] = useState<
    UsersDto[]
  >([]);
  const [studentsIdsFromCohort, setStudentsIdsFromCohort] =
    useState<string[]>();
  const [
    filteredCurrentTalentsListValues,
    setFilteredCurrentTalentsListValues,
  ] = useState<UsersDto[]>([]);

  const dispatch = useDispatch();

  const fetchData = ({ page, filterBy }: FetchData) => {
    if (page === 0) {
      setPaginationOn(true);
    }
    let payload = {
      limit: 4,
      offset: 4 * page,
      filterBy: {
        ...(filterBy ? filterBy : {}),
        active: true,
        hidden: false,
      },
    };
    const select = {
      picture: 1,
      lastName: 1,
      name: 1,
      gender: 1,
      email: 1,
      _id: 1,
      github: 1,
      curriculum: 1,
      cohorts: 1,
      portfolio: 1,
      linkedin: 1,
      comments: 1,
    };

    const sortDirection = "desc";
    if (current.currentSection === "academy") {
      dispatch(
        usersActions.getAllByAcademy({
          _id: current.id,
          payload: {
            ...payload,
            sortDirection,
            select,
            academyMP: true,
          },
        })
      );
    } else {
      dispatch(
        usersActions.getAll({
          ...payload,
          sortDirection,
          select,
          ...(current.currentSection === "personal" && {
            inPublicCohorts: true,
          }),
        })
      );
    }
  };

  const addAitTalent = (values) => {
    const {
      name,
      lastName,
      email,
      country,
      province,
      english,
      avatar,
      curriculum,
      cohorts,
      github,
      portfolio,
      linkedin,
      comments,
      _id,
    } = values;
    dispatch(
      actionData.create({
        name,
        lastName,
        cohorts,
        email,
        user: user._id,
        country,
        province,
        english,
        avatar,
        curriculum,
        github,
        portfolio,
        linkedin,
        comments,
        talent: _id,
        external: false,
      })
    );
  };

  const buildFilters = (newFilter) => {
    const filterUpdated =
      applyedFilters.filter(
        (applyedFilter) => applyedFilter.field === newFilter.field
      ).length > 0;

    const filterRemoved = newFilter.value === "no";

    /**
     * Se elimina el filtro
     */
    if (filterRemoved) {
      setFilters([
        ...applyedFilters.filter(
          (applyedFilter) => applyedFilter.field !== newFilter.field
        ),
      ]);
    }

    /**
     * Se actualiza un filtro
     */
    if (filterUpdated && !filterRemoved) {
      setFilters([
        ...applyedFilters.map((applyedFilter) => {
          if (applyedFilter.field === newFilter.field) {
            return newFilter;
          } else {
            return applyedFilter;
          }
        }),
      ]);
    }

    /**
     * Se agrega un filtro
     */
    if (!filterUpdated && !filterRemoved) {
      setFilters([...applyedFilters, newFilter]);
    }
  };

  useEffect(() => {
    if (cohorts && fetchOn) {
      const studentsFromCohorts = Array.from(
        new Set(
          (current.currentSection === "academy"
            ? filterCohortByRole(cohorts, user._id, current.role)
            : cohorts
          )
            .map((cohort) => cohort.studentsIds)
            .flat(1)
        )
      );
      setStudentsIdsFromCohort(studentsFromCohorts);
    }
  }, [cohorts]);
  useEffect(() => {
    if (paginationOn) {
      if (!isLoading && users && cohorts) {
        const usersWithBootcampName = users.map((userF) => {
          return {
            ...userF,
            bootcampName: cohorts.filter((cohort) =>
              userF.cohorts.find((cohortFounded) => cohortFounded._id)
            )[0]?.name,
          };
        });
        setUserIdsForAnalytics((state) => [
          ...state,
          ...usersWithBootcampName.map((userF) => {
            return {
              _id: userF._id,
              cohorts: userF.cohorts.map((cohort) => cohort._id),
            };
          }),
        ]);
        setCurrentTalentsListValues((state) => [
          ...state,
          ...usersWithBootcampName,
        ]);
      }

      setThereAreItems(!!users && users?.length > 0);
    }
  }, [users, isLoading, cohorts]);

  useEffect(() => {
    if (usersIdsForAnalytics.length > 0 && paginationOn) {
      const usersWithCohorts = usersIdsForAnalytics.filter(
        (user) => user.cohorts.length > 0
      );
      dispatch(getManyIndividualStats(usersWithCohorts));
    }
  }, [usersIdsForAnalytics]);

  useEffect(() => {
    if (studentsStatsStates.success) {
      setAnalyticsFromUsers(studentsStats);
      dispatch(resetGetAllStudentsStats());
    }
    if (studentsStatsStates.error) {
      dispatch(resetGetAllStudentsStats());
    }
  }, [studentsStatsStates]);

  useEffect(() => {
    const filteredTalents = currentTalentsListValues?.filter(
      (currentTalentValues) => {
        const thereAreFilters =
          applyedFilters.filter((applyedFilter) => !applyedFilter.groupal)
            .length > 0;
        const totalOfFilters = applyedFilters.filter(
          (applyedFilter) => !applyedFilter.groupal
        ).length;
        const passFilters = applyedFilters
          .filter(
            (applyedFilter) => !applyedFilter.special || !applyedFilter.groupal
          )
          .filter(
            (applyedFilter) =>
              currentTalentValues[applyedFilter.field] === applyedFilter.value
          ).length;
        const specialFilter = applyedFilters.filter(
          (applyedFilter) => !!applyedFilter.special
        );

        const speacialFilterCheck = specialFilter.filter((applyedFilter) => {
          return applyedFilter.special(
            currentTalentValues,
            applyedFilter.value
          );
        });
        return (
          !thereAreFilters ||
          (thereAreFilters &&
            totalOfFilters === passFilters + specialFilter.length &&
            speacialFilterCheck.length === specialFilter.length)
        );
      }
    );
    let initResult = filteredTalents;
    applyedFilters
      .filter((applyedFilter) => !!applyedFilter.groupal)
      .forEach((applyedFilter) => {
        initResult = applyedFilter.groupal(initResult);
      });

    setFilteredCurrentTalentsListValues(filteredTalents);
  }, [applyedFilters, currentTalentsListValues]);

  useEffect(() => {
    setIsLoading(usersStates.loading);
  }, [usersStates]);

  useEffect(() => {
    !cohorts &&
      fetchOn &&
      dispatch(
        getAllCohorts({
          filterBy: {
            ...(current.currentSection === "academy" && {
              academy: current.id,
            }),
          },
          select: {
            _id: 1,
            name: 1,
            studentsIds: 1,
            mentorsIds: 1,
            observerIds: 1,
          },
        })
      );
  }, [cohorts, fetchOn]);

  useEffect(() => {
    if (!cohorts) {
      setFetchOn(true);
    }
  }, [cohorts]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllCohortsData());
      dispatch(usersActions.resetGetAll());
    };
  }, []);

  return (
    <>
      <PageTitle>Talent Board</PageTitle>

      <Grid.ContainerFluid>
        <Grid.Row>
          <Grid.ContainerFluid>
            <Grid.Row
              style={{
                marginBottom: "20px",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <Grid.Col lg={8} offset={{ lg: 2 }}>
                <FilterByName
                  placeholder={"Buscar talento"}
                  users={currentTalentsListValues}
                  applyedFilters={applyedFilters}
                  setFilter={buildFilters}
                  setSelectedStudentId={({ id, name }) => {
                    buildFilters({ field: "_id", value: id ?? "no" });
                  }}
                />
              </Grid.Col>
              <Grid.Col style={{ alignSelf: "center" }}>
                <FlexContainer gap="10px" justify="center">
                  <GridButton
                    active={grid === true}
                    onClick={() => {
                      setGrid(true);
                    }}
                  >
                    <BsGrid size={22}></BsGrid>
                  </GridButton>
                  <GridButton
                    active={grid === false}
                    onClick={() => {
                      setGrid(false);
                    }}
                  >
                    <AiOutlineUnorderedList size={22}></AiOutlineUnorderedList>
                  </GridButton>
                </FlexContainer>
              </Grid.Col>
            </Grid.Row>
          </Grid.ContainerFluid>
        </Grid.Row>
        {/*     <Grid.Row>
          <Grid.Col lg={8} offset={{ lg: 2 }}>
            <Center>
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <FilterByEnglish
                  applyedFilters={applyedFilters}
                  setFilter={buildFilters}
                />
                <FilterByLocation
                  applyedFilters={applyedFilters}
                  setFilter={buildFilters}
                />
              </div>
            </Center>
          </Grid.Col>
        </Grid.Row> */}

        <div style={{ height: "24px" }} />
        <InfinityScroll
          action={fetchData}
          page={page}
          setPage={setPage}
          data={filteredCurrentTalentsListValues}
          active={thereAreItems}
          isLoading={isLoading}
        >
          <GridSwitchStructure width="200px" grid={grid} maxWidth="800px">
            {filteredCurrentTalentsListValues?.map(
              (currentTalentValues, index) => (
                <Grid.Row style={{ marginBottom: "50px" }}>
                  <Grid.Col>
                    <TalentCard
                      grid={grid}
                      studentsStatsStates={studentsStatsStates}
                      usersIdsForAnalytics={usersIdsForAnalytics}
                      isAIT={
                        !!aitTalents?.find(
                          (aitTalent) =>
                            currentTalentValues._id === aitTalent.user
                        )
                      }
                      setTalent={setTalentSelected}
                      currentTalent={currentTalentValues}
                      stats={analyticsFromUsers[index]}
                      addAitTalent={addAitTalent}
                      currentTalentValues={currentTalentValues}
                    />
                  </Grid.Col>
                </Grid.Row>
              )
            )}
          </GridSwitchStructure>
        </InfinityScroll>
      </Grid.ContainerFluid>

      {!!talentSelected && <TeamModal talent={talentSelected}></TeamModal>}
      {!!talentSelected && (
        <ObserverModal talent={talentSelected}></ObserverModal>
      )}
    </>
  );
};

const states = ({
  talentStore,
  staticsStore,
  abilityStore,
  cohortStore,
  studentStore,
  analyticsStore,
  recruitingStore,
  usersStore,
  userStore,
  currentStore,
  academyStore,
}) => {
  const { states: newTalentStates } = talentStore.newTalent;
  const { states: abilitiesStates, data: abilities } =
    abilityStore.allAbilities;
  const { data: users, states: usersStates } = usersStore.all;
  const { data: user } = userStore;
  const { states: cohortsStates, data: cohorts } = cohortStore.allCohorts;
  const { states: studentsStates, data: students } = studentStore.allStudents;
  const { states: talentsStates, data: talents } = talentStore.allTalents;
  const { states: provincesStates, data: provinces } =
    staticsStore.allProvinces;
  const { states: studentsStatsStates, data: studentsStats } =
    analyticsStore.getAllStudentsStats;
  const { data: aitTalents } = recruitingStore.talent.all;
  const { data: current } = currentStore;
  const { data: academies } = academyStore.all;
  return {
    talentsStates,
    current,
    aitTalents,
    talents,
    newTalentStates,
    studentsStatsStates,
    studentsStats,
    abilitiesStates,
    abilities,
    cohortsStates,
    cohorts,
    studentsStates,
    students,
    provinces,
    provincesStates,
    users,
    user,
    academies,
    usersStates,
  };
};

export default connect(states)(Component);
