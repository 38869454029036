import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import Icon from "../../../components/Icon";
import arrowLeft from "../../../assets/icons/angle-left.svg";
import arrowRight from "../../../assets/icons/angle-right.svg";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { getAllMeets } from "../../../store/actions/calendar";
import { MeetDto } from "../../../types/meet.dto";
import Loader from "../../../components/Loader/index";
import { CurrentDto } from "../../../types/current.dto";
import { UserDto } from "../../../types/user.dto";
import { CohortDto } from "../../../types/cohort.dto";
import {
  getAllCohorts,
  resetGetAllCohortsData,
} from "../../../store/actions/cohort";
import { EventDto } from "../../../types/event.dto";
import ModalGoMeet from "./components/ModalGoMeet";
import { showModal } from "../../../store/actions/modal";
import { AcademyDto } from "../../../types/academy.dto";
import {
  getAllEvents,
  getEventsByUser,
  resetGetAllEvents,
} from "../../../store/actions/event";
import academyActions from "../../../store/actions/academies";
// Setup the localizer by providing the moment (or globalize, or Luxon) Object
// to the correct localizer.
const localizer = momentLocalizer(moment); // or globalizeLocalizer

interface ComponentProps {
  meets: MeetDto[];
  current: CurrentDto;
  user: UserDto;
  cohorts: CohortDto[];
  events: EventDto[];
  academies: AcademyDto[];
}
const Component = ({
  meets,
  current,
  user,
  cohorts,
  events,
  academies,
}: ComponentProps) => {
  const [fetchOn, setFetchOn] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [meetSelected, setMeetSelected] = useState<EventDto>();

  useEffect(() => {
    if (!events && fetchOn) {
      dispatch(getEventsByUser({ _id: user._id, filterBy: { active: true } }));
    }
  }, [events, fetchOn]);

  useEffect(() => {
    if (!events && !academies) {
      setFetchOn(true);
    }
  }, [events, academies]);

  useEffect(() => {
    if (events && fetchOn) {
      let academiesId: string[] = [];
      for (let event of events) {
        academiesId = [...academiesId, event.academy];
      }
      dispatch(
        academyActions.getAll({
          filterBy: { _id: { $in: Array.from(new Set(academiesId)) } },
        })
      );
    }
  }, [events]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllEvents());
      dispatch(academyActions.resetGetAllAcademiesData());
    };
  }, []);
  if (!events || !academies) {
    return <Loader color="Primary"></Loader>;
  }

  return (
    <>
      <Calendar
        localizer={localizer}
        events={events.filter(
          (event) => event.active && event.academy === current.id
        )}
        onSelectEvent={(value: EventDto) => {
          setMeetSelected(value);
          dispatch(showModal("send-meet"));
        }}
        startAccessor="start"
        endAccessor="end"
        messages={{
          week: "Semana",
          work_week: "Semana de trabajo",
          day: "Día",
          month: "Mes",
          previous: <Icon icon={arrowLeft} size="20px" color="black" />,
          next: <Icon icon={arrowRight} size="20px" color="black" />,
          today: "Hoy",
          agenda: "Diario",
          showMore: (total) => `+${total} más`,
        }}
      />
      <ModalGoMeet
        title={
          <span>
            {`¿Estas a punto de entrar a una reunion de la academia `}
            <span>
              {
                academies.filter(
                  (academy) => academy._id === meetSelected?.academy
                )[0]?.name
              }
            </span>
          </span>
        }
        meetLink={meetSelected}
      ></ModalGoMeet>
    </>
  );
};

const state = ({
  meetStore,
  currentStore,
  userStore,
  cohortStore,
  eventStore,
  academyStore,
}) => {
  const { data: meets } = meetStore.allMeets;
  const { data: current } = currentStore;
  const { data: user } = userStore;
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: events } = eventStore.allEvents;
  const { data: academies } = academyStore.all;
  return {
    meets,
    current,
    user,
    cohorts,
    events,
    academies,
  };
};

export default connect(state)(Component);
