import * as Yup from "yup";

export const schema = Yup.object().shape({
  criterion: Yup.string(),
  name: Yup.string(),
  "skill-1": Yup.string(),
  "skill-2": Yup.string(),
  "skill-3": Yup.string(),
  "skill-4": Yup.string(),
  "skill-5": Yup.string(),
});

interface FormValues {
  "skill-1": string;
  "skill-2": string;
  "skill-3": string;
  "skill-4": string;
  "skill-5": string;
  criterion: string;
  name: string;
}

export const initialValues: FormValues = {
  criterion: "",
  name: "",
  "skill-1": "",
  "skill-2": "",
  "skill-3": "",
  "skill-4": "",
  "skill-5": "",
};
