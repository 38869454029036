import * as Yup from "yup";

interface InitialValues {
  name: string;
  lastName: string;
  email: string;
  companies: string[];
  searches: string[];
}

export const schema = Yup.object().shape({
  name: Yup.string()
    .required("Se requiere un nombre")
    .min(3, "Al menos 7 caracteres"),
  email: Yup.string().email().required("Se requiere un email"),
  lastName: Yup.string()
    .required("Se requiere un Apelllido")
    .min(3, "Al menos 3 caracteres"),
  companies: Yup.array().required(),
  searches: Yup.array().required(),
});

export const initialValues: InitialValues = {
  name: "",
  email: "",
  lastName: "",
  companies: [],
  searches: [],
};
