import styled from "styled-components";

export const Header = styled("div")`
  padding: 10px;
`;

export const SectionIcon = styled("div")`
  width: 40px;
  height: 40px;
  background-color: #5089c6;
  margin-right: 10px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled("h2")`
  font-size: 14px;
  font-weight: 500;
  color: #334253;
  margin: 0px;
`;

export const PrimarySubTitle = styled("h3")`
  font-size: 14px;
  color: #697482;
  font-weight: normal;
  margin: 0px;
`;

export const SecondSubtitle = styled("h3")`
  font-size: 12px;
  color: #697482;
  font-weight: normal;
  margin: 0px;
  text-transform: capitalize;
`;

export const Actions = styled("div")`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Action = styled("button")`
  border: 0px;
  border-radius: 10px;
  box-shadow: 1px 1px 1px 0 rgba(112, 112, 112, 0.16);
  background-color: #f5f5f5;
  padding: 9px 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-right: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  }

  i {
    margin-right: 10px;
  }
`;

export const Tab = styled("div")``;

export const TabTitle = styled("h2")`
  font-size: 18px;
  font-weight: 500;
  color: #334253;
  margin: 0px;
`;

export const Description = styled("p")`
  margin: 14px 0px;
`;

export const Content = styled("div")`
  margin-bottom: 14px;
`;

export const ListTitle = styled("h3")`
  margin: 0px;
  font-size: 16px;
  font-weight: 500;
  color: #697482;
  margin-bottom: 14px;
`;

export const GrayCard = styled("div")`
  margin: 0px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 10px;
  min-height: 60px;
  width: 100%;
`;

export const List = styled("ul")`
  margin: 0px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 10px;
  min-height: 60px;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 5px;
  vertical-align: center;
  width: 100%;
`;

export const ListItem = styled("li")`
  list-style: none;
  height: 22px;
  border-radius: 11px;
  background-color: #d5f5ff;
  font-size: 14px;
  color: #334253;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    margin-right: 5px;
  }
`;

export const Delete = styled("button")`
  margin: 0px;
  padding: 0px;
  border: 0px;
  background: none;
  margin-left: 5px;
  cursor: pointer;
`;
