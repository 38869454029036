import styled from "styled-components";
import {
  CustomText,
  EllipsisText,
} from "../../../../../../../../components/StyledComponents";

export const AcademyCardWrapper = styled("div")`
  border-radius: 10px;
  box-shadow: 0 0 4px 0 rgba(0, 30, 108, 0.2);
  cursor: pointer;
`;

export const AcademyCardBannerImage = styled("div")`
  height: 80px;
`;

export const AcademyCardProfileImage = styled("div")`
  width: 48px;
  height: 48px;
  border-radius: 10px;
  overflow: hidden;
  margin-top: -20px;
`;

export const AcademyCardName = styled("h2")`
  font-size: 18px;
  font-weight: 500;
  color: #2a2747;
  ${EllipsisText}
`;

export const AcademyCardDataText = styled("p")`
  ${CustomText}
`;

export const AcademyCardTags = styled("div")`
  padding: 2.5px 4px;
  border-radius: 5px;
  background-color: #001e6c;
  color: ${(props) => props.theme.colors.White};
`;
