const permissions = {
  static: ["route:calendar:preview", "route:team:preview"],
  dynamic: {
    "op:edit:state": ({ status }) => {
      if (!status) return false;
      return ["requested", "pending", "inProgress", "done"].includes(status);
    },
  },
};

export default permissions;
