import {
  initialState,
  Actions,
  State,
  GET_ALL_ABILITIES,
  GET_ALL_ABILITIES_ERROR,
  GET_ALL_ABILITIES_SUCCESS,
  GET_ABILITY,
  GET_ABILITY_ERROR,
  GET_ABILITY_SUCCESS,
  NEW_ABILITY,
  NEW_ABILITY_SUCCESS,
  NEW_ABILITY_ERROR,
  RESET_NEW_ABILITY,
  UPDATE_ABILITY,
  UPDATE_ABILITY_ERROR,
  UPDATE_ABILITY_SUCCESS,
  RESET_UPDATE_ABILITY,
  DELETE_ABILITY,
  DELETE_ABILITY_ERROR,
  DELETE_ABILITY_SUCCESS,
  RESET_DELETE_ABILITY,
} from "../types/ability";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_ABILITIES:
      return {
        ...state,
        allAbilities: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_ABILITIES_SUCCESS:
      return {
        ...state,
        allAbilities: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_ABILITIES_ERROR:
      return {
        ...state,
        allAbilities: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case GET_ABILITY:
      return {
        ...state,
        ability: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ABILITY_SUCCESS:
      return {
        ...state,
        ability: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ABILITY_ERROR:
      return {
        ...state,
        ability: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case NEW_ABILITY:
      return {
        ...state,
        newAbility: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_ABILITY_SUCCESS:
      return {
        ...state,
        newAbility: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case NEW_ABILITY_ERROR:
      return {
        ...state,
        newAbility: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_ABILITY:
      return {
        ...state,
        newAbility: initialState.newAbility,
      };
    case DELETE_ABILITY:
      return {
        ...state,
        deleteAbility: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_ABILITY_SUCCESS:
      return {
        ...state,
        deleteAbility: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_ABILITY_ERROR:
      return {
        ...state,
        deleteAbility: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_ABILITY:
      return {
        ...state,
        deleteAbility: initialState.deleteAbility,
      };
    case UPDATE_ABILITY:
      return {
        ...state,
        updateAbility: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_ABILITY_SUCCESS:
      return {
        ...state,
        updateAbility: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_ABILITY_ERROR:
      return {
        ...state,
        updateAbility: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_ABILITY:
      return {
        ...state,
        updateAbility: initialState.updateAbility,
      };

    default:
      return state;
  }
};

export default reducers;
