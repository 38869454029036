import * as Yup from "yup";

interface FormValues {
  name: string;
  backgroundColor: string;
  color: string;
  urls: { id: number; url: string }[];
  fontSize: number;
}

export const schema = Yup.object().shape({
  name: Yup.string()
    .min(5, "Minimo 5 caracteres")
    .required("Se necesita nombre"),
  backgroundColor: Yup.string(),
  color: Yup.string(),
  fontSize: Yup.number().min(1).max(30),
  urls: Yup.array(),
});

export const initialValues = (initialValues = {}): FormValues => {
  return {
    name: "",
    backgroundColor: "#ffffff",
    color: "#000000",
    fontSize: 18,
    urls: [],
    ...initialValues,
  };
};
