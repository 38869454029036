import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

import { UserDto } from "../../../../../../types/user.dto";
import {
  Center,
  FlexContainer,
} from "../../../../../../components/StyledComponents";
import { CohortDto } from "../../../../../../types/cohort.dto";
import { AcademyDto } from "../../../../../../types/academy.dto";
import Modal from "../../../../../../components/Modal";
import { ContentDto } from "../../../../../../types/content.dto";
import contentActions, {
  updateMany,
} from "../../../../../../store/actions/content";
import {
  GridCard,
  GridCardDescription,
  GridCardImage,
  TrashIcon,
} from "../../../../../../components/Layout/Dashboard/styles";
import { GridGrayWrapper, GridStructure } from "../../style";
import { BsFillTrashFill } from "react-icons/bs";
import { MdOutlineAddBox } from "react-icons/md";
import FilterByName from "./components/FilterByName";
import Grid from "../../../../../../components/Grid";
import { ImageContent } from "../DropDown/style";
import Button from "../../../../../../components/Button";
import { hideModal } from "../../../../../../store/actions/modal";
import { updateUnit } from "../../../../../../store/actions/unit";
import { UnitDto } from "../../../../../../types/unit.dto";
import {
  updateManyVideo,
  updateVideo,
} from "../../../../../../store/actions/video";
import {
  updateCDOCs,
  updateManyCdoc,
} from "../../../../../../store/actions/cdoc";
import { setFormData } from "../../../../../../helpers/formData";
import {
  updateManyRoadmap,
  updateRoadmap,
} from "../../../../../../store/actions/roadmap";
import {
  updateImage,
  updateManyImage,
} from "../../../../../../store/actions/image";
import {
  updateManySlideshow,
  updateSlideShow,
} from "../../../../../../store/actions/slideshow";
import {
  updateListen,
  updateManyListen,
} from "../../../../../../store/actions/listen";
import {
  updateByte,
  updateManyByte,
} from "../../../../../../store/actions/byte";
import {
  getAllGeneralContents,
  resetUpdateManyGeneralContents,
  updateManyGeneralContents,
} from "../../../../../../store/actions/generalContent";
import { GeneralContentDto } from "../../../../../../types/generalContent.dto";
import { StateDto } from "../../../../../../types/states.dto";
import { CurrentDto } from "../../../../../../types/current.dto";
import Loader from "../../../../../../components/Loader";
import { toast } from "react-toastify";
import services from "../../../../../../services";
interface ComponentProps {
  academies: AcademyDto[];
  user: UserDto;
  cohorts: CohortDto[];
  contents: ContentDto[];
  deleteStates;
  unit: UnitDto;
  cohort: CohortDto;
  generalContents: GeneralContentDto[];
  getGeneralContents: StateDto;
  current: CurrentDto;
  updateManyGeneralContentsStates: StateDto;
  setCurrentContent: any;
  updateManyGeneralContentsData;
}
const Component = ({
  academies,
  user,
  generalContents,
  getGeneralContents,
  cohorts,
  contents,
  deleteStates,
  unit,
  cohort,
  current,
  updateManyGeneralContentsStates,
  setCurrentContent,
  updateManyGeneralContentsData,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const [selectedContents, setSelectedContents] = useState<any[]>([]);
  const [currentContentsList, setCurrentContentsList] = useState<any[]>([]);
  const [applyedFilters, setFilters] = useState<
    {
      field: string;
      value: string | boolean;
      special: (data?: any, data2?: any) => boolean;
    }[]
  >([]);
  const [
    filteredCurrentContentsListValues,
    setFilteredCurrentContentsListValues,
  ] = useState<any[]>([]);

  const buildFilters = (newFilter) => {
    const filterUpdated =
      applyedFilters.filter(
        (applyedFilter) => applyedFilter.field === newFilter.field
      ).length > 0;

    const filterRemoved = newFilter.value === "no";

    /**
     * Se elimina el filtro
     */
    if (filterRemoved) {
      setFilters([
        ...applyedFilters.filter(
          (applyedFilter) => applyedFilter.field !== newFilter.field
        ),
      ]);
    }

    /**
     * Se actualiza un filtro
     */
    if (filterUpdated && !filterRemoved) {
      setFilters([
        ...applyedFilters.map((applyedFilter) => {
          if (applyedFilter.field === newFilter.field) {
            return newFilter;
          } else {
            return applyedFilter;
          }
        }),
      ]);
    }

    /**
     * Se agrega un filtro
     */
    if (!filterUpdated && !filterRemoved) {
      setFilters([...applyedFilters, newFilter]);
    }
  };
  const saveChanges = () => {
    const updatedContents = selectedContents.map((content) => {
      return {
        _id: content._id,
        body: setFormData(
          {
            units: content.units.includes(unit._id)
              ? content.units
              : [...content.units, unit._id],
            cohorts: content.cohorts.includes(cohort._id)
              ? content.cohorts
              : [...content.cohorts, cohort._id],
            __v: content.__v,
          },
          []
        ),
      };
    });

    updatedContents.length > 0 &&
      dispatch(updateManyGeneralContents({ items: updatedContents }));
  };

  useEffect(() => {
    setSelectedContents([]);
    const fetchData = async () => {
      const response: any = await services.generalContent.getAll({
        filterBy: {
          $or: [{ cohorts: cohort?._id }, { allCohorts: true }],
          academy: cohort?.academy?._id,
        },
      });
      if (response.response) {
        setCurrentContentsList(response.response);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (updateManyGeneralContentsStates.success) {
      toast.success("Se ha agregado contenidos");
      setCurrentContent((state) => [
        ...state,
        ...updateManyGeneralContentsData,
      ]);
      dispatch(resetUpdateManyGeneralContents());
      dispatch(hideModal());
    }
    if (updateManyGeneralContentsStates.error) {
      dispatch(resetUpdateManyGeneralContents());
      toast.error(updateManyGeneralContentsStates.error);
    }
  }, [updateManyGeneralContentsStates, dispatch]);

  useEffect(() => {
    const filteredTalents = currentContentsList?.filter(
      (currentTalentValues) => {
        const thereAreFilters = applyedFilters.length > 0;
        const totalOfFilters = applyedFilters.length;
        const passFilters = applyedFilters
          .filter((applyedFilter) => !applyedFilter.special)
          .filter(
            (applyedFilter) =>
              currentTalentValues[applyedFilter.field] === applyedFilter.value
          ).length;
        const specialFilter = applyedFilters.filter(
          (applyedFilter) => !!applyedFilter.special
        );

        const speacialFilterCheck = specialFilter.filter((applyedFilter) => {
          return applyedFilter.special(
            currentTalentValues,
            applyedFilter.value
          );
        });

        return (
          !thereAreFilters ||
          (thereAreFilters &&
            totalOfFilters === passFilters + specialFilter.length &&
            speacialFilterCheck.length === specialFilter.length)
        );
      }
    );

    setFilteredCurrentContentsListValues(filteredTalents);
  }, [applyedFilters, currentContentsList]);
  /* 
  if (!contents) return <Loader color="Primary"></Loader>; */
  return (
    <Modal name="new-content-modal" title="Agregar contenido">
      {!getGeneralContents.loading ? (
        <>
          <Grid.Row>
            <Grid.Col style={{ marginBottom: "20px" }}>
              <FilterByName
                items={filteredCurrentContentsListValues.map((academy) => {
                  return { value: academy.name, label: academy.name };
                })}
                setFilter={buildFilters}
              ></FilterByName>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row style={{ marginBottom: "30px" }}>
            <Grid.Col>
              {filteredCurrentContentsListValues.length > 0 ? (
                <GridGrayWrapper>
                  <GridStructure width="120px" maxWidth="900px">
                    {filteredCurrentContentsListValues.map((content, key) => (
                      <GridCard key={content._id}>
                        <TrashIcon style={{ right: "18px" }}>
                          {!selectedContents?.find(
                            (contentF) => contentF._id === content._id
                          ) ? (
                            <MdOutlineAddBox
                              onClick={() => {
                                setSelectedContents((state) => {
                                  return [...state, content];
                                });
                              }}
                              fontSize={16}
                            ></MdOutlineAddBox>
                          ) : (
                            <BsFillTrashFill
                              onClick={() => {
                                setSelectedContents((state) =>
                                  state.filter(
                                    (itemsF) => itemsF._id !== content._id
                                  )
                                );
                              }}
                              fontSize={16}
                            ></BsFillTrashFill>
                          )}
                        </TrashIcon>
                        <FlexContainer justify="center">
                          <ImageContent>
                            <GridCardImage
                              src={
                                content.picture ||
                                "https://upload.wikimedia.org/wikipedia/commons/8/81/Gallet_clamshell_600x600_6.jpg"
                              }
                            ></GridCardImage>
                          </ImageContent>
                        </FlexContainer>
                        <FlexContainer justify="center">
                          <GridCardDescription>
                            {content.name}
                          </GridCardDescription>
                        </FlexContainer>
                      </GridCard>
                    ))}
                  </GridStructure>
                </GridGrayWrapper>
              ) : (
                <Center>
                  <h3>No hay resultados</h3>
                </Center>
              )}
            </Grid.Col>
          </Grid.Row>
          <Center>
            <Button
              loading={updateManyGeneralContentsStates.loading}
              onClick={saveChanges}
              type="button"
              options={{
                type: "filled",
                skin: "primary",
                size: "lg",
                marginBottom: "0px",
              }}
              style={{ marginLeft: "10px" }}
            >
              Confirmar
            </Button>
          </Center>
        </>
      ) : (
        <Loader color="Primary"></Loader>
      )}
    </Modal>
  );
};

const states = ({
  academyStore,
  userStore,
  cohortStore,
  contentStore,
  generalContentStore,
  currentStore,
}) => {
  const { data: academies } = academyStore.all;
  const { data: user } = userStore;
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: contents } = contentStore.all;
  const { states: deleteStates } = contentStore.update;
  const { data: generalContents, states: getGeneralContents } =
    generalContentStore.all;
  const {
    states: updateManyGeneralContentsStates,
    data: updateManyGeneralContentsData,
  } = generalContentStore.updateMany;
  const { data: current } = currentStore;
  return {
    academies,
    user,
    cohorts,
    contents,
    deleteStates,
    generalContents,
    getGeneralContents,
    current,
    updateManyGeneralContentsStates,
    updateManyGeneralContentsData,
  };
};

export default connect(states)(Component);
