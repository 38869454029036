import {
  SubCriterionDto,
  SubCriterionDtoApi,
} from "../../types/sub-criterion.dto";

export const GET_ALL_SUB_CRITERIONS = "GET_ALL_SUB_CRITERIONS";
export const GET_ALL_SUB_CRITERIONS_ERROR = "GET_ALL_SUB_CRITERIONS_ERROR";
export const GET_ALL_SUB_CRITERIONS_SUCCESS = "GET_ALL_SUB_CRITERIONS_SUCCESS";
export const RESET_GET_ALL_SUB_CRITERIONS = "RESET_GET_ALL_SUB_CRITERIONS";
export const GET_SUB_CRITERION = "GET_SUB_CRITERION";
export const GET_SUB_CRITERION_ERROR = "GET_SUB_CRITERION_ERROR";
export const GET_SUB_CRITERION_SUCCESS = "GET_SUB_CRITERION_SUCCESS";
export const NEW_SUB_CRITERION = "NEW_SUB_CRITERION";
export const NEW_SUB_CRITERION_ERROR = "NEW_SUB_CRITERION_ERROR";
export const NEW_SUB_CRITERION_SUCCESS = "NEW_SUB_CRITERION_SUCCESS";
export const RESET_NEW_SUB_CRITERION = "RESET_NEW_SUB_CRITERION";
export const UPDATE_SUB_CRITERION = "UPDATE_SUB_CRITERION";
export const UPDATE_SUB_CRITERION_ERROR = "UPDATE_SUB_CRITERION_ERROR";
export const UPDATE_SUB_CRITERION_SUCCESS = "UPDATE_SUB_CRITERION_SUCCESS";
export const RESET_UPDATE_SUB_CRITERION = "RESET_UPDATE_SUB_CRITERION";
export const DELETE_SUB_CRITERION = "DELETE_SUB_CRITERION";
export const DELETE_SUB_CRITERION_ERROR = "DELETE_SUB_CRITERION_ERROR";
export const DELETE_SUB_CRITERION_SUCCESS = "DELETE_SUB_CRITERION_SUCCESS";
export const RESET_DELETE_SUB_CRITERION = "RESET_DELETE_SUB_CRITERION";
export const NEW_MANY_SUB_CRITERION = "NEW_MANY_SUB_CRITERION";
export const NEW_MANY_SUB_CRITERION_SUCCESS = "NEW_MANY_SUB_CRITERION_SUCCESS";
export const NEW_MANY_SUB_CRITERION_ERROR = "NEW_MANY_SUB_CRITERION_ERROR";
export const RESET_NEW_MANY_SUB_CRITERION = "RESET_NEW_MANY_SUB_CRITERION";
export const UPDATE_MANY_SUB_CRITERION = "UPDATE_MANY_SUB_CRITERION";
export const UPDATE_MANY_SUB_CRITERION_SUCCESS =
  "UPDATE_MANY_SUB_CRITERION_SUCCESS";
export const UPDATE_MANY_SUB_CRITERION_ERROR =
  "UPDATE_MANY_SUB_CRITERION_ERROR";
export const RESET_UPDATE_MANY_SUB_CRITERION =
  "RESET_UPDATE_MANY_SUB_CRITERION";

export interface GetAllSubCriterions {
  type: typeof GET_ALL_SUB_CRITERIONS;
  payload: null;
}

export interface GetAllSubCriterionsSuccess {
  type: typeof GET_ALL_SUB_CRITERIONS_SUCCESS;
  payload: any;
}

export interface GetAllSubCriterionsError {
  type: typeof GET_ALL_SUB_CRITERIONS_ERROR;
  payload: boolean | string | any;
}

export interface ResetGetAllSubCriterions {
  type: typeof RESET_GET_ALL_SUB_CRITERIONS;
  payload: null;
}

export interface NewManySubCriterion {
  type: typeof NEW_MANY_SUB_CRITERION;
  payload: any;
}
export interface NewManySubCriterionSuccess {
  type: typeof NEW_MANY_SUB_CRITERION_SUCCESS;
  payload: SubCriterionDtoApi[];
}
export interface NewManySubCriterionError {
  type: typeof NEW_MANY_SUB_CRITERION_ERROR;
  payload: string | boolean;
}
export interface ResetNewManySubCriterion {
  type: typeof RESET_NEW_MANY_SUB_CRITERION;
  payload: null;
}
export interface UpdateManySubCriterion {
  type: typeof UPDATE_MANY_SUB_CRITERION;
  payload: any;
}
export interface UpdateManySubCriterionSuccess {
  type: typeof UPDATE_MANY_SUB_CRITERION_SUCCESS;
  payload: SubCriterionDtoApi[];
}
export interface UpdateManySubCriterionError {
  type: typeof UPDATE_MANY_SUB_CRITERION_ERROR;
  payload: string | boolean;
}
export interface ResetUpdateManySubCriterion {
  type: typeof RESET_UPDATE_MANY_SUB_CRITERION;
  payload: null;
}

export interface GetSubCriterion {
  type: typeof GET_SUB_CRITERION;
  payload: { id: string };
}

export interface GetSubCriterionSuccess {
  type: typeof GET_SUB_CRITERION_SUCCESS;
  payload: SubCriterionDto;
}

export interface GetSubCriterionError {
  type: typeof GET_SUB_CRITERION_ERROR;
  payload: boolean | string;
}

export interface NewSubCriterion {
  type: typeof NEW_SUB_CRITERION;
  payload: {
    criterion?: string;
    skills?: {
      ["level-1"]: string;
      ["level-2"]: string;
      ["level-3"]: string;
      ["level-4"]: string;
      ["level-5"]: string;
    };
    name?: string;
  };
}

export interface NewSubCriterionSuccess {
  type: typeof NEW_SUB_CRITERION_SUCCESS;
  payload: SubCriterionDtoApi;
}

export interface NewSubCriterionError {
  type: typeof NEW_SUB_CRITERION_ERROR;
  payload: boolean | string;
}

export interface ResetNewSubCriterion {
  type: typeof RESET_NEW_SUB_CRITERION;
  payload: null;
}

export interface UpdateSubCriterion {
  type: typeof UPDATE_SUB_CRITERION;
  payload: SubCriterionDtoApi;
}

export interface UpdateSubCriterionSuccess {
  type: typeof UPDATE_SUB_CRITERION_SUCCESS;
  payload: null;
}

export interface UpdateSubCriterionError {
  type: typeof UPDATE_SUB_CRITERION_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateSubCriterion {
  type: typeof RESET_UPDATE_SUB_CRITERION;
  payload: null;
}

export interface DeleteSubCriterion {
  type: typeof DELETE_SUB_CRITERION;
  payload: { id: string };
}

export interface DeleteSubCriterionSuccess {
  type: typeof DELETE_SUB_CRITERION_SUCCESS;
  payload: SubCriterionDtoApi;
}

export interface DeleteSubCriterionError {
  type: typeof DELETE_SUB_CRITERION_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteSubCriterion {
  type: typeof RESET_DELETE_SUB_CRITERION;
  payload: null;
}

export interface State {
  allSubCriterions: {
    data: SubCriterionDtoApi[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  subCriterion: {
    data: SubCriterionDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  newMany: {
    data: null | SubCriterionDtoApi[];
    states: {
      loading: boolean;
      success: boolean;
      error: string | boolean;
    };
  };
  updateMany: {
    data: null | SubCriterionDtoApi[];
    states: {
      loading: boolean;
      success: boolean;
      error: string | boolean;
    };
  };
  newSubCriterion: {
    data: null | SubCriterionDtoApi;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  deleteSubCriterion: {
    data: null | SubCriterionDtoApi;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  updateSubCriterion: {
    data: null | SubCriterionDtoApi;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  allSubCriterions: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  subCriterion: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  updateMany: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  newMany: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  newSubCriterion: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  deleteSubCriterion: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  updateSubCriterion: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllSubCriterions
  | GetAllSubCriterionsSuccess
  | GetAllSubCriterionsError
  | GetSubCriterion
  | GetSubCriterionSuccess
  | GetSubCriterionError
  | NewSubCriterion
  | NewSubCriterionSuccess
  | NewSubCriterionError
  | ResetNewSubCriterion
  | UpdateSubCriterion
  | UpdateSubCriterionSuccess
  | UpdateSubCriterionError
  | ResetUpdateSubCriterion
  | DeleteSubCriterion
  | DeleteSubCriterionSuccess
  | DeleteSubCriterionError
  | ResetDeleteSubCriterion
  | NewManySubCriterion
  | NewManySubCriterionSuccess
  | NewManySubCriterionError
  | ResetNewManySubCriterion
  | UpdateManySubCriterion
  | UpdateManySubCriterionSuccess
  | UpdateManySubCriterionError
  | ResetUpdateManySubCriterion
  | ResetGetAllSubCriterions;
