import React, { useEffect, useState } from "react";
import { BiNews } from "react-icons/bi";
import { connect, useDispatch } from "react-redux";
import Button from "../../../../../../components/Button";
import { Img } from "../../../../Cohort/Customize/style";
import { Icon } from "../../../../Content/style";
import { CardColumn, CardRow, CardTitle } from "../../../Profile/style";
import { DataText, Item } from "../../style";
import invitationStore from "../../../../../../store/actions/invitation";
import {
  FlexContainer,
  Separator,
} from "../../../../../../components/StyledComponents";
import { InvitationDto } from "../../../../../../types/invitation.dto";
import { toast } from "react-toastify";
import AcademyInviteButton from "../../components/InviteButton";
import OrganizationInviteButton from "../../../../Organization/Gestion/components/InviteButton";
import Loader from "../../../../../../components/Loader";
const Component = ({
  invitations,
  organizations,
  academies,
  updateInvitationStates,
  user,
  selectedItem,
  current,
  cohorts,
  updatedInvitation,
}: {
  invitations: InvitationDto[];
  organizations;
  academies;
  updateInvitationStates;
  user;
  selectedItem;
  current;
  cohorts;
  updatedInvitation;
}) => {
  const dispatch = useDispatch();
  const [fetchOn, setFetchOn] = useState<boolean>(false);
  const [selectedInvitation, setSelectedInvitation] = useState<InvitationDto>();
  const [currentInvitations, setCurrentInvitations] =
    useState<InvitationDto[]>();
  const payload = {
    filterBy: {
      active: true,
      status: "PENDING",
      email: user.email,
    },
  };
  useEffect(() => {
    if (!invitations && fetchOn) dispatch(invitationStore.getAll(payload));
  }, [invitations, fetchOn]);

  useEffect(() => {
    if (invitations && fetchOn) {
      setCurrentInvitations(invitations);
    }
  }, [invitations]);

  useEffect(() => {
    if (updateInvitationStates.success) {
      toast.success("Se realizo correctamente");

      setCurrentInvitations((state) =>
        state?.map((st) =>
          st._id === updatedInvitation._id ? updatedInvitation : st
        )
      );
      dispatch(invitationStore.resetUpdate());
    }
    if (updateInvitationStates.error) {
      toast.error(updateInvitationStates.error);
      dispatch(invitationStore.resetUpdate());
    }
  }, [updateInvitationStates]);

  useEffect(() => {
    return () => {
      dispatch(invitationStore.resetGetAll());
    };
  }, []);

  useEffect(() => {
    if (!invitations) {
      setFetchOn(true);
    }
  }, [invitations]);

  if (!currentInvitations) return <Loader color="Primary"></Loader>;

  return (
    <>
      {current.currentSection === "personal" && (
        <CardRow>
          <CardColumn gap="9px">
            <DataText>{selectedItem.label}</DataText>

            <DataText>{selectedItem.data.description}</DataText>
          </CardColumn>
        </CardRow>
      )}
      {current.currentSection !== "personal" && (
        <CardRow justify="space-between">
          <CardRow>
            <Icon icon="" size="40px" />
            <CardColumn gap="9px">
              <DataText>{selectedItem.label}</DataText>

              <DataText>{selectedItem.data.description}</DataText>
            </CardColumn>
          </CardRow>

          {current.currentSection === "organization" && (
            <OrganizationInviteButton></OrganizationInviteButton>
          )}
          {current.currentSection === "academy" && (
            <AcademyInviteButton></AcademyInviteButton>
          )}
        </CardRow>
      )}
      <Separator size={20}></Separator>
      <CardColumn style={{ width: "100%" }} gap="15px">
        <CardRow align="center" gap="8px">
          <BiNews fontSize={20}></BiNews>
          <CardTitle>Listado de Invitaciones</CardTitle>
        </CardRow>
        <CardColumn style={{ maxHeight: "580px", overflowY: "auto" }}>
          {currentInvitations
            .filter(
              (invitation) =>
                invitation.active && invitation.status === "PENDING"
            )
            .map((invitation) => {
              let entityFounded;

              if (invitation.academy) {
                entityFounded = invitation.academy;
              }
              if (invitation.cohort) {
                entityFounded = invitation.cohort;
              }
              /*   if (!!invitation.organization) {
              entityFounded = organizations.find(
                (cohort) => cohort._id === invitation.cohort._id
              );
            } */
              return (
                <Item>
                  <CardRow align="center" justify="space-between">
                    <CardRow align="center" gap="3px">
                      <Icon icon="">
                        <Img src={entityFounded?.picture}></Img>
                      </Icon>
                      <CardColumn>
                        <DataText size="14px">{entityFounded?.name}</DataText>
                        <FlexContainer gap="6px" align="center">
                          <DataText size="12px">{`Como`}</DataText>
                          <DataText
                            style={{ fontweight: "800" }}
                            size="14px"
                            color="#000"
                          >{`${invitation?.role}`}</DataText>
                        </FlexContainer>
                      </CardColumn>
                    </CardRow>
                    <CardRow gap="15px" align="center">
                      <>
                        <Button
                          type="button"
                          loading={
                            updateInvitationStates.loading &&
                            selectedInvitation?._id === invitation._id
                          }
                          onClick={() => {
                            setSelectedInvitation(invitation);
                            dispatch(
                              invitationStore.update({
                                _id: invitation._id,
                                condition: "accept",
                              })
                            );
                          }}
                          style={{
                            height: "26px",
                            maxWidth: "103px",
                            minWidth: "auto",
                          }}
                          options={{
                            size: "md",
                            type: "filled",
                            skin: "primary",
                          }}
                        >
                          Aceptar
                        </Button>
                        <Button
                          type="button"
                          loading={
                            updateInvitationStates.loading &&
                            selectedInvitation?._id === invitation._id
                          }
                          onClick={() => {
                            setSelectedInvitation(invitation);
                            dispatch(
                              invitationStore.update({
                                _id: invitation._id,
                                condition: "reject",
                              })
                            );
                          }}
                          style={{
                            height: "26px",
                            maxWidth: "103px",
                            minWidth: "auto",
                          }}
                          options={{
                            size: "md",
                            type: "filled",
                            skin: "danger",
                          }}
                        >
                          Rechazar
                        </Button>{" "}
                      </>
                    </CardRow>
                  </CardRow>
                </Item>
              );
            })}
        </CardColumn>
      </CardColumn>
    </>
  );
};

const states = ({
  invitationStore,
  academyStore,
  organizationStore,
  userStore,
  currentStore,
  cohortStore,
}) => {
  const { data: invitations } = invitationStore.all;
  const { data: organizations } = organizationStore.all;
  const { data: academies } = academyStore.all;
  const { states: updateInvitationStates, data: updatedInvitation } =
    invitationStore.update;
  const { data: user } = userStore;
  const { data: current } = currentStore;
  const { data: cohorts } = cohortStore.allCohorts;
  return {
    invitations,
    organizations,
    academies,
    updateInvitationStates,
    user,
    current,
    cohorts,
    updatedInvitation,
  };
};

export default connect(states)(Component);
