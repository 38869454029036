import { put, call, takeLatest, select } from "redux-saga/effects";
import services from "../../services";
import {
  NEW_TEAM,
  NEW_TEAM_ERROR,
  NEW_TEAM_SUCCESS,
  NewTeam,
  GET_ALL_TEAMS,
  GET_ALL_TEAMS_ERROR,
  GET_ALL_TEAMS_SUCCESS,
  GetAllTeams,
  UPDATE_TEAM,
  UPDATE_TEAM_ERROR,
  UPDATE_TEAM_SUCCESS,
  UpdateTeam,
  DELETE_TEAM,
  UPDATE_CURRENT_TEAM_LIST,
  DELETE_TEAM_SUCCESS,
  DELETE_TEAM_ERROR,
  NEW_TEAM_INVITE_LINK_ERROR,
  NEW_TEAM_INVITE_LINK_SUCCESS,
  NEW_TEAM_INVITE_LINK,
  DELETE_TEAM_INVITE_LINK,
  DELETE_TEAM_INVITE_LINK_ERROR,
  DELETE_TEAM_INVITE_LINK_SUCCESS,
  DELETE_TEAM_MEMBER,
  UPDATE_TEAM_ROLE,
} from "../types/team";

const companyList = ({ recruitingStore }) => recruitingStore.company.all.data;

function* newTeam({ payload }) {
  const { response, error } = yield call(services.team.new, payload);

  if (error) {
    yield put({
      type: NEW_TEAM_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    const currentListOfTechsTeams = yield select(companyList);
    const newListofTechsTeams = !!currentListOfTechsTeams
      ? [...currentListOfTechsTeams, response]
      : null;
    yield put({
      type: UPDATE_CURRENT_TEAM_LIST,
      payload: newListofTechsTeams,
    });
    yield put({ type: NEW_TEAM_SUCCESS, payload: response });
  }
}

function* deleteTeam({ payload }) {
  const { response, error } = yield call(services.team.delete, payload);

  if (error) {
    yield put({
      type: DELETE_TEAM_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    const currentListOfTeams = yield select(companyList);
    const newListofTeams = currentListOfTeams?.filter(
      (company) => company._id !== payload._id
    );
    yield put({
      type: UPDATE_CURRENT_TEAM_LIST,
      payload: newListofTeams,
    });
    yield put({ type: DELETE_TEAM_SUCCESS, payload: response });
  }
}

function* getAllTeams() {
  const { response, error } = yield call(services.team.getAll);
  if (error) {
    yield put({
      type: GET_ALL_TEAMS_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_TEAMS_SUCCESS, payload: response });
  }
}

function* createLink({ payload }) {
  const { response, error } = yield call(services.team.createLink, payload);
  if (error) {
    yield put({
      type: NEW_TEAM_INVITE_LINK_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({
      type: NEW_TEAM_INVITE_LINK_SUCCESS,
      payload: response,
    });
    navigator.clipboard.writeText(response.inviteLink);
  }
}

function* deleteLink({ payload }) {
  const { response, error } = yield call(services.team.deleteLink, payload);
  if (error) {
    yield put({
      type: DELETE_TEAM_INVITE_LINK_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({
      type: DELETE_TEAM_INVITE_LINK_SUCCESS,
      payload: response,
    });
  }
}

function* updatedTeam({ payload }) {
  const { response, error } = yield call(services.team.update, payload);

  if (error) {
    yield put({
      type: UPDATE_TEAM_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    const currentListOfComapnies = yield select(companyList);
    const newListofComapnies = currentListOfComapnies?.data?.map((company) => {
      if (company._id === payload._id) {
        return { ...company, ...payload };
      }
      return company;
    });
    yield put({
      type: UPDATE_CURRENT_TEAM_LIST,
      payload: newListofComapnies,
    });
    yield put({ type: UPDATE_TEAM_SUCCESS, payload: null });
    yield put({ type: GET_ALL_TEAMS, payload: null });
  }
}

function* deleteMember({ payload }) {
  const { response, error } = yield call(services.team.deleteMember, payload);

  if (error) {
    yield put({
      type: UPDATE_TEAM_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    const currentListOfComapnies = yield select(companyList);
    const newListofComapnies = currentListOfComapnies?.map((company) => {
      if (company._id === payload._id) {
        return { ...company, ...payload };
      }
      return company;
    });
    yield put({
      type: UPDATE_CURRENT_TEAM_LIST,
      payload: newListofComapnies,
    });
    yield put({ type: UPDATE_TEAM_SUCCESS, payload: null });
    yield put({ type: GET_ALL_TEAMS, payload: null });
  }
}

function* updatedRole({ payload }) {
  const { response, error } = yield call(services.team.updateRole, payload);

  if (error) {
    yield put({
      type: UPDATE_TEAM_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    const currentListOfComapnies = yield select(companyList);
    const newListofComapnies = currentListOfComapnies?.map((company) => {
      if (company._id === payload._id) {
        return { ...company, ...payload };
      }
      return company;
    });
    yield put({
      type: UPDATE_CURRENT_TEAM_LIST,
      payload: newListofComapnies,
    });
    yield put({ type: UPDATE_TEAM_SUCCESS, payload: null });
    yield put({ type: GET_ALL_TEAMS, payload: null });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<NewTeam>(NEW_TEAM, newTeam);
  yield takeLatest<GetAllTeams>(GET_ALL_TEAMS, getAllTeams);
  yield takeLatest<UpdateTeam>(UPDATE_TEAM, updatedTeam);
  yield takeLatest<any>(NEW_TEAM_INVITE_LINK, createLink);
  yield takeLatest<UpdateTeam>(DELETE_TEAM, deleteTeam);
  /* yield takeLatest<any>(NEW_INVITE_LINK, createLink); */
  yield takeLatest<any>(DELETE_TEAM_INVITE_LINK, deleteLink);
  yield takeLatest<any>(DELETE_TEAM_MEMBER, deleteMember);
  yield takeLatest<any>(UPDATE_TEAM_ROLE, updatedRole);
}
