import styled from "styled-components";

const resetFieldStyles = `
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  outline: none;
  border: 0px;
  margin: 0px;
  padding: 0px;
  overflow:hidden;
  background-color: transparent;
  width: 100%;`;
/* //height: calc(${(props) => props.theme.form.size[props.size].height} - 2px); */
export const Textarea = styled("textarea")<{ skin; size }>`
  ${resetFieldStyles}
  border-radius: 5px;
  min-height: 130px;
  max-height: 200px;
  line-height: ${(props) => props.theme.form.size[props.size].lineHeight};
  font-size: ${(props) => props.theme.form.size[props.size].fontSize};
  font-family: "Lato", sans-serif;
  color: ${(props) => props.theme.colors.Black};
  padding: ${(props) => props.theme.form.size[props.size].padding};
  overflow-y: auto;
  resize: none;
  background-color: #fff;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px
      ${(props) => props.theme.form.skin[props.skin].idle.backgroundColor} inset !important;
    background-color: transparent !important;
    background-image: none !important;
    color: ${(props) => props.theme.colors.Black} !important;
    font-family: "Lato", sans-serif;
    font-size: ${(props) => props.theme.form.size[props.size].mobileFontSize};
    line-height: ${(props) =>
      props.theme.form.size[props.size].mobileLineHeight};

    @media (min-width: 768px) {
      font-size: ${(props) => props.theme.form.size[props.size].fontSize};
      line-height: ${(props) => props.theme.form.size[props.size].lineHeight};
    }
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
`;
