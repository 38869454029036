import styled from "styled-components";

const resetFieldStyles = `
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  outline: none;
  border: 0px;
  margin: 0px;
  padding: 0px;
  background-color: transparent;
  width: 100%;`;
/* agregar padding links del menu, boton de salir esta muy al fondo, boton ver niveles con shadow */
export const Input = styled("input")<{ skin; size }>``;
export const ClearInput = styled("button")`
  border: 0px;
  margin: 0px;
  padding: 0px;
  background: none;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translate(0%, -50%);
  cursor: pointer;
`;
