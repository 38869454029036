import { put, call, takeLatest, select } from "redux-saga/effects";
import services from "../../services";
import {
  NEW_CONTENT,
  NEW_CONTENT_ERROR,
  NEW_CONTENT_SUCCESS,
  NewContent,
  GET_ALL_CONTENTS,
  GET_ALL_CONTENTS_ERROR,
  GET_ALL_CONTENTS_SUCCESS,
  GetAllContents,
  UPDATE_CONTENT,
  UPDATE_CONTENT_ERROR,
  UPDATE_CONTENT_SUCCESS,
  UpdateContent,
  DELETE_CONTENT,
  UPDATE_CURRENT_CONTENT_LIST,
  DELETE_CONTENT_SUCCESS,
  DELETE_CONTENT_ERROR,
  UNSAVED_CONTENT,
  SAVED_CONTENT,
  GetContent,
  GET_CONTENT,
  GET_CONTENT_ERROR,
  GET_CONTENT_SUCCESS,
  AddUserToContent,
  ADD_USER_TO_CONTENT,
  SharedContent,
  SHARED_CONTENT,
  UPDATE_MANY_CONTENT_SUCCESS,
  UPDATE_MANY_CONTENT_ERROR,
  UpdateManyContent,
  UPDATE_MANY_CONTENT,
} from "../types/content";
import { ContentDto } from "../../types/content.dto";

function* newContent({ payload }) {
  const { response, error } = yield call(services.content.new, payload);

  if (error) {
    yield put({
      type: NEW_CONTENT_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: NEW_CONTENT_SUCCESS, payload: response });
  }
}

function* manageContent({ payload }) {
  const { response, error } = yield call(
    services.content.manageContent,
    payload
  );

  if (error) {
    yield put({
      type: UPDATE_CONTENT_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: UPDATE_CONTENT_SUCCESS, payload: response });
  }
}

function* updateManyContent({ payload }) {
  let updatedList: ContentDto[] = [];

  try {
    for (let i = 0; i < payload.items.length; i++) {
      const { _id, ...body } = payload.items[i];
      const formDataInsteadBody = !!body.body ? body.body : body;
      const { response, error } = yield call(services.content.update, {
        _id,
        body: formDataInsteadBody,
      });
      if (!!response) {
        updatedList = [...updatedList, response];
      }
    }

    yield put({ type: UPDATE_MANY_CONTENT_SUCCESS, payload: updatedList });
  } catch (err) {
    yield put({ type: UPDATE_MANY_CONTENT_ERROR, payload: err });
  }
}

function* deleteContent({ payload }) {
  const { response, error } = yield call(services.content.delete, payload);

  if (error) {
    yield put({
      type: DELETE_CONTENT_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: DELETE_CONTENT_SUCCESS, payload: response });
  }
}

function* getAllContents({ payload }) {
  const { response, error } = yield call(services.content.getAll, payload);
  if (error) {
    yield put({
      type: GET_ALL_CONTENTS_ERROR,
      payload: error.response.data ? error.response.data : "Error",
    });
  } else {
    yield put({ type: GET_ALL_CONTENTS_SUCCESS, payload: response });
  }
}

function* updatedContent({ payload }) {
  const { response, error } = yield call(services.content.update, payload);
  if (error) {
    yield put({
      type: UPDATE_CONTENT_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: UPDATE_CONTENT_SUCCESS, payload: response });
  }
}
function* addUserToContent({ payload }) {
  const { response, error } = yield call(services.content.addUser, payload);

  if (error) {
    yield put({
      type: UPDATE_CONTENT_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: UPDATE_CONTENT_SUCCESS, payload: response });
  }
}
function* sharedContent({ payload }) {
  const { response, error } = yield call(services.content.shared, payload);

  if (error) {
    yield put({
      type: UPDATE_CONTENT_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: UPDATE_CONTENT_SUCCESS, payload: response });
  }
}
function* getContent({ payload }) {
  const { response, error } = yield call(services.content.getOne, payload);

  if (error) {
    yield put({
      type: GET_CONTENT_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_CONTENT_SUCCESS, payload: response });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<NewContent>(NEW_CONTENT, newContent);
  yield takeLatest<GetAllContents>(GET_ALL_CONTENTS, getAllContents);
  yield takeLatest<UpdateContent>(UPDATE_CONTENT, updatedContent);
  yield takeLatest<UpdateContent>(DELETE_CONTENT, deleteContent);
  yield takeLatest<any>(UNSAVED_CONTENT, manageContent);
  yield takeLatest<any>(SAVED_CONTENT, manageContent);
  yield takeLatest<GetContent>(GET_CONTENT, getContent);
  yield takeLatest<AddUserToContent>(ADD_USER_TO_CONTENT, addUserToContent);
  yield takeLatest<SharedContent>(SHARED_CONTENT, sharedContent);
  yield takeLatest<UpdateManyContent>(UPDATE_MANY_CONTENT, updateManyContent);
}
