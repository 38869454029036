import { Center, PageTitle } from "../../../../components/StyledComponents";
import CompanyModal from "./components/CompanyModal";
import SearchModal from "./components/SearchModal";
import TalentModal from "./components/TalentModal";
import DragAndDrop, { Column } from "../../../../components/DragAndDrop";
import { useDashboardLayout } from "../../../../provider/DashboardProvider";
import { useEffect, useState } from "react";
import SideTalentModal from "./components/SideTalentModal";
import { connect, useDispatch } from "react-redux";
import { showModal } from "../../../../store/actions/modal";
import { getIndividualStats } from "../../../../store/actions/analytics";
import aitActions from "../../../../store/actions/recruiting/index";
import { UserDto } from "../../../../types/user.dto";
import { AitTalentDto } from "../../../../types/recruiting.dto";
import { TalentDto } from "../../../../types/talent.dto";
import { getTalents, makeClick } from "../../../../store/actions/talent";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Loader from "../../../../components/Loader";
import { CurrentDto } from "../../../../types/current.dto";

interface ComponentProps {
  user: UserDto;
  talents: TalentDto[];
  ait: {
    _id: string;
    columns: Column<{ _id: string; name: string; avatar: string }>[];
    __v: number;
    user?: string;
    team?: string;
  }[];
  aitStates: {
    loading: boolean;
    error: boolean | string;
    success: boolean;
  };
  updateAitStates: {
    loading: boolean;
    error: boolean | string;
    success: boolean;
  };
  aitTalents: AitTalentDto[];
  selectedAcademy: string;
  current: CurrentDto;
}

const Component = ({
  user,
  ait,
  aitStates,
  talents,
  updateAitStates,
  aitTalents,
  selectedAcademy,
  current,
}: ComponentProps) => {
  const { setDashboardSkin } = useDashboardLayout();
  const [currentTalent, setCurrentTalent] = useState<AitTalentDto>();
  let params = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    setDashboardSkin("ait");
    !talents && dispatch(getTalents());
  }, []);

  useEffect(() => {
    if (updateAitStates.error) {
      toast(`😱 ${updateAitStates.error}`, {
        containerId: "submit",
        type: "error",
        toastId: "submiterror",
      });
      dispatch(aitActions.ait.getAit({ _id: "sa" }));
      setTimeout(() => {
        dispatch(aitActions.ait.resetUpdateAitTable());
      }, 1000);
    }
  }, [updateAitStates]);

  if (!talents || !aitTalents || !ait) return <Loader color="Primary"></Loader>;
  return (
    <>
      <PageTitle>Talent Board</PageTitle>

      <Center gap="16px">
        <SearchModal />
        <TalentModal />
        <CompanyModal />
      </Center>
      <div style={{ height: "45px" }}></div>
      {!!talents && !!ait && (
        <DragAndDrop
          onMove={(tables) => {
            /*  dispatch(
               makeClick({
                 _id: mergedCurrentTalent._id,
                 ...(current.currentSection === "academy" && {
                   currentAcademy: current.id,
                 }),
               })
             ); */
            dispatch(
              aitActions.ait.updateAitTable({
                _id: !params.id
                  ? ait.find((tab) => tab.user === user._id)?._id
                  : ait.find((tab) => tab.team === params.id)?._id,
                tables,
                __v: !params.id
                  ? ait.find((tab) => tab.user === user._id)?.__v
                  : ait.find((tab) => tab.team === params.id)?.__v,
              })
            );
          }}
          onSelect={(card) => {
            dispatch(
              getIndividualStats(
                talents?.find((talent) => talent._id === card.talent)?.userId
              )
            );
            dispatch(
              makeClick({
                _id: talents?.find((talent) => talent._id === card.talent)?._id,
                ...(current.currentSection === "academy" && {
                  currentAcademy: current.id,
                }),
              })
            );
            setCurrentTalent(card);
            dispatch(showModal("talent-side-modal"));
          }}
        />
      )}
      <SideTalentModal user={currentTalent} />
    </>
  );
};

const states = ({
  userStore,
  recruitingStore,
  talentStore,
  teamStore,
  currentStore,
}) => {
  const { data: user } = userStore;
  const { states: aitStates, data: ait } = recruitingStore.ait.all;
  const { states: updateAitStates } = recruitingStore.ait.update;
  const { data: talents } = talentStore.allTalents;
  const { data: aitTalents } = recruitingStore.talent.all;
  const { data: selectedAcademy } = teamStore.selected;
  const { data: current } = currentStore;
  return {
    user,
    talents,
    aitTalents,
    ait,
    aitStates,
    updateAitStates,
    selectedAcademy,
    current,
  };
};

export default connect(states)(Component);
