import { EventDto } from "../../types/event.dto";
import { PaginationDto } from "../../types/pagination.dto";

export const GET_ALL_EVENTS = "GET_ALL_EVENTS";
export const GET_ALL_EVENTS_ERROR = "GET_ALL_EVENTS_ERROR";
export const GET_ALL_EVENTS_SUCCESS = "GET_ALL_EVENTS_SUCCESS";
export const GET_EVENT = "GET_EVENT";
export const GET_EVENT_ERROR = "GET_EVENT_ERROR";
export const GET_EVENT_SUCCESS = "GET_EVENT_SUCCESS";
export const NEW_EVENT = "NEW_EVENT";
export const NEW_EVENT_ERROR = "NEW_EVENT_ERROR";
export const NEW_EVENT_SUCCESS = "NEW_EVENT_SUCCESS";
export const RESET_NEW_EVENT = "RESET_NEW_EVENT";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const UPDATE_EVENT_ERROR = "UPDATE_EVENT_ERROR";
export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS";
export const RESET_UPDATE_EVENT = "RESET_UPDATE_EVENT";
export const DELETE_EVENT = "DELETE_EVENT";
export const DELETE_EVENT_ERROR = "DELETE_EVENT_ERROR";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const RESET_DELETE_EVENT = "RESET_DELETE_EVENT";
export const RESET_GET_ALL_EVENTS = "RESET_GET_ALL_EVENTS";
export const GET_EVENTS_BY_USER = "GET_EVENTS_BY_USER";

export interface GetAllEvents {
  type: typeof GET_ALL_EVENTS;
  payload: PaginationDto;
}

export interface GetAllEventsSuccess {
  type: typeof GET_ALL_EVENTS_SUCCESS;
  payload: EventDto[];
}

export interface GetAllEventsError {
  type: typeof GET_ALL_EVENTS_ERROR;
  payload: boolean | string;
}

export interface ResetGetAllEvents {
  type: typeof RESET_GET_ALL_EVENTS;
  payload: null;
}

export interface GetEvent {
  type: typeof GET_EVENT;
  payload: { id: string };
}

export interface GetEventSuccess {
  type: typeof GET_EVENT_SUCCESS;
  payload: EventDto;
}

export interface GetEventError {
  type: typeof GET_EVENT_ERROR;
  payload: boolean | string;
}

export interface GetEventsByUser {
  type: typeof GET_EVENTS_BY_USER;
  payload: { _id: string };
}

export interface NewEvent {
  type: typeof NEW_EVENT;
  payload: EventDto;
}

export interface NewEventSuccess {
  type: typeof NEW_EVENT_SUCCESS;
  payload: EventDto;
}

export interface NewEventError {
  type: typeof NEW_EVENT_ERROR;
  payload: boolean | string;
}

export interface ResetNewEvent {
  type: typeof RESET_NEW_EVENT;
  payload: null;
}

export interface UpdateEvent {
  type: typeof UPDATE_EVENT;
  payload: EventDto;
}

export interface UpdateEventSuccess {
  type: typeof UPDATE_EVENT_SUCCESS;
  payload: EventDto;
}

export interface UpdateEventError {
  type: typeof UPDATE_EVENT_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateEvent {
  type: typeof RESET_UPDATE_EVENT;
  payload: null;
}

export interface DeleteEvent {
  type: typeof DELETE_EVENT;
  payload: { id: string };
}

export interface DeleteEventSuccess {
  type: typeof DELETE_EVENT_SUCCESS;
  payload: null;
}

export interface DeleteEventError {
  type: typeof DELETE_EVENT_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteEvent {
  type: typeof RESET_DELETE_EVENT;
  payload: null;
}

export interface State {
  allEvents: {
    data: EventDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  event: {
    data: EventDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  newEvent: {
    data: null | EventDto;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  deleteEvent: {
    data: null | EventDto;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  updateEvent: {
    data: null | EventDto;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  allEvents: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  event: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  newEvent: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  deleteEvent: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  updateEvent: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllEvents
  | GetAllEventsSuccess
  | GetAllEventsError
  | GetEvent
  | GetEventSuccess
  | GetEventError
  | NewEvent
  | NewEventSuccess
  | NewEventError
  | ResetNewEvent
  | UpdateEvent
  | UpdateEventSuccess
  | UpdateEventError
  | ResetUpdateEvent
  | DeleteEvent
  | DeleteEventSuccess
  | DeleteEventError
  | ResetDeleteEvent
  | ResetGetAllEvents
  | GetEventsByUser;
