import {
  initialState,
  Actions,
  State,
  GET_ALL_CATEGORIES,
  GET_ALL_CATEGORIES_ERROR,
  GET_ALL_CATEGORIES_SUCCESS,
  GET_CATEGORY,
  GET_CATEGORY_ERROR,
  GET_CATEGORY_SUCCESS,
  NEW_CATEGORY,
  NEW_CATEGORY_ERROR,
  NEW_CATEGORY_SUCCESS,
  RESET_NEW_CATEGORY,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_ERROR,
  UPDATE_CATEGORY_SUCCESS,
  RESET_UPDATE_CATEGORY,
  DELETE_CATEGORY,
  DELETE_CATEGORY_ERROR,
  DELETE_CATEGORY_SUCCESS,
  RESET_DELETE_CATEGORY,
} from "../types/category";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_CATEGORIES:
      return {
        ...state,
        allCategories: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        allCategories: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_CATEGORIES_ERROR:
      return {
        ...state,
        allCategories: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case GET_CATEGORY:
      return {
        ...state,
        category: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_CATEGORY_SUCCESS:
      return {
        ...state,
        category: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_CATEGORY_ERROR:
      return {
        ...state,
        category: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case NEW_CATEGORY:
      return {
        ...state,
        newCategory: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_CATEGORY_SUCCESS:
      return {
        ...state,
        newCategory: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case NEW_CATEGORY_ERROR:
      return {
        ...state,
        newCategory: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_CATEGORY:
      return {
        ...state,
        newCategory: initialState.newCategory,
      };
    case DELETE_CATEGORY:
      return {
        ...state,
        deleteCategory: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        deleteCategory: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_CATEGORY_ERROR:
      return {
        ...state,
        deleteCategory: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_CATEGORY:
      return {
        ...state,
        deleteCategory: initialState.deleteCategory,
      };
    case UPDATE_CATEGORY:
      return {
        ...state,
        updateCategory: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        updateCategory: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_CATEGORY_ERROR:
      return {
        ...state,
        updateCategory: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_CATEGORY:
      return {
        ...state,
        updateCategory: initialState.updateCategory,
      };
    default:
      return state;
  }
};

export default reducers;
