import * as Yup from "yup";

interface FormValues {
  name: string;
  lastName?: string;
  avatar?: any;
  country?: string;
  github?: string;
  portfolio?: string;
  linkedin?: string;
  curriculum?: string;
  province?: string;
  english?: string;
  email?: string;
  comments?: string;
  techsIds?: string[];
}

export const schema = Yup.object().shape({
  name: Yup.string().required("Ingresar minimo un caracter"),
  lastName: Yup.string(),
  country: Yup.string(),
  github: Yup.string(),
  portfolio: Yup.string(),
  linkedin: Yup.string(),
  province: Yup.string(),
  english: Yup.string(),
  email: Yup.string().email(),
  comments: Yup.string(),
  techsIds: Yup.array().of(Yup.string()),
});

export const initialValues = (initialValues: any): FormValues => ({
  name: "",
  lastName: "",
  ...initialValues,
});
