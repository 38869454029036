import { Outlet } from "react-router-dom";
import { Layout, Logo, Content } from "./styles";
import LogoImage from "../../../assets/images/logo-black.svg";
import logo from "../../../assets/icons/logo-TAAP-blue.svg";
interface ComponentProps {}

const Component = ({}: ComponentProps) => {
  return (
    <Layout style={{ backgroundColor: "#f4f5f6" }}>
      <Content>
        <Outlet />
      </Content>
    </Layout>
  );
};

export default Component;
