import React from "react";
import { HiArrowSmUp } from "react-icons/hi";
import { TiDocumentAdd } from "react-icons/ti";
import { FlexContainer } from "../../../../../components/StyledComponents";
import { ToolItemWrapper, ToolSideWrapper, ToolTipItem } from "./style";

const Component = ({ menu, setOpenDropDown, openDropDown }) => {
  return (
    <ToolSideWrapper openDropDown={openDropDown}>
      <FlexContainer direction="row-reverse" gap="10px">
        <ToolItemWrapper
          className={!openDropDown ? "dropActive active arrow" : "arrow"}
          onClick={() => {
            setOpenDropDown((state) => !state);
          }}
        >
          <HiArrowSmUp className="arrow" size={20}></HiArrowSmUp>
          <TiDocumentAdd size={20}></TiDocumentAdd>
        </ToolItemWrapper>
        {menu.map((item) => {
          return (
            <>
              {!item.onAvailable ||
              (!!item.onAvailable && !!item.onAvailable()) ? (
                item.activeFunction && !!item.activeFunction() ? (
                  !!item.IconActive ? (
                    <ToolItemWrapper
                      onClick={!!item.onClickActive && item.onClickActive}
                      className="active"
                    >
                      <ToolTipItem>{item.toolTipName}</ToolTipItem>
                      <item.IconActive size={17}></item.IconActive>
                    </ToolItemWrapper>
                  ) : (
                    <ToolItemWrapper
                      onClick={!!item.onClickActive && item.onClickActive}
                      className="active"
                    >
                      <ToolTipItem>{item.toolTipName}</ToolTipItem>
                      <item.Icon size={17}></item.Icon>
                    </ToolItemWrapper>
                  )
                ) : (
                  <ToolItemWrapper onClick={!!item.onClick && item.onClick}>
                    <ToolTipItem>{item.toolTipName}</ToolTipItem>
                    <item.Icon size={17}></item.Icon>
                  </ToolItemWrapper>
                )
              ) : (
                <ToolItemWrapper className="disavailable">
                  <ToolTipItem>{item.toolTipName}</ToolTipItem>
                  <item.Icon size={17}></item.Icon>
                </ToolItemWrapper>
              )}
            </>
          );
        })}
      </FlexContainer>
    </ToolSideWrapper>
  );
};

export default Component;
