import { createEditorStateWithText } from "@draft-js-plugins/editor";
import { convertToRaw } from "draft-js";
import React, { useEffect, useState } from "react";
import { MdFlashlightOn } from "react-icons/md";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Button from "../../../../components/Button";
import Grid from "../../../../components/Grid";
import {
  GridCard,
  GridCardImage,
  GridCardImageContainer,
  GridCardTitle,
  GridStructure,
} from "../../../../components/Layout/Dashboard/styles";
import Loader from "../../../../components/Loader";
import {
  ButtonFilter,
  Center,
  FlexContainer,
  PageTitle,
} from "../../../../components/StyledComponents";
import { DateFromNow } from "../../../../helpers/format-date";
import {
  getAllCDOCs,
  newCDOCs,
  resetGetAllCdocs,
  resetNewCDOCs,
  resetUpdateManyCdoc,
  updateManyCdoc,
} from "../../../../store/actions/cdoc";
import { showModal } from "../../../../store/actions/modal";
import { CDocDto } from "../../../../types/cdoc.dto";
import { CohortDto } from "../../../../types/cohort.dto";
import { CurrentDto } from "../../../../types/current.dto";
import { StateDto } from "../../../../types/states.dto";
import FilterButton from "./components/FilterButton";
import FilterByName from "./components/FilterByName";
import FilterModal from "./components/FilterModal";
import { DotContent, ItemDataText } from "./style";
import { UserDto } from "../../../../types/user.dto";
import { useNavigate } from "react-router-dom";
import { BiWorld } from "react-icons/bi";
import StateContentModal from "../../../../components/StateContentModal";
import ConfirmStateModal from "../../../../components/StateContentModal/components/ConfirmStateModal";
import { updateCohort } from "../../../../store/actions/cohort";
import { FetchData } from "../../../../types/fetchData.dto";
import InfinityScroll from "../../../../components/InfinityScroll";

const filterValues: any = [
  {
    fieldName: ["visibility", "order", "cohorts"],
    value: "no",
    name: "Todos",
  },
  {
    fieldName: "order",
    value: "recent",
    name: "Recientes",
    groupal: (array: CDocDto[]) =>
      array.sort(
        (a, b) =>
          new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
      ),
  },
  {
    fieldName: "order",
    value: "older",
    name: "Antiguos",
    groupal: (array: CDocDto[]) =>
      array.sort(
        (a, b) =>
          new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf()
      ),
  },
  {
    fieldName: "published",
    value: true,
    name: "Publicos",
  },
  {
    fieldName: "published",
    value: false,
    name: "Privados",
  },
];

const Component = ({
  current,
  cdocs,
  newCdoc,
  newCdocStates,
  cohorts,
  user,
  updateCohortStates,
  updateCdocStates,
  updateManyCdocStates,
  cdocsStates,
  updateManyCdocs,
}: {
  current: CurrentDto;
  cdocs: CDocDto[];
  newCdoc: CDocDto;
  newCdocStates: StateDto;
  cohorts: CohortDto[];
  user: UserDto;
  updateCohortStates: StateDto;
  updateCdocStates: StateDto;
  updateManyCdocStates: StateDto;
  cdocsStates: StateDto;
  updateManyCdocs: CDocDto[];
}) => {
  const [applyedFilters, setFilters] = useState<
    {
      field: string;
      value: string | boolean;
      special: (data?: any, data2?: any) => boolean;
      groupal: (...args: any) => any[];
    }[]
  >([]);
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);
  const [currentCdocsList, setCurrentCdocsList] = useState<any[]>([]);
  const [paginationOn, setPaginationOn] = useState<boolean>(false);
  const [filteredCurrentCdocsListValues, setFilteredCurrentCdocsListValues] =
    useState<any[]>([]);
  const fetchData = ({ page, filterBy }: FetchData) => {
    if (page === 0) {
      setPaginationOn(true);
    }
    let payload = {
      limit: 9,
      offset: 9 * page,
      filterBy: {
        ...(filterBy ? filterBy : {}),
        active: true,
        academy: current.id,
      },
    };
    const sortBy = "createdAt";
    const select = {
      _id: 1,
      name: 1,
      picture: 1,
      __v: 1,
      academy: 1,
      viewCount: 1,
      createdAt: 1,
      updatedAt: 1,
      users: 1,
    };
    const sortDirection = "desc";
    dispatch(getAllCDOCs({ ...payload, sortBy, sortDirection, select }));
  };
  const buildFilters = (newFilter) => {
    const filterUpdated =
      applyedFilters.filter((applyedFilter) =>
        !Array.isArray(newFilter.field)
          ? applyedFilter.field === newFilter.field
          : newFilter.field.includes(applyedFilter.field)
      ).length > 0;

    const filterRemoved = newFilter.value === "no";

    if (filterRemoved) {
      /**
       * Se elimina el filtro
       */
      if (Array.isArray(newFilter.field)) {
        setFilters([
          ...applyedFilters.filter(
            (applyedFilter) => !newFilter.field.includes(applyedFilter.field)
          ),
        ]);
      } else {
        setFilters([
          ...applyedFilters.filter(
            (applyedFilter) => applyedFilter.field !== newFilter.field
          ),
        ]);
      }
    }

    /**
     * Se actualiza un filtro
     */
    if (filterUpdated && !filterRemoved) {
      setFilters([
        ...applyedFilters.map((applyedFilter) => {
          if (applyedFilter.field === newFilter.field) {
            return newFilter;
          } else {
            return applyedFilter;
          }
        }),
      ]);
    }

    /**
     * Se agrega un filtro
     */
    if (!filterUpdated && !filterRemoved) {
      setFilters([...applyedFilters, newFilter]);
    }
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (!!paginationOn) {
      if (!isLoading && !!cdocs) {
        const filterCdocs = cdocs
          .filter((cdoc) =>
            current.role === "ADMIN"
              ? cdoc
              : cdoc.users.find((userFounded) => userFounded.id === user._id)
          )
          .map((cdoc) => {
            return {
              ...cdoc,
              type: !!cdoc.academy ? "Academy" : "Organization",
            };
          });
        setCurrentCdocsList((state) => [...state, ...filterCdocs]);
      }
      setThereAreItems(!!cdocs && cdocs?.length > 0);
    }
  }, [cdocs, isLoading, current]);

  useEffect(() => {
    setIsLoading(cdocsStates.loading);
  }, [cdocsStates]);

  useEffect(() => {
    if (updateManyCdocStates.success) {
      dispatch(resetUpdateManyCdoc());
    }
    if (updateManyCdocStates.error) {
      dispatch(resetUpdateManyCdoc());
    }
  }, [updateManyCdocStates, dispatch]);

  useEffect(() => {
    const filteredTalents = currentCdocsList?.filter((currentTalentValues) => {
      const thereAreFilters =
        applyedFilters.filter((applyedFilter) => !applyedFilter.groupal)
          .length > 0;
      const totalOfFilters = applyedFilters.length;
      const passFilters = applyedFilters
        .filter(
          (applyedFilter) => !applyedFilter.special || !applyedFilter.groupal
        )
        .filter(
          (applyedFilter) =>
            currentTalentValues[applyedFilter.field] === applyedFilter.value
        ).length;
      const specialFilter = applyedFilters.filter(
        (applyedFilter) => !!applyedFilter.special
      );

      const speacialFilterCheck = specialFilter.filter((applyedFilter) => {
        return applyedFilter.special(currentTalentValues, applyedFilter.value);
      });
      return (
        !thereAreFilters ||
        (thereAreFilters &&
          totalOfFilters === passFilters + specialFilter.length &&
          speacialFilterCheck.length === specialFilter.length)
      );
    });
    let initResult = filteredTalents;
    applyedFilters
      .filter((applyedFilter) => !!applyedFilter.groupal)
      .forEach((applyedFilter) => {
        initResult = applyedFilter.groupal(initResult);
      });
    setFilteredCurrentCdocsListValues(initResult);
  }, [applyedFilters, currentCdocsList]);

  useEffect(() => {
    if (newCdocStates.success) {
      window.open(
        `${process.env.REACT_APP_URL}/dashboard/contents/cdocs/${newCdoc._id}`,
        "_blank"
      );
      dispatch(resetNewCDOCs());
    }
    if (newCdocStates.error) {
      toast.error(newCdocStates.error);
      dispatch(resetNewCDOCs());
    }
  }, [newCdocStates]);

  useEffect(() => {
    if (updateManyCdocs && updateManyCdocStates.success && paginationOn) {
      setCurrentCdocsList(updateManyCdocs);
    }
  }, [updateManyCdocStates, updateManyCdocs, paginationOn]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllCdocs());
    };
  }, []);

  return (
    <>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col>
            <PageTitle>CDocs</PageTitle>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col
            offset={{ lg: 10, sm: 10, md: 10 }}
            lg={2}
            md={2}
            sm={2}
            style={{ marginBottom: "14px" }}
          >
            <Button
              onClick={() => {
                navigate(`management`);
              }}
              type="button"
              options={{
                type: "filled",
                skin: "primary",
                size: "md",
                marginBottom: "0px",
              }}
              style={{
                width: "100px",
                height: "50px",
              }}
            >
              Crear
            </Button>
          </Grid.Col>
        </Grid.Row>
        <Center>
          <Grid.Row style={{ width: "100%" }}>
            <Grid.Col lg={12} style={{ marginBottom: "14px" }}>
              <FlexContainer
                style={{
                  width: "100%",
                  maxWidth: "700px",
                  margin: "0 auto",
                }}
                justify="space-between"
                align="center"
                gap="14px"
              >
                <FilterByName setFilter={buildFilters}></FilterByName>
                <ButtonFilter
                  onClick={() => {
                    dispatch(showModal("filter-cohort-modal"));
                  }}
                >
                  <MdFlashlightOn size={20}></MdFlashlightOn>
                </ButtonFilter>
                <ButtonFilter
                  onClick={() => {
                    dispatch(showModal("change-content-states"));
                  }}
                >
                  <BiWorld size={20}></BiWorld>
                </ButtonFilter>
              </FlexContainer>
            </Grid.Col>
          </Grid.Row>
        </Center>
        <Center gap="10px" style={{ marginBottom: "25px" }}>
          <FlexContainer wrap="wrap" align="center" gap="20px">
            {filterValues.map((filterValue) => {
              return (
                <FilterButton
                  setFilter={buildFilters}
                  name={filterValue.name}
                  fieldName={filterValue.fieldName}
                  value={filterValue.value}
                  filters={applyedFilters}
                  {...(!!filterValue.special && {
                    special: { ...filterValue.special },
                  })}
                ></FilterButton>
              );
            })}
          </FlexContainer>
        </Center>
        <Grid.Row>
          <Grid.Col>
            <InfinityScroll
              action={fetchData}
              page={page}
              setPage={setPage}
              data={filteredCurrentCdocsListValues}
              active={thereAreItems}
              isLoading={isLoading}
            >
              <GridStructure
                width={"240px"}
                maxWidth="900px"
                style={{ width: "100%", margin: "0 auto" }}
              >
                {filteredCurrentCdocsListValues.map((item) => {
                  return (
                    <GridCard
                      onClick={() => {
                        navigate(
                          `/dashboard/contents/cdocs/management/${item._id}`
                        );
                      }}
                      pd={"12px"}
                      key={item._id}
                    >
                      <GridCardImageContainer>
                        <GridCardImage
                          src={
                            item.picture ||
                            "https://upload.wikimedia.org/wikipedia/commons/8/81/Gallet_clamshell_600x600_6.jpg"
                          }
                        ></GridCardImage>
                      </GridCardImageContainer>
                      <FlexContainer gap="12px">
                        <FlexContainer direction="column" gap="1px">
                          <GridCardTitle>{item.name}</GridCardTitle>
                          <FlexContainer gap="5px" align="center">
                            <ItemDataText>Usuarios:</ItemDataText>
                            <ItemDataText>{item.users.length}</ItemDataText>

                            <FlexContainer align="center" gap="5px">
                              <DotContent></DotContent>
                              <ItemDataText>{`Publicado: ${DateFromNow(
                                item.createdAt
                              )}`}</ItemDataText>
                            </FlexContainer>
                          </FlexContainer>
                        </FlexContainer>
                      </FlexContainer>
                    </GridCard>
                  );
                })}
              </GridStructure>
            </InfinityScroll>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
      <FilterModal buildFilters={buildFilters}></FilterModal>

      <StateContentModal
        action={updateManyCdoc}
        section="Cdocs"
        loading={updateManyCdocStates.loading}
        items={filteredCurrentCdocsListValues}
      ></StateContentModal>

      {/*   {!!cohorts && <ConfirmStateModal items={cohorts} fieldChanging={fieldChanging} loading={}></ConfirmStateModal>} */}
    </>
  );
};

const states = ({ currentStore, cdocStore, cohortStore, userStore }) => {
  const { data: current } = currentStore;
  const { data: cdocs, states: cdocsStates } = cdocStore.all;
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: newCdoc, states: newCdocStates } = cdocStore.new;
  const { states: updateCdocStates } = cdocStore.update;
  const { states: updateManyCdocStates, data: updateManyCdocs } =
    cdocStore.updateMany;
  const { states: updateCohortStates } = cohortStore.updateCohort;
  const { data: user } = userStore;
  return {
    current,
    cdocs,
    newCdoc,
    newCdocStates,
    cohorts,
    user,
    updateCohortStates,
    updateCdocStates,
    updateManyCdocStates,
    cdocsStates,
    updateManyCdocs,
  };
};

export default connect(states)(Component);
