interface Props {
  stages?: {
    [key: string]: number;
  }[];
  type: string;
}

export const formatStages = ({ stages, type }: Props) => {
  if (stages)
    return stages.map((stage) => {
      const stageValue = Object.entries(stage);
      return {
        level: stageValue[0],
        note: stageValue[1],
      };
    });
};
