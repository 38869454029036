import { connect } from "react-redux";
import { InLine } from "../../../../../../components/StyledComponents";
import { useRecruitingLayout } from "../../../../../../provider/RecruitingProvider";
import Icon from "../../../../../../components/Icon";
import {
  TabTitle,
  Description,
  Content,
  ListTitle,
  GrayCard,
} from "../../../components/StyledComponents";
import { AiOutlineMail, AiFillLinkedin, AiFillGithub } from "react-icons/ai";
import { MdOutlineCategory } from "react-icons/md";
import { GiSuitcase } from "react-icons/gi";
import TalentSearchIcon from "../../../../../../assets/icons/talent-search.svg";
import GlobeIcon from "../../../../../../assets/icons/globe.svg";
import MarkerIcon from "../../../../../../assets/icons/marker.svg";

const Component = ({ children }) => {
  const { selectedItem, statics } = useRecruitingLayout();

  return (
    <>
      <InLine justify="space-between">
        <TabTitle>Perfil de Talento</TabTitle>
        {children}
      </InLine>

      <Description>
        Customizar el perfil de talento externo te ayudara a ti y a Dev Place a
        facilitar la conexión entre talentos, tus búsquedas y las necesidades de
        esta empresa en particular. Ten en cuenta que es un perfil externo a los
        talentos que se egresan de los Bootcamps IT de Dev Place por lo que no
        podrás ver ningún tipo de métrica.
      </Description>

      <Content>
        <GrayCard>
          <InLine
            style={{
              alignItems: "flex-start",
              justifyContent: "flex-start",
              marginBottom: "5px",
            }}
          >
            <InLine style={{ justifyContent: "flex-start" }}>
              <Icon icon={TalentSearchIcon} size="12px" color="black" />
              <span style={{ margin: "0px 5px", color: "#334253" }}>
                Perfil:
              </span>
            </InLine>
            {selectedItem?.data?.name}
          </InLine>
          <InLine
            style={{
              alignItems: "flex-start",
              justifyContent: "flex-start",
              marginBottom: "5px",
            }}
          >
            <InLine style={{ justifyContent: "flex-start" }}>
              <AiFillLinkedin
                height={12}
                width={12}
                color={"black"}
              ></AiFillLinkedin>
              <span style={{ margin: "0px 5px", color: "#334253" }}>
                Linkedin:
              </span>
            </InLine>
            {selectedItem?.data?.linkedin}
          </InLine>
          <InLine
            style={{
              alignItems: "flex-start",
              justifyContent: "flex-start",
              marginBottom: "5px",
            }}
          >
            <InLine style={{ justifyContent: "flex-start" }}>
              <AiOutlineMail
                height={12}
                width={12}
                color={"black"}
              ></AiOutlineMail>
              <span style={{ margin: "0px 5px", color: "#334253" }}>
                Email:
              </span>
            </InLine>
            {selectedItem?.data?.email}
          </InLine>
          <InLine
            style={{
              alignItems: "flex-start",
              justifyContent: "flex-start",
              marginBottom: "5px",
            }}
          >
            <InLine style={{ justifyContent: "flex-start" }}>
              <Icon icon={MarkerIcon} size="12px" color="black" />
              <span style={{ margin: "0px 5px", color: "#334253" }}>
                Ubicación:
              </span>
            </InLine>
            Provincia de{" "}
            {
              statics?.allProvinces?.data?.filter(
                (province) => province?.value === selectedItem?.data?.province
              )[0]?.label
            }
            , Argentina
          </InLine>

          <InLine
            style={{
              alignItems: "flex-start",
              justifyContent: "flex-start",
              marginBottom: "5px",
            }}
          >
            <InLine style={{ justifyContent: "flex-start" }}>
              <Icon icon={GlobeIcon} size="12px" color="black" />
              <span style={{ margin: "0px 5px", color: "#334253" }}>
                Inglés:
              </span>
            </InLine>
            {selectedItem?.data?.english}
          </InLine>
          <InLine
            style={{
              alignItems: "flex-start",
              justifyContent: "flex-start",
              marginBottom: "5px",
            }}
          >
            <InLine style={{ justifyContent: "flex-start" }}>
              <AiFillGithub
                height={12}
                width={12}
                color={"black"}
              ></AiFillGithub>
              <span style={{ margin: "0px 5px", color: "#334253" }}>
                Gitub:
              </span>
            </InLine>
            {selectedItem?.data?.github}
          </InLine>
          <InLine
            style={{
              alignItems: "flex-start",
              justifyContent: "flex-start",
              marginBottom: "5px",
            }}
          >
            <InLine style={{ justifyContent: "flex-start" }}>
              <GiSuitcase height={12} width={12} color={"black"}></GiSuitcase>
              <span style={{ margin: "0px 5px", color: "#334253" }}>
                Portfolio:
              </span>
            </InLine>
            {selectedItem?.data?.portfolio}
          </InLine>
          <InLine
            style={{
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <InLine style={{ justifyContent: "flex-start" }}>
              <span style={{ margin: "0px 5px 0px 0px", color: "#334253" }}>
                Resumen:
              </span>
            </InLine>
            {selectedItem?.data?.comments}
          </InLine>
        </GrayCard>
      </Content>
    </>
  );
};

const states = ({ recruitingStore }) => {
  const { data: allSearches, states: allSearchesStates } =
    recruitingStore.search.all;

  return { allSearches, allSearchesStates };
};

export default connect(states)(Component);
