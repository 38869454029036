import { connect, useDispatch } from "react-redux";
import { InLine } from "../../../../../../components/StyledComponents";
import CrossIcon from "../../../../../../assets/icons/cross.svg";
import Icon from "../../../../../../components/Icon";
import { useRecruitingLayout } from "../../../../../../provider/RecruitingProvider";
import { CompanyDto } from "../../../../../../types/recruiting.dto";
import ShowModal from "../../../components/ShoModal";
import {
  Tab,
  TabTitle,
  Description,
  Content,
  ListTitle,
  List,
  ListItem,
  GrayCard,
  Delete,
} from "../../../components/StyledComponents";
import { useParams } from "react-router-dom";

interface ComponentProps {
  companies: CompanyDto[];
}

const Component = ({ companies }: ComponentProps) => {
  const { actions, selectedItem } = useRecruitingLayout();
  const dispatch = useDispatch();
  const params = useParams();

  return (
    <>
      <InLine justify="space-between">
        <TabTitle>Asignar Empresas a Talento</TabTitle>
        <ShowModal.AsignarEmpresa />
      </InLine>

      <Description>
        Puedes asignar empresas a talentos externos o talentos de los bootcamps
        IT. Te ayudara a ti y a Dev Place a facilitar la conexión entre
        talentos, tus búsquedas y las necesidades de esta empresa en particular.
      </Description>

      <Content>
        <List>
          {companies
            ?.filter((company) => company.active)
            .filter((ability) => ability.team === params.id)
            .filter((company) =>
              selectedItem?.data?.companies?.includes(company._id)
            )
            .map((item) => (
              <ListItem>
                {item.name}
                <Delete
                  onClick={() =>
                    dispatch(
                      actions.update({
                        ...selectedItem?.data,
                        companies: selectedItem?.data?.companies?.filter(
                          (company) => company !== item._id
                        ),
                      })
                    )
                  }
                >
                  <Icon icon={CrossIcon} size="9px" color="black" />
                </Delete>
              </ListItem>
            ))}
        </List>
      </Content>
    </>
  );
};

const states = ({ recruitingStore }) => {
  const { data: companies } = recruitingStore.company.all;
  return {
    companies,
  };
};

export default connect(states)(Component);
