import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Player from "react-player";
import VideoSpinner from "../../../../../../components/VideoSpinner";
import CustomControllers from "./components/CustomControllers";
import { LightWrapper, SpinnerWrapper, VideoPreview } from "./style";
import screenfull from "screenfull";
import ReactPlayer from "react-player";
const Component = forwardRef(
  (
    {
      video,
      CustomPlay,
      lightImage,
      children,
      playerHeight,
      videoCurrent,
    }: {
      video: string;
      CustomPlay: any;
      lightImage?: string;
      children?;
      playerHeight: string;
      videoCurrent;
    },
    ref
  ) => {
    const [isPlayed, setIsPlayed] = useState<boolean>(true);
    const [currentSeek, setCurrentSeek] = useState<number>(0);
    const [volumeBar, setVolumeBar] = useState<number>(0);
    const [volume, setVolume] = useState<number>(0);
    const [totalVideoDuration, setTotalVideoDuration] = useState<number>(0);
    const [isMuted, setIsMuted] = useState<boolean>(false);
    const [lightOn, setLightOn] = useState<boolean>(true);
    const [previousVolume, setPreviousVolume] = useState<number>(0);
    const [refState, setRefState] = useState<any>(null);
    const [buffering, setBuffering] = useState<boolean>(true);
    let playerRef: any = useRef(null);
    let playerWapperRef: any = useRef(null);
    const handleVolumeChange = (e) => {
      setVolume(Number(e.target.value / 100));
      setVolumeBar(Number(e.target.value));
    };
    const handleOnProgress = (e) => {
      setCurrentSeek(e.playedSeconds);
    };

    const handleFullScreen = () => {
      if (playerRef.current) {
        screenfull.isFullscreen
          ? screenfull.exit()
          : screenfull.request(playerWapperRef.current);
      }
    };
    const handleSeekChange = (e) => {
      if (playerRef.current) {
        setCurrentSeek(Number(e.target.value));
        playerRef.current.seekTo(Number(e.target.value));
      }
    };

    const onSeek = (e) => {
      if (totalVideoDuration === e) {
        setCurrentSeek(0);
      }
    };

    const handlePlay = () => {
      if (totalVideoDuration === 0 && playerRef.current) {
        setTotalVideoDuration(playerRef.current.getDuration());
      }
      if (totalVideoDuration === currentSeek) {
        setCurrentSeek(0);
      }
      setIsPlayed(true);
    };

    const getDuration = (e) => {
      setTotalVideoDuration(e);
    };

    const handlePause = (e) => {
      setIsPlayed(false);
    };

    const handleMute = () => {
      if (!isMuted) {
        setVolume(0);
        setVolumeBar((state) => {
          setPreviousVolume(state);
          return 0;
        });
        setIsMuted(true);
      } else {
        setVolume(previousVolume / 100);
        setVolumeBar(previousVolume);
        setIsMuted(false);
      }
    };

    const handleSeekTo = (seconds) => {
      if (playerRef.current) {
        setCurrentSeek((state) => state + seconds);
        playerRef.current.seekTo(playerRef.current.getCurrentTime() + seconds);
      }
    };

    const handleBufferStart = () => {
      setBuffering(true);
    };

    const handleBufferEnd = () => {
      setBuffering(false);
    };
    useImperativeHandle(
      ref,
      () => ({
        resetLight: () => {
          if (refState) refState.showPreview();
          setLightOn(true);
        },
        getDuration: () => {
          if (refState) {
            return refState.getDuration();
          }
        },
      }),
      [refState]
    );

    useEffect(() => {
      if (volumeBar > 0) {
        setIsMuted(false);
      }
    }, [volumeBar]);

    useEffect(() => {
      if (playerWapperRef && !lightOn) {
        const player = playerWapperRef.current;

        player.addEventListener("keydown", (ev) => {
          if (ev.keyCode === 32) {
            ev.preventDefault();
            setIsPlayed((state) => !state);
          }
          if (ev.which === 39) {
            handleSeekTo(5);
          }
          if (ev.which === 37) {
            handleSeekTo(-5);
          }
        });
        return () => player.removeEventListener("keydown", () => {});
      }
    }, [playerWapperRef, lightOn]);

    useEffect(() => {
      if (!!playerRef.current && currentSeek === totalVideoDuration) {
        playerRef.current.seekTo(0);
        setCurrentSeek(0);
        setIsPlayed(false);
      }
    }, [currentSeek, totalVideoDuration]);

    return (
      <VideoPreview tabIndex="0" ref={playerWapperRef} height={playerHeight}>
        {children}
        <Player
          controls={false}
          width={"100%"}
          height={"100%"}
          url={video}
          playIcon={
            <LightWrapper onClick={() => setLightOn(false)}>
              <CustomPlay onClick={() => setLightOn(false)}></CustomPlay>
            </LightWrapper>
          }
          light={lightImage}
          playing={isPlayed}
          muted={isMuted}
          volume={volume}
          onSeek={onSeek}
          onProgress={handleOnProgress}
          onClick={(ev) => {
            playerWapperRef.current.focus();
          }}
          onDuration={(e) => {
            getDuration(e);
          }}
          onBuffer={handleBufferStart}
          onBufferEnd={handleBufferEnd}
          onReady={(player) => {
            setRefState(player);
            playerRef.current = player;
          }}
        />
        {!!buffering && !lightOn && (
          <SpinnerWrapper>
            <VideoSpinner></VideoSpinner>
          </SpinnerWrapper>
        )}
        {!lightOn && (
          <CustomControllers
            handleFullScreen={handleFullScreen}
            currentSeek={currentSeek}
            handlePause={handlePause}
            handlePlay={handlePlay}
            handleSeekChange={handleSeekChange}
            handleVolumeChange={handleVolumeChange}
            isPlayed={isPlayed}
            totalVideoDuration={totalVideoDuration}
            volumeBar={volumeBar}
            handleMute={handleMute}
            isMuted={isMuted}
          ></CustomControllers>
        )}

        {/*   <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            handleSeekTo(-5);
          }}
        >
          Retroceder
        </button>
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            handleSeekTo(5);
          }}
        >
          Avanzar
        </button> */}
      </VideoPreview>
    );
  }
);

export default Component;
