import styled from "styled-components";
import { NavLink as NavLinkUi } from "react-router-dom";
import { EllipsisText } from "../../../StyledComponents";
export const List = styled(`div`)`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

export const TitleList = styled("label")`
  font-size: 16px;
  font-weight: 500;
  color: #646464;
`;

export const Links = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Link = styled("div")`
  cursor: pointer;
  padding: 10px 8px 10px 10px;
  border-radius: 10px;
  box-shadow: 0 0 2px 0 rgba(0, 30, 108, 0.1);
  background-color: ${(props) => props.theme.colors.White};
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: ${(props) => props.theme.colors.Primary};
  }
  &:hover h3 {
    color: ${(props) => props.theme.colors.White};
  }
`;

export const LinkText = styled("h3")`
  font-size: 14px;
  font-weight: 500;
  color: #2a2747;
  ${EllipsisText}
`;

export const LinkImageWrapper = styled("div")`
  min-width: 32px;
  min-height: 32px;
  max-width: 32px;
  max-height: 32px;
  overflow: hidden;
`;

export const LinkRole = styled("div")`
  padding: 10px 4px;
  border-radius: 10px;
  border: solid 1px #ececec;
  background-color: ${(props) => props.theme.colors.White};
`;
