import Modal from "../../../../../components/Modal";
import NewContentForm from "../New";

const Component = () => {
  return (
    <Modal name="new-content-modal" title="Nuevo Contenido">
      <NewContentForm />
    </Modal>
  );
};

export default Component;
