export const breakpoints = {
  xs: 320,
  sm: 768,
  md: 1024,
  lg: 1200,
}

export const mediaBreakpoints = {
  xsMin: `${breakpoints.xs}px`,
  xsMax: `${breakpoints.sm - 1}px`,
  smMin: `${breakpoints.sm}px`,
  smMax: `${breakpoints.md - 1}px`,
  mdMin: `${breakpoints.md}px`,
  mdMax: `${breakpoints.lg - 1}px`,
  lgMin: `${breakpoints.lg}px`,
}
