import {
  initialState,
  Actions,
  State,
  GET_ALL_STUDENTS,
  GET_ALL_STUDENTS_ERROR,
  GET_ALL_STUDENTS_SUCCESS,
  GET_STUDENT,
  GET_STUDENT_ERROR,
  GET_STUDENT_SUCCESS,
  NEW_STUDENT,
  NEW_STUDENT_SUCCESS,
  NEW_STUDENT_ERROR,
  RESET_NEW_STUDENT,
  UPDATE_STUDENT,
  UPDATE_STUDENT_ERROR,
  UPDATE_STUDENT_SUCCESS,
  RESET_UPDATE_STUDENT,
  DELETE_STUDENT,
  DELETE_STUDENT_ERROR,
  DELETE_STUDENT_SUCCESS,
  RESET_DELETE_STUDENT,
} from "../types/student";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_STUDENTS:
      return {
        ...state,
        allStudents: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_STUDENTS_SUCCESS:
      return {
        ...state,
        allStudents: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_STUDENTS_ERROR:
      return {
        ...state,
        allStudents: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case GET_STUDENT:
      return {
        ...state,
        student: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_STUDENT_SUCCESS:
      return {
        ...state,
        student: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_STUDENT_ERROR:
      return {
        ...state,
        student: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case NEW_STUDENT:
      return {
        ...state,
        newStudent: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_STUDENT_SUCCESS:
      return {
        ...state,
        newStudent: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case NEW_STUDENT_ERROR:
      return {
        ...state,
        newStudent: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_STUDENT:
      return {
        ...state,
        newStudent: initialState.newStudent,
      };
    case DELETE_STUDENT:
      return {
        ...state,
        deleteStudent: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_STUDENT_SUCCESS:
      return {
        ...state,
        deleteStudent: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_STUDENT_ERROR:
      return {
        ...state,
        deleteStudent: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_STUDENT:
      return {
        ...state,
        deleteStudent: initialState.deleteStudent,
      };
    case UPDATE_STUDENT:
      return {
        ...state,
        updateStudent: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_STUDENT_SUCCESS:
      return {
        ...state,
        updateStudent: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_STUDENT_ERROR:
      return {
        ...state,
        updateStudent: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_STUDENT:
      return {
        ...state,
        updateStudent: initialState.updateStudent,
      };
    default:
      return state;
  }
};

export default reducers;
