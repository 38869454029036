import styled from "styled-components";
import { Card as CardUi } from "../../../../../components/StyledComponents";

export const Card = styled(CardUi)`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: 960px) {
    flex-direction: row;
    width: 100%;
    max-width: 760px;
    &.grid {
      display: block;
    }
  }
`;
export const CardRow = styled("div")`
  display: flex;
`;
export const CardCol = styled("div")`
  display: flex;
  flex-direction: column;
`;
export const Avatar = styled("div")<{ avatar: string }>`
  ${(props) => {
    return `
    padding: 0px;
  background: rgb(30, 120, 232);
  border-radius: 100%;
  background-color: gray;
  background-image: url(${props.avatar});
  background-size: cover;
  background-position: center;
  margin-right: 8px;
  font-size: 32px;
  width: ${!!props.grid ? "50px" : "80px"};
  height: ${!!props.grid ? "50px" : "80px"};
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  & > * {
    width: 100%;
    border-radius: 100%;
    object-fit: cover;
  }
    
    `;
  }}
`;
export const Information = styled("div")`
  padding: 0px;
`;
export const Name = styled("p")`
  margin: ${(props) => (!!props.grid ? "0px 0px 4px" : "0px 0px 5px")};
  font-size: ${(props) => (!!props.grid ? "14px" : "20px")};
  line-height: normal;
  font-weight: 500;
  color: #707070;
`;
export const Profile = styled("p")`
  margin: ${(props) => (!!props.grid ? "0px 0px 4px" : "0px 0px 5px")};
  font-size: ${(props) => (!!props.grid ? "12px" : "16px")};
  font-weight: 400;
  line-height: normal;
  color: #334253;
`;

export const ButtonText = styled("span")`
  font-size: 12px;
  font-weight: bold;
  color: #fff;
`;

export const Location = styled("p")`
  margin: 0px;
  font-size: ${(props) => (!!props.grid ? "10px" : "12px")};
  font-weight: 400;
  line-height: normal;
  color: #334253;

  span {
    text-transform: capitalize;
  }
`;
export const ItemLink = styled("a")``;
export const Commets = styled("p")`
  margin: 0px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 400;
  color: #697482;
  margin-bottom: 20px;

  @media (min-width: 960px) {
    margint-bottom: 0px;
  }
`;

export const List = styled("ul")`
  width: 100%;
  margin: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
export const Item = styled("li")`
  list-style: none;
`;

export const SmallCardChart = styled(CardUi)`
  width: 200px;
  height: 70px;
`;
export const BigCardChart = styled(CardUi)`
  width: 150px;
  height: 150px;
  padding: 5px;
`;

export const NoteCard = styled("h3")`
  margin: 6px;
  font-size: 40px;
  font-weight: 500;
  color: #334253;
  text-align: center;
`;
