import {
  NewContent,
  NEW_CONTENT,
  ResetNewContent,
  RESET_NEW_CONTENT,
  GET_ALL_CONTENTS,
  GetAllContents,
  UpdateContent,
  UPDATE_CONTENT,
  ResetUpdateContent,
  RESET_UPDATE_CONTENT,
  GetContent,
  DeleteContent,
  DELETE_CONTENT,
  GET_CONTENT,
  RESET_DELETE_CONTENT,
  UNSAVED_CONTENT,
  SAVED_CONTENT,
  RESET_GET_CONTENT,
  ADD_USER_TO_CONTENT,
  SHARED_CONTENT,
  UPDATE_MANY_CONTENT,
  RESET_UPDATE_MANY_CONTENT,
  RESET_GET_ALL_CONTENT_DATA,
} from "../types/content";

const create = (payload): NewContent => {
  return { type: NEW_CONTENT, payload };
};
const resetCreate = (): ResetNewContent => {
  return { type: RESET_NEW_CONTENT, payload: null };
};

const update = (payload): UpdateContent => {
  return { type: UPDATE_CONTENT, payload };
};

const resetUpdate = (): ResetUpdateContent => {
  return { type: RESET_UPDATE_CONTENT, payload: null };
};

const unSaved = (payload) => {
  return { type: UNSAVED_CONTENT, payload };
};
const saved = (payload) => {
  return { type: SAVED_CONTENT, payload };
};

const addUser = (payload) => {
  return { type: ADD_USER_TO_CONTENT, payload };
};

export const updateMany = (payload) => {
  return { type: UPDATE_MANY_CONTENT, payload };
};

export const resetUpdateMany = () => {
  return { type: RESET_UPDATE_MANY_CONTENT, payload: null };
};

const getAll = (payload): GetAllContents => {
  return { type: GET_ALL_CONTENTS, payload };
};

const resetGetAllContents = () => {
  return { type: RESET_GET_ALL_CONTENT_DATA, payload: null };
};

const getOne = (payload: { _id: string }): GetContent => {
  return { type: GET_CONTENT, payload };
};

const resetGetOne = () => {
  return { type: RESET_GET_CONTENT, payload: null };
};
const deleteOne = (payload: { _id: string }): DeleteContent => {
  return { type: DELETE_CONTENT, payload };
};

const resetDeleteOne = () => {
  return { type: RESET_DELETE_CONTENT, payload: null };
};

const sharedContent = (payload) => {
  return { type: SHARED_CONTENT, payload };
};

const actions = {
  create,
  resetCreate,
  update,
  resetUpdate,
  resetDeleteOne,
  unSaved,
  getAll,
  getOne,
  deleteOne,
  saved,
  resetGetOne,
  addUser,
  sharedContent,
  updateMany,
  resetUpdateMany,
  resetGetAllContents,
};

export default actions;
