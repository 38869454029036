import React from "react";
import { FlexContainer } from "../../../../../../../components/StyledComponents";
import { NumberStatLabel, NumberStatValue, NumberStatWrapper } from "./style";

const Component = ({ value, label }) => {
  return (
    <NumberStatWrapper>
      <FlexContainer
        direction="column"
        gap="12px"
        justify="center"
        align="center"
      >
        <NumberStatValue>{value}</NumberStatValue>
        <NumberStatLabel>{label}</NumberStatLabel>
      </FlexContainer>
    </NumberStatWrapper>
  );
};

export default Component;
