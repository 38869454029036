import * as Yup from "yup";

interface InitialValues {
  name: string;
}

export const schema = Yup.object().shape({
  name: Yup.string().required().min(7, "Al menos 7 caracteres"),
});

export const initialValues: InitialValues = {
  name: "",
};
