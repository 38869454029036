import * as Yup from "yup";

export const schema = Yup.object().shape({
  accountHolder: Yup.string() /* .required("Se requiere nombre del titular") */,
  cbu: Yup.string() /* .matches(/^\d{22}$/, "CBU invalido") */,
  alias: Yup.string(),
  bankName: Yup.string(),
});

interface FormValues {
  accountHolder: string;
  cbu: string;
  alias: boolean;
  bankName: string;
}

export const initialValues = (values): FormValues => {
  return {
    accountHolder: "",
    cbu: "",
    alias: "",
    bankName: "",
    ...values,
  };
};
