import React from "react";
import { FlexContainer } from "../../../../../../../../components/StyledComponents";
import AnalyticCard from "../../../../../components/AnalyticCard";
import {
  formatNumber,
  overThousandFixed,
} from "../../../../../../../../helpers/number-fixed";
import { DateEStoDateEN } from "../../../../../../../../helpers/format-date";
import NotData from "../../../../../components/NotData";
import LineGraphHorizontal from "../../../../../../../../components/LineGraphHorizontal";
import { CohortUserTimeDto } from "../../../../../../../../types/analytics.dto";
import { GridStructure } from "../../../../../../../../components/Layout/Dashboard/styles";
import PieChart from "../../../../../../../../components/PieChart";

const Component = ({
  analyticsUserTime,
  values,
}: {
  analyticsUserTime: CohortUserTimeDto;
  values: any;
}) => {
  return (
    <FlexContainer direction="column" gap="6px">
      <AnalyticCard
        title={
          "Horas totales de consumo de contenido por cada unit del cohort."
        }
        graphTitle={"Horas totales de consumo de contenido de la unidad"}
        data={{
          stats: [
            {
              label: "Últimos 30 días",
              value: overThousandFixed(
                analyticsUserTime.units.filter(
                  (unit) => unit[0] === values["unit"]
                )[0][1].lastMonth
              ),
            },
            {
              label: "Últimos 7 días",
              value: overThousandFixed(
                analyticsUserTime.units.filter(
                  (unit) => unit[0] === values["unit"]
                )[0][1].lastWeek
              ),
            },
            {
              label: "Últimas 24 horas",
              value: overThousandFixed(
                analyticsUserTime.units.filter(
                  (unit) => unit[0] === values["unit"]
                )[0][1].lastDay
              ),
            },
          ],
          graphData: analyticsUserTime.units
            .filter((unit) => unit[0] === values["unit"])[0][1]
            .growth.sort((a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0]))
            .map((growthData) => {
              return {
                name: growthData[0],
                value: formatNumber(growthData[1]),
              };
            }),
        }}
      ></AnalyticCard>

      <AnalyticCard
        height="250px"
        title="Top 10 contenidos mas consumidos de la unidad"
      >
        <NotData
          data={
            analyticsUserTime.units.filter(
              (unit) => unit[0] === values["unit"]
            )[0][1].topContents
          }
        >
          <LineGraphHorizontal
            data={analyticsUserTime.units
              .filter((unit) => unit[0] === values["unit"])[0][1]
              .topContents.total.map((contact) => {
                return {
                  label: contact[0],
                  value: formatNumber(contact[1]),
                };
              })}
            title={""}
          ></LineGraphHorizontal>
        </NotData>
      </AnalyticCard>
      <AnalyticCard height="300px" title="Cantidad de contenidos en la unidad">
        <NotData
          data={
            analyticsUserTime.units.filter(
              (unit) => unit[0] === values["unit"]
            )[0][1]
          }
        >
          <PieChart
            pieData={Object.entries({
              Videos: analyticsUserTime.units.filter(
                (unit) => unit[0] === values["unit"]
              )[0][1].videos.count,
              Bytes: analyticsUserTime.units.filter(
                (unit) => unit[0] === values["unit"]
              )[0][1].bytes.count,
              Cdocs: analyticsUserTime.units.filter(
                (unit) => unit[0] === values["unit"]
              )[0][1].cdocs.count,
              Imagenes: analyticsUserTime.units.filter(
                (unit) => unit[0] === values["unit"]
              )[0][1].images.count,
              Listens: analyticsUserTime.units.filter(
                (unit) => unit[0] === values["unit"]
              )[0][1].listens.count,
              Varios: analyticsUserTime.units.filter(
                (unit) => unit[0] === values["unit"]
              )[0][1].misc.count,
              Trayectos: analyticsUserTime.units.filter(
                (unit) => unit[0] === values["unit"]
              )[0][1].roadmaps.count,
              Slideshows: analyticsUserTime.units.filter(
                (unit) => unit[0] === values["unit"]
              )[0][1].slideshows.count,
            }).map((content) => {
              return {
                label: content[0],
                value: content[1],
              };
            })}
          ></PieChart>
        </NotData>
      </AnalyticCard>
    </FlexContainer>
  );
};

export default Component;
