import { useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import { Outlet } from "react-router-dom";
import { toast } from "react-toastify";
import { resetGetAcademyStats } from "../../../../store/actions/analytics";

const Component = ({ analyticsAcademy, analyticsAcademyStates, current }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (analyticsAcademyStates.success) {
      setTimeout(() => {
        dispatch(resetGetAcademyStats());
      }, 1000);
    }

    if (analyticsAcademyStates.error) {
      toast.error(`😱 ${analyticsAcademyStates.error}`);

      setTimeout(() => {
        dispatch(resetGetAcademyStats());
      }, 1000);
    }
  }, [analyticsAcademyStates]);

  return <Outlet></Outlet>;
};

const states = ({ analyticsStore, currentStore }) => {
  const { data: analyticsAcademy, states: analyticsAcademyStates } =
    analyticsStore.getAcademyStats;
  const { data: current } = currentStore;
  return {
    analyticsAcademy,
    analyticsAcademyStates,
    current,
  };
};

export default connect(states)(Component);
