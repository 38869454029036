import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BiNews } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import Button from "../../../../../../components/Button";
import Loader from "../../../../../../components/Loader";
import ModalConfirmDelete from "../../../../../../components/ModalConfirmDelete";
import FilterByName from "./FilterByName";
import { showModal } from "../../../../../../store/actions/modal";
import {
  deleteRequest,
  getAllRequests,
  newRequest as newRequestAction,
  resetDeleteRequest,
  resetGetAllRequests,
  resetNewRequest,
  resetUpdateManyRequest,
  resetUpdateRequest,
  updateManyRequest,
  updateRequest,
} from "../../../../../../store/actions/request";
import { AcademyDto } from "../../../../../../types/academy.dto";
import { OrganizationDto } from "../../../../../../types/organization.dto";
import { Img } from "../../../../Cohort/Customize/style";
import { Icon } from "../../../../Content/style";
import { CardColumn, CardRow, CardTitle } from "../../../Profile/style";
import { DataText, Item } from "../../style";
import { RequestDto } from "../../../../../../types/request.dto";
import AcademyInviteButton from "../../components/InviteButton";
import OrganizationInviteButton from "../../../../Organization/Gestion/components/InviteButton";
import { getAllCohorts } from "../../../../../../store/actions/cohort";
import { resetUpdateMany } from "../../../../../../store/actions/content";
import { CurrentDto } from "../../../../../../types/current.dto";
import { UserDto } from "../../../../../../types/user.dto";
import { UsersDto } from "../../../../../../types/users.dto";
import { StateDto } from "../../../../../../types/states.dto";
import { toast } from "react-toastify";
import usersActions from "../../../../../../store/actions/users";
import academyActions from "../../../../../../store/actions/academies";
import { resetGetAllEvents } from "../../../../../../store/actions/event";
import { Separator } from "../../../../../../components/StyledComponents";
const Component = ({
  organizationStore,
  academyStore,
  organizations,
  academies,
  current,
  user,
  requests,
  users,
  updateRequestStates,
  deleteRequestStates,
  newRequestsStates,
  selectedItem,
  updateManyRequestStates,
  updatedRequest,
  deletedRequest,
  updatedManyRequestsData,
  newRequest,
}: {
  organizationStore: any;
  academyStore: any;
  organizations: OrganizationDto[];
  academies: AcademyDto[];
  current: CurrentDto;
  user: UserDto;
  requests: RequestDto[];
  users: UsersDto[];
  updateRequestStates: StateDto;
  deleteRequestStates: StateDto;
  newRequestsStates: StateDto;
  selectedItem: any;
  updateManyRequestStates: StateDto;
  updatedRequest: RequestDto;
  deletedRequest: RequestDto;
  updatedManyRequestsData: RequestDto[];
  newRequest: RequestDto;
}) => {
  const dispatch = useDispatch();
  const [fetchOn, setFetchOn] = useState<boolean>(false);
  const [selectedRequest, setSelectedRequest] = useState<any>();
  const [selectedEntity, setSelectedEntity] = useState<
    AcademyDto | OrganizationDto
  >();
  const [currentRequestsList, setCurrentRequestsList] = useState<
    (RequestDto & { entityName?: string })[]
  >([]);
  const [requestsUsers, setRequestsUsers] = useState<UsersDto[]>();
  const [usersIdsFromRequests, setUsersIdsFromRequests] = useState<string[]>(
    []
  );
  const [applyedFilters, setFilters] = useState<
    {
      field: string;
      value: string | boolean;
      special: (data?: any, data2?: any) => boolean;
    }[]
  >([]);
  const [action, setAction] = useState<any>();
  const [
    filteredCurrentRequestsListValues,
    setFilteredCurrentRequestsListValues,
  ] = useState<(RequestDto & { entityName?: string })[]>([]);

  const requestPayload = {
    filterBy: {
      ...(current.currentSection === "personal"
        ? { sentBy: user._id }
        : { [current.currentSection]: current.id }),
      active: true,
      status: "PENDING",
    },
  };

  const buildFilters = (newFilter) => {
    const filterUpdated =
      applyedFilters.filter(
        (applyedFilter) => applyedFilter.field === newFilter.field
      ).length > 0;

    const filterRemoved = newFilter.value === "no";

    /**
     * Se elimina el filtro
     */
    if (filterRemoved) {
      setFilters([
        ...applyedFilters.filter(
          (applyedFilter) => applyedFilter.field !== newFilter.field
        ),
      ]);
    }

    /**
     * Se actualiza un filtro
     */
    if (filterUpdated && !filterRemoved) {
      setFilters([
        ...applyedFilters.map((applyedFilter) => {
          if (applyedFilter.field === newFilter.field) {
            return newFilter;
          } else {
            return applyedFilter;
          }
        }),
      ]);
    }

    /**
     * Se agrega un filtro
     */
    if (!filterUpdated && !filterRemoved) {
      setFilters([...applyedFilters, newFilter]);
    }
  };
  /* console.log(usersIdsFromRequests, "mamamamas"); */
  useEffect(() => {
    if (requests && fetchOn) {
      setCurrentRequestsList(requests);
    }
  }, [requests]);

  useEffect(() => {
    if (usersIdsFromRequests && fetchOn) {
      dispatch(
        usersActions.getAll({
          filterBy: { _id: { $in: usersIdsFromRequests } },
        })
      );
    }
  }, [usersIdsFromRequests]);

  useEffect(() => {
    if (users && fetchOn) {
      setRequestsUsers(users);
    }
  }, [users]);

  useEffect(() => {
    if (!requests && fetchOn) dispatch(getAllRequests(requestPayload));
  }, [requests, fetchOn]);

  useEffect(() => {
    if (!academies && fetchOn)
      dispatch(academyActions.getAll({ filterBy: { private: true } }));
  }, [academies, fetchOn]);

  useEffect(() => {
    if (currentRequestsList && fetchOn) {
      const requestsEmails = Array.from(
        new Set(currentRequestsList.map((request) => request.sentBy?._id))
      );
      setUsersIdsFromRequests(requestsEmails);
    }
  }, [currentRequestsList]);

  useEffect(() => {
    if (deleteRequestStates.success) {
      setCurrentRequestsList((state) =>
        state.filter((st) => st._id !== deletedRequest._id)
      );
    }
    if (deleteRequestStates.error) {
      toast.error(updateRequestStates.error);
    }
  }, [deleteRequestStates, dispatch]);

  useEffect(() => {
    const filteredTalents = currentRequestsList?.filter(
      (currentTalentValues) => {
        const thereAreFilters = applyedFilters.length > 0;
        const totalOfFilters = applyedFilters.length;
        const passFilters = applyedFilters
          .filter((applyedFilter) => !applyedFilter.special)
          .filter(
            (applyedFilter) =>
              currentTalentValues[applyedFilter.field] === applyedFilter.value
          ).length;
        const specialFilter = applyedFilters.filter(
          (applyedFilter) => !!applyedFilter.special
        );

        const speacialFilterCheck = specialFilter.filter((applyedFilter) => {
          return applyedFilter.special(
            currentTalentValues,
            applyedFilter.value
          );
        });

        return (
          !thereAreFilters ||
          (thereAreFilters &&
            totalOfFilters === passFilters + specialFilter.length &&
            speacialFilterCheck.length === specialFilter.length)
        );
      }
    );
    setFilteredCurrentRequestsListValues(filteredTalents);
  }, [applyedFilters, currentRequestsList]);
  useEffect(() => {
    if (updateRequestStates.success) {
      setCurrentRequestsList((state) =>
        state.map((st) => (st._id === updatedRequest._id ? updatedRequest : st))
      );
      dispatch(resetUpdateRequest());
    }
    if (updateRequestStates.error) {
      toast.error(updateRequestStates.error);
      dispatch(resetUpdateRequest());
    }
  }, [updateRequestStates, dispatch]);
  useEffect(() => {
    if (updateManyRequestStates.success) {
      dispatch(resetUpdateManyRequest());
      setCurrentRequestsList((state) =>
        state.map((st) => {
          const founded = updatedManyRequestsData.filter(
            (request) => request._id === st._id
          )[0];
          if (founded) return founded;
          return st;
        })
      );
    }
    if (updateManyRequestStates.error) {
      dispatch(resetUpdateManyRequest());
    }
  }, [updateManyRequestStates, dispatch]);

  useEffect(() => {
    if (newRequestsStates.success) {
      setCurrentRequestsList((state) => [...state, newRequest]);
    }
  }, [newRequestsStates]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllRequests());
      dispatch(academyActions.resetGetAllAcademiesData());
      dispatch(usersActions.resetGetAll());
      dispatch(resetGetAllEvents());
    };
  }, []);

  useEffect(() => {
    if (!requests && !academies && !users) {
      setFetchOn(true);
    }
  }, [requests, academies, users]);

  if (!requestsUsers) return <Loader color="Primary"></Loader>;

  return (
    <>
      {current.currentSection !== "personal" && (
        <CardRow justify="space-between">
          <CardRow>
            <Icon icon="" size="40px" />
            <CardColumn gap="9px">
              <DataText>{selectedItem.label}</DataText>
              <DataText>{selectedItem.data.description}</DataText>
            </CardColumn>
          </CardRow>

          {current.currentSection === "organization" && (
            <OrganizationInviteButton></OrganizationInviteButton>
          )}
          {current.currentSection === "academy" && (
            <AcademyInviteButton></AcademyInviteButton>
          )}
        </CardRow>
      )}
      {current.currentSection === "personal" && (
        <>
          <CardTitle>
            Busca academias y organizaciones de las que quieras ser parte
          </CardTitle>
          <Separator size={10}></Separator>
          <div style={{ maxWidth: "400px" }}>
            <FilterByName
              {...(!!academies && {
                items: [
                  ...academies
                    .filter(
                      (academy) =>
                        !currentRequestsList.find(
                          (request) =>
                            (request.academy?._id === academy._id ||
                              request.cohort?.academy?._id === academy._id) &&
                            request.sentBy._id === user._id
                        )
                    )
                    .map((academy) => {
                      return { ...academy, section: "academy" };
                    }),
                  /*  ...(organizations ?? []).map((organization) => {
                    return { ...organization, section: "organization" };
                  }), */
                ],
              })}
              setAction={setAction}
              setFilter={buildFilters}
            ></FilterByName>
          </div>
        </>
      )}
      <Separator size={20}></Separator>
      <CardColumn style={{ width: "100%" }} gap="15px">
        <CardRow align="center" justify="space-between">
          <CardRow align="center" gap="8px">
            <BiNews fontSize={20}></BiNews>
            <CardTitle>Listado de Solicitudes</CardTitle>
          </CardRow>
          {current.currentSection !== "personal" &&
            filteredCurrentRequestsListValues.length > 0 && (
              <Button
                type="button"
                onClick={() => {
                  const itemsMapped = filteredCurrentRequestsListValues.map(
                    (item) => {
                      return { _id: item._id, condition: "accept" };
                    }
                  );
                  dispatch(updateManyRequest({ items: itemsMapped }));
                }}
                options={{
                  size: "md",
                  type: "filled",
                  skin: "primary",
                }}
              >
                Aceptar Todas
              </Button>
            )}
        </CardRow>

        <CardColumn style={{ maxHeight: "400px", overflowY: "auto" }}>
          {!!requestsUsers &&
            filteredCurrentRequestsListValues
              .filter(
                (request) =>
                  request.active &&
                  request.status === "PENDING" &&
                  (current.currentSection === "personal"
                    ? request.sentBy?._id === user._id
                    : request.academy._id === current.id)
              )
              .map((request) => {
                const userFounded = requestsUsers.find(
                  (userF) => userF._id === request.sentBy?._id
                );
                const entityFounded = request.academy ?? request.organization;

                return (
                  <Item>
                    <CardRow align="center" justify="space-between">
                      <CardRow align="center" gap="3px">
                        <Icon icon="">
                          <Img
                            src={
                              current.currentSection === "personal"
                                ? entityFounded?.picture
                                : userFounded?.picture
                            }
                          ></Img>
                        </Icon>
                        <CardColumn>
                          <DataText size="14px">{`${
                            current.currentSection === "personal"
                              ? `${entityFounded?.name}`
                              : `${userFounded?.name} ${userFounded?.lastName}`
                          }`}</DataText>
                          {current.currentSection !== "personal" && (
                            <DataText size="12px">
                              {userFounded?.position}
                            </DataText>
                          )}
                        </CardColumn>
                      </CardRow>
                      <CardRow gap="15px" align="center">
                        {current.currentSection === "personal" ? (
                          <Button
                            type="button"
                            loading={
                              (updateRequestStates.loading &&
                                selectedRequest._id === request._id) ||
                              updateManyRequestStates.loading
                            }
                            onClick={() => {
                              setSelectedRequest(request);
                              setAction({
                                action: "cancel-request",
                                data: { request, entityFounded },
                              });
                              dispatch(
                                showModal(
                                  "confirm-delete-item-secuencial-modal"
                                )
                              );
                            }}
                            style={{
                              height: "26px",
                              maxWidth: "103px",
                              minWidth: "auto",
                            }}
                            options={{
                              size: "md",
                              type: "filled",
                              skin: "danger",
                            }}
                          >
                            Cancelar
                          </Button>
                        ) : (
                          <>
                            <Button
                              type="button"
                              loading={
                                (updateRequestStates.loading &&
                                  selectedRequest._id === request._id) ||
                                updateManyRequestStates.loading
                              }
                              onClick={() => {
                                setSelectedRequest(request);
                                dispatch(
                                  updateRequest({
                                    _id: request._id,
                                    condition: "accept",
                                  })
                                );
                              }}
                              style={{
                                height: "26px",
                                maxWidth: "103px",
                                minWidth: "auto",
                              }}
                              options={{
                                size: "md",
                                type: "filled",
                                skin: "primary",
                              }}
                            >
                              Aceptar
                            </Button>
                            <Button
                              type="button"
                              loading={
                                (updateRequestStates.loading &&
                                  selectedRequest._id === request._id) ||
                                updateManyRequestStates.loading
                              }
                              onClick={() => {
                                setSelectedRequest(request);
                                dispatch(
                                  updateRequest({
                                    _id: request._id,
                                    condition: "reject",
                                  })
                                );
                              }}
                              style={{
                                height: "26px",
                                maxWidth: "103px",
                                minWidth: "auto",
                              }}
                              options={{
                                size: "md",
                                type: "filled",
                                skin: "danger",
                              }}
                            >
                              Rechazar
                            </Button>
                          </>
                        )}
                      </CardRow>
                    </CardRow>
                  </Item>
                );
              })}
        </CardColumn>
      </CardColumn>
      {!!action && action.action === "cancel-request" && (
        <ModalConfirmDelete
          bntConfig={{
            content: "Cancelar Solicitud",
            style: {
              color: "Danger",
              options: {
                type: "outline",
                skin: "danger",
                size: "lg",
                marginBottom: "0px",
                loading: "Danger",
              },
            },
          }}
          states={deleteRequestStates}
          title={
            <span>
              {`¿Seguro que quiere cancelar la solicitud a `}
              <span style={{ fontWeight: "bold", color: "black" }}>
                {action.data.entityFounded.name}
              </span>
              ?
            </span>
          }
          elementActions={deleteRequest({ _id: action.data.request._id })}
          resetAction={resetDeleteRequest}
          resetState={setSelectedEntity}
        ></ModalConfirmDelete>
      )}
      {!!action && action.action === "create-request" && (
        <ModalConfirmDelete
          bntConfig={{
            content: "Enviar Solicitud",
            style: {
              color: "Primary",
              options: {
                type: "filled",
                skin: "primary",
                size: "lg",
                marginBottom: "0px",
                loading: "Primary",
              },
            },
          }}
          states={newRequestsStates}
          title={
            <span>
              {`¿Seguro mandar solicitud a `}
              <span style={{ fontWeight: "bold", color: "black" }}>
                {action.data.entityData.name}
              </span>
              ?
            </span>
          }
          elementActions={newRequestAction({
            sentBy: action.data.sentBy,
            [action.data.entity]: action.data.entityData._id,
            platform: action.data.platform,
          })}
          resetAction={resetNewRequest}
          resetState={setAction}
        ></ModalConfirmDelete>
      )}
    </>
  );
};

const states = ({
  organizationStore,
  academyStore,
  userStore,
  requestStore,
  usersStore,
  currentStore,
}) => {
  const { data: organizations } = organizationStore.all;
  const { data: academies } = academyStore.all;
  const { data: requests } = requestStore.allRequests;
  const { data: user } = userStore;
  const { data: users } = usersStore.all;
  const { states: updateRequestStates, data: updatedRequest } =
    requestStore.updateRequest;
  const { states: deleteRequestStates, data: deletedRequest } =
    requestStore.deleteRequest;
  const { states: updateManyRequestStates, data: updatedManyRequestsData } =
    requestStore.updateMany;
  const { states: newRequestsStates, data: newRequest } =
    requestStore.newRequest;
  const { data: current } = currentStore;
  return {
    organizationStore,
    academyStore,
    organizations,
    academies,
    user,
    requests,
    users,
    newRequestsStates,
    current,
    updateRequestStates,
    deleteRequestStates,
    updateManyRequestStates,
    updatedRequest,
    deletedRequest,
    updatedManyRequestsData,
    newRequest,
  };
};

export default connect(states)(Component);
