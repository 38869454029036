import * as Yup from "yup";

export const schema = Yup.object().shape({
  name: Yup.string()
    .required("Ingresa el nombre de tu Contenido.")
    .min(5, "Minimo 5 letras"),
  picture: Yup.string(),
  available: Yup.bool().required(),
  description: Yup.string(),
  cohorts: Yup.array(),
  units: Yup.array(),
  allCohorts: Yup.bool().required(),
});

interface FormValues {
  name: string;
  picture?: string;
  available: boolean;
  units: string[];
  cohorts: string[];
  allCohorts: boolean;
}

export const initialValues = (values) => {
  return {
    name: "",
    picture: "",
    available: false,
    cohorts: [],
    units: [],
    allCohorts: false,
    ...values,
  };
};
