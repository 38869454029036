import { Bar } from "react-chartjs-2";
import type { ChartData, ChartOptions } from "chart.js";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface LineProps {
  options: ChartOptions<"bar">;
  data: ChartData<"bar">;
}

const Component = ({ data, title }) => {
  const randomColor = [
    "#000f37",
    "#001a5d",
    "rgba(0, 15, 55, 0.8)",
    "rgba(0, 30, 108, 0.8)",
    "rgba(0, 15, 55, 0.6)",
    "rgba(0, 30, 108, 0.6)",
    "rgba(0, 15, 55, 0.4)",
    "rgba(0, 26, 93, 0.4)",
    "rgba(0, 0, 0, 0.15)",
    "rgba(0, 26, 93, 0.15)",
    "#e0e7fb",
  ];
  const Props: LineProps = {
    options: {
      responsive: true,
      indexAxis: "y" as const,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "right",
          onClick: (evt, legendItem, legend): any => {
            const index = legend.chart.data.labels?.indexOf(legendItem.text);

            if (index !== undefined) {
              legend.chart.toggleDataVisibility(index);
              legend.chart.update();
            }
          },
          labels: {
            generateLabels: (chart): any => {
              return chart.data.labels?.map((label, index) => ({
                text: label,
                ...(!!chart.data.datasets[0].borderColor &&
                  !!chart.data.datasets[0].backgroundColor &&
                  !!chart.data.datasets[0].borderColor[index] && {
                    strokeStyle:
                      !!chart.data.datasets[0]?.borderColor[index] &&
                      chart.data.datasets[0].borderColor[index],
                    fillStyle: chart.data.datasets[0].backgroundColor[index],

                    hidden: false,
                  }),
              }));
            },
          },
        },
        datalabels: {
          color: "#fff",
          font: {
            size: 15,
          },
        },
      },
      scales: {
        y: {
          beginAtZero: true,
        },
        x: {
          beginAtZero: true,
        },
      },
    },
    data: {
      datasets: [
        {
          data: data.map((analytic) => analytic.value),
          borderColor: randomColor.map((color) => color),
          backgroundColor: randomColor.map((color) => color),
        },
      ],
      labels: data.map((analytic) => analytic.label),
    },
  };
  return <Bar data={Props.data} options={Props.options} />;
};

export default Component;
