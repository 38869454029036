import { combineReducers } from "redux";
import company from "./company";
import search from "./search";
import talent from "./ait-talent";
import techSkill from "./tech-skill";
import technology from "./technology";
import ait from "./ait";
const createRootReducer = combineReducers({
  company,
  search,
  talent,
  techSkill,
  ait,
  technology,
});

export default createRootReducer;
