import { put, call, takeLatest } from "redux-saga/effects";
import services from "../../services";
import {
  GetAcademyStats,
  GetAllStudentsStats,
  GetCohortsStats,
  GetIndividualStats,
  GetOrganizationStats,
  GET_ACADEMY_STATS,
  GET_ACADEMY_STATS_ERROR,
  GET_ACADEMY_STATS_SUCCESS,
  GET_ALL_STUDENTS_STATS,
  GET_ALL_STUDENTS_STATS_ERROR,
  GET_ALL_STUDENTS_STATS_SUCCESS,
  GET_COHORT_STATS,
  GET_COHORT_STATS_ERROR,
  GET_COHORT_STATS_SUCCESS,
  GET_INDIVIDUAL_STATS,
  GET_INDIVIDUAL_STATS_ERROR,
  GET_INDIVIDUAL_STATS_SUCCESS,
  GET_ORGANIZATION_STATS,
  GET_ORGANIZATION_STATS_ERROR,
  GET_ORGANIZATION_STATS_SUCCESS,
  GET_MANY_INDIVIDUAL_STATS,
  GetManyIndividualStats,
  GET_COHORT_USER_TIME_ERROR,
  GET_COHORT_USER_TIME_SUCCESS,
  GetCohortUserTime,
  GET_COHORT_USER_TIME,
  GET_ACADEMY_USER_TIME,
  GET_ACADEMY_USER_TIME_SUCCESS,
  GET_ACADEMY_USER_TIME_ERROR,
  GetAcademyUserTime,
  GET_ACADEMY_SUSCRIPTION_STATS_ERROR,
  GET_ACADEMY_SUSCRIPTION_STATS_SUCCESS,
  GetAcademySuscriptionStats,
  GET_ACADEMY_SUSCRIPTION_STATS,
  GET_COHORT_SUSCRIPTION_STATS,
  GetCohortSuscriptionStats,
  GET_COHORT_SUSCRIPTION_STATS_ERROR,
  GET_COHORT_SUSCRIPTION_STATS_SUCCESS,
} from "../types/analytics";
import { AnalyticsUserDto } from "../../types/analytics.dto";

function* getStudentStats({ payload }) {
  const { response, error } = yield call(services.analytics.getOne, payload);
  if (error) {
    yield put({
      type: GET_INDIVIDUAL_STATS_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_INDIVIDUAL_STATS_SUCCESS, payload: response });
  }
}

function* getCohortStats({ payload }) {
  const { response, error } = yield call(
    services.analytics.getOneCohort,
    payload
  );
  if (error) {
    yield put({
      type: GET_COHORT_STATS_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_COHORT_STATS_SUCCESS, payload: response });
  }
}

function* getAcademySuscriptionStats({ payload }) {
  const { response, error } = yield call(
    services.analytics.getAcademySuscriptionStats,
    payload
  );
  if (error) {
    yield put({
      type: GET_ACADEMY_SUSCRIPTION_STATS_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({
      type: GET_ACADEMY_SUSCRIPTION_STATS_SUCCESS,
      payload: response,
    });
  }
}
function* getCohortSuscriptionStats({ payload }) {
  const { response, error } = yield call(
    services.analytics.getCohortSuscriptionStats,
    payload
  );
  if (error) {
    yield put({
      type: GET_COHORT_SUSCRIPTION_STATS_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({
      type: GET_COHORT_SUSCRIPTION_STATS_SUCCESS,
      payload: response,
    });
  }
}

function* getCohortUserTimeStats({ payload }) {
  const { response, error } = yield call(
    services.analytics.getOneCohortUserTime,
    payload
  );
  if (error) {
    yield put({
      type: GET_COHORT_USER_TIME_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_COHORT_USER_TIME_SUCCESS, payload: response });
  }
}
function* getAcademyUserTimeStats({ payload }) {
  const { response, error } = yield call(
    services.analytics.getOneAcademyUserTime,
    payload
  );
  if (error) {
    yield put({
      type: GET_ACADEMY_USER_TIME_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ACADEMY_USER_TIME_SUCCESS, payload: response });
  }
}

function* getAllStudentsStats() {
  const { response, error } = yield call(services.analytics.getStudentsStats);
  if (error) {
    yield put({
      type: GET_ALL_STUDENTS_STATS_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_STUDENTS_STATS_SUCCESS, payload: response });
  }
}
function* getManyStudentsStats({ payload }) {
  let updatedList: AnalyticsUserDto[] = [];

  try {
    for (let i = 0; i < payload.length; i++) {
      const body = payload[i];
      const { response, error } = yield call(services.analytics.getOne, body);

      if (!!error) {
        throw new Error(error.response.data.message);
      }
      if (!!response) {
        updatedList = [...updatedList, response];
      }
    }
    yield put({ type: GET_ALL_STUDENTS_STATS_SUCCESS, payload: updatedList });
  } catch (err) {
    yield put({ type: GET_ALL_STUDENTS_STATS_ERROR, payload: err });
  }
}

function* getAcademyStats({ payload }) {
  const { response, error } = yield call(
    services.analytics.getAcademyStats,
    payload
  );
  if (error) {
    yield put({
      type: GET_ACADEMY_STATS_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ACADEMY_STATS_SUCCESS, payload: response });
  }
}
function* getOrganizationStats({ payload }) {
  const { response, error } = yield call(
    services.analytics.getOrganizationStats,
    payload
  );
  if (error) {
    yield put({
      type: GET_ORGANIZATION_STATS_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ORGANIZATION_STATS_SUCCESS, payload: response });
  }
}

export default function* applicant() {
  yield takeLatest<GetIndividualStats>(GET_INDIVIDUAL_STATS, getStudentStats);
  yield takeLatest<GetCohortsStats>(GET_COHORT_STATS, getCohortStats);
  yield takeLatest<GetAllStudentsStats>(
    GET_ALL_STUDENTS_STATS,
    getAllStudentsStats
  );
  yield takeLatest<GetManyIndividualStats>(
    GET_MANY_INDIVIDUAL_STATS,
    getManyStudentsStats
  );
  yield takeLatest<GetAcademyStats>(GET_ACADEMY_STATS, getAcademyStats);
  yield takeLatest<GetOrganizationStats>(
    GET_ORGANIZATION_STATS,
    getOrganizationStats
  );
  yield takeLatest<GetCohortUserTime>(
    GET_COHORT_USER_TIME,
    getCohortUserTimeStats
  );
  yield takeLatest<GetAcademyUserTime>(
    GET_ACADEMY_USER_TIME,
    getAcademyUserTimeStats
  );
  yield takeLatest<GetAcademySuscriptionStats>(
    GET_ACADEMY_SUSCRIPTION_STATS,
    getAcademySuscriptionStats
  );
  yield takeLatest<GetCohortSuscriptionStats>(
    GET_COHORT_SUSCRIPTION_STATS,
    getCohortSuscriptionStats
  );
}
