import {
  initialState,
  Actions,
  State,
  SET_USER_INFORMATION,
  SET_USER_INFORMATION_SUCCESS,
  SET_USER_INFORMATION_ERROR,
  RESET_SET_USER_INFORMATION,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  RESET_UPDATE_USER,
  FORGOT_PASSWORD,
} from "../types/user";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case SET_USER_INFORMATION:
      return {
        ...state,
        data: null,
        states: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case SET_USER_INFORMATION_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case SET_USER_INFORMATION_ERROR:
      return {
        ...state,
        data: null,
        states: {
          loading: false,
          error: action.payload,
          success: false,
        },
      };
    case RESET_SET_USER_INFORMATION:
      return {
        ...state,
        states: {
          loading: false,
          error: false,
          success: false,
        },
      };
    case FORGOT_PASSWORD:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_USER:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_USER_ERROR:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_USER:
      return {
        ...state,
        update: initialState.update,
      };
    default:
      return state;
  }
};

export default reducers;
