import {
  NewOrganization,
  NEW_ORGANIZATION,
  ResetNewOrganization,
  RESET_NEW_ORGANIZATION,
  GET_ALL_ORGANIZATIONS,
  GetAllOrganizations,
  UpdateOrganization,
  UPDATE_ORGANIZATION,
  ResetUpdateOrganization,
  RESET_UPDATE_ORGANIZATION,
  GetOrganization,
  DeleteOrganization,
  DELETE_ORGANIZATION,
  GET_ORGANIZATION,
  RESET_DELETE_ORGANIZATION,
  NEW_ORGANIZATION_INVITE_LINK,
  SELECT_ORGANIZATION,
  DELETE_ORGANIZATION_MEMBER,
  UPDATE_ORGANIZATION_ROLE,
  RESET_ORGANIZATION_NEW_INVITE_LINK,
  DELETE_ORGANIZATION_INVITE_LINK,
  RESET_ORGANIZATION_DELETE_INVITE_LINK,
  AddUserOrganization,
  ADD_USER_ORGANIZATION,
} from "../types/organization";

const create = (payload): NewOrganization => {
  return { type: NEW_ORGANIZATION, payload };
};
const resetCreate = (): ResetNewOrganization => {
  return { type: RESET_NEW_ORGANIZATION, payload: null };
};

const update = (payload): UpdateOrganization => {
  return { type: UPDATE_ORGANIZATION, payload };
};

const resetUpdate = (): ResetUpdateOrganization => {
  return { type: RESET_UPDATE_ORGANIZATION, payload: null };
};

const getAll = (): GetAllOrganizations => {
  return { type: GET_ALL_ORGANIZATIONS, payload: null };
};

const getOne = (payload: { _id: string }): GetOrganization => {
  return { type: GET_ORGANIZATION, payload };
};

const deleteOne = (payload: { _id: string }): DeleteOrganization => {
  return { type: DELETE_ORGANIZATION, payload };
};

const addMember = (payload): AddUserOrganization => {
  return { type: ADD_USER_ORGANIZATION, payload };
};

const resetDeleteOne = () => {
  return { type: RESET_DELETE_ORGANIZATION, payload: null };
};

const newLink = (payload) => {
  return { type: NEW_ORGANIZATION_INVITE_LINK, payload };
};

const resetNewLink = () => {
  return { type: RESET_ORGANIZATION_NEW_INVITE_LINK, payload: null };
};

const deleteLink = (payload) => {
  return { type: DELETE_ORGANIZATION_INVITE_LINK, payload };
};

const resetDeleteLink = () => {
  return { type: RESET_ORGANIZATION_DELETE_INVITE_LINK, payload: null };
};

const selectOne = (payload) => {
  return { type: SELECT_ORGANIZATION, payload };
};

const deleteMember = (payload) => {
  return { type: DELETE_ORGANIZATION_MEMBER, payload };
};

const updateRole = (payload) => {
  return { type: UPDATE_ORGANIZATION_ROLE, payload };
};

const actions = {
  resetDeleteLink,
  updateRole,
  deleteMember,
  selectOne,
  deleteLink,
  newLink,
  resetNewLink,
  create,
  resetCreate,
  update,
  resetUpdate,
  resetDeleteOne,
  getAll,
  getOne,
  deleteOne,
  addMember,
};

export default actions;
