import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Modal from "../../../../../../components/Modal";
import { Formik } from "formik";
import {
  Center,
  FlexContainer,
  Separator,
} from "../../../../../../components/StyledComponents";
import {
  initialValues,
  schema,
} from "../../../../../../constants/form/suscription/cohort/update";
import Grid from "../../../../../../components/Grid";
import Button from "../../../../../../components/Button";
import Submit from "../../../../../../components/Form/Submit";
import { toast } from "react-toastify";
import { hideModal } from "../../../../../../store/actions/modal";
import { CurrentDto } from "../../../../../../types/current.dto";
import { SuscriptionText } from "../../style";
import { ToggleButtonWrapper, ToggleInput, ToggleSlider } from "./style";
import Input from "../../../../../../components/Form/Input";
import { StateDto } from "../../../../../../types/states.dto";
import { resetUpdateCohort } from "../../../../../../store/actions/cohort";
import { PlanDto } from "../../../../../../types/plan.dto";
import { deletePlan, updatePlan } from "../../../../../../store/actions/plan";

const Component = ({
  activeCohortPlan,
  current,
  updateCohortStates,
  modalName,
  updatePlanStates,
  deletePlanStates,
}: {
  activeCohortPlan: PlanDto;
  current: CurrentDto;
  updateCohortStates: StateDto;
  modalName: string;
  updatePlanStates: StateDto;
  deletePlanStates: StateDto;
}) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const dispatch = useDispatch();

  const onSubmit = ({ values, actions }) => {
    if (values.monetizationStatus) {
      if (values.isUnique) {
        dispatch(
          updatePlan({
            currencyId: "ARS",
            type: "UNIQUE",
            unitPrice: values.unitPrice,
            cohort: activeCohortPlan.cohort,
          })
        );
      } else {
        dispatch(
          updatePlan({
            currencyId: "ARS",
            type: "SUBSCRIPTION",
            unitPrice: values.unitPrice,
            cohort: activeCohortPlan.cohort,
          })
        );
      }
    } else {
      dispatch(deletePlan({ _id: activeCohortPlan._id }));
    }

    setFormActions(actions);
  };

  useEffect(() => {
    if (updateCohortStates.success && modalName === "edit-plan-modal-cohort") {
      toast.success("👌🏼 Plan Editado");

      setTimeout(() => {
        dispatch(resetUpdateCohort());
        dispatch(hideModal());
        formActions?.resetForm();
      }, 1000);
    }
  }, [updateCohortStates]);

  return (
    <Modal name="edit-plan-modal-cohort" title={`Editar plan de monetización`}>
      <Formik
        initialValues={{
          ...initialValues,
          ...(activeCohortPlan && {
            monetizationStatus:
              !activeCohortPlan.validUntil && !!activeCohortPlan.validFrom,
            unitPrice: activeCohortPlan.unitPrice,
            isUnique: activeCohortPlan.type === "UNIQUE",
          }),
        }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="invite-academy-modal-form"
            >
              <Grid.Row style={{ alignItems: "center" }}>
                <Grid.Col>
                  <>{console.log(errors, "mendy")}</>
                  <FlexContainer align="center" gap="6px">
                    <SuscriptionText size={14}>free</SuscriptionText>
                    <ToggleButtonWrapper>
                      <ToggleInput
                        value={values["monetizationStatus"]}
                        onChange={() =>
                          setFieldValue(
                            "monetizationStatus",
                            !values["monetizationStatus"]
                          )
                        }
                        checked={values["monetizationStatus"]}
                        type="checkbox"
                      ></ToggleInput>
                      <ToggleSlider></ToggleSlider>
                    </ToggleButtonWrapper>
                    <SuscriptionText size={14}>paid</SuscriptionText>
                  </FlexContainer>
                </Grid.Col>
                <Grid.Col>
                  <SuscriptionText size={12}>
                    (Una vez hecho el cambio, este tendra efecto en el proximo
                    mes)
                  </SuscriptionText>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row style={{ alignItems: "center" }}>
                <Grid.Col>
                  <FlexContainer align="center" gap="6px">
                    <SuscriptionText size={14}>monthly</SuscriptionText>
                    <ToggleButtonWrapper>
                      <ToggleInput
                        value={values["isUnique"]}
                        checked={values["isUnique"]}
                        type="checkbox"
                      ></ToggleInput>
                      <ToggleSlider></ToggleSlider>
                    </ToggleButtonWrapper>
                    <SuscriptionText size={14}>unique</SuscriptionText>
                  </FlexContainer>
                </Grid.Col>
                <Grid.Col>
                  <SuscriptionText size={12}>
                    (Cambie el plan a free y vuelva a crearlo para modificar el
                    modo de suscripcion)
                  </SuscriptionText>
                </Grid.Col>
              </Grid.Row>
              <Separator size={12}></Separator>
              <Grid.Row>
                <Grid.Col>
                  <SuscriptionText size={14}>
                    {`Pago ${
                      values["isUnique"] ? "unico" : "mensual"
                    } para el acceso a mi Academia`}
                  </SuscriptionText>
                  <Separator size={10}></Separator>
                  <FlexContainer
                    gap="7px"
                    align="center"
                    justify="center"
                    wrap="wrap"
                  >
                    <Input
                      name="unitPrice"
                      error={errors["unitPrice"]}
                      touched={touched["unitPrice"]}
                      value={values["unitPrice"]}
                      type="number"
                      placeholder="Ingresa monto"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={{
                        marginBottom: 0,
                        skin: "base",
                        before: (
                          <span
                            style={{
                              display: "inline-block",
                              paddingLeft: "10px",
                            }}
                          >
                            ARS$
                          </span>
                        ),
                      }}
                    />
                    <SuscriptionText size={10}>(por mes)</SuscriptionText>
                  </FlexContainer>
                  <Separator size={14}></Separator>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col>
                  <Center gap={"70px"}>
                    <Submit
                      isSubmmiting={
                        updatePlanStates.loading || deletePlanStates.loading
                      }
                      form="invite-academy-modal-form"
                      color="Primary"
                      options={{
                        type: "filled",
                        skin: "primary",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                    >
                      Confirmar
                    </Submit>
                    <Button
                      onClick={resetForm}
                      type="button"
                      options={{
                        type: "filled",
                        skin: "danger",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                      style={{ marginLeft: "10px" }}
                    >
                      Deshacer
                    </Button>
                  </Center>
                </Grid.Col>
              </Grid.Row>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const states = ({
  associationStore,
  cohortStore,
  currentStore,
  organizationStore,
  planStore,
  modalStore,
}) => {
  const { states: updateCohortStates } = cohortStore.updateCohort;
  const { data: current } = currentStore;
  const { visibleModal: modalName } = modalStore;
  const { states: updatePlanStates } = planStore.update;
  const { states: deletePlanStates } = planStore.delete;
  return {
    current,
    updateCohortStates,
    modalName,
    updatePlanStates,
    deletePlanStates,
  };
};

export default connect(states)(Component);
