import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Modal from "../../../../../../components/Modal";
import { Field, Formik } from "formik";
import {
  Center,
  FlexContainer,
} from "../../../../../../components/StyledComponents";
import {
  initialValues,
  schema,
} from "../../../../../../constants/form/listen/public-listen";
import Grid from "../../../../../../components/Grid";
import Button from "../../../../../../components/Button";
import Submit from "../../../../../../components/Form/Submit";
import { toast } from "react-toastify";
import { hideModal } from "../../../../../../store/actions/modal";
import { AcademyDto } from "../../../../../../types/academy.dto";
import { CurrentDto } from "../../../../../../types/current.dto";
import DinamicField from "../../../../../../components/Form/DinamicField";
import { LetterModal } from "./style";
import { CohortDto } from "../../../../../../types/cohort.dto";
import { UnitDto } from "../../../../../../types/unit.dto";
import { StateDto } from "../../../../../../types/states.dto";
import { resetUpdateCDOCs } from "../../../../../../store/actions/cdoc";
import { ListenDto } from "../../../../../../types/listen.dto";
import {
  newListen,
  updateListen,
} from "../../../../../../store/actions/listen";
import { UserDto } from "../../../../../../types/user.dto";
import { setFormData } from "../../../../../../helpers/formData";
import Loader from "../../../../../../components/Loader";
import DinamicLinked from "../../../../../../components/Form/DinamicLinked";

const Component = ({
  academies,
  cohorts,
  current,
  units,
  listen,
  updateStates,
  listenImage,
  listenCurrent,
  newStates,
  listenDataChanged,
  user,
}: {
  academies: AcademyDto[];
  listenDataChanged: any;
  newStates: StateDto;
  cohorts: CohortDto[];
  current: CurrentDto;
  units: UnitDto[];
  listen: ListenDto;
  updateStates: StateDto;
  listenImage: any;
  listenCurrent: any;
  user: UserDto;
}) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const [activeAcademy, setActiveAcademy] = useState<any>();
  const dispatch = useDispatch();
  const onSubmit = ({ values, actions }) => {
    if (!!listen) {
      if (!listen.audio && !listenCurrent) return null;
      if (!listen.picture && !listenImage) return null;
      dispatch(
        updateListen({
          _id: listen._id,
          body: setFormData(
            {
              name: values.name,
              cohorts: values.cohorts,
              description: values.description,
              units: values.units.map((unit) => unit.value),
              ...(!!listenImage && { picture: listenImage.file }),
              ...(!!listenCurrent && { audio: listenCurrent.file }),
              allCohorts: values.allCohorts,
              published: true,
              available: values.available,
              __v: listen.__v,
            },
            ["picture", "audio"]
          ),
        })
      );
    } else {
      dispatch(
        newListen(
          setFormData(
            {
              name: values.name,
              cohorts: values.cohorts,
              description: values.description,
              units: values.units.map((unit) => unit.value),
              ...(!!listenImage && { picture: listenImage.file }),
              ...(!!listenCurrent && { audio: listenCurrent.file }),
              allCohorts: values.allCohorts,
              type: "LISTEN",
              published: true,
              academy: current.id,
              available: values.available,
            },
            ["picture", "audio"]
          )
        )
      );
    }

    setFormActions(actions);
  };
  useEffect(() => {
    if (updateStates.success) {
      toast.success("👌🏼 Cdoc publicado");

      setTimeout(() => {
        dispatch(resetUpdateCDOCs());
        dispatch(hideModal());
        formActions?.resetForm();
      }, 1000);
    }

    if (updateStates.error) {
      toast.error(`😱 ${updateStates.error}`);

      setTimeout(() => {
        dispatch(resetUpdateCDOCs());
      }, 1000);
    }
  }, [updateStates]);
  return (
    <Modal name="publish-listen-modal" title={`Publicar Listen`}>
      {!units ? (
        <Loader color="Primary"></Loader>
      ) : (
        <Formik
          initialValues={{
            ...initialValues({
              ...(!!listen && {
                units: listen.units.map((unit) => {
                  const unitFounded = units.find(
                    (unitFounded) => unitFounded._id === unit
                  );
                  return {
                    label: unitFounded?.name,
                    value: unitFounded?._id,
                  };
                }),
                allCohorts: listen.allCohorts,
                cohorts: listen.cohorts,
                available: listen.available,
              }),
              description: listenDataChanged.description,
              name: listenDataChanged.name,
            }),
          }}
          onSubmit={(values, actions) => {
            if (onSubmit) onSubmit({ values, actions });
          }}
          validateOnChange={formSubmmited}
          validateOnMount={true}
          validateOnBlur={false}
          validationSchema={schema}
          enableReinitialize
        >
          {({
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
            setFieldValue,
            isSubmitting,
          }) => {
            return (
              <form
                className="theme-form"
                onSubmit={(event) => {
                  if (values.description.length < 5 || values.name.length < 5) {
                    toast.error(
                      "La longitud de la descripcion y el titulo tienen que ser mayor a 5 caracteres"
                    );
                  }
                  if (!!listen && !listen.picture) {
                    if (!listenImage) {
                      toast.error(
                        "Se necesita subir una imagen de portada para el listen"
                      );
                    }
                  }
                  if (!!listen && !listen.audio) {
                    if (!listenCurrent) {
                      toast.error("Se necesita subir un listen");
                    }
                  }

                  setFormSubmmited(true);
                  handleSubmit(event);
                }}
                id="publish-form"
              >
                <Grid.Row style={{ marginBottom: "20px" }}>
                  <Grid.Col>
                    <FlexContainer
                      justify="space-between"
                      style={{ padding: "0px 0px 0px 20px" }}
                    >
                      <FlexContainer
                        direction="column"
                        gap="2px"
                        style={{ maxWidth: "300px" }}
                      >
                        <FlexContainer align="center" gap="1px">
                          <LetterModal lg>Publicación General</LetterModal>
                          <LetterModal lg gray>
                            (Recomendado):
                          </LetterModal>
                        </FlexContainer>
                        <LetterModal gray>
                          El contenido se publicara para todo el publico de
                          Constana Campus, dándole visibilidad a tu Academia y
                          contenidos a nuevos usuarios.
                        </LetterModal>
                      </FlexContainer>
                      <Field
                        style={{
                          width: "20px",
                          height: "20px",
                          boxShadow: "0 0 4px 0 rgba(21, 115, 229, 0.16)",
                        }}
                        type="checkbox"
                        name="available"
                      />
                    </FlexContainer>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row style={{ marginBottom: "20px" }}>
                  <Grid.Col>
                    <FlexContainer
                      justify="space-between"
                      style={{ padding: "0px 0px 0px 20px" }}
                    >
                      <FlexContainer
                        direction="column"
                        gap="2px"
                        style={{ maxWidth: "300px" }}
                      >
                        {" "}
                        <FlexContainer align="center" gap="1px">
                          <LetterModal lg>Compartir entre Cohorts</LetterModal>
                        </FlexContainer>
                        <LetterModal gray>
                          Mostrar a todos los cohorts de la academia
                        </LetterModal>
                      </FlexContainer>
                      <Field
                        style={{
                          width: "20px",
                          height: "20px",
                          boxShadow: "0 0 4px 0 rgba(21, 115, 229, 0.16)",
                        }}
                        type="checkbox"
                        name="allCohorts"
                      />
                    </FlexContainer>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col>
                    <DinamicField
                      name="cohorts"
                      error={errors["cohorts"]}
                      touched={touched["cohorts"]}
                      value={values["cohorts"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={{
                        itemOnClick: {
                          functionItem: (value) =>
                            setActiveAcademy((state) =>
                              state === value ? undefined : value
                            ),
                          value: activeAcademy,
                        },
                        onDelete: (item, index) => {
                          setFieldValue(
                            "units",
                            values["units"].filter((val) => val.linked !== item)
                          );
                        },
                        skin: "gray",
                        type: "select",
                        height: "95px",
                        ...(!!academies &&
                          !!cohorts && {
                            data: cohorts
                              ?.filter(
                                (cohort) =>
                                  cohort.active &&
                                  cohort.academy._id === current.id
                              )
                              .map((cohort) => {
                                return {
                                  value: cohort._id,
                                  label: cohort.name,
                                };
                              }),
                          }),

                        loading: !cohorts,
                        inputLabel: "Seleccionar Cohorts (Opcional)",
                        inputPlaceholder: "Selecciona un Cohort",
                      }}
                    />
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col>
                    <DinamicLinked
                      name="units"
                      error={errors["units"]}
                      touched={touched["units"]}
                      value={values["units"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={{
                        notLoading: true,
                        skin: "gray",
                        type: "select",
                        height: "95px",
                        ...(!!units &&
                          !!activeAcademy && {
                            data: units
                              .filter(
                                (unit) =>
                                  unit.active &&
                                  unit.cohort.includes(activeAcademy)
                              )
                              .map((cohort) => {
                                return {
                                  linked: activeAcademy,
                                  value: cohort._id,
                                  label: cohort.name,
                                };
                              }),
                          }),
                        externalData: true,
                        loading: !units,
                        inputLabel:
                          "Seleccionar Módulos del Cohort Seleccionado",
                        inputPlaceholder: "Selecciona un Modulo",
                      }}
                    ></DinamicLinked>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col>
                    <Center gap={"10px"}>
                      <Submit
                        isSubmmiting={
                          !!listen ? updateStates.loading : newStates.loading
                        }
                        form="publish-form"
                        color="Primary"
                        style={{ width: "170px" }}
                        options={{
                          type: "filled",
                          skin: "primary",
                          size: "lg",
                          marginBottom: "0px",
                        }}
                      >
                        Confirmar
                      </Submit>
                      <Button
                        onClick={resetForm}
                        type="button"
                        options={{
                          type: "outline",
                          skin: "danger",
                          size: "lg",
                          marginBottom: "0px",
                        }}
                        style={{ marginLeft: "10px", width: "170px" }}
                      >
                        Cancelar
                      </Button>
                    </Center>
                  </Grid.Col>
                </Grid.Row>
              </form>
            );
          }}
        </Formik>
      )}
    </Modal>
  );
};

const states = ({
  academyStore,
  cohortStore,
  currentStore,
  unitStore,
  userStore,
  listenStore,
}) => {
  const { data: academies } = academyStore.all;
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: current } = currentStore;
  const { data: units } = unitStore.allUnits;
  const { states: updateStates } = listenStore.update;
  const { states: newStates } = listenStore.new;
  const { data: user } = userStore;
  return { academies, cohorts, current, units, updateStates, user, newStates };
};

export default connect(states)(Component);
