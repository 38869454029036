import { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import ReactSelect from "../../../../../components/Form/ReactSelect";
import Grid from "../../../../../components/Grid";
import Modal from "../../../../../components/Modal";
import { Center } from "../../../../../components/StyledComponents";
import Button from "../../../../../components/Button";
import Submit from "../../../../../components/Form/Submit";
import talentActions from "../../../../../store/actions/recruiting/ait-talent";
import {
  schema,
  initialValues,
} from "../../../../../constants/form/team/addAitTalent";
const Component = ({
  selectedAcademy,
  createStates,
  current,
  talent,
  teams,
  updateStates,
  organizations,
  user,
  aitTalents,
}) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const dispatch = useDispatch();

  const onSubmit = ({ values, actions }) => {
    const { _id, userId, ...restTalent } = talent;
    for (let i = 0; i <= values.team.length - 1; i++) {
      dispatch(
        talentActions.create({
          ...restTalent,
          talent: _id,
          cohorts: talent.cohorts.map((cohort) => cohort._id),
          external: false,
          team: values.team[i],
          ...(current.currentSection === "academy" && {
            currentAcademy: current.id,
          }),
        })
      );
    }
    setFormActions(actions);
  };

  return (
    <Modal name="invite-team-modal" title="Agregar a Equipo">
      <Formik
        initialValues={{
          ...initialValues,
        }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="invite-team-modal-form"
            >
              <Grid.Row>
                <Grid.Col>
                  <ReactSelect
                    name="team"
                    error={errors["team"]}
                    touched={touched["team"]}
                    isMulti
                    {...(!!teams && {
                      items: teams
                        ?.filter(
                          (team) =>
                            team.active &&
                            team.organization === current.id &&
                            !aitTalents.find(
                              (aitTalent) =>
                                aitTalent.team === team._id &&
                                aitTalent.active &&
                                aitTalent.talent === talent._id
                            )
                        )
                        .map((team) => ({
                          label: team.name,
                          value: team._id,
                        })),
                    })}
                    placeholder="Seleccione Equipos"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      skin: "gray",
                      label: "Equipos",
                      marginBottom: 20,
                    }}
                  />
                </Grid.Col>
              </Grid.Row>

              <Grid.Row>
                <Grid.Col>
                  <Center gap={"70px"}>
                    <Button
                      onClick={resetForm}
                      type="button"
                      options={{
                        type: "outline",
                        skin: "gray",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                      style={{ marginLeft: "10px" }}
                    >
                      Cancelar
                    </Button>

                    <Submit
                      isSubmmiting={updateStates.loading}
                      form="invite-team-modal-form"
                      color="Primary"
                      options={{
                        type: "filled",
                        skin: "primary",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                    >
                      Confirmar
                    </Submit>
                  </Center>
                </Grid.Col>
              </Grid.Row>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};
/* Cada vez que paso de entidad tengo que borrar el historial */
const states = ({
  invitationStore,
  academyStore,
  currentStore,
  teamStore,
  recruitingStore,
  organizationStore,
  userStore,
}) => {
  const { data: selectedAcademy } = academyStore.selected;
  const { states: createStates } = invitationStore.create;
  const { data: teams } = teamStore.all;
  const { data: organizations } = organizationStore.all;
  const { data: current } = currentStore;
  const { states: updateStates } = recruitingStore.talent.create;
  const { data: aitTalents } = recruitingStore.talent.all;
  const { data: user } = userStore;
  return {
    selectedAcademy,
    createStates,
    teams,
    organizations,
    current,
    aitTalents,
    updateStates,
    user,
  };
};

export default connect(states)(Component);
