import { CurrentDto } from "../../types/current.dto";

export const SET_CURRENT = "SET_CURRENT";

export interface SetCurrent {
  type: typeof SET_CURRENT;
  payload: CurrentDto;
}

export interface State {
  data: null | CurrentDto;
}

export const initialState: State = {
  data: null,
};

export type Actions = SetCurrent;
