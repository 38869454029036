import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import {
  PageTitle,
  SectionTitle,
  Center,
  AddButton,
} from "../../../../components/StyledComponents";
import { toast } from "react-toastify";
import Icon from "../../../../components/Icon";
import Grid from "../../../../components/Grid";

import Submit from "../../../../components/Form/Submit";
import ReactSelect from "../../../../components/Form/ReactSelect";
import StudentCard from "../components/TalentCard";
import EditStudentModal from "./components/EditStudentModal";
import { AbilityDto } from "../../../../types/ability.dto";
import { CohortDto } from "../../../../types/cohort.dto";

import { EditTalent } from "./styles";
import searchIcon from "../../../../assets/icons/search.svg";
import { StudentDto } from "../../../../types/student.dto";
import {
  getTalents,
  newTalent,
  resetNewTalent,
} from "../../../../store/actions/talent";
import { TalentDto } from "../../../../types/talent.dto";
import PencilIcon from "../../../../assets/icons/edit.svg";
import { showModal } from "../../../../store/actions/modal";
import { getAllProvinces } from "../../../../store/actions/statics";
import { getIndividualStats } from "../../../../store/actions/analytics";
import { AnalyticsDto } from "../../../../types/analytics.dto";
import { AitTalentDto } from "../../../../types/recruiting.dto";
import { getAllStudents } from "../../../../store/actions/student";
import { getAllCohorts } from "../../../../store/actions/cohort";
import { getAllAbilities } from "../../../../store/actions/ability";
import { UsersDto } from "../../../../types/users.dto";

export interface CurrentTalentValues {
  _id?: string;
  userId?: string;
  cohorts?: CohortDto[];
  avatar?: string;
  country: string;
  province: string;
  english: string;
  email?: string;
  portfolio?: string;
  linkedin?: string;
  curriculum?: string;
  name?: string;
  comments?: string;
}

interface CurrentTalent {
  _id?: string;
  userId: string;
  cohorts: CohortDto[];
  bootcampName: string;
  name: string;
  lastName: string;
  avatar?: string;
  country?: string;
  province?: string;
  english?: string;
  email?: string;
  portfolio?: string;
  linkedin?: string;
  curriculum?: string;
  comments?: string;
}

interface Option {
  label: string;
  value: string;
}

interface ComponentProps {
  abilities: AbilityDto[];
  abilitiesStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  cohorts: CohortDto[];
  cohortsStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  students: StudentDto[];
  studentsStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  talents: TalentDto[];
  talentsStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  provinces: Option[];
  provincesStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  newTalentStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  analytics: AnalyticsDto[];
  aitTalents: AitTalentDto[];
  users: UsersDto[];
}

const Component = ({
  newTalentStates,
  abilities,
  abilitiesStates,
  cohorts,
  cohortsStates,
  students,
  studentsStates,
  talents,
  analytics,
  talentsStates,
  provinces,
  provincesStates,
  aitTalents,
  users,
}: ComponentProps) => {
  const [selectedStudent, setSelectedStudent] = useState<string>("");
  const [selectedBootcamp, setSelectedBootcamp] = useState<{
    label: string;
    value: string;
  }>();
  const [currentTalent, setCurrentTalent] = useState<CurrentTalent>();
  const [currentTalentValues, setCurrentTalentValues] =
    useState<CurrentTalentValues>();
  const [formSubmmited, setFormSubmmited] = useState<boolean>(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const dispatch = useDispatch();
  /*  const greatestStageHard = analytics
    ?.find((analytic) => analytic.cohort === selectedBootcamp?.value)
    ?.hardSkills.reduce((acc, current) => {
      if (current.stage > acc) {
        return current.stage;
      } else {
        return acc;
      }
    }, 1);
  const greatestStageSoft = analytics
    ?.find((analytic) => analytic.cohort === selectedBootcamp?.value)
    ?.softSkills.reduce((acc, current) => {
      if (current.stage > acc) {
        return current.stage;
      } else {
        return acc;
      }
    }, 1);
  const individualHard = analytics
    ?.find((analytic) => analytic.cohort === selectedBootcamp?.value)
    ?.hardSkills.filter((skill) => skill.stage === greatestStageHard);
  const softProm = analytics?.find(
    (analytic) => analytic.cohort === selectedBootcamp?.value
  )?.stagesNotes[`stage-${greatestStageSoft}`].soft;

  const globalProm = analytics?.find(
    (analytic) => analytic.cohort === selectedBootcamp?.value
  )?.stagesNotes[`stage-${greatestStageSoft}`].global; */
  const onSubmit = ({ values, actions }) => {
    dispatch(newTalent(currentTalentValues));
    setFormActions(actions);
  };
  useEffect(() => {
    if (!!selectedStudent && !!talents && !!selectedBootcamp && !!users) {
      const talent = talents.filter(
        (student) => student._id === selectedStudent
      );
      const student = users.filter(
        (student) => student._id === talent[0].userId
      )[0];
      const { _id, ...rest } = student;

      setCurrentTalent({
        ...rest,
        userId: _id,
        cohorts: talent.find((talent) => talent.userId === _id)?.cohorts || [],
        bootcampName: selectedBootcamp.label,
        ...(talent.length > 0 && talent[0]),
      });
    }
  }, [students, talents, selectedStudent, users, selectedBootcamp]);

  useEffect(() => {
    if (!!currentTalent) {
      setCurrentTalentValues({
        userId: currentTalent.userId,
        cohorts: currentTalent.cohorts,
        avatar: currentTalent.avatar,
        country: currentTalent.country || "",
        province: currentTalent.province || "",
        english: currentTalent.english || "",
        email: currentTalent.email,
        portfolio: currentTalent.portfolio,
        linkedin: currentTalent.linkedin,
        curriculum: currentTalent.curriculum,
        comments: currentTalent.comments,
      });
    }
  }, [currentTalent]);

  useEffect(() => {
    if (newTalentStates.success) {
      toast.success("👌🏼 Talento creado exitosamente");

      setTimeout(() => {
        dispatch(resetNewTalent());
        //formActions?.resetForm();
      }, 1000);
    }

    if (newTalentStates.error) {
      toast.error(`😱 ${newTalentStates.error}`);

      setTimeout(() => {
        dispatch(resetNewTalent());
      }, 1000);
    }
  }, [newTalentStates]);

  useEffect(() => {
    !abilities && dispatch(getAllAbilities());
    !cohorts && dispatch(getAllCohorts());
    !talents && dispatch(getTalents());
    !provinces && dispatch(getAllProvinces("argentina"));
  }, []);

  return (
    <>
      <PageTitle>Crear y Editar Tarjetas de Talentos</PageTitle>

      <Formik
        initialValues={{}}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="new-talent-form"
            >
              <Grid.Row>
                <Grid.Col sm={6} offset={{ sm: 3 }}>
                  <ReactSelect
                    name="technology"
                    error={errors["technology"]}
                    touched={touched["technology"]}
                    items={
                      !!abilities &&
                      abilities
                        .filter((ability) => ability.active)
                        .map((ability) => ({
                          label: ability.name,
                          value: ability._id,
                        }))
                    }
                    placeholder="Selecciona una tecnología"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      loading: !abilities || abilitiesStates.loading,
                      label: "Tecnologia *",
                      marginBottom: 24,
                    }}
                  />

                  <ReactSelect
                    name="bootcamp"
                    error={errors["bootcamp"]}
                    touched={touched["bootcamp"]}
                    items={
                      !!cohorts &&
                      cohorts
                        .filter((cohort) => cohort.active)
                        .filter(
                          (cohort) => cohort.abilityId === values["technology"]
                        )
                        .map((cohort) => ({
                          label: cohort.name,
                          value: cohort._id,
                        }))
                    }
                    placeholder="Selecciona un bootcamp"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onOptionSelected={(optionSelected) => {
                      setSelectedBootcamp(optionSelected);
                    }}
                    options={{
                      loading: !cohorts || cohortsStates.loading,
                      label: "Seleccionar Bootcamp*",
                      marginBottom: 24,
                    }}
                  />

                  <Grid.Row>
                    <Grid.Col>
                      <ReactSelect
                        name="student"
                        error={errors["student"]}
                        touched={touched["student"]}
                        items={
                          !!users &&
                          !!talents &&
                          talents
                            .filter(
                              (student) =>
                                student.active &&
                                student.cohorts.includes(values["bootcamp"])
                            )
                            .map((talent) => {
                              return {
                                talentId: talent._id,
                                ...users.filter(
                                  (user) => user._id === talent.userId
                                )[0],
                              };
                            })
                            .map((student) => ({
                              label: `${student.lastName}, ${student.name}`,
                              value: student.talentId,
                            }))
                        }
                        placeholder="Busca por nombre del talento"
                        onOptionSelected={(optionSelected) => {
                          setSelectedStudent(optionSelected.value);
                          dispatch(getIndividualStats(optionSelected.value));
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        options={{
                          loading: !users,
                          marginBottom: 24,
                          label: "Seleccionar Talento",
                        }}
                      />

                      {!!selectedStudent && (
                        <EditTalent
                          type="button"
                          onClick={() =>
                            dispatch(showModal("talent-edit-modal"))
                          }
                        >
                          <Icon icon={PencilIcon} color="#1573e5" size="24px" />
                        </EditTalent>
                      )}
                    </Grid.Col>
                  </Grid.Row>
                </Grid.Col>
              </Grid.Row>
            </form>
          );
        }}
      </Formik>

      <Grid.Row style={{ marginBottom: "10px" }}>
        <Grid.Col>
          <SectionTitle
            style={{
              maxWidth: "760px",
              margin: "0px auto 10px",
            }}
          >
            Preview de la Tarjeta:
          </SectionTitle>
          {/*   <StudentCard
            isAIT={false}
            currentTalent={currentTalent}
            currentTalentValues={currentTalentValues}
            stats={
              !!analytics && { hardNotes: individualHard, softProm, globalProm }
            }
          /> */}
        </Grid.Col>
      </Grid.Row>

      <Center>
        <Submit
          isSubmmiting={newTalentStates.loading}
          form="new-talent-form"
          color="Primary"
          options={{
            type: "filled",
            skin: "primary",
            size: "lg",
            marginBottom: "0px",
          }}
        >
          Confirmar
        </Submit>
      </Center>

      <EditStudentModal
        currentTalent={currentTalent}
        setCurrentTalentValues={setCurrentTalentValues}
        currentTalentValues={currentTalentValues}
      />
    </>
  );
};

const states = ({
  talentStore,
  staticsStore,
  abilityStore,
  cohortStore,
  studentStore,
  analyticsStore,
  recruitingStore,
  usersStore,
}) => {
  const { states: newTalentStates } = talentStore.newTalent;
  const { states: abilitiesStates, data: abilities } =
    abilityStore.allAbilities;
  const { states: cohortsStates, data: cohorts } = cohortStore.allCohorts;
  const { states: studentsStates, data: students } = studentStore.allStudents;
  const { states: talentsStates, data: talents } = talentStore.allTalents;
  const { states: provincesStates, data: provinces } =
    staticsStore.allProvinces;
  const { data: analytics } = analyticsStore.getIndividualStats;
  const { data: aitTalents } = recruitingStore.talent.all;
  const { data: users } = usersStore.all;
  return {
    talentsStates,
    talents,
    analytics,
    newTalentStates,
    abilitiesStates,
    abilities,
    cohortsStates,
    cohorts,
    studentsStates,
    students,
    users,
    provinces,
    provincesStates,
    aitTalents,
  };
};

export default connect(states)(Component);
