import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import Input from "../../../../../../components/Form/Input";
import Submit from "../../../../../../components/Form/Submit";
import ReactSelect from "../../../../../../components/Form/ReactSelect";
import {
  initialValues,
  schema,
} from "../../../../../../constants/form/academy/new";
import academyActions from "../../../../../../store/actions/academies";
import invitationActions from "../../../../../../store/actions/invitation";
import { getAllEvents } from "../../../../../../store/actions/event";
import { getAllAbilities } from "../../../../../../store/actions/ability";
import { AbilityDto } from "../../../../../../types/ability.dto";
import { EventDto } from "../../../../../../types/event.dto";
import Button from "../../../../../../components/Button";
import { hideModal } from "../../../../../../store/actions/modal";
import { UserDto } from "../../../../../../types/user.dto";
import { getAllMentors } from "../../../../../../store/actions/mentor";
import { Center } from "../../../../../../components/StyledComponents";
import { CurrentDto } from "../../../../../../types/current.dto";
import { CohortDto } from "../../../../../../types/cohort.dto";
import FileUploader from "../../../../../../components/Form/FileUploader";
import Icon from "../../../../../../components/Icon";
import UploadIcon from "../../../../../../assets/icons/cloud-upload.svg";
import { AcademyDto } from "../../../../../../types/academy.dto";
import { UsersDto } from "../../../../../../types/users.dto";
import { setFormData } from "../../../../../../helpers/formData";
import DinamicFieldListIn from "../../../../../../components/Form/DinamicFieldListIn";
import Modal from "../../../../../../components/Modal";
import { StateDto } from "../../../../../../types/states.dto";
import usersActions from "../../../../../../store/actions/users";
import {
  newManyTags,
  resetNewManyTags,
} from "../../../../../../store/actions/tag";

import { toast } from "react-toastify";
import DinamicCreateable from "../../../../../../components/Form/DinamicCreateable";
import { TagDto } from "../../../../../../types/tag.dto";
interface ComponentProps {
  newCohortStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  mentors: UserDto[];
  abilities: AbilityDto[];
  events: EventDto[];
  current: CurrentDto;
  cohorts: CohortDto[];
  user: UserDto;
  academies: AcademyDto[];
  users: UsersDto[];
  newAcademyStates: StateDto;
  newAcademy: AcademyDto;
  tags: TagDto[];
  newManyStates: StateDto;
}

const Component = ({
  abilities,
  mentors,
  events,
  newCohortStates,
  current,
  cohorts,
  user,
  academies,
  users,
  newAcademyStates,
  newAcademy,
  tags,
  newManyStates,
}: ComponentProps) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [filteredValues, setFilteredValues] = useState<any>();
  const [avatarPreview, setAvatarPreview] = useState<HTMLInputElement>();
  const [bannerPreview, setBannerPreview] = useState<HTMLInputElement>();
  const [academyUsers, setAcademyUsers] = useState<any>([]);
  const [academyMentors, setAcademyMentors] = useState<any>([]);
  const [currentTags, setCurrentTags] = useState<string[]>([]);
  const dispatch = useDispatch();

  const onSubmit = ({ values, actions }) => {
    const formValues = {
      ...values,
      ...(!!avatarPreview &&
        !!avatarPreview?.files && {
          picture: avatarPreview?.files[0],
        }),
      ...(!!bannerPreview &&
        !!bannerPreview?.files && {
          bannerPic: bannerPreview?.files[0],
        }),
    };
    const { users, mentorsIds, tags, ...rest } = formValues;
    dispatch(
      newManyTags({
        items: values.tags.map((tag) => {
          return {
            name: tag.value,
            academy: current.id,
          };
        }),
      })
    );
    dispatch(
      academyActions.create(setFormData({ ...rest }, ["picture", "bannerPic"]))
    );
  };

  const closeModal = () => {
    dispatch(hideModal());
  };

  useEffect(() => {
    if (newManyStates.error) {
      toast.error(newManyStates.error);
      dispatch(resetNewManyTags());
    }
  }, [newManyStates]);

  useEffect(() => {
    if (users) {
      const filteredValues = users
        .filter((user) => user.active && (user.username || user.email))
        .map((user, index) => {
          const objUser = {
            userName: {
              ...(!!user.username && {
                label: user.username,
                value: {
                  id: user._id,
                  value: user.username,
                  field: "username",
                },
              }),
            },
            email: {
              ...(!!user.email && {
                label: user.email,
                value: { id: user._id, value: user.email, field: "email" },
              }),
            },
          };

          return Object.values(objUser).filter(
            (obj) => Object.values(obj).length !== 0
          );
        })
        .flat(1);
      setFilteredValues(filteredValues);
    }
  }, [users]);
  useEffect(() => {
    !events && dispatch(getAllEvents({ filterBy: { active: true } }));
  }, [events]);

  useEffect(() => {
    if (!users && current.currentSection === "personal")
      dispatch(
        usersActions.getAll({ filterBy: { active: true, hidden: false } })
      );
  }, [users, current]);

  useEffect(() => {
    if (newAcademyStates.success) {
      toast.success("Se creo la academia correctamente");
      dispatch(
        newManyTags({
          items: currentTags.map((tag) => {
            return {
              name: tag,
              academy: current.id,
            };
          }),
        })
      );
      setTimeout(() => {
        dispatch(hideModal());
        dispatch(academyActions.resetCreate());
      }, 2000);
      if (users) {
        for (let i = 0; i < academyUsers.length; i++) {
          const userFounded = users.find(
            (userF) => userF._id === academyUsers[i].id
          );
          dispatch(
            invitationActions.create({
              email: userFounded?.email,
              academy: newAcademy._id,
              role: "STUDENT",
            })
          );
        }
        for (let i = 0; i < academyMentors.length; i++) {
          const userFounded = users.find(
            (userF) => userF._id === academyMentors[i].id
          );
          dispatch(
            invitationActions.create({
              email: userFounded?.email,
              academy: newAcademy._id,
              role: "PROFESSOR",
            })
          );
        }
      }
    }
    if (newAcademyStates.error) {
      toast.error(newAcademyStates.error);
      setTimeout(() => {
        dispatch(academyActions.resetCreate());
      }, 2000);
    }
  }, [newAcademyStates, dispatch]);

  return (
    <Modal name="new-academy-modal" title="Crear Academia">
      <Formik
        initialValues={{ ...initialValues }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="edit-cohort-form"
            >
              <Input
                name="name"
                error={errors["name"]}
                touched={touched["name"]}
                value={values["name"]}
                type="text"
                placeholder="Ingrese nombre de Academia"
                onChange={handleChange}
                onBlur={handleBlur}
                options={{
                  skin: "gray",
                  label: "Nombre de la Academia",
                  marginBottom: 20,
                }}
              />
              <FileUploader
                name="picture"
                error={errors["picture"]}
                touched={touched["picture"]}
                placeholder={
                  <Icon icon={UploadIcon} size="24px" color="black" />
                }
                onChange={(event) => {
                  setAvatarPreview(event.target);
                  handleChange(event);
                }}
                onBlur={handleBlur}
                accept="image/jpg;image/png;image/jpeg;capture=camera"
                options={{
                  label: "Foto de la Academia",
                  marginBottom: 24,
                }}
              />
              <FileUploader
                name="bannerPic"
                error={errors["bannerPic"]}
                touched={touched["bannerPic"]}
                placeholder={
                  <Icon icon={UploadIcon} size="24px" color="black" />
                }
                onChange={(event) => {
                  setBannerPreview(event.target);
                  handleChange(event);
                }}
                onBlur={handleBlur}
                accept="image/jpg;image/png;image/jpeg;capture=camera"
                options={{
                  label: "Banner de la Academia",
                  marginBottom: 24,
                }}
              />

              <ReactSelect
                name="private"
                error={errors["private"]}
                touched={touched["private"]}
                items={[
                  { label: "Publico", value: false },
                  { label: "Privado", value: true },
                ]}
                placeholder="Seleccione un tipo"
                onChange={handleChange}
                onBlur={handleBlur}
                options={{
                  skin: "gray",
                  label: "Acceso a la Academia",
                  marginBottom: 20,
                }}
              />
              <Input
                name="surname"
                error={errors["surname"]}
                touched={touched["surname"]}
                value={values["surname"]}
                type="text"
                placeholder="Escriba un rubro"
                onChange={handleChange}
                onBlur={handleBlur}
                options={{
                  skin: "gray",
                  label: "Rubro de la Academia",
                  marginBottom: 20,
                }}
              />
              <Input
                name="email"
                error={errors["email"]}
                touched={touched["email"]}
                value={values["email"]}
                type="text"
                placeholder="Escriba un email"
                onChange={handleChange}
                onBlur={handleBlur}
                options={{
                  skin: "gray",
                  label: "Email de la Academia",
                  marginBottom: 20,
                }}
              />
              <Input
                name="phone"
                error={errors["phone"]}
                touched={touched["phone"]}
                value={values["phone"]}
                type="text"
                placeholder="Escriba un telefono"
                onChange={handleChange}
                onBlur={handleBlur}
                options={{
                  skin: "gray",
                  label: "Telefono de Contacto",
                  marginBottom: 20,
                }}
              />
              <DinamicCreateable
                name="tags"
                error={errors["tags"]}
                touched={touched["tags"]}
                value={values["tags"]}
                onChange={handleChange}
                onBlur={handleBlur}
                options={{
                  onOptionSelected(item, index, fieldValue) {
                    const itemValue = item.value ? item.value : item;
                    setCurrentTags((state) => [...state, itemValue]);
                  },
                  onRemoveItem(item, index, fieldValue) {
                    const itemValue = item.value ? item.value : item;
                    setCurrentTags((state) =>
                      state.filter((st) => st !== itemValue)
                    );
                  },
                  height: "95px",
                  type: "select",
                  skin: "gray",
                  data: [],
                  externalData: true,
                  inputLabel: "Etiquetas",
                  inputPlaceholder: "Crea o selecciona etiquetas",
                  multi: true,
                }}
              />
              <DinamicFieldListIn
                name="mentorsIds"
                error={errors["users"]}
                touched={touched["mentorsIds"]}
                value={values["mentorsIds"]}
                onChange={handleChange}
                valueFieldName="id"
                onBlur={handleBlur}
                pickFunction={(array, valuePicked, fieldName) => {
                  const firstResult = array.filter(
                    (item) => valuePicked[fieldName] === item.value[fieldName]
                  );

                  if (firstResult[0].value["value"] !== valuePicked["value"]) {
                    return firstResult[1].value;
                  } else {
                    return firstResult[0].value;
                  }
                }}
                options={{
                  skin: "gray",
                  multi: true,
                  type: "select",
                  height: "95px",
                  setFieldValue: setFieldValue,
                  ...(!!filteredValues && {
                    data: filteredValues.map((user) => {
                      return { value: user.value, label: user.label };
                    }),
                  }),
                  externalData: true,
                  onDeleteItem: (item) => {
                    setAcademyMentors((state) =>
                      state.filter((itemF) => itemF.id !== item.value.id)
                    );
                  },
                  onOptionSelected: (item) => {
                    setAcademyMentors((state) => [...state, item]);
                  },
                  loading: !users,
                  inputLabel: "Invitar Profesores",
                  inputPlaceholder: "Selecciona un usuario",
                }}
              />
              <DinamicFieldListIn
                name="users"
                error={errors["users"]}
                touched={touched["users"]}
                value={values["users"]}
                onChange={handleChange}
                valueFieldName="id"
                onBlur={handleBlur}
                pickFunction={(array, valuePicked, fieldName) => {
                  const firstResult = array.filter(
                    (item) => valuePicked[fieldName] === item.value[fieldName]
                  );

                  if (firstResult[0].value["value"] !== valuePicked["value"]) {
                    return firstResult[1].value;
                  } else {
                    return firstResult[0].value;
                  }
                }}
                options={{
                  skin: "gray",
                  type: "select",
                  height: "95px",
                  multi: true,
                  setFieldValue: setFieldValue,
                  ...(!!filteredValues && {
                    data: filteredValues.map((user) => {
                      return { value: user.value, label: user.label };
                    }),
                  }),
                  onDeleteItem: (item) => {
                    setAcademyUsers((state) =>
                      state.filter((itemF) => itemF.id !== item.value.id)
                    );
                  },
                  onOptionSelected: (item) => {
                    setAcademyUsers((state) => [...state, item]);
                  },
                  externalData: true,
                  loading: !users,
                  inputLabel:
                    "Invita personas a que se suscriban a tu Academia!!!",
                  inputPlaceholder: "Selecciona un usuario",
                }}
              />

              <Center>
                <Submit
                  isSubmmiting={newAcademyStates.loading}
                  form="edit-cohort-form"
                  color="Primary"
                >
                  Confirmar
                </Submit>
                <Button
                  onClick={closeModal}
                  type="button"
                  options={{
                    type: "outline",
                    skin: "danger",
                    size: "lg",
                    marginBottom: "0px",
                  }}
                  style={{ marginLeft: "10px" }}
                >
                  Cancelar
                </Button>
              </Center>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const states = ({
  abilityStore,
  eventStore,
  cohortStore,
  mentorStore,
  currentStore,
  userStore,
  academyStore,
  usersStore,
  tagStore,
}) => {
  const { data: mentors } = mentorStore.allMentors;
  const { data: abilities } = abilityStore.allAbilities;
  const { data: tags } = tagStore.all;
  const { states: newManyStates } = tagStore.newMany;
  const { data: academies } = academyStore.all;
  const { data: events } = eventStore.allEvents;
  const { states: newCohortStates } = cohortStore.newCohort;
  const { states: newAcademyStates, data: newAcademy } = academyStore.create;
  const { data: current } = currentStore;
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: user } = userStore;
  const { data: users } = usersStore.all;
  return {
    abilities,
    events,
    mentors,
    tags,
    newCohortStates,
    newAcademy,
    current,
    cohorts,
    user,
    academies,
    users,
    newAcademyStates,
    newManyStates,
  };
};

export default connect(states)(Component);
