import React from "react";
import Button from "../../../../../../../../components/Button";
import { CardColumn, CardRow } from "../../../../../Profile/style";
import { CohortModal, DataText, ModalTitle } from "./style";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch } from "react-redux";
const Component = ({
  cohorts,
  top,
  right,
  active,
  setActive,
  actions,
  payload,
  title,
}) => {
  const dispatch = useDispatch();
  return (
    <CohortModal className={!!active ? "active" : ""} right={right} top={top}>
      <CardRow style={{ width: "100%" }} justify="space-between">
        <ModalTitle>{title}</ModalTitle>
        <AiOutlineClose
          onClick={() => setActive(null)}
          fontSize={15}
          cursor={"pointer"}
        ></AiOutlineClose>
      </CardRow>
      <CardColumn style={{ width: "100%", "margin-top": "10px" }} gap="10px">
        {cohorts?.map((cohort) => (
          <CardRow
            align="center"
            style={{ width: "100%" }}
            justify="space-between"
            gap="10px"
          >
            <DataText size="14px">{cohort.name}</DataText>
            <Button
              type="button"
              options={{ type: "filled", size: "sm", skin: "gray" }}
              style={{
                minWidth: "unset",
                padding: " 5px 10px",
                width: "auto",
                fontSize: "12px",
                fontWeight: "200",
              }}
              onClick={() => dispatch(actions({ ...payload, _id: cohort._id }))}
            >
              Eliminar
            </Button>
          </CardRow>
        ))}
      </CardColumn>
    </CohortModal>
  );
};

export default Component;
