import React, { useEffect, useState } from "react";
import Grid from "../../../../components/Grid";
import {
  FlexContainer,
  PageTitle,
  Separator,
} from "../../../../components/StyledComponents";
import {
  FinanceAccountPreview,
  FinanceBillWrapper,
  FinanceInputText,
  FinanceInputTextBox,
  FinanceText,
  FinanceWrapper,
} from "./style";
import { v4 as uuidv4 } from "uuid";
import MonthlyTracking from "./components/MonthlyTracking";
import { MdEdit } from "react-icons/md";
import { useTheme } from "styled-components";
import { connect, useDispatch } from "react-redux";
import { showModal } from "../../../../store/actions/modal";
import FiscalInformationModal from "./components/FiscalInformationModal";
import BankInformation from "./components/BankInformation";
import { CurrentDto } from "../../../../types/current.dto";
import { AcademyDto } from "../../../../types/academy.dto";
import Loader from "../../../../components/Loader";
import { useSearchParams } from "react-router-dom";
import academyActions from "../../../../store/actions/academies";
import { StateDto } from "../../../../types/states.dto";
import { toast } from "react-toastify";
import { SuscriptionStatus } from "../Academy/style";

const Component = ({
  current,
  academy,
  updatedAcademy,
  updateAcademyStates,
}: {
  current: CurrentDto;
  academy: AcademyDto;
  updatedAcademy: AcademyDto;
  updateAcademyStates: StateDto;
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [fetchOn, setFetchOn] = useState<boolean>(false);
  const [currentAcademy, setCurrentAcademy] = useState<AcademyDto>();
  let [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (academy && fetchOn) {
      setCurrentAcademy(academy);
    }
  }, [academy, fetchOn]);

  useEffect(() => {
    if (current.currentSection === "academy" && fetchOn) {
      dispatch(academyActions.getOne({ _id: current.id }));
    }
  }, [current, fetchOn]);

  useEffect(() => {
    if (searchParams.get("code")) {
      dispatch(
        academyActions.setMp({
          code: searchParams.get("code"),
          _id: current.id,
        })
      );
    }
  }, [searchParams]);

  useEffect(() => {
    if (updateAcademyStates.success) {
      toast.success(`Se completo correctamente`);
      setCurrentAcademy(updatedAcademy);
      dispatch(academyActions.resetUpdate());
    }
    if (updateAcademyStates.error) {
      toast.error(`${updateAcademyStates.error}`);
      dispatch(academyActions.resetUpdate());
    }
  }, [updateAcademyStates]);

  useEffect(() => {
    if (!academy) {
      setFetchOn(true);
    }
  }, [academy]);

  useEffect(() => {
    return () => {
      dispatch(academyActions.resetGetOne());
    };
  }, []);

  if (!currentAcademy) return <Loader color="Primary"></Loader>;
  return (
    <>
      <Grid.Container>
        <PageTitle>Mis Finanzas</PageTitle>
        <Grid.Row>
          <Grid.Col lg={5}>
            {/*  <MonthlyTracking title={"Ingresos mensuales"}></MonthlyTracking> */}
            <Separator size={16}></Separator>
            <FlexContainer justify="space-between" align="center">
              <FinanceText size={14}>Informacion Fiscal</FinanceText>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  dispatch(showModal("fiscal-info-modal"));
                }}
              >
                <MdEdit size={20} color={theme.colors.lightGray}></MdEdit>
              </div>
            </FlexContainer>
            <Separator size={7}></Separator>
            <FinanceWrapper>
              <FinanceInputText size={16}>
                Persona Fisica/Persona Juridica
              </FinanceInputText>
              <Separator size={3}></Separator>
              <FinanceInputTextBox>
                {currentAcademy.taxInfo?.personType ?? "No hay datos"}
              </FinanceInputTextBox>
              <Separator size={14}></Separator>
              <FinanceInputText size={16}>Nombre</FinanceInputText>
              <Separator size={3}></Separator>
              <FinanceInputTextBox>
                {currentAcademy.taxInfo?.personName ?? "No hay datos"}
              </FinanceInputTextBox>
              <Separator size={14}></Separator>
              <FinanceInputText size={16}>CUIT</FinanceInputText>
              <Separator size={3}></Separator>
              <FinanceInputTextBox>
                {currentAcademy.taxInfo?.cuit ?? "No hay datos"}
              </FinanceInputTextBox>
              <Separator size={14}></Separator>
              <FinanceInputText size={16}>DNI</FinanceInputText>
              <Separator size={3}></Separator>
              <FinanceInputTextBox>
                {currentAcademy.taxInfo?.dni ?? "No hay datos"}
              </FinanceInputTextBox>
              <Separator size={14}></Separator>
              <FinanceInputText size={16}>
                Condición frente al IVA
              </FinanceInputText>
              <Separator size={3}></Separator>
              <FinanceInputTextBox>
                {currentAcademy.taxInfo?.ivaType ?? "No hay datos"}
              </FinanceInputTextBox>
            </FinanceWrapper>
          </Grid.Col>
          <Grid.Col lg={5} offset={{ lg: 2 }}>
            <FlexContainer gap="15px" wrap="wrap">
              <FinanceText size={14}>Tu cuenta de mercado pago:</FinanceText>
              {currentAcademy.atat && (
                <SuscriptionStatus className={"active"}>
                  Cuenta Vinculada
                </SuscriptionStatus>
              )}
            </FlexContainer>

            <FinanceAccountPreview
              hasAcount={currentAcademy.atat}
              onClick={() => {
                window.open(
                  `https://auth.mercadopago.com/authorization?client_id=${
                    process.env.REACT_APP_MP_ID
                  }&response_type=code&platform_id=mp&state=${uuidv4()}&redirect_uri=https://academy.academia.catel.org.ar/dashboard/myfinances`,
                  "_self"
                );
              }}
              height="135px"
            >
              {!currentAcademy.atat && (
                <span className="title">Vincular cuenta Mercado Pago</span>
              )}
            </FinanceAccountPreview>
            <Separator size={19}></Separator>
            <FlexContainer justify="space-between" align="center">
              <FinanceText size={14}>Informacion Fiscal</FinanceText>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  dispatch(showModal("bank-information-modal"));
                }}
              >
                <MdEdit size={20} color={theme.colors.lightGray}></MdEdit>
              </div>
            </FlexContainer>
            <Separator size={7}></Separator>
            <FinanceWrapper>
              <FinanceInputText size={16}>
                Titular de la cuenta
              </FinanceInputText>
              <Separator size={3}></Separator>
              <FinanceInputTextBox>
                {currentAcademy.taxInfo?.accountHolder ?? "No hay datos"}
              </FinanceInputTextBox>
              <Separator size={14}></Separator>
              <FinanceInputText size={16}>CBU</FinanceInputText>
              <Separator size={3}></Separator>
              <FinanceInputTextBox>
                {currentAcademy.taxInfo?.cbu ?? "No hay datos"}
              </FinanceInputTextBox>
              <Separator size={14}></Separator>
              <FinanceInputText size={16}>Alias</FinanceInputText>
              <Separator size={3}></Separator>
              <FinanceInputTextBox>
                {currentAcademy.taxInfo?.alias ?? "No hay datos"}
              </FinanceInputTextBox>
              <Separator size={14}></Separator>
              <FinanceInputText size={16}>Nombre del Banco</FinanceInputText>
              <Separator size={3}></Separator>
              <FinanceInputTextBox>
                {currentAcademy.taxInfo?.bankName ?? "No hay datos"}
              </FinanceInputTextBox>
            </FinanceWrapper>
            <Separator size={16}></Separator>
            <FinanceText size={14}>Facturas</FinanceText>
            <Separator size={7}></Separator>
            <FinanceBillWrapper align="center" justify="center">
              Enviar mail a admin@academia.catel.org.ar para pedido de facturas
            </FinanceBillWrapper>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
      <FiscalInformationModal
        currentAcademy={currentAcademy}
      ></FiscalInformationModal>
      <BankInformation currentAcademy={currentAcademy}></BankInformation>
    </>
  );
};

const states = ({ academyStore, currentStore }) => {
  const { data: updatedAcademy, states: updateAcademyStates } =
    academyStore.update;
  const { data: current } = currentStore;
  const { data: academy } = academyStore.selected;
  return {
    updatedAcademy,
    updateAcademyStates,
    current,
    academy,
  };
};

export default connect(states)(Component);
