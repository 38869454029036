import {
  initialState,
  Actions,
  State,
  GET_ALL_TAGS,
  GET_ALL_TAGS_ERROR,
  GET_ALL_TAGS_SUCCESS,
  RESET_GET_ALL_TAGS,
  NEW_MANY_TAGS,
  NEW_MANY_TAGS_SUCCESS,
  NEW_MANY_TAGS_ERROR,
  RESET_NEW_MANY_TAGS,
  UPDATE_MANY_TAGS,
  UPDATE_MANY_TAGS_SUCCESS,
  UPDATE_MANY_TAGS_ERROR,
  RESET_UPDATE_MANY_TAGS,
} from "../types/tag";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_TAGS:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_TAGS_SUCCESS:
      return {
        ...state,
        all: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_TAGS_ERROR:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_GET_ALL_TAGS:
      return {
        ...state,
        all: initialState.all,
      };
    case NEW_MANY_TAGS:
      return {
        ...state,
        newMany: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_MANY_TAGS_SUCCESS:
      return {
        ...state,
        newMany: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case NEW_MANY_TAGS_ERROR:
      return {
        ...state,
        newMany: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_MANY_TAGS:
      return {
        ...state,
        newMany: initialState.all,
      };
    case UPDATE_MANY_TAGS:
      return {
        ...state,
        updateMany: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_MANY_TAGS_SUCCESS:
      return {
        ...state,
        updateMany: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_MANY_TAGS_ERROR:
      return {
        ...state,
        updateMany: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_MANY_TAGS:
      return {
        ...state,
        updateMany: initialState.updateMany,
      };
    default:
      return state;
  }
};

export default reducers;
