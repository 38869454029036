import * as Yup from "yup";

interface FormValues {
  name: string;
  lastName?: string;
  avatar?: string;
  country?: string;
  github?: string;
  portfolio?: string;
  linkedin?: string;
  curriculum?: string;
  province?: string;
  english?: string;
  email?: string;
  comments?: string;
  techsIds?: string[];
  external: boolean;
}

export const schema = Yup.object().shape({
  name: Yup.string().required("Se necesita un nombre"),
  lastName: Yup.string(),
  country: Yup.string(),
  github: Yup.string(),
  avatar: Yup.string(),
  portfolio: Yup.string(),
  linkedin: Yup.string(),
  curriculum: Yup.string(),
  province: Yup.string(),
  english: Yup.string(),
  email: Yup.string().email(),
  comments: Yup.string(),
  techsIds: Yup.array().of(Yup.string()),
});

export const initialValues: FormValues = {
  name: "",
  lastName: "",
  external: true,
};
