import React from "react";
import { Spinner } from "./style";

const Component = () => {
  return (
    <Spinner className="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </Spinner>
  );
};

export default Component;
