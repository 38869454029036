import { put, call, all, takeLatest, select } from "redux-saga/effects";
import services from "../../services";
import {
  GET_REQUEST,
  GET_REQUEST_SUCCESS,
  GET_REQUEST_ERROR,
  GET_ALL_REQUESTS,
  GET_ALL_REQUESTS_SUCCESS,
  GET_ALL_REQUESTS_ERROR,
  NEW_REQUEST,
  NEW_REQUEST_ERROR,
  NEW_REQUEST_SUCCESS,
  NewRequest,
  UPDATE_REQUEST,
  UPDATE_REQUEST_SUCCESS,
  UPDATE_REQUEST_ERROR,
  DELETE_REQUEST,
  DELETE_REQUEST_SUCCESS,
  DELETE_REQUEST_ERROR,
  GetRequest,
  GetAllRequests,
  UpdateRequest,
  DeleteRequest,
  UPDATE_MANY_REQUEST_SUCCESS,
  UPDATE_MANY_REQUEST_ERROR,
  UPDATE_MANY_REQUEST,
  UpdateManyRequest,
} from "../types/request";
import { RequestDto } from "../../types/request.dto";

const requestList = ({ requestStore }) => requestStore.allRequests.data;

function* getAllRequests({ payload }) {
  const { response, error } = yield call(services.request.getAll, payload);

  if (error) {
    yield put({
      type: GET_ALL_REQUESTS_ERROR,
      payload: error.response?.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_REQUESTS_SUCCESS, payload: response });
  }
}

function* getRequest({ payload }) {
  const { response, error } = yield call(services.request.getOne, payload);

  if (error) {
    yield put({
      type: GET_REQUEST_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_REQUEST_SUCCESS, payload: response });
  }
}

function* newRequest({ payload }) {
  const { response, error } = yield call(services.request.new, payload);

  if (error) {
    yield put({
      type: NEW_REQUEST_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: NEW_REQUEST_SUCCESS, payload: response })]);
  }
}

function* updateRequest({ payload }) {
  const { response, error } = yield call(services.request.update, payload);

  if (error) {
    yield put({
      type: UPDATE_REQUEST_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_REQUEST_SUCCESS, payload: response })]);
  }
}

function* updateManyRequest({ payload }) {
  let updatedList: RequestDto[] = [];

  try {
    for (let i = 0; i < payload.items.length; i++) {
      const { response, error } = yield call(
        services.request.update,
        payload.items[i]
      );

      if (response) {
        updatedList = [...updatedList, response];
      }
    }

    yield put({ type: UPDATE_MANY_REQUEST_SUCCESS, payload: updatedList });
  } catch (err) {
    yield put({ type: UPDATE_MANY_REQUEST_ERROR, payload: err });
  }
}

function* deleteRequest({ payload }) {
  const { response, error } = yield call(services.request.delete, payload);

  if (error) {
    yield put({
      type: DELETE_REQUEST_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: DELETE_REQUEST_SUCCESS, payload: response })]);
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllRequests>(GET_ALL_REQUESTS, getAllRequests);
  yield takeLatest<GetRequest>(GET_REQUEST, getRequest);
  yield takeLatest<NewRequest>(NEW_REQUEST, newRequest);
  yield takeLatest<UpdateRequest>(UPDATE_REQUEST, updateRequest);
  yield takeLatest<DeleteRequest>(DELETE_REQUEST, deleteRequest);
  yield takeLatest<UpdateManyRequest>(UPDATE_MANY_REQUEST, updateManyRequest);
}
