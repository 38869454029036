import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import auth from "./auth";
import modal from "./modal";
import executive from "./executive";
import user from "./user";
import criterion from "./criterion";
import subCriterion from "./sub-criterion";
import talent from "./talent";
import statics from "./statics";
import newEvaluate from "./evaluate";
import analytics from "./analytics";
import recruiting from "./recruiting";
import ability from "./ability";
import category from "./category";
import cohort from "./cohort";
import event from "./event";
import mentor from "./mentor";
import meet from "./meet";
import student from "./student";
import academy from "./academy";
import organization from "./organization";
import invitation from "./invitation";
import users from "./users";
import team from "./team";
import current from "./current";
import storage from "redux-persist/lib/storage";
import confirmation from "./confirmation";
import confirmationAuth from "./confirmationAuth";
import content from "./content";
import { persistReducer } from "redux-persist";
import unit from "./unit";
import certificate from "./certificate";
import request from "./request";
import notification from "./notification";
import association from "./association";
import cdoc from "./cdoc";
import video from "./video";
import roadmap from "./roadmap";
import image from "./image";
import slideshow from "./slideshow";
import listen from "./listen";
import byte from "./byte";
import generalContent from "./generalContent";
import tag from "./tag";
import plan from "./plan";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, current);
const createRootReducer = (history: any) =>
  combineReducers({
    academyStore: academy,
    currentStore: persistedReducer,
    confirmationAuthStore: confirmationAuth,
    cdocStore: cdoc,
    tagStore: tag,
    planStore: plan,
    imageStore: image,
    generalContentStore: generalContent,
    videoStore: video,
    byteStore: byte,
    requestStore: request,
    slideshowStore: slideshow,
    listenStore: listen,
    certificateStore: certificate,
    teamStore: team,
    unitStore: unit,
    roadmapStore: roadmap,
    notificationStore: notification,
    associationStore: association,
    invitationStore: invitation,
    usersStore: users,
    contentStore: content,
    organizationStore: organization,
    analyticsStore: analytics,
    studentStore: student,
    confirmationStore: confirmation,
    meetStore: meet,
    cohortStore: cohort,
    mentorStore: mentor,
    abilityStore: ability,
    categoryStore: category,
    eventStore: event,
    authStore: auth,
    criterionStore: criterion,
    evaluateStore: newEvaluate,
    executiveStore: executive,
    modalStore: modal,
    recruitingStore: recruiting,
    routerStore: connectRouter(history),
    staticsStore: statics,
    subCriterionStore: subCriterion,
    talentStore: talent,
    userStore: user,
  });

export default createRootReducer;
