import {
  GetAllAbilities,
  GET_ALL_ABILITIES,
  GetAbility,
  GET_ABILITY,
  UpdateAbility,
  UPDATE_ABILITY,
  DeleteAbility,
  DELETE_ABILITY,
  GetAbilitySuccess,
  GET_ABILITY_SUCCESS,
  NewAbility,
  NEW_ABILITY,
  ResetNewAbility,
  RESET_NEW_ABILITY,
  ResetUpdateAbility,
  RESET_UPDATE_ABILITY,
  ResetDeleteAbility,
  RESET_DELETE_ABILITY,
} from "../types/ability";

export const getAllAbilities = (payload): GetAllAbilities => {
  return { type: GET_ALL_ABILITIES, payload };
};

export const getAbility = (id): GetAbility => {
  return { type: GET_ABILITY, payload: { id } };
};

export const setSelectedAbility = (payload): GetAbilitySuccess => {
  return { type: GET_ABILITY_SUCCESS, payload };
};

export const newAbility = (payload): NewAbility => {
  return { type: NEW_ABILITY, payload };
};

export const resetNewAbility = (): ResetNewAbility => {
  return { type: RESET_NEW_ABILITY, payload: null };
};

export const updateAbility = (id): UpdateAbility => {
  return { type: UPDATE_ABILITY, payload: id };
};

export const resetUpdateAbility = (): ResetUpdateAbility => {
  return { type: RESET_UPDATE_ABILITY, payload: null };
};

export const deleteAbility = (payload): DeleteAbility => {
  return { type: DELETE_ABILITY, payload };
};

export const resetDeleteAbility = (): ResetDeleteAbility => {
  return { type: RESET_DELETE_ABILITY, payload: null };
};
