import New from "./New";
import Edit from "./Edit";
import List from "./List";
import Preview from "./Preview";
import Customize from "./Customize";
import Events from "./Events";
const Cohort = {
  New,
  Edit,
  Preview,
  List,
  Customize,
  Events,
};

export default Cohort;
