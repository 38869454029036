import styled from "styled-components";

export const DropDownItem = styled("div")`
  max-height: ${(props) => (props.active ? "min-content" : "0px")};
  overflow: hidden;
  padding: ${(props) => (props.active ? "3px" : "0px")};
  transition: max-height 0.3s ease-in;
`;

export const ListItem = styled("div")`
  border-radius: 5px;
  box-shadow: 0 0 4px 0 rgba(21, 115, 229, 0.3);
  padding: 4px 10px;
`;

export const ListCriterionTitle = styled("div")`
  font-size: 14px;
  font-weight: 500;
  color: #000f37;
`;
export const ListSubCriterionTitle = styled("div")`
  font-size: 14px;
  font-weight: 500;
  color: #000f37;
`;

export const TableContainer = styled("div")`
  width: 100%;
  overflow-x: auto;
`;

export const Header = styled("div")`
  display: flex;

  font-weight: bold;
  text-align: center;
`;

export const ColumnHeader = styled("div")`
  flex: 1;
  padding: 8px;
`;

export const Column = styled("div")`
  flex: 1;
  padding: 8px;
  text-align: center;
`;

export const Body = styled("div")`
  display: flex;
  flex-direction: column;
`;

export const Row = styled("div")`
  display: flex;
`;

export const HasBeenEvaluated = styled("span")`
  font-size: 12px;
  font-weight: 500;
  color: #697482;
`;

export const ArrowIcon = styled("div")`
  svg {
    transition: transform 0.3s ease-in-out;
  }

  &.active {
    svg {
      transform: rotate(180deg);
    }
  }
`;
