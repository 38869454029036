import {
  GetAllRoadmaps,
  GET_ALL_ROADMAPS,
  GetRoadmap,
  GET_ROADMAP,
  UpdateRoadmap,
  UPDATE_ROADMAP,
  DeleteRoadmap,
  DELETE_ROADMAP,
  GetRoadmapSuccess,
  GET_ROADMAP_SUCCESS,
  NewRoadmap,
  NEW_ROADMAP,
  ResetNewRoadmap,
  RESET_NEW_ROADMAP,
  ResetUpdateRoadmap,
  RESET_UPDATE_ROADMAP,
  ResetDeleteRoadmap,
  RESET_DELETE_ROADMAP,
  UploadMedia,
  UPLOAD_MEDIA,
  RESET_GET_ROADMAP,
  ADD_USER_TO_ROADMAP,
  SHARED_ROADMAP,
  SET_GET_ROADMAP,
  RESET_UPDATE_MANY_ROADMAP,
  UPDATE_MANY_ROADMAP,
  ResetGetAllRoadmapData,
  RESET_GET_ALL_ROADMAP_DATA,
} from "../types/roadmap";

export const getAllRoadmaps = (payload): GetAllRoadmaps => {
  return { type: GET_ALL_ROADMAPS, payload };
};

export const resetGetAllRoadmaps = (): ResetGetAllRoadmapData => {
  return { type: RESET_GET_ALL_ROADMAP_DATA, payload: null };
};

export const getRoadmap = (id): GetRoadmap => {
  return { type: GET_ROADMAP, payload: id };
};

export const resetGetRoadmap = () => {
  return { type: RESET_GET_ROADMAP, payload: null };
};

export const setSelectedRoadmap = (payload): GetRoadmapSuccess => {
  return { type: GET_ROADMAP_SUCCESS, payload };
};

export const newRoadmap = (payload): NewRoadmap => {
  return { type: NEW_ROADMAP, payload };
};
export const uploadMediaRoadmap = (payload): UploadMedia => {
  return { type: UPLOAD_MEDIA, payload };
};
export const resetNewRoadmap = (): ResetNewRoadmap => {
  return { type: RESET_NEW_ROADMAP, payload: null };
};

export const updateManyRoadmap = (payload) => {
  return { type: UPDATE_MANY_ROADMAP, payload };
};

export const resetUpdateManyRoadmap = () => {
  return { type: RESET_UPDATE_MANY_ROADMAP, payload: null };
};
export const updateRoadmap = (payload): UpdateRoadmap => {
  return { type: UPDATE_ROADMAP, payload };
};

export const resetUpdateRoadmap = (): ResetUpdateRoadmap => {
  return { type: RESET_UPDATE_ROADMAP, payload: null };
};

export const addUserToRoadmap = (payload) => {
  return { type: ADD_USER_TO_ROADMAP, payload };
};

export const deleteRoadmap = (payload): DeleteRoadmap => {
  return { type: DELETE_ROADMAP, payload };
};

export const setGetRoadmap = (payload) => {
  return { type: SET_GET_ROADMAP, payload };
};

export const resetDeleteRoadmap = (): ResetDeleteRoadmap => {
  return { type: RESET_DELETE_ROADMAP, payload: null };
};

export const shareRoadmap = (payload) => {
  return { type: SHARED_ROADMAP, payload };
};
