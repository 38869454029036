import * as Yup from "yup";

export const schema = Yup.object().shape({
  cohorts: Yup.array().of(Yup.string()),

  name: Yup.string()
    .required("Se requiere nombre")
    .min(3, "Minimo 3 caracteres"),
});

interface FormValues {
  cohorts: any[];
  name: string;
}

export const initialValues: FormValues = {
  cohorts: [],
  name: "",
};
