import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Modal from "../../../../../../components/Modal";
import { Formik } from "formik";
import { Center } from "../../../../../../components/StyledComponents";
import {
  initialValues,
  schema,
} from "../../../../../../constants/form/organization/profile/data/edit";
import Grid from "../../../../../../components/Grid";
import Button from "../../../../../../components/Button";
import Submit from "../../../../../../components/Form/Submit";
import { toast } from "react-toastify";
import Input from "../../../../../../components/Form/Input";
import academyActions from "../../../../../../store/actions/academies";
import { hideModal } from "../../../../../../store/actions/modal";
import { AcademyDto } from "../../../../../../types/academy.dto";
import services from "../../../../../../services";
import Loader from "../../../../../../components/Loader";

const Component = ({
  selectedAcademy,
  updateAcademyStates,
  academies,
  current,
}) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();

  const [currentAcademy, setCurrentAcademy] = useState<AcademyDto>();
  const dispatch = useDispatch();
  const onSubmit = ({ values, actions }) => {
    dispatch(academyActions.update({ _id: current.id, ...values }));
    setFormActions(actions);
  };
  useEffect(() => {
    if (updateAcademyStates.success) {
      toast("👌🏼 Academia modificada", {
        containerId: "submit",
        type: "success",
        toastId: "submitsucceful",
      });

      setTimeout(() => {
        dispatch(academyActions.resetUpdate());
        dispatch(hideModal());
        formActions?.resetForm();
      }, 1000);
    }

    if (updateAcademyStates.error) {
      toast(`😱 ${updateAcademyStates.error}`, {
        containerId: "submit",
        type: "error",
        toastId: "submiterror",
      });

      setTimeout(() => {
        dispatch(academyActions.resetUpdate());
      }, 1000);
    }
  }, [updateAcademyStates]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response: any = await services.academy.getOne({
          _id: current.id,
        });
        setCurrentAcademy(response.response);
      } catch (err) {
        setCurrentAcademy(undefined);
      }
    };
    fetchData();
  }, []);

  return (
    <Modal name="edit-academy-data" title="Datos de Contacto">
      {currentAcademy ? (
        <Formik
          initialValues={{
            ...initialValues(
              !!currentAcademy && {
                phone: currentAcademy.phone,
                email: currentAcademy.email,
                location: currentAcademy.location,
                linkedin: currentAcademy.linkedin,
                github: currentAcademy.github,
                website: currentAcademy.website,
              }
            ),
          }}
          onSubmit={(values, actions) => {
            if (onSubmit) onSubmit({ values, actions });
          }}
          validateOnChange={formSubmmited}
          validateOnBlur={false}
          validationSchema={schema}
          enableReinitialize
        >
          {({
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
            setFieldValue,
            isSubmitting,
          }) => {
            return (
              <form
                className="theme-form"
                onSubmit={(event) => {
                  setFormSubmmited(true);
                  handleSubmit(event);
                }}
                id="edit-academy-data-form"
              >
                <Grid.Row>
                  <Grid.Col>
                    <Input
                      name={"phone"}
                      error={errors["phone"]}
                      touched={touched["phone"]}
                      value={values["phone"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={{ label: "Teléfono", skin: "gray" }}
                    />
                    <Input
                      name={"email"}
                      error={errors["email"]}
                      touched={touched["email"]}
                      value={values["email"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={{ label: "E-mail", skin: "gray" }}
                    />
                    <Input
                      name={"location"}
                      error={errors["location"]}
                      touched={touched["location"]}
                      value={values["location"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={{
                        label: "Ubicación",
                        skin: "gray",
                      }}
                    />
                    <Input
                      name={"linkedin"}
                      error={errors["linkedin"]}
                      touched={touched["linkedin"]}
                      value={values["linkedin"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={{ label: "Linkedin", skin: "gray" }}
                    />
                    <Input
                      name={"github"}
                      error={errors["github"]}
                      touched={touched["github"]}
                      value={values["github"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={{
                        label: "GitHub",
                        skin: "gray",
                      }}
                    />
                    <Input
                      name={"website"}
                      error={errors["website"]}
                      touched={touched["website"]}
                      value={values["website"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={{
                        label: "WebSite",
                        skin: "gray",
                      }}
                    />
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col>
                    <Center gap={"70px"}>
                      <Button
                        onClick={resetForm}
                        type="button"
                        options={{
                          type: "outline",
                          skin: "gray",
                          size: "lg",
                          marginBottom: "0px",
                        }}
                        style={{ marginLeft: "10px" }}
                      >
                        Cancelar
                      </Button>

                      <Submit
                        isSubmmiting={isSubmitting}
                        form="edit-academy-data-form"
                        color="Primary"
                        options={{
                          type: "filled",
                          skin: "primary",
                          size: "lg",
                          marginBottom: "0px",
                        }}
                      >
                        Confirmar
                      </Submit>
                    </Center>
                  </Grid.Col>
                </Grid.Row>
              </form>
            );
          }}
        </Formik>
      ) : (
        <Loader color="Primary"></Loader>
      )}
    </Modal>
  );
};

const states = ({ academyStore, currentStore }) => {
  const { data: selectedAcademy } = academyStore.selected;
  const { states: updateAcademyStates } = academyStore.update;
  const { data: current } = currentStore;
  const { data: academies } = academyStore.all;
  return {
    selectedAcademy,
    updateAcademyStates,
    current,
    academies,
  };
};

export default connect(states)(Component);
