import React from "react";

const Component = ({ onChange, checked }) => {
  return (
    <input
      onClick={(ev) => {
        ev.stopPropagation();
      }}
      style={{ height: "17px", width: "17px" }}
      onChange={onChange}
      type="checkbox"
      checked={checked}
    />
  );
};

export default Component;
