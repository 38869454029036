import { CriterionDto } from "../../types/criterion.dto";

export const GET_ALL_CRITERIONS = "GET_ALL_CRITERIONS";
export const GET_ALL_CRITERIONS_ERROR = "GET_ALL_CRITERIONS_ERROR";
export const GET_ALL_CRITERIONS_SUCCESS = "GET_ALL_CRITERIONS_SUCCESS";
export const RESET_GET_ALL_CRITERIONS = "RESET_GET_ALL_CRITERIONS";
export const GET_CRITERION = "GET_CRITERION";
export const GET_CRITERION_ERROR = "GET_CRITERION_ERROR";
export const GET_CRITERION_SUCCESS = "GET_CRITERION_SUCCESS";
export const NEW_CRITERION = "NEW_CRITERION";
export const NEW_CRITERION_ERROR = "NEW_CRITERION_ERROR";
export const NEW_CRITERION_SUCCESS = "NEW_CRITERION_SUCCESS";
export const RESET_NEW_CRITERION = "RESET_NEW_CRITERION";
export const UPDATE_CRITERION = "UPDATE_CRITERION";
export const UPDATE_CRITERION_ERROR = "UPDATE_CRITERION_ERROR";
export const UPDATE_CRITERION_SUCCESS = "UPDATE_CRITERION_SUCCESS";
export const RESET_UPDATE_CRITERION = "RESET_UPDATE_CRITERION";
export const DELETE_CRITERION = "DELETE_CRITERION";
export const DELETE_CRITERION_ERROR = "DELETE_CRITERION_ERROR";
export const DELETE_CRITERION_SUCCESS = "DELETE_CRITERION_SUCCESS";
export const RESET_DELETE_CRITERION = "RESET_DELETE_CRITERION";

export interface GetAllCriterions {
  type: typeof GET_ALL_CRITERIONS;
  payload: null;
}

export interface GetAllCriterionsSuccess {
  type: typeof GET_ALL_CRITERIONS_SUCCESS;
  payload: CriterionDto[];
}

export interface GetAllCriterionsError {
  type: typeof GET_ALL_CRITERIONS_ERROR;
  payload: boolean | string;
}

export interface ResetGetAllCriterions {
  type: typeof RESET_GET_ALL_CRITERIONS;
  payload: null;
}

export interface GetCriterion {
  type: typeof GET_CRITERION;
  payload: { id: string };
}

export interface GetCriterionSuccess {
  type: typeof GET_CRITERION_SUCCESS;
  payload: CriterionDto;
}

export interface GetCriterionError {
  type: typeof GET_CRITERION_ERROR;
  payload: boolean | string;
}

export interface NewCriterion {
  type: typeof NEW_CRITERION;
  payload: CriterionDto;
}

export interface NewCriterionSuccess {
  type: typeof NEW_CRITERION_SUCCESS;
  payload: CriterionDto;
}

export interface NewCriterionError {
  type: typeof NEW_CRITERION_ERROR;
  payload: boolean | string;
}

export interface ResetNewCriterion {
  type: typeof RESET_NEW_CRITERION;
  payload: null;
}

export interface UpdateCriterion {
  type: typeof UPDATE_CRITERION;
  payload: {
    type: string;
    bootcamps: string[];
    criterion: string;
    name: string;
    subCriterions: any[];
  };
}

export interface UpdateCriterionSuccess {
  type: typeof UPDATE_CRITERION_SUCCESS;
  payload: null;
}

export interface UpdateCriterionError {
  type: typeof UPDATE_CRITERION_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateCriterion {
  type: typeof RESET_UPDATE_CRITERION;
  payload: null;
}

export interface DeleteCriterion {
  type: typeof DELETE_CRITERION;
  payload: { id: string };
}

export interface DeleteCriterionSuccess {
  type: typeof DELETE_CRITERION_SUCCESS;
  payload: null;
}

export interface DeleteCriterionError {
  type: typeof DELETE_CRITERION_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteCriterion {
  type: typeof RESET_DELETE_CRITERION;
  payload: null;
}

export interface State {
  allCriterions: {
    data: CriterionDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  criterion: {
    data: CriterionDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  newCriterion: {
    data: null | CriterionDto;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  deleteCriterion: {
    data: null | CriterionDto;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  updateCriterion: {
    data: null | CriterionDto;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  allCriterions: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  criterion: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  newCriterion: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  deleteCriterion: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  updateCriterion: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllCriterions
  | GetAllCriterionsSuccess
  | GetAllCriterionsError
  | GetCriterion
  | GetCriterionSuccess
  | GetCriterionError
  | NewCriterion
  | NewCriterionSuccess
  | NewCriterionError
  | ResetNewCriterion
  | UpdateCriterion
  | UpdateCriterionSuccess
  | UpdateCriterionError
  | ResetUpdateCriterion
  | DeleteCriterion
  | DeleteCriterionSuccess
  | DeleteCriterionError
  | ResetDeleteCriterion
  | ResetGetAllCriterions;
