import { ListenDto } from "../../types/listen.dto";
import { PaginationDto } from "../../types/pagination.dto";

export const GET_ALL_LISTENS = "GET_ALL_LISTENS";
export const GET_ALL_LISTENS_ERROR = "GET_ALL_LISTENS_ERROR";
export const GET_ALL_LISTENS_SUCCESS = "GET_ALL_LISTENS_SUCCESS";
export const RESET_GET_ALL_LISTEN_DATA = "RESET_GET_ALL_LISTEN_DATA";
export const GET_LISTEN = "GET_LISTEN";
export const GET_LISTEN_ERROR = "GET_LISTEN_ERROR";
export const GET_LISTEN_SUCCESS = "GET_LISTEN_SUCCESS";
export const RESET_GET_LISTEN = "RESET_GET_LISTEN";
export const SET_GET_LISTEN = "SET_GET_LISTEN";
export const NEW_LISTEN = "NEW_LISTEN";
export const NEW_LISTEN_ERROR = "NEW_LISTEN_ERROR";
export const NEW_LISTEN_SUCCESS = "NEW_LISTEN_SUCCESS";
export const RESET_NEW_LISTEN = "RESET_NEW_LISTEN";
export const UPDATE_LISTEN = "UPDATE_LISTEN";
export const UPDATE_LISTEN_ERROR = "UPDATE_LISTEN_ERROR";
export const UPDATE_LISTEN_SUCCESS = "UPDATE_LISTEN_SUCCESS";
export const RESET_UPDATE_LISTEN = "RESET_UPDATE_LISTEN";
export const DELETE_LISTEN = "DELETE_LISTEN";
export const DELETE_LISTEN_ERROR = "DELETE_LISTEN_ERROR";
export const DELETE_LISTEN_SUCCESS = "DELETE_LISTEN_SUCCESS";
export const RESET_DELETE_LISTEN = "RESET_DELETE_LISTEN";
export const UPLOAD_MEDIA = "UPLOAD_MEDIA";
export const UPDATE_LISTEN_LOADING = "UPDATE_LISTEN_LOADING";
export const ADD_USER_TO_LISTEN = "ADD_USER_TO_LISTEN";
export const SHARED_LISTEN = "SHARED_LISTEN";
export const UPDATE_MANY_LISTEN = "UPDATE_MANY_LISTEN";
export const UPDATE_MANY_LISTEN_ERROR = "UPDATE_MANY_LISTEN_ERROR";
export const UPDATE_MANY_LISTEN_SUCCESS = "UPDATE_MANY_LISTEN_SUCCESS";
export const RESET_UPDATE_MANY_LISTEN = "RESET_UPDATE_MANY_LISTEN";

export interface SharedListen {
  type: typeof SHARED_LISTEN;
  payload: any;
}

export interface UpdateManyListen {
  type: typeof UPDATE_MANY_LISTEN;
  payload: any;
}

export interface UpdateManyListenSuccess {
  type: typeof UPDATE_MANY_LISTEN_SUCCESS;
  payload: ListenDto[];
}

export interface UpdateManyListenError {
  type: typeof UPDATE_MANY_LISTEN_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateManyListen {
  type: typeof RESET_UPDATE_MANY_LISTEN;
  payload: null;
}

export interface GetAllListens {
  type: typeof GET_ALL_LISTENS;
  payload: PaginationDto;
}

export interface GetAllListensSuccess {
  type: typeof GET_ALL_LISTENS_SUCCESS;
  payload: ListenDto[];
}

export interface ResetGetAllListenData {
  type: typeof RESET_GET_ALL_LISTEN_DATA;
  payload: null;
}

export interface SetGetListen {
  type: typeof SET_GET_LISTEN;
  payload: ListenDto | null;
}

export interface GetAllListensError {
  type: typeof GET_ALL_LISTENS_ERROR;
  payload: boolean | string;
}

export interface AddUserToListen {
  type: typeof ADD_USER_TO_LISTEN;
  payload: any;
}

export interface GetListen {
  type: typeof GET_LISTEN;
  payload: { id: string };
}

export interface GetListenSuccess {
  type: typeof GET_LISTEN_SUCCESS;
  payload: ListenDto;
}

export interface GetListenError {
  type: typeof GET_LISTEN_ERROR;
  payload: boolean | string;
}

export interface NewListen {
  type: typeof NEW_LISTEN;
  payload: ListenDto;
}

export interface NewListenSuccess {
  type: typeof NEW_LISTEN_SUCCESS;
  payload: ListenDto;
}

export interface ResetGetListen {
  type: typeof RESET_GET_LISTEN;
  payload: null;
}

export interface NewListenError {
  type: typeof NEW_LISTEN_ERROR;
  payload: boolean | string;
}

export interface UploadMedia {
  type: typeof UPLOAD_MEDIA;
  payload: any;
}

export interface ResetNewListen {
  type: typeof RESET_NEW_LISTEN;
  payload: null;
}

export interface UpdateListen {
  type: typeof UPDATE_LISTEN;
  payload: any;
}

export interface UpdateListenLoading {
  type: typeof UPDATE_LISTEN_LOADING;
  payload: ListenDto;
}

export interface UpdateListenSuccess {
  type: typeof UPDATE_LISTEN_SUCCESS;
  payload: ListenDto;
}

export interface UpdateListenError {
  type: typeof UPDATE_LISTEN_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateListen {
  type: typeof RESET_UPDATE_LISTEN;
  payload: null;
}

export interface DeleteListen {
  type: typeof DELETE_LISTEN;
  payload: { _id: string };
}

export interface DeleteListenSuccess {
  type: typeof DELETE_LISTEN_SUCCESS;
  payload: null;
}

export interface DeleteListenError {
  type: typeof DELETE_LISTEN_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteListen {
  type: typeof RESET_DELETE_LISTEN;
  payload: null;
}

export interface State {
  all: {
    data: ListenDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  updateMany: {
    data: null | ListenDto[];
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  listen: {
    data: ListenDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  new: {
    data: ListenDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  delete: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  update: {
    data: null | ListenDto;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  all: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  listen: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  updateMany: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  new: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  delete: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  update: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllListens
  | GetAllListensSuccess
  | GetAllListensError
  | ResetGetAllListenData
  | GetListen
  | GetListenSuccess
  | GetListenError
  | NewListen
  | NewListenSuccess
  | NewListenError
  | ResetNewListen
  | UpdateListen
  | UpdateListenSuccess
  | UpdateListenError
  | ResetUpdateListen
  | DeleteListen
  | DeleteListenSuccess
  | DeleteListenError
  | ResetDeleteListen
  | UploadMedia
  | UpdateListenLoading
  | ResetGetListen
  | AddUserToListen
  | SharedListen
  | SetGetListen
  | UpdateManyListen
  | UpdateManyListenSuccess
  | UpdateManyListenError
  | ResetUpdateManyListen;
