// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 80% !important;
  height: 100% !important;
}

.react-pdf__Document {
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/SlideShow/Creator/style.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,qBAAqB;EACrB,uBAAuB;AACzB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".react-pdf__Page__canvas {\n  margin: 0 auto;\n  width: 80% !important;\n  height: 100% !important;\n}\n\n.react-pdf__Document {\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
