import { Formik } from "formik";
import { useEffect, useState } from "react";
import ReactSelect from "../../../../components/Form/ReactSelect/index";
import Input from "../../../../components/Form/Input/index";
import Grid from "../../../../components/Grid";
import { PageTitle } from "../../../../components/StyledComponents";
import { analyticsBootcamp } from "../../../../constants/analytics-select";

import { IndividualCard } from "../../../../components/LineGraph/styles";
import { connect, useDispatch } from "react-redux";
import {
  getCohortStats,
  resetGetCohortStats,
} from "../../../../store/actions/analytics";
import {
  getAllCohorts,
  resetGetAllCohortsData,
} from "../../../../store/actions/cohort";
import { toast } from "react-toastify";
import Status from "./components/Status/index";
import SprintStats from "./components/SprintStats/index";
import Growing from "./components/Growing/index";
import StatusProm from "./components/StatusProm/index";
import { CohortDto } from "../../../../types/cohort.dto";
import FilterByName from "./components/FilterByName";
import { filterCohortByRole } from "../../../../helpers/roleAcademy";
import { StateDto } from "../../../../types/states.dto";
import { AnalyticsCohortDto } from "../../../../types/analytics.dto";
import { CurrentDto } from "../../../../types/current.dto";
import { UserDto } from "../../../../types/user.dto";
import { AcademyDto } from "../../../../types/academy.dto";
import academyActions from "../../../../store/actions/academies";

const Component = ({
  cohortStates,
  cohorts,
  analyticsState,
  cohortStats,
  current,
  user,
  academies,
}: {
  cohortStates: StateDto;
  cohorts: CohortDto[];
  analyticsState: StateDto;
  cohortStats: AnalyticsCohortDto;
  current: CurrentDto;
  user: UserDto;
  academies: AcademyDto[];
}) => {
  const [paginationOn, setPaginationOn] = useState<boolean>(false);
  const [selectedCohort, setSelectedCohort] = useState<string>();
  const [selectedCohortId, setSelectedCohortId] = useState<{
    name: string;
    id: string;
    cohortStudents?: number;
  }>();
  const [chartSelected, setChartSelected] = useState<{
    label: string;
    value: string | number;
  }>();
  const [currentCohortList, setCurrentCohortList] = useState<CohortDto[]>([]);

  const [applyedFilters, setFilters] = useState<
    { field: string; value: string }[]
  >([]);

  const [currentCohortListValues, setCurrentCohortListValues] = useState<
    CohortDto[]
  >([]);
  const dispatch = useDispatch();

  const buildFilters = (newFilter) => {
    const filterUpdated =
      applyedFilters.filter(
        (applyedFilter) => applyedFilter.field === newFilter.field
      ).length > 0;

    const filterRemoved = newFilter.value === "no";

    /**
     * Se elimina el filtro
     */
    if (filterRemoved) {
      setFilters([
        ...applyedFilters.filter(
          (applyedFilter) => applyedFilter.field !== newFilter.field
        ),
      ]);
    }

    /**
     * Se actualiza un filtro
     */
    if (filterUpdated && !filterRemoved) {
      setFilters([
        ...applyedFilters.map((applyedFilter) => {
          if (applyedFilter.field === newFilter.field) {
            return newFilter;
          } else {
            return applyedFilter;
          }
        }),
      ]);
    }

    /**
     * Se agrega un filtro
     */
    if (!filterUpdated && !filterRemoved) {
      setFilters([...applyedFilters, newFilter]);
    }
  };

  const payloadCohort = {
    filterBy: {
      active: true,
      ...(current.currentSection !== "personal" && { academy: current.id }),
      hidden: false,
    },
  };

  useEffect(() => {
    !cohorts && paginationOn && dispatch(getAllCohorts(payloadCohort));
  }, [cohorts, paginationOn]);

  useEffect(() => {
    if (!academies && paginationOn && current.currentSection === "personal") {
      dispatch(
        academyActions.getAll({
          filterBy: {
            active: true,
            private: false,
          },
        })
      );
    }
  }, [academies, paginationOn]);

  useEffect(() => {
    if (analyticsState.success) {
      toast.success("👌🏼 Bootcamp encontrado");
      setSelectedCohort(selectedCohortId?.name);
      setTimeout(() => {
        dispatch(resetGetCohortStats());
      }, 1000);
    }

    if (analyticsState.error) {
      toast.error(analyticsState.error);
      setTimeout(() => {
        dispatch(resetGetCohortStats());
      }, 2000);
      setSelectedCohort(undefined);
    }
  }, [analyticsState]);

  useEffect(() => {
    if (!!cohorts && !!paginationOn) {
      const filterCohorts =
        current.currentSection === "academy"
          ? filterCohortByRole(cohorts, user._id, current.role)
          : cohorts;

      setCurrentCohortList(filterCohorts);
    }
  }, [cohorts, current]);

  useEffect(() => {
    const filteredCohorts = currentCohortList?.filter((currentTalentValues) => {
      const thereAreFilters = applyedFilters.length > 0;
      const totalOfFilters = applyedFilters.length;
      const passFilters = applyedFilters.filter(
        (applyedFilter) =>
          currentTalentValues[applyedFilter.field] === applyedFilter.value
      ).length;

      return (
        !thereAreFilters || (thereAreFilters && totalOfFilters === passFilters)
      );
    });
    setCurrentCohortListValues(filteredCohorts);
  }, [applyedFilters, currentCohortList]);

  useEffect(() => {
    if (!cohorts && !academies) {
      setPaginationOn(true);
    }
  }, [cohorts, academies]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllCohortsData());
      dispatch(academyActions.resetGetAllAcademiesData());
    };
  }, []);

  return (
    <div style={{ minHeight: "500px" }}>
      <PageTitle>Analytics Cohorts</PageTitle>
      <Grid.Row>
        <Grid.Col>
          <Formik
            initialValues={{}}
            onSubmit={(values, actions) => {}}
            enableReinitialize
          >
            {({
              handleSubmit,
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
            }) => {
              return (
                <form
                  onSubmit={(event) => {
                    handleSubmit(event);
                  }}
                  id="userid-a-form"
                >
                  <Grid.Row>
                    {current.currentSection === "personal" && (
                      <Grid.Col sm={12} md={4}>
                        <ReactSelect
                          name="academy"
                          error={errors["academy"]}
                          touched={touched["academy"]}
                          {...(academies && {
                            items: academies.map((academy, index) => {
                              return {
                                value: academy._id,
                                label: academy.name,
                              };
                            }),
                          })}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          notLoading={true}
                          options={{
                            loading:
                              current.currentSection === "personal" &&
                              !academies,
                            label: "Academia",
                            marginBottom: 0,
                          }}
                        ></ReactSelect>
                      </Grid.Col>
                    )}
                    <Grid.Col md={4} sm={12}>
                      <FilterByName
                        values={values}
                        cohorts={currentCohortList}
                        setSelectedBootcamp={setSelectedCohortId}
                        applyedFilters={applyedFilters}
                        setFilter={buildFilters}
                        placeholder={"Buscar nombre del cohort"}
                      />
                    </Grid.Col>
                    <Grid.Col sm={12} md={4}>
                      <ReactSelect
                        name="chart"
                        error={errors["chart"]}
                        touched={touched["chart"]}
                        {...(!!cohortStats && {
                          items: [
                            ...analyticsBootcamp,
                            ...cohortStats?.stagesNotes?.map((stage, index) => {
                              return {
                                value: stage.stage,
                                label: `Etapa ${stage.stage}`,
                              };
                            }),
                          ],
                        })}
                        disabled={
                          !cohortStats ||
                          !selectedCohortId ||
                          (current.currentSection === "personal" &&
                            !values["academy"])
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onOptionSelected={(newValue) =>
                          setChartSelected(newValue)
                        }
                        notLoading={true}
                        options={{
                          loading: false,
                          label: "Tablero",
                          marginBottom: 0,
                        }}
                      ></ReactSelect>
                    </Grid.Col>
                  </Grid.Row>
                </form>
              );
            }}
          </Formik>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row style={{ marginTop: "40px" }}>
        <Grid.Col>
          {cohortStats && selectedCohort && chartSelected && (
            <IndividualCard>
              {chartSelected.value === "status" && (
                <Status
                  analytics={cohortStats}
                  selectedCohort={selectedCohortId}
                ></Status>
              )}
              {cohortStats?.stagesNotes.map((stage, index) => {
                return chartSelected.value === stage.stage ? (
                  <SprintStats
                    stage={stage.stage}
                    stageLabel={`Etapa ${stage.stage}`}
                    selectedCohort={selectedCohortId}
                  ></SprintStats>
                ) : null;
              })}

              {chartSelected.value === "crecimiento" && (
                <Growing
                  analytics={cohortStats}
                  selectedCohort={selectedCohortId}
                ></Growing>
              )}
            </IndividualCard>
          )}
        </Grid.Col>
      </Grid.Row>
    </div>
  );
};

const states = ({
  cohortStore,
  analyticsStore,
  currentStore,
  userStore,
  academyStore,
}) => {
  const { states: cohortStates, data: cohorts } = cohortStore.allCohorts;
  const { states: analyticsState, data: cohortStats } =
    analyticsStore.getCohortStats;
  const { data: current } = currentStore;
  const { data: user } = userStore;
  const { data: academies } = academyStore.all;
  return {
    cohortStates,
    cohortStats,
    cohorts,
    analyticsState,
    current,
    user,
    academies,
  };
};

export default connect(states)(Component);
