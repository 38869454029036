import { EvaluateDto } from "../../types/evaluate.dto";

export const NEW_EVALUATE = "NEW_EVALUATE";
export const NEW_EVALUATE_SUCCESS = "NEW_EVALUATE_SUCCESS";
export const NEW_EVALUATE_ERROR = "NEW_EVALUATE_ERROR";
export const NEW_MANY_EVALUATE = "NEW_MANY_EVALUATE";
export const NEW_MANY_EVALUATE_SUCCESS = "NEW_MANY_EVALUATE_SUCCESS";
export const NEW_MANY_EVALUATE_ERROR = "NEW_MANY_EVALUATE_ERROR";
export const RESET_NEW_MANY_EVALUATE = "RESET_NEW_MANY_EVALUATE";
export const RESET_NEW_EVALUATE = "RESET_NEW_EVALUATE";
export const GET_ALL_EVALUATE = "GET_ALL_EVALUATE";
export const GET_ALL_EVALUATE_SUCCESS = "GET_ALL_EVALUATE_SUCCESS";
export const GET_ALL_EVALUATE_ERRROR = "GET_ALL_EVALUATE_ERRROR";
export const RESET_GET_ALL_EVALUATE = "RESET_GET_ALL_EVALUATE";
export const SET_EVALUATE_LIST = "SET_EVALUATE_LIST";
export const UPDATE_EVALUATE = "UPDATE_EVALUATE";
export const UPDATE_EVALUATE_SUCCESS = "UPDATE_EVALUATE_SUCCESS";
export const UPDATE_EVALUATE_ERROR = "UPDATE_EVALUATE_ERROR";
export const RESET_UPDATE_EVALUATE = "RESET_UPDATE_EVALUATE";
export const initialValues: State = {
  newEvaluate: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  newManyEvaluate: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  all: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  update: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export interface NewEvaluate {
  type: typeof NEW_EVALUATE;
  payload: EvaluateDto;
}

export interface NewEvaluateSuccess {
  type: typeof NEW_EVALUATE_SUCCESS;
  payload: EvaluateDto;
}

export interface NewEvaluateError {
  type: typeof NEW_EVALUATE_ERROR;
  payload: boolean | string;
}

export interface NewManyEvaluate {
  type: typeof NEW_MANY_EVALUATE;
  payload: any;
}
export interface NewManyEvaluateSuccess {
  type: typeof NEW_MANY_EVALUATE_SUCCESS;
  payload: EvaluateDto[];
}
export interface NewManyEvaluateError {
  type: typeof NEW_MANY_EVALUATE_ERROR;
  payload: string | string;
}
export interface ResetNewManyEvaluate {
  type: typeof RESET_NEW_MANY_EVALUATE;
  payload: null;
}

export interface UpdateEvaluate {
  type: typeof UPDATE_EVALUATE;
  payload: any;
}
export interface UpdateEvaluateSuccess {
  type: typeof UPDATE_EVALUATE_SUCCESS;
  payload: any;
}
export interface UpdateEvaluateError {
  type: typeof UPDATE_EVALUATE_ERROR;
  payload: string | boolean;
}
export interface ResetUpdateEvaluate {
  type: typeof RESET_UPDATE_EVALUATE;
  payload: null;
}

export interface SetEvaluateList {
  type: typeof SET_EVALUATE_LIST;
  payload: EvaluateDto[];
}

export interface ResetNewEvaluate {
  type: typeof RESET_NEW_EVALUATE;
  payload: null;
}

export interface GetAllEvaluates {
  type: typeof GET_ALL_EVALUATE;
  payload: null;
}

export interface GetAllEvaluatesError {
  type: typeof GET_ALL_EVALUATE_ERRROR;
  payload: string | boolean;
}
export interface GetAllEvaluatesSuccess {
  type: typeof GET_ALL_EVALUATE_SUCCESS;
  payload: EvaluateDto[];
}

export interface ResetGetAllEvaluate {
  type: typeof RESET_GET_ALL_EVALUATE;
  payload: null;
}

export interface State {
  newEvaluate: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  all: {
    data: null | EvaluateDto[];
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  newManyEvaluate: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
    data: null | EvaluateDto[];
  };
  update: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export type Actions =
  | NewEvaluate
  | NewEvaluateSuccess
  | NewEvaluateError
  | ResetNewEvaluate
  | GetAllEvaluates
  | GetAllEvaluatesError
  | GetAllEvaluatesSuccess
  | ResetGetAllEvaluate
  | SetEvaluateList
  | UpdateEvaluate
  | UpdateEvaluateSuccess
  | UpdateEvaluateError
  | ResetUpdateEvaluate
  | NewManyEvaluate
  | NewManyEvaluateSuccess
  | NewManyEvaluateError
  | ResetNewManyEvaluate;
