import { Outlet, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Layout,
  Content,
  Sidebar,
  SidebarHeader,
  SidebarBody,
  SidebarToggle,
} from "./styles";
import Profile from "../../Profile";
import Header from "../../Header";
import Menu from "../../Menu";
import HambugerMenuImage from "../../../assets/icons/menu-burger-remix.svg";
import { useDashboardLayout } from "../../../provider/DashboardProvider";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { FaBell, FaUniversity, FaGraduationCap, FaPlay } from "react-icons/fa";
import { HiDocumentText } from "react-icons/hi";
import { RiOrganizationChart } from "react-icons/ri";
import { MdOutlineClose, MdPermMedia } from "react-icons/md";
import SideBar from "./components/SideBar";
import { showModal } from "../../../store/actions/modal";
import { useDispatch } from "react-redux";
import { UserDto } from "../../../types/user.dto";
import moment from "moment";
import { CurrentDto } from "../../../types/current.dto";
import ProfileMobile from "../../ProfileMobile";
import { useWindowSize } from "../../../hooks/useWindowScreen";
import MobileContentModal from "../../MobileContentModal";
import NotificationList from "../../Header/components/NotificationList";
import { allowModals } from "../../../constants/allowModals";
import { GiHamburgerMenu } from "react-icons/gi";

interface ComponentProps {
  modal: string | null;
  user: UserDto;
  current: CurrentDto;
}

const Component = ({ modal, user, current }: ComponentProps) => {
  const { isMobile } = useWindowSize();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { dashboardStyles, sidebarCollapsed, setSidebarCollapsed } =
    useDashboardLayout();
  const [openDropDown, setOpenDropDown] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const menu = [
    {
      Icon: FaPlay,
      toolTipName: "Crear Video",
      onAvailable: () => true,
      onClick: () => {
        navigate("/dashboard/contents/video/management");
      },
    },
    {
      Icon: HiDocumentText,
      toolTipName: "Crear Cdoc",
      onAvailable: () => true,
      onClick: () => {
        navigate("/dashboard/contents/cdocs/management");
      },
    },
    {
      Icon: RiOrganizationChart,
      toolTipName: "Crear Trayecto",
      onAvailable: () => true,
      onClick: () => {
        navigate("/dashboard/contents/roadmaps/management");
      },
    },
    {
      Icon: MdPermMedia,
      toolTipName: "Crear Contenido Vario",
      onAvailable: () => true,
      onClick: () => {
        navigate("/dashboard/contents/variety/management");
      },
    },
    {
      Icon: FaGraduationCap,
      toolTipName: "Crear Cohort",
      onAvailable: () => true,
      onClick: () => {
        navigate("/dashboard/cohorts/list");
        dispatch(showModal("new-cohort-modal"));
      },
    },
  ];

  return (
    <>
      <Layout isMobile={isMobile} sidebarCollapsed={sidebarCollapsed}>
        <Sidebar
          isMobile={isMobile}
          sidebarCollapsed={sidebarCollapsed}
          style={dashboardStyles.sidebar}
          modal={modal && !allowModals.includes(modal)}
        >
          {isMobile ? (
            <ProfileMobile
              onClick={() => {
                !!setSidebarCollapsed && setSidebarCollapsed((state) => !state);
              }}
              sidebarCollapsed={sidebarCollapsed}
            ></ProfileMobile>
          ) : (
            <SidebarHeader sidebarCollapsed={sidebarCollapsed}>
              <Profile sidebarCollapsed={sidebarCollapsed} />
              <SidebarToggle
                sidebarCollapsed={sidebarCollapsed}
                onClick={() => {
                  !!setSidebarCollapsed &&
                    setSidebarCollapsed((state) => !state);
                }}
              >
                {sidebarCollapsed ? (
                  <GiHamburgerMenu size={23} color="#001E6C"></GiHamburgerMenu>
                ) : (
                  <MdOutlineClose size={23} color="#001E6C" />
                )}
              </SidebarToggle>
            </SidebarHeader>
          )}

          <SidebarBody sidebarCollapsed={sidebarCollapsed}>
            <Menu sidebarCollapsed={sidebarCollapsed} />
          </SidebarBody>
        </Sidebar>

        <Header
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          setSidebarCollapsed={setSidebarCollapsed}
          sidebarCollapsed={sidebarCollapsed}
          style={dashboardStyles.header}
          modal={modal && !allowModals.includes(modal)}
        />

        <Content
          modal={modal && !allowModals.includes(modal)}
          isMobile={isMobile}
          style={dashboardStyles.content}
        >
          <Outlet />
          {current.currentSection === "academy" &&
            ["ADMIN", "PM", "PROFESSOR"].includes(current.role) && (
              <SideBar
                menu={menu}
                setOpenDropDown={setOpenDropDown}
                openDropDown={openDropDown}
              ></SideBar>
            )}
        </Content>
      </Layout>
      <MobileContentModal
        isVisible={isModalOpen}
        setIsVisible={setIsModalOpen}
        title="Notificaciones"
        Icon={FaBell}
      >
        <NotificationList></NotificationList>
      </MobileContentModal>
      <ToastContainer
        position="top-right"
        limit={1}
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

const states = ({ modalStore, userStore, currentStore }) => {
  const { visibleModal: modal } = modalStore;
  const { data: user } = userStore;
  const { data: current } = currentStore;
  return {
    modal,
    user,
    current,
  };
};

export default connect(states)(Component);
