import { useEffect, useState } from "react";
import Grid from "../../../../components/Grid";
import {
  FlexContainer,
  PageTitle,
  Separator,
} from "../../../../components/StyledComponents";

import { connect, useDispatch } from "react-redux";
import FilterByName from "./components/FilterByName";
import contentActions from "../../../../store/actions/content";
import {
  getAllCohorts,
  resetGetAllCohortsData,
} from "../../../../store/actions/cohort";
import { useParams } from "react-router-dom";
import {
  CardWrapper,
  BannerCohortPhoto,
  CohortBoldFont,
  CohortLabelFont,
  Img,
  CohortMediumFont,
  AddModule,
} from "./style";
import { CohortDto } from "../../../../types/cohort.dto";
import DropDown from "./components/DropDown";
import ModalAddContent from "./components/ModalAddContent/index";
import { UnitDto } from "../../../../types/unit.dto";
import ModalAddUnit from "./components/ModalAddUnit";
import { showModal } from "../../../../store/actions/modal";
import Loader from "../../../../components/Loader";
import ModalConfirmDelete from "../../../../components/ModalConfirmDelete";
import { filterCohortByRole } from "../../../../helpers/roleAcademy";
import { ContentDto } from "../../../../types/content.dto";
import { VideoDto } from "../../../../types/video.dto";
import { CDocDto } from "../../../../types/cdoc.dto";
import { getAllCDOCs, resetGetAllCdocs } from "../../../../store/actions/cdoc";
import {
  getAllVideos,
  resetGetAllVideos,
} from "../../../../store/actions/video";
import moment from "moment";
import {
  deleteUnit,
  resetDeleteUnit,
  resetGetAllUnits,
} from "../../../../store/actions/unit";
import ModalUpdateUnit from "./components/ModalUpdateUnit";
import { ImageDto } from "../../../../types/image.dto";
import { RoadmapDto } from "../../../../types/roadmap.dto";
import {
  getAllRoadmaps,
  resetGetAllRoadmaps,
} from "../../../../store/actions/roadmap";
import {
  getAllImages,
  resetGetAllImages,
} from "../../../../store/actions/image";
import { SlideShowDto } from "../../../../types/slideshow.dto";
import { ListenDto } from "../../../../types/listen.dto";
import { getAllSlideShows } from "../../../../store/actions/slideshow";
import {
  getAllListens,
  resetGetAllListens,
} from "../../../../store/actions/listen";
import { getAllBytes, resetGetAllBytes } from "../../../../store/actions/byte";
import { ByteDto } from "../../../../types/byte.dto";
import { StateDto } from "../../../../types/states.dto";
import ScrollUnits from "./components/ScrollUnits";
import { PaginationDto } from "../../../../types/pagination.dto";
import services from "../../../../services";
const Component = ({
  studentsState,
  students,
  individualStatsState,
  individualData,
  cohorts,
  talents,
  aitTalents,
  users,
  user,
  academies,
  current,
  units,
  contents,
  updateContentStats,
  videos,
  cdocs,
  updateVideoStates,
  updateCdocStates,
  updateContentStates,
  deleteUnitStates,
  roadmaps,
  images,
  updateImageStates,
  updateRoadmapStates,
  slideshows,
  listens,
  bytes,
  updateBytesStates,
  updateSlideShowStates,
  updateListenStates,
  updatedContent,
  updatedVideo,
  updatedCdoc,
  updatedImage,
  updatedRoadmap,
  updatedSlideShow,
  updatedListen,
  updatedByte,
  deletedUnit,
  updateUnitStates,
  updatedUnit,
  updateManyBytesStates,
  updatedBytes,
  updateManyVideosStates,
  updatedVideos,
  updateManyRoadmapsStates,
  updatedRoadmaps,
  updateManySlideShowsStates,
  updateSlideShows,
  updateManyCdocsStates,
  updatedCdocs,
  updateManyImagesStates,
  updatedImages,
  updateManyContentsStates,
  updatedContents,
  updateManyListensStates,
  updatedListens,
  contentsStates,
  videosStates,
  cdocsStates,
  roadmapsStates,
  imagesStates,
  slideshowStates,
  listensStates,
  bytesStates,
}) => {
  const [currentCohortsList, setCurrentCohortsList] = useState<CohortDto[]>([]);
  const [contentCount, setContentCount] = useState<number>();
  const [active, setActive] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [applyedFilters, setFilters] = useState<
    { field: string; value: string }[]
  >([]);
  /*   const [currentUnits, setCurrentUnits] = useState<UnitDto[]>([]); */
  const [currentSection, setCurrentSection] = useState();
  const [currentStates, setCurrentStates] = useState<StateDto>();
  const [action, setAction] = useState<{ action: string; data: any }>();
  const [currentCohortsListValues, setCurrentCohortsListValues] = useState<
    CohortDto[]
  >([]);
  const [currentUnit, setCurrentUnit] = useState<string>();
  const [currentContent, setCurrentContent] = useState<
    (
      | ContentDto
      | VideoDto
      | CDocDto
      | ImageDto
      | RoadmapDto
      | SlideShowDto
      | ListenDto
      | ByteDto
    )[]
  >([]);
  const [cohortSelected, setCohortSelected] = useState<CohortDto>();
  const [
    filteredCurrentCohortsListValues,
    setFilteredCurrentCohortsListValues,
  ] = useState<CohortDto[]>([]);
  const dispatch = useDispatch();
  const params = useParams();
  const buildFilters = (newFilter) => {
    const filterUpdated =
      applyedFilters.filter(
        (applyedFilter) => applyedFilter.field === newFilter.field
      ).length > 0;

    const filterRemoved = newFilter.value === "no";

    /**
     * Se elimina el filtro
     */
    if (filterRemoved) {
      setFilters([
        ...applyedFilters.filter(
          (applyedFilter) => applyedFilter.field !== newFilter.field
        ),
      ]);
    }

    /**
     * Se actualiza un filtro
     */
    if (filterUpdated && !filterRemoved) {
      setFilters([
        ...applyedFilters.map((applyedFilter) => {
          if (applyedFilter.field === newFilter.field) {
            return newFilter;
          } else {
            return applyedFilter;
          }
        }),
      ]);
    }

    /**
     * Se agrega un filtro
     */
    if (!filterUpdated && !filterRemoved) {
      setFilters([...applyedFilters, newFilter]);
    }
  };
  const handleItemClick = (item) => {
    if (currentUnit !== item) {
      // Only set the currentItem if it's a new item
      setCurrentUnit(item);
    }
  };

  const getUnitData = ({ unitId }: { unitId: string }) => {
    let payload: { [key: string]: PaginationDto } = {
      videos: {
        filterBy: {
          url: { $exists: true },
          active: true,
          published: true,
          units: unitId,
        },
      },
      cdocs: {
        filterBy: {
          active: true,
          published: true,
          units: unitId,
        },
      },
      slideshows: {
        filterBy: {
          active: true,
          published: true,
          units: unitId,
        },
      },
      roadmaps: {
        filterBy: {
          active: true,
          published: true,
          units: unitId,
        },
      },
      images: {
        filterBy: {
          active: true,
          published: true,
          units: unitId,
        },
      },
      contents: {
        filterBy: {
          active: true,
          published: true,
          units: unitId,
        },
      },
      listens: {
        filterBy: {
          active: true,
          published: true,
          units: unitId,
        },
      },
      bytes: {
        filterBy: {
          url: { $exists: true },
          active: true,
          published: true,
          units: unitId,
        },
      },
    };
    dispatch(contentActions.getAll(payload.contents));
    dispatch(getAllVideos(payload.videos));
    dispatch(getAllCDOCs(payload.cdocs));
    dispatch(getAllImages(payload.images));
    dispatch(getAllRoadmaps(payload.roadmaps));
    dispatch(getAllListens(payload.listens));
    dispatch(getAllSlideShows(payload.slideshows));
    dispatch(getAllBytes(payload.bytes));
  };

  useEffect(() => {
    if (cohortSelected) {
      const fetchContentLength = async () => {
        const response: any = await services.generalContent.getAll({
          filterBy: { cohorts: cohortSelected._id },
        });
        if (response.error) return null;
        setContentCount(response.response.length);
      };
      fetchContentLength();
    }
  }, [cohortSelected]);

  useEffect(() => {
    if (!!currentUnit) {
      getUnitData({ unitId: currentUnit });
    }
  }, [currentUnit]);

  useEffect(() => {
    if (updateManyBytesStates.success) {
      setCurrentContent((state) =>
        state.map((st) =>
          !!updatedBytes.find((byte) => byte._id === st._id)
            ? {
                ...updatedBytes.find((byte) => byte._id === st._id),
                type: "byte",
              }
            : st
        )
      );
    }
  }, [updateManyBytesStates]);
  useEffect(() => {
    if (updateManyCdocsStates.success) {
      setCurrentContent((state) =>
        state.map((st) =>
          !!updatedCdocs.find((cdoc) => cdoc._id === st._id)
            ? {
                ...updatedCdocs.find((cdoc) => cdoc._id === st._id),
                type: "cdoc",
              }
            : st
        )
      );
    }
  }, [updateManyCdocsStates]);
  useEffect(() => {
    if (updateManyListensStates.success) {
      setCurrentContent((state) =>
        state.map((st) =>
          !!updatedListens.find((listen) => listen._id === st._id)
            ? {
                ...updatedListens.find((listen) => listen._id === st._id),
                type: "listen",
              }
            : st
        )
      );
    }
  }, [updateManyListensStates]);
  useEffect(() => {
    if (updateManyRoadmapsStates.success) {
      setCurrentContent((state) =>
        state.map((st) =>
          !!updatedRoadmaps.find((roadmap) => roadmap._id === st._id)
            ? {
                ...updatedRoadmaps.find((roadmap) => roadmap._id === st._id),
                type: "roadmap",
              }
            : st
        )
      );
    }
  }, [updateManyRoadmapsStates]);
  useEffect(() => {
    if (updateManySlideShowsStates.success) {
      setCurrentContent((state) =>
        state.map((st) =>
          !!updateSlideShows.find((slideshow) => slideshow._id === st._id)
            ? {
                ...updateSlideShows.find(
                  (slideshow) => slideshow._id === st._id
                ),
                type: "slideshow",
              }
            : st
        )
      );
    }
  }, [updateManySlideShowsStates]);
  useEffect(() => {
    if (updateManyVideosStates.success) {
      setCurrentContent((state) =>
        state.map((st) =>
          !!updatedVideos.find((video) => video._id === st._id)
            ? {
                ...updatedVideos.find((video) => video._id === st._id),
                type: "video",
              }
            : st
        )
      );
    }
  }, [updateManyVideosStates]);
  useEffect(() => {
    if (updateManyContentsStates.success) {
      setCurrentContent((state) =>
        state.map((st) =>
          !!updatedContents.find((content) => content._id === st._id)
            ? {
                ...updatedContents.find((content) => content._id === st._id),
                type: "variety",
              }
            : st
        )
      );
    }
  }, [updateManyContentsStates]);
  useEffect(() => {
    if (updateManyImagesStates.success) {
      setCurrentContent((state) =>
        state.map((st) =>
          !!updatedImages.find((image) => image._id === st._id)
            ? {
                ...updatedImages.find((image) => image._id === st._id),
                type: "image",
              }
            : st
        )
      );
    }
  }, [updateManyImagesStates]);

  useEffect(() => {
    if (!!updateContentStates.success) {
      setCurrentContent((state) =>
        state.map((st) =>
          st._id === updatedContent._id
            ? { ...updatedContent, type: "variety" }
            : st
        )
      );
    }
  }, [updateContentStates]);

  useEffect(() => {
    if (!!updateCdocStates.success) {
      setCurrentContent((state) =>
        state.map((st) =>
          st._id === updatedCdoc._id ? { ...updatedCdoc, type: "cdoc" } : st
        )
      );
    }
  }, [updateCdocStates]);
  useEffect(() => {
    if (!!updateVideoStates.success) {
      setCurrentContent((state) =>
        state.map((st) =>
          st._id === updatedVideo._id ? { ...updatedVideo, type: "video" } : st
        )
      );
    }
  }, [updateVideoStates]);

  useEffect(() => {
    if (updateRoadmapStates.success) {
      setCurrentContent((state) =>
        state.map((st) =>
          st._id === updatedRoadmap._id
            ? { ...updatedRoadmap, type: "roadmap" }
            : st
        )
      );
    }
  }, [updateRoadmapStates]);
  useEffect(() => {
    if (updateSlideShowStates.success) {
      setCurrentContent((state) =>
        state.map((st) =>
          st._id === updatedSlideShow._id
            ? { ...updatedSlideShow, type: "slideshow" }
            : st
        )
      );
    }
  }, [updateSlideShowStates]);
  useEffect(() => {
    if (updateBytesStates.success) {
      setCurrentContent((state) =>
        state.map((st) =>
          st._id === updatedByte._id ? { ...updatedByte, type: "byte" } : st
        )
      );
    }
  }, [updateBytesStates]);
  useEffect(() => {
    if (updateImageStates.success) {
      setCurrentContent((state) =>
        state.map((st) =>
          st._id === updatedImage._id ? { ...updatedImage, type: "image" } : st
        )
      );
    }
  }, [updateImageStates]);
  useEffect(() => {
    if (updateListenStates.success) {
      setCurrentContent((state) =>
        state.map((st) =>
          st._id === updatedListen._id
            ? { ...updatedListen, type: "listen" }
            : st
        )
      );
    }
  }, [updateListenStates]);

  useEffect(() => {
    if (currentSection === "cdoc") {
      setCurrentStates(updateCdocStates);
    }
    if (currentSection === "video") {
      setCurrentStates(updateVideoStates);
    }
    if (currentSection === "variety") {
      setCurrentStates(updateContentStates);
    }
    if (currentSection === "image") {
      setCurrentStates(updateImageStates);
    }
    if (currentSection === "roadmap") {
      setCurrentStates(updateRoadmapStates);
    }
    if (currentSection === "byte") {
      setCurrentStates(updateBytesStates);
    }
    if (currentSection === "slideshow") {
      setCurrentStates(updateSlideShowStates);
    }
    if (currentSection === "listen") {
      setCurrentStates(updateListenStates);
    }
  }, [
    currentSection,
    updateContentStates,
    updateVideoStates,
    updateCdocStates,
    updateImageStates,
    updateRoadmapStates,
    updateBytesStates,
    updateSlideShowStates,
    updateListenStates,
  ]);

  useEffect(() => {
    if (!!cohorts) {
      const currentCohorts = cohorts.filter(
        (cohort) =>
          cohort.active &&
          cohort.academy._id === current.id &&
          filterCohortByRole(cohorts, user._id, current.role).find(
            (cohortFounded) => cohortFounded._id === cohort._id
          )
      );

      setCurrentCohortsList(currentCohorts);
    }
  }, [cohorts]);

  useEffect(() => {
    setIsLoading(
      contentsStates.loading ||
        videosStates.loading ||
        imagesStates.loading ||
        roadmapsStates.loading ||
        slideshowStates.loading ||
        cdocsStates.loading ||
        listensStates.loading ||
        bytesStates.loading
    );
  }, [
    contentsStates,
    videosStates,
    imagesStates,
    roadmapsStates,
    slideshowStates,
    cdocsStates,
    listensStates,
    bytesStates,
  ]);

  useEffect(() => {
    if (
      !!contents &&
      !!videos &&
      !!cdocs &&
      !!images &&
      !!roadmaps &&
      !!slideshows &&
      !!listens &&
      !!bytes
    ) {
      const currentContents = contents.map((content) => {
        return { ...content, type: "variety" };
      });

      const currentVideos = videos.map((video) => {
        return { ...video, type: "video" };
      });
      const currentCdocs = cdocs.map((cdoc) => {
        return { ...cdoc, type: "cdoc" };
      });
      const currentImages = images.map((image) => {
        return { ...image, type: "image" };
      });
      const currentRoadmaps = roadmaps.map((roadmap) => {
        return { ...roadmap, type: "roadmap" };
      });
      const currentListens = listens.map((listen) => {
        return { ...listen, type: "listen" };
      });
      const currentSldeshows = slideshows.map((slideshow) => {
        return { ...slideshow, type: "slideshow" };
      });
      const currentBytes = bytes.map((video) => {
        return { ...video, type: "byte" };
      });
      setCurrentContent([
        ...currentContents,
        ...currentCdocs,
        ...currentVideos,
        ...currentImages,
        ...currentRoadmaps,
        ...currentBytes,
        ...currentSldeshows,
        ...currentListens,
      ]);
    }
  }, [
    contents,
    cdocs,
    videos,
    roadmaps,
    listens,
    slideshows,
    images,
    bytes,
    cohortSelected,
  ]);

  useEffect(() => {
    dispatch(getAllCohorts({ filterBy: { academy: current.id } }));
  }, []);

  useEffect(() => {
    if (cohorts) {
      setCurrentCohortsList(cohorts);
    }
  }, [cohorts]);

  useEffect(() => {
    if (params.id) {
      const cohortFounded = cohorts.filter(
        (cohort) => cohort._id === params.id
      )[0];
      setCohortSelected(cohortFounded);
    }
  }, [params, cohorts]);

  useEffect(() => {
    if (currentCohortsList.length > 0) {
      setCurrentCohortsListValues(currentCohortsList);
    }
  }, [currentCohortsList]);

  useEffect(() => {
    const filteredCohorts = currentCohortsListValues?.filter(
      (currentTalentValues) => {
        const thereAreFilters = applyedFilters.length > 0;
        const totalOfFilters = applyedFilters.length;
        const passFilters = applyedFilters.filter(
          (applyedFilter) =>
            currentTalentValues[applyedFilter.field] === applyedFilter.value
        ).length;

        return (
          !thereAreFilters ||
          (thereAreFilters && totalOfFilters === passFilters)
        );
      }
    );
    if (
      !!filteredCohorts[0] &&
      !!applyedFilters.find((filter) => filter.field === "_id")
    ) {
      setCohortSelected(filteredCohorts[0]);

      setFilteredCurrentCohortsListValues(filteredCohorts);
    }
  }, [applyedFilters, currentCohortsListValues]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllVideos());
      dispatch(resetGetAllCdocs());
      dispatch(contentActions.resetGetAllContents());
      dispatch(resetGetAllRoadmaps());
      dispatch(resetGetAllImages());
      dispatch(resetGetAllListens());
      dispatch(resetGetAllBytes());
      dispatch(resetGetAllCohortsData());
      dispatch(resetGetAllUnits());
    };
  }, []);

  /* OFFSET DEJAR COL VACIAS
    LG largo
  */

  return (
    <>
      <PageTitle>Cohorts - Customización de Módulos</PageTitle>
      <Grid.Row>
        <Grid.Col lg={7} offset={{ lg: 2.5 }} style={{ marginBottom: "15px" }}>
          <FilterByName
            onselect={(newValue) => {
              setContentCount(undefined);
            }}
            items={currentCohortsListValues}
            setFilter={buildFilters}
          />
        </Grid.Col>
      </Grid.Row>
      {!cohortSelected && (
        <FlexContainer justify="center">
          <h2 style={{ marginTop: "50px" }}>
            Selecciona un cohort para customizar sus modulos de contenidos
          </h2>
          <Separator size={100}></Separator>
        </FlexContainer>
      )}
      {cohortSelected && (
        <>
          <>
            <Grid.Row style={{ marginTop: "40px" }}>
              <Grid.Col>
                <CardWrapper>
                  <FlexContainer align="center" gap="16px">
                    <BannerCohortPhoto>
                      <Img
                        src={
                          cohortSelected.picture ||
                          "https://cdn.pixabay.com/photo/2015/11/19/08/52/banner-1050629__340.jpg"
                        }
                      ></Img>
                    </BannerCohortPhoto>
                    <FlexContainer direction="column" gap="10px">
                      <FlexContainer gap="16px" align="center">
                        <CohortBoldFont>{cohortSelected.name}</CohortBoldFont>
                      </FlexContainer>
                      <FlexContainer gap="14px" align="center">
                        <FlexContainer align="center" gap="4px">
                          <CohortLabelFont>{`Contenidos: `}</CohortLabelFont>
                          {contentCount && (
                            <CohortBoldFont size="12px">
                              {contentCount}
                            </CohortBoldFont>
                          )}
                        </FlexContainer>
                        <FlexContainer align="center" gap="4px">
                          <CohortLabelFont>Inscriptos:</CohortLabelFont>
                          <CohortBoldFont size="12px">
                            {cohortSelected.studentsIds.length}
                          </CohortBoldFont>
                        </FlexContainer>
                        <FlexContainer align="center" gap="4px">
                          <CohortLabelFont>Vistas:</CohortLabelFont>
                          <CohortBoldFont size="12px">
                            {cohortSelected.viewCount}
                          </CohortBoldFont>
                        </FlexContainer>
                        {cohortSelected.startDate &&
                          cohortSelected.type === "SYNCHRONOUS" && (
                            <FlexContainer align="center" gap="4px">
                              <CohortLabelFont>
                                Fecha de comienzo:
                              </CohortLabelFont>
                              <CohortBoldFont size="12px">
                                {moment
                                  .utc(
                                    cohortSelected.startDate.toLocaleString()
                                  )
                                  .format("DD/MM/YYYY")}
                              </CohortBoldFont>
                            </FlexContainer>
                          )}
                        {cohortSelected.endDate &&
                          cohortSelected.type === "SYNCHRONOUS" && (
                            <FlexContainer align="center" gap="4px">
                              <CohortLabelFont>Fecha de Fin:</CohortLabelFont>
                              <CohortBoldFont size="12px">
                                {!!cohortSelected.endDate &&
                                  moment
                                    .utc(
                                      cohortSelected.endDate.toLocaleString()
                                    )
                                    .format("DD/MM/YYYY")}
                              </CohortBoldFont>
                            </FlexContainer>
                          )}
                      </FlexContainer>
                    </FlexContainer>
                  </FlexContainer>
                </CardWrapper>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row style={{ marginTop: "15px", padding: "0px 50px" }}>
              <Grid.Col>
                <FlexContainer gap="25px" direction="column">
                  <ScrollUnits
                    cohortSelected={cohortSelected}
                    isLoadingContents={isLoading}
                    currentContent={currentContent}
                    active={active}
                    setActive={setActive}
                    handleItemClick={handleItemClick}
                    setCurrentSection={setCurrentSection}
                    setUnit={setAction}
                  />
                  <AddModule
                    onClick={() => {
                      dispatch(showModal("add-unit-cohort"));
                    }}
                  >
                    Agregar Módulo
                  </AddModule>
                </FlexContainer>
              </Grid.Col>
            </Grid.Row>
          </>
          {action?.action === "add-content-to-unit" && (
            <ModalAddContent
              setCurrentContent={setCurrentContent}
              cohort={cohortSelected}
              unit={action.data}
            ></ModalAddContent>
          )}
          <ModalAddUnit cohort={cohortSelected}></ModalAddUnit>
          {action?.action === "delete-content-from-unit" && currentStates && (
            <ModalConfirmDelete
              bntConfig={{
                content: "Quitar",
                style: {
                  options: {
                    type: "outline",
                    skin: "danger",
                    size: "lg",
                    marginBottom: "0px",
                  },
                },
              }}
              states={currentStates}
              title={
                <span>
                  {`¿Seguro que quiere quitar el contenido `}
                  <span style={{ fontWeight: "bold", color: "black" }}>
                    {action.data.name}
                  </span>
                  ?
                </span>
              }
              externalFunction={action.data.externalFunction}
              elementActions={action.data.action}
              resetAction={action.data.resetAction}
              resetState={setAction}
            ></ModalConfirmDelete>
          )}
          {action?.action === "delete-unit" && (
            <ModalConfirmDelete
              bntConfig={{
                content: "Quitar",
                style: {
                  options: {
                    type: "outline",
                    skin: "danger",
                    size: "lg",
                    marginBottom: "0px",
                  },
                },
              }}
              states={deleteUnitStates}
              title={
                <span>
                  {`¿Seguro que quiere eliminar la unidad `}
                  <span style={{ fontWeight: "bold", color: "black" }}>
                    {action.data.name}
                  </span>
                  ?
                </span>
              }
              elementActions={deleteUnit({ _id: action.data._id })}
              resetAction={resetDeleteUnit}
              resetState={setAction}
            ></ModalConfirmDelete>
          )}
          {action?.action === "update-unit" && (
            <ModalUpdateUnit unit={action.data}></ModalUpdateUnit>
          )}
        </>
      )}
    </>
  );
};

const states = ({
  talentStore,
  staticsStore,
  abilityStore,
  cohortStore,
  studentStore,
  analyticsStore,
  recruitingStore,
  usersStore,
  userStore,
  academyStore,
  currentStore,
  unitStore,
  contentStore,
  videoStore,
  cdocStore,
  roadmapStore,
  imageStore,
  slideshowStore,
  listenStore,
  byteStore,
}) => {
  const { states: studentsState, data: students } = studentStore.allStudents;
  const { states: individualStatsState, data: individualData } =
    analyticsStore.getIndividualStats;
  const { data: users } = usersStore.all;
  const { data: user } = userStore;
  const { states: cohortsStates, data: cohorts } = cohortStore.allCohorts;
  const { data: aitTalents } = recruitingStore.talent.all;
  const { states: talentsStates, data: talents } = talentStore.allTalents;
  const { data: academies } = academyStore.all;
  const { data: current } = currentStore;
  const { data: units } = unitStore.allUnits;
  const { states: deleteUnitStates, data: deletedUnit } = unitStore.deleteUnit;
  const { states: updateUnitStates, data: updatedUnit } = unitStore.updateUnit;
  const { data: contents, states: contentsStates } = contentStore.all;
  const { states: updateContentStats, data: updatedContent } =
    contentStore.update;
  const { data: videos, states: videosStates } = videoStore.all;
  const { data: cdocs, states: cdocsStates } = cdocStore.all;
  const { states: updateVideoStates, data: updatedVideo } = videoStore.update;
  const { states: updateCdocStates, data: updatedCdoc } = cdocStore.update;
  const { states: updateContentStates } = contentStore.update;
  const { data: roadmaps, states: roadmapsStates } = roadmapStore.all;
  const { data: images, states: imagesStates } = imageStore.all;
  const { states: updateImageStates, data: updatedImage } = imageStore.update;
  const { states: updateRoadmapStates, data: updatedRoadmap } =
    roadmapStore.update;
  const { data: slideshows, states: slideshowStates } = slideshowStore.all;
  const { states: updateSlideShowStates, data: updatedSlideShow } =
    slideshowStore.update;
  const { data: listens, states: listensStates } = listenStore.all;
  const { data: bytes, states: bytesStates } = byteStore.all;
  const { states: updateListenStates, data: updatedListen } =
    listenStore.update;
  const { states: updateBytesStates, data: updatedByte } = byteStore.update;

  //update many
  const { states: updateManyBytesStates, data: updatedBytes } =
    byteStore.updateMany;
  const { states: updateManyVideosStates, data: updatedVideos } =
    videoStore.updateMany;
  const { states: updateManyRoadmapsStates, data: updatedRoadmaps } =
    roadmapStore.updateMany;
  const { states: updateManySlideShowsStates, data: updateSlideShows } =
    roadmapStore.updateMany;
  const { states: updateManyCdocsStates, data: updatedCdocs } =
    cdocStore.updateMany;
  const { states: updateManyImagesStates, data: updatedImages } =
    imageStore.updateMany;
  const { states: updateManyContentsStates, data: updatedContents } =
    contentStore.updateMany;
  const { states: updateManyListensStates, data: updatedListens } =
    listenStore.updateMany;

  return {
    updateVideoStates,
    updateCdocStates,
    updateContentStates,
    studentsState,
    students,
    individualStatsState,
    individualData,
    users,
    user,
    cohortsStates,
    cohorts,
    talentsStates,
    talents,
    aitTalents,
    academies,
    current,
    units,
    contents,
    updateContentStats,
    videos,
    cdocs,
    deleteUnitStates,
    roadmaps,
    images,
    updateImageStates,
    updateRoadmapStates,
    slideshows,
    listens,
    bytes,
    updateBytesStates,
    updateSlideShowStates,
    updateListenStates,
    updatedContent,
    updatedVideo,
    updatedCdoc,
    updatedImage,
    updatedRoadmap,
    updatedSlideShow,
    updatedListen,
    updatedByte,
    deletedUnit,
    updateUnitStates,
    updatedUnit,
    updateManyBytesStates,
    updatedBytes,
    updateManyVideosStates,
    updatedVideos,
    updateManyRoadmapsStates,
    updatedRoadmaps,
    updateManySlideShowsStates,
    updateSlideShows,
    updateManyCdocsStates,
    updatedCdocs,
    updateManyImagesStates,
    updatedImages,
    updateManyContentsStates,
    updatedContents,
    updateManyListensStates,
    updatedListens,
    contentsStates,
    videosStates,
    cdocsStates,
    roadmapsStates,
    imagesStates,
    slideshowStates,
    listensStates,
    bytesStates,
  };
};

export default connect(states)(Component);
