import styled from "styled-components";

export const ChildrenSelectorWrapper = styled("div")`
  padding: 10px 4px 4px 10px;
  border-radius: 10px;
  box-shadow: 0 0 4px 0 rgba(0, 30, 108, 0.2);
  background: ${(props) => props.theme.colors.White};
`;

export const CounterWrapper = styled("div")`
  background-color: ${(props) => props.theme.colors.Primary};
  color: ${(props) => props.theme.colors.White};
  padding: 5px;
  border-radius: 10px;
`;
