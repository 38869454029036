import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import Button from "../../../../../components/Button";
import Grid from "../../../../../components/Grid";
import { GridStructure } from "../../../../../components/Layout/Dashboard/styles";
import LineGraphHorizontal from "../../../../../components/LineGraphHorizontal";
import Loader from "../../../../../components/Loader";
import PieChart from "../../../../../components/PieChart";
import {
  FlexContainer,
  PageTitle,
} from "../../../../../components/StyledComponents";
import { DateEStoDateEN } from "../../../../../helpers/format-date";
import { getOrganizationStats } from "../../../../../store/actions/analytics";
import AnalyticCard from "../../components/AnalyticCard";
import NotData from "../../components/NotData";
import { RankingItem, RankingTable } from "../../style";
import {
  formatNumber,
  overThousandFixed,
} from "../../../../../helpers/number-fixed";

const Component = ({
  analyticsOrganization,
  analyticsOrganizationStates,
  current,
}) => {
  const printRef = React.useRef();
  const dispatch = useDispatch();
  const handleDownloadPdf = async () => {
    if (!!printRef.current) {
      const element = printRef.current;
      const canvas = await html2canvas(element);
      const data = canvas.toDataURL("image/png");

      const pdf = new jsPDF("p", "px", [canvas.width, canvas.height]);
      const imgProperties = pdf.getImageProperties(data);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

      pdf.addImage(data, "PNG", 5, 10, pdfWidth - 10, pdfHeight + 5);
      pdf.save("print.pdf");
    }
  };
  useEffect(() => {
    dispatch(getOrganizationStats({ _id: current.id }));
  }, []);
  if (!analyticsOrganization) return <Loader color="Primary"></Loader>;

  return (
    <>
      <PageTitle>Dashboard</PageTitle>
      <Grid.Col offset={{ sm: 10 }} sm={12}>
        <Button
          onClick={() => {
            handleDownloadPdf();
          }}
          type="button"
          options={{
            type: "filled",
            skin: "primary",
            size: "md",
            marginBottom: "10px",
          }}
          style={{ marginLeft: "10px" }}
        >
          Descargar
        </Button>
      </Grid.Col>
      <FlexContainer ref={printRef} direction="column" gap="6px">
        <AnalyticCard
          title={"Tiempo de tus miembros en tu Organización (hs)"}
          graphTitle={
            "Tiempo de tu audiencia en tu Organización (hs) - Crecimiento Histórico "
          }
          data={{
            stats: [
              {
                label: "Últimos 30 días",
                value:
                  analyticsOrganization.organization.users.screentime.lastMonth,
              },
              {
                label: "Últimos 7 días",
                value:
                  analyticsOrganization.organization.users.screentime.lastWeek,
              },
              {
                label: "Últimas 24 horas",
                value:
                  analyticsOrganization.organization.users.screentime.lastDay,
              },
            ],
            graphData: Object.entries(
              analyticsOrganization.organization.users.screentime.growth
            )
              .sort((a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0]))
              .map((growthData) => {
                return {
                  name: growthData[0],
                  value: overThousandFixed(formatNumber(growthData[1])),
                };
              }),
          }}
        ></AnalyticCard>
        <AnalyticCard
          height="250px"
          title="Top 10 Equipos con mas interacciones"
        >
          <NotData data={analyticsOrganization.teams.clicksTopTen}>
            <LineGraphHorizontal
              data={analyticsOrganization.teams.clicksTopTen.map((team) => {
                return {
                  label: team.name,
                  value: team.clicks,
                };
              })}
              title={"Alumnos"}
            ></LineGraphHorizontal>
          </NotData>
        </AnalyticCard>
        <AnalyticCard
          height="250px"
          title="Top 10 Miembros con mas interacciones"
        >
          <NotData
            data={analyticsOrganization.organization.users.pms.clicksTopTen}
          >
            <LineGraphHorizontal
              data={analyticsOrganization.organization.users.pms.clicksTopTen.map(
                (user) => {
                  return {
                    label: user.name,
                    value: user.clicks,
                  };
                }
              )}
              title={"Alumnos"}
            ></LineGraphHorizontal>
          </NotData>
        </AnalyticCard>
        <GridStructure style={{ width: "100%" }} type="auto-fit" width="400px">
          <AnalyticCard height="300px" title="Genero de tus Miembros y PMs">
            <NotData
              data={analyticsOrganization.organization.users.pms.genders}
            >
              <PieChart
                pieData={Object.entries(
                  analyticsOrganization.organization.users.pms.genders
                ).map((user) => {
                  return {
                    label: user[0],
                    value: user[1],
                  };
                })}
              ></PieChart>
            </NotData>
          </AnalyticCard>
          <AnalyticCard height="300px" title="Edad de tus Miembros y PMs">
            <NotData data={analyticsOrganization.organization.users.pms.ages}>
              <PieChart
                pieData={Object.entries(
                  analyticsOrganization.organization.users.pms.ages
                ).map((user) => {
                  return {
                    label: user[0],
                    value: user[1],
                  };
                })}
              ></PieChart>
            </NotData>
          </AnalyticCard>
        </GridStructure>
        <AnalyticCard height="auto" title="Palabras clave de tu Organización">
          <NotData data={analyticsOrganization.organization.keywords}>
            <RankingTable>
              {analyticsOrganization.organization.keywords.map(
                (keyword, index) => {
                  return (
                    <FlexContainer
                      style={{ marginBottom: "5px" }}
                      align="center"
                      gap="5px"
                    >
                      <span>{index + 1}</span>
                      <RankingItem>{keyword.name}</RankingItem>
                    </FlexContainer>
                  );
                }
              )}
            </RankingTable>
          </NotData>
        </AnalyticCard>
      </FlexContainer>
    </>
  );
};

const states = ({ analyticsStore, currentStore }) => {
  const { data: analyticsOrganization, states: analyticsOrganizationStates } =
    analyticsStore.getOrganizationStats;
  const { data: current } = currentStore;
  return {
    analyticsOrganization,
    analyticsOrganizationStates,
    current,
  };
};

export default connect(states)(Component);
