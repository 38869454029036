import { OrganizationDto } from "../../types/organization.dto";

export const GET_ALL_ORGANIZATIONS = "GET_ALL_ORGANIZATIONS";
export const GET_ALL_ORGANIZATIONS_ERROR = "GET_ALL_ORGANIZATIONS_ERROR";
export const GET_ALL_ORGANIZATIONS_SUCCESS = "GET_ALL_ORGANIZATIONS_SUCCESS";
export const GET_ORGANIZATION = "GET_ORGANIZATION";
export const GET_ORGANIZATION_ERROR = "GET_ORGANIZATION_ERROR";
export const GET_ORGANIZATION_SUCCESS = "GET_ORGANIZATION_SUCCESS";
export const NEW_ORGANIZATION = "NEW_ORGANIZATION";
export const NEW_ORGANIZATION_ERROR = "NEW_ORGANIZATION_ERROR";
export const NEW_ORGANIZATION_SUCCESS = "NEW_ORGANIZATION_SUCCESS";
export const RESET_NEW_ORGANIZATION = "RESET_NEW_ORGANIZATION";
export const UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION";
export const UPDATE_ORGANIZATION_ERROR = "UPDATE_ORGANIZATION_ERROR";
export const UPDATE_ORGANIZATION_SUCCESS = "UPDATE_ORGANIZATION_SUCCESS";
export const RESET_UPDATE_ORGANIZATION = "RESET_UPDATE_ORGANIZATION";
export const DELETE_ORGANIZATION = "DELETE_ORGANIZATION";
export const DELETE_ORGANIZATION_ERROR = "DELETE_ORGANIZATION_ERROR";
export const DELETE_ORGANIZATION_SUCCESS = "DELETE_ORGANIZATION_SUCCESS";
export const RESET_DELETE_ORGANIZATION = "RESET_DELETE_ORGANIZATION";
export const SET_UPDATE_ORGANIZATION_DATA = "SET_UPDATE_ORGANIZATION_DATA";
export const SET_NEW_ORGANIZATION_DATA = "SET_NEW_ORGANIZATION_DATA";
export const UPDATE_CURRENT_ORGANIZATION_LIST =
  "UPDATE_CURRENT_ORGANIZATION_LIST";
export const NEW_ORGANIZATION_INVITE_LINK = "NEW_ORGANIZATION_INVITE_LINK";
export const NEW_ORGANIZATION_INVITE_LINK_SUCCESS =
  "NEW_ORGANIZATION_INVITE_LINK_SUCCESS";
export const NEW_ORGANIZATION_INVITE_LINK_ERROR =
  "NEW_ORGANIZATION_INVITE_LINK_ERROR";
export const DELETE_ORGANIZATION_INVITE_LINK =
  "DELETE_ORGANIZATION_INVITE_LINK";
export const DELETE_ORGANIZATION_INVITE_LINK_SUCCESS =
  "DELETE_ORGANIZATION_INVITE_LINK_SUCCESS";
export const DELETE_ORGANIZATION_INVITE_LINK_ERROR =
  "DELETE_ORGANIZATION_INVITE_LINK_ERROR";
export const RESET_ORGANIZATION_DELETE_INVITE_LINK =
  "RESET_ORGANIZATION_DELETE_INVITE_LINK";
export const RESET_ORGANIZATION_NEW_INVITE_LINK =
  "RESET_ORGANIZATION_NEW_INVITE_LINK";
export const SELECT_ORGANIZATION = "SELECT_ORGANIZATION";
export const DELETE_ORGANIZATION_MEMBER = "DELETE_ORGANIZATION_MEMBER";
export const DELETE_ORGANIZATION_MEMBER_SUCCESS =
  "DELETE_ORGANIZATION_MEMBER_SUCCESS";
export const DELETE_ORGANIZATION_MEMBER_ERROR =
  "DELETE_ORGANIZATION_MEMBER_ERROR";
export const UPDATE_ORGANIZATION_ROLE = "UPDATE_ORGANIZATION_ROLE";
export const ADD_USER_ORGANIZATION = "ADD_USER_ORGANIZATION";

export interface UpdateOrganizationRole {
  type: typeof UPDATE_ORGANIZATION_ROLE;
  payload: { _id: string; user: string };
}

export interface SelectOrganization {
  type: typeof SELECT_ORGANIZATION;
  payload: OrganizationDto;
}

export interface AddUserOrganization {
  type: typeof ADD_USER_ORGANIZATION;
  payload: { user: string; _id: string; role: string };
}

export interface DeleteOrganizationMember {
  type: typeof DELETE_ORGANIZATION_MEMBER;
  payload: { _id: string; userId: string };
}
export interface DeleteOrganizationMemberSuccess {
  type: typeof DELETE_ORGANIZATION_MEMBER_SUCCESS;
  payload: null;
}
export interface DeleteOrganizationMemberError {
  type: typeof DELETE_ORGANIZATION_MEMBER_ERROR;
  payload: string | boolean;
}

export interface UpdateCurrentOrganizationList {
  type: typeof UPDATE_CURRENT_ORGANIZATION_LIST;
  payload: OrganizationDto[];
}

export interface GetAllOrganizations {
  type: typeof GET_ALL_ORGANIZATIONS;
  payload: null;
}

export interface GetAllOrganizationsSuccess {
  type: typeof GET_ALL_ORGANIZATIONS_SUCCESS;
  payload: OrganizationDto[];
}

export interface NewInviteLink {
  type: typeof NEW_ORGANIZATION_INVITE_LINK;
  payload: string;
}
export interface NewInviteLinkSuccess {
  type: typeof NEW_ORGANIZATION_INVITE_LINK_SUCCESS;
  payload: string;
}
export interface NewInviteLinkError {
  type: typeof NEW_ORGANIZATION_INVITE_LINK_ERROR;
  payload: string | boolean;
}
export interface ResetNewInviteLink {
  type: typeof RESET_ORGANIZATION_NEW_INVITE_LINK;
  payload: null;
}

export interface DeleteInviteLink {
  type: typeof DELETE_ORGANIZATION_INVITE_LINK;
  payload: string;
}
export interface DeleteInviteLinkSuccess {
  type: typeof DELETE_ORGANIZATION_INVITE_LINK_SUCCESS;
  payload: string;
}
export interface DeleteInviteLinkError {
  type: typeof DELETE_ORGANIZATION_INVITE_LINK_ERROR;
  payload: string | boolean;
}

export interface ResetDeleteInviteLink {
  type: typeof RESET_ORGANIZATION_DELETE_INVITE_LINK;
  payload: null;
}

export interface GetAllOrganizationsError {
  type: typeof GET_ALL_ORGANIZATIONS_ERROR;
  payload: boolean | string;
}

export interface GetOrganization {
  type: typeof GET_ORGANIZATION;
  payload: { _id: string };
}

export interface GetOrganizationSuccess {
  type: typeof GET_ORGANIZATION_SUCCESS;
  payload: OrganizationDto;
}

export interface GetOrganizationError {
  type: typeof GET_ORGANIZATION_ERROR;
  payload: boolean | string;
}

export interface NewOrganization {
  type: typeof NEW_ORGANIZATION;
  payload: OrganizationDto;
}

export interface NewOrganizationSuccess {
  type: typeof NEW_ORGANIZATION_SUCCESS;
  payload: OrganizationDto;
}

export interface NewOrganizationError {
  type: typeof NEW_ORGANIZATION_ERROR;
  payload: boolean | string;
}

export interface SetUpdateOrganizationData {
  type: typeof SET_UPDATE_ORGANIZATION_DATA;
  payload: OrganizationDto;
}

export interface SetNewOrganizationData {
  type: typeof SET_NEW_ORGANIZATION_DATA;
  payload: OrganizationDto;
}

export interface ResetNewOrganization {
  type: typeof RESET_NEW_ORGANIZATION;
  payload: null;
}

export interface UpdateOrganization {
  type: typeof UPDATE_ORGANIZATION;
  payload: OrganizationDto;
}

export interface UpdateOrganizationSuccess {
  type: typeof UPDATE_ORGANIZATION_SUCCESS;
  payload: OrganizationDto;
}

export interface UpdateOrganizationError {
  type: typeof UPDATE_ORGANIZATION_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateOrganization {
  type: typeof RESET_UPDATE_ORGANIZATION;
  payload: null;
}

export interface DeleteOrganization {
  type: typeof DELETE_ORGANIZATION;
  payload: { _id: string };
}

export interface DeleteOrganizationSuccess {
  type: typeof DELETE_ORGANIZATION_SUCCESS;
  payload: null;
}

export interface DeleteOrganizationError {
  type: typeof DELETE_ORGANIZATION_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteOrganization {
  type: typeof RESET_DELETE_ORGANIZATION;
  payload: null;
}

export interface State {
  all: {
    data: OrganizationDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  selected: {
    data: OrganizationDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  create: {
    data: null | OrganizationDto;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  delete: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  invite: {
    create: {
      data: null | string;
      states: {
        loading: boolean;
        success: boolean;
        error: boolean | string;
      };
    };
    delete: {
      states: {
        loading: boolean;
        success: boolean;
        error: boolean | string;
      };
    };
  };
  update: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  all: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  selected: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  create: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  delete: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  invite: {
    create: {
      data: null,
      states: {
        loading: false,
        success: false,
        error: false,
      },
    },
    delete: {
      states: {
        loading: false,
        success: false,
        error: false,
      },
    },
  },
  update: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllOrganizations
  | GetAllOrganizationsSuccess
  | GetAllOrganizationsError
  | GetOrganization
  | GetOrganizationSuccess
  | GetOrganizationError
  | NewOrganization
  | NewOrganizationSuccess
  | NewOrganizationError
  | ResetNewOrganization
  | UpdateOrganization
  | UpdateOrganizationSuccess
  | UpdateOrganizationError
  | ResetUpdateOrganization
  | DeleteOrganization
  | DeleteOrganizationSuccess
  | DeleteOrganizationError
  | ResetDeleteOrganization
  | SetUpdateOrganizationData
  | SetNewOrganizationData
  | UpdateCurrentOrganizationList
  | NewInviteLink
  | NewInviteLinkSuccess
  | NewInviteLinkError
  | DeleteInviteLink
  | DeleteInviteLinkSuccess
  | DeleteInviteLinkError
  | ResetNewInviteLink
  | ResetDeleteInviteLink
  | SelectOrganization
  | DeleteOrganizationMember
  | DeleteOrganizationMemberSuccess
  | DeleteOrganizationMemberError
  | AddUserOrganization;
