import * as Yup from "yup";

const cuitRegex = /^\d{2}-\d{8}-\d$/;
const dniRegex = /^[1-3]\d{6,7}$/;
Yup.addMethod(Yup.string, "cbu", function () {
  return this.test("is-cbu", "Invalid CBU", function (value) {
    if (!value) {
      return true; // Empty value is considered valid, but you can adjust this behavior
    }

    const cbuRegex = /^\d{22}$/;
    if (!cbuRegex.test(value)) {
      return this.createError({
        path: this.path,
        message: "Invalid CBU",
      });
    }

    const cbuDigits = value.substring(0, 21);
    const cbuChecksum = parseInt(value.charAt(21), 10);
    const weights = [
      3, 1, 7, 9, 3, 1, 7, 9, 3, 1, 7, 9, 3, 1, 7, 9, 3, 1, 7, 9, 3,
    ];

    let total = 0;
    for (let i = 0; i < cbuDigits.length; i++) {
      total += parseInt(cbuDigits.charAt(i), 10) * weights[i];
    }

    const remainder = total % 10;
    const calculatedChecksum = (10 - remainder) % 10;

    if (cbuChecksum !== calculatedChecksum) {
      return this.createError({
        path: this.path,
        message: "Invalid CBU",
      });
    }

    return true;
  });
});

export const schema = Yup.object().shape({
  personType: Yup.string() /* .required("Se requiere tipo juridico") */,
  personName: Yup.string() /* .required("Ingresa un nombre.") */,
  cuit: Yup.string() /* .required("Se requiere CUIT").matches(cuitRegex, {
    message: "CUIT invalido",
  }),
  dni: Yup.string().matches(dniRegex, {
    message: "Invalid Argentine DNI",
  }) */,
  ivaType: Yup.string() /* .required("Se requiere tipo de responsabilidad"), */,
});

interface FormValues {
  personType: "" | "Física" | "Jurídica";
  personName: string;
  cuit: string;
  dni: string;
  ivaType:
    | ""
    | "Responsable inscripto"
    | "Autónomo"
    | "Monotributista"
    | "Exento"
    | "No alcanzado";
}

export const initialValues: FormValues = {
  personType: "",
  personName: "",
  cuit: "",
  dni: "",
  ivaType: "",
};
