import { useEffect, useMemo, useState } from "react";
import {
  RecruitingLayout,
  useRecruitingLayout,
} from "../../../../provider/RecruitingProvider";
import Tabs from "../../../../components/Tabs";
import Icon from "../../../../components/Icon";
import { InLine } from "../../../../components/StyledComponents";
import {
  Header,
  SectionIcon,
  Title,
  PrimarySubTitle,
  SecondSubtitle,
  Actions,
  Action,
  Tab,
  TabTitle,
  Description,
  Content,
  ListTitle,
  List,
  ListItem,
} from "../components/StyledComponents";
import { useDispatch } from "react-redux";
import recruiting from "../../../../store/actions/recruiting/";
import { showModal } from "../../../../store/actions/modal";
import ModalCreateSkills from "./components/ModalCreateSkills";
import ModalEditSkills from "./components/ModalEditSkills";
import TalentSearchIcon from "../../../../assets/icons/talent-search.svg";
import ShowModal from "../components/ShoModal";
import { TechSkillDto } from "../../../../types/recruiting.dto";
import { connect } from "react-redux";
import TabProfile from "./components/TabProfile";
import TapTechnologies from "./components/TapTechnologies";
import { useParams } from "react-router-dom";

interface ComponentProps {
  allTechSkills: TechSkillDto[];
  allTechSkillsStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  selected;
}

const Component = ({
  allTechSkills,
  allTechSkillsStates,
  selected,
}: ComponentProps) => {
  const { setRecruitingLayout, selectedItem } = useRecruitingLayout();
  const dispatch = useDispatch();
  const params = useParams();
  const EditarPerfil = () => (
    <Action onClick={() => dispatch(showModal("edit-skill-modal"))}>
      <Icon icon={TalentSearchIcon} size="16px" color="black" />
      Editar Perfil
    </Action>
  );

  const getAllItems = () => {
    dispatch(recruiting.techSkill.getAll());
  };

  const recruitingData = useMemo(
    (): RecruitingLayout => ({
      title: "Mis Skills Tecnicos",
      icon: "",
      model: "techSkill",
      externalIds: [],
      fieldName: "criterions",
      newProfileModal: "new-skill-modal",
      actions: recruiting.techSkill,
      list: allTechSkills
        ?.filter((techSkill) => techSkill.active)
        .filter((techSkill) => techSkill.team === params.id)
        .map((techSkill) => ({
          label: techSkill.name,
          value: techSkill._id,
          data: techSkill,
        })),
    }),
    [allTechSkills, selected, params]
  );

  useEffect(() => {
    setRecruitingLayout(recruitingData);
    getAllItems();
  }, [selected, params]);

  useEffect(() => {
    setRecruitingLayout(recruitingData);
  }, [allTechSkills, selected, params]);

  return (
    <>
      <Header>
        <InLine
          align="flex-start"
          justify="flex-start"
          style={{ marginBottom: "14px" }}
        >
          <SectionIcon>
            <Icon icon={TalentSearchIcon} size="16px" color="white" />
          </SectionIcon>

          <span>
            <Title>{selectedItem?.label}</Title>
          </span>
        </InLine>

        <InLine justify="flex-start">
          <EditarPerfil />
          <ShowModal.AsignarTecnologia />
        </InLine>
      </Header>

      <Tabs>
        <Tab id="perfil" label="Perfil">
          <TabProfile>
            <EditarPerfil></EditarPerfil>
          </TabProfile>
        </Tab>

        <Tab id="tecnologias" label="Tecnologias">
          <TapTechnologies></TapTechnologies>
        </Tab>
      </Tabs>

      <ModalCreateSkills />
      <ModalEditSkills />
    </>
  );
};

const states = ({ recruitingStore, teamStore }) => {
  const { data: allTechSkills, states: allTechSkillsStates } =
    recruitingStore.techSkill.all;
  const { data: selected } = teamStore.selected;
  return { allTechSkills, allTechSkillsStates, selected };
};

export default connect(states)(Component);
