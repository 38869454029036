import {
  initialState,
  Actions,
  State,
  GET_ALL_TEAMS,
  GET_ALL_TEAMS_ERROR,
  GET_ALL_TEAMS_SUCCESS,
  GET_TEAM,
  GET_TEAM_ERROR,
  GET_TEAM_SUCCESS,
  NEW_TEAM,
  NEW_TEAM_ERROR,
  NEW_TEAM_SUCCESS,
  RESET_NEW_TEAM,
  UPDATE_TEAM,
  UPDATE_TEAM_ERROR,
  UPDATE_TEAM_SUCCESS,
  RESET_UPDATE_TEAM,
  DELETE_TEAM,
  DELETE_TEAM_ERROR,
  DELETE_TEAM_SUCCESS,
  RESET_DELETE_TEAM,
  SET_UPDATE_TEAM_DATA,
  SET_NEW_TEAM_DATA,
  UPDATE_CURRENT_TEAM_LIST,
  NEW_TEAM_INVITE_LINK,
  DELETE_TEAM_INVITE_LINK_SUCCESS,
  NEW_TEAM_INVITE_LINK_SUCCESS,
  NEW_TEAM_INVITE_LINK_ERROR,
  DELETE_TEAM_INVITE_LINK,
  DELETE_TEAM_INVITE_LINK_ERROR,
  RESET_TEAM_DELETE_INVITE_LINK,
  RESET_TEAM_NEW_INVITE_LINK,
  SELECT_TEAM,
} from "../types/team";

const updateReducerArray = (array, data) =>
  array.map((elem) => (elem._id === data._id ? data : elem));

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_TEAMS:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_TEAMS_SUCCESS:
      return {
        ...state,
        all: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_TEAMS_ERROR:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case GET_TEAM:
      return {
        ...state,
        selected: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_TEAM_SUCCESS:
      return {
        ...state,
        selected: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_TEAM_ERROR:
      return {
        ...state,
        selected: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case NEW_TEAM:
      return {
        ...state,
        create: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_TEAM_SUCCESS:
      return {
        ...state,
        create: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case NEW_TEAM_INVITE_LINK:
      return {
        ...state,
        invite: {
          ...state.invite,
          create: {
            data: null,
            states: {
              loading: true,
              error: false,
              success: false,
            },
          },
        },
      };
    case NEW_TEAM_INVITE_LINK_SUCCESS:
      return {
        ...state,
        invite: {
          ...state.invite,
          create: {
            data: action.payload,
            states: {
              loading: false,
              error: false,
              success: true,
            },
          },
        },
      };
    case NEW_TEAM_INVITE_LINK_ERROR:
      return {
        ...state,
        invite: {
          ...state.invite,
          create: {
            data: null,
            states: {
              loading: false,
              error: true,
              success: false,
            },
          },
        },
      };
    case RESET_TEAM_NEW_INVITE_LINK:
      return {
        ...state,
        invite: {
          ...state.invite,
          create: {
            ...state.invite.create,
            states: {
              loading: false,
              error: false,
              success: false,
            },
          },
        },
      };
    case DELETE_TEAM_INVITE_LINK:
      return {
        ...state,
        invite: {
          ...state.invite,
          delete: {
            states: {
              loading: true,
              error: false,
              success: false,
            },
          },
        },
      };
    case DELETE_TEAM_INVITE_LINK_SUCCESS:
      return {
        ...state,
        invite: {
          ...state.invite,
          create: {
            ...state.invite.create,
            data: null,
          },
          delete: {
            states: {
              loading: false,
              error: false,
              success: true,
            },
          },
        },
      };
    case DELETE_TEAM_INVITE_LINK_ERROR:
      return {
        ...state,
        invite: {
          ...state.invite,
          delete: {
            states: {
              loading: false,
              error: true,
              success: false,
            },
          },
        },
      };
    case RESET_TEAM_DELETE_INVITE_LINK:
      return {
        ...state,
        invite: {
          ...state.invite,
          delete: initialState.invite.delete,
        },
      };
    case SET_NEW_TEAM_DATA:
      return {
        ...state,
        all: {
          ...state.all,
          data: !!state.all.data
            ? [...state.all.data, action.payload]
            : [action.payload],
        },
      };
    case UPDATE_CURRENT_TEAM_LIST:
      return {
        ...state,
        all: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: false,
          },
        },
      };
    case SET_UPDATE_TEAM_DATA:
      return {
        ...state,
        all: {
          ...state.all,
          data: !!state.all.data
            ? updateReducerArray(state.all.data, action.payload)
            : null,
        },
      };
    case NEW_TEAM_ERROR:
      return {
        ...state,
        create: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_TEAM:
      return {
        ...state,
        create: initialState.create,
      };
    case DELETE_TEAM:
      return {
        ...state,
        delete: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_TEAM_SUCCESS:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case SELECT_TEAM: {
      return {
        ...state,
        selected: {
          ...state.selected,
          data: action.payload,
          states: {
            success: true,
            error: false,
            loading: false,
          },
        },
      };
    }
    case DELETE_TEAM_ERROR:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_TEAM:
      return {
        ...state,
        delete: initialState.delete,
      };
    case UPDATE_TEAM:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_TEAM_SUCCESS:
      return {
        ...state,
        update: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_TEAM_ERROR:
      return {
        ...state,
        update: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_TEAM:
      return {
        ...state,
        update: initialState.update,
      };
    default:
      return state;
  }
};

export default reducers;
