import styled from "styled-components";

export const LabelToggle = styled("label")`
  position: relative;
  display: inline-block;
  width: 35px;
  height: 18px;
  border-radius: 10px;
`;

export const ToggleInput = styled("input")`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + span::before {
    transform: translateX(15px);
  }
  &:checked + span {
    background-color: #2196f3;
  }
`;

export const ToggleSlider = styled("span")`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 10px;
  transition: background-color 0.3s ease-in-out;
  &:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 4px;
    background-color: #fff;
    border-radius: 50%;

    transition: transform 0.2s ease-in-out;
  }
`;
