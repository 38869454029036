import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Field, Formik } from "formik";
import Input from "../../../../components/Form/Input";
import Submit from "../../../../components/Form/Submit";
import ReactSelect from "../../../../components/Form/ReactSelect";
import { initialValues, schema } from "../../../../constants/form/cohort/edit";
import {
  resetUpdateCohort,
  updateCohort,
} from "../../../../store/actions/cohort";
import { CohortDto } from "../../../../types/cohort.dto";
import { eventsTypes } from "../../../../constants/events-types";
import UploadIcon from "../../../../assets/icons/cloud-upload.svg";
import { getAllEvents } from "../../../../store/actions/event";
import { getAllAbilities } from "../../../../store/actions/ability";
import { AbilityDto } from "../../../../types/ability.dto";
import { EventDto } from "../../../../types/event.dto";
import Button from "../../../../components/Button";
import { hideModal } from "../../../../store/actions/modal";
import { UserDto } from "../../../../types/user.dto";
import { getAllMentors } from "../../../../store/actions/mentor";
import { Center, FlexContainer } from "../../../../components/StyledComponents";
import { CurrentDto } from "../../../../types/current.dto";
import { InLine } from "../../../../components/Form/Field";
import { minInputDate } from "../../../../helpers/data-handler";
import DinamicCreateable from "../../../../components/Form/DinamicCreateable";
import DinamicField from "../../../../components/Form/DinamicField";
import FileUploader from "../../../../components/Form/FileUploader";
import Icon from "../../../../components/Icon";
import { AcademyDto } from "../../../../types/academy.dto";
import { UsersDto } from "../../../../types/users.dto";
import moment from "moment";
import { toast } from "react-toastify";
import { LetterModal } from "../../Video/Creator/components/PublishVideoModal/style";
import { TagDto } from "../../../../types/tag.dto";
import Loader from "../../../../components/Loader";
import {
  getAllTags,
  newManyTags,
  updateManyTags,
} from "../../../../store/actions/tag";

interface ComponentProps {
  cohort: CohortDto;
  abilities: AbilityDto[];
  events: EventDto[];
  mentors: UserDto[];
  updateCohortStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  current: CurrentDto;
  cohorts: CohortDto[];
  user: UserDto;
  academies: AcademyDto[];
  users: UsersDto[];
  tags: TagDto[];
  updatedCohortData: CohortDto;
}

const Component = ({
  cohort,
  abilities,
  mentors,
  events,
  updateCohortStates,
  current,
  cohorts,
  user,
  academies,
  users,
  tags,
  updatedCohortData,
}: ComponentProps) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [type, setType] = useState<string>();
  const [currentTags, setCurrentTags] = useState<any[]>([]);
  const [currentTypeEvents, setCurrentTypeEvents] = useState<EventDto[]>();
  const [currentTypeAbilities, setCurrentTypeAbilities] =
    useState<AbilityDto[]>();
  const [avatarPreview, setAvatarPreview] = useState<HTMLInputElement>();
  const [bannerPreview, setBannerPreview] = useState<HTMLInputElement>();
  const dispatch = useDispatch();
  const onSubmit = ({ values, actions }) => {
    const { tags, ...rest } = values;
    const formValues = {
      ...rest,
      ...(!!avatarPreview &&
        !!avatarPreview?.files && {
          picture: avatarPreview?.files[0],
        }),
      ...(!!bannerPreview &&
        !!bannerPreview?.files && {
          bannerPic: bannerPreview?.files[0],
        }),
      abilitiesIds: rest.abilitiesIds.map((ability) =>
        !!ability.value ? ability.value : ability
      ),
    };
    dispatch(updateCohort({ _id: cohort._id, ...formValues }));
  };

  const closeModal = () => {
    dispatch(hideModal());
  };

  useEffect(() => {
    if (!!cohort) {
      setType(cohort?.type);
    }
  }, [cohort]);

  useEffect(() => {
    if (abilities) {
      setCurrentTypeAbilities(
        abilities.filter(
          (ability) =>
            ((!!ability.academy && ability.academy === current.id) ||
              !ability.academy) &&
            !!ability.active
        )
      );
    }
  }, [abilities]);

  useEffect(() => {
    if (!!events) {
      setCurrentTypeEvents(
        events.filter((event) => event.active && event.academy === current.id)
      );
    }
  }, [events]);

  useEffect(() => {
    if (cohort && tags) {
      setCurrentTags(
        tags
          .filter((tag) => tag.cohorts.includes(cohort._id))
          .map((tag) => tag._id)
      );
    }
  }, [cohort, tags]);

  useEffect(() => {
    if (updateCohortStates.success) {
      const newTagsItems = currentTags
        .filter((tag) => !tags.find((tagFounded) => tagFounded._id === tag))
        .map((tag) => {
          return {
            name: tag,
            academy: current.id,
            cohorts: [updatedCohortData._id],
          };
        });
      const externalTagsAdded = tags
        .filter(
          (externalTag) =>
            !externalTag.cohorts.includes(cohort._id) &&
            currentTags.includes(externalTag._id)
        )
        .map((externalTag) => {
          return {
            _id: externalTag._id,
            cohorts: [...(externalTag.cohorts ?? []), cohort._id],
          };
        });
      const tagsFromCohortRemoved = tags
        .filter(
          (tag) =>
            tag.cohorts.includes(cohort._id) && !currentTags.includes(tag._id)
        )
        .map((tag) => {
          return {
            _id: tag._id,
            cohorts: (tag.cohorts ?? []).filter(
              (tagCohort) => tagCohort !== cohort._id
            ),
          };
        });

      const updateItems = [...externalTagsAdded, ...tagsFromCohortRemoved];

      /* 
        
        
         return {
            _id: tag._id,
            cohorts:  tagFounded ? [...(tagFounded?.cohorts ?? []), updatedCohortData._id] : tag.cohorts.filter(cohortFounded=>cohortFounded !== cohort._id),
          };
        */
      if (newTagsItems.length > 0)
        dispatch(newManyTags({ items: newTagsItems }));
      if (updateItems.length > 0) {
        dispatch(updateManyTags({ items: updateItems }));
      }
      setTimeout(() => {
        dispatch(hideModal());
        dispatch(resetUpdateCohort());
      }, 1000);
    }
  }, [updateCohortStates]);

  useEffect(() => {
    if (cohort) {
      dispatch(getAllTags({ filterBy: { cohorts: cohort._id } }));
    }
  }, [cohort]);

  // if (!currentTypeEvents && !currentTypeAbilities) {
  //   return <Loader />;
  // }
  return (
    <>
      {!tags ? (
        <Loader color="Primary"></Loader>
      ) : (
        <Formik
          initialValues={{
            ...initialValues,
            name: cohort.name,
            private: cohort.private,
            tags: tags
              .filter((tagFounded) => tagFounded.cohorts.includes(cohort._id))
              .map((tag) => {
                return {
                  label: tag.name,
                  value: tag._id,
                };
              }),
            mentorsIds: cohort.mentorsIds,
            abilitiesIds: cohort.abilityId ? [cohort.abilityId] : [],
            type: cohort.type,
            hidden: cohort.hidden,
            classes: cohort.classes || 1,
            startDate: moment
              .utc(cohort.startDate.toLocaleString())
              .format("YYYY-MM-DD"),
            endDate: moment
              .utc(cohort.endDate.toLocaleString())
              .format("YYYY-MM-DD"),
            maxStage: cohort.maxStage,
            eventsIds: cohort.eventsIds,
          }}
          onSubmit={(values, actions) => {
            if (onSubmit) onSubmit({ values, actions });
          }}
          validateOnChange={formSubmmited}
          validateOnBlur={false}
          validationSchema={schema}
          enableReinitialize
        >
          {({
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => {
            return (
              <form
                className="theme-form"
                onSubmit={(event) => {
                  Object.entries(errors).forEach((item) => {
                    toast.error(item[1] as string);
                  });
                  setFormSubmmited(true);
                  handleSubmit(event);
                }}
                id="edit-cohort-form"
              >
                <Input
                  name="name"
                  error={errors["name"]}
                  touched={touched["name"]}
                  value={values["name"]}
                  type="text"
                  placeholder=""
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={{
                    skin: "gray",
                    label: "Nombre del cohort",
                    marginBottom: 20,
                  }}
                />
                <FileUploader
                  name="picture"
                  error={errors["picture"]}
                  touched={touched["picture"]}
                  placeholder={
                    <Icon icon={UploadIcon} size="24px" color="black" />
                  }
                  onChange={(event) => {
                    setAvatarPreview(event.target);
                    handleChange(event);
                  }}
                  onBlur={handleBlur}
                  accept="image/jpg;image/png;image/jpeg;capture=camera"
                  options={{
                    label: "Foto del cohort",
                    marginBottom: 24,
                  }}
                />
                <FileUploader
                  name="bannerPic"
                  error={errors["bannerPic"]}
                  touched={touched["bannerPic"]}
                  placeholder={
                    <Icon icon={UploadIcon} size="24px" color="black" />
                  }
                  onChange={(event) => {
                    setBannerPreview(event.target);
                    handleChange(event);
                  }}
                  onBlur={handleBlur}
                  accept="image/jpg;image/png;image/jpeg;capture=camera"
                  options={{
                    label: "Banner del Cohort",
                    marginBottom: 24,
                  }}
                />

                <ReactSelect
                  name="private"
                  error={errors["private"]}
                  touched={touched["private"]}
                  items={[
                    { label: "Publico", value: false },
                    { label: "Privado", value: true },
                  ]}
                  placeholder="Seleccione un tipo"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={{
                    skin: "gray",

                    label: "Acceso al Cohort",
                    marginBottom: 20,
                  }}
                />
                {!!values["private"] && (
                  <FlexContainer justify="space-between">
                    <FlexContainer
                      direction="column"
                      gap="2px"
                      style={{
                        maxWidth: "300px",
                        marginBottom: "20px",
                        padding: "0 0 0 20px",
                      }}
                    >
                      <FlexContainer align="center" gap="1px">
                        <LetterModal lg>
                          Acceso al cohort solo via invitacion
                        </LetterModal>
                      </FlexContainer>
                      <LetterModal gray>
                        Los usuarios acceden solo por invitacion
                      </LetterModal>
                    </FlexContainer>
                    <Field
                      style={{
                        width: "20px",
                        height: "20px",
                        boxShadow: "0 0 4px 0 rgba(21, 115, 229, 0.16)",
                      }}
                      type="checkbox"
                      name="hidden"
                    />
                  </FlexContainer>
                )}
                <DinamicCreateable
                  name="tags"
                  error={errors["tags"]}
                  touched={touched["tags"]}
                  value={values["tags"]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={{
                    onOptionSelected(item, index, fieldValue) {
                      const itemValue = item.value ? item.value : item;
                      setCurrentTags((state) => [...state, itemValue]);
                    },
                    onRemoveItem(item, index, fieldValue) {
                      const itemValue = item.value ? item.value : item;
                      setCurrentTags((state) =>
                        state.filter((st) => st !== itemValue)
                      );
                    },
                    height: "95px",
                    type: "select",
                    skin: "gray",
                    data: tags?.map((tag) => {
                      return {
                        label: tag.name,
                        value: tag._id,
                      };
                    }),
                    externalData: true,
                    loading: !tags,
                    inputLabel: "Etiquetas",
                    inputPlaceholder: "Crea o selecciona etiquetas",
                    multi: true,
                  }}
                />
                <DinamicField
                  name="mentorsIds"
                  error={errors["mentorsIds"]}
                  touched={touched["mentorsIds"]}
                  value={values["mentorsIds"]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={{
                    skin: "gray",
                    type: "select",
                    height: "95px",
                    ...(!!users && {
                      data: users.map((userAcademy) => ({
                        label:
                          `${userAcademy.name} ${userAcademy.lastName}` || "",
                        value: userAcademy._id,
                      })),
                    }),
                    loading: !users,
                    inputLabel: "Mentores",
                    inputPlaceholder: "Selecciona un mentor",
                  }}
                />

                <DinamicCreateable
                  name="abilitiesIds"
                  error={errors["abilitiesIds"]}
                  touched={touched["abilitiesIds"]}
                  value={values["abilitiesIds"]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={{
                    height: "95px",
                    type: "select",
                    skin: "gray",
                    data:
                      currentTypeAbilities &&
                      currentTypeAbilities.map((cohort) => ({
                        label: cohort.name,
                        value: cohort._id,
                      })),
                    externalData: true,
                    loading: !currentTypeAbilities,
                    inputLabel: "Habilidades",
                    inputPlaceholder: "Crea o selecciona habilidades",
                    multi: true,
                  }}
                />

                <ReactSelect
                  name="type"
                  error={errors["type"]}
                  touched={touched["type"]}
                  items={[
                    {
                      label: "Asincronico",
                      value: "ASYNCHRONOUS",
                    },
                    {
                      label: "Sincronico",
                      value: "SYNCHRONOUS",
                    },
                    {
                      label: "Mixto",
                      value: "MIXED",
                    },
                  ]}
                  placeholder="Tipo cohort"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={{
                    skin: "gray",
                    label: "Tipo de Cohort",
                    marginBottom: 20,
                  }}
                />
                <Input
                  name="classes"
                  error={errors["classes"]}
                  touched={touched["classes"]}
                  value={values["classes"]}
                  type="number"
                  placeholder=""
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={{
                    skin: "gray",
                    label: "Cantidad de clases",
                    marginBottom: 20,
                  }}
                />
                <InLine template="1fr 1fr">
                  <Input
                    name="startDate"
                    error={errors["startDate"]}
                    touched={touched["startDate"]}
                    value={values["startDate"]}
                    type="date"
                    min={moment
                      .utc(cohort.startDate.toLocaleString())
                      .format("YYYY-MM-DD")}
                    placeholder="Fecha inicio"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      skin: "gray",
                      label: "Fecha inicio",
                      marginBottom: 20,
                    }}
                  />
                  <Input
                    name="endDate"
                    error={errors["endDate"]}
                    touched={touched["endDate"]}
                    value={values["endDate"]}
                    type="date"
                    min={moment
                      .utc(cohort.startDate.toLocaleString())
                      .format("YYYY-MM-DD")}
                    placeholder="Fecha de Cierre"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "Fecha de Cierre",
                      marginBottom: 20,
                      skin: "gray",
                    }}
                  />
                </InLine>

                <ReactSelect
                  name="maxStage"
                  error={errors["maxStage"]}
                  touched={touched["maxStage"]}
                  items={Array.from(Array(48).keys()).map((number) => {
                    return {
                      label: (number + 1).toString(),
                      value: number + 1,
                    };
                  })}
                  placeholder="Seleccione el numero de etapas"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={{
                    label: "Etapas",
                    marginBottom: 20,
                    skin: "gray",
                  }}
                />

                <ReactSelect
                  name="eventsIds"
                  error={errors["eventsIds"]}
                  touched={touched["eventsIds"]}
                  isMulti
                  {...(!!currentTypeEvents && {
                    items: currentTypeEvents?.map((event) => ({
                      label: event.name,
                      value: event._id,
                    })),
                  })}
                  disabled={!events}
                  placeholder="Selecciona un evento"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={{
                    label: "Eventos",
                    marginBottom: 50,
                    skin: "gray",
                  }}
                />
                <Center>
                  <Submit
                    isSubmmiting={isSubmitting}
                    form="edit-cohort-form"
                    color="Primary"
                    options={{
                      type: "filled",
                      skin: "primary",
                      size: "lg",
                      marginBottom: "0px",
                    }}
                  >
                    Confirmar
                  </Submit>
                  <Button
                    onClick={closeModal}
                    type="button"
                    options={{
                      type: "outline",
                      skin: "danger",
                      size: "lg",
                      marginBottom: "0px",
                    }}
                    style={{ marginLeft: "10px" }}
                  >
                    Cancelar
                  </Button>
                </Center>
              </form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

const states = ({
  cohortStore,
  abilityStore,
  eventStore,
  mentorStore,
  currentStore,
  userStore,
  academyStore,
  usersStore,
}) => {
  const { data: mentors } = mentorStore.allMentors;
  const { data: abilities } = abilityStore.allAbilities;
  const { data: events } = eventStore.allEvents;
  const { states: updateCohortStates, data: updatedCohortData } =
    cohortStore.updateCohort;
  const { data: current } = currentStore;
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: user } = userStore;
  const { data: academies } = academyStore.all;
  const { data: users } = usersStore.all;
  return {
    abilities,
    events,
    mentors,
    updateCohortStates,
    users,
    current,
    cohorts,
    user,
    academies,
    updatedCohortData,
  };
};

export default connect(states)(Component);
