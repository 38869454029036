import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Modal from "../../../../../../components/Modal";
import { Formik } from "formik";
import {
  Center,
  FlexContainer,
  Separator,
} from "../../../../../../components/StyledComponents";
import {
  initialValues,
  schema,
} from "../../../../../../constants/form/suscription/cohort/new";
import Grid from "../../../../../../components/Grid";
import Button from "../../../../../../components/Button";
import Submit from "../../../../../../components/Form/Submit";
import { toast } from "react-toastify";
import associationActions from "../../../../../../store/actions/association";
import { hideModal } from "../../../../../../store/actions/modal";
import { AiOutlineSearch } from "react-icons/ai";
import { AcademyDto } from "../../../../../../types/academy.dto";
import { CurrentDto } from "../../../../../../types/current.dto";
import DinamicField from "../../../../../../components/Form/DinamicField";
import { OrganizationDto } from "../../../../../../types/organization.dto";
import { SuscriptionPrice, SuscriptionText } from "../../style";
import academyActions from "../../../../../../store/actions/academies";
import { ToggleButtonWrapper, ToggleInput, ToggleSlider } from "./style";
import Input from "../../../../../../components/Form/Input";
import { StateDto } from "../../../../../../types/states.dto";
import {
  newCohortPlan,
  updateCohort,
} from "../../../../../../store/actions/cohort";
import { CohortDto } from "../../../../../../types/cohort.dto";
import { newPlan } from "../../../../../../store/actions/plan";

const Component = ({
  cohortActiveItem,
  current,
  updateCohortStates,
  modalName,
  newPlanStates,
}: {
  cohortActiveItem: CohortDto;
  current: CurrentDto;
  updateCohortStates: StateDto;
  modalName: string;
  newPlanStates: StateDto;
}) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const dispatch = useDispatch();

  const onSubmit = ({ values, actions }) => {
    dispatch(
      newPlan({
        cohort: cohortActiveItem._id,
        unitPrice: values.unitPrice,
        currencyId: "ARS",
        type: values.isUnique ? "UNIQUE" : "SUBSCRIPTION",
      })
    );
    setFormActions(actions);
  };

  useEffect(() => {
    if (newPlanStates.success && modalName === "add-plan-modal-cohort") {
      setTimeout(() => {
        dispatch(hideModal());
        formActions?.resetForm();
      }, 1000);
    }
  }, [newPlanStates]);

  return (
    <Modal name="add-plan-modal-cohort" title={`Crear plan de monetización`}>
      <Formik
        initialValues={{
          ...initialValues,
        }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="invite-academy-modal-form"
            >
              <Grid.Row style={{ alignItems: "center" }}>
                <Grid.Col>
                  <FlexContainer align="center" gap="6px">
                    <SuscriptionText size={14}>monthly</SuscriptionText>
                    <ToggleButtonWrapper>
                      <ToggleInput
                        value={values["isUnique"]}
                        onChange={() => {
                          setFieldValue("isUnique", !values["isUnique"]);
                        }}
                        checked={values["isUnique"]}
                        type="checkbox"
                      ></ToggleInput>
                      <ToggleSlider></ToggleSlider>
                    </ToggleButtonWrapper>
                    <SuscriptionText size={14}>unique</SuscriptionText>
                  </FlexContainer>
                </Grid.Col>
                <Grid.Col>
                  <SuscriptionText size={12}>
                    (Ten en cuenta que no puedes pasarte de “unique” a monthly
                    una vez creado)
                  </SuscriptionText>
                </Grid.Col>
              </Grid.Row>
              <Separator size={12}></Separator>
              <Grid.Row>
                <Grid.Col>
                  <SuscriptionText size={14}>
                    {`Pago ${
                      values["isUnique"] ? "unico" : "mensual"
                    } para el acceso a mi Academia`}
                  </SuscriptionText>
                  <Separator size={10}></Separator>
                  <FlexContainer
                    gap="7px"
                    align="center"
                    justify="center"
                    wrap="wrap"
                  >
                    <Input
                      name="unitPrice"
                      error={errors["unitPrice"]}
                      touched={touched["unitPrice"]}
                      value={values["unitPrice"]}
                      type="number"
                      placeholder="Ingresa monto"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={{
                        marginBottom: 0,
                        skin: "base",
                        before: (
                          <span
                            style={{
                              display: "inline-block",
                              paddingLeft: "10px",
                            }}
                          >
                            ARS$
                          </span>
                        ),
                      }}
                    />
                  </FlexContainer>
                  <Separator size={14}></Separator>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col>
                  <Center gap={"70px"}>
                    <Submit
                      isSubmmiting={newPlanStates.loading}
                      form="invite-academy-modal-form"
                      color="Primary"
                      options={{
                        type: "filled",
                        skin: "primary",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                    >
                      Confirmar
                    </Submit>
                    <Button
                      onClick={resetForm}
                      type="button"
                      options={{
                        type: "filled",
                        skin: "danger",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                      style={{ marginLeft: "10px" }}
                    >
                      Deshacer
                    </Button>
                  </Center>
                </Grid.Col>
              </Grid.Row>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const states = ({
  associationStore,
  cohortStore,
  currentStore,
  organizationStore,
  modalStore,
  planStore,
}) => {
  const { states: updateCohortStates } = cohortStore.updateCohort;
  const { data: current } = currentStore;
  const { visibleModal: modalName } = modalStore;
  const { states: newPlanStates } = planStore.new;
  return { current, updateCohortStates, modalName, newPlanStates };
};

export default connect(states)(Component);
