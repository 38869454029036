import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import CrossIcon from "../../../../../../assets/icons/cross.svg";
import Icon from "../../../../../../components/Icon";
import { InLine } from "../../../../../../components/StyledComponents";
import { useRecruitingLayout } from "../../../../../../provider/RecruitingProvider";
import {
  TechnologyDto,
  TechSkillDto,
} from "../../../../../../types/recruiting.dto";
import ShowModal from "../../../components/ShoModal";
import {
  Tab,
  TabTitle,
  Description,
  Content,
  ListTitle,
  List,
  ListItem,
  Delete,
} from "../../../components/StyledComponents";

interface ComponentsProps {
  allTechnologies: TechnologyDto[];
  allSkills: TechSkillDto[];
}

const Component = ({ allTechnologies, allSkills }: ComponentsProps) => {
  const { actions, selectedItem } = useRecruitingLayout();
  const dispatch = useDispatch();
  const params = useParams();
  return (
    <>
      <InLine justify="space-between">
        <TabTitle>Asignar Tecnologias a Búsqueda</TabTitle>
        <ShowModal.AsignarTecnologia />
      </InLine>

      <Description>
        Podras asignar tecnologias a este skill. Encontraras tecnologias que
        ofrece la TAAP pero también podrás encontrar y asignar las tecnologias
        creados por vos.
      </Description>

      <Content>
        <ListTitle>Tecnología</ListTitle>

        <List>
          {!!allTechnologies &&
            allTechnologies
              ?.filter((technology) => technology.active)
              .filter((ability) => ability.team === params.id)
              ?.filter((technology) =>
                selectedItem?.data?.abilities?.includes(technology._id)
              )
              .map((item) => (
                <ListItem>
                  {item.name}
                  <Delete
                    onClick={() =>
                      dispatch(
                        actions.update({
                          ...selectedItem?.data,
                          abilities: selectedItem?.data?.abilities?.filter(
                            (ability) => ability !== item._id
                          ),
                        })
                      )
                    }
                  >
                    <Icon icon={CrossIcon} size="9px" color="black" />
                  </Delete>
                </ListItem>
              ))}
        </List>
      </Content>
    </>
  );
};

const states = ({ recruitingStore }) => {
  const { data: allTechnologies } = recruitingStore.technology.all;
  const { data: allSkills } = recruitingStore.techSkill.all;
  return {
    allTechnologies,
    allSkills,
  };
};

export default connect(states)(Component);
