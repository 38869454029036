import * as Yup from "yup";

interface FormValues {
  name: string;
  description: string;
}

export const schema = Yup.object().shape({
  name: Yup.string().required("Ingresar minimo un caracter"),
  description: Yup.string(),
});

export const initialValues = (initialValues = {}): FormValues => {
  return {
    name: "",
    description: "",
    ...initialValues,
  };
};
