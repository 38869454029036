import {
  initialState,
  Actions,
  State,
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_IN_ERROR,
  SIGN_OUT,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_ERROR,
  RESET_SIGN_IN,
  SIGN_UP,
  RESET_SIGN_UP,
  SIGN_UP_ERROR,
  SIGN_UP_SUCCESS,
  RECOVER_PASSWORD,
  RECOVER_PASSWORD_SUCCESS,
  RECOVER_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  SIGN_IN_WITH_SOCIAL,
  SIGN_UP_WITH_SOCIAL,
} from "../types/auth";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
     case SIGN_IN_WITH_SOCIAL:
      return {
        ...state,
        signin: {
          states: {
            loading: true,
            success: false,
            error: false,
          },
        },
      };
    case SIGN_IN:
      return {
        ...state,
        signin: {
          states: {
            loading: true,
            success: false,
            error: false,
          },
        },
      };
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        signin: {
          states: {
            loading: false,
            success: true,
            error: false,
          },
        },
      };
    case RESET_SIGN_IN:
      return {
        ...state,
        signin: initialState.signin,
      };
    case SIGN_IN_ERROR:
      return {
        ...state,
        signin: {
          states: {
            loading: false,
            success: false,
            error: action.payload,
          },
        },
      };
      case SIGN_UP_WITH_SOCIAL:
      return {
        ...state,
        signup: {
          states: {
            loading: true,
            success: false,
            error: false,
          },
        },
      };
    case SIGN_UP:
      return {
        ...state,
        signup: {
          states: {
            loading: true,
            success: false,
            error: false,
          },
        },
      };
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        signup: {
          states: {
            loading: false,
            success: true,
            error: false,
          },
        },
      };
    case RESET_SIGN_UP:
      return {
        ...state,
        signup: initialState.signup,
      };
    case SIGN_UP_ERROR:
      return {
        ...state,
        signup: {
          states: {
            loading: false,
            success: false,
            error: action.payload,
          },
        },
      };
    case SIGN_OUT:
      return {
        ...state,
        signout: {
          states: {
            loading: true,
            success: false,
            error: false,
          },
        },
      };
    case SIGN_OUT_SUCCESS:
      return {
        ...state,
        signout: {
          states: {
            loading: false,
            success: true,
            error: false,
          },
        },
      };
    case SIGN_OUT_ERROR:
      return {
        ...state,
        signout: {
          states: {
            loading: false,
            success: false,
            error: action.payload,
          },
        },
      };
    case RECOVER_PASSWORD:
      return {
        ...state,
        recoverPassword: {
          data: null,
          states: {
            loading: true,
            success: false,
            error: false,
          },
        },
      };
    case RECOVER_PASSWORD_SUCCESS:
      return {
        ...state,
        recoverPassword: {
          data: action.payload,
          states: {
            loading: false,
            success: true,
            error: false,
          },
        },
      };
    case RECOVER_PASSWORD_ERROR:
      return {
        ...state,
        recoverPassword: {
          data: null,
          states: {
            loading: false,
            success: false,
            error: action.payload,
          },
        },
      };
    case RESET_PASSWORD:
      return {
        ...state,
        resetPassword: {
          data: null,
          states: {
            loading: true,
            success: false,
            error: false,
          },
        },
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassword: {
          data: action.payload,
          states: {
            loading: false,
            success: true,
            error: false,
          },
        },
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        resetPassword: {
          data: null,
          states: {
            loading: false,
            success: false,
            error: action.payload,
          },
        },
      };
    default:
      return state;
  }
};

export default reducers;
