import {
  initialState,
  Actions,
  State,
  GET_ALL_ACADEMIES,
  GET_ALL_ACADEMIES_ERROR,
  GET_ALL_ACADEMIES_SUCCESS,
  GET_ACADEMY,
  GET_ACADEMY_ERROR,
  GET_ACADEMY_SUCCESS,
  NEW_ACADEMY,
  NEW_ACADEMY_ERROR,
  NEW_ACADEMY_SUCCESS,
  RESET_NEW_ACADEMY,
  UPDATE_ACADEMY,
  UPDATE_ACADEMY_ERROR,
  UPDATE_ACADEMY_SUCCESS,
  RESET_UPDATE_ACADEMY,
  DELETE_ACADEMY,
  DELETE_ACADEMY_ERROR,
  DELETE_ACADEMY_SUCCESS,
  RESET_DELETE_ACADEMY,
  SET_UPDATE_ACADEMY_DATA,
  SET_NEW_ACADEMY_DATA,
  UPDATE_CURRENT_ACADEMY_LIST,
  NEW_INVITE_LINK,
  DELETE_INVITE_LINK_SUCCESS,
  NEW_INVITE_LINK_SUCCESS,
  NEW_INVITE_LINK_ERROR,
  DELETE_INVITE_LINK,
  DELETE_INVITE_LINK_ERROR,
  RESET_DELETE_INVITE_LINK,
  RESET_NEW_INVITE_LINK,
  SELECT_ACADEMY,
  DELETE_ACADEMY_MEMBER,
  ACADEMY_DISSOCIATE_ORGANIZATION,
  ADD_ACADEMY_MEMBER,
  RESET_GET_ALL_ACADEMIES_DATA,
  SET_ACADEMY_MP,
  RESET_GET_ACADEMY,
  UPDATE_ACADEMY_PLAN,
  NEW_ACADEMY_PLAN,
} from "../types/academy";

const updateReducerArray = (array, data) =>
  array.map((elem) => (elem._id === data._id ? data : elem));

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_ACADEMIES:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_ACADEMIES_SUCCESS:
      return {
        ...state,
        all: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_ACADEMIES_ERROR:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_GET_ALL_ACADEMIES_DATA:
      return {
        ...state,
        all: initialState.all,
      };
    case ACADEMY_DISSOCIATE_ORGANIZATION:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ACADEMY:
      return {
        ...state,
        selected: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ACADEMY_SUCCESS:
      return {
        ...state,
        selected: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ACADEMY_ERROR:
      return {
        ...state,
        selected: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_GET_ACADEMY:
      return {
        ...state,
        selected: initialState.selected,
      };
    case ADD_ACADEMY_MEMBER:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            success: false,
            error: false,
          },
        },
      };
    case NEW_ACADEMY:
      return {
        ...state,
        create: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_ACADEMY_SUCCESS:
      return {
        ...state,
        create: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_ACADEMY_MEMBER:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case SELECT_ACADEMY:
      return {
        ...state,
        selected: {
          ...state.selected,
          data: action.payload,
          states: {
            success: true,
            error: false,
            loading: false,
          },
        },
      };
    case NEW_INVITE_LINK:
      return {
        ...state,
        invite: {
          ...state.invite,
          create: {
            data: null,
            states: {
              loading: true,
              error: false,
              success: false,
            },
          },
        },
      };
    case NEW_INVITE_LINK_SUCCESS:
      return {
        ...state,
        invite: {
          ...state.invite,
          create: {
            data: action.payload,
            states: {
              loading: false,
              error: false,
              success: true,
            },
          },
        },
      };
    case NEW_INVITE_LINK_ERROR:
      return {
        ...state,
        invite: {
          ...state.invite,
          create: {
            data: null,
            states: {
              loading: false,
              error: true,
              success: false,
            },
          },
        },
      };
    case RESET_NEW_INVITE_LINK:
      return {
        ...state,
        invite: {
          ...state.invite,
          create: {
            ...state.invite.create,
            states: {
              loading: false,
              error: false,
              success: false,
            },
          },
        },
      };
    case DELETE_INVITE_LINK:
      return {
        ...state,
        invite: {
          ...state.invite,
          delete: {
            states: {
              loading: true,
              error: false,
              success: false,
            },
          },
        },
      };
    case DELETE_INVITE_LINK_SUCCESS:
      return {
        ...state,
        invite: {
          ...state.invite,
          create: {
            ...state.invite.create,
            data: null,
          },
          delete: {
            states: {
              loading: false,
              error: false,
              success: true,
            },
          },
        },
      };
    case DELETE_INVITE_LINK_ERROR:
      return {
        ...state,
        invite: {
          ...state.invite,
          delete: {
            states: {
              loading: false,
              error: true,
              success: false,
            },
          },
        },
      };
    case RESET_DELETE_INVITE_LINK:
      return {
        ...state,
        invite: {
          ...state.invite,
          delete: initialState.invite.delete,
        },
      };
    case SET_NEW_ACADEMY_DATA:
      return {
        ...state,
        all: {
          ...state.all,
          data: !!state.all.data
            ? [...state.all.data, action.payload]
            : [action.payload],
        },
      };
    case UPDATE_CURRENT_ACADEMY_LIST:
      return {
        ...state,
        all: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: false,
          },
        },
      };
    case SET_UPDATE_ACADEMY_DATA:
      return {
        ...state,
        all: {
          ...state.all,
          data: !!state.all.data
            ? updateReducerArray(state.all.data, action.payload)
            : null,
        },
      };
    case NEW_ACADEMY_ERROR:
      return {
        ...state,
        create: {
          ...state.create,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_ACADEMY:
      return {
        ...state,
        create: initialState.create,
      };
    case DELETE_ACADEMY:
      return {
        ...state,
        delete: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_ACADEMY_SUCCESS:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_ACADEMY_ERROR:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_ACADEMY:
      return {
        ...state,
        delete: initialState.delete,
      };
    case UPDATE_ACADEMY:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case SET_ACADEMY_MP:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_ACADEMY_PLAN:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_ACADEMY_PLAN:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_ACADEMY_SUCCESS:
      return {
        ...state,
        update: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_ACADEMY_ERROR:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_ACADEMY:
      return {
        ...state,
        update: initialState.update,
      };
    default:
      return state;
  }
};

export default reducers;
