import React from "react";
import { LabelToggle, ToggleInput, ToggleSlider } from "./style";

const Component = ({
  setActive,
  checkedValue,
  unCheckedValue,
  field,
  value,
  checked,
  handleChange,
}) => {
  return (
    <LabelToggle>
      <ToggleInput
        type="checkbox"
        checked={checked}
        onChange={handleChange}
      ></ToggleInput>
      <ToggleSlider></ToggleSlider>
    </LabelToggle>
  );
};

export default Component;
