import action from "../helpers/rest-client";

export class ErrorTalent extends Error {}

class Talent {
  public async new(payload) {
    const formData = new FormData();

    for (let key in payload) {
      const currentElement = payload[key];
      if (key !== "curriculum" && key !== "avatar" && currentElement) {
        formData.append(key, currentElement);
      }
    }

    for (let key in payload) {
      const currentElement = payload[key];

      if (key === "curriculum" && currentElement) {
        if (typeof currentElement !== "string") {
          formData.append("curriculum", currentElement, currentElement.name);
        } else {
          formData.append(key, currentElement);
        }
      }

      if (key === "avatar" && currentElement) {
        if (typeof currentElement !== "string") {
          formData.append("avatar", currentElement, currentElement.name);
        } else {
          formData.append(key, currentElement);
        }
      }
    }
    return action
      .Post({
        url: "/talent",
        body: formData,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public addView({ _id, ...rest }) {
    return action
      .Patch({
        url: `/talent/add-view/${_id}`,
        body: rest,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return { error };
      });
  }
  public addClick({ _id, ...rest }) {
    return action
      .Patch({
        url: `/talent/add-click/${_id}`,
        body: rest,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return { error };
      });
  }
  public addContact({ _id, ...rest }) {
    return action
      .Patch({
        url: `/talent/add-contact/${_id}`,
        body: rest,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return { error };
      });
  }
  public getAll() {
    return action
      .Get({
        url: "/talent",
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const talent = new Talent();
export default talent;
