import React, { useEffect, useState } from "react";
import { useDispatch, connect } from "react-redux";
import Button from "../../../../../../components/Button";
import Grid from "../../../../../../components/Grid";
import Loader from "../../../../../../components/Loader";
import { PageTitle } from "../../../../../../components/StyledComponents";

import {
  getAcademyStats,
  getCohortUserTimeStats,
} from "../../../../../../store/actions/analytics";

import { PDFExport } from "@progress/kendo-react-pdf";
import { StateDto } from "../../../../../../types/states.dto";
import { CurrentDto } from "../../../../../../types/current.dto";
import { CohortUserTimeDto } from "../../../../../../types/analytics.dto";
import { Formik } from "formik";
import ReactSelect from "../../../../../../components/Form/ReactSelect";

import { CohortDto } from "../../../../../../types/cohort.dto";
import { UnitDto } from "../../../../../../types/unit.dto";
import {
  getAllCohorts,
  resetGetAllCohortsData,
} from "../../../../../../store/actions/cohort";
import {
  getAllUnits,
  resetGetAllUnits,
} from "../../../../../../store/actions/unit";
import DashboardCohortGeneral from "./components/DashboardCohortGeneral";
import DashboardUnit from "./components/DashboardUnit";
const Component = ({
  analyticsUserTime,
  analyticsUserTimeStates,
  current,
  cohorts,
  units,
}: {
  analyticsUserTime: CohortUserTimeDto;
  analyticsUserTimeStates: StateDto;
  current: CurrentDto;
  cohorts: CohortDto[];
  units: UnitDto[];
}) => {
  const printRef: any = React.useRef();
  const [fetchOn, setFetchOn] = useState<boolean>(false);
  const dispatch = useDispatch();
  const handleExportWithComponent = () => {
    printRef.current.save();
  };

  useEffect(() => {
    if (!cohorts && fetchOn)
      dispatch(
        getAllCohorts({ filterBy: { academy: current.id, active: true } })
      );
  }, [cohorts, fetchOn]);

  useEffect(() => {
    if (cohorts && fetchOn) {
      dispatch(
        getAllUnits({
          filterBy: { cohort: { $in: cohorts.map((cohort) => cohort._id) } },
        })
      );
    }
  }, [cohorts]);

  useEffect(() => {
    if (!cohorts && !units) {
      setFetchOn(true);
    }
  }, [cohorts, units]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllCohortsData());
      dispatch(resetGetAllUnits());
    };
  }, []);

  return (
    <Grid.Container>
      <div style={{ minHeight: "100vh" }}>
        <PageTitle>Dashboard</PageTitle>
        <Formik
          initialValues={{}}
          onSubmit={(values, actions) => {}}
          validateOnBlur={false}
          enableReinitialize
        >
          {({
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
            setFieldValue,
            isSubmitting,
          }) => {
            return (
              <form
                className="theme-form"
                onSubmit={(event) => {}}
                id="edit-academy-visibility-form"
              >
                <Grid.Row>
                  <Grid.Col md={3} sm={12}>
                    <ReactSelect
                      name="cohort"
                      error={errors["cohort"]}
                      touched={touched["cohort"]}
                      {...(!!cohorts && {
                        items: cohorts?.map((cohort) => ({
                          label: cohort.name,
                          value: cohort._id,
                        })),
                      })}
                      notLoading={true}
                      placeholder="Selecciona un Cohort"
                      onOptionSelected={(newValue) => {
                        if (newValue) {
                          dispatch(
                            getCohortUserTimeStats({
                              cohortId: newValue.value,
                              topCohortContents: 10,
                              topUnitsContents: 10,
                            })
                          );
                        }
                        setFieldValue("type", null);
                        setFieldValue("unit", null);
                      }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={{
                        loading: !cohorts,
                        label: "Cohorts",
                        marginBottom: 10,
                        skin: "base",
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col md={3} sm={12}>
                    <ReactSelect
                      name="type"
                      items={[
                        { label: "General", value: "GENERAL" },
                        { label: "Unidad", value: "UNIT" },
                      ]}
                      placeholder="Seleccionar un dashboard..."
                      onChange={handleChange}
                      disabled={!units || !analyticsUserTime}
                      notLoading={true}
                      options={{
                        label: "Selecciona dashboard",
                        marginBottom: 10,
                        skin: "base",
                      }}
                    />
                  </Grid.Col>
                  {values["type"] && values["type"] === "UNIT" && (
                    <Grid.Col md={3} sm={12}>
                      <ReactSelect
                        name="unit"
                        {...(units && {
                          items: units
                            ?.filter((unit) => unit.cohort === values["cohort"])
                            .map((unit) => ({
                              label: unit.name,
                              value: unit._id,
                            })),
                        })}
                        placeholder="Seleccione una Unidad"
                        onChange={handleChange}
                        disabled={!units || !analyticsUserTime}
                        notLoading={true}
                        options={{
                          label: "Unidad",
                          marginBottom: 10,
                          skin: "base",
                        }}
                      />
                    </Grid.Col>
                  )}
                </Grid.Row>

                {analyticsUserTimeStates.loading ? (
                  <Loader color="Primary"></Loader>
                ) : !analyticsUserTime || !values["type"] ? null : (
                  <>
                    <Grid.Col offset={{ sm: 10 }} sm={12}>
                      <Button
                        onClick={() => {
                          handleExportWithComponent();
                        }}
                        type="button"
                        options={{
                          type: "filled",
                          skin: "primary",
                          size: "md",
                          marginBottom: "10px",
                        }}
                        style={{ marginLeft: "10px" }}
                      >
                        Descargar
                      </Button>
                    </Grid.Col>
                    <PDFExport
                      fileName={`Constana General ${new Date().valueOf()}`}
                      ref={printRef}
                    >
                      {!!values["unit"] && values["type"] === "UNIT" && (
                        <DashboardUnit
                          analyticsUserTime={analyticsUserTime}
                          values={values}
                        ></DashboardUnit>
                      )}
                      {values["type"] === "GENERAL" && (
                        <DashboardCohortGeneral
                          analyticsUserTime={analyticsUserTime}
                          values={values}
                        ></DashboardCohortGeneral>
                      )}
                    </PDFExport>
                  </>
                )}
              </form>
            );
          }}
        </Formik>
      </div>
    </Grid.Container>
  );
};

const states = ({ analyticsStore, currentStore, cohortStore, unitStore }) => {
  const { data: analyticsUserTime, states: analyticsUserTimeStates } =
    analyticsStore.cohortUserTime;
  const { data: current } = currentStore;
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: units } = unitStore.allUnits;
  return {
    analyticsUserTime,
    analyticsUserTimeStates,
    current,
    cohorts,
    units,
  };
};

export default connect(states)(Component);
