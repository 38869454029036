import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Field, Formik } from "formik";
import Input from "../../../../components/Form/Input";
import Submit from "../../../../components/Form/Submit";
import ReactSelect from "../../../../components/Form/ReactSelect";
import { initialValues, schema } from "../../../../constants/form/cohort/new";
import { newCohort, resetNewCohort } from "../../../../store/actions/cohort";
import { eventsTypes } from "../../../../constants/events-types";
import { getAllEvents } from "../../../../store/actions/event";
import { getAllAbilities } from "../../../../store/actions/ability";
import { AbilityDto } from "../../../../types/ability.dto";
import { EventDto } from "../../../../types/event.dto";
import Button from "../../../../components/Button";
import { hideModal } from "../../../../store/actions/modal";
import { UserDto } from "../../../../types/user.dto";
import { getAllMentors } from "../../../../store/actions/mentor";
import { Center, FlexContainer } from "../../../../components/StyledComponents";
import { CurrentDto } from "../../../../types/current.dto";
import { CohortDto } from "../../../../types/cohort.dto";
import FileUploader from "../../../../components/Form/FileUploader";
import Icon from "../../../../components/Icon";
import UploadIcon from "../../../../assets/icons/cloud-upload.svg";
import DinamicField from "../../../../components/Form/DinamicField";
import DinamicCreateable from "../../../../components/Form/DinamicCreateable";
import { AcademyDto } from "../../../../types/academy.dto";
import { UsersDto } from "../../../../types/users.dto";
import {
  ErrorMessage,
  HelperLine,
  InLine,
} from "../../../../components/Form/Field";
import { minInputDate } from "../../../../helpers/data-handler";
import { setFormData } from "../../../../helpers/formData";
import { toast } from "react-toastify";
import { LetterModal } from "../../Video/Creator/components/PublishVideoModal/style";
import { TagDto } from "../../../../types/tag.dto";
import { newManyTags, updateManyTags } from "../../../../store/actions/tag";
interface ComponentProps {
  newCohortStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  mentors: UserDto[];
  abilities: AbilityDto[];
  events: EventDto[];
  current: CurrentDto;
  cohorts: CohortDto[];
  user: UserDto;
  academies: AcademyDto[];
  users: UsersDto[];
  tags: TagDto[];
  newCohortData: CohortDto;
}

const Component = ({
  abilities,
  mentors,
  events,
  newCohortStates,
  current,
  cohorts,
  user,
  academies,
  users,
  tags,
  newCohortData,
}: ComponentProps) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [currentTypeEvents, setCurrentTypeEvents] = useState<EventDto[]>();
  const [currentTags, setCurrentTags] = useState<any[]>([]);
  const [currentTypeAbilities, setCurrentTypeAbilities] =
    useState<AbilityDto[]>();
  const [avatarPreview, setAvatarPreview] = useState<HTMLInputElement>();
  const [bannerPreview, setBannerPreview] = useState<HTMLInputElement>();
  /* const [] */
  const dispatch = useDispatch();

  const onSubmit = ({ values, actions }) => {
    const { tags, ...rest } = values;
    const formValues = {
      ...rest,
      abilitiesIds: rest.abilitiesIds.map((ability) => ability.value),
      ...(!!avatarPreview &&
        !!avatarPreview?.files && {
          picture: avatarPreview?.files[0],
        }),
      ...(!!bannerPreview &&
        !!bannerPreview?.files && {
          bannerPic: bannerPreview?.files[0],
        }),
    };

    /* dispatch(newManyTags({items:})) */
    dispatch(
      newCohort(
        setFormData({ academy: current.id, ...formValues }, ["picture"])
      )
    );
  };

  const closeModal = () => {
    dispatch(hideModal());
  };

  useEffect(() => {
    if (abilities) {
      setCurrentTypeAbilities(
        abilities.filter(
          (ability) =>
            ((!!ability.academy && ability.academy === current.id) ||
              !ability.academy) &&
            !!ability.active
        )
      );
    }
  }, [abilities]);

  useEffect(() => {
    if (!!events) {
      setCurrentTypeEvents(
        events.filter((event) => event.active && event.academy === current.id)
      );
    }
  }, [events]);

  useEffect(() => {
    if (newCohortStates.success) {
      const newTagsItems = currentTags
        .filter((tag) => !tags.find((tagFounded) => tagFounded._id === tag))
        .map((tag) => {
          return {
            name: tag,
            academy: current.id,
            cohorts: [newCohortData._id],
          };
        });
      const updatedTagsItems = currentTags
        .filter((tag) => tags.find((tagFounded) => tagFounded._id === tag))
        .map((tag) => {
          const tagFounded = tags.find((tagFounded) => tagFounded._id === tag);
          return {
            _id: tagFounded?._id,
            cohorts: [...(tagFounded?.cohorts ?? []), newCohortData._id],
          };
        });
      if (newTagsItems.length > 0)
        dispatch(newManyTags({ items: newTagsItems }));
      if (updatedTagsItems.length > 0) {
        dispatch(updateManyTags({ items: updatedTagsItems }));
      }
      dispatch(hideModal());
      setTimeout(() => {
        dispatch(resetNewCohort());
      }, 2000);
    }
    if (newCohortStates.error) {
      toast.error(newCohortStates.error);
      setTimeout(() => {
        dispatch(resetNewCohort());
      }, 2000);
    }
  }, [newCohortStates]);
  return (
    <Formik
      initialValues={{ ...initialValues }}
      onSubmit={(values, actions) => {
        if (onSubmit) onSubmit({ values, actions });
      }}
      validateOnChange={formSubmmited}
      validateOnBlur={false}
      validationSchema={schema}
      enableReinitialize
    >
      {({
        touched,
        errors,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }) => {
        return (
          <form
            className="theme-form"
            onSubmit={(event) => {
              setFormSubmmited(true);
              handleSubmit(event);
            }}
            id="edit-cohort-form"
          >
            <Input
              name="name"
              error={errors["name"]}
              touched={touched["name"]}
              value={values["name"]}
              type="text"
              placeholder=""
              onChange={handleChange}
              onBlur={handleBlur}
              options={{
                skin: "gray",
                label: "Nombre del cohort",
                marginBottom: 20,
              }}
            />
            <FileUploader
              name="picture"
              error={errors["picture"]}
              touched={touched["picture"]}
              placeholder={<Icon icon={UploadIcon} size="24px" color="black" />}
              onChange={(event) => {
                setAvatarPreview(event.target);
                handleChange(event);
              }}
              onBlur={handleBlur}
              accept="image/jpg;image/png;image/jpeg;capture=camera"
              options={{
                label: "Foto del cohort",
                marginBottom: 24,
              }}
            />
            <FileUploader
              name="bannerPic"
              error={errors["bannerPic"]}
              touched={touched["bannerPic"]}
              placeholder={<Icon icon={UploadIcon} size="24px" color="black" />}
              onChange={(event) => {
                setBannerPreview(event.target);
                handleChange(event);
              }}
              onBlur={handleBlur}
              accept="image/jpg;image/png;image/jpeg;capture=camera"
              options={{
                label: "Banner del Cohort",
                marginBottom: 24,
              }}
            />
            <ReactSelect
              name="private"
              error={errors["private"]}
              touched={touched["private"]}
              items={[
                { label: "Publico", value: false },
                { label: "Privado", value: true },
              ]}
              placeholder="Seleccione un tipo"
              onChange={handleChange}
              onBlur={handleBlur}
              options={{
                skin: "gray",
                label: "Acceso al Cohort",
                marginBottom: 20,
              }}
            />

            {!!values["private"] && (
              <FlexContainer justify="space-between">
                <FlexContainer
                  direction="column"
                  gap="2px"
                  style={{
                    maxWidth: "300px",
                    marginBottom: "20px",
                    padding: "0 0 0 20px",
                  }}
                >
                  <FlexContainer align="center" gap="1px">
                    <LetterModal lg>
                      Acceso al cohort solo via invitacion
                    </LetterModal>
                  </FlexContainer>
                  <LetterModal gray>
                    Los usuarios acceden solo por invitacion
                  </LetterModal>
                </FlexContainer>
                <Field
                  style={{
                    width: "20px",
                    height: "20px",
                    
                  }}
                  type="checkbox"
                  name="hidden"
                />
              </FlexContainer>
            )}
            <DinamicCreateable
              name="abilitiesIds"
              error={errors["abilitiesIds"]}
              touched={touched["abilitiesIds"]}
              value={values["abilitiesIds"]}
              onChange={handleChange}
              onBlur={handleBlur}
              options={{
                height: "95px",
                type: "select",
                skin: "gray",
                data:
                  currentTypeAbilities &&
                  currentTypeAbilities.map((cohort) => ({
                    label: cohort.name,
                    value: cohort._id,
                  })),
                externalData: true,
                loading: !currentTypeAbilities,
                inputLabel: "Habilidades",
                inputPlaceholder: "Crea o selecciona habilidades",
                multi: true,
              }}
            />
            <DinamicField
              name="mentorsIds"
              error={errors["mentorsIds"]}
              touched={touched["mentorsIds"]}
              value={values["mentorsIds"]}
              onChange={handleChange}
              onBlur={handleBlur}
              options={{
                skin: "gray",
                type: "select",
                height: "95px",
                ...(!!users && {
                  data: users.map((userAcademy) => ({
                    label: `${userAcademy.name} ${userAcademy.lastName}` || "",
                    value: userAcademy._id,
                  })),
                }),

                loading: !users,
                inputLabel: "Mentores",
                inputPlaceholder: "Selecciona un mentor",
              }}
            />
            <DinamicCreateable
              name="tags"
              error={errors["tags"]}
              touched={touched["tags"]}
              value={values["tags"]}
              onChange={handleChange}
              onBlur={handleBlur}
              options={{
                onOptionSelected(item, index, fieldValue) {
                  const itemValue = item.value ? item.value : item;
                  setCurrentTags((state) => [...state, itemValue]);
                },
                loading: !tags,
                height: "95px",
                type: "select",
                skin: "gray",
                data: tags?.map((tag) => {
                  return {
                    label: tag.name,
                    value: tag._id,
                  };
                }),
                onRemoveItem(item, index, fieldValue) {
                  const itemValue = item.value ? item.value : item;
                  setCurrentTags((state) =>
                    state.filter((st) => st !== itemValue)
                  );
                },
                externalData: true,
                inputLabel: "Etiquetas",
                inputPlaceholder: "Crea o selecciona etiquetas",
                multi: true,
              }}
            />

            <ReactSelect
              name="type"
              error={errors["type"]}
              touched={touched["type"]}
              items={[
                {
                  label: "Asincronico",
                  value: "ASYNCHRONOUS",
                },
                {
                  label: "Sincronico",
                  value: "SYNCHRONOUS",
                },
                {
                  label: "Mixto",
                  value: "MIXED",
                },
              ]}
              placeholder="Tipo cohort"
              onChange={handleChange}
              onBlur={handleBlur}
              options={{
                skin: "gray",
                label: "Tipo de Cohort",
                marginBottom: 20,
              }}
            />
            <Input
              name="classes"
              error={errors["classes"]}
              touched={touched["classes"]}
              value={values["classes"]}
              type="number"
              placeholder=""
              onChange={handleChange}
              onBlur={handleBlur}
              options={{
                skin: "gray",
                label: "Cantidad de clases",
                marginBottom: 20,
              }}
            />
            <InLine template="1fr 1fr">
              <Input
                name="startDate"
                error={errors["startDate"]}
                touched={touched["startDate"]}
                value={values["startDate"]}
                type="date"
                min={minInputDate()}
                placeholder="Fecha inicio"
                onChange={handleChange}
                onBlur={handleBlur}
                options={{
                  skin: "gray",
                  label: "Fecha inicio",
                  marginBottom: 20,
                }}
              />

              <Input
                name="endDate"
                error={errors["endDate"]}
                touched={touched["endDate"]}
                value={values["endDate"]}
                type="date"
                min={values["startDate"] || minInputDate()}
                placeholder="Fecha de Cierre"
                onChange={handleChange}
                onBlur={handleBlur}
                options={{
                  label: "Fecha de Cierre",
                  marginBottom: 20,
                  skin: "gray",
                }}
              />
            </InLine>
            <ReactSelect
              name="maxStage"
              error={errors["maxStage"]}
              touched={touched["maxStage"]}
              items={Array.from(Array(48).keys()).map((number) => {
                return { label: (number + 1).toString(), value: number + 1 };
              })}
              placeholder="Seleccione el numero de etapas"
              onChange={handleChange}
              onBlur={handleBlur}
              options={{
                label: "Etapas",
                marginBottom: 20,
                skin: "gray",
              }}
            />
            <DinamicField
              name="eventsIds"
              error={errors["eventsIds"]}
              touched={touched["eventsIds"]}
              value={values["eventsIds"]}
              onChange={handleChange}
              onBlur={handleBlur}
              options={{
                type: "select",
                height: "95px",
                ...(!!currentTypeEvents && {
                  data: currentTypeEvents?.map((event) => ({
                    label: event.name,
                    value: event._id,
                  })),
                }),
                skin: "gray",
                loading: !currentTypeEvents,
                inputLabel: "Eventos",
                inputPlaceholder: "Selecciona un evento",
              }}
            />
            <Center>
              <Submit
                isSubmmiting={newCohortStates.loading}
                form="edit-cohort-form"
                color="Primary"
              >
                Confirmar
              </Submit>
              <Button
                onClick={closeModal}
                type="button"
                options={{
                  type: "outline",
                  skin: "danger",
                  size: "lg",
                  marginBottom: "0px",
                }}
                style={{ marginLeft: "10px" }}
              >
                Cancelar
              </Button>
            </Center>
          </form>
        );
      }}
    </Formik>
  );
};

const states = ({
  abilityStore,
  eventStore,
  cohortStore,
  mentorStore,
  currentStore,
  userStore,
  academyStore,
  usersStore,
  tagStore,
}) => {
  const { data: mentors } = mentorStore.allMentors;
  const { data: abilities } = abilityStore.allAbilities;
  const { data: academies } = academyStore.all;
  const { data: events } = eventStore.allEvents;
  const { states: newCohortStates, data: newCohortData } =
    cohortStore.newCohort;
  const { data: current } = currentStore;
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: user } = userStore;
  const { data: users } = usersStore.all;
  return {
    abilities,
    events,
    mentors,
    newCohortStates,
    current,
    cohorts,
    user,
    academies,
    users,
    newCohortData,
  };
};

export default connect(states)(Component);
