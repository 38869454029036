import styled, { css } from "styled-components";

const itemHeight = 25;

export const Dropdown = styled("div")`
  position: relative;
  z-index: 100;
`;
export const DropdownToogleButton = styled("div")`
  cursor: pointer;
`;
export const DropdownBody = styled("div")<{ opened: boolean; length: number }>`
  transition: all 0.2s ease-in-out;
  z-index: ${({ opened }) => (opened ? "1" : "-100")};
  opacity: ${({ opened }) => (opened ? "1" : "0")};
  overflow: hidden;
  margin-top: 10px;
  position: absolute;
  padding: 5px;
  top: 100%;
  right: 0px;
  border-radius: 10px 0px 10px 10px;
  box-shadow: 6px 6px 10px 0 rgba(112, 112, 112, 0.16);
  background-color: white;
  min-width: 100px;
`;
export const DropdownItem = styled("div")``;
const itemStyle = css`
  display: block;
  width: 100%;
  cursor: pointer;
  line-height: ${itemHeight}px;
  font-size: 14px;
  margin-bottom: 2px;
  padding: 0px 5px;
  color: black;

  &:hover {
    background-color: #f5f5f5;
    border-radius: 5px;
  }
`;
export const DropdownButton = styled("button")`
  border: none;
  background: none;
  outline: none;
  padding: 0px;
  text-align: left;
  ${itemStyle}
`;
export const DropdownAnchor = styled("a")`
  text-decoration: none;
  ${itemStyle}
`;
