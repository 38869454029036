import {
  GetAllEvents,
  GET_ALL_EVENTS,
  GetEvent,
  GET_EVENT,
  UpdateEvent,
  UPDATE_EVENT,
  DeleteEvent,
  DELETE_EVENT,
  GetEventSuccess,
  GET_EVENT_SUCCESS,
  NewEvent,
  NEW_EVENT,
  ResetNewEvent,
  RESET_NEW_EVENT,
  ResetUpdateEvent,
  RESET_UPDATE_EVENT,
  ResetDeleteEvent,
  RESET_DELETE_EVENT,
  RESET_GET_ALL_EVENTS,
  GET_EVENTS_BY_USER,
} from "../types/event";

export const getAllEvents = (payload): GetAllEvents => {
  return { type: GET_ALL_EVENTS, payload };
};

export const resetGetAllEvents = () => {
  return { type: RESET_GET_ALL_EVENTS, payload: null };
};

export const getEvent = (id): GetEvent => {
  return { type: GET_EVENT, payload: id };
};

export const getEventsByUser = (payload) => {
  return { type: GET_EVENTS_BY_USER, payload };
};

export const setSelectedEvent = (payload): GetEventSuccess => {
  return { type: GET_EVENT_SUCCESS, payload };
};

export const newEvent = (payload): NewEvent => {
  return { type: NEW_EVENT, payload };
};
export const resetNewEvent = (): ResetNewEvent => {
  return { type: RESET_NEW_EVENT, payload: null };
};

export const updateEvent = (payload): UpdateEvent => {
  return { type: UPDATE_EVENT, payload };
};

export const resetUpdateEvent = (): ResetUpdateEvent => {
  return { type: RESET_UPDATE_EVENT, payload: null };
};

export const deleteEvet = (id): DeleteEvent => {
  return { type: DELETE_EVENT, payload: id };
};

export const resetDeleteEvent = (): ResetDeleteEvent => {
  return { type: RESET_DELETE_EVENT, payload: null };
};
