import * as Yup from "yup";

interface FormValues {
  name: string;
  picture: string;
  private: boolean;
  surname: string;
  email: string;
  phone: string;
  tags: any[];
  bannerPic: string;
  mentorsIds: string[];
  users: string[];
}

export const schema = Yup.object().shape({
  name: Yup.string()
    .min(5, "Minimo 5 caracteres")
    .required("Se necesita nombre"),
  picture: Yup.string().required(),
  bannerPic: Yup.string().required(),
  tags: Yup.array(),
  private: Yup.bool().required(),
  surname: Yup.string().required(),
  email: Yup.string().email("No es un email").required(),
  phone: Yup.string(),
  mentorsIds: Yup.array(),
  users: Yup.array(),
});

export const initialValues = (initialValues = {}): FormValues => {
  return {
    name: "",
    picture: "",
    bannerPic: "",
    tags: [],
    private: false,
    surname: "",
    email: "",
    phone: "",
    mentorsIds: [],
    users: [],
    ...initialValues,
  };
};
