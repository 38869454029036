import styled from "styled-components";

export const Logo = styled("div")`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 26px;
  @media (max-width: 500px) {
    margin-bottom: 22px;
  }
`;

export const Title = styled("h1")`
  font-size: 25px;
  text-align: left;
  font-weight: normal;
  line-height: normal;
  margin-bottom: 16px;
  color: #2a2747;
  @media (max-width: 500px) {
    font-size: 20px;
  }
`;

export const Link = styled("a")`
  color: ${(props) => props.theme.colors.Primary};
  display: inline-block;
  cursor: pointer;
  text-align: left;
  text-decoration: underline;
  font-size: 14px;
`;

export const Img = styled("img")`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const AuthWrapper = styled("div")`
  position: relative;
  border-radius: 10px;
  
  background-color: #fff;
  padding: 35px 32px 100px 32px;
  @media (max-width: 500px) {
    padding: 20px 12px 10px 12px;
    margin: 5px;
  }
`;

export const GridStructure = styled("div")`
  display: grid;
  width: 100%;
  gap: 10px;
  grid-template-columns: 1fr;
  @media (max-width: 500px) {
    /*   grid-template-columns: repeat(auto-fill, minmax(152px, 1fr)); */
    justify-content: center;
    margin: 0 auto;
    max-width: 300px;
    /*   gap: 24px 12px; */
  }
`;

export const GridStructurePlane = styled("div")`
  display: grid;
  width: 100%;
  gap: 10px;
  grid-template-columns: 1fr;
`;
