import React, { useState } from "react";
import { CohortUserTimeDto } from "../../../../../../../../types/analytics.dto";
import {
  FlexContainer,
  Separator,
} from "../../../../../../../../components/StyledComponents";
import AnalyticCard from "../../../../../components/AnalyticCard";
import {
  formatNumber,
  overThousandFixed,
} from "../../../../../../../../helpers/number-fixed";
import { DateEStoDateEN } from "../../../../../../../../helpers/format-date";
import NotData from "../../../../../components/NotData";
import LineGraphHorizontal from "../../../../../../../../components/LineGraphHorizontal";
import Grid from "../../../../../../../../components/Grid";
import { Select } from "../../../../../../Cdocs/Document/components/SelectFontSize/style";
import { Formik } from "formik";
import ReactSelect from "../../../../../../../../components/Form/ReactSelect";

import NumericStats from "../../../../../../Analytics/IndividualPerformance/components/NumericStats/NumericStats";
import { connect } from "react-redux";
import { UnitDto } from "../../../../../../../../types/unit.dto";
import { useDispatch } from "react-redux";
import { ErrorMessage } from "../../../../../../../../components/Form/Field";
import { roundedNumbers } from "../../../../../../../../helpers/rounded-numbers";

const Component = ({
  analyticsUserTime,
  values,
  units,
}: {
  analyticsUserTime: CohortUserTimeDto;
  values: any;
  units: UnitDto[];
}) => {
  const [unitComparePercentage, setUnitComparePercentage] = useState<number>();
  const calculatePercentageDifference = (firstValue, secondValue) => {
    let diferencia = secondValue - firstValue;
    // Calcular el porcentaje de cambio
    let porcentajeCambio = (diferencia / firstValue) * 100;

    return porcentajeCambio;
  };

  const splittedHourValue = (value: string) => {
    if (!value) return 0;
    const numericPart = value.split("-")[0];
    return Number(numericPart);
  };

  return (
    <FlexContainer direction="column" gap="6px">
      <AnalyticCard
        title={"Horas totales de consumo de contenido del Cohort."}
        graphTitle={
          "Horas totales de consumo de contenido del Cohort - Crecimiento Histórico"
        }
        data={{
          stats: [
            {
              label: "Últimos 30 días",
              value: overThousandFixed(analyticsUserTime.lastMonth),
            },
            {
              label: "Últimos 7 días",
              value: overThousandFixed(analyticsUserTime.lastWeek),
            },
            {
              label: "Últimas 24 horas",
              value: overThousandFixed(analyticsUserTime.lastDay),
            },
          ],
          graphData: Object.entries(analyticsUserTime.growth)
            .sort((a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0]))
            .map((growthData) => {
              return {
                name: growthData[0],
                value: formatNumber(growthData[1]),
              };
            }),
        }}
      ></AnalyticCard>
      <AnalyticCard
        height="250px"
        title="Top 10 contenidos mas consumidos deL Cohort"
      >
        <NotData data={analyticsUserTime.topContents}>
          <LineGraphHorizontal
            data={analyticsUserTime.topContents.total.map((contact) => {
              return {
                label: contact.name,
                value: formatNumber(contact.total),
              };
            })}
            title={""}
          ></LineGraphHorizontal>
        </NotData>
      </AnalyticCard>
      <AnalyticCard title="% de crecimiento o decrecimiento de consumo de contenido por unidades.">
        <Formik
          initialValues={{}}
          onSubmit={(values, actions) => {}}
          validateOnBlur={false}
          enableReinitialize
        >
          {({
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
            setFieldValue,
            isSubmitting,
          }) => {
            return (
              <form
                className="theme-form"
                onSubmit={(event) => {
                  handleSubmit(event);
                }}
                id="new-evaluate-form"
              >
                <Grid.Row
                  style={{ alignCenter: "center", justifyContent: "center" }}
                  lg={12}
                >
                  <Grid.Col sm={12} md={4}>
                    <ReactSelect
                      name="firstUnit"
                      error={errors["firstUnit"]}
                      touched={touched["firstUnit"]}
                      {...(units && {
                        items: analyticsUserTime.units.map((unit) => {
                          const unitFounded = units.filter(
                            (unitFounded) => unitFounded._id === unit[0]
                          )[0];
                          return {
                            label: `${
                              unitFounded?.name
                            } - ${unit[1].total.toFixed(1)}hrs`,
                            value: `${unit[1].total}-${unitFounded._id}`,
                          };
                        }),
                      })}
                      placeholder="Seleccione una Unidad"
                      notLoading={true}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onOptionSelected={(newValue) => {
                        if (splittedHourValue(newValue.value) === 0) {
                          setUnitComparePercentage(0);
                          setFieldValue("secondUnit", undefined);
                        }
                        if (
                          newValue &&
                          values["secondUnit"] !== undefined &&
                          splittedHourValue(newValue.value)
                        ) {
                          const splittedValue = splittedHourValue(
                            newValue.value
                          );
                          setUnitComparePercentage(
                            calculatePercentageDifference(
                              splittedValue,
                              splittedHourValue(values["secondUnit"])
                            )
                          );
                        }
                      }}
                      options={{
                        loading: !units,
                        label: "Unidad a comparar",
                        marginBottom: 1,
                      }}
                    />
                    {values["firstUnit"] !== undefined &&
                      splittedHourValue(values["firstUnit"]) === 0 && (
                        <ErrorMessage
                          style={{ padding: "1px 0" }}
                        >{`El valor es 0 -> Infinito`}</ErrorMessage>
                      )}
                    <Separator size={10}></Separator>
                    <ReactSelect
                      name="secondUnit"
                      error={errors["secondUnit"]}
                      touched={touched["secondUnit"]}
                      {...(units && {
                        items: analyticsUserTime.units.map((unit) => {
                          const unitFounded = units.filter(
                            (unitFounded) => unitFounded._id === unit[0]
                          )[0];
                          return {
                            label: `${
                              unitFounded?.name
                            } - ${unit[1].total.toFixed(1)}hrs`,
                            value: `${unit[1].total}-${unitFounded._id}`,
                          };
                        }),
                      })}
                      placeholder="Seleccione una Unidad"
                      notLoading={true}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onOptionSelected={(newValue) => {
                        if (newValue && values["firstUnit"]) {
                          const splittedValue = splittedHourValue(
                            newValue.value
                          );
                          setUnitComparePercentage(
                            calculatePercentageDifference(
                              splittedHourValue(values["firstUnit"]),
                              splittedValue
                            )
                          );
                        }
                      }}
                      disabled={
                        values["firstUnit"] === undefined ||
                        splittedHourValue(values["firstUnit"]) === 0
                      }
                      options={{
                        loading: !units,
                        label: "Unidad para comparar",
                        marginBottom: 20,
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col sm={12} offset={{ md: 0 }} md={4}>
                    <NumericStats
                      value={unitComparePercentage ?? 0}
                      label=""
                    ></NumericStats>
                  </Grid.Col>
                </Grid.Row>
              </form>
            );
          }}
        </Formik>
      </AnalyticCard>
      <AnalyticCard
        title={"Horas totales de consumo de contenido de videos."}
        graphTitle={
          "Horas totales de consumo de videos del cohort- Crecimiento Histórico"
        }
        data={{
          stats: [
            {
              label: "Últimos 30 días",
              value: overThousandFixed(analyticsUserTime.videos.lastMonth),
            },
            {
              label: "Últimos 7 días",
              value: overThousandFixed(analyticsUserTime.videos.lastWeek),
            },
            {
              label: "Últimas 24 horas",
              value: overThousandFixed(analyticsUserTime.videos.lastDay),
            },
          ],
          graphData: Object.entries(analyticsUserTime.videos.growth)
            .sort((a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0]))
            .map((growthData) => {
              return {
                name: growthData[0],
                value: formatNumber(growthData[1]),
              };
            }),
        }}
      ></AnalyticCard>
      <AnalyticCard
        title={"Horas totales de consumo de contenido de Slideshows."}
        graphTitle={
          "Horas totales de consumo de contenido de Slideshows.- Crecimiento Histórico"
        }
        data={{
          stats: [
            {
              label: "Últimos 30 días",
              value: overThousandFixed(analyticsUserTime.slideshows.lastMonth),
            },
            {
              label: "Últimos 7 días",
              value: overThousandFixed(analyticsUserTime.slideshows.lastWeek),
            },
            {
              label: "Últimas 24 horas",
              value: overThousandFixed(analyticsUserTime.slideshows.lastDay),
            },
          ],
          graphData: Object.entries(analyticsUserTime.slideshows.growth)
            .sort((a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0]))
            .map((growthData) => {
              return {
                name: growthData[0],
                value: formatNumber(growthData[1]),
              };
            }),
        }}
      ></AnalyticCard>
      <AnalyticCard
        title={"Horas totales de consumo de contenido de bytes."}
        graphTitle={
          "Horas totales de consumo de contenido de bytes.- Crecimiento Histórico"
        }
        data={{
          stats: [
            {
              label: "Últimos 30 días",
              value: overThousandFixed(analyticsUserTime.bytes.lastMonth),
            },
            {
              label: "Últimos 7 días",
              value: overThousandFixed(analyticsUserTime.bytes.lastWeek),
            },
            {
              label: "Últimas 24 horas",
              value: overThousandFixed(analyticsUserTime.bytes.lastDay),
            },
          ],
          graphData: Object.entries(analyticsUserTime.bytes.growth)
            .sort((a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0]))
            .map((growthData) => {
              return {
                name: growthData[0],
                value: formatNumber(growthData[1]),
              };
            }),
        }}
      ></AnalyticCard>
      <AnalyticCard
        title={"Horas totales de consumo de contenido de imágenes."}
        graphTitle={
          "Horas totales de consumo de contenido de imágenes.- Crecimiento Histórico"
        }
        data={{
          stats: [
            {
              label: "Últimos 30 días",
              value: overThousandFixed(analyticsUserTime.images.lastMonth),
            },
            {
              label: "Últimos 7 días",
              value: overThousandFixed(analyticsUserTime.images.lastWeek),
            },
            {
              label: "Últimas 24 horas",
              value: overThousandFixed(analyticsUserTime.images.lastDay),
            },
          ],
          graphData: Object.entries(analyticsUserTime.images.growth)
            .sort((a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0]))
            .map((growthData) => {
              return {
                name: growthData[0],
                value: formatNumber(growthData[1]),
              };
            }),
        }}
      ></AnalyticCard>
      <AnalyticCard
        title={"Horas totales de consumo de contenido de cdocs."}
        graphTitle={
          "Horas totales de consumo de contenido de cdocs.- Crecimiento Histórico"
        }
        data={{
          stats: [
            {
              label: "Últimos 30 días",
              value: overThousandFixed(analyticsUserTime.cdocs.lastMonth),
            },
            {
              label: "Últimos 7 días",
              value: overThousandFixed(analyticsUserTime.cdocs.lastWeek),
            },
            {
              label: "Últimas 24 horas",
              value: overThousandFixed(analyticsUserTime.cdocs.lastDay),
            },
          ],
          graphData: Object.entries(analyticsUserTime.cdocs.growth)
            .sort((a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0]))
            .map((growthData) => {
              return {
                name: growthData[0],
                value: formatNumber(growthData[1]),
              };
            }),
        }}
      ></AnalyticCard>
      <AnalyticCard
        title={"Horas totales de consumo de contenido de listens."}
        graphTitle={
          "Horas totales de consumo de contenido de listens.- Crecimiento Histórico"
        }
        data={{
          stats: [
            {
              label: "Últimos 30 días",
              value: analyticsUserTime.listens.lastMonth,
            },
            {
              label: "Últimos 7 días",
              value: analyticsUserTime.listens.lastWeek,
            },
            {
              label: "Últimas 24 horas",
              value: analyticsUserTime.listens.lastDay,
            },
          ],
          graphData: Object.entries(analyticsUserTime.listens.growth)
            .sort((a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0]))
            .map((growthData) => {
              return {
                name: growthData[0],
                value: formatNumber(growthData[1]),
              };
            }),
        }}
      ></AnalyticCard>
      <AnalyticCard
        title={"Horas totales de consumo de contenido de trayectos."}
        graphTitle={
          "Horas totales de consumo de contenido de trayectos.- Crecimiento Histórico"
        }
        data={{
          stats: [
            {
              label: "Últimos 30 días",
              value: overThousandFixed(analyticsUserTime.roadmaps.lastMonth),
            },
            {
              label: "Últimos 7 días",
              value: overThousandFixed(analyticsUserTime.roadmaps.lastWeek),
            },
            {
              label: "Últimas 24 horas",
              value: overThousandFixed(analyticsUserTime.roadmaps.lastDay),
            },
          ],
          graphData: Object.entries(analyticsUserTime.roadmaps.growth)
            .sort((a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0]))
            .map((growthData) => {
              return {
                name: growthData[0],
                value: formatNumber(growthData[1]),
              };
            }),
        }}
      ></AnalyticCard>
    </FlexContainer>
  );
};

const states = ({ unitStore }) => {
  const { data: units } = unitStore.allUnits;
  return {
    units,
  };
};

export default connect(states)(Component);
