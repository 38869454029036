import action from "../helpers/rest-client";

export class ErrorAcademy extends Error {}

class Academy {
  public getOne({ _id }) {
    return action
      .Get({
        url: `/academies/${_id}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public new(payload) {
    return action
      .Post({
        url: "/academies",
        body: payload,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public update({ _id, ...rest }) {
    return action
      .Patch({
        url: `/academies/${_id}`,
        body: !!rest?.body ? rest.body : { ...rest },
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public setMp({ _id, ...rest }) {
    return action
      .Patch({
        url: `/payments/set-mp/${_id}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public newPlan(payload) {
    return action
      .Post({
        url: `/payments/plans`,
        body: payload,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public updatePlan({ _id, ...rest }) {
    return action
      .Patch({
        url: `/payments/plans/${_id}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public createLink(payload) {
    return action
      .Patch({
        url: `/academies/create-link/${payload}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public dissociated({ _id, ...rest }) {
    return action
      .Patch({
        url: `/academies/dissociate/${_id}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public addView({ _id, ...rest }) {
    return action
      .Patch({
        url: `/academies/add-view/${_id}`,
        body: rest,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return { error };
      });
  }
  public addClick({ _id, ...rest }) {
    return action
      .Patch({
        url: `/academies/add-view/${_id}`,
        body: rest,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return { error };
      });
  }
  public deleteLink(payload) {
    return action
      .Patch({
        url: `/academies/delete-link/${payload}`,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return { error };
      });
  }

  public updateRole({ _id, ...rest }) {
    return action
      .Patch({
        url: `/academies/update-role/${_id}`,
        body: rest.user,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public deleteMember({ _id, ...rest }) {
    return action
      .Patch({
        url: `/users/delete-from-academy/${_id}?platform=CONSTANA`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getAll(payload) {
    if (payload.filterBy) {
      payload.filterBy = JSON.stringify(payload.filterBy);
    }
    if (payload.select) {
      payload.select = JSON.stringify(payload.select);
    }
    const queryString = new URLSearchParams(payload).toString();

    return action
      .Get({
        url: `/academies${queryString ? "?" + queryString : ""}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public addMember({ _id, ...rest }) {
    return action
      .Patch({
        url: `/users/add-to-academy/${_id}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public delete(cohortId) {
    return action
      .Del({
        url: `/academies/${cohortId}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const academy = new Academy();

export default academy;
