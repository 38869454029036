import React from "react";
import { connect } from "react-redux";
import { FlexContainer } from "../../../../../../components/StyledComponents";
import { ToolItemWrapper, ToolSideWrapper, ToolTipItem } from "./style";

const Component = ({ menu, current, listen, user }) => {
  return (
    <ToolSideWrapper>
      <FlexContainer direction="column" gap="5px">
        {menu.map((item) => {
          if (current.role !== "ADMIN") {
            if (
              !item.listenRole.includes(
                listen?.users.find((userFounded) => userFounded.id === user._id)
              )
            )
              return null;
          }
          return (
            <>
              {!item.onAvailable ||
              (!!item.onAvailable && !!item.onAvailable()) ? (
                item.activeFunction && !!item.activeFunction() ? (
                  !!item.IconActive ? (
                    <ToolItemWrapper
                      onClick={!!item.onClickActive && item.onClickActive}
                      className="active"
                    >
                      <ToolTipItem>{item.toolTipName}</ToolTipItem>
                      <item.IconActive size={17}></item.IconActive>
                    </ToolItemWrapper>
                  ) : (
                    <ToolItemWrapper
                      onClick={!!item.onClickActive && item.onClickActive}
                      className="active"
                    >
                      <ToolTipItem>{item.toolTipName}</ToolTipItem>
                      <item.Icon size={17}></item.Icon>
                    </ToolItemWrapper>
                  )
                ) : (
                  <ToolItemWrapper onClick={!!item.onClick && item.onClick}>
                    <ToolTipItem>{item.toolTipName}</ToolTipItem>
                    <item.Icon size={17}></item.Icon>
                  </ToolItemWrapper>
                )
              ) : (
                <ToolItemWrapper className="disavailable">
                  <ToolTipItem>{item.toolTipName}</ToolTipItem>
                  <item.Icon size={17}></item.Icon>
                </ToolItemWrapper>
              )}
            </>
          );
        })}
      </FlexContainer>
    </ToolSideWrapper>
  );
};

const states = ({ currentStore, userStore }) => {
  const { data: current } = currentStore;
  const { data: user } = userStore;
  return {
    current,
    user,
  };
};

export default connect(states)(Component);
