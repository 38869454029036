import { createRef, useState } from "react";
import { ArrayHelpers, FieldArray } from "formik";
import Input from "../Input";
import ReactSelect from "../ReactSelect";
import { FieldContainer } from "../Field";
import {
  AddButton,
  RemoveButton,
  EditButton,
  ScrollSection,
} from "../../StyledComponents";
import { List, Item, Label } from "./styles";
import { uniqueArrayValues } from "../../../helpers/unique-array-values";

interface ComponentProps {
  name: string;
  value?: { value: string | number; linked: string }[];
  onFocus?: (data: any) => void;
  onBlur?: (data: any) => void;
  onChange?: (data: any) => void;
  initialValues?: string[] | Option[];
  error?: {};
  touched?: {};
  forwardRef?;
  options: {
    itemOnClick?: { functionItem: (value: any) => any; value: any };
    height?: string;
    multi?: boolean;
    before?: any;
    skin?: "base" | "gray";
    onDelete?: any;
    linked?: boolean;
    type: "input" | "select";
    data?: { value: string | number; label: string; linked: string }[];
    loading?: boolean;
    inputPlaceholder?: string;
    onClick?: (item: any, index: number, arrayHelpers: ArrayHelpers) => void;
    onOptionSelected?: (item: any, index: number, fieldValue: string[]) => void;
    inputLabel: string;
    notLoading?: boolean;
    listLabel?: string;
    specialValid?: () => boolean;
    externalData?: boolean;
    indicator?: boolean;
    marginBottom?: number;
  };
}

interface Option {
  label?: string;
  value?: string;
}

const Component = ({
  value,
  name,
  initialValues,
  options,
  ...rest
}: ComponentProps) => {
  const { loading = false } = options;
  const [selectedItems, setSelectedItems] = useState<string[] | Option[]>(
    initialValues || []
  );
  const inputRef = createRef<HTMLInputElement>();
  const externalValues = (array1, array2) => {
    const arrayValues = uniqueArrayValues([
      ...array1.map((el) => el.value),
      ...array2.map((el) => el.value),
    ]);
    const valuesOptions = arrayValues.map((el) => {
      const founded = [...array1, ...array2].find(
        (found) => found.value === el
      );
      return {
        label: founded.label,
        value: founded.value,
      };
    });
    return valuesOptions;
  };
  return (
    <>
      <FieldArray
        {...rest}
        name={name}
        render={(arrayHelpers) => (
          <>
            {options.type === "select" && (
              <ReactSelect
                {...(options.data && {
                  items: options.data.filter(
                    (item) => !value?.find((val) => val.value === item.value)
                  ),
                })}
                notLoading={options.notLoading}
                onOptionSelected={(data: {
                  label: string;
                  value: string;
                  linked: string;
                }) => {
                  if (
                    !value ||
                    (!!value && !value.find((val) => val.value === data.value))
                  ) {
                    arrayHelpers.push(data);
                    !!options.onOptionSelected &&
                      options.onOptionSelected(
                        data.value,
                        selectedItems.length - 1,
                        arrayHelpers.form.values[name]
                      );
                    setSelectedItems([...(selectedItems as Option[]), data]);
                  }
                }}
                {...(!!options.inputPlaceholder && {
                  placeholder: options.inputPlaceholder,
                })}
                options={{
                  ...options,
                  loading: loading,
                  label: options.inputLabel,
                  marginBottom: 30,
                }}
              />
            )}

            <FieldContainer>
              {!!options.listLabel && <Label>{options.listLabel}</Label>}

              <ScrollSection
                style={{
                  ...(options.skin === "gray" && {
                    backgroundColor: "#f5f5f5",
                    boxShadow: "none",
                  }),
                  minHeight: "95px",
                  height: options?.height || "400px",
                  padding: "10px",
                }}
              >
                <List className={!!options.multi ? "multiLine" : "list"}>
                  {!!arrayHelpers.form.values[name] &&
                    arrayHelpers.form.values[name]?.map((item, index) => (
                      <Item
                        onClick={() => {
                          !!options.itemOnClick &&
                            options.itemOnClick.functionItem(item);
                        }}
                        className={`${!!options.multi ? "multiLine" : "list"} ${
                          !!options.itemOnClick &&
                          options.itemOnClick.value === item
                            ? "active"
                            : ""
                        }`}
                        key={`${item}-${index}`}
                      >
                        <span>
                          {options.type === "select" && !!options.externalData
                            ? externalValues(
                                selectedItems,
                                arrayHelpers.form.values[name]
                              ).filter((selectedItem) => {
                                return selectedItem.value === item.value;
                              })[0]?.label
                            : (options.data as Option[])?.filter(
                                (selectedItem) => selectedItem.value === item
                              )[0]?.label}

                          {options.type === "input" && item}
                        </span>

                        <span>
                          {!!options.onClick && (
                            <EditButton
                              type="button"
                              onClick={() =>
                                !!options.onClick &&
                                options.onClick(item, index, arrayHelpers)
                              }
                            />
                          )}

                          <RemoveButton
                            type="button"
                            onClick={() => {
                              arrayHelpers.remove(index);
                              if (options.type === "select") {
                                !!options.onDelete &&
                                  options.onDelete(item, index, value);
                                setSelectedItems(
                                  (selectedItems as Option[]).filter(
                                    (_selectedItem, selectedItemIndex) =>
                                      index !== selectedItemIndex
                                  )
                                );
                              } else {
                                setSelectedItems(
                                  (selectedItems as string[]).filter(
                                    (_selectedItem, selectedItemIndex) =>
                                      index !== selectedItemIndex
                                  )
                                );
                              }
                            }}
                          />
                        </span>
                      </Item>
                    ))}
                </List>
              </ScrollSection>
            </FieldContainer>
          </>
        )}
      />
    </>
  );
};

export default Component;

/* 


<DinamicCreateable
                    name="bootcamps"
                    error={errors["bootcamps"]}
                    touched={touched["bootcamps"]}
                    value={values["bootcamps"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      type: "select",
                      skin: "base",
                      data:
                        !!cohorts &&
                        cohorts
                          .filter((cohort) => cohort.active)
                          .filter(
                            (cohort) =>
                              cohort.abilityId === values["technology"]
                          )
                          .filter((cohort) =>
                            current.role === "ADMIN"
                              ? cohort
                              : cohort.studentsIds.includes(user._id)
                          )
                          .map((cohort) => ({
                            label: cohort.name,
                            value: cohort._id,
                          })),
                      externalData: true,
                      loading: !cohorts || cohortsStates.loading,
                      inputLabel: "Seleccionar Bootcamp",
                      inputPlaceholder: "Selecciona un bootcamp",
                      listLabel: "Bootcamps Selecciosas",
                    }}
                  />



*/
