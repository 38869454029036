import * as Yup from "yup";

interface FormValues {
  phone: string;
  email: string;
  location: string;
  linkedin: string;
  github: string;
  website: string;
}

export const schema = Yup.object().shape({
  phone: Yup.string(),
  email: Yup.string().email(),
  location: Yup.string(),
  linkedin: Yup.string(),
  github: Yup.string(),
  website: Yup.string(),
});

export const initialValues = (initialValues = {}): FormValues => {
  return {
    phone: "",
    email: "",
    location: "",
    linkedin: "",
    github: "",
    website: "",
    ...initialValues,
  };
};
