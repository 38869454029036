import * as Yup from "yup";

interface FormValues {
  name: string;
  description: string;
  linkedin: string;
  email: string;
  location: string;
  portfolio: string;
  area: string;
}

export const schema = Yup.object().shape({
  name: Yup.string().required("Ingresar minimo un caracter"),
  description: Yup.string(),
  linkedin: Yup.string(),
  email: Yup.string(),
  location: Yup.string(),
  portfolio: Yup.string(),
  area: Yup.string(),
});

export const initialValues = (initialValues = {}): FormValues => ({
  name: "",
  description: "",
  linkedin: "",
  email: "",
  location: "",
  portfolio: "",
  area: "",
  ...initialValues,
});
