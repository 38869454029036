import React, { useEffect, useState } from "react";
import Grid from "../../../../components/Grid";
import {
  FlexContainer,
  PageTitle,
  RemoveButton,
  ScrollSection,
  Separator,
} from "../../../../components/StyledComponents";
import { Formik } from "formik";
import {
  initialValues,
  schema,
} from "../../../../constants/form/criterion/new";

import InputList from "../../../../components/Form/InputList";
import { connect } from "react-redux";
import { CriterionDto } from "../../../../types/criterion.dto";
import Loader from "../../../../components/Loader";
import { CohortDto } from "../../../../types/cohort.dto";
import { CurrentDto } from "../../../../types/current.dto";
import { useDispatch } from "react-redux";
import { hideModal, showModal } from "../../../../store/actions/modal";
import EditCriterionCohorts from "./components/EditCriterionCohorts";
import {
  deleteCriterion,
  getCriterions,
  newCriterion,
  resetDeleteCriterion,
  resetGetAllCriterions,
  resetNewCriterion,
  resetUpdateCriterion,
  updateCriterion,
} from "../../../../store/actions/criterion";
import {
  getAllCohorts,
  resetGetAllCohortsData,
} from "../../../../store/actions/cohort";
import { CohorListtItem, CohortListTitle } from "./style";
import ModalConfirmDelete from "../../../../components/ModalConfirmDelete";
import { StateDto } from "../../../../types/states.dto";
import { resetGetAllSubCriterions } from "../../../../store/actions/sub-criterion";
const Component = ({
  criterions,
  cohorts,
  current,
  updateCriterionStates,
  deleteCriterionStates,
  newCriterionsStates,
  newCriterion,
  updateCriterionData,
  deleteCriterionData,
}: {
  criterions: CriterionDto[];
  cohorts: CohortDto[];
  current: CurrentDto;
  updateCriterionStates: StateDto;
  deleteCriterionStates: StateDto;
  newCriterionsStates: StateDto;
  newCriterion: CriterionDto;
  updateCriterionData: CriterionDto;
  deleteCriterionData: CriterionDto;
}) => {
  const dispatch = useDispatch();
  const [formSubmmited, setFormSubmmited] = useState<boolean>(false);
  const [filteredCriterions, setFilteredCriterions] =
    useState<CriterionDto[]>();
  const [selectedCriterion, setSelectedCriterion] = useState<string>();
  const [criterionForCohort, setCriterionForCohort] = useState<CriterionDto>();
  const [action, setAction] = useState<any>();
  const [fetchOn, setFetchOn] = useState<boolean>(false);
  /*  const [createdCriterions, setCreatedCriterions] = useState<any[]>([]); */
  const onSubmit = ({ values, actions }) => {};

  useEffect(() => {
    if (!!criterions && !!cohorts) {
      setFilteredCriterions(
        criterions.filter(
          (criterion) =>
            /* cohorts
            .filter((cohort) => criterion.bootcamps.includes(cohort._id))
            .map((cohort) => cohort.academy._id)
            .includes(current.id) */
            criterion.academy === current.id
        )
      );
    }
  }, [criterions, cohorts]);

  useEffect(() => {
    if (!cohorts && fetchOn)
      dispatch(
        getAllCohorts({ filterBy: { academy: current.id, active: true } })
      );
  }, [cohorts, fetchOn]);
  useEffect(() => {
    if (!criterions && fetchOn)
      dispatch(
        getCriterions({ filterBy: { academy: current.id, active: true } })
      );
  }, [criterions, fetchOn]);
  useEffect(() => {
    if (!cohorts && !criterions) {
      setFetchOn(true);
    }
  }, [cohorts, criterions]);

  useEffect(() => {
    if (newCriterionsStates.success) {
      setFilteredCriterions((state) => [newCriterion, ...(state ?? [])]);
      dispatch(resetNewCriterion());
    }
    if (newCriterionsStates.error) {
      dispatch(resetNewCriterion());
    }
  }, [newCriterionsStates]);

  useEffect(() => {
    if (updateCriterionStates.success) {
      setFilteredCriterions((state) => [
        ...(state ?? []).map((st) =>
          st._id === updateCriterionData._id ? updateCriterionData : st
        ),
      ]);
      dispatch(resetUpdateCriterion());
    }

    if (updateCriterionStates.error) {
      dispatch(resetUpdateCriterion());
    }
  }, [updateCriterionStates, dispatch]);

  useEffect(() => {
    if (deleteCriterionStates.success) {
      dispatch(resetDeleteCriterion());
      setFilteredCriterions((state) => [
        ...(state ?? []).filter((st) => st._id !== deleteCriterionData._id),
      ]);
      setCriterionForCohort(undefined);
    }
    if (deleteCriterionStates.error) {
      dispatch(resetDeleteCriterion());
    }
  }, [deleteCriterionStates, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllCriterions());
      dispatch(resetGetAllSubCriterions());
      dispatch(resetGetAllCohortsData());
    };
  }, []);

  if (!filteredCriterions || !cohorts) return <Loader color="Primary"></Loader>;
  return (
    <>
      <Grid.Container>
        <PageTitle>Criterios</PageTitle>
        <Formik
          initialValues={{
            ...initialValues,
            criterions: filteredCriterions.map((criterion) => criterion._id),
          }}
          onSubmit={(values, actions) => {
            if (onSubmit) onSubmit({ values, actions });
          }}
          validateOnChange={formSubmmited}
          validateOnBlur={false}
          validationSchema={schema}
          enableReinitialize
        >
          {({
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
          }) => {
            return (
              <form
                className="theme-form"
                onSubmit={(event) => {
                  setFormSubmmited(true);
                  handleSubmit(event);
                }}
                id="new-criterion-form"
              >
                <Grid.Row>
                  <Grid.Col lg={5.8}>
                    <InputList
                      name="criterions"
                      error={errors["criterions"]}
                      touched={touched["criterions"]}
                      value={values["criterions"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={{
                        onClick: (item, index, array) => {
                          dispatch(showModal("edit-cohort-criterion"));
                          setSelectedCriterion(item);
                        },
                        onDelete: (item, index, fileldValue) => {
                          const value = filteredCriterions.find(
                            (el) => el._id === item
                          );
                          setAction({
                            action: "delete-criterion",
                            data: {
                              _id: value?._id,
                              name: value?.name,
                              title: `¿Seguro quieres ELIMINAR el Criterio ${value?.name}?`,
                              action: () => deleteCriterion({ _id: item }),
                              resetAction: () => {},
                            },
                          });
                          dispatch(
                            showModal("confirm-delete-item-secuencial-modal")
                          );
                        },
                        /*  createdItem: createdCriterions, */
                        itemOnClick: {
                          functionItem: (value) =>
                            setCriterionForCohort((state) =>
                              state?._id === value
                                ? undefined
                                : filteredCriterions.find(
                                    (crit) => crit._id === value
                                  )
                            ),
                          value: criterionForCohort?._id,
                        },
                        onAdd: (text) => {
                          dispatch(showModal("edit-cohort-criterion"));
                          setSelectedCriterion(undefined);
                        },
                        ...(filteredCriterions && {
                          data: filteredCriterions.map((criterion) => {
                            return {
                              label: criterion.name,
                              value: criterion._id,
                            };
                          }),
                        }),
                        type: "input",
                        skin: "base",
                        inputLabel: "Nombre del Criterio",
                        inputPlaceholder: "Escribe un nombre de criterio",
                        listLabel: "Listado de Criterios",
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col offset={{ lg: 0.2 }} lg={5.8}>
                    <CohortListTitle>
                      Cohorts con el criterio seleccionado
                    </CohortListTitle>
                    <Separator size={9}></Separator>
                    <ScrollSection
                      style={{ height: "500px", padding: "14px 40px" }}
                    >
                      <FlexContainer direction="column" gap="8px">
                        {!!criterionForCohort &&
                          cohorts
                            .filter((cohort) =>
                              filteredCriterions
                                .find(
                                  (cri) => cri._id === criterionForCohort?._id
                                )
                                ?.bootcamps.includes(cohort._id)
                            )
                            .map((cohort) => {
                              return (
                                <CohorListtItem>
                                  <FlexContainer gap="7px" align="center">
                                    <RemoveButton
                                      type="button"
                                      onClick={(ev) => {
                                        setAction({
                                          action: "delete-content-from-unit",
                                          data: {
                                            _id: criterionForCohort?._id,
                                            name: criterionForCohort?.name,
                                            title: `¿Seguro quieres ELIMINAR el Cohort del Criterio ${criterionForCohort.name}?`,
                                            action: () =>
                                              updateCriterion({
                                                _id: criterionForCohort?._id,
                                                bootcamps: [
                                                  ...criterionForCohort?.bootcamps.filter(
                                                    (bootcamp) =>
                                                      bootcamp !== cohort._id
                                                  ),
                                                ],
                                              }),
                                            resetAction: () => {},
                                          },
                                        });
                                        dispatch(
                                          showModal(
                                            "confirm-delete-item-secuencial-modal"
                                          )
                                        );
                                      }}
                                    />
                                    <h4>{cohort.name}</h4>
                                  </FlexContainer>
                                </CohorListtItem>
                              );
                            })}
                      </FlexContainer>
                    </ScrollSection>
                  </Grid.Col>
                </Grid.Row>
              </form>
            );
          }}
        </Formik>
      </Grid.Container>

      <EditCriterionCohorts
        criterions={filteredCriterions}
        cohorts={cohorts}
        criterion={selectedCriterion}
        onClose={() => setSelectedCriterion(undefined)}
      ></EditCriterionCohorts>

      {action?.action === "delete-content-from-unit" && (
        <ModalConfirmDelete
          bntConfig={{
            content: "Eliminar",
            style: {
              style: { width: "170px", height: "40px" },
              color: "Danger",
              options: {
                type: "filled",
                skin: "danger",
                size: "lg",
                marginBottom: "0px",
              },
            },
          }}
          description="El cohort sera desvinculado del criterio junto con todas sus calificaciones hechas de los subcriterios coorespondientes a este cohort"
          states={updateCriterionStates}
          title={
            <span
              style={{ color: "#697482", fontSize: "20px", fontWeight: "600" }}
            >
              {`${action.data.title}`}
            </span>
          }
          elementActions={action.data.action()}
          resetState={setAction}
        ></ModalConfirmDelete>
      )}
      {action?.action === "delete-criterion" && (
        <ModalConfirmDelete
          bntConfig={{
            content: "Eliminar",

            style: {
              style: { width: "170px", height: "40px" },
              color: "Danger",
              options: {
                type: "filled",
                skin: "danger",
                size: "lg",
                marginBottom: "0px",
              },
            },
          }}
          description="El criterio se eliminara de manera global con todos sus subcriterios y de todos los cohorts a los que fue asignado."
          states={deleteCriterionStates}
          title={
            <span
              style={{ color: "#697482", fontSize: "20px", fontWeight: "600" }}
            >
              {`${action.data.title}`}
            </span>
          }
          elementActions={action.data.action()}
          resetState={setAction}
          sucessAction={() => {
            dispatch(hideModal());
          }}
        ></ModalConfirmDelete>
      )}
    </>
  );
};

const states = ({ criterionStore, cohortStore, currentStore }) => {
  const { data: criterions } = criterionStore.allCriterions;
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: current } = currentStore;
  const { states: updateCriterionStates, data: updateCriterionData } =
    criterionStore.updateCriterion;
  const { states: deleteCriterionStates, data: deleteCriterionData } =
    criterionStore.deleteCriterion;
  const { states: newCriterionsStates, data: newCriterion } =
    criterionStore.newCriterion;
  return {
    criterions,
    cohorts,
    current,
    updateCriterionStates,
    deleteCriterionStates,
    updateCriterionData,
    deleteCriterionData,
    newCriterionsStates,
    newCriterion,
  };
};

export default connect(states)(Component);
