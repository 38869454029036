import { useRecruitingLayout } from "../../../../provider/RecruitingProvider";
import Loader from "../../../Loader";
import { InLine } from "../../../StyledComponents";
import { List, ListItem, Button } from "./styles";

const Component = () => {
  const { selectItem, selectedItem, filteredList } = useRecruitingLayout();

  return (
    <List>
      {!filteredList && (
        <InLine style={{ margin: "24px 0px" }}>
          <Loader color="Primary" />
        </InLine>
      )}
      {!!filteredList && filteredList.length <= 0 && (
        <InLine style={{ margin: "24px 0px" }}>
          No se encontraron resultados
        </InLine>
      )}
      {filteredList &&
        filteredList.map((item, index) => {
          return (
            <ListItem key={`recruiting-list-item-${index}`}>
              <Button
                onClick={() => selectItem(item)}
                active={selectedItem?.value === item.value}
              >
                {item.label}
              </Button>
            </ListItem>
          );
        })}
    </List>
  );
};

export default Component;
