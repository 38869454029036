import { showModal } from "../../../../../store/actions/modal";
import { useDispatch } from "react-redux";
import { Action } from "../../components/StyledComponents";
import Icon from "../../../../../components/Icon";
import GirlIcon from "../../../../../assets/icons/girl.svg";
import CompanyIcon from "../../../../../assets/icons/company.svg";
import CodeIcon from "../../../../../assets/icons/code.svg";
import { AiOutlineSearch, AiOutlineCode } from "react-icons/ai";
const AsignarTalento = () => {
  const dispatch = useDispatch();

  return (
    <Action onClick={() => dispatch(showModal("assign-talent-modal"))}>
      <Icon icon={GirlIcon} size="12px" color="black" />
      Asignar Talentos
    </Action>
  );
};

const AsignarBusqueda = () => {
  const dispatch = useDispatch();

  return (
    <Action onClick={() => dispatch(showModal("assign-search-modal"))}>
      <AiOutlineSearch
        style={{ marginRight: "8px" }}
        width={12}
        height={12}
        color={"black"}
      ></AiOutlineSearch>
      Asignar Busqueda
    </Action>
  );
};

const AsignarMetrica = () => {
  const dispatch = useDispatch();

  return (
    <Action onClick={() => dispatch(showModal("assign-metric-modal"))}>
      <Icon icon={GirlIcon} size="12px" color="black" />
      Metricas
    </Action>
  );
};

const AsignarEmpresa = () => {
  const dispatch = useDispatch();

  return (
    <Action onClick={() => dispatch(showModal("assign-company-modal"))}>
      <Icon icon={CompanyIcon} size="12px" color="black" />
      Asignar Empresa
    </Action>
  );
};

const AsignarSkill = () => {
  const dispatch = useDispatch();
  return (
    <Action onClick={() => dispatch(showModal("assign-skill-modal"))}>
      <AiOutlineCode
        style={{ marginRight: "8px" }}
        width={12}
        height={12}
        color={"black"}
      ></AiOutlineCode>
      Asignar Skills Tecnicos
    </Action>
  );
};

const AsignarTecnologia = () => {
  const dispatch = useDispatch();

  return (
    <Action onClick={() => dispatch(showModal("assign-technology-modal"))}>
      <Icon icon={CodeIcon} size="12px" color="black" />
      Asignar Tecnología
    </Action>
  );
};

const ShowModal = {
  AsignarTalento,
  AsignarEmpresa,
  AsignarTecnologia,
  AsignarBusqueda,
  AsignarMetrica,
  AsignarSkill,
};

export default ShowModal;
