import * as Yup from "yup";

interface FormValues {
  category: string;
}

export const schema = Yup.object().shape({
  category: Yup.string().required(),
});

export const initialValues = (initialValues = {}): FormValues => {
  return {
    category: "",
  };
};
