import {
  NewEvaluate,
  NEW_EVALUATE,
  ResetNewEvaluate,
  RESET_NEW_EVALUATE,
  GetAllEvaluates,
  GET_ALL_EVALUATE,
  ResetGetAllEvaluate,
  RESET_GET_ALL_EVALUATE,
  UPDATE_EVALUATE,
  UpdateEvaluate,
  ResetUpdateEvaluate,
  RESET_UPDATE_EVALUATE,
  NEW_MANY_EVALUATE,
  RESET_NEW_MANY_EVALUATE,
} from "../types/evaluate";

export const newEvaluate = (payload): NewEvaluate => {
  return { type: NEW_EVALUATE, payload };
};

export const resetNewEvaluate = (): ResetNewEvaluate => {
  return { type: RESET_NEW_EVALUATE, payload: null };
};

export const getEvaluates = (payload): GetAllEvaluates => {
  return { type: GET_ALL_EVALUATE, payload };
};
export const resetGetAllEvaluate = (): ResetGetAllEvaluate => {
  return { type: RESET_GET_ALL_EVALUATE, payload: null };
};

export const newManyEvaluate = (payload) => {
  return { type: NEW_MANY_EVALUATE, payload };
};

export const resetNewManyEvaluate = () => {
  return { type: RESET_NEW_MANY_EVALUATE, payload: null };
};

export const updateEvaluates = (payload): UpdateEvaluate => {
  return { type: UPDATE_EVALUATE, payload };
};

export const resetUpdateEvaluates = (): ResetUpdateEvaluate => {
  return { type: RESET_UPDATE_EVALUATE, payload: null };
};
