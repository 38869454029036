import { connect } from "react-redux";
import { InLine } from "../../../../../../components/StyledComponents";
import CrossIcon from "../../../../../../assets/icons/cross.svg";
import { useRecruitingLayout } from "../../../../../../provider/RecruitingProvider";
import Icon from "../../../../../../components/Icon";
import {
  TabTitle,
  Description,
  Content,
  ListTitle,
  List,
  ListItem,
  Delete,
} from "../../../components/StyledComponents";
import { useDispatch } from "react-redux";
import ShowModal from "../../../components/ShoModal";
import {
  AitTalentDto,
  SearchDto,
} from "../../../../../../types/recruiting.dto";
import { useParams } from "react-router-dom";
interface ComponentProps {
  talents: AitTalentDto[];
  searches: SearchDto[];
}

const Component = ({ talents, searches }: ComponentProps) => {
  const { selectedItem, actions } = useRecruitingLayout();
  const dispatch = useDispatch();
  const params = useParams();

  return (
    <>
      <InLine justify="space-between">
        <TabTitle>Asignar Busquedas a Talento</TabTitle>
        <ShowModal.AsignarBusqueda />
      </InLine>
      <Description>
        Customizar el perfil de talento externo te ayudara a ti y a Dev Place a
        facilitar la conexión entre talentos, tus búsquedas y las necesidades de
        esta empresa en particular. Ten en cuenta que es un perfil externo a los
        talentos que se egresan de los Bootcamps IT de Dev Place por lo que no
        podrás ver ningún tipo de métrica.
      </Description>
      <Content>
        <ListTitle>Busquedas</ListTitle>
        <List>
          {!!searches &&
            searches
              .filter((search) => search.active)
              .filter((ability) => ability.team === params.id)
              .filter((search) =>
                selectedItem?.data?.searches?.includes(search._id)
              )
              .map((item) => (
                <ListItem>
                  {item?.name}
                  <Delete
                    onClick={() =>
                      dispatch(
                        actions.update({
                          ...selectedItem?.data,
                          searches: selectedItem?.data?.searches?.filter(
                            (talent) => talent !== item._id
                          ),
                        })
                      )
                    }
                  >
                    <Icon icon={CrossIcon} size="9px" color="black" />
                  </Delete>
                </ListItem>
              ))}
        </List>
      </Content>
    </>
  );
};

const states = ({ recruitingStore }) => {
  const { data: talents, states: talentsStates } = recruitingStore.talent.all;
  const { data: searches } = recruitingStore.search.all;
  return { talents, talentsStates, searches };
};

export default connect(states)(Component);
