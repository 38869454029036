import { put, call, all, takeLatest } from "redux-saga/effects";
import services from "../../services";
import {
  GET_CATEGORY,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_ERROR,
  GET_ALL_CATEGORIES,
  GET_ALL_CATEGORIES_SUCCESS,
  GET_ALL_CATEGORIES_ERROR,
  NEW_CATEGORY,
  NEW_CATEGORY_ERROR,
  NEW_CATEGORY_SUCCESS,
  NewCategory,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_ERROR,
  DELETE_CATEGORY,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_ERROR,
  GetCategory,
  GetAllCategories,
  UpdateCategory,
  DeleteCategory,
} from "../types/category";

function* getAllCategories() {
  const { response, error } = yield call(services.category.getAll);

  if (error) {
    yield put({
      type: GET_ALL_CATEGORIES_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_CATEGORIES_SUCCESS, payload: response });
  }
}

function* getCategory({ payload }) {
  const { response, error } = yield call(services.category.getOne, payload);

  if (error) {
    yield put({
      type: GET_CATEGORY_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_CATEGORY_SUCCESS, payload: response });
  }
}

function* newCategory({ payload }) {
  const { response, error } = yield call(services.category.new, payload);

  if (error) {
    yield put({
      type: NEW_CATEGORY_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([
      put({ type: NEW_CATEGORY_SUCCESS, payload: response }),
      put({ type: GET_ALL_CATEGORIES, payload: null }),
    ]);
  }
}

function* updateCategory({ payload }) {
  const { response, error } = yield call(services.category.update, payload);

  if (error) {
    yield put({
      type: UPDATE_CATEGORY_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([
      put({ type: UPDATE_CATEGORY_SUCCESS, payload: response }),
      put({ type: GET_ALL_CATEGORIES, payload: null }),
    ]);
  }
}

function* deleteCategory({ payload }) {
  const { response, error } = yield call(services.category.delete, payload);

  if (error) {
    yield put({
      type: DELETE_CATEGORY_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([
      put({ type: DELETE_CATEGORY_SUCCESS, payload: response }),
      put({ type: GET_ALL_CATEGORIES, payload: null }),
    ]);
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllCategories>(GET_ALL_CATEGORIES, getAllCategories);
  yield takeLatest<GetCategory>(GET_CATEGORY, getCategory);
  yield takeLatest<NewCategory>(NEW_CATEGORY, newCategory);
  yield takeLatest<UpdateCategory>(UPDATE_CATEGORY, updateCategory);
  yield takeLatest<DeleteCategory>(DELETE_CATEGORY, deleteCategory);
}
