import {
  initialState,
  Actions,
  State,
  GET_ALL_BYTES,
  GET_ALL_BYTES_ERROR,
  GET_ALL_BYTES_SUCCESS,
  GET_BYTE,
  GET_BYTE_ERROR,
  GET_BYTE_SUCCESS,
  NEW_BYTE,
  NEW_BYTE_ERROR,
  NEW_BYTE_SUCCESS,
  RESET_NEW_BYTE,
  UPDATE_BYTE,
  UPDATE_BYTE_ERROR,
  UPDATE_BYTE_SUCCESS,
  RESET_UPDATE_BYTE,
  DELETE_BYTE,
  DELETE_BYTE_ERROR,
  DELETE_BYTE_SUCCESS,
  RESET_DELETE_BYTE,
  UPLOAD_MEDIA,
  UPDATE_BYTE_LOADING,
  RESET_GET_BYTE,
  ADD_USER_TO_BYTE,
  SHARED_BYTE,
  SET_GET_BYTE,
  RESET_UPDATE_MANY_BYTE,
  UPDATE_MANY_BYTE,
  UPDATE_MANY_BYTE_ERROR,
  UPDATE_MANY_BYTE_SUCCESS,
  RESET_GET_ALL_BYTE_DATA,
} from "../types/byte";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_BYTES:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_BYTES_SUCCESS:
      return {
        ...state,
        all: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_BYTES_ERROR:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_GET_ALL_BYTE_DATA:
      return {
        ...state,
        all: initialState.all,
      };
    case UPLOAD_MEDIA:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };

    case ADD_USER_TO_BYTE:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_MANY_BYTE:
      return {
        ...state,
        updateMany: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_MANY_BYTE_SUCCESS:
      return {
        ...state,
        updateMany: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_MANY_BYTE_ERROR:
      return {
        ...state,
        updateMany: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_MANY_BYTE:
      return {
        ...state,
        updateMany: initialState.updateMany,
      };
    case SHARED_BYTE:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_BYTE_LOADING:
      return {
        ...state,
        new: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_BYTE:
      return {
        ...state,
        byte: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_BYTE_SUCCESS:
      return {
        ...state,
        byte: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_BYTE_ERROR:
      return {
        ...state,
        byte: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case NEW_BYTE:
      return {
        ...state,
        new: {
          ...state.new,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_BYTE_SUCCESS:
      return {
        ...state,
        new: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case SET_GET_BYTE:
      return {
        ...state,
        byte: {
          ...state.byte,
          data: !!action.payload ? action.payload : null,
        },
      };
    case RESET_GET_BYTE:
      return {
        ...state,
        byte: {
          ...state.byte,
          states: {
            success: false,
            loading: false,
            error: false,
          },
        },
      };
    case NEW_BYTE_ERROR:
      return {
        ...state,
        new: {
          ...state.new,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_BYTE:
      return {
        ...state,
        new: {
          data: state.new.data,
          states: {
            loading: false,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_BYTE:
      return {
        ...state,
        delete: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_BYTE_SUCCESS:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_BYTE_ERROR:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_BYTE:
      return {
        ...state,
        delete: initialState.delete,
      };
    case UPDATE_BYTE:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_BYTE_SUCCESS:
      return {
        ...state,
        update: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_BYTE_ERROR:
      return {
        ...state,
        update: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_BYTE:
      return {
        ...state,
        update: initialState.update,
      };
    default:
      return state;
  }
};

export default reducers;
