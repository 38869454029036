import { all } from "redux-saga/effects";
import auth from "./auth";
import user from "./user";
import executive from "./executive";
import criterion from "./criterion";
import talent from "./talent";
import subcriterio from "./sub-criterion";
import statics from "./statics";
import evaluate from "./evaluate";
import analytics from "./analytics";

import recruitingCompany from "./recruiting/company";
import recruitingSearch from "./recruiting/search";
import recruitingTalent from "./recruiting/ait-talent";
import recruitingTechSkill from "./recruiting/tech-skill";
import recruitingTechnology from "./recruiting/technology";
import recruitingAit from "./recruiting/ait";

import ability from "./ability";
import category from "./category";
import cohort from "./cohort";
import event from "./event";
import mentor from "./mentor";
import meet from "./meet";
import student from "./student";
import academy from "./academy";
import organization from "./organization";
import invitation from "./invitation";
import team from "./team";
import users from "./users";
import confirmation from "./confirmation";
import confirmationAuth from "./confirmationAuth";
import unit from "./unit";
import content from "./content";
import certificate from "./certificate";
import request from "./request";
import notification from "./notification";
import association from "./association";
import cdoc from "./cdoc";
import video from "./video";
import image from "./image";
import roadmap from "./roadmap";
import slideshow from "./slideshow";
import listen from "./listen";
import byte from "./byte";
import generalContent from "./generalContent";
import tag from "./tag";
import plan from "./plan";

export default function* rootSaga() {
  yield all([
    auth(),
    association(),
    roadmap(),
    cdoc(),
    certificate(),
    tag(),
    image(),
    byte(),
    listen(),
    users(),
    confirmationAuth(),
    notification(),
    generalContent(),
    plan(),
    request(),
    unit(),
    slideshow(),
    team(),
    content(),
    organization(),
    invitation(),
    academy(),
    student(),
    meet(),
    confirmation(),
    user(),
    mentor(),
    executive(),
    ability(),
    video(),
    category(),
    cohort(),
    event(),
    criterion(),
    subcriterio(),
    talent(),
    statics(),
    evaluate(),
    analytics(),
    recruitingCompany(),
    recruitingSearch(),
    recruitingTalent(),
    recruitingTechSkill(),
    recruitingAit(),
    recruitingTechnology(),
  ]);
}
