import React, { useEffect, useState } from "react";
import { NotificationCount } from "../../styles";
import { AiOutlineBell } from "react-icons/ai";
import { useTheme } from "styled-components";
import { connect } from "react-redux";
import services from "../../../../services";
import LoaderSpinner from "../../../LoaderSpinner";
import { StateDto } from "../../../../types/states.dto";
import { NotificationDto } from "../../../../types/notification.dto";

const Component = ({
  updateManyNotificationsStates,
  updateManyNotifications,
}: {
  updateManyNotificationsStates: StateDto;
  updateManyNotifications: NotificationDto[];
}) => {
  const theme = useTheme();
  const [currentNotifications, setCurrentNotifications] =
    useState<NotificationDto[]>();
  useEffect(() => {
    const fetchData = async () => {
      const response: any = await services.notification.getAll({
        filterBy: { seen: false },
      });
      if (response.response) {
        setCurrentNotifications(response.response);
      }
    };
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 50000);
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    if (updateManyNotificationsStates.success) {
      setCurrentNotifications((state) =>
        (state ?? [])
          .map((st) => {
            const notificationFounded = updateManyNotifications.filter(
              (updatedNotification) => updatedNotification._id === st._id
            )[0];
            if (notificationFounded) {
              return notificationFounded;
            } else {
              return st;
            }
          })
          .filter((state) => !state.seen)
      );
    }
  }, [updateManyNotificationsStates]);
  return (
    <>
      {!currentNotifications ? (
        <NotificationCount
          className={`header-notifications`}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoaderSpinner size={"10px"}></LoaderSpinner>
        </NotificationCount>
      ) : (
        <NotificationCount className={`header-notifications`}>
          {currentNotifications.length > 99
            ? `99+`
            : currentNotifications.length}
        </NotificationCount>
      )}

      <AiOutlineBell size={22} color={theme.colors.Primary}></AiOutlineBell>
    </>
  );
};

const states = ({ notificationStore }) => {
  const {
    states: updateManyNotificationsStates,
    data: updateManyNotifications,
  } = notificationStore.updateMany;
  return {
    updateManyNotificationsStates,
    updateManyNotifications,
  };
};

export default connect(states)(Component);
