import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Grid from "../../Grid";
import { Card } from "./styles";
import { Outlet } from "react-router-dom";
import List from "./List";
import Searcher from "./Searcher";
import { useRecruitingLayout } from "../../../provider/RecruitingProvider";
import { PageTitle } from "../../StyledComponents";
import Dropdown from "../../Dropdown";
import { useDispatch } from "react-redux";
import { showModal } from "../../../store/actions/modal";

interface ComponentProps {
  // title: string;
  // icon: string;
  // list: { label: string; value: string | number }[];
  // ctas: {
  //   name: string;
  //   label: string;
  //   action: () => void;
  // };
  // tabs: {
  //   name: string;
  //   title: string;
  //   description: string;
  //   content: ReactNode;
  // }[];
}

const Component = ({}: ComponentProps) => {
  const { title } = useRecruitingLayout();
  const dispatch = useDispatch();

  return (
    <>
      <PageTitle>{title}</PageTitle>
      <Grid.Row style={{ height: "calc(100% - 59px)" }}>
        <Grid.Col lg={4}>
          <Card style={{ padding: "10px" }}>
            <Searcher />
            <List />
          </Card>
        </Grid.Col>

        <Grid.Col>
          <Card>
            <Dropdown
              style={{ position: "absolute", top: "20px", right: "24px" }}
            >
              <button
                onClick={() => dispatch(showModal("confirm-delete-item-modal"))}
              >
                Eliminar
              </button>
            </Dropdown>
            <Outlet />
          </Card>
        </Grid.Col>
      </Grid.Row>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        limit={1}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Component;
