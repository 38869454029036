import styled from "styled-components";

export const EditTalent = styled("button")`
  padding: 0px;
  margin: 0px;
  background: none;
  border: 0;
  position: absolute;
  left: calc(100% + 20px);
  top: 23px;
  height: 40px;
  cursor: pointer;
`;
