import {
  GetAllCDOCs,
  GET_ALL_CDOCS,
  GetCDOC,
  GET_CDOC,
  UpdateCDOC,
  UPDATE_CDOC,
  DeleteCDOC,
  DELETE_CDOC,
  GET_CDOC_SUCCESS,
  GetCDOCSuccess,
  NewCDOC,
  NEW_CDOC,
  ResetNewCDOC,
  RESET_NEW_CDOC,
  ResetDeleteCDOC,
  RESET_DELETE_CDOC,
  ResetUpdateCDOC,
  RESET_UPDATE_CDOC,
  ADD_USER_CDOC,
  SHARED_CDOC,
  RESET_GET_CDOC,
  UPDATE_MANY_CDOC,
  RESET_UPDATE_MANY_CDOC,
  RESET_GET_ALL_CDOC_DATA,
} from "../types/cdoc";

export const getAllCDOCs = (payload): GetAllCDOCs => {
  return { type: GET_ALL_CDOCS, payload };
};

export const resetGetAllCdocs = () => {
  return { type: RESET_GET_ALL_CDOC_DATA, payload: null };
};

export const getCDOCs = (_id): GetCDOC => {
  return { type: GET_CDOC, payload: _id };
};

export const resetGetCdocs = () => {
  return { type: RESET_GET_CDOC, payload: null };
};

export const setSelectedCDOCs = (payload): GetCDOCSuccess => {
  return { type: GET_CDOC_SUCCESS, payload };
};

export const updateCDOCs = (payload): UpdateCDOC => {
  return { type: UPDATE_CDOC, payload };
};

export const updateManyCdoc = (payload) => {
  return { type: UPDATE_MANY_CDOC, payload };
};

export const resetUpdateManyCdoc = () => {
  return { type: RESET_UPDATE_MANY_CDOC, payload: null };
};

export const shareCdoc = (payload) => {
  return { type: SHARED_CDOC, payload };
};

export const addUserToCdoc = (payload) => {
  return { type: ADD_USER_CDOC, payload };
};

export const resetUpdateCDOCs = (): ResetUpdateCDOC => {
  return { type: RESET_UPDATE_CDOC, payload: null };
};

export const newCDOCs = (payload): NewCDOC => {
  return { type: NEW_CDOC, payload };
};

export const resetNewCDOCs = (): ResetNewCDOC => {
  return { type: RESET_NEW_CDOC, payload: null };
};

export const deleteCDOCs = (id): DeleteCDOC => {
  return { type: DELETE_CDOC, payload: id };
};

export const resetDeleteCDOCs = (): ResetDeleteCDOC => {
  return { type: RESET_DELETE_CDOC, payload: null };
};
