import React, { useEffect, useState } from "react";

import { Center, FlexContainer } from "../../../../components/StyledComponents";
import Button from "../../../../components/Button";
import { connect, useDispatch } from "react-redux";
import { AcademyDto } from "../../../../types/academy.dto";
import { UserDto } from "../../../../types/user.dto";

import { CohortDto } from "../../../../types/cohort.dto";
import notificationActions from "../../../../store/actions/notification";
import invitationsActions from "../../../../store/actions/invitation";
import Loader from "../../../../components/Loader";
import academyActions from "../../../../store/actions/academies";

import { InvitationDto } from "../../../../types/invitation.dto";
import {
  DateFromNotification,
  EntityImageContainer,
  EntityName,
  EntityTitleDescription,
  ListItem,
} from "./style";
import { StateDto } from "../../../../types/states.dto";
import { NotificationDto } from "../../../../types/notification.dto";

import Grid from "../../../../components/Grid";
import {
  getAllCohorts,
  resetGetAllCohortsData,
} from "../../../../store/actions/cohort";
import { UsersDto } from "../../../../types/users.dto";
import InfinityScroll from "../../../../components/InfinityScroll";
import { DateFromNow } from "../../../../helpers/format-date";
import { Img } from "../../../../pages/SignIn/styles";
import FormattedStrongMessage from "../../../FormattedStrongMessage";
import { setCurrent } from "../../../../store/actions/current";
import { useNavigate } from "react-router-dom";
import services from "../../../../services";
interface ComponentProps {
  user: UserDto;
  cohorts: CohortDto[];
  notifications: NotificationDto[];
  academies: AcademyDto[];
  updateNotificationStates: StateDto;
  invitations: InvitationDto[];
  users: UsersDto[];
  notificationsStates: StateDto;
  updateManyNotifications: NotificationDto[];
  updateManyNotificationsStates: StateDto;
}

interface FetchData {
  page: number;
  filterBy?: { [key: string]: string | number | boolean };
}

const filterValues = [
  {
    fieldName: "type",
    value: "no",
    name: "Todos",
  },
  {
    fieldName: "type",
    value: "Academy",
    name: "Academias",
  },
  {
    fieldName: "type",
    value: "Organization",
    name: "Organizaciones",
  },
];

const Component = ({
  user,
  cohorts,
  notifications,
  academies,
  updateNotificationStates,
  invitations,
  users,
  notificationsStates,
  updateManyNotifications,
  updateManyNotificationsStates,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fetchData = ({ page, filterBy }: FetchData) => {
    let payload = {
      limit: 9,
      offset: 9 * Math.floor(page),
      filterBy: {
        ...(filterBy ? filterBy : {}),
        active: true,
      },
    };
    const sortBy = "createdAt";

    const sortDirection = "desc";
    dispatch(notificationActions.getAll({ ...payload, sortBy, sortDirection }));
  };
  const [currentNotificationsList, setCurrentNotificationsList] = useState<
    NotificationDto[]
  >([]);
  const [applyedFilters, setFilters] = useState<
    {
      field: string;
      value: string | boolean;
      special: (...args: any) => boolean;
      groupal: (...args: any) => any[];
    }[]
  >([]);
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);

  const buildFilters = (newFilter) => {
    const filterUpdated =
      applyedFilters.filter(
        (applyedFilter) => applyedFilter.field === newFilter.field
      ).length > 0;

    const filterRemoved = newFilter.value === "no";

    /**
     * Se elimina el filtro
     */
    if (filterRemoved) {
      setFilters([
        ...applyedFilters.filter(
          (applyedFilter) => applyedFilter.field !== newFilter.field
        ),
      ]);
    }

    /**
     * Se actualiza un filtro
     */
    if (filterUpdated && !filterRemoved) {
      setFilters([
        ...applyedFilters.map((applyedFilter) => {
          if (applyedFilter.field === newFilter.field) {
            return newFilter;
          } else {
            return applyedFilter;
          }
        }),
      ]);
    }

    /**
     * Se agrega un filtro
     */
    if (!filterUpdated && !filterRemoved) {
      setFilters([...applyedFilters, newFilter]);
    }
  };

  useEffect(() => {
    buildFilters({ field: "type", value: "no" });
    return () => {
      dispatch(resetGetAllCohortsData());
      dispatch(academyActions.resetGetAllAcademiesData());
      dispatch(notificationActions.resetGetAll());
    };
  }, []);
  useEffect(() => {
    if (updateManyNotificationsStates.success) {
      dispatch(notificationActions.resetUpdateMany());
    }
    if (updateManyNotificationsStates.error) {
      dispatch(notificationActions.resetUpdateMany());
    }
  }, [updateManyNotificationsStates]);
  useEffect(() => {
    if (!!notifications && !isLoading) {
      /* Me dice en que lugar estoy */

      dispatch(
        notificationActions.updateMany({
          items: notifications.map((notification) => {
            return {
              _id: notification._id,
              seen: true,
            };
          }),
        })
      );
      setCurrentNotificationsList((state) => [...state, ...notifications]);
      setThereAreItems(notifications.length > 0);
    }
  }, [notifications, isLoading]);

  useEffect(() => {
    setThereAreItems(!!notifications && notifications?.length > 0);
  }, [notifications]);

  useEffect(() => {
    setIsLoading(notificationsStates.loading);
  }, [notificationsStates]);

  return (
    <InfinityScroll
      action={fetchData}
      page={page}
      setPage={setPage}
      data={notifications}
      active={thereAreItems}
      isLoading={isLoading}
      style={{ paddingBottom: "10px" }}
    >
      <FlexContainer gap="5px" direction="column">
        {currentNotificationsList.map((notification) => {
          return (
            <ListItem
              onClick={async () => {
                if (notification.type === "Academy") {
                  const response: any = await services.academy.getOne({
                    _id: notification.entity._id,
                  });
                  if (response.response) {
                    dispatch(
                      setCurrent({
                        currentSection: "academy",
                        id: notification.entity._id,
                        role:
                          response.response.users.filter(
                            (userF) => userF.user === user._id && !userF.roleEnd
                          )[0].role ?? "OBSERVER",
                      })
                    );
                    navigate("/dashboard/contents/video");
                  }
                }
              }}
            >
              <FlexContainer justify="space-between" align="center">
                <FlexContainer gap="10px">
                  <EntityImageContainer>
                    <Img src={notification.entity.picture}></Img>
                  </EntityImageContainer>
                  <FlexContainer direction="column" gap="6px">
                    <EntityTitleDescription>
                      <FormattedStrongMessage
                        text={notification.description}
                        normalTextStyle={{ fontSize: "10px" }}
                        strongTextStyle={{
                          fontSize: "10px",
                          fontWeight: "bold",
                        }}
                      ></FormattedStrongMessage>
                    </EntityTitleDescription>
                    <DateFromNotification>
                      {`Hace ${DateFromNow(notification.createdAt)}`}
                    </DateFromNotification>
                  </FlexContainer>
                </FlexContainer>
              </FlexContainer>
              {notification.invitation && (
                <FlexContainer justify="center" wrap="wrap" gap="8px">
                  <Button
                    type="button"
                    loading={updateNotificationStates.loading}
                    onClick={() => {
                      dispatch(
                        invitationsActions.update({
                          _id: notification.invitation,
                          condition: "accept",
                        })
                      );
                    }}
                    style={{
                      height: "24px",
                      minWidth: "80px",
                    }}
                    options={{
                      size: "md",
                      type: "filled",
                      skin: "primary",
                    }}
                  >
                    Aceptar
                  </Button>
                  <Button
                    type="button"
                    loading={updateNotificationStates.loading}
                    onClick={() => {
                      dispatch(
                        invitationsActions.update({
                          _id: notification.invitation,
                          condition: "reject",
                        })
                      );
                    }}
                    style={{
                      height: "24px",
                      minWidth: "80px",
                    }}
                    options={{
                      size: "md",
                      type: "filled",
                      skin: "danger",
                    }}
                  >
                    Rechazar
                  </Button>
                </FlexContainer>
              )}
            </ListItem>
          );
        })}
      </FlexContainer>
    </InfinityScroll>
  );
};

const states = ({
  userStore,
  cohortStore,
  invitationStore,
  academyStore,
  notificationStore,
  usersStore,
}) => {
  const { states: updateNotificationStates } = invitationStore.update;
  const { data: invitations } = invitationStore.all;
  const { data: academies } = academyStore.all;
  const { data: notifications, states: notificationsStates } =
    notificationStore.all;
  const { states: updateManyNotificationsStates, updateManyNotifications } =
    notificationStore.updateMany;
  const { data: user } = userStore;
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: users } = usersStore.all;
  return {
    notifications,
    user,
    cohorts,
    academies,
    updateNotificationStates,
    invitations,
    users,
    notificationsStates,
    updateManyNotificationsStates,
    updateManyNotifications,
  };
};

export default connect(states)(Component);
