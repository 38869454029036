import {
  initialState,
  Actions,
  State,
  GET_ALL_UNITS,
  GET_ALL_UNITS_ERROR,
  GET_ALL_UNITS_SUCCESS,
  GET_UNIT,
  GET_UNIT_ERROR,
  GET_UNIT_SUCCESS,
  NEW_UNIT,
  NEW_UNIT_ERROR,
  NEW_UNIT_SUCCESS,
  RESET_NEW_UNIT,
  UPDATE_UNIT,
  UPDATE_UNIT_ERROR,
  UPDATE_UNIT_SUCCESS,
  RESET_UPDATE_UNIT,
  DELETE_UNIT,
  DELETE_UNIT_ERROR,
  DELETE_UNIT_SUCCESS,
  RESET_DELETE_UNIT,
  DELETE_UNIT_MEMBER,
  DELETE_UNIT_MEMBER_ERROR,
  DELETE_UNIT_MEMBER_SUCCESS,
  RESET_GET_ALL_UNITS,
} from "../types/unit";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_UNITS:
      return {
        ...state,
        allUnits: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_UNITS_SUCCESS:
      return {
        ...state,
        allUnits: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_UNITS_ERROR:
      return {
        ...state,
        allUnits: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_GET_ALL_UNITS:
      return {
        ...state,
        allUnits: initialState.allUnits,
      };
    case DELETE_UNIT_MEMBER:
      return {
        ...state,
        deleteUnit: {
          data: null,
          states: {
            loading: true,
            success: false,
            error: false,
          },
        },
      };
    case DELETE_UNIT_MEMBER_SUCCESS:
      return {
        ...state,
        deleteUnit: {
          data: null,
          states: {
            loading: false,
            success: true,
            error: false,
          },
        },
      };
    case DELETE_UNIT_MEMBER_ERROR:
      return {
        ...state,
        deleteUnit: {
          data: null,
          states: {
            loading: false,
            success: false,
            error: true,
          },
        },
      };
    case GET_UNIT:
      return {
        ...state,
        cohort: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };

    case GET_UNIT_SUCCESS:
      return {
        ...state,
        cohort: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_UNIT_ERROR:
      return {
        ...state,
        cohort: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case NEW_UNIT:
      return {
        ...state,
        newUnit: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_UNIT_SUCCESS:
      return {
        ...state,
        newUnit: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case NEW_UNIT_ERROR:
      return {
        ...state,
        newUnit: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_UNIT:
      return {
        ...state,
        newUnit: initialState.newUnit,
      };
    case DELETE_UNIT:
      return {
        ...state,
        deleteUnit: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_UNIT_SUCCESS:
      return {
        ...state,
        deleteUnit: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_UNIT_ERROR:
      return {
        ...state,
        deleteUnit: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_UNIT:
      return {
        ...state,
        deleteUnit: initialState.deleteUnit,
      };
    case UPDATE_UNIT:
      return {
        ...state,
        updateUnit: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_UNIT_SUCCESS:
      return {
        ...state,
        updateUnit: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_UNIT_ERROR:
      return {
        ...state,
        updateUnit: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_UNIT:
      return {
        ...state,
        updateUnit: initialState.updateUnit,
      };
    default:
      return state;
  }
};

export default reducers;
