import * as Yup from "yup";

export const schema = Yup.object().shape({
  cohort: Yup.string().required("Seleccionar bootcamp"),
  students: Yup.array()
    .of(
      Yup.object().shape({
        grade: Yup.number()
          .min(1, "Nota debe ser mayor a 1")
          .max(5, "Nota debe ser menor a 5"),
        name: Yup.string().required("Usuario no tiene nombre"),
        id: Yup.string().required(),
      })
    )
    .required(),
  stage: Yup.number().required("Seleccione un etapa"),
});

interface FormValues {
  cohort: string;
  students: { grade: number; id: string; name: string }[];
  stage: number | null;
}

export const initialValues: FormValues = {
  cohort: "",
  students: [],
  stage: null,
};
