import { put, call, takeLatest } from "redux-saga/effects";
import services from "../../services";
import {
  NEW_PLAN,
  NEW_PLAN_ERROR,
  NEW_PLAN_SUCCESS,
  NewPlan,
  GET_ALL_PLANS_ERROR,
  GET_ALL_PLANS_SUCCESS,
  GetAllPlans,
  GET_ALL_PLANS,
  UPDATE_PLAN_ERROR,
  UPDATE_PLAN_SUCCESS,
  UpdatePlan,
  UPDATE_PLAN,
  DeletePlan,
  DELETE_PLAN,
  DELETE_PLAN_ERROR,
  DELETE_PLAN_SUCCESS,
} from "../types/plan";

function* newPlan({ payload }) {
  const { response, error } = yield call(services.plan.new, payload);

  if (error) {
    yield put({
      type: NEW_PLAN_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: NEW_PLAN_SUCCESS, payload: response });
  }
}

function* getAllPlans({ payload }) {
  const { response, error } = yield call(services.plan.getAll, payload);
  if (error) {
    yield put({
      type: GET_ALL_PLANS_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_PLANS_SUCCESS, payload: response });
  }
}

function* updatedPlan({ payload }) {
  const { response, error } = yield call(services.plan.updated, payload);
  if (error) {
    yield put({
      type: UPDATE_PLAN_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: UPDATE_PLAN_SUCCESS, payload: response });
  }
}
function* deletePlan({ payload }) {
  const { response, error } = yield call(services.plan.delete, payload);
  if (error) {
    yield put({
      type: DELETE_PLAN_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: DELETE_PLAN_SUCCESS, payload: response });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<NewPlan>(NEW_PLAN, newPlan);
  yield takeLatest<GetAllPlans>(GET_ALL_PLANS, getAllPlans);
  yield takeLatest<UpdatePlan>(UPDATE_PLAN, updatedPlan);
  yield takeLatest<DeletePlan>(DELETE_PLAN, deletePlan);
}
