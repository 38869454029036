import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Modal from "../../../../../../components/Modal";
import { Formik } from "formik";
import { Center } from "../../../../../../components/StyledComponents";
import {
  initialValues,
  schema,
} from "../../../../../../constants/form/user/profile/data";
import Grid from "../../../../../../components/Grid";
import Button from "../../../../../../components/Button";
import Submit from "../../../../../../components/Form/Submit";
import { toast } from "react-toastify";
import Input from "../../../../../../components/Form/Input";
import usersActions from "../../../../../../store/actions/users";
import { hideModal } from "../../../../../../store/actions/modal";
import { updateUser } from "../../../../../../store/actions/user";

const Component = ({ updateStates, current, users, user }) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const dispatch = useDispatch();

  const onSubmit = ({ values, actions }) => {
    dispatch(updateUser({ _id: user._id, body: { ...values } }));
    setFormActions(actions);
  };

  return (
    <Modal name="edit-profile-contact" title="Datos de Contacto">
      <Formik
        initialValues={{
          ...initialValues(user),
        }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="edit-profile-data-form"
            >
              <Grid.Row>
                <Grid.Col>
                  <Input
                    name={"phone"}
                    error={errors["phone"]}
                    touched={touched["phone"]}
                    value={values["phone"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{ label: "Teléfono", skin: "gray" }}
                  />
                  <Input
                    name={"email"}
                    error={errors["email"]}
                    touched={touched["email"]}
                    value={values["email"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{ label: "E-mail", skin: "gray" }}
                  />
                  <Input
                    name={"location"}
                    error={errors["location"]}
                    touched={touched["location"]}
                    value={values["location"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "Ubicación",
                      skin: "gray",
                    }}
                  />
                  <Input
                    name={"linkedinProfile"}
                    error={errors["linkedinProfile"]}
                    touched={touched["linkedinProfile"]}
                    value={values["linkedinProfile"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{ label: "Linkedin", skin: "gray" }}
                  />
                  <Input
                    name={"github"}
                    error={errors["github"]}
                    touched={touched["github"]}
                    value={values["github"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "GitHub",
                      skin: "gray",
                    }}
                  />
                  <Input
                    name={"website"}
                    error={errors["website"]}
                    touched={touched["website"]}
                    value={values["website"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "WebSite",
                      skin: "gray",
                    }}
                  />
                </Grid.Col>
              </Grid.Row>

              <Grid.Row>
                <Grid.Col>
                  <Center gap={"70px"}>
                    <Button
                      onClick={resetForm}
                      type="button"
                      options={{
                        type: "outline",
                        skin: "gray",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                      style={{ marginLeft: "10px" }}
                    >
                      Cancelar
                    </Button>

                    <Submit
                      isSubmmiting={isSubmitting}
                      form="edit-profile-data-form"
                      color="Primary"
                      options={{
                        type: "filled",
                        skin: "primary",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                    >
                      Confirmar
                    </Submit>
                  </Center>
                </Grid.Col>
              </Grid.Row>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const states = ({ usersStore, currentStore, userStore }) => {
  const { states: updateStates } = usersStore.update;
  const { data: current } = currentStore;
  const { data: users } = usersStore.all;
  const { data: user } = userStore;
  return {
    updateStates,
    current,
    users,
    user,
  };
};

export default connect(states)(Component);
