import Grid from "../../../../components/Grid";
import { Logo } from "../../../../components/Header/styles";
import {
  Card,
  CardColumn,
  CardRow,
  CardTitle,
  DataText,
  LinkText,
  TextTitle,
} from "./style";
import EditButton from "../Profile/components/EditButton/index";
import EditProfileModal from "./components/EditProfileModal";
import EditContactModal from "./components/EditContactModal";
import EditVisibilityModal from "./components/EditVisibilityModal";
import {
  deleteCohort,
  resetDeleteCohort,
} from "../../../../store/actions/cohort";

import { AiFillLinkedin, AiOutlineMail, AiFillGithub } from "react-icons/ai";
import { FaBriefcase } from "react-icons/fa";
import { HiAcademicCap } from "react-icons/hi";
import { BsEyeFill, BsPersonCircle } from "react-icons/bs";
import { FiSmartphone } from "react-icons/fi";
import { GoLocation } from "react-icons/go";
import { TbWorld } from "react-icons/tb";
import { connect } from "react-redux";
import { CohortDto } from "../../../../types/cohort.dto";
import { useEffect, useState } from "react";
import { AcademyDto } from "../../../../types/academy.dto";
import Loader from "../../../../components/Loader";
import { useDispatch } from "react-redux";
import { UsersDto } from "../../../../types/users.dto";
import { toast } from "react-toastify";
import { CurrentDto } from "../../../../types/current.dto";
import { UserDto } from "../../../../types/user.dto";
import { OrganizationDto } from "../../../../types/organization.dto";
import Entity from "../../../../assets/images/team-icon-24.png";
import { StateDto } from "../../../../types/states.dto";
import { resetUpdateUser } from "../../../../store/actions/user";
import { hideModal, showModal } from "../../../../store/actions/modal";
import moment from "moment";
import AddPasswordModal from "./components/AddPasswordModal";
import academyActions from "../../../../store/actions/academies";
import { ScrollSection } from "../../../../components/StyledComponents";

interface ComponentProps {
  cohorts: CohortDto[];
  academies: AcademyDto[];
  selectedAcademy: AcademyDto;
  users: UsersDto[];
  deleteStates;
  current: CurrentDto;
  userLogged: UserDto;
  organizations: OrganizationDto[];
  updateUserStates: StateDto;
}

const Component = ({
  cohorts,
  academies,
  selectedAcademy,
  users,
  deleteStates,
  userLogged,
  current,
  organizations,
  updateUserStates,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const [fetchOn, setFetchOn] = useState<boolean>(false);
  useEffect(() => {
    if (updateUserStates.success) {
      toast.success(`Se actualizo usuario`);
      dispatch(resetUpdateUser());
      dispatch(hideModal());
    }
    if (updateUserStates.error) {
      toast.error(updateUserStates.error);
      dispatch(resetUpdateUser());
    }
  }, [updateUserStates]);

  useEffect(() => {
    if (!!userLogged) {
      const createDate = moment(new Date(userLogged.createdAt)).utc();
      const today = moment().utc();
      if (!userLogged.password && today.diff(createDate, "hours") < 1) {
        dispatch(showModal("change-password"));
      }
    }
  }, [userLogged]);

  useEffect(() => {
    if (deleteStates.sucess) {
      toast.success(`Se ha eliminado correctamente`);
      setTimeout(() => {
        dispatch(resetDeleteCohort());
      }, 2000);
    }
    if (deleteStates.error) {
      toast.error(deleteStates.error);
      setTimeout(() => {
        dispatch(resetDeleteCohort());
      }, 2000);
    }
  }, [deleteStates]);

  useEffect(() => {
    if (!academies) {
      setFetchOn(true);
    }
  }, [academies]);

  useEffect(() => {
    if (!academies && fetchOn)
      dispatch(
        academyActions.getAll({
          filterBy: {
            active: true,
            users: {
              $elemMatch: {
                user: userLogged._id,
                roleEnd: { $exists: false },
              },
            },
          },
        })
      );
  }, [academies, fetchOn]);

  useEffect(() => {
    return () => {
      dispatch(academyActions.resetGetAllAcademiesData());
    };
  }, []);

  if (!academies) return <Loader color="Primary"></Loader>;
  return (
    <>
      <Grid.Row>
        <Grid.Col sm={4}>
          <Grid.Row>
            <Grid.Col style={{ marginBottom: "20px" }}>
              <Card>
                <CardColumn gap="20px">
                  <CardRow align="center" gap="5px">
                    <CardColumn>
                      <div
                        style={{
                          height: "50px",
                          width: "50px",
                          overflow: "hidden",
                          objectFit: "cover",
                          borderRadius: "50%",
                        }}
                      >
                        <Logo
                          src={userLogged?.picture || Entity}
                          alt={userLogged?.position}
                        />
                      </div>
                    </CardColumn>
                    <CardColumn gap="5px">
                      <CardColumn>
                        <CardRow gap="5px">
                          <CardTitle>{userLogged?.name ?? ""}</CardTitle>
                          <CardTitle>{userLogged?.lastName ?? ""}</CardTitle>
                        </CardRow>
                      </CardColumn>
                      <CardColumn>
                        <DataText>{userLogged?.position ?? ""}</DataText>
                      </CardColumn>
                    </CardColumn>
                  </CardRow>
                  <CardRow>
                    <CardColumn gap="14px">
                      <CardRow align="center" gap="5px">
                        <DataText>{`Nacionalidad:`}</DataText>
                        <DataText>{userLogged?.nationality ?? ""}</DataText>
                      </CardRow>
                      <CardRow align="center" gap="5px">
                        <DataText>{`Fecha de Nacimiento:`}</DataText>
                        <DataText>{userLogged?.birthDate || ""}</DataText>
                      </CardRow>
                      <CardRow align="center" gap="5px">
                        <DataText>{`Genero:`}</DataText>
                        <DataText>{userLogged?.gender ?? ""}</DataText>
                      </CardRow>
                      <CardRow align="center" gap="5px">
                        <DataText>{`Estado Civil:`}</DataText>
                        <DataText>{userLogged?.civilStatus ?? ""}</DataText>
                      </CardRow>
                      <CardRow align="center" gap="5px">
                        <DataText>{`DNI:`}</DataText>
                        <DataText>{userLogged?.dni ?? ""}</DataText>
                      </CardRow>
                    </CardColumn>
                  </CardRow>
                </CardColumn>
                <EditButton modalName="edit-user-profile" />
              </Card>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col>
              <Card>
                <CardColumn gap="20px">
                  <CardRow align="center" gap="10.6px">
                    <BsPersonCircle fontSize={32}></BsPersonCircle>
                    <CardTitle>Datos de Contacto</CardTitle>
                  </CardRow>
                  <CardRow>
                    <CardColumn gap="16px">
                      <CardRow gap="8px" align="center">
                        <AiOutlineMail fontSize={20}></AiOutlineMail>
                        <DataText>{userLogged?.email}</DataText>
                      </CardRow>
                      <CardRow gap="8px" align="center">
                        <FiSmartphone fontSize={20}></FiSmartphone>
                        <DataText>{userLogged?.phone}</DataText>
                      </CardRow>
                      <CardRow gap="8px" align="center">
                        <GoLocation fontSize={20}></GoLocation>
                        <DataText>{userLogged?.location ?? ""}</DataText>
                      </CardRow>
                      <CardRow gap="8px" align="center">
                        <AiFillLinkedin fontSize={20}></AiFillLinkedin>
                        <LinkText
                          href={userLogged?.linkedinProfile ?? ""}
                          target="_blank"
                        >
                          {userLogged?.linkedinProfile ?? ""}
                        </LinkText>
                      </CardRow>
                      <CardRow gap="8px" align="center">
                        <AiFillGithub fontSize={20}></AiFillGithub>
                        <LinkText
                          href={userLogged?.github ?? ""}
                          target="_blank"
                        >
                          {userLogged?.github}
                        </LinkText>
                      </CardRow>
                      <CardRow gap="8px" align="center">
                        <TbWorld fontSize={20}></TbWorld>
                        <LinkText
                          href={userLogged?.website ?? ""}
                          target="_blank"
                        >
                          {userLogged?.website}
                        </LinkText>
                      </CardRow>
                    </CardColumn>
                  </CardRow>
                </CardColumn>
                <EditButton modalName="edit-profile-contact"></EditButton>
              </Card>
            </Grid.Col>
          </Grid.Row>
        </Grid.Col>
        <Grid.Col sm={6}>
          <Card>
            <CardColumn gap="10px">
              <CardRow align="center" gap="8px">
                <FaBriefcase fontSize={16}></FaBriefcase>
                <CardTitle>Academias</CardTitle>
              </CardRow>
              <ScrollSection
                style={{
                  padding: "3px",
                  boxShadow: "none",
                  maxHeight: "195px",
                }}
              >
                <CardColumn gap="5px">
                  {academies.map((organization) => (
                    <TextTitle>{organization.name}</TextTitle>
                  ))}
                </CardColumn>
              </ScrollSection>
            </CardColumn>
          </Card>
        </Grid.Col>
        <Grid.Col sm={2}>
          <Card>
            <CardColumn gap="20.5px">
              <CardRow align="center" gap="5px">
                <BsEyeFill fontSize={20}></BsEyeFill>
                <CardTitle>Visibilidad</CardTitle>
              </CardRow>
              <CardRow gap="6px">
                <CardTitle>Perfil: </CardTitle>
                <DataText>
                  {userLogged?.private ? "Privado" : "Publico"}
                </DataText>
              </CardRow>
            </CardColumn>
            <EditButton modalName="edit-profile-visibility"></EditButton>
          </Card>
        </Grid.Col>
      </Grid.Row>
      {userLogged && (
        <>
          <EditProfileModal></EditProfileModal>
          <EditContactModal></EditContactModal>
          <EditVisibilityModal></EditVisibilityModal>
          <AddPasswordModal></AddPasswordModal>
        </>
      )}
    </>
  );
};

const states = ({
  cohortStore,
  academyStore,
  usersStore,
  currentStore,
  userStore,
  organizationStore,
}) => {
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: academies } = academyStore.all;
  const { data: selectedAcademy } = academyStore.selected;
  const { data: users } = usersStore.all;
  const { states: deleteStates } = cohortStore.deleteCohort;
  const { data: current } = currentStore;
  const { data: userLogged } = userStore;
  const { data: organizations } = organizationStore.all;
  const { states: updateUserStates } = userStore.update;
  return {
    cohorts,
    academies,
    updateUserStates,
    selectedAcademy,
    users,
    deleteStates,
    current,
    userLogged,
    organizations,
  };
};

export default connect(states)(Component);
