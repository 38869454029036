import { initialState, Actions, State, SET_CURRENT } from "../types/current";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case SET_CURRENT:
      return {
        ...state,
        data: action.payload,
      };

    default:
      return state;
  }
};

export default reducers;
