import {
  initialState,
  Actions,
  State,
  GET_ALL_COHORTS,
  GET_ALL_COHORTS_ERROR,
  GET_ALL_COHORTS_SUCCESS,
  GET_COHORT,
  GET_COHORT_ERROR,
  GET_COHORT_SUCCESS,
  NEW_COHORT,
  NEW_COHORT_ERROR,
  NEW_COHORT_SUCCESS,
  RESET_NEW_COHORT,
  UPDATE_COHORT,
  UPDATE_COHORT_ERROR,
  UPDATE_COHORT_SUCCESS,
  RESET_UPDATE_COHORT,
  DELETE_COHORT,
  DELETE_COHORT_ERROR,
  DELETE_COHORT_SUCCESS,
  RESET_DELETE_COHORT,
  DELETE_COHORT_MEMBER,
  DELETE_COHORT_MEMBER_ERROR,
  DELETE_COHORT_MEMBER_SUCCESS,
  RESET_GET_ALL_COHORT_DATA,
  GET_ALL_COHORT_STUDENTS,
  GET_ALL_COHORT_STUDENTS_SUCCESS,
  GET_ALL_COHORT_STUDENTS_ERROR,
  RESET_GET_ALL_COHORT_STUDENTS,
  UPDATE_COHORT_PLAN,
  NEW_COHORT_PLAN,
} from "../types/cohort";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_COHORTS:
      return {
        ...state,
        allCohorts: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_COHORTS_SUCCESS:
      return {
        ...state,
        allCohorts: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_COHORTS_ERROR:
      return {
        ...state,
        allCohorts: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_GET_ALL_COHORT_DATA:
      return {
        ...state,
        allCohorts: initialState.allCohorts,
      };
    case DELETE_COHORT_MEMBER:
      return {
        ...state,
        deleteCohort: {
          data: null,
          states: {
            loading: true,
            success: false,
            error: false,
          },
        },
      };
    case DELETE_COHORT_MEMBER_SUCCESS:
      return {
        ...state,
        deleteCohort: {
          data: action.payload,
          states: {
            loading: false,
            success: true,
            error: false,
          },
        },
      };
    case DELETE_COHORT_MEMBER_ERROR:
      return {
        ...state,
        deleteCohort: {
          data: null,
          states: {
            loading: false,
            success: false,
            error: true,
          },
        },
      };
    case GET_ALL_COHORT_STUDENTS:
      return {
        ...state,
        usersByCohort: {
          data: null,
          states: {
            loading: true,
            success: false,
            error: false,
          },
        },
      };
    case UPDATE_COHORT_PLAN:
      return {
        ...state,
        updateCohort: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_COHORT_PLAN:
      return {
        ...state,
        updateCohort: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_COHORT_STUDENTS_SUCCESS:
      return {
        ...state,
        usersByCohort: {
          data: action.payload,
          states: {
            loading: false,
            success: true,
            error: false,
          },
        },
      };
    case GET_ALL_COHORT_STUDENTS_ERROR:
      return {
        ...state,
        usersByCohort: {
          data: null,
          states: {
            loading: false,
            success: false,
            error: action.payload,
          },
        },
      };
    case RESET_GET_ALL_COHORT_STUDENTS:
      return {
        ...state,
        usersByCohort: initialState.usersByCohort,
      };
    case GET_COHORT:
      return {
        ...state,
        cohort: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };

    case GET_COHORT_SUCCESS:
      return {
        ...state,
        cohort: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_COHORT_ERROR:
      return {
        ...state,
        cohort: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case NEW_COHORT:
      return {
        ...state,
        newCohort: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case NEW_COHORT_SUCCESS:
      return {
        ...state,
        newCohort: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case NEW_COHORT_ERROR:
      return {
        ...state,
        newCohort: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_COHORT:
      return {
        ...state,
        newCohort: initialState.newCohort,
      };
    case DELETE_COHORT:
      return {
        ...state,
        deleteCohort: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_COHORT_SUCCESS:
      return {
        ...state,
        deleteCohort: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_COHORT_ERROR:
      return {
        ...state,
        deleteCohort: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_COHORT:
      return {
        ...state,
        deleteCohort: initialState.deleteCohort,
      };
    case UPDATE_COHORT:
      return {
        ...state,
        updateCohort: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_COHORT_SUCCESS:
      return {
        ...state,
        updateCohort: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_COHORT_ERROR:
      return {
        ...state,
        updateCohort: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_COHORT:
      return {
        ...state,
        updateCohort: initialState.updateCohort,
      };
    default:
      return state;
  }
};

export default reducers;
