import styled from "styled-components";

export const ToolSideWrapper = styled("div")`
  position: fixed;
  right: 60px;
  bottom: 20px;
  padding: 4px 12px 4px 4px;
  border-radius: 6px;
  box-shadow: 0 0 3px 0 rgba(79, 38, 162, 0.25);
  background-color: #fff;
  overflow: visible;
  height: auto;
  transition: max-width 0.2s ease-in-out;
  ${(props) => (!!props.openDropDown ? "max-width:70px;overflow:hidden;" : "")}
`;
export const ToolItemWrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #1573e5;
  position: relative;
  padding: 4px 5px;
  border-radius: 5px;
  transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
  &:hover > div {
    visibility: visible;
  }
  svg {
    color: #1573e5;
    transition: color 0.2s ease-in-out, transform 0.2s ease-in-out;
  }
  &.active {
    background-color: #1573e5;
    svg {
      color: #fff;
    }
  }

  &.dropActive .arrow {
    transform: rotate(270deg);
  }
  &:not(.dropActive):is(.arrow) .arrow {
    transform: rotate(90deg);
  }
  &:hover {
    background-color: #1573e5;
    svg {
      color: #fff;
    }
  }
  &.disavailable {
    background-color: #fff;
    svg {
      color: rgba(0, 30, 108, 0.3);
    }
  }
  &.disavailable:hover {
    background-color: #fff;
    svg {
      color: rgba(0, 30, 108, 0.3);
    }
  }
`;

/* export const ToolItemWrapper = styled("div")`` */

export const ToolTipItem = styled("div")`
  position: absolute;
  visibility: hidden;
  width: max-content;
  transition: visibility 0.1s ease-in-out;
  top: -40px;
  right: 13px;
  padding: 5px;
  box-shadow: 0 0 6px 0 rgba(31, 119, 180, 0.2);
  background-color: #fff;
`;
