import { setFormData } from "../helpers/formData";
import action from "../helpers/rest-client";

export class ErrorCohort extends Error {}

class Cohort {
  public new(payload) {
    return action
      .Post({
        url: "/cohorts",
        body: payload,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public update({ _id, ...rest }) {
    return action
      .Patch({
        url: `/cohorts/${_id}`,
        body: setFormData(rest, ["picture", "bannerPic"]),
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getAll(payload) {
    if (payload?.filterBy) {
      payload.filterBy = JSON.stringify(payload?.filterBy);
    }

    if (payload?.select) {
      payload.select = JSON.stringify(payload?.select);
    }

    const queryString = new URLSearchParams(payload).toString();

    return action
      .Get({
        url: `/cohorts?platform=CONSTANA${
          queryString ? "&" + queryString : ""
        }`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getOne({ _id }) {
    return action
      .Get({
        url: `/cohorts/${_id}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public deleteMember({ _id, ...rest }) {
    return action
      .Patch({
        url: `/users/remove-from-cohort/${_id}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getStudentsByCohort({ _id, ...rest }) {
    const { payload } = rest;
    if (payload?.filterBy) {
      payload.filterBy = JSON.stringify(payload?.filterBy);
    }

    if (payload?.select) {
      payload.select = JSON.stringify(payload?.select);
    }

    const queryString = new URLSearchParams(payload).toString();

    return action
      .Get({
        url: `cohorts/get-users/${_id}?platform=CONSTANA${
          queryString ? "&" + queryString : ""
        }`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public delete({ _id }) {
    return action
      .Del({
        url: `/cohorts/${_id}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const cohort = new Cohort();
export default cohort;
