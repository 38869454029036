import styled from "styled-components";
import { Name as NameAit } from "../../pages/Dashboard/Recruiting/AIT/components/TalentCard/styles";
export const Header = styled("header")<{ sidebarCollapsed }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 20px 6px 25px;
  box-shadow: rgba(0, 30, 108, 0.1) 0px 0px 4px 1px;
  background-color: ${(props) => props.theme.colors.White};
  transition: left 0.4s ease-in-out;
  position: fixed;
  width: 100%;
  right: 0px;
  top: 0px;
  z-index: ${(props) => (props.modal ? "-1" : "7001")};
`;

export const NotificationCount = styled("div")`
  position: absolute;
  top: -4px;
  left: 12px;
  display: inline-block;
  padding: 0 4px;
  min-width: 8px;
  pointer-events: none;
  border-radius: 22px;
  text-align: center;
  height: 16px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  background-color: #c00;
  color: #fff;
`;

export const Name = styled(NameAit)`
  font-size: 13px;
  color: #fff;
  height: 17px;
`;

export const Ocupation = styled(NameAit)`
  font-size: 11px;
  color: #fff;
  height: 14px;
`;

export const Logo = styled("img")`
  padding: ${(props) => props.padding};
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const HeaderTools = styled("div")`
  display: flex;
  align-items: center;
  gap: ${(props) => (props.isMobile ? "30px" : "49px")};
`;

export const MarkerWrapper = styled("div")`
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  border-radius: ${(props) => props.br ?? "5px"};
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  &.active,
  &:not(.active):hover {
    background-color: ${(props) => props.theme.colors.Primary};
    button {
      color: ${(props) => props.theme.colors.White};
    }
    .caret {
      i::before {
        background-color: ${(props) => props.theme.colors.White};
      }
      transform: rotate(0deg);
    }
  }
`;

export const HeaderDropDown = styled("div")`
  visibility: hidden; /* Cambiamos display por visibility */
  overflow: hidden;
  transition: opacity 0.3s ease, transform 0.3s ease;
  position: absolute;
  top: 40px;
  left: 0;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 4px 0 rgba(0, 30, 108, 0.2);
  background-color: #fff;
  width: 180px;
  padding: 8px;
  opacity: 0;
  transform: translateY(-10px);
  z-index: 1;
  &.open {
    visibility: visible; /* Mostramos el dropdown cuando el item está en hover */
    opacity: 1;
    transform: translateY(0);
  }
`;

export const HearderDropDownWrapper = styled("div")`
  position: relative;
  transition: background-color 0.3s ease;
  cursor: pointer;
  &:hover:not(.open) .header-${(props) => props.dropdownName} {
    display: block;
  }
  &.circle {
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 36px;
      height: 36px;
      border-radius: 10px;
      z-index: -1;
    }
    &:hover::after {
      background-color: ${(props) => props.theme.colors.Primary};
    }
    &:not(.open):hover svg {
      color: ${(props) => props.theme.colors.White} !important;
    }

    &.open::after {
      background-color: ${(props) => props.theme.colors.Primary};
    }
    &.open svg {
      color: ${(props) => props.theme.colors.White} !important;
    }
  }
`;
