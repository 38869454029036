import React, { useEffect, useState } from "react";
import {
  GridCardImage,
  GridCardImageContainer,
  GridCardTitle,
  GridCardWithLabel,
  GridStructure,
  ToolKit,
  ToolKitItem,
} from "../../../../components/Layout/Dashboard/styles";
import {
  Center,
  FlexContainer,
  PageTitle,
} from "../../../../components/StyledComponents";
import { DateString } from "./styles";
import { connect, useDispatch } from "react-redux";
import { AcademyDto } from "../../../../types/academy.dto";
import { UserDto } from "../../../../types/user.dto";

import FilterByName from "./components/FilterByName";
import { CohortDto } from "../../../../types/cohort.dto";
import { FiEdit } from "react-icons/fi";
import { FaTrash } from "react-icons/fa";
import { StateDto } from "../../../../types/states.dto";
import { showModal } from "../../../../store/actions/modal";
import Loader from "../../../../components/Loader";
import { useNavigate } from "react-router-dom";
import ModalConfirmDelete from "../../../../components/ModalConfirmDelete";
import {
  deleteCohort,
  getAllCohorts,
  resetGetAllCohortsData,
  resetUpdateCohort,
} from "../../../../store/actions/cohort";
import EditModal from "./components/EditModal";
import Grid from "../../../../components/Grid";
import Button from "../../../../components/Button";
import NewModal from "./components/NewModal";
import { CurrentDto } from "../../../../types/current.dto";
import { filterCohortByRole } from "../../../../helpers/roleAcademy";
import moment from "moment";
import { FetchData } from "../../../../types/fetchData.dto";
import InfinityScroll from "../../../../components/InfinityScroll";
import {
  getAllEvents,
  resetGetAllEvents,
} from "../../../../store/actions/event";
import { EventDto } from "../../../../types/event.dto";
import { AbilityDto } from "../../../../types/ability.dto";
import { getAllAbilities } from "../../../../store/actions/ability";
import usersActions from "../../../../store/actions/users";
import { UsersDto } from "../../../../types/users.dto";
import { TagDto } from "../../../../types/tag.dto";
import {
  getAllTags,
  resetGetAllTags,
  resetNewManyTags,
  resetUpdateManyTags,
} from "../../../../store/actions/tag";
import { toast } from "react-toastify";
import { getPlans, resetGetAllPlans } from "../../../../store/actions/plan";
import { PlanDto } from "../../../../types/plan.dto";

interface ComponentProps {
  academies: AcademyDto[];
  user: UserDto;
  cohorts: CohortDto[];
  deleteCohortStates: StateDto;
  current: CurrentDto;
  updateCohortStates: StateDto;
  cohortStates: StateDto;
  events: EventDto[];
  abilities: AbilityDto[];
  users: UsersDto[];
  deletedCohort: CohortDto;
  updatedCohort: CohortDto;
  newCohortStates: StateDto;
  newCohort: CohortDto;
  tags: TagDto[];
  updatedManyTags: TagDto[];
  updateManyTagsStates: StateDto;
  newManyTags: TagDto[];
  newManyTagsStates: StateDto;
  getPlanStates: StateDto;
  plans: PlanDto[];
}

const Component = ({
  academies,
  user,
  cohorts,
  deleteCohortStates,
  updateCohortStates,
  current,
  cohortStates,
  events,
  abilities,
  users,
  deletedCohort,
  updatedCohort,
  newCohortStates,
  newCohort,
  tags,
  updatedManyTags,
  updateManyTagsStates,
  newManyTags,
  newManyTagsStates,
  getPlanStates,
  plans,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const [action, setAction] = useState<{ action: string; data: any }>();
  const [cohortSelected, setCohortSelected] = useState<CohortDto>();
  const [currentAcademiesList, setCurrentAcademiesList] = useState<CohortDto[]>(
    []
  );
  const [currentTags, setCurrentTags] = useState<TagDto[]>();
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);
  const [paginationOn, setPaginationOn] = useState<boolean>(false);
  const [currentPlans, setCurrentPlans] = useState<PlanDto[]>([]);

  const [applyedFilters, setFilters] = useState<
    {
      field: string;
      value: string | boolean;
      special: (data?: any, data2?: any) => boolean;
      groupal: (...args: any) => any[];
    }[]
  >([]);

  const [
    filteredCurrentAcademiesListValues,
    setFilteredCurrentAcademiesListValues,
  ] = useState<CohortDto[]>([]);
  const buildFilters = (newFilter) => {
    const filterUpdated =
      applyedFilters.filter(
        (applyedFilter) => applyedFilter.field === newFilter.field
      ).length > 0;

    const filterRemoved = newFilter.value === "no";

    /**
     * Se elimina el filtro
     */
    if (filterRemoved) {
      setFilters([
        ...applyedFilters.filter(
          (applyedFilter) => applyedFilter.field !== newFilter.field
        ),
      ]);
    }

    /**
     * Se actualiza un filtro
     */
    if (filterUpdated && !filterRemoved) {
      setFilters([
        ...applyedFilters.map((applyedFilter) => {
          if (applyedFilter.field === newFilter.field) {
            return newFilter;
          } else {
            return applyedFilter;
          }
        }),
      ]);
    }

    /**
     * Se agrega un filtro
     */
    if (!filterUpdated && !filterRemoved) {
      setFilters([...applyedFilters, newFilter]);
    }
  };

  const fetchData = ({ page, filterBy }: FetchData) => {
    if (page === 0) {
      setPaginationOn(true);
    }
    let payload = {
      limit: 9,
      offset: 9 * page,
      filterBy: {
        ...(filterBy ? filterBy : {}),
        active: true,
        academy: current.id,
      },
    };
    const sortBy = "createdAt";

    const sortDirection = "desc";
    dispatch(getAllCohorts({ ...payload, sortBy, sortDirection }));
  };

  useEffect(() => {
    if (paginationOn) {
      if (!isLoading && cohorts) {
        const filterCohorts = filterCohortByRole(
          cohorts,
          user._id,
          current.role
        );

        setCurrentAcademiesList((state) => [...state, ...filterCohorts]);
        dispatch(
          getPlans({
            filterBy: {
              cohort: { $in: filterCohorts.map((cohort) => cohort._id) },
            },
          })
        );
      }
      setThereAreItems(!!cohorts && cohorts?.length > 0);
    }
  }, [cohorts, isLoading, current, paginationOn]);

  useEffect(() => {
    const filteredTalents = currentAcademiesList?.filter(
      (currentTalentValues) => {
        const thereAreFilters =
          applyedFilters.filter((applyedFilter) => !applyedFilter.groupal)
            .length > 0;
        const totalOfFilters = applyedFilters.length;
        const passFilters = applyedFilters
          .filter(
            (applyedFilter) => !applyedFilter.special || !applyedFilter.groupal
          )
          .filter(
            (applyedFilter) =>
              currentTalentValues[applyedFilter.field] === applyedFilter.value
          ).length;
        const specialFilter = applyedFilters.filter(
          (applyedFilter) => !!applyedFilter.special
        );

        const speacialFilterCheck = specialFilter.filter((applyedFilter) => {
          return applyedFilter.special(
            currentTalentValues,
            applyedFilter.value
          );
        });
        return (
          !thereAreFilters ||
          (thereAreFilters &&
            totalOfFilters === passFilters + specialFilter.length &&
            speacialFilterCheck.length === specialFilter.length)
        );
      }
    );
    let initResult = filteredTalents;
    applyedFilters
      .filter((applyedFilter) => !!applyedFilter.groupal)
      .forEach((applyedFilter) => {
        initResult = applyedFilter.groupal(initResult);
      });
    setFilteredCurrentAcademiesListValues(initResult);
  }, [applyedFilters, currentAcademiesList]);

  useEffect(() => {
    if (!!action && !!cohortSelected) {
      dispatch(showModal("confirm-delete-item-secuencial-modal"));
    }
  }, [action, cohortSelected]);

  useEffect(() => {
    setIsLoading(cohortStates.loading);
  }, [cohortStates]);

  useEffect(() => {
    !events &&
      paginationOn &&
      dispatch(getAllEvents({ filterBy: { academy: current.id } }));
  }, [events, paginationOn]);

  useEffect(() => {
    !abilities &&
      paginationOn &&
      dispatch(
        getAllAbilities({
          filterBy: {
            $or: [{ academy: current.id }, { academy: { $exists: false } }],
          },
        })
      );
  }, [abilities, paginationOn]);

  useEffect(() => {
    !users &&
      paginationOn &&
      dispatch(
        usersActions.getAllByAcademy({
          _id: current.id,
          payload: { filterBy: {} },
        })
      );
  }, [users, paginationOn]);

  useEffect(() => {
    if (newCohortStates.success) {
      setCurrentAcademiesList((state) => [newCohort, ...state]);
    }
  }, [newCohortStates]);

  useEffect(() => {
    if (updateCohortStates.success) {
      setCurrentAcademiesList((state) =>
        state.map((st) => (st._id === updatedCohort._id ? updatedCohort : st))
      );
    }
  }, [updateCohortStates]);

  useEffect(() => {
    if (deleteCohortStates.success) {
      setCurrentAcademiesList((state) =>
        state.filter((st) => st._id !== deletedCohort._id)
      );
    }
  }, [deleteCohortStates]);

  useEffect(() => {
    paginationOn &&
      !tags &&
      dispatch(
        getAllTags({
          filterBy: { academy: current.id, cohorts: { $exists: true } },
        })
      );
  }, [tags, paginationOn]);

  useEffect(() => {
    if (tags && paginationOn) {
      setCurrentTags(tags);
    }
  }, [tags, paginationOn]);

  useEffect(() => {
    if (updateManyTagsStates.success) {
      setCurrentTags((state) =>
        state?.map((st) => {
          const foundedTag = updatedManyTags.filter(
            (updatedTag) => updatedTag._id === st._id
          )[0];
          if (foundedTag) return foundedTag;
          return st;
        })
      );
      dispatch(resetUpdateManyTags());
    }
    if (updateManyTagsStates.error) {
      toast.error(updateManyTagsStates.error);
      dispatch(resetUpdateManyTags());
    }
  }, [updateManyTagsStates]);

  useEffect(() => {
    if (newManyTagsStates.success) {
      setCurrentTags((state) => [...(state ?? []), ...newManyTags]);
      dispatch(resetNewManyTags());
    }
    if (newManyTagsStates.error) {
      toast.error(newManyTagsStates.error);
      dispatch(resetNewManyTags());
    }
  }, [newManyTagsStates]);

  useEffect(() => {
    if (cohortSelected) {
      dispatch(getPlans({ filterBy: { cohort: cohortSelected._id } }));
    }
  }, [cohortSelected]);

  useEffect(() => {
    if (plans && paginationOn) {
      setCurrentPlans((state) => [...state, ...plans]);
    }
  }, [plans]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllPlans());
      dispatch(resetGetAllCohortsData());
      dispatch(resetGetAllEvents());
      dispatch(usersActions.resetGetAll());
      dispatch(resetGetAllTags());
    };
  }, []);

  return (
    <>
      <PageTitle>Cohorts</PageTitle>
      <Grid.Row>
        <Grid.Col offset={{ sm: 10 }} sm={12}>
          <Button
            onClick={() => {
              dispatch(showModal("new-cohort-modal"));
            }}
            type="button"
            options={{
              type: "filled",
              skin: "primary",
              size: "md",
              marginBottom: "10px",
            }}
            style={{ marginLeft: "10px", backgroundColor: "rgb(0, 30, 108)" }}
          >
            Crear
          </Button>
        </Grid.Col>
      </Grid.Row>
      <Center>
        <div style={{ width: "50%", marginBottom: "20px" }}>
          <FilterByName
            items={filteredCurrentAcademiesListValues.map((cohort) => {
              return { value: cohort.name, label: cohort.name };
            })}
            setFilter={buildFilters}
          ></FilterByName>
        </div>
      </Center>
      <div style={{ height: "24px" }}></div>
      <InfinityScroll
        action={fetchData}
        page={page}
        setPage={setPage}
        data={filteredCurrentAcademiesListValues}
        active={thereAreItems}
        isLoading={isLoading}
      >
        <GridStructure width="275px" maxWidth="900px">
          {filteredCurrentAcademiesListValues.map((cohort, key) => (
            <GridCardWithLabel key={cohort._id}>
              <GridCardImageContainer>
                <GridCardImage
                  src={
                    cohort.picture ??
                    "https://upload.wikimedia.org/wikipedia/commons/8/81/Gallet_clamshell_600x600_6.jpg"
                  }
                ></GridCardImage>
              </GridCardImageContainer>
              <ToolKit className="toolkit" top="1px" right="1px">
                <ToolKitItem
                  onClick={(ev) => {
                    ev.stopPropagation();
                    setCohortSelected(cohort);
                    dispatch(showModal("edit-cohort-modal"));
                  }}
                >
                  <FiEdit fontSize={15} color="#A9AFB7"></FiEdit>
                </ToolKitItem>
                {currentPlans.find(
                  (currentPlan) =>
                    currentPlan.cohort === cohort._id &&
                    currentPlan.type === "SUBSCRIPTION"
                ) && (
                  <ToolKitItem
                    onClick={(ev) => {
                      ev.stopPropagation();
                      setCohortSelected(cohort);
                      setAction({
                        action: "delete-cohort",
                        data: { _id: cohort._id },
                      });
                    }}
                  >
                    <FaTrash fontSize={15} color="#A9AFB7"></FaTrash>
                  </ToolKitItem>
                )}
              </ToolKit>
              <FlexContainer
                style={{ marginLeft: "5px", marginBottom: "10px" }}
                justify="space-between"
              >
                <GridCardTitle>{cohort.name}</GridCardTitle>
              </FlexContainer>
              <FlexContainer
                style={{ marginLeft: "5px" }}
                direction="column"
                gap="10px"
              >
                <FlexContainer direction="column" gap="4px">
                  {cohort.startDate && cohort.type === "SYNCHRONOUS" && (
                    <DateString>
                      {`Inicia: ${
                        cohort.startDate
                          ? moment
                              .utc(cohort.startDate.toLocaleString())
                              .format("DD/MM/YYYY")
                          : `No hay fecha`
                      }`}
                    </DateString>
                  )}
                  {cohort.endDate && cohort.type === "SYNCHRONOUS" && (
                    <DateString>{`Finaliza: ${
                      cohort.endDate
                        ? moment
                            .utc(cohort.endDate.toLocaleString())
                            .format("DD/MM/YYYY")
                        : `No hay fecha`
                    }`}</DateString>
                  )}
                </FlexContainer>
                <FlexContainer direction="column" gap="18px"></FlexContainer>
              </FlexContainer>
            </GridCardWithLabel>
          ))}
        </GridStructure>
      </InfinityScroll>
      {action?.action === "delete-cohort" && !!cohortSelected && (
        <ModalConfirmDelete
          bntConfig={{
            content: "Eliminar",
            style: {
              options: {
                type: "outline",
                skin: "danger",
                size: "lg",
                marginBottom: "0px",
              },
            },
          }}
          states={deleteCohortStates}
          title={
            <span>
              {`¿Seguro que quiere eliminar el cohort `}
              <span style={{ fontWeight: "bold", color: "black" }}>
                {cohortSelected?.name}
              </span>
              ?
            </span>
          }
          elementActions={deleteCohort(action.data)}
          resetAction={resetUpdateCohort}
          resetState={setAction}
        ></ModalConfirmDelete>
      )}
      {!!cohortSelected && (
        <EditModal tags={currentTags} cohort={cohortSelected}></EditModal>
      )}
      <NewModal tags={currentTags}></NewModal>
    </>
  );
};

const states = ({
  academyStore,
  userStore,
  cohortStore,
  currentStore,
  eventStore,
  abilityStore,
  usersStore,
  tagStore,
  planStore,
}) => {
  const { data: academies } = academyStore.all;
  const { data: user } = userStore;
  const { data: cohorts, states: cohortStates } = cohortStore.allCohorts;
  const { states: deleteCohortStates, data: deletedCohort } =
    cohortStore.deleteCohort;
  const { states: updateCohortStates, data: updatedCohort } =
    cohortStore.updateCohort;
  const { states: newCohortStates, data: newCohort } = cohortStore.newCohort;
  const { data: events } = eventStore.allEvents;
  const { data: current } = currentStore;
  const { data: abilities } = abilityStore.allAbilities;
  const { data: users } = usersStore.all;
  const { data: tags } = tagStore.all;
  const { data: updatedManyTags, states: updateManyTagsStates } =
    tagStore.updateMany;
  const { data: newManyTags, states: newManyTagsStates } = tagStore.newMany;
  const { states: getPlanStates, data: plans } = planStore.all;
  return {
    academies,
    user,
    cohorts,
    cohortStates,
    deleteCohortStates,
    updateCohortStates,
    current,
    events,
    abilities,
    users,
    deletedCohort,
    updatedCohort,
    newCohortStates,
    newCohort,
    tags,
    updatedManyTags,
    updateManyTagsStates,
    newManyTags,
    newManyTagsStates,
    getPlanStates,
    plans,
  };
};

export default connect(states)(Component);
