import action from "../helpers/rest-client";

export class ErrorCohort extends Error {}

class Certificate {
  public new(payload) {
    return action
      .Post({
        url: "/certificates",
        body: payload,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public update({ _id, ...rest }) {
    return action
      .Patch({
        url: `/certificates/${_id}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getAll() {
    return action
      .Get({
        url: "/certificates",
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getOne({ _id }) {
    return action
      .Get({
        url: `/certificates/${_id}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public deleteMember({ _id, ...rest }) {
    return action
      .Patch({
        url: `/users/remove-from-cohort/${_id}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public delete({ _id }) {
    return action
      .Del({
        url: `/certificates/${_id}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const certificate = new Certificate();
export default certificate;
