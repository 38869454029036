import { put, call, all, takeLatest } from "redux-saga/effects";
import services from "../../services";
import {
  GET_UNIT,
  GET_UNIT_SUCCESS,
  GET_UNIT_ERROR,
  NEW_UNIT,
  NEW_UNIT_SUCCESS,
  NEW_UNIT_ERROR,
  GET_ALL_UNITS,
  GET_ALL_UNITS_SUCCESS,
  GET_ALL_UNITS_ERROR,
  UPDATE_UNIT,
  UPDATE_UNIT_SUCCESS,
  UPDATE_UNIT_ERROR,
  DELETE_UNIT,
  DELETE_UNIT_SUCCESS,
  DELETE_UNIT_ERROR,
  GetUnit,
  NewUnit,
  GetAllUnits,
  UpdateUnit,
  DeleteUnit,
  DELETE_UNIT_MEMBER,
  DELETE_UNIT_MEMBER_ERROR,
  DELETE_UNIT_MEMBER_SUCCESS,
  DeleteUnitMember,
} from "../types/unit";

function* getAllUnits({ payload }) {
  const { response, error } = yield call(services.unit.getAll, payload);

  if (error) {
    yield put({
      type: GET_ALL_UNITS_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_UNITS_SUCCESS, payload: response });
  }
}

function* getUnit({ payload }) {
  const { response, error } = yield call(services.unit.getOne, payload);

  if (error) {
    yield put({
      type: GET_UNIT_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_UNIT_SUCCESS, payload: response });
  }
}

function* newUnit({ payload }) {
  const { response, error } = yield call(services.unit.new, payload);

  if (error) {
    yield put({
      type: NEW_UNIT_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: NEW_UNIT_SUCCESS, payload: response })]);
  }
}

function* updateUnit({ payload }) {
  const { response, error } = yield call(services.unit.update, payload);

  if (error) {
    yield put({
      type: UPDATE_UNIT_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_UNIT_SUCCESS, payload: response })]);
  }
}

function* deleteUnit({ payload }) {
  const { response, error } = yield call(services.unit.delete, payload);

  if (error) {
    yield put({
      type: DELETE_UNIT_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: DELETE_UNIT_SUCCESS, payload: response })]);
  }
}
function* deleteUnitMember({ payload }) {
  const { response, error } = yield call(services.unit.deleteMember, payload);

  if (error) {
    yield put({
      type: DELETE_UNIT_MEMBER_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: DELETE_UNIT_MEMBER_SUCCESS, payload: response })]);
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllUnits>(GET_ALL_UNITS, getAllUnits);
  yield takeLatest<GetUnit>(GET_UNIT, getUnit);
  yield takeLatest<NewUnit>(NEW_UNIT, newUnit);
  yield takeLatest<UpdateUnit>(UPDATE_UNIT, updateUnit);
  yield takeLatest<DeleteUnit>(DELETE_UNIT, deleteUnit);
  yield takeLatest<DeleteUnitMember>(DELETE_UNIT_MEMBER, deleteUnitMember);
}
