import styled from "styled-components";

export const Col = styled.div<{ offset?: {}; order?: number; gutter }>`
  ${(props) => {
    const { offset: offsets, order, gutter: customGutter } = props;
    const { breakpoints } = props.theme;
    const { columns, gutter: defaultGutter } = props.theme.grid;
    const gutter = customGutter >= 0 ? customGutter : defaultGutter;

    let styles = `
      ${order ? `order: ${order};` : ""}
      position: relative;
      width: 100%;
      padding-right: ${gutter / 2}px;
      padding-left: ${gutter / 2}px;
      scroll-behavior: smooth;`;

    /**
     * Si contamos con offset creamos los media queries para aplicarlos
     * el offset esta conformado por:
     * - El breakpoint donde se aplica, será la key del object
     * - Tamaño del offset, que será el value del object
     *
     * offset = {{xs: 2, lg: 4}}
     */
    if (offsets) {
      Object.keys(breakpoints).forEach((breakpoint) => {
        Object.keys(offsets).forEach((offset) => {
          if (offset === breakpoint) {
            styles += `
              @media (min-width: ${breakpoints[breakpoint]}px) {
                margin-left: ${(offsets[offset] * 100) / columns}%;
              }`;
          }
        });
      });
    }

    /**
     *  Chequeamos si en los props que recibimos contamos con algún breakpoint
     *  Esperamos recibir xs, sm, md, lg, xl
     */
    let hasBreakpoints = false;

    Object.keys(breakpoints).forEach((breakpoint) => {
      if (!hasBreakpoints) {
        hasBreakpoints = props.hasOwnProperty(breakpoint);
      }
    });

    if (hasBreakpoints) {
      Object.keys(breakpoints).forEach((breakpoint) => {
        if (props.hasOwnProperty(breakpoint)) {
          const size =
            typeof props[breakpoint] === "object"
              ? props[breakpoint].size
              : props[breakpoint];
          const order = props[breakpoint].order
            ? props[breakpoint].order
            : null;

          styles += `
            @media ( min-width: ${breakpoints[breakpoint]}px ) {
              -ms-flex: 0 0 ${size ? (size * 100) / columns : 100}%;
              flex: 0 0 ${size ? (size * 100) / columns : 100}%;
              max-width: ${size ? (size * 100) / columns : 100}%;
              order: ${order != null ? `${order}` : ""};
            }`;
        }
      });
    } else {
      styles += `
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;`;
    }

    return styles;
  }}
`;
