import { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { Transition } from "react-transition-group";
import { Animation } from "../Animation";
import { connect, useDispatch } from "react-redux";
import { hideModal } from "../../store/actions/modal";

import {
  ModalBackdrop,
  Modal,
  ModalDialog,
  ModalContent,
  ModalHeader,
  ModalBody,
  Title,
  Close,
  ModalAnimation,
  Description,
} from "./styles";

import closeIcon from "../../assets/icons/close.svg";

export interface ModalProps {
  animation?: string;
  children: any;
  showClose?: boolean;
  title?: string | JSX.Element;
  name: string;
  onClose?: () => void;
  visibleModal?: string | null;
  styles?: any;
  width?: string;
  height?: string;
  position?: string;
  padding?: string;
  remove?: boolean;
  backdrop?: boolean;
  description?: string;
  onCloseModal?: () => void;
}

export const Component = ({
  animation = "fadeInSlideUp",
  showClose = true,
  children,
  title,
  name,
  onClose,
  visibleModal,
  styles,
  width,
  height,
  position,
  padding,
  remove,
  backdrop,
  description,
}: ModalProps) => {
  const modalDOM = document.getElementById("modal") as HTMLElement;
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const ref: any = useRef();

  const handleClose = () => {
    dispatch(hideModal());
    setIsVisible(false);
    if (onClose) {
      onClose();
    }
  };

  const setBodyStyles = () => {
    if (isVisible) {
      document.body.style.height = "100%";
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.height = "auto";
      document.body.style.overflow = "auto";
    }
  };

  useEffect(() => {
    setIsVisible(name === visibleModal);
  }, [name, visibleModal]);

  useEffect(() => {
    const checkIfClickOutside = (e) => {
      e.stopPropagation();
      if (isVisible && e.target.getAttribute("name") === "back") {
        handleClose();
      }
    };
    document.addEventListener("click", checkIfClickOutside);
    return () => {
      document.removeEventListener("click", checkIfClickOutside);
    };
  }, [isVisible]);

  useEffect(() => {
    setBodyStyles();
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return ReactDOM.createPortal(
    <Transition in={isVisible} timeout={350} mountOnEnter>
      {(state) => (
        <>
          <ModalAnimation
            name={animation}
            className={`animation-${state}`}
            width={width}
            duration={0.4}
            position={!!position && position}
            {...(!!styles && styles)}
          >
            <Modal ref={ref} name="back" data="hola">
              <ModalDialog width={width} name="shadow">
                <ModalContent padding={!!padding && padding} height={height}>
                  <ModalHeader>
                    {showClose && !remove && (
                      <Close onClick={() => handleClose()}>
                        <img
                          src={closeIcon}
                          alt="X"
                          width="16px"
                          height="16px"
                        />
                      </Close>
                    )}
                    {!!title && <Title>{title}</Title>}
                    {!!description && <Description>{description}</Description>}
                  </ModalHeader>
                  <ModalBody name="asdasd">{children}</ModalBody>
                  {/* <ModalFooter></ModalFooter> */}
                </ModalContent>
              </ModalDialog>
            </Modal>
          </ModalAnimation>

          <Animation name="fadeIn" className={`animation-${state}`}>
            {!!backdrop ? (
              <></>
            ) : (
              <ModalBackdrop onClick={() => handleClose()} />
            )}
          </Animation>
        </>
      )}
    </Transition>,
    modalDOM
  );
};

const state = ({ modalStore }) => ({ ...modalStore });

export default connect(state)(Component);
