import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import Button from "../../../../../components/Button";
import Grid from "../../../../../components/Grid";
import { GridStructure } from "../../../../../components/Layout/Dashboard/styles";
import LineGraphHorizontal from "../../../../../components/LineGraphHorizontal";
import Loader from "../../../../../components/Loader";
import PieChart from "../../../../../components/PieChart";
import {
  FlexContainer,
  PageTitle,
} from "../../../../../components/StyledComponents";
import { DateEStoDateEN } from "../../../../../helpers/format-date";
import { getOrganizationStats } from "../../../../../store/actions/analytics";
import AnalyticCard from "../../components/AnalyticCard";
import NotData from "../../components/NotData";
import { RankingItem, RankingTable } from "../../style";

const Component = ({
  analyticsOrganization,
  analyticsOrganizationStates,
  current,
}) => {
  const printRef = React.useRef();
  const dispatch = useDispatch();
  const handleDownloadPdf = async () => {
    if (!!printRef.current) {
      const element = printRef.current;
      const canvas = await html2canvas(element);
      const data = canvas.toDataURL("image/png");

      const pdf = new jsPDF("p", "px", [canvas.width, canvas.height]);
      const imgProperties = pdf.getImageProperties(data);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

      pdf.addImage(data, "PNG", 5, 10, pdfWidth - 10, pdfHeight + 5);
      pdf.save("print.pdf");
    }
  };
  useEffect(() => {
    dispatch(getOrganizationStats({ _id: current.id }));
  }, []);
  if (!analyticsOrganization) return <Loader color="Primary"></Loader>;

  return (
    <>
      <PageTitle>Dashboard</PageTitle>
      <Grid.Col offset={{ sm: 10 }} sm={12}>
        <Button
          onClick={() => {
            handleDownloadPdf();
          }}
          type="button"
          options={{
            type: "filled",
            skin: "primary",
            size: "md",
            marginBottom: "10px",
          }}
          style={{ marginLeft: "10px" }}
        >
          Descargar
        </Button>
      </Grid.Col>
      <FlexContainer ref={printRef} direction="column" gap="6px">
        <AnalyticCard
          height="250px"
          title="Top 10 talentos con mas interacciones"
        >
          <NotData data={analyticsOrganization.aitTalents.clicksTopTen}>
            <LineGraphHorizontal
              data={analyticsOrganization.aitTalents.clicksTopTen.map(
                (team) => {
                  return {
                    label: team.name,
                    value: team.clicks,
                  };
                }
              )}
              title={"Alumnos"}
            ></LineGraphHorizontal>
          </NotData>
        </AnalyticCard>

        <GridStructure style={{ width: "100%" }} type="auto-fit" width="400px">
          <AnalyticCard height="300px" title="Genero de tus talentos agregados">
            <NotData data={analyticsOrganization.aitTalents.genders}>
              <PieChart
                pieData={Object.entries(
                  analyticsOrganization.aitTalents.genders
                ).map((user) => {
                  return {
                    label: user[0],
                    value: user[1],
                  };
                })}
              ></PieChart>
            </NotData>
          </AnalyticCard>
          <AnalyticCard height="300px" title="Edad de tus talentos">
            <NotData data={analyticsOrganization.aitTalents.ages}>
              <PieChart
                pieData={Object.entries(
                  analyticsOrganization.aitTalents.ages
                ).map((user) => {
                  return {
                    label: user[0],
                    value: user[1],
                  };
                })}
              ></PieChart>
            </NotData>
          </AnalyticCard>
          <AnalyticCard
            height="300px"
            title="Ubicacion de tus talentos agregados"
          >
            <NotData data={analyticsOrganization.aitTalents.locations}>
              <PieChart
                pieData={Object.entries(
                  analyticsOrganization.aitTalents.locations
                ).map((user) => {
                  return {
                    label: user[0],
                    value: user[1],
                  };
                })}
              ></PieChart>
            </NotData>
          </AnalyticCard>
        </GridStructure>
      </FlexContainer>
    </>
  );
};

const states = ({ analyticsStore, currentStore }) => {
  const { data: analyticsOrganization, states: analyticsOrganizationStates } =
    analyticsStore.getOrganizationStats;
  const { data: current } = currentStore;
  return {
    analyticsOrganization,
    analyticsOrganizationStates,
    current,
  };
};

export default connect(states)(Component);
