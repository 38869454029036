import * as Yup from "yup";

export const schema = Yup.object().shape({
  unitPrice: Yup.number()
    .required("Se requiere un precio")
    .min(1, "Precio mayor a 0"),
  isUnique: Yup.boolean(),
});

interface FormValues {
  unitPrice: number;
  isUnique: boolean;
}

export const initialValues: FormValues = {
  unitPrice: 0,
  isUnique: false,
};
