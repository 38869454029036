export const fontsFamilies = [
  "Lato",
  "Roboto",
  "Montserrat",
  "Poppins",
  "Roboto Mono",
  "Ubuntu",
  "Oswald",
].map((font) => {
  return {
    label: `FONT_FAMILY_${font}`,
    value: font,
    prop: "fontFamily",
  };
});
