import React, { useEffect, useState } from "react";
import { Title } from "../../../../components/Layout/Auth/styles";
import {
  Center,
  FlexContainer,
  Separator,
} from "../../../../components/StyledComponents";
import Grid from "../../../../components/Grid";
import {
  InvalidUntil,
  SuscriptionListItem,
  SuscriptionListWrapper,
  SuscriptionPrice,
  SuscriptionStatus,
  SuscriptionText,
  SuscriptionTitle,
  SuscriptionWrapper,
} from "./style";
import ToggleButton from "./components/ToggleButton";
import Button from "../../../../components/Button";
import { connect, useDispatch } from "react-redux";
import { showModal } from "../../../../store/actions/modal";
import { AiFillEdit } from "react-icons/ai";
import MonthlyTracking from "./components/MonthlyTracking";
import FilterByName from "./components/FilterByName";
import { CohortDto } from "../../../../types/cohort.dto";
import Loader from "../../../../components/Loader";
import AddPlanModal from "./components/AddPlanModal";
import { CurrentDto } from "../../../../types/current.dto";
import { AcademyDto } from "../../../../types/academy.dto";
import academyActions from "../../../../store/actions/academies";
import {
  getAllCohorts,
  resetGetAllCohortsData,
  resetUpdateCohort,
} from "../../../../store/actions/cohort";
import { FetchData } from "../../../../types/fetchData.dto";
import InfinityScroll from "../../../../components/InfinityScroll";
import { StateDto } from "../../../../types/states.dto";
import EditPlanModal from "./components/EditPlanModal";
import { toast } from "react-toastify";
import AddPlanCohortModal from "./components/AddPlanCohortModal";
import EditPlanCohortModal from "./components/EditPlanCohortModal";
import { PlanDto } from "../../../../types/plan.dto";
import services from "../../../../services";
import {
  getPlans,
  resetDeletePlan,
  resetGetAllPlans,
  resetNewPlan,
  resetUpdatePlan,
} from "../../../../store/actions/plan";
import DashboardByPeriod from "../../../../components/DashboardByPeriod";
import {
  getAcademySuscriptionStats,
  getCohortSuscriptionStats,
  resetGetCohortSuscriptionStats,
} from "../../../../store/actions/analytics";
import { SuscriptionAnalyticsDto } from "../../../../types/analytics.dto";
const Component = ({
  cohorts,
  current,
  academy,
  getCohortsStates,
  updateAcademyStates,
  updatedAcademy,
  updatedCohort,
  updateCohortStates,
  plans,
  getPlanStates,
  updatedPlan,
  updatePlanStates,
  deletedPlan,
  deletePlanStates,
  newPlan,
  newPlanStates,
  academySuscriptionStats,
  academySuscriptionStatsStates,
  cohortSuscriptionStats,
  cohortSuscriptionStatsStates,
}: {
  cohorts: CohortDto[];
  current: CurrentDto;
  academy: AcademyDto;
  getCohortsStates: StateDto;
  updateAcademyStates: StateDto;
  updatedAcademy: AcademyDto;
  updatedCohort: CohortDto;
  updateCohortStates: StateDto;
  plans: PlanDto[];
  getPlanStates: StateDto;
  updatedPlan: PlanDto;
  updatePlanStates: StateDto;
  deletedPlan: PlanDto;
  deletePlanStates: StateDto;
  newPlan: PlanDto;
  newPlanStates: StateDto;
  academySuscriptionStats: SuscriptionAnalyticsDto;
  academySuscriptionStatsStates: StateDto;
  cohortSuscriptionStats: SuscriptionAnalyticsDto;
  cohortSuscriptionStatsStates: StateDto;
}) => {
  const dispatch = useDispatch();
  const [applyedFilters, setFilters] = useState<
    {
      field: string;
      value: string | boolean;
      special: (...args: any) => boolean;
      groupal: (...args: any) => any[];
    }[]
  >([]);
  const [fetchOn, setFetchOn] = useState<boolean>(false);
  const [cohortActiveItem, setCohortActiveItem] = useState<CohortDto>();
  const [currentCohortsList, setCurrentCohortsList] = useState<CohortDto[]>([]);

  const [
    filteredCurrentCohortsListValues,
    setFilteredCurrentCohortsListValues,
  ] = useState<CohortDto[]>([]);

  const [page, setPage] = useState<number>(0);
  const [paginationOn, setPaginationOn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [thereAreItems, setThereAreItems] = useState<boolean>(true);
  const [currentAcademyPlan, setCurrentAcademyPlan] = useState<PlanDto[]>();
  const [currentCohortsPlans, setCurrentCohortsPlans] = useState<PlanDto[]>([]);
  const [activeCohortPlan, setActiveCohortPlan] = useState<PlanDto>();
  const fetchData = ({ page, filterBy }: FetchData) => {
    if (page === 0) {
      setPaginationOn(true);
    }
    let payload = {
      limit: 9,
      offset: 9 * page,
      filterBy: {
        ...(filterBy ? filterBy : {}),
        active: true,
        academy: current.id,
      },
    };

    const sortBy = "createdAt";

    const sortDirection = "desc";
    dispatch(getAllCohorts({ ...payload, sortBy, sortDirection }));
  };

  const buildFilters = (newFilter) => {
    const filterUpdated =
      applyedFilters.filter((applyedFilter) =>
        !Array.isArray(newFilter.field)
          ? applyedFilter.field === newFilter.field
          : newFilter.field.includes(applyedFilter.field)
      ).length > 0;

    const filterRemoved = newFilter.value === "no";

    if (filterRemoved) {
      /**
       * Se elimina el filtro
       */
      if (Array.isArray(newFilter.field)) {
        setFilters([
          ...applyedFilters.filter(
            (applyedFilter) => !newFilter.field.includes(applyedFilter.field)
          ),
        ]);
      } else {
        setFilters([
          ...applyedFilters.filter(
            (applyedFilter) => applyedFilter.field !== newFilter.field
          ),
        ]);
      }
    }

    /**
     * Se actualiza un filtro
     */
    if (filterUpdated && !filterRemoved) {
      setFilters([
        ...applyedFilters.map((applyedFilter) => {
          if (applyedFilter.field === newFilter.field) {
            return newFilter;
          } else {
            return applyedFilter;
          }
        }),
      ]);
    }

    /**
     * Se agrega un filtro
     */
    if (!filterUpdated && !filterRemoved) {
      setFilters([...applyedFilters, newFilter]);
    }
  };
  useEffect(() => {
    if (paginationOn) {
      if (!isLoading && cohorts) {
        setCurrentCohortsList((state) => [...state, ...cohorts]);

        setThereAreItems(cohorts?.length > 0);
      }
    }
  }, [cohorts, isLoading, paginationOn]);

  useEffect(() => {
    if (cohorts && cohorts.length > 0 && fetchOn) {
      const fetchCohortPlans = async () => {
        const response: any = await services.plan.getAll({
          filterBy: {
            cohort: { $in: cohorts && cohorts.map((cohort) => cohort._id) },
            validFrom: { $lte: Date.now() },
            $or: [
              { validUntil: { $exists: false } },
              { validUntil: { $gt: Date.now() } },
            ],
          },
        });
        if (response.response) {
          setCurrentCohortsPlans((state) => [
            ...(state ?? []),
            ...response.response,
          ]);
        }
      };
      fetchCohortPlans();
    }
  }, [cohorts, fetchOn]);

  useEffect(() => {
    if (current.currentSection === "academy" && fetchOn) {
      dispatch(academyActions.getOne({ _id: current.id }));
    }
  }, [current, fetchOn]);

  useEffect(() => {
    setIsLoading(getCohortsStates.loading);
  }, [getCohortsStates]);

  useEffect(() => {
    const filteredTalents = currentCohortsList?.filter(
      (currentTalentValues) => {
        const thereAreFilters =
          applyedFilters.filter((applyedFilter) => !applyedFilter.groupal)
            .length > 0;
        const totalOfFilters = applyedFilters.filter(
          (applyedFilter) => !applyedFilter.groupal
        ).length;
        const passFilters = applyedFilters
          .filter(
            (applyedFilter) => !applyedFilter.special || !applyedFilter.groupal
          )
          .filter(
            (applyedFilter) =>
              currentTalentValues[applyedFilter.field] === applyedFilter.value
          ).length;
        const specialFilter = applyedFilters.filter(
          (applyedFilter) => !!applyedFilter.special
        );

        const speacialFilterCheck = specialFilter.filter((applyedFilter) => {
          return applyedFilter.special(
            currentTalentValues,
            applyedFilter.value,
            currentTalentValues
          );
        });
        return (
          !thereAreFilters ||
          (thereAreFilters &&
            totalOfFilters === passFilters + specialFilter.length &&
            speacialFilterCheck.length === specialFilter.length)
        );
      }
    );
    let initResult = filteredTalents;
    applyedFilters
      .filter((applyedFilter) => !!applyedFilter.groupal)
      .forEach((applyedFilter) => {
        initResult = applyedFilter.groupal(initResult);
      });
    setFilteredCurrentCohortsListValues(initResult);
  }, [applyedFilters, currentCohortsList]);

  useEffect(() => {
    if (cohortActiveItem) {
      dispatch(getCohortSuscriptionStats({ _id: cohortActiveItem._id }));
    }
  }, [cohortActiveItem]);

  useEffect(() => {
    if (academy) {
      dispatch(getAcademySuscriptionStats({ _id: academy._id }));
    }
  }, [academy]);

  useEffect(() => {
    if (plans && fetchOn) {
      setCurrentAcademyPlan(plans);
    }
  }, [plans, fetchOn]);

  useEffect(() => {
    if (newPlanStates.success) {
      if (newPlan.cohort) {
        setCurrentCohortsPlans((state) => [...state, newPlan]);
      } else {
        setCurrentAcademyPlan([newPlan]);
      }
      dispatch(resetNewPlan());
    }
    if (newPlanStates.error) {
      toast.error(newPlanStates.error);
      dispatch(resetNewPlan());
    }
  }, [newPlanStates]);

  useEffect(() => {
    if (!plans) {
      setFetchOn(true);
    }
  }, [plans]);

  useEffect(() => {
    if (deletePlanStates.success) {
      toast.success("Se ha eliminado el plan");
      if (deletedPlan.cohort) {
        setCurrentCohortsPlans((state) =>
          state.map((st) =>
            st.cohort === deletedPlan.cohort ? deletedPlan : st
          )
        );
      } else {
        setCurrentAcademyPlan((state) =>
          (state ?? []).map((st) =>
            st.academy === deletedPlan.academy ? deletedPlan : st
          )
        );
      }
      dispatch(resetDeletePlan());
    }
    if (deletePlanStates.error) {
      toast.error(deletePlanStates.error);
      dispatch(resetDeletePlan());
    }
  }, [deletePlanStates]);

  useEffect(() => {
    if (updatePlanStates.success) {
      toast.success(`Se ha modificado el plan`);
      if (updatedPlan.cohort) {
        setCurrentCohortsPlans((state) =>
          state.map((st) =>
            st.cohort === updatedPlan.cohort ? updatedPlan : st
          )
        );
      } else {
        setCurrentAcademyPlan((state) =>
          (state ?? []).map((st) =>
            st.academy === updatedPlan.academy ? updatedPlan : st
          )
        );
      }
      dispatch(resetUpdatePlan());
    }
    if (updatePlanStates.error) {
      toast.error(updatePlanStates.error);
      dispatch(resetUpdatePlan());
    }
  }, [updatePlanStates]);

  useEffect(() => {
    if (!plans && fetchOn) {
      dispatch(
        getPlans({
          filterBy: {
            academy: current.id,
            validFrom: { $lte: Date.now() },
            $or: [
              { validUntil: { $exists: false } },
              { validUntil: { $gt: Date.now() } },
            ],
          },
        })
      );
    }
  }, [plans, fetchOn]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllPlans());
      dispatch(resetGetAllCohortsData());
      dispatch(academyActions.resetGetOne());
    };
  }, []);

  return (
    <div style={{ margin: "0 auto", maxWidth: "800px" }}>
      <SuscriptionTitle>Suscripciones</SuscriptionTitle>
      <Separator size={14}></Separator>
      <h4>Suscripcion a mi Academia</h4>
      <Separator size={12}></Separator>
      <SuscriptionWrapper>
        <Grid.Row>
          <Grid.Col lg={6}>
            {getPlanStates.loading || !currentAcademyPlan ? (
              <Loader color="Primary"></Loader>
            ) : (
              <>
                <FlexContainer align="center" gap="20px">
                  <SuscriptionText size={14}>
                    Acceso a mi Academia
                  </SuscriptionText>
                  <SuscriptionStatus
                    className={currentAcademyPlan[0] ? "active" : ""}
                  >{`${
                    currentAcademyPlan[0] ? "paid" : "free"
                  }`}</SuscriptionStatus>
                  {currentAcademyPlan[0]?.validUntil &&
                    new Date(currentAcademyPlan[0]?.validUntil).valueOf() >
                      Date.now().valueOf() && (
                      <InvalidUntil>
                        A partir del proximo mes se cambiara a FREE
                      </InvalidUntil>
                    )}
                </FlexContainer>
                <>{console.log(currentAcademyPlan[0], "meisaxz")}</>
                <Separator size={14}></Separator>
                <FlexContainer align="center" gap="5px">
                  <SuscriptionPrice>
                    <SuscriptionText size={14}>
                      {currentAcademyPlan[0]
                        ? `${currentAcademyPlan[0].currencyId} ${currentAcademyPlan[0].unitPrice}`
                        : "No tiene plan creado"}
                    </SuscriptionText>
                  </SuscriptionPrice>
                  {currentAcademyPlan[0] && (
                    <SuscriptionText size={14}>(Por mes)</SuscriptionText>
                  )}
                </FlexContainer>
                <Separator size={14}></Separator>
                <Button
                  type="button"
                  options={{
                    size: "md",
                    type: "filled",
                    skin: "primary",
                  }}
                  style={{ minWidth: "0px" }}
                  onClick={() => {
                    currentAcademyPlan[0]
                      ? dispatch(showModal("edit-plan-modal-academy"))
                      : dispatch(showModal("add-plan-modal-academy"));
                  }}
                >
                  <FlexContainer align="center" gap="5px">
                    <span style={{ fontSize: "14px", fontWeight: 600 }}>
                      {currentAcademyPlan[0] ? "Editar Plan" : "Crear Plan"}
                    </span>
                    <AiFillEdit color="#fff" size={20}></AiFillEdit>
                  </FlexContainer>
                </Button>
              </>
            )}
          </Grid.Col>
          <Grid.Col lg={6}>
            <DashboardByPeriod
              isLoading={academySuscriptionStatsStates.loading}
              title={"Actividad de la Suscripcion en la Academia"}
              analytics={academySuscriptionStats}
            ></DashboardByPeriod>
          </Grid.Col>
        </Grid.Row>
      </SuscriptionWrapper>

      <Separator size={25}></Separator>
      <h4>Suscripciones a Cohorts</h4>
      <Separator size={12}></Separator>
      <Grid.Row>
        <Grid.Col lg={12}>
          <SuscriptionWrapper>
            <Grid.Row>
              <Grid.Col lg={6} md={12} sm={12}>
                <SuscriptionText size={14}>Cohorts</SuscriptionText>
                <Separator size={8}></Separator>
                <FilterByName
                  items={[]}
                  setFilter={buildFilters}
                ></FilterByName>
                <Separator size={12}></Separator>
                <SuscriptionListWrapper>
                  <InfinityScroll
                    action={fetchData}
                    page={page}
                    setPage={setPage}
                    data={cohorts}
                    active={thereAreItems}
                    isLoading={isLoading}
                    style={{ paddingBottom: "20px" }}
                  >
                    <FlexContainer
                      gap="10px"
                      direction="column"
                      style={{ paddingBottom: "20px" }}
                    >
                      {filteredCurrentCohortsListValues.map((cohort) => {
                        return (
                          <SuscriptionListItem
                            onClick={() => {
                              if (cohort._id !== cohortActiveItem?._id) {
                                dispatch(resetGetCohortSuscriptionStats());
                              }
                              setCohortActiveItem(cohort);
                            }}
                            active={cohort._id === cohortActiveItem?._id}
                          >
                            {cohort.name}
                          </SuscriptionListItem>
                        );
                      })}
                    </FlexContainer>
                  </InfinityScroll>
                </SuscriptionListWrapper>
              </Grid.Col>
              <Grid.Col lg={6} md={12} sm={12}>
                {cohortActiveItem ? (
                  <>
                    <FlexContainer align="center" gap="20px">
                      <SuscriptionText size={14}>
                        {`Acceso a ${cohortActiveItem?.name}`}
                      </SuscriptionText>
                      <SuscriptionStatus
                        className={
                          currentCohortsPlans.filter(
                            (cohortPlan) =>
                              cohortPlan.cohort === cohortActiveItem._id
                          )[0]
                            ? "active"
                            : ""
                        }
                      >{`${
                        currentCohortsPlans.filter(
                          (cohortPlan) =>
                            cohortPlan.cohort === cohortActiveItem._id
                        )[0]
                          ? "paid"
                          : "free"
                      }`}</SuscriptionStatus>
                      {currentCohortsPlans.filter(
                        (cohortPlan) =>
                          cohortPlan.cohort === cohortActiveItem._id
                      )[0] && (
                        <SuscriptionStatus className={"active"}>{`${
                          currentCohortsPlans.filter(
                            (cohortPlan) =>
                              cohortPlan.cohort === cohortActiveItem._id
                          )[0].type === "SUBSCRIPTION"
                            ? "monthly"
                            : "unique"
                        }`}</SuscriptionStatus>
                      )}
                      {currentCohortsPlans.filter(
                        (cohortPlan) =>
                          cohortPlan.cohort === cohortActiveItem._id
                      )[0]?.validUntil &&
                        new Date(
                          currentCohortsPlans.filter(
                            (cohortPlan) =>
                              cohortPlan.cohort === cohortActiveItem._id
                          )[0]?.validUntil
                        ).valueOf() > Date.now().valueOf() && (
                          <InvalidUntil>
                            A partir del proximo mes se cambiara a FREE
                          </InvalidUntil>
                        )}
                    </FlexContainer>

                    <Separator size={14}></Separator>
                    {currentCohortsPlans.filter(
                      (cohortPlan) => cohortPlan.cohort === cohortActiveItem._id
                    )[0] ? (
                      <FlexContainer align="center" gap="5px">
                        <SuscriptionPrice>
                          <SuscriptionText size={14}>
                            {`${
                              currentCohortsPlans.filter(
                                (cohortPlan) =>
                                  cohortPlan.cohort === cohortActiveItem._id
                              )[0].currencyId
                            } ${
                              currentCohortsPlans.filter(
                                (cohortPlan) =>
                                  cohortPlan.cohort === cohortActiveItem._id
                              )[0].unitPrice
                            }`}
                          </SuscriptionText>
                        </SuscriptionPrice>
                        <SuscriptionText size={14}>
                          {currentCohortsPlans.filter(
                            (cohortPlan) =>
                              cohortPlan.cohort === cohortActiveItem._id
                          )[0].type === "SUBSCRIPTION"
                            ? "Por mes"
                            : "Pago Unico"}
                        </SuscriptionText>
                      </FlexContainer>
                    ) : (
                      <SuscriptionText size={14}>No hay plan</SuscriptionText>
                    )}
                    <Separator size={14}></Separator>
                    <Button
                      type="button"
                      options={{
                        size: "md",
                        type: "filled",
                        skin: "primary",
                      }}
                      style={{ minWidth: "0px" }}
                      onClick={() => {
                        const plan = currentCohortsPlans.filter(
                          (cohortPlan) =>
                            cohortPlan.cohort === cohortActiveItem._id
                        )[0];
                        if (plan) {
                          dispatch(showModal("edit-plan-modal-cohort"));
                          setActiveCohortPlan(plan);
                        } else {
                          dispatch(showModal("add-plan-modal-cohort"));
                        }
                      }}
                    >
                      <FlexContainer align="center" gap="5px">
                        <span style={{ fontSize: "14px", fontWeight: 600 }}>
                          {currentCohortsPlans.filter(
                            (cohortPlan) =>
                              cohortPlan.cohort === cohortActiveItem._id
                          )[0]
                            ? "Editar Plan"
                            : "Crear Plan"}
                        </span>
                        <AiFillEdit color="#fff" size={20}></AiFillEdit>
                      </FlexContainer>
                    </Button>
                    <Separator size={14}></Separator>
                    <DashboardByPeriod
                      isLoading={cohortSuscriptionStatsStates.loading}
                      title={"Actividad de la Suscripcion del Cohort"}
                      analytics={cohortSuscriptionStats}
                    ></DashboardByPeriod>
                  </>
                ) : (
                  <FlexContainer
                    style={{ height: "100%" }}
                    align="center"
                    justify="center"
                  >
                    Esperando seleccion...
                  </FlexContainer>
                )}
              </Grid.Col>
            </Grid.Row>
          </SuscriptionWrapper>
        </Grid.Col>
      </Grid.Row>
      {cohortActiveItem && (
        <AddPlanCohortModal
          cohortActiveItem={cohortActiveItem}
        ></AddPlanCohortModal>
      )}
      {cohortActiveItem && activeCohortPlan && (
        <EditPlanCohortModal
          activeCohortPlan={activeCohortPlan}
        ></EditPlanCohortModal>
      )}
      <AddPlanModal></AddPlanModal>
      {currentAcademyPlan && currentAcademyPlan[0] && (
        <EditPlanModal currentPlan={currentAcademyPlan[0]}></EditPlanModal>
      )}
    </div>
  );
};

const states = ({
  cohortStore,
  currentStore,
  academyStore,
  planStore,
  analyticsStore,
}) => {
  const { data: cohorts, states: getCohortsStates } = cohortStore.allCohorts;
  const { data: updatedCohort, states: updateCohortStates } =
    cohortStore.updateCohort;
  const { data: current } = currentStore;
  const { data: academy } = academyStore.selected;
  const { states: updateAcademyStates, data: updatedAcademy } =
    academyStore.update;
  const { data: plans, states: getPlanStates } = planStore.all;
  const { data: updatedPlan, states: updatePlanStates } = planStore.update;
  const { data: deletedPlan, states: deletePlanStates } = planStore.delete;
  const { data: newPlan, states: newPlanStates } = planStore.new;
  const {
    data: academySuscriptionStats,
    states: academySuscriptionStatsStates,
  } = analyticsStore.academySuscriptionStats;
  const { data: cohortSuscriptionStats, states: cohortSuscriptionStatsStates } =
    analyticsStore.cohortSuscriptionStats;
  return {
    cohorts,
    current,
    academy,
    getCohortsStates,
    updateAcademyStates,
    updatedAcademy,
    updatedCohort,
    updateCohortStates,
    plans,
    getPlanStates,
    updatedPlan,
    updatePlanStates,
    deletedPlan,
    deletePlanStates,
    newPlan,
    newPlanStates,
    academySuscriptionStats,
    academySuscriptionStatsStates,
    cohortSuscriptionStats,
    cohortSuscriptionStatsStates,
  };
};

export default connect(states)(Component);
