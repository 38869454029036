import React, { useEffect, useState } from "react";
import {
  BsFillVolumeDownFill,
  BsFillVolumeMuteFill,
  BsFillVolumeOffFill,
  BsVolumeUpFill,
} from "react-icons/bs";
import { FaPause, FaPlay } from "react-icons/fa";
import { BiFullscreen } from "react-icons/bi";
import {
  PlayerControllBottom,
  PlayerCounter,
  PlayerSlider,
  PlayerSliderWrapper,
  PlayerVolumeSlider,
  PlayerVolumeSliderWrapper,
  PlayerVolumeWrapper,
} from "./style";

const Component = ({
  currentSeek,
  isPlayed,
  volumeBar,
  handlePause,
  handlePlay,
  handleSeekChange,
  totalVideoDuration,
  handleVolumeChange,
  handleMute,
  isMuted,
  handleFullScreen,
}: {
  currentSeek: number;
  isPlayed: boolean;
  volumeBar: number;
  handlePause: (e: any) => void;
  handlePlay: () => void;
  handleSeekChange: (e: any) => void;
  totalVideoDuration: number;
  handleVolumeChange: (e: any) => void;
  handleFullScreen: () => void;
  handleMute: () => void;
  isMuted: boolean;
}) => {
  const [currentTime, setCurrentTime] = useState<string>("00:00:00");
  const getDataPlayed = (secondsPlayed, section) => {
    var sec_num = parseInt(secondsPlayed, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor(sec_num / 60) % 60;
    var seconds = sec_num % 60;
    return [hours, minutes, seconds]
      .map((v) => (v < 10 ? "0" + v : v))
      .filter((v, i) => v !== "00" || i > 0)
      .join(":");
  };
  const totalDurationInTime = getDataPlayed(totalVideoDuration, "total");
  useEffect(() => {
    setCurrentTime(getDataPlayed(currentSeek, "current"));
  }, [currentSeek]);
  return (
    <PlayerControllBottom>
      {isPlayed ? (
        <FaPause
          size={25}
          color={"#2a2747"}
          onClick={(e) => handlePause(e)}
        ></FaPause>
      ) : (
        <FaPlay
          size={25}
          color={"#2a2747"}
          onClick={() => handlePlay()}
        ></FaPlay>
      )}
      <PlayerSliderWrapper>
        <PlayerCounter>{`${currentTime}/${totalDurationInTime}`}</PlayerCounter>
        <PlayerSlider
          onChange={(e) => {
            handleSeekChange(e);
          }}
          min={0}
          max={totalVideoDuration}
          type="range"
          step="any"
          value={currentSeek}
          widthBackground={(currentSeek / totalVideoDuration) * 100}
        ></PlayerSlider>
      </PlayerSliderWrapper>
      <PlayerVolumeWrapper>
        {(volumeBar <= 0 || !!isMuted) && (
          <BsFillVolumeMuteFill
            size={25}
            color={"#2a2747"}
            onClick={() => handleMute()}
          ></BsFillVolumeMuteFill>
        )}
        {volumeBar < 25 && volumeBar > 0 && !isMuted && (
          <BsFillVolumeOffFill
            size={25}
            color={"#2a2747"}
            onClick={() => handleMute()}
          ></BsFillVolumeOffFill>
        )}
        {volumeBar >= 25 && volumeBar < 75 && !isMuted && (
          <BsFillVolumeDownFill
            size={25}
            color={"#2a2747"}
            onClick={() => handleMute()}
          ></BsFillVolumeDownFill>
        )}
        {volumeBar >= 75 && !isMuted && (
          <BsVolumeUpFill
            size={25}
            color={"#2a2747"}
            onClick={() => handleMute()}
          ></BsVolumeUpFill>
        )}

        <PlayerVolumeSliderWrapper>
          <PlayerVolumeSlider
            onChange={(e) => {
              handleVolumeChange(e);
            }}
            min={0}
            max={100}
            type="range"
            step="1"
            value={volumeBar}
            widthBackground={(volumeBar / 100) * 100}
          ></PlayerVolumeSlider>
        </PlayerVolumeSliderWrapper>
      </PlayerVolumeWrapper>
      <BiFullscreen
        size={30}
        color={"#2a2747"}
        onClick={() => handleFullScreen()}
      ></BiFullscreen>
    </PlayerControllBottom>
  );
};

export default Component;
