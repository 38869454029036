import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
/* import { AnyObject } from "chart.js/types/basic"; */
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const Component = ({ pieData, mode }: { pieData; mode?: string }) => {
  /* const plugins: Plugin<"pie", AnyObject>[] = [
    {
      id: "",
    },
  ]; */

  const data = {
    labels: pieData.map((data) => data.label),
    datasets: [
      {
        label: "# of Votes",
        data: pieData.map((data) => data.value),
        backgroundColor: [
          "#000f37",
          "#001a5d",
          "rgba(0, 15, 55, 0.8)",
          "rgba(0, 30, 108, 0.8)",
          "rgba(0, 15, 55, 0.6)",
          "rgba(0, 30, 108, 0.6)",
          "rgba(0, 15, 55, 0.4)",
          "rgba(0, 26, 93, 0.4)",
          "rgba(0, 0, 0, 0.15)",
          "rgba(0, 26, 93, 0.15)",
          "#e0e7fb",
        ],
        borderColor: [
          "#000f37",
          "#001a5d",
          "rgba(0, 15, 55, 0.8)",
          "rgba(0, 30, 108, 0.8)",
          "rgba(0, 15, 55, 0.6)",
          "rgba(0, 30, 108, 0.6)",
          "rgba(0, 15, 55, 0.4)",
          "rgba(0, 26, 93, 0.4)",
          "rgba(0, 0, 0, 0.15)",
          "rgba(0, 26, 93, 0.15)",
          "#e0e7fb",
        ],
        borderWidth: 1,
        pieceLabel: { mode: "percentage", precision: 2 },
        labels: {
          render: "label",
        },
      },
    ],
  };
  return (
    <Pie
      data={data}
      options={{
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            color: "#fff",
            align: "center",

            display: true,
            formatter(value, context) {
              if (mode === "per") {
                return context.chart.data.datasets[0].data[
                  context.dataIndex
                ] === 0
                  ? ""
                  : `${value}%`;
              }
              if (!mode) {
                return context.chart.data.datasets[0].data[
                  context.dataIndex
                ] === 0
                  ? ""
                  : value;
              }
            },
            font: {
              weight: "bold",
              size: 20,
            },
          },
        },
      }}
    />
  );
};

export default Component;
