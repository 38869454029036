import styled from "styled-components";
import ReactSelect from "react-select";

const resetSelect = `
  margin: 0px;
  padding: 0px;
  border: 0px;
  border-radius: 0px;
  background: none;
  box-shadow: none;`;
export const Select = styled(ReactSelect)<{ theme; before; variant; size }>`
  ${resetSelect}
  font-size: ${(props) => {
    return props.theme.form.size[props.size].fontSize;
  }};
  font-weight: 400;
  letter-spacing: 0.009375em;
  box-sizing: border-box;
  background-color:transparent;
  width: 100%;
  min-height: calc(${(props) =>
    props.theme.form.size[props.size].height} - 2px);
  }
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  outline: none;
  position: static!important;

  .select__indicators {
    cursor: pointer;

    
    .select__dropdown-indicator{
      transition: all .2s ease-in-out;
      transform: rotate(0deg);

      svg {
        fill: ${(props) => (!!props.indicator ? "transparent" : "black")} ;
      }
    }
    
    span {
      display: none;
    }

    .select__indicator {
      padding: 0;
    }
  }

  & > .select__control, 
  & > .select__control:hover {
    ${resetSelect}

    width: 100%;
    color: rgba(0, 0, 24, 0.6);
    padding: ${(props) => (props.before ? "0 16px 0 43px" : "0px")};
    min-height: calc(${(props) =>
      props.theme.form.size[props.size].height} - 2px);
    
    
    .select__value-container {
      ${resetSelect}
      padding: 4px 0px;
      
      
      .select__placeholder {
        ${resetSelect}
        text-overflow: ellipsis;
white-space: nowrap;
overflow: hidden;
      }
      
      [class^="css-"]{
        ${resetSelect}
      }

      .select__single-value{
        margin: 0px;
        color: ${(props) => props.theme.colors.Gray};
      }

      .select__multi-value {
        background: #c5daff;
        border-radius: 10px;
        padding: 2px 10px;
        margin-right: 5px;
        &:not(:last-child){margin-bottom: 2px;}

        .select__multi-value__label {
          margin-right: 10px;
          background: transparent;
        }

        .select__multi-value__remove{
          cursor: pointer;
        }
      }

      .select__input {
        input {
          font-family: 'Lato', sans-serif;
        }
      }
    }

    .select__indicators {
      .select__indicator {
        

        img {
          width: 10px;
          height: auto;  
        }
      }
    }

    &.select__control--menu-is-open {
      ${resetSelect}

      .select__indicators {
        .select__dropdown-indicator{
          transform: rotate(180deg);
        }
      }
    }

    &.select__control--menu-is-focused {
      ${resetSelect}
    }
  }

  & > .select__menu {
    min-width: 100%;
    
    border-radius: 10px;
    background:  ${(props) =>
      props.variant === "lighten"
        ? "#ffffff"
        : `${(props) => props.theme.colors.Gray}`}
    position: relative;
    z-index: 20;
    padding: 8px;
    left: 0;
    right: 0;

    .select__placeholder, 
    .select__value-container .select__single-value {
      color: ${(props) => props.theme.colors.Gray};
      
      .select__input-container {
        margin: 0px;
        padding-bottom: 0px;
        padding-top: 0px;
      }
    }

    .select__menu-list {
      padding: 2px;
      max-height: 300px;
      
      .select__option {
      
        ${resetSelect}
  margin-bottom:4px;
       
      }
    }
  }`;
