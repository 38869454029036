import * as Yup from "yup";

export const schema = Yup.object().shape({
  name: Yup.string().required("Ingresa un nombre."),
  categories: Yup.array().of(Yup.string()).required("Ingresa una categoría."),
  type: Yup.string().required("Se requiere un tipo"),
});

interface FormValues {
  name: string;
  categories: string[];
  type: string;
}

export const initialValues: FormValues = {
  name: "",
  categories: [],
  type: "",
};
