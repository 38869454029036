import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Modal from "../../../../../../components/Modal";
import { Formik } from "formik";
import { Center } from "../../../../../../components/StyledComponents";
import {
  initialValues,
  schema,
} from "../../../../../../constants/form/unit/create";
import Grid from "../../../../../../components/Grid";
import Button from "../../../../../../components/Button";
import Submit from "../../../../../../components/Form/Submit";
import { toast } from "react-toastify";
import Input from "../../../../../../components/Form/Input";
import { hideModal } from "../../../../../../store/actions/modal";

import {
  resetUpdateUnit,
  updateUnit,
} from "../../../../../../store/actions/unit";

const Component = ({ updateUnitStates, unit }: { updateUnitStates; unit }) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const dispatch = useDispatch();

  const onSubmit = ({ values, actions }) => {
    dispatch(updateUnit({ _id: unit._id, name: values.name }));
    setFormActions(actions);
  };

  useEffect(() => {
    if (updateUnitStates.success) {
      toast.success("👌🏼 Unidad editada");

      setTimeout(() => {
        dispatch(resetUpdateUnit());
        dispatch(hideModal());
        formActions?.resetForm();
      }, 1000);
    }

    if (updateUnitStates.error) {
      toast.error(`😱 ${updateUnitStates.error}`);

      setTimeout(() => {
        dispatch(resetUpdateUnit());
      }, 1000);
    }
  }, [updateUnitStates]);

  return (
    <Modal name="edit-unit-name" title="Editar unidad">
      <Formik
        initialValues={initialValues({ name: unit.name })}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="invite-academy-modal-form"
            >
              <Grid.Row>
                <Grid.Col>
                  <Input
                    name={"name"}
                    error={errors["name"]}
                    touched={touched["name"]}
                    value={values["name"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{ label: "Nombre de Unidad", skin: "gray" }}
                  />
                </Grid.Col>
              </Grid.Row>

              <Grid.Row>
                <Grid.Col>
                  <Center gap={"70px"}>
                    <Button
                      onClick={resetForm}
                      type="button"
                      options={{
                        type: "outline",
                        skin: "gray",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                      style={{ marginLeft: "10px" }}
                    >
                      Cancelar
                    </Button>

                    <Submit
                      isSubmmiting={updateUnitStates.loading}
                      form="invite-academy-modal-form"
                      color="Primary"
                      options={{
                        type: "filled",
                        skin: "primary",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                    >
                      Confirmar
                    </Submit>
                  </Center>
                </Grid.Col>
              </Grid.Row>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const states = ({ unitStore }) => {
  const { states: updateUnitStates } = unitStore.updateUnit;

  return {
    updateUnitStates,
  };
};

export default connect(states)(Component);
