import action from "../helpers/rest-client";

export class ErrorTag extends Error {}

class Tag {
  public new(payload) {
    return action
      .Post({
        url: "/tags",
        body: payload,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public update({ _id, ...rest }) {
    return action
      .Patch({
        url: `/tags/${_id}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public getAll(payload) {
    if (payload.filterBy) {
      payload.filterBy = JSON.stringify(payload.filterBy);
    }
    if (payload.select) {
      payload.select = JSON.stringify(payload.select);
    }
    const queryString = new URLSearchParams(payload).toString();
    return action
      .Get({
        url: `/tags?platform=CONSTANA${queryString ? "&" + queryString : ""}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const tag = new Tag();
export default tag;
