import React, { CSSProperties, ReactElement } from "react";
import { Select } from "./style";
import { Field } from "../Form/Field";

const initialValue = {
  options: {
    skin: "base",
    size: "md",
    loading: false,
  },
};

interface Option {
  label: string | ReactElement;
  value: string | number | boolean;
}

interface ComponentProps {
  placeholder?: string;
  items?: Option[];
  style?: CSSProperties;
  options?: {
    classes?: any;
    label?: string;
    skin?: "base" | "gray";
    size?: "md";
    helperText?: string;
    before?: any;
    after?: any;
    marginBottom?: number;
    hidden?: boolean;
    noOptionsText?: string;
    loading?: boolean;
    indicator?: boolean;
  };
}
const Component = ({
  options,
  placeholder = "Selecciona...",
  items = [],
  style,
}: ComponentProps) => {
  const {
    loading,
    skin,
    size = "md",
    noOptionsText,
  } = {
    ...initialValue.options,
    ...options,
  };
  return (
    <Field skin={skin} size={size} style={style} select={true}>
      <Select
        variant="base"
        skin={skin}
        size={size}
        classNamePrefix="select"
        indicator={options?.indicator}
        placeholder={placeholder || "Selecciona"}
        options={items}
        menuPlacement="bottom"
        noOptionsMessage={({ inputValue }) =>
          inputValue ? noOptionsText : "No hay resultados"
        }
        isLoading={loading}
      />
    </Field>
  );
};

export default Component;
