import { connect, useDispatch } from "react-redux";
import { InLine } from "../../../../../../components/StyledComponents";
import CrossIcon from "../../../../../../assets/icons/cross.svg";
import Icon from "../../../../../../components/Icon";
import { useRecruitingLayout } from "../../../../../../provider/RecruitingProvider";
import { TalentDto } from "../../../../../../types/talent.dto";
import ShowModal from "../../../components/ShoModal";
import {
  Tab,
  TabTitle,
  Description,
  Content,
  ListTitle,
  List,
  ListItem,
  GrayCard,
  Delete,
} from "../../../components/StyledComponents";
import actionsData from "../../../../../../store/actions/recruiting/ait-talent";

import { AitTalentDto } from "../../../../../../types/recruiting.dto";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

interface ComponentProps {
  talents: AitTalentDto[];
  talentsStates: {
    success: boolean;
    error: string | boolean;
    loading: boolean;
  };
}

const Component = ({ talents, talentsStates }: ComponentProps) => {
  const { actions, selectedItem } = useRecruitingLayout();
  const params = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (talentsStates.success) {
      toast.success("👌🏼 Talento editado correctamente");

      setTimeout(() => {
        dispatch(actionsData.resetUpdate());
      }, 1000);
    }

    if (talentsStates.error) {
      toast.error(`😱 ${talentsStates.error}`);

      setTimeout(() => {
        dispatch(actionsData.resetUpdate());
      }, 1000);
    }
  }, [talentsStates]);

  return (
    <>
      <InLine justify="space-between">
        <TabTitle>Asignar Talentos a Búsqueda</TabTitle>
        <ShowModal.AsignarTalento />
      </InLine>

      <Description>
        Asignar talentos a la búsqueda te ayudara a ti y a Dev Place a facilitar
        la conexión entre talentos, tus búsquedas y las necesidades de esta
        empresa en particular. También te enviaremos recomendaciones de perfiles
        que no has seleccionado pero que por la información recopilada
        recomendaremos que les eches un vistazo.
      </Description>

      <Content>
        <ListTitle>Talentos</ListTitle>
        <List>
          {!!talents &&
            talents
              ?.filter((talent) => talent.active)
              .filter((ability) => ability.team === params.id)
              .filter((talent) =>
                talent.searches.includes(selectedItem?.data?._id)
              )
              .map((item) => (
                <ListItem>
                  {`${item.name} ${item.lastName}`}
                  <Delete
                    onClick={() =>
                      dispatch(
                        actionsData.update({
                          _id: item._id,
                          searches: item.searches?.filter(
                            (search) => search !== selectedItem?.value
                          ),
                        })
                      )
                    }
                  >
                    <Icon icon={CrossIcon} size="9px" color="black" />
                  </Delete>
                </ListItem>
              ))}
        </List>
      </Content>
    </>
  );
};

const states = ({ recruitingStore }) => {
  const { data: talents } = recruitingStore.talent.all;
  const { states: talentsStates } = recruitingStore.talent.update;
  return {
    talents,
    talentsStates,
  };
};

export default connect(states)(Component);
