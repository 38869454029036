import { useEffect, useState } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { connect, useDispatch } from "react-redux";
import {
  CardRow,
  Information,
} from "../../pages/Dashboard/Marketplace/components/TalentCard/styles";
import {
  Label,
  ListLabel,
  TalentAvatar,
  TalentCol,
  Profile,
  Name,
} from "../../pages/Dashboard/Recruiting/AIT/components/TalentCard/styles";
import Company from "../../assets/icons/menu/company.svg";
import Search from "../../assets/icons/menu/search.svg";
import { Column, Header, Body, Title, Card, Scroll } from "./styles";
import {
  AitTalentDto,
  CompanyDto,
  SearchDto,
} from "../../types/recruiting.dto";
import {
  getAllCohorts,
  resetGetAllCohortsData,
} from "../../store/actions/cohort";
import { CohortDto } from "../../types/cohort.dto";
import { CurrentDto } from "../../types/current.dto";
import { useParams } from "react-router-dom";
import { makeClick } from "../../store/actions/talent";

const reorder = (cards, startIndex, endIndex) => {
  const result = Array.from(cards);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (
  sourceCards,
  destinationCards,
  droppableSource,
  droppableDestination
) => {
  const sourceClone = Array.from(sourceCards);
  const destClone = Array.from(destinationCards);
  const [removed] = sourceClone.splice(droppableSource.index, 1);
  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

export interface Card {
  _id: string;
}

export interface Column<T extends Card> {
  title: string;
  _id: string;
  cards: T[];
}

export interface ComponentProps {
  columns?: Column<any>[];
  onSelect: (card) => void;
  onMove: (columns?) => void;
  modal: string | null;
  aitTalents: AitTalentDto[];
  companies: CompanyDto[];
  searches: SearchDto[];
  cohorts: CohortDto[];
  current: CurrentDto;
  ait;
  user;
  selected;
}

const Component = ({
  columns: _columns,
  cohorts,
  aitTalents,
  onMove,
  onSelect,
  companies,
  searches,
  modal,
  current,
  ait,
  selected,
  user,
}: ComponentProps) => {
  const [columns, setColumns] = useState<any>();
  const dispatch = useDispatch();
  const params = useParams();
  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (
      !aitTalents.find((aitTalent) => aitTalent.talent === result.draggableId)
        ?.external &&
      !!aitTalents.find((aitTalent) => aitTalent.talent === result.draggableId)
        ?.talent
    ) {
      dispatch(
        makeClick({
          _id: aitTalents.find(
            (aitTalent) => aitTalent.talent === result.draggableId
          )?.talent,
          ...(current.currentSection === "academy" && {
            currentAcademy: current.id,
          }),
        })
      );
    }
    if (!destination) {
      // dropped outside the list
      return;
    }
    const sInd = source.droppableId;
    const dInd = destination.droppableId;
    let newState;

    if (sInd === dInd) {
      const result: any = reorder(
        columns?.filter((column) => column._id === sInd)[0].cards,
        source.index,
        destination.index
      );

      newState = columns?.map((column) => {
        if (column._id === sInd) {
          return {
            ...column,
            cards: result,
          };
        }

        return column;
      });
    } else {
      const result = move(
        columns?.filter((column) => column._id === sInd)[0].cards,
        columns?.filter((column) => column._id === dInd)[0].cards,
        source,
        destination
      );

      newState = columns?.map((column) => {
        if (column._id === sInd) {
          return {
            ...column,
            cards: result[sInd],
          };
        }

        if (column._id === dInd) {
          return {
            ...column,
            cards: result[dInd],
          };
        }

        return column;
      });
    }

    !!newState && setColumns(newState);
    !!newState && !!onMove && onMove(newState);
  };
  const payload = {
    filterBy: {
      active: true,
      academy: current.id,
    },
    select: {
      _id: 1,
      name: 1,
    },
  };

  useEffect(() => {
    !cohorts && dispatch(getAllCohorts(payload));
  }, [cohorts, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllCohortsData());
    };
  }, []);

  useEffect(() => {
    if (params.id) {
      setColumns(ait?.find((tab) => tab.team === params.id).columns);
    } else {
      setColumns(ait?.find((tab) => tab.user === user._id).columns);
    }
  }, [ait, params]);

  return (
    <>
      {!!columns && (
        <div
          style={{
            overflow: "auto",
            padding: "0px 20px 40px",
          }}
        >
          <div
            style={{
              display: "flex",
              minWidth: columns?.length
                ? `${260 * columns?.length}px`
                : `${260 * 5}px`,
            }}
          >
            {!!columns && (
              <DragDropContext onDragEnd={onDragEnd}>
                {columns
                  ?.map((column) => {
                    const foundedAitTalents = column?.cards?.map(
                      (card) =>
                        aitTalents?.filter((talent) => {
                          return talent._id.toString() === card.toString();
                        })[0]
                    );
                    return {
                      ...column,
                      cards: foundedAitTalents,
                    };
                  })
                  .map((column, ind) => (
                    <Droppable key={ind} droppableId={column._id}>
                      {(provided, snapshot) => (
                        <Column
                          className={
                            column.title === "Descartados" ? "overlay" : ""
                          }
                        >
                          <Header>
                            <Title>{column.title}</Title>
                          </Header>
                          <Body
                            ref={provided.innerRef}
                            isDraggingOver={snapshot.isDraggingOver}
                            {...provided.droppableProps}
                          >
                            <Scroll>
                              {column?.cards?.map((item, index) => (
                                <Draggable
                                  key={item?._id}
                                  draggableId={item?._id}
                                  index={index}
                                >
                                  {(provided, snapshot) => {
                                    return (
                                      <Card
                                        onClick={() =>
                                          !!onSelect && onSelect(item)
                                        }
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                          ...provided.draggableProps.style,
                                        }}
                                      >
                                        <TalentCol style={{ gap: "5px" }}>
                                          <CardRow
                                            style={{ alignItems: "center" }}
                                          >
                                            <TalentAvatar
                                              avatar={
                                                !!item?.avatar && item?.avatar
                                              }
                                            />
                                            <Information>
                                              <Name>{`${item?.name} ${item?.lastName}`}</Name>
                                              <Profile>
                                                Bootcamp:
                                                {!!cohorts &&
                                                  !!item?.cohorts[0] &&
                                                  cohorts?.find(
                                                    (cohort) =>
                                                      cohort._id ===
                                                      item?.cohorts[0]
                                                  )?.name}
                                              </Profile>
                                            </Information>
                                          </CardRow>
                                          <CardRow>
                                            <ListLabel>
                                              <img
                                                style={{ paddingLeft: "10px" }}
                                                src={Company}
                                                alt="company"
                                              />
                                              {!!aitTalents &&
                                                aitTalents
                                                  .find(
                                                    (aitTalent) =>
                                                      item._id === aitTalent._id
                                                  )
                                                  ?.companies?.map((company) =>
                                                    !params.id
                                                      ? companies?.find(
                                                          (comp) =>
                                                            comp._id ===
                                                              company &&
                                                            !comp.team
                                                        )
                                                      : companies?.find(
                                                          (comp) =>
                                                            comp._id ===
                                                              company &&
                                                            comp.team ===
                                                              params.id
                                                        )
                                                  )
                                                  .map((company) => (
                                                    <Label>
                                                      {company?.name}
                                                    </Label>
                                                  ))}
                                            </ListLabel>
                                          </CardRow>
                                          <CardRow>
                                            <img
                                              style={{
                                                paddingLeft: "10px",
                                                paddingRight: "9px",
                                              }}
                                              src={Search}
                                              alt="search"
                                            />{" "}
                                            {!!aitTalents &&
                                              aitTalents
                                                ?.find(
                                                  (aitTalent) =>
                                                    item._id === aitTalent._id
                                                )
                                                ?.searches?.map((search) =>
                                                  !params.id
                                                    ? searches?.find(
                                                        (sear) =>
                                                          sear._id === search &&
                                                          !sear.team
                                                      )
                                                    : searches?.find(
                                                        (sear) =>
                                                          sear._id === search &&
                                                          sear.team ===
                                                            params.id
                                                      )
                                                )[0]?.name}
                                          </CardRow>
                                        </TalentCol>
                                      </Card>
                                    );
                                  }}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </Scroll>
                          </Body>
                        </Column>
                      )}
                    </Droppable>
                  ))}
              </DragDropContext>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const states = ({
  modalStore,
  recruitingStore,
  cohortStore,
  currentStore,
  teamStore,
  userStore,
}) => {
  const { visibleModal: modal } = modalStore;
  const { data: aitTalents } = recruitingStore.talent.all;
  const { data: companies } = recruitingStore.company.all;
  const { data: ait } = recruitingStore.ait.all;
  const { data: searches } = recruitingStore.search.all;
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: current } = currentStore;
  const { data: selected } = teamStore.selected;
  const { data: user } = userStore;
  return {
    modal,
    companies,
    cohorts,
    searches,
    user,
    selected,
    aitTalents,
    current,
    ait,
  };
};

export default connect(states)(Component);
