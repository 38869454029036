import { put, call, all, takeLatest, select } from "redux-saga/effects";
import services from "../../services";
import {
  GET_IMAGE,
  GET_IMAGE_SUCCESS,
  GET_IMAGE_ERROR,
  GET_ALL_IMAGES,
  GET_ALL_IMAGES_SUCCESS,
  GET_ALL_IMAGES_ERROR,
  NEW_IMAGE,
  NEW_IMAGE_ERROR,
  NEW_IMAGE_SUCCESS,
  NewImage,
  UPDATE_IMAGE,
  UPDATE_IMAGE_SUCCESS,
  UPDATE_IMAGE_ERROR,
  DELETE_IMAGE,
  DELETE_IMAGE_SUCCESS,
  DELETE_IMAGE_ERROR,
  GetImage,
  GetAllImages,
  UpdateImage,
  DeleteImage,
  UploadMedia,
  UPLOAD_MEDIA,
  AddUserToImage,
  ADD_USER_TO_IMAGE,
  SharedImage,
  SHARED_IMAGE,
  UPDATE_MANY_IMAGE_SUCCESS,
  UPDATE_MANY_IMAGE_ERROR,
  UPDATE_MANY_IMAGE,
  UpdateManyImage,
} from "../types/image";
import { ImageDto } from "../../types/image.dto";

function* getAllImages({ payload }) {
  const { response, error } = yield call(services.image.getAll, payload);

  if (error) {
    yield put({
      type: GET_ALL_IMAGES_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_IMAGES_SUCCESS, payload: response });
  }
}

function* getImage({ payload }) {
  const { response, error } = yield call(services.image.getOne, payload);

  if (error) {
    yield put({
      type: GET_IMAGE_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_IMAGE_SUCCESS, payload: response });
  }
}

function* newImage({ payload }) {
  const { response, error } = yield call(services.image.new, payload);

  if (error) {
    yield put({
      type: NEW_IMAGE_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: NEW_IMAGE_SUCCESS, payload: response })]);
  }
}

function* updateImage({ payload }) {
  const { response, error } = yield call(services.image.update, payload);
  if (error) {
    yield put({
      type: UPDATE_IMAGE_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_IMAGE_SUCCESS, payload: response })]);
  }
}
function* addUserToImage({ payload }) {
  const { response, error } = yield call(services.image.addUserImage, payload);

  if (error) {
    yield put({
      type: UPDATE_IMAGE_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_IMAGE_SUCCESS, payload: response })]);
  }
}

function* uploadMedia({ payload }) {
  const { response, error } = yield call(services.image.update, payload);

  if (error) {
    yield put({
      type: UPDATE_IMAGE_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_IMAGE_SUCCESS, payload: response })]);
  }
}

function* deleteImage({ payload }) {
  const { response, error } = yield call(services.image.delete, payload);

  if (error) {
    yield put({
      type: DELETE_IMAGE_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: DELETE_IMAGE_SUCCESS, payload: response })]);
  }
}

function* shareImage({ payload }) {
  const { response, error } = yield call(services.image.shareImage, payload);

  if (error) {
    yield put({
      type: UPDATE_IMAGE_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_IMAGE_SUCCESS, payload: response })]);
  }
}

function* updateManyImage({ payload }) {
  let updatedList: ImageDto[] = [];
  try {
    for (let i = 0; i < payload.items.length; i++) {
      const { _id, ...body } = payload.items[i];
      const formDataInsteadBody = !!body.body ? body.body : body;
      const { response, error } = yield call(services.image.update, {
        _id,
        body: formDataInsteadBody,
      });
      if (!!response) {
        updatedList = [...updatedList, response];
      }
    }

    yield put({ type: UPDATE_MANY_IMAGE_SUCCESS, payload: updatedList });
  } catch (err) {
    yield put({ type: UPDATE_MANY_IMAGE_ERROR, payload: err });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllImages>(GET_ALL_IMAGES, getAllImages);
  yield takeLatest<GetImage>(GET_IMAGE, getImage);
  yield takeLatest<NewImage>(NEW_IMAGE, newImage);
  yield takeLatest<UpdateImage>(UPDATE_IMAGE, updateImage);
  yield takeLatest<DeleteImage>(DELETE_IMAGE, deleteImage);
  yield takeLatest<UploadMedia>(UPLOAD_MEDIA, uploadMedia);
  yield takeLatest<AddUserToImage>(ADD_USER_TO_IMAGE, addUserToImage);
  yield takeLatest<SharedImage>(SHARED_IMAGE, shareImage);
  yield takeLatest<UpdateManyImage>(UPDATE_MANY_IMAGE, updateManyImage);
}
