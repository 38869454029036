import styled from "styled-components";
import {
  Avatar,
  CardCol,
} from "../../../../Marketplace/components/TalentCard/styles";

export const TalentCard = styled("div")`
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: white;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.16);
  margin-bottom: 1rem;
  padding: 3px 22px 5px 3px;
  @media (min-width: 960px) {
    flex-direction: row;
    width: 100%;
    max-width: 760px;
  }
`;

export const TalentCol = styled(CardCol)`
  font-size: 10px;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  width: 100%;
  gap: 4px;
`;

export const ListLabel = styled("div")`
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
  & .text {
    font-size: 14px;
  }
`;

export const TalentAvatarWrapper = styled("div")`
  position: relative;
  cursor: pointer;
  padding: 4px;
  border-radius: 100%;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: rgba(245, 245, 245, 0.2);
  }
`;

export const TalentAvatar = styled(Avatar)`
  height: ${(props) => (!!props.height ? props.height : "30px")};
  width: ${(props) => (!!props.width ? props.width : "30px")};
`;

export const Name = styled("p")`
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: #707070;
`;

export const Profile = styled("p")`
  margin: 0;
  opacity: 0.9;
  font-size: 10px;
`;

export const Label = styled("label")`
  padding: 1px 8px;
  border-radius: 7.5px;
  background-color: #d5f5ff;
  font-size: 10px;
`;
