import React, { useEffect, useState } from "react";
import { BiNews, BiLink } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import academyActions from "../../../../../../store/actions/academies";
import {
  deleteMember,
  getAllCohorts,
  resetDeleteCohort,
  resetGetAllCohortsData,
} from "../../../../../../store/actions/cohort";
import Button from "../../../../../../components/Button";
import Icon from "../../../../../../components/Icon";
import { AcademyDto } from "../../../../../../types/academy.dto";
import { CohortDto } from "../../../../../../types/cohort.dto";
import { CardColumn, CardRow, CardTitle } from "../../../Profile/style";
import { ListItem, DataText, Item } from "../../style";
import CohortModal from "./components/CohortModal";
import teamActions from "../../../../../../store/actions/team";
import { toast } from "react-toastify";
import { UsersDto } from "../../../../../../types/users.dto";
import { CurrentDto } from "../../../../../../types/current.dto";
import { TeamDto } from "../../../../../../types/team.dto";
import { useCurrentLayout } from "../../../../../../provider/CurrentProvider";
import { OrganizationDto } from "../../../../../../types/organization.dto";
import Loader from "../../../../../../components/Loader";
import AcademyInviteButton from "../../components/InviteButton";
import OrganizationInviteButton from "../../../../Organization/Gestion/components/InviteButton";
import usersActions from "../../../../../../store/actions/users";
import { StateDto } from "../../../../../../types/states.dto";
const Component = ({
  cohorts,
  selectedAcademy,
  users,
  current,
  teams,
  organizationInvite,
  academyInvite,
  organizations,
  academies,
  selectedItem,
  deletedCohort,
  deleteCohortStates,
  updatedAcademyStates,
  updatedAcademy,
}: {
  cohorts: CohortDto[];
  selectedAcademy;
  users: UsersDto[];
  current: CurrentDto;
  teams: TeamDto[];
  organizationInvite;
  academyInvite;
  organizations: OrganizationDto[];
  academies: AcademyDto[];
  deletedCohort: CohortDto;
  deleteCohortStates: StateDto;
  selectedItem: {
    label: string;
    value: string;
    data: {
      description: string;
    };
  };
  updatedAcademyStates: StateDto;
  updatedAcademy: AcademyDto;
}) => {
  const [fetchOn, setFetchOn] = useState<boolean>(false);
  const [activeItem, setActiveItem] = useState<any>();
  const [currentCohorts, setCurrentCohorts] = useState<CohortDto[]>();
  const [currentAcademies, setCurrentAcademies] = useState<AcademyDto[]>();
  const { data } = useCurrentLayout();
  const dispatch = useDispatch();
  const invitationStates =
    current.currentSection === "academy" ? academyInvite : organizationInvite;
  useEffect(() => {
    if (invitationStates.create.states.success) {
      toast.success("👌🏼 Link copiado a portapapeles");
      setTimeout(() => {
        dispatch(
          !!academyActions.resetNewLink && academyActions.resetNewLink()
        );
      }, 1000);
    }
    if (invitationStates.create.states.error) {
      toast.error(invitationStates.create.states.error);
      setTimeout(() => {
        dispatch(
          !!academyActions.resetNewLink && academyActions.resetNewLink()
        );
      }, 1000);
    }
  }, [invitationStates.create.states]);
  useEffect(() => {
    if (invitationStates.delete.states.success) {
      toast.success("👌🏼 Link borrado");
      setTimeout(() => {
        dispatch(
          !!academyActions.resetDeleteLink && academyActions.resetDeleteLink()
        );
      }, 1000);
    }
    if (invitationStates.delete.states.error) {
      toast.error(invitationStates.delete.states.error);
      setTimeout(() => {
        dispatch(
          !!academyActions.resetDeleteLink && academyActions.resetDeleteLink()
        );
      }, 1000);
    }
  }, [invitationStates.delete.states]);

  useEffect(() => {
    if (!academies && fetchOn)
      dispatch(academyActions.getAll({ filterBy: { _id: current.id } }));
  }, [academies, fetchOn]);

  useEffect(() => {
    if (!users && fetchOn) {
      dispatch(
        usersActions.getAllByAcademy({
          _id: current.id,
          payload: { filterBy: {} },
        })
      );
    }
  }, [fetchOn]);

  useEffect(() => {
    if (!cohorts && fetchOn)
      dispatch(
        getAllCohorts({ filterBy: { academy: current.id, active: true } })
      );
  }, [cohorts, fetchOn]);

  useEffect(() => {
    if (cohorts && fetchOn) {
      setCurrentCohorts(cohorts);
    }
  }, [cohorts]);

  useEffect(() => {
    if (academies && fetchOn) {
      setCurrentAcademies(academies);
    }
  }, [academies]);

  useEffect(() => {
    if (deleteCohortStates.success) {
      setCurrentCohorts((state) => [
        ...(state ?? []).filter((st) => st._id !== deletedCohort._id),
      ]);
      dispatch(resetDeleteCohort());
    }
    if (deleteCohortStates.error) {
      toast.error(deleteCohortStates.error);
      dispatch(resetDeleteCohort());
    }
  }, [deleteCohortStates]);

  useEffect(() => {
    if (updatedAcademyStates.success) {
      setCurrentAcademies((state) => [
        ...(state ?? []).map((st) =>
          st._id === updatedAcademy._id ? updatedAcademy : st
        ),
      ]);
      dispatch(academyActions.resetUpdate());
    }
    if (updatedAcademyStates.error) {
      toast.error(updatedAcademyStates.error);
      dispatch(academyActions.resetUpdate());
    }
  }, [updatedAcademyStates]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllCohortsData());
      dispatch(academyActions.resetGetAllAcademiesData());
      dispatch(usersActions.resetGetAll());
    };
  }, []);

  useEffect(() => {
    if (!cohorts && !academies && !users) {
      setFetchOn(true);
    }
  }, [cohorts, academies, users]);

  if (!currentCohorts || !users || !currentAcademies)
    return <Loader color="Primary"></Loader>;

  return (
    <>
      <CardRow justify="space-between">
        <CardRow>
          <Icon icon="" size="40px" />
          <CardColumn gap="9px">
            <DataText>{selectedItem.label}</DataText>
            {current.currentSection !== "personal" && (
              <DataText>{selectedItem.data.description}</DataText>
            )}
          </CardColumn>
        </CardRow>

        {current.currentSection === "organization" && (
          <OrganizationInviteButton></OrganizationInviteButton>
        )}
        {current.currentSection === "academy" && (
          <AcademyInviteButton></AcademyInviteButton>
        )}
      </CardRow>

      <CardColumn gap="21px">
        <Item>
          <CardRow justify="space-between">
            <CardColumn gap="9px">
              <DataText size="14px">Invitar con link</DataText>
              <DataText size="12px">
                Cualquier persona con un link de invitación va a poder ingresar
                a la organizacion como miembro. Puedes deshabilitar el link en
                cualquier momento, una vez que haya sido generado.
              </DataText>
            </CardColumn>
            <CardRow gap="10px">
              <Button
                type="button"
                options={{ type: "outline", size: "sm", skin: "gray" }}
                style={{
                  minWidth: "77px",
                  width: "107px",
                  fontSize: "12px",
                }}
                onClick={() =>
                  dispatch(
                    academyActions.deleteLink &&
                      academyActions.deleteLink(current.id)
                  )
                }
              >
                Deshabilitar
              </Button>
              <Button
                type="button"
                options={{ type: "filled", size: "sm", skin: "primary" }}
                style={{
                  minWidth: "77px",
                  width: "112px",
                  fontSize: "12px",
                  fontWeight: "200",
                }}
                onClick={() => dispatch(academyActions.newLink(current.id))}
              >
                <CardRow align="center" gap="8px">
                  <BiLink fontSize={15} color={"#fff"}></BiLink>
                  <DataText color="#fff">Crear link</DataText>
                </CardRow>
              </Button>
            </CardRow>
          </CardRow>
        </Item>
        <CardColumn gap="21px">
          <CardColumn gap="15px">
            <CardRow align="center" gap="8px">
              <BiNews fontSize={20}></BiNews>
              <CardTitle>Listado de Miembros</CardTitle>
            </CardRow>
            {currentAcademies && users ? (
              <CardColumn>
                <ListItem>
                  {current.currentSection === "academy" &&
                    currentAcademies
                      ?.find((academy) => academy._id === current.id)
                      ?.users?.filter(
                        (user) =>
                          (user.role === "ADMIN" ||
                            user.role === "PM" ||
                            user.role === "PROFESSOR" ||
                            user.role === "OBSERVER") &&
                          !user.roleEnd
                      )
                      .map((user) => {
                        const userFounded = users?.find(
                          (userFound) =>
                            userFound._id === user.user && userFound.active
                        );
                        return (
                          !!userFounded && (
                            <Item>
                              <CardRow align="center" justify="space-between">
                                <CardRow align="center" gap="3px">
                                  <Icon icon=""></Icon>
                                  <CardColumn>
                                    <DataText size="14px">
                                      {`${userFounded?.name} ${userFounded?.lastName}`}
                                    </DataText>
                                    <DataText size="12px">
                                      {userFounded?.position}
                                    </DataText>
                                  </CardColumn>
                                </CardRow>
                                <CardRow gap="15px" align="center">
                                  <div style={{ position: "relative" }}>
                                    <Button
                                      type="button"
                                      options={{
                                        type: "link",
                                        size: "md",
                                        skin: "gray",
                                      }}
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "200",
                                        boxShadow: "none",
                                      }}
                                      onClick={() => setActiveItem(user.user)}
                                    >
                                      En{" "}
                                      {
                                        currentCohorts?.filter(
                                          (cohort) =>
                                            cohort.active &&
                                            cohort.studentsIds.find(
                                              (student) => student === user.user
                                            )
                                        ).length
                                      }{" "}
                                      Cohorts
                                    </Button>
                                    <CohortModal
                                      setActive={setActiveItem}
                                      title="Cohorts"
                                      top={"20px"}
                                      right={"0px"}
                                      cohorts={currentCohorts?.filter(
                                        (cohort) =>
                                          cohort.studentsIds.find(
                                            (student) => student === user.user
                                          )
                                      )}
                                      payload={{ userId: user.user }}
                                      actions={deleteMember}
                                      active={activeItem === user.user}
                                    ></CohortModal>
                                  </div>

                                  <Button
                                    type="button"
                                    options={{
                                      type: "outline",
                                      size: "sm",
                                      skin: "gray",
                                    }}
                                    style={{
                                      minWidth: "77px",
                                      width: "156px",
                                      fontSize: "12px",
                                      fontWeight: "200",
                                    }}
                                    onClick={() =>
                                      dispatch(
                                        academyActions.deleteMember({
                                          _id: current.id,
                                          userId: user.user,
                                        })
                                      )
                                    }
                                  >
                                    Eliminar de Academy
                                  </Button>
                                </CardRow>
                              </CardRow>
                            </Item>
                          )
                        );
                      })}
                  {current.currentSection === "organization" &&
                    organizations
                      ?.find((organization) => organization._id === current.id)
                      ?.users?.filter(
                        (user) =>
                          (user.role === "ADMIN" ||
                            user.role === "PM" ||
                            user.role === "MEMBER") &&
                          !user.roleEnd
                      )
                      .map((user) => {
                        const userFounded = users?.find(
                          (userFound) => userFound._id === user.user
                        );

                        return (
                          <Item>
                            <CardRow align="center" justify="space-between">
                              <CardRow align="center" gap="3px">
                                <Icon icon=""></Icon>
                                <CardColumn>
                                  <DataText size="14px">
                                    {`${userFounded?.name} ${userFounded?.lastName}`}
                                  </DataText>
                                  <DataText size="12px">
                                    {userFounded?.position}
                                  </DataText>
                                </CardColumn>
                              </CardRow>
                              <CardRow gap="15px" align="center">
                                <div style={{ position: "relative" }}>
                                  <Button
                                    type="button"
                                    options={{
                                      type: "link",
                                      size: "md",
                                      skin: "gray",
                                    }}
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "200",
                                      boxShadow: "none",
                                    }}
                                    onClick={() => setActiveItem(user.user)}
                                  >
                                    En{" "}
                                    {
                                      teams
                                        ?.filter(
                                          (team) =>
                                            team.organization === current.id
                                        )
                                        .filter((team) => team.active)
                                        .filter((team) =>
                                          team.users.find(
                                            (teamUser) => teamUser === user.user
                                          )
                                        ).length
                                    }{" "}
                                    Equipos
                                  </Button>
                                  <CohortModal
                                    setActive={setActiveItem}
                                    top={"20px"}
                                    right={"0px"}
                                    cohorts={teams
                                      ?.filter(
                                        (team) =>
                                          team.organization === current.id
                                      )
                                      .filter((team) => team.active)
                                      .filter((team) =>
                                        team.users.find(
                                          (teamUser) => teamUser === user.user
                                        )
                                      )}
                                    title="Equipos"
                                    payload={{ userId: user.user }}
                                    actions={teamActions.deleteMember}
                                    active={activeItem === user.user}
                                  ></CohortModal>
                                </div>
                                {user.role !== "ADMIN" && (
                                  <Button
                                    type="button"
                                    options={{
                                      type: "outline",
                                      size: "sm",
                                      skin: "gray",
                                    }}
                                    style={{
                                      minWidth: "77px",
                                      width: "112px",
                                      fontSize: "12px",
                                      fontWeight: "200",
                                    }}
                                    onClick={() =>
                                      dispatch(
                                        !!academyActions.updateRole &&
                                          academyActions.updateRole({
                                            _id: current.id,
                                            user: {
                                              user: user.user,
                                              role: "ADMIN",
                                            },
                                          })
                                      )
                                    }
                                  >
                                    Hacer Admin
                                  </Button>
                                )}

                                <Button
                                  type="button"
                                  options={{
                                    type: "outline",
                                    size: "sm",
                                    skin: "gray",
                                  }}
                                  style={{
                                    minWidth: "77px",
                                    width: "156px",
                                    fontSize: "12px",
                                    fontWeight: "200",
                                  }}
                                  onClick={() =>
                                    dispatch(
                                      academyActions.deleteMember({
                                        _id: current.id,
                                        userId: user.user,
                                      })
                                    )
                                  }
                                >
                                  Eliminar de Organizacion
                                </Button>
                              </CardRow>
                            </CardRow>
                          </Item>
                        );
                      })}
                </ListItem>
              </CardColumn>
            ) : (
              <Loader color="Primary"></Loader>
            )}
          </CardColumn>
        </CardColumn>
      </CardColumn>
    </>
  );
};

const states = ({
  cohortStore,
  academyStore,
  organizationStore,
  usersStore,
  currentStore,
  teamStore,
}) => {
  const { data: cohorts } = cohortStore.allCohorts;
  const { data: deletedCohort, states: deleteCohortStates } =
    cohortStore.deleteCohort;
  const { data: selectedAcademy } = academyStore.selected;
  const { data: academies } = academyStore.all;
  const { states: updatedAcademyStates, data: updatedAcademy } =
    academyStore.update;
  const { data: users } = usersStore.all;
  const { data: current } = currentStore;
  const { data: teams } = teamStore.all;
  const { data: organizations } = organizationStore.all;
  const organizationInvite = organizationStore.invite;
  const academyInvite = academyStore.invite;
  return {
    cohorts,
    selectedAcademy,
    users,
    academies,
    organizationInvite,
    organizations,
    academyInvite,
    current,
    teams,
    deletedCohort,
    deleteCohortStates,
    updatedAcademyStates,
    updatedAcademy,
  };
};
export default connect(states)(Component);
