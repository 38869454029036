import parseJwt from "../helpers/parse-token";
import action from "../helpers/rest-client";

export class ErrorAuth extends Error {}

class Auth {
  private accessToken: any;
  private refreshAcessToken: any;
  private refreshTokenTimeout: any;

  public getRole = async () => {
    return this.accessToken.roles;
  };

  public getToken = () => this.refreshAcessToken;

  public isValidToken = () => {
    const accessToken = (
      document.cookie.split(";").find((x) => x.includes("refreshToken")) || "="
    ).split("=")[1];
    if (!accessToken) {
      return false;
    }

    const accessTokenJson = parseJwt(accessToken);
    const time = accessTokenJson.exp * 1000;
    const cookieExp = new Date(time);

    return cookieExp >= new Date();
  };

  public refreshToken = async () => {
    const refreshToken = (
      document.cookie.split(";").find((x) => x.includes("refreshToken")) || "="
    ).split("=")[1];

    return action
      .Post({
        url: "/auth/refresh",
        body: { token: refreshToken },
      })
      .then((response) => {
        this.accessToken = parseJwt(response.accessToken);
        this.refreshAcessToken = response.accessToken;
        this.startRefreshTokenTimer();

        const time = this.accessToken.exp * 1000;
        const cookieExp = new Date(time).toUTCString();
        document.cookie = `refreshToken=${response.accessToken}; expires=${cookieExp} ; path=/;`;

        return { response: this.accessToken };
      })
      .catch((error) => {
        return { error };
      });
  };

  public AutoSignIn = () => {
    const accessToken = (
      document.cookie.split(";").find((x) => x.includes("refreshToken")) || "="
    ).split("=")[1];

    const accessTokenJson = parseJwt(accessToken);
    const time = accessTokenJson.exp * 1000;
    const cookieExp = new Date(time);

    if (!(cookieExp < new Date())) {
      this.accessToken = parseJwt(accessToken);
      this.refreshAcessToken = accessToken;
      this.startRefreshTokenTimer();

      const time = this.accessToken.exp * 1000;
      const cookieExp = new Date(time).toUTCString();
      document.cookie = `refreshToken=${accessToken}; expires=${cookieExp} ; path=/;`;
    }

    return { response: this.accessToken };
  };

  public SignIn = async (payload) => {
    return action
      .Post({
        url: "/auth/signin",
        body: payload,
      })
      .then((response) => {
        this.accessToken = parseJwt(response.accessToken);
        this.refreshAcessToken = response.accessToken;
        this.startRefreshTokenTimer();

        const time = this.accessToken.exp * 1000;
        const cookieExp = new Date(time).toUTCString();
        document.cookie = `refreshToken=${response.accessToken}; expires=${cookieExp} ; path=/;`;
        return { response: this.accessToken };
      })
      .catch((error) => {
        return { error };
      });
  };

  public SignUp = async (payload) => {
    return action
      .Post({
        url: "/auth/signup",
        body: { ...payload, platform: "CONSTANA" },
      })
      .then((response) => {
        return { response };
        /*    console.log(response, "messi");
        this.accessToken = parseJwt(response.accessToken);
        this.refreshAcessToken = response.accessToken;
        this.startRefreshTokenTimer();

        const time = this.accessToken.exp * 1000;
        const cookieExp = new Date(time).toUTCString();
        document.cookie = `refreshToken=${response.accessToken}; expires=${cookieExp} ; path=/;`;
        return { response: this.accessToken }; */
      })
      .catch((error) => {
        return { error };
      });
  };

  public SignOut = async () => {
    document.cookie = `refreshToken= ; expires= Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
    this.accessToken = null;
    await this.stopRefreshTokenTimer();
    window.location.href = "/sign-in";

    return true;
  };
  public SignSocial = async (payload) => {
    return action
      .Post({
        url: `/auth/${payload.social}/${payload.code}`,
        ...(!!payload.body && { body: payload.body }),
      })
      .then((response) => {
        this.accessToken = parseJwt(response.accessToken);
        this.refreshAcessToken = response.accessToken;
        this.startRefreshTokenTimer();

        const time = this.accessToken.exp * 1000;
        const cookieExp = new Date(time).toUTCString();
        document.cookie = `refreshToken=${response.accessToken}; expires=${cookieExp} ; path=/`;
        return { response: this.accessToken };
      })
      .catch((error) => {
        return { error };
      });
  };
  public updatePassword = async ({ id, body }) => {
    return action
      .Patch({
        url: `/users/${id}/update-password`,
        body,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  };

  public cleanPassword = async ({ id, body }) => {
    return action
      .Patch({
        url: `/users/${id}/clean-password`,
        body,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  };

  private startRefreshTokenTimer() {
    const jwtToken = this.accessToken;
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - 60 * 1000;
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken(), timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  public recoverPassword(payload) {
    return action
      .Post({
        url: "/forgot-password/forgot",
        body: payload,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public resetPassword(payload) {
    return action
      .Post({
        url: "/forgot-password/reset",
        body: payload,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const auth = new Auth();
export default auth;
