import { Formik, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { InLine } from "../../../StyledComponents";
import Icon from "../../../Icon";
import Button from "../../../Button";
import Input from "../../../Form/Input";
import { useRecruitingLayout } from "../../../../provider/RecruitingProvider";
import searchIcon from "../../../../assets/icons/search.svg";
import { showModal } from "../../../../store/actions/modal";
import { useDispatch } from "react-redux";

interface ComponentProps {}

const MyAutoSavingComponent = () => {
  const formik = useFormikContext();

  useEffect(() => {
    // use your own equality test or react-fast-compare because they are probably different objects
    if (formik.values !== formik.initialValues) {
      formik.submitForm(); // or onSubmit if you want to do validations before submitting
    }
  }, [formik.values]);
  // not listening for initialValues, because even if they are updated you probably don't want to autosave.
  return null;
};

const Component = ({}: ComponentProps) => {
  const { filterList, newProfileModal } = useRecruitingLayout();
  const dispatch = useDispatch();
  const [formSubmmited, setFormSubmmited] = useState<boolean>(false);

  const onSubmit = ({ values, actions }) => {
    filterList(values);
  };

  return (
    <InLine>
      <div style={{ marginRight: "10px" }}>
        <Formik
          initialValues={{}}
          onSubmit={(values, actions) => {
            if (onSubmit) onSubmit({ values, actions });
          }}
          validateOnChange={formSubmmited}
          validateOnBlur={false}
          enableReinitialize
        >
          {({
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => {
            return (
              <form
                onSubmit={(event) => {
                  setFormSubmmited(true);
                  handleSubmit(event);
                }}
                id="layout-search"
              >
                <Input
                  name="search"
                  error={errors["search"]}
                  touched={touched["search"]}
                  value={values["search"]}
                  type="text"
                  placeholder="Búsqueda..."
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={{
                    marginBottom: 0,
                    before: <Icon icon={searchIcon} size="16px" color="" />,
                  }}
                />

                <MyAutoSavingComponent />
              </form>
            );
          }}
        </Formik>
      </div>
      <Button
        type="button"
        options={{
          size: "lg",
          type: "filled",
          skin: "primary",
        }}
        style={{ minWidth: "0px" }}
        onClick={() =>
          !!newProfileModal && dispatch(showModal(newProfileModal))
        }
      >
        Agregar
      </Button>
    </InLine>
  );
};

export default Component;
