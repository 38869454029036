import * as Yup from "yup";

interface FormValues {
  name: string;
  picture: string;
  private: boolean;
  description: string;
  email: string;
  phone: string;
  users: string[];
}

export const schema = Yup.object().shape({
  name: Yup.string()
    .min(5, "Minimo 5 caracteres")
    .required("Se necesita nombre"),
  picture: Yup.string(),
  private: Yup.bool().required(),
  description: Yup.string().required(),
  email: Yup.string().email("No es un email").required(),
  phone: Yup.string(),
  users: Yup.array(),
});

export const initialValues = (initialValues = {}): FormValues => {
  return {
    name: "",
    picture: "",
    private: false,
    description: "",
    email: "",
    phone: "",
    users: [],
    ...initialValues,
  };
};
