import { put, call, takeLatest, select } from "redux-saga/effects";
import services from "../../../services";
import {
  NEW_COMPANY,
  NEW_COMPANY_ERROR,
  NEW_COMPANY_SUCCESS,
  NewCompany,
  GET_ALL_COMPANIES,
  GET_ALL_COMPANIES_ERROR,
  GET_ALL_COMPANIES_SUCCESS,
  GetAllCompanies,
  UPDATE_COMPANY,
  UPDATE_COMPANY_ERROR,
  UPDATE_COMPANY_SUCCESS,
  UpdateCompany,
  DELETE_COMPANY,
  SET_UPDATE_COMPANY_DATA,
  UPDATE_CURRENT_COMPANY_LIST,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_ERROR,
} from "../../types/recruiting/company";

const companyList = ({ recruitingStore }) => recruitingStore.company.all.data;

function* newCompany({ payload }) {
  const { response, error } = yield call(
    services.recruiting.company.new,
    payload
  );

  if (error) {
    yield put({
      type: NEW_COMPANY_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    const currentListOfTechsCompanies = yield select(companyList);
    const newListofTechsCompanies = !!currentListOfTechsCompanies
      ? [...currentListOfTechsCompanies, response]
      : null;
    yield put({
      type: UPDATE_CURRENT_COMPANY_LIST,
      payload: newListofTechsCompanies,
    });
    yield put({ type: NEW_COMPANY_SUCCESS, payload: response });
  }
}

function* deleteCompany({ payload }) {
  const { response, error } = yield call(
    services.recruiting.company.delete,
    payload
  );

  if (error) {
    yield put({
      type: DELETE_COMPANY_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    const currentListOfCompanies = yield select(companyList);
    const newListofCompanies = currentListOfCompanies?.filter(
      (company) => company._id !== payload._id
    );
    yield put({
      type: UPDATE_CURRENT_COMPANY_LIST,
      payload: newListofCompanies,
    });
    yield put({ type: DELETE_COMPANY_SUCCESS, payload: response });
  }
}

function* getAllCompanies() {
  const { response, error } = yield call(services.recruiting.company.getAll);
  if (error) {
    yield put({
      type: GET_ALL_COMPANIES_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_COMPANIES_SUCCESS, payload: response });
  }
}

function* updatedCompany({ payload }) {
  const { response, error } = yield call(
    services.recruiting.company.update,
    payload
  );

  if (error) {
    yield put({
      type: UPDATE_COMPANY_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    const currentListOfComapnies = yield select(companyList);
    const newListofComapnies = currentListOfComapnies?.data?.map((company) => {
      if (company._id === payload._id) {
        return { ...company, ...payload };
      }
      return company;
    });
    yield put({
      type: UPDATE_CURRENT_COMPANY_LIST,
      payload: newListofComapnies,
    });
    yield put({ type: UPDATE_COMPANY_SUCCESS, payload: null });
    yield put({ type: GET_ALL_COMPANIES, payload: null });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<NewCompany>(NEW_COMPANY, newCompany);
  yield takeLatest<GetAllCompanies>(GET_ALL_COMPANIES, getAllCompanies);
  yield takeLatest<UpdateCompany>(UPDATE_COMPANY, updatedCompany);
  yield takeLatest<UpdateCompany>(DELETE_COMPANY, deleteCompany);
}
