import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Modal from "../../../../../../components/Modal";
import { Formik } from "formik";
import { Center } from "../../../../../../components/StyledComponents";
import {
  initialValues,
  schema,
} from "../../../../../../constants/form/association/new";
import Grid from "../../../../../../components/Grid";
import Button from "../../../../../../components/Button";
import Submit from "../../../../../../components/Form/Submit";
import { toast } from "react-toastify";
import associationActions from "../../../../../../store/actions/association";
import { hideModal } from "../../../../../../store/actions/modal";
import { AiOutlineSearch } from "react-icons/ai";
import { AcademyDto } from "../../../../../../types/academy.dto";
import { CurrentDto } from "../../../../../../types/current.dto";
import DinamicField from "../../../../../../components/Form/DinamicField";
import { OrganizationDto } from "../../../../../../types/organization.dto";

const Component = ({
  academies,
  organizations,
  createStates,
  current,
}: {
  createStates: {
    loading: boolean;
    error: boolean;
    success: boolean;
  };
  organizations: OrganizationDto[];
  academies: AcademyDto[];
  current: CurrentDto;
}) => {
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const dispatch = useDispatch();

  const onSubmit = ({ values, actions }) => {
    for (const entity of values.entities) {
      dispatch(
        associationActions.create({
          [current.currentSection === "academy"
            ? "organizationTo"
            : "academyTo"]: entity,
          [current.currentSection === "academy"
            ? "academyFrom"
            : "organizationFrom"]: current.id,
        })
      );
    }
    setFormActions(actions);
  };

  useEffect(() => {
    if (createStates.success) {
      toast.success("👌🏼 Invitacion creada");

      setTimeout(() => {
        dispatch(associationActions.resetCreate());
        dispatch(hideModal());
        formActions?.resetForm();
      }, 1000);
    }

    if (createStates.error) {
      toast.error(`😱 ${createStates.error}`);

      setTimeout(() => {
        dispatch(associationActions.resetCreate());
      }, 1000);
    }
  }, [createStates]);

  return (
    <Modal name="invite-association-modal" title={`Invitar para asociarse`}>
      <Formik
        initialValues={{ ...initialValues() }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="invite-academy-modal-form"
            >
              <Grid.Row>
                <Grid.Col>
                  <DinamicField
                    name="entities"
                    error={errors["entities"]}
                    touched={touched["entities"]}
                    value={values["entities"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      skin: "gray",
                      type: "select",
                      height: "95px",
                      indicator: true,
                      before: <AiOutlineSearch size={15}></AiOutlineSearch>,
                      ...(!!academies &&
                        !!organizations && {
                          data: (current.currentSection === "organization"
                            ? academies.filter(
                                (academy) =>
                                  academy.active &&
                                  !academy.associatedOrgs.includes(current.id)
                              )
                            : organizations.filter(
                                (organization) =>
                                  organization.active &&
                                  !academies
                                    .find(
                                      (academy) => academy._id === current.id
                                    )
                                    ?.associatedOrgs.includes(organization._id)
                              )
                          ).map((entity) => ({
                            label: entity.name,
                            value: entity._id,
                          })),
                        }),

                      loading: !academies || !organizations,
                      inputLabel: "Buscar por nombre de entidad",
                      inputPlaceholder: "Busca la entidad",
                    }}
                  />
                </Grid.Col>
              </Grid.Row>

              <Grid.Row>
                <Grid.Col>
                  <Center gap={"70px"}>
                    <Button
                      onClick={resetForm}
                      type="button"
                      options={{
                        type: "outline",
                        skin: "gray",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                      style={{ marginLeft: "10px" }}
                    >
                      Cancelar
                    </Button>

                    <Submit
                      isSubmmiting={isSubmitting}
                      form="invite-academy-modal-form"
                      color="Primary"
                      options={{
                        type: "filled",
                        skin: "primary",
                        size: "lg",
                        marginBottom: "0px",
                      }}
                    >
                      Confirmar
                    </Submit>
                  </Center>
                </Grid.Col>
              </Grid.Row>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const states = ({
  associationStore,
  academyStore,
  currentStore,
  organizationStore,
}) => {
  const { data: academies } = academyStore.all;
  const { data: organizations } = organizationStore.all;
  const { states: createStates } = associationStore.create;
  const { data: current } = currentStore;
  return {
    organizations,
    academies,
    createStates,
    current,
  };
};

export default connect(states)(Component);
