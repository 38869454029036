import { CohortDto } from "../types/cohort.dto";

export const filterCohortByRole = (cohorts, user, role): CohortDto[] => {
  switch (role) {
    case "ADMIN":
      return cohorts;
    case "PROFESSOR":
      return cohorts.filter(
        (cohort) =>
          cohort.mentorsIds.includes(user) ||
          cohort.observerIds.includes(user) ||
          cohort.studentsIds.includes(user) ||
          !cohort.private
      );
    case "STUDENT":
      return cohorts.filter(
        (cohort) =>
          cohort.mentorsIds.includes(user) ||
          cohort.observerIds.includes(user) ||
          cohort.studentsIds.includes(user) ||
          !cohort.private
      );
    case "PM":
      return cohorts.filter(
        (cohort) =>
          cohort.mentorsIds.includes(user) ||
          cohort.observerIds.includes(user) ||
          cohort.studentsIds.includes(user) ||
          !cohort.private
      );
    case "OBSERVER":
      return cohorts.filter(
        (cohort) =>
          cohort.mentorsIds.includes(user) ||
          cohort.observerIds.includes(user) ||
          cohort.studentsIds.includes(user) ||
          !cohort.private
      );

    default:
      return cohorts;
  }
};
