import { ExecutiveDto } from "../../types/executive.dto";

export const GET_ALL_EXECUTIVES = "GET_ALL_EXECUTIVES";
export const GET_ALL_EXECUTIVES_ERROR = "GET_ALL_EXECUTIVES_ERROR";
export const GET_ALL_EXECUTIVES_SUCCESS = "GET_ALL_EXECUTIVES_SUCCESS";
export const GET_EXECUTIVE = "GET_EXECUTIVE";
export const GET_EXECUTIVE_ERROR = "GET_EXECUTIVE_ERROR";
export const GET_EXECUTIVE_SUCCESS = "GET_EXECUTIVE_SUCCESS";
export const NEW_EXECUTIVE = "NEW_EXECUTIVE";
export const NEW_EXECUTIVE_ERROR = "NEW_EXECUTIVE_ERROR";
export const NEW_EXECUTIVE_SUCCESS = "NEW_EXECUTIVE_SUCCESS";
export const RESET_NEW_EXECUTIVE = "RESET_NEW_EXECUTIVE";
export const UPDATE_EXECUTIVE = "UPDATE_EXECUTIVE";
export const UPDATE_EXECUTIVE_ERROR = "UPDATE_EXECUTIVE_ERROR";
export const UPDATE_EXECUTIVE_SUCCESS = "UPDATE_EXECUTIVE_SUCCESS";
export const RESET_UPDATE_EXECUTIVE = "RESET_UPDATE_EXECUTIVE";
export const DELETE_EXECUTIVE = "DELETE_EXECUTIVE";
export const DELETE_EXECUTIVE_ERROR = "DELETE_EXECUTIVE_ERROR";
export const DELETE_EXECUTIVE_SUCCESS = "DELETE_EXECUTIVE_SUCCESS";
export const RESET_DELETE_EXECUTIVE = "RESET_DELETE_EXECUTIVE";

export interface GetAllExecutives {
  type: typeof GET_ALL_EXECUTIVES;
  payload: null;
}

export interface GetAllExecutivesSuccess {
  type: typeof GET_ALL_EXECUTIVES_SUCCESS;
  payload: ExecutiveDto[];
}

export interface GetAllExecutivesError {
  type: typeof GET_ALL_EXECUTIVES_ERROR;
  payload: boolean | string;
}

export interface GetExecutive {
  type: typeof GET_EXECUTIVE;
  payload: { id: string };
}

export interface GetExecutiveSuccess {
  type: typeof GET_EXECUTIVE_SUCCESS;
  payload: ExecutiveDto;
}

export interface GetExecutiveError {
  type: typeof GET_EXECUTIVE_ERROR;
  payload: boolean | string;
}

export interface NewExecutive {
  type: typeof NEW_EXECUTIVE;
  payload: ExecutiveDto;
}

export interface NewExecutiveSuccess {
  type: typeof NEW_EXECUTIVE_SUCCESS;
  payload: ExecutiveDto;
}

export interface NewExecutiveError {
  type: typeof NEW_EXECUTIVE_ERROR;
  payload: boolean | string;
}

export interface ResetNewExecutive {
  type: typeof RESET_NEW_EXECUTIVE;
  payload: null;
}

export interface UpdateExecutive {
  type: typeof UPDATE_EXECUTIVE;
  payload: ExecutiveDto;
}

export interface UpdateExecutiveSuccess {
  type: typeof UPDATE_EXECUTIVE_SUCCESS;
  payload: ExecutiveDto;
}

export interface UpdateExecutiveError {
  type: typeof UPDATE_EXECUTIVE_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateExecutive {
  type: typeof RESET_UPDATE_EXECUTIVE;
  payload: null;
}

export interface DeleteExecutive {
  type: typeof DELETE_EXECUTIVE;
  payload: { id: string };
}

export interface DeleteExecutiveSuccess {
  type: typeof DELETE_EXECUTIVE_SUCCESS;
  payload: null;
}

export interface DeleteExecutiveError {
  type: typeof DELETE_EXECUTIVE_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteExecutive {
  type: typeof RESET_DELETE_EXECUTIVE;
  payload: null;
}

export interface State {
  allExecutives: {
    data: ExecutiveDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  executive: {
    data: ExecutiveDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  newExecutive: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  deleteExecutive: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  updateExecutive: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  allExecutives: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  executive: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  newExecutive: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  deleteExecutive: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  updateExecutive: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllExecutives
  | GetAllExecutivesSuccess
  | GetAllExecutivesError
  | GetExecutive
  | GetExecutiveSuccess
  | GetExecutiveError
  | NewExecutive
  | NewExecutiveSuccess
  | NewExecutiveError
  | ResetNewExecutive
  | UpdateExecutive
  | UpdateExecutiveSuccess
  | UpdateExecutiveError
  | ResetUpdateExecutive
  | DeleteExecutive
  | DeleteExecutiveSuccess
  | DeleteExecutiveError
  | ResetDeleteExecutive;
