import styled from "styled-components";

export const RoadmapUploaderDescriptionWrapper = styled("div")`
  border-radius: 10px;
  width: 100%;
  padding: 10px;
  box-shadow: 0 0 2px 0 rgba(21, 115, 229, 0.16);
  background-color: #fff;
`;

export const ButtonInput = styled("label")`
  font-family: Inter, sans-serif;
  cursor: pointer;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border: 1px solid rgb(21, 115, 229);
  height: 40px;
  background-color: rgb(21, 115, 229);
  font-size: 16px;
  color: rgb(255, 255, 255);
  border-radius: 15px;
  padding: 0px 17px;
  text-decoration: none;
  letter-spacing: 0.2px;
  font-weight: 500;
  
  text-align: center;
  transition: all 0.2s ease-in-out 0s;
  margin-bottom: 0px;
  outline: none;
`;

export const RoadmapUploadWrapper = styled("div")`
  position: relative;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 0 2px 0 rgba(21, 115, 229, 0.16);
  height: ${(props) => props.height};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: #1573e5;
    svg {
      color: #fff;
    }
    .title {
      color: #fff;
    }
  }
`;

export const RoadmapUploaderTitle = styled("h3")`
  font-size: 24px;
  font-weight: 500;
  color: #697482;
  text-align: center;
`;
