import {
  GetAllImages,
  GET_ALL_IMAGES,
  GetImage,
  GET_IMAGE,
  UpdateImage,
  UPDATE_IMAGE,
  DeleteImage,
  DELETE_IMAGE,
  GetImageSuccess,
  GET_IMAGE_SUCCESS,
  NewImage,
  NEW_IMAGE,
  ResetNewImage,
  RESET_NEW_IMAGE,
  ResetUpdateImage,
  RESET_UPDATE_IMAGE,
  ResetDeleteImage,
  RESET_DELETE_IMAGE,
  UploadMedia,
  UPLOAD_MEDIA,
  RESET_GET_IMAGE,
  ADD_USER_TO_IMAGE,
  SHARED_IMAGE,
  UPDATE_MANY_IMAGE,
  RESET_UPDATE_MANY_IMAGE,
  RESET_GET_ALL_IMAGE_DATA,
} from "../types/image";

export const getAllImages = (payload): GetAllImages => {
  return { type: GET_ALL_IMAGES, payload };
};

export const resetGetAllImages = () => {
  return { type: RESET_GET_ALL_IMAGE_DATA, payload: null };
};

export const getImage = (id): GetImage => {
  return { type: GET_IMAGE, payload: id };
};

export const resetGetImage = () => {
  return { type: RESET_GET_IMAGE, payload: null };
};

export const setSelectedImage = (payload): GetImageSuccess => {
  return { type: GET_IMAGE_SUCCESS, payload };
};

export const newImage = (payload): NewImage => {
  return { type: NEW_IMAGE, payload };
};
export const uploadMediaImage = (payload): UploadMedia => {
  return { type: UPLOAD_MEDIA, payload };
};

export const updateManyImage = (payload) => {
  return { type: UPDATE_MANY_IMAGE, payload };
};

export const resetUpdateManyImage = () => {
  return { type: RESET_UPDATE_MANY_IMAGE, payload: null };
};

export const resetNewImage = (): ResetNewImage => {
  return { type: RESET_NEW_IMAGE, payload: null };
};

export const updateImage = (payload): UpdateImage => {
  return { type: UPDATE_IMAGE, payload };
};

export const resetUpdateImage = (): ResetUpdateImage => {
  return { type: RESET_UPDATE_IMAGE, payload: null };
};

export const addUserToImage = (payload) => {
  return { type: ADD_USER_TO_IMAGE, payload };
};

export const deleteImage = (payload): DeleteImage => {
  return { type: DELETE_IMAGE, payload };
};

export const resetDeleteImage = (): ResetDeleteImage => {
  return { type: RESET_DELETE_IMAGE, payload: null };
};

export const shareImage = (payload) => {
  return { type: SHARED_IMAGE, payload };
};
