import { CompanyDto } from "../../../types/recruiting.dto";

export const GET_ALL_COMPANIES = "GET_ALL_COMPANIES";
export const GET_ALL_COMPANIES_ERROR = "GET_ALL_COMPANIES_ERROR";
export const GET_ALL_COMPANIES_SUCCESS = "GET_ALL_COMPANIES_SUCCESS";
export const GET_COMPANY = "GET_COMPANY";
export const GET_COMPANY_ERROR = "GET_COMPANY_ERROR";
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS";
export const NEW_COMPANY = "NEW_COMPANY";
export const NEW_COMPANY_ERROR = "NEW_COMPANY_ERROR";
export const NEW_COMPANY_SUCCESS = "NEW_COMPANY_SUCCESS";
export const RESET_NEW_COMPANY = "RESET_NEW_COMPANY";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const UPDATE_COMPANY_ERROR = "UPDATE_COMPANY_ERROR";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const RESET_UPDATE_COMPANY = "RESET_UPDATE_COMPANY";
export const DELETE_COMPANY = "DELETE_COMPANY";
export const DELETE_COMPANY_ERROR = "DELETE_COMPANY_ERROR";
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS";
export const RESET_DELETE_COMPANY = "RESET_DELETE_COMPANY";
export const SET_UPDATE_COMPANY_DATA = "SET_UPDATE_COMPANY_DATA";
export const SET_NEW_COMPANY_DATA = "SET_NEW_COMPANY_DATA";
export const UPDATE_CURRENT_COMPANY_LIST = "UPDATE_CURRENT_COMPANY_LIST";

export interface UpdateCurrentCompanyList {
  type: typeof UPDATE_CURRENT_COMPANY_LIST;
  payload: CompanyDto[];
}

export interface GetAllCompanies {
  type: typeof GET_ALL_COMPANIES;
  payload: null;
}

export interface GetAllCompaniesSuccess {
  type: typeof GET_ALL_COMPANIES_SUCCESS;
  payload: CompanyDto[];
}

export interface GetAllCompaniesError {
  type: typeof GET_ALL_COMPANIES_ERROR;
  payload: boolean | string;
}

export interface GetCompany {
  type: typeof GET_COMPANY;
  payload: { _id: string };
}

export interface GetCompanySuccess {
  type: typeof GET_COMPANY_SUCCESS;
  payload: CompanyDto;
}

export interface GetCompanyError {
  type: typeof GET_COMPANY_ERROR;
  payload: boolean | string;
}

export interface NewCompany {
  type: typeof NEW_COMPANY;
  payload: CompanyDto;
}

export interface NewCompanySuccess {
  type: typeof NEW_COMPANY_SUCCESS;
  payload: CompanyDto;
}

export interface NewCompanyError {
  type: typeof NEW_COMPANY_ERROR;
  payload: boolean | string;
}

export interface SetUpdateCompanyData {
  type: typeof SET_UPDATE_COMPANY_DATA;
  payload: CompanyDto;
}

export interface SetNewCompanyData {
  type: typeof SET_NEW_COMPANY_DATA;
  payload: CompanyDto;
}

export interface ResetNewCompany {
  type: typeof RESET_NEW_COMPANY;
  payload: null;
}

export interface UpdateCompany {
  type: typeof UPDATE_COMPANY;
  payload: CompanyDto;
}

export interface UpdateCompanySuccess {
  type: typeof UPDATE_COMPANY_SUCCESS;
  payload: CompanyDto;
}

export interface UpdateCompanyError {
  type: typeof UPDATE_COMPANY_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateCompany {
  type: typeof RESET_UPDATE_COMPANY;
  payload: null;
}

export interface DeleteCompany {
  type: typeof DELETE_COMPANY;
  payload: { _id: string };
}

export interface DeleteCompanySuccess {
  type: typeof DELETE_COMPANY_SUCCESS;
  payload: null;
}

export interface DeleteCompanyError {
  type: typeof DELETE_COMPANY_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteCompany {
  type: typeof RESET_DELETE_COMPANY;
  payload: null;
}

export interface State {
  all: {
    data: CompanyDto[] | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  selected: {
    data: CompanyDto | null;
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  create: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  delete: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  update: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  all: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  selected: {
    data: null,
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  create: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  delete: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  update: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
};

export type Actions =
  | GetAllCompanies
  | GetAllCompaniesSuccess
  | GetAllCompaniesError
  | GetCompany
  | GetCompanySuccess
  | GetCompanyError
  | NewCompany
  | NewCompanySuccess
  | NewCompanyError
  | ResetNewCompany
  | UpdateCompany
  | UpdateCompanySuccess
  | UpdateCompanyError
  | ResetUpdateCompany
  | DeleteCompany
  | DeleteCompanySuccess
  | DeleteCompanyError
  | ResetDeleteCompany
  | SetUpdateCompanyData
  | SetNewCompanyData
  | UpdateCurrentCompanyList;
