import styled from "styled-components";

export const ProgressBarWrapper = styled("div")`
  max-width: 500px;
  width: 100%;
  border-radius: 5px;
  
  background: #fff;
  position: relative;
  display: flex;
  height: 24px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    
    left: 0;
    height: 100%;
    width: ${(props) => props.width || 0}%;
  }
`;

export const ProgressBarText = styled("p")`
  font-size: 16px;
  font-weight: 500;
  color: #273241;
`;
