import { put, call, all, takeLatest, select } from "redux-saga/effects";
import services from "../../services";
import {
  GET_LISTEN,
  GET_LISTEN_SUCCESS,
  GET_LISTEN_ERROR,
  GET_ALL_LISTENS,
  GET_ALL_LISTENS_SUCCESS,
  GET_ALL_LISTENS_ERROR,
  NEW_LISTEN,
  NEW_LISTEN_ERROR,
  NEW_LISTEN_SUCCESS,
  NewListen,
  UPDATE_LISTEN,
  UPDATE_LISTEN_SUCCESS,
  UPDATE_LISTEN_ERROR,
  DELETE_LISTEN,
  DELETE_LISTEN_SUCCESS,
  DELETE_LISTEN_ERROR,
  GetListen,
  GetAllListens,
  UpdateListen,
  DeleteListen,
  UploadMedia,
  UPLOAD_MEDIA,
  AddUserToListen,
  ADD_USER_TO_LISTEN,
  SharedListen,
  SHARED_LISTEN,
  UPDATE_MANY_LISTEN,
  UPDATE_MANY_LISTEN_ERROR,
  UPDATE_MANY_LISTEN_SUCCESS,
  UpdateManyListen,
} from "../types/listen";
import { ListenDto } from "../../types/listen.dto";

function* getAllListens({ payload }) {
  const { response, error } = yield call(services.listen.getAll, payload);

  if (error) {
    yield put({
      type: GET_ALL_LISTENS_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_LISTENS_SUCCESS, payload: response });
  }
}

function* getListen({ payload }) {
  const { response, error } = yield call(services.listen.getOne, payload);

  if (error) {
    yield put({
      type: GET_LISTEN_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_LISTEN_SUCCESS, payload: response });
  }
}

function* updateManyListen({ payload }) {
  let updatedList: ListenDto[] = [];
  try {
    for (let i = 0; i < payload.items.length; i++) {
      const { _id, ...body } = payload.items[i];
      const formDataInsteadBody = !!body.body ? body.body : body;
      const { response, error } = yield call(services.listen.update, {
        _id,
        body: formDataInsteadBody,
      });
      if (!!response) {
        updatedList = [...updatedList, response];
      }
    }

    yield put({ type: UPDATE_MANY_LISTEN_SUCCESS, payload: updatedList });
  } catch (err) {
    yield put({ type: UPDATE_MANY_LISTEN_ERROR, payload: err });
  }
}

function* newListen({ payload }) {
  const { response, error } = yield call(services.listen.new, payload);

  if (error) {
    yield put({
      type: NEW_LISTEN_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: NEW_LISTEN_SUCCESS, payload: response })]);
  }
}

function* updateListen({ payload }) {
  const { response, error } = yield call(services.listen.update, payload);
  if (error) {
    yield put({
      type: UPDATE_LISTEN_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_LISTEN_SUCCESS, payload: response })]);
  }
}
function* addUserToListen({ payload }) {
  const { response, error } = yield call(
    services.listen.addUserListen,
    payload
  );

  if (error) {
    yield put({
      type: UPDATE_LISTEN_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_LISTEN_SUCCESS, payload: response })]);
  }
}

function* uploadMedia({ payload }) {
  const { response, error } = yield call(services.listen.update, payload);

  if (error) {
    yield put({
      type: UPDATE_LISTEN_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_LISTEN_SUCCESS, payload: response })]);
  }
}

function* deleteListen({ payload }) {
  const { response, error } = yield call(services.listen.delete, payload);

  if (error) {
    yield put({
      type: DELETE_LISTEN_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: DELETE_LISTEN_SUCCESS, payload: response })]);
  }
}

function* shareListen({ payload }) {
  const { response, error } = yield call(services.listen.shareListen, payload);

  if (error) {
    yield put({
      type: UPDATE_LISTEN_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield all([put({ type: UPDATE_LISTEN_SUCCESS, payload: response })]);
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllListens>(GET_ALL_LISTENS, getAllListens);
  yield takeLatest<GetListen>(GET_LISTEN, getListen);
  yield takeLatest<NewListen>(NEW_LISTEN, newListen);
  yield takeLatest<UpdateListen>(UPDATE_LISTEN, updateListen);
  yield takeLatest<DeleteListen>(DELETE_LISTEN, deleteListen);
  yield takeLatest<UploadMedia>(UPLOAD_MEDIA, uploadMedia);
  yield takeLatest<AddUserToListen>(ADD_USER_TO_LISTEN, addUserToListen);
  yield takeLatest<SharedListen>(SHARED_LISTEN, shareListen);
  yield takeLatest<UpdateManyListen>(UPDATE_MANY_LISTEN, updateManyListen);
}
