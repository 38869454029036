import {
  initialState,
  Actions,
  State,
  SHOW_MODAL,
  HIDE_MODAL,
} from "../types/modal";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        visibleModal: action.payload,
      };
    case HIDE_MODAL:
      return {
        visibleModal: null,
      };
    default:
      return state;
  }
};

export default reducers;
