import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import Button from "../../../../../../components/Button";
import Grid from "../../../../../../components/Grid";
import { GridStructure } from "../../../../../../components/Layout/Dashboard/styles";
import LineGraphHorizontal from "../../../../../../components/LineGraphHorizontal";
import Loader from "../../../../../../components/Loader";
import PieChart from "../../../../../../components/PieChart";
import {
  FlexContainer,
  PageTitle,
} from "../../../../../../components/StyledComponents";
import { DateEStoDateEN } from "../../../../../../helpers/format-date";
import { getAcademyStats } from "../../../../../../store/actions/analytics";
import AnalyticCard from "../../../components/AnalyticCard";
import NotData from "../../../components/NotData";
import { RankingItem, RankingTable } from "../../../style";
import { PDFExport } from "@progress/kendo-react-pdf";
import {
  formatNumber,
  overThousandFixed,
} from "../../../../../../helpers/number-fixed";
import { AcademyAnalyticsDto } from "../../../../../../types/analytics.dto";
import { StateDto } from "../../../../../../types/states.dto";
import { CurrentDto } from "../../../../../../types/current.dto";

const Component = ({
  analyticsAcademy,
  analyticsAcademyStates,
  current,
}: {
  analyticsAcademy: AcademyAnalyticsDto;
  analyticsAcademyStates: StateDto;
  current: CurrentDto;
}) => {
  const printRef: any = React.useRef();
  const dispatch = useDispatch();
  const handleExportWithComponent = () => {
    printRef.current.save();
  };
  useEffect(() => {
    dispatch(getAcademyStats({ _id: current.id }));
  }, []);
  /*  if (!analyticsAcademy) return <Loader color="Primary"></Loader>; */
  if (!analyticsAcademy) return <Loader color="Primary"></Loader>;
  return (
    <>
      <PageTitle>Dashboard</PageTitle>
      <Grid.Col offset={{ sm: 10 }} sm={12}>
        <Button
          onClick={() => {
            handleExportWithComponent();
          }}
          type="button"
          options={{
            type: "filled",
            skin: "primary",
            size: "md",
            marginBottom: "10px",
          }}
          style={{ marginLeft: "10px" }}
        >
          Descargar
        </Button>
      </Grid.Col>
      <PDFExport fileName={`Audiencia ${new Date().valueOf()}`} ref={printRef}>
        <FlexContainer direction="column" gap="6px">
          <AnalyticCard
            title={"Tiempo de tu audiencia en tu Academia (hs)"}
            graphTitle={
              "Tiempo de tu audiencia en tu Academia (hs) - Crecimiento Histórico"
            }
            data={{
              stats: [
                {
                  label: "Últimos 30 días",
                  value:
                    overThousandFixed(
                      analyticsAcademy.academy.screentime.lastMonth
                    ) ?? 0,
                },
                {
                  label: "Últimos 7 días",
                  value:
                    overThousandFixed(
                      analyticsAcademy.academy.screentime.lastWeek
                    ) || 0,
                },
                {
                  label: "Últimas 24 horas",
                  value:
                    overThousandFixed(
                      analyticsAcademy.academy.screentime.lastDay
                    ) || 0,
                },
              ],
              graphData: Object.entries(
                analyticsAcademy.academy.screentime.growth
              )
                .sort((a, b) => DateEStoDateEN(a[0]) - DateEStoDateEN(b[0]))
                .map((growthData) => {
                  return {
                    name: growthData[0],
                    value: formatNumber(growthData[1]) || 0,
                  };
                }),
            }}
          ></AnalyticCard>

          <AnalyticCard
            height="250px"
            title="Top 10 Cohort con mas interacciones"
          >
            <NotData data={analyticsAcademy.cohorts.clicks.topTen}>
              <LineGraphHorizontal
                data={analyticsAcademy.cohorts.clicks.topTen.map((cohort) => {
                  return {
                    label: cohort.name,
                    value: formatNumber(cohort.clickCount),
                  };
                })}
                title={"Alumnos"}
              ></LineGraphHorizontal>
            </NotData>
          </AnalyticCard>
          <AnalyticCard
            height="250px"
            title="Top 10 Contenido con mas interacciones"
          >
            <NotData data={analyticsAcademy.contents.topTen}>
              <LineGraphHorizontal
                data={analyticsAcademy.contents.topTen.map((content) => {
                  return {
                    label: content.name,
                    value: formatNumber(content.clickCount),
                  };
                })}
                title={"Alumnos"}
              ></LineGraphHorizontal>
            </NotData>
          </AnalyticCard>
          <GridStructure
            style={{ width: "100%" }}
            type="auto-fit"
            width="400px"
          >
            <AnalyticCard height="300px" title="Genero de tus Usuarios">
              <NotData data={analyticsAcademy.academy.users.genders}>
                <PieChart
                  mode="per"
                  pieData={analyticsAcademy.academy.users.genders.map(
                    (contact) => {
                      return {
                        label: contact._id,
                        value: formatNumber(contact.count),
                      };
                    }
                  )}
                ></PieChart>
              </NotData>
            </AnalyticCard>
            <AnalyticCard height="300px" title="Edad de tus Usuarios">
              <NotData data={analyticsAcademy.academy.users.ages}>
                <PieChart
                  mode="per"
                  pieData={analyticsAcademy.academy.users.ages.map(
                    (contact) => {
                      return {
                        label: contact._id,
                        value: formatNumber(contact.count),
                      };
                    }
                  )}
                ></PieChart>
              </NotData>
            </AnalyticCard>
          </GridStructure>
          <AnalyticCard height="auto" title="Intereses de tus Usuarios">
            <NotData data={analyticsAcademy.academy.users.topInterests}>
              <RankingTable>
                {analyticsAcademy.academy.users.topInterests.map(
                  (user, index) => {
                    return (
                      <FlexContainer
                        style={{ marginBottom: "5px" }}
                        align="center"
                        gap="5px"
                      >
                        <span>{index + 1}</span>
                        <RankingItem>{user.name}</RankingItem>
                      </FlexContainer>
                    );
                  }
                )}
              </RankingTable>
            </NotData>
          </AnalyticCard>
        </FlexContainer>
      </PDFExport>
    </>
  );
};

const states = ({ analyticsStore, currentStore }) => {
  const { data: analyticsAcademy, states: analyticsAcademyStates } =
    analyticsStore.getAcademyStats;
  const { data: current } = currentStore;
  return {
    analyticsAcademy,
    analyticsAcademyStates,
    current,
  };
};

export default connect(states)(Component);
