import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { AiFillEdit, AiFillLock, AiFillUnlock } from "react-icons/ai";
import { FaCloudUploadAlt, FaSave, FaShare } from "react-icons/fa";
import Submit from "../../../../components/Form/Submit";
import Grid from "../../../../components/Grid";
import { FlexContainer } from "../../../../components/StyledComponents";
import {
  schema,
  initialValues,
} from "../../../../constants/form/video/video-creator";
import Input from "./components/Field/index";
import TextArea from "./components/TextArea";
import { RiCloseCircleFill } from "react-icons/ri";
import {
  ButtonInput,
  TrianglePlay,
  VideoPreviewPublished,
  VideoUploaderDescriptionWrapper,
  VideoUploaderTitle,
  VideoUploadLoaderWrapper,
  VideoUploadWrapper,
} from "./style";
import { BiLink } from "react-icons/bi";
import { BsFillTrash2Fill } from "react-icons/bs";
import { FiUpload } from "react-icons/fi";
import { HiUserAdd } from "react-icons/hi";
import { toast } from "react-toastify";
import { hideModal, showModal } from "../../../../store/actions/modal";
import { connect, useDispatch } from "react-redux";
import { VideoDto } from "../../../../types/video.dto";
import SideBar from "./components/SideBar";
import Loader from "../../../../components/Loader";
import {
  deleteVideo,
  getAllVideos,
  getVideo,
  newVideo,
  resetDeleteVideo,
  resetGetVideo,
  resetNewVideo,
  resetUpdateVideo,
} from "../../../../store/actions/video";
import { useNavigate, useParams } from "react-router-dom";
import { StateDto } from "../../../../types/states.dto";
import Button from "../../../../components/Button";
import PublishVideoModal from "./components/PublishVideoModal";
import {
  NEW_VIDEO_ERROR,
  NEW_VIDEO_SUCCESS,
  SET_GET_VIDEO,
  UPDATE_VIDEO,
  UPDATE_VIDEO_LOADING,
} from "../../../../store/types/video";
import axios from "axios";
import services from "../../../../services";
import AddUsersModal from "./components/AddUsersModal";
import { UsersDto } from "../../../../types/users.dto";
import ShareModal from "./components/ShareModal";
import PrivateModal from "./components/PrivateModal";
import ModalConfirmDelete from "../../../../components/ModalConfirmDelete";
import { CurrentDto } from "../../../../types/current.dto";
import LoadingSource from "./components/LoadingSource";
import CustomPlayer from "./components/CustomPlayer";
import { setFormData } from "../../../../helpers/formData";
import DropzoneField from "../../../../components/Form/Dropzone";
import { UnitDto } from "../../../../types/unit.dto";
import { CohortDto } from "../../../../types/cohort.dto";
import {
  getAllCohorts,
  resetGetAllCohortsData,
} from "../../../../store/actions/cohort";
import { getAllUnits, resetGetAllUnits } from "../../../../store/actions/unit";

const Component = ({
  video,
  videoGetStates,
  videoUpdateStates,
  users,
  videoDeleteStates,
  videos,
  current,
  newVideoDataStates,
  user,
  newVideoData,
  units,
  cohorts,
}: {
  video: VideoDto;
  videoGetStates: StateDto;
  videoUpdateStates: StateDto;
  users: UsersDto[];
  videoDeleteStates: StateDto;
  videos: VideoDto[];
  current: CurrentDto;
  newVideoDataStates: StateDto;
  user: UsersDto;
  newVideoData: VideoDto;
  units: UnitDto[];
  cohorts: CohortDto[];
}) => {
  const [fetchOn, setFetchOn] = useState<boolean>(false);
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const [videoImage, setVideoImage] = useState<any>();
  const [videoCurrent, setVideoCurrent] = useState<any>();
  const [videoDataChanged, setVideoDataChanged] = useState<any>({
    name: "",
    description: "",
  });
  const [loading, setLoading] = useState(0);
  const [duration, setDuration] = useState<number>(0);
  const [videoMedia, setVideoMedia] = useState<{
    videoApi?: string;
    imageApi?: string;
  }>({ videoApi: undefined, imageApi: undefined });
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cancelFileUpload = useRef<any>(null);
  const ref: any = useRef(null);

  const getConfig = async (config) => {
    const token = (await services.auth.getToken()) || "";
    const BASE_URL = process.env.REACT_APP_API_URL || "/";
    return {
      baseURL: BASE_URL,
      ...config,
      ...(token && {
        headers: { authorization: `Bearer ${token}`, ...config.headers },
      }),
    };
  };

  const onSubmitPatch = async (values, actions) => {
    const config = await getConfig({});
    dispatch({ type: UPDATE_VIDEO, payload: null });
    axios
      .patch(
        `/contents/${video?._id}`,
        setFormData(
          {
            name: values.name,
            description: values.description,
            ...(!!values.picture &&
              !videoMedia.imageApi && { picture: values.picture }),
            ...(!!values.url && !video?.beenPublished && { url: values.url }),
            __v: video?.__v,
          },
          ["picture", "url"]
        ),
        {
          ...config,
        }
      )
      .then((response) => {
        dispatch({ type: "UPDATE_VIDEO_SUCCESS", payload: response.data });
        dispatch(getVideo({ _id: response.data._id }));
        setVideoCurrent(undefined);
        setVideoImage(undefined);
        setTimeout(() => {
          dispatch(resetUpdateVideo());
        }, 1000);
      })
      .catch((response) => {
        dispatch({
          type: "UPDATE_VIDEO_ERROR",
          payload: response.message,
        });
        setTimeout(() => {
          dispatch(resetUpdateVideo());
        }, 1000);
      });
  };

  const onSubmitPost = async (values, actions) => {
    const config = await getConfig({});
    if (!video?.url && !videoCurrent) return null;
    if (!video?.picture && !videoImage) return null;

    dispatch({ type: UPDATE_VIDEO_LOADING, payload: null });

    axios
      .post(
        `/contents`,
        setFormData(
          {
            name: values.name,
            description: values.description,
            ...(!!videoImage && { picture: videoImage.file }),
            ...(!!videoCurrent && { url: videoCurrent.file }),
            type: "VIDEO",
            available: true,
            published: true,
            academy: current.id,
            ...values,
          },
          ["picture", "url"]
        ),
        {
          ...config,
          onUploadProgress(data) {
            const currentValue = Math.round((data.loaded / data.total) * 100);
            if (currentValue >= 99) {
              toast.info("Procesando video");
            }
            setLoading(Math.min(currentValue, 99));
          },
          cancelToken: new axios.CancelToken(
            (cancel) => (cancelFileUpload.current = cancel)
          ),
        }
      )
      .then((response) => {
        setLoading(100);
        dispatch({ type: NEW_VIDEO_SUCCESS, payload: response.data });
        setTimeout(() => {
          setLoading(0);
        }, 200);
      })
      .catch((response) => {
        dispatch({
          type: NEW_VIDEO_ERROR,
          payload: response.message,
        });
        setTimeout(() => {
          setLoading(0);
        }, 200);
      });
  };

  const onSelectVideo = (files) => {
    if (files.length <= 0) {
      setVideoCurrent(undefined);
    } else {
      setVideoCurrent({
        file: files[0],
        preview: URL.createObjectURL(files[0]),
      });
    }

    // I've kept this example simple by using the first image instead of multiple
  };

  const onSelectCover = (files) => {
    if (files.length <= 0) {
      setVideoImage(undefined);
    }

    // I've kept this example simple by using the first image instead of multiple
    setVideoImage({
      file: files[0],
      preview: URL.createObjectURL(files[0]),
    });
  };

  const setChangedVideoData = (e) => {
    setVideoDataChanged((state) => {
      return { ...state, [e.target.name]: e.target.value };
    });
  };

  const menu = [
    {
      videoRole: ["OWNER"],
      Icon: FiUpload,
      toolTipName: !!video?.published ? "Editar publicacion" : "Publicar CDoc",
      onClick: () => {
        dispatch(showModal("publish-video-modal"));
      },
      IconActive: AiFillEdit,
      activeFunction: () => !!video?.published,
      onClickActive: () => {
        dispatch(showModal("publish-video-modal"));
      },
    },
    {
      Icon: AiFillLock,
      videoRole: ["OWNER"],
      IconActive: AiFillUnlock,
      toolTipName: "Cambiar visibilidad",
      onAvailable: () => !!video && !!video?.published,
      activeFunction: () => !!video?.published,
      onClick: () => {
        dispatch(showModal("private-video"));
      },
      onClickActive: () => {
        dispatch(showModal("private-video"));
      },
    },
    {
      Icon: HiUserAdd,
      videoRole: ["OWNER", "COOWNER"],
      toolTipName: "Añadir Usuarios",
      onAvailable: () => !!video && !!video?.published,
      onClick: () => {
        dispatch(showModal("add-user-video"));
      },
    },
    {
      Icon: FaSave,
      toolTipName: "Guardar",
      videoRole: ["OWNER", "COOWNER"],
      onClick: () => {},
      activeFunction: () =>
        (!video &&
          videoDataChanged.name?.length > 5 &&
          videoDataChanged.description?.length > 0 &&
          !!videoImage &&
          !!videoCurrent) ||
        (!!video &&
          videoDataChanged.name?.length > 5 &&
          videoDataChanged.description?.length > 0 &&
          (video?.name !== videoDataChanged.name ||
            video?.description !== videoDataChanged.description ||
            (!!videoCurrent && video?.url) ||
            (!!videoImage && video?.picture))),
      onAvailable: () =>
        (!video &&
          videoDataChanged.name?.length > 5 &&
          videoDataChanged.description?.length > 0 &&
          !!videoImage &&
          !!videoCurrent) ||
        (!!video &&
          videoDataChanged.name?.length > 5 &&
          videoDataChanged.description?.length > 0 &&
          (video?.name !== videoDataChanged.name ||
            video?.description !== videoDataChanged.description ||
            (!!videoCurrent && video?.url) ||
            (!!videoImage && video?.picture))),
      onClickActive: () => {
        if (!!video) {
          onSubmitPatch(
            {
              name: videoDataChanged.name,
              description: videoDataChanged.description,
              _v: video?.__v,
              ...(!!videoCurrent && { url: videoCurrent.file }),
              ...(!!videoImage && { picture: videoImage.file }),
            },
            {}
          );
        } else {
          onSubmitPost(
            {
              name: videoDataChanged.name,
              description: videoDataChanged.description,
              available: false,
              published: false,
            },
            {}
          );
        }
      },
    },
    {
      Icon: BiLink,
      videoRole: ["OWNER", "COOWNER", "READER"],
      toolTipName: "Copiar Link",
      onAvailable: () => !!video && !!video?.available && !!video?.published,
      onClick: () => {
        navigator.clipboard.writeText(
          `https://academia.catel.org.ar/dashboard/videos/${video?._id}`
        );
        toast.success(`Link copiado`);
      },
    },
    {
      Icon: FaShare,
      toolTipName: "Compartir",
      videoRole: ["OWNER", "COOWNER", "READER"],
      onAvailable: () => !!video && !!video?.available && !!video?.published,
      onClick: () => {
        dispatch(showModal("share-user-video"));
      },
    },
    {
      Icon: BsFillTrash2Fill,
      videoRole: ["OWNER"],
      toolTipName: "Eliminar Video",
      onAvailable: () => !!video,
      onClick: () => {
        dispatch(showModal("confirm-delete-item-secuencial-modal"));
      },
    },
  ];

  const cancelUploading = () => {
    if (cancelFileUpload.current) {
      cancelFileUpload.current("User has cancel");
    }
  };

  useEffect(() => {
    if (!cohorts) {
      setFetchOn(true);
    }
  }, [cohorts]);

  useEffect(() => {
    !cohorts &&
      fetchOn &&
      dispatch(
        getAllCohorts({ filterBy: { academy: current.id, active: true } })
      );
  }, [cohorts, fetchOn]);

  useEffect(() => {
    if (cohorts && fetchOn) {
      dispatch(
        getAllUnits({
          filterBy: { cohort: { $in: cohorts.map((cohort) => cohort._id) } },
        })
      );
    }
  }, [cohorts]);

  useEffect(() => {
    setVideoCurrent(undefined);
    setVideoImage(undefined);
    dispatch({ type: SET_GET_VIDEO, response: null });
  }, []);

  useEffect(() => {
    if (!!params.id && !video) {
      dispatch(getVideo({ _id: params.id }));
    }
    if (!params.id && !!video) {
      dispatch({ type: SET_GET_VIDEO, response: null });
    }
  }, [dispatch, params.id, video]);

  useEffect(() => {
    if (videoUpdateStates.success) {
      toast.success("👌🏼 Video actualizado");
      setVideoCurrent(undefined);
      setVideoImage(undefined);
      dispatch(getVideo({ _id: video?._id }));
      setTimeout(() => {
        dispatch(resetUpdateVideo());
        dispatch(hideModal());
        formActions?.resetForm();
      }, 1000);
    }

    if (videoUpdateStates.error) {
      toast.error(`😱 ${videoUpdateStates.error}`);
      dispatch(getVideo({ _id: video?._id }));
      setTimeout(() => {
        dispatch(resetUpdateVideo());
      }, 1000);
    }
  }, [videoUpdateStates]);

  useEffect(() => {
    if (videoGetStates.success) {
      setTimeout(() => {
        dispatch(resetGetVideo());
      }, 1000);
    }
    if (videoGetStates.error) {
      toast.error(videoGetStates.error);
      setTimeout(() => {
        dispatch(resetGetVideo());
      }, 1000);
    }
  }, [videoGetStates]);

  useEffect(() => {
    if (!cohorts) {
      setFetchOn(true);
    }
  }, [cohorts]);

  useEffect(() => {
    if (!!video) {
      setVideoDataChanged({
        name: video?.name,
        description: video?.description,
      });
      setVideoMedia({
        videoApi: video?.url,
        imageApi: video?.picture,
      });
    } else {
      setVideoDataChanged({ name: "", description: "" });
      setVideoMedia({ videoApi: undefined, imageApi: undefined });
    }
  }, [video]);

  useEffect(() => {
    if (newVideoDataStates.success) {
      toast.success("Se creo el video exitosamente");
      setVideoCurrent(undefined);
      setVideoImage(undefined);

      if (!!newVideo) {
        navigate(newVideoData._id);
      }
      /*  dispatch(getAllVideos()); */
      setTimeout(() => {
        dispatch(resetNewVideo());
      }, 1000);
    }

    if (newVideoDataStates.error) {
      toast.error("No se pudo crear el video");
      setTimeout(() => {
        dispatch(resetNewVideo());
      }, 1000);
    }
  }, [newVideoDataStates, dispatch]);

  useEffect(() => {
    const getDuration = async function getDuration(file) {
      const url = URL.createObjectURL(file);

      return new Promise((resolve) => {
        const audio = document.createElement("audio");
        audio.muted = true;
        const source = document.createElement("source");
        source.src = url; //--> blob URL
        audio.preload = "metadata";
        audio.appendChild(source);
        audio.onloadedmetadata = function () {
          resolve(audio.duration);
        };
      });
    };

    if (!!videoCurrent) {
      getDuration(videoCurrent.file).then((response: any) => {
        setDuration(response);
      });
    }
  }, [videoCurrent]);

  useEffect(() => {
    return () => {
      dispatch(resetGetAllCohortsData());
      dispatch(resetGetAllUnits());
    };
  }, []);

  return (
    <>
      {(!!params.id && !video) || videoGetStates.loading || !units ? (
        <Loader color="Primary"></Loader>
      ) : (
        <>
          <Grid.Container>
            <Formik
              initialValues={initialValues({
                name: videoDataChanged.name,
                description: videoDataChanged.description,
              })}
              onSubmit={(values, actions) => {
                if (!!video) {
                  onSubmitPatch(values, actions);
                } else {
                  onSubmitPost(values, actions);
                }
              }}
              validateOnChange={false}
              validateOnBlur={false}
              validationSchema={schema}
              validateOnMount={false}
              enableReinitialize
            >
              {({
                touched,
                errors,
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                resetForm,
                setFieldValue,
                isSubmitting,
              }) => {
                return (
                  <form
                    className="theme-form"
                    onSubmit={(event) => {
                      if (
                        values.description.length < 5 ||
                        values.name.length < 5
                      ) {
                        toast.error(
                          "La longitud de la descripcion y el titulo tienen que ser mayor a 5 caracteres"
                        );
                      }
                      if (!videoImage && !video?.picture) {
                        toast.error(
                          "Se necesita subir una imagen de portada para el video"
                        );
                      }
                      if (!video?.url && !videoCurrent) {
                        toast.error("Se necesita subir un video");
                      }
                      setFormSubmmited(true);
                      handleSubmit(event);
                    }}
                    id="video-player-create"
                  >
                    <FlexContainer gap="10px">
                      <div style={{ width: "calc(100% - 50px)" }}>
                        <FlexContainer direction="column" gap="10px">
                          {!videoMedia.videoApi ? (
                            !!video ? (
                              <VideoUploadLoaderWrapper height={"419px"}>
                                <h2>
                                  El video se esta procesando, aparecera en
                                  todos los lugares donde indico una vez
                                  terminado
                                </h2>
                              </VideoUploadLoaderWrapper>
                            ) : !videoCurrent ? (
                              <DropzoneField
                                name="videoSource"
                                onChange={(files) => {
                                  toast.info(
                                    "Los unicos formatos que se pueden previsualizar antes de crearse son mp4-webm-ogv"
                                  );
                                  onSelectVideo(files);
                                }}
                                onBlur={handleBlur}
                                error={errors["videoSource"]}
                                touched={touched["videoSource"]}
                                placeholder={
                                  "Selecciona el archivo que quieras subir"
                                }
                                options={{ size: "big", openOnClick: true }}
                                validation={{
                                  maxFileSize: 1024,
                                  maxFiles: 1,
                                  accept:
                                    "video/mp4,video/x-m4v,video/mkv,video/*",
                                }}
                              />
                            ) : !!newVideoDataStates.loading ? (
                              <LoadingSource
                                height={"419px"}
                                loading={loading}
                                iconSize={100}
                                cancelUploading={cancelUploading}
                              ></LoadingSource>
                            ) : (
                              <CustomPlayer
                                videoCurrent={values}
                                ref={ref}
                                playerHeight="419px"
                                video={videoCurrent.preview}
                                CustomPlay={TrianglePlay}
                                lightImage={
                                  "https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8&w=1000&q=80"
                                }
                                {...(!!videoImage && {
                                  lightImage: videoImage.preview,
                                })}
                                {...(!!videoMedia.imageApi && {
                                  lightImage: videoMedia.imageApi,
                                })}
                              >
                                <RiCloseCircleFill
                                  onClick={() => {
                                    setVideoCurrent(undefined);
                                  }}
                                  size={25}
                                  fill={"#E91E63"}
                                  color={"#fff"}
                                  style={{
                                    position: "absolute",
                                    top: "10px",
                                    right: "10px",
                                    zIndex: 3,
                                  }}
                                ></RiCloseCircleFill>
                              </CustomPlayer>
                            )
                          ) : (
                            <CustomPlayer
                              videoCurrent={videoCurrent}
                              ref={ref}
                              playerHeight="419px"
                              video={videoMedia.videoApi}
                              CustomPlay={TrianglePlay}
                              lightImage={
                                "https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8&w=1000&q=80"
                              }
                              {...(!!videoImage && {
                                lightImage: videoImage.preview,
                              })}
                              {...(!!videoMedia.imageApi && {
                                lightImage: videoMedia.imageApi,
                              })}
                            >
                              {!!video && !video?.published && (
                                <VideoPreviewPublished>
                                  El video no ha sido publicado
                                </VideoPreviewPublished>
                              )}
                              {!!video && (
                                <RiCloseCircleFill
                                  onClick={() => {
                                    setFieldValue("videoSource", "");
                                    setVideoMedia((state) => {
                                      return { ...state, videoApi: undefined };
                                    });
                                    setVideoCurrent(undefined);
                                  }}
                                  size={25}
                                  fill={"#E91E63"}
                                  color={"#fff"}
                                  style={{
                                    position: "absolute",
                                    top: "10px",
                                    right: "10px",
                                    zIndex: 3,
                                  }}
                                ></RiCloseCircleFill>
                              )}
                            </CustomPlayer>
                          )}
                          <VideoUploaderDescriptionWrapper>
                            <FlexContainer
                              justify="space-between"
                              wrap="wrap"
                              gap="10px"
                            >
                              <FlexContainer direction="column" gap="12px">
                                <FlexContainer
                                  direction="column"
                                  gap="12px"
                                  style={{ width: "360px" }}
                                >
                                  <Input
                                    containerStyles={{
                                      width: "100%",
                                      maxWidth: "340px",
                                      borderColor: "#697482",
                                    }}
                                    name="name"
                                    touched={touched["name"]}
                                    value={values["name"]}
                                    type="text"
                                    placeholder="Nombre del Video"
                                    onChange={(ev) => {
                                      setChangedVideoData(ev);
                                      handleChange(ev);
                                    }}
                                    onBlur={handleBlur}
                                    options={{
                                      marginBottom: 1,
                                    }}
                                  />
                                  <TextArea
                                    containerStyles={{
                                      width: "100%",
                                      maxWidth: "340px",
                                    }}
                                    name="description"
                                    touched={touched["description"]}
                                    value={values["description"]}
                                    onChange={(ev) => {
                                      setChangedVideoData(ev);
                                      handleChange(ev);
                                    }}
                                    placeholder="Escriba su descripcion"
                                    onBlur={handleBlur}
                                    options={{
                                      skin: "base",
                                      marginBottom: 1,
                                    }}
                                  />
                                  <FlexContainer
                                    gap="10px"
                                    align="center"
                                    wrap="wrap"
                                  >
                                    {((!!video &&
                                      (video?.users.find(
                                        (userFounded) =>
                                          !userFounded.roleEnd &&
                                          userFounded.id === user._id
                                      )?.role === "OWNER" ||
                                        video?.users.find(
                                          (userFounded) =>
                                            !userFounded.roleEnd &&
                                            userFounded.id === user._id
                                        )?.role === "COOWNER")) ||
                                      !video) && (
                                      <>
                                        <Submit
                                          isSubmmiting={
                                            videoUpdateStates.loading
                                          }
                                          form="video-player-create"
                                          color="Primary"
                                          options={{
                                            type: "filled",
                                            skin: "primary",
                                            size: "lg",
                                          }}
                                        >
                                          Publicar Rapido
                                        </Submit>

                                        <Button
                                          onClick={() => {
                                            dispatch(
                                              showModal("publish-video-modal")
                                            );
                                          }}
                                          type="button"
                                          options={{
                                            type: "filled",
                                            skin: "primary",
                                            size: "lg",
                                          }}
                                          style={{ marginLeft: "10px" }}
                                        >
                                          Publicar
                                        </Button>

                                        <ButtonInput
                                          style={{
                                            ...(!videoCurrent && {
                                              cursor: "not-allowed",
                                            }),
                                            backgroundColor: `${
                                              !videoCurrent
                                                ? "grey"
                                                : "rgb(21, 115, 229)"
                                            }`,
                                          }}
                                        >
                                          Subir Otro Video
                                          {!!videoCurrent && (
                                            <input
                                              accept="video/mp4,video/x-m4v,video/*"
                                              onChange={(ev) => {
                                                toast.info(
                                                  "Los unicos formatos que se pueden previsualizar antes de crearse son mp4-webm-ogv"
                                                );
                                                if (!!videoCurrent) {
                                                  onSelectVideo(
                                                    ev.target.files
                                                  );
                                                }
                                              }}
                                              type="file"
                                              style={{
                                                visibility: "hidden",
                                                width: "0",
                                                height: "0",
                                              }}
                                            ></input>
                                          )}
                                        </ButtonInput>
                                      </>
                                    )}
                                  </FlexContainer>
                                </FlexContainer>
                              </FlexContainer>
                              {!videoImage && !videoMedia.imageApi ? (
                                <DropzoneField
                                  name="videoImg"
                                  onChange={(files) => {
                                    onSelectCover(files);
                                  }}
                                  onBlur={handleBlur}
                                  error={errors["videoImg"]}
                                  touched={touched["videoImg"]}
                                  placeholder={
                                    "Selecciona el archivo que quieras subir"
                                  }
                                  options={{ size: "small", openOnClick: true }}
                                  validation={{
                                    maxFileSize: 1024,
                                    maxFiles: 1,
                                    accept: "image/*",
                                  }}
                                />
                              ) : (
                                <VideoUploadWrapper
                                  height={"200px"}
                                  style={{
                                    width: "340px",
                                    ...(!!videoImage && {
                                      backgroundImage: `url(${videoImage.preview})`,
                                    }),
                                    ...(!!videoMedia.imageApi && {
                                      backgroundImage: `url(${videoMedia.imageApi})`,
                                    }),
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center center",
                                  }}
                                >
                                  <RiCloseCircleFill
                                    onClick={() => {
                                      setVideoImage(undefined);
                                      setVideoMedia((state) => {
                                        return {
                                          ...state,
                                          imageApi: undefined,
                                        };
                                      });
                                    }}
                                    size={25}
                                    fill={"#E91E63"}
                                    color={"#fff"}
                                    style={{
                                      position: "absolute",
                                      top: "10px",
                                      right: "10px",
                                    }}
                                  ></RiCloseCircleFill>
                                </VideoUploadWrapper>
                              )}
                            </FlexContainer>
                          </VideoUploaderDescriptionWrapper>
                        </FlexContainer>
                      </div>
                      <SideBar video={video} menu={menu}></SideBar>
                    </FlexContainer>
                  </form>
                );
              }}
            </Formik>
          </Grid.Container>

          <PublishVideoModal
            onSubmitPost={onSubmitPost}
            duration={duration}
            videoDataChanged={videoDataChanged}
            video={video}
            videoImage={videoImage}
            videoCurrent={videoCurrent}
          ></PublishVideoModal>

          {!!video && <AddUsersModal video={video}></AddUsersModal>}
          {!!video && <ShareModal video={video}></ShareModal>}
          {!!video && (
            <PrivateModal
              video={{
                _id: video?._id,
                published: video?.published,
                name: videoDataChanged.name,
                description: videoDataChanged.description,
                __v: video?.__v,
              }}
            ></PrivateModal>
          )}
          {!!video && (
            <ModalConfirmDelete
              bntConfig={{
                content: "Eliminar",
                style: {
                  style: { width: "170px", height: "40px" },
                  color: "Danger",
                  options: {
                    type: "filled",
                    skin: "danger",
                    size: "lg",
                    marginBottom: "0px",
                  },
                },
              }}
              sucessAction={() => navigate("/dashboard/contents/video")}
              states={videoDeleteStates}
              description={`El Video se eliminara para siempre del sistema.`}
              title={
                <span
                  style={{
                    color: "#697482",
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  {`¿Seguro que quiere eliminar a ${video?.name}?`}
                </span>
              }
              elementActions={deleteVideo({ _id: video?._id })}
              resetAction={resetDeleteVideo}
              resetState={() => {}}
            ></ModalConfirmDelete>
          )}
        </>
      )}
    </>
  );
};

const states = ({
  videoStore,
  usersStore,
  currentStore,
  userStore,
  unitStore,
  cohortStore,
}) => {
  const { data: video } = videoStore.video;
  const { states: videoGetStates } = videoStore.video;
  const { states: videoUpdateStates } = videoStore.update;
  const { states: videoDeleteStates } = videoStore.delete;
  const { data: users } = usersStore.all;
  const { data: videos } = videoStore.all;
  const { data: current } = currentStore;
  const { data: user } = userStore;
  const { states: newVideoDataStates, data: newVideoData } = videoStore.new;
  const { data: units } = unitStore.allUnits;
  const { data: cohorts } = cohortStore.allCohorts;
  return {
    video,
    videoGetStates,
    videoUpdateStates,
    users,
    videoDeleteStates,
    videos,
    current,
    newVideoDataStates,
    user,
    newVideoData,
    units,
    cohorts,
  };
};

export default connect(states)(Component);
