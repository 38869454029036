import React from "react";
import { Button } from "./style";
import { CardRow } from "../../../Profile/style";
import { AiOutlineUserAdd } from "react-icons/ai";
import { DataText } from "../../style";
import { showModal } from "../../../../../../store/actions/modal";
import { useDispatch } from "react-redux";

const Component = () => {
  const dispatch = useDispatch();
  return (
    <Button
      onClick={() => dispatch(showModal("invite-organization-modal-modal"))}
    >
      <CardRow style={{ width: "180px" }} gap="15.8px" align="center">
        <AiOutlineUserAdd fontSize={20} color={"white"}></AiOutlineUserAdd>
        <DataText size="14px" color="#fff">
          Invitar a Organizacion
        </DataText>
      </CardRow>
    </Button>
  );
};

export default Component;
