import * as Yup from "yup";

export const schema = Yup.object().shape({
  cohorts: Yup.array().of(Yup.string()).nullable(),
  units: Yup.array().nullable(),
  available: Yup.boolean().required(),
  name: Yup.string().min(5).required(),
  description: Yup.string(),
});

interface FormValues {
  cohorts: string[];
  units: any[];
  available: boolean;
  name: string;
  description: string;
}

export const initialValues = (intial): FormValues => {
  return {
    cohorts: [],
    units: [],
    available: false,
    name: "",
    description: "",
    ...intial,
  };
};
