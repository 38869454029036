export const minInputDate = () => {
  const today = new Date();
  const dd = today.getDate();
  const mm = today.getMonth() + 1; //January is 0 so need to add 1 to make it 1!
  const yyyy = today.getFullYear();

  const day = dd < 10 ? `0${dd}` : dd;
  const month = mm < 10 ? `0${mm}` : mm;

  return `${yyyy}-${month}-${day}`;
};
