import styled from "styled-components";

export const Container = styled("div")<{ margin?: number }>`
  ${(props) => {
    const { gutter, margin } = props.theme.grid;

    let styles = `
      width: 100%;
      padding-right: ${props.margin || margin}px;
      padding-left: ${props.margin || margin}px;
      margin-right: auto;
      margin-left: auto;
      
      @media ( max-width: 767px ) {
        padding-right: ${gutter}px;
        padding-left: ${gutter}px;
      }
      `;

    return styles;
  }}
`;
