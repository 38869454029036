import action from "../helpers/rest-client";

export class ErrorCohort extends Error {}

class Cdocs {
  public new(payload) {
    return action
      .Post({
        url: "/contents",
        body: payload,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public update({ _id, ...rest }) {
    return action
      .Patch({
        url: `/contents/${_id}`,
        body: !!rest.body ? rest.body : rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public addUserCdoc({ _id, ...rest }) {
    return action
      .Patch({
        url: `/contents/add-user/${_id}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public shareCdoc({ _id, ...rest }) {
    return action
      .Post({
        url: `/contents/share/${_id}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public getAll(payload) {
    if (payload.filterBy) {
      const newFilterBy = { ...payload.filterBy, type: "CDOC" };
      payload.filterBy = JSON.stringify(newFilterBy);
    }

    if (payload.select) {
      payload.select = JSON.stringify(payload.select);
    }

    const queryString = new URLSearchParams(payload).toString();

    return action
      .Get({
        url: `/contents?platform=CONSTANA${
          queryString ? "&" + queryString : ""
        }`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getOne({ _id }) {
    return action
      .Get({
        url: `/contents/${_id}?platform=CONSTANA`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public delete({ _id }) {
    return action
      .Del({
        url: `/contents/${_id}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const cdoc = new Cdocs();
export default cdoc;
