import {
  GetAllListens,
  GET_ALL_LISTENS,
  GetListen,
  GET_LISTEN,
  UpdateListen,
  UPDATE_LISTEN,
  DeleteListen,
  DELETE_LISTEN,
  GetListenSuccess,
  GET_LISTEN_SUCCESS,
  NewListen,
  NEW_LISTEN,
  ResetNewListen,
  RESET_NEW_LISTEN,
  ResetUpdateListen,
  RESET_UPDATE_LISTEN,
  ResetDeleteListen,
  RESET_DELETE_LISTEN,
  UploadMedia,
  UPLOAD_MEDIA,
  RESET_GET_LISTEN,
  ADD_USER_TO_LISTEN,
  SHARED_LISTEN,
  UPDATE_MANY_LISTEN,
  RESET_UPDATE_MANY_LISTEN,
  RESET_GET_ALL_LISTEN_DATA,
} from "../types/listen";

export const getAllListens = (payload): GetAllListens => {
  return { type: GET_ALL_LISTENS, payload };
};

export const resetGetAllListens = () => {
  return { type: RESET_GET_ALL_LISTEN_DATA, payload: null };
};

export const getListen = (id): GetListen => {
  return { type: GET_LISTEN, payload: id };
};

export const resetGetListen = () => {
  return { type: RESET_GET_LISTEN, payload: null };
};

export const setSelectedListen = (payload): GetListenSuccess => {
  return { type: GET_LISTEN_SUCCESS, payload };
};

export const newListen = (payload): NewListen => {
  return { type: NEW_LISTEN, payload };
};

export const updateManyListen = (payload) => {
  return { type: UPDATE_MANY_LISTEN, payload };
};

export const resetUpdateManyListen = () => {
  return { type: RESET_UPDATE_MANY_LISTEN, payload: null };
};
export const uploadMediaListen = (payload): UploadMedia => {
  return { type: UPLOAD_MEDIA, payload };
};
export const resetNewListen = (): ResetNewListen => {
  return { type: RESET_NEW_LISTEN, payload: null };
};

export const updateListen = (payload): UpdateListen => {
  return { type: UPDATE_LISTEN, payload };
};

export const resetUpdateListen = (): ResetUpdateListen => {
  return { type: RESET_UPDATE_LISTEN, payload: null };
};

export const addUserToListen = (payload) => {
  return { type: ADD_USER_TO_LISTEN, payload };
};

export const deleteListen = (payload): DeleteListen => {
  return { type: DELETE_LISTEN, payload };
};

export const resetDeleteListen = (): ResetDeleteListen => {
  return { type: RESET_DELETE_LISTEN, payload: null };
};

export const shareListen = (payload) => {
  return { type: SHARED_LISTEN, payload };
};
