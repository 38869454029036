import { connect } from "react-redux";
import Grid from "../../../../../../components/Grid";
import LineGraph from "../../../../../../components/LineGraph";
import {
  ChartContainer,
  NoteCard,
} from "../../../../../../components/LineGraph/styles";
import { formatStages } from "../../../../../../helpers/format-stages";
import { AnalyticsCohortDto } from "../../../../../../types/analytics.dto";
import NumericStats from "../../../IndividualPerformance/components/NumericStats/NumericStats";
import {
  GrowingStages,
  GrowingStageWrapper,
  TitleCriterion,
  TitleStage,
} from "../../../IndividualPerformance/components/NumericStats/styles";
import { BootcampCard } from "../../styles";
import { LargeTitle } from "../Status/styles";
import {
  FlexContainer,
  Separator,
} from "../../../../../../components/StyledComponents";
import { BigGrade } from "../../../IndividualPerformance/components/SprintStats/styles";
import { arrayLengthDivision } from "../../../../../../helpers/notLengthDivision";
import { roundedNumbers } from "../../../../../../helpers/rounded-numbers";
import Loader from "../../../../../../components/Loader";

interface Props {
  selectedCohort?: {
    name: string;
    id: string;
    cohortStudents?: number;
  };
  analytics: AnalyticsCohortDto;
}

const Component = ({ analytics, selectedCohort }: Props) => {
  if (!analytics) return <Loader color="Primary"></Loader>;
  const globalStageProm = analytics.stagesNotes.reduce(
    (prev, current, currentIndex, array) => {
      if (currentIndex === 0) return prev;
      return (
        prev +
        ((current.avg - array[currentIndex - 1].avg) /
          (array[currentIndex - 1].avg === 0
            ? 1
            : array[currentIndex - 1].avg)) *
          100
      );
    },
    0
  );
  return (
    <Grid.Row style={{ width: "100%" }}>
      <Grid.Col>
        <Grid.Row>
          <Grid.Col lg={3.9} sm={12}>
            <BootcampCard>
              <TitleCriterion>Nombre del Cohort:</TitleCriterion>

              <LargeTitle title={selectedCohort?.name}>
                {selectedCohort?.name}
              </LargeTitle>
            </BootcampCard>
          </Grid.Col>
          <Grid.Col lg={3.9} offset={{ lg: 0.1 }} sm={12}>
            <BootcampCard style={{ height: "100%" }}>
              <TitleCriterion>Crecimiento por etapas promedios</TitleCriterion>
              <Separator size={20}></Separator>
              <FlexContainer justify="center">
                <BigGrade>
                  {`${roundedNumbers(
                    globalStageProm / arrayLengthDivision(analytics.stagesNotes)
                  )}%`}
                </BigGrade>
              </FlexContainer>
            </BootcampCard>
          </Grid.Col>
        </Grid.Row>
        <Separator size={25}></Separator>
        <Grid.Row>
          <Grid.Col lg={12} sm={12}>
            <ChartContainer>
              <NoteCard height={"273px"}>
                <TitleStage>Crecimiento promedio global por etapas</TitleStage>
                <LineGraph
                  analytics={analytics?.stagesNotes.map((el) => {
                    return {
                      level: el.stage,
                      note: roundedNumbers(el.avg),
                    };
                  })}
                />
              </NoteCard>
            </ChartContainer>
          </Grid.Col>
        </Grid.Row>
      </Grid.Col>
    </Grid.Row>
  );
};

const states = ({ analyticsStore }) => {
  const { states: analyticsStates } = analyticsStore.getCohortStats;
  return {
    analyticsStates,
  };
};

export default connect(states)(Component);
