import styled from "styled-components";
import crossDanger from "./../../../assets/icons/cross-circle-red.svg";

const makeVariation = ({ theme, skin, state }) => {
  return `
    border-color: ${theme.form.skin[skin][state].borderColor};
    background-color: ${theme.form.skin[skin][state].backgroundColor};
  

    textarea, 
    input {
      color: ${theme.form.skin[skin][state].color};
    }

    textarea::-webkit-input-placeholder {
      color: #c1c1c1;
    }
    
    textarea:-moz-placeholder { /* Firefox 18- */
      color: #c1c1c1;  
    }
    
    textarea::-moz-placeholder {  /* Firefox 19+ */
      color: #c1c1c1;  
    }
    
    textarea:-ms-input-placeholder {
      color: #c1c1c1;  
    }
    
    textarea::placeholder {
      color: #c1c1c1;  
    }

    
    input::-webkit-input-placeholder {
      color: #c1c1c1;
    }
    
    input:-moz-placeholder { /* Firefox 18- */
      color: #c1c1c1;  
    }
    
    input::-moz-placeholder {  /* Firefox 19+ */
      color: #c1c1c1;  
    }
    
    input:-ms-input-placeholder {
      color: #c1c1c1;  
    }
    
    input::placeholder {
      color: #c1c1c1;  
    }


    .select__control {
      color: ${theme.form.skin[skin][state].color}!important;

      .select__placeholder{
        color: #c1c1c1!important;
      }

      & > i {
        &:before{
          background-color: ${theme.form.skin[skin][state].iconColor}!important;
        }
      }
    }

    .field-before,
    .field-after{
      i {
        &:before{
          background-color: ${theme.form.skin[skin][state].iconColor}!important;
        }
      }
    }

    .select__indicators{
      i {
        &:before{
          background-color: ${theme.form.skin[skin][state].iconColor}!important;
        }
      }
    }`;
};

export const Backdrop = styled("div")`
  position: absolute;
  z-index: 2;
  pointer-events: none;
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 15px;
`;

export const FieldContainer = styled("div")<{ marginBottom: number }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: ${(props) =>
    props.marginBottom >= 0 ? props.marginBottom : 40}px;
`;

export const Field = styled("div")<{ skin; size }>`
  ${(props) => {
    const { theme, skin = "base", size = "md" } = props;
    const modalBoxShadow = "0 0 6px 0 rgba(21, 115, 229, 0.16)";
    return `
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    ${
      !!props.select
        ? `min-height: calc(${theme.form.size[size].height} - 2px); padding-left: ${theme.form.size[size].padding};
    padding-right: ${theme.form.size[size].padding};`
        : ""
    };
    box-shadow: ${modalBoxShadow};
    transition: all 0.3s ease-in-out;
  /*   padding-left: ${theme.form.size[size].padding};
    padding-right: ${theme.form.size[size].padding}; */
    transition: all 150ms ease-in-out;
    border: 2px solid;
    border-radius: 10px;
    background:${
      props.theme.form.skin[props.skin].idle.backgroundColor
    } inset !important};
    ${
      !!props.search
        ? "width:400px;min-height:unset;height:26px;border-radius: 10px;"
        : ""
    };
    ${makeVariation({ theme, skin, state: "idle" })}
    &.simple{
       box-shadow: unset;
        border-radius: unset;
        border: none;
        &::after{
          content:"";
          width: 340px;
          height: 0.5px;
          position:absolute;
          bottom:0;
          background-color:#a1b0c4;
        }
    }
    &:disabled,
    &:hover,
    &:focus,
    &:active {
      outline: 0;
    }
  
    &.with-error {
      ${makeVariation({ theme, skin, state: "error" })}
    }
  
    &.field-active {
      ${makeVariation({ theme, skin, state: "active" })}
    }
  
    &.field-disabled{
      cursor: not-allowed;
      ${makeVariation({ theme, skin, state: "disabled" })}
    }
     ${
       !!props.search
         ? "width:400px;min-height:unset;height:26px;border-radius: 10px;"
         : ""
     };
    
    
    `;
  }}
`;

export const Label = styled("label")<{ size }>`
  font-weight: 500;
  font-size: ${(props) => props.theme.form.size[props.size || "md"].fontSize};
  line-height: ${(props) =>
    props.theme.form.size[props.size || "md"].lineHeight};
  color: ${(props) => props.theme.colors.black84};
  margin-bottom: 5px;
  padding: 0px 20px;
`;

export const HelperLine = styled("div")<{ marginBottom }>`
  line-height: 16px;
  position: absolute;
  top: 100%;
  left: 0px;
  width: 100%;
  padding: 0px;
  margin-top: 6px;
  height: 15px;
`;

export const HelperText = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  color: rgba(36, 40, 44, 0.76);
`;

const fieldBeforeAndAfter = `
  min-width: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;`;

export const FieldBefore = styled("div")<{ size }>`
  ${(props) => {
    const { theme, size = "md" } = props;

    return `
      ${fieldBeforeAndAfter}
      margin-right: ${theme.form.size[size].padding};`;
  }}
`;

export const FieldAfter = styled("div")<{ size }>`
  ${(props) => {
    const { theme, size = "md" } = props;

    return `
      ${fieldBeforeAndAfter}
      margin-left: ${theme.form.size[size].padding};`;
  }}
`;

export const CharacterCounter = styled("span")<{ errors }>`
  ${(props) => {
    const { theme, errors } = props;

    return `
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 15px;
      text-align: right;
      position: absolute;
      right: 0px;
      bottom: 0px;
      color: ${theme.colors.black84};`;
  }}
`;

export const ErrorMessage = styled.span`
  position: relative;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  color: ${(props) => props.theme.colors.Danger};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 22px;

  &:before {
    content: "";
    width: 14px;
    height: 14px;
    background-image: url(${crossDanger});
    background-position: center;
    background-size: cover;
    margin-right: 6px;
  }
`;

export const TooglePassword = styled.button`
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  background: none;
  box-shadow: none;
  height: 24px;
`;

export const Required = styled.sup`
  color: red;
`;

export const ExplainText = styled("p")`
  margin: 8px 0px;
  font-size: 14px;
  line-height: 17px;
  color: #2d3436;
`;

export const InLine = styled("div")`
  display: grid;
  grid-template-columns: ${(props) =>
    !!props.column ? props.column : "1fr 1fr 1fr"};
  grid-column-gap: 10px;
`;
