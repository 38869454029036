import { put, call, all, takeLatest } from "redux-saga/effects";
import services from "../../services";
import {
  GET_ALL_MEETS,
  GET_ALL_MEETS_SUCCESS,
  GET_ALL_MEETS_ERROR,
  GetAllMeets,
} from "../types/meet";

function* getAllMeets() {
  const { response, error } = yield call(services.meet.getAll);

  if (error) {
    yield put({
      type: GET_ALL_MEETS_ERROR,
      payload: error.response.data?.message || "Error",
    });
  } else {
    yield put({ type: GET_ALL_MEETS_SUCCESS, payload: response });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<GetAllMeets>(GET_ALL_MEETS, getAllMeets);
}
