import {
  SET_NEW_SEARCH_DATA,
  SET_UPDATE_SEARCH_DATA,
} from "../../types/recruiting/search";
import {
  initialState,
  Actions,
  State,
  GET_ALL_TECHSKILLS,
  GET_ALL_TECHSKILLS_ERROR,
  GET_ALL_TECHSKILLS_SUCCESS,
  GET_TECHSKILL,
  GET_TECHSKILL_ERROR,
  GET_TECHSKILL_SUCCESS,
  NEW_TECHSKILL,
  NEW_TECHSKILL_ERROR,
  NEW_TECHSKILL_SUCCESS,
  RESET_NEW_TECHSKILL,
  UPDATE_TECHSKILL,
  UPDATE_TECHSKILL_ERROR,
  UPDATE_TECHSKILL_SUCCESS,
  RESET_UPDATE_TECHSKILL,
  DELETE_TECHSKILL,
  DELETE_TECHSKILL_ERROR,
  DELETE_TECHSKILL_SUCCESS,
  RESET_DELETE_TECHSKILL,
  SET_NEW_TECHSKILL_DATA,
  SET_UPDATE_TECHSKILL_DATA,
  UPDATE_CURRENT_TECHSKILL_LIST,
} from "../../types/recruiting/tech-skill";

const updateReducerArray = (array, data) =>
  array.map((elem) => (elem._id === data._id ? data : elem));

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_TECHSKILLS:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_ALL_TECHSKILLS_SUCCESS:
      return {
        ...state,
        all: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_ALL_TECHSKILLS_ERROR:
      return {
        ...state,
        all: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case GET_TECHSKILL:
      return {
        ...state,
        selected: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_TECHSKILL_SUCCESS:
      return {
        ...state,
        selected: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_TECHSKILL_ERROR:
      return {
        ...state,
        selected: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case NEW_TECHSKILL:
      return {
        ...state,
        create: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case SET_NEW_TECHSKILL_DATA:
      return {
        ...state,
        all: {
          ...state.all,
          data: !!state.all.data
            ? [...state.all.data, action.payload]
            : [action.payload],
        },
      };
    case UPDATE_CURRENT_TECHSKILL_LIST:
      return {
        ...state,
        all: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: false,
          },
        },
      };
    case SET_UPDATE_TECHSKILL_DATA:
      return {
        ...state,
        all: {
          ...state.all,
          data: !!state.all.data
            ? updateReducerArray(state.all.data, action.payload)
            : null,
        },
      };
    case NEW_TECHSKILL_SUCCESS:
      return {
        ...state,
        create: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case NEW_TECHSKILL_ERROR:
      return {
        ...state,
        create: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_NEW_TECHSKILL:
      return {
        ...state,
        create: initialState.create,
      };
    case DELETE_TECHSKILL:
      return {
        ...state,
        delete: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case DELETE_TECHSKILL_SUCCESS:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case DELETE_TECHSKILL_ERROR:
      return {
        ...state,
        delete: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_DELETE_TECHSKILL:
      return {
        ...state,
        delete: initialState.delete,
      };
    case UPDATE_TECHSKILL:
      return {
        ...state,
        update: {
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case UPDATE_TECHSKILL_SUCCESS:
      return {
        ...state,
        update: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case UPDATE_TECHSKILL_ERROR:
      return {
        ...state,
        update: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case RESET_UPDATE_TECHSKILL:
      return {
        ...state,
        update: initialState.update,
      };
    default:
      return state;
  }
};

export default reducers;
