import action from "../helpers/rest-client";

export class ErrorCriterion extends Error {}

class Evaluate {
  public new(payload) {
    return action
      .Post({
        url: "/evaluate",
        body: payload,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public update({ _id, ...rest }) {
    return action
      .Patch({
        url: `/evaluate/${_id}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public getAll(payload) {
    if (payload?.filterBy) {
      payload.filterBy = JSON.stringify(payload?.filterBy);
    }

    if (payload?.select) {
      payload.select = JSON.stringify(payload?.select);
    }

    const queryString = new URLSearchParams(payload).toString();

    return action
      .Get({
        url: `/evaluate?platform=CONSTANA${
          queryString ? "&" + queryString : ""
        }`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const criterion = new Evaluate();
export default criterion;
