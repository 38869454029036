import styled from "styled-components";

export const WrapperBootcamp = styled("div")`
  padding: 10px;
  border-radius: 10px;
  
  min-height: 580px;
`;

export const BootcampCard = styled("div")`
  padding: 10px 12px;
  border-radius: 10px;
  box-shadow: 0 0 3px 0 rgba(21, 115, 229, 0.16);
`;

export const WrapperList = styled("div")`
  border-radius: 10px;
  
  padding: 10px;
`;
