import { connect } from "react-redux";
import { InLine } from "../../../../../../components/StyledComponents";
import CrossIcon from "../../../../../../assets/icons/cross.svg";
import { useRecruitingLayout } from "../../../../../../provider/RecruitingProvider";
import Icon from "../../../../../../components/Icon";
import {
  TabTitle,
  Description,
  Content,
  ListTitle,
  List,
  ListItem,
  Delete,
} from "../../../components/StyledComponents";
import { useDispatch } from "react-redux";
import ShowModal from "../../../components/ShoModal";
import { SearchDto } from "../../../../../../types/recruiting.dto";
import actionData from "../../../../../../store/actions/recruiting/index";
import { toast } from "react-toastify";
import { useEffect } from "react";
interface ComponentProps {
  searches: any;
  searchesUpdateStates: {
    success: boolean;
    error: string | boolean;
    loading: boolean;
  };
}

const Component = ({ searches, searchesUpdateStates }: ComponentProps) => {
  const { selectedItem, actions } = useRecruitingLayout();
  const dispatch = useDispatch();

  useEffect(() => {
    if (searchesUpdateStates.success) {
      toast.success("👌🏼 Busqueda editada correctamente");

      setTimeout(() => {
        dispatch(actionData.search.resetUpdate());
      }, 1000);
    }

    if (searchesUpdateStates.error) {
      toast.error(`😱 ${searchesUpdateStates.error}`);

      setTimeout(() => {
        dispatch(actionData.search.resetUpdate());
      }, 1000);
    }
  }, [searchesUpdateStates]);
  return (
    <>
      <InLine justify="space-between">
        <TabTitle>Búsquedas asignadas a la empresa</TabTitle>
        <ShowModal.AsignarBusqueda />
      </InLine>
      <Description>
        Asignar búsquedas a la empresa te ayudara a ti y a Dev Place a facilitar
        la conexión entre talentos, tus búsquedas y las necesidades de esta
        empresa en particular. También te enviaremos recomendaciones de perfiles
        que no has seleccionado pero que por la información recopilada
        recomendaremos que les eches un vistazo.
      </Description>

      <Content>
        <ListTitle>Busquedas</ListTitle>
        <List>
          {searches
            ?.filter((search) => search?.active)
            .filter((search) =>
              search?.companies?.includes(selectedItem?.value)
            )
            .map((item) => (
              <ListItem>
                {item.name}
                <Delete
                  onClick={() =>
                    dispatch(
                      actionData.search.update({
                        _id: item._id,
                        companies: item.companies.filter(
                          (sea) => sea !== selectedItem?.value
                        ),
                      })
                    )
                  }
                >
                  <Icon icon={CrossIcon} size="9px" color="black" />
                </Delete>
              </ListItem>
            ))}
        </List>
      </Content>
    </>
  );
};

const states = ({ recruitingStore }) => {
  const { data: searches, states: searchesStates } = recruitingStore.search.all;
  const { states: searchesUpdateStates } = recruitingStore.search.update;
  return { searches, searchesStates, searchesUpdateStates };
};

export default connect(states)(Component);
