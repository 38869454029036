import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import Input from "../../../../../../components/Form/Input";
import Submit from "../../../../../../components/Form/Submit";
import Grid from "../../../../../../components/Grid";
import Modal from "../../../../../../components/Modal";
import { Center } from "../../../../../../components/StyledComponents";
import {
  schema,
  initialValues,
} from "../../../../../../constants/form/recruiting/ait/create-talent";
import { connect, useDispatch } from "react-redux";
import ReactSelect from "../../../../../../components/Form/ReactSelect";
import Button from "../../../../../../components/Button";
import { hideModal, showModal } from "../../../../../../store/actions/modal";
import actionsData from "../../../../../../store/actions/recruiting/index";
import { CompanyDto, SearchDto } from "../../../../../../types/recruiting.dto";
import { toast } from "react-toastify";
import { CurrentDto } from "../../../../../../types/current.dto";
import { UserDto } from "../../../../../../types/user.dto";
import { useParams } from "react-router-dom";
interface ComponentProps {
  companies: CompanyDto[];
  searches: SearchDto[];
  talentStates: {
    success: boolean;
    loading: boolean;
    error: string | boolean;
  };
  current: CurrentDto;
  user: UserDto;
}

const Index = ({
  companies,
  searches,
  talentStates,
  current,
  user,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const onSubmit = (values, actions) => {
    const section = current.currentSection === "organization" ? "team" : "user";
    const data =
      current.currentSection === "organization" ? params.id : user._id;

    dispatch(
      actionsData.aitTalent.create({
        ...values,
        external: true,
        [section]: `${data}`,
        ...(current.currentSection === "academy" && {
          currentAcademy: current.id,
        }),
      })
    );
    setFormActions(actions);
  };

  useEffect(() => {
    if (talentStates.success) {
      toast.success("👌🏼 Talento creado exitosamente");
      dispatch(hideModal());
      setTimeout(() => {
        dispatch(actionsData.aitTalent.resetCreate());
        formActions?.resetForm();
      }, 1000);
    }

    if (talentStates.error) {
      toast.error(`😱 ${talentStates.error}`);

      setTimeout(() => {
        dispatch(actionsData.aitTalent.resetCreate());
      }, 1000);
    }
  }, [talentStates]);
  return (
    <>
      <Button
        onClick={() => dispatch(showModal("create-talent-modal"))}
        options={{
          type: "outline",
          size: "md",
          skin: "gray",
        }}
      >
        Agregar Talentos
      </Button>
      <Modal name="create-talent-modal" title="Crear Talento" width={"580px"}>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => onSubmit(values, actions)}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={schema}
          enableReinitialize
        >
          {({
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
            setFieldValue,
            isSubmitting,
          }) => {
            return (
              <form
                className="theme-form"
                onSubmit={(event) => {
                  setFormSubmmited(true);
                  handleSubmit(event);
                }}
                id="create-talent-form"
              >
                <Grid.Row>
                  <Grid.Col>
                    <Input
                      name="name"
                      modal
                      error={errors["name"]}
                      touched={touched["name"]}
                      value={values["name"]}
                      type="text"
                      placeholder=""
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={{
                        label: "Nombre",
                        marginBottom: 24,
                      }}
                    />
                    <Input
                      name="lastName"
                      modal
                      error={errors["lastName"]}
                      touched={touched["lastName"]}
                      value={values["lastName"]}
                      type="text"
                      placeholder=""
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={{
                        label: "Apellido",
                        marginBottom: 24,
                      }}
                    />
                    <Input
                      name="email"
                      modal
                      error={errors["email"]}
                      touched={touched["email"]}
                      value={values["email"]}
                      type="text"
                      placeholder=""
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={{
                        label: "Email",
                        marginBottom: 24,
                      }}
                    />
                    <ReactSelect
                      name="companies"
                      isMulti
                      modal
                      error={errors["companies"]}
                      touched={touched["companies"]}
                      items={
                        !!companies &&
                        companies
                          .filter((company) => company.active)
                          .filter((company) =>
                            !params.id
                              ? !company.team
                              : company.team === params.id
                          )
                          .map((company) => ({
                            label: company.name,
                            value: company._id,
                          }))
                      }
                      placeholder="Seleccionar Empresa"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={{
                        label: "Seleccionar Empresas",
                        marginBottom: 24,
                      }}
                    />
                    <ReactSelect
                      name="searches"
                      isMulti
                      modal
                      error={errors["searches"]}
                      touched={touched["searches"]}
                      items={
                        !!searches &&
                        searches
                          .filter((skill) => skill.active)
                          .filter((search) =>
                            !params.id
                              ? !search.team
                              : search.team === params.id
                          )
                          .map((skill) => ({
                            label: skill.name,
                            value: skill._id,
                          }))
                      }
                      placeholder="Seleccionar Busqueda"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={{
                        label: "Seleccionar Busquedas",
                        marginBottom: 24,
                      }}
                    />
                  </Grid.Col>
                </Grid.Row>
                <Center>
                  <Submit
                    isSubmmiting={talentStates.loading}
                    form="create-talent-form"
                    color="Primary"
                    options={{
                      type: "filled",
                      skin: "primary",
                      size: "lg",
                      marginBottom: "0px",
                    }}
                  >
                    Confirmar
                  </Submit>
                </Center>
              </form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

const states = ({ recruitingStore, currentStore, userStore }) => {
  const { data: companies } = recruitingStore.company.all;
  const { data: searches } = recruitingStore.search.all;
  const { states: talentStates } = recruitingStore.talent.create;
  const { data: current } = currentStore;
  const { data: user } = userStore;
  return {
    searches,
    companies,
    talentStates,
    current,
    user,
  };
};

export default connect(states)(Index);
