import action from "../helpers/rest-client";

export class ErrorTeam extends Error {}

class Team {
  public getOne(payload) {
    return action
      .Get({
        url: `/teams/${payload}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public new(payload) {
    return action
      .Post({
        url: "/teams",
        body: payload,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public update({ _id, ...rest }) {
    return action
      .Patch({
        url: `/teams/${_id}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
  public createLink(payload) {
    return action
      .Patch({
        url: `/teams/create-link/${payload}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public deleteLink(payload) {
    return action
      .Patch({
        url: `/teams/delete-link/${payload}`,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return { error };
      });
  }

  public updateRole({ _id, ...rest }) {
    return action
      .Patch({
        url: `/teams/update-role/${_id}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public deleteMember({ _id, ...rest }) {
    return action
      .Patch({
        url: `/users/remove-from-team/${_id}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public getAll() {
    return action
      .Get({
        url: "/teams",
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public delete(cohortId) {
    return action
      .Del({
        url: `/teams/${cohortId}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const teams = new Team();

export default teams;
