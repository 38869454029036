import React from "react";
import { Row } from "./styles";

interface ComponentsProps {
  gutter?: number;
  children?: any;
  style?: any;
  sm?: number;
  md?: number;
  lg?: number;
  offset?: {
    sm?: number;
    md?: number;
    lg?: number;
  };
}

const Component = ({ children, gutter, ...rest }: ComponentsProps) => {
  return (
    <Row gutter={gutter} {...rest}>
      {children}
    </Row>
  );
};

export default Component;
