import ACADEMY from "./academy/index";
import ORGANIZATION from "./organization/index";
import USER from "./user/USER";
import TEAM from "./team/index";
const permissions = {
  academy: ACADEMY,
  organization: ORGANIZATION,
  personal: USER,
  team: TEAM,
};

export default permissions;
