import React, { useEffect, useState } from "react";
import Modal from "../../../../../../components/Modal";
import { Information } from "../../../../Marketplace/components/TalentCard/styles";
import { Location } from "../../../../Marketplace/components/TalentCard/styles";
import { ListLabel, TalentAvatar } from "../TalentCard/styles";
import { Name, Profile, TalentCard, TalentCol, TalentRow } from "./styles";
import Envelope from "../../../../../../assets/icons/menu/envelope.svg";
import GitHub from "../../../../../../assets/icons/menu/github.svg";
import Linkdein from "../../../../../../assets/icons/menu/linkedin.svg";
import BriefCase from "../../../../../../assets/icons/menu/briefcase.svg";
import { Center } from "../../../../../../components/StyledComponents";
import Submit from "../../../../../../components/Form/Submit";
import { Formik } from "formik";
import DinamicField from "../../../../../../components/Form/DinamicField";
import {
  schema,
  initialValues,
} from "../../../../../../constants/form/recruiting/ait/edit-talent";
import LineGraph from "../../../../../../components/LineGraph";
import { connect } from "react-redux";
import { formatStages } from "../../../../../../helpers/format-stages";
import { AnalyticsDto } from "../../../../../../types/analytics.dto";
import {
  AitTalentDto,
  CompanyDto,
  SearchDto,
} from "../../../../../../types/recruiting.dto";
import { useDispatch } from "react-redux";
import actionsData from "../../../../../../store/actions/recruiting/index";
import { toast } from "react-toastify";
import { hideModal } from "../../../../../../store/actions/modal";
import Link from "../../../../../../components/Link/index";
import { useParams } from "react-router-dom";

interface ComponentProps {
  user?: AitTalentDto;
  analytics: AnalyticsDto;
  companies: CompanyDto[];
  searches: SearchDto[];
  aitTalentStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({
  user,
  analytics,
  companies,
  searches,
  aitTalentStates,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formActions, setFormActions] = useState<{ resetForm: () => void }>();
  const params = useParams();
  const onSubmit = (values, actions) => {
    dispatch(actionsData.aitTalent.update({ ...values, _id: user?._id }));
    setFormActions(actions);
  };

  useEffect(() => {
    if (aitTalentStates.success) {
      toast.success("👌🏼 Talento editado exitosamente");
      dispatch(hideModal());
      setTimeout(() => {
        dispatch(actionsData.aitTalent.resetUpdate());
        formActions?.resetForm();
      }, 1000);
    }

    if (aitTalentStates.error) {
      toast.error(`😱 ${aitTalentStates.error}`);

      setTimeout(() => {
        dispatch(actionsData.aitTalent.resetUpdate());
      }, 1000);
    }
  }, [aitTalentStates]);
  return (
    <Modal
      width={"397px"}
      animation="fadeInSlideRight"
      name="talent-side-modal"
      title={`${user?.name || ""} ${user?.lastName || ""}`}
      styles={{
        top: 0,
        bottom: 0,
        height: "100%",
        overflowY: "auto",
      }}
      padding={"11px 5px 30px 5px"}
      position={"right"}
      height={"100vh"}
    >
      <Formik
        initialValues={{
          companies:
            !!companies &&
            companies
              .filter((company) => company.active)
              .filter((company) =>
                !params.id ? !company.team : company.team === params.id
              )
              .filter((company) => user?.companies.includes(company._id))
              .map((company) => company._id),
          searches:
            !!searches &&
            searches
              .filter((search) => search.active)
              .filter((search) =>
                !params.id ? !search.team : search.team === params.id
              )
              .filter((search) => user?.searches.includes(search._id))
              .map((search) => search._id),
        }}
        onSubmit={(values, actions) => {
          onSubmit(values, actions);
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="update-talent-form"
            >
              <TalentCard>
                <TalentCol>
                  <TalentRow>
                    <TalentAvatar avatar={!!user?.avatar && user?.avatar} />
                    <Information>
                      <Name>{`${user?.name} ${user?.lastName}`}</Name>
                      <Profile>{"Bootcamp"}</Profile>
                      <Location>
                        Ubicación:{" "}
                        <span>{!!user?.country && user.country}</span>
                        {", "}
                        <span>{!!user?.province && user.province}</span>
                      </Location>
                    </Information>
                  </TalentRow>
                </TalentCol>
              </TalentCard>
              <TalentCard>
                <TalentCol>
                  <TalentRow>
                    <ListLabel>
                      <span className="text">Contactar:</span>
                      <Link
                        href={user?.email ? user?.email : ""}
                        options={{
                          type: "link",
                          skin: "primary",
                          size: "sm",
                          marginBottom: "0px",
                        }}
                      >
                        <img src={Envelope} alt="envelope" />
                      </Link>
                      <Link
                        href={user?.linkedin ? user?.linkedin : ""}
                        options={{
                          type: "link",
                          skin: "primary",
                          size: "sm",
                          marginBottom: "0px",
                        }}
                      >
                        <img src={Linkdein} alt="linkedin" />
                      </Link>
                    </ListLabel>
                  </TalentRow>
                </TalentCol>
              </TalentCard>
              <TalentCard>
                <TalentCol>
                  <TalentRow>
                    <ListLabel>
                      <span className="text">Ver Trabajo:</span>
                      <Link
                        href={user?.github ? user.github : ""}
                        options={{
                          type: "link",
                          skin: "primary",
                          size: "sm",
                          marginBottom: "0px",
                        }}
                      >
                        <img src={GitHub} alt="GitHub" />
                      </Link>
                      <Link
                        href={user?.portfolio ? user?.portfolio : ""}
                        options={{
                          type: "link",
                          skin: "primary",
                          size: "sm",
                          marginBottom: "0px",
                        }}
                      >
                        <img src={BriefCase} alt="BriefCase" />
                      </Link>
                    </ListLabel>
                  </TalentRow>
                </TalentCol>
              </TalentCard>
              <TalentCard>
                Ver metricas:
                <TalentRow>
                  <TalentCol>
                    <LineGraph
                      analytics={formatStages({
                        stages: !!analytics && analytics?.stagesNotes,
                        type: "global",
                      })}
                    />
                  </TalentCol>
                </TalentRow>
              </TalentCard>
              <DinamicField
                name="searches"
                error={errors["searches"]}
                touched={touched["searches"]}
                value={values["searches"]}
                onChange={handleChange}
                onBlur={handleBlur}
                options={{
                  height: "48px",
                  type: "select",
                  multi: true,
                  data:
                    !!searches &&
                    searches
                      .filter((search) => search.active)
                      .filter((search) =>
                        !params.id ? !search.team : search.team === params.id
                      )
                      .map((search) => ({
                        label: search.name,
                        value: search._id,
                      })),
                  inputLabel: "Asignar Busquedas",
                  inputPlaceholder: "Asigne una busqueda",
                }}
              />
              <DinamicField
                name="companies"
                error={errors["companies"]}
                touched={touched["companies"]}
                value={values["companies"]}
                onChange={handleChange}
                onBlur={handleBlur}
                options={{
                  height: "48px",
                  multi: true,
                  type: "select",
                  data:
                    !!companies &&
                    companies
                      .filter((company) => company.active)
                      .filter((company) =>
                        !params.id ? !company.team : company.team === params.id
                      )
                      .map((company) => ({
                        label: company.name,
                        value: company._id,
                      })),
                  inputLabel: "Asignar Empresas",
                  inputPlaceholder: "Asigne una Empresa",
                }}
              />

              <Center>
                <Submit
                  isSubmmiting={aitTalentStates.loading}
                  form="update-talent-form"
                  color="Primary"
                  options={{
                    type: "filled",
                    skin: "primary",
                    size: "lg",
                    marginBottom: "0px",
                  }}
                >
                  Confirmar
                </Submit>
              </Center>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const states = ({ analyticsStore, recruitingStore }) => {
  const { states: analyticsStates, data: analytics } =
    analyticsStore.getIndividualStats;
  const { data: companies } = recruitingStore.company.all;
  const { data: searches } = recruitingStore.search.all;
  const { states: aitTalentStates } = recruitingStore.talent.update;
  return {
    analyticsStates,
    analytics,
    companies,
    searches,
    aitTalentStates,
  };
};

export default connect(states)(Component);
