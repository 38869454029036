import { FlexContainer } from "../../../../../components/StyledComponents";

const Component = ({ data, children }) => {
  const NotDataShow = (
    <FlexContainer align="center" justify="center" style={{ height: "100%" }}>
      <h3>N/A</h3>
    </FlexContainer>
  );
  if (typeof data === "object" && !Array.isArray(data)) {
    if (Object.entries(data).length === 0) return NotDataShow;
  } else if (typeof data === "object" && Array.isArray(data)) {
    if (data.length === 0) return NotDataShow;
  }
  return children;
};

export default Component;
